import React, { useEffect } from 'react';
import { Input, Modal, Button, Row, Col } from 'antd';

function AccesoryModal({ accessoriesModal, scanAccessoriesFunction, accessoriesToBeScan, inputState, setInputState, accesoryInput, refreshDispatch }) {

    useEffect(() => {
        if (inputState && inputState.input) {
            inputState.input.focus();
            inputState.input.value = ""
        }
    }, [inputState, accessoriesToBeScan])

    return (
        <Modal
            visible={accessoriesModal}
            centered
            width={700}
            footer={null}
        >
            <div>
                <h1 style={{ fontWeight: "bold", fontSize: "15px", textAlign: "center" }}>Please scan barcode for {`"${accessoriesToBeScan && accessoriesToBeScan.accessory_name}"`}</h1>
                <div style={{ paddingTop: 10 }}>
                    {accessoriesModal &&
                        <Input placeholder="Scan Barcode" id='calvin-sobrang-galing' ref={accesoryInput => { setInputState(accesoryInput) }}
                            onKeyDown={(event) => scanAccessoriesFunction(event)}
                        />
                    }
                    <Row type="flex" align="middle" justify="center" style={{ paddingTop: 10 }} >
                        <Col span={5}>
                            <Button type="danger" style={{ width: "100%" }}
                                onClick={() => Modal.confirm({
                                    'title': 'Warning',
                                    'content': `All barcodes that are scanned will be lost would you like to continue?`,
                                    onOk: () => {
                                        refreshDispatch("Cancel dispatch successful!")
                                    },
                                })}
                            >Cancel Dispatch
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    )
}

export default AccesoryModal
