import React from 'react';
import { Header, Title } from 'components/primaries';
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'
const SummaryDashboard = () => {
  return (
    <>
      <Header>
        <Title>App Setup {">"} Summary Dashboard </Title>
      </Header>
      <Panel title="SUMMARY DASHBOARDS">
      <DataListPage model="summary_dashboards" select={['id', 'name', 'settings', 'daily_target' ,'created_at', 'updated_at']} link={"app/data-view/summary_dashboards/"} pageSize={50}
          addListButton={{
            buttonLabel: "Add Summary Dashboard",
            actionApi: "/api/v1/data-api/summary_dashboards",
            offset: 15,
            span: 3
          }}
        />
      </Panel>
    </>
  )
}
export default SummaryDashboard