import axios from 'axios';

const SaveReport = async (postData = {
  name: '',
  tight_table: '',
  sortation_field: '',
  sortation_field_type: '',
  hide_details: '',
  hide_drop_zones: '',
  description: '',
  table: '',
  primary_filter: [],
  secondary_filter: [],
  bucket_fields: [],

  fields: [],
  groups: [],
  visualization: '',
  chartConfiguration: {},
  group_matrix_x: [],
  group_matrix_y: [],
  grouped_date: [],
  primary_numerator_filters: [],
  secondary_numerator_filters: []
}) => {
  let res = await axios.post(`${process.env.REACT_APP_ANALYTICS_SERVER}/report`, postData)
  let { data } = res;
  return data;
}

export default SaveReport