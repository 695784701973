import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Alert,
    Button,
    Form,
    Input,
    Modal, notification,
    Row,
    Select,
} from 'antd';
import { Label } from '../../../../components/zensmart-design-system';
import ModalStyle from './ModalStyle';
import Packages from './Components/Packages';
import useApi from '../../../../hooks/useApi';
import Cookies from 'js-cookie';
import PrintThisComponent from 'components/composites/printTicket';
import * as _ from 'lodash';

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 2px;
`;

const Option = Select.Option;

const CloseShipmentForm = (props) => {

    const { form } = props;
    const createDropshipApi = useApi();
    const { loading, errors } = createDropshipApi
    // const {setPrintDropshipment, setShowPrintDropshipment} = props;

    const [showPrintDropshipment, setShowPrintDropshipment] = useState(false)
    const [printDropshipment, setPrintDropshipment] = useState(null)

    const { getFieldDecorator } = form;

    const handleModalClose = (submitted = false) => {
        form.resetFields();

        if (submitted) {
            props.onSuccess();
        } else {
            props.onClose();
        }
    };

    const handleSubmit = () => {
        form.validateFields((err, values) => {


            Modal.confirm({
                'content': "Are you sure you want to close this dropshipment?",
                onOk: () => {

                createDropshipApi.request('post', '/api/v1/dropship', {
                    logistics_provider_id: values.logistics_provider_id,
                    station_id: Cookies.get('STATION') ? parseInt(Cookies.get('STATION')) : null,
                    items: props.items.map(item => {
                        return item.barcode;
                    }),
                    packages: values.packages,
                }).then(response => {
                    let data = response.data.data;

                    if (data.label.use_osi) {
                        // window.location = data.label.osi_path;
                        // window.location.href = data.label.osi_path;
                        setTimeout(() => { window.location.assign(data.label.osi_path) }, 1000);
                    }


                    if (data && data.label.type === 'HTML') {
                        setPrintDropshipment({
                            label: {
                                content: data.label.content,
                                type: 'html'
                            }
                        })
                        setShowPrintDropshipment(true);
                        setTimeout(() => {
                            setShowPrintDropshipment(false);
                        })
                    }

                    if (_.get(data, 'invoice.osi_path')) {
                        setTimeout(() => {
                            window.location.assign(data.invoice.osi_path);
                        }, 1500);
                    }

                    // focusInput()
                    notification.success({
                        message: 'Dropship created successfully!',
                    });
                    handleModalClose(true);
                })


                },
            });

        });
    };

    const renderFormItem = (field, initialValue, input = null) => {

        let component = <Input />;
        if (input) {
            component = input;
        }

        return (
            <StyledFormItem validateStatus={errors[field] ? 'error' : null}
                help={errors[field] && errors[field][0]}>
                {getFieldDecorator(field,
                    { initialValue })(
                        component,
                    )}
            </StyledFormItem>
        );
    };
    return (
        <>
            <ModalStyle />

            {showPrintDropshipment ? <><PrintThisComponent url={printDropshipment.label.content} type={printDropshipment.label.type} /></> : null}


            <Modal
                visible={props.open}
                destroyOnClose={true}
                width={900}
                onCancel={() => handleModalClose()}
                footer={null}
            >
                <Row type="flex" justify="center" style={{ padding: '10 20' }}>
                    <Label status="blue">SET PROVIDER AND PACKAGES</Label>
                </Row>
                <Form>

                    {/* Provider */}
                    <div className="flex mt-4">
                        <div className="w-2/5">
                            {renderFormItem(
                                `logistics_provider_id`,
                                props.defaultLogisticsProvider !== null ? props.defaultLogisticsProvider.id : '0',
                                <Select>
                                    <Option value="0">Select provider</Option>
                                    {props.logisticsProviders.map(
                                        logisticsProvider => (
                                            <Option
                                                key={logisticsProvider.id}
                                                value={logisticsProvider.id}>{logisticsProvider.name}</Option>
                                        ))}
                                </Select>,
                            )}

                        </div>
                        <div className="ml-4">
                            <Button loading={loading} onClick={handleSubmit}
                                type="primary">Submit</Button>
                        </div>
                    </div>

                    <div className="mt-4">
                        <Packages form={form} errors={errors}
                            renderFormItem={renderFormItem} />
                    </div>
                </Form>
                {errors.items &&
                    <div className="mt-4">
                        <Alert
                            message="There are errors in items scanned"
                            description="Please go back to the scanned items and review the list."
                            type="error"
                            closable
                        />
                    </div>
                }
            </Modal>
        </>
    );
};

const CloseShipmentFormWithForm = Form.create()(CloseShipmentForm);

export default CloseShipmentFormWithForm;