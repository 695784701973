export const selectStation = station => {
  return {
      type: 'SELECT_STATION',
      payload: {
        station: station
      }
  }
}

export const getTasks = tasks => {
  return {
    type: 'GET_TASKS',
    payload: {
      tasks: tasks
    }
  }
}

export const setOverviewDates = (start_date, end_date) => {
  return {
    type: 'SET_OVERVIEW_DATES',
    payload: {
      start_date: start_date,
      end_date: end_date
    }
  }
}

export const getTasksByDeviceTypeAndClass = (tasks, device_type, task_class) => {
  return {
    type: 'GET_TASKS_BY_DEVICE_AND_CLASS',
    payload: {
      tasks: tasks,
      device_type: device_type,
      class: task_class
    }
  }
}

export const getFaultsByStatus = (faults, status) => {
  return {
    type: 'GET_FAULTS_BY_STATUS',
    payload: {
      faults: faults,
      status: status
    }
  }
}

export const setTaskClass = task_class => {
  return {
    type: 'SET_TASK_CLASS',
    payload: {
      class: task_class
    }
  }
}