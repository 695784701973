import React, {useContext} from 'react';
import styled from 'styled-components';
import {Droppable} from 'react-beautiful-dnd';
import _ from 'lodash'

import {color} from 'components/zensmart-design-system/shared/styles.js';

import DispatchLibrary from './DispatchLibrary.js';
import DispatchStages from './DispatchStages.js';
import {DesignerContext} from '../../DispatchDesignerContext';

const Heading = styled.h1`
  margin: 0;
  color: ${color.body};

  font-size: 13px;
  font-weight: bold;
`;

const Header = styled.header`
  padding: 8px 20px;
  background-color: #F9F9FB; /* CUSTOM COLOR. NOT IN STYLE GUIDE. */

  border-top: 1px solid ${color.nav.unselected};
  border-bottom: 1px solid ${color.nav.unselected};
`;

const Row = styled.div`
  display: flex;
  background-color: #EFF1F5; /* CUSTOM COLOR. NOT IN STYLE GUIDE. */
  border-bottom: 1px solid ${color.nav.unselected};
`;

const Column = styled.div`
  padding: 8px 20px;

  flex: 1 0 auto;
  flex-basis: 50%;
  max-width: 50%;

  &:not(:last-of-type) {
    border-right: 1px solid ${color.nav.unselected};
  }

  & > h1 {
    margin-bottom: 16px;
  }
`;

const LibraryContainer = styled.div`
  display: flex;
  align-items: flex-start;

  overflow: auto;
  height: 96px;
`;

function DesignerArea(props) {
  const {stages, template} = useContext(DesignerContext);

  if (!stages) {
    return null;
  }

  return (
      <Droppable droppableId="NO_OP_DROP_ZONE" isDropDisabled>
        {provided => (
            <section
                {...provided.droppableProps}
                ref={provided.innerRef}
            >
              <Header>
                <Heading>EDIT MODE</Heading>
              </Header>

              <Row>
                <Column>
                  <Heading>DISPATCH LIBRARY</Heading>
                  <LibraryContainer>
                    <DispatchLibrary/>
                  </LibraryContainer>
                </Column>
              </Row>

              {template &&
              <div style={{display: 'flex', justifyContent: 'flex-end', padding: '1px 5px 0 0'}}>
                Template&nbsp;<strong>{template.name}</strong>, last updated {template.last_updated} {template.last_updated_by && `by ${template.last_updated_by}`}
              </div>
              }

              <DispatchStages {...props} />

              {provided.placeholder}
            </section>
        )}
      </Droppable>
  );
}

export default DesignerArea;