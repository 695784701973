import styled from 'styled-components';
import {
    Button,
    DatePicker
} from 'antd';

export const DatePickerContainer = styled.div`
    padding: 15px;
    display: block
    width: max-content;
`

export const DatePickerInput = styled(DatePicker)`
    width: 300px;
`;

export const SubmitButton = styled(Button)`
    margin-left: 15px;
    font-size: 15px;
    font-family: 'Rubik',sans-serif;
    font-weight: bold
`;
