import React, {useState, useCallback} from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useMenu } from 'context/menuContext'
import {Sidebar} from 'components/sections'
import TopBar from 'components/composites/TopBar.js'
import {SearchBar} from 'components/composites/SearchBar.js'
import {background} from 'components/zensmart-design-system/shared/styles.js'
import {Icon} from 'components/zensmart-design-system'

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${background.default};
`

const Anchor = styled.a`
  color: #fff;
`

const TopBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const getWidth = props => props.isMenuOpen ? 'calc(100vw - 230px)' : '100%'
const MainContainer = styled.div`
  flex: 1 1 auto;
  transition-duration: 0.3s;
  overflow-x: hidden;
  background-color: ${background.default};
`
const OrderHold = styled.div`
  color : white;
  position: absolute; 
  right: 8%;
  bottom: 0%;
`

const Main = styled.main`
  padding: 26px;
  width: inherit;
`

function Dashboard(props) {
  const {children, user, logout, helpLink, ...dashboard} = props
  const location = useLocation()
  const [isMenuOpen, setMenuOpen] = useMenu()

  const toggleMenu = useCallback(() => {
    setMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  const mainContainerStyles = () => {
    if (location.pathname === '/app/people/permissions') {
      return { flexBasis: `${getWidth}`, maxWidth: `${getWidth}`}
    } else {
      return { overflowX: "visible"}
    }
  }

  return (
    <Container {...dashboard} className="parent-container">
      <Sidebar user={user} logout={logout} isMenuOpen={isMenuOpen} />
      <MainContainer isMenuOpen={isMenuOpen} className='main-content' style={mainContainerStyles()}>
        <TopBar isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} className="parent-header">
          <TopBarContainer>
            <SearchBar disabled={!user.authenticated} />
            {
              user?.details?.order_on_hold !== undefined && user?.details?.order_on_hold !== null ? (
                <a href={'/app/process/on-hold-report/'}>
                  <OrderHold>
                    <ul>
                        <li>
                          Manual Check: <span style={{ color: user.details.order_on_hold.order_hold_counts > 50 ? "red" : "white", fontSize: '30px', fontWeight: 'bold' }}>{user.details.order_on_hold.order_hold_counts}</span>
                        </li>
                        <li style={{fontSize: '13px' , marginTop: '-5%' , marginBottom: '3%' }}>
                          Locked: <span style={{ color: user.details.order_on_hold.order_locked_counts > 50 ? "red" : "white", fontWeight: 'bold' }}>{user.details.order_on_hold.order_locked_counts}</span>
                        </li>
                      </ul>
                  </OrderHold>
                </a>
              ) : null
            }
            <Anchor
                href={helpLink}
                target="_blank"
                rel="noreferrer noopener"
            >
              <Icon height="32" width="32px" icon="question" />
            </Anchor>
          </TopBarContainer>
        </TopBar>
        <Main>{children}</Main>
      </MainContainer>
    </Container>
  )
}

export default Dashboard
