import styled from 'styled-components';
import {
    Alert,
    Button,
    DatePicker
} from 'antd';

export const DatePickerContainer = styled.div`
    padding: 15px;
    display: block
    width: max-content;
`

export const DatePickerLabel = styled.h1`
    font-size: 15px;
    font-family: 'Rubik',sans-serif;
    font-weight: bold;
    line-height: 32px;
    display: inline;
    width: max-content;
    margin: 0 15px;
    
    :first-child {
        margin-left: 0;
    }
`;

export const DatePickerInput = styled(DatePicker)`
    width: 300px;
`;

export const AlertPanel = styled(Alert)`
    margin: 0 0 16px 0;
`;

export const SubmitButton = styled(Button)`
    margin-left: 15px;
    font-size: 15px;
    font-family: 'Rubik',sans-serif;
    font-weight: bold
`;
