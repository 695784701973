import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Pagination, Row } from 'antd'

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-tbody {
    cursor : pointer;
}

`
const TableData = (props) => {

    const { loading, data, columns, link, tableProperties, filterSearchValue, setCurrentPage, getSortFunction, loadingPage = false } = props

    const openDataView = (e, record) => {
        e.preventDefault();
        if (e.ctrlKey || e.type === 'contextmenu') {
            window.open(`/${link}${record.id}`)
        }
        else {
            window.location.href = `/${link}${record.id}`;
        }
    }

    const fetchPagination = (page) => {
        setCurrentPage(page)
        getSortFunction(page, filterSearchValue)
    }

    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading || loadingPage}
                    columns={columns}
                    dataSource={data}
                    bordered
                    onRow={(record) => {
                        return {
                            onDoubleClick: event => link ? openDataView(event, record) : undefined, // click row
                            onContextMenu: event => link ? openDataView(event, record) : undefined, // click row
                        };
                    }}
                    size="middle"
                    scroll={{ y: "50vh" }}
                    className={link.includes('performance/reports') ? 'saved-report': null}
                    pagination={false}

                />
                {tableProperties &&
                    <Row type="flex" align="middle" justify="end" style={{ padding: 10 }}>
                        <Pagination defaultCurrent={1} total={tableProperties.total} pageSize={tableProperties.per_page} onChange={fetchPagination} />
                    </Row>
                }
            </StyleTable>
        </>
    )
}

export default TableData