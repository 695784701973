import React, {useState} from 'react';
import _ from 'lodash';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon as AntIcon,
  Modal,
  Row,
  Select,
  Table,
  TimePicker,
  Alert,
  notification
} from 'antd';
import moment from 'moment';
import {generateKey} from '../../utils';
import styled from 'styled-components';
import {color} from 'components/zensmart-design-system/shared/styles.js';
import QualifierInput from '../../../../components/composites/QualifierInput';
import AutoCompleteBar from 'components/composites/autoCompleteBar';

const { Column } = Table;

const DeleteButton = styled.button`
  font-size: 12px;
  color: ${color.red.dark};
  font-weight: bold;
`
const ModalTitle = styled.h3`
  font-weight: bold;
`

const DayPicker = styled.div`
  display: flex;

  .ant-select-selection--single  {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 115px;
  }

  .ant-time-picker {
    width: 90px;
  }

  .ant-time-picker-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const ModalSubTitle = styled.p`
  font-weight: 300;
  margin: 10px 0;
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0243EB;
  font-size: 12px;
  border: 0;
  margin-top: 10px;
  box-shadow: none;
`

const Icon = styled(AntIcon)`
  font-size: 16px;
`

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-label {
    display: none
  }
`;

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const QueueRulesModal = ({ visible, edit, onCancel, onSave, processID, queueRules, setQueueRules, stage, qualifiers, qualifiersToRemove, setQualifiersToRemove, originalQueueRules, setOriginalQueueRules, forceSeparateQueues, updateForceSeparateQueues }) => {

  const [errors] = useState({});
  const [qualifyerinput, setQualifyerInput] = useState(true)
  const onRemove = (item) => {
    const toRemove = [...qualifiersToRemove]
    toRemove.push(item)
    const newData = [...queueRules];
    newData.splice(newData.indexOf(item), 1);

    setQueueRules(newData)
    setQualifiersToRemove(toRemove)
  }

  const onAddNewRule = () => {
    const newRule = {
      key: generateKey(),
      qualifierField: {},
      qualifier: '',
      ...(stage && {fields: []}),
      active: true,
      ...(stage && {stage})
    };

    const newData = [
      ...queueRules,
      newRule
    ];

    const newOriginalData = [
      ...originalQueueRules,
      newRule
    ];
    setQueueRules(newData)
    setOriginalQueueRules(newOriginalData)
  }

  const constructModalHeader = () => (
    <>
      <ModalTitle>{stage ? 'Qualifiers' : 'Queue Rules'}</ModalTitle>
      <ModalSubTitle>{`Use the tools below to set your ${stage ? 'qualifiers' : 'queue rules'}.`}</ModalSubTitle>
      {!edit && <Alert message="You must enter qualifiers for this derivation branch." type="error" />}
    </>
  );

  const onChangeDayPicker = (value, item, isFrom) => {
    const selectedRow = queueRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = [...queueRules];

    if (isFrom) {
      const scheduleFrom = newData[selectedRow].scheduleFrom ? newData[selectedRow].scheduleFrom.split(" ") : null;
      newData[selectedRow].scheduleFrom = `${value} ${scheduleFrom ? scheduleFrom[1] : "00:00"}`;
      setQueueRules(newData);
    } else {
      const scheduleTo = newData[selectedRow].scheduleTo ? newData[selectedRow].scheduleTo.split(" ") : null;
      newData[selectedRow].scheduleTo = `${value} ${scheduleTo ? scheduleTo[1] : "00:00"}`;
      setQueueRules(newData);
    }

  }

  const onChangeTimePicker = (value, item, isFrom) => {
    const selectedRow = queueRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = [...queueRules];

    if (isFrom) {
      const scheduleFrom = newData[selectedRow].scheduleFrom ? newData[selectedRow].scheduleFrom.split(" ") : null;
      newData[selectedRow].scheduleFrom = `${scheduleFrom ? scheduleFrom[0] : ''} ${moment(value).format('HH:mm')}`;
      setQueueRules(newData);
    } else {
      const scheduleTo = newData[selectedRow].scheduleTo ? newData[selectedRow].scheduleTo.split(" ") : null;
      newData[selectedRow].scheduleTo = `${scheduleTo ? scheduleTo[0] : ''} ${moment(value).format('HH:mm')}`;
      setQueueRules(newData);
    }
  };

  const onChangeTimeToNull = (value, item) => {
    const selectedRow = queueRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = queueRules.map((item, key) => {
      return (
        key === selectedRow
          ? {
            ...item, scheduleFrom: value.target.checked ? null : originalQueueRules[selectedRow].scheduleFrom ?
              originalQueueRules[selectedRow].scheduleFrom : `${days[new Date().getDay()]} 00:00`,
            scheduleTo: value.target.checked ? null : originalQueueRules[selectedRow].scheduleTo ? originalQueueRules[selectedRow].scheduleTo : `${days[new Date().getDay()]} 00:00`
          }
          : item
      )
    })
    setQueueRules(newData);
  };

  const setQualifierValue = (value, item) => {
    const selectedRow = queueRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = [...queueRules];
    newData[selectedRow].qualifier = value;
    setQualifyerInput(false)
    setQueueRules(newData);
  };

  const setQualifierField = (modelDefinition, item) => {
    const selectedRow = queueRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = [...queueRules];

    if (!modelDefinition) {
      newData[selectedRow].qualifierField = {};
    } else {
      newData[selectedRow].qualifierField = modelDefinition;
    }

    setQueueRules(newData);
  }

  const handleAutoCompleteSearch = (ids, values, item) => {
    const fields = values.map(val => val.path);
    const selectedRow = queueRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = [...queueRules];
    newData[selectedRow].fields = fields;

    newData[selectedRow].fieldsTags = values;
    setQueueRules(newData);
  }

  const onQueueSave = (e,queueRules, processID) => {
    if (stage) {
      onSave(queueRules, processID, qualifiersToRemove, true);
    } else if (stage === 0) {
      const emptyQualifier = queueRules.filter(item => item.fields.length === 0)
      if(emptyQualifier.length !== 0){
        notification.error({ message: "Please select field!" })
        return false;
      }
      onSave(queueRules, processID, qualifiersToRemove);
    }
    onCancel(e, true)
  }

  return (
    <Modal
      width={1400}
      title={constructModalHeader()}
      visible={visible}
      onOk={(e) => onQueueSave(e,queueRules, processID)}
      onCancel={(e) => onCancel(e, true)}
      maskClosable={edit}
      okButtonProps={{ disabled: stage == 0 ? false : (queueRules?.length && !qualifyerinput ? false : true) }}
      cancelButtonProps={{ disabled: edit ? false : true }}
    >
      {
        !stage &&
        <div style={{ display: 'flex', marginBottom: 10 }}><h2 style={{ marginRight: 10 }}>Force Separate Queues</h2>
        <Checkbox
          defaultChecked={forceSeparateQueues}
          onChange={e => {
            updateForceSeparateQueues(e.target.checked)
          }}
            />
      </div>
      }

      <Table dataSource={queueRules} pagination={false} bordered={true}>
        {!stage && <Column
          title="Fields"
          dataIndex="fieldsTags"
          key="fieldsTags"
          width={350}
          render={(fieldsTags, item) => {
            return (
              <AutoCompleteBar
                prefix={"lines"}
                ignoredValues={['order.product.code']}
                placeholder="Enter Field (Click Suggestions to add each field)"
                returnValue={(ids, values, tags) => handleAutoCompleteSearch(ids, values, item, tags)}
                value={fieldsTags}
                hideNonDb={false}
              />
            )
          }}
        />}
        
        <Column
          title="Qualifier"
          dataIndex="qualifier"
          key="qualifier"
          render={(qualifier, item) => (
              <>
                <StyledFormItem
                    validateStatus={errors['qualifier'] && 'error'}
                    help={errors['qualifier'] && errors['qualifier'][0]}
                    label="Qualifier"
                >
                  <AutoCompleteBar
                      prefix={"lines"}
                      limit={1}
                      placeholder="Select the field qualifier should be checked against"
                      returnValue={(ids, values, tags) => setQualifierField(values[0], item)}
                      value={_.isEmpty(item.qualifierField) ? null : [item.qualifierField]}
                  />
                </StyledFormItem>
                <StyledFormItem
                    validateStatus={errors['qualifier'] && 'error'}
                    help={errors['qualifier'] && errors['qualifier'][0]}
                    label="Qualifier"
                >
                  <QualifierInput
                      disabled={_.isEmpty(item.qualifierField)}
                      value={qualifier}
                      onChange={value => setQualifierValue(value, item)}
                      types={qualifiers}
                      falseWhiteSpace
                  />
                </StyledFormItem>
              </>
          )}
        />
        {!stage && <Column
          title="Schedule From"
          dataIndex="scheduleFrom"
          key="scheduleFrom"
          render={(scheduleFrom, item) => {
            const dayValue = scheduleFrom ? scheduleFrom.split(" ")[0] : '';
            const timeValue = scheduleFrom ? scheduleFrom.split(" ")[1] : '00:00';
            return (
              <DayPicker>
                <Select value={dayValue} placeholder="Day" disabled={!dayValue} onChange={(e) => onChangeDayPicker(e, item, 'isFrom')}>
                  {days.map(day => {
                    return <Select.Option key={day} value={day}>{day}</Select.Option>
                  })}
                </Select>
                <TimePicker
                  onChange={(e) => onChangeTimePicker(e, item, 'isFrom')}
                  defaultOpenValue={moment('00:00', 'HH:mm')}
                  format={'HH:mm'}
                  placeholder="Time"
                  value={moment(timeValue, 'HH:mm')}
                  allowClear={false}
                  disabled={!dayValue}
                />
              </DayPicker>
            )
          }}
        />}

        {!stage && <Column
          title="Schedule To"
          dataIndex="scheduleTo"
          key="scheduleTo"
          render={(scheduleTo, item) => {
            const dayValue = scheduleTo ? scheduleTo.split(" ")[0] : '';
            const timeValue = scheduleTo ? scheduleTo.split(" ")[1] : '00:00';
            return (
              <div>
                <Row type="flex" align="middle" justify="center">
                  <Col span={16}>
                    <DayPicker>
                      <Select value={dayValue} placeholder="Day" disabled={!dayValue} onChange={(e) => onChangeDayPicker(e, item)}>
                        {days.map(day => {
                          return <Select.Option key={day} value={day}>{day}</Select.Option>
                        })}
                      </Select>
                      <TimePicker
                        onChange={(e) => onChangeTimePicker(e, item)}
                        defaultOpenValue={moment('00:00', 'HH:mm')}
                        format={'HH:mm'}
                        placeholder="Time"
                        value={moment(timeValue, 'HH:mm')}
                        allowClear={false}
                        disabled={!dayValue}
                      />
                    </DayPicker>
                  </Col>
                  <Col span={8} style={{ textAlign: "center" }}>
                    <Checkbox onChange={(e) => onChangeTimeToNull(e, item)} defaultChecked={!item.scheduleFrom || !item.scheduleTo}>No Schedule</Checkbox>
                  </Col>
                </Row>
              </div>
            )
          }}
        />}

        <Column
            title="Inactive"
            render={record => {
              return <Row type="flex" align="middle" justify="center"><Checkbox
                  defaultChecked={record.inactive}
                  onChange={e => {
                      setQueueRules(queueRules.map(queueRule => {
                        if(record.id === queueRule.id) {
                          queueRule.inactive = e.target.checked
                        }
                        return queueRule
                      }))
                  }}
              />
              </Row>

            }}
        >
        </Column>
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <DeleteButton onClick={() => onRemove(record)}>REMOVE</DeleteButton>
          )}
        />
      </Table>
      <AddButton onClick={onAddNewRule}>
        <Icon type="plus-circle" theme="filled" />
            {stage ? 'ADD NEW QUALIFIER' : 'ADD NEW RULE'}
          </AddButton>
    </Modal>
  )
};

export default QueueRulesModal;