import React, {useEffect, useState} from 'react';
import { Modal, notification, Select } from 'antd';
import { InputSection } from '../composites';
import { GetLabel } from 'pages/Performance/utils';

const {Option} = Select

const MatrixOptionsModal = ({
  summaryOptions = [],
  numberFields  = [],
  configuration = {},
  setConfiguration,
  setChartType,
  visible,
  cancelDisplay
}) => {
  const [localConfig, setLocalConfig] = useState({
    option: '',
    field: ''
  })

  useEffect(() => {
    setLocalConfig(configuration)
  }, [configuration])
  
  return (
    <Modal
      title='Matrix Editor'
      visible={visible}
      onOk={()=>{
        if (localConfig.option === 'record count' && localConfig.field === '') {
          setConfiguration(localConfig)
          setChartType && setChartType(localConfig.option)
          cancelDisplay()
        } else if (localConfig.option !== 'record count' && localConfig.field !== '') {
          setConfiguration(localConfig)
          setChartType && setChartType(localConfig.option)
          cancelDisplay()
        } else {
          notification.error({
            message: 'Please input the proper values'
          })
        }
        
      }}
      onCancel={()=>{
        cancelDisplay()
      }}
    >
      <div style={{
        width: '100%'
      }}>
        <InputSection
          label = 'Matrix Summary'
          style={{
            marginBottom: 20
          }}
        >
          <Select
            value={localConfig.option ? localConfig.option : (
              (configuration && configuration.option) ? configuration.option : ''
            )}
            onSelect={val => setLocalConfig(localConfig => {
              localConfig.option = val
              if (val === 'record count') {
                localConfig.field = ''
              }
              return {...localConfig}
            })}
            style={{width:'100%'}}
          >
            {summaryOptions.map(option => (
              <Option value={option}>{option}</Option>
            ))}
          </Select>
        </InputSection>
        <InputSection
          label = 'Summary Field'
          style={{
            marginBottom: 20
          }}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={true}
            disabled={localConfig.option === 'record count'}
            value={localConfig.field ? localConfig.field : (
              (configuration && configuration.field) ? configuration.field : ''
            )}
            onSelect={val => setLocalConfig(localConfig => {
              localConfig.field = val
              return {...localConfig}
            })}
            style={{width:'100%'}}
          >
            {Object.values(numberFields).map((numberField, index) => (
              <Option key={index} value={`${numberField.table}.${numberField.field}`}>{numberField.table} {GetLabel(`${numberField.table}.${numberField.field}`)}</Option>
            ))}
          </Select>
        </InputSection>
      </div>
    </Modal>
  )
}

export default MatrixOptionsModal