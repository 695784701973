const isNumber = /^\d+$/

function getProcessPath(id) {
  const arr = id.split(',')
  return {
    processPath: [...arr[1].split('.').map(path => isNumber.test(path) ? parseInt(path) : path)],
    childrenPath: [...arr[2] ? arr[2].split(".").map(path => isNumber.test(path) ? parseInt(path): path) : []],
    processID: `process,${arr[1]}`
  }
}

export default getProcessPath