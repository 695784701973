import React, { useEffect, useState } from 'react';
import { Header, Title, Panel } from 'components/primaries';
import * as S from '../components/Table/HourlyReportStyled';
import DatePicker from '../components/DatePicker/DatePicker';
import moment from 'moment';
import { formatDate } from 'utils/dateFormatter';
import { ZenSmartAPI } from 'utils';
import { handleActiveRows, buildData, getUrl } from '../components/Table/TableUtilities';
import AssociateProgressReportTable from './AssociateProgressReportTable';

const AssociateProgressReport = () => {
    const dateFormat = 'YYYY-MM-DD';
    const dateValue = formatDate(dateFormat, moment());
    const [date, setDate] = useState(formatDate(dateFormat, dateValue));

    const getByAssociateApiRoute = '/api/v1/linear-feet/per-hour/by-associate';
    const [byAssociateLoading, setByAssociateLoading] = useState(true);
    const [byAssociateData, setByAssociateData] = useState([]);
    const [activeByAssociateRows, setActiveByAssociateRows] = useState([]);
    const uniqueKeys = [
        'user_id',
        'department_id'
    ];

    const getByAssociateData = (parameters) =>  ZenSmartAPI.get(getUrl(getByAssociateApiRoute, parameters))
                                                           .then(({config, data}) => setByAssociateData(buildData('user_id', config.url, uniqueKeys, byAssociateData, data)))
                                                           .catch(() => setByAssociateData([]))
                                                           .finally(() => setByAssociateLoading(false));

    const handleByAssociate = (id) => {
        if (id !== null) {
            const toQuery = !activeByAssociateRows.includes(id);
            setActiveByAssociateRows(handleActiveRows(id, activeByAssociateRows));
            setTimeout(() => {
                setByAssociateLoading(true);
                (toQuery) ? getByAssociateData({
                    date,
                    user_id : id
                }) : setByAssociateLoading(false);
            }, 100);
        }
    }

    //Initial loading
    useEffect(() => {
        setByAssociateData([]);
    }, []);

    //When dates update
    useEffect(() => {
        setByAssociateData([]);
        setByAssociateLoading(true);
        getByAssociateData({date});
    }, [date]);

    return (
        <>
            <Header>
                <Title>Performance {'>'} Associate Progress Report</Title>
            </Header>
            <S.Layout>
                <Panel>
                    <DatePicker 
                        date={(date) => setDate(date)}
                        defaultDate={date}
                        format={dateFormat}
                    />
                    <AssociateProgressReportTable
                        loading={byAssociateLoading}
                        data={byAssociateData}
                        activeRows={activeByAssociateRows}
                        handleRow={(id) => handleByAssociate(id)}
                    />
                </Panel>
            </S.Layout>
        </>
    );
}

export default AssociateProgressReport;
