import React, { useState } from 'react'
import { Table } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'
import _ from 'lodash';

const StyleTable = styled.div`font-family: 'Rubik', sans-serif;`

const TableStyling = createGlobalStyle`
#toprint-table thead,
#toprint-table tbody {
    font-size: 12px;
}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

.ant-table-tbody {
    cursor : pointer;
}

tr.failed-batch th, tr.failed-batch td {
    background: #fb4b4b !important;
    font-color : white;
}

tr.selected-left th, tr.selected-left td {
    background: rgba(11, 107, 242, 0.5) !important;
    font-color : white;
}`



const WorkOrderLeftPanelTable = (props) => {
    const { leftLoading, column, leftTableData, selectedLeftRow, setSelectedLeftRow } = props;
    const [lastLeftShiftSelected, setLastLeftShiftSelected] = useState(null)

    const selectRow = (record, event) => {
        if (event.shiftKey && lastLeftShiftSelected) {
            const lastLeftShiftSelectedIndex = leftTableData.findIndex((data) => data.id === lastLeftShiftSelected.id)
            const recordIndex = leftTableData.findIndex((item) => item.id === record.id)
            const ar2 = lastLeftShiftSelectedIndex < recordIndex ? leftTableData.slice(lastLeftShiftSelectedIndex, recordIndex + 1) : leftTableData.slice(recordIndex, lastLeftShiftSelectedIndex + 1);

            const selectLeft = ar2.filter(filterItem => filterItem.id !== lastLeftShiftSelected.id).map(item => {
                var element = document.getElementsByClassName(`selected-left-${item.id}`)[0];
                element.classList.add("selected-left");
                return item.id;
            })
            if (selectedLeftRow.includes(lastLeftShiftSelected.id)) {
              
                setSelectedLeftRow(_.uniq(selectedLeftRow.concat(selectLeft)), true)
            }
            else {
                setSelectedLeftRow(selectedLeftRow.filter(item => !selectLeft.includes(item)))
            }
            return
        }
        var element = document.getElementsByClassName(`selected-left-${record.id}`)[0];
        if (selectedLeftRow.includes(record.id)) {
            setSelectedLeftRow(selectedLeftRow.filter(data => data !== record.id))
            element.classList.remove("selected-left");
        }
        else {
            setSelectedLeftRow([...selectedLeftRow, record.id])
            element.classList.add("selected-left");
        }
        setLastLeftShiftSelected(record)
    }
    return (
        <>
            <TableStyling />
            <StyleTable>
                <Table
                    size="middle"
                    scroll={{ y: "52vh", x: "700px" }}
                    bordered
                    pagination={false}
                    loading={leftLoading}
                    columns={column}
                    dataSource={leftTableData}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => selectRow(record, event), // click row
                        };
                    }}
                    id={'toprint-table'}
                    rowClassName={(record, index) => `selected-left-${record.id}`}
                />
            </StyleTable>
        </>
    )
}

export default WorkOrderLeftPanelTable
