import React from 'react';

const HeaderRow = ({
  colspan,
  children,
  indent,
  colorTone,
  getProps,
  id
}) => {
  return (
    <tr>
      <td style={{
        backgroundColor: `rgba(192,212,241,0.${colorTone})`,
        fontWeight: '600',
        paddingLeft: (10 + (20 * indent))
      }}
      colSpan={colspan}
      {...getProps(id)}
      >
        {children}
      </td>
    </tr>
  )
}

export default HeaderRow