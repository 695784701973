import React from 'react';
import { styles, Button } from '../../../../components/zensmart-design-system';
import styled from 'styled-components';

const color = styles.color;

const TextGroupStyle = styled.div`
  display: flex;
  padding: 2px;

  .item_name {
    font-weight: 600;
    width: 12em;
    color: ${props => props.active ? color.blue.normal : 'black'};

    p {
      margin: 10px;
      margin-bottom: 0;
      font-size: 12px;
    }
  }

  .item_name:hover {
    cursor: pointer;
    text-decoration: underline ${props => props.active ? color.blue.normal : 'black'};
  }

  ${props => props.active && `
    .item_name {
      background: ${color.blue.light};
    }
  `}

  
`

const TextBucketGroupItem = ({
  index,
  name,
  active = false,
  deleteItem,
  ...props
}) => {
  return (
    <TextGroupStyle active={active} {...props}>
      <div className='item_name'>
        <p>{name}</p>
      </div>
      <Button
        color='red'
        size='small'
        onClick={()=>deleteItem(index)}
      >
        Delete
      </Button>
    </TextGroupStyle>
  )
}

export default TextBucketGroupItem