import React from 'react';
import { Header, Title } from 'components/primaries';
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'


const SavedReports = () => {

  return (
    <>
      <Header>
        <Title>Performance {">"} Saved Reports</Title>
      </Header>
      <Panel title="SAVED REPORTS">
        <DataListPage sort={"name"} model="an_reports" select={['id', 'name', 'table', 'created_at', 'updated_at']} queries={[{ "column": "deleted_at", "operator": "equals", "value": null }]} link={"app/performance/reports/"} pageSize={50}
        />
      </Panel>
    </>
  )
}

export default SavedReports