import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Select, notification, Spin } from 'antd';
import { color } from 'components/zensmart-design-system/shared/styles.js';
import { Panel } from 'components/primaries';
import moment from 'moment';
import { ZenSmartAPI } from 'utils';
import { getHealthCheckListRoute, getHealthCheckResultRoute } from 'utils/apiRoutes';
import axios from 'axios';

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`

const Container = styled.div`
  padding: 20px;
`

const Label = styled.label`
  display: block;
  color: ${color.heading};
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
`

const StyledSelect = styled(Select)`
  width: 200px;
`

const DashboardRow = styled(Row)`
  margin-top: 30px;
  border: 1px solid #ccc;
`

const DashboardHeader = styled.div`
  color: #fff;
  background: ${color.blue.dark};
  font-weight: 900;
  font-size: 24px;
  padding: 0.25em 0.5em;
`

const DashboardDate = styled.div`
  padding: 0.25em 0.5em;
`

const ItemHeader = styled.div`
  width: 100%;
  color: #000;
  font-weight: 600;
  background: ${color.blue.light};
  padding: 0.25em;
`

const ItemCol = styled(Col)`
  height: 150px;
  text-align: center;
  width : 14.285%;
`

const ItemCount = styled.h1`
  font-weight: 600;
  font-size: 36px;
  height: 100px;
  line-height: 100px;
`

const Loading = styled(Spin)`
  position: relative;
  text-align: center;
  width: 100%;
  height: 200px;
  top: 100px;
`

const HealthPanelPage = () => {
  const [healthCheckList, setHealthCheckList] = useState([]);
  const [healthCheckResult, setHealthCheckResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requestCancel, setRequestCancel] = useState({ cancel: undefined })

  useEffect(() => {
    getHealthCheckList();
    if (Object.keys(healthCheckResult).length === 0)
      getHealthCheckResult('all');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getHealthCheckList = () => {
    ZenSmartAPI.get(getHealthCheckListRoute)
      .then(res => {
        setHealthCheckList(res.data.data);
      })
      .catch(err => {
        notification.error({
          message: 'Fetching health check list failed.'
        })
      })
  }

  const getHealthCheckResult = id => {
    const CancelToken = axios.CancelToken;

    if (requestCancel.cancel !== undefined) {
      requestCancel.cancel("cancel request");
    }
    setLoading(true);
    ZenSmartAPI.get(getHealthCheckResultRoute(id), {
      cancelToken: new CancelToken(function executor(c) {
        setRequestCancel({ ...requestCancel, cancel: c })
      }),
    })
      .then(res => {
        setTimeout(() => {
          setHealthCheckResult(res.data.data);
          setLoading(false);
        }, 1200);
      })
      .catch(error => {
        if (error.message && error.message !== "Cannot read property 'status' of undefined") {
          notification.error({
            message: 'Fetch health check result failed.'
          })
          setLoading(false);
        }

      })
  }

  return (
    <div>
      <Header>
        <Title>Performance {">"} Healthcheck Panel</Title>
      </Header>
      <Panel title="HEALTHCHECK PANEL">
        <Container>
          <Row type="flex" align="middle">
            <Col>
              <Label>View: </Label>
              <StyledSelect
                defaultValue={'all'}
                onChange={id => getHealthCheckResult(id)}
              >
                <Select.Option value={'all'}>All of Production</Select.Option>
                {healthCheckList.map(item =>
                  <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>
                )}
              </StyledSelect>
            </Col>
          </Row>
          {!loading ?
            <DashboardRow justify="center" align="middle">
              <Col sm={24}>
                <DashboardHeader>Plant Dashboard</DashboardHeader>
                <DashboardDate>{moment().format('dddd, DD MMM, YYYY hh:mm a')}</DashboardDate>
              </Col>
              {healthCheckResult.map((key, i) =>
                <ItemCol key={i} sm={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                  <ItemHeader>{key.label}</ItemHeader>
                  <ItemCount>{key.value}</ItemCount>
                </ItemCol>
              )}
            </DashboardRow>
            :
            <Loading size="large" />
          }
        </Container>
      </Panel>
    </div>
  )
}

export default HealthPanelPage;