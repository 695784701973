import React from 'react';

const DataTotal = ({
  fontSize,
  fontVariant,
  fontWeight,
  fontStyle,
  children,
  ...props
}) => {
  return (
    <h3 
      style={{
        fontSize,
        fontVariant,
        fontWeight,
        fontStyle
      }}
      {...props}
    >
      {children}
    </h3>
  )
}

export default DataTotal