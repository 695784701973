import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Row, Col, Button } from 'antd'
import { panelSortData } from 'utils/sortData'
import moment from 'moment';
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { failedTransactionInitialData } from "utils/panelsInitialData"

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

`
const TableData = (props) => {
    // i left the other props variable for later pagination 
    const { itemData, loading, data, setData, ascend, setAscend, retryData } = props

    const sortingData = (data, property, ifNotProperty) => {
        if (ascend[property] === null) {
            setAscend({ ...failedTransactionInitialData, [property]: true })
        }
        else {
            ascend[property] === true ? setAscend({ ...failedTransactionInitialData, [property]: false }) : setAscend({ ...failedTransactionInitialData, [property]: true })
        }
        setData(panelSortData(data, property, ascend[property], ifNotProperty))
    }
    const getHeaders = (property, text, ifAscend) => {
        return (
            <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifAscend)} style={{ cursor: "pointer" }}>
                <Col span={ascend[property] === null ? 24 : 21}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }

    const columns = [

        {
            title: getHeaders("client_number", "CLIENT NUMBER", false),
            dataIndex: 'client_number',
            key: 'client_number',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("brand", "BRAND", false),
            dataIndex: 'brand',
            key: 'brand',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("cobrand_id", "CO BRAND", false),
            dataIndex: 'cobrand_id',
            key: 'cobrand_id',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("fail_reasons", "FAIL REASON", false),
            dataIndex: 'fail_reasons',
            key: 'fail_reasons',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("created_at", "FAIL DATE / TIME", false),
            dataIndex: 'created_at',
            key: 'created_at',
            render(value, row, index) {
                const dataValue = moment(value).format("D MMM YYYY, h:mma")
                return rowStyle(value ? dataValue : "-", index, row)
            },
        },
        {
            title: "ACTIONS",
            dataIndex: 'action',
            key: 'action',

            render: (record, row, index) => {
                const value = <p><Button type="danger" onClick={() => retryData(row.client_number)}>Retry</Button></p >
                return rowStyle(value, index, row)
            }
        },
    ];

    const rowStyle = (value, index, row) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }

        return obj
    }
    useEffect(() => {

        if (data.length > 0) {

            const itemObj = itemData.map(data => {
                const obj = {
                    client_number: data.client_number,
                    brand: data.brand,
                    cobrand_id: data.cobrand_id,
                    fail_reasons: data.fail_reasons,
                    created_at: data.created_at,
                }
                return obj;
            })

            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "50vh" }}
                    pagination={{ pageSize: 500 }}

                />
            </StyleTable>
        </>
    )
}

export default TableData