import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { ZenSmartAPI } from 'utils';
import { incrementProductSearchHitsRoute, getProductDropdownRoute } from 'utils/apiRoutes';
import { color } from 'components/zensmart-design-system/shared/styles.js';
// import { alphabeticalData } from 'utils/sortData';

const dropdownStyle = {
    maxHeight: 256,
    overflow: 'auto'
}

const selectStyle = {
    minWidth: '312px', 
    maxWidth: 'max-content', 
    marginRight: '8px'
}

const ProductDropdown = (props) => {

    const {
        productSelected, //method to trigger parent method
        // setSelectedProductId,
        currentProductId,
        loading,
        disabled,
        customStyle,
        dropdownMatchSelectWidth = true
    } = props;

    const [products, setProducts] = useState([])
    const [selectedProductId, setSelectedProductId] = useState(currentProductId) // TODO:: move this to parent component for better control

    const options = products.map(product => product ? (
        <Select.Option value={product.id} key={product.id}>
            {product.code}: {product.name}
        </Select.Option>
    ) : null);


    const onSearchProduct = _.debounce((value, limit = 20) => {
        ZenSmartAPI.get(getProductDropdownRoute(value, limit)).then(result => {
            if(props.setProductsInParent) {
                props.setProductsInParent(result.data.data)
            }
            setProducts(result.data.data)

        })
    }, 500)

    const onSelectProduct = async productID => {

        // call hit api
        setSelectedProductId(productID)
        ZenSmartAPI.put(incrementProductSearchHitsRoute(productID)).catch(error => {
            console.log(error?.response?.data?.message ?? error.message ?? 'Error: increment product hits')
        })

        // call parent method props
        await productSelected(productID)
    }

    useEffect(() => {
        onSearchProduct()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Select
            style={{...selectStyle, ...customStyle }}
            showSearch
            placeholder="Search for product..."
            loading={loading}
            disabled={disabled ?? loading}
            value={selectedProductId || undefined}
            optionFilterProp="children"
            filterOption={true}
            onChange={onSelectProduct}
            onSearch={(e) => onSearchProduct(e)}
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            dropdownStyle={dropdownStyle}
        >
            {options}
        </Select>
    );
}


export default ProductDropdown