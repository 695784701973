import React, {useRef, useState} from 'react';
import {Button, Modal} from 'antd';

export default function DetailFieldModal({title, ...props}) {

  const {children} = props;
  const [showModal, setShowModal] = useState(false);
  const contentRef = useRef(null);
  let modalContainer = null;

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
      <div>
        <Button className={`detail-field-button`} onClick={e => {
          toggleModal();
        }}>View</Button>
        <Modal
            onCancel={e => setShowModal(!showModal)}
            width={700}
            bodyStyle={{overflowX: 'auto', marginRight: '10px'}}
            getContainer={modalContainer}
            title={title}
            footer={null}
            closable={true}
            visible={showModal}
        >
          <div ref={contentRef}>{children}</div>
        </Modal>
      </div>
  );
}