import React, {useEffect, useState} from 'react';
import {Modal, Row} from 'antd';
import {Button, Label} from '../../../components/zensmart-design-system';

export default function PostDispatchPromptModal(props) {

  const {visible, prompts, onOk} = props;
  const [autoCloseTimer, setAutoCloseTimer] = useState(null);
  const [countDown, setCountdown] = useState(5);

  useEffect(() => {
    if(visible) {
      setAutoCloseTimer(setTimeout(() => {
        onOk()
      }, 5000))
    } else {
      clearTimeout(autoCloseTimer)
      setAutoCloseTimer(null)
      setCountdown(5);
    }
    return () => {
      setCountdown(5);
      clearTimeout(autoCloseTimer)
    }
  }, [visible]);

  useEffect(() => {
    if(countDown > 0 && visible === true) {
      setTimeout(() => {
        setCountdown(countDown - 1)
      }, 1000)
    }
  }, [countDown, visible])

  return (
      <Modal
          visible={visible}
          destroyOnClose={true}
          centered
          onCancel={onOk}
          footer={null}
      >
        {prompts.map(prompt => ( (prompt.type === 'text' ? 
            <Row key={prompt} type="flex" justify="center" align="center">
              <Label style={{margin: 10, fontSize: '20px', fontWeight: '700'}} status={'green'}>
                {prompt.text}
              </Label>
            </Row>
            : "")
        ))}
        <Row type="flex" justify="end">
          <Button onClick={() => onOk()}>OK ({countDown})</Button>
        </Row>
      </Modal>
  );
}
