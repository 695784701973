import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, Input, Spin } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { ZenSmartAPI } from 'utils'
import { fetchPrinterAssignmentRoute } from "utils/apiRoutes"
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import _ from "lodash"
import PrinterAssignmentRow from './PrinterAssignmentRow'
import { debounce } from "utils/debounce"


const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DivStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
  h2 {
    font-weight: 700;
    font-family: 'Rubik', sans-serif;
  }
  .ant-input-affix-wrapper
  .ant-input {
      border-color : blue;
  }

  .ant-select-selection--single{
      border-color : blue;
  }
  
  .ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}
.ant-select-lg .ant-select-selection__rendered {
    color : blue;
}
`
const PrinterAssignmentPage = (props) => {
    const [printers, setPrinters] = useState([]);
    const [printersOriginalData, setPrintersOriginalData] = useState([])
    const [batchingConfigurations, setBatchingConfigurations] = useState([]);
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState("")

    const fetchInitData = () => {
        setLoading(true)
        ZenSmartAPI.get(fetchPrinterAssignmentRoute)
            .then((res) => {
                setLoading(false)
                if (_.get(res, 'data.data')) {
                    setPrintersOriginalData(res.data.data.printers)
                    setBatchingConfigurations(res.data.data.batching_configurations)
                    if (searchValue !== "") {
                        searchOperation(searchValue, res.data.data.printers)
                    }
                    else {
                        setPrinters(res.data.data.printers)
                    }

                }
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant fetch printer assignments!")

            })
    }

    const searchOperation = debounce(async (value, source) => {
        setSearchValue(value)
        if (value === '') {
            setPrinters(source)
        } else {
            const result = [];

            console.log(`value`, value)
            source.filter(item => {
                const returnData = {
                    name: item.name,
                    printers: item.printers.filter(data => data.full_name.toString().toLowerCase().includes(value.toString().toLowerCase()))
                }
                result.push(returnData)
            })
            setPrinters(result)
        }
    }, 400)


    useEffect(() => {
        fetchInitData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <DivStyle />
            <Header>
                <Title>Print {">"} Printer Assignment</Title>
            </Header>
            <Spin tip="Loading..." spinning={loading}>

                <Panel title="Printer Assignment">
                    <Row type="flex" style={{ padding: 40 }}>
                        <Col span="24">
                            <Heading>Filter</Heading>
                        </Col>
                        <Col span="7" style={{ paddingTop: 10 }}>
                            <Input.Search placeholder="Search printer..." onChange={(e) => searchOperation(e.target.value, printersOriginalData)} />
                            <br />
                        </Col>

                        {printers.map(printer => {
                            return <Col span="24" style={{ padding: 10, marginTop: 10 }}>
                                <Heading>{printer.name}</Heading>
                                {printer.printers.length > 0 ? printer.printers.map(item => {
                                    return <PrinterAssignmentRow
                                        data={item}
                                        batchingConfigurations={batchingConfigurations}
                                        setLoading={setLoading}
                                        fetchInitData={fetchInitData}
                                    />
                                }) : <p> No available printer.</p>}

                            </Col>
                        })}
                    </Row>
                </Panel>
            </Spin>
        </>
    )
}

export default PrinterAssignmentPage