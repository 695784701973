import React from "react"

interface Props {
  width?: number
  height?: number
  src?: string
  objectFit?: any
  alt?: string
  loading?: any
  radius?: number
}

const Image: React.FC<Props> = (props) => {
  const imgStyle = {
    objectFit: props.objectFit,
    maxHeight: props.height,
    maxWidth: props.width,
    borderRadius: `${props.radius}%`
  } as const;

  const imgContainerStyle = {
    overflow: "hidden",
    width: "initial",
    height: "initial",
    background: "none",
    opacity: "1",
    border: 0,
    margin: 0,
    padding: 0,
    position: "relative",
    borderRadius: `${props.radius}%`
  } as const;

  return (
    <div className="img-wrapper">
      <div style={imgContainerStyle}>
        <img
          src={props.src}
          alt={props.alt}
          loading={props.loading}
          width={props.width}
          height={props.height}
          style={imgStyle}
        />
      </div>
    </div>
  )
}

Image.defaultProps = {
  loading: "lazy"
}

export default Image