import React, {useContext, useState} from 'react';
import {Divider, Form, Input} from 'antd';
import styled from 'styled-components';
import FormModal from '../../components/FormModal';
import {DesignerContext} from '../../DispatchDesignerContext';

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 10px;
`;

function DispatchFormModal(props) {
  const {selectedProduct, template} = useContext(DesignerContext);
  const {form, stageData, onSave, onCancel, ...modal} = props;
  const [errors, setErrors] = useState({});
  const {getFieldDecorator} = form;

  const getPayload = () => {
    const data = form.getFieldsValue();
    data.type = 'dispatch_config';
    return data;
  };

  if (modal.visible === false) {
    return null;
  }

  const renderFormItem = (props) => {
    const {placeholder, info, field, initialValue} = props;
    const help = errors[field] ? errors[field][0] : info;
    return (<StyledFormItem label={placeholder}
                            validateStatus={errors[field] && 'error'}
                            help={help}>
          {getFieldDecorator(field,
              {initialValue})(<Input
              placeholder={placeholder}/>)}
        </StyledFormItem>
    );
  };

  const handleClose = () => {
    setErrors([]);
  };

  return (
      <FormModal {...modal}
                 form={form}
                 onError={setErrors}
                 getPayload={getPayload}
                 stageData={stageData}
                 onClose={handleClose}
      >

        {renderFormItem({
          field: 'template_name',
          placeholder: 'Template name',
          info: !template ? 'Set a name for the designer template. Product name is set as default' : null,
          initialValue: template ? template.name : selectedProduct.name,
        })}

        <Divider/>

        {renderFormItem({
          field: 'weight_per_line',
          placeholder: 'Weight per item',
          initialValue: stageData.weightPerLine,
        })}

        {renderFormItem({
          field: 'additional_weight_per_page',
          placeholder: 'Additional weight per page',
          initialValue: stageData.additionalWeightPerPage,
        })}

        {renderFormItem({
          field: 'additional_weight_per_sqm',
          placeholder: 'Additional weight per SQM',
          initialValue: stageData.additionalWeightPerSqm,
        })}

        {renderFormItem({
          field: 'additional_weight_per_consignment',
          placeholder: 'Additional weight per consignment',
          initialValue: stageData.additionalWeightPerConsignment,
        })}
      </FormModal>
  );
}

export default Form.create()(DispatchFormModal);