import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Modal, notification, Row, Select, Spin } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { Label } from 'components/zensmart-design-system/components/Label/Label.js';
import { ZenSmartAPI } from 'utils';
import { batchPrintRoute, estimatePrintTimeForBatchesRoute, specialOptionsForPrinterRoute } from 'utils/apiRoutes';
import { Input } from 'antd';

const { Option } = Select;

const HeaderStyle = styled.h1`
    font-weight : bold;
    font-size : 16px;
    font-family: 'Rubik', sans-serif;
    padding : 10px;

`
const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}

.ant-select-lg && path {
    color : blue;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}
.ant-select-lg .ant-select-selection__rendered {
    color : blue;
}
`

const BatchManagerModal = (props) => {

    const { showModal, setShowModal, resend, printerSelectValue, consolidationSelectValue, pressReleasePayload, batchPayload, successCallBack, setPrinterSent } = props
    let consolidationsDropdownOptions = [];
    let defaultValue = null;
    const newConsolidation = consolidationSelectValue['NEW'];
    const existingConsolidations = consolidationSelectValue['EXISTING'];

    const [finishingTypeSelect, setFinishingTypeSelect] = useState([])
    const initialPayload = {
        batch_barcodes: [],
        printer_id: null,
        finishing_type_id: null,
        consolidation: null,
        consolidation_option: null,
        merge_batches: false,
        special_option_id: null,
        add_to_queue: true
    }
    const [payload, setPayload] = useState(initialPayload)
    const [loading, setLoading] = useState(false)
    const [estLoading, setEstLoading] = useState(false)
    const [estPrinting, setEstPrinting] = useState(false)
    const [doLoadEst, setDoLoadEst] = useState(true)
    const [specialOptions, setSpecialOptions] = useState([]);
    if (newConsolidation && 'id' in newConsolidation) {
        consolidationsDropdownOptions.push('NEW');
    }
    if (existingConsolidations && 'id' in existingConsolidations) {
        consolidationsDropdownOptions.push('EXISTING');
        defaultValue = 'EXISTING';
    }

    useEffect(() => {
        // rest selections on modal open
        if (showModal) {
            setPayload({
                ...payload,
                printer_id: null,
                finishing_type_id: null,
                merge_batches: false,
            })
            setDoLoadEst(true)

        }
    }, [showModal]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        // rest selections on modal open
        if (defaultValue == "EXISTING") {
            setPayload({ ...payload, consolidation: existingConsolidations.id, consolidation_option: existingConsolidations.barcode })
        }
    }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectValue = (value, type) => {

        if (type === "printer") {
            setPayload({ ...payload, printer_id: value, batch_barcodes: batchPayload })
            const findPrinter = printerSelectValue.printers.find(data => {
                return data.id === value
            })
            setFinishingTypeSelect(findPrinter.finishing_types);
            setPrinterSent(findPrinter)
        }
        else if (type === "finishing_type") {
            setPayload({ ...payload, finishing_type_id: value, batch_barcodes: batchPayload })
        }
        else if (type === "special_option_id")
            setPayload({ ...payload, special_option_id: value })
        else if (type === "consolidation_option") {
            const valueConsolidation = consolidationSelectValue[value];
            const consolidation_id = valueConsolidation.id;
            setPayload({ ...payload, consolidation_option: value, consolidation: consolidation_id })
        }

        setDoLoadEst(true)
    }

    const handleCancel = () => {
        setShowModal(false)
        setDoLoadEst(false)
        setEstPrinting(false)
        setPayload(initialPayload)
        setSpecialOptions([]);
    }

    const handleSubmit = () => {
        if (payload.printer_id === null || payload.finishing_type_id === null) {
            notification.error({ message: "Please select all the values!" })
            return;
        }
        setLoading(true)

        let submissionAPI = batchPrintRoute
        if (resend) {
            submissionAPI = `${submissionAPI}?resend`
        }

        ZenSmartAPI.post(submissionAPI, payload)
            .then((res) => {
                notification.success({ message: "Batches sent successfully!" })
                setLoading(false);
                setShowModal(false);
                successCallBack()
                setPayload(initialPayload)
                setSpecialOptions([]);
            })
            .catch((error) => {

                setLoading(false);
                console.log('error', error);
                const { response } = error;
                if (response && response.status < 500) {
                    notification.error({
                        message: response.data.message
                    })
                    return;
                }
                notification.error({
                    message: 'Could not send to print. Please try again or raise with a supervisor.'
                })
            })


    }

    const secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay;

    }

    const estimatePrintTimeForBatches = () => {
        if (payload.printer_id === null || payload.finishing_type_id === null) {
            return;
        }
        setEstLoading(true)

        let estPayload = {
            'press_release': pressReleasePayload['batched']['where'],
            'payload': payload
        }

        ZenSmartAPI.post(estimatePrintTimeForBatchesRoute, estPayload)
            .then((res) => {
                setEstLoading(false);
                if (res.data.data.estimate_seconds) {

                    setEstPrinting(secondsToHms(res.data.data.estimate_seconds));
                }

            })
            .catch((error) => {
                setEstLoading(false);
                const { response } = error;
                if (response && response.status && response.status < 500) {
                    notification.error({
                        message: response.data.message
                    })
                    return;
                }
                notification.error({
                    message: 'Could load estimated printing time.'
                })
            })

        ZenSmartAPI.post(specialOptionsForPrinterRoute, { printer_id: payload.printer_id })
            .then((res) => {
                setEstLoading(false);
                console.log(res.data.data.specialOptions);
                setSpecialOptions(res.data.data?.special_options)
            })
            .catch((error) => {
                setEstLoading(false);
                const { response } = error;
                if (response && response.status && response.status < 500) {
                    notification.error({
                        message: response.data.message
                    })
                    return;
                }
                notification.error({
                    message: 'Failed to load special options for printer.'
                })
            })
        setDoLoadEst(false)

    }

    useEffect(() => {
        if (printerSelectValue.printers) {
            const payloadData = initialPayload
            if (printerSelectValue.default_printer) {
                payloadData.printer_id = printerSelectValue.default_printer.id
                setFinishingTypeSelect(printerSelectValue.default_printer.finishing_types);
                setPrinterSent(printerSelectValue.default_printer)
                payloadData.finishing_type_id = printerSelectValue.default_printer.finishing_type_default.id
                payloadData.batch_barcodes = batchPayload

            }
            else if (printerSelectValue.printers.length === 1) {
                payloadData.printer_id = printerSelectValue.printers[0].id
                setFinishingTypeSelect(printerSelectValue.printers[0].finishing_types);
                setPrinterSent(printerSelectValue.printers[0])
                if (printerSelectValue.printers[0].finishing_types.length === 1) {
                    payloadData.finishing_type_id = printerSelectValue.printers[0].finishing_types[0].id
                }
                payloadData.batch_barcodes = batchPayload

            }

            if (defaultValue == "EXISTING") {
                payloadData.consolidation = existingConsolidations.id
                payloadData.consolidation_option = existingConsolidations.barcode
            }

            setPayload(payloadData)
        }
    }, [printerSelectValue, showModal]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (finishingTypeSelect.length === 1) {
            setPayload({ ...payload, finishing_type_id: finishingTypeSelect[0].id })
        } else if (finishingTypeSelect.length > 1) {
            const defaultFinishingType = finishingTypeSelect.filter(finishingType => finishingType.is_default === true);
            setPayload({ ...payload, finishing_type_id: defaultFinishingType[0]?.id })
        }
    }, [finishingTypeSelect]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (payload.printer_id && payload.batch_barcodes && payload.finishing_type_id && doLoadEst) {
            estimatePrintTimeForBatches();
        }
    }, [doLoadEst, estimatePrintTimeForBatches, payload])
    return (
        <>
            <ModalStyle />
            <Modal
                title=""
                width={700}
                visible={showModal}
                footer={null}
                centered
            >
                {showModal &&
                    <>
                        <Row type="flex" justify="center" style={{ padding: 20 }}>
                            <Label status="blue" >ARE YOU SURE YOU WANT TO SEND THESE BATCHES?</Label>

                            <Col span={14} style={{ padding: 15 }}>
                                <HeaderStyle>Select Printer: </HeaderStyle>
                                <Select value={payload.printer_id} size="large" placeholder="Select Printer" style={{ width: "100%" }} onChange={(e) => selectValue(e, "printer")} >
                                    <option value={null} selected disabled hidden>Select Printer</option>
                                    {printerSelectValue ? printerSelectValue.printers.map(item =>
                                        <Option value={item.id}>{item.name}</Option>
                                    ) : null}
                                </Select>
                            </Col>
                            <Col span={14} style={{ padding: 15 }}>
                                <HeaderStyle>Select Finishing: </HeaderStyle>
                                <Select size="large" value={payload.finishing_type_id} placeholder="Select Finishing Type" style={{ width: "100%" }} onChange={(e) => selectValue(e, "finishing_type")} >
                                    <option value={null} selected disabled hidden>Select Finishing Type</option>
                                    {finishingTypeSelect.length > 0 ? finishingTypeSelect.map(item =>
                                        <Option value={item.id}>{item.name}</Option>
                                    ) : null}
                                </Select>
                            </Col>

                            {
                                consolidationsDropdownOptions.length && printerSelectValue.show_consolidation ?
                                    (<Col span={14} style={{ padding: 15 }}>
                                        <HeaderStyle>Select Consolidation: </HeaderStyle>
                                        <Select defaultValue={defaultValue} value={payload.consolidation_option || defaultValue} size="large" placeholder="Select Consolidation" style={{ width: "100%" }} onChange={(e) => selectValue(e, "consolidation_option")} >
                                            <option value={null} disabled hidden>Select Consolidation</option>
                                            {consolidationsDropdownOptions.map(option =>
                                                <Option value={option}>{option}</Option>
                                            )}
                                        </Select>
                                    </Col>) : null
                            }

                            {
                                !consolidationsDropdownOptions.length && printerSelectValue.show_consolidation ?
                                    (<Col span={14} style={{ padding: 15 }}>
                                        <HeaderStyle>Consolidation:</HeaderStyle>
                                        <Input
                                            style={{
                                                width: '100%',
                                                textTransform: 'capitalize'
                                            }}
                                            placeholder='Enter Consolidation Barcode'
                                            value={payload.consolidation}
                                            onChange={event => setPayload({
                                                ...payload,
                                                consolidation: event.target.value
                                            })}
                                        />
                                    </Col>) : null
                            }

                            {
                                specialOptions && specialOptions.length > 0 &&
                                <Col span={14} style={{ padding: 15 }}>
                                    <HeaderStyle>Special Option: </HeaderStyle>
                                    <Select size="large" value={payload.special_option_id} placeholder="Select Special Option" style={{ width: "100%" }} onChange={(e) => selectValue(e, "special_option_id")} >
                                        <option value={null} selected disabled hidden>Select Special Option</option>
                                        {specialOptions.map(option =>
                                            <Option value={option.id} key={option.id}>{option.name}</Option>
                                        )}
                                    </Select>
                                </Col>
                            }
                            <Col span={14} style={{ padding: 15 }}>
                                <Checkbox
                                    checked={payload.merge_batches}
                                    onChange={e => setPayload({ ...payload, merge_batches: e.target.checked })}
                                >
                                    Merge selected jobs into one imposed <br />file where possible
                                </Checkbox>
                            </Col>
                            <Col span={14} style={{ padding: 15 }}>
                                <Checkbox
                                    checked={payload.add_to_queue}
                                    onChange={e => setPayload({ ...payload, add_to_queue: e.target.checked })}
                                >
                                    Add to submission queue if available <br />(unticking will send NOW)
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row type="flex" justify="center" align="middle" style={{ paddingLeft: 80, paddingRight: 20, paddingBottom: 20 }}>
                            <Col span={24} style={{ padding: 15, height: 50, alignContent: 'center' }}>
                                {estPrinting && (!estLoading) ?
                                    <p><b>Estimated time to print:&nbsp;&nbsp;&nbsp;</b>{estPrinting}</p>
                                    :
                                    estLoading ?
                                        <p><b>Estimated time to print:&nbsp;&nbsp;&nbsp;</b><Spin size='small' /></p>
                                        :
                                        <p></p>
                                }
                            </Col>
                        </Row>
                        <Row type="flex" justify="center" style={{ paddingBottom: 30 }}>

                            <Col span={5} style={{ padding: 5 }} >
                                <Button type="primary" style={{ width: "100%" }} loading={loading} onClick={handleSubmit}>Send to Print</Button>
                            </Col>
                            <Col span={5} style={{ padding: 5 }} >
                                <Button type="danger" style={{ width: "100%" }} onClick={handleCancel}>Cancel</Button>
                            </Col>
                        </Row>
                    </>
                }
            </Modal>
        </>
    )
}

export default BatchManagerModal