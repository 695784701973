import React, { useState } from "react";
import { createGlobalStyle } from "styled-components";

const TableStyle = createGlobalStyle`

.assigned-users {
    border-collapse: collapse;
    width: 100%;
  }
  
  .assigned-users th,
  .assigned-users td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;    
  }
  
  .assigned-users th {
    background-color: #d0f2fe;   
    color: black; 
  }
  
  .assigned-users tr:nth-child(even) {
    background-color: #f1f3ff;
  }
  
  .assigned-users td button {
    background-color: transparent;
    border: none;
    color: red;
    cursor: pointer;
    font-weight: bold;
  }
`;

function PermissionTableView({ permissions, users, roleDescription }) {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };


    const getPermissionsByGroup = (permissions) => {
        const permissionsByGroup = {};
        permissions.forEach((permission) => {
            if (!permissionsByGroup[permission.group]) {
                permissionsByGroup[permission.group] = [];
            }
            permissionsByGroup[permission.group].push(permission);
        });

        for (const group in permissionsByGroup) {
            permissionsByGroup[group].sort((a, b) => a.name.localeCompare(b.name));
        }

        return permissionsByGroup;
    };

    const filterPermissions = (permissions) => {
        if (!searchQuery) {
            return permissions;
        }
    
        return permissions.filter((permission) => {
            const matchesPermissionName = permission.name.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesGroupName = permission.group.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesPermissionName || matchesGroupName;
        });
    };
    

    const permissionsByGroup = getPermissionsByGroup(filterPermissions(permissions));

    return (
        <>
            <p style={{ color: '#908A8A', fontStyle: 'italic' }}>{roleDescription}</p>
            <div style={{ position: 'relative', width: '100%', marginBottom: '2px', marginTop: '12px' }}>
                <input
                    type="text"
                    placeholder="Search assigned permissions by name..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{
                        width: '100%',
                        padding: '12px',
                        paddingLeft: '36px',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        textAlign: 'center',
                    }}
                />
                {!searchQuery && (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6"
                        style={{
                            position: 'absolute',
                            left: 'calc(36% - 18px)',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            width: '1.25em',
                            height: '1.25em',
                        }}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                )}
            </div>

            <div className="mt-5">
                {permissions.length > 0 ? (
                    <table style={{ borderSpacing: '0', width: '100%' }}>
                        <tbody>
                            {Object.keys(permissionsByGroup).map((group, index) => (
                                <React.Fragment key={index}>
                                    <tr style={{ backgroundColor: '#f1f3ff', color: "black" }}>
                                        <td colSpan="2" style={{ fontWeight: 'bold', padding: '5px', textTransform: 'uppercase' }}>{group}</td>
                                    </tr>
                                    {permissionsByGroup[group].map((permission, index) => (
                                        <tr key={index}>
                                            <td style={{ wordBreak: 'break-word', padding: '5px' }}>{permission.name}</td>
                                            <td style={{ wordBreak: 'break-word', padding: '5px' }}>{permission.description}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <p>No Permission Data Available</p>
                    </div>
                )}
                <hr />
                {users && (
                    <div className="mt-5">
                        <h3 style={{ fontWeight: 'bold', fontSize: '18px' }}>Current assigned to:</h3>
                        {users.length > 0 ? (
                            <ul style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem', listStyleType: 'disc', paddingLeft: '20px' }}>
                                {users.map((user, index) => (
                                    <li key={user.id}>
                                        {user.name}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <p>No User Data Available</p>
                                </div>
                                <hr />
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );

}

export default PermissionTableView;