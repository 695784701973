import React, {forwardRef} from 'react'
import styled, {css} from 'styled-components'

import {color} from 'components/zensmart-design-system/shared/styles.js'
import {ReactComponent as DragHandleIcon} from 'static/icons/drag-handle-icon.svg'

const getColor = props => props.color
const getStylings = props => props.stylings

const defaultShapeStyling = ({isDefault}) => isDefault ? css`
  padding-left: 8px;
  background-color: #fff;

  border: 1px solid;
  border-left-width: 8px;
` : null

const Container = styled.div`
  display: flex;
  flex: 0 0 auto;

  position: relative;
  padding-left: 16px;

  width: 128px;
  height: 56px;

  color: ${getColor};
  
  ${defaultShapeStyling}
  ${getStylings}

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & > g {
      stroke: ${getColor};
    }
  }
`

const HandleContainer = styled.div`
  z-index: 2;
  width: 20%;

  display: flex;
  align-items: center;
`

const Text = styled.p`
  width: 80%;
  height: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;

  line-height: 1;
  font-weight: bold;

  padding-right: 10px;
  z-index: 2;
`

function Item(props, ref) {
  const {
    text,
    shape,
    contentEditable,
    onBlur,
    ...item
  } = props

  return (
    <Container {...item} ref={ref} isDefault={!shape}>
      {shape}
      <HandleContainer>
        <DragHandleIcon fill={color.body} width="10px" />
      </HandleContainer>
      <Text>{text}</Text>
    </Container>
  )
}

export default forwardRef(Item)