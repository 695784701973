import React, { useRef } from "react";
import GridLayout from "react-grid-layout";
import styled, { css } from "styled-components";
import is from "is_js";
import { useDispatch, useSelector } from "react-redux";
import { DashboardItem } from "pages/Performance/partials/composites";
import { ReactComponent as CloseIcon } from "static/icons/close.svg";
import { MATRIX_FONT } from "../../../store/actions/dashboardTypes";

const Grid = styled.div`
  margin: 0;
  padding: 0;
  padding-top: 1px;
  width: ${(props) => (props.sidebar ? "74%": "100%")};
  transition: .5s;
  overflow-y: auto;
  
  ${(props) =>
    props.sidebar &&
    css`
      background-color: #dee8fc;
      background-size: 98px 69px;
      background-attachment: local;
      background-image: linear-gradient(to right, #fff 3px, transparent 1px),
        linear-gradient(to bottom, #fff 3px, transparent 1px);
    `}

  ${(props) =>
    props.edit &&
    css`
      position: relative;
    `}

  .gridLayout {
    width: ${(props) => (props.edit ? props.gridWidth +"px" : "100%")};
    padding: 0;
    margin-top: ${(props) => (props.homePage ? "-20px" : "-11px" )};
    margin-left: ${(props) => (props.homePage ? "-20px" : "-10px" )};
    ${(props) =>
    props.edit &&
    css`
      position: absolute;
    `}
  }
`;

const WidgetContainer = styled.div`
  padding: 0;
  opacity: 1;
  &.dragging {
    opacity: 0.5;
    overflow: hidden;
  }
`;

const IconContainer = styled.div`
  ${(props) =>
    props.sidebar
      ? css`
          position: absolute;
          right: 2px;
          top: 3px;
          cursor: pointer;
          z-index: 9999;
        `
      : css`
          display: none;
        `}
`;

function DashboardWidget(props) {
  const {
    sidebar = false,
    refreshData = false,
    interruptTimer,
    dashboardMapping = [],
    dashboardLayout = [],
    updateDashboardLayout = (_) => {},
    onDrop = (_,__) => {},
    onRemove = (_) => {},
    isEdit = false,
    keyValue,
    mode = "live",
    homePage
  } = props;

  const reports = useSelector((state) => state.dashboard.reports);
  const matrixFontSize = useSelector((state) => state.dashboard.matrixFonts);
  const dispatch = useDispatch();
  const widgetsRef = useRef([]);
  const matrixRef = useRef([]);
  const gridWidth = 4920;

  function cancel(e) {
    e.preventDefault();
  }

  const onDragItem = (layout, oldItem, newItem, placeholder, e, element) =>
    element.classList.add("dragging");

  const onResizeItem = (layout, oldItem, newItem, placeholder, e, element) =>
    element.offsetParent.classList.add("dragging");

  const onDragStop = (layout, oldItem, newItem, placeholder, e, element) =>
    element.classList.remove("dragging");

  function onResizeStop (layout, oldItem, newItem, placeholder, e, element) {
    element.offsetParent.classList.remove("dragging");
    const widgetIndex = newItem.i;

    updateMatrixFont(widgetIndex, matrixRef.current[widgetIndex], widgetsRef.current[widgetIndex]);
  }

  function updateMatrixFont(refIndex, matrixElement, widgetElement){
    let fontSize = 14

    if (is.existy(matrixElement) && is.existy(widgetElement)) {
      const fontIncrement = 0.2
      const multiply = { y: 1, x: 1.08 } // The offset widths and heights are usually off so this corrects that
      const scaleX = widgetElement.offsetWidth / (matrixElement.offsetWidth * multiply.x)
      const scaleY = widgetElement.offsetHeight / matrixElement.offsetHeight

      while(true) {
        matrixElement.style.fontSize = fontSize + 'px'
        if(matrixElement.offsetHeight > widgetElement.offsetHeight){
          matrixElement.style.fontSize = (fontSize - fontIncrement)  + 'px'
          matrixElement.style.transform = `scaleX(${scaleX}) scaleY(${scaleX})`
          matrixElement.style.transformOrigin = 'left top'
          matrixElement.scrollLeft = 0
          matrixElement.scrollTop = 0
          break
        }
        else fontSize += fontIncrement
      }
    }
    
    dispatch({
      type: MATRIX_FONT,
      payload: { ...matrixFontSize, [refIndex]: fontSize },
    });
  }

  return (
    <Grid
      key={is.existy(keyValue) ? keyValue : 0}
      onDrop={(event) => onDrop(event)}
      onDragOver={(event) => cancel(event)}
      sidebar={sidebar}
      edit={isEdit}
      gridWidth={gridWidth}
      homePage={homePage}
    >
      <GridLayout
        className="layout gridLayout"
        layout={dashboardLayout}
        compactType={null}
        preventCollision={true}
        onLayoutChange={(layout) => {
          updateDashboardLayout(layout);
        }}
        onDrag={onDragItem}
        onResize={onResizeItem}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        isDraggable={sidebar}
        isResizable={sidebar}
        width={gridWidth}
        cols={50}
        rowHeight={50}
        margin={[21, 19]}
        onDragStart={(layout, oldItem, newItem, placeholder, e, _) => {
          e.stopPropagation();
        }}
      >
        {dashboardMapping
          .filter((mapping) => is.not.truthy(mapping.deleted))
          .map((mapping) => {
            const index = mapping.layout_id;
            const filteredLayout = dashboardLayout.find(
                (layout) => layout.i === index
            );
            const filteredReport = reports.find(
              (report) => report.id === mapping.report_id
            );
            const {matrix_summary, matrix_summary_field} = filteredReport

            return (
              <WidgetContainer
                key={index}
              >
                <div style={{height: "100%"}} ref={(el) => (widgetsRef.current[index] = el)}>
                  <IconContainer
                      sidebar={sidebar}
                      onClick={() => onRemove(index)}
                  >
                    <CloseIcon width="20px" height="15px" />
                  </IconContainer>
                  <DashboardItem
                      report={filteredReport}
                      matrixOptions={{
                        field: matrix_summary_field ? matrix_summary_field : '',
                        option: matrix_summary ? matrix_summary : 'record count'
                      }}
                      sidebar={sidebar}
                      interruptTimer={interruptTimer}
                      refreshData={refreshData}
                      parentRef={widgetsRef}
                      matrixRef={matrixRef}
                      refIndex={index}
                      gridWidth={filteredLayout ? filteredLayout.w : 0}
                      gridHeight={filteredLayout ? filteredLayout.h : 0}
                      updateMatrixFont={updateMatrixFont}
                      reportURL={`/app/performance/reports/${filteredReport.id}`}
                      mode={mode}
                      homePage={homePage}
                  />
                </div>
              </WidgetContainer>
            );
          })}
      </GridLayout>
    </Grid>
  );
}

export default DashboardWidget;
