import React, { useState, useContext } from 'react'

const MenuContext = React.createContext()
MenuContext.displayName = "MenuContext"

function MenuContextProvider(props) {
  const [isMenuOpen, setMenuOpen] = useState(true)
  return <MenuContext.Provider value={[isMenuOpen, setMenuOpen]} {...props} />
}

function useMenu() {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error('useMenu must be used within MenuContextProvider')
  }
  return context
}

export { MenuContextProvider, useMenu }
