import React, { useState } from 'react';
import { Modal, Form, Input, Select, Button, notification } from 'antd';
import { updateFaultRoute } from "utils/apiRoutes"
import { useSelector } from 'react-redux';
import { ZenSmartAPI, notifyAPIErrorResponse } from 'utils';

const { TextArea } = Input;

const UpdateFaultModal = props => {
  const { show, setShow, fault, setFault, reload } = props;

  const [ isInit, setIsInit ] = useState(false);
  const [ faultFormData, setFaultFormData ] = useState({comment: '', severity: 'low', status: 'reported'})
  const faultSeverity = ['low', 'medium', 'high'];
  const faultStatus = ['reported', 'analysing', 'repairing', 'completed'];

  const user = useSelector(state => state.user && state.user.authenticated ? state.user.details : null);

  if(fault && show && isInit === false) {
    setFaultFormData({
      ...faultFormData,
      severity: fault.severity,
      status: fault.status
    })
    setIsInit(true);
  }

  const updateFault = () => {
    const payload = {
      fault_id: fault.id,
      station_id: fault.station_id,
      email: user.email,
      ...faultFormData
    }

    if(payload.comment.trim() == ''){
      notification.error({
        message: "Comment field is required."
      })
    }else{
      ZenSmartAPI.post(updateFaultRoute, payload)
        .then(res => {
          if(res.data) {
            notification.success({
              message: 'Successfully updated fault.'
            })
          }
          reload(val => !val)
          setShow(false);
        })
        .catch(err => {
          notifyAPIErrorResponse(err.response.data, 'Updating fault failed.')   
        })
    }
  }

  const onCloseModal = () => {
    setIsInit(false);
    setFault(null);
    setFaultFormData({
      comment: '',
      severity: 'low',
      status: 'reported'
    })
  }

  return (
    <Modal
      title={`Update Fault - Case ${fault && fault.id} @ ${fault && fault.station}`}
      visible={show}
      onCancel={ () => setShow(false) }
      afterClose={ () => onCloseModal() }
      footer={[
        <Button key="submit" type="primary" onClick={() => updateFault()}>
          Update
        </Button>
      ]}
    >
      <Form>
        <Form.Item label="Comment">
          <TextArea 
            placeholder="Enter comment" 
            value={faultFormData.comment}
            onChange={ e => setFaultFormData({...faultFormData, comment: e.target.value}) }
          />
        </Form.Item>

        <Form.Item label="Severity">
          <Select 
            value={faultFormData.severity}
            onChange={ val => setFaultFormData({...faultFormData, severity: val}) }
          >
            { faultSeverity.map(severity => 
              <Select.Option key={severity}>{ severity }</Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Status">
          <Select 
            value={faultFormData.status}
            onChange={ val => setFaultFormData({...faultFormData, status: val}) }
          >
            { faultStatus.map(status => 
              <Select.Option key={status}>{ status }</Select.Option>
            )}
          </Select>
        </Form.Item>
      </Form>

    </Modal>
  )
}

export default UpdateFaultModal;