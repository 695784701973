import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, spacing } from '../../shared/styles';

// Base styling for panels
const StyledDiv = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: ${props => props.width ? props.width : 'auto'};
  height: ${props => props.height ? props.height : 'auto'};
  background: ${color.white};
  border-radius: ${spacing.borderRadius.small}px;
  &:focus {
    outline:0;
  }
  ${(props) => (props.active) && `
    -webkit-box-shadow: 0px 0px 0px 1px ${color.border.unselected};
    -moz-box-shadow: 0px 0px 0px 1px ${color.border.unselected};
    box-shadow: 0px 0px 0px 1px ${color.border.unselected};
    &:focus {
      -webkit-box-shadow: 0px 0px 1px 2px ${color.border.selected};
      -moz-box-shadow: 0px 0px 1px 2px ${color.border.selected};
      box-shadow: 0px 0px 1px 2px ${color.border.selected};
    }
  `}
`;

const applyStyle = PanelWrapper => {
  return (
    PanelWrapper &&
    StyledDiv.withComponent(({ ...rest }) => (
      <PanelWrapper {...rest} />
    ))
  );
};

export function Panel({
  PanelWrapper,
  ...props
}) {
  const StyledDivWrapper = React.useMemo(() => applyStyle(PanelWrapper), [PanelWrapper]);

  let SelectedPanel = StyledDiv;
  if (PanelWrapper) {
    SelectedPanel = StyledDivWrapper;
  }

  return (
    //add tabindex to allow div use focus selector
    <SelectedPanel tabIndex={0} {...props}>
    </SelectedPanel>
  );
}

// properties catered for and their types
Panel.propTypes = {
  active: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string
};

Panel.defaultProps = {
  active: false
};
