import styled from 'styled-components';
import { Panel } from 'components/primaries';

export const Layout = styled.div`
    display: grid;
    grid-template-columns: auto 350px;
    grid-gap: 15px;
`;

export const PrinterPanel = styled(Panel)`
    padding-top: 62px;
`;