import React, { useState, useEffect } from "react";
import { Panel } from "components/primaries";
import { Button, Table, Popconfirm, notification } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import styled from "styled-components";
import { color } from "components/zensmart-design-system/shared/styles.js";
import TaskFormModal from "./TaskFormModal";
import TaskHeader from "../partials/TaskHeader";
import { useSelector, useDispatch } from 'react-redux';
import { getTasks } from 'store/actions/machine';
import { ZenSmartAPI } from 'utils';
import { getStationTasksRoute, deleteTaskRoute, saveTaskRoute, getStationDeviceClassesRoute } from "utils/apiRoutes"
import arrayMove from 'array-move';
import "../Machine.css"

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;

const StyledDivider = styled.hr`
  background-color : #2E5BFF;
  margin: 1rem;
  margin-bottom: 2rem;
  height: 1px;
  opacity: 25%;
`

const SubtitleStyle = styled.h2`
  color: #8798AD;
  font-size: 13px;
  font-weight: normal;
  margin: 1rem 50px;
`

const StyledTable = styled(Table)`
  thead > tr > th {
    background: transparent;
  }

  tbody tr:nth-child(odd) {
    background: #f2f4fd;
  }
  
  tr > th:nth-child(1),
  tr > td:nth-child(1) {
    padding-left: 50px;
  }

  tr > td:last-child {
    padding-right: 50px;
  }

  .ant-pagination {
    margin: 20px 50px;
  }
`

const ActionButton = styled(Button)`
  margin-right: 10px;
`

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const TaskEdit = () => {
  
  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Class',
      dataIndex: 'class',
      key: 'class'
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render(value, row) {
        const items = row.description ? row.description.split("\n").map(item => item.trim()) : [];
        return (
          <div>
            {items.map((item, index) => (<div key={index}><span>{item}</span><br /></div>))}
          </div>
        );
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (obj, row) => 
        <>
          <ActionButton type="primary" ghost onClick={ e => loadTaskFormModal(row) }>Edit</ActionButton>
          <Popconfirm 
            placement="top" 
            title={'Are you sure you want to delete this task?'} 
            okText="Yes" 
            cancelText="No"
            onConfirm={ () => deleteTask(row.id) }
          >
            <ActionButton type="danger">Delete</ActionButton>
          </Popconfirm>
        </>
    }
  ];

  const [ showTaskFormModal, setShowTaskFormModal ] = useState(false);
  const [ selectedTask, setSelectedTask ] = useState(null);
  const [deviceClasses, setDeviceClasses] = useState([]);
  const [selectedDeviceClass, setSelectedDeviceClass] = useState("");
  // redux states
  const dispatch = useDispatch();
  const machine = useSelector(state => state.machine);

  useEffect(() => {
    ZenSmartAPI.get(getStationDeviceClassesRoute).then((res) => {
      const classes = res.data.data;
      setDeviceClasses(classes);
      setSelectedDeviceClass(classes[0]?.device_type);
    }).catch((err) => {
      notification.error({
        message: 'Failed to fetch device classes'
      })
    })
  }, []);

  useEffect(() => {
    if (selectedDeviceClass) {
      ZenSmartAPI.post(getStationTasksRoute, {device_type: selectedDeviceClass})
      .then((res)=>{
        dispatch(getTasks(res.data.data))
      })
      .catch((res)=>{
        notification.error({
          message: 'Fetching tasks failed'  
        })
      })
    }
  }, [dispatch, selectedDeviceClass]);

  const loadTaskFormModal = task => {
    setShowTaskFormModal(true);
    setSelectedTask(task);
  }

  const dataSource = machine.tasks && machine.tasks.map((task,index) => {
    return {
      ...task, 
      key: index,
      index: task.sort_order,
      frequency: task.frequency.replace(/,/g,"x").replace(/-/g,'z').replace(/\W/g, "").replace(/x/g, ", ").replace(/z/g, '-')
    } 
  });

  const loadTasks = () => {
    if(selectedDeviceClass) {
      const payload = {device_type: selectedDeviceClass}
      ZenSmartAPI.post(getStationTasksRoute, payload)
        .then((res)=>{
          dispatch(getTasks(res.data.data))
        })
        .catch((res)=>{
          notification.error({
            message: 'Fetching tasks failed'  
          })
        })
    } else {
      dispatch(getTasks([]));
    }
  }

  const deleteTask = id => {
    ZenSmartAPI.delete(deleteTaskRoute(id))
      .then(res => {
        notification.success({
          message: 'Delete task successful.'
        })
        loadTasks();
      })
      .catch(res => {
        notification.error({
          message: 'Deleting task failed.'
        })
      })
  }


  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      newData = newData.map((item, index) => {
        return {
          ...item,
          sort_order: parseInt(index) + 1
        }
      });

      dispatch(getTasks(newData))

      const payload = {
        tasks: [...newData].map(item => {
          return {
            ...item,
            frequency: item.frequency.trim().split(',')
          }
        })
      }
      ZenSmartAPI.post(saveTaskRoute, payload)
      .then((res)=>{
        console.log(res)
      })
      .catch((res)=>{
        notification.error({
          message: 'Saving tasks order failed.'
        })
      })
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const handleSelectedDeviceClassChange = (value) => {
    setSelectedDeviceClass(value);
  }

  return (
    <>
      <TaskFormModal 
        task={selectedTask}
        setTask={setSelectedTask} 
        show={showTaskFormModal} 
        setShow={setShowTaskFormModal}
        deviceClass={selectedDeviceClass}
        allClasses={deviceClasses}
        loadTasks={loadTasks}
      />
      <Header>
        <Title>Machine {">"} Task Designer</Title>
      </Header>
      <Panel title="TASKS MANAGEMENT">
          <TaskHeader
            clickCreateTask={loadTaskFormModal} 
            showCreateTask={true}
            deviceClasses={deviceClasses}
            selectedDeviceClass={selectedDeviceClass}
            onDeviceClassChange={handleSelectedDeviceClassChange}
            loadTasks={loadTasks}
          />
  
          <StyledDivider />
  
          <SubtitleStyle>TASK EDITOR</SubtitleStyle>
  
          <StyledTable 
            dataSource={dataSource} 
            columns={columns}
            rowKey="index"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
      </Panel>
    </>
  );
}

export default TaskEdit;
