import React from 'react'
import styled from 'styled-components'

import {color} from 'components/zensmart-design-system/shared/styles.js'

const PanelTitleContainer = styled.section`
  margin: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > i {
    font-size: 20px;
  }
`

const Title = styled.h2`
  color: ${color.body};

  font-size: 13px;
  font-weight: normal;
  margin: 0;
`

const PanelTitle = ({children}) => (
  <PanelTitleContainer>
    <Title>{children}</Title>
  </PanelTitleContainer>
)

const PanelContainer = styled.section`
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 2px;

  border-radius: 5px;
  border: 1px solid ${color.blue.normal};
  ${props => props.stylings}
`

function Panel(props) {
  const {title, children, styles, ...panel} = props

  const panelTitle = title ? (
    <PanelTitle>{title}</PanelTitle>
  ) : null

  return (
    <PanelContainer {...panel} style={{ ...styles }}>
      {panelTitle}
      {children}
    </PanelContainer>
  )
}

export default Panel