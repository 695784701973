import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

const StyledSpan = styled.span`
  margin-left: 40px;
  margin-bottom: 3px;
  display: flex;
  text-transform: capitalize;

  .item, .action_icon {
    display: inline-flex;
  }

  .item {
    width: 77%;
  }
`

const FieldItem = ({
  children,
  deleteItem,
  editItem,
  setDragAction,
  stopDragAction,
  ...props
}) => (
  <StyledSpan {...props}>
    <p
      className='item'
      draggable
      onDragStart={() => setDragAction && setDragAction()}
      onDragEnd={() => stopDragAction && stopDragAction()}
    >
      {children}
    </p>
    {editItem && (
      <Icon
        type='edit'
        className='action_icon'
        onClick={e => {
          e.preventDefault()
          editItem()
        }}
      />
    )}
    {deleteItem && (
      <Icon
        type='delete'
        className='action_icon'
        onClick={(e) => {
          e.preventDefault()
          deleteItem()
        }}
      />
    )}
    
  </StyledSpan>
)

export default FieldItem