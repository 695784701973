import React from 'react';
import { Header, Title } from 'components/primaries';
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'

const ProductSkew = () => {
    
    return (
        <>
            <Header>
                <Title> Settings {">"} Product Skews</Title>
            </Header>
            <Panel title="PRODUCT SKEWS">
                <DataListPage model="product-skews" select={['id','original_product_code','skew_height','skew_width','method','position']} hidden={[]} link={"app/data-view/product-skews/"} pageSize={50}
                    addListButton={{
                        buttonLabel: "Add Product Skew",
                        actionApi: "/api/v1/data-api/product-skews",
                        offset: 14,
                        span: 3,
                    }}
                />

            </Panel>
        </>
    );
};

export default ProductSkew;