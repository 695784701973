import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {Button, Col, Dropdown, Icon, Menu, Row, Select} from 'antd';

import { color } from 'components/zensmart-design-system/shared/styles.js';
import { DesignerContext } from '../../DispatchDesignerContext';
import { alphabeticalData } from 'utils/sortData';
import { ZenSmartAPI } from 'utils'
import { productSearchRoute } from "utils/apiRoutes"
import { debounce } from "utils/debounce"
import ProductDropdown from "components/composites/ProductDropdown";

const Container = styled.div`
  margin-bottom: 32px;
`;

const Label = styled.label`
  display: block;
  color: ${color.heading};
  margin-bottom: 8px;

  font-size: 15px;
  font-weight: bold;
`;

function ProductArea(props) {
  const [loading, setLoading] = useState(false);
  const { products, setProducts, selectedProduct, copyTemplateFromProductById, setSelectedProductById, setPackTypeFormVisible } = useContext(
    DesignerContext);

  const options = alphabeticalData(products, 'code').map(product => product ? (
    <Select.Option value={product.id} key={product.id}>
      {product.code}: {product.name}
    </Select.Option>
  ) : null);

  const onSearchProduct = debounce((value, limit = null) => {
    if (value !== "" || limit) {
      ZenSmartAPI.post(productSearchRoute(value), { limit: limit, response_type: "dispatch_designer" }).then(result => {
        setProducts(result.data.data)
      })
    }
  }, 400)

  useEffect(() => {
    onSearchProduct("", 20)
  }, [])
  return (
    <>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={5}>
        <Label>Product:</Label>
        <ProductDropdown
              setProductsInParent={setProducts}
              productSelected={setSelectedProductById}
              currentProductId={selectedProduct ? selectedProduct.id : undefined}
              loading={loading}
              disabled={loading}
              customStyle={{maxWidth: '312px'}}
      />
      </Col>

        <Col className="gutter-row" span={5} offset={1}>
        <Label>Copy Template From:</Label>
        <Select
            style={{minWidth: '312px', maxWidth: 'max-content', marginRight: '8px'}}
            showSearch
            placeholder="Search for product..."
            loading={loading}
            disabled={loading}
            value={undefined}
            optionFilterProp="children"
            filterOption={true}
            onChange={copyTemplateFromProductById}
            onSearch={(e) => onSearchProduct(e)}
            dropdownMatchSelectWidth={false}
        >
          {options}
        </Select>
      </Col>

        <Col className="gutter-row" span={6}>
        <Label>&nbsp;</Label>
        <Dropdown
          overlay={<Menu>
            <Menu.Item
              key={'edit-pack-packs'}
              onClick={e => setPackTypeFormVisible(true)}
            >
              Manage pack types
            </Menu.Item>
          </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <Button>
            Actions <Icon type="down" />
          </Button>
        </Dropdown>
      </Col>
    </Row><br />
    </>
  );
}

export default ProductArea;