import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spacing, color } from '../../shared/styles';
import { icons } from '../../shared/icons';

const StyledButton = styled.input`
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${color.input.unselected};
  padding: 7px;
  padding-left: 1rem;
  padding-right: 27px;
  text-align: left;
  width: 
  ${props => props.width ? props.width : '100%'};
  color: ${color.input.selected};
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' width='16' height='16' aria-hidden='true'><path fill='rgb(11,107,242)' d='${props => props.open ? icons['up'] : icons['down']}'></path></svg>");
  background-repeat: no-repeat;
  background-position: 97% 70%;
  &::placeholder {
    color: ${color.input.unselected};
    content: 'Search';
  }
  &:focus {
    border: 1px solid ${color.input.selected};
    outline: 0;
  }
`;

const DropdownParent = styled.div`
  width: fit-content;
`;

const DropdownList = styled.ul`
  border: 1px solid #CBD6FF;
  max-height: 161px;
  width: ${props => props.width ? props.width : '100%'};
  overflow: auto;
  border-radius: ${spacing.borderRadius.small}px;
  li {
    border-bottom: 1px solid #CBD6FF;
    height: 38px;
    color: ${color.blue.normal};
  }
  li:hover {
    background: ${color.blue.normal};
    color: ${color.white}
  }
  li:last-child {
    border-bottom: 0;
  }
  
`;

const DropdownItem = styled.li`
  ${props => props.selected && `
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' width='16' height='16' aria-hidden='true'><path fill='rgb(11,107,242)' d='${icons['online']}'></path></svg>");
    background-repeat: no-repeat;
    background-position: 97% 60%;
  `}
`;

const applyStyle = DropdownWrapper => {
  return (
    DropdownWrapper &&
    StyledButton.withComponent(({ ...rest }) => (
      <DropdownWrapper {...rest} />
    ))
  );
};

export function DropdownField({
  onClick,
  items,
  width,
  DropdownWrapper,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const [userInput, setUserInput] = React.useState(items[0]);
  const drop = React.useRef(null);
  function handleClick(e){
    if(!e.target.closest(`.${drop.current.className}`) && open){
      setOpen(false);
    }
  }
  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    }
  })

  function handleItemClick(e){
    setUserInput(e.target.innerHTML);
  }

  const StyledButtonWrapper = React.useMemo(() => applyStyle(DropdownWrapper), [DropdownWrapper]);

  let SelectedDropdown = StyledButton;
  if (DropdownWrapper) {
    SelectedDropdown = StyledButtonWrapper;
  }

  return (
    <DropdownParent ref={drop}>
      <SelectedDropdown onClick={() => setOpen(open => !open)} type = 'button' value = {userInput} width = {width} open = {open} {...props} />
      {open && <DropdownList className="dropdown-menu absolute" color={props.color} width = {width}>
        {items.map(function(item) {
          return <DropdownItem className="py-2 px-4 block whitespace-no-wrap" selected = {item === userInput} onClick = {e => handleItemClick(e)} key={item}>{item}</DropdownItem>;
        })}
      </DropdownList>}
    </DropdownParent>
  );
}

DropdownField.propTypes = {
  items: PropTypes.array,
  DropdownWrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

DropdownField.defaultProps = {
  items: [],
  DropdownWrapper: undefined,
};
