import axios from 'axios';

export const GetReport = async ($id) => {
  const getData = {
    'id': $id
  }
  let res = await axios.get(`${process.env.REACT_APP_ANALYTICS_SERVER}/report`, {
    params: getData
  })
  let { data } = res;
  return data;
}

export const GetReportByName = async (postData) => {
  let res = await axios.post(`${process.env.REACT_APP_ANALYTICS_SERVER}/report/name`, postData)
  let { data } = res;
  return data;
}