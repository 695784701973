import axios from 'axios';

const LoadLabels = async (resource) => {
  if (resource) {
    let res = await axios.get(`${process.env.REACT_APP_ANALYTICS_SERVER}/labels/${resource}`);
    let { data } = res;
    return data;
  }
}

export default LoadLabels