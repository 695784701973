import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {Button, Collapse, Form, notification} from 'antd';

import {Panel} from 'components/primaries';
import {color} from 'components/zensmart-design-system/shared/styles.js';
import {productCloneIdRoute} from 'utils/apiRoutes';
import {ComponentDetail} from './ProductCloneHelper';

import {SearchArea} from './partials/sections';
import useApi from '../../hooks/useApi';
import _ from 'lodash';
import BulkCloneUpload from './components/BulkCloneUpload';

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;

const StyledForm = styled(Form)`
  margin: 16px;
`;
const SubmitContainer = styled.div`
  text-align: right;
`;

const Section = styled.section`
  margin-left: 36px;
  margin-right: 36px;
`;

const Field = styled(Form.Item)`
  margin-bottom: 16px;
  display: inline-block;
  width: 46%;
  margin: 0 20px;
`;

const ProductClone = () => {
  const [currentProduct, setCurrentProduct] = useState(null);
  const [allProductsQuery, setProducts] = useState([]);
  const [components, setComponents] = useState([])
  const apiRequest = useApi();

  const setProduct = (product, isNewProduct) => {
    setCurrentProduct(product);

    apiRequest.request('get',
        productCloneIdRoute(product.productDefinition.id),
    ).then(res => {
      setComponents(res.data);
    });

  };

  const modal = useState(null);
  const history = useHistory();

  const constructPayload = (data, productId) => {
    const payload = [
      {
        resource: 'product-clone',
        id: productId.toString(),
        data: [],
      }];
    for (var key in data) {
      const item = {};
      item.field = key;
      item.value = data[key];
      payload[0].data.push(item);
    }
    return payload;
  };

  function CloneProductFormDetails(props) {
    const {form, onSubmit, components, setComponents} = props;
    const {
      getFieldDecorator,
      getFieldsValue,
      getFieldValue,
      setFieldsValue,
      validateFields,
    } = form;
    let errors = {};
    const createNewProduct = async (evt) => {
      evt.preventDefault();
      let payload = getFieldsValue();

      // ADD_FEATURE_SELECT component constructs the field/value data different to the way  fields are
      // generated for this components. So we will be identifying fields that came from ADD_FEATURE_SELECT
      // and transforming them to make all fields consistent.
      Object.keys(payload).forEach(key => {
        if(key.includes(':')) {
          const parts = key.split(':');
          payload[key.replace(':', ',',)] = _.get(payload, `${key}.${parts[1]}`, null);
          delete payload[key]
        }
      });

      validateFields((err, values) => {
        if (!err) {
          payload = constructPayload(payload,
              currentProduct.productDefinition.id);
          apiRequest.request('post',
              productCloneIdRoute(currentProduct.productDefinition.id),
              {payload}).then((res) => {
            notification.success({
              message: 'Product cloned successfully',
            });
            history.push(`/app/prep/product-flow?product_id=${res.data.id}`);
          });
        } else {
          notification.error({
            message: 'Please fill all fields in form',
          });
          return false;
        }
      });
    };

    useEffect(() => {

    }, [currentProduct]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <StyledForm onSubmit={createNewProduct}>
          <Collapse defaultActiveKey={[0, 1, 2, 3, 4, 5]}>
            {ComponentDetail(form, getFieldDecorator, errors, components,
                setComponents, getFieldValue, setFieldsValue)
            }
          </Collapse>
          <>
            <Button
                type="primary"
                disabled={apiRequest.loading}
                htmlType="submit"
                style={{marginTop: '15px'}}
            >
              Save Changes
            </Button>
          </>
        </StyledForm>
    );
  }

  const CloneProductForm = Form.create({name: 'Create Product Form'})(
      CloneProductFormDetails);

  return (
      <>
        <Header>
          <Title>Process {'>'} Product Clone</Title>
        </Header>

        <Panel title="CLONE PRODUCT">
          <Section className={'flex justify-between'}>
            <SearchArea
                modal={modal}
                currentProduct={currentProduct}
                setCurrentProduct={setCurrentProduct}
                allProductsQuery={allProductsQuery}
                setProduct={setProduct}
                hasUnsavedChanges={false}
                enableClone={true}
            />
            <BulkCloneUpload />
          </Section>
          {currentProduct && (
              <CloneProductForm components={components} setComponents={setComponents}/>
          )}
        </Panel>
      </>
  );
};

export default ProductClone;