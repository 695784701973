import React from 'react';

const SummaryData = ({
  row = [],
  columns = [],
  hideColumn,
  getProps
}) => (
  Object.values(columns).map((column, index) => (
    <td
      key={index}
      style={
        (hideColumn) ? (
          (column.dataIndex === hideColumn) ? {display: 'none'} : {}
        ) : {}
      }
      {...getProps(index)}
    >
      {column.render ? column.render(row[column.dataIndex], column.dataIndex) : row[column.dataIndex]}
    </td>
  ))
)

export default SummaryData