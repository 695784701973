import React, {useEffect, useState } from 'react'

import styled from 'styled-components'
import { Modal, Form, InputNumber, Button, notification, Checkbox, AutoComplete, Select, Row, Col, Icon, Table, Input } from 'antd'
import { alphabeticalData } from 'utils/sortData';
import { PROCESS } from 'types'
import { ZenSmartAPI } from 'utils'
import { generateKey } from '../../utils';
import { createPickingGroups, pickingGroupsRoute, createLocations, pickingLocationsRoute , createPickingRanges , createPickingComponent , pickingComponentsRoute} from "utils/apiRoutes"
import { PreDispatchOverview } from 'pages/PackAndShip/PreDispatchOverview';


const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`


const ButtonContainer = styled.div`
  margin-top: 15px;
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`

const RowAddButton = styled(Button)`
  font-weight: normal;
  font-size: 11px;
  border: 0;
  margin:0;
  width:auto;
  box-shadow: none;
  padding: 10px 10px 25px 10px;
`






const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0243EB;
  font-size: 12px;
  border: 0;
  margin-top: 10px;
  box-shadow: none;
`

const Number = styled(InputNumber)`
  width: 100%;
`;

const error = {
  borderBottom : '1px solid #eb516d',
};



const processTextRules = [
  {
    required: true,
    message: 'Cannot be blank'
  },
  {
    max: 23,
    message: 'Must be less than 23 characters'
  }
];


function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function EditForm(props) {
  const {
    form,
    process,
    onSave,
    processID,
    hideModal,
    pickingGroupOptions,
    setPickingGroupOptions,
    pickingComponents,
    pickingLocations,
    newItemType,
    pickingCombinations,
    setPickingCombinations,
    setPickingLocations,
    pickingCombinationValues,
    setPickingCombinationValues,
    pickingGroup,
    setPickingGroup,
    pickingCombinationsValidation,
    setPickingCombinationsValidations,
    setPickingComponents
  } = props

  const {
    getFieldDecorator,
    isFieldTouched,
    getFieldError,
    getFieldsError,
    getFieldsValue,
    validateFields,
    setFieldsValue,
  } = form

  const handleSubmit = e => {
    e.preventDefault()
    const data = getFieldsValue();
    const payload = { id: pickingGroup , details : pickingCombinations}
    const errors = validatePayload(pickingCombinations)
    if(errors.length !== 0){
      notification.error({ message: 'Please fill all fields' })
      return;
    }
    const onSavePayload = { 'picking_group_id' : data.picking_group_id , 'required_verification' : (data.required_verification === true ? 1 : 0  ) , 'wait_threshold' : data.wait_threshold}
    ZenSmartAPI.post(createPickingRanges, payload)
    .then((res) => {
      onSave(onSavePayload,processID)
      hideModal()
      editPicking(res.data)
      notification.success({
        message: 'Saved Picking item changes.'
      })
    }).catch((res) => {
      notification.error({ message: res.response.data.message })
    }) 
    
    
    
  }

  const validatePayload = (payload) =>{
    let errors = []
    payload.map(function(data) {
      const keys = Object.keys(data)
      for (let index = 0; index < keys.length; index++) {
        let element = data[`${keys[index]}`];
        if(keys[index] == 'values' || keys[index] == 'fields'){
        
        }else if(typeof element === 'undefined' || element === null || element === ''){
          placeError(data.id,keys[index],errors)
        }
        
      }
    })
    return errors
  }

  const placeError = (id,field,errors) => {
    return errors.push(id)
  }

  const processTextError = isFieldTouched('text') && getFieldError('text')
  const { Option } = AutoComplete;
  const { Column } = Table;
  const selectPickingGroup = (value) => {
    setPickingGroup(value)
    setFieldsValue({
      "picking_group_id" : value
    })
    const newKey = generateKey();
    ZenSmartAPI.get(`/api/v1/picking/components/${value}`)
    .then((res) => {
      if(res.data.length !== 0){
          editPicking(res.data)
      }else{
          setPickingCombinations([])
          setPickingCombinations([
          {
            id: `newRule.${generateKey()}`,
            label: null,
            location: null,
            values: [{
              id: `value.${generateKey()}`,
              value: null,
            }],
            fields: [{
              id: `field.${generateKey()}`,
              value: null,
            }],
          }
        ]);
      }
    })
    .catch((res) => {
      console.log(res)
    })
  }


  const editPicking = (data) => {
    setPickingCombinations([])
    const outputData = [];
    data.map((field) => {
      const fields = JSON.parse(field.field_value)
      const rowField = (fields === null ? null : [fields]) 
      const newKey = generateKey();
      const columnValues = (rowField === null ? [{
              id: `value.${generateKey()}`,
              value: null,
            }]
        : rowField.map(function(data){
          return Object.values(data).map(function(value, index) {
            return {
              id: `value.${generateKey()}`,
              value: value,
            }
          });  
      }))
      const columnFields = (rowField === null ? [{
              id: `field.${generateKey()}`,
              value: null,
            }]
        : rowField.map(function(data){
          return Object.keys(data).map(function(key, index) {
            return {
              id: `field.${generateKey()}`,
              value: key,
            }
          });  
      }))
      
      outputData.push({
        id: `newRule.${generateKey()}`,
        label: field.label,
        location: field.picking_location_id,
        values: (rowField === null ? columnValues : columnValues[0]),
        fields: (rowField === null ? columnFields : columnFields[0]),
      })
    });
    setPickingCombinations(outputData)
  }

  const getPickingGroups = () => {
    ZenSmartAPI.get(pickingGroupsRoute)
      .then((res) => {
        setPickingGroupOptions && setPickingGroupOptions(res.data)
      })
      .catch((res) => {
        notification.error({ message: 'Error fetching picking group!' })
      })
  };

  const getArrayfromString = (details) => {
    if (details) {
      let newString;

      newString = details.split(',')

      return newString.map(function (data) {
        return data.replace('[', '').replace(']', '').replace('"', '').replace('"', '')
      })
    } else {
      return []
    }
  }


  const assignComponentValue = (value, childID, id, item, property) => {

    let currentState;
    const key = childID
    currentState = getArrayfromString(pickingComponents[value]);
    setPickingCombinationValues({ ...pickingCombinationValues, [key]: currentState })
    let valueId = childID.replace("field", "value")
    //making next value null
    updateDataFieldMultiple([],  valueId , id, item, 'values')
    //updating options
    updateDataFieldMultiple(value, childID, id, item, property)

  }

  // const getLocations = () => {
  //   ZenSmartAPI.get(pickingLocationsRoute)
  //     .then((res) => {
  //       setPickingLocations(res.data)
  //     })
  //     .catch((res) => {
  //       notification.error({ message: 'Error fetching picking locations!' })
  //     })
  // };

  const addNewPickingGroup = (value) => {
    if (value.keyCode === 13) {
      const payload = { name: value.target.value }
      ZenSmartAPI.post(createPickingGroups, payload)
        .then((res) => {
          getPickingGroups()
        }).catch((res) => {
          notification.error({ message: res.response.data.message })
        })
    }
  }

  const addNewType = (value) => {
    if (value.keyCode === 13) {
      const payload = { name: value.target.value }
      ZenSmartAPI.post(createPickingComponent, payload)
        .then((res) => {
         getPickingComponents()
        }).catch((res) => {
          notification.error({ message: res.response.data.message })
        })
    }
  }

  

  // const addNewLocation = (value) => {
  //   if (value.keyCode === 13) {
  //     const payload = { name: value.target.value }
  //     ZenSmartAPI.post(createLocations, payload)
  //       .then((res) => {
  //         getLocations()
  //       }).catch((res) => {
  //         notification.error({ message: res.response.data.message })
  //       })
  //   }
  // }

  const addFieldValue = (id) => {

    const newData = pickingCombinations.map((item, key) => {
      const newKey = generateKey()
      return id === item.id
        ? {
          ...item, 'values': (item.values.concat({
            id: `value.${generateKey()}`,
            value: null,
          })), 'fields': (item.fields.concat({
            id: `field.${generateKey()}`,
            value: null,
          }))
        }
        : item

    });
    setPickingCombinations(newData);
  }
  
  const onAddNewRule = () => {
    const newKey = generateKey()
    if (!pickingGroup) {
      notification.error({ message: "Please select picking group first!" })
      return
    }

    setPickingCombinations([
      ...pickingCombinations,
      {
        id: `newRule.${generateKey()}`,
        label: null,
        location: null,
        values: [{
          id: `value.${generateKey()}`,
          value: null,
        }],
        fields: [{
          id: `field.${generateKey()}`,
          value: null,
        }],
      }
    ]);

  }

  const updateDataField = (value, item, property) => {
    const selectedRow = pickingCombinations.findIndex(selectedRow => selectedRow.id === item.id);
    const newData = pickingCombinations.map((item, key) => {
      return (
        key === selectedRow
          ? { ...item, [property]: value }
          : item
      )
    }
    )
    setPickingCombinations(newData);

  }

  const updateDataFieldMultiple = (value, childID, id, item, property) => {
    const selectedRow = pickingCombinations.findIndex(selectedRow => selectedRow.id === item.id);
    const newData = pickingCombinations.map((item, key) => {
      return (
        key === selectedRow
          ? {
            ...item, [property]: item[property].map((data) => {
              return (
                data.id === childID
                  ? { ...data, 'value': value }
                  : data
              )

            })
          }
          : item
      )
    }
    )
    setPickingCombinations(newData);
  }

  const deleteFieldValue = (id) => {
      const newData = pickingCombinations.map((item) => {
        return getFieldsDelete(id,item)    
      });
      setPickingCombinations(newData);
  }  

  const getFieldsDelete = (id,item) => {
    const newId = generateKey()
    if(id === item.id){
      if(item.values.length > 1 && item.fields.length > 1){
        return { ...item, ['values']: item.values.slice(0, -1),
                    ['fields']: item.fields.slice(0, -1) }
      }else if (item.values.length === 1 && item.fields.length === 1){
        return { ...item, ['values']:       [{
          id: `value.${newId}`,
          value: null,
        }], ['fields']:       [{
          id: `field.${newId}`,
          value: null,
        }] }
      }
    }else{
      return item
    }
  };
  

  const getPickingComponents = () => {

    ZenSmartAPI.get(pickingComponentsRoute).then(res => {
      if (res) {
        setPickingComponents(res.data);
      }
    })
    
  }

  const onDeleteRule = (id) => {
      const newData = pickingCombinations.filter((item) => item.id !== id);
      setPickingCombinations(newData);
  }

  useEffect(() => {
    if(process && process.picking_group_id){
      setPickingCombinations([])
      selectPickingGroup(process.picking_group_id)
    }
  },[process]) // eslint-disable-line react-hooks/exhaustive-deps


  

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row style={{ paddingBottom: 15 }}>
        <Col span={6} style={{ padding: 10 }}>
          <StyledFormItem
            label="Picking Group"
            validateStatus={processTextError ? 'error' : ''}
            help={processTextError || ''}
          >
            {getFieldDecorator('picking_group_id', {
              initialValue: "",
              rules: ""
            })(
              <Select
                showSearch
                placeholder="Select Picking Group"
                optionFilterProp="children"
                filterOption={true}
                onChange={(value) => selectPickingGroup(value)}
                onInputKeyDown={(event) => addNewPickingGroup(event)}
              >
                {alphabeticalData(pickingGroupOptions, "text").map(({ id, name }) => {
                  return <Option key={id} value={id} style={{ textTransform: "capitalize" }}>{name}</Option>
                })}
              </Select>
            )}
          </StyledFormItem>
 
        </Col>
      </Row>
      <Table dataSource={pickingCombinations ? pickingCombinations : []} pagination={false} bordered={true}>
        <Column
          title="Label"
          dataIndex="label"
          key="label"
          width={200}
          render={(label, item) => {
            return (
              <div>
                <Input style={(pickingCombinationsValidation.includes(item.id) ? {error} : undefined )} placeholder="Please enter label" onChange={(value) => updateDataField(value.target.value, item, 'label')} value={label}
           />
              </div>
            )
          }}
        />
        <Column
          title="Locations"
          dataIndex="location"
          key="location"
          width={200}
          render={(location, item) => {
            return (
              <Select
                showSearch
                defaultValue={pickingLocations.find(item => item.id === location) ? location : null}
                style={{ width: "100%" }}
                placeholder="Select Location"
                optionFilterProp="children"
                onChange={(value) => updateDataField(value, item, 'location')}
              >
                {alphabeticalData(pickingLocations, "text").map(({ id, warehouse , bin }) => {
                  return <Option key={id} value={id} style={{ textTransform: "capitalize" }}>{warehouse} / {bin}</Option>
                })}
              </Select>
            )
          }}
        />

        <Column
          title="Fields"
          dataIndex="fields"
          key="fields"
          width={200}
          render={(fields, item) => {
            const id = item.id
            return (
              <>
                {item.fields && item.fields.map(data => {
                  return <Select
                    showSearch
                    defaultValue={(data.value !== null ? data.value : '' )}
                    style={{ width: "100%" }}
                    placeholder="Select Field"
                    optionFilterProp="children"
                    onChange={(value) => assignComponentValue(value, data.id, id, item, 'fields')}
                    key={data.id}
                    onInputKeyDown={(event) => addNewType(event)}
                    dropdownStyle={{ marginBottom: '5px' }}
                  >
                    {Object.keys(pickingComponents).map((key, index) => {
                      return (
                        <Select.Option key={key} value={key}>
                          {key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}
                        </Select.Option>
                      )
                    })}
                  </Select>
                })
                }
              </>
            )
          }}
        />

        <Column
          title="Values"
          dataIndex="values"
          key="values"
          width={200}
          render={(values, item) => {
            const id = item.id
            return (
              <>
                {item.values && item.values.map((data, index) => {

                  return <Select
                    showSearch
                    defaultValue={(data.value === null ? undefined : data.value )}
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Select Values"
                    optionFilterProp="children"
                    onChange={(value) => updateDataFieldMultiple(value, data.id, id, item, 'values')}
                    key={data.id}
                    dropdownStyle={{ marginBottom: '5px' }}
                  >
                    {pickingCombinationValues[item.fields[index].id] && pickingCombinationValues[item.fields[index].id].map((data) => {
                      return (
                        <Select.Option key={data} value={data}>
                          {data}
                        </Select.Option>
                      )
                    })}
                  </Select>
                })
                }
              </>
            )
          }
          }
        />

        <Column
            title="Actions"
            dataIndex="fieldActions"
            key="fieldActions"
            width={300}
            render={(values, item) => {
                const id = item.id
                return (
                    <div style={{display: "flex",
                        justifyContent: "space-between"}}>
                          <RowAddButton type="primary" onClick={() => addFieldValue(id)} >Add</RowAddButton>
                          <RowAddButton type="primary" onClick={() => deleteFieldValue(id)} >Remove Last Setting</RowAddButton>
                         <RowAddButton type="danger" style={{ backgroundColor : "rgb(232, 74, 80)"}} onClick={() => onDeleteRule(id)} >Remove Pigeonhole</RowAddButton>
                    </div>
                )
            }
            }
        />
      </Table>
      <ButtonContainer>
        <Row type="flex" align="middle" justify="space-between">
          <Col span={4}>
          <AddButton onClick={onAddNewRule} style={{margin : "0 0 10px 0"}}>
            <Icon type="plus-circle" theme="filled" />
            Add Pigeonhole
          </AddButton>
          </Col>
          <Col span={20}>
          <Row type="flex" justify="end" align="middle">
          <Button style={{ marginRight: "5px"}} onClick={hideModal}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </Button>
          </Row>
          </Col>
        </Row>
      </ButtonContainer>

      <StyledFormItem
        validateStatus={processTextError ? 'error' : ''}
        help={processTextError || ''}
      >
        {getFieldDecorator('required_verification', {
          valuePropName: "checked",
          initialValue: process && process.required_verification,
        })(<Checkbox>Requires Verification?</Checkbox>
        )}
      </StyledFormItem>
      <StyledFormItem
                label="Wait Threshold"
                validateStatus={processTextError ? 'error' : ''}
                help={processTextError || ''}
                style={{ width: "20%" }}
                    
            >
                {getFieldDecorator('wait_threshold', {
                    initialValue: process && process.wait_threshold,
                    rules : processTextRules,
                })(<Number min={0} placeholder="Wait Threshold" />)}
        </StyledFormItem> 
  
    </Form>
  )
}

const PickForm = Form.create({ name: 'PickForm Item Form' })(EditForm)

function PickingModal(props) {
  const [pickingCombinations, setPickingCombinations] = useState([])
  const [pickingCombinationsValidation, setPickingCombinationsValidations] = useState([])
  const [pickingCombinationValues, setPickingCombinationValues] = useState([])
  const [pickingGroup, setPickingGroup] = useState(null)
  const { process, onSave, processID, pickingGroupOptions, pickingComponents, setPickingLocations, pickingLocations, setPickingGroupOptions, newItemType,setPickingComponents, ...modal } = props
  
  return (
    <Modal {...modal}
      width={1300}
    >
      <PickForm
        process={process}
        newItemType={newItemType}
        onSave={onSave}
        processID={processID}
        pickingGroupOptions={pickingGroupOptions}
        pickingComponents={pickingComponents}
        pickingLocations={pickingLocations}
        setPickingGroupOptions={setPickingGroupOptions}
        pickingCombinations={pickingCombinations}
        setPickingCombinations={setPickingCombinations}
        hideModal={modal.onCancel}
        pickingCombinationValues={pickingCombinationValues}
        setPickingCombinationValues={setPickingCombinationValues}
        pickingGroup={pickingGroup}
        setPickingGroup={setPickingGroup}
        setPickingLocations={setPickingLocations}
        pickingCombinationsValidation={pickingCombinationsValidation}
        setPickingCombinationsValidations={setPickingCombinationsValidations}
        setPickingComponents={setPickingComponents}
      />
    </Modal>
  )
}

export default PickingModal