export default {
  VARIANT: {
    ITEM: 'item',
    CONTAINER: 'container'
  },

  TYPE: {
    EDGE: 'Edge',
    AUTOMATED: 'Automated Stage',
    BARCODE: 'Barcode Stage',
    PACK_TYPE: 'Pack Type',
    FIELD_SWITCH: 'Field Switch',
    DISPATCH_INIT: 'Dispatch Init',
    BUCKET: 'Bucket',
    PROVIDER: 'Provider',
    IMPOSITION: 'Imposition',
    PRE_DISPATCH: 'Pre Dispatch',
    DISPATCH: 'Dispatch',
    BLOCK: 'Block',
    TRANSPARENT: 'transparent',
    QUEUED: 'Queued for Batching',
    READY: 'Ready for Release',
    MATCH_OUT: 'Match Out',
    PICKING: 'Picking',
  },

  TRIGGER: {
    EMAIL: 'sendEmail',
    FIELD: 'fieldUpdate',
    CALLOUT: 'callout',
    ADD_COST: 'addCost',
  }
}