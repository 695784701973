import React, { Children, cloneElement, useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { NavLink, matchPath, useLocation } from 'react-router-dom'
import { Row, Icon as AntIcon } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { GET_TITLE } from '../../store/actions/globalTypes'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { Icon } from 'components/zensmart-design-system'
import {
  SettingOutlined, PartitionOutlined
} from '@ant-design/icons';
import * as _ from 'lodash';
const MenuIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
`
const IconSettingOutlined = styled(SettingOutlined)`
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
`
const IconPartitionOutlined = styled(PartitionOutlined)`
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
`

const Menu = styled.nav`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

Menu.Link = MenuLink
Menu.Group = MenuGroup

const linkStyles = css`
  width: 100%;
  cursor: pointer;
  padding: 1rem;

  border-right: 5px solid transparent;
  color: ${color.gray};

  display: flex;
  align-items: center;

  transition-duration: 0.3s;

  &:hover {
    color: ${color.blue.dark};
  }
`

const Link = styled(NavLink)`
  ${linkStyles}
  text-transform : capitalize;
  &.active {
    color: ${color.blue.dark};
    border-right-color: ${color.nav.selected};
    background-color: ${color.blue.light};
  }
`
const scrollToTop = e => {
  let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, currentScroll - (currentScroll / 3));
  }
}

function MenuLink(props) {
  const { icon, text, permission, ...menuLink } = props
  const globalData = useSelector(state => state.global)
  const userData = useSelector((state) => state.user.details);
  const dispatch = useDispatch()

  const capitalizeHeader = text.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })
  if (props.to === useLocation().pathname && globalData.title !== capitalizeHeader) {
    dispatch({
      type: GET_TITLE,
      payload: capitalizeHeader
    })
  }

  if (userData === null) {
    return null;
  }

  if (_.get(userData, 'hidden_pages', []).includes(text))  {
    return null;
  }

  if (userData.should_authorize && permission && !userData.permissions.find(p => p === permission)) {
    return null;
  }

  const menuIcon = icon ? <MenuIcon icon={icon} /> : null
  return <Link {...menuLink} onClick={scrollToTop}>{menuIcon} {text}</Link>
}

const GroupButton = styled.button`
  ${linkStyles}
  font-size: 14px;
  justify-content: space-between;
  color: ${props => props.isVisible ? color.blue.dark : null};
`

const LinksContainer = styled.div`
  display: ${props => props.isVisible ? 'block' : 'none'};
`

function MenuGroup(props) {
  const { icon, text, scope, children, style, ...menuGroup } = props

  const { pathname } = useLocation()
  const match = matchPath(pathname, { path: scope })
  const pathMatches = match !== null
  const [isOpen, setOpen] = useState(pathMatches)

  const toggleOpen = useCallback(() => {
    setOpen(!isOpen)
  }, [isOpen])

  if (children && children.length == 0) {
    return null;
  }

  const links = Children.map(children, child => {
    const injectedProps = {
      style: {
        paddingLeft: style && style.paddingLeft
          ? style.paddingLeft + 16
          : 50
      }
    }
    if (child) {
      switch (child.type) {
        case MenuGroup: {
          injectedProps.scope = scope + child.props.scope
          return cloneElement(child, injectedProps)
        }

        case MenuLink: {
          injectedProps.to = scope + child.props.to
          return cloneElement(child, injectedProps)
        }

        default:
          return child
      }
    }
  })

  const menuIcon = icon === "settings" ? <IconSettingOutlined style={{ fontSize: "20px" }} /> : icon ? <MenuIcon icon={icon} /> : null
  return (
    <div {...menuGroup}>
      <GroupButton isVisible={pathMatches || isOpen} onClick={toggleOpen} style={style}>
        <Row>{menuIcon} {text}</Row>
        <AntIcon type={isOpen ? 'up' : 'down'} />
      </GroupButton>

      <LinksContainer isVisible={isOpen}>{links}</LinksContainer>
    </div>
  )
}

export default Menu