import React, { useState, useEffect } from 'react';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Area, ResponsiveContainer } from 'recharts';
import { Row, Col, Table } from "antd";
import styled, { createGlobalStyle } from 'styled-components'


const TableStyle = createGlobalStyle`
.ant-table-thead{
    display:none;
}
td{
    font-size : 12px;
    text-align : center
}

.recharts-tooltip-item:nth-child(2) {
    color : black !important;
}

tr:first-child {
    font-weight : bold;
  }
td:first-child {
    font-weight : bold;
    text-align : left;
  }
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 14px;
  }  
`
const Dot = styled.span`
  height: 12px;
  width: 12px;
  margin-right : 10px;
  border-radius: 50%;
  display: inline-block;
`

const ItemValues = {
    four_d: { color: "#c4342c", text: "4d+ Backlog" },
    three_d: { color: "#fadb4e", text: "3d Backlog" },
    two_d: { color: "#58b53a", text: "0-2d Backlog" },
    target: { color: "#9ea99a", text: "Targets" },
    actual_shipped: { color: "#6d9cb2", text: "Actual Shipped" },
    actual_finished: { color: "#d0824f", text: "Actual Finished" }
}

const TargetChart = (props) => {

    const { datas, chartData } = props
    const [columns, setColumn] = useState([])
    const [tableDatas, setTableDatas] = useState([])
    const [getBottomAxisText, setGetBottomAxisText] = useState(null);

    useEffect(() => {
        if (datas.length > 0) {
            const addWidth = 30 + datas.length
            console.log(addWidth);
            const dataobj = datas.map(data => {
                return {
                    dataIndex: data.name,
                    key: data.name,
                    width: datas.length > 1 ? 90 : 500,

                }
            })
            dataobj.unshift({
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: datas.length > 10 ? (datas.length * 9.8) - addWidth : datas.length > 4 ? (datas.length * 9.8) - addWidth + 20 : 50,
                render: (text, key) => <a style={{ textAlign: "left" }}> {text !== '' && <Dot style={{ backgroundColor: ItemValues[text].color, verticalAlign: 'middle', marginTop: -3 }}></Dot>}
                    {text !== '' && ItemValues[text].text}</a>,
            })
            setColumn(dataobj)
            const setDataTable = []
            Object.keys(datas[0]).map((data, key) => {
                const tableData = { name: key === 0 ? '' : data }
                datas.map(item => {
                    tableData[item.name] = item[data]
                    return item
                })
                setDataTable.push(tableData)
                return data
            })

            setTableDatas(setDataTable)
            setGetBottomAxisText(document.getElementsByClassName("recharts-cartesian-axis-ticks")[0]);

        }
    }, [datas]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (getBottomAxisText) {
            getBottomAxisText.classList.add("hide-div");
        }
    }, [getBottomAxisText]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div>
                <TableStyle />
                <Row type="flex">
                    <Col span={1}>
                    </Col>
                    <Col span={23}>
                        <ResponsiveContainer width={datas.length > 10 ? 2200 : 1437} height={500}>
                            <ComposedChart data={chartData}
                                margin={{ top: 5, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Area type="monotone" dataKey="four_d" name="4d+ Backlog" stroke="#c4342c" fill="#c4342c" fillOpacity="1" />
                                <Area type="monotone" dataKey="three_d" name="3d Backlog" stroke="#fadb4e" fill="#fadb4e" activeDot={{ r: 8 }} fillOpacity="1" />
                                <Area type="monotone" dataKey="two_d" name="0-2d Backlog" stroke="#58b53a" fill='#58b53a' fillOpacity="1" />
                                <Line type="monotone" dataKey="target" name="Targets" stroke="#9ea99a" strokeWidth={5} />
                                <Line type="monotone" dataKey="actual_shipped" name="Actual Shipped" stroke="#6d9cb2" strokeWidth={5} />
                                <Line type="monotone" dataKey="actual_finished" name="Actual Finished" stroke="#d0824f" strokeWidth={5} />
                            </ComposedChart>
                        </ResponsiveContainer>

                    </Col>
                </Row>

                <Table style={{ marginTop: "-35px", width: datas.length > 10 ? 2300 : 1500 }} columns={columns} pagination={false} dataSource={tableDatas} bordered />
            </div>
        </>
    );
}


export default TargetChart