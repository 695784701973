import React, { useEffect, useState } from 'react';
import { useMenu } from 'context/menuContext'
import { useHistory } from 'react-router-dom'
import { useScreenWidth } from 'hooks/useScreenWidth';
import { Header, Title } from 'components/primaries';
import { ZenSmartAPI } from '../../../utils';
import PermissionModal from './PermissionModal';
import RolesManageModal from './RolesManageModal';
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'

const Operators = () => {
  const [isMenuOpen, setMenuOpen] = useMenu()
  const [permissions, setPermissions] = useState([]);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [showRolesManageModal, setShowRolesManageModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const screenWidth = useScreenWidth();
  const history = useHistory()

  useEffect(() => {
    setMenuOpen(true)
    getAllPermissions();
  }, []);

  const getAllPermissions = () => {
    ZenSmartAPI('/api/v1/permissions/all').then(response => {
      response = response.data.data;
      setPermissions(response.map(permission => {
        permission.loading = false;
        return permission;
      }));
    });
  };

  const managePermission = (row, refreshData) => {
    setSelectedUserId(row.id)
    setShowPermissionModal(true)
  }

  const manageRoles = () => {
    setShowRolesManageModal(true)
  }

  return (
    <>
      <Header>
        <Title>People {">"} Operators</Title>
      </Header>
      <Panel title="OPERATORS">
        <DataListPage model="users" select={['id', 'name', 'created_at', 'updated_at']} link={"app/data-view/users/"} pageSize={50}
          customizedButton={
            [{
              buttonLabel: "Update All Permissions",
              buttonType: "primary",
              buttonAction: "normal",
              actionFunction: () => history.push('/app/people/permissions'),
              offset: 8,
              span: 4
            },
            {
              buttonLabel: "Manage Roles",
              buttonType: "primary",
              buttonAction: "normal",
              actionFunction: manageRoles,
              offset: 0,
              span: 3
            }
            ]
          }
          addListButton={{
            buttonLabel: "Add Operators",
            actionApi: "/api/v1/data-api/users",
            offset: 0,
            span: 3,
          }}
          actionButtons={
            [{
              buttonLabel: "Manage Permission",
              buttonType: "primary",
              buttonAction: "normal",
              actionFunction: managePermission,
              reloadData: true
            },
            ]
          }
        />
        <PermissionModal
          visible={showPermissionModal}
          permissions={permissions}
          userId={selectedUserId}
          onClose={() => {
            setSelectedUserId(null);
            setShowPermissionModal(!showPermissionModal);
          }}
        />
        <RolesManageModal
          visible={showRolesManageModal}
          permissions={permissions}
          onClose={() => setShowRolesManageModal(!showRolesManageModal)}
        />
      </Panel>
    </>
  );
};

export default Operators;
