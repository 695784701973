import React, { useState, useEffect } from 'react'
import { Panel } from 'components/primaries'
import { Row, Col, Input } from 'antd'
import { ZenSmartAPI } from 'utils'
import { modelDefinitionRoute } from "utils/apiRoutes"
import _ from "lodash"
import debounce from 'lodash/debounce';
import ConfigurationSortOrder from './ConfigurationSortOrder'
import ConfigurationFilters from './ConfigurationFilters'


const Configuration = (props) => {
    const { configurations, setConfigurations, loading, setLoading, fetchConfigurations, getOneConfiguration, setOneConfiguration, updateOneConfiguration,
        oneConfiguration, configurationInitData } = props

    const [fieldSelectValues, setFieldSelectValues] = useState(null)

    const onSearchField = debounce((value, prefix, inputType) => {
        ZenSmartAPI.get(modelDefinitionRoute(`${prefix}.`), {})
            .then((res) => {
                const includedFieldValues = res.data.queryable_paths.length > 0 ? res.data.data.filter(item => res.data.queryable_paths.includes(item.path)) : res.data.data
                setFieldSelectValues(includedFieldValues)
            })
    });

    useEffect(() => {
        if (oneConfiguration.id) {
            onSearchField("", "lines")
        }
    }, [oneConfiguration]) // eslint-disable-line react-hooks/exhaustive-deps
    return <div>
        <h2 style={{ paddingBottom: 10 }}>Configuration Name</h2>
        <Row type="flex" align="middle">
            <Col span="24">
                <Input value={oneConfiguration.name} onChange={(e) => setOneConfiguration({ ...oneConfiguration, name: e.target.value })}
                    onKeyDown={(e) => updateOneConfiguration(oneConfiguration.id, e)} />

            </Col>
            <Col span="24">
                <br />
                <br />
                <h2 style={{ paddingBottom: 10 }}>CURRENT SORT ORDER</h2>
                <ConfigurationSortOrder
                    configurations={configurations}
                    oneConfiguration={oneConfiguration}
                    setConfigurations={setConfigurations}
                    getOneConfiguration={getOneConfiguration}
                    fetchConfigurations={fetchConfigurations}
                    loading={loading}
                    setLoading={setLoading}
                    fieldSelectValues={fieldSelectValues}
                    configurationInitData={configurationInitData}
                />
                <br />
                <h2 style={{ paddingBottom: 10 }}>CURRENT FILTERS</h2>
                <ConfigurationFilters
                    configurations={configurations}
                    oneConfiguration={oneConfiguration}
                    setConfigurations={setConfigurations}
                    getOneConfiguration={getOneConfiguration}
                    fetchConfigurations={fetchConfigurations}
                    loading={loading}
                    setLoading={setLoading}
                    configurationInitData={configurationInitData}
                    fieldSelectValues={fieldSelectValues}

                />
            </Col>

        </Row>

    </div>
}

export default Configuration