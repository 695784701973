import React, { useState, useEffect , useRef } from "react";
import styled from "styled-components";
import { Libraries } from "../../config/Imposition";
import { Droppable, Draggable } from "react-beautiful-dnd";
import DragItem from "components/zensmart-design-system/components/Draggable/DragItem";
import { getRecipeBlacklistRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'
import { logError } from "utils/logging";

const LibraryContainer = styled.div`
  height: ${(props) => (props.height >= 0 ? `${props.height}px` : '500px')};
  margin-right: 10px;
  overflow-x: hidden;
  min-height: 500px;
`;

const Library = (props) => {
  const elementRef = useRef(null);
  const [availableHeight, setAvailableHeight] = useState(500);
  const [libraries, setLibraries] = useState([]);

  useEffect(() => {
    function updateAvailableHeight() {
      const element = elementRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        setAvailableHeight(rect.bottom);
      }
    }
    window.addEventListener('resize', updateAvailableHeight);
    updateAvailableHeight(); // Initial calculation
    return () => {
      window.removeEventListener('resize', updateAvailableHeight);
    };
  }, []); // Empty dependency array means this effect runs only once on component mount

  const getBlacklist = () => {
    ZenSmartAPI.get(getRecipeBlacklistRoute)
    .then((res) => {
      const blacklist = res.data?.blacklist || []
      let filteredLibraries = Libraries;
      if (blacklist.length >= 1) {
        filteredLibraries = Libraries.filter(recipe => !blacklist.includes(recipe.id));
      }
      setLibraries(filteredLibraries)
    })
    .catch((err) => { logError(err, true) })
  }

  /**
   * Filter Recipe Libraries
   */
  useEffect(() => {
    getBlacklist()
    // eslint-disable-next-line
  }, [])

  return (
    <LibraryContainer ref={elementRef} height={availableHeight}>
      <Droppable droppableId="Library" isDropDisabled>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {libraries.sort((previous, current) => {
              if (previous.label < current.label) {
                return -1;
              }
              if (previous.label > current.label) {
                return 1;
              }
              return 0;
            }).map((value, index) => {
              return (
                <Draggable draggableId={value.id} index={index} key={value.id}>
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <DragItem id={index} name={value.label}></DragItem>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </LibraryContainer>
  );
};

export default Library;
