import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input, Modal, Form, Spin } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import _ from "lodash"
import moment from 'moment'

import { ZenSmartAPI } from 'utils'
import {
    getSingleLocationDataRoute,
    bomPartsRoute,
    getAllBomLocationsRoute,
    getSingleLocationPartsDataRoute,
    getAllSingleLocationPartsDataRoute,
    getHistoryLocationDataRoute
} from "utils/apiRoutes"

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
  padding: 10px;

`
function EditForm(props) {
    const {
        form,
        singleLocationDetails,
        updateLocation,
        setUpdateLocation,
        fetchInitData,
        setsingleLocationDetails,
        setloading,
        getLocationHistory
    } = props

    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields
    } = form

    const [errors, setErrors] = useState({})

    const handleSubmit = e => {
        e.preventDefault()
        validateFields((err, values) => {
            if (!err) {
                const payload = getFieldsValue()
                setloading(true)
                if (singleLocationDetails.id) {
                    ZenSmartAPI.put(getSingleLocationDataRoute(singleLocationDetails.id), payload)
                        .then((res) => {
                            setErrors({})
                            fetchInitData()
                            setloading(false)
                            setsingleLocationDetails(res.data.data)
                            getLocationHistory()
                            notification.success({ message: "Bom Location successfully updated!" })
                        }).catch((res) => {
                            setloading(false)

                            if (_.get(res, 'response.status') === 422) {
                                setErrors(_.get(res, 'response.data.errors'))
                                notification.error({
                                    message: _.get(res, 'response.data.message'),
                                });
                            }
                        })
                }
                else {
                    ZenSmartAPI.post(getAllBomLocationsRoute, payload)
                        .then((res) => {
                            setUpdateLocation(res.data.data.id)
                            setErrors({})
                            fetchInitData()
                            setloading(false)
                            setsingleLocationDetails(res.data.data)
                            getLocationHistory()
                            notification.success({ message: "Bom Part successfully added!" })
                        }).catch((res) => {
                            if (_.get(res, 'response.status') === 422) {
                                setloading(false)
                                setErrors(_.get(res, 'response.data.errors'))
                                notification.error({
                                    message: _.get(res, 'response.data.message'),
                                });
                            }
                        })
                }
            }
        })

    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Col span={24}>
                    <Form.Item label="Warehouse"
                        validateStatus={errors['warehouse'] ? 'error' : undefined}
                        help={errors['warehouse'] ? errors.warehouse[0] : undefined}
                    >
                        {getFieldDecorator(`warehouse`, {
                            initialValue: singleLocationDetails.warehouse ? singleLocationDetails.warehouse : null,
                        })(
                            <Input />,
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Bin"
                        validateStatus={errors['bin'] ? 'error' : undefined}
                        help={errors['bin'] ? errors.bin[0] : undefined}
                    >
                        {getFieldDecorator(`bin`, {
                            initialValue: singleLocationDetails.bin ? singleLocationDetails.bin : null,
                        })(
                            <Input />,
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Type"
                        validateStatus={errors['type'] ? 'error' : undefined}
                        help={errors['type'] ? errors.type[0] : undefined}
                    >
                        {getFieldDecorator(`type`, {
                            initialValue: !singleLocationDetails.id ? "primary" : singleLocationDetails.type ? singleLocationDetails.type : null,
                        })(
                            <Select>
                                <Select.Option value="primary">Primary</Select.Option>
                                <Select.Option value="floor">Floor</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <p style={{ textAlign: "right" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Save Changes
                    </Button>

                </p>
            </Form>
        </>
    )
}

function EditPartsForm(props) {
    const {
        form,
        data,
        partsDetails,
        singleLocationDetails,
        index,
        partsId,
        getInventories,
        setloading,
        getLocationHistory
    } = props

    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields,
        resetFields
    } = form

    const [errors, setErrors] = useState({})
    const [filteredParts, setfilteredParts] = useState([])

    const deleteInventoryPart = () => {
        ZenSmartAPI.delete(getSingleLocationPartsDataRoute(singleLocationDetails.id, data.id))
            .then((res) => {
                getInventories()
                notification.success({ message: "Inventory successfully deleted!" })
            }).catch((res) => {
                if (_.get(res, 'response.status') === 422) {
                    setErrors(_.get(res, 'response.data.errors'))
                    notification.error({
                        message: _.get(res, 'response.data.message'),
                    });
                }
            })
    }

    const handleSubmit = e => {
        e.preventDefault()
        validateFields((err, values) => {
            if (!err) {
                const payload = getFieldsValue()
                // for updating part
                payload.location_id = singleLocationDetails.id

                if (payload.max_level == "") {
                    payload.max_level = null
                }
                if (payload.min_level == "") {
                    payload.min_level = null
                }
                setloading(true)
                if (data.id) {
                    ZenSmartAPI.put(getSingleLocationPartsDataRoute(singleLocationDetails.id, data.id), payload)
                        .then((res) => {
                            notification.success({ message: "Inventory successfully updated!" })
                            setErrors({})
                            setloading(false)
                            getLocationHistory()
                        }).catch((res) => {
                            if (_.get(res, 'response.status') === 422) {
                                setloading(false)
                                setErrors(_.get(res, 'response.data.errors'))
                                notification.error({
                                    message: _.get(res, 'response.data.message'),
                                });
                            }
                        })
                }
                // for adding new part
                else {
                    ZenSmartAPI.post(getAllSingleLocationPartsDataRoute(singleLocationDetails.id), payload)
                        .then((res) => {
                            getInventories()
                            setloading(false)
                            resetFields()
                            setErrors({})
                            getLocationHistory()
                            notification.success({ message: "Inventory successfully added!" })
                        }).catch((res) => {
                            setloading(false)
                            if (_.get(res, 'response.status') === 422) {
                                setErrors(_.get(res, 'response.data.errors'))
                                notification.error({
                                    message: _.get(res, 'response.data.message'),
                                });
                            }
                        })
                }
            }
        })

    };
    const odd = index % 2
    const part = data.part_id ? partsDetails.find(item => item.id === data.part_id) : null

    useEffect(() => {
        setfilteredParts(partsDetails.filter(item => !partsId.includes(item.id)))
    }, [partsId, data]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row type="flex" align="middle" style={{ paddingLeft: 10, background: odd ? "#f1f3ff" : undefined }} >
                    <Col span={6} style={{ padding: 5, paddingTop: 30 }}>
                        {part ?
                            <Form.Item
                                validateStatus={errors['part_id'] ? 'error' : undefined}
                                help={errors['part_id'] ? errors.part_id[0] : undefined}>
                                {getFieldDecorator(`part_id`, {
                                    initialValue: data.part_id,
                                })(
                                    <p style={{ fontWeight: "bold", lineHeight: 1.5 }}>{part.code} - {part.description}</p>)}
                            </Form.Item>
                            :
                            <Form.Item
                                validateStatus={errors['part_id'] ? 'error' : undefined}
                                help={errors['part_id'] ? errors.part_id[0] : undefined}>
                                {getFieldDecorator(`part_id`, {
                                    initialValue: data.part_id,
                                })(
                                    <Select placeholder={"Please select location"} style={{ width: "100%" }} >
                                        {filteredParts.map(item => {
                                            return <Select.Option style={{ textTransform: "capitalize" }}
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.code} / {item.description}
                                            </Select.Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        }

                    </Col>
                    <Col span={4} style={{ padding: 5, paddingTop: 30 }}>
                        <Form.Item
                            validateStatus={errors['stock'] ? 'error' : undefined}
                            help={errors['stock'] ? errors.stock[0] : undefined}>
                            {getFieldDecorator(`stock`, {
                                initialValue: data.stock,
                            })(
                                <Input type="number" />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ padding: 5, paddingTop: 30 }}>
                        <Form.Item
                            validateStatus={errors['min_level'] ? 'error' : undefined}
                            help={errors['min_level'] ? errors.min_level[0] : undefined}
                        >

                            {getFieldDecorator(`min_level`, {
                                initialValue: data.min_level,
                            })(
                                <Input type="number" />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ padding: 5, paddingTop: 30 }}>
                        <Form.Item
                            validateStatus={errors['max_level'] ? 'error' : undefined}
                            help={errors['max_level'] ? errors.max_level[0] : undefined}
                        >
                            {getFieldDecorator(`max_level`, {
                                initialValue: data.max_level,
                            })(
                                <Input type="number" />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ padding: 5 }} >
                        {data.id ?
                            <Row type="flex">
                                <Col style={{ padding: 5 }}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Update
                                    </Button>
                                </Col>
                                <Col style={{ padding: 5 }}>
                                    <Button
                                        type="danger"
                                        onClick={deleteInventoryPart}
                                    >
                                        Delete
                                    </Button>
                                </Col>
                            </Row>
                            :
                            <Col style={{ padding: 5 }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Add
                                </Button>
                            </Col>
                        }
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const EditUpdateLocationForm = Form.create({
})(EditForm)

const EditUpdatePartsForm = Form.create({
})(EditPartsForm)

const UpdateLocationModal = (props) => {
    const { showModal, setShowModal, updateLocation, setUpdateLocation, fetchInitData } = props
    const [singleLocationDetails, setsingleLocationDetails] = useState({})
    const [singelPartInventoriesDetails, setSinglePartInventoriesDetails] = useState([])
    const [partsDetails, setPartsDetails] = useState([])
    const [loading, setloading] = useState(false)
    const [partId, setPartsId] = useState([])
    const [locationHistory, setLocationHistory] = useState([])
    const fetchSingleLocationData = () => {
        setloading(true)
        getSingleLocation()
        getInventories()
        getAllLocations()
        getLocationHistory()
    }

    const getSingleLocation = () => {
        setloading(true)
        ZenSmartAPI.get(getSingleLocationDataRoute(updateLocation))
            .then((res) => {
                setloading(false)
                setsingleLocationDetails(res.data.data)
            }).catch((res) => {

            })
    }


    const getInventories = () => {
        setloading(true)
        ZenSmartAPI.get(getAllSingleLocationPartsDataRoute(updateLocation))
            .then((res) => {
                setloading(false)
                setSinglePartInventoriesDetails(res.data.data)
            }).catch((res) => {
                setloading(false)
            })
    }

    const getAllLocations = () => {
        setloading(true)
        ZenSmartAPI.get(bomPartsRoute)
            .then((res) => {
                setloading(false)
                setPartsDetails(res.data.data)
            }).catch((res) => {
                setloading(false)
            })
    }

    const getLocationHistory = () => {
        setloading(true)
        ZenSmartAPI.get(getHistoryLocationDataRoute(updateLocation))
            .then((res) => {
                setloading(false)
                setLocationHistory(res.data.data)
            }).catch((res) => {
                setloading(false)
            })
    }

    const refreshState = () => {
        setsingleLocationDetails({})
        setSinglePartInventoriesDetails([])
        // setPartsDetails([])
        setShowModal(false)
    }

    const renderHistory = (data, index) => {
        const odd = index % 2
        return (
            <Row type="flex" align="middle" style={{ paddingLeft: 10, background: odd ? "#f1f3ff" : undefined }} >
                <Col span={6} style={{ padding: 5 }}>
                    <h1>{moment(data.created_at).format("MM/DD/YYYY hh:mm a")}</h1>
                </Col>
                <Col span={5} style={{ padding: 5 }}>
                    <h1>{data.method}</h1>
                </Col>
                <Col span={5} style={{ padding: 5 }}>
                    <h1>{data.user}</h1>
                </Col>
                <Col span={8} style={{ padding: 5 }}>
                    {Object.keys(data.data_after).map(item => {
                        return <h1>"{!data.data_before || (data.data_before && !data.data_after[item]) ? null : data.data_before[item]}" <span style={{ fontSize: 25 }}>&#8594;</span> "{data.data_after[item]}"</h1>
                    })}
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        if (showModal && updateLocation) {
            fetchSingleLocationData()
        }
    }, [showModal, updateLocation]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {

        setPartsId(singelPartInventoriesDetails.map(part => part.part_id))
    }, [singelPartInventoriesDetails]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Modal title={singleLocationDetails.warehouse ? `Edit (${singleLocationDetails.warehouse}/${singleLocationDetails.bin})` : "Add New Location"} visible={showModal} onCancel={setShowModal} width={1500}
            footer={[
                <Button key="back" onClick={() => refreshState()}>
                    Close
                </Button>
            ]}
        >
            <Spin tip="Loading..." spinning={loading}>
                {showModal &&
                    <Row type="flex" justify="center">
                        <Col span={6} style={{ borderRight: "1px solid grey", padding: 10 }}>
                            <EditUpdateLocationForm
                                singleLocationDetails={singleLocationDetails}
                                updateLocation={updateLocation}
                                setUpdateLocation={setUpdateLocation}
                                fetchInitData={fetchInitData}
                                setsingleLocationDetails={setsingleLocationDetails}
                                setloading={setloading}
                                getLocationHistory={getLocationHistory}
                            />
                        </Col>
                        <Col span={18}>
                            <Col span={24} style={{ padding: 10 }} >

                                <Heading>Inventory</Heading>
                                <hr />

                                <div style={{
                                    height: 300,
                                    overflowY: "auto",
                                    position: "relative"
                                }}>
                                    <Row type="flex" align="middle" >
                                        <Col span={6} style={{ padding: 5 }}>
                                            <Heading>Part</Heading>
                                        </Col>
                                        <Col span={4} style={{ padding: 5 }}>
                                            <Heading>Stock</Heading>
                                        </Col>
                                        <Col span={4} style={{ padding: 5 }}>
                                            <Heading>Min</Heading>
                                        </Col>
                                        <Col span={4} style={{ padding: 5 }}>
                                            <Heading>Max</Heading>
                                        </Col>
                                        <Col span={6} style={{ padding: 5 }}>
                                            <Heading>Action</Heading>
                                        </Col>
                                    </Row>
                                    {partsDetails.length > 0 && singelPartInventoriesDetails.map((item, index) => {
                                        return (
                                            <EditUpdatePartsForm
                                                data={item}
                                                partsDetails={partsDetails}
                                                index={index}
                                                singleLocationDetails={singleLocationDetails}
                                                setloading={setloading}
                                                partsId={partId}
                                                getInventories={getInventories}
                                                getLocationHistory={getLocationHistory}
                                            />
                                        )
                                    })}
                                    {updateLocation && showModal &&
                                        <EditUpdatePartsForm
                                            data={{}}
                                            partsDetails={partsDetails}
                                            singleLocationDetails={singleLocationDetails}
                                            partsId={partId}
                                            getInventories={getInventories}
                                            index={singelPartInventoriesDetails.length}
                                            setloading={setloading}
                                            getLocationHistory={getLocationHistory}

                                        />}
                                </div>
                            </Col>
                            <Col span={24} style={{ padding: 10 }} >

                                <Heading>History</Heading>
                                <hr />
                                <div style={{
                                    height: 250,
                                    overflowY: "auto",
                                    position: "relative"
                                }}>
                                    <Row type="flex" align="middle">
                                        <Col span={6} style={{ padding: 5 }}>
                                            <Heading>Date/Time</Heading>
                                        </Col>
                                        <Col span={5} style={{ padding: 5 }}>
                                            <Heading>Method</Heading>
                                        </Col>
                                        <Col span={5} style={{ padding: 5 }}>
                                            <Heading>Operator</Heading>
                                        </Col>
                                        <Col span={8} style={{ padding: 5 }}>
                                            <Heading>Change</Heading>
                                        </Col>
                                    </Row>
                                    {locationHistory.map((item, index) => {
                                        return renderHistory(item, index)
                                    })}
                                </div>
                            </Col>
                        </Col>
                    </Row>
                }
            </Spin>

        </Modal>
    )
}

export default UpdateLocationModal