import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const Svg = styled.svg`
  display: ${props => (props.block ? 'block' : 'inline-block')};
  vertical-align: middle;

  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
`;

const Path = styled.path`
  fill: currentColor;
`;



export const materialIcon = (width, height, block, props) => {

    return (

        <Svg viewBox="0 0 81.000000 110.000000" width={width} height={height} block={block} {...props}>
            <g transform="translate(0.000000,110.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <Path d="M347 1070 c-30 -26 -42 -30 -96 -30 -57 0 -61 -2 -76 -30 -12 -24
       -22 -30 -48 -30 -47 0 -97 -17 -113 -37 -11 -15 -13 -101 -14 -444 0 -416 0
       -427 21 -453 l20 -26 -20 0 c-12 0 -21 -4 -21 -10 0 -7 142 -10 405 -10 263 0
       405 3 405 10 0 6 -9 10 -21 10 l-20 0 20 26 c21 26 21 37 21 453 -1 343 -3
       429 -14 444 -16 20 -66 37 -113 37 -26 0 -36 6 -48 30 -15 28 -19 30 -76 30
       -54 0 -66 4 -96 30 -18 17 -45 30 -58 30 -13 0 -40 -13 -58 -30z m85 -65 c10
       -22 18 -25 59 -25 26 0 54 -3 63 -6 21 -8 21 -50 0 -58 -9 -3 -76 -6 -149 -6
       -73 0 -140 3 -149 6 -9 3 -16 16 -16 29 0 28 17 35 79 35 41 0 49 3 59 25 6
       14 19 25 27 25 8 0 21 -11 27 -25z m-257 -125 l16 -30 214 0 214 0 16 31 c15
       28 19 30 63 27 l47 -3 0 -410 0 -410 -340 0 -340 0 -3 400 c-1 220 0 406 3
       412 2 8 23 13 49 13 40 0 47 -3 61 -30z"/>
                <Path d="M181 662 c-6 -11 -6 -24 -1 -33 8 -11 48 -14 225 -14 177 0 217 3
       225 14 5 9 5 22 -1 33 -9 16 -28 18 -224 18 -196 0 -215 -2 -224 -18z"/>
                <Path d="M180 526 c0 -13 7 -27 16 -30 20 -8 398 -8 418 0 9 3 16 17 16 30 l0
       24 -225 0 -225 0 0 -24z"/>
                <Path d="M187 434 c-4 -4 -7 -18 -7 -31 l0 -23 226 0 225 0 -3 28 -3 27 -216
       3 c-118 1 -218 -1 -222 -4z"/>
                <Path d="M180 301 c-7 -15 -6 -23 6 -36 24 -23 312 -18 327 7 8 12 7 21 -2 32
       -11 13 -39 16 -167 16 -141 0 -154 -2 -164 -19z"/>
            </g>
        </Svg>
    )

}