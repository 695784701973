const saveData = (data) => {
  localStorage.setItem('analytics_storage', JSON.stringify(data));
}

const retrieveData = () => {
  const storedItem = localStorage.getItem('analytics_storage')
  if (storedItem !== 'undefined') {

    return JSON.parse(storedItem);
  }
  return []
}

const deleteData = () => {
  localStorage.removeItem('analytics_storage');
}

export default { saveData, retrieveData, deleteData }