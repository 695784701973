import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Checkbox, Dropdown, Icon, Menu } from 'antd';
import { DataTypes, DateFormatConversion, GetLabel } from 'pages/Performance/utils';
import { CaretDownOutlined, CheckOutlined } from '@ant-design/icons';
import is from "is_js";
import moment from 'moment';
import { arrayReportSort } from 'utils/sortData';
import { useSelector } from "react-redux";
import { LoadTypes } from '../../../Performance/api';


const TableStyling = styled.div`
  width: 100%;
  overflow-x: ${(props) => props.isDashboard ? "auto" : "auto"};
  ${(props) =>
    props.tight &&
    props.isDashboard &&
    `
    font-size: ${ is.existy(props.matrixFontSize) && is.existy(props.refIndex)
                  ? props.matrixFontSize[props.refIndex] + "px"
                  : "9px"
    };
    margin-top: ${props.homePage ? "38px" : "32px"};
    height: ${is.existy(props.parentSize) ? props.parentSize.height - 45 + "px" : "75%"};
  `};

  table {
    min-width: 100%;
  }

  .blank_region {
    background-color: #e9edf9;
    width:auto;
  }
  .x_heading {
    background-color: #dbf2f9;
  }
  .drop_region {
    font-weight: 500;
    min-width: 200px;
    vertical-align: top;
  }
  .drop_region.x_axis {
    background-color: #e9edf9;
  }
  .drop_region.heading {
    min-width: 300px;
  }
  .grand_total {
    background-color: #8798ad;
    color: white;
    font-weight: 700;
    min-width: 94px !important;
    max-width: max-content;
    padding: 5px;
  }
  .matrix-table {
    width: 98%;
    height: 98%;
    table-layout: auto;
    transform: none !important;
    ${(props) =>
      props.homePage ?
      `margin-left : 15px;` : ''
    }

    ${(props) =>
      !props.edit &&
      props.groupX.length > 0 &&
      props.groupY.length > 0
        ? `
            min-width: 100px;
            ${props.isDashboard ? 'transform: none !important;' : ''}
          `
        : ''}
}

    td:not(.y-header) {

      text-align: right !important;

    }
  }
  .group_heading {
    min-width : max-content !important;
    background: #dbe9fd;
    border: 1px solid #bfc4c5;
    cursor: pointer;
    font-weight: bolder;
    ${(props) =>
    props.tight ?
    `
      padding: 2px;
    `:
    `
      padding: 10px;
    `
}
  }
  .group_heading_items {
    background: rgba(144, 199, 204, 0.92);
  }
  .instructions {
    border: 1px solid #d4dadc;
  }
  .subtotal {
    background-color: #f5f7ff;
  }
  .subtotal_heading {
    font-weight: bold;
    padding: 10px;
    white-space: nowrap;
  }
  .y_heading {
  }
  .first_grouping, .second_grouping {
    font-weight: 500;
    border-color: #d4dadc;
    ${(props) =>
    props.tight ?
    `
      padding: 2px;
    `:
    `
      white-space: nowrap;
      padding: 10px;
    `
}
  }
  .first_grouping {
    background-color: #e8e9ec;
  }
  .second_grouping {
    background-color: #f8f8fb;
    min-width : 10px;
  }
  .first_width_column
  {
    min-width : ${(props) => props.maxWidthColumns}px;
  }
  .second_width_column{
    min-width : ${(props) => props.maxWidthColumns}px;
  }
  td {
    border-right: 1px solid #d4dadc;
    border-bottom: 1px solid #d4dadc;
    word-break: normal;
  }
  .total_item {
    background-color: #8798ad;
    color: white;
    font-weight: 700;
  }
  .bottom_total_row {
    background-color: #8798ad;
    color: white;
    font-weight: bold;
  }
  .count_item, .total_count, .total_item {
    text-align: right;
    padding: 3px;
  }

  .summary {
    font-weight: 600;

    .group1 {
      background-color: rgba(192, 212, 241, 0.1);
      padding-left: 10px;
    }
    .group2 {
      background-color: rgba(192, 212, 241, 0.4);
      padding-left: 15px;
    }
  }
`

const { GetDataType, DATATYPE } = DataTypes

const Matrix = ({
  edit,
  dataSource = {},
  groupX = [],
  groupY = [],
  dropY,
  dropX,
  onDragOver,
  editableReport,
  hideDropZone,
  deleteGroupXItem,
  deleteGroupYItem,
  summaryType = 'record count',
  summaryRow = '',
  onAxisDragStart,
  onAxisDragEnd,
  getGroupType,
  setGroupedDate,
  containsGroupedItem,
  dateGroupingOptions = [],
  height,
  groupedDate,
  isPrintReport,
  setIsPrintReport,
  setIsPrintReportLoading,
  reportName,
  tight = false,
  isDashboard,
  parentSize,
  parentRef,
  matrixRef,
  refIndex,
  updateMatrixFont,
  homePage
}) => {
  const [rowCount, setRowCount] = useState(0);
  const [columnCount, setColumnCount] = useState(1);
  const [xColumns, setXColumns] = useState([])
  const [yColumns, setYColumns] = useState([])
  const [groupedData, setGroupedData] = useState({})
  const [topXRow, setTopXRow] = useState([])
  const [bottomXRow, setBottomXRow] = useState([])
  const [yAxis, setYAxis] = useState([])
  const arrayOfSums = []
  const [maxWidthColumns, setMaxWidthColumns] = useState(false)
  const matrixFontSize = useSelector((state) => state.dashboard.matrixFonts);
  const [xColumnGroupType, setXColumnGroupType] = useState('');
  const [yColumnGroupType, setYColumnGroupType] = useState('');

  const sortByType = (data = [], groupedDate, group, xGroup = null) => {
    // get the type of data and sort using specific method

    if (data.length > 0 && group) {
      let type = '';

      if(xGroup) {
        type = xColumnGroupType[xGroup] || '';
      }

      if(!type) {
        type = getGroupType(group);
      }

      switch (GetDataType(type)) {
        case DATATYPE.number:
        case DATATYPE.bool:
          return data.sort((a, b) => numberSort(parseInt(a), parseInt(b)));
        case DATATYPE.time:
          const grouped_date_keys = _.isEmpty(groupedDate) ? [] : Object.keys(groupedDate)
          // get the format of the group if it exists
          const format = grouped_date_keys.includes(group) ? DateFormatConversion(groupedDate[group]) : ''

          return data.sort((a, b) => timeSort(a, b, group, format));
        case DATATYPE.text:
        case DATATYPE.bucket:
          return data.sort((a, b) => stringSort(a, b, group));
        default:
          // return a[item.field].length - b[item.field].length
          return data;
      }
    }
    return data;
  }

  const stringSort = (a, b, field) => {

    const c = a[field] && a[field] !== null ? a[field].toLowerCase() : ""
    const d = b[field] && b[field] !== null ? b[field].toLowerCase() : ""
    return c.localeCompare(d)

  }

  const timeSort = (a, b, field, format = '') => {

    const c = format ? moment(a[field], format) : moment(a[field])
    const d = format ? moment(b[field], format).toString() : b[field]
    return c.isBefore(d) ? -1 : 1;

  }

  const getHeadingOneWidth = (data, columns) => {
    return columns.length + Object.keys(data).length;
  }

  const numberSort = (a, b, field) => a[field] - b[field]

  const stringTypeConverter = (theString, theType) => {
    switch (theType) {
      case DATATYPE.number:
        let numberVal = parseFloat(theString);
        if(isNaN(numberVal)) {
          return null;
        }
        return numberVal
        break;
      case DATATYPE.bool:
        return JSON.parse(theString || null);
      case DATATYPE.time:
      case DATATYPE.text:
      case DATATYPE.bucket:
        return theString === "null" ? null : theString;
      default:
        switch (theString.toLowerCase()) {
          case 'null':
            return null;
            break;
          case 'true':
            return true;
            break;
          case 'false':
            return false;
            break;
          case 'undefined':
            return undefined;
            break;
          default:
            return theString
            break;
        }
    }
  }

  const sumBy = (rows, field) => {
    var sum = 0

    rows.forEach(row => {
      sum += Number(row[field])
    });
    return sum
  }

  const minBy = (rows, field) => {
    var min
    rows.forEach(row => {
      if (min === undefined || Number(row[field]) < min) {
        min = Number(row[field])
      }
    });
    return min
  }

  const maxBy = (rows, field) => {
    var max
    rows.forEach(row => {
      if (max === undefined || Number(row[field]) > max) {
        max = Number(row[field])
      }
    });
    return max
  }

  const averageBy = (rows, field) => {
    var sum = 0
    rows.forEach(row => {
      sum += Number(row[field])
    });
    return parseFloat((sum / rows.length).toFixed(4))
  }

  const getMatrixSummaryData = (
      rows = [],
      summaryType = 'record count',
      summarizationField = ''
  ) => {
    switch (summaryType) {
      case 'sum':
        return (
                   rows.length && summarizationField
               ) ? sumBy(rows, summarizationField) : 0;
      case 'min':
        return (
                   rows.length && summarizationField
               ) ? minBy(rows, summarizationField) : 0;
      case 'max':
        return (
                   rows.length && summarizationField
               ) ? maxBy(rows, summarizationField) : 0;
      case 'average':
        return (
                   rows.length && summarizationField
               ) ? averageBy(rows, summarizationField) : 0;
      default:
        // record count
        return rows.length || 0;
    }
  }

  const modifyFalseyFields = (value) => {
    // modifys the given item for zero and falsey values
    switch (value) {
      case "0":
        return "zero";
      case null:
        return "";
      case "":
        return "empty";
      case false:
        return "false";
      default:
        return value;
    }
  }
  const dateGroupingMenu = (item) => {
    return(
        <Menu>
          {dateGroupingOptions.map(option => {
            return (
                <Menu.Item onClick={() => setGroupedDate(item, option)}>
                  {containsGroupedItem && (option === containsGroupedItem(item)) ? <p style={{ verticalAlign: "baseline" }}>{option}<CheckOutlined /></p> : option}
                </Menu.Item>
            )
          })}
        </Menu>
    )}

  useEffect(() => {
    if (rowCount !== 0 && is.existy(updateMatrixFont)) {
      updateMatrixFont(refIndex, matrixRef.current[refIndex], parentRef.current[refIndex])
    }
  }, [rowCount])

  useEffect(()=> {
    if(!editableReport)
    {
      if(groupX.length === 2)
      {
        var firstColumnWidths = [].slice.call(document.querySelectorAll('td.second_width_column'))
        .map(function (div) { return div.getBoundingClientRect().width; });
      }
      else{
        var firstColumnWidths = [].slice.call(document.querySelectorAll('td.first_width_column'))
        .map(function (div) { return div.getBoundingClientRect().width; });
      }


      var maxWidth = Math.max.apply(null, firstColumnWidths);
      setMaxWidthColumns(maxWidth)
    }
    else{
      setMaxWidthColumns(false)
    }
  }, [editableReport])

  useEffect(() => {
    setMaxWidthColumns(false);
  }, [groupY, groupX])

  useEffect(() => {

    const { x_fields, y_fields, grouped_data } = dataSource

    setXColumns(x_fields ? x_fields : [])
    setYColumns(y_fields ? y_fields : [])
    setGroupedData(grouped_data ? grouped_data : {})
    setColumnCount((x_fields && x_fields.length > 0) ? x_fields.length + 2 : 1)
    setRowCount((y_fields && y_fields.length > 0) ? y_fields.length + 1 : 1)

    const top_x_axis_values = groupX[0] && (
        x_fields && Object.entries(
          _.countBy(sortByType(x_fields, groupedDate, groupX[0], groupX[0]), groupX[0])
        )
    )

    let bottom_x_axis_values = [];
    if(groupX.length == 2) {
      if(top_x_axis_values) {
        let sorted_x_fields = sortByType(x_fields, groupedDate, groupX[1], groupX[1]);
        top_x_axis_values.forEach(([value, count]) => {
          let bottom_x_axis_val = sorted_x_fields.filter((x_field) =>{
            return x_field[groupX[0]] == stringTypeConverter(value, GetDataType(getGroupType(groupX[0])))
          })
          bottom_x_axis_values.push(bottom_x_axis_val);
        })
      }
    } else {
      bottom_x_axis_values = top_x_axis_values;
    }

    setTopXRow(top_x_axis_values)
    setBottomXRow(bottom_x_axis_values)

  }, [dataSource]);
  
  useEffect(() => {
    LoadTypes(groupX).then(res => {
      if(res.data) {
        setXColumnGroupType(res.data);
      }
    });
    LoadTypes(groupY).then(res => {
      if(res.data) {
        setYColumnGroupType(res.data);
      }
    });
  }, []);


  const XColumn = ({
    span = 1,
    rowCount=1,
    children,
    className
  }) => (
      <td
          className={className ? className : ''}
          colSpan={span}
          rowSpan={rowCount}
      >
        {children}
      </td>
  )

  const DropRegion = ({
    columnCount = 0,
    rowCount = 1,
    className,
    onDragOver,
    onDrop,
    type = 'column',
    disable = false
  }) => (
      disable ? (
          <td
              colSpan={columnCount}
              rowSpan={rowCount}
              className={className}
              onDragOver={disable && onDragOver}
              onDrop={disable && onDrop}
          >
            Drop a field here to <br /> create a {type === 'column' ? 'column' : 'row'} grouping.
          </td>
      ) : (
          <td
              colSpan={columnCount}
              rowSpan={rowCount}
              className={className}
          >
            Enter edit mode to drop {type === 'column' ? 'column' : 'row'} grouping.
          </td>
      )
  )

  const group_x_heading_1 = groupX && groupY && groupX.length > 0 && (
      <td
          className='group_heading'
          colSpan={groupX.length === 2 ? getHeadingOneWidth(groupedData, xColumns) : columnCount - 2}
          // colSpan={!editableReport ? (
          //   (groupX.length === 1) ? (columnCount - 2) : (
          //     (groupX.length === 2) ? 1 : (columnCount - 1)
          //   )
          // ) : 1}
      >
        <div
            onDragStart={() => onAxisDragStart && onAxisDragStart(groupX[0])}
            onDragEnd={() => onAxisDragEnd && onAxisDragEnd()}
            draggable
            // style={{display: 'flex', alignItems: 'center'}}
        >
          {GetLabel(groupX[0])}
          {editableReport && <Icon type='delete' style={{marginLeft: '5px'}} onClick={() => deleteGroupXItem && deleteGroupXItem(groupX[0], true)} />}
          {editableReport && getGroupType && GetDataType(getGroupType(groupX[0])) === DATATYPE.time && (
              <Dropdown overlay={dateGroupingMenu(groupX[0])} placement="bottomLeft">
                <CaretDownOutlined />
              </Dropdown>
          )}
        </div>
      </td>
  )

  const group_x_heading_2 = groupX && groupX.length === 2 && (
      <td
          className='group_heading'
      >
        <div
            draggable
            onDragStart={() => onAxisDragStart(groupX[1])}
            onDragEnd={() => onAxisDragEnd()}
            style={{display: 'flex', alignItems: 'center'}}
        >
          {GetLabel(groupX[1])}
          {editableReport && <Icon type='delete' style={{marginLeft: '5px'}} onClick={() => deleteGroupXItem && deleteGroupXItem(groupX[1], true)} />}
          {editableReport && getGroupType && GetDataType(getGroupType(groupX[1])) === DATATYPE.time && (
              <Dropdown overlay={dateGroupingMenu(groupX[1])} placement="bottomLeft">
                <CaretDownOutlined />
              </Dropdown>
          )}
        </div>
      </td>
  )

  const count_or_sum = summaryType === 'record count' || summaryType === 'sum'

  const first_x_axis_values = () => {
    if (groupX && groupX.length > 0 && topXRow) {
      const sortedArr = arrayReportSort(topXRow, "0", groupX[0], groupedDate)
      return sortedArr.map(([xColumn, XColumnCount], index) => {
        const colspan = XColumnCount + (count_or_sum && groupX.length === 2 && groupY.length > 0 ? 1 : 0)
        const spanOne = groupX.length === 1 && !edit
        return (
            <XColumn
            span={index === sortedArr.length - 1 && !spanOne ? colspan : colspan}
                className='first_grouping first_width_column'
            >
              {xColumn !== undefined && (
                  (xColumn === 'true' || xColumn === 'false') ? (
                      <Checkbox checked={xColumn === 'true' ? true : false} disabled={true} />
                  ) : (
                      xColumn === null ? "" : xColumn.toString()
                  )
              )}
            </XColumn>
        )
      })
    }
  }

  const group_titles = groupX && groupX.length == 2 && topXRow && (
      arrayReportSort(topXRow, "0", groupX[0], groupedDate).map(([xColumn, XColumnCount]) => (
          <td
              className="group_heading"
              colSpan={XColumnCount + (count_or_sum && groupX.length === 2 && groupY.length > 0 ? 1 : 0)}
          >
            {GetLabel(groupX[1])}
            {(editableReport && GetLabel(groupX[1]) !== '') && <Icon type='delete' style={{marginLeft: '5px'}} onClick={() => deleteGroupXItem && deleteGroupXItem(groupX[1], true)} />}
            {editableReport && getGroupType && GetDataType(getGroupType(groupX[1])) === DATATYPE.time && (
              <Dropdown overlay={dateGroupingMenu(groupX[1])} placement="bottomLeft">
                <CaretDownOutlined />
              </Dropdown>
          )}
          </td>
      ))
  )

  const second_x_axis_values = groupX && groupX.length === 2 && bottomXRow && (
      arrayReportSort(bottomXRow, "0", groupX[0], groupedDate).map(firstLevel => (
          <>
            {firstLevel.map(xColumn => {
              const thisXColumn = xColumn[groupX[1]]
              const theseRows = (
                  <XColumn span={1} rowCount={editableReport && groupY.length == 1 ? 2 : 1} className='second_grouping second_width_column'>
                    {thisXColumn !== undefined && (
                        (_.isBoolean(thisXColumn)) ? (
                            <Checkbox checked={thisXColumn} disabled={true} />
                        ) : (
                            thisXColumn === null ? (
                                ""
                            ) : thisXColumn.toString()
                        )
                    )}
                  </XColumn>
              )
              return (
                  <>
                    {theseRows}
                  </>
              )
            })}
            {count_or_sum && groupX.length === 2 && groupY.length > 0 && (
                <td rowSpan={editableReport && groupY.length == 1 ? 2 : 1} className='subtotal subtotal_heading'>Subtotal</td>
            )}
          </>
      ))
  )

  const group_y_headings = (
    arrayReportSort(Object.values(groupY), "0", groupY[0],groupedDate).map(yHeader => (
          <td
              className='group_heading y_heading'
          >
            <div
                draggable
                onDragStart={() => onAxisDragStart(yHeader)}
                onDragEnd={() => onAxisDragEnd()}
                style={{ display: 'flex', alignItems: 'center', width: 'max-content'}}
            >
              {GetLabel(yHeader)}
              {editableReport && <Icon type='delete' style={{marginRight: '5px'}} onClick={() => deleteGroupYItem && deleteGroupYItem(yHeader, true)} />}
              {editableReport && getGroupType && GetDataType(getGroupType(yHeader)) === DATATYPE.time && (
                  <Dropdown overlay={dateGroupingMenu(yHeader)} placement="bottomLeft">
                    <CaretDownOutlined />
                  </Dropdown>
              )}
            </div>
          </td>
      ))
  )

  const blank_region = (
      <td
          colSpan={groupY && editableReport ? (
              groupY.length > 0 ? 2 : 1
          ) : (
                       groupY.length
                   )}
          rowSpan={!editableReport ? 1 : (
              groupX && groupY && (
                  groupX.length === 0 || (groupX.length === 1 && groupY.length !== 0) || (groupX.length === 2 && groupY.length === 2) ? 1 : 2
              )
          )}
          className='blank_region'
      >
        {(summaryRow && summaryType) ? (
            `Summarised by ${summaryType.toUpperCase()}${summaryType !== 'record count' ? ` of ${GetLabel(summaryRow)}` : ''}`
        ) : ''}
      </td>
  )

  const instructions_data = xColumns && (
      <td
          colSpan={groupY.length === 1 ? 2 + xColumns.length + 1 : 2 + xColumns.length}
          className='instructions'
      >
      </td>
  )

  const grand_total = count_or_sum && (
      <td
          className='grand_total'
          rowSpan={editableReport ? (
            (groupX.length === 2 && groupY.length === 1) ? 5 : groupX.length === 2 ? 4 : 2
            ) : 4}
      >
        Grand Total
      </td>
  )

  const x_heading = groupX && groupY && (
      <>
        <tr className='first_grouping'>
          {blank_region}
          {groupX.length === 0 ? (
              <DropRegion
                  disable={editableReport}
                  columnCount={columnCount}
                  className='drop_region heading x_axis'
                  onDragOver={onDragOver}
                  onDrop={dropX}
              />
          ) : (
               !editableReport && (groupY.length === 1 && groupX.length === 1) ? (
                   group_x_heading_1
               ) : (
                   group_x_heading_1
               )
           )}
          {grand_total}
        </tr>
        {/* Single and multi row */}
        <tr>
          {(groupX.length !== 0 && groupX.length !== 1) && !(!editableReport && (groupY.length === 1 && groupX.length === 1)) && (
              <>
                {(!edit || groupY.length === 2) && blank_region}
                {(editableReport || groupX.length === 2) && first_x_axis_values()}
              </>
          )}
          {
            (editableReport && groupX.length == 1) && (
              <>
                {blank_region}
                {first_x_axis_values()}
              </>
            )
          }
        </tr>
        {/* Second row with titles */}
        {groupX.length !== 0 && (
            <tr>
              {blank_region}
              {group_titles}
            </tr>
        )}

        {(groupX.length > 0) && (
            <>
              {groupX.length === 1 && (
                  editableReport ? (
                      <tr>
                        {group_y_headings}
                        {groupY && groupY.length === 1 && (
                            <DropRegion
                                disable={editableReport}
                                columnCount={1}
                                rowCount={rowCount}
                                className='drop_region heading'
                                onDragOver={onDragOver}
                                onDrop={dropY}
                                type='row'
                            />
                        )}
                        <DropRegion
                            disable={editableReport}
                            columnCount={columnCount}
                            className='drop_region heading x_axis'
                            onDragOver={onDragOver}
                            onDrop={dropX}
                        />
                      </tr>
                  ) : (
                      <tr>
                        {!editableReport && group_y_headings}
                        {first_x_axis_values()}
                      </tr>
                  )

              )}
              {groupX.length === 2 && (
                  <tr>
                    {groupY && (!editableReport || groupY.length === 2) && group_y_headings}
                    {second_x_axis_values}
                  </tr>
              )}
            </>
        )}
      </>
  )

  const getGrandTotalCount = (data) => {
    let thisDataCount = 0;
    if(typeof data == 'object') {
     let keys = Object.keys(data);
     if(keys.length > 0) {
       keys.forEach(i => {
         thisDataCount += data[i];
       });
     }
    } else if(!isNaN(data)) {
     thisDataCount = data;
    }
    
    return thisDataCount;
  }

  const present_data = (
      <>
        {groupY &&
        editableReport &&
        !(
            (groupY.length === 1 && groupX.length === 1) ||
            (groupY.length === 2 && groupX.length === 1) ||
            (groupY.length === 2 && groupX.length === 2)
        ) &&
        (
            <tr>
              {!(groupY.length === 0) && group_y_headings}
              {!(groupX.length === 0 && groupY.length === 2) && (
                  <DropRegion
                      disable={editableReport}
                      columnCount={1}
                      rowCount={rowCount}
                      className='drop_region heading'
                      onDragOver={onDragOver}
                      onDrop={dropY}
                      type='row'
                  />
              )}
              {/* {groupX.length == 1 ? instructions_data : grand_total} */}
            </tr>
        )}

        {yColumns && yColumns.length > 0 && groupY.length > 0 && (
            arrayReportSort(Object.entries(
                _.countBy(sortByType(yColumns, groupedDate, groupY[0]), groupY[0])
            ), "0", groupY[0],groupedDate).map(([uniqueYColumn, uniqueYColumnCount], uniqueYColumnIndex) => {
              const thisSubtotalCount = [];
              let convertedString = '';
              if(yColumnGroupType && yColumnGroupType[groupY[0]]) {
                convertedString = stringTypeConverter(uniqueYColumn, GetDataType(yColumnGroupType[groupY[0]]));
              } else {
                convertedString = GetDataType(getGroupType(groupY[0])) ? stringTypeConverter(uniqueYColumn, GetDataType(getGroupType(groupY[0]))) : stringTypeConverter(uniqueYColumn, !isNaN(uniqueYColumn) ? 'number' : GetDataType(getGroupType(groupY[0])))
              }
              const dataRows = _.filter(sortByType(yColumns, groupedDate, groupY[1]), [groupY[0], convertedString]).map((yColumn, yColumnIndex) => {
                var totalXCount = 0;
                return (
                    <tr>
                      {groupY.map((groupYItem, groupYItemIndex) => {
                        const yHeading = yColumn[groupYItem]
                        const display = (yColumnIndex === 0) ? true : (
                            groupYItemIndex !== 0 ? true : false
                        )
                        // yColumnIndex === 0 || groupYItemIndex !== 0
                        return (
                            display && (
                                <td
                                    className={
                                      groupYItemIndex === 0 ? 'first_grouping y-header' : (
                                          groupYItemIndex === 1 ? 'second_grouping y-header' : ''
                                      )
                                    }
                                    rowSpan={yColumnIndex === 0 && groupYItemIndex === 0 ? uniqueYColumnCount : 1}
                                    colSpan={!edit && groupY.length === 1 ? 1 : (groupYItemIndex === 0 && (!editableReport && groupY.length === 1 && groupX.length === 2)) ? 2 : 1}
                                >
                                  {yHeading !== undefined && (
                                      _.isBoolean(yHeading) ? (
                                          <Checkbox checked={yHeading} disabled={true} />
                                      ) : (
                                          yHeading === null ? (
                                              ""
                                          ) : (
                                              yHeading.toString()
                                          )
                                      )
                                  )}
                                </td>
                            )
                        )
                      })}
                      {/* {(editableReport ? true : (groupY.length === 2 && groupX.length === 2)) && (
                  <td></td>
                )} */}
                      {groupX.length === 1 && topXRow && topXRow.length > 0 && (
                          topXRow.map(([xColumn, XColumnCount], xColumnIndex) => {
                            const rowAndColumnGroupValues = []

                            groupX.map(() => {
                              rowAndColumnGroupValues.push(xColumn === 'null' ? "" : xColumn)
                            })
                            groupY.map(yItem => {
                              rowAndColumnGroupValues.push(modifyFalseyFields(yColumn[yItem]))
                            })
                            const thisData = rowAndColumnGroupValues.reduce((object, path) => {
                              return (object || {})[path];
                            }, groupedData)
                            // determine if to display record count(default), sum, min, max or average
                            // const thisDataCount = thisData ? getMatrixSummaryData(thisData, summaryType, summaryRow) : 0
                            let thisDataCount = getGrandTotalCount(thisData)

                            if (count_or_sum) {
                              totalXCount += thisDataCount
                              if (arrayOfSums[xColumnIndex] === undefined) {
                                arrayOfSums[xColumnIndex] = thisDataCount
                              } else {
                                arrayOfSums[xColumnIndex] += thisDataCount
                              }

                              if (thisSubtotalCount[xColumnIndex] === undefined) {
                                thisSubtotalCount[xColumnIndex] = thisDataCount
                              } else {
                                thisSubtotalCount[xColumnIndex] += thisDataCount
                              }
                            }

                            return (
                                <td className='count_item'>
                                  {parseFloat(thisDataCount).toLocaleString('en-US')}
                                </td>
                            )
                          })
                      )}
                      {groupX.length === 2 && bottomXRow && bottomXRow.length > 0 && (
                          bottomXRow.map((firstLevel, firstLevelIndex) => {
                            var thisRowCount = 0

                            if (thisSubtotalCount[firstLevelIndex] === undefined) {
                              thisSubtotalCount[firstLevelIndex] = []
                            }

                            return (
                                <>
                                  {firstLevel.map((xColumn, xColumnIndex, arr) => {
                                    const rowAndColumnGroupValues = []

                                    groupX.map(xItem => {
                                      rowAndColumnGroupValues.push(modifyFalseyFields(xColumn[xItem]))
                                    })
                                    groupY.map(yItem => {
                                      rowAndColumnGroupValues.push(modifyFalseyFields(yColumn[yItem]))
                                    })
                                    const thisData = rowAndColumnGroupValues.reduce((object, path) => {
                                      return (object || {})[path];
                                    }, groupedData)
                                  // determine if to display record count(default), sum, min, max or average
                                    // const thisDataCount = thisData ? getMatrixSummaryData(thisData, summaryType, summaryRow) : 0
                                    let thisDataCount = getGrandTotalCount(thisData)

                                    if (count_or_sum) {
                                      totalXCount += thisDataCount

                                      if (arrayOfSums[firstLevelIndex] === undefined) {
                                        arrayOfSums[firstLevelIndex] = []
                                      }

                                      if (arrayOfSums[firstLevelIndex][xColumnIndex] === undefined) {
                                        arrayOfSums[firstLevelIndex][xColumnIndex] = thisDataCount
                                      } else {
                                        arrayOfSums[firstLevelIndex][xColumnIndex] += thisDataCount
                                      }

                                      thisRowCount += thisDataCount

                                      if (thisSubtotalCount[firstLevelIndex][xColumnIndex] === undefined) {
                                        thisSubtotalCount[firstLevelIndex][xColumnIndex] = thisDataCount
                                      } else {
                                        thisSubtotalCount[firstLevelIndex][xColumnIndex] += thisDataCount
                                      }
                                    }

                                    return (
                                        <>
                                          <td className='count_item'>
                                            {parseFloat(thisDataCount).toLocaleString('en-US')}
                                          </td>
                                          {count_or_sum && groupX.length === 2 && groupY.length > 0 && arr.length === xColumnIndex + 1 && (
                                              <td className='subtotal count_item'>{parseFloat(thisRowCount).toLocaleString('en-US')}</td>
                                          )}
                                        </>
                                    )
                                  })}
                                </>
                            )
                          })
                      )}
                      {count_or_sum && (
                          <td className='total_item'>
                            {xColumns.length > 0 ? totalXCount.toLocaleString('en-US') : (
                                getMatrixSummaryData(groupY.map(yItem => modifyFalseyFields(yColumn[yItem])).reduce((object, path) => {
                                  return (object || {})[path];
                                }, groupedData), summaryType, summaryRow)
                            ).toLocaleString('en-US')}
                          </td>
                      )}
                    </tr>
                )
              })
              // display data and subtotal row
              return (
                  <>
                    {dataRows}
                    {count_or_sum && groupY.length === 2 && groupX.length > 0 && (
                        <tr className='subtotal'>
                          {groupY.map((item, index) => (
                              <td
                                  className={index === 1 ? 'subtotal_heading' : 'first_grouping'}
                              >
                                {index === 1 ? 'Subtotal' : ''}
                              </td>
                          ))}
                          {/* <td></td> */}
                          {groupX.length === 1 && (
                              thisSubtotalCount.map(subtotal => (
                                  <td className='count_item'>
                                    {parseFloat(subtotal).toLocaleString('en-US')}
                                  </td>
                              ))
                          )}
                          {groupX.length === 2 && (
                              thisSubtotalCount.map((topLevel) => {
                                return (
                                    <>
                                      {topLevel.map(subtotal => (
                                          <td className='count_item'>
                                            {parseFloat(subtotal).toLocaleString('en-US')}
                                          </td>
                                      ))}
                                      <td className='count_item'>
                                      {parseFloat(_.sum(topLevel)).toLocaleString('en-US')}
                                      </td>
                                    </>
                                )
                              })
                          )}
                          {
                            <td className='total_item'>
                              {_.sum(thisSubtotalCount.flat()).toLocaleString('en-US')}
                            </td>
                          }
                        </tr>
                    )}
                  </>
              )
            })
        )}
      </>
  )

  const footer = groupX.length > 0 && (
      <tr className='bottom_total_row'>
        <td
        colSpan={editableReport ? 2 : (
          groupY.length === 2 ? 2 : 1
          )}
      >
        Grand Total
      </td>
        {arrayOfSums.length > 0 ? (
            //{
            <>
              {groupX.length === 1 && (
                  arrayOfSums.map(columnTotal => (
                      <td className='total_count'>
                        {columnTotal.toLocaleString('en-US')}
                      </td>
                  ))
              )}
              {groupX.length === 2 && (
                  arrayOfSums.map((topLevel) => {
                    return (
                        <>
                          {topLevel.map(columnTotal => (
                              <td className='total_count'>
                                {columnTotal.toLocaleString('en-US')}
                              </td>
                          ))}
                          <td className='total_count'>
                            {(_.sum(topLevel)).toLocaleString('en-US')}
                          </td>
                        </>
                    )
                  })
              )}
            </>
        ) : (
             (groupX.length > 0 && groupY.length > 0) && (
                 groupX.length === 1 ? (
                     topXRow && topXRow.map(([xColumn, XColumnCount], xColumnIndex) => {
                       const rowAndColumnGroupValues = [];
                       groupX.map(() => {
                         rowAndColumnGroupValues.push(xColumn === 'null' ? '' : xColumn)
                       })
                       const thisData = rowAndColumnGroupValues.reduce((object, path) => {
                         return (object || {})[path];
                       }, groupedData)
                       // determine if to display record count(default), sum, min, max or average
                       // const thisDataCount = thisData ? getMatrixSummaryData(thisData, summaryType, summaryRow) : 0
                       let thisDataCount = getGrandTotalCount(thisData)

                       if (count_or_sum) {
                         if (arrayOfSums[xColumnIndex] === undefined) {
                           arrayOfSums[xColumnIndex] = thisDataCount
                         } else {
                           arrayOfSums[xColumnIndex] += thisDataCount
                         }
                       }
                      //  console.log(thisData, thisDataCount)
                       return (
                           <td className='count_item'>
                             {parseFloat(thisDataCount).toLocaleString('en-US')}
                           </td>
                       )
                     })
                 ) : (
                     bottomXRow && bottomXRow.length > 0 && (
                         bottomXRow.flat().map((xColumn, xColumnIndex) => {
                           const rowAndColumnGroupValues = []

                           groupX.map(xItem => {
                             rowAndColumnGroupValues.push(modifyFalseyFields(xColumn[xItem]))
                           })
                           const thisData = rowAndColumnGroupValues.reduce((object, path) => {
                             return (object || {})[path];
                           }, groupedData)
                           // determine if to display record count(default), sum, min, max or average
                           // const thisDataCount = thisData ? getMatrixSummaryData(thisData, summaryType, summaryRow) : 0
                           let thisDataCount = getGrandTotalCount(thisData)

                           if (count_or_sum) {
                             if (arrayOfSums[xColumnIndex] === undefined) {
                               arrayOfSums[xColumnIndex] = thisDataCount
                             } else {
                               arrayOfSums[xColumnIndex] += thisDataCount
                             }
                           }

                           return (
                               <td className='count_item'>
                                 {parseFloat(thisDataCount).toLocaleString('en-US')}
                               </td>
                           )
                         })
                     )
                 )
             )
         )}
        <td className='total_count'>
          {_.sum(arrayOfSums.flat()).toLocaleString('en-US')}
        </td>
      </tr>
  )

  const Summary = ({
    group
  }) => {
    return (
        arrayReportSort(Object.entries(groupedData), "0" , group[0] , groupedDate).map(([key1, value1]) => {
          if (value1) {
            console.log({group, key1, value1});
            return (
                <tr className='summary'>
                  <td className='group1'>{group && GetLabel(group[0])}: {key1} - ({parseInt(value1)} {parseInt(value1) === 1 ? 'record' : 'records'})</td>
                </tr>
            )
          } else {
            const secondValues = []
            var totalCount = 0

            arrayReportSort(Object.entries(value1), "0", group[1], groupedDate).map(([key2, value2]) => {
              totalCount += value2.length
              secondValues.push(
                  <tr className='summary'>
                    <td className='group2'>{group && GetLabel(group[1])}: {key2} - ({parseInt(value2)} {parseInt(value2) === 1 ? 'record' : 'records'})</td>
                  </tr>
              )
            })

            return (
                <>
                  <tr className='summary'>
                    <td className='group1'>{group && GetLabel(group[0])}: {key1} - ({totalCount} {totalCount === 1 ? 'record' : 'records'})</td>
                  </tr>
                  {secondValues.map(item => item)}
                </>
            )
          }


        })
    )
  }

  return (
      <TableStyling
          tight={tight}
          groupX={groupX}
          groupY={groupY}
          edit={editableReport}
          isDashboard={isDashboard}
          parentSize={parentSize}
          matrixFontSize={matrixFontSize}
          maxWidthColumns={maxWidthColumns}
          refIndex={refIndex}
          homePage={homePage}>
        <table className='matrix-table' ref={(el) => {
          if (is.existy(matrixRef) && is.existy(refIndex)) {
            matrixRef.current[refIndex] = el
          }
        }}>
          <tbody>
          {groupX && groupY &&

           (groupY.length > 0 && groupX.length === 0 && !editableReport) ? (
               <Summary group={groupY} />
           ) : (
               groupX.length > 0 && groupY.length === 0 && !editableReport ? (
                   <Summary group={groupX} />
               ) : (
                   <>
                     {x_heading}
                     {present_data}
                     {count_or_sum && footer}
                   </>
               )
           )}
          </tbody>
        </table>
      </TableStyling>
  )
}

export default React.memo(Matrix)