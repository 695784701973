import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ProfileInfo } from "components/composites/ProfileInfo.js";
import { Menu } from "components/composites";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";
import HandleInactive from "../composites/handleInactive";
import { useDispatch } from "react-redux";
import { fetchScanTypes } from "../../pages/People/Scans/scanData";
import CountDownTarget from "components/composites/countDownTarget";
import { alphabeticalData } from "utils/sortData";
import { useBeforeunload } from "react-beforeunload";
import Cookies from "js-cookie";
import moment from "moment";
import _ from 'lodash';
import { ZenSmartAPI } from "utils";
import { getBySettingUrlPopulate, getAllSummaryDashboards, getAllDepartments  } from 'utils/apiRoutes';

const getVisibility = ({ isVisible }) => css`
  opacity: ${isVisible ? 1 : 0};
  visibility: ${isVisible ? "visible" : "hidden"};
  min-width: ${isVisible ? "230px" : 0};
`;

let once = 1;

const getWidth = ({ isVisible }) => (isVisible ? "10px" : "0");

const Aside = styled.aside`
  ${getVisibility}
  flex-basis: ${getWidth};
  max-width: ${getWidth};
  background-color: #fff;
  transition-duration: 0.3s;
`;

const getDisplay = ({ isVisible }) => (isVisible ? "block" : "none");

const Container = styled.div`
  display: ${getDisplay};
`;

const FooterNumber = styled.div`
text-align: center;
margin-top : 10px;
font-family: 'Rubik',sans-serif;
font-size: 14px;
text-align: center;
color: #8798AD;
`;

const StyleMenu = styled(Menu)`
  & a {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
function Sidebar(props) {
  const { isMenuOpen, user, logout, ...sidebar } = props;
  const scanData = useSelector((state) => state.scan.scan_menu.data);
  const titleHeader = useSelector((state) => state.global.title);
  const userData = useSelector((state) => state.user);
  const [showBatchLinkOverview, setShowBatchLinkOverview] = useState(0);
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState([]);


  useEffect(() => {
    ZenSmartAPI(getBySettingUrlPopulate('batch', 'show_batch_link_overview_page')).then(res => {
      setShowBatchLinkOverview(res.data.data.val);
    }).catch((e) => {
      console.log(e)
    });
  }, [])

  useEffect(() => {
    if (userData.authenticated) {
      if (Cookies.get("SESSION_TIMEOUT")) {
        const getExpiry =
            parseInt(Cookies.get("SESSION_TIMEOUT")) -
            parseInt(moment().format("X"));
        if (Cookies.get("SESSION_TIME") == 0) {
        } else if (getExpiry < 0) {
          // logout();
        }
      }
    }
  }, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userData.details) {
      if (!window.location.href.includes("/people/scan")) {
        fetchScanTypes(dispatch);
      }
    }
  }, [userData.details]);

  useEffect(() => {
    if(userData.authenticated) {
      ZenSmartAPI.get(getAllSummaryDashboards).then(res => {
        const departments = res.data.data;
        setDepartments(alphabeticalData(departments, 'name'));
        localStorage.setItem('department',JSON.stringify(departments))
      }).catch(err => {
        setDepartments([]);
      })
    }
  }, [userData.authenticated])

  useBeforeunload(() => {
    const expiredMinutes = userData.details.inactive_login_expiration_duration
                           ? 60 * userData.details.inactive_login_expiration_duration
                           : 60 * 5;
    Cookies.set(
        "SESSION_TIMEOUT",
        parseInt(moment().format("X")) + expiredMinutes,
        { expires: 1 }
    );
    return true;
  });

  return (
      <Aside isVisible={isMenuOpen} {...sidebar}>
        {userData.authenticated &&
         userData.details &&
         window.location.href.indexOf("dashboard") === -1 ? (
             <HandleInactive logout={logout} userData={userData} />
         ) : null}
        <Container isVisible={isMenuOpen}>
          <ProfileInfo user={user} logout={logout} />
          <StyleMenu>
            {titleHeader ? (
                <Helmet>
                  <title>{titleHeader}</title>
                </Helmet>
            ) : null}
            <Menu>
              <Menu.Link exact to="/app/home" icon="home" text="Home" />
              <Menu.Group scope="/app/process" icon="process" text="Process">
                <Menu.Link
                    permission="view-incoming-orders"
                    exact
                    to="/incoming-orders"
                    text="Incoming Orders"
                />

                {process.env.REACT_APP_BARADUR_SERVER ? (
                    <Menu.Link
                        exact
                        to="/failed-transactions"
                        text="Failed Transactions"
                        permission="view-failed-transactions-page"
                    />
                ) : null}

                {userData.details &&
                 userData.details.page_settings
                     .departments &&
                 userData.details.page_settings
                     .departments.length === 0 ? (
                     <Menu.Link
                         to="/on-hold-report"
                         text="Hold Panel"
                     />
                 ) : (
                     userData.details &&
                     userData.details.page_settings
                         .departments &&
                     userData.details.page_settings
                         .departments.length > 0 && (
                         <Menu.Group
                             scope="/on-hold-report"
                             text="Hold Panel"
                         >
                           <Menu.Link
                               permission="view-hold-panel"
                               to="/"
                               text="All Departments"
                               exact
                           />
                           {userData.details.page_settings.departments.map(
                               (data) => {
                                 return (
                                     <Menu.Link
                                         permission="view-hold-panel"
                                         to={`/${data.name}`}
                                         text={data.name}
                                         key={'department-' + data.id}
                                     />
                                 );
                               }
                           )}
                         </Menu.Group>
                     )
                 )}

                <Menu.Link
                    permission="view-checked-out-orders"
                    to="/checked-out"
                    text="Checked Out"
                />
                <Menu.Link
                    permission="view-order-postbacks-page"
                    to="/postbacks"
                    text="Postbacks"
                />
              </Menu.Group>

              <Menu.Group scope="/app/people" icon="users" text="People">
                <Menu.Group scope="/scan" text="Scan Apps">
                  {scanData
                   ? alphabeticalData(scanData, "text").map((data, key) => {
                        return data.text.toLowerCase() === "match" &&
                               data.text ? (
                                   ""
                               ) : data.text.toLowerCase() === "match in" ? (
                            <Menu.Link to={`/match`} text={"match"} key={key} />
                        ) : data.text.toLowerCase() === "qa-fail" ||
                            data.text.toLowerCase() === "qa fail" ? (
                                <Menu.Link
                                    to={`/${data.text
                                    .split(" ")
                                    .join("-")
                                    .toLowerCase()}`}
                                    text={"QA Fail"}
                                    key={key}
                                />
                            ) : (
                                <Menu.Link
                                    to={`/${data.text
                                    .split(" ")
                                    .join("-")
                                    .toLowerCase()}`}
                                    text={data.text.split("-").join(" ")}
                                    key={key}
                                />
                            );
                      })
                   : ""}
                </Menu.Group>
                <Menu.Link
                    permission="view-operators"
                    to="/operators"
                    text="Operators"
                />

                <Menu.Link
                    permission="view-stations"
                    to="/stations"
                    text="Stations"
                />
              </Menu.Group>

              <Menu.Group scope="/app/prep" icon="prep" text="Prep">
                <Menu.Link
                    exact
                    permission="view-product-flow-designer"
                    to="/product-flow"
                    text="Product Flow Designer"
                />
                <Menu.Link
                  exact
                  permission="clone-product"
                  to="/product-clone"
                  text="Product Clone"
                />
                {userData &&
                userData.details &&
                userData.details.show_accessories_page != "0" &&
                userData.details.show_accessories_page != false && (
                    <Menu.Link
                        permission="view-accessories-page"
                        to="/accessories"
                        text="accessories"
                    />
                )}
                 <Menu.Link
                    exact
                    to="/bulk-data-loading-toolkit"
                    text="Bulk Data Loading Toolkit"
                    permission="manage-bulk-data-loading-toolkit"
                />
              </Menu.Group>

              <Menu.Group scope="/app/print" icon="print" text="Print">
                {(userData?.details?.page_settings?.printer_location_settings.length > 0) &&
                <Menu.Group scope="/control-tower" text="Control Tower">
                  {userData.details.page_settings.printer_location_settings.map(
                      (data) => {
                        return (
                            <Menu.Link
                                permission="view-control-tower"
                                to={`/${data.name.replace(/\W+(?!$)/g, '-').toLowerCase()}`}
                                text={data.name}
                            />
                        );
                      }
                  )}
                </Menu.Group>
                }
                <Menu.Link
                    permission="view-imposition-designer"
                    to="/imposition"
                    text="Imposition Designer"
                />
                {userData.details &&
                 userData.details.page_settings
                     .waiting_for_partners_setting_multi_panel &&
                 userData.details.page_settings
                     .waiting_for_partners_setting_multi_panel.length === 0 ? (
                     <Menu.Link
                         permission="view-waiting-for-partners"
                         to="/waiting-for-partners"
                         text="Waiting for Partners"
                     />
                 ) : (
                     userData.details &&
                     userData.details.page_settings
                         .waiting_for_partners_setting_multi_panel &&
                     userData.details.page_settings
                         .waiting_for_partners_setting_multi_panel.length > 0 && (
                         <Menu.Group
                             permission="view-waiting-for-partners"
                             scope="/waiting-for-partners"
                             text="Waiting for Partners"
                         >
                           {userData.details.page_settings.waiting_for_partners_setting_multi_panel.map(
                               (data) => {
                                 return (
                                     <Menu.Link
                                         permission="view-press-release"
                                         to={`/${data.slug}`}
                                         text={data.name}
                                         key={data.id}
                                     />
                                 );
                               }
                           )}
                         </Menu.Group>
                     )
                 )}

                {userData.details &&
                 userData.details.page_settings.press_release_setting &&
                 userData.details.page_settings.press_release_setting.length ===
                 1 ? (
                     <Menu.Link
                         permission="view-press-release"
                         to="/press-release"
                         text="Press Release"
                     />
                 ) : (
                     userData.details &&
                     userData.details.page_settings.press_release_setting &&
                     userData.details.page_settings.press_release_setting.length >
                     1 && (
                         <Menu.Group scope="/press-release" text="Press Release">
                           {userData.details.page_settings.press_release_setting.map(
                               (data) => {
                                 return (
                                     <Menu.Link
                                         key={data.slug}
                                         permission="view-press-release"
                                         to={`/${data.slug}`}
                                         text={data.name}
                                     />
                                 );
                               }
                           )}
                         </Menu.Group>
                     )
                 )}
                {userData.details &&
                 userData.details.page_settings.work_order_setting &&
                 userData.details.page_settings.work_order_setting.length === 1 ? (
                     <Menu.Link
                         permission="view-work-order"
                         to="/work-order-release"
                         text="Work Order Release"
                     />
                 ) : (
                     userData.details &&
                     userData.details.page_settings.work_order_setting &&
                     userData.details.page_settings.work_order_setting.length >
                     1 && (
                         <Menu.Group scope="/work-order-release" text="Work Order Release">
                           {userData.details.page_settings.work_order_setting.map(
                               (data) => {
                                 return (
                                     <Menu.Link
                                         permission="view-work-order"
                                         to={`/${data.slug}`}
                                         text={data.name}
                                     />
                                 );
                               }
                           )}
                         </Menu.Group>
                     )
                 )}

                <Menu.Link
                    permission="view-press-release"
                    to="/barcode-press-release"
                    text="Barcode Press Release"
                />
                <Menu.Link
                    permission="view-batching-configuration"
                    to="/batch-release-designer"
                    text="Batch Release Designer"
                />
                <Menu.Link
                    permission="view-printers-page"
                    to="/printers"
                    text="printers"
                />
                <Menu.Link
                    permission="view-printer-batching-configuration-assignment"
                    to="/printer-assignment"
                    text="Printer Assignment"
                />
                <Menu.Group scope="/batch-release" text="Batch Release">
                  {_.get(userData, 'details.page_settings.printer_location_settings') && userData.details.page_settings.printer_location_settings.map(
                      (data) => {
                        return (
                            <Menu.Link
                                permission="view-control-tower"
                                to={`/${data.name.replace(/\W+(?!$)/g, '-').toLowerCase()}`}
                                text={data.name}
                            />
                        );
                      }
                  )}
                </Menu.Group>
              </Menu.Group>

            <Menu.Group
              scope="/app/pack-and-ship"
              icon="pack_ship"
              text="Pack & Ship"
            >
              {showBatchLinkOverview &&
                <Menu.Link
                  to="/batch-link-overview"
                  text="Batch Link Overview"
                />              
              }
              <Menu.Link
                permission="view-pre-dispatch-overview"
                to="/pre-dispatch-overview"
                text="Pre Dispatch Overview"
              />
              <Menu.Link
                permission="view-match-overview"
                to="/match-overview"
                text="Match Overview"
              />
              <Menu.Link
                permission="view-dispatch-designer"
                to="/dispatch-define"
                text="Dispatch Designer"
              />
              {userData &&
                userData.details &&
                userData.details.show_manifest_page && (
                    <Menu.Link to="/manifest" text="Manifest" />
                )}
                <Menu.Link
                    permission="manage-dropship"
                    to="/dropship"
                    text="Dropship"
                />

                <Menu.Link
                    permission="create-manual-consignments"
                    to="/manual-consignment"
                    text="Manual Consignment"
                />
                <Menu.Link
                    permission="view-manual-pickup"
                    to="/manual-pickup"
                    text="Manual Pickup"
                />
              </Menu.Group>
              {userData &&
              userData.details &&
              userData.details.show_materials_page && (
                  <Menu.Group
                      scope="/app/materials"
                      icon="materials"
                      text="Materials"
                  >
                    {userData && userData.details && userData.details.show_materials_page && userData.details.show_materials_page.show_bill_of_materials_page && (
                        <Menu.Link
                            exact
                            to="/bill-of-materials"
                            text="Bill of materials"
                            permission="view-bom"
                        />
                    )}
                    {userData && userData.details && userData.details.show_materials_page && userData.details.show_materials_page.job_ticket_printer && (
                        <Menu.Link
                            exact
                            to="/job-ticket-printer"
                            text="Job Ticket Printer"
                            permission="view-job-ticket-printer"
                        />
                    )}
                  </Menu.Group>
              )}
              <Menu.Group
                  scope="/app/performance"
                  icon="performance"
                  text="Performance"
              >
                <Menu.Link
                  permission="view-production-queues"
                  to="/production-queues"
                  text="Production Queues"
                />
                <Menu.Link
                  permission="view-hourly-linear-feet-progress-report"
                  to="/hourly-linear-feet-progress-report"
                  text="Hourly Linear Feet Progress Report"
                />
                <Menu.Link
                  permission="view-associate-progress-report"
                  to="/associate-progress-report"
                  text="Associate Progress Report"
                />
                <Menu.Link
                    permission="view-reports-page"
                    to="/reports"
                    text="Report Designer"
                />
                <Menu.Link
                    permission="view-saved-reports-page"
                    to="/saved-reports"
                    text="Saved Reports"
                />
                <Menu.Link
                    permission="view-dashboards-page"
                    to="/dashboards"
                    text="Dashboards"
                />
                <Menu.Link
                    permission="view-wip-panel"
                    to="/wip-panel"
                    text="WIP Panel"
                />
                <Menu.Link
                    permission="view-fail-panel"
                    to="/fail-panel"
                    text="Fail Panel"
                />
                <Menu.Link
                    permission="view-health-check-panel"
                    to="/healthcheck-panel"
                    text="Healthcheck Panel"
                />
                <Menu.Link
                    permission="view-in-out-panel-panel"
                    to="/in-out-panel"
                    text="In/Out Panel"
                />
                <Menu.Link
                    permission="view-targets-page"
                    to="/targets"
                    text="Targets"
                />
                <Menu.Group scope="/department-wip-summary" text="Department Wip Summaries">
                  {departments && departments.map(department => {
                    return <Menu.Link key={department.id} to={`/${department.id}`} text={department.name} />
                  })}
                </Menu.Group>
                {(userData && userData.details && userData.details.show_one_click_billing_page) &&
                <Menu.Link to="/billing" text="Billing" />}
                <Menu.Link
                    permission="view-product-flow-reporter-page"
                    to="/product-flow-reporter"
                    text="Product Flow Reporter"
                />
                <Menu.Group scope="/cell-performance" text="Cell Performance">
                  <Menu.Link to="/weekly-cell-department-summary" permission="view-weekly-cell-department-summary" text="Weekly Cell Department Summary" />
                  <Menu.Link to="/cell-activity-data" permission="view-cell-activity-data" text="Cell Activity Data" />
                </Menu.Group>
                <Menu.Group scope="/operator-performance" text="Op. Performance">
                  <Menu.Link
                      permission="view-weekly-department-summary"
                      to="/weekly-department-summary"
                      text="Weekly Department Summary"
                  />
                  <Menu.Link
                      permission="view-operators-activity"
                      to="/operators-activity"
                      text="Operator Activity Data"
                  />
                  <Menu.Link
                      permission="view-operators-efficiency-chart"
                      to="/operators-efficiency-chart"
                      text="Operator Efficiency Chart"
                  />
                  <Menu.Link
                      permission="view-operators-comparison-chart"
                      to="/operators-comparison-chart"
                      text="Operator Comparison Chart"
                  />
                  <Menu.Link
                      permission="view-operators-table"
                      to="/operators-table"
                      text="Operator Table"
                  />

                </Menu.Group>
                <Menu.Group scope="/station-performance" text="Station Performance">
                  <Menu.Link
                      permission="view-station-activity"
                      to="/station-activity"
                      text="Station Activity Data"
                  />
                  <Menu.Link
                      permission="view-station-product-activity"
                      to="/station-product-activity"
                      text="Station Product Activity Data"
                  />
                  <Menu.Link
                      permission="view-station-efficiency-chart"
                      to="/station-efficiency-chart"
                      text="Station Efficiency Chart"
                  />

                </Menu.Group>
              </Menu.Group>

              <Menu.Group scope="/app/machine" icon="machine" text="Machine">
                <Menu.Link
                    permission="view-tasks"
                    to="/maintenance"
                    text="Tasks"
                />
                <Menu.Link
                    permission="view-machine-task-designer"
                    to="/task-management"
                    text="Task Designer"
                />
                <Menu.Link
                    permission="view-machine-overview"
                    to="/overview"
                    text="Overview"
                />
                <Menu.Link
                    permission="view-machine-overview"
                    to="/faults-overview"
                    text="Faults Overview"
                />
                <Menu.Link
                    permission="view-machine-overview"
                    to="/engineers-list"
                    text="Engineers List"
                />
                <Menu.Link
                    permission='view-uchida'
                    to='/uchida-input-generator'
                    text='Uchida Input Generator'
                />
                {(userData?.details?.page_settings?.printer_location_settings.length > 0) &&
                <Menu.Group scope='/autorun' text='Auto Run Designer'>
                  {userData.details.page_settings.printer_location_settings.map(locationItem => (
                      <Menu.Link
                          key={locationItem.id}
                          permission="view-machine-overview"
                          to={`/${locationItem.id}/${locationItem.name.replaceAll('/', '⧸')}`}
                          text={`${locationItem.name}`}
                      />
                  ))}
                </Menu.Group>}
              {(userData?.details?.page_settings?.printer_location_settings.length > 0) &&
                <Menu.Group scope='/autorun-printer-overview' text='Auto Run Overview'>
                  {userData.details.page_settings.printer_location_settings.map(locationItem => (
                    <Menu.Link
                      key={locationItem.id}
                      permission="view-machine-autorun-printer-overview"
                      to={`/${locationItem.id}/${locationItem.name.replaceAll('/', '⧸')}`}
                      text={`${locationItem.name}`}
                    />
                  ))}
                </Menu.Group>}
            </Menu.Group>
            <Menu.Group scope="/app/app-setup" icon="settings" text="App Setup">
              <Menu.Link
                permission="view-product-flow-designer"
                to="/workflow-rules"
                text="Work Flow Rules"
              />
              <Menu.Link
                permission="view-setting-page"
                to="/settings"
                text="Settings"
              />
               <Menu.Link
                permission="view-setting-page"
                to="/currencies"
                text="Currencies"
              />
              <Menu.Link
                permission="view-vendor-page"
                to="/vendors"
                text="vendors"
              />
              <Menu.Link permission="view-post-dispatch-message-page" to="/post-dispatch-messages" text="Post Dispatch Message" />
              <Menu.Link
                permission="view-summary-dashboard-page"
                to="/summary_dashboards"
                text="Summary Dashboards"
              />
              <Menu.Link to="/product-skews" text="Product Skew" />
              <Menu.Link permission="view-viesus-rule-page" to="/viesus-rules" text="Viesus Rules" />
              <Menu.Link permission="view-multi-cut-rule-page" to="/multicut-rules" text="MultiCut Rules" />
              <Menu.Link permission="view-imposition-rule-page" to="/imposition-rules" text="Imposition Rules" />
              <Menu.Link
                  permission="view-qa-fail-reasons-page"
                  to="/qa-fail-reasons"
                  text="QA Fail Reasons"
              />
              <Menu.Link
                  permission="view-cancel-reasons-page"
                  to="/cancel-reasons"
                  text="Cancel Reasons"
              />
              <Menu.Link
                  permission="view-checkout-reasons-page"
                  to="/checkout-reasons"
                  text="Checkout Reasons"
              />
              <Menu.Link
                  permission="view-label-templates-page"
                  to="/label-templates"
                  text="Label Templates"
              />
              <Menu.Link
                  permission="view-email-templates-page"
                  to="/email-templates"
                  text="Email Templates"
              />
            </Menu.Group>
          </Menu>
          {userData.authenticated && userData.details ? (
            <CountDownTarget />
          ) : null}
          {process.env.REACT_APP_VERSION ? (<FooterNumber>version: {`${process.env.REACT_APP_VERSION}`}</FooterNumber>) : null}
        </StyleMenu>
      </Container>
    </Aside>
  );
}

export default Sidebar;

