import styled, { createGlobalStyle } from "styled-components";
import { color } from "components/zensmart-design-system/shared/styles.js";

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;

const StyledDivider = styled.hr`
  background-color: #2e5bff;
  margin: 1rem;
  margin-bottom: 2rem;
  height: 1px;
  opacity: 25%;
`;

const SubtitleStyle = styled.h2`
  color: #8798ad;
  font-size: 13px;
  font-weight: normal;
  margin: 1rem 50px;
`;

const StyleTable = styled.div`
  font-family: "Rubik", sans-serif;
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 1em 50px 2em;
`

const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;
}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

`;

export {
  Header,
  Title,
  StyledDivider,
  SubtitleStyle,
  Message,
  StyleTable,
  TableStyling,
};
