import React, { useState, useEffect } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import { DROP, PROCESS } from 'types'
import { LibraryItem } from 'components/composites'
import { background } from 'components/zensmart-design-system/shared/styles.js'
import { ReactComponent as EditIcon } from 'static/icons/edit-icon.svg'
import { ReactComponent as CloseIcon } from 'static/icons/close.svg'
import { ReactComponent as LockIcon } from 'static/icons/lock-icon.svg'
import { ReactComponent as EmailIcon } from 'static/icons/email-icon.svg'
import { ReactComponent as CalloutIcon } from 'static/icons/callout-icon.svg'
import { ReactComponent as FieldUpdateIcon } from 'static/icons/field-update-icon.svg'
import { ReactComponent as AddCostIcon } from 'static/icons/add-cost-icon.svg'
import { color } from 'components/zensmart-design-system/shared/styles'
import { fethAllFreightGroup } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'
import { SiblingArrow, DropZone } from '../primaries'

import {
  ProcessItemTextModal,
  AutomatedStageModal,
  BarcodeDetailsModal,
  EmailTriggerModal,
  FieldUpdateTriggerModal,
  CalloutTriggerModal,
  AddCostTriggerModal,
  QueueRulesModal,
  BatchRulesModal,
  MatchOutModal,
  DispatchModal,
  PickingModal,
} from '../sections'

import { getColorFromType } from 'utils'
import PreDispatchModal from '../sections/PreDispatchModal'

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`

const Wrapper = styled.div`
  position: relative;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  background-color: ${props => props.color};
  border-radius: 50%;

  cursor: ${props => props.cursor || 'pointer'};
  transition-duration: 0.15s;
`

const Icons = styled.div`
  z-index: 2;
  display: flex;

  position: absolute;
  top: -4px;
  right: -2px;

  & > ${IconContainer}:not(:last-of-type) {
    margin-right: 2px;
  }
`

const NumberBadge = styled.div`
  position: absolute;
  bottom: -10px;
  left: 10px;
  z-index: 2;

  background-color: ${background.default};
  border-radius: 50%;

  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`

/**
 * Utility function for disabling react-beautiful-dnd's
 * built-in drop animations
 */
function getStyle(style, snapshot) {
  if (!snapshot.isDragging) {
    return {}
  }

  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    transitionDuration: `0.001s`,
  }
}

function ChildProcessItem(props) {
  const { process, processID, processIndex, processNumber, editArea, productID, isLast, isOneChild = false } = props
  const [freightGroups, setFreightGroups] = useState(null)
  const {
    dragType,
    modal,
    updateProcess,
    updateTrigger,
    matchGroupOptions,
    getMatchRange,
    onRemoveBarcode,
    scanTypesOptions,
    automatedScanTypesOptions,
    queueRules,
    setQueueRules,
    batchRules,
    setBatchRules,
    qualifiers,
    recipients,
    emailTemplates,
    removeTrigger,
    dispatchTemplatesOptions,
    batchRulesOptions,
    preDistachRules,
    setPreDistachRules,
    letterRange,
    originalDispatchRules,
    setOriginalDispatchRules,
    originalBatchRules,
    setOriginalBatchRules,
    originalQueueRules,
    setOriginalQueueRules,
    setPickingGroupOptions,
    setPickingLocations,
    pickingComponents,
    pickingLocations,
    pickingGroupOptions,
    setPickingComponents,
    matchOutRules,
    setMatchOutRules,
    originalMatchOutRules,
    setOriginalMatchOutRules,
    matchGroup, 
    setMatchGroup,
    getMatchGroup,
    forceSeparateQueues,
    updateForceSeparateQueues,

  } = editArea
  const [currentModal, setModal] = modal

  const itemID = `<${DROP.ITEM}>${processID}`

  const isLocked = !process.editable
  const isAutomated = process.type === PROCESS.TYPE.AUTOMATED
  const isBarcode = process.type === PROCESS.TYPE.BARCODE
  const isQueued = process.type === PROCESS.TYPE.QUEUED
  const isReady = process.type === PROCESS.TYPE.READY
  const isMatchOut = process.type === PROCESS.TYPE.MATCH_OUT
  const isPreDispatch = process.type === PROCESS.TYPE.PRE_DISPATCH
  const isImposition = process.type === PROCESS.TYPE.IMPOSITION
  const isDispatch = process.type === PROCESS.TYPE.DISPATCH
  const isPicking = process.type === PROCESS.TYPE.PICKING
  const isDefault = !isBarcode && !isQueued && !isPreDispatch && !isDispatch && !isAutomated

  const EditTextIcon = (isLocked && process.type !== PROCESS.TYPE.IMPOSITION) ? LockIcon : EditIcon

  const showEditTextModal = () => {
    setModal(itemID)
  }

  const closeModal = (evt) => {
    /**
     * Click events on the antd Modal component won't
     * work unless the event doesn't get propagated further.
     * 
     * More details on this link:
     * {@link https://github.com/ant-design/ant-design/issues/8738#issuecomment-354035083}
     **/
    if (evt) {
      evt.stopPropagation()
    }

    setModal(null)
    setFreightGroups(null)
    //setOriginalQueueRules(queueRules)
    setOriginalBatchRules(batchRules)
  }

  const showTriggerModal = triggerName => {
    setModal(`${itemID}>>${triggerName}`)
  };

  const removeBarcode = () => {
    onRemoveBarcode(process);
  }

  useEffect(() => {
    if (isPreDispatch) {
      ZenSmartAPI.get(fethAllFreightGroup)
        .then((res) => {
          setFreightGroups && setFreightGroups(res.data.data)
        })
        .catch((res) => {
        })
    }
  }, [productID, currentModal, isPreDispatch])

  const triggers = ['sendEmail', 'fieldUpdate', 'callout', 'addCost']
  const isTrigger = triggers.includes(dragType)

  return (
    <Droppable
      type={!isTrigger && process.attachables.includes(dragType) && dragType}
      droppableId={itemID}
    >
      {provided => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <Draggable
            draggableId={itemID}
            index={processIndex}
          >
            {(provided, snapshot) => (
              <Container
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getStyle(provided.draggableProps.style, snapshot)}
                data-with-sibling-arrow={process.sibling && 1}
              >
                <Wrapper>
                  <LibraryItem
                    style={{textTransform : "capitalize"}}
                    type={process.type}
                    text={process.text}
                  />

                  <NumberBadge>{processNumber}</NumberBadge>

                  <Icons>
                    {process.triggers.addCost ? (
                      <IconContainer
                        color={getColorFromType(process.type)}
                        onClick={() => showTriggerModal(PROCESS.TRIGGER.ADD_COST)}
                      >
                        <AddCostIcon width="8px" height="8px" fill="#fff" />

                        <AddCostTriggerModal
                          title={`[Add Cost] ${process.text}`}
                          visible={currentModal === `${itemID}>>${PROCESS.TRIGGER.ADD_COST}`}
                          onCancel={closeModal}
                          footer={null}
                          processID={itemID}
                          addCost={process.triggers.addCost}
                          onSave={updateTrigger}
                          removeTrigger={removeTrigger}
                        />
                      </IconContainer>
                    ) : null}

                    {process.triggers.callout ? (
                      <IconContainer
                        color={getColorFromType(process.type)}
                        onClick={() => showTriggerModal(PROCESS.TRIGGER.CALLOUT)}
                      >
                        <CalloutIcon width="8px" fill="#fff" />

                        <CalloutTriggerModal
                          title={`[Callout] ${process.text}`}
                          width={750}
                          visible={currentModal === `${itemID}>>${PROCESS.TRIGGER.CALLOUT}`}
                          onCancel={closeModal}
                          footer={null}
                          processID={itemID}
                          callout={process.triggers.callout}
                          onSave={updateTrigger}
                          removeTrigger={removeTrigger}
                          qualifiers={qualifiers}

                        />
                      </IconContainer>
                    ) : null}

                    {process.triggers.fieldUpdate ? (
                      <IconContainer
                        color={getColorFromType(process.type)}
                        onClick={() => showTriggerModal(PROCESS.TRIGGER.FIELD)}
                      >
                        <FieldUpdateIcon width="8px" fill="#fff" />

                        <FieldUpdateTriggerModal
                          title={`[Field Update] ${process.text}`}
                          visible={currentModal === `${itemID}>>${PROCESS.TRIGGER.FIELD}`}
                          onCancel={closeModal}
                          footer={null}
                          processID={itemID}
                          fieldUpdate={process.triggers.fieldUpdate}
                          onSave={updateTrigger}
                          removeTrigger={removeTrigger}
                          qualifiers={qualifiers}
                          width={700}

                        />
                      </IconContainer>
                    ) : null}

                    {process.triggers.sendEmail ? (
                      <IconContainer
                        color={getColorFromType(process.type)}
                        onClick={() => showTriggerModal(PROCESS.TRIGGER.EMAIL)}
                      >
                        <EmailIcon width="8px" fill="#fff" />

                        <EmailTriggerModal
                          title={`[Send Email] ${process.text}`}
                          width={700}
                          visible={currentModal === `${itemID}>>${PROCESS.TRIGGER.EMAIL}`}
                          onCancel={closeModal}
                          footer={null}
                          processID={itemID}
                          sendEmail={process.triggers.sendEmail}
                          onSave={updateTrigger}
                          removeTrigger={removeTrigger}
                          qualifiers={qualifiers}
                          recipients={recipients}
                          emailTemplates={emailTemplates}
                        />
                      </IconContainer>
                    ) : null}

                    <IconContainer
                      cursor={(isLocked && process.type !== PROCESS.TYPE.IMPOSITION) ? 'not-allowed' : null}
                      color={getColorFromType(process.type)}
                      onClick={showEditTextModal}
                    >

                      <EditTextIcon
                        width="8px"
                        height="8px"
                        fill="#fff"
                      />

                      <AutomatedStageModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && isAutomated}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        isChild={true}
                        onSave={updateProcess}
                        scanTypesOptions={automatedScanTypesOptions}
                      />

                      <PreDispatchModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && isPreDispatch}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        qualifiers={qualifiers}
                        preDistachRules={preDistachRules}
                        letterRange={letterRange}
                        freightGroups={freightGroups}
                        setFreightGroups={setFreightGroups}
                        setPreDistachRules={setPreDistachRules}
                        originalDispatchRules={originalDispatchRules}
                        setOriginalDispatchRules={setOriginalDispatchRules}
                      />

                      <DispatchModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && isDispatch}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        dispatchTemplatesOptions={dispatchTemplatesOptions}
                        onSave={updateProcess}
                      />

                      <ProcessItemTextModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && isDefault}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                      />

                      <BarcodeDetailsModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && (isBarcode || isImposition) && !isQueued}
                        onCancel={closeModal}
                        footer={null}
                        newItemType={process.type}
                        isChild={true}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        scanTypesOptions={scanTypesOptions}
                      />

                      <PickingModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && (isPicking)}
                        onCancel={closeModal}
                        footer={null}
                        newItemType={process.type}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        scanTypesOptions={scanTypesOptions}
                        pickingGroupOptions={pickingGroupOptions}
                        pickingComponents={pickingComponents}
                        pickingLocations={pickingLocations}
                        setPickingGroupOptions={setPickingGroupOptions}
                        setPickingLocations={setPickingLocations}
                        setPickingComponents={setPickingComponents}
                      />

                      <MatchOutModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && isMatchOut && !isBarcode}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        matchGroupOptions={matchGroupOptions}
                        getMatchRange={getMatchRange}
                        onSave={updateProcess}
                        matchOutRules={matchOutRules}
                        setMatchOutRules={setMatchOutRules}
                        originalMatchOutRules={originalMatchOutRules}
                        setOriginalMatchOutRules={setOriginalMatchOutRules}
                        getMatchGroup={getMatchGroup}
                        matchGroup={matchGroup} 
                        setMatchGroup={setMatchGroup}
                      />

                      <QueueRulesModal
                        visible={currentModal === itemID && isQueued}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        queueRules={queueRules}
                        setQueueRules={setQueueRules}
                        qualifiers={qualifiers}
                        originalQueueRules={originalQueueRules}
                        setOriginalQueueRules={setOriginalQueueRules}
                        forceSeparateQueues={forceSeparateQueues}
                        updateForceSeparateQueues={updateForceSeparateQueues}
                      />

                      <BatchRulesModal
                        visible={currentModal === itemID && isReady}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        batchRules={batchRules}
                        setBatchRules={setBatchRules}
                        qualifiers={qualifiers}
                        originalBatchRules={originalBatchRules}
                        setOriginalBatchRules={setOriginalBatchRules}
                        batchRulesOptions={batchRulesOptions}
                      />
                    </IconContainer>

                    {((isBarcode || isAutomated || isImposition || isPicking) && process.text !== 'QA Fail' && process.text !== 'Match In') &&
                      <IconContainer
                        color={color.red}
                        onClick={removeBarcode}
                      >
                        <CloseIcon
                          width="16px"
                          height="16px"
                          fill="#fff"
                        />
                      </IconContainer>
                    }
                  </Icons>
                </Wrapper>

                {/* {process.sibling && isLast && !isOneChild && (
                  <SiblingArrow
                    whitelist={process.text === "Imposition" || process.text === "Picking" || process.type === "Barcode Stage" || process.type === "Automated Stage" ? ['Barcode Stage', 'Automated Stage', 'Imposition', 'Picking'] : process.text === "Match Out" ? ['Barcode Stage', 'Automated Stage'] : process.sibling}
                    dragType={dragType}
                    processID={processID}
                    from='child'
                  />
                )} */}
              </Container>
            )}
          </Draggable>

          {/* <DropZone
            whitelist={process.text === "Imposition" || process.text === "Picking" || process.type === "Barcode Stage" || process.type === "Automated Stage" ? ['Barcode Stage', 'Automated Stage', 'Imposition', 'Picking'] : process.text === "Match Out" ? ['Barcode Stage', 'Automated Stage'] : process.sibling}
            dragType={dragType}
            processID={processID}
            stage={process.stage}
            root={false}
          /> */}

          {/**
            * @note 
            * Add the placeholder but put it in an element with a display
            * of _none_.
            * 
            * This is so we won't have that weird _auto-stretching_ whenever
            * a draggable item is hovering over any process item component 
            */}
          <span style={{ display: 'none' }}>{provided.placeholder}</span>
        </div>
      )}
    </Droppable>
  )
}

export default ChildProcessItem