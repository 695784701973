import React, { useState, useEffect } from 'react'
import { useParams } from "react-router";
import styled from 'styled-components'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { Form, Input, Button, Collapse, Select, Checkbox, InputNumber, notification } from 'antd'

import { Panel } from 'components/primaries'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { ZenSmartAPI } from 'utils'
import { productCreateRoute, productUpdateRoute } from "utils/apiRoutes"
import { alphabeticalData } from 'utils/sortData';
const Link = styled(RouterLink)`
  color: ${color.blue.normal};
`

const StyledForm = styled(Form)`
  margin: 16px;
`

const Field = styled(Form.Item)`
  margin-bottom: 16px;
  display: inline-block;
  width: 46%;
  margin: 0 20px;
`

const Header = styled.header`
  margin-bottom: 24px;
`

const SubmitContainer = styled.div`
  text-align: right;
`

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`



function ActualEditProductForm(props) {
  const productId = useParams().id;
  const { form, onSubmit } = props
  const { getFieldDecorator, getFieldsValue } = form

  const [submitting, setSubmitting] = useState(false)
  const [formItems, setFormItems] = useState();

  const createNewProduct = async (evt) => {
    evt.preventDefault()

    const getKeys = Object.keys(getFieldsValue());
    const payload = getFieldsValue()

    getKeys.map(data => {
      if (payload[data] === "null") {
        payload[data] = null
      }
      return data
    })

    onSubmit(payload, setSubmitting)
  }

  useEffect(() => {
    ZenSmartAPI.get(`${productCreateRoute}/${productId}`).then(res => {
      const sections = res.data.page_definitions.sections;

      const formItems = sections[0].section_data.map((section, i) => {
        const { label, field, data_type, options, required, value } = section;

        if (data_type === 'TEXT') {
          return (
            <Field key={i} label={label} hasFeedback>
              {getFieldDecorator(field, {
                valuePropName: field,
                initialValue: value,
                validate: [{
                  trigger: 'onBlur',
                  rules: [
                    { required: true, message: 'Input your Email!' },
                  ],
                }],
                // rules: [{ required, message: `Please input the ${label}!` }]
              })(<Input defaultValue={value} />)}
            </Field>
          )
        }

        if (data_type === 'INTEGER') {
          return (
            <Field key={i} label={label}>
              {getFieldDecorator(field, {
                valuePropName: field,
                initialValue: value,
                rules: [{ required, message: `Please input the ${label}!` }]
              })(<InputNumber defaultValue={value} />)}
            </Field>
          )
        }

        if (data_type === 'SELECT') {
          return (
            <Field key={i} label={label}>
              {getFieldDecorator(field, {
                valuePropName: field,
                initialValue: (value === null ? '' : value.toString()),
                rules: [{ required, message: `Please input the ${label}!` }]
              })(
                <Select defaultValue={(value === null ? '' : value.toString())}>
                  {alphabeticalData(options, "text").map(item => <Select.Option key={item.value}>{item.text}</Select.Option>)}
                </Select>
              )}
            </Field>
          )
        }

        if (data_type === 'BOOLEAN') {
          return (
            <Field key={i} label={label}>
              {getFieldDecorator(field, {
                valuePropName: field,
                initialValue: value,
                rules: [{ required, message: `Please input the ${label}!` }]
              })(<Checkbox defaultChecked={value} />)}
            </Field>
          )
        }

        return false;
      })

      setFormItems(formItems);
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledForm onSubmit={createNewProduct} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
      <Collapse activeKey="0">
        <Collapse.Panel key="0" showArrow={false} header="Product Definition">
          {formItems}
          <SubmitContainer>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting}
            >
              Save Product
            </Button>
          </SubmitContainer>
        </Collapse.Panel>
      </Collapse>
    </StyledForm>
  )
}

const EditProductForm = Form.create({ name: 'Create Product Form' })(ActualEditProductForm)

const constructPayload = (data, productId) => {
  const payload = [{ resource: 'products', id: productId, data: [] }]

  for (var key in data) {
    const item = {}
    item.field = key;
    item.value = data[key] !== null ? data[key].toString() : data[key];
    payload[0].data.push(item);
  }

  return payload;
}

const EditProduct = () => {
  const history = useHistory()
  const productId = useParams().id;

  const handleSubmit = async (data, setSubmitting) => {
    const payload = constructPayload(data, productId);
    setSubmitting(true)

    ZenSmartAPI.patch(`${productUpdateRoute}/${productId}`, { payload }).then(result => {

      if (result.status === 200) {
        notification.success({
          message: 'Product Successfully Updated.'
        })
        history.push(`/app/prep/product-flow?product_id=${productId}`)
      } else {
        notification.error({
          message: 'Something went wrong while saving the product. Please try again later.'
        })
      }

      setSubmitting(false)
    }).catch(error => {
      setSubmitting(false)
      notification.error({
        message: 'Something went wrong while saving the product. Please try again later.'
      })
    })
  }

  return (
    <>
      <Header>
        <Title>
          <Link exact={1} to="/app/prep/product-flow">Product Flow</Link> {">"} Create Product
        </Title>
      </Header>

      <Panel title="EDIT PRODUCTS">
        <div style={{ padding: "0px 15px" }}>
          <Button type="primary"  onClick={() => history.push(`/app/prep/product-flow?product_id=${productId}`) }>Go Back</Button>
        </div>

        <EditProductForm onSubmit={handleSubmit} />
      </Panel>
    </>
  )
}

export default EditProduct