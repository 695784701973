import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, Button, Input } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenSmartAPI } from 'utils'
import { getEditableSettingsRoute } from "utils/apiRoutes"
import moment from 'moment';
import { settingsInitialData } from "utils/panelsInitialData"

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
  .ant-drawer-body {
    overflow: auto;
    height: calc(100vh - 100px); // 100px would be height of header + height of footer
  }
`
const SettingsPage = (props) => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [originalData, setOriginalData] = useState([]);
    const [ascend, setAscend] = useState(settingsInitialData)

    const fetchData = () => {
        setLoading(true)
        ZenSmartAPI.get(getEditableSettingsRoute)
            .then((res) => {
                setLoading(false)
                setItemData(res.data.data)
                setOriginalData(res.data.data)
                setAscend(settingsInitialData)
            })
            .catch((error) => {
                setLoading(false);
                // handle validation errors
                notification.error({ message: "Failed fetching Data!" })
            })
    }

    const searchOperation = async (value, source) => {
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    // if null ignore
                    if (anItemValue === null) {
                        return false
                    }
                    // handle json special setting
                    if (typeof anItemValue === "object") {
                        return "Click to view".toString()
                            .toLowerCase()
                            .includes(value.toLowerCase())
                    }

                    // handle objects
                    if (anItemValue.value) {
                        return anItemValue.value.toString()
                            .toLowerCase()
                            .includes(value.toLowerCase())
                    }

                    // handle dates
                    if (moment.unix(anItemValue).isValid()) {
                        return moment.unix(anItemValue).format('D MMM YYY, h:mma')
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase());
                    }

                    // plain values
                    return anItemValue.replace(/_/g, ' ').includes(value.toLowerCase())
                });
                return containsValue;
            })
            setItemData(result)
        }
    }

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>App Setup {">"} Settings</Title>
            </Header>
            <Panel title="SETTINGS">
                <div style={{ padding: "10px 50px 20px 50px" }}>
                    <InputStyle />
                    <Row type="flex" align="middle">
                        <InputStyle />
                        <Col span={4} style={{ padding: 10 }}>
                            <Input.Search
                                type="text"
                                placeholder="Filters.."
                                disabled={loading}
                                onChange={(value) => searchOperation(value.target.value, originalData)}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </div>
                <TableData loading={loading} fetchData={fetchData} setLoading={setLoading} data={itemData} setData={setItemData} ascend={ascend} setAscend={setAscend} />
            </Panel>
        </>
    )
}

export default SettingsPage