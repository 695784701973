import _ from "lodash";
import is from "is_js";
import { FormTypes } from "../config/Form";
import { MULTIPLE_NUMBER_TYPE, MULTIPLE_TYPE } from "../config/Imposition";

export const getProperty = (properties, key, defaultKey = false) => {
    const prop = properties.find((property) => {
        const propKey = property.key;
        return (Array.isArray(propKey) && propKey.includes(key)) || propKey === key;
    });

    if (prop) {
        return prop.value;
    } else if (defaultKey) {
        return key;
    } else {
        return "";
    }
};

export const getPropertyExcept = (properties, key, recipeLib) => {
    const prop = properties.find((property) => {
        const propKey = property.key;
        return (Array.isArray(propKey) && propKey.includes(key)) || propKey === key;
    });

    if (prop) {
        return (prop.except ? (prop.except.includes(recipeLib) ? true : false ) : false);
    }else {
        return false;
    }
};

export const packMultiType = (initialRecipes) => {
    const cloneRecipes = _.cloneDeep(initialRecipes);

    cloneRecipes.forEach((cloneRecipe) => {
        if (is.not.empty(cloneRecipe.parameters)) {
            cloneRecipe.parameters.forEach((parameter, index) => {
                const formType = getProperty(FormTypes, parameter.name);

                if (formType == MULTIPLE_NUMBER_TYPE || formType == MULTIPLE_TYPE){
                    let paramVal = [];

                    if (is.existy(parameter.value) && parameter.value.length > 0){
                        paramVal = parameter.value.split(',');
                    }

                    parameter.value = paramVal;
                }
            });
        }
    });

    return cloneRecipes;
};

export const unpackMultiType = (initialRecipes) => {
    const cloneRecipes = _.cloneDeep(initialRecipes);

    cloneRecipes.forEach((cloneRecipe) => {
        if (is.not.empty(cloneRecipe.parameters)) {
            cloneRecipe.parameters.forEach((parameter) => {
                const formType = getProperty(FormTypes, parameter.name);
                if (is.existy(parameter.value) && (formType == MULTIPLE_NUMBER_TYPE || formType == MULTIPLE_TYPE)){
                    parameter.value = parameter.value.toString();
                }
            });
        }
    });

    return cloneRecipes;
};
