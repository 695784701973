import { handleRowStyle } from './TableUtilities';
export const TABLE_COLUMNS = [
    {
        title     : '',
        key       : 'title',
        align     : 'left',
        dataIndex : 'title',
        width     : 'min-content',
        type      : 'string',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'Linear Feet Production',
        key       : 'linear_feet_production',
        align     : 'center',
        dataIndex : 'linear_feet_production',
        width     : 'min-content',
        type      : 'string',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'Total',
        key       : 'total',
        dataIndex : 'total',
        align     : 'center',
        width     : '100px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '12AM-6AM',
        key       : '12am-6am',
        dataIndex : '12am-6am',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '6AM-7AM',
        key       : '6am-7am',
        dataIndex : '6am-7am',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '7AM-8AM',
        key       : '7am-8am',
        dataIndex : '7am-8am',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '8AM-9AM',
        key       : '8am-9am',
        dataIndex : '8am-9am',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '9AM-10AM',
        key       : '9am-10am',
        dataIndex : '9am-10am',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '10AM-11AM',
        key       : '10am-11am',
        dataIndex : '10am-11am',
        align     : 'center',
        width     : '140px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '11AM-12PM',
        key       : '11am-12pm',
        dataIndex : '11am-12pm',
        align     : 'center',
        width     : '140px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '12PM-1PM',
        key       : '12pm-1pm',
        dataIndex : '12pm-1pm',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '1PM-2PM',
        key       : '1pm-2pm',
        dataIndex : '1pm-2pm',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '2PM-3PM',
        key       : '2pm-3pm',
        dataIndex : '2pm-3pm',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '3PM-4PM',
        key       : '3pm-4pm',
        dataIndex : '3pm-4pm',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '4PM-5PM',
        key       : '4pm-5pm',
        dataIndex : '4pm-5pm',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '5PM-6PM',
        key       : '5pm-6pm',
        dataIndex : '5pm-6pm',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '6PM-7PM',
        key       : '6pm-7pm',
        dataIndex : '6pm-7pm',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '7PM-8PM',
        key       : '7pm-8pm',
        dataIndex : '7pm-8pm',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '8PM-9PM',
        key       : '8pm-9pm',
        dataIndex : '8pm-9pm',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '9PM-10PM',
        key       : '9pm-10pm',
        dataIndex : '9pm-10pm',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '10PM-11PM',
        key       : '10pm-11pm',
        dataIndex : '10pm-11pm',
        align     : 'center',
        width     : '140px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : '11PM-12AM',
        key       : '11pm-12am',
        dataIndex : '11pm-12am',
        align     : 'center',
        width     : '140px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
];

