import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
    Alert,
    Button,
    Col,
    Form,
    Icon as AIcon,
    Input,
    InputNumber,
    Modal,
    notification,
    Row,
    Select,
} from 'antd';
import * as _ from 'lodash';
import styled, { css } from 'styled-components';
import { Label } from 'components/zensmart-design-system/components/Label/Label.js';
import { Panel } from '../../../components/primaries';
import { ZenSmartAPI } from 'utils';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { GET_DISPATCH_LINES } from '../../../store/actions/dispatchTypes';
import {
    dispatchConsigmentRoute,
    dispatchScanRoute,
    normalBarcodeScanRoute,
} from 'utils/apiRoutes';
import PrintThisComponent from 'components/composites/printTicket';
import OverrideShippingVerificationModal
    from './OverrideShippingVerificationModal';
import PostDispatchPromptModal from './PostDispatchPromptModal';
import AccesoryModal from './AccesoryModal';
import DispatchPanel from './DispatchPanel/DispatchPanelPage';
import { defaultGamificationQuizValue, fetchGamificationQuiz, resetState } from 'components/sections/Gamification/helper';
import GameView, { Spinner } from 'components/sections/Gamification/GameView';
import { formatPickupData } from './mockData';
import PickUpNotesModal from "./PickUpNotesModal";
import Image from "components/zensmart-design-system/components/Image/Image"
import { ExclamationCircleOutlined } from '@ant-design/icons';

const panelStylings = css`
  margin-bottom: 24px;
  padding-bottom: 50px;
`
const StyledRow = styled(Row)`
  margin-bottom: 24px;
`

const CenterButton = styled.p`
  text-align : center;
`
const WhiteLabel = styled.span`
  color: red;
  font-size: 24px;
  font-weight: 500;
  margin: 5px;
`
const FirstClasMail = styled.span`
  margin-right: auto;
  color: darkred;
  font-size: 24px;
  font-weight: 500;
  margin: 5px;
`
const FlexImageWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;

  div {
    padding: 0 5px;
    text-align: center;
    margin-bottom: 5px;
  }
`;
const LookupForm = Form.create({ name: 'LookupForm' })(props => {
    const dispatch = useDispatch();
    const { onSubmit, form, scanTypeId, dispatchStructure, inputProps, showPickupNotesModal, setShowPickupNotesModal,
        dispacthModal, showDispatchwModal, changeDispatchStructureData, updateScannedLines, scannedLines, refreshDispatch, dispatchData,
        scanAccessories, setScanAccessories, setAccessoriesToBeScan, accessoriesToBeScan, setAccessoriesModal, accessoriesModal,
        loading, setLoading, children, overrideConfirmed, setOverrideConfirmed, setShowPrintDispatch, setPrintDispatch, showPrintDispatch, printDispatch, isPickUp, showBin } = props
    const [showOverrideModal, setShowOverrideModal] = useState(false)
    const [showPostDispatchPromptModal, setPostDispatchPromptModal] = useState(false);
    const [scanGamification, setScanGamification] = useState(defaultGamificationQuizValue)
    const [scannedInput, setScannedInput] = useState(null);
    const [consignmentPreview, setConsignmentPreview] = useState(null);
    const [numberOfArticles, setNumberOfArticles] = useState(null);
    const [preferredPackType, setPreferredPackType] = useState(null);
    const { getFieldDecorator, getFieldValue, resetFields } = form
    const [inputAccessoryState, setInputAccessoryState] = useState(null);
    const [disableButtons, setDisableButtons] = useState(false)
    const [ifShipNow, setIfShipNow] = useState(false);
    const [pickupNotes, setPickUpNotes] = useState(null);
    const scanInput = useRef(null)
    const refocusQueueID = useRef(null)
    const accesoryInput = useRef(null)
    const preferredPackTypeUpdated = useRef(false);
    const preferredPackTypeInput = useRef(null);

    const { dispatchNowCount, setDispatchNowCount } = props;
    const { Option } = Select;

    useEffect(() => {

        const afterPrintHandler = () => {
            scanInput.current.focus()
        }

        window.addEventListener('afterprint', afterPrintHandler)

        return () => {
            window.removeEventListener('afterprint', afterPrintHandler)
        }

    }, [])

    const clearRefocusQueue = () => {
        clearTimeout(refocusQueueID.current)
    }

    const refocusQueue = () => {
        refocusQueueID.current = setTimeout(() => {
            // only refocus if the active element is not any kind of an input
            let activeElement = document.activeElement;
            let inputs = ['input', 'select', 'button', 'textarea'];

            if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
                return;
            }
            if (scanInput.current) {
                scanInput.current.focus()
            }
        }, 5000)
    }

    const checkBarcodeIfScanned = (value) => {
        const initialDispatchStructure = dispatchStructure
        const barcodeAlreadyScanned = { value: false }
        initialDispatchStructure.line_items.map(data => {
            if (data.status === "scanned") {
                const checkBardcodeAlradyScanned = data.barcodes.some((item) => {
                    return item === value
                })
                if (checkBardcodeAlradyScanned) {
                    barcodeAlreadyScanned.value = true
                }
                if (value[0] === "L") {
                    if (value.substring(1) === data.number) {
                        barcodeAlreadyScanned.value = true
                    }
                }
            }
            return;
        })
        return barcodeAlreadyScanned.value
    }

    const scanAccessoriesFunction = (evt) => {
        if (evt.keyCode === 13) {

            if (accessoriesToBeScan.barcode === evt.target.value) {
                setAccessoriesModal(false)
                notification.success({ message: "Scan successful!" })
                setScanAccessories(scanAccessories.filter(data => data.id != accessoriesToBeScan.id))
                checkAccessorriesIfAllScanned(scanAccessories.filter(data => data.id != accessoriesToBeScan.id))
            }
            else {
                notification.error({ message: "Barcode not match!" })
                if (inputAccessoryState) {
                    inputAccessoryState.input.value = ""
                }
            }

        }
    }

    const checkAccessorriesIfAllScanned = (data) => {
        const findAccessoryNotScanned = data.find(data => data.scanned === false)
        console.log(`findAccessoryNotScanned`, findAccessoryNotScanned)
        if (findAccessoryNotScanned && !showBin) {
            setAccessoriesToBeScan(findAccessoryNotScanned)
            setAccessoriesModal(true)
            setConsignmentPreview(null)
            setShowOverrideModal(false)
            showDispatchwModal("cancel")
        }
        else {
            setAccessoriesModal(false)
            checkLinesIfAllScanned(data, false)
        }
    }

    const checkLinesIfAllScanned = (accessories, shipNow = false) => {

        // If the shipping verification is required and if the override it not yet confirmed,
        // dont ship yet.
        if (!overrideConfirmed &&
            dispatchStructure.override_shipping_verification === true
        ) {
            return;
        }

        if (dispatchNowCount === null && !shipNow) {
            const allLinesNotScanned = dispatchStructure.line_items.some(data => {
                return data.status === 'not-scanned';
            });

            if (allLinesNotScanned) {
                return;
            }
        } else if (dispatchNowCount && !shipNow) {
            if (dispatchNowCount !== dispatchStructure.line_items.filter(line => line.status === 'scanned').length) {
                return;
            }
        }
        if (accessories.length > 0 && !showBin) {
            checkAccessorriesIfAllScanned(accessories)
            return
        }
        if (dispatchStructure.dispatch_confirmation_required && !shipNow && !showBin) {
            const payload = {
                station_id: Cookies.get('STATION'),
                lines: scannedLines.lines.map(line => line.id),
            };
            ZenSmartAPI.post('/api/v1/consignments/preview', payload)
                .then((response) => {
                    setConsignmentPreview(response.data.data);

                    // only needed when pack types list is present -- only for PQ
                    if (dispatchStructure.pack_types) {
                        //set selected preferred pack type on payload
                        if (response.data.data.pack_breakdowns) {
                            setPreferredPackType(_.get(response, 'data.data.pack_breakdowns.0.preferred', null));
                        }

                        // set selected number of articles on payload
                        if (response.data.data.number_of_articles) {
                            setNumberOfArticles(_.get(response, 'data.data.number_of_articles', 1));
                        }
                    }

                    if (dispatchStructure.allow_change_article_count)
                    {
                        // set selected number of articles on payload
                        if (response.data.data.number_of_articles) {
                            setNumberOfArticles(_.get(response, 'data.data.number_of_articles', 1));
                        }
                    }

                    setIfShipNow(true)
                    showDispatchwModal();
                })
                .catch((error) => {
                    if (error.response.data && error.response.data.message) {
                        notification.error({ message: error.response.data.message })
                    }
                });
            return;
        }

        if (_.get(dispatchStructure, 'isPickUp', false) && pickupNotes === null) {
            setShowPickupNotesModal(true);
            showDispatchwModal("cancel")
            return;
        }

        shipScannedLines();
    }

    const scanLines = (value, notif) => {
        const initialDispatchStructure = dispatchStructure
        const ifBarcodeExist = { value: false }
        const updateLineItems = initialDispatchStructure.line_items.map(data => {

            const checkBardcodeExist = data.barcodes.some((item) => {
                return item === value
            })
            if (value && value[0] === "L") {
                if (value.substring(1) === data.number) {
                    data.status = "scanned";
                    ifBarcodeExist.value = true
                    updateScannedLines(data)
                    checkLinesIfAllScanned()
                }
            }
            const check = isPickUp === true ? data.number === value : checkBardcodeExist
            if (check) {
                data.status = "scanned";
                ifBarcodeExist.value = true
                updateScannedLines(data)
                checkLinesIfAllScanned(scanAccessories)
            }
            return data;
        })
        if (ifBarcodeExist.value === true) {
            scanInput.current.focus()
            // notification.success({
            //     message: 'Barcode scan successful!'
            // })
        }
        else if (notif) {
            scanInput.current.focus()
            notification.error({
                message: 'Invalid barcode scan!'
            })
        }
        else {
            scanInput.current.focus()
        }
        initialDispatchStructure.line_items = updateLineItems;
        resetFields()
        changeDispatchStructureData(initialDispatchStructure)
    }

    const handleDownloadDocket = async () => {
        try {
            if (!dispatchStructure ?.manual_pickup_id) {
                notification.error({ message: `Missing manual pickup id to generate docket` })
                return;
            }
            const url = `${process.env.REACT_APP_ZENSMART_API_SERVER}/api/v1/consignments/docket/${dispatchStructure.manual_pickup_id}?allLinesScaned=true`
            const token = localStorage.getItem('token')
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Docket.pdf')
                document.body.appendChild(link)
                link.click()
                setLoading(false);
                refreshDispatch(`Pickup Scan Successful`);
                scanInput.current.focus()
            })
        } catch (error) {
            notification.error({
                message:
                    error ?.response ?.data ?.message ??
                        error ?.message ??
                            'Error downloading docket'
            })
            setLoading(false);
            scanInput.current.focus()
            refreshDispatch();
        }
    }

    const shipScannedLines = () => {
        setLoading(true)
        const payload = { lines: [] }
        payload.lines = scannedLines.lines.map(data => {
            return data.id;
        })
        payload.station_id = Cookies.get('STATION');

        // set user selected pack type
        if (preferredPackType) {
            payload.preferredPackType = preferredPackType;

            // If user has typed in a valid pack type code, then we will set that as the preferred pack type
            if(preferredPackTypeInput.current) {
                const pType = dispatchStructure.pack_types.find(p => p.code === preferredPackTypeInput.current.input.value);
                if (pType) {
                    payload.preferredPackType = pType.code;
                } else if (pType === undefined && preferredPackTypeInput.current.input.value.length > 0) {
                    notification.error({
                        message: 'Invalid Pack Type - please check the value and try again'
                    })
                    setLoading(false);
                    return;
                }
            }

        }

        if (numberOfArticles) {
            payload.numberOfArticles = numberOfArticles;
        }

        if (pickupNotes) {
            payload.pickupNotes = pickupNotes;
        }

        if (isPickUp === true) return handleDownloadDocket();
        return ZenSmartAPI.post(dispatchConsigmentRoute, payload)
            .then(response => {
                setLoading(false);
                response = response.data.data;
                if (response.label.has_multiple_labels) {
                    notification.info({ message: 'Multiple labels will be printed' })
                }
                let successMessage = 'Dispatch successful!';
                setAccessoriesToBeScan(undefined)
                if(!showBin) { setPickUpNotes(null) }

                if (_.isObject(response) && response.label.use_osi) {
                    if (response.label ?.osi_path_secondary && response.label.osi_path_secondary !== "") window.location.href = response.label.osi_path_secondary;
                    setTimeout(() => { window.location.assign(response.label.osi_path) }, 1000);
                }

                if (_.get(response, 'invoice.osi_path')) {
                    setTimeout(() => {
                        window.location.assign(response.invoice.osi_path);
                    }, 1500);
                }

                if (_.isObject(response) && response.label.type === 'HTML') {
                    // TODO: Change print component to accept HTML in uppercase
                    response.label.type = 'html';
                    setPrintDispatch(response);
                    if(!showBin) {
                        setShowPrintDispatch(true);
                        setShowPrintDispatch(false);
                    }
                }

                if (_.isObject(response) && response.label.type === 'PNG') {
                    successMessage = 'Dispatch successful! Label print dialog should appear shortly.';
                    setPrintDispatch({
                        label: {
                            content: response.label.image_url,
                            type: 'PNG'
                        }
                    })
                    if (!showBin) {
                        setShowPrintDispatch(true);
                    }
                } else {
                    if (_.isObject(response) && response.label.type === 'HTML') {
                        // TODO: Change print component to accept HTML in uppercase
                        response.label.type = 'html';
                        setPrintDispatch(response)
                        if (!showBin) {
                            setShowPrintDispatch(true);
                        }
                    }

                    if (_.isObject(response) && response.label.type === 'PNG') {
                        successMessage = 'Dispatch successful! Label print dialog should appear shortly.';
                        setPrintDispatch({
                            label: {
                                content: response.label.image_url,
                                type: 'PNG'
                            }
                        })
                        if (!showBin) {
                            setShowPrintDispatch(true);
                        }
                    }
                }

                if (dispatchStructure.post_dispatch_prompts.length > 0) {
                    dispatchStructure.post_dispatch_prompts.forEach(post_dispatch_prompt => {
                        if (post_dispatch_prompt.type == 'print') {
                            window.location = post_dispatch_prompt.osi_path;
                        } else {
                            setPostDispatchPromptModal(true);
                            return;
                        }
                    });
                } else {
                    refreshDispatch(successMessage);
                    scanInput.current.focus()
                }

            })
            .catch((error) => {
                const { response } = error;
                setLoading(false)
                scanInput.current.focus()
                refreshDispatch();
                if(!showBin) { setPickUpNotes(null); }

                const osiPath = _.get(response, 'data.label.osi_path', null);
                if (osiPath) {
                    window.location = osiPath;
                }

                if (response.status >= 400 && response.status < 499 && response.data.hasOwnProperty('message')) {
                    // use modal error instead of notiication to capture all the long error message
                    Modal.error({
                        title: 'Consignment Failed',
                        content: <div dangerouslySetInnerHTML={{ __html: response.data.message }}></div>,
                    })
                }
                else {
                    notification.error({
                        message: 'Dispatch not successful!',
                        duration: 10
                    })
                    setAccessoriesToBeScan(undefined)
                }
            })
    }

    const onOverrideVerificationModalClose = (shouldRefreshDispatch = true) => {
        setShowOverrideModal(false);
        if (shouldRefreshDispatch) {
            refreshDispatch();
        }
    };

    const handleOverrideShippingVerificationWithDispatchNow = (count) => {
        setShowOverrideModal(false);
        setDispatchNowCount(count);
    }

    useEffect(() => {
        if (dispatchNowCount !== null) {
            checkLinesIfAllScanned(scanAccessories)
        }
    }, [dispatchNowCount])

    const onPickupNoteSaved = () => {
        checkLinesIfAllScanned(scanAccessories, ifShipNow);
    }

    const onPickupModalCancel = () => {
        refreshDispatch('Dispatch process has been cancelled!');
    }

    const handleOverrideShippingVerification = () => {
        dispatchStructure.line_items = dispatchStructure.line_items.map(data => {
            data.status = 'scanned';
            updateScannedLines(data);
            return data;
        });

        changeDispatchStructureData(
            dispatchStructure
        );

        if (dispatchStructure.dispatch_confirmation_required) {
            const payload = {
                station_id: Cookies.get('STATION'),
                lines: scannedLines.lines.map(line => line.id)
            };
            ZenSmartAPI.post('/api/v1/consignments/preview', payload)
                .then((response) => {
                    onOverrideVerificationModalClose(false);
                    setConsignmentPreview(response.data.data)

                    // only needed when pack types list is present -- only for PQ
                    if (dispatchStructure.pack_types) {
                        //set selected preferred pack type on payload
                        if (response.data.data.pack_breakdowns) {
                            setPreferredPackType(_.get(response, 'data.data.pack_breakdowns.0.preferred', null));
                        }

                        // set selected number of articles on payload
                        if (response.data.data.number_of_articles) {
                            setNumberOfArticles(_.get(response, 'data.data.number_of_articles', 1));
                        }
                    }

                    setIfShipNow(true)
                    showDispatchwModal();
                }).catch(error => {
                    onOverrideVerificationModalClose(false);
                    const { response } = error;
                    if (response.status < 500) {
                        notification.error({
                            message: response.data.message
                        })
                        return;
                    }
                    notification.error({
                        message: 'Something went wrong while trying to get consignment preview details.'
                    })
                });
        } else {
            Promise.resolve(checkLinesIfAllScanned(scanAccessories)
            ).then(() => {
                onOverrideVerificationModalClose(false);
                setDisableButtons(false)
            });
        }
    };

    const handlePostDispatchPromptOk = () => {
        setPostDispatchPromptModal(false);
        if (printDispatch !== null) {
            setShowPrintDispatch(true);
        }

        setTimeout(() => {
            refreshDispatch();
        }, 500)
    }

    const setDispatchDatas = (res, input_value) => {
        if (res.data.data.override_shipping_verification) {
            setShowOverrideModal(res.data.data.override_shipping_verification);
            clearRefocusQueue();
        }
        setScannedInput(input_value);
        setLoading(false)
        resetFields()
        resetState(setScanGamification)
        dispatch({
            type: GET_DISPATCH_LINES,
            payload: res.data.data
        })
    }

    const handleSubmit = async (evt) => {
        if (evt) evt.preventDefault();
        const input_value = getFieldValue('lookup_text');
        let payload = {};

        if (accessoriesToBeScan) {
            return
        }
        if (input_value === undefined) {
            scanInput.current.focus()
            return onSubmit({ error: 'Please scan a barcode' })
        }
        if(showBin && !pickupNotes) {
            return onSubmit({ error: 'Bin must be entered' })
        }

        else if (input_value.includes('STATION') === true) {
            setLoading(true)
            payload = { barcode: input_value, scan_type_id: scanTypeId }
            return ZenSmartAPI.post(normalBarcodeScanRoute, payload)
                .then((res) => {
                    setLoading(false)
                    resetFields()
                    scanInput.current.focus()
                    if (res.data && res.data.duplicate_scan_prompt) {
                        Modal.confirm({
                            'content': res.data.duplicate_scan_prompt,
                            onOk: () => {
                            },
                            cancelButtonProps: { style: { display: "none" } }
                        });
                    }
                    onSubmit({ ...res.data, success_scan: true })
                    return;
                })
                .catch((res) => {
                    setLoading(false)
                    scanInput.current.focus()
                    resetFields()
                    res.response.data.hasOwnProperty('message') ? onSubmit({ error: res.response.data.message }) : onSubmit('Not a valid Scan Item');
                })
        }

        else if ((input_value.includes('STATION') === false && Cookies.get('STATION') === undefined)
            || parseInt(Cookies.get('SCAN_TYPE')) !== parseInt(scanTypeId)) {
            resetFields()
            scanInput.current.focus()
            return onSubmit({ error: 'Please scan station card first' })
        }

        else if (dispatchStructure) {
            const ifScanned = checkBarcodeIfScanned(input_value);
            scanInput.current.focus()
            resetFields()
            if (ifScanned) {
                notification.error({
                    message: 'Barcode has already been scanned!'
                })
            }
            else {
                scanLines(input_value, true);
            }
            return

        }

        else {
            payload = { station_id: Cookies.get('STATION'), barcode: input_value, scan_type_id: scanTypeId };
            if (scanGamification.isVerified === false && input_value.includes('STATION') === false && Cookies.get('ALLOW_QUIZ') === 'true') {
                const quizCheck = await fetchGamificationQuiz(payload, setLoading, setScanGamification, scanInput);
                if (quizCheck === true) return;
            }
            setLoading(true)
            scanInput.current.focus()
            ZenSmartAPI.get(dispatchScanRoute(input_value, isPickUp, showBin === true))
                .then((res) => {
                    if (res.data.data.pre_dispatch_prompts && res.data.data.pre_dispatch_prompts.text) {
                        Modal.confirm({
                            'content': res.data.data.pre_dispatch_prompts.text,
                            onOk: () => {
                                setDispatchDatas(res, input_value)
                            },
                            onCancel: () => {
                                setLoading(false)
                                resetFields()
                                resetState(setScanGamification)
                                refreshDispatch()

                            }
                        });
                    }
                    else if (res.data.data?.enforce_stages) {
                        Modal.confirm({
                            title: 'Warning',
                            icon: <ExclamationCircleOutlined />,
                            'content': (
                                <div dangerouslySetInnerHTML={{ __html: res.data.data.enforce_stages }} />
                            ),
                            onOk: () => {
                                setDispatchDatas(res, input_value)
                            },
                            onCancel: () => {
                                setLoading(false)
                                resetFields()
                                resetState(setScanGamification)
                                refreshDispatch()
                            }
                        });
                    }
                    else {
                        setDispatchDatas(isPickUp === true ? formatPickupData(res.data.data) : res, input_value)
                    }
                })
                .catch((res) => {
                    resetFields()
                    resetState(setScanGamification)
                    setLoading(false)
                    if (res ?.response ?.data.hasOwnProperty('message')) {
                        showInternalMessage(res.response.data.message)
                    }
                    else {
                        notification.error({
                            message: 'Invalid barcode scan!'
                        })
                    }

                })
        }

    }

    const showInternalMessage = (message) => {
        if (Array.isArray(message)) {
            if (message.length !== 0) {
                const messageText = message.join('<br />')
                Modal.info({
                    title: 'Hold At Dispatch',
                    icon: <AIcon type='exclamation' />,
                    content: <div dangerouslySetInnerHTML={{ __html: messageText }}></div>,
                })
            }
        } else {
            notification.error({
                message: message
            })
        }
    }

    useEffect(() => {
        if (scanInput.current) {
            scanInput.current.focus()
        }
        return clearRefocusQueue
    }, [])


    useEffect(() => {
        if (dispatchStructure) {
            scanLines(scannedInput)
        }
        scanInput.current.focus()
    }, [dispatchStructure])

    const renderThumbnails = () => {

        if (dispatchStructure === null) {
            return null;
        }


        const selectedOrders = dispatchStructure.orders.filter(order => {
            return scannedLines.lines.find(line => order.id === line.order_id)
        })

        return selectedOrders.map(data => (
            <FlexImageWrapper>
                {data.thumbnails.map(item =>
                    <Image
                        src={item}
                        alt=""
                        width={160}
                        height={300}
                        objectFit="fill"
                    />
                )}
            </FlexImageWrapper>
        ))
    }
    useEffect(() => {
        if (scanGamification.isVerified === true) handleSubmit()
    }, [scanGamification.isVerified])

    useEffect(() => {
        if (preferredPackTypeUpdated.current) {
            preferredPackTypeUpdated.current = false;
            checkLinesIfAllScanned(scanAccessories, ifShipNow);
        }
    }, [preferredPackType])

    // Update dispatch structure with preferred pack type
    const onPackTypeSelected = (packType) => {
        // do not call ship method
        preferredPackTypeUpdated.current = false;

        const pType = dispatchStructure.pack_types.find(p => p.code === packType);
        if (pType) {
            setPreferredPackType(pType.code);
        }
    }

    // Update dispatch structure with preferred pack type
    const onPackTypeKeyDown = (event) => {
        event.persist();
        if (event.key === 'Enter') {
            let packType = event.target.value;

            // simply call ship method when packtype is same, don't need to fire with useEffect
            if (preferredPackType === packType) {
                preferredPackTypeUpdated.current = false;
                checkLinesIfAllScanned(scanAccessories, ifShipNow);
                return;
            }

            preferredPackTypeUpdated.current = true;
            const pType = dispatchStructure.pack_types.find(p => p.code === event.target.value);
            if (pType) {
                setPreferredPackType(pType.code);
            }
        } else {
            // Set to false so that it won't ship instantly
            preferredPackTypeUpdated.current = false;
            const pType = dispatchStructure.pack_types.find(p => p.code === event.target.value);
            if (pType) {
                setPreferredPackType(pType.code);
            }
        }
    }

    return (
        <>
            <div>
                {(dispatchNowCount && dispatchNowCount > 1) &&
                    <StyledRow type="flex" justify="center" align="middle">
                        <Alert
                            message={'Please scan the other Lines being partial dispatched.'} />
                    </StyledRow>
                }
                <Form onSubmit={handleSubmit}>
                    <StyledRow type="flex" justify="center" align="middle">
                        { showBin == true &&
                        <Col span={8} style={{ 'padding-right': 10 }}>
                            {getFieldDecorator('lookup_bin', { initialValue: pickupNotes })
                            (
                                <Input
                                    {...inputProps}
                                    type="text"
                                    placeholder="Bin"
                                    ref={pickupNotes}
                                    onFocus={clearRefocusQueue}
                                    onBlur={refocusQueue}
                                    onChange={(e) => setPickUpNotes(e.target.value)}
                                />
                            )}
                        </Col> }
                        <Col span={8}>
                            {getFieldDecorator('lookup_text')(
                                <Input.Search
                                    type="text"
                                    placeholder="Item Lookup"
                                    ref={scanInput}
                                    onFocus={clearRefocusQueue}
                                    onBlur={refocusQueue}
                                    disabled={loading}
                                    loading={loading}
                                    {...inputProps}
                                />
                            )}
                        </Col>
                        { showBin == true && <Button className='invisible' type="primary" htmlType="submit"></Button> }
                        {/*<Col span={1}></Col>*/}
                        {/*<Col xxl={4} xl={6} style={{ padding: 5 }}>*/}
                        {/*    <CenterButton>*/}
                        {/*        <Button style={{ width: "100%" }} disabled={scannedLines.lines.length > 0 ? false : true} onClick={showDispatchwModal} type="primary">Create Manual Consignment</Button>*/}
                        {/*    </CenterButton>*/}
                        {/*</Col>*/}
                        {/*<Col xxl={4} xl={6} style={{ padding: 5 }}>*/}
                        {/*    <CenterButton>*/}
                        {/*        <Button style={{ width: "100%" }} disabled={scannedLines.lines.length > 0 ? false : true} type="primary">Create Drop Shipment</Button>*/}
                        {/*    </CenterButton>*/}
                        {/*</Col>*/}
                    </StyledRow>
                    {scannedLines.lines.length > 0 &&
                        <Modal
                            visible={dispacthModal}
                            centered
                            width={700}
                            onCancel={() => showDispatchwModal('cancel')}
                            footer={null}
                        >
                            {dispatchStructure && (_.get(dispatchStructure, 'transaction.first_class_mail', null) ||
                                _.get(dispatchStructure, 'transaction.white_label', null)) && (
                                    <Row justify="center" align="middle">
                                        {_.get(dispatchStructure, 'transaction.first_class_mail', null) && (
                                            <Col span={6} style={{ textAlign: 'left' }}>
                                                <FirstClasMail>FIRST CLASS</FirstClasMail>
                                            </Col>
                                        )}
                                        {_.get(dispatchStructure, 'transaction.white_label', null) && (
                                            <Col span={_.get(dispatchStructure, 'transaction.first_class_mail', null) ? 12 : 6} style={{ textAlign: 'center' }}>
                                                <WhiteLabel>WHITELABEL</WhiteLabel>
                                            </Col>
                                        )}
                                    </Row>
                            )}
                            <div style={{ padding: '80px 20px 40px 20px' }}>
                                {renderThumbnails()}

                                {dispatchStructure && dispatchStructure.pack_types && (
                                    <div style={{ margin: '20px 20px' }}>
                                        <p style={{ textAlign: 'center', marginBottom: '10px' }}>
                                            <Label>IF YOU WISH TO CHOOSE ANOTHER PACK TYPE, PLEASE SCAN A PACK TYPE BARCODE OR PICK ONE MANUALLY FROM THE DROPDOWN BELOW</Label>
                                        </p>
                                        <Row type="flex" justify="center" align="center">
                                            <Col span={8}>
                                                <Input
                                                    ref={preferredPackTypeInput}
                                                    autoFocus={true}
                                                    style={{width: "100%"}}
                                                    placeholder="Select Pack Type"
                                                    onPressEnter={onPackTypeKeyDown}
                                               />
                                            </Col>
                                        </Row>

                                        <p style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                                            <Label>IF YOU WISH TO CHANGE NUMBER OF ARTICLES TO BE CREATED CHANGE FROM THE FIELD BELOW</Label>
                                        </p>
                                        <Row type="flex" justify="center" align="center">
                                            <Col span={8}>
                                                <InputNumber
                                                    defaultValue={numberOfArticles}
                                                    size={"large"}
                                                    min={1}
                                                    onChange={setNumberOfArticles}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                )}

                                {dispatchStructure && dispatchStructure.allow_change_article_count && !dispatchStructure.pack_types && (
                                    <div style={{ margin: '20px 20px' }}>
                                        <p style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                                            <Label>IF YOU WISH TO CHANGE NUMBER OF ARTICLES TO BE CREATED CHANGE FROM THE FIELD BELOW</Label>
                                        </p>
                                        <Row type="flex" justify="center" align="center">
                                            <Col span={3}>
                                                <InputNumber
                                                    defaultValue={numberOfArticles}
                                                    size={"large"}
                                                    min={1}
                                                    onChange={setNumberOfArticles}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                )}

                                <p style={{ textAlign: 'center' }}>
                                    {consignmentPreview &&
                                        <>
                                            <Label style={{ margin: 20 }}>
                                                Ship
                                            Order {consignmentPreview.total_packs} Article{consignmentPreview.total_packs >
                                                    1 &&
                                                    's'} in {consignmentPreview.pack_breakdowns.map(
                                                        pack => `${pack.count} ${pack.code}`)}
                                            </Label>
                                            <Label style={{ margin: 20 }}
                                                status="mountain_meadow">
                                                ARE YOU SURE YOU WANT TO
                                            SHIP {scannedLines.lines.length} ITEM{scannedLines.lines.length >
                                                    1 &&
                                                    'S'} IN {consignmentPreview.total_packs} ARTICLE{consignmentPreview.total_packs >
                                                        1 && 'S'}
                                            </Label>

                                            <Label style={{ margin: 20 }}>
                                                Products: { consignmentPreview.products }
                                            </Label>

                                        </>
                                    }
                                </p>

                                <Row type="flex" justify="center" align="center">
                                    <Col span={6}>
                                        <CenterButton>
                                            <Button size={'large'} loading={loading}
                                                style={{ width: '100%' }}
                                                type="primary"
                                                onClick={() => checkLinesIfAllScanned(scanAccessories, ifShipNow)}>SHIP</Button>
                                        </CenterButton>
                                    </Col>
                                    <Col span={6} offset={1}>
                                        <CenterButton>
                                            <Button size={'large'}
                                                style={{ width: '100%' }}
                                                onClick={() => refreshDispatch(
                                                    'Dispatch process has been cancelled!')}
                                                type="danger" ghost>CANCEL</Button>
                                        </CenterButton>
                                    </Col>
                                </Row>
                            </div>
                        </Modal>
                    }

                </Form>

                {/* Dispatch structure is displayed here */}
                {children}

                {showPrintDispatch ? <><PrintThisComponent url={printDispatch.label.content} type={printDispatch.label.type} /></> : null}

                <OverrideShippingVerificationModal
                    overrideConfirmed={overrideConfirmed}
                    setOverrideConfirmed={setOverrideConfirmed}
                    overrideShippingVerification={handleOverrideShippingVerification}
                    handleOverrideShippingVerificationWithDispatchNow={handleOverrideShippingVerificationWithDispatchNow}
                    onClose={onOverrideVerificationModalClose}
                    dispatchData={dispatchStructure}
                    visible={showOverrideModal}
                    disableButtons={disableButtons}
                    setDisableButtons={setDisableButtons}
                />
                <PostDispatchPromptModal
                    visible={showPostDispatchPromptModal}
                    prompts={dispatchStructure !== null ? dispatchStructure.post_dispatch_prompts : []}
                    onOk={handlePostDispatchPromptOk}
                />
                <AccesoryModal accessoriesModal={accessoriesModal}
                    accesoryInput={accesoryInput}
                    accessoriesToBeScan={accessoriesToBeScan}
                    scanAccessoriesFunction={scanAccessoriesFunction}
                    inputState={inputAccessoryState}
                    setInputState={setInputAccessoryState}
                    refreshDispatch={refreshDispatch}
                />

                <PickUpNotesModal
                    loading={loading}
                    visible={showPickupNotesModal}
                    setShowPickupNotesModal={setShowPickupNotesModal}
                    pickupNotes={pickupNotes}
                    setPickUpNotes={setPickUpNotes}
                    onSubmit={onPickupNoteSaved}
                    onCancel={onPickupModalCancel}
                />

            </div>
            {scanGamification.loading && <Spinner size="large" tip={'Loading...'} />}
            {(scanGamification.gameData.length > 0 && scanGamification.loading === false) && (
                <GameView
                    scanGamification={scanGamification}
                    setScanGamification={setScanGamification}
                    resetFields={resetFields}
                    onSubmit={onSubmit}
                />
            )}
        </>
    )
})

const DispatchForm = Form.create({
    name: 'Dispatch Form'
})(LookupForm)

function DispatchLookup(props) {
    const {
        title,
        detailLabel,
        onLookup,
        inputProps,
        children,
        scanTypeId,
        dispatchStructure,
        changeDispatchStructureData,
        updateScannedLines,
        scannedLines,
        dispacthModal,
        showDispatchwModal,
        showPickupNotesModal,
        setShowPickupNotesModal,
        refreshDispatch,
        dispatchData,
        setScanAccessories,
        scanAccessories,
        accessoriesToBeScan,
        setAccessoriesToBeScan,
        setAccessoriesModal,
        accessoriesModal,
        dispatchNowCount,
        setDispatchNowCount,
        overrideConfirmed,
        isPickUp,
        showBin,
        setOverrideConfirmed,
        loading,
        setLoading
    } = props
    const [showPrintDispatch, setShowPrintDispatch] = useState(false)
    const [printDispatch, setPrintDispatch] = useState(null)

    const onLookUpPlusCookieChecker = (params) => {
        setHasCookie(false);
        onLookup(params)
        if (Cookies.get('STATION') !== undefined &&
            parseInt(Cookies.get('SCAN_TYPE')) === parseInt(scanTypeId)) {
            setHasCookie(parseInt(Cookies.get('STATION')));
        }
    }

    const [hasCookie, setHasCookie] = useState(false);
    console.log(`hasCookie`, hasCookie)

    useEffect(() => {
        const forceOnce = setTimeout(() => {
            if (Cookies.get('STATION') === undefined
                || parseInt(Cookies.get('SCAN_TYPE')) !== parseInt(scanTypeId)) {
                refreshDispatch()
                notification.info({ message: 'Please scan station card first to show consignments.' })
            } else {
                setHasCookie(Cookies.get('STATION'));
            }
        }, 550)
        return () => {
            notification.destroy();
            clearTimeout(forceOnce)
            refreshDispatch()
        }
    }, [])

    useEffect(() => {
        if (hasCookie) {
            console.log(hasCookie);
        }
    }, [hasCookie])

    return (
        <Panel title={title} stylings={panelStylings}>
            <StyledRow type="flex" justify="center">
                <Label status={"blue"} >{detailLabel}</Label>
            </StyledRow>
            <LookupForm
                hasCookie={hasCookie}
                dispatchStructure={dispatchStructure}
                dispatchData={dispatchData}
                refreshDispatch={refreshDispatch}
                showDispatchwModal={showDispatchwModal}
                dispacthModal={dispacthModal}
                showPickupNotesModal={showPickupNotesModal}
                setShowPickupNotesModal={setShowPickupNotesModal}
                changeDispatchStructureData={changeDispatchStructureData}
                scannedLines={scannedLines}
                updateScannedLines={updateScannedLines}
                onSubmit={onLookup}
                isPickUp={isPickUp}
                showBin={showBin}
                scanTypeId={scanTypeId}
                inputProps={inputProps}
                scanAccessories={scanAccessories}
                setScanAccessories={setScanAccessories}
                setAccessoriesToBeScan={setAccessoriesToBeScan}
                accessoriesToBeScan={accessoriesToBeScan}
                setAccessoriesModal={setAccessoriesModal}
                accessoriesModal={accessoriesModal}
                dispatchNowCount={dispatchNowCount}
                setDispatchNowCount={setDispatchNowCount}
                overrideConfirmed={overrideConfirmed}
                setOverrideConfirmed={setOverrideConfirmed}
                loading={loading}
                setLoading={setLoading}
                children={children}
                setShowPrintDispatch={setShowPrintDispatch}
                setPrintDispatch={setPrintDispatch}
                showPrintDispatch={showPrintDispatch}
                printDispatch={printDispatch}

            />
            {isPickUp !== true && <div style={{ marginTop: 65 }} >
                <DispatchPanel
                    setShowPrintDispatch={setShowPrintDispatch}
                    setPrintDispatch={setPrintDispatch}
                    scannedLines={scannedLines}
                    hasCookie={hasCookie}
                />
            </div>}
        </Panel>
    )
}

export default DispatchLookup
