import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SectionStyle = styled.div`
  ${({position}) => (
    (position === 'left') && (`
    display: flex;

    .label_section {
      width: 30%;
    }
    
    .child_section {
      width: 70%;
    }
  `))}
  ${({position}) => (
    (position === 'top') && (`
    display: block;

    .label_section {
      width: 100%;
    }

    .child_section {
      width: 100%;
    }
  `))}
  label {
    text-transform: uppercase;
    ${props => (
      props.size === 'default' && (`
      font-weight: 600;
      font-size: 12px;
      `)
    )}
    ${props => (
      props.size === 'large' && (`
        font-weight: bolder;
        font-size: 14px;
      `)
    )}
    
  }
  
`

const InputSection = ({
  label,
  labelStyle,
  children,
  position = 'left',
  size = 'default',
  ...props
}) => (
  <SectionStyle position={position} size={size} {...props}>
    <div className='label_section' style={labelStyle}>
      <label>{label} </label>
    </div>
    <div className='child_section'>
      {children}
    </div>
  </SectionStyle>  
)


InputSection.propTypes = {
  position: PropTypes.oneOf(['left', 'top'])
};


export default InputSection