import React from "react";
import { Select } from "antd";
import is from "is_js";
import { alphabeticalData } from 'utils/sortData';
import { productBlockComponents} from "utils/apiRoutes";
import { ZenSmartAPI } from "utils";

const BlockSelect = (props) => {
  const {
    headerField,
    invokeSelect,
    queryProductBlockPrinter,
    clearHeaderFields,
    updateHeaderFields,
    setCheckBox,
    setQaFailCheckBox
  } = props;

  const blockChange = (value) => {
    invokeSelect(blockSelect, value);
  };

  const blockSelect = (value) => {
    clearHeaderFields();

    const filteredBlock = headerField.blocks.find(
      (block) => block.id === value
    );

    //change paper and lamination codes
    ZenSmartAPI.get(productBlockComponents(headerField.product,filteredBlock.block_type), {
    }).then((result) => {
      const response = result.data.data;
      response.map(function(data){
        if(data.type === 'lamination'){
          props.setLaminationCodes(data.data)
        }
        if(data.type === 'paper'){
          props.setPaperCodes(data.data)
        }
      })
    })
    

    let headerState = {
      ...headerField,
      block: value,
      blockType: filteredBlock.block_type,
      defaultPrinter: false,
      productBlockPrinter: null,
      plex: filteredBlock.plex,
    };

    updateHeaderFields(headerState);
    setCheckBox(false);
    setQaFailCheckBox(false)

    queryProductBlockPrinter(
      headerField.product,
      value,
      headerField.printer,
      headerField.finishingType,
      headerState
    );
  };

  return (
    <>
      <span className="Header-field">
        <span className="Header-boldText">Block: </span>
        <Select
          showSearch
          className="Header-select-block"
          value={is.not.empty(headerField.blocks) ? headerField.block : null}
          optionFilterProp="children"
          filterOption={true}
          onChange={(event) => blockChange(event)}
        >
          {alphabeticalData(headerField.blocks, "block_type").map((block) => (
            <Select.Option key={block.id} value={block.id}>
              {block.block_type}
            </Select.Option>
          ))}
        </Select>
      </span>
    </>
  );
};

export default BlockSelect;
