import React from 'react';
import { Header, Title } from 'components/primaries';
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'


const Vendors = () => {

  return (
    <>
      <Header>
        <Title>App Setup {">"} Vendors</Title>
      </Header>
      <Panel title="VENDORS">
        <DataListPage model="vendors" select={['id', 'name', 'code' ,'created_at', 'updated_at']} link={"app/data-view/vendors/"} pageSize={50}
          addListButton={{
            buttonLabel: "Add Vendors",
            actionApi: "/api/v1/data-api/vendors",
            offset: 15,
            span: 3
          }}
        />
      </Panel>
    </>
  )
}

export default Vendors