import React, {useState} from 'react';
import * as _ from 'lodash';
import {Button, Icon, InputNumber} from 'antd';

export default ({renderFormItem}) => {

    const [count, setCount] = useState(1);

    return (
        <>
            <table className="w-full">
                <thead>
                <tr>
                    <th className="text-center">Length</th>
                    <th className="text-center">Width</th>
                    <th className="text-center">Height</th>
                    <th className="text-center">Box Weight</th>
                    <th className="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    _.times(count, index => {
                        return (
                            <tr key={index}>
                                <td>
                                    {renderFormItem(
                                        `packages.${index}.length`,
                                        null,
                                        <InputNumber placeholder="Length" style={{width: '100%'}}
                                                     step={0.1}/>,
                                    )}
                                </td>
                                <td>
                                    {renderFormItem(
                                        `packages.${index}.width`,
                                        null,
                                        <InputNumber placeholder="Width" style={{width: '100%'}}
                                                     step={0.1}/>,
                                    )}
                                </td>
                                <td>
                                    {renderFormItem(
                                        `packages.${index}.height`,
                                        null,
                                        <InputNumber placeholder="Height" style={{width: '100%'}}
                                                     step={0.1}/>,
                                    )}
                                </td>
                                <td>
                                    {renderFormItem(
                                        `packages.${index}.box_weight`,
                                        null,
                                        <InputNumber placeholder="Box weight" style={{width: '100%'}}
                                                     step={0.1}/>,
                                    )}
                                </td>
                                <td className="flex justify-center">
                                    {index > 0 &&
                                    <Button class={''}
                                            onClick={() => setCount(count - 1)}
                                            icon="delete"/>
                                    }
                                </td>
                            </tr>
                        );
                    })
                }

                </tbody>
            </table>
            <Button type="dashed" onClick={() => setCount(count + 1)}
                    className="w-full mt-2">
                <Icon type="plus"/> Add package
            </Button>
        </>
    );
}