import React from 'react';
import { Button } from "../../../../components/zensmart-design-system";
import { Menu, Dropdown, Modal, Input, Spin, Alert, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReportTitle, ReportDescription } from '../primaries';
import { InputSection, ReportTables } from '../composites';
import styled from 'styled-components';
import { debounce } from "utils/debounce"
const StyledRow = styled.div`
  margin: 0px;
  display: flex;
  padding-bottom: 2px;
  padding-top: 2px;
  width: 100%;

  .title_section {
    width: 69%;
  }

  .title_input {
    width: 37.8%;
    line-height: 2.3em;
  }

  .button_section {
    width: 45%;
    text-align: center;  

  }
  
  .empty_space {
    width: 40%;
  }

  .edit_button_section {
    width: 24%;
  }

  .title_input:first-child {
    padding-left: 3rem;
  }

  button {
    margin: 0 3px 6px;
    outline: none;
  }

`
const StyledDiv = styled.div`
  margin-top: 1rem;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 100%;
`
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

const TitleAndControls = ({
  reportName,
  reportDescription,
  setCurrentTableObject,
  edit,
  setEdit,
  stopEdit,
  setReportName,
  setReportDescription,
  save,
  saveAndRun,
  exportCSV,
  exportJSON,
  setHideDetails,
  setHideDropZones,
  setHideFilters,
  hideFilters,
  hideDetails,
  hideDropZones,
  reportingTables,
  chooseTable,
  currentTable,
  loading = false,
  runMode,
  saveAsReport,
  saveAsModal,
  setSaveAsModal,
  userData,
  saveReportID,
  deleteReport,
  runControls = true,
  isPrintReport,
  setIsPrintReport,
  showDelete = false,
  showFullScreen = false,
  fullScreen,
  isPrintReportLoading,
  setIsPrintReportLoading,
  visualization,
  isDashboards = false,
  inputRefDescription,
  inputRefName,
  inputRefSaveAs,
  isExportReportLoading = false,
  exportSummaryCsv,
  exportMatrixCsv,
  canModifyData
}) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <div onClick={exportCSV}>CSV (Tabular)</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => exportSummaryCsv(false)}>CSV (Summary)</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={exportMatrixCsv}>CSV (Matrix)</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={exportJSON}>JSON</div>
      </Menu.Item>

    </Menu>
  )

  const runControlsStyle = { display: runControls ? "inline" : "none" }

  const handleReportName = debounce((event) => {
    setReportName(event.target.value)
  }, 600)
  const handleDescription = debounce((event) => {
    setReportDescription(event.target.value)
  }, 600)

  return (
    <StyledDiv>
      <StyledRow>
        {edit ? (
          <>
            <InputSection label={isDashboards ? 'Dashboard: ' : 'Report: '} size='large' className='title_input' labelStyle={{ width: 'auto' }}>
              <Input
                disabled={loading}
                style={{ width: '98%', marginLeft: 3 }}
                size='default'
                defaultValue={reportName && reportName}
                placeholder={'Untitled ' + (isDashboards ? 'Dashboard' : 'Report')}
                onChange={(e) => { e.persist(); handleReportName(e) }}
                ref={inputRefName}
              />
            </InputSection>
            {reportingTables ? <ReportTables
              tables={reportingTables}
              setCurrentTableObject={setCurrentTableObject}
              chooseTable={chooseTable}
              currentTable={currentTable}
              className='title_input'
            /> : <div className='empty_space' />}
          </>
        ) : (
            <div className='title_section'>
              <ReportTitle>{reportName ? reportName : 'Untitled ' + (isDashboards ? 'Dashboard' : 'Report')}</ReportTitle>
            </div>
          )}


        {edit ? (
          ( canModifyData ? <div className='edit_button_section'>
            <Button disabled={loading} size='small' onClick={() => runMode(false)}>Run</Button>
            <Button disabled={loading} size='small' onClick={() => save(reportName, reportDescription)}>Save</Button>
            <Button disabled={loading} size='small' onClick={() => saveAndRun(reportName, reportDescription)}>Save and Run</Button>
            {((userData &&
              saveReportID.owner_id === userData.id) || showDelete) &&
              <Button disabled={loading} size='small' onClick={() => deleteReport()} style={{ backgroundColor: "black", color: "white" }}>Delete</Button>
            }
            <Button
              size='small'
              color='red'
              onClick={stopEdit}
            >Cancel</Button>
          </div> : <div className='edit_button_section'>
            <Button disabled={loading} size='small' onClick={() => runMode(false)}>Run</Button>
            <Button
              size='small'
              color='red'
              onClick={stopEdit}
            >Cancel</Button></div>
          )
        ) : (
            <div className='button_section'>
              <Button style={runControlsStyle} size='small' appearance='ghost' onClick={() => setHideFilters(!hideFilters)}>
                {
                  hideFilters === false ? 'Show Filters' : 'Hide Filters'
                }
              </Button>
              <Button style={runControlsStyle} size='small' appearance='ghost' onClick={() => setHideDetails(!hideDetails)}>
                {
                  hideDetails === false ? 'Hide Details' : 'Show Details'
                }
              </Button>
              {canModifyData && <Button style={runControlsStyle} size='small' onClick={() => setSaveAsModal(true)} appearance='ghost'>Save as</Button>}
              <Modal
                title="Save As Report"
                visible={saveAsModal}
                onOk={saveAsReport}
                onCancel={() => setSaveAsModal(false)}
                centered
              >
                <Input placeholder="Input Name" ref={inputRefSaveAs} />
              </Modal>
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button style={runControlsStyle} size='small' appearance='ghost'>Export as</Button>
              </Dropdown>

              {canModifyData && <Button
                size='small'
                appearance='ghost'
                onClick={() => setEdit(true)}
              >Edit</Button>}

              {showFullScreen && <Button
                size='small'
                appearance='ghost'
                onClick={() => fullScreen()}
              >Full Screen</Button>}

              {!showFullScreen &&
                <Button size='small' onClick={() => { setIsPrintReport(true); setIsPrintReportLoading(true) }} appearance='ghost'>{isPrintReportLoading ? <Spin indicator={antIcon}>Print</Spin> : "Print"}</Button>
              }

            </div>
          )}
      </StyledRow>
      <StyledRow>
        {edit ? (
          <>
            <InputSection label='Description: ' size='large' className='title_input' labelStyle={{ width: 'auto' }}>
              <Input
                style={{ width: '98%', marginLeft: 3 }}
                size='default'
                defaultValue={reportDescription && reportDescription}
                placeholder={(isDashboards ? 'Dashboard' : 'Report') + ' Description...'}
                onChange={(e) => { e.persist(); handleDescription(e) }}
                ref={inputRefDescription}
              />
            </InputSection>
          </>
        ) : (
            <div className='title_section'>
              <ReportDescription>{reportDescription && reportDescription}</ReportDescription>
            </div>
          )}

      </StyledRow>
      <Row type="flex" align="center">
        <Spin size="large" spinning={isExportReportLoading}>

        </Spin>
      </Row>



    </StyledDiv>

  )
};

export default TitleAndControls