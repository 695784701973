import React, { useState } from "react";
import { Table, notification } from "antd";
import { Panel } from "components/primaries";
import TaskHeader from "../partials/TaskHeader";
import { ZenSmartAPI } from "utils";
import { faultsOverviewRoute } from "utils/apiRoutes";
import {
  Header,
  Title,
  StyledDivider,
  SubtitleStyle,
  StyleTable,
  TableStyling,
} from "../styles";
import "../Machine.css";

const columns = [
  {
    title: "REPORTED COMMENT",
    dataIndex: "comment",
    key: "comment",
    className: "align-left",
    render(value, row) {
      return (
        <p>
          <span>
            {row.id} - {row.user}
          </span>
          <br />
          <span>{value}</span>
        </p>
      );
    },
  },
  {
    title: "STATION",
    dataIndex: "station",
    key: "station",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "REPORTED",
    dataIndex: "reported",
    key: "reported",
  },
  {
    title: "STARTED",
    dataIndex: "started",
    key: "started",
  },
  {
    title: "COMPLETED",
    dataIndex: "ended",
    key: "ended",
  },
  {
    title: "SEVERITY",
    dataIndex: "severity",
    key: "severity",
  },
];

function FaultOverview() {
  const [faults, setFaults] = useState([]);

  const loadFaultsOverview = (station, start_date, end_date) => {
    ZenSmartAPI.get(faultsOverviewRoute(station.barcode, start_date, end_date))
      .then((res) => {
        const data = res.data && res.data.map((fault, index) => {
          const f = fault
          return {
            ...f,
            uniqueId: `${fault.id}${index}`
          }
        });
        setFaults(data);
      })
      .catch((res) => {
        notification.error({
          message: "Loading of faults overview failed.",
        });
      });
  };

  return (
    <>
      <Header>
        <Title>Machine {">"} Faults Overview</Title>
      </Header>
      <Panel title="FAULTS MANAGEMENT">
        <TaskHeader
          loadTasks={() => null}
          loadTasksOverview={loadFaultsOverview}
          overview="faults"
          showOverview={true}
        />
        <StyledDivider />
        <SubtitleStyle>FAULTS OVERVIEW</SubtitleStyle>
        <TableStyling />
        <StyleTable>
          <Table
            columns={columns}
            dataSource={faults}
            rowKey="uniqueId"
            bordered
            size="middle"
            pagination={true}
          />
        </StyleTable>
      </Panel>
    </>
  );
}

export default FaultOverview;
