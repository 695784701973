import React, { useEffect, useState, useMemo } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Form, Input, Button, Spin, Result, notification } from 'antd'
import styled from 'styled-components'

import { ZenSmartPublicPage } from 'components/templates'
import { Panel } from 'components/primaries'
import { ZenSmartAPI } from 'utils'
import { changePasswordValidateRoute } from "utils/apiRoutes"
import * as _ from 'lodash';

const StyledForm = styled(Form)`
  margin: 2rem 4rem;
`

const Field = styled(Form.Item)`
  margin-bottom: 16px;

  & .ant-form-item-label {
    line-height: inherit;
  }
`

const passwordFieldConfig = {
  rules: [
    {
      required: true,
      message: <p>This field is required.</p>
    },
    {
      min: 6,
      message: <p>Must be at least 6 characters.</p>
    },
    {
      pattern: /\d/,
      message: <p>Must have at least 1 digit character.</p>
    },
    {
      pattern: /[a-z]/,
      message: <p>Must have at least 1 lowercase character.</p>
    },
    {
      pattern: /[A-Z]/,
      message: <p>Must have at least 1 uppercase character.</p>
    },
    {
      pattern: /\W/,
      message: <p>Must have at least 1 special character.</p>
    },
  ]
}

function ActualForm(props) {
  const { token, form, setResult } = props

  const { getFieldDecorator, getFieldValue, validateFields } = form

  const password = getFieldValue('password')

  const repeatPasswordFieldConfig = useMemo(() => ({
    rules: [
      {
        required: true,
        message: <p>This field is required.</p>
      },
      {
        validator(_, value, callback) {
          if (value !== password) {
            callback('Password does not match.')
          } else {
            callback()
          }
        }
      }
    ]
  }), [password])

  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState(null)

  const changePassword = evt => {
    evt.preventDefault()

    validateFields((err, values) => {
      if (err) return

      const { password } = values

      setSubmitting(true)

      ZenSmartAPI.post(changePasswordValidateRoute(token), { password })
        .then(() => {
          setResult({
            status: 'success',
            title: 'Password Successfully Updated!',
            subTitle: 'Your account\'s password has now been updated. ' +
              'You may now sign in to your account with your new password!'
          })
        })
        .catch((res) => {
          if (_.get(res, 'response.status') === 422) {
            setErrors(_.get(res, 'response.data.errors.password'))
            setSubmitting(false)
          }
          else if (_.get(res, 'response.data.message')) {
            notification.error({
              message: _.get(res, 'response.data.message')
            });
            setSubmitting(false)
          }
          else {
            setResult({
              status: 'error',
              title: 'Unexpected Server Error',
              subTitle: 'Something unexpected occured with the application. ' +
                'Please try again later.'
            })
          }
        })
    })
  }

  return (
    <StyledForm onSubmit={changePassword}>
      <Field label="New Password" validateStatus={errors ? 'error' : undefined}
        help={errors ?
          <>
            {errors[0].map(item => {
              return <p>{item}</p>
            })}
          </>
          : undefined}>
        {getFieldDecorator('password', passwordFieldConfig)(<Input.Password />)}
      </Field>

      <Field label="Confirm New Password">
        {getFieldDecorator('repeat_password', repeatPasswordFieldConfig)(<Input.Password />)}
      </Field>

      <Field>
        <Button
          type="primary"
          htmlType="submit"
          loading={submitting}
        >
          Change Password
        </Button>
      </Field>
    </StyledForm>
  )
}

const ChangePasswordForm = Form.create({ name: 'Change Password Form' })(ActualForm)

const resultBackButton = (
  <Link to="/app">
    <Button type="primary">Back to Home Page</Button>
  </Link>
)

function ChangePassword() {
  const { token } = useParams()

  const [validating, setValidating] = useState(true)
  const [result, setResult] = useState(null)

  useEffect(() => {
    ZenSmartAPI.get(changePasswordValidateRoute(token))
      .then(() => {
        setValidating(false)
      })
      .catch((err) => {
        setValidating(false)

        // [400]: The token is invalid
        if (err.response && err.response.status === 400) {
          setResult({
            status: 'warning',
            title: 'Invalid Password Change Request',
            subTitle: 'Request to change password for an account has now ' +
              'expired. Please make another change password request to continue.'
          })
          return
        }

        setResult({
          status: 'error',
          title: 'Unexpected Server Error',
          subTitle: 'Something unexpected occured with the application. ' +
            'Please try again later.'
        })
      })
  }, [token])

  let content = <ChangePasswordForm token={token} setResult={setResult} />

  if (result) {
    content = <Result {...result} extra={resultBackButton} />
  }

  return (
    <ZenSmartPublicPage>
      <Panel title="Change Password">
        <Spin
          tip="Validating Change Password Request..."
          spinning={validating}
        >
          {content}
        </Spin>
      </Panel>
    </ZenSmartPublicPage >
  )
}

export default ChangePassword