import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input, Upload } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { productIdRoute, productSearchRoute, uploadConsumptionRoute } from "utils/apiRoutes"
import moment from 'moment';
import { failInitialData } from "utils/panelsInitialData"
import { debounce } from "utils/debounce"
import { alphabeticalData } from 'utils/sortData';
import { UploadOutlined } from '@ant-design/icons';
import UpdatePartsModal from '../BomModals/UpdatePartsModal'
import UpdateLocationModal from '../BomModals/UpdateLocationModal'
import UpdateWorkFlowModal from '../BomModals/UpdateWorkFlowModal'
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
  padding: 10px;

`
const ColInputs = styled(Col)`
  padding-left : 20px;
  padding-right : 20px;
  padding-bottom : 10px
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;
const BomDataInput = (props) => {
    const [productsDropDownValues, setProductsDropdownValues] = useState([])
    const { initialData, updateParts, setUpdateParts, updateLocation, loading, setLoading, setUpdateLocation, updateWorkFlow, setUpdateWorkFLow, fetchInitData } = props
    const [showUpdatePartsModal, setShowUpdatePartsModal] = useState(false)
    const [showUpdateLocationsModal, setshowUpdateLocationsModal] = useState(false)
    const [showUpdateWorkFlowModal, setshowUpdateWorkFlowModal] = useState(false)
    const hiddenFileInput = React.useRef(null);

    const onSearchProduct = debounce((value, limit = null) => {
        if (value !== "" || limit) {
            ZenSmartAPI.post(productSearchRoute(value), { limit: limit, response_type: "product_flow" }).then(result => {
                setProductsDropdownValues(result.data.data)
            })
        }
    }, 400)

    const openUpdateWorkFlowModal = () => {
        if (updateWorkFlow) {
            setshowUpdateWorkFlowModal(true)
        }
        else {
            notification.error({ message: "Please select workflow first!" })
        }
    }

    const uploadConsumption = (info) => {
        const file = info.target.files[0]
        const formData = new FormData();
        formData.append(
            "file_name",
            file
        );
        setLoading(true)
        ZenSmartAPI.post(uploadConsumptionRoute, formData)
            .then(result => {
                setLoading(false)
                notification.success({ message: "Upload consumption successful!" })
                fetchInitData()
            }).catch(err => {
                setLoading(false)
                notification.error({
                    message: _.get(err, 'response.data.message'),
                });
            })

    }

    const handleUploadFile = event => {
        hiddenFileInput.current.click();
    };

    useEffect(() => {
        onSearchProduct("", 20)
    }, [])


    return (
        <>
            <div style={{ margin: "10px 0px 0px 0px", borderBottom: "1px solid grey" }}>
                <Heading>Update BOM Data</Heading>
                <Row style={{ marginTop: 10, marginBottom: 10 }} type="flex" align="middle" >

                    <ColInputs span={24}>
                        <p style={{ padding: 5 }}>This facility will allow you to upload either a Staged Input File, a Stock Status
                            Report or a Min Max Update. Zen will automatically detect which you upload and provide feedback if there are issues prior to importing.</p>
                        <p style={{ fontWeight: "bold", padding: 5 }}>You should always import the input file, followed by the stock file, ensuring the data correlates and nothing is missing from either files since all parts, locations and workflows
                            that aren't included in the upload file will be assumed unneeded and deleted from the BOM database prior to upload.</p>
                    </ColInputs>
                    <ColInputs span={24} style={{ marginBottom: 10 }}>
                        <p style={{ padding: "0px 5px 5px 5px" }}>Min Max Update files can be uploaded whenever since they are non-destructive </p>
                        <Button onClick={handleUploadFile}>
                            <UploadOutlined style={{ verticalAlign: "baseline" }} /> Upload a file
                        </Button>
                        {!loading ?
                            <input
                                type="file"
                                accept=".csv"
                                // multiple={row.multiple_upload}
                                ref={hiddenFileInput}
                                onChange={(event) => uploadConsumption(event)}
                                style={{ display: 'none' }}
                            />
                            : null
                        }
                    </ColInputs>
                    <ColInputs span={8}>
                        <p style={{ fontWeight: "bold" }}>Update Parts</p>
                        <Col span={19}>
                            <Select value={updateParts} onChange={(e) => setUpdateParts(e)} style={{ width: "100%" }} >
                                <Option style={{ textTransform: "capitalize" }} value={null}>
                                    Select Existing Or Add New
                                </Option>
                                {initialData && alphabeticalData(initialData.parts).map(item => (
                                    <Option style={{ textTransform: "capitalize" }} key={item.id} value={item.id}>
                                        {item.code}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col style={{ paddingLeft: 10, paddingRight: 10 }} span={5}>
                            <Button type="primary" style={{ width: "100%" }} onClick={() => setShowUpdatePartsModal(true)}>Add/Edit</Button>
                        </Col>

                    </ColInputs>

                    <ColInputs span={8}>
                        <p style={{ fontWeight: "bold" }}>Update Locations</p>
                        <Col span={19}>
                            <Select value={updateLocation} onChange={(e) => setUpdateLocation(e)} style={{ width: "100%" }} >
                                <Option style={{ textTransform: "capitalize" }} value={null}>
                                    Select Existing Or Add New
                                </Option>
                                {initialData && alphabeticalData(initialData.locations).map(item => (
                                    <Option style={{ textTransform: "capitalize" }}
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.warehouse}/{item.bin}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col style={{ paddingLeft: 10, paddingRight: 10 }} span={5}>
                            <Button type="primary" style={{ width: "100%" }} onClick={() => setshowUpdateLocationsModal(true)}>Add/Edit</Button>
                        </Col>

                    </ColInputs>

                    <ColInputs span={8}>
                        <p style={{ fontWeight: "bold" }}>Update Workflow</p>
                        <Col span={19}>
                            <Select value={updateWorkFlow} onChange={(e) => setUpdateWorkFLow(e)} showSearch
                                placeholder="Search for a product"
                                optionFilterProp="children"
                                filterOption={true}
                                style={{ width: '100%' }}
                                // onChange={selectProduct}
                                // value={currentProduct ? productId : undefined}
                                onSearch={(e) => onSearchProduct(e)} >
                                <Option style={{ textTransform: "capitalize" }} value={null}>
                                    Select Existing
                                </Option>
                                {alphabeticalData(productsDropDownValues, "code").map(prodDef => (
                                    <Select.Option
                                        key={prodDef.id}
                                        value={prodDef.id}
                                    >
                                        {prodDef.code}: {prodDef.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col style={{ paddingLeft: 10, paddingRight: 10 }} span={5}>
                            <Button type="primary" style={{ width: "100%" }} onClick={openUpdateWorkFlowModal}>Add/Edit</Button>
                        </Col>
                    </ColInputs>
                </Row>
                <UpdatePartsModal
                    showModal={showUpdatePartsModal}
                    setShowModal={setShowUpdatePartsModal}
                    updateParts={updateParts}
                    setUpdateParts={setUpdateParts}
                    fetchInitData={fetchInitData}
                />
                <UpdateLocationModal
                    showModal={showUpdateLocationsModal}
                    setShowModal={setshowUpdateLocationsModal}
                    updateLocation={updateLocation}
                    setUpdateLocation={setUpdateLocation}
                    fetchInitData={fetchInitData}
                />
                <UpdateWorkFlowModal
                    showModal={showUpdateWorkFlowModal}
                    setShowModal={setshowUpdateWorkFlowModal}
                    updateWorkFlow={updateWorkFlow}
                    setUpdateWorkFLow={setUpdateWorkFLow}
                    fetchInitData={fetchInitData}
                    initialData={initialData}

                />
            </div>
        </>
    )
}
export default BomDataInput