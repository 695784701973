import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Form, Input, Button, Select, Col, Row } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import { icons, styles } from "../zensmart-design-system/index";
import { InputStyle } from 'pages/Machine/UchidaInputGenerator/component';


const color = styles.color;
const search = icons.icons.search
const { Option } = Select;
const { TextArea } = Input;

const StyledInput = styled(Input)`
  height: 30px;
  border-radius: 4px 0 0 4px;
  border: 1px solid ${color.white};
  padding: 7px;
  padding-right: 27px;
  padding-left: 10%;
  width: 100%;
  color: ${color.body};
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' width='16' height='16' aria-hidden='true'><path fill='rgb(191,197,210)' d='${search}'></path></svg>");
  background-repeat: no-repeat;
  background-position: 3% 50%;
  &::placeholder {
    color: ${color.gray};
  }
  &:focus {
    outline: 0;
  }
`;

const StyledDiv = styled.div`
  width: ${props => props.width ? props.width : 'fit-content'};
  height: fit-content;
  display: inline-flex;
  color: ${color.input.unselected};
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  text-transform: uppercase;
  color: ${color.white};
  background-color: rgba(255,255,255,0.3);
  margin:0px;
  height:32px;
  top:-1px;
  border-radius: 0 5px 5px 0;
  padding: 4px;
  font-weight: 700;
  border: 1px solid ${color.white};
  &:focus {
    outline: 0;
  }
`;

const RoundSearch = styled(Input)`
  .ant-input {
    border-radius: 0px;
  }
`;

const StyledTextArea = styled(TextArea)`
  ${(props) =>
    props.isBulkSearch &&
    css`
      height: 10em;
      overflow: auto;
    `}
`;
const applyStyle = SearchWrapper => {
  return (
    SearchWrapper &&
    StyledDiv.withComponent(({ ...rest }) => (
      <SearchWrapper {...rest} />
    ))
  );
};

function useHandleSearch() {
  const history = useHistory()
  
  const handleSearch = React.useCallback((searchValue, modelValue, searchType) => {
    const query = { query: searchValue, model: modelValue, type: searchType}

    history.push({
      pathname: '/app/search',
      search: queryString.stringify(query),
    })
  }, [history])

  return handleSearch
}

const getSearchingState = state => state.search.searching

function SearchForm(props) {
  const {
    width,
    children,
    SearchWrapper,
    onSearch,
    form,
    ...input
  } = props

  const StyledDivWrapper = React.useMemo(() => applyStyle(SearchWrapper), [SearchWrapper]);
  const [bulkSearchValue, setBulkSearchValue] = useState('');
  const [searchType, setSearchType] = useState('single');

  let SelectedSearch = StyledDiv;
  if (SearchWrapper) {
    SelectedSearch = StyledDivWrapper;
  }

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form

  const searching = useSelector(getSearchingState)
  const handleSearch = useHandleSearch()

  const handleSubmit = React.useCallback((evt) => {
    evt.preventDefault()
    handleSearch(getFieldValue('search'), getFieldValue('model'), searchType)
    setFieldsValue({ search: '' })
    // eslint-disable-next-line
  }, [])


  const handleSearchButtonPress = () => {
    if (searchType === 'single') {
      handleSearch(getFieldValue('search'), getFieldValue('model'), searchType);
    } else {
      handleSearch(bulkSearchValue, getFieldValue('model'), searchType);
    }
    setFieldsValue({ search: '' });
  }

  const handleToggleSearchType = () => {
    if (searchType === 'single') {
      setSearchType('bulk');
      setFieldsValue({ search: '' });
    } else {
      setSearchType('single');
      setBulkSearchValue('');
    }
  };

  const selectBefore = getFieldDecorator('model', {
    initialValue: "transaction"
  })(
    <Select className='search-component' style={{ width: 140 }}>
      <Option value="batch">Batch</Option>
      <Option value="block">Blocks</Option>
      <Option value="consignment">Consignment</Option>
      <Option value="customer">Customer</Option>
      <Option value="line">Line</Option>
      <Option value="order">Order</Option>
      <Option value="printer">Printer</Option>
      <Option value="product">Product</Option>
      <Option value="station">Station</Option>
      <Option value="transaction">Transaction</Option>
      <Option value="user">User</Option>
      <Option value="vendor">Vendor</Option>
      <Option value="submission">Submission</Option>
    </Select>
  );
  const buttonAfter = (
    <StyledButton>
      <Button type="primary">SEARCH</Button>
    </StyledButton>

  );

  return (
    <Form onSubmit={handleSubmit} layout="inline">
      <Input.Group style={{ position: 'absolute', width: 'fit-content' }}>
        <Row gutter={0}>
          <Col span={18}>
            <div >
              {searchType === 'single' ? (
                getFieldDecorator('search')(
                  <RoundSearch
                    {...input}
                    type="text"
                    placeholder="Search"
                    autoComplete="off"
                    loading={searching ? 1 : 0}
                    addonBefore={selectBefore}
                  />
                )
              ) : (
                <div style={{ display: 'flex' }}>
                  <div className="search-component">{selectBefore}</div>
                  <div style={{ flex: 1 }}>
                    <StyledTextArea
                      value={bulkSearchValue}
                      style={{ 'borderRadius' : 0 }}
                      onChange={(e) => setBulkSearchValue(e.target.value)}
                      placeholder="Search"                      
                      autoSize={{ minRows: 4, maxRows: 6 }}
                      onBlur={(e) => {
                        const { relatedTarget } = e;
                        const isSearchComponent = relatedTarget && relatedTarget.closest('.search-component');
                        if (isSearchComponent) {
                          return;
                        }

                        setSearchType('single');
                        setBulkSearchValue('');
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col span={6} style={{ display: 'flex' }}>
            <StyledButton className="search-component" htmlType="submit" onClick={handleSearchButtonPress}>
              SEARCH
            </StyledButton>
            <span onClick={handleToggleSearchType} style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '5px', color: 'white' }}>
              {searchType === 'single' ? 'Bulk' : 'Single'}
            </span>
          </Col>
        </Row>
      </Input.Group>
    </Form>
  );
}

export const SearchBar = Form.create({ name: 'SearchForm' })(SearchForm)

SearchBar.propTypes = {
  width: PropTypes.string
};
