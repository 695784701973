import * as S from './TableStyled';

const buildStyle = (isHeader, isHidden) => {
    const styleOfHeader = (isHeader == true) ? S.ProductGroupRowHeaderStyle : {};
    const styleOfHidden = (isHidden == true) ? S.Hidden : {};
    return Object.assign(styleOfHeader, styleOfHidden);
}

export const buildRow = (rowId, isHeader, isHidden, data) => {
    return Object.assign(data, {
        row: {
            id     : rowId,
            header : isHeader == true,
            style  : buildStyle(isHeader, isHidden)
        }
    });
}

const handleNumbers = (hasLetter, value) => {
    return (!hasLetter) ? parseFloat(value).toLocaleString('en-US', {
        'minimumFractionDigits' : 0,
        'maximumFractionDigits' : 2
    }) : value;
}

export const handleRowStyle = (style, value) => {
    const hasLetter = /[a-zA-Z]/.test(value);

    return {
        props: {
            style : Object.assign({
                'textAlign' : hasLetter ? 'left' : 'right'
            }, style)
        },
        children: handleNumbers(hasLetter, value),
    }
}

const createUniqueKey = (uniqueKeys, data) => uniqueKeys.map((item) => data[item] ?? null).join('-');

export const uniqueObjectsFromArray = (uniqueKeys, inputObjects) => {
    const uniqueObjects = [];
    const seenKeys = new Set();

    for (const obj of inputObjects) {
      // Create a unique key by concatenating the values
      const key = createUniqueKey(uniqueKeys, obj);

      // Check if the key is unique
        if (!seenKeys.has(key)) {
            seenKeys.add(key);
            uniqueObjects.push(obj);
        }
    }

    return uniqueObjects;
}

export const mergeData = (keyName, keyValue, uniqueKeys, oldData, newData) => {
    let data = [];
    const indexOfInsert = oldData.findIndex(data => data[keyName] == parseInt(keyValue, 10));

    if (keyValue == null || keyValue == undefined) {
        data = (newData.length > 0) ? newData : [];
    } else if (indexOfInsert > 0) {
        oldData.splice(indexOfInsert + 1, 0, newData);
        data = oldData.flat(1);
    }
    // if (data.every(obj => obj.hasOwnProperty('tertiary_product_group_id'))) {
    //     return uniqueObjectsFromArray(uniqueKeys, data);
    // } else {
        return  data;
    // }
}

export const handleActiveRows = (id, rows) => {
    if (rows.includes(id)) {
        rows = rows.filter((element) => element != id);
    } else {
        rows.push(id);
    }
    return rows;
}

export const getIdFromUrl = (key, queryString) => {
    queryString = '?' + (queryString.split('?')[1] ?? '');
    return (new URLSearchParams(queryString)).get(key);
}

export const getUrl = (url, parameters) => {
    return url + '?' + Object.keys(parameters).map(key => key + '=' + parameters[key]).join('&');
}

export const buildData = (keyName, url, uniqueKeys, oldData, newData) => mergeData(keyName, getIdFromUrl(keyName, url), uniqueKeys, oldData, newData);
