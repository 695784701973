import { Select } from 'antd'
import React from 'react'

const OptGroup = Select.OptGroup
const Option = Select.Option

export const customDateInputs = (inputFunction, valueData, prefix = "") => {

    return <Select
        style={{ width: "100%" }}
        onChange={inputFunction}
        defaultValue={valueData}

    >
        <Option value={`${prefix}custom`}>Custom</Option>

        <OptGroup label="Year">
            <Option value={`${prefix}thisyear`}>This Year</Option>
            <Option value={`${prefix}lastyear`}>Last Year</Option>
            <Option value={`${prefix}thisyearandlastyear`}>This Year And Last Year</Option>
        </OptGroup>

        <OptGroup label="Month">
            <Option value={`${prefix}thismonth`}>This Month</Option>
            <Option value={`${prefix}lastmonth`}>Last Month</Option>
            <Option value={`${prefix}thismonthandlastmonth`}>This Month And Last Month</Option>
        </OptGroup>

        <OptGroup label="Week">
            <Option value={`${prefix}thisweek`}>This Week</Option>
            <Option value={`${prefix}lastweek`}>Last Week</Option>
            <Option value={`${prefix}thisweekandlastweek`}>This Week and Last Week</Option>
        </OptGroup>

        <OptGroup label="Day">
            <Option value={`${prefix}yesterday`}>Yesterday</Option>
            <Option value={`${prefix}today`}>Today</Option>
            <Option value={`${prefix}yesterdayandtoday`}>Yesterday And Today</Option>
            <Option value={`${prefix}tomorrow`}>Tomorrow</Option>
            <Option value={`${prefix}last7days`}>Last 7 Days</Option>
            <Option value={`${prefix}last14days`}>Last 14 Days</Option>
            <Option value={`${prefix}next7days`}>Next 7 Days</Option>
            <Option value={`${prefix}next14days`}>Next 14 Days</Option>
        </OptGroup>
        <OptGroup label="Special">
            <Option value={`${prefix}past`}>in the Past</Option>
            <Option value={`${prefix}future`}>in the Future</Option>
            <Option value={`${prefix}is empty`}>is empty</Option>
            <Option value={`${prefix}is not empty`}>is not empty</Option>
        </OptGroup>
    </Select>
}