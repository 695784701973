import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { format } from 'date-fns'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import moment from 'moment'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;
  padding : 0px 40px;
`

const Text = styled.h2`
  color: ${color.body};

  font-size: 16px;
  font-weight: bold;
  margin: 0;
`

function LastUpdateTimedSection(props) {
  const { loading, dateTime, updatePrinterTimeline, options, setOption } = props
  return (
    <Container>
      <Text style={{ padding: 20 }}>LAST UPDATED: {format(dateTime, 'E hh:mm:ss a')}</Text>

      <Button
        loading={loading}
        type="primary"
        onClick={() => {
          updatePrinterTimeline(false);
          setOption({
            ...options, start: moment().subtract(45, 'minutes'),
            end: moment().add(50, 'minutes'),
          })
        }}
      >
        Update
      </Button>
    </Container>
  )
}

export default LastUpdateTimedSection