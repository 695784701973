import React, { useEffect, useState } from 'react';
import { Header, Title, Panel } from 'components/primaries';
import * as S from '../components/Table/HourlyReportStyled';
import DatePicker from '../components/DatePicker/DatePicker';
import moment from 'moment';
import { formatDate } from 'utils/dateFormatter';
import { ZenSmartAPI } from 'utils';
import { handleActiveRows, buildData, getUrl } from '../components/Table/TableUtilities';
import HourlyLinearFeetProgressReportTable from './HourlyLinearFeetProgressReportTable';

const HourlyLinearFeetProgressReport = () => {
    const dateFormat = 'YYYY-MM-DD';
    const dateValue = formatDate(dateFormat, moment());
    const [date, setDate] = useState(formatDate(dateFormat, dateValue));
    
    const getPerHourApiRoute = '/api/v1/linear-feet/per-hour';
    const [perHourLoading, setPerHourLoading] = useState(true);
    const [perHourData, setPerHourData] = useState([]);
    const [activePerHourRows, setActivePerHourRows] = useState([]);
    const uniqueKeys = [
        'department_id',
        'tertiary_product_group_id',
    ];

    const getPerHourData = (parameters) => ZenSmartAPI.get(getUrl(getPerHourApiRoute, parameters))
                                                      .then(({config, data}) => setPerHourData(buildData('department_id', config.url, uniqueKeys, perHourData, data)))
                                                      .catch(() => setPerHourData([]))
                                                      .finally(() => setPerHourLoading(false));

    const handlePerHour = (id) => {
        if (id !== null) {
            setPerHourData([]);
            getPerHourData({date});
            const toQuery = !activePerHourRows.includes(id);
            setActivePerHourRows(handleActiveRows(id, activePerHourRows));
            setTimeout(() => {
                setPerHourLoading(true);
                (toQuery) ? getPerHourData({
                    date,
                    department_id : id
                }) : setPerHourLoading(false);
            }, 100);
        }
    }

    //Initial loading
    useEffect(() => {
        setPerHourData([]);
    }, []);

    //When dates update
    useEffect(() => {
        setPerHourData([]);
        setPerHourLoading(true);
        getPerHourData({date});
    }, [date]);

    return (
        <>
            <Header>
                <Title>Performance {'>'} Hourly Linear Feet Progress Report</Title>
            </Header>
            <S.Layout>
                <Panel>
                    <DatePicker 
                        date={(date) => setDate(date)}
                        defaultDate={date}
                        format={dateFormat}
                    />
                    <HourlyLinearFeetProgressReportTable
                        loading={perHourLoading}
                        data={perHourData}
                        activeRows={activePerHourRows}
                        handleRow={(id) => handlePerHour(id)}
                    />
                </Panel>
            </S.Layout>
        </>
    );
}

export default HourlyLinearFeetProgressReport;
