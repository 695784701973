import React, { useCallback, useState, useEffect } from 'react';
import { Icon, notification, Result } from 'antd';
import { ZenSmartAPI } from 'utils';
import { Details } from 'components/sections';
import DetailFieldTable from 'components/sections/DetailFieldTable';

export default function DetailFieldView({ api_url, ...props }) {
  const [fetchingDetails, setFetchingDetails] = useState(false)
  const [error, setError] = useState(null)
  const [details, setDetails] = useState(null)

  const fetchResource = () => {
    setFetchingDetails(true)

    ZenSmartAPI.get(api_url)
      .then(res => {
        setFetchingDetails(false)
        setDetails(res.data)
      })
      .catch(err => {
        setFetchingDetails(false)
        setDetails(null)
        setError('Could not fetch resource. Please try again later.')
        notification.error({
          message: 'Could not fetch resource...'
        })
      })
  }

  useEffect(() => {
    if (api_url) {
      fetchResource()
    }
    // eslint-disable-next-line
  }, [api_url])

  return (
    details ? (
      details.render_as === 'table' ?
        <DetailFieldTable
          section={details}
        /> :
        <Details
          details={details}
          refetchResource={fetchResource}
        />
    ) : <>No Data</>
  );
}