import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import * as _ from 'lodash';
import {Button, Modal, notification} from 'antd';
import {failTransactionsPanelRoute} from './apiRoutes';

const http = axios.create({
  baseURL: process.env.REACT_APP_ZENSMART_API_SERVER
})

http.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (error) => { Promise.reject(error) })

http.interceptors.response.use(function (response) {

  if (process.env.REACT_APP_ENV === 'production') {
    checkAppVersion(response);
  }

  if(_.isObjectLike(response.data) === false) {
    if(response?.config?.url.includes("thermal-label") === true) return response; 
    Modal.info({
      title: 'HTML response',
      width: 1200,
      closable: true,
      centered: true,
      content: <div style={{overflow:'auto', height: '500px'}} dangerouslySetInnerHTML={{__html: response.data}}></div>
    })
    return Promise.reject(response)
  }

  return response;
}, function (error) {

  if (error.response.status === 401 && error.response.data.error === "authentication-failed") {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('token');
    Cookies.remove('SESSION_TIMEOUT');
    window.location.reload();

  }

  // Handle authorization error
  if(error.response.status === 403 && _.get(error, 'response.data.error') === 'unauthorized-access') {
    notification.error({
      message: 'Unauthorized action',
      description: error.response.data.message
    });
  }

  return Promise.reject(error);
});

const checkAppVersion = (response) => {
  const appVersion = _.get(response.headers, 'x-app-version', null);

  if(
      appVersion !== null &&
      appVersion !== process.env.REACT_APP_VERSION
  ) {
    notification.info({
      key: 'app-update',
      message: 'New update available',
      duration: null,
      description: (
          <div>
            A new version of the Zensmart has been released. Please reload the page to get the latest updates. <br/>
            <Button onClick={() => window.location.reload()} type="primary" className="mt-3">
              Update Now
            </Button>
          </div>
      )
    })
  }
}

export default http