import {UserActions} from 'store/slices/userSlice.js'

const USER_LOGIN = UserActions.login.toString()
const USER_LOGOUT = UserActions.logout.toString()

const authLocalStoreMiddleware = () => next => action => {
  switch (action.type) {
    case USER_LOGIN: {
      localStorage.setItem('token', action.payload.access_token)
      localStorage.setItem('token_expiration', action.payload.expires_in)
      break
    }

    case USER_LOGOUT: {
      localStorage.removeItem('token')
      localStorage.removeItem('token_expiration')
      localStorage.removeItem('imposition')
      break
    }

    default: // no-op
  }

  next(action)
}

export default authLocalStoreMiddleware