import React, {useEffect} from 'react';

function useFocusOnSlash(inputRef) {

    useEffect(() => {
        let listener = (e) => {

            if(e.code !== 'Slash') {
                return;
            }

            if(e.target === inputRef.current.input.input) {
                return;
            }

            const ignoreElements = [
                'input',
                'textarea',
                'select',
                'button'
            ];

            if(ignoreElements.indexOf(e.target.tagName) !== -1) {
                return;
            }

            e.preventDefault();
            inputRef.current.focus();
        }

        window.addEventListener('keydown', listener)

        return () => window.removeEventListener('keydown', listener)
    }, [])

}

export default useFocusOnSlash