import React, {useContext, useEffect, useState} from 'react';
import {Form} from 'antd';
import FormModal from '../../components/FormModal';
// import PROCESS from '../../../../../types/processTypes';
import styled from 'styled-components';
import QualifierInput 
  from '../../../../../components/composites/QualifierInput';
import AutoCompleteBar
  from '../../../../../components/composites/autoCompleteBar';
import {DesignerContext} from '../../DispatchDesignerContext';

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 10px;
`;

function FieldSwitchFormModal(props) {
  const {stages, types} = useContext(DesignerContext);
  const {form, stageData, onSave, onCancel, ...modal} = props;
  const [errors, setErrors] = useState({});
  const [showQualifier, setShowQualifier] = useState(false);
  const [qualifierValue, setQualifierValue] = useState(null);
  const [modelDefinitionId, setModelDefinitionId] = useState(null);

  useEffect(() => {

    if (modal.visible) {
      let [nodeType, nodeId] = stageData.parentID.split('.');

      // no qualifier required
      if (nodeType === 'buckets' || nodeType === 'switches') {
        if (stageData.qualifier !== 'other') {

          if(nodeType === 'buckets') {
            nodeId = stages.buckets[nodeId].parent.index;
          }

          if (stages.switches[nodeId].nextNode.indexes !== null) {
            setShowQualifier(true);
            setQualifierValue(stageData.qualifier);
          }
        }
      }

      if (stageData.context) {
        setModelDefinitionId(stageData.context.id);
      }
    }

  }, [stageData]);

  if (modal.visible === false) {
    return null;
  }

  const getPayload = () => {
    return {
      type: 'dispatch_switch',
      model_definition_id: modelDefinitionId,
      parent_node_id: stageData.parentID.split('.').reverse()[0],
      qualifier: qualifierValue,
    };
  };

  const onSelectModelDefinition = result => {
    // TODO: fix the AutocompletBar where a result is being return at mount
    if (result.length === 0) {
      return;
    }
    setModelDefinitionId(result[0]);
  };

  const handleClose = () => {
    setErrors([]);
    setModelDefinitionId(null);
    setShowQualifier(false);
    setQualifierValue(null);
  }

  return (
      <FormModal {...modal} form={form} onClose={handleClose} onError={setErrors}
                 getPayload={getPayload} stageData={stageData}>
        {showQualifier &&
        <StyledFormItem validateStatus={errors['qualifier'] && 'error'}
                        help={errors['qualifier'] && errors['qualifier'][0]} label="Qualifier">
          <QualifierInput value={qualifierValue}
                          onChange={value => setQualifierValue(value)}
                          types={types.qualifiers}/>
        </StyledFormItem>
        }
        <StyledFormItem
            validateStatus={errors['model_definition_id'] && 'error'}
            help={errors['model_definition_id'] ? errors['model_definition_id'][0]: 'Start typing to look for the value'}
            label="Model Definition"
        >
          <AutoCompleteBar limit={1} prefix={`orders`} placeholder={`Select the field`}
                           returnValue={onSelectModelDefinition}/>
        </StyledFormItem>
        {!!stageData.context && (
            <div style={{
              fontWeight: 'bold',
              marginTop: '15px',
              marginBottom: '15px',
            }}>
              Current model definition: {stageData.context.label}
            </div>
        )}
      </FormModal>
  );
}

export default Form.create()(FieldSwitchFormModal);