import React, {useEffect, useState} from 'react';
import { Modal, Button, Table, Row, Pagination } from 'antd';
import moment from 'moment';
import { Label } from 'components/zensmart-design-system';
import { ZenSmartAPI } from 'utils';

const ViewModelAudit = (props) => {
  const { visible, onClose, taggableId, taggableType, modelAudit: initialModelAudit } = props;
  const [modelAudit, setModelAudit] = useState(initialModelAudit);
  const modalRef = React.useRef(null);

  useEffect(() => {
    if(visible) {
      setModelAudit(initialModelAudit)
    }
  }, [visible])

  const styles = {
    truncate: {
      display: '-webkit-box',
      WebkitLineClamp: 5,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    timeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    date: {
      textAlign: 'center',
    },
    time: {
      textAlign: 'center',
      fontStyle: 'italic',
    },
  };

  const columns = [
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      render: (text, audit) => {
        if (!audit.user) {
          return 'System';
        }
        return audit.user.name;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
    },
    {
      title: 'Time',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      render: text => {
        const formattedDate = moment(text).format('DD MMM YYYY');
        const formattedTime = moment(text).format('h:mm A');
        return (
          <div style={styles.timeContainer}>
            <div style={styles.date}>{formattedDate}</div>
            <div style={styles.time}>{formattedTime}</div>
          </div>
        );
      },
    },
    {
      title: 'Data Before',
      dataIndex: 'data_before',
      key: 'data_before',
      width: 400,
      align: 'center',
      render: text => {
        let prettyText = '';
        try {
          const parsedData = JSON.parse(text);
          prettyText = JSON.stringify(parsedData, null, 2);
          prettyText = prettyText.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        } catch (error) {
          prettyText = text;
        }

        return (
          <div style={{ width: '100%', maxWidth: '400px' }}>
            {text !== null && prettyText.trim() !== '[]' ? (
              <pre className="truncate text-left bg-gray-800 text-white rounded-lg shadow p-3 overflow-x-auto text-xs" dangerouslySetInnerHTML={{ __html: prettyText }} />
            ) : (
              <span className='italic text-xs'>No Data Available</span>
            )}
          </div>
        );
      }
    },
    {
      title: 'Data After',
      dataIndex: 'data_after',
      key: 'data_after',
      width: 400,
      align: 'center',
      render: text => {
        let prettyText = '';
        try {
          const parsedData = JSON.parse(text);
          prettyText = JSON.stringify(parsedData, null, 2);
          prettyText = prettyText.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        } catch (error) {
          prettyText = text;
        }

        return (
          <div style={{ width: '100%', maxWidth: '400px' }}>
            {text !== null && prettyText.trim() !== '[]' ? (
              <pre className="truncate text-left bg-gray-800 text-white rounded-lg shadow p-3 overflow-x-auto text-xs" dangerouslySetInnerHTML={{ __html: prettyText }} />
            ) : (
              <span className='italic text-xs'>No Data Available</span>
            )}
          </div>
        );
      }
    }
  ];

  const fetchAuditData = async (page) => {
    const url = `/api/v1/data-view-audits/${taggableType}/${taggableId}?page=${page}`;

    try {
      const response = await ZenSmartAPI.get(url);
      setModelAudit(response.data.model_audit);
    } catch (error) {
      console.error("There was an error fetching the data", error);
    }
  }

  const handlePageChange = (page) => {
    fetchAuditData(page);
  };

  return (
    <>
      <Modal
        visible={visible}
        ref={modalRef}
        destroyOnClose={true}
        forceRender={true}
        onCancel={onClose}
        width={1200}
        footer={[
          <Button key="close" onClick={event => {
            setModelAudit(null)
            onClose();
          }}>
            Close
          </Button>
        ]}
      >
        <Row type="flex" justify="center" style={{ padding: '10 20', marginBottom: '20px' }}>
          <Label status="blue">Audit History</Label>
        </Row>
        <div className="ml-2 mr-2 mx-auto">
          {modelAudit && (
            <>
              <Table
                dataSource={modelAudit.data}
                rowKey={record => record.id}
                columns={columns}
                pagination={false} />
              <div className="flex justify-center mt-4">
                <Pagination
                  total={modelAudit.total}
                  current={modelAudit.current_page}
                  pageSize={modelAudit.per_page}
                  onChange={handlePageChange}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default ViewModelAudit;