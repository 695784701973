import axios from 'axios'
import Cookies from 'js-cookie';
import * as _ from 'lodash';
import {notification} from 'antd';

const http = axios.create({
  baseURL: process.env.REACT_APP_ANALYTICS_SERVER
})

http.interceptors.response.use(function (response) {

  return response;
}, function (error) {

  // Handle authorization error
  if(error.response.status === 403 && _.get(error, 'response.data.error') === 'unauthorized-access') {
    notification.error({
      message: 'Unauthorized action',
      description: error.response.data.message
    });
  }

  return Promise.reject(error);
});


http.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.timeout = 0
  return config
}, (error) => { Promise.reject(error) })

export default http