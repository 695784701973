import React, { useEffect, useState, useCallback } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { renderToString } from 'react-dom/server'
import Timeline from 'react-visjs-timeline'
import { Modal, Switch, notification, Row, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ZenSmartAPI } from 'utils';
import { alphabeticalData } from 'utils/sortData';

import {
  SubmissionDetailsModal,
  SubmissionChangeModal
} from '../sections/'
import { isReadyPrinterRoute, getOnePrinterRoute, getSubmissionDetailsRoute, submissionChangeRoute, getSubmissionsRoute, finishingTypesRoute } from "utils/apiRoutes"
import moment from 'moment'
import { SET_SUBMISSIONS_DETAIL_DATA, SET_PRINTER_TIMELINE_DATA } from 'store/actions/controlTowerTypes';
import * as _ from 'lodash';
import { errorHandler } from 'utils/errorMessageHandler';

const PrinterModal = styled(Modal)`
`
const Dot = styled.span`
  height: 12px;
  width: 12px;
  margin-right : 10px;
  border-radius: 50%;
  display: inline-block;
`

const HeaderStyle = styled.h1`
  font-size : 16px;
  text-transform : capitalize;
  font-family: 'Rubik', sans-serif;
  margin : 0px 8px;

`
const VisJsStyleOverrides = createGlobalStyle`
  .vis-item {
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer;
    display: flex;
  }
  .ant-switch {
    background-color: red;
  }
  .ant-switch-checked {
    background-color: green;
  }
 
  .vis-foreground > .vis-group {
    border-right: 1px solid lightgrey;
  }

  .vis-foreground > .vis-group:not(:last-child) {
    border-bottom: 1px dashed lightgrey !important;
  }

  .vis-time-axis .vis-grid.vis-vertical {
    border-left: 1px dashed lightgrey !important;
  }

  .vis-panel.vis-center {
    border: 1px solid lightgrey !important;
  }

  .vis-text.vis-minor {
    border-left: 2px solid #fff !important;
  }


  .vis-item {
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer;
    background-color : #55585d;
  }
  img {
    height : 20px !important;
  }
  .vis-inner {
    cursor : pointer;
  }
  .vis-inner p {
    margin-left : 20px;
  }
  .vis-inner img {
    margin-top : -20px;
  }
  .item-group {
  }

`
function PrinterTimeline(props) {

  const dispatch = useDispatch();
  const printerData = useSelector(state => state.controlTower.printers)
  const submissionsData = useSelector(state => state.controlTower.submissions)
  const finishingTypeData = useSelector(state => state.controlTower.finishing_types)
  const { updatePrinterTimeline, locationId, options, setOption, fetchTUpdateSubmissionDatas } = props
  const [groups, setgroups] = useState([])
  const [printerModalValue, setPrinterModalValue] = useState(null)
  const [showPrinterModal, setShowPrinterModal] = useState(false)
  const [masterSubmissionValue, setMasterSubmissionValue] = useState(null)
  const [showSubmissionsModal, setShowSubmissionsModal] = useState(false)
  const [switchCheck, setSwitchCheck] = useState(false)
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [itemColors, setItemColors] = useState([])
  const [itemColorStyle, setItemColorStyle] = useState(null)
  const [submissionChangeModal, setSubmissionChangeModal] = useState(false)
  const [submissionChangePayload, setSubmissionChangePayload] = useState({})
  const [currentRunValue, setCurrentRunValue] = useState(null)
  const VisItemOverrides = createGlobalStyle`
  ${itemColorStyle}
  `
  const getHour = parseInt(moment().format("H"));
  const getMinute = parseInt(moment().format("m"));
  const today = new Date()
  const tomorrow = today.setDate(today.getDate() + 1)

  const changeSubmission = (payload) => {
    if (!payload.finishing_type_id) {
      notification.error({ message: "Please select finishing type!" })
      return
    }
    ZenSmartAPI.post(submissionChangeRoute, payload)
      .then((res) => {
        setItems([])
        setOption({ ...options, start: null, end: null })
        updatePrinterTimeline(true)
        setSubmissionChangeModal(false)
        setSubmissionChangePayload({})
        notification.success({
          message: "Successfully change time or printer of submission!"
        })
      })
      .catch((res) => {
        if (_.get(res, 'response.status') === 422) {
          notification.error({
            message: errorHandler(_.get(res, 'response.data.errors'))
          })
          return
        }
        else if (_.get(res, 'response.data.message')) {
          notification.error({
            message: _.get(res, 'response.data.message')
          })
          return
        }
        notification.error({
          message: "Unable change time or printer of submission!"
        })
      })
  }
  const getUnique = (arr, comp) => {

    const unique = arr.map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e]).map(e => arr[e]);
    return unique;
  }

  const checkIfNotDraggable = (submissions) => {

    const returnData = submissions.some(submission => {
      console.log(`submission.status`, submission.status)
      return submission.status != "waiting"
    });

    return !returnData

  }

  const fetchSubmissionDetails = (master_id) => {
    ZenSmartAPI.get(getSubmissionDetailsRoute(master_id))
      .then((res) => {
        setShowSubmissionsModal(true)
        dispatch({
          type: SET_SUBMISSIONS_DETAIL_DATA,
          payload: res.data.data
        })
      })
      .catch((res) => {
        if (_.get(res, 'response.status') === 422) {
          notification.error({
            message: errorHandler(_.get(res, 'response.data.errors'))
          })
          return
        }
        else if (_.get(res, 'response.data.message')) {
          notification.error({
            message: _.get(res, 'response.data.message')
          })
          return
        }
        notification.error({
          message: "Unable to Get Submission Details!"
        })
      })
  }

  const updateSubmissionDetails = (master_id) => {
    fetchSubmissionDetails(master_id)
    fetchTUpdateSubmissionDatas(locationId)
  } // eslint-disable-line react-hooks/exhaustive-deps

  const setPrinterDataGroupsValue = (printerValue, state) => {
    const newPrinterValue = printerData.data.map(data => {
      if (data.id === printerValue.id) {
        return { ...data, is_ready: state }
      }
      return data

    })

    dispatch({
      type: SET_PRINTER_TIMELINE_DATA,
      payload: { data: newPrinterValue }
    })

  }
  const isPrinterReady = (checked, autoRunValue = null) => {
    if (!printerModalValue.machine_ready || !printerModalValue.server_ready) {
      notification.error({
        message: "Server or machine is not ready!"
      })
      setSwitchCheck(false)
      return;
    }

    const state = checked ? 1 : 0
    const payload = { printer_name: printerModalValue.full_name, state, current_run_id: autoRunValue }
    setLoading(true);

    ZenSmartAPI.post(isReadyPrinterRoute, payload)
      .then(async (res) => {
        setOption({ ...options, start: null, end: null })
        setCurrentRunValue(autoRunValue)
        setPrinterDataGroupsValue(printerModalValue, state)
        setLoading(false)
        setPrinterModalValue({ ...printerModalValue, is_ready: checked })
        setSwitchCheck(checked)
      })
      .catch((res) => {
        setLoading(false)
        setSwitchCheck(checked ? false : true)
        if (_.get(res, 'response.status') === 422) {
          notification.error({
            message: errorHandler(_.get(res, 'response.data.errors'))
          })
          return
        }
        else if (_.get(res, 'response.data.message')) {
          notification.error({
            message: _.get(res, 'response.data.message')
          })
          return
        }
        console.log(`res`, res)
        notification.error({
          message: "Unable to Update Printer!"
        })
      })
  }

  const fetchTSubmissionDatas = async (id) => {
    setLoading(true)
    const returnValue = { value: null }
    await ZenSmartAPI.get(getSubmissionsRoute(id))
      .then((res) => {
        setLoading(false)
        returnValue.value = res.data
      })
      .catch((res) => {
        returnValue.value = null
      })

    return returnValue.value

  }

  const clickHandler = async (props) => {
    if (props.what === "group-label") {
      ZenSmartAPI.get(getOnePrinterRoute(props.group))
        .then((res) => {
          setPrinterModalValue(res.data.data)
          setCurrentRunValue(res.data.data.current_run_id)
          setShowPrinterModal(true);

        })
        .catch((res) => {
          notification.error({
            message: "Unable to Get Printer!"
          })
        })
    }
  }
  const doubleClickHandler = async (props, locationId) => {
    if (props.what === "item") {
      const fetchSubmission = await fetchTSubmissionDatas(locationId)
      fetchSubmissionDetails(props.item)
      setMasterSubmissionValue(fetchSubmission.data.find((item) => {
        return item.master_id === props.item;
      }));

    }
  }
  const getSubmissionColors = () => {
    const submissionColors = submissionsData.data.map(data => {
      return { name: data.primary_group_name, color: data.colour }
    })
    const filterColors = [];
    submissionColors.forEach(function (item) {
      const checkColor = filterColors.some(data => {
        return data.name === item.name
      })
      if (!checkColor) {
        filterColors.push(item);
      }
    });

    var styleItemColors = ""
    filterColors.map(item => {
      const addFilterColor = `.vis-item.color${item.color ? item.color.replace('#', '') : null} {
        background-color : ${item.color} !important;
            }`
      styleItemColors += addFilterColor;
      return item
    })

    setItemColorStyle(styleItemColors);
    setItemColors(filterColors);
  }

  const getSubmissionFinish = (submission) => {
    let estimate = 0;
    const timeNow = new Date().getTime() / 1000
    if (submission.finish !== null) {
      estimate = (submission.finish - submission.send_time) + submission.send_time;
    }
    else {
      estimate = submission.send_time + submission.estimate

      estimate = estimate < timeNow ? timeNow + 60 : estimate
    }

    return estimate
  }

  useEffect(() => {
    const interval = setInterval(() => {
      updatePrinterTimeline(false, false, locationId, true);
      setOption({
        ...options, start: moment().subtract(45, 'minutes'),
        end: moment().add(50, 'minutes'),
      })
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [locationId]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setOption({
      ...options,
      zoomMax: 300000000,
      editable: {
        updateTime: false,
        updateGroup: false,
        remove: false,
        overrideItems: false,
      },
      zoomKey: 'ctrlKey',
      orientation: "both",
      start: moment().subtract(45, 'minutes'),
      end: moment().add(50, 'minutes'),
      onMove: function (item) {
        const payload = { master_submission_id: item.id, printer_id: item.group, new_time: new Date(item.start).getTime() / 1000, old_submission_data: submissionsData.data.find(data => data.master_id === item.id) }
        setSubmissionChangePayload(payload)
        setSubmissionChangeModal(true)

      },
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setOption({
      ...options,
      zoomMax: 300000000,
      editable: {
        updateTime: false,
        updateGroup: false,
        remove: false,
        overrideItems: false,
      },
      zoomKey: 'ctrlKey',
      orientation: "both",
      start: options.start === null ? null : moment().subtract(45, 'minutes'),
      end: options.end === null ? null : moment().add(50, 'minutes'),
      onMove: function (item) {
        const payload = { master_submission_id: item.id, printer_id: item.group, new_time: new Date(item.start).getTime() / 1000, old_submission_data: submissionsData.data.find(data => data.master_id === item.id) }
        setSubmissionChangePayload(payload)
        setSubmissionChangeModal(true)

      },
    })
  }, [printerData, submissionsData]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (printerData) {
      const groupData = printerData.data.map((data, key) => {
        return {
          id: data.id,
          style: `color: ${!data.machine_ready || !data.server_ready || !data.is_ready ? "red" : "green"};`,
          className: "item-group",
          content: renderToString(<div style={{ padding: 10, cursor: "pointer" }}>
            <h1 style={{ fontSize: 30, fontWeight: "bold" }}> {data.full_name}</h1>
            {!data.machine_ready || !data.server_ready || !data.is_ready ?
              <div>
                <p style={{ verticalAlign: "middle", fontSize: 16, color: "red" }}>
                  Not Ready
                </p>
                <img src="https://img.icons8.com/emoji/452/exclamation-mark-emoji.png" />
              </div>
              : <div>
                <p style={{ verticalAlign: "middle", fontSize: 16, color: "red" }}>
                  Ready
                </p>
                <img src="https://www.freeiconspng.com/thumbs/check-tick-icon/tick-icon-44.png" />
              </div>
            }
          </div>
          )
        }
      })
      setgroups(groupData)
    }
  }, [printerData, submissionsData]) // eslint-disable-line react-hooks/exhaustive-deps



  useEffect(() => {
    if (printerModalValue) {
      if (!printerModalValue.machine_ready || !printerModalValue.server_ready || !printerModalValue.is_ready) {
        setSwitchCheck(false)
      }
      else {
        setSwitchCheck(printerModalValue.is_ready)
      }
    }
  }, [printerModalValue])


  useEffect(() => {

    if (submissionsData.data) {
      const uniqueItems = getUnique(submissionsData.data, 'master_id');

      getSubmissionColors();
      const itemDatas = uniqueItems.map((submissions) => {

        let startTime = null;
        let estimate = 0;

        const filteredSubmissionsByMasterId = submissionsData.data.filter(item => {
          return item.master_id === submissions.master_id && item.status !== "cancelled" && item.status !== "deleted"
        })


        filteredSubmissionsByMasterId.map(item => {
          const sendTimeValue = new Date(moment.unix(item.send_time).format(`YYYY-MM-DDTHH:mm:ss.sssZ`))
          if (startTime == null || sendTimeValue < startTime) {
            startTime = sendTimeValue;
          }
          const getEstimateValue = getSubmissionFinish(item);
          if (getEstimateValue > estimate) {
            estimate = getEstimateValue
          }

        })

        const estimateTime = new Date(moment.unix(estimate).format(`YYYY-MM-DDTHH:mm:ss.sssZ`))
        const editableTimeAndPrinter = checkIfNotDraggable(submissionsData.data.filter(item => item.master_id === submissions.master_id))

        const submissionObject = {
          id: submissions.master_id,
          content: " ",
          start: startTime,
          end: estimateTime,
          group: submissions.printer_id,
          className: `color${submissions.colour ? submissions.colour.replace('#', '') : null}`
        }

        if (editableTimeAndPrinter) {
          submissionObject.editable = {
            add: true,
            updateTime: true,
            updateGroup: true,
          }

        }

        return submissionObject
      })
      setItems(itemDatas)
    }
  }, [submissionsData])

  return (
    <>
      <VisJsStyleOverrides />
      <VisItemOverrides />
      <div style={{ padding: "30px 40px" }}>
        {itemColors.length > 0 ?
          <div style={{ padding: 20 }}>
            <Row type="flex" justify="end" align="middle">
              {itemColors.map(item =>
                <HeaderStyle style={{ textAlign: 'right' }}>
                  <Dot style={{ backgroundColor: item.color, verticalAlign: 'middle', marginTop: -3 }}></Dot>
                  {item.name.toLowerCase()}
                </HeaderStyle>
              )}
            </Row>
          </div>
          : null}
        {locationId &&
          <Timeline
            options={options}
            items={printerData && printerData.data && printerData.data.length > 0 ? items : []}
            groups={printerData && printerData.data && printerData.data.length > 0 ? groups : [{
              className: "item-group",
              content: "",
              id: 1,
            }]}
            clickHandler={clickHandler}
            doubleClickHandler={(e) => doubleClickHandler(e, locationId)}
          />
        }
        {printerModalValue ?

          <PrinterModal
            title={printerModalValue.full_name}
            style={{ top: 20 }}
            centered
            width={400}
            visible={showPrinterModal}
            onCancel={() => {
              setShowPrinterModal(false);
            }}
            footer={null}
          >
            <p style={{ padding: 5 }}>Server : {printerModalValue.server_ready ? <span style={{ color: "green" }}>Available</span> : <span style={{ color: "red" }}>Not Available</span>}</p>
            <p style={{ padding: 5 }}>Machine : {printerModalValue.machine_ready ? <span style={{ color: "green" }}>Available</span> : <span style={{ color: "red" }}>Not Available</span>}</p>
            <p style={{ padding: 5 }}>Operator : <span><Switch loading={loading} checked={switchCheck} onChange={(e) => isPrinterReady(e, currentRunValue)} />{switchCheck == true ? " Ready" : " Not Ready"}</span></p>
            <p style={{ padding: 5 }}>Current Run : <Select optionFilterProp="children"
              showSearch style={{ width: "100%" }}
              value={currentRunValue}
              onChange={(e) => isPrinterReady(printerModalValue.is_ready, e)}
            >
              {alphabeticalData(printerModalValue.runs, "description").map((data, key) =>
                <Select.Option value={data.id} style={{ textTransform: "capitalize" }}>{data.description}</Select.Option>
              )}
            </Select></p>
          </PrinterModal>
          : null}
        <SubmissionDetailsModal
          showSubmissionsModal={showSubmissionsModal}
          setShowSubmissionsModal={setShowSubmissionsModal}
          masterSubmissionValue={masterSubmissionValue}
          updateSubmissionDetails={updateSubmissionDetails}
          printerData={printerData}

        />
        <SubmissionChangeModal
          submissionChangeModal={submissionChangeModal}
          setSubmissionChangeModal={setSubmissionChangeModal}
          finishingTypeData={finishingTypeData}
          changeSubmission={changeSubmission}
          submissionChangePayload={submissionChangePayload}
          setSubmissionChangePayload={setSubmissionChangePayload}
          updatePrinterTimeline={updatePrinterTimeline}
          printerData={printerData}
          locationId={locationId}
          fetchTSubmissionDatas={fetchTSubmissionDatas}
          setOption={setOption}
          options={options}
        />
      </div>
    </>
  )
}

export default PrinterTimeline