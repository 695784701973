import React, { useState, useEffect } from 'react';
import { Row, Col, Icon, notification } from 'antd';
import { FilterItem, DropRegion } from '../primaries';
import FilterEditor from './FilterEditor';
import { GetOperations, DataTypes, CheckTypeAndConvert, GetLabel } from 'pages/Performance/utils';
import { InputSection } from '../composites';

const { GetDataType, DATATYPE } = DataTypes

const NumeratorFilters = ({
  filterItems,
  setFilterItems,
  allFields,
  onDragOver,
  onDrop,
  droppedItem,
  setDroppedItem,
  deleteItem,
  edit,
  setEdit,
  editableReport,
  type
}) => {
  const [operations, setOperations] = useState([])
  const [inputType, setInputType] = useState(droppedItem.type)
  const [currentFilterKey, setCurrentFilterKey] = useState(undefined)
  const [hideFilterText, setHideFilterText] = useState(null)

  const setTypeAndOperations = (type) => {
    setOperations(GetOperations(GetDataType(type), 'secondary'))
    setInputType(type)
  }

  const setDateFormat = (currentFilter) => {
    if (currentFilter.to && currentFilter.from) {
      currentFilter.from = currentFilter.from.replace("00:00:00", "");
      currentFilter.from = currentFilter.from.replace("23:59:59", "");
      currentFilter.to = currentFilter.to.replace("00:00:00", "");
      currentFilter.to = currentFilter.to.replace("23:59:59", "");

      if (currentFilter.from.trim() == currentFilter.to.trim()) {
        currentFilter.from = `${currentFilter.from} 00:00:00`
        currentFilter.to = `${currentFilter.to} 23:59:59`
      }
      else {
        currentFilter.from = `${currentFilter.from} 00:00:00`
        currentFilter.to = `${currentFilter.to} 23:59:59`
      }
    }
    return currentFilter
  }

  const allDone = (currentFilter) => {
    const theType = GetDataType(currentFilter.type)

    const currentFilterData = currentFilter


    if (theType !== DATATYPE.time && currentFilter.operation == undefined) {
      currentFilterData.operation = "equals"
    }

    if (currentFilterData.operation === "is empty" || currentFilterData.operation === "is not empty") {
      currentFilterData.value = [""];
    } else if (theType === DATATYPE.time) {
      if (currentFilter.from === "null" || !currentFilter.from || !currentFilter.to || currentFilter.to === "") {
        notification.error({ message: "Invalid Input" })
        return;
      }
      delete currentFilter.operation
      const dateFormat = setDateFormat(currentFilter)
      currentFilter.from = dateFormat.from
      currentFilter.to = dateFormat.to
      if (currentFilter.value == "custom") {
        currentFilterData.value = ["custom"];
      }
    }

    if (
      Object.keys(DATATYPE).includes(theType) &&
      currentFilterData.value !== undefined &&
      currentFilterData.value.length > 0 &&
      (theType !== DATATYPE.time ? (currentFilterData.operation) : true) &&
      currentFilterData.field
    ) {

      if (theType !== DATATYPE.time && theType !== DATATYPE.bool) {
        currentFilter.value = CheckTypeAndConvert(theType, currentFilter.value)
        setHideFilterText(null)
        if (currentFilter.value.length === 0) {
          notification.error({ message: "Invalid Input" })
        }
      }

      if (currentFilterData.value.length > 0) {

        setFilterItems && setFilterItems(currentFilterData, currentFilterKey)
        setCurrentFilterKey(undefined)
        setEdit && setEdit(false);
        setHideFilterText(null)
        return
      }
    }
    else {
      notification.error({ message: "Invalid Input" })
    }
  }

  useEffect(() => {
    setTypeAndOperations(droppedItem.type)
  }, [droppedItem]);

  const itemSelected = (selectedItem) => {
    setTypeAndOperations(selectedItem.type)
  }

  const editFilterItem = (key) => {
    setHideFilterText(key)
    setEdit(true)
    setCurrentFilterKey(key)
    setDroppedItem(filterItems[key])
  }


  return (
    <Row style={{ paddingTop: 20, paddingLeft: 48, paddingRight: 33, paddingBottom: 20 }}>
      <Col droppable='true' onDragOver={onDragOver} onDrop={onDrop}>
        {editableReport ? (
          <>
            <h1 style={{
              fontWeight: "bold"
            }}>
              {type ? (type === 'primary' ? 'Primary Numerator Filters' : 'Secondary Numerator Filters') : 'Numerator Filters'}
            </h1>
            <DropRegion
              style={{
                backgroundColor: 'rgba(209,217,246,0.9)',
                marginBottom: 5,
                padding: 5
              }}
            >
              Drop a field here to filter.
            </DropRegion>

            {Object.keys(filterItems).map((key) =>
              <FilterItem key={filterItems[key].field} style={hideFilterText === key ? { display: "none" } : undefined}>
                <h3>{GetLabel(`${filterItems[key].table}.${filterItems[key].field}`)}</h3>
                <p>{filterItems[key].operation}</p>
                <h2>{(!Array.isArray(filterItems[key].value) || filterItems[key].value.length === 0) ? filterItems[key].value : filterItems[key].value.join(' OR ')}
                  {filterItems[key].value == "custom" ? ` ${filterItems[key].from.replace("00:00:00", "")} to ${filterItems[key].to.replace("23:59:59", "")}` : null}
                </h2>
                <Icon type='edit' onClick={() => editFilterItem(key)} />
                <Icon type='delete' onClick={() => deleteItem(filterItems[key])} />
              </FilterItem>
            )}
            {edit &&
              <FilterEditor
                visible={edit}
                setVisibility={setEdit}
                allItems={allFields}
                selectedItem={droppedItem}
                showCancel={true}
                operations={operations}
                inputType={inputType}
                itemFieldSelected={itemSelected}
                OKClicked={allDone}
              />

            }
          </>
        ) : (
            Object.keys(filterItems).map(key => (
              <Row style={{ paddingLeft: 0, paddingRight: 33, paddingTop: 0, paddingBottom: 0, display: 'inline-block' }}>
                <InputSection label={GetLabel(`${filterItems[key].table}.${filterItems[key].field}`)} position='top' labelStyle={{ color: '#B0BAC9' }} >
                  <FilterEditor
                    visible={true}
                    allItems={allFields}
                    selectedItem={filterItems[key]}
                    operations={[filterItems[key].operation]}
                    inputType={filterItems[key].type}
                    // itemFieldSelected={itemSelected}
                    editChange={() => {
                      setCurrentFilterKey(key)
                    }}
                    showOK={currentFilterKey === key && editableReport === true}
                    OKClicked={allDone}
                    mode='edit'
                    editableReport={editableReport}
                  />
                </InputSection>
              </Row>
            ))
          )}

      </Col>
    </Row>
  )
}

export default NumeratorFilters