import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Select, Icon } from 'antd'
import _ from "lodash"
import { FilterItem } from '../../Performance/partials/primaries';
import { Button } from '../../../components/zensmart-design-system';
import { sorterConfigurationRoute } from "utils/apiRoutes"
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import { ZenSmartAPI } from 'utils'


const IconStyle = styled(Icon)`
  cursor : pointer;
`

const SorterFieldsComponent = (props) => {
    const { data, fieldSelectValues, deleteSortOrder, setLoading, key, changeSorterFieldValues } = props
    const [editMode, setEditMode] = useState(false)
    const [oneSorterValue, setOneSorterValue] = useState({})

    const updateSorterNewFieldValue = (value, key) => {

        if (key === "sort_direction" && value !== 'custom') {
            setOneSorterValue({ ...oneSorterValue, sort_values: null, [key]: value })

        }
        else if (key === "sort_direction" && value === 'custom') {
            setOneSorterValue({ ...oneSorterValue, sort_values: [], [key]: value })

        }
        else {
            setOneSorterValue({ ...oneSorterValue, [key]: value })
        }
    }

    const updateSorter = (sorterId) => {
        setLoading(true)
        const payload = oneSorterValue

        if (payload.sort_values) {
            delete payload.sort_direction
        }
        ZenSmartAPI.put(sorterConfigurationRoute(sorterId), payload)
            .then((res) => {
                setLoading(false)
                setEditMode(false)
                setOneSorterValue(res.data.data)
                changeSorterFieldValues(res.data.data, key)
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant Update sorter!")
            })

    }

    const cancelEdit = () => {
        setEditMode(false)
        setOneSorterValue(data)
    }
    useEffect(() => {
        if (data) {
            setOneSorterValue(data);
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    return !editMode ?
        <FilterItem >
            <h2 style={{ textTransform: "capitalize", color: "black" }}>{oneSorterValue && oneSorterValue.label} </h2>
            {oneSorterValue.sort_values ? <p>custom</p> : ", "}
            <h2 style={{ textTransform: "capitalize", color: "blue" }}> {oneSorterValue.sort_values ? oneSorterValue.sort_values.map(data => ` ${data},`) : oneSorterValue && oneSorterValue.sort_direction ? oneSorterValue.sort_direction.toUpperCase() : null}</h2>
            <IconStyle type='edit' onClick={() => setEditMode(true)} />
            <IconStyle type='delete' onClick={() => deleteSortOrder(data.id)} />
        </FilterItem> :
        <Row type="flex" align="middle">
            <Col span="7" style={{ padding: 10 }}>
                <Select
                    showSearch
                    style={{ width: "100%" }}
                    dropdownMatchSelectWidth={false}
                    placeholder="Enter Field (Click Suggestions to add each field)"
                    optionFilterProp="children"
                    value={_.get(oneSorterValue, 'model_definition_id', undefined)}
                    onChange={(value) => updateSorterNewFieldValue(value, 'model_definition_id')}
                >
                    {fieldSelectValues && fieldSelectValues.map(data =>
                        <Select.Option value={data.id}>{data.label.replace('lines.', '')}</Select.Option>
                    )}
                </Select>
            </Col>

            <Col span="5" style={{ paddingLeft: 10 }}>
                <Select
                    showSearch
                    dropdownMatchSelectWidth={false}
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    value={oneSorterValue.sort_values ? "custom" : oneSorterValue.sort_direction}
                    onChange={(value) => updateSorterNewFieldValue(value, "sort_direction")}
                >
                    <Select.Option value={"ascending"}>Ascending</Select.Option>
                    <Select.Option value={"descending"}>Descending</Select.Option>
                    <Select.Option value={"custom"}>Custom</Select.Option>
                </Select>
            </Col>
            {oneSorterValue.sort_values &&
                <Col span="5" style={{ paddingLeft: 10 }}>
                    <Select
                        showSearch
                        dropdownMatchSelectWidth={false}
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        value={oneSorterValue.sort_values}
                        onChange={(value) => updateSorterNewFieldValue(value, "sort_values")}
                        mode='tags'
                    >
                    </Select>
                </Col>
            }

            <Col span="5" style={{ padding: "5px 5px 5px 10px" }}>
                <Button size="small" style={{ width: "100%" }} onClick={() => updateSorter(oneSorterValue.id)}>Update
                </Button>
            </Col>
            <Col span="4" style={{ padding: 5 }}>
                <Button size="small" style={{ background: "red", width: "100%" }} onClick={cancelEdit} >Cancel
                </Button>
            </Col>
        </Row>

}

export default SorterFieldsComponent