import React from 'react'
import styled from 'styled-components'
import {Modal, Form, Button, Select, notification} from 'antd';

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`

const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`

const processTextRules = [
  {
    required: true,
    message: 'Cannot be blank'
  },
  {
    max: 23,
    message: 'Must be less than 23 characters'
  }
];

function EditForm(props) {
  const {
    form,
    process,
    onSave,
    hideModal,
    flowConfigured
  } = props

  const {
    getFieldDecorator,
    isFieldTouched,
    getFieldError,
    getFieldsValue
  } = form

  const handleSubmit = e => {
    e.preventDefault()

    const selectedProductFlow = flowConfigured.find(product => product.id === getFieldsValue().productFlow);
    const processes = selectedProductFlow.processes;
    const flow = selectedProductFlow.flow;

    onSave(processes, flow)
    hideModal()
    notification.success({
      message: 'Saved selected template.'
    })
  }

  const processTextError = isFieldTouched('text') && getFieldError('text')

  return (
    <Form layout="vertical" onSubmit={handleSubmit} width={700}>
      <StyledFormItem
        label="Product flow"
        validateStatus={processTextError ? 'error' : ''}
        help={processTextError || ''}
      >
        {getFieldDecorator('productFlow', {
          initialValue: process && process.productFlow,
          rules: processTextRules
        })(<Select placeholder="Select">
            {flowConfigured.map(({id, code, name}) => {
              return (
              <Select.Option key={id} value={id}>
                {`${code}${name ? (`: ${name}`) : ""}`}
              </Select.Option>
              )
            })}
          </Select>
        )}
      </StyledFormItem>
      <ButtonContainer>
        <Button onClick={hideModal}>Cancel</Button>

        <Button
          type="primary"
          htmlType="submit"
        >
          Save Changes
        </Button>
      </ButtonContainer>
    </Form>
  )
}

const PreDispatchForm = Form.create({name: 'Pre Dispatch Form'})(EditForm)

function TemplateModal(props) {
  const {process, onSave, processID, flowConfigured, ...modal} = props
  return (
    <Modal {...modal}>
      <PreDispatchForm
        process={process}
        onSave={onSave}
        processID={processID}
        flowConfigured={flowConfigured}
        hideModal={modal.onCancel}
      />
    </Modal>
  )
}

export default TemplateModal