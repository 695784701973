import React, { useEffect, useState, useRef } from "react";
import { Widget } from "pages/Performance/Widget";
import {
  sortObjects,
  formatUniqueCount,
  defaultChartLeft,
  defaultChartBottom
} from "pages/Performance/utils";
import {
  LoadData,
  GetUniqueValues,
  GetMatrixData,
  GetUniqueValuesCount,
  GetFields,
} from "pages/Performance/api";
import styled from "styled-components";
import is from "is_js";
import {
  color,
  spacing,
} from "components/zensmart-design-system/shared/styles";
import { notification } from "antd";
import _ from "lodash";
import { emptyRecords } from "../../utils/ReportingUtil";
import {GetDateValues} from "../../utils";

const StyledDiv = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "auto")};
  background: ${color.white};
  border-radius: ${spacing.borderRadius.small}px;

  ${(props) =>
    props.active &&
    `
    -webkit-box-shadow: 0px 0px 0px 1px ${color.border.unselected};
    -moz-box-shadow: 0px 0px 0px 1px ${color.border.unselected};
    box-shadow: 0px 0px 0px 1px ${color.border.unselected};
  `}

  .state_title {
    margin-left: 5px;
  }
  
  .widget_title {
    color: #8798AD;
    font-size: 13px;
    text-transform: uppercase;
    z-index: 9999;
    width: ${props => (is.existy(props.widgetWidth) && props.widgetWidth < 80) ? '75%' : '90%'};
    margin-left: 5px;
    
    .bold_title {
      font-weight: bold;
      font-size: 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

function DashboardItem(props) {
  const {
    parentRef,
    refIndex,
    report,
    sidebar,
    interruptTimer,
    refreshData = false,
    gridWidth,
    gridHeight,
    currentUser,
    legendLabels,
    matrixRef,
    updateMatrixFont,
    matrixOptions,
    reportURL,
    mode,
    homePage,
  } = props;

  const { name, groups, visualization } = report;

  const currentRef = is.existy(refIndex)
    ? parentRef.current[refIndex]
    : parentRef.current;

  const [chartConfiguration, setChartConfiguration] = useState(
    report.chart_configuration
  );

  const loadingMessage = "Loading...";
  const widgetErrMessage = "An error occurred";

  const [dataFields, setDataFields] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [uniqueValues, setUniqueValues] = useState([]);
  const [matrixDataSource, setMatrixDataSource] = useState({});
  const [widgetState, setWidgetState] = useState(loadingMessage);

  //References to get updated state for interval
  const reportRef = useRef(report);

  const pushURL = (e, url) => {
    if(!url){
      return false;
    }
    e.preventDefault();
    window.open(url);
  }

  useEffect(() => {
    fetchDashboardData();

    if (refreshData) {
      //Set timer when dashboard widget is mounted
      const interval = setInterval(fetchDashboardData, 300000);
      return () => {
        //Disable timer when dashboard widget is unmounted
        clearInterval(interval);
      };
    }
  }, []);

  function fetchDashboardData() {
    if (is.existy(interruptTimer) && interruptTimer()) {
      return;
    }

    initDashboardWidgetData();

    const {
      table,
      fields,
      groups,
      primary_filter,
      secondary_filter,
      sort_field,
      bucket_fields,
      grouped_date,
      group_matrix_x,
      group_matrix_y,
    } = reportRef.current;

    const reportType = reportRef.current.visualization;
    const errorMessage = "Error loading " + reportType + " data";
    let primaryFilters = _.cloneDeep(primary_filter);
    let secondaryFilters = _.cloneDeep(secondary_filter);

    // match the from and to date base on the date and time now (e.g today)
    if (primary_filter && primary_filter.length > 0) {
      const modifyPrimaryFilter = primaryFilters.map(data => {
        if ((data.type === "timestamp" || data.type === "date") && (data.value && data.value.length > 0 && data.value[0] !== "custom" && data.value[0] !== null) && Array.isArray(data.value)) {
          data.from = GetDateValues(data.value[0])[0].format('YYYY-MM-DD') + " 00:00:00"
          data.to = GetDateValues(data.value[0])[1].format('YYYY-MM-DD') + " 23:59:59"
        }
        return data
      })
      primaryFilters = modifyPrimaryFilter;
    }

    if (secondary_filter && secondary_filter.length > 0) {
      const modifySecondaryFilter = secondaryFilters.map(data => {
        if ((data.type === "timestamp" || data.type === "date") && (data.value && data.value.length > 0 && data.value[0] !== "custom" && data.value[0] !== null) && Array.isArray(data.value)) {
          data.from = GetDateValues(data.value[0])[0].format('YYYY-MM-DD') + " 00:00:00"
          data.to = GetDateValues(data.value[0])[1].format('YYYY-MM-DD') + " 23:59:59"
        }
        return data
      })
      secondaryFilters = modifySecondaryFilter;
    }

    if (
      reportType === "tabular" ||
      (is.empty(chartConfiguration) &&
        (reportType === "summary" ||
          reportType === "total"))
    ) {
      LoadData({
        table: table,
        secondary_filter: secondaryFilters,
        primary_filter: primaryFilters,
        fields: fields,
        bucket_fields: bucket_fields,
        mode: mode,
        sort_field: sort_field ? JSON.parse(sort_field) : {},
        visualization: reportType,
      })
        .then((result) => {

          if (reportType === "total"){
            setDataSource(result["actual_record_count"]);
          }else{
            setDataSource(result["data"]);
          }

          if (reportType === "tabular" && is.empty(result["data"])) {
            setWidgetState(emptyRecords);
          }

          if (reportType === "summary") {
            GetFields({
              table: table,
            })
              .then((result) => {
                setDataFields(result.data);
                GetUniqueValues({
                  table: table,
                  groups: groups,
                  fields: fields,
                  secondary_filter: secondaryFilters,
                  primary_filter: primaryFilters,
                  bucket_fields: bucket_fields,
                  grouped_dates: is.existy(grouped_date) ? grouped_date : [],
                })
                  .then((result) => {
                    setUniqueValues(sortObjects(result));
                  })
                  .catch((err) => {
                    setWidgetState(widgetErrMessage);
                    notification.error({
                      message: errorMessage,
                    });
                  });
              })
              .catch((err) => {
                setWidgetState(widgetErrMessage);
                notification.error({
                  message: errorMessage,
                });
              });
          }
        })
        .catch((err) => {
          setWidgetState(widgetErrMessage);
          notification.error({
            message: errorMessage,
          });
        });
    } else if (reportType === "matrix" && is.empty(chartConfiguration)) {
      if ((is.not.existy(group_matrix_x) && is.not.existy(group_matrix_y)) ||
          (group_matrix_x &&
              group_matrix_y &&
              group_matrix_x.length === 0 &&
              group_matrix_y.length === 0)
      ) {
        setWidgetState(emptyRecords);
        setMatrixDataSource({
          x_fields: [],
          y_fields: [],
          grouped_data: {},
        });
      } else {
        GetMatrixData({
          table: table,
          secondary_filter: secondaryFilters,
          primary_filter: primaryFilters,
          // add number fields to allow for matrix summarization; average, sum, etc
          fields: matrixOptions.field ? [matrixOptions.field] : [],
          groupX: is.existy(group_matrix_x)
              ? group_matrix_x
              : [],
          groupY: is.existy(group_matrix_y)
              ? group_matrix_y
              : [],
          grouped_dates: is.existy(grouped_date)
              ? grouped_date
              : [],
          bucket_fields: bucket_fields,
          matrix_summary: matrixOptions ? matrixOptions.option || null : null,
          matrix_summary_field: matrixOptions ? matrixOptions.field || null : null,
        })
            .then(({ data }) => {
              if (is.empty(data.x_fields) || is.empty(data.y_fields)){
                setWidgetState(emptyRecords);
              }

              setMatrixDataSource(data);
            })
            .catch((err) => {
              setWidgetState(widgetErrMessage);
              notification.error({
                message: errorMessage,
              });
            });
      }
    }else if (
      (reportType === "summary" || reportType === "matrix") &&
      is.not.empty(chartConfiguration)
    ) {
      const newChartConfiguration = _.cloneDeep(chartConfiguration);
      let matrixEditorOptions = matrixOptions ? matrixOptions : {
        field: "",
        option: "record count",
      };

      if (
        !_.isEmpty(newChartConfiguration) &&
        newChartConfiguration.type &&
        newChartConfiguration.yAxis &&
        chartConfiguration
      ) {
        const fields = [newChartConfiguration.yAxis];
        if (newChartConfiguration.stackData) {
          fields.push(newChartConfiguration.stackData);
        }

        // logics to update the charts when grouping is updating
        if (newChartConfiguration.options && groups.length === 2) {
          newChartConfiguration.options.isStacked = true;
          if (groups.some((data) => data !== newChartConfiguration.yAxis)) {
            newChartConfiguration.yAxis = groups.find(
              (data) => data !== newChartConfiguration.stackData
            );
          }
          newChartConfiguration.stackData = groups.find(
            (data) => data !== newChartConfiguration.yAxis
          );
        } else if (
          newChartConfiguration.stackData &&
          groups.length === 1 &&
          newChartConfiguration.yAxis === groups[0]
        ) {
          delete newChartConfiguration.stackData;
          newChartConfiguration.options.isStacked = false;
        } else if (
          newChartConfiguration.stackData &&
          groups.length === 1 &&
          newChartConfiguration.stackData === groups[0]
        ) {
          newChartConfiguration.yAxis = newChartConfiguration.stackData;
          delete newChartConfiguration.stackData;
          newChartConfiguration.options.isStacked = false;
        }

        GetUniqueValuesCount({
          table: table,
          fields: !newChartConfiguration.stackData
            ? fields
            : groups.length === 1 || groups.length === 2
            ? groups
            : fields,
          secondary_filter: secondaryFilters.length
            ? secondaryFilters
            : undefined,
          primary_filter: is.existy(currentUser) ? [...primaryFilters, currentUser] : primaryFilters,
          bucket_fields: bucket_fields,
          summary_type: matrixEditorOptions.option ? matrixEditorOptions.option : undefined,
          summary_field: matrixEditorOptions.field ? matrixEditorOptions.field : undefined,
          groups: !newChartConfiguration.stackData
            ? fields
            : groups.length === 1 || groups.length === 2
            ? groups
            : fields,
          grouped_dates: is.existy(grouped_date)
            ? Object.keys(grouped_date).length > 0 &&
              fields.some((item) => item === Object.keys(grouped_date)[0])
              ? grouped_date
              : []
            : [],
        }).then((result) => {
          const uniqueData = _.cloneDeep(result);

          if (is.empty(uniqueData)){
            setWidgetState(emptyRecords);
          }

          const chartConfig = formatUniqueCount(
              uniqueData,
              newChartConfiguration,
              matrixEditorOptions.option,
              true
          );

          //Show values to charts
          if (newChartConfiguration.showValues){
            chartConfig.data = chartConfig.data.map((item, index) => {
              if ((item.length >= 2) && (item.length % 2 === 0)) {
                if (index === 0) {
                  item.push({ role: 'annotation' })
                  return item
                } else {
                  item.push(item[item.length - 1])
                  return item
                }
              }
              return item
            });
          }

          if (is.existy(legendLabels)){
            chartConfig.options.hAxis.title = legendLabels[0];
            chartConfig.options.vAxis.title = legendLabels[1];
            if (chartConfig.data){
              chartConfig.data[0] = legendLabels;
            }
          }

          setChartConfiguration(chartConfig);
        }).catch((err) => {
          setWidgetState(widgetErrMessage);
          notification.error({
            message: errorMessage,
          });
        });
      }
    }
  }

  function getChartConfiguration(){
    const chartConfig = _.cloneDeep(chartConfiguration);

    if (chartConfig.type !== "PieChart") {
      chartConfig.options.chartArea = {
        ...chartConfig.options.chartArea,
        left:
            gridWidth <= 8
                ? defaultChartLeft
                : gridWidth <= 8
                ? defaultChartLeft + 40
                : defaultChartLeft + 80,
        bottom:
            gridWidth <= 8
                ? defaultChartBottom
                : gridWidth <= 40
                ? defaultChartBottom + 10
                : defaultChartBottom + 15,
      };
    }

    return {
      ...chartConfig,
      width: "100%",
      height:
          gridHeight <= 4
              ? "93%"
              : gridHeight <= 6
              ? "95%"
              : gridHeight <= 8
                  ? "97%"
                  : "98%",
    };
  }

  function initDashboardWidgetData(){
    setDataFields([]);
    setDataSource([]);
    setUniqueValues([]);
    setMatrixDataSource({});
    setWidgetState(loadingMessage);
  }

  const isChart = is.existy(chartConfiguration) && is.not.array(chartConfiguration);

  const hasChartData =
    (isChart && is.existy(chartConfiguration.data) && chartConfiguration.data.length > 1) ||
    ((!isChart) && (visualization === "tabular" || visualization === "total") &&
      is.not.empty(dataSource)) ||
    ((!isChart) && visualization === "summary" && is.not.empty(uniqueValues)) ||
    ((!isChart) && visualization === "matrix" && is.not.empty(matrixDataSource) &&
        (is.not.empty(matrixDataSource.x_fields) || is.not.empty(matrixDataSource.y_fields)));

  return hasChartData ? (
    <Widget
      title={name}
      fixedheader
      isDashboard
      tight_table
      active={sidebar}
      sidebar={sidebar}
      report={
        is.not.empty(chartConfiguration)
          ? {
              visualization: visualization,
              groups: [...groups],
              chart_configuration: getChartConfiguration(),
            }
          : report
      }
      dataSource={dataSource}
      uniqueValues={uniqueValues}
      matrixDataSource={matrixDataSource}
      dataFields={dataFields}
      parentSize={
        is.existy(currentRef)
          ? {
              width: currentRef.offsetWidth,
              height: currentRef.offsetHeight,
            }
          : null
      }
      parentRef={parentRef}
      matrixRef={matrixRef}
      refIndex={refIndex}
      updateMatrixFont={updateMatrixFont}
      matrixOptions={matrixOptions}
      reportURL={reportURL}
      pushURL={pushURL}
      homePage={homePage}
    />
  ) : (
    <StyledDiv active={sidebar} widgetWidth={is.existy(currentRef) && currentRef.offsetWidth} width="100%" height="100%">
      {widgetState !== loadingMessage && <div className='widget_title'>
        {name && <p className='bold_title'><a href={reportURL} onClick={e => pushURL(e, reportURL)}>{name}</a></p>}
      </div>}
      <div className='state_title'>{widgetState}</div>
    </StyledDiv>
  );
}

export default DashboardItem;
