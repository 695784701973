import {PROCESS} from 'types'
import {color} from 'components/zensmart-design-system/shared/styles'

function getColorFromType(type) {
  switch (type) {
    case PROCESS.TYPE.EDGE:
    case PROCESS.TYPE.PROVIDER:
      return color.red.normal
    case PROCESS.TYPE.AUTOMATED:
      return color.purple.normal
    case PROCESS.TYPE.MATCH_OUT:  
    case PROCESS.TYPE.BARCODE:
      return color.blue.dark
    case PROCESS.TYPE.IMPOSITION:
      return color.mountain_meadow.dark
    case PROCESS.TYPE.QUEUED:
        return color.mountain_meadow.dark
    case PROCESS.TYPE.READY:
        return color.purple.dark
    case PROCESS.TYPE.DISPATCH:
    case PROCESS.TYPE.PRE_DISPATCH:
    case PROCESS.TYPE.PACK_TYPE:
      return color.pacific_blue.dark
    case PROCESS.TYPE.BLOCK:
    case PROCESS.TYPE.FIELD_SWITCH:
      return color.buttercup.normal
    case PROCESS.TYPE.BUCKET:
      return color.gray
    case PROCESS.TYPE.PICKING:
      return 'black'
    default:
      return null
  }
}

export default getColorFromType
