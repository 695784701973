import React, { useState, useEffect } from 'react';
import {useHistory, useParams} from 'react-router-dom'
import { Widget } from 'pages/Performance/Widget';
import { Header, Title } from 'components/primaries';
import { GetReport, LoadData, GetUniqueValues } from 'pages/Performance/api';

const SavedReportsViewer = () => {
  const { reportID } = useParams()

  const [dataSource, setDataSource] = useState([])
  const [searchedDataSource, setSearchedDataSource] = useState([])
  const [report, setReport] = useState({})
  const [reportName, setReportName] = useState('')
  const [uniqueValues, setUniqueValues] = useState([])

  const filterColumn = 'name';
  const history = useHistory()


  const loadResource = (id) => {
    report.table && (
      history.push({
        pathname: `/app/data-view/${report.table}/${id}`
      })
    )
  }

  const PageTitle = (
    <Header>
      <Title>Performance &gt; Reports {reportName && `> ${reportName}`}</Title>
    </Header>
  )

  const searchForValue = (value = '') => {
    if (value === '') {
      setSearchedDataSource(dataSource)
    } else {
      setSearchedDataSource(dataSource.filter(item => (
        item[filterColumn] && item[filterColumn].toLowerCase().includes(value.toLowerCase())
      ) ))
    }
  }

  useEffect(() => {
    reportID && GetReport(reportID).then(result => {
      if (result.data) {
        const {fields, table, name, groups, secondary_filter, primary_filter, bucket_fields } = result.data

        if (groups && table && groups.length > 0) {
          GetUniqueValues({
            table: table,
            groups: groups
          }).then(uniqueItems => setUniqueValues((uniqueItems)))
        }

        setReport(result.data)
        setReportName(name)

        LoadData({
          table: table,
          fields: fields,
          groups: groups,
          secondary_filter: secondary_filter,
          primary_filter: primary_filter,
          // order,
          bucket_fields: bucket_fields,
          mode: 'live'
        }).then(loadedData => {
          setDataSource(loadedData)
          setSearchedDataSource(loadedData)
        })
      }
    })
  }, [reportID]);
  
  return (
    <>
      {PageTitle}
  
      <Widget
        title='Saved Reports'
        filter_column={filterColumn}
        fixedheader
        height='650px'
        dataSource={searchedDataSource}
        report={report}
        searchFieldChanged={searchForValue}
        uniqueValues={uniqueValues}
        iconRender={
          (item, column) => {
            if (column && column.toLowerCase() === 'id') {
              return (
                <a
                  onClick={() => loadResource(item)}
                >
                  {`${item}`}
                </a> 
              )
            }
            return `${item}`
          }
        }
      />
    </>
  )
}

export default SavedReportsViewer