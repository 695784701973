import React, { useState, useEffect } from "react";
import {
    Drawer,
    Row,
    Col,
    notification,
    Select,
    Button,
    Modal,
    Table,
    Form
} from "antd";
import { ZenSmartAPI } from 'utils'
import { singleEventsInSingleTrigger, modelDefinitionRoute, addSingleEventsInSingleTrigger } from "utils/apiRoutes"
import debounce from 'lodash/debounce';
import { TriggerEmail, TriggerCallout, FieldUpdate, FORM_TYPES } from './TriggerEventsHelperForms'
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import _ from 'lodash';


const { Option } = Select

function CreateTriggerEventForm(props) {
    const {
        form,
        editSingleTriggerEventDetails,
        fieldsData,
        onSearchField,
        workflowInitialDatas,
        closeEditModal,
        manageTriggers,
        parentTrigger,
        ruleData,
        loading,
        setLoading
    } = props

    const {
        getFieldDecorator,
        getFieldsValue,
        getFieldValue,
        validateFields,
        setFieldsValue,

    } = form;
    const [selectTriggerType, setSelectTriggerType] = useState(null)
    const [headersData, setHeadersData] = useState([])
    const [errors, setErrors] = useState({})
    const handleSubmit = e => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                setLoading(true)
                const payload = getFieldsValue()
                const api = editSingleTriggerEventDetails ? ZenSmartAPI.put(singleEventsInSingleTrigger(ruleData.id, editSingleTriggerEventDetails.id), payload) :
                    ZenSmartAPI.post(addSingleEventsInSingleTrigger(ruleData.id), payload)

                api.then(result => {
                    notification.success({ message: "Edit successful!" })
                    manageTriggers({ id: ruleData.id }, false, false)
                    closeEditModal()
                    setLoading(false)

                }).catch(error => {
                    setLoading(false)

                    if (error.response && error.response.data.message) {
                        notification.error({ message: error.response.data.message })
                        if (error.response.status === 422) {
                            setErrors(error.response.data.errors)
                        }
                    }

                })
            }
        });
    };

    // if add new trigger the select type is not disabled, if edit it should be disabled
    const addTypeInput = (details) => {
        return <Col span="24">
            <Form.Item label="Select Type"
            >
                {getFieldDecorator(`type`, {
                    initialValue: details ? details.taggable_type : undefined,
                    rules: [{ required: true, message: `Please input this field!` }]
                }
                )(
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Please select trigger type"
                        optionFilterProp="children"
                        onChange={(e) => {
                            setSelectTriggerType(e)
                        }}
                        disabled={details}
                    >
                        {workflowInitialDatas.trigger_library.actions.map(data =>
                            <Option value={data.key}>{data.value}</Option>
                        )}
                    </Select>
                )}
            </Form.Item >
        </Col>
    }

    // dynamic generating of form depends on the trigger type
    const editgenerateForm = () => {

        if (editSingleTriggerEventDetails && editSingleTriggerEventDetails.taggable_type === "trigger_email") {
            return (
                <div>
                    <Row type="flex" align="middle" justify="center">
                        {addTypeInput(editSingleTriggerEventDetails, true)}
                        <Col span="24">
                            {TriggerEmail(getFieldDecorator, editSingleTriggerEventDetails, fieldsData, onSearchField, workflowInitialDatas, parentTrigger, errors)}
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (editSingleTriggerEventDetails && editSingleTriggerEventDetails.taggable_type === "trigger_field_update") {
            return (
                <div>
                    <Row type="flex" align="middle" justify="center">
                        {addTypeInput(editSingleTriggerEventDetails, true)}
                        <Col span="24">
                            {FieldUpdate(getFieldDecorator, editSingleTriggerEventDetails, fieldsData, onSearchField, workflowInitialDatas, parentTrigger, errors)}
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (editSingleTriggerEventDetails && editSingleTriggerEventDetails.taggable_type === "trigger_callout") {
            return (
                <div>
                    <Row type="flex" align="middle" justify="center">
                        {addTypeInput(editSingleTriggerEventDetails, true)}
                        <Col span="24">
                            {TriggerCallout(getFieldDecorator, editSingleTriggerEventDetails, getFieldValue, setFieldsValue, headersData, setHeadersData, errors)}
                        </Col>
                    </Row>
                </div>
            )
        }
    }
    const addTriggerGenerateForm = () => {
        return (
            <div>
                <Row type="flex" align="middle" justify="center">
                    {addTypeInput()}
                    <Col span="24">
                        {selectTriggerType === "trigger_email" && TriggerEmail(getFieldDecorator, editSingleTriggerEventDetails, fieldsData, onSearchField, workflowInitialDatas, parentTrigger, errors)}
                        {selectTriggerType === "trigger_field_update" && FieldUpdate(getFieldDecorator, editSingleTriggerEventDetails, fieldsData, onSearchField, workflowInitialDatas, parentTrigger, errors)}
                        {selectTriggerType === "trigger_callout" && TriggerCallout(getFieldDecorator, editSingleTriggerEventDetails, getFieldValue, setFieldsValue, headersData, setHeadersData, errors)}

                    </Col>
                </Row>
            </div>
        )
    }

    useEffect(() => {
        if (editSingleTriggerEventDetails && editSingleTriggerEventDetails.taggable_type === "trigger_callout") {
            setHeadersData(editSingleTriggerEventDetails.taggable_details.headers);
        }
    }, [editSingleTriggerEventDetails]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (selectTriggerType) {
            if (selectTriggerType === "trigger_email") {
                onSearchField("", false, parentTrigger.prefix, "email")
            }
            else {
                onSearchField("", false, parentTrigger.prefix)
            }
        }
    }, [selectTriggerType]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form onSubmit={handleSubmit} autoComplete={'off'}>
            {
                editSingleTriggerEventDetails ? editgenerateForm() : addTriggerGenerateForm()
            }
            <div style={{ position: "absolute", bottom: 0, left: 0, width: "100%", padding: 10, background: "white" }}>
                <hr />
                <Row type="flex" align="middle" justify="end" style={{ marginTop: 5 }}>
                    <Col span={4} style={{ padding: 5 }}>
                        <Button type="primary" style={{ width: "100%" }} ghost onClick={closeEditModal} >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={6} style={{ padding: 5 }}>
                        <Button type="primary" htmlType="submit"
                            style={{ width: "100%" }} loading={loading}>
                            Save Changes
                        </Button>
                    </Col>
                </Row>
            </div>
        </Form>
    );
}

const TriggerEventForm = Form.create({
})(CreateTriggerEventForm)

function ManageTriggersModal(props) {
    const { data, showModal, setShowModal, modalTitle, workflowInitialDatas, manageTriggers, parentTrigger, loading, setLoading } = props
    const [updateSingleTriggerModal, setUpdateSingleTriggerModal] = useState(false)
    const [editSingleTriggerEventDetails, setEditSingleTriggerEventDetails] = useState(null)
    const [fieldsData, setFieldsData] = useState([])
    const [modalTriggerEventTitle, setModalTriggerEventTitle] = useState("")
    const columns = [
        {
            title: <p style={{ textAlign: "center" }}>Type</p>,
            dataIndex: 'taggable_type',
            key: "taggable_type",
            align: "center",
            render: (record, row, index) => {
                return <p style={{ textTransform: "capitalize" }}>{row.taggable_type.replace(/_/g, ' ')}</p>
            }
        },
        {
            title: <p style={{ textAlign: "center" }}>Description</p>,
            dataIndex: 'desciprtion',
            key: "desciption",
            align: "center",
            render: (record, row, index) => {
                return <p style={{ textTransform: "capitalize" }}>{row.description}</p>
            }
        },
        {
            title: <p style={{ textAlign: "center" }}>Actions</p>,
            dataIndex: 'action',
            key: 'action',
            align: "center",
            render: (record, row, index) => {
                return (
                    <Row type="flex" align="middle" justify="center" >
                        <Col style={{ padding: 5 }}>
                            <Button type="primary" onClick={() => editSingleTrigger(row)}><EditOutlined /></Button>
                        </Col>
                        <Col style={{ padding: 5 }}>
                            <Button type="danger" onClick={() => deleteTriggerEvent(row)}><DeleteOutlined /></Button>
                        </Col>
                    </Row>
                )
            }
        },
    ]

    const closeModal = () => {
        setShowModal(false)
    }

    const closeEditModal = () => {
        setUpdateSingleTriggerModal(false)
        setEditSingleTriggerEventDetails(null)
    }

    const editSingleTrigger = (row) => {
        ZenSmartAPI.get(singleEventsInSingleTrigger(row.taggable_id, row.id))
            .then(result => {
                setUpdateSingleTriggerModal(true)
                setEditSingleTriggerEventDetails(result.data.data)
                if (result.data.data.taggable_type === "trigger_email") {
                    searchFieldsData("", true, result.data.data.prefix, "email")
                }
                else {
                    searchFieldsData("", true, result.data.data.prefix)
                }
                setModalTriggerEventTitle(`Edit ${_.startCase(result.data.data.taggable_type)} (${result.data.data.description})`)

            }).catch(error => {
                notification.error({ message: "Fetch trigger events failed!" })
            })
    }

    const addNewTrigger = () => {

        setUpdateSingleTriggerModal(true)
        setModalTriggerEventTitle(`Add New Trigger Event`)

    }

    const deleteTriggerEvent = (row) => {
        Modal.confirm({
            'content': "Are you sure you want to delete this trigger event?",
            onOk: () => {
                setLoading(true)
                ZenSmartAPI.delete(singleEventsInSingleTrigger(row.taggable_id, row.id))
                    .then(result => {
                        manageTriggers({ id: row.trigger_id }, false, false)
                        notification.success({ message: "Delete trigger event successful!" })
                        setLoading(false)

                    }).catch(error => {
                        setLoading(false)
                        notification.error({ message: "Delete trigger event not successful!" })
                    })
            },
        });

    }

    const searchFieldsData = (value, ifSetLoading, prefix, fieldType = null) => {
        if (ifSetLoading) {
            setLoading(true)
        }
        const queryParam = !fieldType ? `${prefix}.${value}` : `${prefix}.${value}&field_type=${fieldType}`
        ZenSmartAPI.get(modelDefinitionRoute(queryParam), {})
            .then((res) => {
                setFieldsData(res.data.data)
                if (ifSetLoading) {
                    setLoading(false)
                }
            })
    }
    const onSearchField = debounce((value, ifSetLoading, prefix, fieldType = null) => {
        searchFieldsData(value, ifSetLoading, prefix, fieldType)
    }, 600);
    return (
        <Drawer visible={showModal} title={modalTitle} width={700} onClose={closeModal}
        >
            <Row type="flex" justify="end" style={{ paddingBottom: 10 }}>
                <Col>
                    <Button type="primary" onClick={addNewTrigger}>Add New Trigger Event</Button>
                </Col>
            </Row>
            <Table columns={columns} dataSource={data.data} loading={loading} pagination={false}
            />
            <Drawer
                title={modalTriggerEventTitle}
                width={600}
                onClose={closeEditModal}
                visible={updateSingleTriggerModal}
            >

                {updateSingleTriggerModal &&
                    (editSingleTriggerEventDetails || modalTriggerEventTitle === "Add New Trigger Event") &&
                    <TriggerEventForm
                        editSingleTriggerEventDetails={editSingleTriggerEventDetails}
                        fieldsData={fieldsData}
                        onSearchField={onSearchField}
                        workflowInitialDatas={workflowInitialDatas}
                        closeEditModal={closeEditModal}
                        manageTriggers={manageTriggers}
                        ruleData={data.rule_data}
                        loading={loading}
                        setLoading={setLoading}
                        parentTrigger={parentTrigger}

                    />}
            </Drawer>
        </Drawer >
    );
}

export default ManageTriggersModal;
