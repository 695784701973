export const dueDateColor = {
    later: "purple",
    today: "blue",
    tomorrow: "green"
}

export const noImage = "https://www.phlpost.gov.ph/assets/img/stamps/no_image.jpg"
export const invalidScanImage = "https://images.vexels.com/media/users/3/143473/isolated/preview/6a4a5a7dd733d452adfd328c32f50d3e-stop-sign-icon-hand-by-vexels.png"

export const formatPickupData = (body) => {
    const { page_definitions, message, manual_pickup_id } = body;
    return ({
        data: {
            data: {
                dispatch_confirmation_required: false,
                override_shipping_verification: false,
                post_dispatch_prompts: [],
                pre_dispatch_prompts: [],
                message,
                manual_pickup_id,
                page_definitions,
            }
        }
    })
}