import React, { useEffect, useState } from 'react';
import { Header, Title } from 'components/primaries';
import { ZenSmartAPI } from 'utils'
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'
import MultiCutRuleUpdateModal from './MultiCutRuleUpdateModal';
import { fetchMultiCutRuleInitDataRoute, modelDefinitionRoute, multicutRuleRoute } from "utils/apiRoutes"
import { notification, Modal } from 'antd';
const MultiCutRulePage = () => {
    const [fetchParams, setFetchParams] = useState({})
    const [fetchData, setFetchData] = useState({ refresh_data: null })
    const [updateMultiCutRuleModal, setUpdateMultiCutRuleModal] = useState(false)
    const [formInitialValue, setFormInitialValue] = useState({})
    const [multicutModalName, setRuleModalName] = useState("")
    const [multicutInitData, setMultiCutInitData] = useState({})
    const [fieldsValue, setFieldsValue] = useState([])
    const [fieldType, setFieldType] = useState("string")
    const addMultiCutRule = (row, refreshData) => {
        setUpdateMultiCutRuleModal(true)
        setRuleModalName("Add New MultiCut Rule")
    }
    const editMultiCut = (row, refreshData) => {
        ZenSmartAPI.get(`${multicutRuleRoute}/${row.id}`)
            .then((res) => {
                setUpdateMultiCutRuleModal(true)
                setFormInitialValue(res.data)
                setRuleModalName(`Edit MultiCut Rule ${res.data.model_definition_name}`)
            })
            .catch((res) => {
                console.log('res', res)
                if (res.response.status === 422) {
                    notification.error({ message: "Accessory code already exist!" })
                }
                else {
                    notification.error({ message: "Error saving accessory!" })
                }
            })
    }
    const deleteMultiCut = (row, refreshData) => {
        Modal.confirm({
            'content': "Are you sure you want to delete this multicut rule?",
            onOk: () => {
                ZenSmartAPI.delete(`${multicutRuleRoute}/${row.id}`)
                    .then((res) => {
                        fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                        notification.success({ message: "Delete multicut successful!" })
                    })
                    .catch((res) => {
                        if (res.response.data) {
                            notification.error({ message: res.response.data.message })
                        }
                    })
            },
        });
    }
    useEffect(() => {
        ZenSmartAPI.get(fetchMultiCutRuleInitDataRoute)
            .then((res) => {
                setMultiCutInitData(res.data.data)
                ZenSmartAPI.get(modelDefinitionRoute(`lines.`), {})
                    .then((res) => {
                        setFieldsValue(res.data.data)
                    })
            })
            .catch((res) => {
                if (res.response.data) {
                    notification.error({ message: res.response.data.message })
                }
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <Header>
                <Title>App Setup {">"} MultiCut Rules</Title>
            </Header>
            <MultiCutRuleUpdateModal
                updateMultiCutRuleModal={updateMultiCutRuleModal}
                setUpdateMultiCutRuleModal={setUpdateMultiCutRuleModal}
                fetchData={fetchData}
                fetchParams={fetchParams}
                formInitialValue={formInitialValue}
                setFormInitialValue={setFormInitialValue}
                multicutModalName={multicutModalName}
                setRuleModalName={setRuleModalName}
                fieldsValue={fieldsValue}
                setValueField={setFieldsValue}
                fieldType={fieldType}
                setFieldType={setFieldType}
                multicutInitData={multicutInitData}
            />
            <Panel title="MULTICUT RULES">
                <DataListPage model="multicut_rules" select={[]} hidden={["id", "model_definition_id"]} link={""} pageSize={50}
                    customizedButton={
                        [{
                            buttonLabel: "Add New MultiCut Rule",
                            buttonType: "primary",
                            buttonAction: "normal",
                            actionFunction: addMultiCutRule,
                            offset: 14,
                            span: 4
                        }
                        ]
                    }
                    setOutSourceFetchData={setFetchData}
                    setOutSourceParameters={setFetchParams}
                    actionButtons={
                        [
                            {
                                buttonLabel: "Edit",
                                buttonType: "primary",
                                buttonAction: "normal",
                                actionFunction: editMultiCut,
                                reloadData: true
                            },
                            {
                                buttonLabel: "Delete",
                                buttonType: "danger",
                                buttonAction: "normal",
                                actionFunction: deleteMultiCut,
                                reloadData: true
                            },
                        ]
                    }
                />
            </Panel>
        </>
    );
};
export default MultiCutRulePage;