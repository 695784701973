import React from "react";
import {
    Drawer,
    Row,
    Col,
    Checkbox,
    Button,
    Menu,
    Dropdown
} from "antd";

import styled from 'styled-components'

const Title = styled.p`
  font-size: 14px;
  color: black;
  font-weight: bold;
`

const WorkFlowDetailsModal = (props) => {

    const { showModal, setShowModal, workFlowDetailInitialData = null, setWorkFlowDetailInitialData, modalTitle = null, editSingleWorkFlowRule, deleteWorkFlowRule, activateRules, objectsData } = props

    const cancelWorkFlowDetail = () => {
        setShowModal(false)
        setWorkFlowDetailInitialData(null)
    }

    const constructDetails = (title, value) => {

        return <Col span={24}>
            <Row type="flex" >
                <Col span={4} style={{ padding: 10 }}>
                    <Title>{title}</Title>
                </Col>
                <Col style={{ padding: 10 }}>
                    {value}
                </Col>
            </Row>
            <hr />
        </Col>
    }

    const actionMenu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={() => editSingleWorkFlowRule(workFlowDetailInitialData)}>
                    Edit
          </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={() => deleteWorkFlowRule(workFlowDetailInitialData)}>
                    Delete
          </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={() => activateRules(workFlowDetailInitialData, undefined, true)}>
                    {workFlowDetailInitialData && workFlowDetailInitialData.active ? "Deactivate" : "Activate"}
                </a>
            </Menu.Item>
        </Menu>
    )

    return (
        <Drawer visible={showModal} title={modalTitle} width={700} onClose={cancelWorkFlowDetail}
        >
            {workFlowDetailInitialData &&
                <div>
                    <Row type="flex" justify="end">
                        <Col>
                            <Dropdown
                                overlay={actionMenu}
                                trigger={['click']}
                                style={{ width: "100%" }}
                            >
                                <Button>
                                    Select Actions
          </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        {constructDetails("Name:", workFlowDetailInitialData.name)}
                        {constructDetails("Object:", objectsData.find(data => data.value === workFlowDetailInitialData.taggable_type).name)}
                        {constructDetails("Active:", <Checkbox checked={workFlowDetailInitialData.active} />)}
                        {constructDetails("Description:", workFlowDetailInitialData.description)}
                        {constructDetails("Events:", workFlowDetailInitialData.events.map(data => {
                            return <p style={{ textTransform: "capitalize" }}>{data} </p>
                        }))}
                        {constructDetails("Rules:", workFlowDetailInitialData.rules && workFlowDetailInitialData.rules.length === 0 ? "No Rules Available" : workFlowDetailInitialData.rules.map(data => {
                            return <div>
                                <p style={{ textTransform: "capitalize" }}>
                                    {`(${data.condition.replace(/\./g, ' ')} : ${data.qualifier.replace(/\_/g, ' ')})`}
                                </p>
                            </div>
                        }))}
                        {constructDetails("Created By:", `${workFlowDetailInitialData.created_by} ${workFlowDetailInitialData.created_at} `)}
                        {constructDetails("Modified By:", `${workFlowDetailInitialData.updated_by} ${workFlowDetailInitialData.updated_at} `)}

                    </Row>
                </div >
            }
        </Drawer >
    );
}

export default WorkFlowDetailsModal;
