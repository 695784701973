import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Spin, Modal } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { ZenSmartAPI } from 'utils'
import PrinterPanels from './PrinterPanels'
import { fetchJitInitDataRoute } from "utils/apiRoutes"
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import { useSelector } from 'react-redux'
import _ from 'lodash';
import { useParams } from 'react-router-dom'
import { alphabeticalData } from "utils/sortData";

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const DivStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
  h2 {
    font-weight: 700;
    font-family: 'Rubik', sans-serif;
  }
  h1 {
    font-weight: 450;
    font-family: 'Rubik', sans-serif;
  }
  .ant-select-selection--single{
    border-color : blue;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
  color : blue;
}
.ant-select-lg .ant-select-selection__rendered {
  color : blue;
}
  `
const JITBatchingPage = (props) => {
  const userData = useSelector(state => state.user)

  const { slug } = useParams()
  const [printerPanels, setPrinterPanels] = useState([])
  const [isAllowedComboBatching, setIsAllowedComboBatching] = useState(false)
  const [finishingTypes, setFinishingTypes] = useState([])
  const [productProfiles, setProductProfiles] = useState([])
  const [laminationCodeTypes, setLaminationCodeTypes] = useState([])
  const [printerLocationValue, setPrinterLocationValue] = useState({})
  const [isBasicVersion, setIsBasicVersion] = useState(null)
  const [loading, setLoading] = useState(false)
  const [qaFailAndLaminationCode, setQaFailAndLaminationCode] = useState({})
  const [allProducts, setAllProducts] = useState({})
  const [isPrintersInitiallySet, setIsPrintersInitiallySet] = useState(false)



  const fetchPrinterPanels = (printerLocationId, setIfLoading = true) => {
    if (setIfLoading)
      setLoading(true)
    ZenSmartAPI.get(fetchJitInitDataRoute(printerLocationId))
      .then((res) => {
        if (setIfLoading)
          setLoading(false)
        setPrinterPanels(alphabeticalData(res.data.data, "id"))
        setFinishingTypes(res.data.finishing_types)
        setProductProfiles(res.data.product_profiles)
        setLaminationCodeTypes(res.data.lamination_code_types)
        setIsAllowedComboBatching(res.data.batch_combo_type)
        setQaFailAndLaminationCode({ qaFailId: res.data.qa_fail_id, laminationCodeId: res.data.lamination_code_id })
        setAllProducts(res.data.all_products)
        setIsAllowedComboBatching(res.data.batch_combo_type)
        setIsBasicVersion(_.get(res, 'data.basic_version', false))
        if (!isPrintersInitiallySet) {
          setIsPrintersInitiallySet(true)
        }
      })
      .catch((err) => {
        if (setIfLoading)
          setLoading(false)
        errorNoticationHandler(err, "Cant fetch initial data!")
      })
  }

  const presetChangedNotification = () => {

    Modal.confirm({
      'content': 'The current preset shown on this page is outdated, please refresh to get updated',
      onOk: () => {
        window.location.reload(false)
        return
      },
    });
  }
  useEffect(() => {
    if (slug && userData.details) {
      const value = _.get(userData, 'details.page_settings.printer_location_settings', []).find(data => data.name.replace(/\W+(?!$)/g, '-').toLowerCase() === slug.toLowerCase())

      if (value) {
        fetchPrinterPanels(value.id)
        setPrinterLocationValue(value)
      }
    }
  }, [userData, slug]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DivStyle />
      <Header>
        <Title>Print {">"} Batch Release {">"} {printerLocationValue.name}</Title>
      </Header>
      <Spin tip="Loading..." spinning={loading}>
        <Panel title={printerLocationValue.name}>
          <div style={{ minWidth: 500, display: "flex" }}>
            {printerPanels.map(data => {
              return <PrinterPanels data={data} loading={loading} setLoading={setLoading} finishingTypes={finishingTypes}
               presetChangedNotification={presetChangedNotification} fetchPrinterPanels={fetchPrinterPanels} printerLocationValue={printerLocationValue}
                isAllowedComboBatching={isAllowedComboBatching} productProfiles={productProfiles} laminationCodeTypes={laminationCodeTypes}
                qaFailAndLaminationCode={qaFailAndLaminationCode} isBasicVersion={isBasicVersion} allProducts={allProducts} />
            })}
          </div>
        </Panel>
      </Spin>
    </>
  )
}

export default JITBatchingPage