import React from 'react'
import styled from 'styled-components'
import { Modal, Form, Input, Button, notification } from 'antd'

import { PROCESS } from 'types'
import * as _ from "lodash";

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`

const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function EditForm(props) {
  const {
    form,
    addCost,
    onSave,
    processID,
    removeTrigger,
    hideModal
  } = props

  const {
    getFieldDecorator,
    getFieldsError,
    getFieldsValue
  } = form


  const handleSubmit = e => {
    e.preventDefault()
     const payload = getFieldsValue()
    payload.trigger_id = _.get(addCost, 'trigger_id', null);
    onSave(payload, PROCESS.TRIGGER.ADD_COST, processID)
    hideModal()
    notification.success({
      message: 'Saved add cost trigger details.'
    })
  }

  const onRemoveTrigger = () => {
    removeTrigger(PROCESS.TRIGGER.ADD_COST, processID)
  }

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <StyledFormItem label="Fixed Cost">
        {getFieldDecorator('fixed', {
          initialValue: addCost && addCost.fixed
        })(<Input type="number" />)}
      </StyledFormItem>

      <StyledFormItem label="Additional Cost (Per Page)">
        {getFieldDecorator('additional', {
          initialValue: addCost && addCost.additional
        })(<Input type="number" />)}
      </StyledFormItem>

      <ButtonContainer>
        <Button onClick={hideModal}>Cancel</Button>
        {processID && <Button type="danger" onClick={onRemoveTrigger}>Delete</Button>}
        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
        >
          Save Changes
        </Button>
      </ButtonContainer>
    </Form>
  )
}

const EditAddCostTriggerForm = Form.create({
  name: 'Edit AddCost Trigger Form'
})(EditForm)

function AddCostTriggerModal(props) {
  const { addCost, onSave, processID, removeTrigger, ...modal } = props
  return (
    <Modal {...modal}>
      <EditAddCostTriggerForm
        addCost={addCost}
        onSave={onSave}
        processID={processID}
        hideModal={modal.onCancel}
        removeTrigger={removeTrigger}
      />
    </Modal>
  )
}

export default AddCostTriggerModal