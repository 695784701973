import {PROCESS} from 'types'
import {createContainer, createItem} from './utils'

export default [
  createItem({
    number: 1,
    sibling: [],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.EDGE,
    editable: false,
    text: 'Order Created',
    is_print_job_ticket: false
  }),

  createItem({
    number: 2,
    sibling: [],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.QUEUED,
    editable: true,
    text: 'Queued for Batching',
    is_print_job_ticket: true
  }),

  createContainer({
    sibling: [
      PROCESS.TYPE.BARCODE,
      PROCESS.TYPE.AUTOMATED,
      PROCESS.TYPE.BLOCK,
      PROCESS.TYPE.DISPATCH,
      PROCESS.TYPE.PRE_DISPATCH
    ],
    variant: PROCESS.VARIANT.CONTAINER,
    type: PROCESS.TYPE.TRANSPARENT,
    is_print_job_ticket: false,
    accepts: [],
    children: [
      createItem({
        number: 3,
        sibling: [null],
        variant: PROCESS.VARIANT.ITEM,
        type: PROCESS.TYPE.READY,
        is_print_job_ticket: false,
        text: 'Ready for Release',
        editable: true,
      }),

      createItem({
        number: 99,
        sibling: [],
        variant: PROCESS.VARIANT.ITEM,
        type: PROCESS.TYPE.BARCODE,
        is_print_job_ticket: false,
        text: 'QA Fail',
      })
    ],
  }),

  createItem({
    number: 4,
    sibling: [],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.PRE_DISPATCH,
    editable: false,
    is_print_job_ticket: false,
    text: 'Pre Dispatch',
  }),

  createItem({
    number: 5,
    sibling: [],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.DISPATCH,
    editable: true,
    is_print_job_ticket: false,
    text: 'Dispatch',
  }),

  createItem({
    number: 6,
    sibling: null,
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.EDGE,
    editable: false,
    is_print_job_ticket: false,
    text: 'Complete',
  })
]
