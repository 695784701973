import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Select, Button ,Modal , notification  } from 'antd'
import { alphabeticalData } from 'utils/sortData';
import { PROCESS } from 'types'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import EditProductModal from './EditProductModal.js'
import { ZenSmartAPI } from 'utils'
import initialData from '../../initialData.js'
import { productIdRoute, productSearchRoute,addProductTemplateRoute , templateIdRoute } from "utils/apiRoutes"
import { useHistory, Prompt } from "react-router-dom";
import { debounce } from "utils/debounce"
import ProductDropdown from "components/composites/ProductDropdown";

const MODAL = {
  EDIT: 'EDIT_PRODUCT',
  CREATE: 'CREATE_PRODUCT'
}

const dropdownStyle = {
  maxHeight: 256,
  overflow: 'auto'
}

const Container = styled.div`
  margin-bottom: 32px;
`
const Label = styled.label`
  display: block;
  color: ${color.heading};
  margin-bottom: 8px;

  font-size: 15px;
  font-weight: bold;
`

const Search = styled(Select)`
  margin-right: 8px;
  width: 312px;
`

function SearchArea(props) {
  const {
    modal,
    currentProduct,
    setCurrentProduct,
    allProductsQuery,
    setProduct,
    hasUnsavedChanges,
    onSave,
    setProductTemplates,
    productTemplates,
    enableClone,
  } = props;

  const [currentModal, setModal] = modal
  const history = useHistory();
  const location = useLocation()
  const [productsDropDownValues, setProductsDropdownValues] = useState([])
  const [productToDropDownValues, setProductToDropDownValues] = useState(true)
  const [productFlowChanged, setProductFlowChanged] = useState(false)

  useEffect(() => {
    if (location.state) {
      allProductsQuery.refetch()
      setProduct({ variables: { productID: location.state.id } })
    }
  }, [location.state, allProductsQuery, setProduct])

  useEffect(() => {
    onSearchProduct("", 20)
  }, [])

  useEffect(() => {
    addCurrentProductToDropDown(productId, productToDropDownValues,productsDropDownValues)
  })  
  const clearModal = () => setModal(null)
  const changeTemplate = templateId => {
    ZenSmartAPI.get(templateIdRoute(templateId)).then(result => {
       let formattedBlockDefinition = result.data.blockDefinition.map(block => {
         return {...block, metadata: { ...block.metadata, ...block.metadata?.extras, xOffset: block?.metadata?.extras?.x_offset, yOffset: block?.metadata?.extras?.y_offset } }
       })
       result.data.blockDefinition = formattedBlockDefinition;
       const product = { ...result.data };
       let isNewProduct = false;
       if (!product.processes) {
         product.processes = initialData;
         isNewProduct = true;
       }
       currentProduct.productDefinition.template = templateId
       setProductFlowChanged(true)
       setProduct(product, isNewProduct,true)
       
     }).catch(error => {
       if(productFlowChanged){
           ZenSmartAPI.get(productIdRoute(currentProduct.productDefinition.id)).then(result => {
           let formattedBlockDefinition = result.data.blockDefinition.map(block => {
             return {...block, metadata: { ...block.metadata, ...block.metadata?.extras, xOffset: block?.metadata?.extras?.x_offset, yOffset: block?.metadata?.extras?.y_offset } }
           })
           result.data.blockDefinition = formattedBlockDefinition;
           const product = { ...result.data };
           let isNewProduct = false;
           if (!product.processes) {
             product.processes = initialData;
             isNewProduct = true;
           }
           setProductFlowChanged(false)
           setProduct(product, isNewProduct)
         })
       }
       notification.error({
         message: `The product template has no products.`
       })
     });


   const onSavePayload = { 'product_template_id' : templateId }
   onSave(onSavePayload)
 }
  const selectProduct = productID => {
    ZenSmartAPI.get(productIdRoute(productID)).then(result => {

      let formattedBlockDefinition = result.data.blockDefinition.map(block => {
        return {...block, metadata: { ...block.metadata, ...block.metadata?.extras, xOffset: block?.metadata?.extras?.x_offset, yOffset: block?.metadata?.extras?.y_offset } }
      })
      result.data.blockDefinition = formattedBlockDefinition;
      const product = { ...result.data };
      let isNewProduct = false;
      if (!product.processes) {
        product.processes = initialData;
        isNewProduct = true;
      }
      setProduct(product, isNewProduct)
    }).catch(error => {
      notification.error({
        message: error?.response?.data?.message ?? error.message ?? 'Error fetching product'
      })
    })

  }

  const setModalEdit = (type) => {
    let path;
    if(type === 'edit'){
      path = `/app/data-view/products/${productId}?edit=true`
    }else{
      path = `/app/data-view/products/${productId}?edit`
    }
    history.push({
        pathname: path,
      })
  }

  let options = null

  if (allProductsQuery.length) {
    options = alphabeticalData(allProductsQuery, "code").map(prodDef => (
      <Select.Option
        key={prodDef.id}
        value={prodDef.id}
      >
        {prodDef.code}: {prodDef.name}
      </Select.Option>
    ))
  };

  const productId = currentProduct && currentProduct.productDefinition.id;
  const templateId = currentProduct && currentProduct.productDefinition.template;

  const addCurrentProductToDropDown = (productId, productToDropDownValues,productsDropDownValues) => {
    if(productId && productToDropDownValues && productsDropDownValues.length != 0){
      //check if id exist
      const selectedType = productsDropDownValues.find(productsDropDownValue => productsDropDownValue.id === productId);
      if(selectedType){
        setProductToDropDownValues(false)
        return;
      }
      let currentProductObject = Object.assign({}, { id: currentProduct.productDefinition.id , code : currentProduct.productDefinition.productCode , name : currentProduct.productDefinition.name })
      productsDropDownValues.push(currentProductObject)
      setProductToDropDownValues(false)
    }
  }
  
  const onSearchProduct = debounce((value, limit = null) => {
    if (value !== "" || limit) {
      ZenSmartAPI.post(productSearchRoute(value), { limit: limit, response_type: "product_flow" }).then(result => {
        setProductsDropdownValues(result.data.data)
      })
    }
  }, 400)

  const addProductTemplate = (evt, options) => {
    const findOptions = options.some(data => data.name.toLowerCase() === evt.target.value.toLowerCase())
    if(findOptions){
      notification.error({ message: "Product Template Already Exist!" })
    }
    if (evt.keyCode === 13 && !findOptions && evt.target.value !== "" && evt.target.value !== null) {
      const inputValue = evt.target.value
      Modal.confirm({
        'content': `Are you sure you want to create a new product template "${inputValue}"?`,
        onOk: () => {
          ZenSmartAPI.post( addProductTemplateRoute , { name: inputValue }).then(res => {
              notification.success({ message: "Product Template Successfully Added!" })
              setProductTemplates(res.data.data)
            })
        },
      });
    }
  }

  return (
    <Container>
      <Label>Product:</Label>
      <Prompt
        when={hasUnsavedChanges}
        message={(location, action) => {
          if (window.location.pathname === location.pathname || window.location.pathname + '?edit=true' === location.pathname) {
            return true;
          }
          else {
            return `All unsaved product flow changes will be lost would you like to continue?`
          }
        }}
      />
      
      <ProductDropdown
              productSelected={selectProduct}
              currentProductId={currentProduct ? productId : undefined}
              loading={allProductsQuery.loading}
              disabled={allProductsQuery.loading}
      />

      {!enableClone && currentProduct && (
        <>
           <Search
              showSearch
              placeholder="Search for a templates"
              disabled={allProductsQuery.loading}
              loading={allProductsQuery.loading}
              optionFilterProp="children"
              filterOption={true}
              onInputKeyDown={(event) => addProductTemplate(event,productTemplates)}
              value={currentProduct.productDefinition.template}
              onChange={changeTemplate}
              dropdownStyle={dropdownStyle}
              >
              {alphabeticalData(productTemplates, "name").map(template => (
                <Select.Option
                  key={template.id}
                  value={template.id}
                >
                  {template.name}
                </Select.Option>
              ))}
              </Search>

              </>
        )}


        {!enableClone && currentProduct && (
          <>
          <Button type="link" onClick={() => setModalEdit('view')}>View</Button>
          <EditProductModal
            title={`Edit Product: ${currentProduct.article}`}
            currentProduct={currentProduct}
            setCurrentProduct={setCurrentProduct}
            visible={currentModal === MODAL.EDIT}
            onCancel={clearModal}
          />
        </>
      )}
          
      {!enableClone && currentProduct && (
        <>
            <Button type="link" onClick={() => setModalEdit('edit')}>Edit</Button>
          <EditProductModal
            title={`Edit Product: ${currentProduct.article}`}
            currentProduct={currentProduct}
            setCurrentProduct={setCurrentProduct}
            visible={currentModal === MODAL.EDIT}
            onCancel={clearModal}
          />
        </>
      )}

      {!enableClone && (
        <>
          <Link to="/app/prep/product-flow/create-product">
            <Button type="primary">Create New Product</Button>
          </Link>
        </>
      )}
    </Container>
  )
}

export default SearchArea