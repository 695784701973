import React, { useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { notification, Select, Input, Button, Form, Modal, Alert, Icon as AIcon } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import Cookies from 'js-cookie';
import QaFailLookup from 'pages/People/QAFail/partials/qaFailLookup'
import { ZenSmartAPI } from 'utils';
import { alphabeticalData } from 'utils/sortData';
import { normalBarcodeScanRoute } from "utils/apiRoutes"
import airhorn from 'static/sounds/airhorn.mp3'

const Header = styled.header`
  margin-bottom: 24px;
`

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const Container = styled.div`
  padding-bottom: 5px;
  font-family: 'Rubik', sans-serif;
`
const scanInputProps = {
  size: 'large',
  placeholder: 'Item Lookup'
}

const InputHeader = styled.span`
  font-weight: bold;
`
const InputWidth = { width: "85%" };

const QAFail = (props) => {
  const [scanItem, setScanItem] = useState(null)
  const { Option } = Select;
  const { scanData } = props
  const [lookupInput, setLookupInput] = useState(null);
  const [itemFailed, setItemFailed] = useState(null)
  const [loading, setLoading] = useState(false)
  const [defaultFailReason, setDefaultFailReason] = useState(null)
  const [showFailedMultiple, setShowFailedMultiple] = useState(false);
  const disableInput = {
    disabled: scanItem ? undefined : true,
  };
  const scanTypeId = scanData.id;
  const scanInput = useRef(null)
  const sound = new Audio(airhorn)

  const setLookup = (e) => {
    setLookupInput(e.target.value)
  }
  const handleLookup = useCallback((lookupValue) => {

    if (lookupValue.hasOwnProperty('error')) {
      sound.play()
      notification.error({
        message: lookupValue.error
      })
    }

    else if (lookupValue.hasOwnProperty('station_id')) {
      Cookies.set('STATION', lookupValue.station_id, { expires: 1 });
      Cookies.set('SCAN_TYPE', scanTypeId, { expires: 1 });
      Cookies.set("ALLOW_QUIZ", lookupValue?.allow_quiz ?? false, { expires: 1 });
      notification.success({
        message: 'Station Scan Successful'
      })
    }

    else if (lookupValue.hasOwnProperty('success_scan')) {
      notification.success({
        message: 'Barcode Scan Successful'
      })
      setScanItem(lookupValue)

      setItemFailed(null)
    }

    else {
      sound.play()
      notification.error({
        message: 'Invalid scan item. Please scan a valid barcode.'
      })
    }
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  const FailForm = Form.create({ name: 'FailForm' })(props => {
    const { form ,showFailedMultiple} = props
    const { getFieldDecorator, getFieldsValue, getFieldValue, validateFields } = form

    const handleSaveChanges = (evt) => {
      evt.preventDefault()

      validateFields((err, values) => {
        if (!err) {
          setLoading(true);
          const payload = getFieldsValue();
          payload.barcode = lookupInput;
          payload.scan_type_id = scanTypeId;
          payload.multipleBarcode = showFailedMultiple;
          payload.station_id = Cookies.get('STATION')
          if (payload.reason_id) {
            setDefaultFailReason(payload.reason_id)
          }
          ZenSmartAPI.post(normalBarcodeScanRoute, payload)
          .then((res) => {
            setLoading(false);
            if (res.data && res.data.duplicate_scan_prompt) {
              Modal.confirm({
                'content': res.data.duplicate_scan_prompt,
                onOk: () => {
                },
                cancelButtonProps: { style: { display: "none" } }
              });
            }
            if (res.data.message === "ITEM FAILED") {
              notification.success({
                message: 'Item Fail Successful'
              })
              if (res.data?.warning_message) {
                showCustomModels(res.data.warning_message,'Warning')
              }
              setItemFailed(res.data)
              scanInput.current.focus()
            }
          })
          .catch((res) => {
            setLoading(false);
            if (res.response.data.hasOwnProperty('message') && res.response.status === 400) {
              handleLookup({ error: res.response.data.message });
            }
            else {
              handleLookup('Not a valid Scan Item');
            }
          })
        }
      });

    }
    const getSelectedValue = (value) => {
      const getSelected = value.find((item) => {
        return item.selected === true
      })
      if (getSelected !== undefined) {
        return getSelected.value
      }
    }

    const hideReprintPageRangeField = (row) => {
      if(row.field === 'block_id' && showFailedMultiple){
        return false;
      }
      return row.field === 'reprint_page_range' && getFieldValue('fail_type') !== 'page_reprint';
    }

    const showCustomModels = (message,header) => {
      if (message) {
        const title = header
        Modal.info({
          title: title,
          icon: <AIcon type='exclamation' />,
          content: <div dangerouslySetInnerHTML={{ __html: message }}></div>,
        })
      }
    }

    return (
        <>
          <Form onSubmit={handleSaveChanges}>
            {scanItem ? alphabeticalData(scanItem.page_definitions.sections[0].section_data, 'label').map((row, key) => {
                  if (!hideReprintPageRangeField(row)) {
                    return (
                        <Form.Item colon={!hideReprintPageRangeField(row)} label={<InputHeader>{!hideReprintPageRangeField(row) && row.label}</InputHeader>}>
                          {getFieldDecorator(row.field, {
                            initialValue: row.label === "Reason" && defaultFailReason ? defaultFailReason : row.options ? getSelectedValue(row.options) : null,
                            rules: row.required === true ? [{ required: true, message: `Please input this feld!` }] : undefined
                          })(
                              row.data_type === "SELECT" ?
                              <Select optionFilterProp="children" showSearch placeholder={row.label} size="large" style={InputWidth}>
                                {alphabeticalData(row.options, "text").map((data, key) =>
                                    <Option value={data.value}>{data.text}</Option>
                                )}
                              </Select>
                                                         :
                              <Input
                                  placeholder={row.required === true ? "Please Input This Field" : "*Optional"}
                                  size="large"
                                  style={hideReprintPageRangeField(row) ? { ...InputWidth, display: 'none' } : InputWidth}
                              />
                          )}
                        </Form.Item>
                    )
                  }
                  return null
                }
            ) : null}

            {itemFailed ? <></>
                        : <Form.Item>
               <Button
                   type="primary"
                   htmlType="submit"
                   {...disableInput}
                   loading={loading ? true : undefined}
               >
                 Save Changes
               </Button>
             </Form.Item>}

          </Form>
        </>
    )
  })

  return (
    <>
      <Container>
        <Header>
          <Title>People {">"} Quality</Title>
        </Header>
        <div style={{ width: "100%" }}>
          <QaFailLookup
            title="QUALITY ASSURANCE FAIL"
            isViewingDetails={scanItem}
            isItemFailed={itemFailed}
            detailLabel={'PLEASE SCAN ITEM'}
            onLookup={handleLookup}
            inputProps={scanInputProps}
            scanTypeId={scanTypeId}
            setLookup={setLookup}
            scanInput={scanInput}
            showFailedMultiple={showFailedMultiple}
            setShowFailedMultiple={setShowFailedMultiple}
            setLookupInput={setLookupInput}
          >

            <FailForm showFailedMultiple={showFailedMultiple} />
          </QaFailLookup>
        </div>
      </Container>

    </>
  )
}

export default QAFail
