import React, { useState, useEffect } from 'react';
import { ZenSmartAPI } from 'utils'
import moment from 'moment'
import Countdown from 'react-countdown';
import { Row, Col } from 'antd';
import styled from "styled-components";
import { getEndBusinessDayRoute, getItemsToProduceRoute } from "utils/apiRoutes"

const CountDownHeader = styled.h1`
font-family: 'Rubik', sans-serif;
font-size : 17px;
text-align : center;
color : #8798AD;
`
const CountDownTimer = styled.h1`
font-family: 'Rubik', sans-serif;
font-size : 17px;
text-align : center;
`
const CountDownNumber = styled.h1`
font-family: 'Rubik', sans-serif;
font-size : 45px;
text-align : center;
padding : 5px;
`
const CountDownTimerHeader = styled(CountDownHeader)`
font-size : 12px;
`

const CountDownTarget = (props) => {
    const [countdownNumber, setCountDownNumber] = useState(null);
    const [itemToProduce, setItemToProduce] = useState(null)
    const countDown = ({ days, hours, minutes, seconds, completed }) => {

        const getTotalHour = days * 24 + hours
        if (completed) {
            return (
                <Row type="flex" justify="center" align="middle" style={{ padding: 5 }}>
                    <Col span={8}>
                        <CountDownTimer>00</CountDownTimer>
                        <CountDownTimerHeader>Hours</CountDownTimerHeader>
                    </Col>
                    <Col span={8}>
                        <CountDownTimer>00</CountDownTimer>
                        <CountDownTimerHeader>Minutes</CountDownTimerHeader>
                    </Col>
                    <Col span={8}>
                        <CountDownTimer>00</CountDownTimer>
                        <CountDownTimerHeader>Seconds</CountDownTimerHeader>
                    </Col>

                </Row>
            );
        } else {

            return (
                <Row type="flex" justify="center" align="middle" style={{ padding: 5 }}>
                    <Col span={8}>
                        <CountDownTimer>{getTotalHour < 10 && "0"}{getTotalHour}</CountDownTimer>
                        <CountDownTimerHeader>Hours</CountDownTimerHeader>
                    </Col>
                    <Col span={8}>
                        <CountDownTimer>{minutes < 10 && "0"}{minutes}</CountDownTimer>
                        <CountDownTimerHeader>Minutes</CountDownTimerHeader>

                    </Col>
                    <Col span={8}>
                        <CountDownTimer>{seconds < 10 && "0"}{seconds}</CountDownTimer>
                        <CountDownTimerHeader>Seconds</CountDownTimerHeader>
                    </Col>
                </Row>
            );
        }
    };

    const getEndBusinessDay = () => {
        ZenSmartAPI.get(getEndBusinessDayRoute)
            .then((res) => {
                setCountDownNumber(res.data.data)
            })
            .catch((res) => {

            })
    }

    const getItemsToProduce = () => {
        ZenSmartAPI.get(getItemsToProduceRoute)
            .then((res) => {
                setItemToProduce(res.data.data)
            })
            .catch((res) => {

            })
    }



    useEffect(() => {
        getEndBusinessDay()
        getItemsToProduce()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (

        <div>
            <CountDownHeader>{moment().format('dddd, MMMM DD')}</CountDownHeader>
            <CountDownNumber>{itemToProduce ? itemToProduce.toLocaleString() : 0}</CountDownNumber>
            <CountDownHeader>ITEMS TO PRODUCE</CountDownHeader>
            {
                countdownNumber ?
                    <Countdown
                        daysInHours={true}
                        date={moment(parseInt(`${countdownNumber}000`)).valueOf()}
                        renderer={countDown}
                    />
                    : null}
        </div>
    )
}

export default CountDownTarget