import React, { useState, useEffect } from 'react'
import { Spin } from "antd";
import './styles/Loading.css';


const Loading = (props) => {

  return (
      <Spin className={`loading-spinner full ${props.full ? 'full' : ''}`} spinning={props.loading || false}></Spin>
  )

}

export default Loading;
