import { checkProductQuiz } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils';

export const defaultGamificationQuizValue = {
  isVerified: false,
  loading: false,
  gameData: [],
  selectedAnswer: '',
  correctAnswer: '',
  quiz_type: '',
  barcode: '',
  count: 0
}

export const validateSelectedAnswer = async (
  selectedAnswer,
  scanGamification,
  setScanGamification,
  resetFields,
  onSubmit
) => {
  try {
    setScanGamification(oldState => ({
      ...oldState,
      selectedAnswer: selectedAnswer
    }))
    if (scanGamification.count > 1) {
      resetFields()
      resetState()
      return onSubmit({
        error: `You've exceeded the number of tries. Please try again later.`
      })
    }
    if (selectedAnswer === '') {
      return onSubmit({ error: 'Please Select an answer' })
    }
    // make call to check answer
    if (selectedAnswer === scanGamification.correctAnswer) {
      setScanGamification(oldState => ({ ...oldState, isVerified: true, loading: true }))
    } else {
      setScanGamification(oldState => ({
        ...oldState,
        count: oldState.count + 1
      }))
      if (scanGamification.count === 1) {
        resetFields()
        resetState(setScanGamification)
      }
      return onSubmit({
        error:
          scanGamification.count === 1
            ? `Number of trials exceeded. Try again later`
            : `Wrong answer selected. You've got one more chance`
      })
    }
  } catch (error) {
    onSubmit({ error: error?.message ?? 'Test validation failed' })
  }
}

export const resetState = setScanGamification => {
  setScanGamification(oldState => ({
    ...oldState,
    isVerified: false,
    loading: false,
    gameData: [],
    quiz_type: '',
    count: 0,
    selectedAnswer: ''
  }))
}

export const randomizeArray = (arr) => {
  return arr
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
}

export const fetchGamificationQuiz = async (payload,  setLoading, setScanGamification, scanInput ) => {
  try {
    setLoading(true)
    setScanGamification(prevState => ({...prevState, loading: true}))
    const res = await ZenSmartAPI.post(checkProductQuiz, payload)
    const game = res?.data
    if (game?.quiz.length > 0) {
      setScanGamification(oldState => ({ ...oldState, gameData: randomizeArray(game.quiz), barcode: payload.barcode, correctAnswer: game?.correct_answer, quiz_type: game?.quiz_type }))
      return true;
    }
    return false
  } catch (error) {
    return false
  } finally {
    setScanGamification(prevState => ({...prevState, loading: false}))
    setLoading(false)
    scanInput.current.focus()
  }
}