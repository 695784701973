import React, { useState, useEffect } from 'react';
import { Modal, Table, notification ,Pagination} from 'antd';
import { getDepartmentWipSummaryData } from 'utils/apiRoutes';
import { ZenSmartAPI } from 'utils';
import moment from 'moment';

import { Link } from 'react-router-dom';

const columns = [
  {
    title: 'Transaction ID',
    key: 'transaction_id',
    render: (order) => {
      return (
        <Link
          to={`/app/data-view/transactions/${order.transaction_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {order.client_number}
        </Link>
      );
    },
  },
  {
    title: 'Order ID',
    key: 'order_id',
    render: (order) => {
      return (
        <Link
          to={`/app/data-view/orders/${order.order_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {order.order_number}
        </Link>
      );
    },
  },
  {
    title: 'Due Date',
    dataIndex: 'due_date',
    key: (order) => order.order_id + 1,
    render: (due_date) => moment(due_date).format('L'),
  },
  {
    title: 'Product',
    key: 'product_id',
    render: (order) => {
      return (
        <Link
          to={`/app/data-view/products/${order.product_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {order.product_code}
        </Link>
      );
    },
  },
  {
    title: 'Stage',
    dataIndex: 'stage',
    key: (order) => order.order_id + 2,
  },
  {
    title: 'Stage Time',
    dataIndex: 'stage_time',
    key: (order) => order.order_id + 3,
    render: (stage_time) => moment(stage_time).format('L'),
  },
];
const WipSummaryModal = (props) => {
  const { is_today, due_date, department, visible, closeModal,stage } = props;
  const [summaryData, setSummaryData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [loading, setLoading] = useState(false);
  const fetchData = (params = {}) => {
    setLoading(true);
    setSummaryData([]);
    const req_params = {
      ...params,
      is_today,
      due_date,
      stage:stage
    };
    ZenSmartAPI.get(getDepartmentWipSummaryData(department.id), { params: req_params})
      .then((res) => {
        const { data } = res.data;
        setSummaryData(res.data.data.data);
        setPagination((prevPagination) => ({
          ...prevPagination,
          current: data.current_page,
          total: data.total
        }));
      })
      .catch((err) => {
        notification.error({
          message: 'No data available.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (visible) {
      fetchData({ page: pagination.current }); // Fetch data for the current page
    }
  }, [visible]);

  const handlePageChange = (page) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page,
    }));
  };


  return (
    <Modal
      visible={visible}
      maskClosable={true}
      footer={null}
      onCancel={() => closeModal()}
      title={department.name}
      width={1000}
    >
      <Table
        columns={columns}
        rowKey={(record) => record.order_id}
        dataSource={summaryData}
        loading={loading}
        pagination={false} // Disable default pagination
        scroll={{ y: 300 }}
      />
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handlePageChange}
        showSizeChanger={false}
        style={{ marginTop: '16px', textAlign: 'right' }}
      />
    </Modal>
  );
};

export default WipSummaryModal;
