import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Row, Col } from 'antd'
import { panelSortData } from 'utils/sortData'
import moment from 'moment';
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { operatorActivityInitialData } from "utils/panelsInitialData"

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    font-size : 13px !important;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    border: 1px solid #f1f3ff;
}

`
const TableData = (props) => {

    const { loading, data, columnValues } = props
    const [columns, setColumns] = useState([])



    const rowStyle = (value, index, row) => {
        const obj = {
            props: {
                style: { border: "none", fontWeight: !row.total_units ? "bold" : null, fontSize: 13 },
            },
            children: <div>{value}</div>,
        };
        return obj
    }

    useEffect(() => {
        if (columnValues.length > 0) {
            const constructColumn = []
            columnValues.map(item => {
                constructColumn.push({
                    title: item === "scan_type" ? "TASKS/OPERATORS" : item.toUpperCase().replace(/_/g, " "),
                    dataIndex: item,
                    width: item === "scan_type" ? 200 : null,
                    key: item,
                    render(value, row, index) {
                        return rowStyle(value !== null && value !== undefined ? value : "", index, row, item === "operator")
                    }
                })
            })
            setColumns(constructColumn)
        }
    }, [columnValues]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "50vh", x: 'fit-content' }}
                    pagination={false}

                />
            </StyleTable>
        </>
    )
}

export default TableData