import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input } from 'antd'
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { reportRoute, productSearchRoute } from "utils/apiRoutes"
import { debounce } from "utils/debounce"
import { alphabeticalData } from 'utils/sortData';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { errorHandler } from 'utils/errorMessageHandler';

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
  padding: 10px;
`
const ColInputs = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;
const ReportingInputs = (props) => {
    const [productsDropDownValues, setProductsDropdownValues,] = useState([])
    const { reportingPanel, setReportingPanel, initialData, reportingInitialState } = props
    const onSearchProduct = debounce((value, limit = null) => {
        if (value !== "" || limit) {
            ZenSmartAPI.post(productSearchRoute(value), { limit: limit, response_type: "product_flow" }).then(result => {
                setProductsDropdownValues(result.data.data)
            })
        }
    }, 400)

    const fetchReportingData = () => {
        if (reportingPanel.report_format == null || reportingPanel.date_from == null || reportingPanel.date_to == null) {
            notification.error({ message: "Please input all report format required fields!" })
            return
        }
        const payload = {
            report_format: reportingPanel.report_format,
            date_from: moment(reportingPanel.date_from).format("MM/DD/YYYY"),
            date_to: moment(reportingPanel.date_to).format("MM/DD/YYYY"),
            product_id: reportingPanel.product,
            line_id: reportingPanel.line
        }
        if (!reportingPanel.line) {
            delete payload.line_id
        }
        if (!reportingPanel.product) {
            delete payload.product_id
        }

        ZenSmartAPI.post(reportRoute, payload).then(result => {
            window.open(result.data.bom_report.download_link, '_blank');
            setReportingPanel(reportingInitialState)
        }).catch(err => {
            if (_.get(err, 'response.status') === 422) {
                notification.error({
                    message: errorHandler(_.get(err, 'response.data.errors'))
                })
                return
            }
            else if (_.get(err, 'response.data.message')) {
                notification.error({
                    message: _.get(err, 'response.data.message')
                })
                return
            }
            notification.error({ message: "Cant download report!" })
        })
    }

    const changeDateFrom = (value, dateString) => {
        setReportingPanel({ ...reportingPanel, date_from: value })
    }
    const changeDateTo = (value, dateString) => {
        setReportingPanel({ ...reportingPanel, date_to: value })
    }

    const updateReportingPanel = (value, key) => {
        if (value.target) {
            setReportingPanel({ ...reportingPanel, [key]: value.target.value })
        }
        else {
            setReportingPanel({ ...reportingPanel, [key]: value })
        }

    }

    useEffect(() => {
        onSearchProduct("", 20)
    }, [])

    return (
        <>
            <div style={{ margin: "10px 0px", borderBottom: "1px solid grey" }}>
                <Heading>Reporting</Heading>
                <Row style={{ marginTop: 10, marginBottom: 10 }} type="flex" align="middle" justify="center">

                    <ColInputs span={6}>
                        <p style={{ fontWeight: "bold" }}>Report Format*</p>
                        <Select value={reportingPanel.report_format} onChange={(e) => updateReportingPanel(e, 'report_format')} style={{ width: "100%" }} >
                            {initialData && alphabeticalData(initialData.report_type).map(item => (
                                <Option style={{ textTransform: "capitalize" }}
                                    key={item.key}
                                    value={item.key}
                                >
                                    {item.label}
                                </Option>
                            ))}

                        </Select>
                    </ColInputs>

                    <ColInputs span={4}>
                        <p style={{ fontWeight: "bold" }}>Date From *</p>
                        <DatePicker value={reportingPanel.date_from} style={{ width: '100%' }} onChange={changeDateFrom} />
                    </ColInputs>

                    <ColInputs span={4}>
                        <p style={{ fontWeight: "bold" }}>Date To *</p>
                        <DatePicker value={reportingPanel.date_to} style={{ width: '100%' }} onChange={changeDateTo} />
                    </ColInputs>

                    <ColInputs span={4}>
                        <p style={{ fontWeight: "bold" }}>Product</p>
                        <Select showSearch
                            placeholder="Search for a product"
                            optionFilterProp="children"
                            filterOption={true}
                            style={{ width: '100%' }}
                            value={reportingPanel.product}
                            onChange={(e) => updateReportingPanel(e, 'product')}
                            onSearch={(e) => onSearchProduct(e)} >
                            <Select.Option
                                value={null}
                            >
                                None
                            </Select.Option>
                            {alphabeticalData(productsDropDownValues, "code").map(prodDef => (
                                <Select.Option
                                    key={prodDef.id}
                                    value={prodDef.id}
                                >
                                    {prodDef.code}: {prodDef.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </ColInputs>

                    <ColInputs span={4}>
                        <p style={{ fontWeight: "bold" }}>Line</p>
                        <Input onChange={(e) => updateReportingPanel(e, 'line')} value={reportingPanel.line} />
                    </ColInputs>
                    <ColInputs span={1}>
                        <br />
                        <Button type="primary" style={{ width: "100%" }} onClick={fetchReportingData}>
                            GO
                        </Button>
                    </ColInputs>
                </Row>
            </div>
        </>
    )
}
export default ReportingInputs