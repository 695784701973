import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Spin, Modal } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { ZenSmartAPI } from 'utils'
import { Button } from '../../../components/zensmart-design-system';
import { fetchConfigurationRoute, fetchConfigurationInitDataRoute } from "utils/apiRoutes"
import Sidebar from './Sidebar'
import Configuration from './Configuration'
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import _ from "lodash"

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const DivStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
  h2 {
    font-weight: 700;
    font-family: 'Rubik', sans-serif;
  }
  
`
const BatchReleaseDesignerPage = (props) => {
    const [loading, setLoading] = useState(false)
    const [configurations, setConfigurations] = useState([])
    const [configurationsPagination, setConfigurationsPagination] = useState({})
    const [configurationInitData, setConfigurationInitData] = useState({})
    const [oneConfiguration, setOneConfiguration] = useState({})
    const fetchConfigurations = () => {
        setLoading(true)
        ZenSmartAPI.get(fetchConfigurationRoute)
            .then((res) => {
                setLoading(false)
                setConfigurations(res.data.data)
                setConfigurationsPagination(res.data.meta)
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant fetch configurations!")

            })
    }

    const fetchInitData = () => {
        setLoading(true)
        ZenSmartAPI.get(fetchConfigurationInitDataRoute)
            .then((res) => {
                setLoading(false)
                setConfigurationInitData(res.data.data)
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant fetch configuration init data!")

            })
    }

    const getOneConfiguration = (id) => {
        setLoading(true)
        ZenSmartAPI.get(`${fetchConfigurationRoute}/${id}`)
            .then((res) => {
                setLoading(false)
                setOneConfiguration(res.data.data)
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant fetch configuration!")

            })
    }
    const updateOneConfiguration = (id, e) => {
        if (e.keyCode === 13) {
            setLoading(true)
            ZenSmartAPI.put(`${fetchConfigurationRoute}/${id}`, { name: e.target.value })
                .then((res) => {
                    setLoading(false)
                    fetchConfigurations()
                })
                .catch((err) => {
                    setLoading(false)
                    errorNoticationHandler(err, "Cant fetch configuration!")

                })
        }
    }

    const deleteConfiguration = (id) => {
        Modal.confirm({
            'content': 'Are you sure you want to delete this configuration?',
            onOk: () => {
                setLoading(true)
                ZenSmartAPI.delete(`${fetchConfigurationRoute}/${id}`)
                    .then((res) => {
                        setLoading(false)
                        setOneConfiguration({})
                        fetchConfigurations()
                        notification.success({ message: " Delete configuration successful!" })
                    })
                    .catch((err) => {
                        setLoading(false)
                        errorNoticationHandler(err, "Cant delete configuration!")

                    })
            },
        });

    }

    useEffect(() => {
        fetchConfigurations()
        fetchInitData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <DivStyle />
            <Header>
                <Title>Print {">"} Batch Release Designer</Title>
            </Header>
            <Spin tip="Loading..." spinning={loading}>
                <Panel title="Batch Release Designer">
                    <div>
                        {oneConfiguration.id &&
                            <Row type="flex" justify="end" align="middle" style={{ padding: 10 }}>
                                <Col style={{ padding: 5 }}>
                                    <Button type="primary" style={{ background: "red" }} onClick={() => deleteConfiguration(oneConfiguration.id)} size='small'>Delete</Button>
                                </Col>

                            </Row>
                        }
                        <hr />
                        <Row type="flex" style={{ minHeight: "50vh" }}>
                            <Col span="6" style={{ padding: 20, background: "rgb(219, 233, 253)" }}>
                                <Sidebar
                                    configurations={configurations}
                                    setConfigurations={setConfigurations}
                                    configurationsPagination={configurationsPagination}
                                    setConfigurationsPagination={setConfigurationsPagination}
                                    getOneConfiguration={getOneConfiguration}
                                    fetchConfigurations={fetchConfigurations}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            </Col>
                            <Col span={18} style={{ padding: 20 }}>
                                <Configuration
                                    configurations={configurations}
                                    oneConfiguration={oneConfiguration}
                                    setConfigurations={setConfigurations}
                                    getOneConfiguration={getOneConfiguration}
                                    setOneConfiguration={setOneConfiguration}
                                    fetchConfigurations={fetchConfigurations}
                                    loading={loading}
                                    setLoading={setLoading}
                                    configurationInitData={configurationInitData}
                                    updateOneConfiguration={updateOneConfiguration}
                                />
                            </Col>
                        </Row>
                    </div>
                </Panel>
            </Spin>
        </>
    )
}

export default BatchReleaseDesignerPage