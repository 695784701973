import is from "is_js";

export const STRING_TYPE = "string";
export const INTEGER_TYPE = "integer";
export const FLOAT_TYPE = "float";
export const BOOLEAN_TYPE = "boolean";
export const TEXTAREA_TYPE = "string";
export const MULTIPLE_TYPE = "multiple";
export const MULTIPLE_NUMBER_TYPE = "multiple_number";

const Mirror = ["Apply on Pages"];
const Rotate = ["Degrees", "Front and Rear Rotation", "Condition", "Follow Page Data"];
const CropMarks = ["Crop Type", "Color", "Length", "Bleed", "Line Weight", "Gap"];
const Trim = ["Side", "Length"];
const ShippingMarker = [
  "X-Location",
  "Y-Location",
  "Marker Size",
  "Rotation",
];
const Text = [
  "X-Location",
  "Y-Location",
  "X-Alignment",
  "Y-Alignment",
  "Rotation",
  "Font Size",
  "Textblock",
  "Color",
  "Background Color",
  "Bold",
  "Print on Pages"
];
const DailyDot = ["X-Location", "Y-Location", "Radius", "Collation Letter", "Is custom color", "Print on Pages"];
const Impose = [
  "Sheet Width",
  "Sheet Height",
  "First Image X",
  "First Image Y",
  "Horizontal Gutter",
  "Vertical Gutter",
  "Images Across",
  "Images Down",
  "Cut and Stack",
  "Limit Paper Wastage",
  "Template",
  "Template X-Offset"
];
const Spine = [
  "Sheet Width",
  "Sheet Height",
  "Spine Line Height",
  "Spine Line Weight",
  "Single Line",
  "Spine Line Bleed",
  "Add Spine Width"
];
const WhiteSpaces = ["Side", "Height", "Opposite side on rear page", "Transparency", "Qualifier", "Limit Sheet Height", "Background Color"];
const VendorLogo = [
  "Height",
  "X-Location",
  "Y-Location",
  "Width",
  "Resize Type",
];
const Overlay = [
  "Overlay Image"
];
const Barcode = [
  "Width",
  "Height",
  "X-Location",
  "Y-Location",
  "X-Alignment",
  "Y-Alignment",
  "Type",
  "With Text",
  "Rotation",
  "Barcode Type",
  "Print on Pages",
  "Maintain Aspect Ratio",
  "Data Matrix Symbol Size",
];
const Skew = [
  "Scale",
  "Width",
  "Height",
  "Stretch",
  "Zoom and Crop",
  "Follow Page Data",
  "Follow DTG Data",
  "Reverse Page Width",
  "Reverse Page Height",
  "Custom Sheet Width",
  "Custom Sheet Height",
  "Image Alignment",
  "Skew Pages",
];
const Duplicate = ["Copies", "Duplicate Each Page", "Duplicate Condition"];
const Fotoba = [
  "Side",
  "Distance from edge",
  "Line Weight",
  "Lines Gap",
  "Color"
];
const Lines = [
  "First Line X",
  "First Line Y",
  "First Line X Alignment",
  "First Line Y Alignment",
  "Length",
  "Line Width",
  "Direction",
  "Number of Lines",
  "Gap",
  "Color"
];
const LinesCustom = [
  "From X",
  "From Y",
  "To X",
  "To Y",
  "Weight",
  "Number of Lines",
  "Gap",
  "Color"
];
const CmykStrip = [
  "X-Location",
  "Y-Location",
  "Height",
  "Width"
];
const DrawRectangle = [
  "X-Location",
  "Y-Location",
  "X-Alignment",
  "Y-Alignment",
  "Origin Top Left",
  "Height",
  "Width",
  "Border Line Weight",
  "Color",
  "Opposite side on rear page",
  "Include In Batch Sheet",
  "Include Spine Width",
  "Draw Rectangle",
  "Custom Background Color"
];
const BatchSheet = [
  "Scale",
  "Sheet Width",
  "Sheet Height",
  "Sheet Location",
  "Barcode Only",
  "Batch Sheet Text",
  "Force Single Sheet",
  "Repeating",
  "Combo"
];
const Thumbnail = [
  "Rotation",
  "X-Location",
  "Y-Location",
  "X-Alignment",
  "Y-Alignment",
  "Width",
  "Height",
  "Resize Width",
  "Resize Height",
  "All Images",
  "Print on Pages"
];
const JoinImages = [
  "Horizontal Gutter",
  "Vertical Gutter",
  "Images Across",
  "Images Down"
];

const LayGuides = [
  "Distance from Corner",
];

const ZeroGapImpose = [
  "Lanes",
  "Cut Line Length",
  "Sheet Width",
  "Sheet Height",
  "Left Margin",
  "Horizontal Gutter",
  "Lane Width",
  "Whitespace Length",
  "Cut Line Width",
  "Cut Line X",
  "First Lane Cut Line X",
  "Cut Line Size",
  "Eyemark X",
  "Eyemark Y",
  "Eyemark Width",
  "Eyemark Height",
  "Front Refresh Line X",
  "Gen8 Template",
  "Alignment Tool Width",
  "Alignment Tool Height",
  "Alignment Tool Middle Width",
  "Alignment Tool Rows",
  "Alignment Tool Cmyk",
  "Alignment Tool Cmyk Interval",
  "Alignment Tool Cmyk Height",
  "QC Sheet Copies",
];
const EmtImpose = [
  "Lanes",
  "Offcut Lanes",
  "Rows",
  "Sheet Width",
  "Sheet Height",
  "Orientation",
  "Top Margin",
  "Left Margin",
  "Horizontal Gutter",
  "Vertical Gutter",
  "Lamination Whitespace",
  "Die Line Height",
  "Die Line Width",
  "Refresh Lines Colors",
  "Refresh Lines Length",
  "Refresh Lines Group",
  "Refresh Lines Y Offset",
  "ToF X Location",
  "ToF Width",
  "ToF Height",
  "ToF Y Start",
  "Barcode X Location",
  "Barcode Starting Y Location",
  "Barcode Distance",
  "Barcode Height",
  "Barcode Width",
  "SOJ Pages",
  "EOJ Pages",
  "ToF Width Rear",
  "ToF Height Rear",
  "ToF X Location Rear",
  "ToF Y Start Rear",
  "Key Line Width",
  "Key Line X-Location",
  "Barcode Rotation",
  "Wireframe Color",
  "Wireframe Width",
  "Wireframe Height",

];

const MultiCutImpose = [
  "Lanes",
  "Lane Widths",
  "Cut Lane Widths",
  "Sub Lanes Horizontal Gutter",
  "Sheet Width",
  "Sheet Height",
  "Left Margin",
  "Horizontal Gutter",
  "Vertical Gutter",
  "Cut Line Length",
  "Cut Line Width",
  "Eyemark X",
  "Eyemark Y",
  "Eyemark Width",
  "Eyemark Height",
  "Front Refresh Line X",
  "Order Ident Barcode X",
  "Order Ident Barcode Y",
  "Order Ident Barcode Page Width",
  "Order Ident Barcode Page Height",
  "Order Ident Barcode Height",
  "Order Ident Barcode Width",
  "Sub Lane X Offset",
  "Sub Lane X Gutter",
  "Alignment Tool Width",
  "Alignment Tool Length",
  "Alignment Tool Middle Width",
  "Alignment Tool X Offset",
  "Order Ident Barcode Type",
  "Submission Sheet Text",
  "Submission Sheet Height",
  "Submission Sheet Offset"
];

const JobSheets = [
  "Template Job Sheets",
  "Sheet Location",
  "Ad Image Location",
  "Custom Sheet Width",
  "Custom Sheet Height",
];

const Shape = [
  "Shape",
  "Size",
  "Color",
  "X-Location",
  "Y-Location",
  "X-Alignment",
  "Y-Alignment",
  "Rotation",
  "Shape Type",
  "Print on Pages",
];

const Duplex = [
  "X-Location",
  "Y-Location",
  "Rotation",
  "Font Size",
  "Text",
  "Barcode X Location",
  "Barcode Y Location",
  "Barcode Width",
  "Barcode Height",
  "Barcode Rotation",
  "Barcode Type",
  "Barcode With Text",
  "Transparency",
  "Barcode CMYK",
  "X-Location on Print",
  "Y-Location on Print",
  "Font Size on Print",
  "Text Rotation on Print",
  "Text on Print",
  "Duplex Print Rotation",
  "Input Includes Duplex Image",
];

const Sheet = [
  "Sheet Width",
  "Sheet Height",
  "X-Location",
  "Y-Location",
  "Transparency",
  "Apply Sheet"
];

const PageSeparator = [
  "Sheet Location",
  "Overwrite First Page",
];

const Marker = [
  "X-Location",
  "Y-Location",
  "X-Alignment",
  "Y-Alignment",
  "Width",
  "Height",
  "Rotation",
  "Marker Type",
  "Print on Pages",
];

const PageStamper = [
  "X-Location",
  "Y-Location",
  "Page Stamp Type",
  "Text Info",
  "Rotation",
  "Font Size",
  "Bold",
  "Print on Pages",
];

const Setup = [
  "Preview File Path",
];

const Border = [
  "X-Location",
  "Y-Location",
  "Width",
  "Height",
  "Line Weight",
  "Border Type"
];

const CutFrame = [
  "Frame Width",
  "Frame Height",
  "Image Alignment"
];

const CutLayer = [
  "Image Lines Alignment",
  "Color",
  "Spotcolor Name",
  "Layer Template"
];

export const LibConstants = {
  Setup: "Setup",
  UpdateRawFiles: "Update Raw Files",
  Rotate: "Rotate",
  Mirror: "Mirror",
  CropMarks: "Crop Marks",
  Trim: "Trim",
  Text: "Text",
  DailyDot: "Daily Dot",
  Impose: "Impose",
  WhiteSpaces: "White Space",
  VendorLogo: "Vendor Logo",
  Barcode: "Barcode",
  Skew: "Skew",
  Duplicate: "Duplicate",
  JobSheets: "Job Sheets",
  BatchSheet: "Batch Sheet",
  Overlay: "Overlay",
  Warp: "Warp",
  Fotoba: "Fotoba",
  Lines: "Lines",
  CmykStrip: "CMYK Strip",
  DrawRectangle: "Draw Rectangle",
  Thumbnail : "Thumbnail",
  LinesCustom : "Lines (Custom)",
  JoinImages : "Join Images",
  LayGuides : "Lay Guides",
  ZeroGapImpose : "Zero Gap Impose",
  EmtImpose : "Emt Impose",
  Spine : "Spine",
  Shape : "Shape",
  Duplex: "Duplex",
  Sheet: "Sheet",
  EnvelopeSlate: "Envelope Slate",
  Label: "Label",
  PageSeparator: "Page Separator",
  Marker: "Marker",
  PageStamper: "Page Stamper",
  ShippingMarker: "Shipping Marker",
  Border: "Border",
  CutFrame: "Cut Frame",
  MultiCutImpose: "Multi Cut Impose",
  CutLayer: "Cut Layer"
};

const constructParameter = (paramArray) => {
  if (is.existy(paramArray)){
    return { parameters: ["Label", ...paramArray] };
  }else{
    return { parameters: ["Label"] };
  }
};

export const Libraries = [
  { id: LibConstants.Setup, label: LibConstants.Setup, ...constructParameter(Setup) },
  { id: LibConstants.Rotate, label: LibConstants.Rotate, ...constructParameter(Rotate) },
  { id: LibConstants.Mirror, label: LibConstants.Mirror, ...constructParameter(Mirror) },
  { id: LibConstants.CropMarks, label: LibConstants.CropMarks, ...constructParameter(CropMarks) },
  { id: LibConstants.Trim, label: LibConstants.Trim, ...constructParameter(Trim) },
  { id: LibConstants.Text, label: LibConstants.Text, ...constructParameter(Text) },
  { id: LibConstants.DailyDot, label: LibConstants.DailyDot, ...constructParameter(DailyDot) },
  { id: LibConstants.Impose, label: LibConstants.Impose, ...constructParameter(Impose) },
  { id: LibConstants.WhiteSpaces, label: LibConstants.WhiteSpaces, ...constructParameter(WhiteSpaces) },
  { id: LibConstants.VendorLogo, label: LibConstants.VendorLogo, ...constructParameter(VendorLogo) },
  { id: LibConstants.Barcode, label: LibConstants.Barcode, ...constructParameter(Barcode) },
  { id: LibConstants.Skew, label: LibConstants.Skew, ...constructParameter(Skew) },
  { id: LibConstants.Duplicate, label: LibConstants.Duplicate, ...constructParameter(Duplicate) },
  { id: LibConstants.JobSheets, label: LibConstants.JobSheets, ...constructParameter(JobSheets) },
  { id: LibConstants.BatchSheet, label: LibConstants.BatchSheet, ...constructParameter(BatchSheet) },
  { id: LibConstants.Overlay, label: LibConstants.Overlay, ...constructParameter(Overlay) },
  { id: LibConstants.Fotoba, label: LibConstants.Fotoba, ...constructParameter(Fotoba) },
  { id: LibConstants.Lines, label: LibConstants.Lines, ...constructParameter(Lines) },
  { id: LibConstants.LinesCustom, label: LibConstants.LinesCustom, ...constructParameter(LinesCustom) },
  { id: LibConstants.CmykStrip, label: LibConstants.CmykStrip, ...constructParameter(CmykStrip) },
  { id: LibConstants.DrawRectangle, label: LibConstants.DrawRectangle, ...constructParameter(DrawRectangle) },
  { id: LibConstants.Thumbnail, label: LibConstants.Thumbnail, ...constructParameter(Thumbnail) },
  { id: LibConstants.JoinImages, label: LibConstants.JoinImages, ...constructParameter(JoinImages) },
  { id: LibConstants.LayGuides, label: LibConstants.LayGuides, ...constructParameter(LayGuides) },
  { id: LibConstants.ZeroGapImpose, label: LibConstants.ZeroGapImpose, ...constructParameter(ZeroGapImpose) },
  { id: LibConstants.EmtImpose, label: LibConstants.EmtImpose, ...constructParameter(EmtImpose) },
  { id: LibConstants.Shape, label: LibConstants.Shape, ...constructParameter(Shape) },
  { id: LibConstants.Duplex, label: LibConstants.Duplex, ...constructParameter(Duplex) },
  { id: LibConstants.Sheet, label: LibConstants.Sheet, ...constructParameter(Sheet) },
  { id: LibConstants.EnvelopeSlate, label: LibConstants.EnvelopeSlate, ...constructParameter() },
  { id: LibConstants.Spine, label: LibConstants.Spine, ...constructParameter(Spine) },
  { id: LibConstants.Marker, label: LibConstants.Marker, ...constructParameter(Marker) },
  { id: LibConstants.Label, label: LibConstants.Label, ...constructParameter() },
  { id: LibConstants.PageSeparator, label: LibConstants.PageSeparator, ...constructParameter(PageSeparator) },
  { id: LibConstants.UpdateRawFiles, label: LibConstants.UpdateRawFiles, ...constructParameter() },
  { id: LibConstants.PageStamper, label: LibConstants.PageStamper, ...constructParameter(PageStamper) },
  { id: LibConstants.ShippingMarker, label: LibConstants.ShippingMarker, ...constructParameter(ShippingMarker) },
  { id: LibConstants.Border, label: LibConstants.Border, ...constructParameter(Border) },
  { id: LibConstants.CutFrame, label: LibConstants.CutFrame, ...constructParameter(CutFrame) },
  { id: LibConstants.MultiCutImpose, label: LibConstants.MultiCutImpose, ...constructParameter(MultiCutImpose) },
  { id: LibConstants.CutLayer, label: LibConstants.CutLayer, ...constructParameter(CutLayer) },
];
