import React, { useState, useEffect } from 'react'
import { Row, Col, Select, notification } from 'antd'
import { ZenSmartAPI } from 'utils'
import { assignConfigurationToPrinterRoute, deleteConfigurationToPrinterRoute } from "utils/apiRoutes"
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import _ from "lodash"
import { Checkbox } from 'antd';


const PrinterAssignmentRow = (props) => {
    const { data, batchingConfigurations, setLoading, fetchInitData } = props
    const [printerConfiguration, setPrinterConfiguration] = useState({})

    useEffect(() => {
        if (data) {
            setPrinterConfiguration(data)
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    const updatePrinterAssignment = (configurationId, printerId, showPrinter=null) => {
        setLoading(true)
        if (configurationId) {
            const payload = {}
            if (showPrinter !== null) {
                payload.show_printer = showPrinter
            }
            ZenSmartAPI.post(assignConfigurationToPrinterRoute(configurationId, printerId), payload)
                .then((res) => {
                    setLoading(false)
                    fetchInitData()
                    notification.success({ message: "Printer assignment updated!" })
                })
                .catch((err) => {
                    setLoading(false)
                    errorNoticationHandler(err, "Cant update printer assignments!")

                })
        }
        else {
            ZenSmartAPI.delete(deleteConfigurationToPrinterRoute(printerId))
                .then((res) => {
                    setLoading(false)
                    fetchInitData()
                    notification.success({ message: "Printer assignment updated" })
                })
                .catch((err) => {
                    setLoading(false)
                    errorNoticationHandler(err, "Cant update printer assignments!")

                })
        }
    }

    return <Row Row type="flex" align="middle" >
        <Col span="4" style={{ padding: 10 }}>
            <p>{printerConfiguration.full_name}</p>
        </Col>
        <Col span="6" style={{ padding: 10 }}>
            <Checkbox
                onChange={e => updatePrinterAssignment(printerConfiguration?.batching_configuration?.id, printerConfiguration.id, e.target.checked)}
                checked={printerConfiguration?.show_printer}
            >
                Show Printer
            </Checkbox>
        </Col>
        <Col span="14" style={{ padding: 10 }}>
            <Select
                showSearch
                style={{ width: "100%" }}
                optionFilterProp="children"
                value={printerConfiguration.batching_configuration ? printerConfiguration.batching_configuration.id : undefined}
                onChange={(value) => updatePrinterAssignment(value, printerConfiguration.id)}
                allowClear
            >
                {batchingConfigurations.map(data =>
                    <Select.Option value={data.id}>{data.name}</Select.Option>
                )}
            </Select>
        </Col>
    </Row>

}

export default PrinterAssignmentRow