import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table } from 'antd'


const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

`
const TableData = (props) => {

    const { itemData, loading, data, setData } = props

    const columns = [
    
        {
            title:"HOURS",
            dataIndex: 'hours',
            key: 'hours',
            render(value, row, index) {
                return rowStyle(value || value === 0 ? value : "-", index, row)
            },
        },
        {
            title:"IN ORDERS",
            dataIndex: 'in_orders',
            key: 'in_orders',
            render(value, row, index) {
                return rowStyle(value || value === 0 ? value : "-", index, row)
            },
        },
        {
            title: "IN UNITS",
            dataIndex: 'in_units',
            key: 'in_units',
            render(value, row, index) {
                return rowStyle(value || value === 0 ? value : "-", index, row)
            },
        },
        {
            title: "OUT ORDERS",
            dataIndex: 'out_orders',
            key: 'out_orders',
            render(value, row, index) {
                return rowStyle(value || value === 0 ? value : "-", index, row)
            },
        },
        {
            title: "OUT UNITS",
            dataIndex: 'out_units',
            key: 'out_units',
            render(value, row, index) {
                return rowStyle(value || value === 0 ? value : "-", index, row)
            },
        },
    ];

    const rowStyle = (value, index, row) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }
        if (value === "Total")
        {
            obj.props.style = { border: "none", fontWeight : "bold" }
        }

        return obj
    }
    useEffect(() => {

        if (data.length > 0) {

            const itemObj = itemData.map(data => {
                const obj = {
                    hours: data.hours,
                    in_orders: data.in_orders,
                    in_units: data.in_units,
                    out_orders: data.out_orders,
                    out_units: data.out_units,
                    transaction: data.transaction,
                }
                return obj;
            })

            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    pagination={false}

                />
            </StyleTable>
        </>
    )
}

export default TableData