import React, {useEffect, useRef, useState} from 'react';
import {Header, Panel, Title} from 'components/primaries';
import {DataListPage} from 'pages/DataListPage';
import {ZenSmartAPI} from 'utils';
import {manifestInitialDataRoute, manifestReprintRoute} from 'utils/apiRoutes';
import {Button, notification, Select} from 'antd';
import PrintThisComponent from '../../../components/composites/printTicket';
import styled from 'styled-components';
import useApi from '../../../hooks/useApi';

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  `
const ManifestPage = () => {

    const [manifestInitialData, setManifestInitialData] = useState(null)
    const [payload, setPayload] = useState({ code: null, from: null, to: null })
    const [fetchParams, setFetchParams] = useState({
        sort: {
            column:'id',
            ascend: false
        }
    })
    const [fetchData, setFetchData] = useState({ refresh_data: null })
    const {request, loading, errors} = useApi()
    const providerListRef = useRef(null)
    const [providerValue, setProviderValue] = useState(undefined)
    const [showPrintDispatch, setShowPrintDispatch] = useState(false)
    const [printDispatch, setPrintDispatch] = useState(null)

    const getDropDownValues = () => {
        if (!payload.code) {
            notification.error({ message: "Please select provider!" })
            return
        }
        request('post', `/api/v1/logistics-providers/${payload.code}/manifests/initiate`)
            .then((res) => {
                fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                fetchManifestInitialData()

                if(res.data.data.manifest.type !== 'UNACTIONABLE') {
                    window.open(res.data.data.manifest.download_link, '_blank');
                    return;
                } else {
                    notification.success({
                        message: "Manifest successful!"
                    });
                    return;
                }

                if(res.data.data.manifest.type === 'HTML') {
                    setPrintDispatch({
                        label: {
                            type: 'html',
                            content: res.data.data.manifest.content
                        }
                    })
                    setShowPrintDispatch(true)
                    setShowPrintDispatch(false)
                    return;
                }

                printOrDownloadPdf(res.data.data, true)
            });
    }

    const printOrDownloadPdf = (data, ifPrint = false) => {
        const pdfData = data.manifest.content
        if (pdfData === null || pdfData === "") {
            notification.error({ message: "Unable to generate pdf!" })
            return
        }
        if (ifPrint) {
            let byteCharacters = atob(pdfData);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: 'application/pdf;base64' });
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        }
        else {
            const linkSource = `data:application/pdf;base64,${pdfData}`;
            const downloadLink = document.createElement("a");
            const fileName = `manifest-${data.manifest.date}.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
    }


    const reprintManifest = (row, refreshData) => {
        if(row.document_type !== 'UNACTIONABLE') {
            window.open(row.download_link, '_blank');
        }
    }
    const downloadManifest = (row, refreshData) => {
        ZenSmartAPI.get(manifestReprintRoute(row.id))
            .then((res) => {
                printOrDownloadPdf(res.data.data, false);
            })
            .catch((res) => {
                notification.error({ message: res.response.data.message })
            })
    }

    const fetchManifestInitialData = () => {
        ZenSmartAPI.get(manifestInitialDataRoute)
            .then((res) => {
                setManifestInitialData(res.data.data)
            })
            .catch((res) => {

            })
    }

    useEffect(() => {
        fetchManifestInitialData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (manifestInitialData !== null) {
            setProviderValue(undefined);
        }
    }, [manifestInitialData]);

    return (
        <>
            <Header>
                <Title>Pack & Ship {">"} Manifest</Title>
            </Header>
            <Panel title="MANIFEST">
                <DataListPage model="manifest" select={['id', 'document_type', 'manifest_number', 'created_at']} hidden={["id", "download_link", "data_type", "document_type"]} pageSize={50} loadingPage={loading}
                              link={''}
                    setOutSourceFetchData={setFetchData}
                    setOutSourceParameters={setFetchParams}
                    searchFields={['created_at', 'logisticsProvider.name']}
                    addColumns={[
                        {
                            columnSize: 11,
                            htmlValue: null

                        },
                        {
                            columnSize: 4,
                            htmlValue: <Select value={providerValue} placeholder={"Select Providers"} ref={providerListRef} onSelect={(value) => {
                                setProviderValue(value);
                                setPayload({ ...payload, code: JSON.parse(value).code })
                            }}
                                               style={{ width: "100%" }} >
                                {manifestInitialData && manifestInitialData.providers.map(item => {
                                    return <Select.Option value={JSON.stringify(item)}>{item.text}</Select.Option>
                                })

                                }
                            </Select>

                        },
                        {
                            columnSize: 1,
                            htmlValue: <Button onClick={getDropDownValues} loading={loading} type="primary">Generate Manifest</Button>
                        },
                    ]}
                    actionButtons={
                        [
                            {
                                buttonLabel: "Reprint",
                                buttonType: "default",
                                buttonAction: "normal",
                                actionFunction: reprintManifest,
                                reloadData: true
                            }
                        ]
                    }
                />
            </Panel>
            {showPrintDispatch ? <PrintThisComponent url={printDispatch.label.content} type={printDispatch.label.type} /> : null}
        </>
    )
}

export default ManifestPage