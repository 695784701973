import {differenceInMilliseconds} from 'date-fns'

function tokenExpired() {
  const tokenExpiration = localStorage.getItem('token_expiration')

  if (tokenExpiration === null) return true

  const now = new Date()
  const expirationDate = new Date(tokenExpiration)

  return differenceInMilliseconds(now, expirationDate) > -1
}

export default tokenExpired
