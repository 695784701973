import React, {useState, useEffect } from 'react';
import { Header, Title } from 'components/primaries';
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'
import ScanTypeModal from './ScanTypeModal';
import { ZenSmartAPI } from 'utils';
import { notification } from 'antd'
import { getAllStationScanType } from 'utils/apiRoutes';

const Stations = () => {

  const [showScanTypeModal, setShowScanTypeModal] = useState(false);
  const [selectedStation, setSelectedStation] = useState(null);
  const [allScanTypes, setAllScanTypes] = useState(null);

  const fetchAllScanTypes = async () => {
    try {
      const scans = await ZenSmartAPI(getAllStationScanType);
      setAllScanTypes(scans.data.data)
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message ??
        error.message ??
        'Failed to fetch all scan types'
      })
    }
  }

  const manageScanTypes = (row, refreshData) => {
    setSelectedStation(row)
    setShowScanTypeModal(true)
  }

  useEffect(() => {
    fetchAllScanTypes();
  }, []);


  return (
    <>
      <Header>
        <Title>People {">"} Stations</Title>
      </Header>
      <Panel title="STATIONS">
        <DataListPage model="stations" select={['id', 'name', 'created_at', 'updated_at']} link={"app/data-view/stations/"} pageSize={50}
          addListButton={{
            buttonLabel: "Add Stations",
            actionApi: "/api/v1/data-api/stations",
            offset: 15,
            span: 3
          }}
          actionButtons={
            [{
              buttonLabel: "Manage Scan Types",
              buttonType: "primary",
              buttonAction: "normal",
              actionFunction: manageScanTypes,
              reloadData: true
            },
            ]
          }
        />
        <ScanTypeModal
          visible={showScanTypeModal}
          selectedStation={selectedStation}
          allScanTypes={allScanTypes}
          onClose={() => {
            setSelectedStation(null);
            setShowScanTypeModal(!showScanTypeModal);
          }}
        />
      </Panel>
    </>
  )
}

export default Stations