import React from 'react'
import styled from 'styled-components'
import {Droppable, Draggable} from 'react-beautiful-dnd'

import {PROCESS} from 'types'
import {getColorFromType} from 'utils'
import {LibraryItem} from 'components/composites'

const Wrapper = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
`

const dispatchLibrary = [
  {type: PROCESS.TYPE.PACK_TYPE, text: 'Pack Type'},
  {type: PROCESS.TYPE.FIELD_SWITCH, text: 'Field Switch'},
  {type: PROCESS.TYPE.PROVIDER, text: 'Provider'},
]

const DispatchLibrary = () => dispatchLibrary.map((dispatchLibraryItem, index) => {
  return (
    <Droppable
      type={dispatchLibraryItem.type}
      droppableId={dispatchLibraryItem.type}
      key={dispatchLibraryItem.type}
    >
      {provided => (
        <Wrapper
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <Draggable
            draggableId={dispatchLibraryItem.type}
            index={index}
          >
            {provided => (
              <LibraryItem
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                {...dispatchLibraryItem}
                color={getColorFromType(dispatchLibraryItem.type)}
                ref={provided.innerRef}
              />
            )}
          </Draggable>
          {provided.placeholder}
        </Wrapper>
      )}
    </Droppable>
  )
})

export default DispatchLibrary