import React, { useEffect, useRef, useState } from "react";
import { Row, Col, notification } from "antd";
import styled, { css } from "styled-components";
import cloneDeep from 'lodash/cloneDeep';
import { color } from "components/zensmart-design-system/shared/styles.js";
import { Label } from "components/zensmart-design-system/components/Label/Label.js";
import { Panel } from "components/primaries";
import { DashboardItem } from "pages/Performance/partials/composites";
import { ZenSmartAPI } from "utils";
import { GetDateValues } from "./Performance/utils";
import { GetDashboard , GetReportByName, GetSavedReports } from "pages/Performance/api";
import { myWorkToday } from "utils/apiRoutes"
import { useSelector , useDispatch} from "react-redux";
import is from "is_js";
import { DashboardWidget } from "pages/Performance/Widget";
import {  REPORTS } from "store/actions/dashboardTypes";

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;

const HeaderContainer = styled.div`
  padding-bottom: 5px;
  font-family: "Rubik", sans-serif;
`;

const panelStylings = css`
  margin-bottom: 20px;
`;

const panelStylings2 = css`
  height: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledRow = styled(Row)`
  margin-left: 16px;
  margin-top: 30px;
  margin-bottom: 24px;
`;

const Divider = styled(Col)`
  height: 200px;
  border-left: 1px solid #cfd9f9;
  opacity: 0.6;
`;

const HeaderLabel = styled(Label)`
  margin-bottom: 15px;
`;

const TextLabel = styled.div`
  color: #b0bac9;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
`;

const TextValue = styled.div`
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  margin-bottom: 15px;
`;

const Chart = styled.div`
  margin-top: 30px;
  margin-bottom: 24px;
  position: relative;
  width: 50%;
`;

function Home() {
  const [workToday, setWorkToday] = useState(null);
  const [myPerformance, setMyPerformance] = useState(null);
  const [myTeamPerformance, setMyTeamPerformance] = useState(null);
  const user = useSelector((state) => state.user);
  const myPerformanceRef = useRef();
  const myTeamPerformanceRef = useRef();
  const loadingMsg = "Loading...";
  const legendLabels = ["Date", "Units Scanned"];
  const [viewMapping, setViewMapping] = useState([]);
  const [viewLayout, setViewLayout] = useState([]);
  const [dashboard, setDashboard] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if(user.details?.dashboard_id){
      setDashboard(user.details?.dashboard_id)
    }else{
      GetReportByName({ reports: ["MY PERFORMANCE"] }).then(({ data }) => {
        const modifyReport = cloneDeep(data.reports[0]);
        modifyReport.primary_filter.forEach((p_filter) => {
          if (
              (p_filter.type === "timestamp" || p_filter.type === "date") &&
              p_filter.value &&
              p_filter.value.length > 0 &&
              p_filter.value[0] !== "custom" &&
              p_filter.value[0] !== null &&
              Array.isArray(p_filter.value)
          ) {
            p_filter.from =
                GetDateValues(p_filter.value[0])[0].format("YYYY-MM-DD") +
                " 00:00:00";
            p_filter.to =
                GetDateValues(p_filter.value[0])[1].format("YYYY-MM-DD") +
                " 23:59:59";
          }
        });
        setMyPerformance(modifyReport);
  
        const teamReport = cloneDeep(modifyReport);
        teamReport.name = "MY TEAM PERFORMANCE";
        setMyTeamPerformance(teamReport);
      }).catch((err) => {
        notification.error({
          message: "Error loading home page"
        });
      });
    }
    
    ZenSmartAPI.get(myWorkToday).then(({data}) => {
      setWorkToday(data.data);
    }).catch((err) => {
      notification.error({
        message: "Error loading my work today"
      });
    });
  }, []);

  useEffect(() => {  
    if(user.details?.dashboard_id && dashboard === null){
      setDashboard(user.details?.dashboard_id)
      updateDashboard(user.details?.dashboard_id)
    }
  });

  function updateReports(reportsObj) {
    dispatch({
      type: REPORTS,
      payload: reportsObj,
    });
  }


  function updateDashboard(dashboard) {
    
    GetSavedReports().then(({ data }) => {
      updateReports(data);
      GetDashboard(dashboard)
        .then(({ data }) => {
          if (is.existy(data)) {
            let layout = [];
            let mapping = [];
            data.dashboards[0].dashboard_reports.forEach((d) => {
              layout = [...layout, d.layout];
              mapping = [
                ...mapping,
                { layout_id: d.layout.i, report_id: d.report_id },
              ];
            });
            setViewMapping(mapping);
            setViewLayout(layout);
          } else {
            notification.error({
              message: "Error loading home page"
            });
          }
        })
    });
  };
  return user.authenticated && user.details ? (
    <HeaderContainer>
      <Header>
        <Title>Home</Title>
      </Header>
      <Panel title="MY WORK TODAY" stylings={panelStylings}>
        <StyledRow>
          <Col span={4}>
            <HeaderLabel status="red">OVERDUE</HeaderLabel>
            <TextLabel>1 DAY OVERDUE</TextLabel>
            <TextValue>{is.existy(workToday) ? workToday.overdue.one_day_overdue : loadingMsg}</TextValue>
            <TextLabel>2 DAYS OVERDUE</TextLabel>
            <TextValue>{is.existy(workToday) ? workToday.overdue.two_day_overdue: loadingMsg}</TextValue>
            <TextLabel>3 DAYS OVERDUE</TextLabel>
            <TextValue>{is.existy(workToday) ? workToday.overdue.three_day_overdue : loadingMsg}</TextValue>
          </Col>
          <Col span={4}>
            <HeaderLabel status="blue">DUE TODAY</HeaderLabel>
            <TextLabel>VIP</TextLabel>
            <TextValue>{is.existy(workToday) ? workToday.due_today.vip : loadingMsg}</TextValue>
            <TextLabel>OTHER</TextLabel>
            <TextValue>{is.existy(workToday) ? workToday.due_today.other : loadingMsg}</TextValue>
          </Col>
          <Col span={3}>
            <HeaderLabel status="purple">TARGET</HeaderLabel>
            <TextLabel>TODAY TARGET</TextLabel>
            <TextValue>{is.existy(workToday) ? workToday.target.today_target : loadingMsg}</TextValue>
            <TextLabel>TO GO</TextLabel>
            <TextValue>{is.existy(workToday) ? workToday.target.to_go : loadingMsg}</TextValue>
          </Col>
          <Divider span={1} />
          <Col span={6}>
            <HeaderLabel status="mountain_meadow">DUE TOMORROW</HeaderLabel>
            <TextLabel>VIP</TextLabel>
            <TextValue>{is.existy(workToday) ? workToday.due_tomorrow.vip : loadingMsg}</TextValue>
            <TextLabel>OTHER</TextLabel>
            <TextValue>{is.existy(workToday) ? workToday.due_tomorrow.other : loadingMsg}</TextValue>
          </Col>
        </StyledRow>
      </Panel>
      {dashboard ? <div
              style={{
                  display: "flex",
                  height: window.screen.height - 300,
              }}
          >
              <DashboardWidget
                  keyValue={dashboard}
                  refreshData={true}
                  dashboardMapping={viewMapping}
                  dashboardLayout={viewLayout}
                  mode={"preview"}
                  homePage={true}
              />
          </div>
        :
          <>
              {myPerformance && (
                  <Panel stylings={panelStylings2} ref={myPerformanceRef.current}>
                      <Chart>
                          <DashboardItem
                              report={myPerformance}
                              sidebar={false}
                              parentRef={myPerformanceRef}
                              currentUser={{
                                  "table": "scan_logs",
                                  "category": "scan_logs",
                                  "field": "created_by",
                                  "path": "created_by",
                                  "value": [user.details.id],
                                  "operation": "equals"
                              }}
                              legendLabels={legendLabels}
                          />
                      </Chart>
                  </Panel>
              )}
              {myTeamPerformance && (
                  <Panel stylings={panelStylings2} ref={myTeamPerformanceRef.current}>
                      <Chart>
                          <DashboardItem
                              report={myTeamPerformance}
                              sidebar={false}
                              parentRef={myTeamPerformanceRef}
                              legendLabels={legendLabels}
                          />
                      </Chart>
                  </Panel>
              )}
          </>
      }
    </HeaderContainer>
  ) : null;
}

export default Home;
