import React from 'react';
import { Breadcrumb } from 'antd'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { addBreadcrumb, deleteBreadcrumbs } from 'store/actions/breadcrumb';

const StyledBreadcrumb = styled(Breadcrumb)`
  font-family: 'Rubik',sans-serif;
  font-size: 20px;
  color: #2E384D;
  font-weight: normal;
  margin-bottom: 24px;

  .ant-breadcrumb a,
  .ant-breadcrumb-link,
  span:last-child a {
    color: #2E384D;
  }
`

const SearchBreadCrumbs = (props) => {
    const history = useHistory();
    const location = useLocation();
    const breadcrumbs = useSelector(state => state.breadcrumbs);
    const dispatch = useDispatch();
  
    if(props.details) {
      const breadcrumb = {
        id: breadcrumbs.length,
        name: `${props.details.resource}`,
        resource_id: `${props.details.id}`,
        link: `/app/data-view/${props.details.resource_name}/${props.details.id}`
      }
    
      if(breadcrumbs.length > 0) {
        const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
        if(lastBreadcrumb.link !==  `/app/data-view/${props.details.resource_name}/${props.details.id}` && location.ignoreLink !== true) {
          dispatch(addBreadcrumb(breadcrumb))
        }
      } else {
        dispatch(addBreadcrumb(breadcrumb))
      }  
    }

    const goToHomepage = e => {
      e.preventDefault();
      history.push('/app');
    }

    const goToBreadcrumbLink = (e, item) => {
      e.preventDefault();
      history.push({
        pathname: item.link,
        ignoreLink: true
      });
      dispatch(deleteBreadcrumbs(item.id))
    }

    const capitalizeBreadcrumbName = name => {
      return name.split(' ').map(str => str.charAt(0) + str.slice(1).toLowerCase()).join(' ');
    }
     
    return (
      <StyledBreadcrumb separator=">">
          <Breadcrumb.Item href={'/app'} onClick={ goToHomepage }>Home</Breadcrumb.Item>
          { breadcrumbs.map(item => 
              <Breadcrumb.Item href={ item.link } onClick={ e => goToBreadcrumbLink(e, item) }>{ capitalizeBreadcrumbName(item.name) }</Breadcrumb.Item>
            )
          }
      </StyledBreadcrumb>
    )
}

export default SearchBreadCrumbs;