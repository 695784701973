import React from "react";
import {Header, Title} from "../partials/primaries";
import { Panel } from "components/zensmart-design-system";
import styled from "styled-components";

const BillingFrame = styled.iframe`
  width: 100%;
  height: 500px;
`;

function Billing() {
    return (
        <>
            <Header>
                <Title>
                    Performance {">"} Billing
                </Title>
            </Header>
            <Panel active={true} height="100%" width="100%">
                <BillingFrame src={`${process.env.REACT_APP_BILLING_SERVER}`}/>
            </Panel>
        </>
    );
}

export default Billing;

