import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Row, Col } from 'antd'
import { panelSortData } from 'utils/sortData'
import moment from 'moment';
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { cellActivityInitialData } from "utils/panelsInitialData"

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

`
const TableData = (props) => {

    const { itemData, loading, data, setData, ascend, setAscend } = props

    const sortingData = (data, property, ifNotProperty) => {
        if (ascend[property] === null) {
            setAscend({ ...cellActivityInitialData, [property]: true })
        }
        else {
            ascend[property] === true ? setAscend({ ...cellActivityInitialData, [property]: false }) : setAscend({ ...cellActivityInitialData, [property]: true })
        }
        setData(panelSortData(data, property, ascend[property], ifNotProperty))
    }
    const getHeaders = (property, text, ifAscend) => {
        return (
            <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifAscend)} style={{ cursor: "pointer" }}>
                <Col span={ascend[property] === null ? 24 : 21}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }

    const columns = [
        {
            title: getHeaders("operator", "OPERATOR", false),
            dataIndex: 'operator',
            key: 'operator',
            render(value, row, index) {
                return rowStyle(value !== null ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("units", "UNITS", false),
            dataIndex: 'units',
            key: 'units',
            render(value, row, index) {
                return rowStyle(value !== null ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("hrs_at_stage", "HOURS AT STAGE", false),
            dataIndex: 'hrs_at_stage',
            key: 'hrs_at_stage',
            render(value, row, index) {
                return rowStyle(value !== null ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("avg_units_per_hr", "AVG UNITS PER HOUR", false),
            dataIndex: 'avg_units_per_hr',
            key: 'avg_units_per_hr',
            render(value, row, index) {
                return rowStyle(value !== null ? value : "-", index, row)
            },
        },

    ];

    const rowStyle = (value, index, row) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }
        if ((data.length - 1) === index) {
            obj.props.style.fontWeight = "bold"
        }

        return obj
    }
    useEffect(() => {

        if (data.length > 0) {

            const itemObj = itemData.map(data => {
                const obj = {
                    operator: data.operator,
                    units: data.units,
                    hrs_at_stage: data.hrs_at_stage,
                    avg_units_per_hr: data.avg_units_per_hr,

                }
                return obj;
            })

            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "50vh" }}
                    pagination={false}

                />
            </StyleTable>
        </>
    )
}

export default TableData