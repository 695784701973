import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Tag, Row, Col, Checkbox } from 'antd'
import { panelSortData } from 'utils/sortData'
import moment from 'moment';
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { wipInitialData } from "utils/panelsInitialData"

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const DueDateTag = styled(Tag)`
    padding : 5px 17px;
    font-size : 12px;
`

const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

.selected-row > td {
    background: none !important;
}
.selected-row {
   background: rgba(11, 107, 242, 0.5) !important;
}
`
const dueDateColor = {
    later: "purple",
    today: "blue",
    tomorrow: "green"
}
const TableData = (props) => {

    const { itemData, loading, data, setData, ascend, setAscend, selectedCheckBoxValue, paginationNumber, setSelectedCheckBoxValue, paginationSize, checkPaginationIfAllSelected } = props
    const [clickedRecords, setClickedRecords] = useState({index: null, id: null})

    const sortingData = (data, property, ifNotProperty) => {
        if (ascend[property] === null) {
            setAscend({ ...wipInitialData, [property]: true })
        }
        else {
            ascend[property] === true ? setAscend({ ...wipInitialData, [property]: false }) : setAscend({ ...wipInitialData, [property]: true })
        }

        setData(panelSortData(data, property, ascend[property], ifNotProperty))
        checkPaginationIfAllSelected(paginationNumber)
    }

    const getHeaders = (property, text, ifAscend) => {
        return (
            <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifAscend)} style={{ cursor: "pointer" }}>
                <Col span={ascend[property] === null ? 24 : 20}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }

    const applySelectedCss = (record, index) => {
        var elements = document.querySelectorAll(`[data-row-key="${index}"]`)[0]
        if (elements) {

            if (selectedCheckBoxValue[record]) {
                elements.classList.add("selected-row");
            }
            else {
                elements.classList.remove("selected-row");
            }
        }
    }


    const selectValue = (value, record, index) => {

        if (value.target.checked) {
            setSelectedCheckBoxValue({ ...selectedCheckBoxValue, [record]: true })

        }
        else {
            setSelectedCheckBoxValue({ ...selectedCheckBoxValue, [record]: false })

        }
    }

    const handleCheckBoxClick = (e, record, index) => {
        const value = e.target.checked
        if (e.nativeEvent.shiftKey) {
            let selectedCheckBoxes = {...selectedCheckBoxValue, [record]: value, [clickedRecords.id]: value}
            if ((clickedRecords.index !== null && clickedRecords.index !== index)) {
                const startPointIndex = clickedRecords.index < index ? clickedRecords.index : index
                const loopTimesIndex = clickedRecords.index < index ? index - clickedRecords.index : clickedRecords.index - index
                for (let i = startPointIndex; i < startPointIndex+loopTimesIndex; i++) {
                    const rowExist = data.filter((item, index) => index === i)
                    if (rowExist.length > 0) {
                        selectedCheckBoxes = {...selectedCheckBoxes, [rowExist[0].id]: value}
                    }
                }
            }
            setSelectedCheckBoxValue({...selectedCheckBoxes})
        } 
        else {
            selectValue(e, record, index)
        }
        setClickedRecords({index: index, id: record})
    }

    const columns = [
        {
            title: '',
            dataIndex: 'check_box',
            key: 'check_box',
            width: 50,
            render: (record, row, index) => {
                applySelectedCss(row.id, index)
                const value = <Checkbox
                    checked={selectedCheckBoxValue[row.id]}
                    onChange={(e) => handleCheckBoxClick(e, row.id, index)}
                />
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("vendor_name", "VENDOR", false),
            dataIndex: 'vendor_name',
            key: 'vendor_name',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("transaction", "TRANSACTION", true),
            dataIndex: 'transaction',
            key: 'transaction',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: row.due_date && row.due_date.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("order_number", "ORDER", true),
            dataIndex: 'order_number',
            key: 'order_number',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: row.due_date && row.due_date.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("line_id", "LINE", true),
            dataIndex: 'line_id',
            key: 'line_id',

            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: row.due_date && row.due_date.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("block", "BLOCK", true),
            dataIndex: 'block',
            key: 'block',

            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: row.due_date && row.due_date.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("box_number", "BOX NUMBER", false),
            dataIndex: 'box_number',
            key: 'box_number',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("dispatch_method_code", "METHOD", false),
            dataIndex: 'dispatch_method_code',
            key: 'dispatch_method_code',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("code", "PRODUCT", true),
            dataIndex: 'code',
            key: 'code',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: row.due_date && row.due_date.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("lamination_code", "LAMINATION", false),
            dataIndex: 'lamination_code',
            key: 'lamination_code',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("due_date", "DUE DATE", false),
            dataIndex: 'due_date',
            key: 'due_date',
            render(record, row, index) {
                const value = <DueDateTag color={dueDateColor[record && record.toLowerCase()] ?
                    dueDateColor[record && record.toLowerCase()] : "red"}
                    key={record}>
                    {record && record.toUpperCase()}
                </DueDateTag>
                return rowStyle(record ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("stage", "STAGE", false),
            dataIndex: 'stage',
            key: 'stage',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("stage_time", "STAGE TIME", false),
            dataIndex: 'stage_time',
            key: 'stage_time',
            render(value, row, index) {
                const dataValue = moment(value).format("D MMM YYYY, h:mma")
                return rowStyle(value ? dataValue : "-", index, row)
            },
        },
        {
            title: getHeaders("hrs_at_stage", "HOURS AT STAGE", false),
            dataIndex: 'hrs_at_stage',
            key: 'hrs_at_stage',
            render(value, row, index) {
                return rowStyle(value ? value : 0, index, row)
            },
        },
        {
            title: getHeaders("collation", "COLLATION TYPE", false),
            dataIndex: 'collation',
            key: 'collation',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },

    ];

    const rowStyle = (value, index, row) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }

        if (row.due_date && row.due_date.toLowerCase() === "overdue") {
            obj.props.style.background = "#faeeed"
        }

        return obj
    }

    useEffect(() => {

        if (itemData) {

            const itemObj = itemData.map(data => {
                const obj = {
                    check_box: data.order_number,
                    id: data.id,
                    vendor_name: data.vendor_name,
                    transaction: data.transaction,
                    order_number: data.order_number,
                    line_id: data.line_id,
                    block: data.block,
                    box_number: data.box_number,
                    dispatch_method_code: data.dispatch_method_code,
                    code: data.code,
                    lamination_code : data.lamination_code,
                    due_date: data.due_date,
                    stage: data.stage,
                    stage_time: data.stage_time,
                    hrs_at_stage: data.hrs_at_stage,
                    collation: data.collation
                }
                return obj;
            })

            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table

                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "45vh" }}
                    pagination={{ pageSize: paginationSize, onChange: (e) => checkPaginationIfAllSelected(e) }}

                />
            </StyleTable>
        </>
    )
}

export default TableData