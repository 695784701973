import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input, Modal, Form, Spin } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import {
    getSingleWorkDataRoute,
} from "utils/apiRoutes"

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
  padding: 10px;

`

function EditForm(props) {
    const {
        form,
        textDetails,
        coverDetails,
        initialData,
        showModal,
        refreshState,
        updateWorkFlow,
        setLoading,
        getSingleWorkFlow,
        loading,
        blockTypes,
        singleWorkFlowDetails

    } = props

    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields
    } = form

    const [errors, setErrors] = useState({})

    const handleSubmit = e => {
        e.preventDefault()
        validateFields((err, values) => {
            const payload = getFieldsValue()
            // if (!payload.cover) {
            //     payload.cover = []
            // }
            const lastTextItem = payload.text ? payload.text[payload.text.length - 1] : null
            const lastCoverItem = payload.cover ? payload.cover[payload.cover.length - 1] : null
            var checkTextDuplicate = false
            var checkCoverDuplicate = false
            var ifErrors = false
            // check if there are duplicates in text block

            if (payload.text) {
                payload.text.map(item => {
                    const checkDuplicate = payload.text.filter(itemTwo => {
                        if (itemTwo.scan_type_id == item.scan_type_id &&
                            itemTwo.location_id == item.location_id &&
                            itemTwo.part_id == item.part_id
                        ) {
                            return true
                        }
                        return false
                    }
                    )
                    if (checkDuplicate.length > 1) {
                        checkTextDuplicate = true
                    }
                    return
                })
            }
            // check if there are duplicates in cover block
            if (payload.cover) {
                payload.cover.map(item => {
                    const checkDuplicate = payload.cover.filter(itemTwo => {

                        if (itemTwo.scan_type_id == item.scan_type_id &&
                            itemTwo.location_id == item.location_id &&
                            itemTwo.part_id == item.part_id
                        ) {
                            return true
                        }
                        return false
                    }
                    )
                    if (checkDuplicate.length > 1) {
                        checkCoverDuplicate = true
                    }
                    return
                })
            }

            if (checkTextDuplicate || checkCoverDuplicate) {
                notification.error({ message: "No duplicate values are allowed!" })
                ifErrors = true
            }
            if (lastTextItem && !lastTextItem.scan_type_id && !lastTextItem.location_id && !lastTextItem.amount && !lastTextItem.part_id) {
                payload.text.pop()
            }
            else if (lastTextItem && (!lastTextItem.scan_type_id || !lastTextItem.location_id || !lastTextItem.amount || !lastTextItem.part_id)) {
                notification.error({ message: "Please input all fields when adding new value" })
                ifErrors = true
            }
            if (lastCoverItem && !lastCoverItem.scan_type_id && !lastCoverItem.location_id && !lastCoverItem.amount && !lastCoverItem.part_id) {
                payload.cover.pop()
            }
            else if (lastCoverItem && (!lastCoverItem.scan_type_id || !lastCoverItem.location_id || !lastCoverItem.amount || !lastCoverItem.part_id)) {
                notification.error({ message: "Please input all fields when adding new value" })
                ifErrors = true
            }
            if (ifErrors) {
                return
            }
            const mergePayload = payload.text.concat(payload.cover).filter(item => item)

            setLoading(true)
            ZenSmartAPI.post(getSingleWorkDataRoute(updateWorkFlow), mergePayload)
                .then((res) => {
                    setLoading(false)
                    notification.success({ message: "Update Workflow successful" })
                    getSingleWorkFlow()
                }).catch((res) => {
                    notification.error({
                        message: _.get(res, 'response.data.message'),
                    });
                    setLoading(false)
                })
        });
    }

    const renderForms = (item, key, type) => {
        return <Row type="flex" style={{ paddingLeft: 10, marginTop: "-20px" }} >
            {item.id && <Form.Item style={{ display: "none" }}
            >
                {getFieldDecorator(`${type}[${key}].id`, {
                    initialValue: item.id,
                })(
                    <Input />
                )}
            </Form.Item>
            }
            <Form.Item style={{ display: "none" }}
            >
                {getFieldDecorator(`${type}[${key}].block`, {
                    initialValue: item.block ? item.block : type.toUpperCase(),
                })(
                    <Input />
                )}
            </Form.Item>
            <Col span={5} style={{ padding: 5, paddingTop: "-20px" }}>
                <Form.Item
                >
                    {getFieldDecorator(`${type}[${key}].scan_type_id`, {
                        initialValue: item.scan_type_id,
                    })(
                        <Select placeholder={"Please select location"} style={{ width: "100%" }} >
                            {singleWorkFlowDetails.find(item => item.block_type === type) && singleWorkFlowDetails.find(item => item.block_type === type).scan_types.map(item => {
                                return <Select.Option style={{ textTransform: "capitalize" }}
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.text}
                                </Select.Option>
                            })}
                        </Select>
                    )}
                </Form.Item>

            </Col>
            <Col span={9} style={{ padding: 5 }}>
                <Form.Item
                >
                    {getFieldDecorator(`${type}[${key}].part_id`, {
                        initialValue: item.part_id,
                    })(
                        <Select placeholder={"Please select location"} style={{ width: "100%" }} >
                            {initialData.parts.map(item => {
                                return <Select.Option style={{ textTransform: "capitalize" }}
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.code} - {item.description}
                                </Select.Option>
                            })}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={5} style={{ padding: 5 }}>
                <Form.Item
                >
                    {getFieldDecorator(`${type}[${key}].location_id`, {
                        initialValue: item.location_id,
                    })(
                        <Select placeholder={"Please select location"} style={{ width: "100%" }} >
                            {initialData.locations.map(item => {
                                return <Select.Option style={{ textTransform: "capitalize" }}
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.warehouse} / {item.bin}
                                </Select.Option>
                            })}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={5} style={{ padding: 5 }}>
                <Form.Item
                >
                    {getFieldDecorator(`${type}[${key}].amount`, {
                        initialValue: item.amount,
                    })(
                        <Input type="number" />,
                    )}
                </Form.Item>
            </Col>
        </Row>
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>

                <Modal title={`Edit workflow`} visible={showModal} width={1200}
                    footer={[
                        <Button key="back" onClick={() => refreshState()}>
                            Close
                        </Button>,
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={handleSubmit}
                        >
                            Save Changes
                        </Button>
                    ]}
                >
                    <Spin tip="Loading..." spinning={loading}>
                        {blockTypes && blockTypes.some(type => type.toLowerCase() === "text") &&
                            <Row type="flex" align="middle">
                                <Col span={24} style={{ padding: 10 }} >

                                    <Heading>Text</Heading>
                                    <hr />

                                    <div style={{
                                        height: 300,
                                        overflowY: "auto",
                                        position: "relative"
                                    }}>
                                        <Row type="flex" align="middle" style={{ marginBottom: 20 }} >
                                            <Col span={5} style={{ padding: 5 }}>
                                                <Heading>Stage</Heading>
                                            </Col>
                                            <Col span={9} style={{ padding: 5 }}>
                                                <Heading>Part</Heading>
                                            </Col>
                                            <Col span={5} style={{ padding: 5 }}>
                                                <Heading>Location</Heading>
                                            </Col>
                                            <Col span={5} style={{ padding: 5 }}>
                                                <Heading>Amount</Heading>
                                            </Col>
                                        </Row>
                                        {textDetails && textDetails.data && textDetails.data.length > 0 && textDetails.data.map((item, key) => {
                                            return renderForms(item, key, "text")
                                        })}
                                        {showModal && renderForms({}, (textDetails && textDetails.data ? textDetails.data.length : 0) + 1, "text")}
                                    </div>
                                </Col>
                            </Row>
                        }
                        {blockTypes && blockTypes.some(type => type.toLowerCase() === "cover") &&
                            <Row type="flex" align="middle">
                                <Col span={24} style={{ padding: 10 }} >

                                    <Heading>Cover</Heading>
                                    <hr />

                                    <div style={{
                                        height: 300,
                                        overflowY: "auto",
                                        position: "relative"
                                    }}>
                                        <Row type="flex" align="middle" style={{ marginBottom: 20 }} >
                                            <Col span={5} style={{ padding: 5 }}>
                                                <Heading>Stage</Heading>
                                            </Col>
                                            <Col span={9} style={{ padding: 5 }}>
                                                <Heading>Part</Heading>
                                            </Col>
                                            <Col span={5} style={{ padding: 5 }}>
                                                <Heading>Location</Heading>
                                            </Col>
                                            <Col span={5} style={{ padding: 5 }}>
                                                <Heading>Amount</Heading>
                                            </Col>
                                        </Row>
                                        {coverDetails && coverDetails.data && coverDetails.data.length > 0 && coverDetails.data.map((item, key) => {
                                            return renderForms(item, key, "cover")
                                        })}
                                        {showModal && renderForms({}, (coverDetails && coverDetails.data ? coverDetails.data.length : 0) + 1, "cover")}

                                    </div>
                                </Col>
                            </Row>
                        }
                    </ Spin>
                </Modal>
            </Form>
        </>
    )
}

const EditUpdateWorkflowForm = Form.create({
})(EditForm)

const UpdateWorkFlowModal = (props) => {
    const { showModal, setShowModal, updateWorkFlow, setUpdateWorkFlow, fetchInitData, initialData } = props
    const [loading, setLoading] = useState(false)
    const [singleWorkFlowDetails, setWorkFlowDetails] = useState([])
    const [textDetails, setTextDetails] = useState([])
    const [coverDetails, setCoverDetails] = useState([])
    const [blockTypes, setBlockTypes] = useState([])
    const fetchSingleWorkflowData = () => {
        setLoading(true)
        getSingleWorkFlow()
    }

    const getSingleWorkFlow = () => {
        setLoading(true)
        setTextDetails([])
        setCoverDetails([])
        ZenSmartAPI.get(getSingleWorkDataRoute(updateWorkFlow))
            .then((res) => {
                setLoading(false)
                setWorkFlowDetails(res.data)
                setBlockTypes(res.data.map(item => item.block_type))
            }).catch((res) => {

            })
    }

    const refreshState = () => {
        setShowModal(false)
        setTextDetails([])
        setCoverDetails([])
    }

    useEffect(() => {
        if (showModal && updateWorkFlow) {
            fetchSingleWorkflowData()
        }
    }, [showModal, updateWorkFlow]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setTextDetails(singleWorkFlowDetails.find(data => data.block_type.toLowerCase() === "text"))
        setCoverDetails(singleWorkFlowDetails.find(data => data.block_type.toLowerCase() === "cover"))
    }, [singleWorkFlowDetails]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>

            <EditUpdateWorkflowForm
                getSingleWorkFlow={getSingleWorkFlow}
                setLoading={setLoading}
                textDetails={textDetails}
                showModal={showModal}
                refreshState={refreshState}
                updateWorkFlow={updateWorkFlow}
                initialData={initialData}
                coverDetails={coverDetails}
                loading={loading}
                blockTypes={blockTypes}
                singleWorkFlowDetails={singleWorkFlowDetails}
            />

        </>
    )
}
export default UpdateWorkFlowModal