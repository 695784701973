import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Input, Checkbox, notification } from 'antd';
import { ZenSmartAPI } from 'utils'
function CurrencyForm({ form, showModal, setShowModal, modalAction, setModalAction, initialValues, setInitialValues, fetchData, fetchParams, corporateCurrency, setCorporateCurrency }) {
  const { getFieldDecorator, getFieldsValue, getFieldValue, validateFields } = form
  const [loading, setLoading] = useState(false)
  const getModalTitle = () => {
    return modalAction && modalAction === 'new' ? 'Add a currency' : modalAction === 'edit' ? 'Update currency' : 'Invalid action'
  }
  const handleCancel = () => {
    setShowModal(false)
    setInitialValues({})
    setModalAction('')
    setCorporateCurrency(null)
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const payload = getFieldsValue();
        setLoading(true);
        if (modalAction === 'new') {
          ZenSmartAPI.post('/api/v1/currencies', payload)
            .then(res => {
              setLoading(false)
              notification.success({ message: `Successfully added currency ${payload.currency_code}` })
              fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
              handleCancel()
            }).catch(err => {
              setLoading(false)
              notification.error({ message: 'Could not create new currency!' })
            })
        } else if (modalAction === 'edit') {
          ZenSmartAPI.patch(`/api/v1/currencies/${initialValues.id}`, payload)
            .then(res => {
              setLoading(false)
              notification.success({ message: 'Successfully updated currency!' })
              fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
              handleCancel()
            }).catch(err => {
              setLoading(false)
              notification.error({ message: 'Could not update currency!' })
            })
        } else {
          setLoading(false)
          notification.error({ message: 'Invalid action!' })
        }
      } else {
        notification.error({ message: 'Invalid form data!' })
      }
    });
  }
  return (
    <Modal
      visible={showModal}
      title={getModalTitle()}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
          Submit
        </Button>
      ]}
    >
      {showModal && (
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label="Currency Name">
            {
              getFieldDecorator('currency_name', {
                rules: [{
                  required: true,
                  message: 'Please enter a currency name!'
                }],
                initialValue: initialValues.currency_name ? initialValues.currency_name : undefined
              })
                (
                  <Input placeholder={'Enter a currency name'} />
                )
            }
          </Form.Item>
          <Form.Item label="Currency Code">
            {
              getFieldDecorator('currency_code', {
                normalize: (input) => input?.toUpperCase(),
                rules: [{
                  required: true,
                  min: 3,
                  max: 3,
                  message: 'Please enter a valid currency code!'
                }],
                initialValue: initialValues.currency_code ? initialValues.currency_code : undefined
              })
                (
                  <Input placeholder={'Enter a currency code'} maxLength={3} />
                )
            }
          </Form.Item>
          <Form.Item label="Is Corporate?">
            {
              getFieldDecorator('corporate', {
                initialValue: initialValues.corporate === true ? initialValues.corporate : false,
                valuePropName: 'checked'
              })
                (
                  <Checkbox />
                )
            }
          </Form.Item>
          <Form.Item label={`Exchange Rate ${(corporateCurrency && !getFieldValue('corporate'))? `(Relative to the ${corporateCurrency.currency_name} - ${corporateCurrency.currency_code})` : ''}`}>
            {
              getFieldDecorator('conversion_rate', {
                rules: [{
                  required: true,
                  pattern: new RegExp(/^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/),
                  message: 'Please enter a valid exchange rate!'
                }],
                initialValue: initialValues.conversion_rate ? initialValues.conversion_rate : getFieldValue('corporate') ? 1 : undefined
              })
                (
                  <Input disabled={getFieldValue('corporate')} placeholder={'Enter exhchange rate relative to the corporate currency'} />
                )
            }
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}
const CurrencyModalForm = Form.create({})(CurrencyForm)
const CurrencyModal = ({ showModal, setShowModal, modalAction, setModalAction, initialValues, setInitialValues, fetchData, fetchParams, corporateCurrency, setCorporateCurrency }) => {
  return <CurrencyModalForm
    showModal={showModal}
    setShowModal={setShowModal}
    modalAction={modalAction}
    setModalAction={setModalAction}
    initialValues={initialValues}
    setInitialValues={setInitialValues}
    corporateCurrency={corporateCurrency}
    setCorporateCurrency={setCorporateCurrency}
    fetchData={fetchData}
    fetchParams={fetchParams}
  />
}
export default CurrencyModal