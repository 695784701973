import React, { useState, useEffect } from 'react'
import { Modal, Table, Dropdown, Button, Menu, notification } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { printOrDeleteSingleSubmissionRoute, printOrDeleteMasterSubmissionRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils';
import { errorHandler } from 'utils/errorMessageHandler';
import * as _ from 'lodash';

function SubmissionDetailsModal(props) {
  const { showSubmissionsModal, setShowSubmissionsModal, masterSubmissionValue, updateSubmissionDetails, printerData } = props
  const submissionsDetail = useSelector(state => state.controlTower.submission_details)
  const [submissionSource, setSubmissionSource] = useState([])
  const COLUMNS = [
    {
      title: 'Submission ID',
      dataIndex: 'id',
      key: 'id',
      render(value, row, index) {
        return <p style={{textAlign: "center"}}><a href={'/app/data-view/submissions/' + row.actions.id} style={{ color: "blue", textDecorationLine: "underline" }}>{row.actions.id}</a></p>
      }
    },
    {
      key: 'batchNo',
      dataIndex: 'batchNo',
      title: 'Batch No.',
      render(value, row, index) {
        return <p style={{textAlign: "center"}}><a href={'/app/data-view/batch_numbers/' + row.batchId} style={{ color: "blue", textDecorationLine: "underline" }}>{row.batchNo[0]}</a></p>
      }
    },

    {
      key: 'productCode',
      dataIndex: 'productCode',
      title: 'Product Code',
      render(value, row, index) {
        return <p style={{textAlign: "center"}}><a href={'/app/data-view/products/' + row.actions.productDefinition.id} style={{ color: "blue", textDecorationLine: "underline" }}>{row.productCode}</a></p>
      }
    },

    {
      key: 'block',
      dataIndex: 'block',
      title: 'Block'
    },

    {
      key: 'autoprint',
      dataIndex: 'autoprint',
      title: 'Auto Print?'
    },

    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status'
    },

    {
      key: 'sendTime',
      dataIndex: 'sendTime',
      title: 'Send'
    },

    {
      key: 'finishTime',
      dataIndex: 'finishTime',
      title: 'Finish'
    },

    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: data => (
        <>
          <Dropdown overlay={<Menu>
            <Menu.Item onClick={() => submissionAction(data, "delete")}>
              <p target="_blank" rel="noopener noreferrer" >
                Delete
              </p>
            </Menu.Item>
            <Menu.Item onClick={() => submissionAction(data, "print")}>
              <p target="_blank" rel="noopener noreferrer" >
                Print
              </p>
            </Menu.Item>

          </Menu>} placement="bottomLeft" arrow>
            <Button style={{ backgroundColor: "green", color: "white" }} >Actions</Button>
          </Dropdown>
        </>
      )
    }
  ]


  const submissionAction = (submission, action) => {
    const payload = { submission_id: submission.id, action }

    if (action === "print") {
      const findPrinter = printerData.data.find(data => {
        return data.full_name === submission.printer
      })
      if (!findPrinter.machine_ready || !findPrinter.server_ready || !findPrinter.is_ready) {
        notification.error({
          message: "Printer is not yet ready!"
        })
        return;
      }
    }

    ZenSmartAPI.post(printOrDeleteSingleSubmissionRoute, payload)
      .then((res) => {
        notification.success({
          message: "Update submission success!"
        })
        updateSubmissionDetails(masterSubmissionValue.master_id);
      })
      .catch((res) => {
        updateSubmissionDetails(masterSubmissionValue.master_id);
        if (_.get(res, 'response.status') === 422) {
          notification.error({
            message: errorHandler(_.get(res, 'response.data.errors'))
          })
          return
        }
        else if (_.get(res, 'response.data.message')) {
          notification.error({
            message: _.get(res, 'response.data.message')
          })
          return
        }
        notification.error({
          message: "Unable to update submission!"
        })
      })

  }
  const submissionAllAction = (master_submission, action) => {

    const payload = { master_submission: master_submission.master_id, action }

    if (action === "print") {
      const findPrinter = printerData.data.find(data => {
        return data.id === master_submission.printer_id
      })
      if (!findPrinter.machine_ready || !findPrinter.server_ready || !findPrinter.is_ready) {
        notification.error({
          message: "Printer is not yet ready!"
        })
        return;
      }
    }

    ZenSmartAPI.post(printOrDeleteMasterSubmissionRoute, payload)
      .then((res) => {
        notification.success({
          message: "Update master submission success!"
        })
        updateSubmissionDetails(masterSubmissionValue.master_id);
      })
      .catch((res) => {
        updateSubmissionDetails(masterSubmissionValue.master_id);

        if (_.get(res, 'response.status') === 422) {
          notification.error({
            message: errorHandler(_.get(res, 'response.data.errors'))
          })
          return
        }
        else if (_.get(res, 'response.data.message')) {
          notification.error({
            message: _.get(res, 'response.data.message')
          })
          return
        }
        notification.error({
          message: "Unable to update master submission!"
        })
      })

  }
  useEffect(() => {

    if (submissionsDetail && masterSubmissionValue) {
      const submissionDatas = submissionsDetail.map(data => {
        const ticketData = eval(data.ticket_data);
        const item = ticketData[0];
        item.id = data.id
        return {
          batchNo: item.batchNumber,
          batchId: data.batch_id,
          productCode: item.productDefinition.productCode,
          block: item.blockType,
          status: data.status,
          sendTime: moment.unix(masterSubmissionValue.send_time).format('DD MMMM YYYY HH:mm'),
          finishTime: data.finish ? moment.unix(data.finish).format('DD MMMM YYYY HH:mm') : null,
          actions: item
        }
      })
      setSubmissionSource(submissionDatas);
    }
  }, [submissionsDetail])

  return (
    <Modal visible={showSubmissionsModal} onCancel={() => { setShowSubmissionsModal(false); }} footer={null} centered width={1200} title={"Submission Details"}
    >
      <p style={{ textAlign: "right", marginBottom: 10 }}>
        <Dropdown overlay={<Menu>
          <Menu.Item onClick={() => submissionAllAction(masterSubmissionValue, "delete")}>
            <p target="_blank" rel="noopener noreferrer" >
              Delete
            </p>
          </Menu.Item>
          <Menu.Item onClick={() => submissionAllAction(masterSubmissionValue, "print")}>
            <p target="_blank" rel="noopener noreferrer" >
              Print
            </p>
          </Menu.Item>

        </Menu>} placement="bottomLeft" arrow>
          <Button style={{ backgroundColor: "green", color: "white" }}>All Actions</Button>
        </Dropdown>
      </p>
      <Table
        columns={COLUMNS}
        dataSource={submissionSource}
      />
    </Modal>
  )
}

export default SubmissionDetailsModal
