import React, { useState, useEffect } from "react";
import { Select } from "antd";
import is from "is_js";
import { alphabeticalData } from "utils/sortData";
import { ZenSmartAPI } from "utils";
import { productSearchRoute , productBlockComponents} from "utils/apiRoutes";
import { debounce } from "utils/debounce";
import { connect } from "react-redux";
import * as actions from "store/actions/index.js";
import _ from "lodash";

const ProductSelect = (props) => {
  const {
    headerField,
    invokeSelect,
    queryProductBlockPrinter,
    clearHeaderFields,
    updateHeaderFields,
    setCheckBox,
    setQaFailCheckBox
  } = props;
  const [initialProductDropDown, setInitialProductDropDown] = useState([]);
  const [productsDropDownValues, setProductsDropdownValues] = useState([]);

  const productChange = (value) => {
    invokeSelect(productSelect, value);
  };

  const productSelect = (value) => {
    clearHeaderFields();

    let filteredProduct = headerField.products.find(
      (product) => product.id === value
    );

    if(filteredProduct === undefined){
      filteredProduct = props.productOptions.find(
        (product) => product.id === value
      );
    }

    const blocks = filteredProduct.blocks;
    const selectedBlock = is.not.empty(blocks) ? blocks[0].id : null;
    const selectedBlockType = is.not.empty(blocks)
      ? blocks[0].block_type
      : null;
    const selectedBlockPlex = is.not.empty(blocks) ? blocks[0].plex : null;
    
    ZenSmartAPI.get(productBlockComponents(filteredProduct.id,selectedBlockType), {
    }).then((result) => {
      const response = result.data.data;
      response.map(function(data){
        if(data.type === 'lamination'){
          props.setLaminationCodes(data.data)
        }
        if(data.type === 'paper'){
          props.setPaperCodes(data.data)
        }
      })
    })
    

    let headerState = {
      ...headerField,
      block: selectedBlock,
      blockType: selectedBlockType,
      product: value,
      productCode: filteredProduct.product_code,
      article: filteredProduct.description,
      blocks: blocks,
      defaultPrinter: false,
      productBlockPrinter: null,
      plex: selectedBlockPlex,
      text_paper_codes : filteredProduct.paper_codes,
      text_lamination_codes : filteredProduct.lamination_codes, 
    };

    updateHeaderFields(headerState);
    setCheckBox(false);
    setQaFailCheckBox(false);

    queryProductBlockPrinter(
      value,
      selectedBlock,
      headerField.printer,
      headerField.finishingType,
      headerState,
      blocks
    );

    return filteredProduct;
  };

  const onSearchProduct = debounce((value, limit = null) => {
    if (value !== "" || limit) {
      ZenSmartAPI.post(productSearchRoute(value), {
        limit: limit,
        response_type: "imposition",
      }).then((result) => {
        const response = result.data.data;
        props.saveProductOptions(response)
      })
    }
  }, 400);

  useEffect(() => {
    if(props.productOptions.length === 0) {
      onSearchProduct("", 20);
      return;
    }
    const productOptions = _.cloneDeep(props.productOptions);

    setProductsDropdownValues(productOptions);
      props.setInitialImpositionData({
        ...props.initialImpositionData,
        products: productOptions,
      })
  }, [props.productOptions]);

  useEffect(()=>{
    if (is.not.empty(headerField.products) && is.empty(initialProductDropDown)){
      let productArray = [...productsDropDownValues];
      const params = new URLSearchParams(window.location.search);
      const productIdParam = params.get("product_id");

      if (is.existy(productIdParam)){
        const searchProduct = productsDropDownValues.find(productsDropDownValue => productsDropDownValue.id === productIdParam);

        if (is.not.existy(searchProduct)){
          productArray = [...productArray, productSelect(parseInt(productIdParam))];
        }
      }

      setInitialProductDropDown(productArray);
      setProductsDropdownValues(productArray);
      props.setInitialImpositionData({
        ...props.initialImpositionData,
        products: productArray,
      });
    }
  },[headerField.products])

  return (
    <>
      <span className="Header-field">
        <span className="Header-boldText">Product: </span>
        <Select
          showSearch
          className="Header-select-product"
          placeholder="Search for a product..."
          optionFilterProp="children"
          filterOption={true}
          value={
            is.not.empty(productsDropDownValues) ? headerField.product : null
          }
          onChange={(event) => productChange(event)}
          onSearch={(e) => onSearchProduct(e)}
        >
          {alphabeticalData(productsDropDownValues, "product_code").map(
            (product) => (
              <Select.Option key={product.id} value={product.id}>
                {product.product_code}: {product.name}
              </Select.Option>
            )
          )}
        </Select>
      </span>
    </>
  );
};

const mapStateToProps = state => {
  return {
    productOptions: state.product_list.productOptions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveProductOptions: options => dispatch(actions.saveProductOptions(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelect);
