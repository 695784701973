import axios from 'axios';

const endpointURL = `${process.env.REACT_APP_ANALYTICS_SERVER}/dashboards`;

export const GetDashboard = async ($id) => {
    let res = await axios.get(endpointURL+"/"+$id);
    let { data } = res;
    return data;
}

export const GetDashboards = async () => {
    let res = await axios.get(endpointURL+"/all");
    let { data } = res;
    return data;
}

export const FetchDashboardData = async (postData) => {
    let res = await axios.post(`${process.env.REACT_APP_ANALYTICS_SERVER}/unique_count`, postData)
    let { data } = res;
    return data;
}