import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Drawer,
    Form,
    Input,
    InputNumber,
    notification,
    Select as AntSelect,
    Select,
    Checkbox,
} from 'antd';
import * as _ from 'lodash';
import { DesignerContext } from '../../DispatchDesignerContext';
import styled from 'styled-components';
import { ZenSmartAPI } from '../../../../../utils';

const { Option } = AntSelect;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`;

const Number = styled(InputNumber)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #e2e8f0;

  & > button {
    margin-left: 8px;
  }
`;

function PackTypeDefaultDrawer(props) {

    const {
        types,
        settings,
        packTypeFormVisible,
        closePackTypeDrawer,
        editingPackType,
        setEditingPackType,
        addOrUpdatePackType,
        packTypeFormEditOnly,
        setPackTypeFormEditOnly,
    } = useContext(DesignerContext);

    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [createMode, setCreateMode] = useState(false);

    const { form } = props;
    const {
        setFieldsValue,
        getFieldDecorator,
        getFieldValue,
        resetFields,
    } = form;

    useEffect(() => {
        if (editingPackType) {
            setFieldsValue({
                type: editingPackType.type,
                width: editingPackType.width,
                height: editingPackType.height,
                length: editingPackType.length,
                weight: editingPackType.weight,
                flexible_height: editingPackType.flexible_height,
                flexible_field: editingPackType.flexible_field,
                'extras.has_packaging_part_number': _.get(editingPackType, 'extras.has_packaging_part_number', false),
                'extras.packaging_part_number': _.get(editingPackType, 'extras.packaging_part_number'),
            });
        }
    }, [editingPackType]);

    const renderFormItem = (field, placeholder, initialValue, input = null) => {

        let component = <Input />;
        if (input) {
            component = input;
        }


        let fieldValues = {
            initialValue
        }

        if (_.get(input, 'type.name') === 'Checkbox') {
            fieldValues.valuePropName = 'checked';
        }


        return (
            <StyledFormItem label={placeholder}
                validateStatus={errors[field] ? 'error' : null}
                help={errors[field] && errors[field][0]}>
                {getFieldDecorator(field, fieldValues)(
                    component,
                )}
            </StyledFormItem>
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let action = 'post';
        let url = '/api/v1/pack-types';

        if (_.isEmpty(editingPackType) === false) {
            action = 'put';
            url = `${url}/${editingPackType.id}`;
        }

        form.validateFields((err, values) => {
            if (!err) {
                ZenSmartAPI[action](url, values).then(response => {
                    setLoading(false);
                    addOrUpdatePackType(response.data.data);
                    closeAndResetPackTypeDrawer();
                    notification.success({
                        message: `Pack type ${response.data.data.code} ${response.status ===
                            201
                            ? 'created'
                            : 'updated'}`,
                    });
                }).catch(e => {
                    setLoading(false);
                    if (e && e.response) {
                        if (_.get(e, 'response.status') === 422) {
                            setErrors(e.response.data.errors);
                            notification.error({
                                message: 'There are errors in form.',
                            });
                        }
                    }
                });
            }
        });
    };

    const closeAndResetPackTypeDrawer = () => {
        setErrors([]);
        setCreateMode(false);
        setEditingPackType({});
        resetFields();
        closePackTypeDrawer();
    };

    const packType = getFieldValue('type');
    const hasPackagingPartNumber = getFieldValue('extras.has_packaging_part_number');
    if (!types.packs) {
        return null;
    }

    return (
        <Drawer
            title="Pack Type"
            placement="right"
            width={360}
            style={{ zIndex: 1001 }}
            closable={true}
            onClose={e => {
                closeAndResetPackTypeDrawer();
            }}
            visible={packTypeFormVisible}
        >
            <Form autoComplete={'off'} onSubmit={handleSubmit}>
                <div>
                    {!createMode ?
                        <div className={`w-full`}>
                            {renderFormItem(
                                'code',
                                'Packaging code',
                                editingPackType.code,
                                <AntSelect
                                    className={`w-full`}
                                    disabled={packTypeFormEditOnly}
                                    showSearch
                                    placeholder="Search..."
                                    onSelect={code => {
                                        const selectedPack = types.packs.find(
                                            pack => pack.code === code);
                                        if (selectedPack !== null) {
                                            setEditingPackType(selectedPack);
                                        }
                                    }}
                                >
                                    {types.packs.map(pack => (
                                        <Option key={pack.id}
                                            value={pack.code}>{pack.code}</Option>
                                    ))}
                                </AntSelect>,
                            )}
                        </div> :
                        <div className={`w-full`}>
                            {renderFormItem(
                                'code',
                                'Packaging code',
                                null,
                                <Input className={`w-full`} />,
                            )}
                        </div>
                    }
                </div>

                {renderFormItem(
                    'weight',
                    'Packaging Weight',
                    editingPackType.weight,
                    <Number />,
                )}

                {renderFormItem(
                    'width',
                    'Packaging Width',
                    editingPackType.width,
                    <Number />,
                )}

                {renderFormItem(
                    'length',
                    'Packaging Length',
                    editingPackType.length,
                    <Number />,
                )}

                {renderFormItem(
                    'type',
                    'Packaging Height Calculation Type',
                    editingPackType.type,
                    <Select className={`w-full`}>
                        <Option value="flexible">Flexible</Option>
                        <Option value="fixed">Fixed</Option>
                    </Select>,
                )}

                <span style={{
                    display: packType === 'flexible' ? 'block' : 'none',
                }}>

                    {renderFormItem(
                        'height',
                        'Base Packaging Height',
                        editingPackType.height,
                        <Number />,
                    )}

                    {renderFormItem(
                        'flexible_height',
                        'Flexible Packaging Height',
                        editingPackType.flexible_height ?? null,
                        <Number />,
                    )}

                    {renderFormItem(
                        'flexible_field',
                        'Flexible Field',
                        editingPackType.flexible_field,
                        <Select className={`w-full`}>
                            <Option value="page_count">Page Count</Option>
                            <Option value="quantity">Quantity</Option>
                        </Select>,
                    )}
                </span>
                <span style={{
                    display: packType === 'flexible' ? 'none' : 'block',
                }}>
                    {renderFormItem(
                        'height',
                        'Packaging Height',
                        editingPackType.flexible_height,
                        <Number />,
                    )}
                </span>

                <div>
                    {_.get(settings, 'has_packaging_part_number', false) &&
                        <>
                            {renderFormItem(
                                'extras.has_packaging_part_number',
                                'Has packaging part number',
                                _.get(editingPackType, 'extras.packaging_part_number',
                                    false),
                                <Checkbox />,
                            )}

                            {
                                hasPackagingPartNumber &&
                                <>
                                    {renderFormItem(
                                        'extras.packaging_part_number',
                                        'Packaging part number',
                                        _.get(editingPackType,
                                            'extras.packaging_part_number', null),
                                        <Input />,
                                    )}
                                </>
                            }
                        </>

                    }
                </div>

                <ButtonContainer className={`flex justify-end`}
                    style={{ marginTop: 15 }}>
                    {!packTypeFormEditOnly &&
                        <div>
                            <button
                                tabIndex={-1}
                                className={`w-32 h-8 border border-gray-300 bg-gray-200 text-gray-700 rounded mb-2 ml-2`}
                                onClick={e => {
                                    e.preventDefault();
                                    setErrors([]);
                                    setEditingPackType({});
                                    resetFields();
                                    setCreateMode(!createMode);
                                }}
                            >
                                {createMode
                                    ? 'Edit'
                                    : 'New'}
                            </button>

                        </div>
                    }
                    <Button loading={loading} htmlType={`submit`}
                        type={`primary `}>Save
                        Changes</Button>
                </ButtonContainer>
            </Form>
        </Drawer>

    );
}

export default Form.create()(PackTypeDefaultDrawer);