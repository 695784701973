import * as actionTypes from "../actions/actionTypes";
import is from "is_js";
import {UPDATE_PRODUCTS} from "../actions/actionTypes";

const initialState = {
  recipes: [],
  output: [],
  pdfURL: null,
  changeTracker: {
    recipe: [],
    output: [],
    defaultPrinter: false,
    failDefaultPrinter : false
  },
  impoDesignerDisabled: true,
  product: null,
  products: [],
  productCode: null,
  article: null,
  block: null,
  blockType: null,
  printer: null,
  printerClass: null,
  finishingType: null,
  finishingTypeText: null,
  productBlockPrinter: null,
  plex: null,
  imposeTemplate: null,
};

const saveImpositionReduxState = (state, payload) => {
  const modifiedState = { ...state, ...payload, };
  return modifiedState;
};

const saveRecipes = (state, action) => {
  return saveImpositionReduxState(state, { recipes: action.recipes });
};

const saveOutput = (state, action) => {
  return saveImpositionReduxState(state, { output: action.output });
};

const savePDFURL = (state, action) => {
  return saveImpositionReduxState(state, { output: action.pdfURL });
};

const saveChangeTracker = (state, action) => {
  return saveImpositionReduxState(state, { changeTracker: action.changeTracker });
};

const saveImpoDesignerDisabled = (state, action) => {
  return saveImpositionReduxState(state, {
    impoDesignerDisabled: action.impoDesignerDisabled,
  });
};

const saveImposeTemplate = (state, action) => {
  return saveImpositionReduxState(state, { imposeTemplate: action.imposeTemplate });
};

const updateProducts = (state, action) => {
  return saveImpositionReduxState(state, { products: action.products });
};

const saveHeader = (state, action) => {
  if (Object.keys(action).length === 1){
    return saveImpositionReduxState(state, initialState);
  }

  return saveImpositionReduxState(state, {
    product: action.product,
    productCode: action.productCode,
    article: action.article,
    block: action.block,
    blockType: action.blockType,
    printer: action.printer,
    printerClass: action.printerClass,
    finishingType: action.finishingType,
    finishingTypeText: action.finishingTypeText,
    productBlockPrinter: action.productBlockPrinter,
    plex: action.plex,
  });
};

const imposition = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_RECIPES:
      return saveRecipes(state, action);
    case actionTypes.SAVE_OUTPUT:
      return saveOutput(state, action);
    case actionTypes.SAVE_PDF_URL:
      return savePDFURL(state, action);
    case actionTypes.SAVE_CHANGE_TRACKER:
      return saveChangeTracker(state, action);
    case actionTypes.SAVE_IMPOSITION_DISABLED:
      return saveImpoDesignerDisabled(state, action);
    case actionTypes.SAVE_HEADER:
      return saveHeader(state, action);
    case actionTypes.SAVE_IMPOSE_TEMPLATES:
      return saveImposeTemplate(state, action);
    case actionTypes.UPDATE_PRODUCTS:
      return updateProducts(state, action);
    default:
      return state;
  }
};

export default imposition;