import React, { useEffect, useState } from 'react';
import { Header, Title } from 'components/primaries';
import { ZenSmartAPI } from 'utils'
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'
import ViesusRuleUpdateModal from './ViesusRuleUpdateModal';
import { fetchViesusRuleInitDataRoute, modelDefinitionRoute, viesusRuleRoute } from "utils/apiRoutes"
import { notification, Modal } from 'antd';

const ViesusRulePage = () => {
    const [fetchParams, setFetchParams] = useState({})
    const [fetchData, setFetchData] = useState({ refresh_data: null })
    const [updateViesusRuleModal, setUpdateViesusRuleModal] = useState(false)
    const [formInitialValue, setFormInitialValue] = useState({})
    const [viesusModalName, setRuleModalName] = useState("")
    const [viesusInitData, setViesusInitData] = useState({})
    const [fieldsValue, setFieldsValue] = useState([])
    const [fieldType, setFieldType] = useState("string")
    const addViesusRule = (row, refreshData) => {
        setUpdateViesusRuleModal(true)
        setRuleModalName("Add New Viesus Rule")
    }
    const editViesus = (row, refreshData) => {
        ZenSmartAPI.get(`${viesusRuleRoute}/${row.id}`)
            .then((res) => {
                setUpdateViesusRuleModal(true)
                setFormInitialValue(res.data)
                setRuleModalName(`Edit Viesus Rule ${res.data.model_definition_name}`)
            })
            .catch((res) => {
                console.log('res', res)
                if (res.response.status === 422) {
                    notification.error({ message: "Accessory code already exist!" })
                }
                else {

                    notification.error({ message: "Error saving accessory!" })
                }

            })
    }

    const deleteViesus = (row, refreshData) => {
        Modal.confirm({
            'content': "Are you sure you want to delete this viesus rule?",
            onOk: () => {
                ZenSmartAPI.delete(`${viesusRuleRoute}/${row.id}`)
                    .then((res) => {
                        fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                        notification.success({ message: "Delete viesus successful!" })
                    })
                    .catch((res) => {
                        if (res.response.data) {
                            notification.error({ message: res.response.data.message })
                        }
                    })
            },
        });
    }

    useEffect(() => {

        ZenSmartAPI.get(fetchViesusRuleInitDataRoute)
            .then((res) => {
                setViesusInitData(res.data.data)
                ZenSmartAPI.get(modelDefinitionRoute(`lines.`), {})
                    .then((res) => {
                        setFieldsValue(res.data.data)
                    })

            })
            .catch((res) => {
                if (res.response.data) {
                    notification.error({ message: res.response.data.message })
                }
            })

    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <Header>
                <Title>App Setup {">"} Viesus Rules</Title>
            </Header>
            <ViesusRuleUpdateModal
                updateViesusRuleModal={updateViesusRuleModal}
                setUpdateViesusRuleModal={setUpdateViesusRuleModal}
                fetchData={fetchData}
                fetchParams={fetchParams}
                formInitialValue={formInitialValue}
                setFormInitialValue={setFormInitialValue}
                viesusModalName={viesusModalName}
                setRuleModalName={setRuleModalName}
                fieldsValue={fieldsValue}
                setValueField={setFieldsValue}
                fieldType={fieldType}
                setFieldType={setFieldType}
                viesusInitData={viesusInitData}
            />
            <Panel title="VIESUS RULES">
                <DataListPage model="viesus_rule" select={[]} hidden={["id", "model_definition_id"]} link={""} pageSize={50}
                    customizedButton={
                        [{
                            buttonLabel: "Add New Viesus Rule",
                            buttonType: "primary",
                            buttonAction: "normal",
                            actionFunction: addViesusRule,
                            offset: 15,
                            span: 3
                        }
                        ]
                    }
                    setOutSourceFetchData={setFetchData}
                    setOutSourceParameters={setFetchParams}
                    actionButtons={
                        [
                            {
                                buttonLabel: "Edit",
                                buttonType: "primary",
                                buttonAction: "normal",
                                actionFunction: editViesus,
                                reloadData: true
                            },
                            {
                                buttonLabel: "Delete",
                                buttonType: "danger",
                                buttonAction: "normal",
                                actionFunction: deleteViesus,
                                reloadData: true
                            },
                        ]
                    }
                />

            </Panel>
        </>
    );
};

export default ViesusRulePage;