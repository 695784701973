import React, {useEffect, useRef} from 'react';
import {Input, notification} from 'antd';
import useFocusOnSlash from '../../../../hooks/useFocusOnSlash';
import Cookies from 'js-cookie'
import useApi from '../../../../hooks/useApi';

const Search = Input.Search;

export default React.forwardRef((props, ref) => {

    ref = ref === null ? useRef(null) : ref;
    const searchApi = useApi()
    useFocusOnSlash(ref);

    useEffect(() => {
        ref.current.input.focus();
    }, []);

    const searchBarcode = (barcode) => {

        if(barcode === '') {
            return;
        }

        if (!Cookies.get('STATION') && !barcode.includes('STATION')) {
            notification.error({
                'message': 'Please scan station card first!',
            });
            return;
        }

        searchApi.request('get', `/api/v1/dropship/scan/${barcode.replace('/', '')}`).then(response => {
            if(!Cookies.get('STATION')) {
                Cookies.set('STATION', response.data.data.id, { expires: 1 })
                notification.success({
                  message: `Signed into station ${response.data.data.name}`
                })
            } else {
                props.onItemFetch(response.data.data);
            }
            ref.current.input.setValue('');
            ref.current.focus();
        }).catch(e => {
            ref.current.input.input.select()
        });
    }

    return (
        <Search
            ref={ref}
            loading={searchApi.loading}
            disabled={searchApi.loading}
            allowClear
            placeholder="Press '/' to focus"
            onSearch={searchBarcode}
            style={{width: 400}}
        />
    );
})