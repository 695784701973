import React from 'react';
import _ from 'lodash';
import FieldHeader from './FieldHeader';
import { GetLabel } from '../../utils';
import FieldItem from './FieldItem'
const Fields = ({
  dataFields = [],
  setDragItem,
  currentTableObject,
  modifiedFieldLabels
}) => {
  const categories = _.chain(dataFields).map('category').uniq().value().sort()


  return (
    categories.map((category) => {
      const fields = _.chain(dataFields).sortBy('field').uniq().filter(['category', category]).value()
      let tblCaption = category
      let tableNames = []
      let excludedFields = []
      let currCatEF = []
      if ('table_names' in currentTableObject) {
        tableNames = JSON.parse(currentTableObject['table_names'])
      }

      if (tableNames && category in tableNames) {
        tblCaption = tableNames[category]
      }

      if ('excluded_fields' in currentTableObject) {
        excludedFields = JSON.parse(currentTableObject['excluded_fields'])
      }

      if (excludedFields && category in excludedFields) {
        currCatEF = excludedFields[category]
      }

      return (
        <FieldHeader key={category} title={tblCaption}>

          {fields.map((field) => {
            if (!currCatEF.includes(field.field)) {
              return (
                <FieldItem
                  key={field.field}
                  setDragAction={() => setDragItem(field)}
                  stopDragAction={() => setDragItem({})}
                >
                  {GetLabel(`${field.table}.${field.field}`, modifiedFieldLabels)}
                </FieldItem>
              )
            }
          }
          )}
        </FieldHeader>
      )
    })
  )
}


export default Fields