import React from 'react'
import { Button, Checkbox, Col, Modal, notification, Row, Select } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { Label } from 'components/zensmart-design-system/components/Label/Label.js';
import { ZenSmartAPI } from 'utils';
import { batchPrintRoute } from 'utils/apiRoutes';

const { Option } = Select;

const HeaderStyle = styled.h1`
    font-weight : bold;
    font-size : 16px;
    font-family: 'Rubik', sans-serif;
    padding : 10px;
`

const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}

.ant-select-lg && path {
    color : blue;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}
.ant-select-lg .ant-select-selection__rendered {
    color : blue;
}
`

const WorkOrderModal = ({ showPrintModal,  modalHandlePrint, printModalOnCancel, loading, printers, setSelectedPrinter, selectedPrinter }) => {
    return (
        <>
            <ModalStyle />
            <Modal
                title=""
                width={500}
                visible={showPrintModal}
                footer={null}
                centered
            >
                <Row type="flex" justify="center" style={{ padding: 20 }}>
                    <Label status="blue" >ARE YOU SURE YOU WANT TO PRINT THESE TICKETS?</Label>
                    {/* <Col span={14} style={{ padding: 15 }}>
                        <HeaderStyle>Select Printer: </HeaderStyle>
                        <Select size="large" placeholder='Select a printer' style={{ width: "100%" }} onChange={e => setSelectedPrinter(e)}>
                            {printers ? printers.map(item =>
                                item && <Option value={item}>{item}</Option>
                            ) : null}
                        </Select>
                    </Col> */}
                </Row>
                <Row type="flex" justify="center" style={{ paddingBottom: 20 }}>
                    <Col span={7} style={{ padding: 5 }} >
                        <Button type="primary" style={{ width: "100%" }} loading={loading} onClick={modalHandlePrint}>Send to Print</Button>
                    </Col>
                    <Col span={5} style={{ padding: 5 }} >
                        <Button type="danger" style={{ width: "100%" }} onClick={printModalOnCancel}>Cancel</Button>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default WorkOrderModal
