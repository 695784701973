import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, Input, Modal, Form, Button, DatePicker, Alert  } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenSmartAPI } from 'utils'
import { getAllDepartments, wipPanelRoute, getQaFailFormRoute, getAllVendors , getAllLaminationCode , getExtraWipFilterSetting } from "utils/apiRoutes"
import { wipInitialData } from "utils/panelsInitialData"
import { Label } from 'components/zensmart-design-system/components/Label/Label.js';
import { alphabeticalData } from 'utils/sortData'
import { debounce } from "utils/debounce"
import axios from 'axios';
import moment from 'moment'
import { errorNoticationHandler } from 'utils/errorMessageHandler';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`

const InputHeader = styled.span`
  font-weight: bold;
`

const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}
`
const { Option } = Select;

const WipPanel = (props) => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [filterStagesData, setFilterStagesData] = useState([])
    const [departments, setDepartments] = useState([])
    const [ascend, setAscend] = useState(wipInitialData)
    const [selectAction, setSelectAction] = useState(undefined)
    const [selectedCheckBoxValue, setSelectedCheckBoxValue] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [formValues, setFormValues] = useState(null)
    const [vendors, setVendors] = useState([])
    const [laminations, setLaminations] = useState([])
    const [extraFilters, setExtraFilters] = useState([])
    const [payload, setPayload] = useState({
        department_id: null,
        stuck: "only",
        due: "overdue"
    })
    const [paginationNumber, setPaginationNumber] = useState(1)
    const [isPageSelectAll, setIsPageSelectAll] = useState(false)
    const paginationSize = 500
    const [filteredStagesDropdown, setFilteredStagesDropdown] = useState([])
    const [filtersSearchValue, setFiltersSearchValue] = useState('')
    const [requestCancel, setRequestCancel] = useState({ cancel: undefined })
    const [statistics, setStatistics] = useState([]);

    const FailForm = Form.create({ name: 'FailForm' })(props => {
        const { form } = props
        const { getFieldDecorator, getFieldsValue, getFieldValue, validateFields, setFieldsValue } = form

        const handleSaveChanges = (evt) => {
            evt.preventDefault()
            validateFields((err, values) => {
                if (!err) {
                    const payload = getFieldsValue();
                    setFieldsValue({
                        ...payload,
                        loading: true
                    })
                    var queryStringID = ''
                    Object.keys(selectedCheckBoxValue).map((data, key) => {
                        if (selectedCheckBoxValue[data]) {
                            queryStringID = key === 0 ? queryStringID + `${data}` : queryStringID + `;${data}`
                        }
                        return data
                    })

                    ZenSmartAPI.post(getQaFailFormRoute(queryStringID.replace(/;$/, "").replace(/^;/, "")), payload)
                    .then((res) => {
                        notification.success({ message: "Save block successful!" })
                        setFieldsValue({
                            ...payload,
                            loading: false
                        })
                        hideModal()
                        setSelectedCheckBoxValue({})
                        setIsPageSelectAll(false)
                        fetchData();
                    })
                    .catch((res) => {
                        setLoading(false);
                        console.log('res', res.data);
                        res.response.data ? (
                            notification.error({ message: "Failed saving a block! " + res.response.data.message })
                        ) : (
                            notification.error({ message: "Failed saving a block!" })
                        );
                        setFieldsValue({
                            ...payload,
                            loading: false
                        });

                    })
                }
            });

        }
        const getFormSelectedValue = (value) => {
            const getSelected = value.find((item) => {
                return item.selected === true
            })
            if (getSelected !== undefined) {
                return getSelected.value
            }
        }

        return (
            <>
                {formValues &&
                <>
                    <p style={{ textAlign: "center" }}>
                        <Label status="blue" >{formValues.message}</Label>
                    </p>

                    <Form onSubmit={handleSaveChanges}>
                        <div style={{ padding: "10px 50px" }}>

                            {formValues ? formValues.page_definitions.sections[0].section_data.map((row, key) =>

                                <Form.Item label={<InputHeader>{row.label}</InputHeader>} >
                                    {getFieldDecorator(row.field, {
                                        initialValue: row.options ? getFormSelectedValue(row.options) : null,
                                        rules: row.required === true ? [{ required: true, message: `Please input this feld!` }] : undefined
                                    })(
                                        row.data_type === "SELECT" ?
                                        <Select optionFilterProp="children"
                                                showSearch placeholder={row.label} size="large"
                                        >
                                            {row.options.map((data, key) =>
                                                <Option value={data.value}>{data.text}</Option>
                                            )}
                                        </Select>
                                                                   :
                                        <Input
                                            placeholder={row.required === true ? "Please Input This Field" : "*Optional"}
                                            size="large"
                                        />
                                    )}
                                </Form.Item>
                            ) : null}
                            <Form.Item >
                                {getFieldDecorator("loading", {
                                    initialValue: false,
                                })(
                                    <Input
                                        style={{ display: "none" }}
                                    />
                                )}
                            </Form.Item>
                        </div>
                        <Row type="flex" justify="center" align="middle">
                            <Col span={5} style={{ padding: 10 }}>
                                <Form.Item>
                                    <Button type="danger" style={{ width: "100%" }} onClick={hideModal}
                                    >Cancel</Button>
                                </Form.Item>

                            </Col>
                            <Col span={7} style={{ padding: 10 }} >
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={getFieldValue('loading')}
                                        style={{ width: "100%" }}
                                    >
                                        Save Changes
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </>
                }
            </>
        )
    })

    const fetchDepartments = () => {

        setLoading(true)
        ZenSmartAPI.get(getAllDepartments)
        .then((res) => {
            setLoading(false)
            setDepartments(res.data.data)
        })
        .catch((res) => {
            setLoading(false)
        })

    }
    const fetchVendors = () => {

        setLoading(true)

        ZenSmartAPI.get(getAllVendors)

            .then((res) => {

                setLoading(false)

                setVendors(res.data.data)

            })

            .catch((res) => {

                setLoading(false)

            })

    }

    const fetchExtaFilter = () => {
        setLoading(true)
        ZenSmartAPI.get(getExtraWipFilterSetting)
            .then((res) => {
                const filters = res.data?.data ? res.data?.data : []
                setLoading(false)
                setExtraFilters(filters)
                if(filters.includes('lamination')){
                    fetchLaminations()
                }
                if(filters.includes('vendors')){
                    fetchVendors()
                }
            })
            .catch((res) => {
                setLoading(false)
            })
    }

    const fetchLaminations = () => {
        setLoading(true)
        ZenSmartAPI.get(getAllLaminationCode)
            .then((res) => {
                setLoading(false)
                setLaminations(res.data.data)
            })
            .catch((res) => {
                setLoading(false)
            })
    }
    const hideModal = () => {
        setShowModal(false)
        setFormValues(null)
        setSelectAction(undefined)
    }

    const fetchData = () => {

        const CancelToken = axios.CancelToken;

        if (requestCancel.cancel !== undefined) {
            requestCancel.cancel("cancel request");
        }
        setLoading(true)

        ZenSmartAPI.get(wipPanelRoute(`department_id=${payload.department_id}&stuck=${payload.stuck}&due=${payload.due}&range=${(payload.range === undefined) ? null : payload.range}&collation=${ (payload.collation === undefined) ? null : payload.collation }&lamination_code=${ (payload.lamination_code === undefined) ? null : payload.lamination_code  }&vendor_id=${ (payload.vendor_id === undefined) ? null : payload.vendor_id }`), {
            cancelToken: new CancelToken(function executor(c) {
                setRequestCancel({ ...requestCancel, cancel: c })
            }),
        }).then((res) => {
            setLoading(false)
            setItemData(res.data.data)
            setOriginalData(res.data.data)
            setStatistics(res.data.statistic)
            setAscend(wipInitialData)
            setIsPageSelectAll(false)

            if (res.data.data) {
                const filteredStages = [];
                res.data.data.map(data => {
                    if (!filteredStages.includes(data.stage)) {
                        filteredStages.push(data.stage)
                    }
                    return data
                })
                setFilteredStagesDropdown(alphabeticalData(filteredStages))
            }

        })
        .catch((error) => {
            if (error && error.message === "Cannot read properties of undefined (reading 'status')") {
                return;
            }
            console.log('error', error)
            // if (error.message)
            //     notification.error({ message: error.message })
            setLoading(false)
        })
    }

    const fetchQaFailForm = (value) => {

        const ifSelectedValue = []
        var payload = ''

        Object.keys(selectedCheckBoxValue).map(data => {
            if (selectedCheckBoxValue[data]) {
                ifSelectedValue.push(data)
                payload = payload + `${data};`

            }
            return data
        })

        if (ifSelectedValue.length > 0) {
            ZenSmartAPI.get(getQaFailFormRoute(payload.replace(/;$/, "").replace(/^;/, "")))
                .then((res) => {
                    setLoading(false)
                    if (res.data && res.data.page_definitions.sections[0].section_data.length > 0) {
                        setSelectAction(value)
                        setFormValues(res.data)
                        setShowModal(true)
                    }
                    else {
                        notification.error({ message: "No available input forms!" })
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    errorNoticationHandler(error, "Cant process QA Fail!")
                })
        }
        else {
            notification.error({ message: "Please select a block!" })
        }

    }

    const selectAllOnPage = (value) => {
        if (data.length > 0) {
            const selectedObj = selectedCheckBoxValue
            data.slice(paginationSize * paginationNumber - paginationSize, (paginationSize * paginationNumber)).map((item, key) => {
                selectedObj[item.id] = value
                return item
            })
            setSelectedCheckBoxValue({ ...selectedCheckBoxValue })
            setIsPageSelectAll(value)
        }
    }

    const checkPaginationIfAllSelected = (value) => {
        setPaginationNumber(value)
        const checkIfNotAllSelected = data.slice(paginationSize * value - paginationSize, (paginationSize * value)).some((item, key) => {
            const ifSelectedItem = selectedCheckBoxValue[item.id];
            return ifSelectedItem ? false : true;
        })
        setIsPageSelectAll(checkIfNotAllSelected ? false : true)
    }

    const updateStatisticsData = (results) => {
        const transactionValuesArray = []
        const orderNumberValuesArray = []
        const lineIdValuesArray = []
        const blockValuesArray = []
        results.forEach(element => {
            if (transactionValuesArray.filter(count => count === element?.transaction?.value)?.length < 1) {
                transactionValuesArray.push(element?.transaction?.value)
            }

            if (orderNumberValuesArray.filter(count => count === element?.order_number?.value)?.length < 1) {
                orderNumberValuesArray.push(element?.order_number?.value)
            }

            if (lineIdValuesArray.filter(count => count === element?.line_id?.value)?.length < 1) {
                lineIdValuesArray.push(element?.line_id?.value)
            }

            if (blockValuesArray.filter(count => count === element?.block?.value)?.length < 1) {
                blockValuesArray.push(element?.block?.value)
            }
        });

        setStatistics({
            blocks_count: blockValuesArray.length,
            lines_count: lineIdValuesArray.length,
            orders_count: orderNumberValuesArray.length,
            transactions_count: transactionValuesArray.length
        })
    }
    const searchOperation = debounce(async (value, source) => {
        let dataResult = [...source]
        if (value === '') {
            setItemData(source)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                        false :
                        anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) : anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
                return containsValue
            })
            setItemData(result)
            dataResult = [...result]
        }

        updateStatisticsData(dataResult)
        setFiltersSearchValue(value)
    }, 500)

    const editPayload = (value, property) => {
        if(property == 'range'){
            value = value.map(function (data){
                if(data === undefined){
                    return moment(value[0]).format("YYYY-MM-DD")
                }else{
                    return moment(data).format("YYYY-MM-DD")
                }
            })
        }
        setPayload({ ...payload, [property]: value })

    }

    const getFilterStages = async (value) => {
        if (value === undefined) {
            searchOperation(filtersSearchValue, originalData);
            setFilterStagesData([])
        }
        else {
            const result = originalData.filter(data => data.stage === value)
            if (filtersSearchValue === '') {
                setItemData(result)
                setFilterStagesData(result)
                updateStatisticsData(result)
            }
            else {
                setFilterStagesData(result)
                searchOperation(filtersSearchValue, result)
            }
        }
    }


    useEffect(() => {
        fetchDepartments()
        fetchExtaFilter()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (departments.length > 0) {
            setPayload({ ...payload, department_id: departments[0].id })
        }
    }, [departments]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useMemo(() => {
        if (payload.department_id) {
            fetchData()
        }
    }, [payload]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Performance {">"} WIP Panel</Title>
            </Header>
            <Panel title="WIP PANEL">
                <div style={{ padding: 40 }}>
                    <Row type="flex" align="middle">
                        <InputStyle />
                        <Col span={4} style={{ padding: 10 }}>
                            <Input.Search
                                type="text"
                                placeholder="Filters.."
                                disabled={loading}
                                size="large"
                                onChange={(value) => searchOperation(value.target.value, filterStagesData.length > 0 ? filterStagesData : originalData)}
                                loading={loading}
                            />
                        </Col>
                        {extraFilters.includes('range') && <Col  style={{ padding: 5 , width: 250 }}>
                            <RangePicker
                                allowClear={true}
                                defaultValue={payload.dateRange ? payload.dateRange : undefined}
                                onChange={(value) => editPayload(value, "range")}
                                format={dateFormat}
                                ranges={{ 'Today': [moment(), undefined] ,  'Tomorrow': [moment().add(1, 'days'), undefined] }}
                            />
                        </Col>}
                        {extraFilters.includes('collation') && <Col span={3} style={{ padding: 5 }}>
                            <Select placeholder="Select Collation" value={payload.collation ? payload.collation : undefined} onChange={(value) => editPayload(value, "collation")} style={{ width: "100%" }} >
                                <Option value="all">All</Option>
                                <Option value="multi">Multi</Option>
                                <Option value="single">Single</Option>
                            </Select>
                        </Col>}
                        {extraFilters.includes('lamination') && <Col span={3} style={{ padding: 5 }}>
                            <Select placeholder="Select Lamination Code" onChange={(value) => editPayload(value, "lamination_code")} value={payload.lamination_code ? payload.lamination_code : undefined} style={{ width: "100%" }} >
                                <Option value="all">All</Option>
                                {laminations.map(data =>
                                    <Option value={data}>{data}</Option>
                                )}
                            </Select>
                        </Col>}
                        {extraFilters.includes('vendors') && <Col span={3} style={{ padding: 5 }}>
                            <Select placeholder="Select Vendor" onChange={(value) => editPayload(value, "vendor_id")} value={payload.vendor_id ? payload.vendor_id : undefined} style={{ width: "100%" }} >
                                <Option value="all">All</Option>
                                {vendors.map(data =>
                                    <Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </Col>}
                        <Col span={3} style={{ padding: 5 }}>
                            <Select placeholder="Select Department" onChange={(value) => editPayload(value, "department_id")} value={payload.department_id ? payload.department_id : undefined} style={{ width: "100%" }} >
                                <Option value="all">All</Option>
                                {departments.map(data =>
                                    <Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </Col>
                        {!extraFilters.includes('range') && <Col span={3} style={{ padding: 5 }}>
                            <Select value={payload.due ? payload.due : undefined} onChange={(value) => editPayload(value, "due")} style={{ width: "100%" }} >
                                <Option value="all">All</Option>
                                <Option value="overdue">Overdue</Option>
                                <Option value="today">Due Today</Option>
                                <Option value="tomorrow">Due Tomorrow</Option>
                                <Option value="later">Due Later</Option>
                            </Select>
                        </Col>}
                        <Col span={3} style={{ padding: 5 }}>
                            <Select value={payload.stuck ? payload.stuck : undefined} onChange={(value) => editPayload(value, "stuck")} style={{ width: "100%" }} >
                                <Option value="all">All Items</Option>
                                <Option value="only">Stuck Only</Option>

                            </Select>
                        </Col>
                        <Col span={3} style={{ padding: 5 }}>
                            <Select onChange={getFilterStages} placeholder="Filter Stages" className={"select-stage"} style={{ width: "100%" }} >
                                <Option value={undefined} style={{ fontWeight: "bold" }}>None</Option>
                                {filteredStagesDropdown.length > 0 &&
                                filteredStagesDropdown.map(data => {
                                    return <Option value={data}>{data !== null ? data : "-"}</Option>
                                })
                                }
                            </Select>
                        </Col>
                        <Col span={3} offset={2} style={{ padding: 5 }}>
                            {!isPageSelectAll ?
                             <Button type="primary" style={{ width: "100%" }} onClick={() => selectAllOnPage(true)}>Select all on page</Button>
                                              : <Button type="primary" style={{ width: "100%" }} onClick={() => selectAllOnPage(false)}>Deselect all on page</Button>
                            }
                        </Col>
                        <Col span={3} style={{ padding: 5 }}>
                            <Select placeholder="Select Action" value={selectAction} onChange={(value) => fetchQaFailForm(value)} style={{ width: "100%" }} >
                                <Option value={'fail_block'} >Fail Block/s</Option>

                            </Select>
                        </Col>
                    </Row>
                    <div style={{ padding: 10, marginBottom: "-30px", fontWeight: "bold" }}>
                        <Heading>
                            TOTAL COUNTS : {statistics.transactions_count} Transactions, {statistics.orders_count} Orders, {statistics.lines_count} Lines, {statistics.blocks_count} Blocks
                        </Heading>
                    </div>
                </div>
                <TableData
                    itemData={itemData} loading={loading} setLoading={setLoading} data={data} setData={setData} ascend={ascend} setAscend={setAscend}
                    selectedCheckBoxValue={selectedCheckBoxValue} setSelectedCheckBoxValue={setSelectedCheckBoxValue} paginationSize={paginationSize}
                    checkPaginationIfAllSelected={checkPaginationIfAllSelected} paginationNumber={paginationNumber}
                />
                <>
                    <ModalStyle />
                    <Modal
                        title=""
                        centered
                        width={650}
                        visible={showModal}
                        footer={null}
                        onCancel={hideModal}
                    >
                        <div style={{ padding: 20 }}>
                            <FailForm />
                        </div>
                    </Modal>
                </>

            </Panel>
        </>
    )
}

export default WipPanel
