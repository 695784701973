import React from 'react'
import styled from 'styled-components'
import {Droppable, Draggable} from 'react-beautiful-dnd'

import {PROCESS} from 'types'
import {LibraryItem} from 'components/composites'

const Wrapper = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
`

const triggerItems = [
  {type: PROCESS.TYPE.BLOCK, text: 'Create Block'},
  {type: PROCESS.TRIGGER.EMAIL, text: 'Send Email'},
  {type: PROCESS.TRIGGER.FIELD, text: 'Field Update'},
  {type: PROCESS.TRIGGER.CALLOUT, text: 'Callout'},
  {type: PROCESS.TRIGGER.ADD_COST, text: 'Add Cost'},
]

const TriggerItems = (props) => triggerItems.map((triggerItem, index) => {
  const dndID = `[${triggerItem.type}]trigger-item,${index}`
  return (
    <Droppable
      type={triggerItem.type}
      droppableId={dndID}
      key={dndID}
    >
      {provided => (
        <Wrapper
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <Draggable
            draggableId={dndID}
            index={index}
          >
            {provided => (
              <LibraryItem
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                {...triggerItem}
                ref={provided.innerRef}
              />
            )}
          </Draggable>
          {provided.placeholder}
        </Wrapper>
      )}
    </Droppable>
  )
})

export default TriggerItems