import React, {useCallback, useEffect, useState} from 'react';
import {Col, Input, Modal, Row} from 'antd';
import styled from 'styled-components';
import {Label} from 'components/zensmart-design-system/components/Label/Label.js';
import {Button} from '../../../components/zensmart-design-system';
import {useSelector} from 'react-redux';

const { confirm } = Modal

const Thumbnail = styled.img`
  padding : 5px;
  height : 140px;
  width : 100%;
`;

const CenterButton = styled.p`
  text-align : center;
`;

export default function OverrideShippingVerificationModal(props) {

  const { visible, onClose, dispatchData, overrideShippingVerification, handleOverrideShippingVerificationWithDispatchNow, disableButtons, setDisableButtons, setOverrideConfirmed, overrideConfirmed } = props;
  const userData = useSelector((state) => state.user.details);
  const [count, setCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const countInput = useCallback(node => {
    if (node !== null) {

      if(errorMessage !== '') {
        node.focus();
        return;
      }

      if (visible === true) {
        resetModalState();
        node.focus();
      }
    }
  }, [visible, errorMessage]);

  // Initiate closing of the modal only after overrideConfirmed flag is set to true
  useEffect(() => {
    if(overrideConfirmed) {

      if(parseInt(count) < dispatchData.lines.length && canDispatchNow()) {
        handleOverrideShippingVerificationWithDispatchNow(parseInt(count));
        return;
      }

      overrideShippingVerification();
    }
  }, [overrideConfirmed])

  const resetModalState = () => {
    setCount(null);
    setErrorMessage('');
    setDisableButtons(false);
  }

  const canDispatchNow = () => {
    return userData.permissions.indexOf('dispatch-now') !== -1;
  }

  if (dispatchData === null) {
    return null;
  }

  const onSubmit = () => {

    if (parseInt(count) < dispatchData.lines.length && canDispatchNow()) {
      confirm({
        title: 'Partial Dispatch',
        content: `This shipment is expecting ${dispatchData.lines.length} Lines. Are you sure you want to dispatch with only ${count}?`,
        onOk() {
          setOverrideConfirmed(true);
        }
      });
      return;
    }

    if (parseInt(count) !== dispatchData.lines.length) {
      setErrorMessage(`INCORRECT UNIT COUNT ${parseInt(count)}, PLEASE CHECK AND TRY AGAIN`);
      return;
    }

    setOverrideConfirmed(true);
    setDisableButtons(true);
  };

  return (
    <Modal
      visible={visible}
      width={700}
      centered
      footer={null}
      destroyOnClose={true}
    >
      <Row style={{ overflow: 'auto', maxHeight: '400px' }}>
        {dispatchData.orders.map(data => {
          return (<Row key={data.id} type="flex" justify="center" align="center">
            {data.thumbnails.map(item =>
              <Col span={8}>
                <Thumbnail src={item} />
              </Col>
            )}
          </Row>)
        })}
      </Row>
      {errorMessage &&
        <Row>
          <div style={{ textAlign: 'center' }}>
            <Label style={{ margin: 10 }} status={'red'}>
              {errorMessage}
            </Label>
          </div>
        </Row>
      }
      <Row>
        <div style={{ textAlign: 'center' }}>
          <Label style={{ margin: 10 }} status={'blue'}>
            HOW MANY UNITS ARE YOU SHIPPING?
          </Label>
        </div>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Input
          ref={countInput}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onSubmit();
            }
          }}
          style={{
            width: '100px',
            appearance: 'none',
            height: '50px',
            borderColor: '#0B6BF2',
            fontSize: '25px',
            textAlign: 'center',
          }}
          pattern={`[0-9]*`}
          value={count}
          onChange={e => {
            if (e.target.validity.valid) {
              setCount(e.target.value);
            }
          }}
        />
      </Row>
      <Row type="flex" justify="center" align="center" style={{ marginTop: '15px' }}>
        <Col span={6}>
          <CenterButton>
            <Button isLoading={disableButtons} isDisabled={disableButtons} size={'large'} style={{ width: '100%' }}
              onClick={onSubmit}>SHIP</Button>
          </CenterButton>
        </Col>
        <Col span={6} offset={1}>
          <CenterButton>
            <Button isDisabled={disableButtons} appearance={`ghost`} size={'large'} style={{ width: '100%' }} onClick={onClose}
              color="red" ghost>CANCEL</Button>
          </CenterButton>
        </Col>
      </Row>
    </Modal>);
}