import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { Form, Input, Button, Collapse, notification, Select, Checkbox, InputNumber, Modal } from 'antd'

import { Panel } from 'components/primaries'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { ZenSmartAPI } from 'utils'
import { productCreateRoute, createGroupsRoute, createDepartmentsRoute, freightGroupCreateRoute, productGroupsSearchRoute } from "utils/apiRoutes"
import { debounce } from "utils/debounce"
import {alphabeticalData} from 'utils/sortData';

const Link = styled(RouterLink)`
  color: ${color.blue.normal};
`

const StyledForm = styled(Form)`
  margin: 16px;
`

const Field = styled(Form.Item)`
  margin-bottom: 16px;
  display: inline-block;
  width: 46%;
  margin: 0 20px;
`

const Header = styled.header`
  margin-bottom: 24px;
`

const SubmitContainer = styled.div`
  text-align: right;
`

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`

function ActualCreateProductForm(props) {
  const { form } = props
  const { getFieldDecorator, getFieldsValue, setFieldsValue, validateFields } = form
  const [submitting, setSubmitting] = useState(false)
  const [formItems, setFormItems] = useState(null);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [groupValues, setGroupValues] = useState([])
  const constructPayload = (data) => {
    const payload = [{ resource: 'products', data: [] }]

    for (var key in data) {
      const item = {}
      item.field = key;
      item.value = data[key] ? data[key].toString() : null;
      payload[0].data.push(item);
    }

    return payload;
  }

  const goToRoute = (route) => route ? history.push(route) : "";


  const onErrorValidate = (errors) => {
    getInputs('',errors)
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    validateFields((err, values) => {
      let data = getFieldsValue()

      if (!err) {
        setSubmitting(true)
        const payload = constructPayload(data);
        ZenSmartAPI.post(productCreateRoute, { payload }).then(result => {
          if (result.status === 200) {
            setFormItems(null)
            getInputs()
            notification.success({
              message: 'Product Successfully Created.'
            })
            setSubmitting(false);
            const productData = result.data;
            const productID = productData.data.id;
            if (productData && productID) {
              return goToRoute(`/app/prep/product-flow/?product_id=${productID}?edit=true`);
            }

          } else {
            notification.error({
              message: 'Something went wrong while creating the new product. Please try again later.'
            })
          }

          setSubmitting(false)
        }).catch(error => {
          const { response } = error;
          if (response.status === 422) {
              notification.error({
                  message: 'There are errors in form.',
              });
              setErrors(response.data.errors);
              onErrorValidate(response.data.errors);
          }else{
            notification.error({
              message: 'Something went wrong while creating the new product. Please try again later.'
            })
          }
          setSubmitting(false)
          
        })
      } else {
        onErrorValidate(err);
      }
    });
  }

  const keyPress = (evt, label) => {
    if (label === "Product Code") {
      const keyCode = evt.key
      const charactersRegex = new RegExp("[a-zA-Z0-9_-]");
      //Allow characters from regex or backspace (189) to clear text
      if (!charactersRegex.test(keyCode) && keyCode !== 189) {
        evt.preventDefault()
        return false
      }
    }
  }

  const addGroups = (evt, options, field , createEndPoint = null , label , data) => {
    const findOptions = options.some(data => data.text.toLowerCase() === evt.target.value.toLowerCase())
    if (evt.keyCode === 13 && !findOptions && evt.target.value !== "" && evt.target.value !== null) {

      const inputValue = evt.target.value
      Modal.confirm({
        'content': `Are you sure you want to create a new ${label.toLowerCase()} "${inputValue}"?`,
        onOk: () => {
          ZenSmartAPI.post(field === "department_id" ? createDepartmentsRoute : field === "freight_group_id" ? freightGroupCreateRoute :
            (field === "primary_product_group_id" || field === "secondary_product_group_id" || field === "tertiary_product_group_id" ) ? createGroupsRoute : createEndPoint , { name: inputValue }).then(res => {
              notification.success({ message: "Add "+label.toLowerCase()+" successful!" })
              if(res.data.data){
                if(res.data.data.id){
                  let newData = data.map(function(o,index){
                    if(o.field === field){
                      o.options = o.options.map(function(option) {
                        if(option.selected === true){
                          option.selected = false;
                        }
                        return option;
                      })
                      o.value = parseFloat(res.data.data.id)
                      o.options.push({text: inputValue, value: o.value, selected: true});
                    }
                    return o;
                  })
                  onChangeGroup(res.data.data.id,field,newData)
                }else{
                  let newData = data.map(function(o,index){
                    if(o.field === field){
                      o.options = o.options.map(function(option) {
                        if(option.selected === true){
                          option.selected = false;
                        }
                        return option;
                      })
                      o.value = inputValue
                      o.options.push({text: inputValue, value: inputValue, selected: true});
                    }
                    return o;
                  })
                  onChangeGroup(inputValue,field,newData)
                }  
              }
            })
        },
      });

    }

  }

  const onSearchProductGroup = debounce((value, data ,field, setFields = null) => {
    if (value !== "" && (field === "primary_product_group_id" || field === "secondary_product_group_id" || field === "tertiary_product_group_id")) {
      ZenSmartAPI.post(productGroupsSearchRoute(value), {}).then(res => {
        if (field === "primary_product_group_id" || field === "secondary_product_group_id" || field === "tertiary_product_group_id") {
          //setGroupValues(res.data.data)
          let newOption = []
          let count = 0
          res.data.data.map(function(o,index,newOptionData){
            if(count === 0){
              newOption.push({text: o.name, value: o.id, selected: true});
              count = 1;
            }else{
              newOption.push({text: o.name, value: o.id, selected: false});
            }
            return o;
          })

          if(newOption.length === 0){
            newOption.push({text: "None", value: null, selected: true});
          }

          let newData = data.map(function(o,index){
            if(o.field === field){
              o.options = newOption
            }
            return o;
          })
          getInputs(newData)
          //console.log(newOption)
          //getInputs(newData)
        }
        return false;
      })
      if (setFields) {
        setFieldsValue({ [field]: setFields })
      }
    }
  }, 400)

  const onChangeGroup = (e, field, data) => {
    setFieldsValue({ [field]: e.toString() })
    getInputs(data)
  }

  const onChangeGroupAddFeature = (e, field, data) => {
    let newData = data.map(function(o,index){
      if(o.field === field){
        o.options = o.options.map(function(option) {
          if(option.selected === true){
            option.selected = false;
          }
          return option;
        })
        o.options = o.options.map(function(option) {
          if(field === 'dtg_garment_code' || field === 'dtg_color_code' || field === 'dtg_size_code'){
            if(option.text === e.toString() ){
              option.selected = true;
              o.value = option.value
            }
          }else{
            if(option.value === e ){
              option.selected = true;
              o.value = option.value
            }
          }
          
          return option;
        })
        
      }
      return o;
    })

    setFieldsValue({ [field]: e.toString() })

    getInputs(newData)
  }

  const getInputs = (data = null,errors = {}) => {

    if (data) {
      console.log('inside',data)
      generateInputs(data,errors)
    }
    else {
      ZenSmartAPI.get(productCreateRoute).then(res => {

        generateInputs(res.data.page_definitions.sections[0].section_data,errors)
      })
    }
  }

  const getSelectedValue = (value) => {
    const getSelected = value
      .filter((item) => {
        return item.selected === true;
      })
      .map((item) => (item.value === null ? "null" : item.value ));

    if (getSelected !== undefined) {
      if (getSelected.length === 1) return getSelected[0];
      else return getSelected;
    }

    return false;
  };

  const generateInputs = (res,errors) => {
    const formItems = res.map((section, i) => {
      const { label, field, data_type, options, required, value , create_endpoint } = section;
      if (data_type === 'TEXT') {
        const textConfig = {
          valuePropName: field,
          initialValue: '',
          validate: [{
            trigger: 'onBlur',
            rules: [
              { required, message: `Please input the ${label}!` },
            ],
          }],
        }


        const textStyle = {};

        if (label === "Product Code") {
          textStyle.style = { textTransform: "uppercase" }
          textConfig.normalize = (input) => input.toUpperCase()
        }
        return (
          <Field key={i} label={label} hasFeedback 
           validateStatus={errors[field] ? 'error' : null}
           help={errors[field] && errors[field][0]}
          >
            {getFieldDecorator(field, textConfig)
              (<Input onKeyDown={(event) => keyPress(event, label)} {...textStyle} onChange={(e) => onChangeGroup(e, field, res)} />)}
          </Field>
        )
      }

      if (data_type === 'INTEGER') {
        return (
          <Field key={i} label={label}
            validateStatus={errors[field] ? 'error' : null}
            help={errors[field] && errors[field][0]}
          >
            {getFieldDecorator(field, {
              valuePropName: field,
              initialValue: '',
              rules: [{ required, message: `Please input the ${label}!` }]
            })(<InputNumber />)}
          </Field>
        )
      }

      if (data_type === "ADD_FEATURE_SELECT") {
          const { Option } = Select;
          const optionValues = options.map(item => <Option key={item.value} value={item.value}>{item.text}</Option>);
          return (
            <Field key={i} label={label}
              validateStatus={errors[field] ? 'error' : null}
              help={errors[field] && errors[field][0]}
            >
              {getFieldDecorator(field, {
                valuePropName: field,
                rules: [{ required, message: `Please input the ${label}!` }]
  
              })(
                <Select showSearch optionFilterProp="children"
                  onInputKeyDown={(event) => addGroups(event, options, field , create_endpoint, label , res)}
                  onChange={(e) => onChangeGroupAddFeature(e, field, res)}
                  value={value}
                  onSearch={(e) => onSearchProductGroup(e,res,field)}
                  placeholder={field === "primary_product_group_id"
                    || field === "secondary_product_group_id"
                    || field === "tertiary_product_group_id" ? "Type to see more options..." : ""}
                >
                  {optionValues}
                </Select>
              )
              }
            </Field >
          )
      }



      if (data_type === 'SELECT') {
        if( getSelectedValue(options) !== null && !(Array.isArray(getSelectedValue(options))) ){
          return (
              <Field key={i} label={label}
                validateStatus={errors[field] ? 'error' : null}
                help={errors[field] && errors[field][0]}
              >
                {getFieldDecorator(field, {
                  valuePropName: field,
                  initialValue: String(getSelectedValue(options)),
                  rules: [{ required, message: `Please input the ${label}!` }]
                })(
                  <Select 
                    defaultValue={String(getSelectedValue(options))}
                    onChange={(e) => onChangeGroup(e, field, res)}
                    optionFilterProp="children"
                    showSearch
                  >
                    {options.map(item => <Select.Option key={item.value}>{item.text}</Select.Option>)}
                  </Select>
                )}
              </Field>
            )
        }else{
          return (
            <Field key={i} label={label}
              validateStatus={errors[field] ? 'error' : null}
              help={errors[field] && errors[field][0]}
            >
              {getFieldDecorator(field, {
                valuePropName: field,
                initialValue: '',
                rules: [{ required, message: `Please input the ${label}!` }]
              })(
                <Select  
                  onChange={(e) => onChangeGroup(e, field, res)}
                  optionFilterProp="children"
                  showSearch
                >
                  {options.map(item => <Select.Option key={item.value}>{item.text}</Select.Option>)}
                </Select>
              )}
            </Field>
          )
        }
      }

      if (data_type === 'BOOLEAN') {
        return (
          <Field key={i} label={label}>
            {getFieldDecorator(field, {
              valuePropName: field,
              initialValue: (value === null ? false : (value === undefined ? false : value)),
              rules: [{ required, message: `Please input the ${label}!` }]
            })(<Checkbox defaultChecked={value} onChange={(e) => onChangeGroup(e.target.checked, field, res)} />)}
          </Field>
        )
      }

      return false;
    })
    setFormItems(formItems);
  }

  useEffect(() => {
    ZenSmartAPI.post(productGroupsSearchRoute(""), { limit: 50 }).then(res => {
      setGroupValues(res.data.data)
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getInputs()
  }, [groupValues]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <StyledForm onSubmit={handleSubmit} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
      <Collapse activeKey="0">
        <Collapse.Panel key="0" showArrow={false} header="Product Definition">
          {formItems}
          <SubmitContainer>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting}
            >
              Create New Product
            </Button>
          </SubmitContainer>
        </Collapse.Panel>
      </Collapse>
    </StyledForm>
  )
}

const CreateProductForm = Form.create({ name: 'Create Product Form' })(ActualCreateProductForm)

const CreateProduct = () => {
  return (
    <>
      <Header>
        <Title>
          <Link exact={1} to="/app/prep/product-flow">Product Flow</Link> {">"} Create Product
        </Title>
      </Header>

      <Panel title="CREATE NEW PRODUCT">
        <CreateProductForm />
      </Panel>
    </>
  )
}

export default CreateProduct