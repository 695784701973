import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Select, notification, Form, Spin, Modal, Row, Col, DatePicker } from 'antd'
import { ZenSmartAPI } from 'utils'
import _ from "lodash"
import TableData from './TableData'
import { Button } from '../../../components/zensmart-design-system';
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import VirtualImposiionComponent from './VirtualImpositionComponent'
import { Label } from "components/zensmart-design-system/components/Label/Label.js";
import moment from 'moment'
import { fetchConfigurationInitDataRoute, fetchConfigurationRoute, fetchJitInitProductsRoute, modelDefinitionRoute, printerOutputBatchesEndpoint, fetchPresetConfigFieldsRoute, updateCustomConfigurationFiltersRoute } from "utils/apiRoutes"
import { debounce } from 'utils/debounce'
import ConfigurationSortOrder from '../BatchReleaseDesigner/ConfigurationSortOrder'
import { customDateInputs } from 'utils/customSelectOptionsInput'

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
  
`
const DetailSpan = styled.span`
  color : rgba(136,153,175,255);
`

const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}

.ant-select-lg && path {
    color : blue;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}
.ant-select-lg .ant-select-selection__rendered {
    color : blue;
}
`

const PrinterPanels = (props) => {
    const {
        data,
        loading,
        setLoading,
        finishingTypes,
        presetChangedNotification,
        isAllowedComboBatching,
        productProfiles,
        laminationCodeTypes,
        qaFailAndLaminationCode,
        isBasicVersion,
        allProducts,
    } = props
    const [panelData, setPanelData] = useState({})
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [ifFail, setIfFail] = useState(false)
    const [laminationCode, setLaminationCode] = useState(null)
    const [optionValues, setOptionValues] = useState({ add_to_roll: undefined, target_length: undefined, finishing_type_id: undefined, roll_length_checking: false })
    const [productError, setProductError] = useState(false)
    const [virtualImpositionData, setVirtualImpositionData] = useState(null)
    const [panelLoading, setPanelLoading] = useState(false)
    const [releaseToPrintPayload, setReleaseToPrintPayload] = useState({})
    const [releaseBatchModal, setReleaseBatchModal] = useState(false);
    const [printerOutput, setPrinterOutput] = useState([])
    const [printerOutputLoading, setPrinterOutputLoading] = useState(false)
    const [isProductsLoading, setIsProductsLoading] = useState(false)
    const [isProductsInitiallySet, setIsProductsInitiallySet] = useState(false)
    const [configurationMethod, setConfigurationMethod] = useState('original')
    const [presetConfValues, setPresetConfValues] = useState([])
    const [sortLoading, setSortLoading] = useState(false)
    const [oneConfiguration, setOneConfiguration] = useState({})
    const [fieldSelectValues, setFieldSelectValues] = useState(null)
    const [configurationInitData, setConfigurationInitData] = useState({})
    const [customDataLoading, setCustomDataLoading] = useState(false)
    const [virtualImposeOrReleaseToPrintSuccess, setVirtualImposeOrReleaseToPrintSuccess] = useState('')
    const [predefinedConfId, setPredefinedConfId] = useState(null)
    const [allConfigurations, setAllConfigurations] = useState([])
    const [allConfigurationLoading, setAllConfigurationLoading] = useState(false)
    const [predefinedConfIdError, setPredefinedConfIdError] = useState(false)
    const [productComboError, setProductComboError] = useState('')
    const [allConfigurationsInitData, setAllConfigurationsInitData] = useState([])
    const [productsDropdownValues, setProductsDropdownValues] = useState([])
    const [finishingTypesToRender, setFinishingTypesToRender] = useState([])

    const configurationMethodRef = useRef(configurationMethod);
    const predefinedConfigurationIdRef = useRef(predefinedConfId);
    const customConfigurationIdRef = useRef(oneConfiguration?.id);
    const productsDropdownStatusRef = useRef(false);
    const ifFailRef = useRef(false);
    const laminationCodeRef = useRef(laminationCode);
    const productIdRef = useRef(null);

    const fetchPrinterProducts = (ifloading) => {
        if (!isProductsInitiallySet || ifloading) {
            setIsProductsInitiallySet(true)
            setIsProductsLoading(true)
        }
        const configurationMethod = configurationMethodRef.current;

        let batchingConfigurationId = data.batching_configuration.id

        if (configurationMethod === "predefined") {
            if (predefinedConfigurationIdRef.current) {
                batchingConfigurationId = predefinedConfigurationIdRef.current
            }
        }

        if (configurationMethod === "custom") {
            if (customConfigurationIdRef.current) {
                batchingConfigurationId = customConfigurationIdRef.current
            }
        }

        const payload = {}
        if (laminationCodeRef.current === null) {
            return
        }
        payload.required_filters = isBasicVersion === false ? {
            "product_profile" : productIdRef.current,
            "fails" : ifFailRef.current,
            "lamination_code" : laminationCodeRef.current
        } : {}


        ZenSmartAPI.post(fetchJitInitProductsRoute(data.id, batchingConfigurationId), payload)
            .then((resp) => {
                setProducts(resp.data)
                    const productsDropdownValues = [...resp.data].map(data => ({id: data.product.id, name: data.product.name, code: data.product.code}))
                    setProductsDropdownValues(productsDropdownValues)
                    productsDropdownStatusRef.current = true

                setIsProductsLoading(false)
            })
    }
    const getProductId = (products) => {
        if (ifFail || products.length === 0) {
            return selectedProduct
        }

        const productsToArray = products?.split(",")
        const productsArrayToStringify = []
        productsToArray.forEach(product => {
            const id = allProducts?.filter(value => value?.code === product)[0]?.id
            if (id) {
                productsArrayToStringify.push(id)
            }
        });
        return productsArrayToStringify.join('_')
    }

    const releaseBatch = () => {
        let payload
        if (!data.virtual_imposition) {
            if (!selectedProduct) {
                setProductError(true)
                return
            }
            payload = {
                "printer_id": data.id,
                "product_id": isAllowedComboBatching === "ALIGNMENT" ? selectedProduct : [selectedProduct],
            }
        }
        else {

            payload = releaseToPrintPayload
        }
        payload['batching_configuration_method'] = configurationMethod || null
        if (configurationMethod === "custom") {
            payload['batching_configuration_id'] = oneConfiguration.id
        } else if (configurationMethod === "predefined") {
            if (!predefinedConfId) {
                setPredefinedConfIdError(true)
            }
            payload['batching_configuration_id'] = predefinedConfId
        } else {
            payload['batching_configuration_id'] = data.batching_configuration.id
        }

        if (!selectedProduct || selectedProduct.length < 1) {
            setProductError(true)
            return
        }
        
        setPanelLoading(true)
        ZenSmartAPI.post(_.get(panelData, "ui.batch_endpoint"), payload)
            .then((res) => {
                setPanelLoading(false)
                notification.success({ message: "Batch successful!" })
                setReleaseBatchModal(false)
                setVirtualImpositionData(null)
                setPrinterOutputLoading(true)
                fetchPrinterProducts()
                setVirtualImposeOrReleaseToPrintSuccess('')
                if (configurationMethod === "custom") {
                    const firstProduct = isAllowedComboBatching === "ALIGNMENT" && !ifFail ? getProductId(selectedProduct) : selectedProduct
                    localStorage.removeItem(`preset_conf_data_${firstProduct}_${panelData.id}`)
                }
                setPresetConfValues([])
                setConfigurationMethod('original')
                setSelectedProduct([])
                setPredefinedConfId(null)
                setTimeout(function () {
                    getPrinterOutput()
                }, 5000);
            })
            .catch((err) => {
                setPanelLoading(false)
                if (_.get(err, "response.status") === 400 && _.get(err, "response.data.message") === "Printer Configuration not match!") {
                    presetChangedNotification()
                }
                else {
                    errorNoticationHandler(err, "Cant release batch!")
                }

            })

    }

    const generateDateOptions = (updateFunction, defaultValue, name) => {
        const splitValue = defaultValue && defaultValue.includes('datetime') ? defaultValue.split('datetime:')[1].split(',')[0] : null
        return customDateInputs(value => updateFunction(`datetime:${value}`, name), splitValue)
    }

    const updateConfigurationPresetValues = (value, name, dateString = '', dueDateExtraKey = '') => {
        const presetValues = [...presetConfValues]

        for (let i = 0; i < presetValues.length; i += 1) {
            const presetValue = presetValues[i];

            if (presetValue.name === name) {
                presetValue.value = value ? value : null
                if (presetValue.type === "DATE") {
                    presetValue.value = value ? value : null
                    presetValue.qualifier = value ? value : null
                    if (value === "datetime:custom") {
                        presetValue[dueDateExtraKey] = dateString === '' ? '' : `,${dateString}`
                        presetValue.qualifier = `${value}${presetValue.from}${presetValue.to}`
                    } else {
                        presetValue.from = ''
                        presetValue.to = ''
                    }
                } else {
                    presetValue.qualifier = value ? `is:${value}` : null
                }
                break
            }
        }

        const firstProduct = isAllowedComboBatching === "ALIGNMENT" && !ifFail ? getProductId(selectedProduct) : selectedProduct
        setPresetConfValues(presetValues)
        localStorage.setItem(`preset_conf_data_${firstProduct}_${panelData.id}`, JSON.stringify({ product: firstProduct, config_data: presetValues }));

        const filters = customConfigurationFilters(presetValues)
        updateCustomConfigurationFilters(oneConfiguration.id, filters)
    }

    const productErrorHelpToShow = () => {
        if (productComboError) {
            return productComboError
        } else if (productError) {
            return 'Field is required!'
        }
    }

    const onSearchAllConfigurations = debounce((value) => {
        if (value) {
            if (allConfigurationsInitData?.filter(conf => conf.name.toLowerCase().indexOf(value) !== -1)) {
                const searchedConfs = allConfigurationsInitData?.filter(conf => conf.name.toLowerCase().indexOf(value) !== -1)
                setAllConfigurations(searchedConfs)
            } else {
                setAllConfigurations([])
            }
        } else {
            setAllConfigurations(allConfigurationsInitData)
        }

    }, 500);

    const getPrinterOutput = () => {
        setPrinterOutputLoading(true)
        ZenSmartAPI.post(printerOutputBatchesEndpoint, { printer_id: panelData.id })
            .then((res) => {
                setPrinterOutputLoading(false)
                setPrinterOutput(res.data.data)
            })
            .catch((err) => {
                setPrinterOutputLoading(false)
                errorNoticationHandler(err, "Cant fetch output!")

            })
    }

    const handleBatch = () => {
        if (!selectedProduct || selectedProduct.length < 1) {
            setProductError(true)
            return
        }
        setReleaseBatchModal(true)
    }

    const onSearchField = debounce((value, prefix, inputType) => {
        ZenSmartAPI.get(modelDefinitionRoute(`${prefix}.`), {})
            .then((res) => {
                const includedFieldValues = res.data.queryable_paths.length > 0 ? res.data.data?.filter(item => res.data.queryable_paths.includes(item.path)) : res.data.data
                setFieldSelectValues(includedFieldValues)
            })
    });

    const updateCustomConfigurationFilters = debounce((batching_configuration_id=null, filters = []) => {
        ZenSmartAPI.post(updateCustomConfigurationFiltersRoute, {
                printer_id: panelData?.id,
                product_id: isAllowedComboBatching === "ALIGNMENT" && !ifFail ? getProductId(selectedProduct) : selectedProduct,
                batching_configuration_method: configurationMethod,
                batching_configuration_id,
                filters,
            })
            .then((res) => {
                fetchPrinterProducts(true)
            })
            .catch(e => {
                errorNoticationHandler(e, "Can't update printer!")
            })
    }, 500);

    const calculateTimeToPrint = (seconds) => {
        var d = Math.abs(seconds);
        var r = {};
        var s = {
            year: 31536000,
            month: 2592000,
            week: 604800,
            day: 86400,
            hour: 3600,
            minute: 60,
            second: 1
        };

        Object.keys(s).forEach(function (key) {
            r[key] = Math.floor(d / s[key]);
            d -= r[key] * s[key];
        });
        return `${r.day} day ${r.hour} hr ${r.minute} min ${r.second} sec`
    }

    const customConfigurationFilters = (presetValues) => {
        const presetConfigPayload = presetValues.map(({model_definition_id, qualifier, name}) => (
            name !== "sort_by" &&
            model_definition_id &&
            qualifier &&
            ({model_definition_id, qualifier})
        ))
        return presetConfigPayload?.filter(Boolean)
    }

    useEffect(() => {
        if (data) {
            setPanelData(data)

            // fetch products
            fetchPrinterProducts(true)
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedProduct) {
            setProductError(false)
            const firstProduct = isAllowedComboBatching === "ALIGNMENT" && !ifFail ? getProductId(selectedProduct) : selectedProduct
            const productFinishingTypes = products.filter(data => data.product.id === parseInt(firstProduct))[0]?.finishing_types
            let formattedProductFinishingTypes = productFinishingTypes?.map(({finishing_type_id, finishing_type}) => ({ id: finishing_type_id, name: finishing_type }))
            if (isAllowedComboBatching === "ALIGNMENT" && !ifFail && selectedProduct.length > 0) {
                getProductId(selectedProduct).split("_").forEach(productId => {
                    let finishingTypeExistingInCurrentInteratedProduct = []
                    const productIdFinishingTypes = products.filter(data => data.product.id === parseInt(productId))[0]?.finishing_types
                        
                    if (productIdFinishingTypes && productIdFinishingTypes.length > 0) {
                        productIdFinishingTypes.forEach(productIdFinishingType => {
                            const finishingTypesInProducts = formattedProductFinishingTypes.filter(productFinishingType => productFinishingType.id === productIdFinishingType.finishing_type_id)
                            if (finishingTypesInProducts.length > 0) {
                                finishingTypesInProducts.forEach(finishingTypesInProduct => {
                                    finishingTypeExistingInCurrentInteratedProduct.push({id: finishingTypesInProduct.id, name: finishingTypesInProduct.name})
                                });
                            }
                        })
                    }
                    formattedProductFinishingTypes = [...finishingTypeExistingInCurrentInteratedProduct]
                });
            }
            setFinishingTypesToRender(formattedProductFinishingTypes?.length > 0 ? formattedProductFinishingTypes : [])
        }

    }, [selectedProduct, products]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (panelData.id) {
            getPrinterOutput()
        }
    }, [panelData]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (laminationCodeTypes.length > 0) {
            setLaminationCode(laminationCodeTypes[0])
            laminationCodeRef.current = laminationCodeTypes[0]
        }
    }, [laminationCodeTypes]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (predefinedConfId) {
            fetchPrinterProducts(true)
        } else {
            predefinedConfigurationIdRef.current = predefinedConfId
        }
    }, [predefinedConfId])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (finishingTypes.length > 0) {
            setFinishingTypesToRender(finishingTypes)
        }
    }, [finishingTypes])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (configurationMethod === "custom" &&
            selectedProduct &&
            !virtualImposeOrReleaseToPrintSuccess
        ) {
            const firstProduct = isAllowedComboBatching === "ALIGNMENT" && !ifFail ? getProductId(selectedProduct) : selectedProduct
            setCustomDataLoading(true)
            ZenSmartAPI.get(`${fetchConfigurationRoute}/${_.get(panelData, "batching_configuration.id")}/${firstProduct}/${_.get(panelData, "suffix")}`)
            .then((res) => {
                const oneConfFromDb = res.data.data
                setOneConfiguration(res.data.data)
                customConfigurationIdRef.current = res.data.data.id
                ZenSmartAPI.get(fetchConfigurationInitDataRoute)
                    .then((res) => {
                        setConfigurationInitData(res.data.data)
                        ZenSmartAPI.get(fetchPresetConfigFieldsRoute)
                            .then((res) => {
                                const presetValues = res.data
                                let presetConfData = []
                                const localStoragePresetConfData = localStorage.getItem(`preset_conf_data_${firstProduct}_${panelData.id}`) ? JSON.parse(localStorage.getItem(`preset_conf_data_${firstProduct}_${panelData.id}`)) : null
                                if (localStoragePresetConfData !== null && localStoragePresetConfData.product === firstProduct) {
                                    presetConfData = localStoragePresetConfData.config_data
                                    for (let i = 0; i < presetConfData.length; i += 1) {
                                        const localStorageData = presetConfData[i];

                                        for (let j = 0; j < presetValues.length; j += 1) {
                                            const resData = presetValues[j];
                                            if (localStorageData.name === resData.name && (localStorageData.values === false || resData.values === false)) {
                                                if (resData.values === false) {
                                                    localStorageData.qualifier = null
                                                    localStorageData.value = null;
                                                    if(localStorageData.name === "due_date") {
                                                        localStorageData.from = '';
                                                        localStorageData.to = '';
                                                    }
                                                }
                                                localStorageData.values = resData.values
                                                
                                                break
                                            }
                                        }
                                    }
                                    localStorage.setItem(`preset_conf_data_${firstProduct}_${panelData.id}`, JSON.stringify({product: firstProduct, config_data: presetConfData}));
                                } else {
                                    presetValues.map((data) => {
                                        data.value = null;
                                        data.qualifier = null;
                                        if(data.name === "due_date") {
                                            data.from = '';
                                            data.to = '';
                                        }
                                        return data;
                                    })
                                    presetConfData = presetValues
                                }
                                
                                setPresetConfValues(presetConfData.sort((a, b) => b.type.localeCompare(a.type)))
                                const newPresetConfValues = [...presetConfData]
                                const filters = customConfigurationFilters(newPresetConfValues)
                                updateCustomConfigurationFilters(oneConfFromDb.id, filters)
                                setCustomDataLoading(false)
                            })
                            .catch((err) => {
                                errorNoticationHandler(err, "Cant fetch product preset data!")
                            })
                    })
                    .catch((err) => {
                        errorNoticationHandler(err, "Cant fetch configuration init data!")
                    })
            })
            .catch((err) => {
                errorNoticationHandler(err, "Cant fetch configuration data!")
            })
            onSearchField("", "lines")
        }

        if (!selectedProduct) {
            setPresetConfValues([])
            setFinishingTypesToRender(finishingTypes)
        }

        if (configurationMethod === "predefined") {
            setAllConfigurationLoading(true)
            ZenSmartAPI.get(`${fetchConfigurationRoute}/get-all-configurations`)
            .then((res) => {
                setAllConfigurationsInitData(res.data.data.sort((a, b) => a.name.localeCompare(b.name)))
                setAllConfigurations(res.data.data.sort((a, b) => a.name.localeCompare(b.name)))
                setAllConfigurationLoading(false)
            })
            .catch((err) => {
                setAllConfigurationLoading(false)
                errorNoticationHandler(err, "Cant fetch configurations!")
            })
        }

        if (virtualImposeOrReleaseToPrintSuccess && configurationMethod !== 'original') {
            if (virtualImposeOrReleaseToPrintSuccess === 'virtual-imposition') {
                setVirtualImposeOrReleaseToPrintSuccess('after-virtual-impose')
            }
        }

        if (configurationMethod) {
            configurationMethodRef.current = configurationMethod;
            if (configurationMethod !== "custom") {
                setOneConfiguration({})
                customConfigurationIdRef.current = null
            }
            if (isAllowedComboBatching === "ALIGNMENT") {
                fetchPrinterProducts(true)
            }
        }
    }, [configurationMethod, selectedProduct, virtualImposeOrReleaseToPrintSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (ifFail === true) {
            productIdRef.current = null
        }
        if (configurationMethod === "predefined" && predefinedConfigurationIdRef.current) {
            fetchPrinterProducts(true)
        }

        if (configurationMethod === "custom" && customConfigurationIdRef.current) {
            fetchPrinterProducts(true)
        }

        if (configurationMethod === "original") {
            fetchPrinterProducts(true)
        }
    }, [ifFail, laminationCode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (configurationMethod !== "custom") {
            productIdRef.current = null
        }
    }, [configurationMethod]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ModalStyle />

            <div style={{ margin: 20, width: 550 }}>
                <Panel title="">
                    <Spin tip="Loading..." spinning={panelLoading}>

                        <div>
                            <div style={{ padding: 20 }}>
                                <Heading style={{ textAlign: "center" }}>{panelData.full_name}</Heading>
                                <br />
                                <h1>CONFIGURATION </h1>
                                <h1 style={{ textAlign: "center", border: "1px solid gray", padding: 10, borderRadius: 5 }}>
                                    {_.get(panelData, "batching_configuration.name")}</h1>
                                <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
                                    {!isBasicVersion && <Form.Item
                                        style={{
                                            width: "45%",
                                            marginBottom: 0,
                                            fontWeight: "bold"
                                        }}
                                        label="Preset Type"
                                    >

                                        <Select
                                            style={{width: "100%"}}
                                            placeholder="Select Configuration"
                                            value={configurationMethod ||
                                            undefined}
                                            onChange={(e) => {
                                                setSelectedProduct(null)
                                                setProductComboError('')
                                                setProductError(false)
                                                setPresetConfValues([])
                                                setPredefinedConfId(null)
                                                setConfigurationMethod(e)

                                            }}
                                        >
                                            <Select.Option value="original">Default
                                                Batching
                                                Configuration</Select.Option>
                                            <Select.Option
                                                value="custom">Custom</Select.Option>
                                            <Select.Option
                                                value="predefined">Predefined</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    }
                                    <Form.Item
                                        validateStatus={productError || productComboError ? "error" : undefined}
                                        help={productErrorHelpToShow()}
                                        style={{ width: !isBasicVersion ? '45%' : '100%', marginBottom: 0, fontWeight: "bold" }}
                                        label="Product"
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder={isAllowedComboBatching ? "Select product(s)..." : "Select a product"}
                                            optionFilterProp="children"
                                            defaultValue={selectedProduct}
                                            value={selectedProduct || undefined}
                                            onChange={(e) => {
                                                if (virtualImpositionData) {
                                                    Modal.confirm({
                                                        'content': "Changing of fields will reset the virtual imposition datas do you want to continue?",
                                                        onOk: () => {
                                                            setSelectedProduct(e)
                                                            productIdRef.current = isAllowedComboBatching === "ALIGNMENT" && !ifFail ? e : null
                                                            setVirtualImpositionData(null)
                                                        },
                                                    });
                                                }
                                                else {
                                                    setSelectedProduct(e)
                                                    productIdRef.current = isAllowedComboBatching === "ALIGNMENT" && !ifFail ? e : null
                                                }
                                                return

                                            }}
                                        >
                                            {isAllowedComboBatching === "ALIGNMENT" && productProfiles.length > 0 && !ifFail ?
                                                productProfiles.sort((a, b) => a.localeCompare(b)).map(data =>
                                                <Select.Option value={data} key={data}>{data}</Select.Option>) : productsDropdownValues ?
                                                productsDropdownValues.sort((a, b) => a.name.localeCompare(b.name)).map(data =>
                                                    <Select.Option value={data.id} key={data.id}>{data.name}</Select.Option>
                                                ) : undefined}

                                        </Select>
                                    </Form.Item>
                                </div>
                                {!isBasicVersion &&
                                <div style={{
                                    marginBottom: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <Form.Item
                                        label="Fails"
                                        style={{
                                            width: "45%",
                                            marginBottom: 0,
                                            fontWeight: "bold"
                                        }}
                                    >
                                        <Select
                                            showSearch
                                            style={{width: "100%"}}
                                            placeholder={'If Fails'}
                                            optionFilterProp="children"
                                            defaultValue={ifFail}
                                            value={ifFail}
                                            onChange={(e) => {

                                                if (virtualImpositionData) {
                                                    Modal.confirm({
                                                        'content': "Changing of fields will reset the virtual imposition datas do you want to continue?",
                                                        onOk: () => {
                                                            setIfFail(e)
                                                            ifFailRef.current = e
                                                            if (isAllowedComboBatching ===
                                                                "ALIGNMENT" &&
                                                                productProfiles.length >
                                                                0 && e &&
                                                                selectedProduct) {
                                                                setSelectedProduct(
                                                                    null)
                                                            } else if (!e) {
                                                                setSelectedProduct(
                                                                    null)
                                                            }
                                                            setVirtualImpositionData(
                                                                null)
                                                        },
                                                    });
                                                } else {
                                                    setIfFail(e)
                                                    ifFailRef.current = e
                                                    if (isAllowedComboBatching ===
                                                        "ALIGNMENT" &&
                                                        productProfiles.length >
                                                        0 && e &&
                                                        selectedProduct) {
                                                        setSelectedProduct(null)
                                                    } else if (!e) {
                                                        setSelectedProduct(null)
                                                    }
                                                }

                                                return

                                            }}
                                        >
                                            <Select.Option value={true}
                                                           key={true}>TRUE</Select.Option>
                                            <Select.Option value={false}
                                                           key={false}>FALSE</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        // validateStatus={productError || productComboError ? "error" : undefined}
                                        // help={productErrorHelpToShow()}
                                        style={{
                                            width: "45%",
                                            marginBottom: 0,
                                            fontWeight: "bold"
                                        }}
                                        label="Lamination Code"

                                    >
                                        <Select
                                            showSearch
                                            style={{width: "100%"}}
                                            placeholder={'Lamination Code'}
                                            optionFilterProp="children"
                                            defaultValue={laminationCode}
                                            value={laminationCode}
                                            onChange={(e) => {
                                                if (virtualImpositionData) {
                                                    Modal.confirm({
                                                        'content': "Changing of fields will reset the virtual imposition datas do you want to continue?",
                                                        onOk: () => {
                                                            setLaminationCode(e)
                                                            laminationCodeRef.current = e
                                                            setVirtualImpositionData(
                                                                null)
                                                        },
                                                    });
                                                } else {
                                                    setLaminationCode(e)
                                                    laminationCodeRef.current = e
                                                }
                                                return

                                            }}
                                        >
                                            {laminationCodeTypes.map(
                                                laminationItem => {
                                                    return <Select.Option
                                                        value={laminationItem}
                                                        key={laminationItem}>{laminationItem}</Select.Option>
                                                })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                }

                                {configurationMethod === "custom" && selectedProduct && (
                                    <Spin tip="Loading..." spinning={customDataLoading}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                        {presetConfValues.length > 0 && presetConfValues?.filter(presetConf => presetConf.type !== "sorter").map(conf => {
                                            return (
                                            <div 
                                                style={{
                                                    width: conf.type !== "DATE" ? "45%" : "100%",
                                                    margin: "5px 0",
                                                    display: conf.type !== "DATE" ? "block" : "flex",
                                                    justifyContent: 'space-between'
                                                }}
                                                key={conf.name}>
                                                {conf.type === "SELECT" && (
                                                    <label style={{ width: conf.type === "SELECT" ? "100%" : "45%" }}>
                                                        <>{conf.displayName}:</><br />
                                                        <Select
                                                            style={{ width: "100%" }}
                                                                placeholder={"ANY"}
                                                            value={conf?.value || undefined}
                                                            dropdownMatchSelectWidth={false}
                                                            onChange={(e) => {
                                                                updateConfigurationPresetValues(e, conf.name)
                                                            }}
                                                        >
                                                            <Select.Option value={''}>Select an option</Select.Option>
                                                            {conf?.values?.filter(Boolean).map(value => (
                                                                <Select.Option key={value} value={value}>{value}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </label>
                                                    
                                                )}
                                                {conf.type === "DATE" && (
                                                    <div style={{width: '100%'}}>
                                                        <p>{conf.displayName}:</p>
                                                        <div style={{display: 'flex', justifyContent: "space-between"}}>
                                                            <div style={{width: '30%'}}>
                                                                {generateDateOptions(updateConfigurationPresetValues, _.get(null, 'qualifier', conf.value), conf.name)}
                                                            </div>
                                                            {conf.value === "datetime:custom" &&
                                                                <>
                                                                <div style={{ width: '30%' }}>
                                                                    <DatePicker
                                                                        style={{ width: '100%' }}
                                                                        onChange={(value, dateString) => updateConfigurationPresetValues("datetime:custom", conf.name, dateString, "from")}
                                                                        defaultValue={conf.from ? moment(moment(conf.from.substring(1)), 'YYYY-MM-DD') : undefined}
                                                                    />

                                                                </div>
                                                                <div span="4" style={{ width: '30%' }}>
                                                                    <DatePicker
                                                                        style={{ width: '100%' }}
                                                                        onChange={(value, dateString) => updateConfigurationPresetValues("datetime:custom", conf.name, dateString, "to")}
                                                                        defaultValue={conf.to ? moment(moment(conf.to.substring(1)), 'YYYY-MM-DD') : undefined}
                                                                    />
                                                                </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                        
                                                    
                                                )}
                                            </div>
                                        )})}
                                    </div>
                                    {presetConfValues.length > 0 && presetConfValues?.filter(data => data?.name === "sort_by")[0].type === "sorter" && (
                                                <Spin tip="Loading..." spinning={sortLoading}>
                                                  <h2 style={{ paddingBottom: 10 }}>CURRENT SORT ORDER</h2>
                                                  <ConfigurationSortOrder
                                                    oneConfiguration={oneConfiguration}
                                                    presetProductId={selectedProduct}
                                                    loading={sortLoading}
                                                    setLoading={setSortLoading}
                                                    fieldSelectValues={fieldSelectValues}
                                                    configurationInitData={configurationInitData}
                                                  />
                                                </Spin>
                                                
                                            )}
                                    </Spin>
                                )}

                                {configurationMethod === "predefined" && (
                                    <Spin tip="Loading..." spinning={allConfigurationLoading}>
                                        <Form.Item
                                            validateStatus={predefinedConfIdError ? "error" : undefined}
                                            help={predefinedConfIdError ? "Field is required!" : undefined}
                                        >
                                            <label>
                                                <>Select a configuration:</><br />
                                                <Select
                                                    style={{ width: "100%" }}
                                                    showSearch
                                                    placeholder={'select a configuration' || undefined}
                                                    value={predefinedConfId || undefined}
                                                    onChange={(e) => {
                                                        setPredefinedConfId(e)
                                                        predefinedConfigurationIdRef.current = e
                                                        setPredefinedConfIdError(false)
                                                    }}
                                                    onSearch={onSearchAllConfigurations}
                                                    filterOption={false}
                                                >
                                                    {allConfigurations.map(conf => (
                                                        <Select.Option key={conf.id} value={conf.id}>{conf.name}</Select.Option>
                                                    ))}
                                                </Select>
                                            </label>
                                        </Form.Item>
                                    </Spin>
                                )}
                            </div>
                            <div style={{ height: 300, marginBottom: "10px", borderBottom: "1px solid blue" }}>
                                <TableData data={products} loading={isProductsLoading} columnValues={['product', 'units', 'wip', 'backlog', 'fails']} />
                            </div>
                            {data.virtual_imposition && <VirtualImposiionComponent
                                panelData={panelData}
                                virtualImpositionData={virtualImpositionData}
                                setVirtualImpositionData={setVirtualImpositionData}
                                selectedProduct={selectedProduct}
                                loading={loading}
                                setLoading={setLoading}
                                setProductError={setProductError}
                                setPanelLoading={setPanelLoading}
                                panelLoading={panelLoading}
                                optionValues={optionValues}
                                setOptionValues={setOptionValues}
                                setReleaseToPrintPayload={setReleaseToPrintPayload}
                                finishingTypes={finishingTypesToRender}
                                calculateTimeToPrint={calculateTimeToPrint}
                                presetChangedNotification={presetChangedNotification}
                                presetConfValues={presetConfValues}
                                configurationMethod={configurationMethod}
                                oneConfiguration={oneConfiguration}
                                setVirtualImposeOrReleaseToPrintSuccess={setVirtualImposeOrReleaseToPrintSuccess}
                                predefinedConfId={predefinedConfId}
                                setPredefinedConfIdError={setPredefinedConfIdError}
                                isAllowedComboBatching={isAllowedComboBatching}
                                ifFail={ifFail}
                                laminationCode={laminationCode}
                                productIdRef={productIdRef}
                                isBasicVersion={isBasicVersion}
                            />}
                            < br />
                            {panelData.virtual_imposition && !virtualImpositionData ? null : <h2 style={{ textAlign: "center" }}>
                                <Button size="large" onClick={handleBatch}>{_.get(panelData, "ui.button_text")}</Button></h2>}
                            < br />

                            <div style={{ padding: 20 }}>
                                <h1>OUTPUT </h1>
                                <Spin tip="Updating..." spinning={printerOutputLoading}>
                                    <div style={{ border: "1px solid blue", borderRadius: 5, height: 100, overflowY: "auto", padding: 10, }}>
                                        {printerOutput.length > 0 ? printerOutput.map(data =>
                                            <h1 style={{ color: "rgba(136,153,175,255)" }}>{data.value}</h1>
                                        ) :
                                            <h1 >No batch released available.</h1>

                                        }
                                    </div>
                                </Spin>

                            </div>

                        </div>
                    </Spin>
                </Panel>
            </div>

            <Modal
                title=""
                width={500}
                visible={releaseBatchModal}
                footer={null}
                centered
            >
                <Spin tip="Loading..." spinning={panelLoading}>
                    {data.virtual_imposition &&
                        <div>
                            <h2 style={{ textAlign: "center", fontSize: 18 }}>Please confirm the following:</h2>
                            <div style={{ padding: 30 }}>
                                <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : "green" }}>Batches to be Formed: {virtualImpositionData && virtualImpositionData.batches_to_be_formed} </DetailSpan></h1>
                                <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : "green" }}>Time to Print: {virtualImpositionData && calculateTimeToPrint(virtualImpositionData.expected_completion_time)} </DetailSpan></h1>
                                <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : "green" }}>Actual Paper Length: {virtualImpositionData && `${virtualImpositionData.total_length.toLocaleString()} Feet`} </DetailSpan></h1>
                                <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : virtualImpositionData.threshold_warning ? "red" : "green" }}>
                                    Efficiency: {virtualImpositionData && virtualImpositionData.efficiency} {virtualImpositionData && virtualImpositionData.threshold_warning &&
                                        <Label status={"red"}>
                                            {virtualImpositionData.threshold_warning}
                                        </Label>
                                    } </DetailSpan></h1>
                                <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : "green" }}>WIP: {virtualImpositionData && `${virtualImpositionData.wip_percentage}%`} </DetailSpan></h1>
                                <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : "green" }}>Lanes Occupied: {virtualImpositionData && `${virtualImpositionData.lanes_occupied}`} </DetailSpan></h1>

                            </div>
                        </div>
                    }
                    <h2 style={{ textAlign: "center", fontSize: 18 }}>Would you like to proceed?</h2>
                    <Row type="flex" justify="center" style={{ padding: 20 }}>
                        <Col style={{ padding: 5 }} >
                            <Button type="primary" style={{ width: "100%" }} loading={panelLoading} onClick={releaseBatch}>Send to Print</Button>
                        </Col>
                        <Col style={{ padding: 5 }} >
                            <Button type="danger" style={{ width: "100%", background: "red" }} onClick={() => setReleaseBatchModal(false)}>Cancel</Button>
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        </>
    )
}

export default PrinterPanels