import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Button } from 'antd';
import AvailableFields from './AvailableFields';
import { Label } from 'components/zensmart-design-system';

const panelFieldStyle = {
    border: '1px solid #1890ff',
    borderRadius: '4px',
    padding: '8px',
    marginBottom: '8px',
    backgroundColor: '#f0f5ff',
}

const tableFieldStyle = {
    display: 'inline-block',
    margin: '8px 8px',
    border: '1px solid #1890ff',
    borderRadius: '4px',
    padding: '8px',
    backgroundColor: '#f0f5ff',
}

const panelAreaStyle = {
    minHeight: '300px',
    padding: '16px',
}

const tableAreaStyle = {
    minHeight: '100px',
    flexWrap: 'wrap',
    padding: '8px 16px 8px 8px',
    overflowX: 'auto',
    whiteSpace: 'nowrap'
}


function EditDataViewFields(props) {
    const { sections } = props;

    return (
        <>
            {sections.map((section) => (
                <div key={section.id}>
                    <div style={{ paddingBottom: '8px', marginTop: '16px' }}>
                        <Label style={{ paddingBottom: '4px', marginBottom:'4px' }} status="blue">{section.section_header}</Label>
                        <AvailableFields section={section} fieldsToDisplay={section.available_fields} />
                    </div>

                    {section && (
                        <Row gutter={[16, 16]}>
                            {section.section_data.map((column) => (
                                <Col key={column.id} span={section.render_as === 'panel' ? 12 : 24}>
                                    <Droppable key={`${section.id}_${column.id}`} droppableId={`${section.id}_${column.id}`} direction={section.render_as === 'panel' ? "vertical" : "horizontal"}>
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                    backgroundColor: snapshot.isDraggingOver ? '#e6f7ff' : '#fff',
                                                    border: '2px dashed #d9d9d9',
                                                    borderRadius: '4px',
                                                    ...(section.render_as === 'panel' ? panelAreaStyle : tableAreaStyle)
                                                }}
                                            >
                                                {column.fields
                                                    .sort((a, b) => a.sort_order - b.sort_order)
                                                    .map((field, index) => (
                                                        <Draggable
                                                            key={`${section.resource_name}_${field.field}`}
                                                            draggableId={`${section.resource_name}_${field.field}`} // this is indecator 
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    id={`${section.resource_name}_${field.field}`}
                                                                    style={{
                                                                        ...provided.draggableProps.style,
                                                                        ...(section.render_as === 'panel' ? panelFieldStyle : tableFieldStyle)

                                                                    }}
                                                                >
                                                                    {field.label}
                                                                </div>

                                                            )}
                                                        </Draggable>
                                                    ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </Col>
                            ))}
                        </Row>
                    )}
                </div>
            ))}
        </>
    );
}

export default EditDataViewFields;
