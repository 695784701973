import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Input, Button } from 'antd'
import BatchedTableData from './BatchedTableData'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { alphabeticalData } from 'utils/sortData';
import moment from 'moment';
import { applySelectedCss, getHeaders, searchOperation, searchOperationWithoutDelay, applyFails } from './PressReleaseHelperFunctions'

const { Option } = Select;

const BatchedPanel = (props) => {
    const { loading, setLoading, itemData, setItemData, originalData, selectedBatched, setSelectedBatched, labels, buttonLoading,
        getPrintersToBeSend, batchedFilterData, batchedColumnFilterDataObject, setBatchedColumnFilterDataObject, batchPanelInputValue, ascend, setAscend, pressRelease, userData,
        setCurrentSearchKey } = props
    const [columns, setColumns] = useState([])
    const [sortColumnsClasses, setSortColumnClasses] = useState(null)
    const [searchFilterValue, setSearchFilterValue] = useState("")

    var getTable = document.getElementById(`batch-table`)
    if (getTable) {
        const getTr = getTable.getElementsByTagName(`tr`)[0]
        if (getTr) {
            const getSortWidth = getTr.getElementsByTagName(`th`);
            if (Array.from(getSortWidth).length > 0 && sortColumnsClasses === null) {
                const filterWidth = Array.from(getSortWidth).map(item => item.clientWidth)
                setSortColumnClasses(filterWidth)
            }
        }
    }

    const setColumnDataFilterFunction = (value, property) => {
        setBatchedColumnFilterDataObject({ ...batchedColumnFilterDataObject, [property]: value })
    }

    const setColumnFiltersLogic = (searchInputValue) => {
        var arrayValue = [];
        if (searchInputValue !== "") {
            arrayValue = searchOperationWithoutDelay(searchInputValue, originalData, originalData, setItemData, setSearchFilterValue, setColumnFiltersLogic,
                batchedColumnFilterDataObject)
        }
        else {
            arrayValue = originalData
        }
        Object.keys(batchedColumnFilterDataObject).map(item => {
            if (batchedColumnFilterDataObject[item] !== "") {
                arrayValue = arrayValue.filter(data => {
                    const dataItem = item === "fails" && data[item] === 0 ? "False" : item === "fails" && data[item] > 0 ? "True" : data[item]
                    return dataItem === batchedColumnFilterDataObject[item]
                })
            }
            return item
        })
        setItemData(arrayValue)
    }

    const renderFilterColumnDatas = (data) => {
        const returnValue = Object.keys(batchedFilterData).map((item, key) => {
            const getTableHeaderWidth = sortColumnsClasses[key];
            if (getTableHeaderWidth && !pressRelease.hide_columns.includes(item)) {
                return <div style={{ textAlign: "center", padding: "10px 2px 15px 2px", width: getTableHeaderWidth }}>
                    <Select defaultValue={""} style={{ marginTop: 10, width: '100%' }}
                        optionFilterProp="children"
                        showSearch
                        dropdownMatchSelectWidth={false}
                        onChange={(e) => setColumnDataFilterFunction(e, item)}
                    >
                        <Option value={""} style={{ fontWeight: "bold" }}>{batchedFilterData[item].label}</Option>
                        {batchedFilterData[item].date_data ? alphabeticalData(batchedFilterData[item].date_data).map(data =>
                            <Option value={moment(data).format('MMM DD ddd hh:mm').toString()}>{moment(data).format('MMM DD ddd hh:mm').toString()}</Option>
                        )
                            : alphabeticalData(batchedFilterData[item].data).map(data =>
                                <Option value={data}>{data}</Option>
                            )}
                    </Select>

                </div >
            }
            return null
        })
        return <div style={{ position: "relative", overflowX: "auto", width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
                {returnValue}
            </div>
        </div>
    }


    useEffect(() => {
        const columnData = [];
        Object.keys(labels).map(data => {
            if (!pressRelease.hide_columns.includes(data)) {
                columnData.push({
                    title: getHeaders(data, labels[data].label.toUpperCase(), false, itemData, labels, ascend, setAscend, setItemData, userData.details.id, pressRelease.id, null, "batched"),
                    dataIndex: data,
                    key: data,
                    width: data === "id" ? "160px" : null,
                    render: (value, row) => {
                        if (data === "id") {
                            applySelectedCss(value, 'selected-batch', selectedBatched)
                            if (row.fails > 0) {
                                applyFails(value, 'failed-batch')
                            }

                            if (row.stuck === "TRUE") {
                                applyFails(value, 'stuck-batch')
                            }
                        }
                        if (data === "fails") {
                            return <div>{value > 0 ? <ExclamationCircleOutlined
                                style={{
                                    color: 'white',
                                    backgroundColor: '#E84A50',
                                    fontSize: 20,
                                    borderRadius: '50%',
                                    width: 20,
                                    height: 20
                                }}
                            /> : '-'}</div>
                        }
                        else if (data === "linear_length") {
                            return <div>{value !== null ? parseFloat(value).toFixed(2) : "-"}</div>
                        }
                        else {
                            return <div>{value !== null ? value : "-"}</div>
                        }
                    }
                })
            }
            return data
        })
        setColumns(columnData)

    }, [ascend, labels, itemData, selectedBatched]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(batchedColumnFilterDataObject).length > 0) {
            setColumnFiltersLogic(searchFilterValue)
        }

    }, [batchedColumnFilterDataObject, originalData]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <div style={{ padding: 5, minHeight: "75vh" }} className="panel-div">
                <Row type="flex" align="middle" justify="space-between" style={{ padding: 10 }}>
                    <Col span={8} style={{ padding: 10 }}>
                        <Input.Search
                            type="text"
                            placeholder="Search"
                            disabled={loading}
                            onChange={(value) => searchOperation(value.target.value, originalData, originalData, setItemData, setSearchFilterValue, setColumnFiltersLogic, batchedColumnFilterDataObject, setCurrentSearchKey)}
                            loading={loading}
                            ref={batchPanelInputValue}
                        />
                    </Col>
                    <Col span={4} style={{ padding: 10 }}>
                        <Button type="primary" style={{ width: "100%" }} loading={buttonLoading} onClick={() => getPrintersToBeSend("batched")}>Send</Button>
                    </Col>
                </Row>

                <BatchedTableData loading={loading}
                    setLoading={setLoading}
                    columns={columns}
                    itemData={itemData}
                    selectedBatched={selectedBatched}
                    setSelectedBatched={setSelectedBatched}
                    labels={labels} />

                {columns.length > 0 && sortColumnsClasses && !loading &&
                    <div >
                        {renderFilterColumnDatas(itemData)}
                    </div>

                }
            </div>
        </>
    )
}

export default BatchedPanel
