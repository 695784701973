import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Row, Col, notification, Tag } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import Cookies from 'js-cookie';
import MatchLookup from './matchLookup'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js'
import { dueDateColor } from './mockData';
import PrintThisComponent from "components/composites/printTicket"
import airhorn from 'static/sounds/airhorn.mp3'

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const Container = styled.div`
  padding-bottom: 5px;
  font-family: 'Rubik', sans-serif;
`
const scanInputProps = {
    size: 'large',
    placeholder: 'Item Lookup'
}

const Bold = styled.span`
  font-weight: bold;
  margin-right: 8px;
  color: ${color.heading};
`
const DueDateTag = styled(Tag)`
padding: 5px 20px;
font - size : 15px;
`
const dataProps = { fontSize: 15, padding: 5 };

const MatchIn = (props) => {
    const [scanItem, setScanItem] = useState(null)
    const { scanData } = props
    const scanTypeId = scanData.id;
    const sound = new Audio(airhorn)

    const handleLookup = useCallback((lookupValue) => {

        if (lookupValue.hasOwnProperty('error')) {
            sound.play()
            notification.error({
                message: lookupValue.error
            })
        }

        else if (lookupValue.hasOwnProperty('station_id')) {
            Cookies.set('STATION', lookupValue.station_id, { expires: 1 });
            Cookies.set('SCAN_TYPE', scanTypeId, { expires: 1 });
            Cookies.set("ALLOW_QUIZ", lookupValue?.allow_quiz ?? false, { expires: 1 });
            notification.success({
                message: 'Station Scan Successful'
            })
        }

        else if (lookupValue.hasOwnProperty('success_scan')) {
            notification.success({
                message: 'Barcode Scan Successful'
            })
            setScanItem(lookupValue)
        }
        else {
            sound.play()
            notification.error({
                message: 'Invalid scan item. Please scan a valid barcode.'
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const removeScanItem = useCallback(() => {
        setScanItem(null)
    }, [])

    const getToUrl = (value) => {
        window.location.href = value
    }

    return (
        <>
            <Container>
                <Header>
                    <Title>People {">"} Scans {">"} Match</Title>
                </Header>
                <div>
                    <MatchLookup
                        title="MATCH SCAN"
                        isViewingDetails={scanItem}
                        setScanItem={removeScanItem}
                        detailLabel={'PLEASE SCAN ITEM'}
                        onLookup={handleLookup}
                        inputProps={scanInputProps}
                        scanTypeId={scanTypeId}
                    >
                        {scanItem ?
                            <Col span={24}>
                                <Col span={24}>
                                    <Label status="mountain_meadow">
                                        SCAN SUCCESSFUL
                                    </Label>
                                </Col>
                                <Col span={24} style={dataProps} >
                                    <br />
                                </Col>
                                <Row>
                                    {scanItem.is_print_job_ticket &&
                                        scanItem.action === "open" ? getToUrl(`${scanItem.ticket_html}${localStorage.token}`)
                                        : < PrintThisComponent url={scanItem.ticket_html} />
                                    }
                                    {
                                        scanItem.page_definitions.sections[0].section_data.map((data, key) =>
                                            <Col span={24} style={dataProps} >
                                                {data.label === "Due Date:" ?
                                                    <>
                                                        <Bold>{data.label}</Bold>
                                                        <DueDateTag color={dueDateColor[data.value.due_date_text.toLowerCase()] ?
                                                            dueDateColor[data.value.due_date_text.toLowerCase()] : "red"}
                                                            key={data.value.due_date_text}>
                                                            {data.value.due_date_text.toUpperCase()}
                                                        </DueDateTag>
                                                        <span> {data.value.formatted_due_date} </span>
                                                    </>

                                                    :
                                                    <>
                                                        <Bold>{data.label}</Bold>
                                                        <span> {data.value} </span>
                                                    </>
                                                }
                                            </Col>
                                        )
                                    }
                                </Row>
                            </Col> : null}
                    </MatchLookup>
                </div>
            </Container>

        </>
    )
}

export default MatchIn