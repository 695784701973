import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Button, DatePicker } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import moment from 'moment';
import { ZenSmartAPI } from 'utils'
import { incomingOrdersRoute } from "utils/apiRoutes"

const Header = styled.header`
  margin-bottom: 24px;
`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const IncomingOrders = (props) => {
    const [fetchDate, setFetchDate] = useState({ from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') })
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const changeDateFrom = (value, dateString) => {
        setFetchDate({ ...fetchDate, from: dateString })
    }
    const changeDateTo = (value, dateString) => {
        setFetchDate({ ...fetchDate, to: dateString })
    }

    const fetchData = () => {
        if (moment(fetchDate.from).isAfter(fetchDate.to)) {
            notification.error({ message: "To date must be greater than from date!" })
        }
        else {
            setLoading(true)
            ZenSmartAPI.get(incomingOrdersRoute(fetchDate.from, fetchDate.to))
                .then((res) => {
                    setLoading(false)
                    setItemData(res.data.data)
                })
                .catch((res) =>{
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        fetchData()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Process Center {">"} Incoming Orders</Title>
            </Header>
            <Panel title="INCOMING ORDERS">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <Row type="flex" align="middle">
                        <DateColStyle span={4} >
                            <Heading>FROM DATE / TIME</Heading>
                            <DatePicker style={{ marginTop: 10, width: '100%' }} onChange={changeDateFrom} defaultValue={moment(new Date(), 'YYYY-MM-DD')} />
                        </DateColStyle>
                        <DateColStyle span={4} >
                            <Heading>TO DATE / TIME</Heading>
                            <DatePicker style={{ marginTop: 10, width: '100%' }} onChange={changeDateTo} defaultValue={moment(new Date(), 'YYYY-MM-DD')} />
                        </DateColStyle>
                        <DateColStyle span={4}>
                            <br />
                            <StyleButton style={{ marginTop: 10 }} onClick={fetchData} loading={loading} type="primary">GO</StyleButton>
                        </DateColStyle>
                    </Row>
                </div>
                <TableData itemData={itemData} loading={loading} />
            </Panel>


        </>
    )
}

export default IncomingOrders