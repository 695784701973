import React from 'react';
import * as S from './TableStyled';
const PrinterDisplayTable = ({
    columns,
    dataSource,
    rowKey,
    loading,
    handleRow
}) => {
    const uniqueItems = [];
    dataSource.forEach(item => {
        const isDuplicate = uniqueItems.some(uniqueItem =>
            uniqueItem.short_name === item.short_name &&
            uniqueItem.department_name === item.department_name
        );

        if (!isDuplicate) {
            uniqueItems.push(item);
        }
        return uniqueItems
    });   

    return (
        <S.TableStyled
            columns={columns}
            rowKey={rowKey}
            dataSource={uniqueItems}
            scroll={{ x: 'max-content' }}
            pagination={false}
            loading={loading}
            onRowClick={(targetRow) => (targetRow?.row?.id != null && targetRow?.row?.header == true && handleRow(targetRow?.row?.id))}
        />
    );
}
export default PrinterDisplayTable;