import React from 'react';
import InputSection from './InputSection';
import { Select } from 'antd';
import { alphabeticalData } from 'utils/sortData';

const Option = Select.Option;

const ReportTables = ({
  tables = [],
  setCurrentTableObject,
  chooseTable,
  currentTable,
  ...props
}) => {
  return (
    <InputSection size='large' label='Objects: ' {...props} labelStyle={{ width: 'auto' }}>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={true}
        style={{ width: '100%', marginLeft: 3 }}
        value={currentTable ? currentTable : ''}
        onSelect={(tableKey) => { chooseTable(tableKey) }}
      >
        {
          alphabeticalData(tables, "name").map((tablekey,key) => (
            <Option key={key} value={tablekey.name}>{tablekey.name}</Option>
          ))
        }
      </Select>
    </InputSection>
  )
}

export default ReportTables
