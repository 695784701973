import { PROCESS } from 'types'

function createTrigger(type) {
  switch (type) {
    case PROCESS.TRIGGER.EMAIL:
      return {
        recipients: [],
        conditions: [{
          conditionField: null,
          qualifier: null
        }],
        template: null
      }

    case PROCESS.TRIGGER.FIELD:
      return {
        field: null,
        field_value: null,
        conditions: [{
          conditionField: null,
          qualifier: null
        }]
      }

    case PROCESS.TRIGGER.CALLOUT:
      return {
        endpoint: null,
        conditions: [{
          conditionField: null,
          qualifier: null
        }]
      }

    case PROCESS.TRIGGER.ADD_COST:
      return {
        fixed: 0,
        additional: 0
      }

    default:
      return {}
  }
}

export default createTrigger