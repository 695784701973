// Global style variables
export var background = {
  default: '#E9EDF9',
  info: '#DBE9FD',
  success: '#E0F6F2',
  failure: '#FCE4E5'
};
export var color = {
  heading: '#2E384D',
  body: '#8798AD',
  button: '#FFFFFF',
  nav: {
    unselected: '#B0BAC9',
    selected: '#194787'
  },
  input: {
    unselected: 'rgba(11,107,242,0.3)',
    selected: '#0B6BF2'
  },
  border: {
    unselected: '#0B6BF2',
    selected: 'rgba(46,91,255,1)'
  },
  white: '#FFFFFF',
  gray: '#BFC5D2',
  blue: {
    normal: '#0B6BF2',
    dark: '#0243EB',
    light: '#DBE9FD'
  },
  pacific_blue: {
    normal: '#00C1D4',
    dark: '#00A6C0',
    light: '#D9F6F9'
  },
  mountain_meadow: {
    normal: '#2CC2A5',
    dark: '#11A783',
    light: '#E0F6F2'
  },
  buttercup: {
    normal: '#F7C137',
    dark: '#F3A618',
    light: '#FEF6E1'
  },
  red: {
    normal: '#E84A50',
    dark: '#DC262B',
    light: '#FCE4E5'
  },
  purple: {
    normal: '#8C54FF',
    dark: '#652EFF',
    light: '#EEE6FF'
  }
};
export var spacing = {
  padding: {
    small: 10,
    medium: 20,
    large: 30
  },
  borderRadius: {
    small: 5,
    default: 10
  },
  border: {
    s1: 1
  }
};
export var typography = {
  type: "'Rubik', sans-serif",
  weight: {
    light: '300',
    regular: '500',
    bold: '700',
    extrabold: '800',
    black: '900'
  },
  size: {
    s1: '13',
    s2: '15',
    m1: '18'
  }
};