import { SET_PRINTER_TIMELINE_DATA, SET_SUBMISSIONS_DATA, SET_SUBMISSIONS_DETAIL_DATA, SET_FINISHING_TYPES_DATA } from "../actions/controlTowerTypes";

const initialState = {
  printers: null,
  submissions: [],
  submission_details: [],
  finishing_types: []

};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PRINTER_TIMELINE_DATA:
      return {
        ...state,
        printers: action.payload
      };
    case SET_SUBMISSIONS_DATA:
      return {
        ...state,
        submissions: action.payload
      };
    case SET_SUBMISSIONS_DETAIL_DATA:
      return {
        ...state,
        submission_details: action.payload
      };
    case SET_FINISHING_TYPES_DATA:
      return {
        ...state,
        finishing_types: action.payload
      };

    default:
      return state;
  }
}