import React from 'react'
import Frame from 'react-frame-component';

const WorkOrderFrame = (props) => {
    const { body } = props;
    return (
        <Frame style={{display: 'none'}} id='iframe' initialContent={body}></Frame>
    )
}

export default WorkOrderFrame
