import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Col, Select, Button, Spin, Row, Input, notification } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { useSelector } from 'react-redux'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js';
import { ZenSmartAPI } from 'utils'
import { getThermalLabel } from "utils/apiRoutes"
import * as _ from 'lodash';
import { errorHandler } from 'utils/errorMessageHandler';
import Frame from 'react-frame-component';

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const StyledRow = styled(Row)`
  margin-bottom: 24px;
`
const JobTicketPrinterPage = (props) => {
    const userData = useSelector(state => state.user)
    const scanInput = useRef(null)
    const refocusQueueID = useRef(null)
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState(null)
    const [files, setFiles] = useState(null)
    const clearRefocusQueue = () => {
        clearTimeout(refocusQueueID.current)
    }

    const refocusQueue = () => {
        refocusQueueID.current = setTimeout(() => {
            // only refocus if the active element is not any kind of an input
            let activeElement = document.activeElement;
            let inputs = ['input', 'select', 'button', 'textarea'];

            if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
                return;
            }
            if (scanInput.current) {
                scanInput.current.focus()
            }
        }, 5000)
    }

    const submitBarcode = (e) => {
        if (e.keyCode === 13) {
            setLoading(true)
            setFiles(null)
            ZenSmartAPI.get(getThermalLabel(`${inputValue}?materials-print=true`))
                .then((res) => {
                    setLoading(false)
                    setFiles(res.data.data)
                    notification.success({
                        message: 'Barcode Scan Successful!'
                    })
                }).catch((res) => {
                    setLoading(false)
                    if (_.get(res, 'response.status') === 422) {
                        notification.error({
                            message: errorHandler(_.get(res, 'response.data.errors'))
                        })
                        return
                    }
                    else if (_.get(res, 'response.data.message')) {
                        notification.error({
                            message: _.get(res, 'response.data.message')
                        })
                        return
                    }
                    notification.error({
                        message: "Invalid scan item!"
                    })
                })
            setInputValue(null)
        }
    }

    const PrintFrame = (body) => {
        return (
            <Frame style={{ display: 'none' }} id='iframe' initialContent={body}></Frame>
        )
    }

    useEffect(() => {
        if (scanInput.current) {
            scanInput.current.focus()
        }
        return clearRefocusQueue
    }, [])

    useEffect(() => {
        if (files) {
            setTimeout(function () {
                window.frames["iframe"].focus();
                window.frames["iframe"].contentWindow.print();
            }, 1000);

        }
    }, [files])

    return (
        <>
            {
                userData && userData.details && userData.details.show_materials_page && userData.details.show_materials_page.job_ticket_printer &&
                <div>
                    <Header>
                        <Title>Materials {">"} Job Ticket Printer</Title>
                    </Header>
                    <Panel title="Job Ticket Printer ">
                        <StyledRow type="flex" justify="center" align="middle">
                            <Col span={24} style={{ marginBottom: 24 }}>
                                <p style={{ textAlign: "center" }}><Label status={"blue"} >{"PLEASE SCAN ITEM"}</Label></p>
                            </Col>
                            <Col span={8}>
                                <Input.Search
                                    type="text"
                                    placeholder="Please scan barcode"
                                    ref={scanInput}
                                    onFocus={clearRefocusQueue}
                                    onBlur={refocusQueue}
                                    loading={loading}
                                    onKeyDown={submitBarcode}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    value={inputValue}
                                    autoFocus
                                    size="large"
                                />
                            </Col>
                        </StyledRow>
                        {files && PrintFrame(files)}

                    </Panel>
                </div>
            }
        </>
    )
}

export default JobTicketPrinterPage