import { createSlice } from '@reduxjs/toolkit'
import { message, notification } from 'antd'

import { ZenSmartAPI, tokenExpired } from 'utils'
import { STATUS } from 'types'
import { loginRoute, authMeRoute } from "utils/apiRoutes"
import axios from 'axios';
import Cookies from 'js-cookie';
function getInitialAuthValue() {
  const hasToken = localStorage.getItem('token') !== null
  return hasToken && tokenExpired() === false
}

const initialState = {
  authenticated: getInitialAuthValue(),
  status: null,
  details: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state) {
      state.authenticated = true
    },

    logout(state) {
      state.authenticated = false
      state.details = null
    },

    fetchingUserDetails(state) {
      state.status = STATUS.FETCHING_USER_DETAILS
    },

    failedFetchingUserDetails(state) {
      state.status = STATUS.FAILED_FETCHING_USER_DETAILS
    },

    setUserDetails(state, action) {
      state.status = null
      state.details = action.payload
    },
  }
})

export const {
  reducer: UserReducer,
  actions: UserActions
} = userSlice

const getUserDetails = () => async (dispatch) => {
  dispatch(UserActions.fetchingUserDetails())

  try {
    const response = await ZenSmartAPI.get(authMeRoute)
    dispatch(UserActions.setUserDetails(response.data.data))
  } catch (err) {
    dispatch(UserActions.failedFetchingUserDetails())
    notification.error({
      message: 'Could not retrieve user details.'
    })
  }
}

const authenticate = (credentials, form) => async (dispatch) => {
  form.submitting()

  try {
    const response = await axios.post(loginRoute, credentials)
    Cookies.remove('SESSION_TIMEOUT');
    const token = response.data.data
    /**
     * Passing the token to the login action is necessary since it'll
     * be processed by a middleware (specifically _authLocalStoreMiddleware_)
     * to store the response token on localStorage
     */
    dispatch(UserActions.login(token))
    notification.success({
      message: 'Sign In Successful!'
    })
  } catch (e) {

    if (e && e.response) {
      if (e.response.status === 422 || e.response.status === 401) {
        const hasNoInput = ('barcode' in credentials) && !credentials.barcode

        if (hasNoInput) {
          form.failed({
            message: 'No Credentials Given',
            description: 'Please enter an email or barcode value to the' +
              ' input below.'
          })
        } else {
          const signInCredential = credentials.barcode ? 'barcode' : 'email or password'
          form.failed({
            message: 'Invalid Credentials',
            description: `Invalid ${signInCredential} given. Please try again` +
              ' with the correct account details.'
          })
        }

        return
      }
    }

    form.failed({
      message: 'Unexpected error',
      description: 'Something went wrong. Please contact support for more details.'
    })
  }
}

export const UserThunks = {
  authenticate,
  getUserDetails,
}