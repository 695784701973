import React, {useCallback} from 'react'
import styled from 'styled-components'
import {Modal, Form, Input, Button, notification} from 'antd'

const modalBodyStyle = {
  maxHeight: '420px',
  overflow: 'auto'
}

const Field = styled(Form.Item)`
  margin-bottom: 16px;

  & .ant-form-item-label {
    line-height: inherit;
  }
`

function ActualEditProductForm(props) {
  const {currentProduct, form, onSubmit} = props
  const {getFieldDecorator, getFieldsValue} = form

  const handleSubmit = evt => {
    evt.preventDefault()
    onSubmit(getFieldsValue())
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Field label="Product Name">
        {getFieldDecorator('article', {
          initialValue: currentProduct.article
        })(<Input placeholder="ex. Photobook" />)}
      </Field>

      <Field label="Product Code">
        {getFieldDecorator('productCode', {
          initialValue: currentProduct.productCode
        })(<Input placeholder="ex. PBOOK_42" />)}
      </Field>

      <Field label="Description">
        {getFieldDecorator('description', {
          initialValue: currentProduct.description
        })(<Input />)}
      </Field>

      <Field label="Size Group">
        {getFieldDecorator('sizeGroup', {
          initialValue: currentProduct.sizeGroup
        })(<Input />)}
      </Field>

      <Field label="Freight Group">
        {getFieldDecorator('freightGroup', {
          initialValue: currentProduct.freightGroup
        })(<Input />)}
      </Field>

      <Field label="Primary Group">
        {getFieldDecorator('primaryGroup', {
          initialValue: currentProduct.primaryGroup
        })(<Input />)}
      </Field>

      <Field label="Secondary Group">
        {getFieldDecorator('secondaryGroup', {
          initialValue: currentProduct.secondaryGroup
        })(<Input />)}
      </Field>

      <Field>
        <Button
          type="primary"
          htmlType="submit"
        >
          Edit Product
        </Button>
      </Field>
    </Form>
  )
}

const EditProductForm = Form.create({name: 'Edit Product Form'})(ActualEditProductForm)

function EditProductModal(props) {
  const {
    currentProduct,
    setCurrentProduct,
    onCancel: closeModal,
    ...modal
  } = props

  const handleSubmit = useCallback(newProductDetails => {
    setCurrentProduct(currentProduct => ({
      ...currentProduct,
      ...newProductDetails
    }))
    notification.success({
      message: `${currentProduct.article} details successfully edited.`
    })
    closeModal()
  }, [currentProduct, setCurrentProduct, closeModal])

  return (
    <Modal
      {...modal}
      onCancel={closeModal}
      bodyStyle={modalBodyStyle}
      footer={null}
    >
      <EditProductForm
        currentProduct={currentProduct}
        onSubmit={handleSubmit}
      />
    </Modal>
  )
}

export default EditProductModal