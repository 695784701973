import React from "react";
import styled from "styled-components";
import { Droppable, Draggable } from "react-beautiful-dnd";
import DragItem from "components/zensmart-design-system/components/Draggable/DragItem";
import {
  DroppableNode,
  TextNode,
  ArrowNode,
  IconContainer,
} from "components/zensmart-design-system/components/Draggable/DropComponents";
import { FormValues } from "modules/imposition/config/Form";
import { ReactComponent as ArrowIcon } from "static/icons/arrow-down.svg";
import { ReactComponent as EditIcon } from "static/icons/edit-icon.svg";
import { Libraries } from "../../config/Imposition";
import { getProperty } from "../../utils/ImpositionUtil";
import RecipeParamDialog from "./RecipeParamDialog";

const RecipeMain = styled.div`
  height: 100%;
  overflow-x: hidden;
`;

//Set min-height to 1 so that droppable components can be dropped
const RecipeContainer = styled.div`
  min-height: 1px;
`;

const Recipe = (props) => {
  const arrowIconStyle = { display: "block", margin: "auto" };

  const outputClick = () => {
    props.setModal({
      visible: true,
      isTable: true,
      tableWidth: 1400,
      columns: [
        {
          title: "Specific Printers",
          dataIndex: "printer_name",
          width: "13%",
          editable: true,
          type: "dropdown",
          idLabel: "printer_id",
          valueLabel: "full_name",
          sorter: (a, b) =>
            a.printer_name && b.printer_name
              ? a.printer_name.localeCompare(b.printer_name)
              : a.printer_name,
          sortDirections: ["ascend", "descend"],
        },
        {
          title: "Production Facility",
          dataIndex: "facility",
          editable: true,
          type: "text",
          sorter: (a, b) =>
            a.facility && b.facility ? a.facility.localeCompare(b.facility) : a.facility,
          sortDirections: ["ascend", "descend"],
        },
        {
          title: "Paper Codes",
          dataIndex: "paper_code",
          width: "13%",
          editable: true,
          type: "dropdown",
          idLabel: "paper_code",
          valueLabel: "code",
          sorter: (a, b) =>
            a.paper_code && b.paper_code
              ? a.paper_code.localeCompare(b.paper_code)
              : a.paper_code,
          sortDirections: ["ascend", "descend"],
        },
        {
          title: "Lamination Codes",
          dataIndex: "lamination_code",
          width: "14%",
          editable: true,
          type: "dropdown",
          idLabel: "lamination_code",
          valueLabel: "code",
          sorter: (a, b) =>
            a.lamination_code && b.lamination_code
              ? a.lamination_code.localeCompare(b.lamination_code)
              : a.lamination_code,
          sortDirections: ["ascend", "descend"],
        },
        {
          title: "Output Format",
          dataIndex: "output_format",
          width: "13%",
          editable: true,
          type: "dropdown",
          defaultValue: props.outputFormat ? props.outputFormat.default_output_format : null,
          sorter: (a, b) =>
              a.output_format && b.output_format
                  ? a.output_format.localeCompare(b.output_format)
                  : a.output_format,
          sortDirections: ["ascend", "descend"],
        },
        {
          title: "Path",
          dataIndex: "path",
          width: "18%",
          editable: true,
          type: "text",
          sorter: (a, b) =>
            a.path && b.path ? a.path.localeCompare(b.path) : a.path,
          sortDirections: ["ascend", "descend"],
        },
        {
          title: "Pre Path",
          dataIndex: "pre_path",
          width: "15%",
          editable: true,
          type: "text",
          sorter: (a, b) =>
            a.pre_path && b.pre_path ? a.pre_path.localeCompare(b.pre_path) : a.pre_path,
          sortDirections: ["ascend", "descend"],
        },
      ],
    });
  };

  const iconClick = (event, value) => {
    event.stopPropagation();
    removeRecipe(Number(value.sortOrder), true);
  };

  const removeRecipe = (recipeId, rerender) => {
    const cloneRecipes = [...props.recipes];
    cloneRecipes.splice(recipeId, 1);
    const newRecipes = cloneRecipes.map((cloneRecipe, index) => {
      return { ...cloneRecipe, sortOrder: index.toString() };
    });
    props.updateRecipes(newRecipes, true);
    props.hasUnsavedChanges(props.checkUnsaveChanges(newRecipes, null, null));

    if (rerender){
      props.updatePdfURL(newRecipes);
    }
  };

  const getName = (value) => {
    const library = Libraries.find((property) => property.id === value.name);
    if(!library){
      return false;
    }
    return library.label;
  };

  return (
    <RecipeMain recipes={props.recipes}>
      <DroppableNode>
        <TextNode>Input Files</TextNode>
      </DroppableNode>
      <ArrowNode>
        <ArrowIcon style={arrowIconStyle} width="20px" height="15px" />
      </ArrowNode>
      <Droppable droppableId="Recipe" key="Recipe">
        {(provided) => (
          <RecipeContainer {...provided.droppableProps} ref={provided.innerRef}>
            {props.recipes.map((value, index) => {
              return (
                <Draggable
                  draggableId={value.sortOrder}
                  index={index}
                  key={index}
                >
                  {(provided, snapshot) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <DragItem
                        key={index}
                        id={index}
                        name={getName(value)}
                        showIcon={true}
                        onClick={(event) => props.setDialogState(value)}
                        onIconClick={(event) => iconClick(event, value)}
                        parameters={
                          value.parameters.length !== 0 && value.parameters
                        }
                        getProperty={getProperty}
                        valuesProp={FormValues}
                      ></DragItem>
                      <ArrowNode isDragging={snapshot.isDragging}>
                        <ArrowIcon
                          style={arrowIconStyle}
                          width="20px"
                          height="15px"
                        />
                      </ArrowNode>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </RecipeContainer>
        )}
      </Droppable>
      <DroppableNode>
        <TextNode>Output</TextNode>
        <IconContainer
          color="blue"
          showIcon={true}
          onClick={() => outputClick()}
        >
          <EditIcon width="8px" height="8px" fill="white" />
        </IconContainer>
      </DroppableNode>
      <RecipeParamDialog
        modal={props.modal}
        setModal={props.setModal}
        recipes={props.recipes}
        recipeId={props.recipeId}
        output={props.output}
        updateRecipes={props.updateRecipes}
        updateOutput={props.updateOutput}
        updatePdfURL={props.updatePdfURL}
        removeRecipe={removeRecipe}
        paperCodes={props.paperCodes}
        setPaperCodes={props.setPaperCodes}
        laminationCodes={props.laminationCodes}
        setLaminationCodes={props.setLaminationCodes}
        specifiedPrinters={props.specifiedPrinters}
        setSpecifiedPrinters={props.setSpecifiedPrinters}
        unsavedChanges={props.unsavedChanges}
        hasUnsavedChanges={props.hasUnsavedChanges}
        checkUnsaveChanges={props.checkUnsaveChanges}
        outputFormat={props.outputFormat ? props.outputFormat.output_formats : []}
      />
    </RecipeMain>
  );
};

export default Recipe;
