import React, {useContext, useEffect, useState} from 'react';
import {Drawer, notification} from 'antd';
import styled from 'styled-components';
import {DragDropContext} from 'react-beautiful-dnd';
import * as _ from 'lodash';

import {ACTION, PROCESS} from 'types';
import {Panel} from 'components/primaries';
import {color} from 'components/zensmart-design-system/shared/styles.js';

import {
  BucketFormModal,
  DesignerArea,
  FieldSwitchFormModal,
  PackTypeFormModal,
  ProductArea,
  ProviderFormModal,
} from './partials/sections';
import DispatchFormModal from './partials/sections/DispatchFormModal';
import {DesignerContext, withDesignerContext} from './DispatchDesignerContext';
import PackTypeFormDrawer from './partials/sections/PackTypeFormDrawer';

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;

const Section = styled.section`
  margin-left: 36px;
  margin-right: 36px;
`;

function DispatchDesigner() {

  const {
    selectedProduct,
    stages,
    updateStages,
    modal,
    setModal,
    stageData,
    setStageData,
    clearModal,
    getUrlParams,
    packTypeFormVisible,
    setPackTypeFormVisible,
  } = useContext(DesignerContext);
  const [currentDragType, setCurrentDragType] = useState(null);

  useEffect(() => {
    const params = getUrlParams();

    if (stages && params.has('focus_on')) {
      const focus_on = params.get('focus_on');
      let stage = _.get(stages, focus_on, null);
      if (stage) {
        stage.stageID = `${focus_on.split('.')[0]}.${stage.id}`;
        stage.parentID = stage.parent && `${stage.parent.category}.${stage.parent.index}`;
        stage.stageType = stage.type;
        stage.actionType = ACTION.EDIT_DISPATCH_STAGE;
        viewStageData(stage);
      }
    }
  }, [stages]);

  const noPackInFlow = (node) => {
    // there is a pack
    if (node.type === PROCESS.TYPE.PACK_TYPE) {
      return false;
    }

    // start of the flow reached. confirmed that no pack in flow
    if (node.parent.category === 'dispatch') {
      return true;
    }

    return noPackInFlow(stages[node.parent.category][node.parent.index]);
  };

  const viewStageData = stageData => {

    let parentType = null;
    let parentNodeId = null;

    if(stageData.parentID) {
      [parentType, parentNodeId] = stageData.parentID.split('.');
    }

    // If the immediate parent is also a pack, we don't to enforce this rule
    if (
        stageData.stageType === PROCESS.TYPE.PACK_TYPE &&
        parentType !== 'packs'
    ) {
      if (noPackInFlow(stages[parentType][parentNodeId]) === false) {
        notification.error({
          message: 'This branch already has pack defined. Cannot set 2 different pack types for the same branch.',
        });
        return;
      }
    }

    // If a pack node was dropped on an existing pack stage, open the stage in edit mode
    if(
        stageData.stageType === PROCESS.TYPE.PACK_TYPE &&
        parentType === 'packs'
    ) {
      let stage = stages['packs'][parentNodeId];
      stageData = {...stageData, ...stage};
      stageData.stageID = `packs.${stage.id}`;
      stageData.parentID = stage.parent && `${stage.parent.category}.${stage.parent.index}`;
      stageData.stageType = stage.type;
      stageData.actionType = ACTION.EDIT_DISPATCH_STAGE;
    }

    if (stageData.stageType === PROCESS.TYPE.PROVIDER) {
      if (parentType === 'dispatch' ||
          noPackInFlow(stages[parentType][parentNodeId])) {
        notification.error({
          message: 'Cannot attach a provider if the flow does not have a pack node.',
        });
        return;
      }
    }

    if(stageData.stageID) {
      const params = getUrlParams();
      stageData.stageType === PROCESS.TYPE.EDGE ? params.set('focus_on', 'dispatch') : params.set('focus_on', stageData.stageID)
      window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    }
    setModal(stageData.stageType);
    setStageData(stageData);
  };

  const handleBeforeCapture = result => {
    setCurrentDragType(result.draggableId);
  };

  const handleDragStart = result => {
    changeDispatchItemDroppableBackgrounds('rgb(233, 237, 249)');
  };

  const handleDragEnd = result => {
    changeDispatchItemDroppableBackgrounds('#fff');
    setCurrentDragType(null);

    const {draggableId, source, destination} = result;
    let droppableId = destination.droppableId.split('.')

    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    // The droppable on packs will only accept other types of nodes if their is no child
    if(droppableId[0] === 'packs') {
      if(result.type !== PROCESS.TYPE.PACK_TYPE) {
        if(stages[droppableId[0]][droppableId[1]].nextNode.category) {
          return;
        }
      }
    }

    if(droppableId.length === 3) {
      droppableId.pop()
    }
    droppableId = droppableId.join('.')

    viewStageData({
      parentID: droppableId,
      stageType: draggableId,
      actionType: ACTION.ADD_DISPATCH_STAGE,
    });
  };

  const changeDispatchItemDroppableBackgrounds = bg => {
    document.querySelectorAll('.library-item-droppable').forEach((node) => {
      node.style.background = bg;
    });
  };

  return (
      <>
        <Header>
          <Title>Pack & Ship {'>'} Dispatch Designer</Title>
        </Header>

        <Panel title="EDIT PRODUCT" className={`pb-4`}>
          <Section>
            <ProductArea/>
          </Section>

          <DragDropContext
              onDragUpdate={(c) => {
                if(!c.destination) {
                  changeDispatchItemDroppableBackgrounds('rgb(233, 237, 249)');
                }
              }}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onBeforeCapture={handleBeforeCapture}
          >
            {stages ? (
                <DesignerArea
                    product={selectedProduct}
                    stages={stages}
                    viewStageData={viewStageData}
                    currentDragType={currentDragType}
                />
            ) : null}
          </DragDropContext>
        </Panel>

        <DispatchFormModal
            title="Dispatch Config"
            visible={modal === PROCESS.TYPE.EDGE}
            stageData={stageData}
        />

        <FieldSwitchFormModal
            title="Field Switch"
            visible={modal === PROCESS.TYPE.FIELD_SWITCH}
            onCancel={clearModal}
            stageData={stageData}
        />

        <ProviderFormModal
            title="Provider"
            visible={modal === PROCESS.TYPE.PROVIDER}
            onCancel={clearModal}
            stageData={stageData}
        />

        <BucketFormModal
            title="Qualifier"
            visible={modal === PROCESS.TYPE.BUCKET}
            onCancel={clearModal}
            stageData={stageData}
        />

        <PackTypeFormModal
            title="Pack Type"
            visible={modal === PROCESS.TYPE.PACK_TYPE}
            onCancel={clearModal}
            stageData={stageData}
        />

        <PackTypeFormDrawer />
      </>
  );
}

const DispatchDesignerWithContext = withDesignerContext(DispatchDesigner);
export default DispatchDesignerWithContext;