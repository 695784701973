import React, { useState, useRef } from "react";
import { Checkbox, Col, Row, Typography, Button, Input, Select, notification } from "antd";
import { createGlobalStyle } from "styled-components";
import * as _ from 'lodash';
import { ZenSmartAPI } from "utils";
import { rolesRoute } from "utils/apiRoutes";

const { Title, Text } = Typography;
const { Option } = Select;
const TableStyle = createGlobalStyle`
table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;    
  }
  
  th {
    background-color: #d0f2fe;   
    color: black; 
  }
  
  tr:nth-child(even) {
    background-color: #f1f3ff;
  }
  
  td button {
    background-color: transparent;
    border: none;
    color: red;
    cursor: pointer;
    font-weight: bold;
  }
`;

function RoleAddEditView({ allPermissions, rolePermissions, roleUsers, users, roleDescription, role, roleName, roleDashboard , setRoleDashboard, dashboards }) {

    const [managingUsers, setManagingUsers] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(roleUsers || []);
    const [inputValue, setInputValue] = useState("");
    const [description, setDescription] = useState(roleDescription || "");
    const [name, setName] = useState(roleName);
    const [loadingPermissions, setLoadingPermissions] = useState([]);
    const initialDescription = useRef(roleDescription || "");
    const initialRoleName = useRef(roleName);
    const [searchQuery, setSearchQuery] = useState("");
    const [checkedPermissions, setCheckedPermissions] = useState(
        rolePermissions?.map((permission) => permission.code) || []
    );


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleManageUsersClick = () => {
        setManagingUsers(!managingUsers);
    };

    const handleUserSelect = (value, option) => {
        const user = { id: value, name: option.props.children };

        if (!selectedUsers.some((u) => Number(u.id) === Number(user.id))) {

            ZenSmartAPI.post(`/api/v1/roles/${role}/users/${user.id}`)
                .then((response) => {
                    response = response.data;
                    setSelectedUsers(response);

                })
                .catch((error) => {
                    const statusCode = _.get(error, 'response.status');
                    if (statusCode !== 403) {
                        notification.error({
                            message: _.get(error, 'response.data.message',
                                'An error occurred while assigning user to role. Please try again.')
                        });
                    }
                })
        }
        setInputValue("");
    };

    const handleRemove = (userId) => {

        ZenSmartAPI.delete(`/api/v1/roles/${role}/users/${userId}`)
            .then((response) => {
                response = response.data;
                setSelectedUsers(response);
            })
            .catch((error) => {
                const statusCode = _.get(error, 'response.status');
                if (statusCode !== 403) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An error occurred while removing user from role. Please try again.')
                    });
                }
            })
    };

    const filterOptions = (inputValue, option) => {
        if (inputValue === undefined || option === undefined) {
            return true;
        }
        return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
    };

    const handleDescriptionChange = (e) => {
        const newDescription = e.target.value;
        if (newDescription === initialDescription.current || newDescription === "") {
            return;
        }

        ZenSmartAPI.put(rolesRoute, { id: role, description: newDescription })
            .then((response) => {
                response = response.data.data;
                setDescription(response.description);
                initialDescription.current = response.description;
                notification.success({
                    message: 'Role description updated successfully.'
                });
            })
            .catch((error) => {
                const statusCode = _.get(error, 'response.status');
                if (statusCode !== 403) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An error occurred while updating the role\'s description. Please try again.')
                    });
                }
            })
    };

    const handleRoleNameChange = (e) => {
        const newRoleName = e.target.value.toLowerCase().trim();

        if(newRoleName === '') {
            notification.error({
                message: 'Please enter a Role Name.'
            });
            return;
        }

        if(newRoleName === initialRoleName.current) {
            return;
        }

        ZenSmartAPI.put(rolesRoute, { id: role, name: newRoleName })
            .then((response) => {
                response = response.data.data;
                setName(response.name);
                roleName = response.name;
                initialRoleName.current = response.initialRoleName;
                notification.success({
                    message: 'Role Name updated successfully.'
                });
            })
            .catch((error) => {
                const statusCode = _.get(error, 'response.status');
                if (statusCode !== 403) {
                    console.log('test');
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An error occurred while updating the role\'s name. Please try again.')
                    });
                }
            })
    }

    const handlePermissionChange = (checked, permission) => {
        setLoadingPermissions((prevState) => [...prevState, permission.code]);

        const action = checked ? 'post' : 'delete';
        ZenSmartAPI[action](
            `/api/v1/roles/${role}/permissions/${permission.code}`)
            .then((response) => {
                response = response.data;
                setCheckedPermissions(response.map((permission) => permission.code))
                setLoadingPermissions((prevState) => prevState.filter((code) => code !== permission.code));
            })
            .catch((error) => {
                setLoadingPermissions((prevState) => prevState.filter((code) => code !== permission.code));
                const statusCode = _.get(error, 'response.status');
                if (statusCode !== 403) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An error occurred while updating the role\'s permission. Please try again.')
                    });
                }
            })
    };

    const handleDashboardChange = (dashboard_id) => {
        ZenSmartAPI.put(rolesRoute, { id: role, dashboard_id })
            .then((response) => {
                response = response.data.data;
                setRoleDashboard(response.dashboard_id);
                notification.success({
                    message: 'Role dashboard updated successfully.'
                });
            })
            .catch((error) => {
                const statusCode = _.get(error, 'response.status');
                if (statusCode !== 403) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An error occurred while updating the role\'s dashboard. Please try again.')
                    });
                }
            })
    };
    const groupMap = allPermissions.reduce((map, permission) => {
        if (!map[permission.group]) {
            map[permission.group] = [];
        }
        map[permission.group].push(permission);
        return map;
    }, {});

    const groups = Object.entries(groupMap).map(([group, permissions]) => ({
        name: group,
        permissions,
    }));

    const filteredGroups = groups.map((group) => ({
        ...group,
        permissions: group.permissions.filter((permission) =>
            permission.name.toLowerCase().includes(searchQuery.toLowerCase()) || group.name.toLowerCase().includes(searchQuery.toLowerCase())
        ),
    }));

    return (
        <div>
            {!managingUsers ? (
                <>
                    <div className="mt-4">
                        <Button className="text-blue-300 border-blue-300" onClick={handleManageUsersClick}>Assign Users</Button>
                    </div>
                    <div className="mt-2 w-1/2">
                        <b>Name</b>
                        <Input
                            placeholder="Enter role name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={handleRoleNameChange}
                        />
                    </div>
                    <div className="mt-2">
                        <b>Description</b>
                        <Input
                            placeholder="Enter a description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            onBlur={handleDescriptionChange}
                        />
                    </div>
                    <div className="mt-2 w-1/2">
                        <b>Dashboard  </b>
                        <Select
                            showSearch
                            style={{ width: 400 }}
                            placeholder="Select Dashboard"
                            filterOption={filterOptions}
                            onChange={(e) => handleDashboardChange(e)}
                            value={roleDashboard}
                        >
                            {Object.values(dashboards).map((dashboard) => (
                                <Option key={dashboard.id} value={dashboard.id}>
                                    {dashboard.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div style={{ position: 'relative', width: '100%', marginBottom: '2px', marginTop: '12px' }}>
                        <input
                            type="text"
                            placeholder="Search for permissions to be assigned..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            style={{
                                width: '100%',
                                padding: '12px',
                                paddingLeft: '36px',
                                boxSizing: 'border-box',
                                borderRadius: '4px',
                                textAlign: 'center',
                            }}
                        />
                        {!searchQuery && (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6"
                                style={{
                                    position: 'absolute',
                                    left: 'calc(36% - 18px)',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    width: '1.25em',
                                    height: '1.25em',
                                }}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                        )}
                    </div>
                    <div>
                        {filteredGroups.map((group, index) => {
                            if (group.permissions.length > 0) {
                                return (
                                    <div key={group.name}>
                                        <br />
                                        <Title level={4} style={{ whiteSpace: 'pre-line' }}>{group.name}</Title>
                                        <Row gutter={[16, 16]} style={{ textAlign: 'left' }}>
                                            {group.permissions.map((permission, permissionIndex) => (
                                                <React.Fragment key={permission.id}>
                                                    {(permissionIndex > 0 && permissionIndex % 3 === 0) &&
                                                        <div style={{ width: '100%', height: '0px', clear: 'both' }} />}
                                                    <Col span={8} style={{ display: 'inline-block' }}>
                                                        <Checkbox
                                                            checked={checkedPermissions.includes(permission.code)}
                                                            onChange={e => handlePermissionChange(
                                                                e.target.checked, permission)}
                                                            disabled={loadingPermissions.includes(permission.code)}
                                                        >
                                                            {permission.name}
                                                            <div><Text type={`secondary`}>{permission.description}</Text></div>
                                                        </Checkbox>
                                                    </Col>
                                                </React.Fragment>
                                            ))}
                                        </Row>
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </>
            ) : (
                <>
                    <div className="mt-4">
                        <Button className="text-blue-300 border-blue-300" onClick={handleManageUsersClick}>Assign Roles</Button>
                    </div>
                    <div className="mt-2 mb-2">
                        <b>Search for User:  </b>
                        <Select
                            showSearch
                            placeholder="Select a user"
                            onSelect={handleUserSelect}
                            style={{ width: 400 }}
                            filterOption={filterOptions}
                            onSearch={setInputValue}
                            value={inputValue}
                        >
                            {users.map((user) => (
                                <Select.Option key={user.id} value={user.id.toString()}>
                                    {user.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <p style={{ fontWeight: 'bold' }}>Assigned Users</p>
                        <TableStyle />
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Created On</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedUsers.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.name}</td>
                                        <td style={{ textAlign: "center" }}>{user.created_at}</td>
                                        <td style={{ textAlign: "center", width: "20px" }}>
                                            <button onClick={() => handleRemove(user.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-red-600 w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
}

export default RoleAddEditView;