import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Table, Result, Icon, Alert, message, notification, Row, Col, Button } from 'antd'
import queryString from 'query-string'

import { ZenSmartAPI } from 'utils'
import { useDataValue } from 'hooks'
import { STATUS } from 'types'
import { Panel } from 'components/primaries'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { SearchActions } from 'store/slices/searchSlice.js'
import { searchRoute } from "utils/apiRoutes"
import SearchBreadCrumbs from './SearchBreadCrumbs';
import { clearBreadcrumb } from 'store/actions/breadcrumb'

const SearchText = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
  margin-bottom: 16px;
`

const Heading = styled.h2`
  font-weight: bold;
  font-size: 18px;
  color: ${color.heading};
  margin-top: 16px;
`

const TitleCase = styled.span`
  text-transform: capitalize;
`

const Wrapper = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
`

const PaginationContainer = styled(Row)`
  margin: 16px;
`

const panelStylings = css`
  margin-bottom: 24px;
`

const StyledTable = styled(Table)`
  .ant-table-row {
    cursor: pointer;
    word-break: break-all;
  }
`

const StyledLink = styled.a`
  text-decoration: underline;
  color: ${color.body};
  &:hover {
    color: ${color.body};
    text-decoration: underline;
  }
`

function Pagination(props) {
  const { currentPage, maxPages, onPrev, onNext, loading } = props
  return (
    <PaginationContainer type="flex" justify="end" align="middle" gutter={8}>
      <Col>
        <Button onClick={onPrev} disabled={currentPage === 1 || loading}>
          <Icon style={{ marginRight: 8 }} type="arrow-left" /> Prev
        </Button>
      </Col>

      <Col>
        <Row type="flex" align="center" style={{ height: '100%' }}>
          {currentPage} / {maxPages}
        </Row>
      </Col>

      <Col>
        <Button onClick={onNext} disabled={currentPage === maxPages || loading}>
          Next <Icon style={{ marginLeft: 8 }} type="arrow-right" />
        </Button>
      </Col>
    </PaginationContainer>
  )
}

function PanelResource(props) {
  const { data } = props
  const dispatch = useDispatch()


  const history = useHistory()
  const { getDataValue } = useDataValue()
  const [resource, setResource] = useState({
    ...data,
    status: STATUS.IDLE,
    message: null
  })

  const columns = resource.table_headers.map(fieldName => ({
    title: fieldName,
    dataIndex: fieldName,
    key: fieldName,
    render: field => getDataValue(field)
  }))

  const dataSource = resource.section_data.map(record => {
    const data = record.section_data.reduce((columns, field) => ({
      ...columns,
      key: record.resource_name + record.id,
      [field.label]: field,
      resource: record.resource_name,
      id: record.id,
    }), {})
    const dataURL = `/app/data-view/${record.resource_name}/${record.id}`;
    data[columns[0].key].value = <StyledLink href={dataURL} onClick={e => onClickDataKey(e, dataURL)}>{data[columns[0].key].value}</StyledLink>
    return data;
  }
  )

  const onClickDataKey = (e, link) => {
    e.preventDefault();
    if (e.ctrlKey || e.metaKey) {
      e.stopPropagation();
      window.open(link);
    } else {
      history.push(link);
    }
  }

  const rerouteOnClick = record => ({
    onClick(e) {
      const url = `/app/data-view/${record.resource}/${record.id}`;
      e.ctrlKey || e.metaKey ? window.open(url) : history.push({ pathname: url });
    }
  })

  const getNewRecords = pageUrl => () => {
    setResource(ps => ({ ...ps, status: STATUS.FETCHING, statusMessage: null }))

    ZenSmartAPI.post(pageUrl)
      .then(res => {
        const { success, ...newResource } = res.data
        setResource(ps => ({
          ...ps,
          status: STATUS.IDLE,
          ...newResource,
        }))
      })
      .catch(err => {
        console.error(err)
        let statusMessage = (
          'Something went wrong while fetching a ' +
          'new record for this resource. Please ' +
          'try again later.'
        )

        if (err.response && err.response.data.message) {
          statusMessage = err.response.data.message
        }

        setResource(ps => ({
          ...ps,
          status: STATUS.FAILED,
          statusMessage,
        }))
      })
  }

  return (
    <Panel stylings={panelStylings} key={resource.section_header}>
      <Wrapper>
        <Heading>
          <TitleCase>{resource.section_header} ({resource.total} total)</TitleCase>
        </Heading>
      </Wrapper>

      {resource.status === STATUS.FAILED && resource.statusMessage ? (
        <Wrapper>
          <Alert
            closable
            showIcon
            type="error"
            message="Can't fetch new record."
            description={resource.statusMessage}
          />
        </Wrapper>
      ) : null}

      <Wrapper>
        <StyledTable
          loading={resource.status === STATUS.FETCHING}
          columns={columns}
          dataSource={dataSource}
          onRow={rerouteOnClick}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
        <Pagination
          loading={resource.status === STATUS.FETCHING}
          currentPage={resource.current_page}
          maxPages={resource.last_page}
          onPrev={getNewRecords(resource.prev_page_url)}
          onNext={getNewRecords(resource.next_page_url)}
        />
      </Wrapper>
    </Panel>
  )
}

const Search = () => {
  const searching = useSelector(state => state.search.searching)
  const dispatch = useDispatch()
  const history = useHistory()

  const [searchResults, setSearchResults] = useState(null)
  const location = useLocation()

  const { model, query, type } = queryString.parse(location.search)

  useEffect(() => {
    if (query) {
      dispatch(SearchActions.setSearching(true))
      setSearchResults(null)
      ZenSmartAPI.post(searchRoute, { query, model, type })
        .then(res => {
          if (res.data.url) {
            dispatch(SearchActions.setSearching(false))
            history.push(res.data.url);
          }
          else {
            dispatch(SearchActions.setSearching(false))
            const { success, ...searchResults } = res.data
            setSearchResults(searchResults)
          }
        })
        .catch(err => {
          dispatch(SearchActions.setSearching(false))
          notification.error({
            message: 'Could not find any search results...'
          })
          console.error(err)
        })
    }
  }, [query, dispatch, model])

  if (searching) {
    dispatch(clearBreadcrumb());
    return (
      <SearchText>
        <Icon spin type="loading" /> Searching for "{query}"...
      </SearchText>
    )
  }

  if (searchResults) {
    if (searchResults.total_count === 0) {
      return (
        <Panel title="NO RESULTS FOUND">
          <Wrapper>
            <Result
              status="error"
              title={`No results found for "${searchResults.searchQuery}".`}
            />
          </Wrapper>
        </Panel>
      )
    }
    const results = searchResults.page_definitions.sections.map(resource => (
      <PanelResource
        data={resource}
        key={resource.section_header}
      />
    ))

    return (
      <>
        <SearchBreadCrumbs details={null} />
        <SearchText>{searchResults.page_definitions.page_header.header_title}</SearchText>
        {results}
      </>
    )
  }

  return null
}

export default Search