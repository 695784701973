import React, { useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Row } from 'antd'

class ComponentToPrint extends React.Component {
    render() {
        return (
            <div>
                {this.props.type == "html" ?
                    <div dangerouslySetInnerHTML={{ __html: this.props.url }} />
                    :
                    <Row justify="center" align="center" style={{ width: "100%" }}>
                        <img src={this.props.url} style={{
                            width: `${this.props.width}px`,
                            height: `${this.props.height}px`,
                            marginLeft: "auto",
                            marginRight: "auto"
                        }} />
                    </Row>
                }
            </div>
        );
    }
}

const PrintThisComponent = (props) => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (props.url) {
            handlePrint();
        }
    }, [props.url])

    return (
        <div>
            <div style={{ display: "none" }}>
                <ComponentToPrint ref={componentRef} url={props.url} type={props.type} width={props.width} height={props.height} />
            </div>
        </div>
    );
};

export default PrintThisComponent