import React, {useEffect, useState} from 'react';
import {Header, Panel, Title} from '../../../components/primaries';
import {Button, Form, Input, notification, Select} from 'antd';
import styled from 'styled-components';
import useApi from '../../../hooks/useApi';
import PrintThisComponent from '../../../components/composites/printTicket';


const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const ManualConsignment = (props) => {

    const {form} = props;
    const {getFieldDecorator, setFieldsValue} = form;
    const {errors, initLoading, request} = useApi();
    const [providers, setProviders] = useState([]);
    const [selectedProviderCode, setSelectedProviderCode]= useState(null);
    const {loading: fieldsFetchLoading, request: fieldsFetchRequest} = useApi();
    const {
        loading: transactionFetchLoading,
        request: transactionFetchRequest,
    } = useApi();

    const {errors: consignmentErrors, loading: createConsignmentLoading, request: createConsignmentRequest} = useApi()

    const [recipientFields, setRecipientFields] = useState([]);
    const [packageFields, setPackageFields] = useState([]);

    const [showPrintDispatch, setShowPrintDispatch] = useState(false);
    const [printDispatch, setPrintDispatch] = useState(null)

    useEffect(() => {
        request('get', '/api/v1/ui/init-data/manual-consignment')
            .then(response => {
                response = response.data.data;
                setProviders(response.logistics_providers);
            });
    }, []);

    const fetchFieldsForProvider = (code) => {
        setSelectedProviderCode(code);
        fieldsFetchRequest('get',
            `/api/v1/ui/logistics-providers/${code}/get-manual-consignment-fields`)
            .then(response => {
                response = response.data.data;
                setRecipientFields(response.Recipient);
                setPackageFields(response.Package);

                delete response.Recipient;
                delete response.Package;
            });
    };

    const fetchTransactionDetails = (e) => {
        if(!e.target.value) {
            return
        }
        transactionFetchRequest('get',
            `/api/v1/ui/manual-consignments/transactions/${e.target.value}`)
            .then(response => {
                response = response.data.data;

                if(!response.customer) {
                    return;
                }

                const {customer} = response;
                const {shipping} = response.addresses;
                setFieldsValue({
                    delivery_name: customer.full_name,
                    delivery_company: shipping.business_name,
                    delivery_address_1: shipping.streets[0],
                    delivery_address_2: shipping.streets[1],
                    delivery_city: shipping.city,
                    delivery_state: shipping.state,
                    delivery_country: shipping.country,
                    delivery_postcode: shipping.postcode,
                    delivery_phone: shipping.phone,
                    delivery_email: customer.contacts.email,
                });
            });
    };

    const submitForm = () => {
        form.validateFields((err, values) => {
            values.provider_code = selectedProviderCode;
            createConsignmentRequest('post', '/api/v1/consignments/manual-new', values).then(response => {
                response = response.data.data;
                if(response.label.use_osi) {
                    notification.success({ message: "Dispatch successful." })
                    setTimeout(() => { window.location.assign(response.label.osi_path) }, 1000);
                    setSelectedProviderCode(null);
                    form.resetFields();
                    return;
                }
                setPrintDispatch({
                    label: {
                        type: 'html',
                        content: response.consignment.label_data
                    }
                })
                setShowPrintDispatch(true)
            })
        });
    };

    useEffect(() => {
        if(showPrintDispatch === true) {
            setShowPrintDispatch(false)
        }
    }, [showPrintDispatch])

    const renderFormItem = (
        field, placeholder, initialValue, input = null, help) => {

        let component = <Input/>;
        if (input) {
            component = input;
        }

        return (
            <StyledFormItem
                className="w-4/5"
                label={placeholder}
                validateStatus={consignmentErrors[field] ? 'error' : null}
                help={consignmentErrors[field] ? consignmentErrors[field][0] : help}>
                {getFieldDecorator(field,
                    {initialValue})(
                    component,
                )}
            </StyledFormItem>
        );
    };

    return (
        <>
            <Header>
                <Title>Pack & Ship {'>'} Manual Consignment</Title>
            </Header>
            <Panel title="MANUAL CONSIGNMENT">
                <div className={'flex justify-center'}>
                    <Form.Item
                        label={'Select a logistics provider'}
                        className="w-2/5"
                    >
                        <Select
                            className="w-full"
                            disabled={initLoading}
                            showSearch
                            placeholder="Select a logistics provider"
                            optionFilterProp="children"
                            onChange={fetchFieldsForProvider}
                            value={selectedProviderCode}
                        >
                            {providers.map(provider => (
                                <Select.Option key={provider.value}
                                               value={provider.value}>{provider.label}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                {selectedProviderCode &&
                <div className="flex justify-center">
                    <div className="w-1/3 p-3">
                        {recipientFields.length > 0 &&
                        <>
                            <Header className="font-bold">Recipient</Header>
                            {renderFormItem('transaction_client_number',
                                'Transaction Number', null, <Input
                                    onBlur={fetchTransactionDetails}/>,
                                'If you want to associate the consignment to an existing transaction, please specify the number here.')}
                            {recipientFields.map((field, index) => (
                                <React.Fragment key={index}>
                                    {renderFormItem(field.name,
                                        field.label, null, <Input
                                            disabled={transactionFetchLoading}/>,
                                        field.description)}
                                </React.Fragment>
                            ))}
                        </>
                        }
                    </div>

                    <div className="w-1/3 p-3">
                        {packageFields.length > 0 &&
                        <>
                            <Header className="font-bold">Package</Header>
                            {packageFields.map((field, index) => (
                                <React.Fragment key={index}>
                                    {renderFormItem(
                                        field.name,
                                        field.label,
                                        null,
                                        field.type === 'select' ? <Select
                                                                    style={{width: '100%'}}>
                                                                    {field.options.map(
                                                                        option => <Select.Option
                                                                            key={option}
                                                                            value={option}>{option}</Select.Option>)}
                                                                </Select>
                                                                : null,
                                        field.description,
                                    )}
                                </React.Fragment>
                            ))}
                            <Button loading={createConsignmentLoading} className="mt-4" type="primary"
                                    onClick={submitForm}>Create
                                Shipment</Button>
                        </>
                        }
                    </div>

                </div>
                }
            </Panel>
            {showPrintDispatch ? <PrintThisComponent url={printDispatch.label.content} type={printDispatch.label.type} /> : null}
        </>
    );
};

const WithManualConsignment = Form.create()(ManualConsignment);

export default WithManualConsignment;