import React, { useState, useReducer, useEffect, useCallback, useMemo } from 'react'
import getObject from 'lodash/fp/getOr'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { DragDropContext } from 'react-beautiful-dnd'
import { notification , Modal} from 'antd'
import { usePreviousValue } from 'hooks'
import { DROP, ACTION, PROCESS } from 'types'
import { Panel } from 'components/primaries'
import { color } from 'components/zensmart-design-system/shared/styles.js'

import {
  SearchArea,
  ArticleArea,
  EditArea,
  ProcessItemTextModal,
  AutomatedStageModal,
  BarcodeDetailsModal,
  BlockDetailsModal,
  EmailTriggerModal,
  FieldUpdateTriggerModal,
  CalloutTriggerModal,
  AddCostTriggerModal,
  DispatchModal,
  PickingModal,
  QueueRulesModal,
  ProductTemplateUpdateModel
} from './partials/sections'

import {
  getDragType,
  getDropType,
  getProcessPath,
  constructFlowPayload
} from './utils'

import initialData from './initialData.js'
import processesReducer from './processesReducer.js'
import { ZenSmartAPI } from 'utils'
import {
  productFlowRoute,
  productsRoute,
  matchRangeRoute,
  blockTypesRoute,
  scanTypesRoute,
  dispatchTemplatesRoute,
  productFlowInitData,
  productIdRoute,
  productFlowConfiguredDataRoute,
  automatedScanTypesRoute,
  pickingGroupsRoute,
  pickingComponentsRoute,
  pickingLocationsRoute,
  matchRangeGroups,
  fetchProductTemplateList,
  getAllQuiz,
} from "utils/apiRoutes"
import PreDispatchModal from './partials/sections/PreDispatchModal'
import { fetchScanTypes } from "pages/People/Scans/scanData";
import TemplateModal from './partials/sections/TemplateModal'
import { errorHandler } from 'utils/errorMessageHandler';
import * as _ from 'lodash';

const Header = styled.header`
  margin-bottom: 24px;
`

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`

const Section = styled.section`
  margin-left: 36px;
  margin-right: 36px;
`

//Default processes
const validProcessItemTextModalTypes = [
  // PROCESS.TYPE.AUTOMATED,
  // PROCESS.TYPE.PRE_DISPATCH,
  // PROCESS.TYPE.DISPATCH
]

const ProductFlow = () => {
  const [dragType, setDragType] = useState(null)
  const [processes, dispatch] = useReducer(processesReducer, [])
  const [createItemCbData, setCreateItemCbData] = useState({})
  const [currentProduct, setCurrentProduct] = useState(null)
  const [allProductsQuery, setProducts] = useState([])
  const [matchGroupOptions, setMatchGroupOptions] = useState();
  const [matchGroup, setMatchGroup] = useState();
  const [freightGroupOptions] = useState([]);
  const [blockTypesOptions, setBlockTypesOptions] = useState([]);
  const [currentProductBlockTypes, setCurrentProductBlockTypes] = useState([]);
  const [queueRules, setQueueRules] = useState([]);
  const [batchRules, setBatchRules] = useState([]);
  const [originalQueueRules, setOriginalQueueRules] = useState([]);
  const [preDistachRules, setPreDistachRules] = useState([]);
  const [matchOutRules, setMatchOutRules] = useState([]);
  const [originalMatchOutRules, setOriginalMatchOutRules] = useState([]);
  const [originalDispatchRules, setOriginalDispatchRules] = useState([])
  const [originalBatchRules, setOriginalBatchRules] = useState([]);
  const [letterRange, setLetterRange] = useState("0")
  const [scanTypesOptions, setScanTypesOptions] = useState([]);
  const [pickingGroupOptions, setPickingGroupOptions] = useState([]);
  const [pickingComponents, setPickingComponents] = useState([]);
  const [pickingLocations, setPickingLocations] = useState([]);
  const [automatedScanTypesOptions, setAutomatedScanTypesOptions] = useState([]);
  const [qualifiers, setQualifiers] = useState([]);
  const [dispatchTemplatesOptions, setDispatchTemplatesOptions] = useState([]);
  const [batchRulesOptions, setBatchRulesOptions] = useState([]);
  const [templateModal, setTemplateModal] = useState(false);
  const [flowConfigured, setFlowConfigured] = useState();
  const [hasRemovedBarcode, setHasRemovedBarcode] = useState(false);
  const [blockDefinition, setBlockDefinition] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const [triggerEmailTemplates, setTriggerEmailTemplates] = useState([]);

  const [showQueueRulesModal, setShowQueueRulesModal] = useState(false)
  const [process, setProcess] = useState(null)
  const [processQualifiers, setProcessQualifiers] = useState([])
  const [originalProcessQualifiers, setOriginalProcessQualifiers] = useState([])
  const [queueRulesStage, setQueueRulesStage] = useState(0)
  const [qualifiersToRemove, setQualifiersToRemove] = useState([])
  const [closeModalOnDrop, setCloseModalOnDrop] = useState(false)
  const [itemID, setItemID] = useState('')
  const [gamificationQuiz, setGamificationQuiz] = useState([])
  const [hasChangedQueue, setHasChangedQueue] = useState(false);
  const [templateChange, setTemplateChange] = useState(false)
  const [createNewTemplate, setCreateNewTemplate] = useState(false)
  const [productTemplates, setProductTemplates] = useState([])
  
  /**
   * The `modal` variable will be passed through the
   * EditArea component as well so we need to not destructure
   * these values here.
   */
  const modal = useState(null)
  const reduxDispatch = useDispatch()
  const [newItemType, showNewItemTypeModal] = modal
  const setProduct = (product, isNewProduct,ignoreProductChange = false) => {
    if(!ignoreProductChange){
      setCurrentProduct(product)
    }
    updateBlockType(product);

    if (isNewProduct) {
      setTemplateModal(true)
    }

    if (product.processes) {
      setFlowFromSelectedProduct(product.flow);

      const productProcesses = [...product.processes];
      productProcesses.map(process => {
        if (process.type === PROCESS.TYPE.BARCODE || process.type === PROCESS.TYPE.AUTOMATED) {
          process.isSaved = true;
        }

        if (process.accepts && process.accepts.length) {
          process.children.map(child => {
            child.children.map(item => {
              if (item.type === PROCESS.TYPE.BARCODE || process.type === PROCESS.TYPE.AUTOMATED) {
                item.isSaved = true;
              }

              return item;
            })

            return child;
          })
        }

        return process;
      })
      setHasRemovedBarcode(false)
      dispatch({
        type: ACTION.SET_PRODUCT_PROCESSES,
        payload: productProcesses
      });

      return;
    }

    /**
     * By default, boot up from stub initial data when no data is
     * saved locally or by the server.
     */
    dispatch({
      type: ACTION.SET_PRODUCT_PROCESSES,
      payload: initialData
    })


  };

  const updateBlockType = product => {
    closeModal();
    const currentBlockTypes = product && product.blockDefinition.map(block => block.blockType);
    setCurrentProductBlockTypes(currentBlockTypes);
  }

  const setFlowFromSelectedProduct = (flow) => {
    if (flow) {
      const productFlow = [...flow];
      productFlow.map(flow => {
        if (flow.name === PROCESS.TYPE.QUEUED) {
          setQueueRules(flow.queueRules || [])
          setOriginalQueueRules(flow.queueRules || [])
        };

        if (flow.name === PROCESS.TYPE.READY) {
          setBatchRules(flow.batchRules || [])
          setOriginalBatchRules(flow.batchRules || [])
        };

        if (flow.name === PROCESS.TYPE.PRE_DISPATCH) {
          setPreDistachRules({ ...preDistachRules, box_ranges: flow.freightRanges, is_letter_range: flow.isLetterRange, freight_group_id: flow.scan.freightGroup })
          setOriginalDispatchRules({ ...originalDispatchRules, box_ranges: flow.freightRanges, is_letter_range: flow.isLetterRange, freight_group_id: flow.scan.freightGroup })
        }

        if (flow.name === PROCESS.TYPE.MATCH_OUT) {
          setMatchOutRules({ ...matchOutRules, box_ranges: flow.matchRanges, match_group_id: flow.scan.matchGroup })
          setOriginalMatchOutRules({ ...originalMatchOutRules, box_ranges: flow.matchRanges, match_group_id: flow.scan.matchGroup })
        }

        return flow;
      })
    } else {
      setQueueRules([])
      setBatchRules([])
    }
  }

  const updateProductFlow = {
    loading: false
  };

  const productID = currentProduct && currentProduct.productDefinition.id

  /**
   * Will be used to check if the current product hasn't changed
   */
  const prevProductID = usePreviousValue(productID)

  /**
   * Calculate whether the current process state has any "unsaved changes".
   * We do this by checking two things:
   * 
   * 1. If the product has a process state saved on the server, we check
   * the value of the current state with that
   * 
   * 2. If no process state is available on the server, we just check the
   * values of the current state with the stub initial state.
   */
  const hasUnsavedChanges = useMemo(() => {
    if (!currentProduct) return false
    if(templateChange){
      return true;
    }
      
    const currentState = JSON.stringify(processes)
    /**
     * If a server state is available, then we'll check if changes
     * have been made from that.
     */

    if (hasRemovedBarcode) {
      return true
    }

    if (
      currentProduct.processes &&
      productID === prevProductID
    ) {
      const serverState = JSON.stringify(currentProduct.processes)
      return serverState !== currentState
    }



    // Otherwise, just check if we've made any changes from initial state
    const initialState = JSON.stringify(initialData)
    return currentState !== initialState
  }, [currentProduct, processes, productID, prevProductID,templateChange,queueRules]); // eslint-disable-line react-hooks/exhaustive-deps

  const getMatchRange = () => {
    ZenSmartAPI.get(matchRangeRoute).then(res => {
      if (res) {
        const matchGroupOptions = res.data;
        setMatchGroupOptions(matchGroupOptions);
      }
    })
  }

  const getProductTemplates = () => {
      ZenSmartAPI.get(fetchProductTemplateList).then(result => {
        setProductTemplates(result.data.data)
      })
  }
    

  const getMatchGroup = () => {
    ZenSmartAPI.get(matchRangeGroups).then(res => {
      if (res) {
        setMatchGroup(res.data);
      }
    })
  }

  const getBlockTypes = () => {
    ZenSmartAPI.get(blockTypesRoute).then(res => {
      if (res) {
        const blockTypesOptions = res.data.data;
        setBlockTypesOptions(blockTypesOptions);
      }
    })
  };

  const getScanTypes = () => {

    ZenSmartAPI.get(scanTypesRoute(true)).then(res => {
      if (res) {
        setScanTypesOptions(res.data.data);
      }
    })
    ZenSmartAPI.get(automatedScanTypesRoute).then(res => {
      if (res) {
        setAutomatedScanTypesOptions(res.data.data);
      }
    })
  }

  const getPickingGroups = () => {

    ZenSmartAPI.get(pickingGroupsRoute).then(res => {
      if (res) {
        setPickingGroupOptions(res.data);
      }
    })

  }

  const getPickingComponents = () => {

    ZenSmartAPI.get(pickingComponentsRoute).then(res => {
      if (res) {
        setPickingComponents(res.data);
      }
    })

  }

  const getPickingLocations = () => {

    ZenSmartAPI.get(pickingLocationsRoute).then(res => {
      if (res) {
        setPickingLocations(res.data.data);
      }
    })

  }


  const getProducts = () => {
    ZenSmartAPI.get(productsRoute).then(res => {
      if (res) {
        const allProductsQuery = res.data.data;
        setProducts(allProductsQuery);
      }
    });
  }

  const getProductFlowInitData = () => {
    ZenSmartAPI.get(productFlowInitData).then(res => {
      if (res) {
        const qualifiers = res.data.types.qualifiers;
        const batchRulesOptions = res.data.types.batch_rules;
        const recipients = res.data.trigger_library ? res.data.trigger_library.recipients : null;
        const triggerEmailTemplates = res.data.trigger_library ? res.data.trigger_library.email_templates : null;
        setQualifiers(qualifiers);
        setBatchRulesOptions(batchRulesOptions);
        setRecipients(recipients);
        setTriggerEmailTemplates(triggerEmailTemplates);
      }
    });
  }

  const getDispatchTemplates = () => {
    ZenSmartAPI.get(dispatchTemplatesRoute).then(res => {
      if (res) {
        setDispatchTemplatesOptions(res.data.data);
      }
    })
  }

  const getFlowConfigured = () => {
    ZenSmartAPI.get(productFlowConfiguredDataRoute).then(res => {
      if (res) {
        setFlowConfigured(res.data.data);
      }
    })
  }

  const getProductFromUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get('product_id');

    if (productId) {
      ZenSmartAPI.get(productIdRoute(productId)).then(result => {
        if (result) {
          let formattedBlockDefinition = result.data.blockDefinition.map(block => {
            return {...block, metadata: { ...block.metadata, ...block.metadata?.extras, xOffset: block?.metadata?.extras?.x_offset, yOffset: block?.metadata?.extras?.y_offset } }
          })
          result.data.blockDefinition = formattedBlockDefinition;
          const product = { ...result.data };
          let isNewProduct = false;
          if (!product.processes) {
            product.processes = initialData;
            isNewProduct = true;
          }
          setProduct(product, isNewProduct)
          return;
        } else {
          notification.error({
            message: 'Product does not exist'
          })
        }

      }).catch(error => {
        notification.error({
          message: 'Product does not exist'
        })
      })
    }
  }

  /**
   * At this point, we decide where to boot up the `processes` state from.
   * For now there are three paths that can happen:
   * 
   * 1. When a local state for a particular product exists, boot up from local
   * 2. When no local state exists but a server state does, boot up from server
   * 3. When both local and server state doesn't exist, boot up from stub data
   */
  useEffect(() => {
    getMatchRange();
    getMatchGroup();
    getBlockTypes();
    getScanTypes();
    getProductFlowInitData();
    getDispatchTemplates();
    getFlowConfigured();
    getPickingGroups();
    getPickingComponents();
    getPickingLocations();
    getProductFromUrlParams();
    getProductTemplates();
    if (!currentProduct) return

    // Boot up from server when the given product doesn't have it locally.
    if (currentProduct.flow[0]) {
      dispatch({
        type: ACTION.SET_PRODUCT_PROCESSES,
        payload: currentProduct.flow
      })
      return
    }

    /**
     * By default, boot up from stub initial data when no data is
     * saved locally or by the server.
     */
    dispatch({
      type: ACTION.SET_PRODUCT_PROCESSES,
      payload: initialData
    })

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentProduct && currentProduct.blockDefinition) {
      setBlockDefinition(currentProduct.blockDefinition)
    }
    if (currentProduct && currentProduct.is_letter_range) {
      setLetterRange(currentProduct.is_letter_range)
    }
  }, [currentProduct]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(gamificationQuiz.length === 0) {
      ZenSmartAPI.get(getAllQuiz(''))
        .then((res) => {
          setGamificationQuiz(res?.data ?? [])
        })
        .catch((res) => {
        })}
  }, [])


  const handleBeforeCapture = item => {
    const dragType = getDragType(item.draggableId)
    setDragType(dragType)
  }

  const handleDragEnd = result => {
    setDragType(null)
    setCloseModalOnDrop(false)

    const { draggableId, source, destination } = result

    if (!destination || source.droppableId === destination.droppableId) {
      return
    }

    const dropType = getDropType(destination.droppableId)
    const droppableId = destination.droppableId.split('__')
    const rootChild = droppableId[1]
    const processPaths = getProcessPath(droppableId[0])
    /* check the droppable id for either __root or __child set inside DropZone.js */
    const showQueueRules = destination.droppableId.split('__').length > 1
    const qRulesStage = parseInt(destination.droppableId.split('__')[2])

    if (dropType === DROP.ITEM) {
      const triggerType = getDragType(draggableId)

      showNewItemTypeModal(triggerType)
      setCreateItemCbData({
        actionType: ACTION.ADD_TRIGGER,
        processPaths,
        rootChild
      })

    }

    if (dropType === DROP.SIBLING) {
      const processType = getDragType(draggableId)
      const {processPath} = processPaths
      const isRootPath = processPath.length < 2

      if (processes[parseInt(processPath) + 1].text === "Match Out" && !processPath[1]) {
        return
      }
      showNewItemTypeModal(processType)
      setCreateItemCbData({
        actionType: isRootPath ? ACTION.ADD_ROOT_SIBLING : ACTION.ADD_SIBLING,
        processPaths,
        rootChild
      })
    }

    if (dropType === DROP.ACCEPT) {
      const processType = getDragType(draggableId)

      /**
       * Don't open any modal. Just add in the imposition item
       * as a child of a Block
       *
       */


      // if (processType === PROCESS.TYPE.IMPOSITION) {
      //   dispatch({
      //     type: ACTION.ADD_CHILDREN,
      //     payload: {
      //       processType,
      //       processPaths
      //     }
      //   })
      //   // return
      // }

      showNewItemTypeModal(processType)
      setCreateItemCbData({
        actionType: ACTION.ADD_CHILDREN,
        processPaths,
        rootChild
      })
    }

    /**
     * For any new process item dragged BELOW, we'll need to add queue rules for that entire row
     * Once dropped in the DropZone, we'll need to check if there are existing queueRules for the parent process and update the stage
     * For example, if we drag an item down at stage 2, if we have an item currently in stage 2, that will need to get
     * updated to stage 3
     */

    if (showQueueRules) {
      const { processPath, childrenPath, processID } = processPaths
      let qProcessID = childrenPath.length ? processID.split(".").slice(0, -1).join(".") : processID
      let qProcessPath = childrenPath.length ? [...processPath].slice(0, -1) : processPath
      
      const targetProcess = getObject(processes, qProcessPath, processes)
      let updatedQualifiers = []

      if (targetProcess.qualifiers && targetProcess.qualifiers.length) {
        updatedQualifiers = targetProcess.qualifiers.map(r => {
          if (r.stage && r.stage >= qRulesStage) {
            r.stage = r.stage + 1
          }
          return r
        }).filter(rule => rule.stage === qRulesStage)
      }

      setProcess(targetProcess)
      setProcessQualifiers(updatedQualifiers)
      setOriginalProcessQualifiers(updatedQualifiers)
      setQueueRulesStage(qRulesStage)
      setItemID(`<${DROP.ITEM}>${qProcessID}`)
      setShowQueueRulesModal(true)
      return
    }
  }

  // const saveData = async (data = false) => {
  //   try {
  //     const newData = data ? data : currentProduct
  //     const payload = await {
  //       ...newData,
  //       processes,
  //       ...constructFlowPayload(processes, queueRules, [...batchRules], preDistachRules, matchOutRules, currentProduct)
  //     };
  //     let formattedBlockDefinition = blockDefinition.map(block => {
  //       return {...block, metadata: { 
  //         ...block.metadata, ...block.metadata?.extras, 
  //         media: block?.metadata?.media ?? block?.metadata?.extras?.media, 
  //         ink: block?.metadata?.ink ?? block?.metadata?.extras?.ink, 
  //         metaMode: block?.metadata?.metaMode ?? block?.metadata?.extras?.metaMode, 
  //         pallet: block?.metadata?.pallet ?? block?.metadata?.extras?.pallet, 
  //         xOffset: block?.metadata?.xOffset ?? block?.metadata?.extras?.x_offset, 
  //         yOffset: block?.metadata?.yOffset ?? block?.metadata?.extras?.y_offset, 
  //       }}
  //     })
  //     payload.blockDefinition = formattedBlockDefinition

  //     ZenSmartAPI.post(productFlowRoute, payload).then(res => {
  //       const currentProduct = res.data.data;
  //       setHasRemovedBarcode(false)
  //       setProduct(currentProduct);
  //       localStorage.removeItem(`unsaved:${productID}`)
  //       notification.success({
  //         message: 'Successfully saved product flow changes'
  //       })
  //       fetchScanTypes(reduxDispatch);
  //       getScanTypes();
  //       getFlowConfigured();

  //     }).catch(res => {
  //       if (_.get(res, 'response.status') === 422) {
  //         const errorMessage = errorHandler(_.get(res, 'response.data.errors'))
  //         notification.error({
  //           message: errorMessage ?? res?.message
  //         })
  //         return
  //       }
  //       else if (_.get(res, 'response.data.message')) {
  //         notification.error({
  //           message: _.get(res, 'response.data.message')
  //         })
  //         return
  //       }
  //       else {
  //         notification.error({
  //           message: 'Failed to save product flow changes'
  //         })
  //       }
  //     })


  //   } catch (error) {
  //     if (error && error.response && error.response.data.message) {
  //       notification.error({
  //         message: error.response.data.message
  //       })
  //     }
  //     else {
  //       notification.error({
  //         message: 'Failed to save product flow changes'
  //       })
  //     }
  //   }
  // }
  const saveData = async (applyTemplateToAllProducts,data = false) => {
    // if (!currentProduct) return

    // if (!preDistachRules.box_ranges || preDistachRules.box_ranges.length === 0) {
    //   notification.error({ message: "You must set a pre-dispatch range to save this product!" })
    //   return
    // }
    try {
      const newData = data ? data : currentProduct
      const payload = await {
        ...newData,
        processes,
        ...constructFlowPayload(processes, queueRules, [...batchRules], preDistachRules, matchOutRules, currentProduct,applyTemplateToAllProducts)
      };

      currentProduct.blockDefinition.map((data, key) => {
        if (payload.blockDefinition[key] !== undefined) {
          payload.blockDefinition[key].id = data.id
        }
        return data
      })

      ZenSmartAPI.post(productFlowRoute, payload).then(res => {
        const currentProduct = res.data.data;
        setHasRemovedBarcode(false)
        setProduct(currentProduct);
        localStorage.removeItem(`unsaved:${productID}`)
        notification.success({
          message: 'Successfully saved product flow changes'
        })
        setTemplateChange(false)
        fetchScanTypes(reduxDispatch);
        getFlowConfigured();

      }).catch(error => {
        if (error && error.response && error.response.data.message) {
          notification.error({
            message: error.response.data.message
          })
        }
        else {
          notification.error({
            message: 'Failed to save product flow changes'
          })
        }
      })


    } catch (error) {
      if (error && error.response && error.response.data.message) {
        notification.error({
          message: error.response.data.message
        })
      }
      else {
        notification.error({
          message: 'Failed to save product flow changes'
        })
      }
    }
  }

  const saveProductFlow = async () => {
    if (!currentProduct) return
    //only update if it has unsaved changes
    if (!preDistachRules.box_ranges || preDistachRules.box_ranges.length === 0) {
      notification.error({ message: "You must set a pre-dispatch range to save this product!" })
      return
    }
    let applyTemplateToAllProducts = false
    //checkng if it has template then only open modal
    if(currentProduct.productDefinition.template){
      Modal.confirm({
        'content': `Update all the products related to the template ?`,
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          //update all products related to products
          applyTemplateToAllProducts = true
          saveData(applyTemplateToAllProducts)
        },
        onCancel: () => {
          setCreateNewTemplate(true)
          return
        }
      });
    }else{
      //normal flow
      saveData(applyTemplateToAllProducts)
    }
  }

  const closeModal = (source) => {
    showNewItemTypeModal(null)
    setCreateNewTemplate(false)
    setCreateItemCbData({})
    if (source === 'cancel') {
      setCloseModalOnDrop(true)
    }
  }

  const closeQueueRulesModal = () => {
    setShowQueueRulesModal(false)
    setQualifiersToRemove([])
    setProcess(false)
    setProcessQualifiers([])
    setOriginalProcessQualifiers([])
    setQueueRulesStage(0)
    setItemID(null)
  }

  const createNewItem = useCallback(values => {
    const { actionType, processPaths, rootChild } = createItemCbData
    dispatch({
      type: actionType,
      payload: {
        processType: newItemType,
        processPaths,
        rootChild,
        triggerName: newItemType,
        values
      }
    })
    // eslint-disable-next-line
  }, [
    createItemCbData.actionType,
    createItemCbData.processPath,
    newItemType
  ])

  const templateChanges = payload => {
    if( (currentProduct.productDefinition.template === null && payload.product_template_id) || currentProduct.productDefinition.template !== payload.product_template_id){
      const newData = _.cloneDeep(currentProduct)
      newData.productDefinition.template = payload.product_template_id
      setCurrentProduct(newData)
      setTemplateChange(true)
    }else{
      setTemplateChange(false)
    }
  }

  const addTemplateThenSave = payload => {
    const newData = _.cloneDeep(currentProduct)
    newData.productDefinition.template = payload.id
    setCurrentProduct({currentProduct, ...newData});
    saveData(false,newData)
  }

  const updateProcess = (newProcessData, processID, toRemove = [], qualifiers = false, child = null) => {
    const processPaths = getProcessPath(processID)
    const {processPath} = processPaths
    dispatch({
      type: ACTION.UPDATE_PROCESS_DATA,
      payload: { processPath, qualifiers, toRemove, child, newProcessData }
    })
    closeQueueRulesModal()
  }

  const updateTrigger = (newTriggerData, triggerName, processID) => {
    const processPaths = getProcessPath(processID)
    const {processPath} = processPaths
    dispatch({
      type: ACTION.UPDATE_TRIGGER_DATA,
      payload: { processPath, triggerName, ...newTriggerData }
    })
  };

  const removeTrigger = (triggerName, processID) => {
    const processPaths = getProcessPath(processID)
    const {processPath} = processPaths
    dispatch({
      type: ACTION.REMOVE_TRIGGER_DATA,
      payload: { processPath, triggerName }
    })
  };

  const onRemoveBarcode = (item) => {
    setHasRemovedBarcode(true)
    dispatch({
      type: ACTION.REMOVE_CHILDREN,
      payload: item
    })
  };

  const closeTemplateModal = () => {
    setTemplateModal(false)
  };

  const saveTemplateFlow = (processes, flow) => {
    setFlowFromSelectedProduct(flow);
    dispatch({
      type: ACTION.SET_PRODUCT_PROCESSES,
      payload: processes
    })
  }

  const updateForceSeparateQueues = payload => {
    const newData = _.cloneDeep(currentProduct)
    newData.productDefinition.forceSeparateQueues = payload
    setCurrentProduct({currentProduct, ...newData});
  }

  return (
    <>
      <Header>
        <Title>Process {">"} Product Flow Designer</Title>
      </Header>

      <Panel title="EDIT PRODUCT">
        <Section>
          <SearchArea
            modal={modal}
            currentProduct={currentProduct}
            setCurrentProduct={setCurrentProduct}
            allProductsQuery={allProductsQuery}
            setProduct={setProduct}
            hasUnsavedChanges={hasUnsavedChanges}
            onSave={templateChanges}
            productTemplates={productTemplates}
            setProductTemplates={setProductTemplates}
          />
          <ProductTemplateUpdateModel
            title={`Create New Product Template`}
            visible={createNewTemplate}
            onCancel={closeModal}
            onSave={addTemplateThenSave}
            footer={null}
            productTemplates={productTemplates}
            setProductTemplates={setProductTemplates}
          />        

        </Section>

        {templateModal && (
          <TemplateModal
            title={`Select Template`}
            visible={true}
            onCancel={closeTemplateModal}
            onSave={saveTemplateFlow}
            flowConfigured={flowConfigured}
            footer={null}
          />
        )}

        {currentProduct && (
          <Section>
            <ArticleArea
              description={currentProduct.productDefinition.name}
              name={currentProduct.productDefinition.name}
              saveProductFlow={saveProductFlow}
              hasUnsavedChanges={hasUnsavedChanges}
              updateProductFlow={updateProductFlow}
              setCurrentProduct={setCurrentProduct}

            />
          </Section>
        )}

        {newItemType ? (
          <>
            <ProcessItemTextModal
              title={`Create ${newItemType}`}
              visible={validProcessItemTextModalTypes.includes(newItemType)}
              onCancel={closeModal}
              onSave={createNewItem}
              footer={null}
            />

            <AutomatedStageModal
              title={`Create ${newItemType}`}
              gamificationQuiz={gamificationQuiz}
              visible={newItemType === PROCESS.TYPE.AUTOMATED}
              onCancel={closeModal}
              onSave={createNewItem}
              footer={null}
              scanTypesOptions={automatedScanTypesOptions}


            />

            <PreDispatchModal
              title={`Create ${newItemType}`}
              visible={newItemType === PROCESS.TYPE.PRE_DISPATCH}
              freightGroupOptions={freightGroupOptions}
              onCancel={closeModal}
              onSave={createNewItem}
              footer={null}
            />

            <DispatchModal
              title={`Create ${newItemType}`}
              visible={newItemType === PROCESS.TYPE.DISPATCH}
              dispatchTemplatesOptions={dispatchTemplatesOptions}
              onCancel={closeModal}
              gamificationQuiz={gamificationQuiz}
              onSave={createNewItem}
              footer={null}
            />

            <BarcodeDetailsModal
              title={`Create ${newItemType}`}
              visible={newItemType === PROCESS.TYPE.BARCODE || newItemType === PROCESS.TYPE.IMPOSITION}
              newItemType={newItemType}
              gamificationQuiz={gamificationQuiz}
              onCancel={closeModal}
              onSave={createNewItem}
              footer={null}
              scanTypesOptions={scanTypesOptions}
            />

            <PickingModal
              title={`Create ${newItemType}`}
              visible={newItemType === PROCESS.TYPE.PICKING}
              newItemType={newItemType}
              onCancel={closeModal}
              onSave={createNewItem}
              footer={null}
              scanTypesOptions={scanTypesOptions}
              pickingGroupOptions={pickingGroupOptions}
              pickingComponents={pickingComponents}
              pickingLocations={pickingLocations}
              setPickingGroupOptions={setPickingGroupOptions}
              setPickingLocations={setPickingLocations}
              setPickingComponents={setPickingComponents}
            />

            <BlockDetailsModal
              title={`Create ${newItemType}`}
              visible={newItemType === PROCESS.TYPE.BLOCK}
              onCancel={closeModal}
              onSave={createNewItem}
              footer={null}
              width={1200}
              currentProductBlockTypes={currentProductBlockTypes}
              setCurrentProductBlockTypes={setCurrentProductBlockTypes}
              blockTypesOptions={blockTypesOptions}
              blockDefinition={blockDefinition}
              setBlockDefinition={setBlockDefinition}
            />

            <EmailTriggerModal
              title="Attach Email Trigger"
              width={700}
              visible={newItemType === PROCESS.TRIGGER.EMAIL}
              onCancel={closeModal}
              onSave={createNewItem}
              removeTrigger={removeTrigger}
              footer={null}
              qualifiers={qualifiers}
              recipients={recipients}
              emailTemplates={triggerEmailTemplates}
            />

            <FieldUpdateTriggerModal
              title="Attach Field Update Trigger"
              visible={newItemType === PROCESS.TRIGGER.FIELD}
              onCancel={closeModal}
              onSave={createNewItem}
              removeTrigger={removeTrigger}
              footer={null}
              qualifiers={qualifiers}
              width={700}
            />

            <CalloutTriggerModal
              title="Attach Callout Trigger"
              visible={newItemType === PROCESS.TRIGGER.CALLOUT}
              onCancel={closeModal}
              onSave={createNewItem}
              removeTrigger={removeTrigger}
              footer={null}
              width={750}
              qualifiers={qualifiers}
            />

            <AddCostTriggerModal
              title="Attach Add Cost Trigger"
              visible={newItemType === PROCESS.TRIGGER.ADD_COST}
              onCancel={closeModal}
              onSave={createNewItem}
              removeTrigger={removeTrigger}
              footer={null}
            />
          </>
        ) : null}
        {showQueueRulesModal && !newItemType && !closeModalOnDrop && currentProduct && (
          <QueueRulesModal
            visible={true}
            onCancel={closeQueueRulesModal}
            edit={false}
            footer={null}
            processID={itemID}
            process={process}
            onSave={updateProcess}
            queueRules={processQualifiers}
            setQueueRules={setProcessQualifiers}
            stage={queueRulesStage}
            qualifiersToRemove={qualifiersToRemove}
            setQualifiersToRemove={setQualifiersToRemove}
            qualifiers={qualifiers}
            originalQueueRules={originalProcessQualifiers}
            setOriginalQueueRules={setOriginalProcessQualifiers}
            forceSeparateQueues={currentProduct?.productDefinition?.forceSeparateQueues}
            updateForceSeparateQueues={updateForceSeparateQueues}
          />
        )}

        {currentProduct && (
          <DragDropContext
            onBeforeCapture={handleBeforeCapture}
            onDragEnd={handleDragEnd}
          >
            <EditArea
              qualifiers={qualifiers}
              recipients={recipients}
              emailTemplates={triggerEmailTemplates}
              queueRules={queueRules}
              batchRules={batchRules}
              preDistachRules={preDistachRules}
              letterRange={letterRange}
              productID={productID}
              prevProductID={prevProductID}
              processes={processes}
              dragType={dragType}
              matchGroupOptions={matchGroupOptions}
              freightGroupOptions={freightGroupOptions}
              currentProductBlockTypes={currentProductBlockTypes}
              setCurrentProductBlockTypes={setCurrentProductBlockTypes}
              scanTypesOptions={scanTypesOptions}
              automatedScanTypesOptions={automatedScanTypesOptions}
              dispatchTemplatesOptions={dispatchTemplatesOptions}
              blockTypesOptions={blockTypesOptions}
              onSave={createNewItem}
              modal={modal}
              updateProcess={updateProcess}
              updateTrigger={updateTrigger}
              getMatchRange={getMatchRange}
              getMatchGroup={getMatchGroup}
              onRemoveBarcode={onRemoveBarcode}
              setQueueRules={setQueueRules}
              setBatchRules={setBatchRules}
              setPreDistachRules={setPreDistachRules}
              setOriginalDispatchRules={setOriginalDispatchRules}
              originalDispatchRules={originalDispatchRules}
              removeTrigger={removeTrigger}
              batchRulesOptions={batchRulesOptions}
              blockDefinition={blockDefinition}
              primaryGroupCode={currentProduct?.productDefinition?.primaryGroup ?? ''}
              setBlockDefinition={setBlockDefinition}
              originalQueueRules={originalQueueRules}
              setOriginalQueueRules={setOriginalQueueRules}
              originalBatchRules={originalBatchRules}
              setOriginalBatchRules={setOriginalBatchRules}
              pickingGroupOptions={pickingGroupOptions}
              pickingComponents={pickingComponents}
              pickingLocations={pickingLocations}
              setPickingLocations={setPickingLocations}
              setPickingComponents={setPickingComponents}
              matchOutRules={matchOutRules}
              setMatchOutRules={setMatchOutRules}
              originalMatchOutRules={originalMatchOutRules}
              setOriginalMatchOutRules={setOriginalMatchOutRules}
              matchGroup={matchGroup}
              setMatchGroup={setMatchGroup}
              setPickingGroupOptions={setPickingGroupOptions}
              forceSeparateQueues={currentProduct?.productDefinition?.forceSeparateQueues}
              updateForceSeparateQueues={updateForceSeparateQueues}
            />
          </DragDropContext>
        )}
      </Panel>
    </>
  )
}

export default ProductFlow