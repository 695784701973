import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, Input, Modal, Form, Button, Pagination } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenSmartAPI, searchValueInRow } from 'utils'
import { getQaFailFormBatchRoute, qaFailMultipleBatch, getAllDepartments, getBySettingUrlPopulate } from "utils/apiRoutes"
import { Label } from 'components/zensmart-design-system/components/Label/Label.js';
import debounce from 'lodash/debounce';
import * as _ from "lodash";
import { useHistory } from 'react-router-dom'

const BatchLinkOverviewPage = createGlobalStyle`

    .page-header {
        margin-bottom: 24px;
    }

    .page-title {
        font-size: 20px;
        color: ${color.heading};
        font-weight: normal;        
    }

    .control-label {
        font-size : 15px;
        font-family: 'Rubik', sans-serif;
        font-weight : bold;
    }

    .ant-input-lg::placeholder {
        color: blue;
    }

    .pagination-container {
        padding: 10px 15px;
    }

`

const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}


.ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}

`

const { Option } = Select;

const title = 'Batch Link Overview';

const excluded_fields = ['earliest_line_due_date'];

const BatchLinkOverview = (props) => {

    const [loading, setLoading] = useState(false)
    const [departments, setDepartments] = useState([])
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [queryParams, setQueryParams] = useState({});
    const [selectedCheckBoxValue, setSelectedCheckBoxValue]= useState([])
    const [batchLinkCodes, setBatchLinkCodes]= useState([])
    const [paginationData, setPaginationData]= useState({})
    const [selectedAction, setSelectedAction]= useState(0)
    const [showModal, setShowModal] = useState(false)
    const [formValues, setFormValues] = useState(null)
    const searchRef = useRef();
    const maxDwellRef = useRef();
    const actionsRef = useRef();
    const inputs = {
        'search'    : searchRef,
        'max_dwell' : maxDwellRef
    };
    const [inputToFocus, setInputToFocus] = useState('search');
    const fail_batch_string = 'fail_batch';
    const history = useHistory();

    const FailForm = Form.create({ name: 'FailForm' })(props => {
        const { form } = props
        const { getFieldDecorator, getFieldsValue, validateFields } = form

        const handleSaveChanges = (evt) => {
            evt.preventDefault()
            validateFields((err, values) => {
                if (!err) {
                    setLoading(true);
                    const payload = getFieldsValue();
                    const batch_ids = selectedCheckBoxValue.join(';');

                    ZenSmartAPI.post(qaFailMultipleBatch(batch_ids), payload)
                        .then((res) => {
                            notification.success({ message: "Save lines successful!" })
                            setLoading(false);
                            hideModal()
                            setSelectedCheckBoxValue([])
                            fetchData(queryParams, 'search');
                        })
                        .catch((res) => {
                            setLoading(false);
                            notification.error({ message: "Failed saving a lines!" })
                        })
                }
            });
        }
        const getFormSelectedValue = (value) => {
            const getSelected = value.find((item) => {
                return item.selected === true
            })
            if (getSelected !== undefined) {
                return getSelected.value
            }
        }

        return (
            <>
                {formValues &&
                    <>
                        <p style={{ textAlign: "center" }}>
                            <Label status="blue" >{formValues.message}</Label>
                        </p>

                        <Form onSubmit={handleSaveChanges}>
                            <div style={{ padding: "10px 50px" }}>

                                {formValues ? formValues.page_definitions.sections[0].section_data.map((row, key) =>

                                    <Form.Item label={<span className='bold-text'>{row.label}</span>} >
                                        {getFieldDecorator(row.field, {
                                            initialValue: row.options ? getFormSelectedValue(row.options) : null,
                                            rules: row.required === true ? [{ required: true, message: `Please input this feld!` }] : undefined
                                        })(
                                            row.data_type === "SELECT" ?
                                                <Select placeholder={row.label} size="large"
                                                >
                                                    {row.options.map((data, key) =>
                                                        <Option value={data.value}>{data.text}</Option>
                                                    )}
                                                </Select>
                                                :
                                                <Input
                                                    placeholder={row.required === true ? "Please Input This Field" : "*Optional"}
                                                    size="large"
                                                />
                                        )}
                                    </Form.Item>
                                ) : null}
                            </div>
                            <Row type="flex" justify="center" align="middle">
                                <Col span={5} style={{ padding: 10 }}>
                                    <Form.Item>
                                        <Button type="danger" style={{ width: "100%" }} onClick={hideModal}
                                        >Cancel</Button>
                                    </Form.Item>

                                </Col>
                                <Col span={7} style={{ padding: 10 }} >
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading ? true : undefined}
                                            style={{ width: "100%" }}
                                        >
                                            Save Changes
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }
            </>
        )
    })    


    useEffect(() => {
        const loadPage = async () => {
            let query_params = {
                sort_direction: 'ascend',
                sort_field: 'batch_link_code'
            };
            await isShowable();
            setQueryParams(query_params);
            fetchDepartments()
            fetchData(query_params);
        }

        loadPage();
    }, [])

    const fetchDepartments = () => {
        ZenSmartAPI.get(getAllDepartments).then((res) => {
            setDepartments(res.data.data)
        }).catch((res) => {
        })
    }

    const fetchData = (params = {}, focus_after = 'search') => {
        setLoading(true)
        if(!params.page) {
            params.page = 0;
        }
        ZenSmartAPI('api/v1/batch-link-overview', {params}).then(res => {
            let {batches, total, page, limit} = res.data.data;
            if(page == 0) {
                renderPagination(total, limit);
            }
            setData(batches);
        }).finally(() => {
            setLoading(false);
            inputs[focus_after].current.focus();
        })
    }

    const renderPagination = (total, limit) => {
        let pagination_data = {
            current: 1,
            total: total,
            pageSize: limit,
        }

        setPaginationData(pagination_data);
    }

    const handlePageChanged = (current) => {
        fetchData({
            ...queryParams,
            page: current,
        }, 'search');
        setPaginationData({
            ...paginationData,
            current
        });
    }

    const isShowable = async () => {
        await ZenSmartAPI(getBySettingUrlPopulate('batch', 'show_batch_link_overview_page')).then(res => {
            const is_showable = res.data.data.val;
            if(is_showable === '0' || is_showable === 0 || is_showable === 'false' || is_showable === false) {
                notification.error({ message: "Cannot Access Batch Link Overview Page!" })
                history.push({
                    pathname: `/app/home`
                })
            }
        }).catch((e) => {
            console.log(e)
        });
    }

    const handleDepartmentChanged = (department_id) => {
        let query_params = {
            ...queryParams,
            department_id
        }
        setQueryParams(query_params);
        fetchData(query_params)
    }

    const handleSearch = debounce((search) => {
        let query_params = {
            ...queryParams,
            search
        }
        setQueryParams(query_params);
        fetchData(query_params, 'search')
    }, 500)

    const hideModal = () => {
        setShowModal(false)
        setFormValues(null)
    }    

    const handleDwellOverChanged = debounce((dwell_over_amount) => {
        let query_params = {
            ...queryParams,
            dwell_over_amount
        }
        setQueryParams(query_params);
        fetchData(query_params, 'max_dwell')
    }, 500)

    const handleActionSelected = (value) => {
        if(value == fail_batch_string) {
            handleFailBatch()
        }
        setSelectedAction(0);
    }

    const handleFailBatch = () => {
        const batch_ids = selectedCheckBoxValue.join(';');
        getQaFailForm(batch_ids);
    }

    const getQaFailForm = (batch_ids) => {
        setLoading(true)
        ZenSmartAPI.get(getQaFailFormBatchRoute(batch_ids)).then(res => {
            if (res.data && res.data.page_definitions.sections[0].section_data.length > 0) {
                setFormValues(res.data)
                setShowModal(true)
            }
            else {
                notification.error({ message: "No available input forms!" })
            }            
        }).catch(err => {

        }).finally(() => {
            setLoading(false);
        })
    }

    const handleTableChanged = (pagination, filters, sorter) => {
        let query_params = {
            ...queryParams,
            sort_field: sorter.field,
            sort_direction: sorter.order
        }
        setQueryParams(query_params);
        fetchData(query_params)
    }

    const onShowSizeChange = (current, page_size) => {
        let query_params = {
            ...queryParams,
            page: 0,
            limit: page_size
        }
        setQueryParams(query_params);
        fetchData(query_params)
    }

    return (
        <>
            <BatchLinkOverviewPage />
            <header className='page-header'>
                <h1 className='page-title'>Pack & Ship {">"} {title}</h1>
            </header>
            <Panel title={title}>

                <div style={{ padding: "40px 0px" }}>
                    <Row type="flex" align="middle" justify="start" style={{ padding: 30 }}>
                        <Col span={4} style={{ padding: 10 }}>
                            <label className='control-label'>SEARCH</label>
                            <Input.Search style={{ marginTop: 10, width: '100%' }}
                                type="text"
                                placeholder="Filters.."
                                disabled={loading}
                                onChange={(e) => handleSearch(e.target.value)}
                                ref={searchRef}
                            />
                        </Col>
                        <Col span={4} style={{ padding: 10 }}>
                            <label className='control-label'>DEPARTMENT</label>
                            <Select defaultValue={0} style={{ marginTop: 10, width: '100%' }} onChange={handleDepartmentChanged} placeholder="Select Vendor"
                                optionFilterProp="children"
                                showSearch  >
                                <Option value={0}>All Departments</Option>
                                {departments.map(data =>
                                    <Option key={data.id} value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={4} style={{ padding: 10 }}>
                            <label className='control-label'>Dwell Over</label>
                            <Input style={{ marginTop: 10, width: '100%' }}
                                type="number"
                                placeholder="Dwell Over"
                                disabled={loading}
                                min={0}
                                onChange={(e) => handleDwellOverChanged(e.target.value)}
                                ref={maxDwellRef}
                            />
                        </Col>     
                        <Col span={4} offset={8} style={{ padding: 0 }}>
                            <label className='control-label'>Action</label>
                            <Select value={selectedAction} style={{ marginTop: 10, width: '100%' }}  onChange={(value) => {handleActionSelected(value)}} >
                                <Option value={0} disabled>Select Action</Option>
                                <Option value={fail_batch_string} >Fail Selected</Option>
                            </Select>
                        </Col>                   
                    </Row>
                    <TableData loading={loading} itemData={data} selectedCheckBoxValue={selectedCheckBoxValue}
                        setSelectedCheckBoxValue={setSelectedCheckBoxValue} handleTableChanged={handleTableChanged}></TableData>
                    <Row type='flex' align='end' className='pagination-container'>
                        <Pagination {...paginationData} onChange={handlePageChanged} onShowSizeChange={onShowSizeChange}
                            showSizeChanger={true} pageSizeOptions={['5', '10', '20', '50', '100']}></Pagination>
                    </Row>
                </div>

            </Panel>
            <>
                <ModalStyle />
                <Modal
                    title=""
                    centered
                    width={650}
                    visible={showModal}
                    footer={null}
                    onCancel={hideModal}
                >
                    <div style={{ padding: 20 }}>
                        <FailForm />
                    </div>
                </Modal>
            </>
        </>
    )
}

export default BatchLinkOverview