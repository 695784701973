import axios from 'axios';

const GetPercentage = async (requestData = {
  table: '',
  groups: [],
  percent_filter: [],
  secondary_filter: [],
  primary_filter: [],
  fields: [],
  bucket_fields: []
}) => {
  let res = await axios.post(`${process.env.REACT_APP_ANALYTICS_SERVER}/percentage`, requestData)
  let { data } = res;
  return data;
}

export default GetPercentage