import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import is from "is_js";
import { GetDashboard, GetSavedReports } from "pages/Performance/api";
import { DashboardWidget } from "pages/Performance/Widget";
import { REPORTS } from "store/actions/dashboardTypes";
import { useDispatch } from "react-redux";

function DashboardViewer() {
  const { dashboardID } = useParams();
  const [viewMapping, setViewMapping] = useState([]);
  const [viewLayout, setViewLayout] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  //useEffect to load only once
  useEffect(() => {
    if (is.not.existy(dashboardID)) {
      setErrorMessage("Please enter dashboard id");
    } else if (isNaN(dashboardID) || is.not.integer(Number(dashboardID))) {
      setErrorMessage("Please enter a valid dashboard id");
    } else {
      GetSavedReports().then(({ data }) => {
        dispatch({
          type: REPORTS,
          payload: data,
        });
        GetDashboard(dashboardID)
          .then(({ data }) => {
            if (is.existy(data)) {
              setErrorMessage(null);
              let layout = [];
              let mapping = [];
              data.dashboards[0].dashboard_reports.forEach((d) => {
                layout = [...layout, d.layout];
                mapping = [
                  ...mapping,
                  { layout_id: d.layout.i, report_id: d.report_id },
                ];
              });
              setViewMapping(mapping);
              setViewLayout(layout);
            } else {
              setErrorMessage("No record found");
            }
          })
          .catch((err) => {
            setErrorMessage("Error: " + err);
          });
      });
    }
    setInterval(function () {
      window.location.reload();
    }, 300000);
  }, []);

  const checkToken = () => is.not.existy(localStorage.getItem("token"));

  return checkToken() ? (
    <Redirect to="/app" />
  ) : is.not.existy(errorMessage) ? (
    <DashboardWidget
      refreshData={true}
      interruptTimer={() => {
        if (checkToken()) {
          setErrorMessage("Logged out");
          return true;
        }
        return false;
      }}
      dashboardMapping={viewMapping}
      dashboardLayout={viewLayout}
    />
  ) : (
        <div>{errorMessage}</div>
      );
}

export default DashboardViewer;
