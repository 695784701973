export const reasons = [
  {value: '1', text: 'Reason A'},
  {value: '2', text: 'Reason B'},
  {value: '3', text: 'Reason C'},
  {value: '4', text: 'Reason D'},
]

export const failType = [
  {value: 'reprint', text: 'Re-print'},
  {value: 'rework', text: 'Rework'},
  {value: 'page_reprint', text: 'Re-print pages'},
]

export const tasks = [
  {name: 'GP2 Roller Assembly Inspection', done: true},
  {name: 'OHS Team Meeting 11:00AM', done: false},
  {name: 'GP2 End Of Day Clean Down', done: true},
  {name: 'Team Lunch 12:30PM', done: false},
]

export const teamTargets = [
  {label: 'WIP DUE TODAY', value: '1,300', status: 'blue'},
  {label: 'WIP LEFT', value: '23,124', status: 'blue'},
  {label: 'OVERDUE', value: '541,544', status: 'red'},
  {label: 'VIP', value: '14', status: 'buttercup'},
]

export const workList = [
  {name: 'COVERS TO MAKE', value: '615'},
  {name: 'COVERS MADE', value: '1,233'},
  {name: 'COVERS TO GO', value: '50'},
  {name: 'COVERS FAILED', value: '1'},
]

export const scanData = {
  test: {
    id: '10400123',
    order_date: new Date(),
    due_date:  new Date(),
    net_prod_days: 123,
    fails: 5,
    type: 'TEXT',
    actions: [
      'Advise team leader of failed item',
      'This went through Final Trim at Guillotine B by Sean Lee',
      'This is a VIP Order',
      'This is 1 of 100 items',
      'Place in Red Priority Fall bin',
    ]
  }
}

export const scanActions = {
  reason_b: [
    'This went through Final Trim at Guillotine B by Sean Lee',
    'This is a VIP Order',
  ]
}

export const dueDateColor = {
  later : "purple",
  today: "blue",
  tomorrow : "green"
}

export const noImage = "https://www.phlpost.gov.ph/assets/img/stamps/no_image.jpg" 