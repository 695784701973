import styled, { css } from "styled-components";

export const DroppableNode = styled.div`
  position: relative;
  border: 1px solid #e0e7ff;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 200px;
  height: 65px;
  background-color: #f7d5c7;
`;

export const TextNode = styled.div`
  font-size: 14px;
  color: #e84a50;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
`;

export const ArrowNode = styled.div`
  display: ${(props) => (props.isDragging ? "none" : "block")};
  width: 200px;
  margin-bottom: 10px;
`;

export const IconContainer = styled.div`
  position: absolute;
  // adjust to your liking
  bottom: -4px;
  right: -6px;
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  cursor: pointer;

  ${(props) =>
    props.showIcon &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  ${(props) =>
    !props.showIcon &&
    css`
      display: none;
    `}
`;
