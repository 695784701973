import DataTypes from "./DataTypes";

const DATATYPE = DataTypes.DATATYPE

const GetOperations = (dataType, filter = 'secondary') => {
  if (filter === 'secondary') {
    switch (dataType) {
      case DATATYPE.bool:
        return ['equals', 'not equal to', 'is empty', 'is not empty'];
      case DATATYPE.time:
      case DATATYPE.number:
        return ['equals', 'not equal to', 'less than', 'greater than', 'less or equal', 'greater or equal', 'is empty', 'is not empty'];
      case DATATYPE.text:
        return ['equals', 'not equal to', 'less than', 'greater than', 'less or equal', 'greater or equal', 'contains', 'does not contain', 'starts with', 'is empty', 'is not empty'];
      case "bucket":
        return ['equals', 'contains', 'not equal to'];
      default:
        return ['equals', 'not equal to', 'is empty', 'is not empty'];
    }
  } else {
    switch (dataType) {
      case DATATYPE.number:
        return ['equals', 'contains', 'is empty', 'is not empty'];
      case "bucket":
        return ['equals', 'contains', 'not equal to'];
      default:
        return ['equals', 'contains', 'is empty', 'is not empty'];
    }
  }
}

export default GetOperations