import React, {useEffect, useState} from 'react';
import {Checkbox, Col, Modal, notification, Row, Typography} from 'antd';
import * as _ from 'lodash';
import {createGlobalStyle} from 'styled-components';
import {Label} from '../../../components/zensmart-design-system';
import {ZenSmartAPI} from '../../../utils';

const {Text} = Typography;

const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}

.ant-select-lg && path {
    color : blue;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}
.ant-select-lg .ant-select-selection__rendered {
    color : blue;
}
`;

export default function PermissionModal({permissions, userId, visible, onClose}) {

  const [userPermissions, setUserPermissions] = useState([]);
  const [user, setUser] = useState(null);
  const [allPermissions, setAllPermissions] = useState(permissions);
  const [searchQuery, setSearchQuery] = useState('');
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (userId !== null) {
      setAllPermissions(permissions);
      fetchUserPermissions();
      fetchUserRolePermissions();
    }
  }, [userId, visible]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchUserPermissions = () => {
    ZenSmartAPI.get(`/api/v1/users/${userId}/permissions`).then(response => {
      response = response.data;
      setUserPermissions(response.data);
      setUser(response.user);
    });
  };

  const fetchUserRolePermissions = () => {
    ZenSmartAPI.get(`/api/v1/users/${userId}/user-roles/permissions`).then(response => {
      response = Object.values(response.data);
      setUserRoles(response);
    });
  };

  const setPermissionLoading = (permission, loading) => {
    setAllPermissions(allPermissions.map(p => {
      if(p.code === permission.code) {
        p.loading = loading;
      }
      return p;
    }))
  }

  const setPermission = (checked, permission) => {

    const action = checked ? 'post' : 'delete';
    setPermissionLoading(permission, true);

    ZenSmartAPI[action](
        `/api/v1/users/${user.id}/permissions/${permission.code}`)
        .then(response => {
          setPermissionLoading(permission, false);
          response = response.data;
          setUserPermissions(response.data)
        })
        .catch(error => {
          setPermissionLoading(permission, false);
          const {response} = error;
          notification.error({
            message: _.get(response, 'data.message', "Something went wrong while attempting to change permission. Please contact your supervisor!")
          });
        });
  };

  const filterPermissions = (permissions) => {
    if (!searchQuery) {
      return permissions;
    }

    return permissions.filter((permission) => permission.name.toLowerCase().includes(searchQuery.toLowerCase()) || permission.group.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  const renderPermissions = () => {
    let groupedPermissions = _.groupBy(filterPermissions(allPermissions), 'group');
    return (
        <>
          <div style={{ position: 'relative', width: '100%', marginBottom: '2px', marginTop: '12px' }}>
            <input
              type="text"
              placeholder="Search for permissions to be assigned..."
              value={searchQuery}
              onChange={handleSearchChange}
              style={{
                width: '100%',
                padding: '12px',
                paddingLeft: '40px',
                boxSizing: 'border-box',
                borderRadius: '4px',
                textAlign: 'center',
              }}
            />
            {!searchQuery && (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6"
                style={{
                  position: 'absolute',
                  left: 'calc(36% - 18px)',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: '1.25em',
                  height: '1.25em',
                }}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
              </svg>
            )}
          </div>
          {Object.keys(groupedPermissions).map(group => (
              <div style={{marginTop: '16px'}} key={group}>
                <h3 style={{
                  fontWeight: 'bold',
                  marginBottom: '7px',
                }}>{group}</h3>
                {_.chunk(groupedPermissions[group], 3)
                    .map(chunkedPermissions => (
                        <Row>
                          {chunkedPermissions.map(permission => (
                              <Col
                                  style={{paddingRight: '5px'}}
                                  key={permission.code}
                                  span={8}
                              >
                                <Checkbox
                                    checked={_.find(userPermissions,
                                        ['code', permission.code])}
                                    onChange={e => setPermission(
                                        e.target.checked, permission)}
                                    disabled={permission.loading || userRoles.some(rolePermission => rolePermission.code === permission.code)}
                                >
                                  {permission.name}
                                  <div><Text
                                      type={`secondary`}>{permission.description}</Text>
                                  </div>
                                </Checkbox>
                              </Col>
                          ))}
                        </Row>
                    ))}
              </div>
          ))}
        </>
    );
  };

  // only load modal when user is fetched
  if (user === null) {
    return null;
  }

  return (
      <>
        <ModalStyle/>
        <Modal
            visible={visible}
            destroyOnClose={true}
            width={900}
            onCancel={() => {
              setUser(null);
              setUserPermissions([]);
              onClose();
            }}
            footer={null}
        >
          <Row type="flex" justify="center" style={{padding: '10 20'}}>
            <Label status="blue">MANAGE USER PERMISSIONS
              - {user.name.toUpperCase()}</Label>
          </Row>
          {renderPermissions()}

        </Modal>
      </>
  );
}
