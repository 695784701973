import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input, Modal, Form, Spin } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import _ from "lodash"
import moment from 'moment'
import {
    ArrowRightOutlined
} from '@ant-design/icons';
import { ZenSmartAPI } from 'utils'
import {
    getSinglePartDataRoute,
    getAllSinglePartInventoriesDataRoute,
    getAllBomLocationsRoute,
    getSinglePartInventoryDataRoute,
    bomPartsRoute,
    getHistoryPartsDataRoute
} from "utils/apiRoutes"

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
  padding: 10px;

`
function EditForm(props) {
    const {
        form,
        singlePartDetails,
        updateParts,
        setUpdateParts,
        fetchInitData,
        getSinglePart,
        getPartHistory
    } = props

    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields
    } = form

    const [errors, setErrors] = useState({})

    const handleSubmit = e => {
        e.preventDefault()
        validateFields((err, values) => {
            if (!err) {
                const payload = getFieldsValue()
                if (singlePartDetails.id) {
                    ZenSmartAPI.put(getSinglePartDataRoute(singlePartDetails.id), payload)
                        .then((res) => {
                            fetchInitData()
                            getSinglePart()
                            setErrors({})
                            getPartHistory()
                            notification.success({ message: "Bom Part successfully updated!" })
                        }).catch((res) => {
                            if (_.get(res, 'response.status') === 422) {
                                setErrors(_.get(res, 'response.data.errors'))
                                notification.error({
                                    message: _.get(res, 'response.data.message'),
                                });
                            }
                        })
                }
                else {
                    ZenSmartAPI.post(bomPartsRoute, payload)
                        .then((res) => {
                            setUpdateParts(res.data.data.id)
                            fetchInitData()
                            getSinglePart()
                            getPartHistory()
                            setErrors({})
                            notification.success({ message: "Bom Part successfully added!" })
                        }).catch((res) => {
                            if (_.get(res, 'response.status') === 422) {
                                setErrors(_.get(res, 'response.data.errors'))
                                notification.error({
                                    message: _.get(res, 'response.data.message'),
                                });
                            }
                        })
                }
            }
        })

    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Col span={24}>
                    <Form.Item label="Code"
                        validateStatus={errors['code'] ? 'error' : undefined}
                        help={errors['code'] ? errors.code[0] : undefined}
                    >
                        {getFieldDecorator(`code`, {
                            initialValue: singlePartDetails.code ? singlePartDetails.code : null,
                        })(
                            <Input />,
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Description"
                        validateStatus={errors['description'] ? 'error' : undefined}
                        help={errors['description'] ? errors.description[0] : undefined}
                    >
                        {getFieldDecorator(`description`, {
                            initialValue: singlePartDetails.description ? singlePartDetails.description : null,
                        })(
                            <Input />,
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Category"
                        validateStatus={errors['category'] ? 'error' : undefined}
                        help={errors['category'] ? errors.category[0] : undefined}
                    >
                        {getFieldDecorator(`category`, {
                            initialValue: singlePartDetails.category ? singlePartDetails.category : null,
                        })(
                            <Input />,
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="UOM Type"
                        validateStatus={errors['uom_type'] ? 'error' : undefined}
                        help={errors['uom_type'] ? errors.uom_type[0] : undefined}
                    >
                        {getFieldDecorator(`uom_type`, {
                            initialValue: singlePartDetails.uom_type ? singlePartDetails.uom_type : null,
                        })(
                            <Input />,
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="UOM Value"
                        validateStatus={errors['uom_value'] ? 'error' : undefined}
                        help={errors['uom_value'] ? errors.uom_value[0] : undefined}
                    >
                        {getFieldDecorator(`uom_value`, {
                            initialValue: singlePartDetails.uom_value ? singlePartDetails.uom_value : null,
                        })(
                            <Input />,
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Metric"
                        validateStatus={errors['metric'] ? 'error' : undefined}
                        help={errors['metric'] ? errors.metric[0] : undefined}
                    >
                        {getFieldDecorator(`metric`, {
                            initialValue: singlePartDetails.metric ? singlePartDetails.metric : null,
                        })(
                            <Input />,
                        )}
                    </Form.Item>
                </Col>
                <p style={{ textAlign: "right" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Save Changes
                    </Button>

                </p>
            </Form>
        </>
    )
}

function EditLocationForm(props) {
    const {
        form,
        data,
        locationsDetails,
        singlePartDetails,
        index,
        inventoriesId,
        getInventories,
        setloading,
        getPartHistory
    } = props

    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields,
        resetFields
    } = form

    const [errors, setErrors] = useState({})
    const [filteredLocations, setFilteredLocations] = useState([])

    const deleteInventoryLocation = () => {
        ZenSmartAPI.delete(getSinglePartInventoryDataRoute(singlePartDetails.id, data.id))
            .then((res) => {
                getInventories()
                notification.success({ message: "Inventory successfully deleted!" })
            }).catch((res) => {
                if (_.get(res, 'response.status') === 422) {
                    setErrors(_.get(res, 'response.data.errors'))
                    notification.error({
                        message: _.get(res, 'response.data.message'),
                    });
                }
            })
    }

    const handleSubmit = e => {
        e.preventDefault()
        validateFields((err, values) => {
            if (!err) {
                const payload = getFieldsValue()
                // for updating inventory
                payload.part_id = singlePartDetails.id
                setloading(true)
                if (data.id) {
                    ZenSmartAPI.put(getSinglePartInventoryDataRoute(singlePartDetails.id, data.id), payload)
                        .then((res) => {
                            notification.success({ message: "Inventory successfully updated!" })
                            setErrors({})
                            setloading(false)
                            getPartHistory()
                        }).catch((res) => {
                            setloading(false)

                            if (_.get(res, 'response.status') === 422) {
                                setErrors(_.get(res, 'response.data.errors'))
                                notification.error({
                                    message: _.get(res, 'response.data.message'),
                                });
                            }
                        })
                }
                // for adding new inventory
                else {
                    ZenSmartAPI.post(getAllSinglePartInventoriesDataRoute(singlePartDetails.id), payload)
                        .then((res) => {
                            setloading(false)
                            getInventories()
                            resetFields()
                            setErrors({})
                            getPartHistory()
                            notification.success({ message: "Inventory successfully added!" })
                        }).catch((res) => {
                            setloading(false)
                            if (_.get(res, 'response.status') === 422) {
                                setErrors(_.get(res, 'response.data.errors'))
                                notification.error({
                                    message: _.get(res, 'response.data.message'),
                                });
                            }
                        })
                }
            }
        })

    };
    const odd = index % 2
    const location = data.location_id ? locationsDetails.find(item => item.id === data.location_id) : null

    useEffect(() => {
        setFilteredLocations(locationsDetails.filter(item => !inventoriesId.includes(item.id)))
    }, [inventoriesId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row type="flex" align="middle" style={{ paddingLeft: 10, background: odd ? "#f1f3ff" : undefined }} >
                    <Col span={6} style={{ padding: 5, paddingTop: 30 }}>
                        {location ?
                            <Form.Item
                                validateStatus={errors['location_id'] ? 'error' : undefined}
                                help={errors['location_id'] ? errors.location_id[0] : undefined}>
                                {getFieldDecorator(`location_id`, {
                                    initialValue: data.location_id,
                                })(
                                    <p style={{ fontWeight: "bold", lineHeight: 1.5 }}>{location.warehouse}/{location.bin}</p>)}
                            </Form.Item>
                            :
                            <Form.Item
                                validateStatus={errors['location_id'] ? 'error' : undefined}
                                help={errors['location_id'] ? errors.location_id[0] : undefined}>
                                {getFieldDecorator(`location_id`, {
                                    initialValue: data.location_id,
                                })(
                                    <Select placeholder={"Please select location"} style={{ width: "100%" }} >
                                        {filteredLocations.map(item => {
                                            return <Select.Option style={{ textTransform: "capitalize" }}
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.warehouse} / {item.bin}
                                            </Select.Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        }

                    </Col>
                    <Col span={4} style={{ padding: 5, paddingTop: 30 }}>
                        <Form.Item
                            validateStatus={errors['stock'] ? 'error' : undefined}
                            help={errors['stock'] ? errors.stock[0] : undefined}>
                            {getFieldDecorator(`stock`, {
                                initialValue: data.stock,
                            })(
                                <Input type="number" />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ padding: 5, paddingTop: 30 }}>
                        <Form.Item
                            validateStatus={errors['min_level'] ? 'error' : undefined}
                            help={errors['min_level'] ? errors.min_level[0] : undefined}
                        >

                            {getFieldDecorator(`min_level`, {
                                initialValue: data.min_level,
                            })(
                                <Input type="number" />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ padding: 5, paddingTop: 30 }}>
                        <Form.Item
                            validateStatus={errors['max_level'] ? 'error' : undefined}
                            help={errors['max_level'] ? errors.max_level[0] : undefined}
                        >
                            {getFieldDecorator(`max_level`, {
                                initialValue: data.max_level,
                            })(
                                <Input type="number" />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ padding: 5 }} >
                        {data.id ?
                            <Row type="flex">
                                <Col style={{ padding: 5 }}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Update
                                    </Button>
                                </Col>
                                <Col style={{ padding: 5 }}>
                                    <Button
                                        type="danger"
                                        onClick={deleteInventoryLocation}
                                    >
                                        Delete
                                    </Button>
                                </Col>
                            </Row>
                            :
                            <Col style={{ padding: 5 }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Add
                                </Button>
                            </Col>
                        }
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const EditUpdatePartsForm = Form.create({
})(EditForm)

const EditUpdatePartsLocationForm = Form.create({
})(EditLocationForm)

const UpdatePartsModal = (props) => {
    const { showModal, setShowModal, updateParts, setUpdateParts, fetchInitData } = props
    const [singlePartDetails, setSinglePartDetails] = useState({})
    const [singelPartInventoriesDetails, setSinglePartInventoriesDetails] = useState([])
    const [locationsDetails, setLocationsDetails] = useState([])
    const [loading, setloading] = useState(false)
    const [partHistory, setPartHistory] = useState([])

    const fetchSinglePartsData = () => {
        setloading(true)
        getSinglePart()
        getInventories()
        getAllLocations()
        getPartHistory()
    }

    const getSinglePart = () => {
        setloading(true)
        ZenSmartAPI.get(getSinglePartDataRoute(updateParts))
            .then((res) => {
                setloading(false)
                setSinglePartDetails(res.data.data)
            }).catch((res) => {

            })
    }

    const getInventories = () => {
        setloading(true)
        ZenSmartAPI.get(getAllSinglePartInventoriesDataRoute(updateParts))
            .then((res) => {
                setloading(false)
                setSinglePartInventoriesDetails(res.data.data)
            }).catch((res) => {
                setloading(false)
            })
    }

    const getAllLocations = () => {
        setloading(true)
        ZenSmartAPI.get(getAllBomLocationsRoute)
            .then((res) => {
                setloading(false)
                setLocationsDetails(res.data.data)
            }).catch((res) => {
                setloading(false)
            })
    }
    const getPartHistory = () => {
        setloading(true)
        ZenSmartAPI.get(getHistoryPartsDataRoute(updateParts))
            .then((res) => {
                setloading(false)
                setPartHistory(res.data.data)
            }).catch((res) => {
                setloading(false)
            })
    }

    const refreshState = () => {
        setSinglePartDetails({})
        setSinglePartInventoriesDetails([])
        setLocationsDetails([])
        setShowModal(false)
        setPartHistory([])
    }

    const renderHistory = (data, index) => {
        const odd = index % 2
        return (
            <Row type="flex" align="middle" style={{ paddingLeft: 10, background: odd ? "#f1f3ff" : undefined }} >
                <Col span={6} style={{ padding: 5 }}>
                    <h1>{moment(data.created_at).format("MM/DD/YYYY hh:mm a")}</h1>
                </Col>
                <Col span={5} style={{ padding: 5 }}>
                    <h1>{data.method}</h1>
                </Col>
                <Col span={5} style={{ padding: 5 }}>
                    <h1>{data.user}</h1>
                </Col>
                <Col span={8} style={{ padding: 5 }}>
                    {Object.keys(data.data_after).map(item => {
                        return <h1>"{!data.data_before || (data.data_before && !data.data_after[item]) ? null : data.data_before[item]}" <span style={{ fontSize: 25 }}>&#8594;</span> "{data.data_after[item]}"</h1>
                    })}
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        if (showModal && updateParts) {
            fetchSinglePartsData()
        }
    }, [showModal, updateParts]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal title={singlePartDetails.code ? `Edit (${singlePartDetails.code})` : "Add New Part"} visible={showModal} onCancel={setShowModal} width={1300}
            footer={[
                <Button key="back" onClick={() => refreshState()}>
                    Close
                </Button>
            ]}
        >
            <Spin tip="Loading..." spinning={loading}>
                {showModal &&

                    <Row type="flex" justify="center">
                        <Col span={6} style={{ borderRight: "1px solid grey", padding: 10 }}>
                            <EditUpdatePartsForm
                                singlePartDetails={singlePartDetails}
                                updateParts={updateParts}
                                setUpdateParts={setUpdateParts}
                                fetchInitData={fetchInitData}
                                getSinglePart={getSinglePart}
                                setloading={setloading}
                                getPartHistory={getPartHistory}
                            />
                        </Col>
                        <Col span={18}>
                            <Col span={24} style={{ padding: 10 }}>
                                <Heading>Inventory</Heading>
                                <hr />
                                <div style={{
                                    height: 300,
                                    overflowY: "auto",
                                    position: "relative"
                                }}>
                                    <Row type="flex" align="middle" style={{}}>
                                        <Col span={6} style={{ padding: 5 }}>
                                            <Heading>Location</Heading>
                                        </Col>
                                        <Col span={4} style={{ padding: 5 }}>
                                            <Heading>Stock</Heading>
                                        </Col>
                                        <Col span={4} style={{ padding: 5 }}>
                                            <Heading>Min</Heading>
                                        </Col>
                                        <Col span={4} style={{ padding: 5 }}>
                                            <Heading>Max</Heading>
                                        </Col>
                                        <Col span={6} style={{ padding: 5 }}>
                                            <Heading>Action</Heading>
                                        </Col>
                                    </Row>
                                    {locationsDetails.length > 0 && singelPartInventoriesDetails.map((item, index) => {
                                        return (
                                            <EditUpdatePartsLocationForm
                                                data={item}
                                                locationsDetails={locationsDetails}
                                                index={index}
                                                singlePartDetails={singlePartDetails}
                                                inventoriesId={singelPartInventoriesDetails.map(location => location.location_id)}
                                                getInventories={getInventories}
                                                setloading={setloading}
                                                getPartHistory={getPartHistory}
                                            />
                                        )
                                    })}
                                    {updateParts && showModal &&
                                        <EditUpdatePartsLocationForm
                                            data={{}}
                                            locationsDetails={locationsDetails}
                                            singlePartDetails={singlePartDetails}
                                            inventoriesId={singelPartInventoriesDetails.map(location => location.location_id)}
                                            getInventories={getInventories}
                                            index={singelPartInventoriesDetails.length}
                                            setloading={setloading}
                                            getPartHistory={getPartHistory}
                                        />}

                                </div>
                            </Col>
                            <Col span={24} style={{ padding: 10 }} >

                                <Heading>History</Heading>
                                <hr />

                                <div style={{
                                    height: 250,
                                    overflowY: "auto",
                                    position: "relative"
                                }}>
                                    <Row type="flex" align="middle" >
                                        <Col span={6} style={{ padding: 5 }}>
                                            <Heading>Date/Time</Heading>
                                        </Col>
                                        <Col span={5} style={{ padding: 5 }}>
                                            <Heading>Method</Heading>
                                        </Col>
                                        <Col span={5} style={{ padding: 5 }}>
                                            <Heading>Operator</Heading>
                                        </Col>
                                        <Col span={8} style={{ padding: 5 }}>
                                            <Heading>Change</Heading>
                                        </Col>
                                    </Row>
                                    {partHistory.map((item, index) => {
                                        return renderHistory(item, index)
                                    })}
                                </div>
                            </Col>
                        </Col>
                    </Row>
                }
            </Spin>

        </Modal>
    )
}

export default UpdatePartsModal