import * as actionTypes from "./actionTypes";

export const saveRecipes = recipes => {
  return {
    type: actionTypes.SAVE_RECIPES,
    recipes: recipes
  };
};

export const saveOutput = output => {
  return {
    type: actionTypes.SAVE_OUTPUT,
    output: output
  };
};

export const savePDFURL = pdfURL => {
  return {
    type: actionTypes.SAVE_PDF_URL,
    pdfURL: pdfURL
  };
};

export const saveChangeTracker = changeTracker => {
  return {
    type: actionTypes.SAVE_CHANGE_TRACKER,
    changeTracker: changeTracker
  };
};

export const saveImpoDesignerDisabled = impositionDisabled => {
  return {
    type: actionTypes.SAVE_IMPOSITION_DISABLED,
    impoDesignerDisabled: impositionDisabled
  };
};

export const updateProducts = products => {
  return {
    type: actionTypes.UPDATE_PRODUCTS,
    products: products
  };
};

export const saveHeader = header => {
  let action = { type: actionTypes.SAVE_HEADER }

  if (header) {
    action = {
      ...action,
      type: actionTypes.SAVE_HEADER,
      product: header.product,
      productCode: header.productCode,
      article: header.article,
      block: header.block,
      blockType: header.blockType,
      printer: header.printer,
      printerClass: header.printerClass,
      finishingType: header.finishingType,
      finishingTypeText: header.finishingTypeText,
      productBlockPrinter: header.productBlockPrinter,
      plex: header.plex,
    };
  }

  return action;
};
