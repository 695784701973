
export const tasks = [
    { name: 'GP2 Roller Assembly Inspection', done: true },
    { name: 'OHS Team Meeting 11:00AM', done: false },
    { name: 'GP2 End Of Day Clean Down', done: true },
    { name: 'Team Lunch 12:30PM', done: false },
  ]
  
  export const teamTargets = [
    { label: 'WIP DUE TODAY', value: '1,300', status: 'blue' },
    { label: 'WIP LEFT', value: '23,124', status: 'blue' },
    { label: 'OVERDUE', value: '541,544', status: 'red' },
    { label: 'VIP', value: '14', status: 'buttercup' },
  ]
  
  export const workList = [
    { name: 'COVERS TO MAKE', value: '615' },
    { name: 'COVERS MADE', value: '1,233' },
    { name: 'COVERS TO GO', value: '50' },
    { name: 'COVERS FAILED', value: '1' },
  ]
  
  export const scanActions = {
    reason_b: [
      'This went through Final Trim at Guillotine B by Sean Lee',
      'This is a VIP Order',
    ]
  }
  
  export const dueDateColor = {
    later: "purple",
    today: "blue",
    tomorrow: "green"
  }
  
  export const noImage = "https://www.phlpost.gov.ph/assets/img/stamps/no_image.jpg"
  
  
  