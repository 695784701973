import React, { useRef, useEffect, useState } from 'react'
import { Row, Col, Form, Input, notification, Modal, Icon as AIcon, Button } from 'antd'
import styled, { css } from 'styled-components'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js'
import { Panel } from '../../../components/primaries'
import { ZenSmartAPI } from 'utils';
import Cookies from 'js-cookie';
import { normalBarcodeScanRoute } from "utils/apiRoutes"
import { ReactComponent as Index } from 'static/icons/inbox.svg'
import { ReactComponent as Retrieve } from 'static/icons/retrieve.svg'
import { ReactComponent as Hold } from 'static/icons/hold.svg'
import { defaultGamificationQuizValue, fetchGamificationQuiz, resetState } from 'components/sections/Gamification/helper'
import GameView, { Spinner } from 'components/sections/Gamification/GameView'
import Image from "components/zensmart-design-system/components/Image/Image"
import * as _ from 'lodash'


/**
 * @note Clean the API for this component. Feels like it has to know
 * a lot of variables/prop values in order to be useful.
 */

const panelStylings = css`
  margin-bottom: 24px;
  padding-bottom: 50px;
`
const StyledRow = styled(Row)`
  margin-bottom: 24px;
`
const InvalidScanImage = styled.p`
  text-align: center;
  margin: 20px auto 0 auto;
`
const BoxNumber = styled.h1`
  text-align: center;
  font-size: 50px;
`
const borderStylings = { borderRight: "1px solid #0B6BF2", padding: "50px" }

const FlexImageWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;

  div {
    padding: 0 5px;
    text-align: center;
    margin-bottom: 5px;
  }
`;
const LookupForm = Form.create({ name: 'LookupForm' })(props => {
  const { onSubmit, inputProps, form, scanTypeId, setInvalidScan, setScanItem, setHtmlToPrint } = props
  const { getFieldDecorator, getFieldValue, resetFields } = form
  const [loading, setLoading] = useState(false)
  const [scanGamification, setScanGamification] = useState(defaultGamificationQuizValue)
  const scanInput = useRef(null)
  const refocusQueueID = useRef(null)
  const clearRefocusQueue = () => {
    clearTimeout(refocusQueueID.current)
  }

  const refocusQueue = () => {
    refocusQueueID.current = setTimeout(() => {
      if (scanInput.current) {
        scanInput.current.focus()
      }
    }, 5000)
  }

  const showInternalMessage = (message) => {
    if (Array.isArray(message)) {
      if (message.length !== 0) {
        const messageText = message.join('<br />')
        Modal.info({
          title: 'Hold At Dispatch',
          icon: <AIcon type='exclamation' />,
          content: <div dangerouslySetInnerHTML={{ __html: messageText }}></div>,
        })
      }
    }
  }

  const handleSubmit = async (evt) => {
    if (evt) evt.preventDefault();
    const input_value = getFieldValue('lookup_text');
    let payload = {};

    if (input_value === undefined) {
      return onSubmit({ error: 'Please Scan A Barcode' })
    }

    if (input_value.includes('STATION') === true) {
      payload = { barcode: input_value, scan_type_id: scanTypeId }
    }

    else if ((input_value.includes('STATION') === false && Cookies.get('STATION') === undefined)
        || parseInt(Cookies.get('SCAN_TYPE')) !== parseInt(scanTypeId)) {
      resetFields()
      scanInput.current.focus()
      return onSubmit({ error: 'Please Scan Station Card First' })
    }

    else {
      payload = { station_id: Cookies.get('STATION'), barcode: input_value, scan_type_id: scanTypeId };
    }
    if (scanGamification.isVerified === false && input_value.includes('STATION') === false && Cookies.get('ALLOW_QUIZ') === 'true') {
      const quizCheck = await fetchGamificationQuiz(payload, setLoading, setScanGamification, scanInput);
      if (quizCheck === true) return;
    }
    setLoading(true)
    setHtmlToPrint(null)
    await ZenSmartAPI.post(normalBarcodeScanRoute, payload)
    .then((res) => {
      resetFields()
      setLoading(false);
      setInvalidScan.setInvalidScanItem(null)
      scanInput.current.focus()
      if (res.data && res.data.internal_comments) {
        showInternalMessage(res.data.internal_comments)
      }
      onSubmit({ ...res.data, success_scan: true })
      resetState(setScanGamification)
    })
    .catch((res) => {
      resetFields()
      resetState(setScanGamification)
      setLoading(false);
      setScanItem()
      scanInput.current.focus()

      if (res.response.data.hasOwnProperty('message') && res.response.status === 400) {
        setInvalidScan.setInvalidScanItem(res.response.data.message);
      }
      else {
        notification.error({
          message: "Not a valid scan item"
        })
      }
    })
  }

  useEffect(() => {
    if (scanInput.current) {
      scanInput.current.focus()
    }
    return clearRefocusQueue
  }, [])

  useEffect(() => {
    if (scanGamification.isVerified === true) handleSubmit()
  }, [scanGamification.isVerified])

  return (
      <>
        <Form onSubmit={handleSubmit}>
          {getFieldDecorator('lookup_text')(
              <Input
                  type="text"
                  placeholder="Scan Item"
                  ref={scanInput}
                  onFocus={clearRefocusQueue}
                  onBlur={refocusQueue}
                  {...inputProps}
                  disabled={loading}
                  loading={loading}
              />
          )}
        </Form>
        {scanGamification.loading && <Spinner size="large" tip={'Loading...'} />}
        {(scanGamification.gameData.length > 0 && scanGamification.loading === false) && (
            <GameView
                scanGamification={scanGamification}
                setScanGamification={setScanGamification}
                resetFields={resetFields}
                onSubmit={onSubmit}
            />
        )}
      </>
  )
})

function PreDispatchLookup(props) {
  const {
    title,
    detailLabel,
    isViewingDetails,
    onLookup,
    inputProps,
    children,
    scanTypeId,
    setScanItem,
    setHtmlToPrint
  } = props

  const [invalidScanItem, setInvalidScanItem] = useState(null)
  const setOrderDetail = (data) => {

    const preDispatchData = data ? data.page_definitions.sections.find(val => val.section_header === data.scan_type) : null;
    const qty = preDispatchData?.section_data.filter( (data) => {
      return data.label === 'QTY'
    })

    const message = preDispatchData?.section_data.filter( (data) => {
      return data.label === 'Message'
    })
    return (
        <>
          {preDispatchData &&
          <Col span={12} style={{ padding: "50px" }}>
            <Row>
              <Col span={24}>
                <p style={{ textAlign: "center" }}>
                <Label status="blue">
                    { (message.length === 1 && message[0].value !== null) ?  message[0].value : (preDispatchData.section_data[0].value === "PUT" ?
                      `PLACE ITEM IN BOX ${preDispatchData.section_data[1].value}`
                      : preDispatchData.section_data[0].value === "GET" ?
                        `GET ITEMS FROM BOX ${preDispatchData.section_data[1].value}`
                        : null)
                        }
                  </Label>
                </p>
                <br /><br />
                {preDispatchData.section_data[0].value === "PUT" ?
                 <Index style={{ height: 100, width: "100%" }} /> : preDispatchData.section_data[0].value === "GET"
                                                                    ? <Retrieve style={{ height: 100, width: "100%" }} /> : null}
                <BoxNumber >{preDispatchData.section_data[1].value}</BoxNumber>
                <br />
                <FlexImageWrapper>
                  {_.get(preDispatchData, 'section_data.5.value.front') && (
                    <Image
                      src={preDispatchData.section_data[5].value.front}
                      width={180}
                      height={300}
                      alt="Line image"
                      objectFit="fill"
                    />
                  )}
                  {_.get(preDispatchData, 'section_data.5.value.back') && (
                    <Image
                      src={preDispatchData.section_data[5].value.back}
                      width={180}
                      height={300}
                      alt="Line image"
                      objectFit="fill"
                    />
                  )}
                </FlexImageWrapper>
                <br />
                {qty.length !== 0  ? <p style={{ textAlign: "center" }}>{qty[0].label} : <BoxNumber style={{ display : "contents" }}>{qty[0].value}</BoxNumber></p> : null}
              </Col>
            </Row>
          </Col>
          }
        </>
    )
  }

  const setImagesForSingle = (data) => {
    
  const singlePreDispatchData = data ? (Array.isArray(data.page_definitions.sections) ? data.page_definitions.sections[0] : null)  : null;
    return (
      <>
        
        <Col span={12} style={{ padding: "50px" }}>
          <Row>
            <Col span={24}>
                <p style={{ textAlign: "center", marginLeft: 20 }}>
                  <Label status="mountain_meadow">{isViewingDetails.message.toUpperCase()}</Label>
                </p>
                <br />
                <FlexImageWrapper>
                  <Image
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn4buLXuLzta9pURLLUaAz4SpSNpMcAu0C4Q&usqp=CAU"
                      alt=""
                      width={180}
                      height={300}
                      objectFit="fill"
                  />
                  <br />
                </FlexImageWrapper>
                <br />
                {singlePreDispatchData &&
                  <FlexImageWrapper>
                      {_.get(singlePreDispatchData, 'thumbnails.front') && (
                        <Image
                          src={singlePreDispatchData.thumbnails.front}
                          width={180}
                          height={300}
                          alt="Line image"
                          objectFit="fill"
                        />
                      )}
                      {_.get(singlePreDispatchData, 'thumbnails.back') && (
                        <Image
                          src={singlePreDispatchData.thumbnails.back}
                          width={180}
                          height={300}
                          alt="Line image"
                          objectFit="fill"
                        />
                      )}
                  </FlexImageWrapper>
                }
            </Col>
          </Row>
        </Col>
      </>
    )
  }

  return (
      <Panel title={title} stylings={panelStylings}>
        <Row style={{ padding: "20px 70px 0px 70px" }}>
          <Col span={12} style={borderStylings}>
            <StyledRow>
              <Label status="blue">{detailLabel}</Label>
            </StyledRow>
            <StyledRow>
              <Col span={24}>
                <LookupForm onSubmit={onLookup} setHtmlToPrint={setHtmlToPrint} setScanItem={setScanItem} setInvalidScan={{ invalidScanItem, setInvalidScanItem }}
                            inputProps={inputProps} scanTypeId={scanTypeId} isViewingDetails={isViewingDetails} />
              </Col>
            </StyledRow>

            {children}
          </Col>
          {invalidScanItem ?
           <Col span={12} style={{ padding: "50px" }}>
             <p style={{ textAlign: "center" }}>
               <Label status="red">ATTENTION: <br />{invalidScanItem}</Label>
             </p>
             <InvalidScanImage>
               <Hold style={{ height: 100, width: "100%" }} />
             </InvalidScanImage>
           </Col>
                : isViewingDetails && isViewingDetails.popup_message ? setImagesForSingle(isViewingDetails) :
                isViewingDetails ? setOrderDetail(isViewingDetails) : null}
        </Row>
      </Panel >
  )
}

export default PreDispatchLookup
