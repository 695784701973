import React from "react";
import { notification, Select } from "antd";
import is from "is_js";
import { ZenSmartAPI } from "utils";
import { addPrinterClassRoute } from "utils/apiRoutes";
import {logError} from "utils/logging";
import { alphabeticalData } from 'utils/sortData';

const PrinterSelect = (props) => {
  const {
    headerField,
    invokeSelect,
    queryProductBlockPrinter,
    clearHeaderFields,
    updateHeaderFields,
    setCheckBox,
    setQaFailCheckBox
  } = props;

  const printerChange = (value) => {
    invokeSelect(printerSelect, value);
  };

  const printerSelect = (value, headerFieldsState) => {
    clearHeaderFields();
    
    const searchPrinters = is.existy(headerFieldsState)
      ? headerFieldsState.printers
      : headerField.printers;

    const filteredPrinter = searchPrinters.find(
      (printer) => printer.id === value
    );

    props.setSpecifiedPrinters(filteredPrinter.specific_printers);

    let headerState = is.existy(headerFieldsState) ? {
      ...headerFieldsState,
      defaultPrinter: false,
      productBlockPrinter: null,
    } : {
      ...headerField,
      printer: value,
      printerClass: filteredPrinter.class,
      defaultPrinter: false,
      productBlockPrinter: null,
    };

    updateHeaderFields(headerState);
    setCheckBox(false);
    setQaFailCheckBox(false);


    queryProductBlockPrinter(
      headerField.product,
      headerField.block,
      value,
      headerField.finishingType,
      headerState
    );
  };

  const addPrinterClass = (evt) => {
    if (evt.keyCode === 13) {
      const printerValue = evt.target.value;
      const printerData = headerField.printers.find((data) => {
        return data.class === printerValue;
      });

      if (is.existy(printerData)) {
        updateHeaderFields({
          ...headerField,
          printer: printerData.id,
          printerClass: printerData.class,
        });
        return;
      }

      if (is.not.existy(printerData)) {
        props.setLoading(true);
        ZenSmartAPI.post(addPrinterClassRoute, { class: printerValue })
          .then((response) => {
            const responseId = response.data.data.id;
            const responseClass = response.data.data.class;
            const updatedHeader = {
              ...headerField,
              printer: responseId,
              printerClass: responseClass,
              printers: [
                ...headerField.printers,
                { id: responseId, class: responseClass, specific_printers: [] },
              ],
            };
            updateHeaderFields(updatedHeader);
            invokeSelect(printerSelect, responseId, updatedHeader);
            notification.info({
              message: "Printer created",
            });
          })
          .catch((err) => {
            logError(err, true);
          })
          .finally(() => {
            props.setLoading(false);
          });
      }
    }
  };

  return (
    <>
      <span className="Header-field">
        <span className="Header-boldText">Printer: </span>
        <Select
          showSearch
          className="Header-select"
          value={
            is.not.empty(headerField.printers) ? headerField.printer : null
          }
          onInputKeyDown={(event) => addPrinterClass(event)}
          optionFilterProp="children"
          filterOption={true}
          onChange={(event) => printerChange(event)}
        >
          {alphabeticalData(headerField.printers, "class").map((printer) => (
            <Select.Option key={printer.id} value={printer.id}>
              {printer.class}
            </Select.Option>
          ))}
        </Select>
      </span>
    </>
  );
};

export default PrinterSelect;
