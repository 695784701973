import React, { useEffect, useState } from 'react';
import { Header, Title, Panel } from 'components/primaries';
import * as S from './ProductionQueueStyled';
import DatePicker from './DatePicker/DatePicker';
import moment from 'moment';
import { formatDate } from 'utils/dateFormatter';
import { ZenSmartAPI } from 'utils';
import { handleActiveRows, buildData, getUrl } from '../components/Table/TableUtilities';
import LinearFeetReportTable from './LinearFeetReport/LinearFeetReportTable';
import PrinterTable from './PrinterTable/PrinterTable';

const ProductionQueue = () => {
    const dateFormat = 'YYYY-MM-DD';
    const startDateValue = formatDate(dateFormat, moment());
    const endDateValue = formatDate(dateFormat, moment());
    const [startDate, setStartDate] = useState(formatDate(dateFormat, startDateValue));
    const [endDate, setEndDate] = useState(formatDate(dateFormat, endDateValue));

    const getLinearFeetApiRoute = '/api/v1/linear-feet';

    const getProductionQueuesApiRoute = getLinearFeetApiRoute + '/production-queues';
    const [departmentData, setDepartmentData] = useState([]);
    const [activeDepartmentRows, setActiveDepartmentRows] = useState([]);
    const [productionQueuesTableLoading, setProductionQueuesTableLoading] = useState(true);
    const uniqueDepartmentKeys = [
        'department_id',
        'tertiary_product_group_id'
    ];

    const getPrinterApiRoute = getLinearFeetApiRoute + '/printers';
    const [printerData, setPrinterData] = useState([]);
    const [activePrinterRows, setActivePrinterRows] = useState([]);
    const [printerTableLoading, setPrinterTableLoading] = useState(true);
    const uniquePrinterKeys = [
        'department_id',
        'short_name'
    ];

    const getProductionQueuesData = (parameters) => ZenSmartAPI.get(getUrl(getProductionQueuesApiRoute, parameters))
                                                               .then(({config, data}) => setDepartmentData(buildData('department_id', config.url, uniqueDepartmentKeys, departmentData, data)))
                                                               .catch(() => setDepartmentData([]))
                                                               .finally(() => setProductionQueuesTableLoading(false));

    const getPrinterData = (parameters) => ZenSmartAPI.get(getUrl(getPrinterApiRoute, parameters))
                                                      .then(({config, data}) => setPrinterData(buildData('department_id', config.url, uniquePrinterKeys, printerData, data)))
                                                      .catch(() => setPrinterData([]))
                                                      .finally(() => setPrinterTableLoading(false));

    const handleDepartment = (id) => {
        if (id !== null) {
            const toQuery = !activeDepartmentRows.includes(id);
            setActiveDepartmentRows(handleActiveRows(id, activeDepartmentRows));
            setTimeout(() => {
                setProductionQueuesTableLoading(true);
                (toQuery) ? getProductionQueuesData({
                    start_date    : startDate,
                    end_date      : endDate,
                    department_id : id
                }) : setProductionQueuesTableLoading(false);
            }, 100);
        }
    }

    const handlePrinters = (id) => {
        if (id !== null) {
            const toQuery = !activePrinterRows.includes(id);
            setActivePrinterRows(handleActiveRows(id, activePrinterRows));
            setTimeout(() => {
                setPrinterTableLoading(true);
                (toQuery) ? getPrinterData({
                    department_id : id
                }) : setPrinterTableLoading(false);   
            }, 100);
        }
    }

    //Initial loading
    useEffect(() => {
        setDepartmentData([]);
        setPrinterData([]);
    }, []);

    //When dates update
    useEffect(() => {
        setDepartmentData([]);
        setProductionQueuesTableLoading(true);
        getProductionQueuesData({
            start_date : startDate,
            end_date   : endDate
        });

        setPrinterData([]);
        setPrinterTableLoading(true);
        getPrinterData({});
    }, [startDate, endDate]);

    return (
        <>
            <Header>
                <Title>Performance {'>'} Production Queues</Title>
            </Header>
            <S.Layout>
                <Panel>
                    <DatePicker 
                        startDate={(date) => setStartDate(date)}
                        defaultStartDate={startDate}
                        endDate={(date) => setEndDate(date)}
                        defaultEndDate={endDate}
                        format={dateFormat}
                    />
                    <LinearFeetReportTable
                        loading={productionQueuesTableLoading}
                        data={departmentData}
                        activeRows={activeDepartmentRows}
                        handleRow={(id) => handleDepartment(id)}
                    />
                </Panel>
                <S.PrinterPanel>
                    <PrinterTable
                        loading={printerTableLoading}
                        data={printerData}
                        activeRows={activePrinterRows}
                        handleRow={(id) => handlePrinters(id)}
                    />
                </S.PrinterPanel>
            </S.Layout>
        </>
    );
}

export default ProductionQueue;
