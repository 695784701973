import React from 'react'
import styled from 'styled-components'

import { ProcessItem, ChildProcessItem, ProcessContainer } from '../composites'
import { PROCESS } from 'types'
import { sortDataBaseOnValue } from 'utils/sortData';

const groupByStage = (children) => {
  let result = children.reduce((groups, child) => {
    const stage = child.stage
    if (Object.keys(groups).includes(stage)) return groups

    groups[stage] = children.filter(c => c.stage === stage)
    return groups
  }, {})
  return result
}

const previousChildrens = (processes,process) => {
  let totalCount = 0
  for(let i = 0; i < processes.length; i++){
    if(processes[i].key === process.key){
      break;
    }
    if(processes[i].children){
      let insideChildren = []
      let groups = processes[i].children.reduce((groups, item) => ({
        ...groups,
        [item.stage]: [...(groups[item.stage] || []), item]
      }), {});
      Object.values(groups).forEach(function(group) {
        insideChildren.push(group.length)
      });
      if(insideChildren.length != 0){
        let childCount = insideChildren.sort((a,b)=>a-b).reverse()[0];
        totalCount = childCount + totalCount
      }
    }
  }
  return totalCount
}

const getTotalChilds = (processes,process) => {
  let count = 0;
  for(let i = 0; i < processes.length; i++){
    if(processes[i].variant === 'item' && (processes[i].type === 'Barcode Stage' || processes[i].type === 'Match Out')){
      if(process.key === processes[i].key){
        break;
      }
      let transparentData = processes[i].children;
      if(transparentData && Array.isArray(transparentData)){
        let groupedChildren = groupByStage(transparentData)
        let maxCountArray = [];
        for (const [key,value] of Object.entries(groupedChildren)) {
          maxCountArray.push(value.length)
        }
        count = count + Math.max(...maxCountArray)
      }
    }
  }
  return count;
}

const getMaxChildNumber = (processes) => {
  let maxCounts = {};
  let sendCount = 0
  processes.forEach((process) => {
    if(process.type === 'transparent' && process.accepts.length !== 0){
      process.children.forEach((treeProcess) => {
        let mainProcessLength = treeProcess.children.length
        let childCount = 0
        let processWithChildrenLength = []
        treeProcess.children.forEach((childProcess, index) => {
          let insideChildren = []
          if(childProcess.children){
            if(childProcess.children.length != 0){
              let groups = childProcess.children.reduce((groups, item) => ({
                ...groups,
                [item.stage]: [...(groups[item.stage] || []), item]
              }), {});
              Object.values(groups).forEach(function(group) {
                insideChildren.push(group.length)
              });
            }
          }
          if(insideChildren.length != 0){
            childCount = insideChildren.sort((a,b)=>a-b).reverse()[0];
            processWithChildrenLength.push(childCount)
            mainProcessLength = mainProcessLength + childCount
          }
        })
        let count = processWithChildrenLength.reduce((partialSum, a) => partialSum + a, 0);
        maxCounts[mainProcessLength] = count
      })
    }
    let keyArray = Object.keys(maxCounts)
    let maxKey = Math.max(...keyArray);
    if(maxKey !== undefined && maxKey !== -Infinity){
      let maxValue = maxCounts[maxKey]
      if(maxValue !== undefined){
        sendCount  = parseInt(maxValue)
      }
    }
  });
  return sendCount
}

const getMaxChildForTransparentNumber = (processes) => {
  let count = 0;
  if(processes[4] !== undefined){
    if(processes[4].type === 'transparent'){
      let transparentData = processes[4].children[0].children
      if(transparentData && Array.isArray(transparentData)){
        let groupedChildren = groupByStage(transparentData)
        let maxCountArray = [];
        for (const [key,value] of Object.entries(groupedChildren)) {
          maxCountArray.push(value.length)
        }
        count = count + Math.max(...maxCountArray)
      }
    }
  }
  return count;
}

const getlargestBlockChildrenCount = (processes) => {
  let maxCounts = {};
  let sendCount = 0
  processes.forEach((process) => {
    if(process.type === 'transparent' && process.accepts.length !== 0){
      process.children.forEach((treeProcess) => {
        let mainProcessLength = treeProcess.children.length
        let childCount = 0
        let processWithChildrenLength = []
        treeProcess.children.forEach((childProcess, index) => {
          let insideChildren = []
          if(childProcess.children){
            if(childProcess.children.length != 0){
              let groups = childProcess.children.reduce((groups, item) => ({
                ...groups,
                [item.stage]: [...(groups[item.stage] || []), item]
              }), {});
              Object.values(groups).forEach(function(group) {
                insideChildren.push(group.length)
              });
            }
          }
          if(insideChildren.length != 0){
            childCount = insideChildren.sort((a,b)=>a-b).reverse()[0];
            processWithChildrenLength.push(childCount)
            mainProcessLength = mainProcessLength + childCount
          }
        })
        let count = processWithChildrenLength.reduce((partialSum, a) => partialSum + a, 0);
        maxCounts[mainProcessLength] = count
      })
    }
    let keyArray = Object.keys(maxCounts)
    let maxKey = Math.max(...keyArray);
    if(maxKey !== undefined && maxKey !== -Infinity){
      let maxValue = maxCounts[maxKey]
      if(maxValue !== undefined){
        sendCount  = parseInt(maxKey) - parseInt(maxValue) - 1
      }
    }
  });
  return sendCount
}

function buildProcesses(processes, editAreaProps, productID = null,maxProcess = []) {
  let largestBlockChildrenCount = 0
  let isOneChild = false
  let acceptList = null

  function buildProcesses(processes, processId = '', rootIndex = null, children = false,maxProcess = []) {
    let processID = processId
    let defaultPathProcessID = ''
    let parentProcessNumber = rootIndex
    let parentProcessProps = null
    let largestChildGroup = 0
    let childGroupLength = 0
    processes = ( (processes instanceof Array ) ? processes : processes.children)
    return processes.map((process, index) => {

      const currentID = processID ? `${processID}.${index}` : (index > 3 ? `process,${index}.children.0` : `process,${index}`)

      const processProps = {
        key: currentID,
        processID: currentID,
        processIndex: index,
        editArea: editAreaProps,
        process,
        productID
      }

      if (process.variant === PROCESS.VARIANT.ITEM) {
        if (!children) {
          /**
           * Calculate the process number whenever we try to render the
           * ProcessItem component.
           */
          let processNumber = rootIndex + largestChildGroup + index + 1

          if (rootIndex === null && index > 3) {
            largestBlockChildrenCount = getlargestBlockChildrenCount(processes)
          }
          
          let maxChildNumber = getMaxChildNumber(processes) 
          if(rootIndex > 3){
            largestBlockChildrenCount = getlargestBlockChildrenCount(maxProcess)
            maxChildNumber = getMaxChildNumber(maxProcess) 
            index = rootIndex
            rootIndex = null
          }

          /**
           * Add the number of largest block children counted to the process
           * number when the process item comes AFTER the block items container
           */
          if (rootIndex === null && index > 3) {
            let maxChildForTransparent = getMaxChildForTransparentNumber(processes)
            if(maxChildForTransparent){
              processNumber = processNumber + maxChildForTransparent
            } 
            let prevousChild = previousChildrens(processes,process)
            if(prevousChild != 0){
              processNumber = processNumber + (prevousChild - largestChildGroup)
            }
            if(largestChildGroup){
              let totalChildCounts = getTotalChilds(processes,process)
              if(largestChildGroup < totalChildCounts){
                largestChildGroup = totalChildCounts
              }
              processNumber = processNumber + (largestBlockChildrenCount - prevousChild) + largestChildGroup
            }else{
              processNumber = processNumber + (largestBlockChildrenCount - prevousChild)
            }
            processNumber = processNumber + maxChildNumber
          }else{
            let prevousChild = previousChildrens(processes,process)
            if(prevousChild != 0){
              processNumber = processNumber + (prevousChild - largestChildGroup)
            }
          }

          // Set the 'QA Fail' barcode item's process number to 99, always.
          if (rootIndex === 2 && index === 1) {
            processNumber = 99
          }

          processProps.processNumber = processNumber

          /**
           * At this point we do a mutation on the _process_ state we are
           * trying to render from by adding a `number` property to it with
           * the value of the process item's order number calculated above.
           *
           * The reason we do this is because this is the easiest and most
           * _efficient_ way to set the process numbers, the alternative was
           * to do this whenever we update the state, which is impractical
           * because every state update meant we have to run iterations on
           * the whole `processes` state in order to recalculate the process
           * numberings AND we also have to run another iteration in order to
           * render them.
           *
           * This way however, we just calculate and set the process numberings
           * DURING rendering of the process items, which means we remove reiteration
           * after an update in order to calculate and set these process numberings.
           *
           * Though it is inelegant since mutations are bad in practice,
           * this should be of no concern as long as the `number` property
           * doesn't get read by React (which it doesn't in this case).
           *
           * This is only of used when we run `constructFlowPayload` whenever
           * the user saves their changes, by then it does get read and passed
           * on to the payload schema as the value for the flow item's `index`.
           */
          process.number = processNumber
          defaultPathProcessID = processID
          parentProcessNumber = processNumber

          if (!process.children) {
            return <ProcessItem {...processProps} />
          } else {
            process.accepts = acceptList
            parentProcessProps = processProps

            isOneChild = process.children.length === 1 ? true : false
            const groupedChildren = groupByStage(process.children)
            const result = {}
            largestChildGroup = 0

            for (const [key,value] of Object.entries(groupedChildren)) {
              largestChildGroup = largestChildGroup < value.length ? value.length : largestChildGroup
              childGroupLength = value.length - 1
              const childProcesses = buildProcesses(value, `${currentID}.children,stage-${key}`, parentProcessNumber, true, maxProcess)
              result[key] = childProcesses
            }

            processID = defaultPathProcessID

            return <ProcessItem {...parentProcessProps} children={result} />
          }
        } else {
          processProps.processNumber = parentProcessNumber + index + 1
          process.number = parentProcessNumber + index + 1
          
          return <ChildProcessItem {...processProps} type={process.type} isLast={index === childGroupLength} isOneChild={isOneChild} />
        }
      }

      if (process.variant === PROCESS.VARIANT.CONTAINER) {
        /* Set accepts variable so we can pass it to any process with children */
        acceptList = process.accepts

        /**
         * Set the largest currently known number of process items found on
         * a block container.
         *
         * We'll need to know this in order to append it with the numbering for
         * the process items that come after the block items container.
         */
        if (process.type === PROCESS.TYPE.BLOCK) {
          largestBlockChildrenCount = largestBlockChildrenCount < process.children.length
                                      ? process.children.length - 1
                                      : largestBlockChildrenCount
        }

        if (process.type === PROCESS.TYPE.TRANSPARENT) {
          const sortingOrder = {
            'text': 1,
            'cover': 2
          }
          process.children = process.children ? sortDataBaseOnValue(process.children, "text", sortingOrder) : process.children
        }

        const childrenProcesses = buildProcesses(
            process.children,
            `${currentID}.children`,
            rootIndex ? rootIndex : index,
            '',            
            processes
        )

        return (
            <ProcessContainer {...processProps}>
              {childrenProcesses}
            </ProcessContainer>
        )
      }

      return null
    })
  }

  return buildProcesses(processes)
}

const Container = styled.div`
  padding: 48px 16px;
  overflow: auto;

  display: flex;
  align-items: flex-start;

  width: 100%;
  height: 400px;
  max-width: calc(81vw + 10px);

  & > div:last-of-type {
    position: relative;

    &:after {
      content: '';

      position: absolute;
      top: 0;
      right: -16px;

      width: 16px;
      height: 1px;
    }
  }
`

function Processes(props) {
  const { data, productID, ...editAreaProps } = props
  const processes = buildProcesses(data, editAreaProps, productID)
  return <Container>{processes}</Container>
}

export default Processes

