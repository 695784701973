import styled from 'styled-components';
const ReportTitle = styled.h1`
  padding-left: 3rem;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 0;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
`

export default ReportTitle;