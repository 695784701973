import React, { useState, useEffect } from 'react';
import * as S from './TableStyled';

const Table = ({
  columns,
  dataSource,
  rowKey,
  loading,
  handleRow
}) => {
  const [expandedRow, setExpandedRow] = useState(null); // State to track the expanded row
  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {


    const processDataSource = (originalData) => {
      const filteredItems = [];

      const processedItems = [];
      const seenPaperCodes = new Set();
      const hasTertiaryProductGroupId = new Set();
      originalData.forEach((item) => {
        if (item.linear_length) {
          processedItems.push(item);
          return;
        }

        if (item.tertiary_product_group_id !== null) {
          hasTertiaryProductGroupId.add(item.paper_code);
          const duplicateItem = { ...item, tertiary_product_group_id: null };
          processedItems.push(item, duplicateItem);
        } else {
          processedItems.push(item);
        }

      });

      const orderSumMap = {};
      const totalSumMap = {};
      const orderTodaySumMap = {};
      const linearFeetDueMap = {};
      const linearFeetPastDueMap = {};
      const linesSubmittedMap = {};
      const linearFeetDueToday = {}
      const linearFeetDueTomorrow = {}
      const linearFeetDueTomorrowPlus = {}
      const linearFeetDueTomorrowGreater = {}
      const totalLinearFeetPrintedToday = {}
      const hrTotal = {}
      const hr0Total = {}
      const hr6Total = {}
      const hr7Total = {}
      const hr8Total = {}
      const hr9Total = {}
      const hr10Total = {}
      const hr11Total = {}
      const hr12Total = {}
      const hr13Total = {}
      const hr14Total = {}
      const hr15Total = {}
      const hr16Total = {}
      const hr17Total = {}
      const hr18Total = {}
      const hr19Total = {}
      const hr20Total = {}
      const hr21Total = {}
      const hr22Total = {}
      const hr23Total = {}

      const reprocessedItems = processedItems.map((item, index, arr) => {

        const paperCodeExists = arr.slice(0, index).some(
          (prevItem) => prevItem.department_id !== item.department_id &&
            prevItem.paper_code === item.paper_code
        );

        if (item.tertiary_product_group_id !== null && item.row && item.row.style) {
          return {
            ...item,
            row: {
              ...item.row,
              style: {
                ...item.row.style,
                fontWeight: 'bold',
              },
            },
            queues: item.paper_code?item.paper_code:"DEFAULT (NO PAPER CODE)",
            linear_feet_production: item.paper_code,
            delete: item.department_id !==1 ? true : paperCodeExists,

          };
        }
        return item;
      });

      const groupedItems = {};
      
      reprocessedItems.forEach((item) => {

        const key = `${item.department_id}-${item.paper_code}`;
        if (!groupedItems[key]) {
          groupedItems[key] = [];
        }
        groupedItems[key].push(item);
      });
      const groupedAndFilteredItems = Object.values(groupedItems).flatMap(
        (group) => {
          return group.filter(
            (item) =>
              item.department_name === "Books & Albums Total" ||
              item.department_name === "Prints & Posters Total" ||
              item.department_name === "Prints & Posters" ||
              item.department_name === "Books & Albums" || (item.department_name === "Acrylic Dept Total" && item.linear_length) || (item.department_name === "Total" && item.linear_length)
          );
        }
      );
      const seenDepartmentIds = new Set();
      groupedAndFilteredItems.forEach((item) => {
        if (seenDepartmentIds.has(item.department_id) && item.tertiary_product_group_id !== null) {
          item.delete = true;
        }
        else {
          seenDepartmentIds.add(item.tertiary_product_group_id);
        }
        if (item.delete === true && seenPaperCodes.has(item.paper_code)) {
          return;
        }
        if (item.delete === true) {
          seenPaperCodes.add(item.paper_code);
        }

        filteredItems.push(item);
      });

      const finalcheck = [];
      const paperCodeToCount = {};
      
      filteredItems.forEach((newitem) => {
        if (
          newitem.department_name === "Books & Albums" &&
          newitem.tertiary_product_group_id !== null
        ) {
          const paperCode = newitem.paper_code;
          if (paperCodeToCount[paperCode] === undefined) {
            paperCodeToCount[paperCode] = 1;
            finalcheck.push({
              ...newitem,
              
            });          
          } else if (paperCodeToCount[paperCode] === 1) {
            paperCodeToCount[paperCode] = 2;
          }

        } else {
          finalcheck.push(newitem);
        }
      });
      finalcheck.forEach((item) => {
        if (item.department_name === "Prints & Posters Total" || item.department_name === "Books & Albums Total") {
          return;
        }
        const key = `${item.department_id}-${item.paper_code}`;

        if (!linearFeetDueMap[key]) {
          linearFeetDueMap[key] = 0;
        }
        
        if (item.tertiary_product_group_id === null) {
          linearFeetDueMap[key] += item.total_linear_feet_due;
        }

        if (!orderTodaySumMap[key]) {
          orderTodaySumMap[key] = 0;
        }
        
        if (item.tertiary_product_group_id === null) {
          orderTodaySumMap[key] += item.orders_due_today;
        }
        
        if (!linearFeetPastDueMap[key]) {
          linearFeetPastDueMap[key] = 0;
        }
        
        if (item.tertiary_product_group_id === null) {
          linearFeetPastDueMap[key] += item.total_linear_feet_past_due;
        }

        if (!linesSubmittedMap[key]) {
          linesSubmittedMap[key] = 0;
        }
        
        if (item.tertiary_product_group_id === null) {
          linesSubmittedMap[key] += item.lines_submitted;
        }
        
        if (!totalSumMap[key]) {
          totalSumMap[key] = 0;
        }
        if (item.total === null) {
          totalSumMap[key] += item.total;
        }  

        if (!orderSumMap[key]) {
          orderSumMap[key] = 0;
        }
        if (item.tertiary_product_group_id === null) {
          orderSumMap[key] += item.orders;
        }  
        
        if (!linearFeetDueToday[key]) {
          linearFeetDueToday[key] = 0;
        }
        if (item.tertiary_product_group_id === null) {
          linearFeetDueToday[key] += item.linear_feet_due_today;
        } 

        if (!linearFeetDueTomorrow[key]) {
          linearFeetDueTomorrow[key] = 0;
        }
        if (item.tertiary_product_group_id === null) {
          linearFeetDueTomorrow[key] += item.linear_feet_due_tomorrow;
        } 

        if (!linearFeetDueTomorrowPlus[key]) {
          linearFeetDueTomorrowPlus[key] = 0;
        }
        if (item.tertiary_product_group_id === null) {
          linearFeetDueTomorrowPlus[key] += item.linear_feet_due_tomorrow_plus_1;
        } 
        if (!linearFeetDueTomorrowGreater[key]) {
          linearFeetDueTomorrowGreater[key] = 0;
        }
        if (item.tertiary_product_group_id === null) {
          linearFeetDueTomorrowGreater[key] += item.linear_feet_due_greater_than_tomorrow_plus_1;
        } 

        if (!totalLinearFeetPrintedToday[key]) {
          totalLinearFeetPrintedToday[key] = 0;
        }
        if (item.tertiary_product_group_id === null) {
          totalLinearFeetPrintedToday[key] += item.total_linear_feet_printed_today;
        }
        
        if (item.hasOwnProperty('12am-6am')) {
          if (!hrTotal[key]) {
            hrTotal[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hrTotal[key] += item.total;
          } 
          if (!hr0Total[key]) {
            hr0Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr0Total[key] += item['12am-6am'];
          } 
          if (!hr6Total[key]) {
            hr6Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr6Total[key] += item['6am-7am'];
          } 
          if (!hr7Total[key]) {
            hr7Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr7Total[key] += item['7am-8am'];
          } 
          if (!hr8Total[key]) {
            hr8Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr8Total[key] += item['8am-9am'] || 0;
          }
          
          if (!hr9Total[key]) {
            hr9Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr9Total[key] += item['9am-10am'] || 0;
          }
          
          if (!hr10Total[key]) {
            hr10Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr10Total[key] += item['10am-11am'] || 0;
          }
          
          if (!hr11Total[key]) {
            hr11Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr11Total[key] += item['11am-12pm'] || 0;
          }
          
          if (!hr12Total[key]) {
            hr12Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr12Total[key] += item['12pm-1pm'] || 0;
          }
          
          if (!hr13Total[key]) {
            hr13Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr13Total[key] += item['1pm-2pm'] || 0;
          }
          
          if (!hr14Total[key]) {
            hr14Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr14Total[key] += item['2pm-3pm'] || 0;
          }
          
          if (!hr15Total[key]) {
            hr15Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr15Total[key] += item['3pm-4pm'] || 0;
          }
          
          if (!hr16Total[key]) {
            hr16Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr16Total[key] += item['4pm-5pm'] || 0;
          }
          
          if (!hr17Total[key]) {
            hr17Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr17Total[key] += item['5pm-6pm'] || 0;
          }
          
          if (!hr18Total[key]) {
            hr18Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr18Total[key] += item['6pm-7pm'] || 0;
          }
          
          if (!hr19Total[key]) {
            hr19Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr19Total[key] += item['7pm-8pm'] || 0;
          }
          
          if (!hr20Total[key]) {
            hr20Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr20Total[key] += item['8pm-9pm'] || 0;
          }
          
          if (!hr21Total[key]) {
            hr21Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr21Total[key] += item['9pm-10pm'] || 0;
          }
          
          if (!hr22Total[key]) {
            hr22Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr22Total[key] += item['10pm-11pm'] || 0;
          }
          
          if (!hr23Total[key]) {
            hr23Total[key] = 0;
          }
          if (item.tertiary_product_group_id === null) {
            hr23Total[key] += item['11pm-12am'] || 0;
          }
        }

       });
      const updatedItems = finalcheck.map((item) => {
        // if (item.department_name === "Prints & Posters Total" || item.department_name === "Books & Albums Total") {
        //   return;
        // }
        const key = `${item.department_id}-${item.paper_code}`;
    
        if (item.tertiary_product_group_id !== null) {
          const newOrders = orderSumMap[key];
          const total = totalSumMap[key];
          const newLinearFeetDue = linearFeetDueMap[key];
          const newLinearFeetPastDue = linearFeetPastDueMap[key];
          const newLinesSubmitted = linesSubmittedMap[key];
          const newLinesDueToday = linearFeetDueToday[key];
          const newLinesDueTomorow = linearFeetDueTomorrow[key];
          const newLinesDueTomorowPlus = linearFeetDueTomorrowPlus[key];
          const newLinesDueTomorowPlusGreater = linearFeetDueTomorrowGreater[key];
          const newTotalLinearFleet = totalLinearFeetPrintedToday[key];
          const newOrderToday = orderTodaySumMap[key]

          if (item.hasOwnProperty('12am-6am')) {
            item.total = hrTotal[key];
            item['12am-6am'] = hr0Total[key];
            item['6am-7am'] = hr6Total[key];
            item['7am-8am'] = hr7Total[key];
            item['8am-9am'] = hr8Total[key];
            item['9am-10am'] = hr9Total[key];
            item['10am-11am'] = hr10Total[key];
            item['11am-12pm'] = hr11Total[key];
            item['12pm-1pm'] = hr12Total[key];
            item['1pm-2pm'] = hr13Total[key];
            item['2pm-3pm'] = hr14Total[key];
            item['3pm-4pm'] = hr15Total[key];
            item['4pm-5pm'] = hr16Total[key];
            item['5pm-6pm'] = hr17Total[key];
            item['6pm-7pm'] = hr18Total[key];
            item['7pm-8pm'] = hr19Total[key];
            item['8pm-9pm'] = hr20Total[key];
            item['9pm-10pm'] = hr21Total[key];
            item['10pm-11pm'] = hr22Total[key];
            item['11pm-12am'] = hr23Total[key];
           }


          if (newOrders) {
            return {
              ...item,
              orders: newOrders,
              total:total,
              orders_due_today: newOrderToday,
              total_linear_feet_due: newLinearFeetDue,
              total_linear_feet_past_due: newLinearFeetPastDue,
              lines_submitted: newLinesSubmitted,
              linear_feet_due_today: newLinesDueToday,
              linear_feet_due_tomorrow: newLinesDueTomorow,
              linear_feet_due_tomorrow_plus_1: newLinesDueTomorowPlus,
              linear_feet_due_greater_than_tomorrow_plus_1: newLinesDueTomorowPlusGreater,
              total_linear_feet_printed_today: newTotalLinearFleet
            };
          }
        }
            
        return item;
      });
     
      return updatedItems;
    };
    // Process the dataSource and set it in the state
    setProcessedData(processDataSource(dataSource));
  }, [dataSource, expandedRow]);
  return (
    <S.TableStyled
      columns={columns}
      rowKey={rowKey}
      dataSource={processedData}
      scroll={{ x: 'max-content' }}
      pagination={false}
      loading={loading}
      onRowClick={(targetRow) => {
        if (targetRow?.row?.id != null && targetRow?.row?.header == true) {
          handleRow(targetRow?.row?.id);
          setExpandedRow(targetRow);
        }
      }}
    />
  );
}

export default Table;
