import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, Input, Modal, notification, Form } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenSmartAPI } from 'utils'
import { holdDepartmentOrdersPanelRoute, releaseOrder , holdPanelLockSetting} from "utils/apiRoutes"
import { holdInitialData } from "utils/panelsInitialData"
import { useParams } from "react-router-dom";
import LockUpdateModal from './LockUpdateModal';

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const HoldPanelPage = (props) => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [ascend, setAscend] = useState(holdInitialData)
    const [updateLockModal, setUpdateLockModal] = useState(false)
    const [lockModalTitle, setLockModalTitle] = useState('')
    const [lockOrder, setLockOrder] = useState('')
    const [enableLockOrder, setEnableLockOrder] = useState(false)

    const {slug} = useParams();

    const fetchData = (slug) => {
        setLoading(true)
        ZenSmartAPI.get(slug ? holdDepartmentOrdersPanelRoute(slug) : holdDepartmentOrdersPanelRoute())
            .then((res) => {
                setLoading(false)
                setItemData(res.data.data)
                setOriginalData(res.data.data)
                setAscend(holdInitialData)

            })
            .catch((res) => {
                setLoading(false)
            })

    }

    const searchOperation = async (value, source) => {
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                            false :
                            anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) : anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
                return containsValue
            })
            setItemData(result)
        }
    }

    const releaseOrderHandler = (orderNumber) => {
        ZenSmartAPI.put(releaseOrder(orderNumber))
            .then(() => {
                setData(
                    data.filter((order) => {
                        return order.order_id !== orderNumber;
                    })
                );
                notification['success']({
                    message: 'Order has been released'
                })
            })
            .catch((err) => {
                notification['error']({
                    message: err.response.data.message
                })
            });
    };

    const confirmRelease = (message, orderNumber) => {
        Modal.confirm({
            content: message,
            onOk: () => releaseOrderHandler(orderNumber)
        })
    }

    const updateLock = (modalTitle, orderNumber) => {
        setUpdateLockModal(true)
        setLockModalTitle(modalTitle)
        setLockOrder(orderNumber)
    }

    useEffect(() => {
        if (slug) {
            fetchData(slug)
        } else {
            fetchData()
        }
    }, [slug]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        enableLockItem();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const enableLockItem = () => {
        setLoading(true)
        ZenSmartAPI.get(holdPanelLockSetting)
            .then((res) => {
                setLoading(false)
                setEnableLockOrder(res.data.data)
            })
            .catch((res) => {
                setLoading(false)
            })
    }

    return (
        <>
            <Header>
                <Title>Process Center {">"} Hold Panel</Title>
            </Header>
            <LockUpdateModal
                modalTitle={lockModalTitle}
                updateLockModal={updateLockModal}
                setUpdateLockModal={setUpdateLockModal}
                setData={setData}
                data={data}
                lockOrder={lockOrder}
                setLockOrder={setLockOrder}
            />
            <Panel title="Hold Panel">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <Row type="flex" align="middle">
                        <InputStyle />
                        <Heading>Search :</Heading>
                        <Col style={{ padding: 10 }}>
                            <Input.Search
                                type="text"
                                placeholder="Filters.."
                                disabled={loading}
                                size="large"
                                onChange={(value) => searchOperation(value.target.value, originalData)}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </div>
                <TableData itemData={itemData} loading={loading} setLoading={setLoading} data={data} setData={setData} ascend={ascend} setAscend={setAscend} confirmRelease={confirmRelease} updateLock={updateLock} enableLockOrder={enableLockOrder} />
            </Panel>
        </>
    )
}

export default HoldPanelPage