import React, { useState, useEffect } from 'react'

import { Row, Col, Select, Input, Button } from 'antd'
import WorkOrderRightPanelTable from './WorkOrderRightPanelTable'

import { applySelectedCss, getHeaders, searchOperation, searchOperationWithoutDelay, applyFails, workOrderApplySelectedCss } from '../PressReleaseV2/PressReleaseHelperFunctions'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment'

import { alphabeticalData } from 'utils/sortData';

const { Option } = Select;
const WorkOrderRightPanel = (props) => {
    const { rightLoading, rawRightTableColumn, rightTableData, ascend, setAscend, setItemData, rightColumnFilterDataObject, setRightColumnFilterDataObject, rightFilterData, rightTableOriginalData, setRightTableData, selectedRightRow, setSelectedRightRow, handlePrint } = props;

    const [ column, setColumn ] = useState([]);
    const [sortColumnsClasses, setSortColumnClasses] = useState(null)
    const [searchFilter, setSearchFilter] = useState('');

    var getTable = document.getElementById(`toprint-table`)
    if (getTable) {
        const getTr = getTable.getElementsByTagName(`tr`)[0]
        if (getTr) {
            const getSortWidth = getTr.getElementsByTagName(`th`);
            if (Array.from(getSortWidth).length > 0 && sortColumnsClasses === null) {
                const filterWidth = Array.from(getSortWidth).map(item => item.clientWidth)
                setSortColumnClasses(filterWidth)
            }
        }
    }

    const setColumnDataFilterFunction = (value, property) => {
        setRightColumnFilterDataObject({ ...rightColumnFilterDataObject, [property]: value })
    }

    const renderFilterColumnDatas = (data) => {
        const returnValue = Object.keys(rightFilterData).map((item, key) => {
            const getTableHeaderWidth = sortColumnsClasses[key];
            if (getTableHeaderWidth) {
                return <div style={{ textAlign: "center", padding: "10px 2px 15px 2px", width: getTableHeaderWidth }}>
                    <Select defaultValue={""} style={{ marginTop: 10, width: '100%' }}
                        optionFilterProp="children"
                        showSearch
                        dropdownMatchSelectWidth={false}
                        onChange={(e) => setColumnDataFilterFunction(e, item)}
                    >
                        <Option value={""} style={{ fontWeight: "bold" }}>{rightFilterData[item].label}</Option>
                        {rightFilterData[item].date_data ? alphabeticalData(rightFilterData[item].date_data).map(data =>
                            <Option value={moment(data).format('MMM DD ddd hh:mm').toString()}>{moment(data).format('MMM DD ddd hh:mm').toString()}</Option>
                        )
                            : alphabeticalData(rightFilterData[item].data).map(data =>
                                <Option value={data}>{data}</Option>
                            )}
                    </Select>

                </div >
            }
            return null
        })
        return <div style={{ position: "relative", overflowX: "auto", width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
                {returnValue}
            </div>
        </div>
    }

    const filterHandler = (input) => {
        var arrayValue = [];
        if (input !== "") {
            arrayValue = searchOperationWithoutDelay(input, rightTableOriginalData)
        }
        else {
            arrayValue = rightTableOriginalData
        }
        Object.keys(rightColumnFilterDataObject).map(item => {
            if (rightColumnFilterDataObject[item] !== "") {
                arrayValue = arrayValue.filter(data => {
                    const dataItem = item === "fails" && data[item] === 0 ? "False" : item === "fails" && data[item] > 0 ? "True" : data[item]
                    return dataItem === rightColumnFilterDataObject[item]
                })
            }
            return item
        })
        setRightTableData(arrayValue);
    }

    useEffect(() => {
        if(rawRightTableColumn){
            const columnData = [];
            Object.keys(rawRightTableColumn).map(data => {
                columnData.push({
                    title: getHeaders(data, rawRightTableColumn[data].label.toUpperCase(), false, rightTableData, rawRightTableColumn, ascend, setAscend, setRightTableData),
                    dataIndex: data,
                    key: data,
                    width : data === "id" ? "160px" : null,
                    render: (value, row) => {
                        if (data === "id") {
                            workOrderApplySelectedCss(value, `selected-right`, selectedRightRow, `selected-right-${value}`)
                            if (row.fails > 0) {
                                applyFails(`selected-right-${value}`, 'failed-sent')
                            }
                        }
                        if (data === "fails") {
                            return <div>{value > 0 ? <ExclamationCircleOutlined
                                style={{
                                    color: 'white',
                                    backgroundColor: '#E84A50',
                                    fontSize: 20,
                                    borderRadius: '50%',
                                    width: 20,
                                    height: 20
                                }}
                            /> : "-"}</div>
                        }
                        else if (data === 'imposed_datetime' || data === 'work_order_printed_at') {
                            return moment(value).format('MMM DD ddd hh:mm').toString()
                        }
                        else {
                            return <div>{value !== null ? value : "-"}</div>
                        }
                    }
                })
                return data
            })
            setColumn(columnData)
        }
        // [ascend, labels, itemData, selectedBatched]
    }, [rawRightTableColumn, ascend, rightTableData, selectedRightRow])


    useEffect(() => {
        filterHandler(searchFilter, rightColumnFilterDataObject)
    }, [rightColumnFilterDataObject, searchFilter])

    return (
        <>
            <div style={{ padding: 5, minHeight: "75vh" }} className="panel-div">
                <Row type="flex" align="middle" justify="space-between" style={{ padding: 10 }}>
                    <Col span={8} style={{ padding: 10 }}>
                        <Input.Search
                            type="text"
                            placeholder="Search"
                            loading={rightLoading}
                            onChange={(value) => searchOperation(value.target.value, rightTableOriginalData, rightTableOriginalData, setRightTableData, setSearchFilter, filterHandler, rightColumnFilterDataObject)}
                        />
                    </Col>
                    <Col span={5} style={{ padding: 10 }}>
                        <Button type="primary" disabled={rightLoading} onClick={handlePrint}>Re-Print Ticket</Button>
                    </Col>
                </Row>
                <WorkOrderRightPanelTable 
                    rightLoading={rightLoading} 
                    rightTableData={rightTableData} 
                    column={column}
                    selectedRightRow={selectedRightRow}
                    setSelectedRightRow={setSelectedRightRow} 
                />
                {column.length > 0 && sortColumnsClasses && !rightLoading &&
                    <div >
                        {renderFilterColumnDatas(rightTableData)}
                    </div>
                }
            </div>
        </>
    )
}

export default WorkOrderRightPanel

