import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Checkbox } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;
`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;
}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

.bold-text {
    font-weight: bold;
}

.batch-code-link {
    text-decoration: underline;
    color: blue;
}

`
const TableData = (props) => {

    const [data, setData] = useState([]);
    const { itemData, loading, selectedCheckBoxValue, setSelectedCheckBoxValue, handleTableChanged } = props

    const batchDisplay = (batches) => {
        return (
            <>
                {batches && batches.map((batch, i) => (
                    <p key={i}>
                        <Checkbox onChange={e => handleBatchChecked(e, batch.batch_barcode || batch.batch_number)} checked={selectedCheckBoxValue.includes(batch.batch_barcode || batch.batch_number)}></Checkbox>&nbsp;
                        <span>
                            <Link className='batch-code-link bold-text' to={`/app/data-view/batch_numbers/${batch.batch_id}`} target="_blank" rel="noopener noreferrer">{batch.batch_barcode || batch.batch_number}</Link>
                            &nbsp;-&nbsp;
                            <span className='bold-text'>{batch.product_code}</span>
                            &nbsp;in&nbsp;
                            <span className='bold-text'>{batch.line_text_stage}</span>
                            &nbsp;for&nbsp; 
                            <span className='bold-text'>{batch.hours} hours</span>&nbsp;
                            <span>
                                    {batch.unique_submission_ids && batch.unique_submission_ids.length > 2 ? batch.unique_submission_ids : ''}
                            </span>
                        </span>
                    </p>
                ))}
            </>
        )
    }

    const batchSorter = (a, b, key) => {
        let current = a[key];
        let next = b[key];

        if(!current[0] && !next[0]) {
            return 0;
        }
        
        if(current[0]) {
            if(next[0]) {
                if (current[0].batch_barcode < next[0].batch_barcode) return -1;
                if (next[0].batch_barcode < current[0].batch_barcode) return 1;
            } else {
                return 1;
            }
        } else {
            return - 1;
        }
        return 0;        
    }

    const columns = [

        {
            title: 'BATCH LINK',
            dataIndex: 'batch_link_code',
            key: 'batch_link_code',
            defaultSortOrder: 'ascend',
            sorter: true
        },
        {
            title: 'DUE DATE',
            dataIndex: 'due_date',
            key: 'due_date',
            sorter: true,
        },
        {
            title: 'VENDOR',
            dataIndex: 'vendor_name',
            key: 'vendor_name',
            sorter: true,      
        },        
        {
            title: 'BATCHES IN COMBO OR P/D',
            dataIndex: 'batches_in_combo_or_pd',
            key: 'batches_in_combo_or_pd',
            sorter: false,
            render: (batches) => batchDisplay(batches),
        },
        {
            title: 'BATCHES NOT YET ARRIVED',
            dataIndex: 'batches_not_yet_arrived',
            key: 'batches_not_yet_arrived',
            sorter: false,
            render: (batches) => batchDisplay(batches),
        },  
        {
            title: 'OLDEST DWELL',
            dataIndex: 'oldest_dwell',
            key: 'oldest_dwell',
            sorter: true,
        },              

    ];

    const handleBatchChecked = (e, barcode) => {
        let selected_batches = selectedCheckBoxValue
        let is_checked = e.target.checked;
        console.log(barcode);
        if (is_checked) {
            setSelectedCheckBoxValue([...selectedCheckBoxValue, barcode])
        }
        else {
            setSelectedCheckBoxValue(selected_batches.filter(data => data !== barcode))
        }
    }

    return (
        <>
            <TableStyling />
            <StyleTable>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={itemData}
                    bordered
                    size="middle"
                    pagination={false}
                    rowKey='batch_link'
                    onChange={handleTableChanged}
                    sortDirections={['ascend', 'descend']}
                />
            </StyleTable>
        </>
    )
}

export default TableData