import moment from 'moment';

const initialState = {
  tasks: [],
  maintenance: {
    tasks: [],
    class: 'start-up',
    device_type: null,
    faults: {
      status: 'pending',
      data: []
    }
  },
  station: null,
  overview: {
    start_date: moment().subtract(1, 'M').format('YYYY-MM-DD 00:00:00'),
    end_date: moment().format('YYYY-MM-DD 00:00:00')
  }
};

const tasks = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_STATION':
      return {
        ...state,
        station: action.payload.station
      }
    case 'GET_TASKS':
      return {
        ...state,
        tasks: action.payload.tasks
      }
    case 'SET_OVERVIEW_DATES':
      return {
        ...state,
        overview: {
          start_date: action.payload.start_date,
          end_date: action.payload.end_date
        }
      }
    case 'GET_TASKS_BY_DEVICE_AND_CLASS':
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          ...action.payload
        }
      }
    case 'GET_FAULTS_BY_STATUS':
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          faults: {
            status: action.payload.status,
            data: action.payload.faults
          }
        }
      }
    case 'SET_TASK_CLASS':
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          class: action.payload.class
        }
      }
    default:
      return state;
  }
};

export default tasks;