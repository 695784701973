import React from 'react';
import {Button, Input, Col, Modal, Row} from "antd";
import {Label} from 'components/zensmart-design-system/components/Label/Label.js';
import styled from "styled-components";

const CenterButton = styled.p`
  text-align : center;
`;

export default function PickUpNotesModal(props) {
    const {TextArea} = Input;
    const {visible, setShowPickupNotesModal, pickupNotes, setPickUpNotes, onCancel, onSubmit, loading} = props;

    const onChange = (e) => {
        setPickUpNotes(e.target.value)
    }

    return (
        <Modal
            visible={visible}
            centered
            width={700}
            onCancel={() => setShowPickupNotesModal(false)}
            footer={null}
        >
            <div style={{padding: '20px 20px 40px 20px'}}>
                <Row>
                    <div style={{textAlign: 'center'}}>
                        <Label style={{margin: 10}} status={'blue'}>
                            PICKUP NOTES
                        </Label>
                    </div>
                </Row>
                <Row className="mb-2" style={{display: 'flex', justifyContent: 'center'}}>
                    <TextArea
                        rows={3}
                        value={pickupNotes}
                        onChange={onChange}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                onSubmit();
                            }
                        }}
                    />
                </Row>

                <Row type="flex" justify="center" align="center">
                    <Col span={6}>
                        <CenterButton>
                            <Button size={'large'} loading={loading}
                                    style={{width: '100%'}}
                                    type="primary"
                                    onClick={onSubmit}>SAVE</Button>
                        </CenterButton>
                    </Col>
                    <Col span={6} offset={1}>
                        <CenterButton>
                            <Button size={'large'}
                                    style={{width: '100%'}}
                                    onClick={onCancel}
                                    type="danger" ghost>CANCEL</Button>
                        </CenterButton>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}