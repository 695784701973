import React, { useState, useEffect, createRef } from "react";
import { Modal, notification } from "antd";
import RecipeParamForm from "../../forms/RecipeParamForm";
import RecipeEditableTable from "../../forms/RecipeEditableTable";
import { getProperty } from "../../utils/ImpositionUtil";
import { Libraries, INTEGER_TYPE, FLOAT_TYPE } from "../../config/Imposition";
import { FormTypes, OverlayTemplates } from "../../config/Form";
import { ZenSmartAPI } from "utils";
import { getOverlayTemplates } from "utils/apiRoutes";
import { useSelector } from "react-redux";
import is from "is_js";
import _ from "lodash";
import cloneDeep from 'lodash/cloneDeep';

const RecipeParamDialog = (props) => {
  const { visible, isTable } = props.modal;
  const [tableDatasource, setTableDataSource] = useState(null);
  const [tableDataState, setTableDataState] = useState(null);
  const [customFormValues, setCustomFormValues] = useState([]);
  const formRef = createRef();
  const imposeTemplates = useSelector((state) => state.imposition.imposeTemplate);

  //set table data source when header drop down values changed after component is mounted
  const stringifyValue = (value) => {
    if(!value) return ''
    return typeof value === 'string' ? value : `${value}`
  }

  useEffect(()=> {
    ZenSmartAPI.get(getOverlayTemplates).then(res => {
      const response = res.data.data
      console.log("overlay templates response: ",JSON.stringify(response));
      setCustomFormValues(cloneDeep([...customFormValues, {
        key: OverlayTemplates.key,
        values: is.existy(response) ? response : [],
        default: is.existy(response) ? response[0].image_location : null
      }]));
    }).catch((_) => {
      console.log("Fail to query background recipe templates from API. Setting defaults.");
      setCustomFormValues(cloneDeep([...customFormValues, {
        key: OverlayTemplates.key,
        values: OverlayTemplates.values,
        default: OverlayTemplates.default
      }]));
    });
  },[]);

  useEffect(() => {
    if (props.output){
        
        if(props.paperCodes && props.laminationCodes){
          let change = false
          const newSource = _.cloneDeep(props.output)
          //getting papercode in array to compare
          
          const papCodes = _.cloneDeep(props.paperCodes).map(function(data){
            return stringifyValue(data.code).toUpperCase();
          });

          //getting lamination code to compare
          const lamCodes = _.cloneDeep(props.laminationCodes).map(function(data){
            return stringifyValue(data.code).toUpperCase();
          });
          
          //assign array for missing values
          let paperCodeNotPresent = []
          let laminationCodeNotPresent = []
          let newLaminationCodes;
          let newPaperCodes;
          
          //getting codes not present
          newSource.forEach(function(data,index){
            if(papCodes.includes(stringifyValue(data.paper_code).toUpperCase()) === false){
              paperCodeNotPresent.push(data.paper_code)
            }
            if(lamCodes.includes(stringifyValue(data.lamination_code).toUpperCase()) === false){
              laminationCodeNotPresent.push(data.lamination_code)
            }
          })

          //removing duplicate
          paperCodeNotPresent = paperCodeNotPresent.filter(function(item, pos) {
            return paperCodeNotPresent.indexOf(item) == pos;
          })
          laminationCodeNotPresent = laminationCodeNotPresent.filter(function(item, pos) {
            return laminationCodeNotPresent.indexOf(item) == pos;
          })
          
          if(laminationCodeNotPresent.length !== 0){
            change = true
          
            const newLaminationCode = laminationCodeNotPresent.map(function(data,index){
              return { id : lamCodes.length + index + 1 ,code : data }
            })
            newLaminationCodes = props.laminationCodes.concat(newLaminationCode)
            props.setLaminationCodes(newLaminationCodes)
          }

          
          if(paperCodeNotPresent.length !== 0){
            change = true
          
            const newPaperCode = paperCodeNotPresent.map(function(data,index){
              return { id : papCodes.length + index + 1, code : data }
            })
            newPaperCodes = props.paperCodes.concat(newPaperCode)
            props.setPaperCodes(newPaperCodes)
          }

          if(change === true){
            //assing paper and lamination id to output
            const newOutput = newSource.map(function(data,index){
              
              //assign key to data
              let paperId;
              let laminationId;
              if(newPaperCodes === undefined){
                paperId = props.paperCodes.map(function(paper){
                  return (stringifyValue(paper.code).toUpperCase() === stringifyValue(data.paper_code).toUpperCase() ? paper.id : undefined)
                }).filter(function(element){
                  return element !== undefined;
                })
            
              }else{
                paperId = newPaperCodes.map(function(paper){
                  return (stringifyValue(paper.code).toUpperCase() === stringifyValue(data.paper_code).toUpperCase() ? paper.id : undefined)
                }).filter(function(element){
                  return element !== undefined;
                })
              }

              if(newLaminationCodes === undefined){
                laminationId = props.laminationCodes.map(function(lamination){
                  return (stringifyValue(lamination.code).toUpperCase() === stringifyValue(data.lamination_code).toUpperCase() ? lamination.id : undefined)
                }).filter(function(element){
                  return element !== undefined;
                })
              }else{
                laminationId = newLaminationCodes.map(function(lamination){
                  return (stringifyValue(lamination.code).toUpperCase() === stringifyValue(data.lamination_code).toUpperCase() ? lamination.id : undefined)
                }).filter(function(element){
                  return element !== undefined;
                })
              }
              
              data.paper_code_id = paperId[0]
              data.lamination_code_id = laminationId[0]
              return {...data};
          })
        
          setTableDataSource(newOutput);
          setTableDataState(newOutput);
        }
        else{
          setTableDataSource(props.output);
          setTableDataState(props.output);
        }
      }
    }else{
      setTableDataSource(props.output);
      setTableDataState(props.output);     
    }
  }, [props.output]);

  useEffect(() => {
    if (is.existy(imposeTemplates)){
      setCustomFormValues(_.cloneDeep([...customFormValues, imposeTemplates]));
    }
  }, [imposeTemplates]);

  const getOperation = () => {
    if (visible && props.recipeId !== "") {
      const recipeList = props.recipes;
      const recipe = Array.isArray(recipeList)
        ? recipeList.find((recipe) => recipe.sortOrder === props.recipeId)
        : recipeList;
      const library = Libraries.find((property) => property.id === recipe.name);
      return library.label;
    } else {
      return "Unknown";
    }
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (isTable) {
      const emptyDataSource = tableDatasource.find(
        (dataSource) =>
          is.not.existy(dataSource.printer_id) &&
          is.not.existy(dataSource.paper_code_id) &&
          is.not.existy(dataSource.lamination_code_id) &&
          is.not.existy(dataSource.path)
      );

      if (is.existy(emptyDataSource)){
        notification.error({
          message: "Please fill in all empty print path details"
        });
      }else{
        props.hasUnsavedChanges(props.checkUnsaveChanges(null, tableDatasource, null));
        props.updateOutput(tableDatasource);
        setTableDataState(tableDatasource);
        closeModal();
      }
    } else {
      const form = formRef.current.form;

      form.validateFields((err, values) => {
        if (!err) {
          saveParam(form);
        }
      });
    }
  };

  const cancelDialog = () => {
    if (!isTable) {
      const filteredRecipe = props.recipes.find((recipe) => recipe.sortOrder === props.recipeId);

      const recipeParam = filteredRecipe.parameters.filter((recipeParam) =>
          is.empty(recipeParam.value)
      );

      if (typeof recipeParam.value !== 'undefined' && recipeParam.length > 0) {
        props.removeRecipe(props.recipeId, false);
      }
    }else{
      setTableDataSource(tableDataState);
    }

    closeModal();
  };

  const saveParam = (form) => {
    const recipeId = props.recipeId;

    if (recipeId !== "") {
      props.updatePdfURL([]);
      const recipeList = [...props.recipes];
      const recipe = recipeList.find(
        (property) => property.sortOrder === props.recipeId
      );
      const library = Libraries.find((property) => property.id === recipe.name);

      let newRecipesParam = [];
      if (library.parameters) {
        library.parameters.forEach((param) => {
          const fieldKey = param + "_" + recipeId;
          const fieldValue = setFieldValue(form.getFieldValue(fieldKey), getProperty(FormTypes, param));
          let recipeParam = recipe.parameters.find(
            (elem) => elem.name === param
          );
          if(!recipeParam){
            recipeParam = {"name" : param};
          }
          newRecipesParam = [...newRecipesParam, {...recipeParam, value: fieldValue}];
        });

        const newRecipe = {...recipe, parameters: newRecipesParam};
        recipeList.splice(newRecipe.sortOrder, 1);
        recipeList.splice(newRecipe.sortOrder, 0, newRecipe);
        
        if (!props.unsavedChanges){
          props.hasUnsavedChanges(props.checkUnsaveChanges(recipeList, null, null));
        }

        props.updateRecipes(recipeList, true);
        notification.info({
          message: "Recipe saved"
        });
      }

      props.updatePdfURL(recipeList);
    }

    closeModal();
  };

  const setFieldValue = (fieldValue, type) => {
    if (is.not.existy(fieldValue)) {
      return "";
    }

    if (type === INTEGER_TYPE || type === FLOAT_TYPE){
      return Number(fieldValue);
    }

    return fieldValue;
  };

  const closeModal = () => {
    props.setModal({
      visible: false,
    });
  };

  return (
    <Modal
      destroyOnClose={true}
      width={isTable ? props.modal.tableWidth : 700}
      title={isTable ? "Output Metadata" : getOperation() + " Metadata"}
      visible={visible}
      onOk={(event) => submitForm(event)}
      onCancel={cancelDialog}
    >
      {isTable ? (
        <RecipeEditableTable
          tableColumns={props.modal.columns}
          tableDatasource={tableDatasource}
          setTableDataSource={setTableDataSource}
          paperCodes={props.paperCodes}
          setPaperCodes={props.setPaperCodes}
          laminationCodes={props.laminationCodes}
          setLaminationCodes={props.setLaminationCodes}
          outputFormat={props.outputFormat}
          specifiedPrinters={props.specifiedPrinters}
          setSpecifiedPrinters={props.setSpecifiedPrinters}
        />
      ) : (
        <RecipeParamForm
          recipeData={{recipes: props.recipes, recipeId: props.recipeId}}
          formData={{customFormValues: customFormValues}}
          wrappedComponentRef={formRef}
        />
      )}
    </Modal>
  );
};

export default RecipeParamDialog;