import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, Form, Button, Select, notification } from 'antd'
import is from "is_js";
import * as _ from 'lodash'
import { alphabeticalData } from "utils/sortData"

import { ConditionsInput } from 'components/composites';
import AutoCompleteBar from 'components/composites/autoCompleteBar';
import { PROCESS } from 'types'

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`

const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`

const toOption = template => (
  <Select.Option
      key={template.id}
      value={template.name_slug}
  >
    {template.name}
  </Select.Option>
)

const toOptionRecipient = recipient => (
  <Select.Option
      key={recipient.id}
      value={recipient.path}
  >
    {recipient.label}
  </Select.Option>
)

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function EditForm(props) {
  const {
    form,
    sendEmail,
    onSave,
    processID,
    hideModal,
    removeTrigger,
    qualifiers,
    recipients,
    emailTemplates,
  } = props

  const recipientOption = alphabeticalData(recipients).map(toOptionRecipient);
  const emailTemplateOptions = alphabeticalData(emailTemplates).map(toOption);

  const conditionValues =
    sendEmail && sendEmail.fieldsTags && sendEmail.fieldsTags.length > 0 &&  sendEmail.conditions[0].qualifier
      ? { fieldsTags: sendEmail.fieldsTags, qualifier:  sendEmail.conditions[0].qualifier }
      : {}

  const {
    getFieldDecorator,
    getFieldsError,
    getFieldsValue
  } = form

  // const [recipient, setRecipient] = useState(null)

  const extractPath = (array) => {
    let pathArray = []

    array.forEach((a) => {
      const { path } = a;
      pathArray = [...pathArray, path]
    })

    return pathArray
  }

  const handleSubmit = e => {
    e.preventDefault()
    const { condition, ...inputValues } = getFieldsValue()
    inputValues.fieldsTags = condition.fieldsTags
    if(condition.fieldsTags.length > 0) {
      inputValues.conditions = [{
        conditionField: condition.fieldsTags[0].path,
        qualifier: condition.qualifier
      }]
    } else {
      inputValues.conditions = [];
    }
    // inputValues.recipientsTags = recipient
    // inputValues.recipients = extractPath(recipient)
    inputValues.trigger_id = _.get(sendEmail, 'trigger_id', null);
    onSave(inputValues, PROCESS.TRIGGER.EMAIL, processID)
    hideModal()
    notification.success({
      message: 'Saved email trigger details.'
    })
  }

  // const handleAutoCompleteSearch = (values) => {
  //   if (is.existy(values) && is.array(values)) {
  //     setRecipient(values)
  //   }
  // }

  const onRemoveTrigger = () => {
    removeTrigger(PROCESS.TRIGGER.EMAIL, processID)
  }

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <StyledFormItem label="Recipient">
        {getFieldDecorator('recipient', {
          initialValue: sendEmail && sendEmail.recipient
        })((
          // <AutoCompleteBar prefix={"orders"} placeholder="Email Recipients (Press 'Enter' key to add each recipient)"
          //   returnValue={(_, values) => handleAutoCompleteSearch(values)} />
          <Select
            style={{ width: '100%'}}
            placeholder="Select Email Recipient"
          >
            {recipientOption}
          </Select>
        ))}
      </StyledFormItem>

      <StyledFormItem label="Template">
        {getFieldDecorator('template', {
          initialValue: sendEmail && sendEmail.template
        })((
          <Select
            style={{ width: '100%' }}
            placeholder="Select Template"
          >
            {emailTemplateOptions}
          </Select>
        ))}
      </StyledFormItem>

      <StyledFormItem label="Condition">
        {getFieldDecorator('condition', {
          initialValue: conditionValues
        })(
          <ConditionsInput resource="scan_logs"
            form={form}
            qualifiers={qualifiers}
          />
        )}
      </StyledFormItem>

      <ButtonContainer>
        <Button onClick={hideModal}>Cancel</Button>
        {processID && <Button type="danger" onClick={onRemoveTrigger}>Delete</Button>}
        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
        >
          Save Changes
        </Button>
      </ButtonContainer>
    </Form>
  )
}

const EditEmailTriggerForm = Form.create({
  name: 'Edit Email Trigger Item Form'
})(EditForm)

function EmailTriggerModal(props) {
  const { sendEmail, onSave, processID, removeTrigger, qualifiers, recipients, emailTemplates, ...modal } = props
  return (
    <Modal {...modal}>
      <EditEmailTriggerForm
        sendEmail={sendEmail}
        onSave={onSave}
        processID={processID}
        hideModal={modal.onCancel}
        removeTrigger={removeTrigger}
        qualifiers={qualifiers}
        recipients={recipients}
        emailTemplates={emailTemplates}
      />
    </Modal>
  )
}

export default EmailTriggerModal