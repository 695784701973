import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import throttle from "lodash.throttle";
import { MenuContextProvider } from "context/menuContext";
import appState from "store";
import { saveState } from "./localStorage.js";

import { Protected } from "components/utils";
import Home from "pages/Home";
import { PagesRoutes } from "./pages/pagesRoutes";
import ChangePassword from "pages/ChangePassword.js";
import { Search, DataView } from "pages/Search";
import { Imposition } from "pages/Imposition";
import { Settings } from "pages/Settings";
import { ProductFlow, CreateProduct, EditProduct, ProductClone } from "pages/ProductFlow";
import { ControlTower } from "pages/ControlTower";
import { WaitingForPartnersV2 } from "pages/WaitingForPartnersV2";
import {
  Reporting,
  SavedReports,
  SavedReportsViewer,
  Dashboards,
  DashboardsViewer,
  Billing,
} from "pages/Performance";
import PermissionRoute from './components/restrictedRoutes/permissionRoutes'
import { IncomingOrder } from "pages/Process/Incoming Orders";
import { FailedTransaction } from "pages/Process/FailedTransactions";
import {BulkDataLoadingToolkit} from "pages/Prep/BulkDataLoadingToolkit/index.js";
import { HoldPanel } from "pages/Process/HoldPanel";
import { Checkout } from "pages/Process/Checkout";
import { PreDispatchOverview } from "pages/PackAndShip/PreDispatchOverview";
import { MatchOverviewPage } from "pages/PackAndShip/MatchOverview";
import { WipPanel } from "pages/Performance/WipPanel";
import { InOutPanel } from "pages/Performance/InOutPanel";
import { FailPanel } from "pages/Performance/FailPanel";
import { HealthPanel } from "pages/Performance/HealthPanel";
import { ProductFlowReporter } from "pages/Performance/ProductFlowReporter";
import { BomMaterialsPage } from "pages/Materials/BOM Materials";
import { JobTicketPrinterPage } from "pages/Materials/JobTicketPrinter";
import { CellActivityData } from "pages/Performance/Cell/CellActivityData";
import { DispatchDesigner } from "pages/PackAndShip";
import { TargetsPage } from "pages/Performance/Targets";
import { DepartmentWipSummaries } from "pages/Performance/DepartmentWipSummaries";
import { Maintenance, Overview, TaskEdit, FaultsOverview, EngineersList } from "pages/Machine";
import "./index.css";
import { WaitingForPartners } from "pages/WaitingForPartners";
import { PressReleaseV2 } from "pages/PressReleaseV2";
import { WorkOrder } from "pages/WorkOrder";
import BarcodePressRelease from "pages/BarcodePressRelease/BarcodePressRelease.js";
import Operators from "pages/People/Operators/Operators.js";
import Accessories from "pages/AccessoryPage/Accessories.js";
import Currencies from "pages/Settings/Currencies/Currencies";
import ViesusRulesPage from "pages/Settings/ViesusRulePage/ViesusRulePage";
import MultiCutRulesPage from "pages/Settings/MultiCutRulePage/MultiCutRulePage";
import Vendors from "pages/Settings/VendorPage/Vendors.js";
import Stations from "pages/People/Stations/Stations.js";
import PermissionsTable from "pages/People/Operators/PermissionsTable.js"
import { WorkFlowRulesPage } from "pages/WorkFlowRules";
import { Manifest } from "pages/PackAndShip/Manifest";
import ReturnToProductionPage from "pages/ReturnToProduction/ReturnToProductionPage";
import Dropship from "pages/PackAndShip/Dropship";
import ManualConsignment from "./pages/PackAndShip/ManualConsignment";
import AutoRun from "pages/Machine/Autorun";
import WeeklyCellDepartmentSummary from "pages/Performance/Cell/WeeklyCellDepartmentSummary/WeeklyCellDepartmentSummary.js";
import OperatorsTableDataPage from "pages/Performance/OperatorPerformance/OperatorTable/OperatorsTableDataPage";
import OperatorsActivityDataPage from "pages/Performance/OperatorPerformance/OperatorsActivityData/OperatorsActivityDataPage";
import OperatorEfficiencyPage from "pages/Performance/OperatorPerformance/OperatorEfficiency/OperatorEfficiencyPage"
import OperatorComparisonPage from "pages/Performance/OperatorPerformance/OperatorComparisonChart/OperatorComparisonPage"
import UchidaInputGenerator from 'pages/Machine/UchidaInputGenerator/UchidaInputGenerator.js'
import WeeklyDepartmentSummaryPage from "pages/Performance/OperatorPerformance/WeeklyDepartmentSummary/WeeklyDepartmentSummaryPage"
import StationActivityData from "pages/Performance/StationPerformance/StationActivityData/StationActivityDataPage";
import StationEfficiencyPage from "pages/Performance/StationPerformance/StationEfficiencyData/StationEfficiencyPage";
import StationProductActivityPage from "pages/Performance/StationPerformance/StationProductActivityData/StationProductActivityPage";
import JITBatchingPage from "pages/BatchRelease/JITBatching/JITBatchingPage";
import BatchReleaseDesignerPage from "pages/BatchRelease/BatchReleaseDesigner/BatchReleaseDesignerPage";
import PrinterAssignmentPage from "pages/BatchRelease/PrinterAssignment/PrinterAssignmentPage";
import ManualPickup from "pages/PackAndShip/ManualPickup/ManualPickup.js";
import Printers from "pages/PrinterPage/Printers.js";
import { BatchLinkOverview } from "pages/PackAndShip/BatchLinkOverview";
import ProductSkew from "pages/ProductSkew/ProductSkew";
import ImpositionRulePage from "pages/Settings/ImpositionRulePage/ImpositionRulePage.js";
import PostDispatchMessage from "pages/PostDispatchMessage/PostDispatchMessage.js";
import SummaryDashboard from "pages/Settings/DashboardSummary/DashboardSummary.js";
import ProductionQueue from "pages/Performance/ProductionQueues/ProductionQueue.js";
import HourlyLinearFeetProgressReport from "pages/Performance/HourlyLinearFeetProgressReport/HourlyLinearFeetProgressReport.js";
import AssociateProgressReport from "pages/Performance/AssociateProgressReport/AssociateProgressReport.js";
import AutoRunOverview from "pages/Machine/AutoRunOverview/index.js";
import QaFailReasons from "pages/Settings/QaFailReasonsPage/QaFailReasons.js";
import CancelReasons from "pages/Settings/CancelReasonsPage/CancelReasons.js";
import CheckoutReasons from "pages/Settings/CheckoutReasonPage/CheckoutReason.js";
import LabelTemplates from "pages/Settings/LabelTemplatesPage/LabelTemplate.js";
import EmailTemplates from "pages/Settings/EmailTemplatesPage/EmailTemplate.js";
import { Postbacks } from "pages/Process/Postbacks";

/**
 * Each time an update happens on redux, save the
 * redux state to localStorage after 1 second.
 */
appState.subscribe(
    throttle(() => {
      saveState(appState.getState());
    }, 1000)
);

const App = () => (
    <Provider store={appState}>
      <MenuContextProvider>
        <Router>
          <Switch>
            <Redirect exact from="/" to="/app" />
            <Route
                path="/return-to-production/:id/:token"
                component={ReturnToProductionPage}
            />
            <Protected path="/app">
              <Route path="/app/home" component={Home} />
              <Route path="/app/search" component={Search} />
              <Route path="/app/data-view/:resource/:id" component={DataView} />
              <PermissionRoute
                  path="/app/process/incoming-orders"
                  component={IncomingOrder}
                  permission="view-incoming-orders"
              />
              <PermissionRoute
                  path="/app/process/failed-transactions"
                  component={FailedTransaction}
                  permission="view-failed-transactions-page"
              />
              <PermissionRoute path="/app/process/on-hold-report/:slug" component={HoldPanel} permission="view-hold-panel" />
              <PermissionRoute path="/app/process/on-hold-report/" exact component={HoldPanel} permission="view-hold-panel" />
              <PermissionRoute path="/app/process/checked-out" component={Checkout} permission="view-checked-out-orders" />
              <PermissionRoute path="/app/process/postbacks" component={Postbacks} permission="view-order-postbacks-page" />
              <PermissionRoute exact path="/app/prep/product-flow" component={ProductFlow} permission="view-product-flow-designer" />
              <Route
                  path="/app/prep/product-flow/create-product"
                  component={CreateProduct}
              />
              <Route
                  path="/app/prep/product-flow/edit-product/:id"
                  component={EditProduct}
              />
              <PermissionRoute exact path="/app/prep/product-clone" component={ProductClone} permission="clone-product" />
              <PermissionRoute
                  path="/app/prep/bulk-data-loading-toolkit"
                  component={BulkDataLoadingToolkit}
                  permission="manage-bulk-data-loading-toolkit"
              />
              <PermissionRoute path="/app/prep/accessories" component={Accessories} permission="view-accessories-page" />
              <PermissionRoute path="/app/print/printers" component={Printers} permission="view-printers-page" />
              <PermissionRoute path="/app/print/imposition" component={Imposition} permission="view-imposition-designer" />
              <PermissionRoute path="/app/print/control-tower/:slug" component={ControlTower} permission="view-control-tower" />
              <PermissionRoute path="/app/print/control-tower" component={ControlTower} permission="view-control-tower" />
              <PermissionRoute
                  path="/app/print/waiting-for-partners/:slug"
                  component={WaitingForPartnersV2}
                  permission="view-waiting-for-partners"
              />
              <PermissionRoute
                  path="/app/print/waiting-for-partners"
                  component={WaitingForPartnersV2}
                  permission="view-waiting-for-partners"
              />
              <PermissionRoute
                  path="/app/print/press-release/:slug"
                  component={PressReleaseV2}
                  permission="view-press-release"
              />
              <PermissionRoute path="/app/print/press-release" component={PressReleaseV2} permission="view-press-release" />
              <PermissionRoute path="/app/print/work-order-release/:slug" component={WorkOrder} permission="view-work-order" />
              <PermissionRoute path="/app/print/work-order-release" component={WorkOrder} permission="view-work-order" />
              <PermissionRoute
                  path="/app/print/barcode-press-release"
                  component={BarcodePressRelease}
                  permission="view-press-release"
              />
              <PermissionRoute path="/app/print/batch-release/:slug" component={JITBatchingPage} permission="view-batching-configuration" />
              <PermissionRoute path="/app/print/batch-release-designer" component={BatchReleaseDesignerPage} permission="view-batching-configuration" />
              <PermissionRoute path="/app/print/printer-assignment" component={PrinterAssignmentPage} permission="view-printer-batching-configuration-assignment" />

            <PermissionRoute
              path="/app/performance/reports/:reportID?"
              component={Reporting}
              permission="view-reports-page"
            />
            <PermissionRoute
              path="/app/performance/saved-reports"
              component={SavedReports}
              permission="view-saved-reports-page"
            />
            <Route path="/app/print/work-order-release/:slug" component={WorkOrder} />
            <Route path="/app/print/work-order-release" component={WorkOrder} />
            <Route path="/app/print/barcode-press-release" component={BarcodePressRelease} />
            <Route path="/app/performance/reports/:reportID?" component={Reporting} />
            <Route path="/app/performance/saved-reports" component={SavedReports} />
            <Route path="/app/performance/production-queues" component={ProductionQueue} />
            <Route path="/app/performance/hourly-linear-feet-progress-report" component={HourlyLinearFeetProgressReport} />
            <Route path="/app/performance/associate-progress-report" component={AssociateProgressReport} />
            <PermissionRoute path="/app/performance/wip-panel" component={WipPanel} permission="view-wip-panel" />
            <PermissionRoute path="/app/performance/fail-panel" component={FailPanel} permission="view-fail-panel" />
            <PermissionRoute path="/app/performance/in-out-panel" component={InOutPanel} permission="view-in-out-panel" />
            <PermissionRoute path="/app/performance/product-flow-reporter" component={ProductFlowReporter} permission="view-product-flow-reporter-page" />
            <PermissionRoute
              path="/app/performance/healthcheck-panel"
              component={HealthPanel}
              permission="view-health-check-panel"
            />
            <PermissionRoute path="/app/performance/targets" component={TargetsPage} permission="view-targets-page" />
            <Route path="/app/performance/department-wip-summary/:deptID" component={DepartmentWipSummaries} />
            <PermissionRoute
              path="/app/materials/job-ticket-printer"
              component={JobTicketPrinterPage}
              permission="view-job-ticket-printer"
            />
            <PermissionRoute
              path="/app/materials/bill-of-materials"
              component={BomMaterialsPage}
              permission="view-bom"
            />
            <PermissionRoute
              path="/app/pack-and-ship/batch-link-overview"
              component={BatchLinkOverview}
              permission="view-pre-dispatch-overview"
            />            
            <PermissionRoute
              path="/app/pack-and-ship/pre-dispatch-overview"
              component={PreDispatchOverview}
              permission="view-pre-dispatch-overview"
            />
            <PermissionRoute
              path="/app/pack-and-ship/match-overview"
              component={MatchOverviewPage}
              permission="view-match-overview"
            />
            <Route path="/app/pack-and-ship/manifest" component={Manifest} />
            <Route exact path="/app/pack-and-ship/manual-pickup" component={ManualPickup}  permission="view-manual-pickup" />
            <PermissionRoute path="/app/pack-and-ship/dropship" component={Dropship} permission="manage-dropship" />
            <PermissionRoute
              path="/app/pack-and-ship/manual-consignment"
              component={ManualConsignment}
              permission="create-manual-consignments"
            />
            <Route
              path="/app/performance/view-reports/:reportID"
              component={SavedReportsViewer}
            />
            <PermissionRoute path="/app/performance/dashboards/:slug?" component={Dashboards} permission="view-dashboards-page" />
            <Route path="/app/performance/billing" component={Billing} />
            <PermissionRoute
              path="/app/performance/cell-performance/cell-activity-data"
              component={CellActivityData}
              permission="view-cell-activity-data"
            />
            <PermissionRoute
              path="/app/performance/cell-performance/weekly-cell-department-summary"
              component={WeeklyCellDepartmentSummary}
              permission="view-weekly-cell-department-summary"
            />
            <PermissionRoute path="/app/performance/station-performance/station-activity" component={StationActivityData} permission="view-station-activity" />
            <PermissionRoute path="/app/performance/station-performance/station-efficiency-chart" component={StationEfficiencyPage} permission="view-station-efficiency-chart" />
            <PermissionRoute path="/app/performance/station-performance/station-product-activity" component={StationProductActivityPage} permission="view-station-product-activity" />
            <PermissionRoute path="/app/performance/operator-performance/operators-activity" component={OperatorsActivityDataPage} permission="view-operators-activity" />
            <PermissionRoute path="/app/performance/operator-performance/operators-table" component={OperatorsTableDataPage} permission="view-operators-table" />
            <PermissionRoute path="/app/performance/operator-performance/operators-efficiency-chart" component={OperatorEfficiencyPage} permission="view-operators-efficiency-chart" />
            <PermissionRoute path="/app/performance/operator-performance/operators-comparison-chart" component={OperatorComparisonPage} permission="view-operators-comparison-chart" />
            <PermissionRoute path="/app/performance/operator-performance/weekly-department-summary" component={WeeklyDepartmentSummaryPage} permission="view-weekly-department-summary" />

            <PermissionRoute
              path="/app/pack-and-ship/dispatch-define"
              component={DispatchDesigner}
              permission="view-dispatch-designer"
            />
            <Route path="/app/pack-and-ship/manifest" component={Manifest} />
            <Route path="/app/machine/overview" component={Overview} />
            <PermissionRoute path="/app/machine/faults-overview" component={FaultsOverview} permission="view-machine-overview" />
            <PermissionRoute path="/app/machine/engineers-list" component={EngineersList} permission="view-machine-overview" />
            <PermissionRoute
              path='/app/machine/uchida-input-generator'
              component={UchidaInputGenerator}
              permission='view-uchida'
            />
            <PermissionRoute path="/app/machine/task-management" component={TaskEdit} permission="view-machine-task-designer" />
            <PermissionRoute path="/app/machine/maintenance" component={Maintenance} permission="view-tasks" />
            <PermissionRoute path="/app/people/operators" component={Operators} permission="view-operators" />
            <PermissionRoute path="/app/people/stations" permission="view-stations" component={Stations} />
            <Route path="/app/people/permissions" component={PermissionsTable} />
            <PermissionRoute
              path="/app/app-setup/workflow-rules"
              component={WorkFlowRulesPage}
              permission="view-product-flow-designer"
            />
            <PermissionRoute path="/app/app-setup/post-dispatch-messages" permission="view-post-dispatch-message-page" component={PostDispatchMessage} />
            <PermissionRoute path="/app/app-setup/vendors" permission="view-vendor-page" component={Vendors} />
            <PermissionRoute path="/app/app-setup/summary_dashboards" component={SummaryDashboard} />
            <Route exact path="/app/app-setup/product-skews" component={ProductSkew} />
            <PermissionRoute exact path="/app/app-setup/settings" permission="view-setting-page" component={Settings} />
            <PermissionRoute exact path="/app/app-setup/currencies" permission="view-setting-page" component={Currencies} />
            <PermissionRoute exact path="/app/app-setup/viesus-rules" permission="view-viesus-rule-page" component={ViesusRulesPage} />
            <PermissionRoute exact path="/app/app-setup/multicut-rules" permission="view-multi-cut-rule-page" component={MultiCutRulesPage} />
              <Route exact path="/app/app-setup/product-skews" component={ProductSkew} />
              <Route exact path="/app/machine/autorun/:id/:name" component={AutoRun} />
            <Route exact path="/app/machine/autorun-printer-overview/:id/:name" component={AutoRunOverview} />
            <PermissionRoute exact path="/app/app-setup/imposition-rules" permission="view-imposition-rule-page" component={ImpositionRulePage} />
            <PermissionRoute path="/app/app-setup/qa-fail-reasons" permission="view-qa-fail-reasons-page" component={QaFailReasons} />
            <PermissionRoute path="/app/app-setup/cancel-reasons" permission="view-cancel-reasons-page" component={CancelReasons} />
            <PermissionRoute path="/app/app-setup/checkout-reasons" permission="view-checkout-reasons-page" component={CheckoutReasons} />
            <PermissionRoute path="/app/app-setup/label-templates" permission="view-label-templates-page" component={LabelTemplates} />
            <PermissionRoute path="/app/app-setup/email-templates" permission="view-email-templates-page" component={EmailTemplates} />
            <PagesRoutes />
          </Protected>
          <Route path="/dashboard/:dashboardID" component={DashboardsViewer} />
          <Route path="/change-password/:token" component={ChangePassword} />
        </Switch>
      </Router>
    </MenuContextProvider>
  </Provider>
);

export default App;

