import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Droppable } from 'react-beautiful-dnd'

import { color } from 'components/zensmart-design-system/shared/styles.js'

import StageItems from './StageItems.js'
import TriggerItems from './TriggerItems.js'
import Processes from './Processes.js'
import { useDispatch } from 'react-redux'
import { GET_PRODUCT_ID } from 'store/actions/productFlowTypes'

const Heading = styled.h1`
  margin: 0;
  color: ${color.body};

  font-size: 13px;
  font-weight: bold;
`

const Header = styled.header`
  padding: 8px 20px;
  background-color: #F9F9FB; /* CUSTOM COLOR. NOT IN STYLE GUIDE. */

  border-top: 1px solid ${color.nav.unselected};
  border-bottom: 1px solid ${color.nav.unselected};
`

const Row = styled.div`
  display: flex;
  background-color: #EFF1F5; /* CUSTOM COLOR. NOT IN STYLE GUIDE. */
  border-bottom: 1px solid ${color.nav.unselected};
`

const Column = styled.div`
  padding: 8px 20px;

  flex: 1 0 auto;
  flex-basis: 50%;
  max-width: 60%;

  &:not(:last-of-type) {
    border-right: 1px solid ${color.nav.unselected};
  }

  & > h1 {
    margin-bottom: 16px;
  }

  &.stage-library {
    max-width: 37%;
  }
`

const LibraryContainer = styled.div`
  display: flex;
  align-items: flex-start;

  overflow: auto;
  height: 96px;
`

function EditArea(props) {
  const { processes, productID, prevProductID, ...rest } = props
  const dispatch = useDispatch()
  /**
   * Saves the current processes state to local storage whenever
   * this component unmounts.
   * 
   * The current solution below is a bit problematic since it'll
   * always save "unsaved changes" to local storage REGARDLESS
   * if the user has made any significant changes to their product
   * flow process.
   * 
   * For now however this works, but for the next updates we should
   * find a way to save ONLY WHEN significant changes have been made
   * on the processes state.
   */
  useEffect(() => () => {
    if (productID === prevProductID) {
      const unsavedChanges = JSON.stringify(processes)
      localStorage.setItem(`unsaved:${productID}`, unsavedChanges)
    }
    dispatch({
      type: GET_PRODUCT_ID,
      payload: productID
    })
  }, [prevProductID, productID, processes]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Droppable droppableId="NO_OP_DROP_ZONE" isDropDisabled>
      {provided => (
        <section
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <Header>
            <Heading>EDIT MODE</Heading>
          </Header>

          <Row>
            <Column className="stage-library">
              <Heading>STAGE LIBRARY</Heading>
              <LibraryContainer>
                <StageItems />
              </LibraryContainer>
            </Column>

            <Column>
              <Heading>TRIGGER LIBRARY</Heading>
              <LibraryContainer>
                <TriggerItems />
              </LibraryContainer>
            </Column>
          </Row>

          <Processes data={processes} {...rest} productID={productID} />

          {provided.placeholder}
        </section>
      )}
    </Droppable>
  )
}

export default EditArea