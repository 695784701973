export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const INIT_IMPOSITION_DESIGNER = "INIT_IMPOSITION_DESIGNER";
export const SAVE_RECIPES = "SAVE_RECIPES";
export const SAVE_OUTPUT = "SAVE_OUTPUT";
export const SAVE_PDF_URL= "SAVE_PDF_URL";
export const SAVE_CHANGE_TRACKER = "SAVE_CHANGE_TRACKER";
export const SAVE_IMPOSITION_DISABLED = "SAVE_IMPOSITION_DISABLED";
export const SAVE_HEADER = "SAVE_HEADER";
export const SAVE_IMPOSE_TEMPLATES = "SAVE_IMPOSE_TEMPLATES";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const SAVE_PRODUCT_OPTIONS = "SAVE_PRODUCT_OPTIONS";
export const SAVE_OUTPUT_FORMAT = "SAVE_OUTPUT_FORMAT";
export const SAVE_IMPOSITION_DESIGN_DATA = "SAVE_IMPOSITION_DESIGN_DATA";
