import styled from 'styled-components';
const ReportTitle = styled.h1`
  padding-left: 3rem;
  font-weight: normal;
  margin-bottom: 0;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
`

export default ReportTitle;