// nesbot to carbon conversion
const DateFormatConversion = (dateFormat) => {
  switch (dateFormat) {
    case "Y":
      return "YYYY";
    case "M Y":
      return "MMM YYYY";
    case "F":
      return "MMMM"
    case "Y-m W":
      return "YYYY-M W"
    case "W":
      return "W"
    case "Y-m-d":
      return "YYYY-M-D"
    case "m-d":
      return "M-D"
    case "l":
      return "dddd"
    case "Y-m-d H":
      return "YYYY-M-D HH"
    case "H":
      return "HH";
    default:
      return dateFormat;
  }
}

export default DateFormatConversion