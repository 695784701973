import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import SiblingChildArrow from './SiblingChildArrow'

import { DROP } from 'types'
import { color } from 'components/zensmart-design-system/shared/styles.js'

const Zone = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 15px;
  &:before {
    border-bottom: 12px solid ${color.nav.unselected};
  }
`

function DropZone(props) {
  const { whitelist, dragType, processID, stage, root = true } = props
  const id = `<${DROP.SIBLING}>${processID}${root ? '__root__1' : `__child__${stage + 1}`}`


  return (
    <Droppable
      type={whitelist?.includes(dragType) && dragType}
      droppableId={id}
    >
      {provided => {
        return (
          <Zone
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div>
              {provided.placeholder}
            </div>
          </Zone>
        )
      }}
    </Droppable>
  )
}

export default DropZone