import React from "react";
import { Button, notification } from "antd";
import { ZenSmartAPI } from "utils";
import { downloadCsvRoute } from "utils/apiRoutes";
import axios from "axios";

function ExportCsvButton({ station, startDate, endDate, type }) {
  const [loading, setLoading] = React.useState(false)

  const downloadCsv = () => {
    setLoading(true)
    ZenSmartAPI.get(downloadCsvRoute(station.barcode, startDate, endDate, type))
      .then(({ data }) => {
        const { error, message, file_name } = data;
        if (!error && message === "success") {
          axios({
            url: data.file_path,
            method: "GET",
            resposeType: "blob",
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.download = file_name;
            setLoading(false)
            link.click();
          });
        } else {
          notification.error({
            message: "Invalid response.",
          });
        }
      })
      .catch((res) => {
        notification.error({
          message: "Failed to download CSV",
        });
      });
  };

  return (
    <Button type="primary" onClick={() => downloadCsv()} loading={loading}>
      Export CSV
    </Button>
  );
}

export default ExportCsvButton;
