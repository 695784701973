import React from 'react'
import styled from 'styled-components'
import { Button, Alert } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'

const Container = styled.div`
  margin-bottom: 32px;
`

const Wrapper = styled.div`
  margin-bottom: 16px;

  & > p {
    margin-right: 32px;
  }

  & > button {
    margin-right: 8px;
  }
`

const Text = styled.p`
  display: inline-block;
  color: ${color.heading};
  font-size: 15px;

  & > span {
    font-weight: bold;
  }
`

function ArticleArea(props) {
  const {
    description,
    setCurrentProduct,
    saveProductFlow,
    hasUnsavedChanges,
    updateProductFlow,
    name
  } = props

  return (
    <Container>
      <Wrapper>
        <Text>
          <span>Product Name: </span>
          {name}
        </Text>

        <Button
          type="primary"
          onClick={saveProductFlow}
          loading={updateProductFlow.loading}
        >
          Save Changes
        </Button>

        <Button
          ghost
          type="danger"
          onClick={() => setCurrentProduct(null)}
        >
          Cancel
        </Button>
      </Wrapper>

      {hasUnsavedChanges ? (
        <Alert
          showIcon
          type="warning"
          message="Unsaved Product Flow"
          description="You have unsaved changes for this product's flow."
        />
      ) : null}
    </Container>
  )
}

export default ArticleArea