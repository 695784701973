import React, { useState, useEffect } from 'react'

import { Panel } from 'components/primaries'
import { Row, Col, notification } from 'antd'
import WorkOrderLeftPanel from './WorkOrderLeftPanel'
import WorkOrderRightPanel from './WorkOrderRightPanel'
import WorkOrderModal from './WorkOrderModal'
import WorkOrderFrame from './WorkOrderFrame'

import { color } from 'components/zensmart-design-system/shared/styles.js'
import styled, { createGlobalStyle } from 'styled-components'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ZenSmartAPI } from 'utils'
import { workOrderRoute, workOrderPrintRoute } from "utils/apiRoutes"

import { renderFilterColumnsData } from '../PressReleaseV2/PressReleaseHelperFunctions'
// mock up printing
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import JsBarcode from 'jsbarcode'

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const PageStyling = createGlobalStyle`
@media only screen and (max-width: 1280px) {
    span, td, th, .ant-select-selection-selected-value {
        font-size : 11px !important
    }
    .panel-div
    {
        min-height : 90vh;
    }
    .main-div{
        width : 1200px !important
    }
    .parent-header {
       width : ${props => props.getPanelWidth ? `${parseInt(props.getPanelWidth.clientWidth) + 300}px!important` : null};
    }
    .parent-container {
       width : ${props => props.getPanelWidth ? `${parseInt(props.getPanelWidth.clientWidth) + 530}px!important` : null};
    }
  }
  @media only screen and (max-width: 1152px) {
   
    .main-div{
        width : 1000px !important
    }
    
  }
@media screen and (min-width: 1281px) and (max-width: 1440px) {
    span {
        font-size : 12px !important;
    }
    td, th, .ant-select-selection-selected-value {
        font-size : 11px !important;
    }
    .panel-div
    {
        min-height : 80vh;
    }
}
@media only screen and (max-height: 900px) {
   
    .panel-div
    {
        min-height : 80vh;
    }
    .ant-table-body {
        height : 42vh !important
    }
    
  }
  .ant-table-placeholder {
    display : none !important;
}`

const WorkOrderPage = () => {
    const getPanelWidth = document.getElementsByClassName("main-div")[0];
    const userData = useSelector(state => state.user)
    const { slug } = useParams()

    // payload
    const [workOrder, setWorkOrder] = useState(null);

    //loading for tables
    const [leftLoading, setLeftLoading] = useState(true);
    const [rightLoading, setRightLoading] = useState(true);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [whatToPrint, setWhatToPrint] = useState(null);
    const [printers, setPrinters] = useState(null);
    const [selectedPrinter, setSelectedPrinter] = useState(null);

    //left table props
    const [leftTableData, setLeftTableData] = useState([]);
    const [leftTableOriginalData, setLeftTableOriginalData] = useState([]);
    const [rawLeftTableColumn, setRawLeftTableColumn] = useState(null);
    const [leftAscend, setLeftAscend] = useState({});
    const [leftFilterData, setLeftFilterData] = useState([]);
    const [leftColumnFilterDataObject, setLeftColumnFilterDataObject] = useState({})
    const [selectedLeftRow, setSelectedLeftRow] = useState([])

    //right table props
    const [rightTableData, setRightTableData] = useState([]);
    const [rawRightTableColumn, setRawRightTableColumn] = useState(null);
    const [rightTableOriginalData, setRightTableOriginalData] = useState([]);
    const [rightAscend, setRightAscend] = useState({});
    const [rightFilterData, setRightFilterData] = useState([]);
    const [rightColumnFilterDataObject, setRightColumnFilterDataObject] = useState({})
    const [selectedRightRow, setSelectedRightRow] = useState([])

    const [body, setBody] = useState(null);

    const fetchData = () => {
        setLeftLoading(true);
        setRightLoading(true);
        ZenSmartAPI.post(workOrderRoute, workOrder)
            .then((res) => {
                setBody(null);
                const result = res.data
                setLeftLoading(false);
                setRightLoading(false);
                setLeftTableData(result.toPrint.data);
                setLeftTableOriginalData(result.toPrint.data);
                setRawLeftTableColumn(result.toPrint.labels)
                setRightTableData(result.toReprint.data);
                setRightTableOriginalData(result.toReprint.data);
                setRawRightTableColumn(result.toReprint.labels);
                setLeftFilterData(renderFilterColumnsData(result.toPrint.labels, result.toPrint.data));
                setRightFilterData(renderFilterColumnsData(result.toReprint.labels, result.toReprint.data));
            })
            .catch((res) => {
                notification.error({ message: "Failed fetching data!" })
            })
    }

    useEffect(() => {
        console.log(slug, 'slug')
        if (userData.details) {
            if (slug) {
                setWorkOrder(userData.details && userData.details.page_settings.work_order_setting ? userData.details.page_settings.work_order_setting.find(data => data.slug === slug) : null)
            }
            else {
                setWorkOrder(userData.details && userData.details.page_settings.work_order_setting && userData.details.page_settings.work_order_setting.length === 1 ? userData.details.page_settings.work_order_setting[0] : null)
            }
        }
    }, [userData, slug]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (workOrder) {
            fetchData()
        }
    }, [workOrder])

    useEffect(() => {

    }, [selectedPrinter])

    useEffect(() => {
        if (body && body.data) {
            setTimeout(function () {
                window.frames["iframe"].focus();
                window.frames["iframe"].contentWindow.print();
            }, 2000);
        }
    }, [body])


    const handleLeftPrint = () => {
        if (selectedPrinter !== null) {
            notification.warning({ message: "Please select a printer" })
        } else {
            setLeftLoading(true);
            setBody(null)
            ZenSmartAPI.post(workOrderPrintRoute, { selected: selectedLeftRow, printer: selectedPrinter, panel_sent: "left", work_order_setting_id : workOrder.id })
                .then((res) => {
                    const result = res.data
                    // if(result.picklist === 'shopping_list'){
                    //     generateShoppingListPDF(result.data);
                    // } else {

                    // }
                    callOsiLinkToPrint(selectedLeftRow, "left")
                    if (res.data.data) {
                        setBody(result);
                    }
                    else {
                        notification.info({ message: 'Info: Only thermal tickets will be printed.' })

                    }
                    setLeftLoading(false);
                    setSelectedLeftRow([])
                    setLeftTableOriginalData(leftTableOriginalData.filter(data => !selectedLeftRow.includes(data.id)))
                    setLeftTableData(leftTableData.filter(data => !selectedLeftRow.includes(data.id)))
                    const mergeRowSent = selectedLeftRow.map(data => {
                        const returnBatch = leftTableOriginalData.find(item => item.id === data)
                        return returnBatch
                    })
                    setRightTableOriginalData(rightTableOriginalData.concat(mergeRowSent))
                    setRightTableData(rightTableData.concat(mergeRowSent))
                    setSelectedPrinter(null);
                    // fetchData()
                    setShowPrintModal(false)

                })
                .catch((res) => {
                    if (res && res.response && res.response.data) {
                        notification.error({ message: res.response.data.message })
                    }
                    setLeftLoading(false);
                    setShowPrintModal(false)
                })
        }
    }
    const callOsiLinkToPrint = (barcodes, panelSent) => {
        var queryStringID = '';
        barcodes.map((data, key) => {
            queryStringID = key === 0 ? queryStringID + `${data}` : queryStringID + `,${data}`;
        })
        window.location.assign(`zensmart://print/thermal-tickets/${queryStringID.replace(/,$/, "").replace(/^,/, "")}?panel_sent=${panelSent}&token=${localStorage.token}&work_order_setting_id=${workOrder.id}`);
    }

    const handleRightPrint = () => {
        if (selectedPrinter !== null) {
            notification.warning({ message: "Please select a printer" })
        } else {
            setRightLoading(true);
            setBody(null)
            ZenSmartAPI.post(workOrderPrintRoute, { selected: selectedRightRow, printer: selectedPrinter, user: userData.details.name, panel_sent: "right", work_order_setting_id : workOrder.id })
                .then((res) => {
                    const result = res.data
                    callOsiLinkToPrint(selectedRightRow, "right")
                    if (res.data.data) {
                        setBody(result);
                    }
                    else {
                        notification.info({ message: 'Info: Only thermal tickets will be printed.' })
                    }
                    setRightLoading(false);
                    // fetchData()
                    setShowPrintModal(false)
                    resetSelectedState()
                })
                .catch((res) => {
                    if (res && res.response && res.response.data) {
                        notification.error({ message: res.response.data.message })
                    }
                    setRightLoading(false);
                    setShowPrintModal(false)
                })
        }
    }

    const printModalHandler = (value) => {
        if (value === 'left') {
            if (!!selectedLeftRow.length > 0) {
                setShowPrintModal(true);
                setWhatToPrint('left');
            } else {
                notification.warning({ message: "Please select a batch to print" })
            }
        } else {
            if (selectedRightRow.length > 0) {
                setShowPrintModal(true);
                setWhatToPrint('right');
            } else {
                notification.warning({ message: "Please select a batch to print" })
            }
        }
    }

    const printModalOnCancel = () => {
        setShowPrintModal(false);
        setWhatToPrint(null);
    }

    const resetSelectedState = () => {
        setSelectedRightRow([]);
        setSelectedLeftRow([]);
        setSelectedPrinter(null);
    }
    return (
        <>
            <PageStyling getPanelWidth={getPanelWidth} />
            <Header>
                <Title style={{ textTransform: "capitalize" }}>Print {">"} Work Orders</Title>
            </Header>
            <div className="main-div">
                {body && <WorkOrderFrame body={body.data} />}
                <Row type="flex" align="middle" style={{ padding: "15px 15px 0px 15px" }} >
                    <Col span={12} style={{ padding: 10 }} >
                        <Panel title="Batched" style={{ minHeight: "75vh" }} className="panel-div">
                            <WorkOrderLeftPanel
                                leftLoading={leftLoading}
                                rawLeftTableColumn={rawLeftTableColumn}
                                leftTableData={leftTableData}
                                setLeftTableData={setLeftTableData}
                                ascend={leftAscend}
                                setAscend={setLeftAscend}
                                leftFilterData={leftFilterData}
                                setLeftFilterData={setLeftFilterData}
                                leftColumnFilterDataObject={leftColumnFilterDataObject}
                                setLeftColumnFilterDataObject={setLeftColumnFilterDataObject}
                                leftTableOriginalData={leftTableOriginalData}
                                selectedLeftRow={selectedLeftRow}
                                setSelectedLeftRow={setSelectedLeftRow}
                                handlePrint={printModalHandler}
                            />
                        </Panel>
                    </Col>
                    <Col span={12} style={{ padding: 10 }} >
                        <Panel title="Work Order Produced" style={{ minHeight: "75vh" }} className="panel-div">
                            <WorkOrderRightPanel
                                rightLoading={rightLoading}
                                rawRightTableColumn={rawRightTableColumn}
                                rightTableData={rightTableData}
                                setRightTableData={setRightTableData}
                                ascend={rightAscend}
                                setAscend={setRightAscend}
                                rightFilterData={rightFilterData}
                                setRightFilterData={setRightFilterData}
                                rightColumnFilterDataObject={rightColumnFilterDataObject}
                                setRightColumnFilterDataObject={setRightColumnFilterDataObject}
                                rightTableOriginalData={rightTableOriginalData}
                                selectedRightRow={selectedRightRow}
                                setSelectedRightRow={setSelectedRightRow}
                                handlePrint={printModalHandler}
                            />
                        </Panel>
                    </Col>
                </Row>
            </div>
            {showPrintModal &&
                <WorkOrderModal
                    printers={printers}
                    showPrintModal={showPrintModal}
                    modalHandlePrint={whatToPrint === 'left' ? handleLeftPrint : handleRightPrint}
                    printModalOnCancel={printModalOnCancel}
                    loading={whatToPrint === 'left' ? leftLoading : rightLoading}
                    setSelectedPrinter={setSelectedPrinter}
                    selectedPrinter={selectedPrinter}
                />
            }
        </>
    )
}

export default WorkOrderPage
