import React from 'react';
import { Modal, Table } from 'antd';
import styled from "styled-components";
import moment from 'moment';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 10px;
  }
`;

const CommentItem = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
`

const LogModal = props => {
  const { show, setShow, data, setData, isFault, setIsFault } = props;
  const taskLogColumns = ['Operator Name', 'Started', 'Completed'];
  const faultLogColumns = ['Reported', 'Completed', 'Severity', 'Comments'];

  const columnFormatter = columns => {
    const cols = columns.map(col => {
      const key = col.toLowerCase().replace(/ /g, '_');
      return {
        key: key,
        title: col,
        dataIndex: key
      }
    })
    return cols;
  }

  const dataFormatter = data => {
    const tableData = data ? data.map(item => {
      if(isFault) {
        return {
          key: item.id,
          reported: moment(item.start_time).format('DD MMM YYYY, hh:mm a'),
          completed: item.end_time && moment(item.end_time).format('DD MMM YYYY, hh:mm a'),
          severity: item.severity,
          comments: 
            <div>
              { item.history.map((log,index) => 
                <CommentItem key={index}> 
                  <div>{ log.operator_name } [ { moment(log.datetime).format('DD MMM YYYY, hh:mm a')} ]</div>
                  <p>{ log.comment }</p>
                </CommentItem> 
              )}
            </div>
        }
      } else {
        return {
          key: item.id,
          operator_name: item.operator_name,
          started: moment(item.start_time).format('DD MMM YYYY, hh:mm a'),
          completed: item.end_time && moment(item.end_time).format('DD MMM YYYY, hh:mm a')
        }
      }
    }) : null;
    return tableData;
  }

  const onCloseModal = () => {
    setData(null);
    setIsFault(false);
  }

  return (
    <StyledModal
      title="Event summary"
      visible={show}
      width={isFault ? 1000 : 600}
      onCancel={ () => setShow(false) }
      onCloseModal={ () => onCloseModal() }
      footer={null}
    >
      <Table 
        columns={ columnFormatter(isFault ? faultLogColumns : taskLogColumns) }  
        dataSource={ dataFormatter(data) }
      />
    </StyledModal>
  )
}

export default LogModal;