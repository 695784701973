import React from 'react'
import styled from 'styled-components'

import {color} from 'components/zensmart-design-system/shared/styles.js'

const Container = styled.div`
  background-color: ${color.nav.selected};
  min-height: 100vh;
  overflow: auto;
`

const Main = styled.main`
  max-width: 900px;
  margin: 0 auto;
  padding: 16px;
`

const Header = styled.header`
  display: flex;
  justify-content: center;

  margin-top: 2rem;
  margin-bottom: 2rem;
`

const ZenSmartPublicPage = ({children}) => (
  <Container>
    <Main>
      <Header>
        <img
          src="https://zensmart.ai/src/images/zen-logo.png"
          alt="ZenSmart Logo"
        />
      </Header>

      {children}
    </Main>
  </Container>
)

export default ZenSmartPublicPage