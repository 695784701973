import React, {useState} from 'react';
import {Header, Title} from 'components/primaries';
import {DataListPage} from "pages/DataListPage"
import {Panel} from 'components/primaries'
import {Modal, notification} from "antd";
import {ZenSmartAPI} from "../../../utils";
import {getCheckoutReason} from "../../../utils/apiRoutes";

const CheckoutReason = () => {
    const deleteCheckoutReason = (row, refreshData) => {
        Modal.confirm({
            'content': "Are you sure you want to delete this Checkout Reason?",
            onOk: () => {
                ZenSmartAPI.delete(getCheckoutReason(row.id))
                    .then((res) => {
                        notification.success({message: "Delete Checkout Reason successful!"})
                        refreshData();
                    })
                    .catch((res) => {
                        if (res.response.data) {
                            notification.error({message: res.response.data.message})
                        }
                    })
            },
        });
    }
    return (
        <>
            <Header>
                <Title>App Setup {">"} Checkout Reasons </Title>
            </Header>
            <Panel title="CHECKOUT REASONS">
                <DataListPage model="checkoutReasons" select={['id', 'reason', 'created_at', 'updated_at']}
                              link={"app/data-view/checkout-reasons/"} hidden={['id']} sort={"reason"} pageSize={15}
                              addListButton={{
                                  buttonLabel: "Add Checkout Reason",
                                  actionApi: "/api/v1/data-api/checkout-reasons",
                                  offset: 15,
                                  span: 3
                              }}
                              actionButtons={
                                  [{
                                      buttonLabel: "DELETE",
                                      buttonType: "danger",
                                      buttonAction: "normal",
                                      actionFunction: deleteCheckoutReason,
                                      reloadData: true
                                  }]
                              }
                />
            </Panel>
        </>
    )
}
export default CheckoutReason