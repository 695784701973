import styled from 'styled-components';

const FilterItem = styled.div`
  display: flex;
  h2 {
    color: #0B6BF2;
    font-weight: bold;
  }
  p {
    margin: 0 3px;
  }
  h3 {
    color: black;
    font-weight: bold;
    margin-bottom: 0;
  }
  svg {
    margin: 0.5rem 0.1rem;
  }
`

export default FilterItem;