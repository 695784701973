import React from 'react';
import FieldHeader from './FieldHeader';
import { Button } from '../../../../components/zensmart-design-system';
import FieldItem from './FieldItem';

const BucketFields = ({
  bucketFields = [],
  setDragBucketItem,
  addBucketField,
  editBucketField,
  deleteBucketField
}) => {
  
  return (
      <FieldHeader title='Bucket Fields'>
        <Button
          onClick={()=>addBucketField('number')}
          size='small'
          style={{
            marginLeft: 0,
            float: 'left',
            marginBottom: 3,
            width: 'calc(50% - 5px)',
            marginTop: 5
          }}
        >
          Number Bucket
        </Button>
        <Button
          onClick={()=>addBucketField('text')}
          size='small'
          style={{
            marginLeft: 0,
            float: 'right',
            marginBottom: 3,
            width: 'calc(50% - 5px)',
            marginTop: 5
          }}
        >
          Text Bucket
        </Button>
        
        {Object.keys(bucketFields).map((fieldKey, index) => (
          <FieldItem
            key={index}
            setDragAction={()=>setDragBucketItem(bucketFields[fieldKey])}
            stopDragAction={() => setDragBucketItem({})}
            editItem={() => editBucketField && editBucketField(fieldKey)}
            deleteItem={() => deleteBucketField && deleteBucketField(fieldKey)}
          >
            {bucketFields[fieldKey].name}
          </FieldItem>
        ))}
      </FieldHeader>
  )
}

export default BucketFields