import React, { useState, useRef, useEffect } from 'react';
import { List, Card, Row, Col, Button, notification} from 'antd';
import styled from 'styled-components';
import { color } from 'components/zensmart-design-system/shared/styles.js'
import moment from 'moment';
import { assignFaultRoute } from "utils/apiRoutes"
import UpdateFaultModal from './UpdateFaultModal';
import FaultFeedbackModal from './FaultFeedbackModal'
import { ZenSmartAPI, notifyAPIErrorResponse } from 'utils';
import { useSelector } from 'react-redux';
import FaultLogs from './FaultLogs';
import WorkRating from '../../partials/WorkRating'

const StyledList = styled(List)`
  .ant-list-pagination {
    padding: 20px;
  }
`

const FaultItem = styled(List.Item)`
  border-bottom: transparent !important;
`

const FaultCard = styled(Card)`
  background: #f2f4fd;
  width: 100%;
`

const FaultTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
`

const LogCount = styled(Button)`
  background: ${color.buttercup.normal};
  color: #fff;
  font-weight: 600;
`

const AcceptButton = styled(Button)`
  background: ${color.mountain_meadow.dark};
  color: #fff;
  &:hover {
    background: ${color.mountain_meadow.normal};
    color: #fff;
  }
`

const Timer = styled.div`
  margin: 18px 0 24px 0;
  color: #fff;
  .timer-number {
    background: #00C1D4;
    padding: 8px;
    border-radius: 5px 0 0 5px;
  }
  .timer-range {
    background: #000;
    padding: 8px;
    border-radius: 0 5px 5px 0
  }
`

const Status = styled.p`
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-left: 0.3rem;
`

const FaultList = props => {
  const { faults, reload } = props;

  const [ showUpdateFaultModal, setShowUpdateFaultModal ] = useState(false); 
  const [ selectedFault, setSelectedFault ] = useState(null);
  const [ selectedFaultLogs, setSelectedFaultLogs ] = useState(null);
  const [ showFeedbackModal, setShowFeedbackModal ] = useState(false)
  const [ feedbackFault, setFeedbackFault ] = useState(null);
  const [ feedbackPosted, setFeedbackPosted ] = useState(false);
  const [ updatedFaults, setUpdatedFaults ] = useState([]);
  
  const machine = useSelector(state => state.machine);
  const user = useSelector(state => state.user && state.user.authenticated ? state.user.details : null);

  const timersRef = useRef([]);

  useEffect(()=>{
    if(faults && faults.length > 0) {
      let intervalID;
      intervalID = startTimer();
      return () => clearInterval(intervalID);
    }
  },[faults]) // eslint-disable-line react-hooks/exhaustive-deps

  const onClickUpdateFault = fault => {
    setSelectedFault(fault);
    setShowUpdateFaultModal(true);
    setSelectedFaultLogs(null);
  }

  const showFaultFeedbackModal = (fault) => {
    setFeedbackFault(fault)
    setShowFeedbackModal(true)
  }

  const closeFaultFeedbackModal = () => {
    setShowFeedbackModal(false)
  }

  const onClickAccept = fault => {
    const payload = {
      engineer_id: user.id,
      fault_id: fault.id
    }
    ZenSmartAPI.post(assignFaultRoute, payload)
      .then(res => {
        if(res.data) {
          reload(val => !val)
          notification.success({
            message: 'Successfully accepted and assigned fault.'
          })
        }
      })
      .catch(err => { 
        notifyAPIErrorResponse(err.response.data, 'Accepting and assigning fault failed.')
      })
  }

  const showFaultLogs = fault => {
    setSelectedFaultLogs({
      fault_logs: fault.fault_log,
      fault_id: fault.id,
      toggle: selectedFaultLogs && selectedFaultLogs.toggle && selectedFaultLogs.fault_id === fault.id ? !selectedFaultLogs.toggle : true
    })
  }

  const startTimer = () =>
  {
    const faultTimerList = machine.maintenance.faults.data;
    let count = 0;
    const timerIntervalID = setInterval(() => {
      faultTimerList.forEach((fault,index) => {
        const startTime = moment(fault.started_at);
        const endTime =  fault.status !== 'completed' ? moment(fault.current_date).add(count,'second') : moment(fault.ended_at);

        const diff = moment.duration(endTime.diff(startTime));
        const days = diff.days() < 10 ? `0${diff.days()}` : diff.days();
        const hrs = diff.hours() < 10 ? `0${diff.hours()}` : diff.hours();
        const min = diff.minutes() < 10 ? `0${diff.minutes()}` : diff.minutes();
        const sec = diff.seconds() < 10 ? `0${diff.seconds()}` : diff.seconds();

        if(timersRef.current[fault.id])
          timersRef.current[fault.id].innerHTML = 
            `<div>
              <span class="timer-number">${ days } </span> <span class="timer-range">day(s)</span>
              <span class="timer-number">${ hrs } </span> <span class="timer-range">hrs</span>
              <span class="timer-number">${ min } </span> <span class="timer-range">min</span>
              <span class="timer-number">${ sec } </span> <span class="timer-range">secs</span>
            </div>`
      })
      count += 1;
    }, 1000)
    return timerIntervalID;
  }

  const getComment = (logs) => {
    const latestComment = logs.filter(log => log.comment !== null)
    return latestComment[latestComment.length-1]?.comment
  }

  const disableUpdateFaultButton = (fault) => {
    const faultReported = user.id === fault.user_id
    const faultAssigned = fault.engineer_id === user.id
    if (faultReported || faultAssigned) {
      return false
    } else {
      return true
    }
  }


  const hideUpdateFaultButton = (fault) => {
    const role = user && user.role && user.role.toLowerCase();
    const faultReported = fault.user_id === user.id
    if (fault.status !== 'completed') {
      if ((role === 'operator' && faultReported) || (role === 'operator' && fault.engineer_id === user.id)) {
        return true
      } else if (faultReported || (fault.engineer_id === user.id)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <>
    <UpdateFaultModal 
      show={showUpdateFaultModal}
      setShow={setShowUpdateFaultModal}
      fault={selectedFault} 
      setFault={setSelectedFault}
      reload={reload}
    />

    <FaultFeedbackModal
      fault={feedbackFault}
      isVisible={showFeedbackModal}
      closeModal={closeFaultFeedbackModal}
      setFeedbackPosted={setFeedbackPosted}
      reload={reload}
    />

    <StyledList pagination={true}>
    { faults && faults.map(fault => 
      <FaultItem key={fault.id}>
        <FaultCard>
          <Row>
            <Col lg={{span: 2}}>
              <LogCount size="small" shape="circle" onClick={() => showFaultLogs(fault) }>{fault.log_count}</LogCount>
            </Col>

            <Col lg={{span: 12}}>
              <FaultTitle>Case  {fault.id} on {fault.station}</FaultTitle>
              <div><strong>Assigned to:</strong> {fault.engineer_name ? fault.engineer_name : '--'}</div>
              <div><strong>Severity:</strong> {fault.severity}</div>
              { fault.fault_log && fault.fault_log.length > 0 &&
                <div>
                  <div><strong>Latest Comment:</strong> {getComment(fault.fault_log)}</div>
                  <div><strong>Updated by:</strong> {fault.fault_log[0].user} on {moment(fault.fault_log[0].updated_at).format('ddd D MMM YYYY - h:mm a').replace('-', 'at')}</div>
                </div>
              }
              <div><strong>Reported by:</strong> {fault.user} on {moment(fault.reported_at).format('ddd D MMM YYYY - h:mm a').replace('-', 'at')}</div>
            </Col>

            <Col lg={{span: 10}}>
              <Status>{fault.status.toUpperCase()}</Status>
              { hideUpdateFaultButton(fault) && <Button type="secondary" style={{ marginRight: '0.5rem'}} onClick={ () => onClickUpdateFault(fault) } disabled={disableUpdateFaultButton(fault)}>Update Fault</Button> }
              {!fault.engineer_id &&
                user && user.role && user.role.toLowerCase() === 'engineer' &&
                  <AcceptButton onClick={ () => onClickAccept(fault) }>Accept</AcceptButton>
              }
              <Timer ref={el => timersRef.current[fault.id] = el}></Timer>
              {fault.status === 'completed' && !fault.rating && !feedbackPosted && fault.user_id === user.id && <Button type="primary" ghost onClick={() => showFaultFeedbackModal(fault)}>Rate the work</Button>}
              { fault.status === 'completed' && fault.rating && <WorkRating rating={fault.rating} feedback={fault.feedback} />}
            </Col>
          </Row>
          { selectedFaultLogs && selectedFaultLogs.fault_id === fault.id && selectedFaultLogs.toggle && 
            <Row>
              <FaultLogs faultLogs={selectedFaultLogs.fault_logs}/>
            </Row>
          }
        </FaultCard>
      </FaultItem>
    )}
    </StyledList>
    </>
  )
}

export default FaultList;