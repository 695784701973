import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { Table } from 'antd'

const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;
    font-family: 'Rubik', sans-serif;
}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 0px;
}

`

const TableData = ({ loading, data, columns }) => {
  return (
    <>
      <TableStyling />

      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        bordered={true}
        size='middle'
        pagination={false}
        expandable={{
          defaultExpandAllRows: true
        }}
      />
    </>
  )
}

export default TableData
