import React from 'react'
import styled from 'styled-components'
import {Droppable, Draggable} from 'react-beautiful-dnd'

import {PROCESS} from 'types'
import {getColorFromType} from 'utils'
import {LibraryItem} from 'components/composites'

const Wrapper = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;

  &[data-rbd-droppable-id="[Pre Dispatch]stage-item,3"] {
    & > div {
      width: 150px;
    }
  }
`

const stageItems = [
  {type: PROCESS.TYPE.AUTOMATED, text: 'Automated Stage'},
  {type: PROCESS.TYPE.BARCODE, text: 'Barcode Stage'},
  {type: PROCESS.TYPE.IMPOSITION, text: 'Imposition'},
  {type: PROCESS.TYPE.PICKING, text: 'Picking'},
]

const StageItems = () => stageItems.map((stageItem, index) => {
  const dndID = `[${stageItem.type}]stage-item,${index}`
  return (
    <Droppable
      type={stageItem.type}
      droppableId={dndID}
      key={dndID}
    >
      {provided => (
        <Wrapper
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <Draggable
            draggableId={dndID}
            index={index}
          >
            {provided => (
              <LibraryItem
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                {...stageItem}
                color={getColorFromType(stageItem.type)}
                ref={provided.innerRef}
              />
            )}
          </Draggable>
          {provided.placeholder}
        </Wrapper>
      )}
    </Droppable>
  )
})

export default StageItems