import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, Col, Collapse, Form, notification, Row } from 'antd';
import { ZenSmartAPI } from 'utils';
import { AddressDetail, ComponentDetail, CustomerDetail, OrderDetail, PaymentDetail } from './CreateOrderHelperForms';
import moment from 'moment';

const InputHeader = styled.h1`
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    margin: 10px 0px;
    font-weight : bold;
`;

const PanelHeader = styled.h1`
  fontSize : 17px;
  fontWeight: bold
`;
const CreateOrderComponent = (props) => {
    const { Panel } = Collapse;
    const { actionValue, showModalForm, setShowModalForm, actionApi, refetchResource, successCallBack } = props;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const {
        form,
    } = props;

    const {
        getFieldDecorator,
        getFieldsValue,
        getFieldValue,
        validateFields,
        setFieldsValue,

    } = form;
    const [ifSameAddress, setIfSameAddress] = useState(false);
    const [addComponents, setAddComponents] = useState([]);

    useEffect(() => {
        if (!showModalForm) {
            setErrors([]);
            form.resetFields()
        }
    }, [showModalForm]);

    function createForm() {
        const handleSubmit = e => {
            e.preventDefault();
            validateFields((err, values) => {
                if (!err) {
                    const payload = values;
                    setLoading(true);
                    if (ifSameAddress) {
                        payload.billing_address = payload.shipping_address;
                    }

                    if (payload.payment && payload.payment.payment_date) {
                        payload.payment.payment_date = moment(payload.payment.payment_date).format('YYYY-MM-DD');
                    }

                    ZenSmartAPI.post(actionApi, payload)
                        .then((res) => {
                            setLoading(false);
                            notification.success({
                                message: 'Succesfully saved changes',
                            });
                            setShowModalForm(false);
                            if (successCallBack) {
                                successCallBack(res.data);
                            }
                            if (res.data.data && res.data.data.url) {
                                window.location.replace(`/app/${res.data.data.url}`);
                            } else {
                                if (refetchResource) {
                                    refetchResource();
                                }
                            }
                        })
                        .catch(error => {
                            const { response } = error;
                            setLoading(false);

                            if (response.status === 422) {
                                notification.error({
                                    message: 'There are errors in form.',
                                });
                                setErrors(response.data.errors);
                            }
                        });
                }
            });
        };

        const sameShippingAddress = (event) => {
            setIfSameAddress(event.target.checked);
        };

        return (
            <Form onSubmit={handleSubmit} autoComplete={'off'}>
                <Form.Item>
                    <Row style={{ marginTop: 30 }} align="center">
                        <Col span={12}>
                            <PanelHeader style={{ fontSize: 17, fontWeight: 'bold' }}>{actionValue.page_definitions.page_header.header_title}</PanelHeader>
                        </Col>
                        <Col span={12} gutter={15}>
                            <p style={{ textAlign: 'right' }}>
                                <Button style={{ marginRight: 10 }}
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading ? true : undefined}
                                >
                                    Save
                                </Button>
                                <Button
                                    type="danger"
                                    onClick={() => { setShowModalForm(false); }}
                                >
                                    Cancel
                                </Button>
                            </p>
                        </Col>
                    </Row>
                </Form.Item>
                {
                    actionValue &&
                    <Collapse style={{ marginTop: 20 }} defaultActiveKey={['1']}>
                        <Panel header={typeof actionValue.page_definitions.sections[0].section_header === 'string' ?
                            actionValue.page_definitions.sections[0].section_header.toUpperCase() : actionValue.page_definitions.sections[0].section_header} key="1">
                            <Row gutter={16}>
                                {actionValue.page_definitions.sections[0].section_data.map((row, key) =>
                                    <Col span={12}>
                                        <Col span={12}>
                                            <InputHeader>{row.label.toUpperCase()}</InputHeader>
                                        </Col>
                                        <Col span={12}>
                                            <p>{row.value}</p>
                                        </Col>
                                    </Col>,
                                )}
                            </Row>
                        </Panel>
                    </Collapse>

                }

                <Collapse style={{ marginTop: 20 }} defaultActiveKey={['1']}>
                    <Panel header="CUSTOMER DETAILS" key="1">
                        <Row gutter={16}>
                            {CustomerDetail(getFieldDecorator, errors, actionValue.page_definitions.sections.find(data => data.section_header === 'CUSTOMER DETAILS'))}
                        </Row>
                    </Panel>
                </Collapse>
                <Collapse style={{ marginTop: 20 }} defaultActiveKey={['1']}>
                    <Panel header="ORDER DETAILS" key="1">
                        <Row gutter={16}>
                            {OrderDetail(getFieldDecorator, errors, actionValue.page_definitions.sections.find(data => data.section_header === 'ORDER DETAILS'), actionValue.components)}
                        </Row>
                    </Panel>
                </Collapse>
                <Collapse style={{ marginTop: 20 }} defaultActiveKey={['1']}>
                    <Panel header="SHIPPING ADDRESS" key="1">
                        <Row gutter={16}>
                            {AddressDetail(getFieldDecorator, errors, actionValue.countries, actionValue.default_country_code, 'shipping_address')}
                        </Row>
                    </Panel>
                </Collapse>
                <Collapse style={{ marginTop: 20 }} defaultActiveKey={['1']}>
                    <Panel header="BILLING ADDRESS" key="1" extra={
                        <div onClick={event => {
                            // If you don't want click extra trigger collapse, you can prevent this:
                            event.stopPropagation();
                        }}>
                            <Checkbox onChange={sameShippingAddress}>Same as Shipping Address?</Checkbox>
                        </div>
                    }>
                        {!ifSameAddress &&
                            <Row gutter={16}>
                                {AddressDetail(getFieldDecorator,
                                    errors,
                                    actionValue.countries,
                                    actionValue.default_country_code,
                                    'billing_address')}
                            </Row>
                        }
                    </Panel>
                </Collapse>
                <Collapse style={{ marginTop: 20 }} defaultActiveKey={['1']}>
                    <Panel header="PAYMENT DETAILS" key="1">
                        <Row gutter={16}>
                            {PaymentDetail(getFieldDecorator, errors, actionValue.payment)}
                        </Row>
                    </Panel>
                </Collapse>
                <Collapse style={{ marginTop: 20 }} defaultActiveKey={['1']}>
                    <Panel header="COMPONENTS" key="1">
                        <Row gutter={16}>
                            {ComponentDetail(getFieldDecorator, errors, addComponents, setAddComponents, actionValue.components, getFieldValue, setFieldsValue)}
                        </Row>
                    </Panel>
                </Collapse>
            </Form>
        );
    }

    return (
        <div>
            {createForm()}
        </div>
    );
};

export default Form.create()(CreateOrderComponent);

