import axios from 'axios'

const http = axios.create({
  baseURL: process.env.REACT_APP_BARADUR_SERVER
})

http.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    //config.headers.Authorization = `Bearer ${token}`
  }
  config.timeout = 0
  return config
}, (error) => { Promise.reject(error) })

export default http