import React, { useCallback, useState } from 'react';

import ZenSmartAPI from '../utils/ZenSmartAPI';
import * as _ from 'lodash';
import { notification } from 'antd';

export default () => {
   const [errors, setErrors] = useState({})
   const [loading, setLoading] = useState(false);

   const request = useCallback((action, url, data = null) => {
      setErrors({});
      setLoading(true);

      return ZenSmartAPI[action](url, data).then(response => {
         setLoading(false);
         return response;
      }).catch(e => {
         setLoading(false);
         if (e && e.response) {

            if (_.get(e, 'response.status') === 422) {
               notification.error({
                  message: 'There are errors in form.',
               });
               setErrors(e.response.data.errors);
            }

            if (_.get(e, 'response.status') === 400) {
               notification.error({
                  message: e.response.data.message,
               });
            }

            if (_.get(e, 'response.status') > 499) {
               notification.error({
                  message: "An unexpected error occurred. Please contact your supervisor",
               });
            }

            return Promise.reject(e);
         }
      })

   }, [])

   return {
      request,
      loading,
      errors
   }
}