import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Checkbox, notification, Select, DatePicker, Input } from 'antd';
import { impositionRuleRoute, ticketDataFieldRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'
import debounce from 'lodash/debounce';
import { customDateInputs } from 'utils/customSelectOptionsInput'
import QualifierInput
    from '../../../components/composites/QualifierInput';
import _ from "lodash"
import moment from 'moment';
import { errorNoticationHandler } from 'utils/errorMessageHandler';

function UpdateForm(props) {
    const {
        form,
        updateImpositionRuleModal,
        setUpdateImpositionRuleModal,
        fetchParams,
        fetchData,
        formInitialValue,
        setFormInitialValue,
        impositionModalName,
        setRuleModalName,
        impositionInitData,
        fieldsValue,
        setValueField,
        fieldType,
        setFieldType,

        groupsValue,
        setGroupsValue,
        group,
        setGroup
    } = props

    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields,
    } = form


    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            const payload = getFieldsValue();
            if (!err) {
                setLoading(true)
                if (payload.qualifier.includes('datetime:custom')) {
                    payload.qualifier = `datetime:custom,${moment(payload.from).format('YYYY-MM-DD')},${moment(payload.to).format('YYYY-MM-DD')}`
                    delete payload.from;
                    delete payload.to;
                }
                const api = formInitialValue.id ? ZenSmartAPI.patch(`${impositionRuleRoute}/${formInitialValue.id}`, payload) : ZenSmartAPI.post(impositionRuleRoute, payload)
                api.then((res) => {
                    notification.success({ message: formInitialValue.id ? "Update imposition rule successful!" : "Add new imposition rule successful!" })
                    setLoading(false);
                    fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                    handleCancel()
                })
                    .catch((res) => {
                        setLoading(false);
                        errorNoticationHandler(res, "Something went wrong!")

                    })
            }
        });
    };

    const handleCancel = () => {
        setUpdateImpositionRuleModal(false)
        setFormInitialValue({})
        setRuleModalName("")
    }

    const onSearchField = debounce(value => {
        ZenSmartAPI.get(ticketDataFieldRoute(`${value}`), {})
            .then((res) => {
                setValueField(res.data.data)
            })
    }, 600);

    const fieldsComponent = (item) => {
        return (
            <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Enter Field (Click Suggestions to add each field)"
                optionFilterProp="children"
            // onSearch={onSearchField}
            // onChange={value => updateFieldValueType(value)}
            >

                {fieldsValue.map(data =>
                    <Select.Option value={data.id}>{data.name}</Select.Option>
                )}
            </Select>
        )
    }

    const groupsComponent = () => {

        return (
            <Select
                // showSearch
                style={{ width: "100%" }}
                placeholder="Select a Group"
                optionFilterProp="children"
                onChange={value => setGroup(value)}
            >
                {impositionInitData.groups.map(data =>
                    <Select.Option value={data}>{data}</Select.Option>
                )}
            </Select>
        )
    }

    // const updateFieldValueType = (value, key) => {
    //     // const getType = fieldsValue.find(data => data.id === value)?.field_type || '';
    //     // setFieldType(getType);
    // }

    // useEffect(() => {
    //     if (formInitialValue.id) {
    //         updateFieldValueType(formInitialValue.ticket_data_field_id)
    //     }
    // }, [formInitialValue]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Modal title={impositionModalName} visible={updateImpositionRuleModal} footer={[
                <Button
                    key="back"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button key="submit"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    Save
                </Button>,
            ]}>
                {updateImpositionRuleModal &&
                    <Form layout="vertical" onSubmit={handleSubmit}>
                        <Form.Item label="Ticket Data Fields:"
                        >
                            {getFieldDecorator(`ticket_data_field_id`, {
                                rules: [{
                                    required: true,
                                    message: "Please select a field"
                                }],
                                initialValue: formInitialValue.ticket_data_field_id ? formInitialValue.ticket_data_field_id : undefined

                            }
                            )(
                                fieldsComponent()
                            )}
                        </Form.Item>
                        <Form.Item label="Qualifier:"
                        >
                            {getFieldDecorator(`qualifier`, {
                                rules: [{
                                    required: true,
                                    pattern: new RegExp(/^$|.*\S+.*/gm),
                                    message: "Please select qualifier",
                                }],
                                initialValue: (fieldType === "datetime" || fieldType === "date") && _.get(formInitialValue, 'qualifier', "").includes('datetime') ?
                                    _.get(formInitialValue, 'qualifier', "").split(',')[0] : formInitialValue.qualifier ? formInitialValue.qualifier : undefined

                            }
                            )(
                                fieldType === "datetime" || fieldType === "date" ? customDateInputs(undefined, null, 'datetime:') :
                                    <QualifierInput
                                        value={_.get(getFieldsValue(), 'qualifier', undefined)}
                                        types={impositionInitData.qualifiers} />
                            )}
                        </Form.Item>

                        {(fieldType === "datetime" || fieldType === "date") && getFieldsValue().qualifier && getFieldsValue().qualifier.includes("datetime:custom") ?
                            <>
                                <Form.Item label="From:"
                                >
                                    {getFieldDecorator(`from`, {
                                        rules: [{
                                            required: true,
                                        }],
                                        initialValue: _.get(formInitialValue, 'qualifier', "").includes('custom') ? moment(_.get(formInitialValue, 'qualifier', "").split(',')[1]) : undefined

                                    }
                                    )(
                                        <DatePicker style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                                <Form.Item label="To:"
                                >
                                    {getFieldDecorator(`to`, {
                                        rules: [{
                                            required: true,
                                        }],
                                        initialValue: _.get(formInitialValue, 'qualifier', "").includes('custom') ? moment(_.get(formInitialValue, 'qualifier', "").split(',')[2]) : undefined

                                    }
                                    )(
                                        <DatePicker style={{ width: '100%' }} />
                                    )}
                                </Form.Item>

                            </>
                            : null
                        }

                        <Form.Item label="Imposition Server ID:"                        >
                            {getFieldDecorator(`imposition_server_id`, {
                                rules: [{
                                    required: true,
                                    message: "Please enter a server id"
                                }],
                                initialValue: formInitialValue.imposition_server_id ? formInitialValue.imposition_server_id :null,
                            }
                            )(
                                <Input placeholder={"Enter Imposition Server ID"}/>
                            )}
                        </Form.Item >
                        <Form.Item label="Group:">
                            {
                                getFieldDecorator(`group`, {
                                    rules: [{
                                        required: true,
                                        message: "Please select a group"
                                    }],
                                    initialValue: formInitialValue.group ? formInitialValue.group : undefined
                                })
                                    (
                                        groupsComponent()
                                    )}
                        </Form.Item>

                        <Form.Item label="Turn on:"
                        >
                            {getFieldDecorator(`active`, {
                                initialValue: formInitialValue.active ? formInitialValue.active : false,
                                valuePropName: "checked",

                            }
                            )(
                                <Checkbox />
                            )}
                        </Form.Item>
                    </Form>
                }
            </Modal>
        </div>
    )
}

const ImpositionForm = Form.create({
})(UpdateForm)

const ImpositionRuleUpdateModal = (props) => {
    const { updateImpositionRuleModal, impositionInitData, setUpdateImpositionRuleModal, fetchData,
        fetchParams, formInitialValue, setFormInitialValue, impositionModalName, setRuleModalName,
        fieldsValue, setValueField, fieldType, setFieldType, groupsValue, setGroupsValue,
        group, setGroup } = props
    return (
        <div>
            <ImpositionForm
                updateImpositionRuleModal={updateImpositionRuleModal}
                impositionInitData={impositionInitData}
                setFormInitialValue={setFormInitialValue}
                formInitialValue={formInitialValue}
                setUpdateImpositionRuleModal={setUpdateImpositionRuleModal}
                fetchData={fetchData}
                fetchParams={fetchParams}
                impositionModalName={impositionModalName}
                setRuleModalName={setRuleModalName}
                fieldsValue={fieldsValue}
                setValueField={setValueField}
                fieldType={fieldType}
                setFieldType={setFieldType}

                groupsValue={groupsValue}
                setGroupsValue={setGroupsValue}
                group={group}
                setGroup={setGroup}
            />
        </div>
    )
}

export default ImpositionRuleUpdateModal