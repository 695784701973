import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import moment from 'moment'
import {
  Button,
  Col,
  Collapse,
  Dropdown,
  Form,
  Icon,
  Menu,
  Modal,
  notification,
  Row,
  Spin,
  Table,
  Tag,
  Checkbox
} from 'antd';
import { DataViewActionForm } from 'components/sections';
import { Panel } from 'components/primaries';
import { useDataValue } from 'hooks';
import GetDataInput from 'hooks/useDataInput.js';
import { ZenSmartAPI } from 'utils';
import { color } from 'components/zensmart-design-system/shared/styles.js';
import { ReactComponent as EditIcon } from 'static/icons/edit-icon.svg';
import PrintThisComponent from 'components/composites/printTicket';
import DocumentMeta from 'react-document-meta';
import Frame from 'react-frame-component';

const Heading = styled.h2`
  font-weight: bold;
  font-size: 18px;
  color: ${color.heading};
`

const FieldLabel = styled.p`
  font-size: 12px;
  text-transform: uppercase;
`
const DueDateTag = styled(Tag)`
    padding : 5px 17px;
    font-size : 12px;
`
const RequiredMark = styled.span`
  color: ${color.red.normal};
  margin-right: 4px;
`

const Container = styled.div`
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 24px;
  word-break: break-all;
`

const EditButton = styled.button`
  padding-left: 8px;
  padding-right: 8px;
  background-color: transparent;
  border: none;
`
const Bold = styled.span`
font-weight: bold;
margin-right: 8px;
color: ${color.heading};
`
const dueDateColor = {
  later: "purple",
  today: "blue",
  tomorrow: "green"
}

const ResourceHeaderStyle = styled.p`
  margin-right : 20px;
  font-size : 16px
`

const styles = {
  newNoteTextArea: {
    width: '100%',
    padding: '8px',
    border: '1px solid lightblue',
    borderRadius: '5px',
  },
  saveNoteButton: {
    backgroundColor: 'green',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '8px 20px',
    marginLeft: '5px',
  },
  disabledButton: {
    backgroundColor: 'grey',
  },
  commentSection: {
    marginBottom: '10px',
    display: 'block',
  },
  boldStyle: {
    fontWeight: 'bold',
  }
}

function DetailsContent(props) {
  const { form, header, sections, actions, refetchResource, onSave, resourceHeaders, notes, setNotes, detailsForm, resourceEditable, ignoreWorkflow, setIgnoreWorkflow , setDetails} = props
  const {
    getFieldsValue,
    getFieldValue,
    setFields,
  } = form

  const { is_workflows_enabled, transaction_workflow_resources, resource_name } = detailsForm

  const {getDataValue, showPrint, printContent} = useDataValue()
  const params = new URLSearchParams(window.location.search);
  const [isEditing, setEditing] = useState(params.get('edit') === "true" ? true : false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSendingAction, setSendingAction] = useState(false)
  const history = useHistory()
  const [showModalForm, setShowModalForm] = useState(false)
  const [modalFormValue, setModalFormValue] = useState({ action_api: null, action_value: null })
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dueDate, setDueDate] = useState(null)
  const [htmlContent, setHtmlContent] = useState(null)
  const [intervalCallAction, setIntervalCallAction] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [newNote, setNewNote] = useState('');
  const [isNoteSaveButtonDisabled, setIsNoteSaveButtonDisabled] = useState(true);
  const [notePage, setNotePage] = useState(1);
  const notePageSize = 5;

  const setErrors = (fieldName, errors) => {
    setFields({
      [fieldName]: {
        value: getFieldValue(fieldName),
        errors
      }
    })
  }

  const toggleEditing = useCallback(isEditing => () => {
    setEditing(isEditing)
    // eslint-disable-next-line
  }, [isEditing])

  const handleSubmit = (evt) => {
    evt.preventDefault()
    // check if resource is workflows enabled and if the resource is included in the workflow_related_resources
    if(is_workflows_enabled && transaction_workflow_resources.includes(resource_name)) {
      setModalVisible(true)
    } else {
      submitForm()
    }
  }

  const submitForm = useCallback(() => {
    const values = getFieldsValue()
    let dueDate = '';
    const form = {
      setErrors,
      submitting() {
        // Clear the errors.
        Object.keys(values).forEach(resourceKey => {
          const value = values[resourceKey]
          Object.keys(value).forEach(field => {
            const fieldName = `${resourceKey}.${field}`
            if (field == 'due_date') {
              dueDate = moment(value.due_date).format("ddd MMM D, YYYY")
            }
            setErrors(fieldName, null)
          })
        })
        setSubmitting(true)
      },
      finished() {
        setEditing(false)
        setDueDate((dueDate !== null ? dueDate : null))
        setSubmitting(false)
      },
      failed() {
        setSubmitting(false)
      }
    }

    onSave(values, form)
    // eslint-disable-next-line
  }, [onSave, ignoreWorkflow])


  const getGroupFields = useCallback(field => {
    const fieldValue = isEditing && !field.read_only
      ? <GetDataInput field={field} form={form} />
      : getDataValue(field)

    const requiredMark = isEditing && field.required
      ? <RequiredMark>*</RequiredMark>
      : null

    return (
      <Row key={field.field} gutter={[0, 16]}>
        <Col span={12}>
          <FieldLabel style={{ fontWeight: "bold" }}>{requiredMark}{field.label}</FieldLabel>
        </Col>
        <Col span={12}>{fieldValue}</Col>
      </Row>
    )
    // eslint-disable-next-line
  }, [isEditing])

  const detailEditButton = isSendingAction || isEditing ? null : (
    <EditButton
      type="button"
      onClick={toggleEditing(true)}
    >
      <EditIcon
        width="14px"
        fill={color.body}
      />
    </EditButton>
  )

  const editingButtons = isEditing ? (
    <Col>
      <Row type="flex" gutter={[8, 0]}>
        <Col>
          <Button
            loading={isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </Col>

        <Col>
          <Button
            type="danger"
            disabled={isSubmitting}
            onClick={toggleEditing(false)}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Col>
  ) : null

  const fetchModalForm = (actions) => {
    setLoading(true)
    setHtmlContent(null)
    ZenSmartAPI.get(actions.api)
      .then((res) => {
        if(_.isObjectLike(res.data) === false && res.data.includes('html')) {
          setHtmlContent(res.data);
          setTimeout(() => setLoading(false), 3000)
          return;
       }
        if (res.data.file_url) {
          setFile(res.data)
          setShowModalForm(false)

        }
        else {
          setModalFormValue({ ...modalFormValue, action_value: res.data, action_api: actions.api })
          setShowModalForm(true)
        }
        setLoading(false)

      })
      .catch((res) => {
        if (_.get(res, 'response.data.message')) {
          notification.error({
            message: _.get(res, 'response.data.message')
          });
        }
        else {
          notification.error({
            message: 'An error has occurred'
          });
        }
        setLoading(false)
      })
  }
  const handleResourceAction = actionMenu => {
    const { action } = actionMenu.item.props

    if (action.promptable) {
      Modal.confirm({
        'content': action.prompt_message,
        onOk: () => {
          runAction(action)
        },
      });
    } else {
      runAction(action)
    }

  }

  const runAction = (action) => {
    if (action.url_type === 'newtab') {
      return;
    }
    if (action.url_type === "ui") {
      history.push(`${action.api}`)
      return;
    }
    if (action.request_method === "GET") {
      fetchModalForm(action)
    }
    else {
      setSendingAction(true)
      setHtmlContent(null)
      ZenSmartAPI.put(action.api, action.payload)
        .then((response) => {
          // Due to format differences in different API responses
          if (!response.data.data) {
            response = response.data;
          } else {
            response = response.data.data;
          }
          if(_.isObjectLike(response) === false && response.includes('html')) {
             setHtmlContent(response);
             setTimeout(() => setSendingAction(false), 3000)
             return;
          }
          if (_.isObject(response) && response.external_app_link) {
            window.location = response.url;
            setSendingAction(false)
            refetchResource()
            return;
          }


          if (_.isObject(response) && response.redirect_link) {
            const urlLastSegment = action.api.substring(action.api.lastIndexOf('/') + 1)

            if (urlLastSegment && urlLastSegment === 'delete') {
              notification.success({
                message: 'Delete successful'
              })
            }

            history.push({ pathname: `/${response.url}` })
            return;
          }


          if (_.isObject(response) && response.download_link) {
            setFile({
              file_url: response.url,
            })
            setSendingAction(false)
            refetchResource()
            return;
          }

          if (_.isObject(response) && response.file_url) {
            setFile(response)
            setSendingAction(false)

            return;
          }
          if (typeof actionMenu.item === 'undefined') {
            if(response?.use_osi){
              window.location = response.osi_path;
              setTimeout(() => { window.location.assign(response.osi_path) }, 1000);
              notification.success({
                message: (response.message) ? response.message : `Success!`
              })
              setSendingAction(false)
              return;
            }
            if (response.url) {
              history.push({ pathname: `/${response.url}` })
            }
            notification.success({
              message: (response.message) ? response.message : `Success! Reloading view...`
            })
          }
          else {
            const { action } = actionMenu.item.props
            notification.success({
              message: `"${action.label}" action successfully made for this item.`
            })
          }
          setSendingAction(false)
          refetchResource()
        })
        .catch(err => {

          setSendingAction(false)

          if (err?.response?.data?.message || err.message) {
            notification.error({
              message: err?.response?.data?.message ?? err.message
            })
            return
          }
        })
    }
  }

  const actionMenuHandler = params => {
    return params.map(param => {
      if (param.url_type === 'interval') {
        if (intervalCallAction === false)
          setIntervalCallAction(param.api);
      }
      else if (param.url_type === 'newtab') {
        return <Menu.Item
          key={param.label}
          action={param}
        >
          <a href={param.api} target="_blank">{param.label}</a>
        </Menu.Item>
      } else {
        return <Menu.Item
          key={param.label}
          action={param}
          disabled={param.disabled}
        >
          {param.label}
        </Menu.Item>
      }
    })
  }

  const actionMenu = actions ? (
    <Menu onClick={handleResourceAction}>
      {actionMenuHandler(actions)}
    </Menu>
  ) : null

  const actionButtons = actions && !isEditing ? (
    <Col>
      <Dropdown
        overlay={actionMenu}
        trigger={['click']}
        placement="bottomRight"
        disabled={isSendingAction}
      >

        {loading ?
          <Spin />
          : <Button loading={isSendingAction}>
            Actions <Icon type="down" />
          </Button>}

      </Dropdown>
      {modalFormValue.action_value ?
        (modalFormValue.action_value.special_form && (modalFormValue.action_value.special_form === "manage_permissions"
           || modalFormValue.action_value.special_form === "manage_roles")
        ?
        <DataViewActionForm showModalForm={showModalForm} detailsForm={detailsForm} refetchResource={refetchResource} actionValue={modalFormValue.action_value} actionApi={modalFormValue.action_api} setShowModalForm={setShowModalForm} />
        :
        modalFormValue.action_value.special_form && modalFormValue.action_value.special_form === "create_component"
        ?
        <DataViewActionForm showModalForm={showModalForm} detailsForm={detailsForm} refetchResource={refetchResource} actionValue={modalFormValue.action_value} actionApi={modalFormValue.action_api} setShowModalForm={setShowModalForm} />
        :
        modalFormValue.action_value.special_form && modalFormValue.action_value.special_form === "view_model_audit"
        ?
        <DataViewActionForm showModalForm={showModalForm} detailsForm={detailsForm} refetchResource={refetchResource} actionValue={modalFormValue.action_value} actionApi={modalFormValue.action_api} setShowModalForm={setShowModalForm} />
        :
        modalFormValue.action_value.special_form && modalFormValue.action_value.special_form === "reorder_transaction"
        ?
        <DataViewActionForm showModalForm={showModalForm} detailsForm={detailsForm} refetchResource={refetchResource} actionValue={modalFormValue.action_value} actionApi={modalFormValue.action_api} setShowModalForm={setShowModalForm} />
        :
        <Modal
          title=""
          style={{ top: 20 }}
          visible={showModalForm}
          width={1300}
          centered
          footer={null}
          onCancel={() => setShowModalForm(false)}
        >
          <DataViewActionForm showModalForm={showModalForm} detailsForm={detailsForm} refetchResource={refetchResource} actionValue={modalFormValue.action_value} actionApi={modalFormValue.action_api} setShowModalForm={setShowModalForm} />
        </Modal>
        )
        
        : null}
      {file ?
        <PrintThisComponent url={file.file_url} width={file.width} height={file.height} />
        : null}
    </Col>
  ) : null

  const rerouteOnClick = record => ({
    onClick(e) {
      if (e.currentTarget.querySelectorAll('.detail-field-button').length === 0) {
        const url = `/app/data-view/${record.resourceName}/${record.resourceID}`;
        e.ctrlKey || e.metaKey ? window.open(url) : history.push({ pathname: url });
      }
    }
  })

  const fetchPagination = async (url,section) => {
    try {
      const response = await ZenSmartAPI.get(url);
      const updatedSections = detailsForm.page_definitions.sections.map(sectionData => {
        if (sectionData?.section_header === section.section_header) {
          return response.data; 
        }
        return sectionData;
      }).filter(sectionData => sectionData !== null);

      setDetails({
        ...detailsForm,
        page_definitions: {
          ...detailsForm.page_definitions,
          sections: updatedSections
        }
      });
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error("There was an error fetching the data", error);
    }
  }

  const onChangePagination = (page,section) => {
    setLoading(true)
    const url = `${section.path}?page=${page}`;
    fetchPagination(url,section)
  }

  const handleNoteTextChange = (e) => {
    const text = e.target.value;
    setNewNote(text);
    setIsNoteSaveButtonDisabled(text === ''); // Disable the button if text is empty
  }
  const handleSaveNote = () => {
    // Check if the new note is not empty
    if (newNote.trim() !== '') {
      ZenSmartAPI.put(`/api/v1/data-api/${detailsForm.resource}/${detailsForm.id}/add-note`, { note: newNote })
          .then(response => {
            const newNoteData = response.data;
            setNotes(newNoteData);
            notification.success({
              message: 'Note added Successfully!',
            });
          })
          .catch(error => {
            console.log(error);
            notification.error({
              message: _.get(error, 'response.data.message', 'Something went wrong!'),
            })
          });
      // Clear the new note input field after saving
      setNewNote('');
      handleNoteTextChange({ target: { value: '' } });
    }
  };
  const loadMoreNotes = () => {
    setNotePage(notePage + 1);
  };
  const renderNotes = () => {
    const allNotes = notes.map((data, key) => (
        <div key={key} style={styles.commentSection}>
          <div>
            <p>
              <span >{data.user} @ </span> {data.created_at}
            </p>
          </div>
          <div>
            <p><span style={styles.boldStyle}>{data.comment}</span></p>
          </div>
        </div>
    ));
    const startIdx = 0;
    const endIdx = notePage * notePageSize;
    return allNotes.slice(startIdx, endIdx);
  };

  const details = sections.map(section => {
    let content = null

    switch (section.render_as) {
      case 'custom-notes': {
        content = (
            <div style={{ marginTop: '10px' }}>
              {notes && notes.length > 0 ? (
                  <div>
                    {renderNotes()}
                  </div>
              ) : (
                  <p>No notes available.</p>
              )}
              {notes && notes.length > notePage * notePageSize ? (
                  <div style={{ textAlign: 'left' }}>
                    <Button onClick={loadMoreNotes}>Load More</Button>
                  </div>
              ) : null}
              {/* Add Text Area and Save Button */}
              <div style={{marginTop: '20px', display: 'block', alignItems: 'center'}}>
            <textarea
                rows="2"
                style={{
                  ...styles.newNoteTextArea,
                  marginBottom: '0'
                }}
                placeholder='Add a new Note...'
                value={newNote}
                onChange={handleNoteTextChange}
            />
                <button
                    onClick={handleSaveNote}
                    disabled={isNoteSaveButtonDisabled}
                    style={
                      isNoteSaveButtonDisabled
                          ? { ...styles.saveNoteButton, ...styles.disabledButton }
                          : styles.saveNoteButton
                    }
                >Save</button>
              </div>
            </div>
        );
        break;
      }
      case 'panel': {
        const { section_data: fields } = section

        const attachResourceInfo = field => ({
          ...field,
          resource_name: section.resource_name,
          resource_id: section.id
        })

        const maxFieldCount = Math.round(fields.length / 2)

        let hasColumn = fields.some(field => 'column' in field && field.column == 1);
        let leftGroup, rightGroup;
        if(hasColumn) {
          leftGroup = fields.filter(field => field.column == 1);
          rightGroup = fields.filter(field => field.column == 2);
        } else {
          leftGroup = fields.slice(0, maxFieldCount)
          rightGroup = fields.slice(maxFieldCount)
        }


        const leftGroupFields = leftGroup
          .map(attachResourceInfo)
          .map(getGroupFields)

        const rightGroupFields = rightGroup
          .map(attachResourceInfo)
          .map(getGroupFields)

        content = (
          <Row gutter={16}>
            <Col span={12}>{leftGroupFields}</Col>
            <Col span={12}>{rightGroupFields}</Col>
          </Row>
        )
        break
      }

      case 'table': {
        const columns = section.table_headers.map(columnName => ({
          title: columnName,
          dataIndex: columnName,
          key: columnName,
          width: 180,
          onCell: rerouteOnClick,
          render: field => getDataValue(field)
        }))

        const dataSource = section.section_data.map(row => (
          row.section_data.reduce((fields, field) => ({
            key: row.id,
            resourceName: row.resource_name,
            resourceID: row.id,
            ...fields,
            [field.label]: {
              ...field,
              resource_name: row.resource_name,
              resource_id: row.id,
            }
          }), {})
        ))

        content = (
          <>{
            section.paginated ? 
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{
                total: section.total,
                defaultCurrent: 1,
                defaultPageSize : section.per_page,
                maxPages:section.last_page,
                onChange: (page) => onChangePagination(page,section),
              }}
              loading={loading}
              // onRow={rerouteOnClick}
              scroll={{ x: 'max-content' }}
            />
          : 
            <Table
              columns={columns}
              dataSource={dataSource}
              // onRow={rerouteOnClick}
              scroll={{ x: 'max-content' }}
            />
          }
          
          </>
        )
        break;
      }

      default: break // no-op
    }

    return (
      <Container key={section.section_header}>
        <Collapse defaultActiveKey={section.section_header}>
          <Collapse.Panel
            header={section.section_header}
            key={section.section_header}
          >
            {content}
            {showPrint ? <><PrintThisComponent url={printContent.content} type={printContent.type} /></> : null}
          </Collapse.Panel>
        </Collapse>
      </Container>
    )
  })

  useEffect(() => {
    if (htmlContent) {
        setTimeout(function () {
            window.frames["iframe"].focus();
            window.frames["iframe"].contentWindow.print();
        }, 1000);
    }
}, [htmlContent])

  useEffect(() => {
    if (intervalCallAction) {

      const interval = setInterval(() => {
        ZenSmartAPI.get(intervalCallAction)
          .then((res) => {
          if(res.data.status === 'success')
          {
            if(res.data.action_type === 'link')
            {
              window.open(res.data.action_value, '_blank');
            }
          }
          })

      }, 5000);
      return () => clearInterval(interval);
    }
  }, [intervalCallAction]);



  const PrintFrame = (body) => {
    return (
        <Frame style={{ display: 'none' }} id='iframe' initialContent={body}></Frame>
    )
  }

  function ucwords(str) {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  function ConfirmWorkflowModal() {
    return (
      <Modal
        title="Workflow rules execution"
        visible={modalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => {
          setModalVisible(false)
          submitForm()
        }
      }
      >
        <Checkbox checked={ignoreWorkflow} onChange={(e) => setIgnoreWorkflow(e.target.checked)}>
          There are workflow rules tied to this resource. They will be executed on save. Check the box if you would like to ignore them.
        </Checkbox>
      </Modal>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      {ConfirmWorkflowModal()}
      <Container>
        <Row type="flex" justify="space-between">
          <Col>
            <Row type="flex" align="middle">
              <Heading>{header}</Heading>
              { (resourceEditable !== false) && detailEditButton}
            </Row>

            {detailsForm && detailsForm.page_definitions && detailsForm.page_definitions.resource_note &&
              <div style={{ paddingTop: 10 }}>
                <h1 style={{ fontWeight: "bold" }}>Notes:</h1>
                {detailsForm.page_definitions.resource_note.map(data => {
                  return <p>{data}</p>
                })}
              </div>
            }
          </Col>
          {actionButtons}
          {editingButtons}
        </Row>
        {resourceHeaders ?
          <Row type="flex" align="middle" style={{ paddingTop: 20 }}>
            {
              resourceHeaders.map((data, key) =>
                <div>
                  {data.label === "Due Date" ?
                    <>
                      <ResourceHeaderStyle>
                        <Bold>{data.label}: </Bold><DueDateTag color={dueDateColor[data.value.due_date_text.toLowerCase()] ?
                          dueDateColor[data.value.due_date_text.toLowerCase()] : "red"}
                          key={data.value.due_date_text}>
                          {data.value.due_date_text.toUpperCase()}
                        </DueDateTag>
                        <span> {(dueDate) ? dueDate : data.value.formatted_due_date} </span>
                      </ResourceHeaderStyle>
                    </> : data.label === "Current Stage" ?
                          <>
                            <ResourceHeaderStyle>
                              <Bold>{data.label}:</Bold>
                              <span> {ucwords(data.value)}</span>
                            </ResourceHeaderStyle>
                          </> :
                    <>
                      <ResourceHeaderStyle>
                        <Bold>{data.label}:</Bold>
                        <span> {data.value}</span>
                      </ResourceHeaderStyle>
                    </>
                  }
                </div>
              )
            }
          </Row>
          : null}
      </Container>
      {details}
      {htmlContent && PrintFrame(htmlContent)}
    </Form>
  )
}

const DetailsForm = Form.create({ name: 'DetailsForm' })(DetailsContent)
function Details(props) {
  const { details, onSave, refetchResource, ignoreWorkflow, setIgnoreWorkflow, setDetails} = props
  const [notes, setNotes] = useState([])
  const title = `${details.resource} VIEW`
  const meta = {
    title: `${details.resource} ${details.name}`,
  };

  useEffect(() => {
    const notesSection = details.page_definitions.sections.find(item => item?.section_header === 'NOTES');
    const notesData = notesSection?.section_data || [];
    setNotes(notesData);
  }, [details])

  return (
    <DocumentMeta {...meta}>
      <Panel title={title}>
        <DetailsForm
          header={details.page_definitions.page_header.header_title}
          sections={details.page_definitions.sections.filter(Boolean)}
          notes={notes}
          actions={details.page_definitions.resource_actions}
          resourceHeaders={details.page_definitions.resource_headers}
          resourceEditable={details.resource_editable}
          refetchResource={refetchResource}
          onSave={onSave}
          detailsForm={details}
          ignoreWorkflow={ignoreWorkflow}
          setIgnoreWorkflow={setIgnoreWorkflow}
          setDetails={setDetails}
          setNotes={setNotes}
        />
      </Panel>
    </DocumentMeta>
  )
}

export default Details
