import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, Input, Modal, Form, Button, Table } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenSmartAPI } from 'utils'
import { getMatchOverview, getAllDepartments , clearPigeonHole} from "utils/apiRoutes"
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux'

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}


.ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}

`

const InputHeader = styled.span`
  font-weight: bold;
`

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;

const MatchOverview = (props) => {
    const [itemData, setItemData] = useState([])
    const [departments, setDepartments] = useState([])
    const [originalData, setOriginalData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [selectedCheckBoxValue, setSelectedCheckBoxValue] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectAction, setSelectAction] = useState(undefined)
    const [formValues, setFormValues] = useState(null)
    const [queueRange,setQueueRange] = useState([]);
    const [visibleQueueRange,setVisibleQueueRange] = useState(false);
    const userData = useSelector((state) => state.user.details);
    const [canClearRange,setCanClearRange] = useState(false);

    const BoxRangeModal = ({ data }) => {
        const columns = [
            {
                title: 'Group',
                dataIndex: 'group',
                key: 'group',
            },
            {
                title: 'Box Range Start',
                dataIndex: 'box_range_start',
                key: 'box_range_start',
            },
            {
                title: 'Box Range End',
                dataIndex: 'box_range_end',
                key: 'box_range_end',
            },
            {
                title: 'Is Batch',
                dataIndex: 'is_batch',
                key: 'is_batch',
                render: (isBatch) => (isBatch ? 'Yes' : 'No'),
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Button
                    size="small"
                    type="primary"
                    disabled={!canClearRange}
                    onClick={() => confirmClearMatchUsingRange(record)}
                    >
                    Clear
                    </Button>
                ),
            },
        ];
        return <Table columns={columns} dataSource={queueRange} />    
    };

    const checkPermission = (permission) => {
        if (userData?.should_authorize && permission && !userData?.permissions.find(p => p === permission)) {
            return false;
        }
        return true;
    }

    const confirmClearMatchUsingRange = (record) => {
        if(record.message){
            Modal.confirm({
                content: record.message,
                onOk: () => {
                    clearMatchUsingRange(record)
                },
                onCancel: () => {}
            });
        }else{
            clearMatchUsingRange(record)
        }
    }

    const clearMatchUsingRange = (record) => {
        let pigeon_holes = record.pigeon_holes;
        ZenSmartAPI.post(clearPigeonHole('match-range'),{pigeon_holes})
        .then((res) => {
            fetchData()
            setVisibleQueueRange(false)
            notification.success({ message: "PigeonHole has been successfully emptied." })
        })
        .catch((res) => {
            setVisibleQueueRange(false)
            notification.error({ message: "PigeonHole clearance failed." })
        })
    }

    const fetchDepartments = () => {

        ZenSmartAPI.get(getAllDepartments)
            .then((res) => {
                setDepartments(res.data.data)

            })
            .catch((res) => {
            })

    }
    const hideModal = () => {
        setVisibleQueueRange(false)
    }

    const fetchData = (deparment_id = null) => {

        setLoading(true)
        setSelectedCheckBoxValue([])
        ZenSmartAPI.get(getMatchOverview(deparment_id))
            .then((res) => {
                setLoading(false)
                setItemData(res.data)
                setOriginalData(res.data)
            })
            .catch((res) => {
                setLoading(false)
            })
    }

    const actionChange = (value) => {

        if(value === 'clear_all'){
            Modal.confirm({
                content: `Are you sure you want to empty all pigeonhole?`,
                onOk: () => {
                    ZenSmartAPI.post(clearPigeonHole('match-all'))
                    .then((res) => {
                        fetchData()
                        notification.success({ message: "PigeonHole has been successfully emptied." })
                    })
                    .catch((res) => {
                        notification.error({ message: "PigeonHole clearance failed." })
                    })
                },
                onCancel: () => {}
            });
        }
        if(value === 'clear_range'){
            setLoading(true);
            ZenSmartAPI.get(clearPigeonHole('match-range'))
            .then((res) => {
                setLoading(false);
                setQueueRange(res.data);
                setVisibleQueueRange(true)
            })
            .catch((res) => {
                setLoading(false);
            })
        }

    }

    const searchOnArray = (anItemValue, value) => {
        const ifValueExist = anItemValue.some(data => {
            if (
                (data.line_id && data.line_id.toString().toLowerCase().includes(value.toLowerCase())) ||
                (data.product_code && data.product_code.toString().toLowerCase().includes(value.toLowerCase())) ||
                (data.current_stage && data.current_stage.toString().toLowerCase().includes(value.toLowerCase())) ||
                (data.submissions && data.submissions.some(submission => submission.block_barcode && submission.block_barcode.toString().toLowerCase().includes(value.toLowerCase())))
            ) {
                return true
            }
            else {
                return false
            }
        })

        return ifValueExist;
    }

    const searchOperation = debounce(async (value, source) => {
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                            false :
                            Array.isArray(anItemValue) ? searchOnArray(anItemValue, value) :
                                anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) :
                                    anItemValue.client_number ? anItemValue.client_number.toString().toLowerCase().includes(value.toLowerCase()) :
                                        anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
                return containsValue
            })
            setItemData(result)
        }
    }, 300)

    useEffect(() => {
        fetchData()
        fetchDepartments()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCanClearRange(checkPermission('can-clear-match-pigeon-hole'))
    }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps
 
    return (
        <>
            <Header>
                <Title>Pack & Ship {">"} Match Overview</Title>
            </Header>
            <Panel title="MATCH OVERVIEW">
                <div style={{ padding: "40px 0px" }}>
                    <Row type="flex" align="middle" justify="start" style={{ padding: 30 }}>
                        <InputStyle />
                        <Col span={4} style={{ padding: 10 }}>
                            <Heading>SEARCH</Heading>
                            <Input.Search style={{ marginTop: 10, width: '100%' }}
                                type="text"
                                placeholder="Filters.."
                                disabled={loading}
                                onChange={(value) => searchOperation(value.target.value, originalData)}
                                loading={loading}
                            />
                        </Col>
                        <Col span={3} offset={14} style={{ padding: 0 }}>
                            <Heading><br /></Heading>
                            <Select placeholder="Select Action" style={{ marginTop: 10, width: '100%' }} value={selectAction} onChange={(value) => actionChange(value)} >
                                <Option disabled={!canClearRange} value={'clear_all'} >Clear All Pigeonhole</Option>
                                <Option disabled={!canClearRange} value={'clear_range'} >Clear Pigeonhole Using Range</Option>
                            </Select>
                        </Col>
                    </Row>
                    <TableData itemData={itemData} loading={loading} style={{ padding: 40 }} selectedCheckBoxValue={selectedCheckBoxValue}
                        setSelectedCheckBoxValue={setSelectedCheckBoxValue} fetchData={fetchData} canClearRange={canClearRange} />
                </div>
                <>
                    <ModalStyle />
                    <Modal
                        title=""
                        centered
                        width={650}
                        visible={visibleQueueRange}
                        footer={null}
                        onCancel={hideModal}
                    >
                        <div style={{ padding: 20 }}>
                            <BoxRangeModal />
                        </div>
                    </Modal>
                </>

            </Panel>
        </>
    )
}

export default MatchOverview