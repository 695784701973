import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import {
  Row,
  Col,
  Form as AntForm,
  Input,
  Button,
  Alert as AntAlert,
  notification
} from 'antd'

import { Panel } from 'components/primaries'
import '../../../node_modules/text-security/text-security.css'
const LOGIN = Symbol('SIGN IN')
const FORGOT = Symbol('FORGOT PASSWORD')

const Header = styled.h1`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
`

const Text = styled.p`
  font-size: 14px;
  margin-bottom: 24px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
`

const Field = styled.div`
  margin-bottom: 16px;
`

const LinkButton = styled(Button)`
  padding: 0;
  line-height: 1.5;
  height: auto;
  margin-bottom: 4px;
`

const Form = styled(AntForm)`
  margin: 2rem 4rem;
  display: flex;
  flex-direction: column;
`

const InputPassword = styled(Input)`
font-family: text-security-disc;
/* Use -webkit-text-security if the browser supports it */
-webkit-text-security: disc;
`

const Alert = styled(AntAlert)`
  margin-bottom: 16px;
`

const FORM_STATUS = {
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  SUBMITTING: 'SUBMITTING',
}

function getAlertType(formStatus) {
  switch (formStatus) {
    case FORM_STATUS.FINISHED:
      return 'success'

    case FORM_STATUS.FAILED:
      return 'error'

    default:
      return null
  }
}

const emailOrBarcodeOptions = {
  rules: [{ type: 'email', required: true }]
}

function SignIn(props) {
  const { form, switchToForgotPasswordScreen, signInHandler } = props

  const {
    getFieldDecorator,
    getFieldsValue,
    getFieldError,
    isFieldTouched,
  } = form

  const [formStatus, setFormStatus] = useState(null)
  const [formMessage, setFormMessage] = useState(null)
  const [isEmailSignIn, setIsEmailSignIn] = useState(false)

  const hasInvalidEmailError = getFieldError('email_or_barcode') === undefined
  const isEmailOrBarcodeTouched = isFieldTouched('email_or_barcode')
  const isEmail = hasInvalidEmailError && isEmailOrBarcodeTouched

  useEffect(() => {
    setIsEmailSignIn(isEmail)
  }, [isEmail])

  const handleSubmit = useCallback((evt) => {
    evt.preventDefault()

    const form = {
      submitting() {
        setFormStatus(FORM_STATUS.SUBMITTING)
        setFormMessage(null)
      },

      finished(successDetails) {
        setFormStatus(FORM_STATUS.FINISHED)
        setFormMessage(successDetails)
      },

      failed(errorDetails) {
        setFormStatus(FORM_STATUS.FAILED)
        setFormMessage(errorDetails)
      }
    }

    const values = getFieldsValue()
    const credentials = {}

    if (isEmailSignIn) {
      credentials.email = values.email_or_barcode
      credentials.password = values.password
    } else {
      credentials.barcode = values.email_or_barcode
    }

    signInHandler(credentials, form)
    // eslint-disable-next-line
  }, [isEmailSignIn])

  const submitting = formStatus === FORM_STATUS.SUBMITTING

  const linkBtn = (
    <LinkButton
      type="link"
      htmlType="button"
      onClick={() => {
        switchToForgotPasswordScreen('')
      }}
      disabled={submitting}
    >
      I forgot my password
    </LinkButton>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Header>Sign In to ZenSmart</Header>

      {formMessage ? (
        <Alert
          closable={true}
          showIcon={true}
          type={getAlertType(formStatus)}
          {...formMessage}
        />
      ) : null}

      <Field>
        <Label>EMAIL ADDRESS / BARCODE</Label>
        {getFieldDecorator('email_or_barcode', emailOrBarcodeOptions)(
          <Input disabled={submitting} autoComplete="off" />
        )}
      </Field>

      {isEmailSignIn ? (
        <Field>
          <Row type="flex" align="middle" justify="space-between">
            <Label>PASSWORD</Label>
            {linkBtn}
          </Row>

          {getFieldDecorator('password')(<InputPassword type="text" disabled={submitting} autoComplete="off" />)}
        </Field>
      ) :
        <Row type="flex" justify="end">
          {linkBtn}
        </Row>
      }

      <Row type="flex" justify="center">
        <Button
          type="primary"
          htmlType="submit"
          loading={submitting}
        >
          Sign In
        </Button>
      </Row>
    </Form>
  )
}

const SignInForm = Form.create({ name: 'SignInForm' })(SignIn)

function ForgotPassword(props) {
  const { form, loginText, switchToLoginScreen, forgotPasswordHandler } = props
  const { getFieldDecorator, getFieldsValue } = form

  const [formStatus, setFormStatus] = useState(null)
  const [formMessage, setFormMessage] = useState(null)

  const handleSubmit = useCallback((evt) => {
    evt.preventDefault()

    const form = {
      submitting() {
        setFormStatus(FORM_STATUS.SUBMITTING)
        setFormMessage(null)
      },

      finished(successDetails) {
        setFormStatus(FORM_STATUS.FINISHED)
        notification.success({
          message: successDetails.message + '. ' + successDetails.description
        })
        switchToLoginScreen()
      },

      failed(errorDetails) {
        setFormStatus(FORM_STATUS.FAILED)
        setFormMessage(errorDetails)
      }
    }

    const payload = {
      ...getFieldsValue(),
      change_password_url: document.location.origin + '/change-password/:token'
    }

    forgotPasswordHandler(payload, form)
    // eslint-disable-next-line
  }, [])

  const submitting = formStatus === FORM_STATUS.SUBMITTING

  return (
    <Form onSubmit={handleSubmit}>
      <Header>Forgot your password?</Header>
      <Text>Enter your email address below to reset your password</Text>

      {formMessage ? (
        <Alert
          closable={true}
          showIcon={true}
          type={getAlertType(formStatus)}
          {...formMessage}
        />
      ) : null}

      <Field>
        <Label>EMAIL ADDRESS</Label>
        {getFieldDecorator("email", { initialValue: loginText && loginText })(
          <Input type="email" disabled={submitting} />
        )}
      </Field>

      <Row type="flex" justify="center">
        <Button
          type="primary"
          htmlType="submit"
          loading={submitting}
        >
          Reset Password
        </Button>
      </Row>

      <Row type="flex" justify="center">
        <Button
          type="link"
          htmlType="button"
          onClick={switchToLoginScreen}
          disabled={submitting}
        >
          Back to Sign In
        </Button>
      </Row>
    </Form>
  )
}

const ForgotPasswordForm = Form.create({ name: 'SignInForm' })(ForgotPassword)

function AuthForm(props) {
  const [authScreen, setAuthScreen] = useState(LOGIN)
  const [loginText, setLoginText] = useState(null)

  const toggleAuthScreen = useCallback((authScreen) => (loginInput) => {
    setAuthScreen(authScreen)
    setLoginText(loginInput)
  }, [])

  const { onSignIn, onResetPassword } = props

  let panelTitle = 'SIGN IN'
  let panelContent = (
    <SignInForm
      switchToForgotPasswordScreen={toggleAuthScreen(FORGOT)}
      signInHandler={onSignIn}
    />
  )

  if (authScreen === FORGOT) {
    panelTitle = 'FORGOT PASSWORD'
    panelContent = (
      <ForgotPasswordForm
        loginText={loginText}
        switchToLoginScreen={toggleAuthScreen(LOGIN)}
        forgotPasswordHandler={onResetPassword}
      />
    )
  }

  return (
    <Row type="flex" justify="center">
      <Col span={12}>
        <Panel title={panelTitle}>{panelContent}</Panel>
      </Col>
    </Row>
  )
}

export default AuthForm