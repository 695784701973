import React, { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row, Button } from 'antd';
import styled, { createGlobalStyle } from 'styled-components'
import { ZenSmartAPI } from 'utils'
import { returnToProductionExternalRoute } from 'utils/apiRoutes';


const PageStyling = createGlobalStyle`
.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    }
`

const ReturnToProductionPage = (props) => {
    const { id, token } = useParams()
    const [data, setData] = useState(false)
    const [headerMessage, setHeaderMessage] = useState("")
    const [message, setMessage] = useState("")
    const [ifLinkExpired, setIfLinkExpired] = useState(null)
    useEffect(() => {
        ZenSmartAPI.get(returnToProductionExternalRoute(id, token))
            .then((res) => {
                const result = res.data
                setData(true)
                setHeaderMessage("SUCCESS!")
                setMessage(res.data.message)
            }).catch((res) => {
                setData(true)
                if (res.response.status === 404 || res.response.status === 401) {
                    var base_url = window.location.origin
                    setHeaderMessage("404 - PAGE NOT FOUND")
                    setMessage("The page you're looking for might have been removed. Please click this link and log in your account.")
                    setIfLinkExpired(`${base_url}/app/data-view/orders/${id}`)
                }
                else {
                    setHeaderMessage("500 - INTERNAL ERROR")
                    setMessage(res.response.data.message)
                }
            })
        // console.log('id', token);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div>
            <PageStyling />
            {data &&
                <div>
                    <Row type="flex" align="middle" justify="center" style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: 10
                    }}>
                        <Col span="24" style={{ textAlign: "center" }}>
                            <h1 style={{ fontWeight: "bold", fontSize: "40px" }}>{headerMessage}</h1>
                            <p style={{ fontSize: "24px" }}>{message}</p>
                        </Col>
                        {ifLinkExpired &&
                            <Col span="24" style={{ textAlign: "center", padding: 20 }}>
                                <Button type="primary" onClick={() => window.location.href = ifLinkExpired} size="large" style={{}}>Go to link</Button>
                            </Col>
                        }
                    </Row>
                </div>
            }
        </div>
    )
}
export default ReturnToProductionPage
