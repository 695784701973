import React, { useState, useEffect } from 'react';
import { Table, Select, Typography, Button, Modal, Row, notification } from 'antd';
import { Label } from 'components/zensmart-design-system';
import RolesManageModal from '../RolesManageModal';
import { ZenSmartAPI } from 'utils';
import _ from 'lodash';
import 'antd/dist/antd.css';

const { Text } = Typography;
const { Option } = Select;

const UserRoleManage = ({ visible, onClose, user, userRoles, permissions, roles }) => {
    const [currentUserRoles, setCurrentUserRoles] = useState(userRoles);
    const [showRolesManageModal, setShowRolesManageModal] = useState(false);
    const [selectedRoleId, setSelectedRoleId] = useState(null);

    useEffect(() => {
        setCurrentUserRoles(userRoles);
    }, [userRoles]);

    const columns = [
        {
            title: 'Role Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span
                    onClick={() => {
                        onClose();
                        setSelectedRoleId(record.id);
                        setShowRolesManageModal(true);
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1890ff' }}
                >
                    {text}
                </span>
            ),
        },
        {
            title: 'Role Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <button onClick={() => removeRole(record)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-red-600 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                </button>
            ),
        },
    ];

    const addRole = (roleId) => {
        const roleToAdd = roles.find((role) => role.id === roleId);
        if (!currentUserRoles.some((role) => role.id === roleId)) {
            
            ZenSmartAPI.post(`/api/v1/roles/${roleId}/users/${user.id}`)
                .then((response) => {
                    setCurrentUserRoles([...currentUserRoles, roleToAdd]);
                })
                .catch((error) => {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An error occurred while adding role to user. Please try again.')
                    });
                }
            )
        }
    };

    const removeRole = (roleToRemove) => {

        ZenSmartAPI.delete(`/api/v1/roles/${roleToRemove.id}/users/${user.id}`)
            .then((response) => {
                setCurrentUserRoles(currentUserRoles.filter((role) => role.id !== roleToRemove.id));
            })
            .catch((error) => {
                notification.error({
                    message: _.get(error, 'response.data.message',
                        'An error occurred while removing role from user. Please try again.')
                });
            }
        )
    };

    return (
        <>
            <Modal
                visible={visible}
                destroyOnClose={true}
                width={900}
                onCancel={() => {
                    onClose();
                }}
                footer={[
                    <Button key="close" onClick={onClose}>
                        Close
                    </Button>,
                ]}
            >
                <Row type="flex" justify="center" style={{ padding: '10 20' }}>
                    <Label status="blue">MANAGE ROLES FOR - {user.name}</Label>
                </Row>
                <Text>Select a role to add:</Text>
                <Select
                    style={{ width: '100%', marginBottom: '1rem' }}
                    placeholder="Select a role"
                    onChange={addRole}
                >
                    {roles.map((role) => (
                        <Option key={role.id} value={role.id}>
                            {role.name}
                        </Option>
                    ))}
                </Select>
                <Table columns={columns} dataSource={currentUserRoles} rowKey="id" pagination={false} />
            </Modal>
            <RolesManageModal
                visible={showRolesManageModal}
                permissions={permissions}
                onClose={() => setShowRolesManageModal(false)}
                roleId={selectedRoleId}
            />
        </>
    );
};

export default UserRoleManage;
