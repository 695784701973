import React, { useState, useEffect } from 'react'
import { Title, Info, Highlight, Container } from './components/index.js'
import { Switch, Skeleton } from 'antd'
import {
  getLocationRunStatusRoute,
  updateLocationRunStatusRoute
} from 'utils/apiRoutes'
import { ZenSmartAPI } from 'utils'

const KillRun = ({ id }) => {
  const [isSwitchedOn, setIsSwitchedOn] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleCheckClick = async () => {
    try {
      setLoading(true)
      const apiResponse = await ZenSmartAPI.patch(
        updateLocationRunStatusRoute(id)
      )
      setIsSwitchedOn(apiResponse.data.data)
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchToggleState = async () => {
      try {
        setLoading(true)
        const apiResponse = await ZenSmartAPI.get(getLocationRunStatusRoute(id))
        setIsSwitchedOn(apiResponse?.data?.data)
        setLoading(false)
      } catch (error) {
        console.log({ error })
        setLoading(false)
      }
    }
    fetchToggleState()
  }, [id])
  return (
    <Container>
      <Title>Kill Switch</Title>
      <Info>
        Click the switch to turn this system{' '}
        <Highlight>{isSwitchedOn ? 'OFF' : 'ON'} </Highlight> immediately
      </Info>
      {loading ? (
        <Skeleton active />
      ) : (
        <Switch
          checked={isSwitchedOn}
          checkedChildren='ON'
          unCheckedChildren='OFF'
          onChange={handleCheckClick}
        />
      )}
    </Container>
  )
}

export default KillRun
