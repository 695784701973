import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import { DROP } from 'types'
import { color } from 'components/zensmart-design-system/shared/styles.js'

function SiblingArrow(props) {
  const { whitelist, dragType, processID, ifFirstBarcode = null } = props

  const arrowStyle = ifFirstBarcode ? `position: relative;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
  margin-right: 8px;
  &:before {
    border-left: 12px solid ${color.nav.unselected};
  }` :

    `position: relative;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
  padding-right: 8px;
  &:before {
    content: '';
    border-top: 8px solid transparent;
    border-left: 12px solid ${color.nav.unselected};
    border-bottom: 8px solid transparent;
  }`

  const Arrow = styled.div`
  ${arrowStyle}
`
  return (

    <Droppable
      // type={'Barcode Stage'}
      type={whitelist.includes(dragType) && dragType}
      droppableId={`<${DROP.SIBLING}>${processID}`}
    >
      {provided => {
        return (
          <Arrow
            {...provided.droppableProps}
            ref={provided.innerRef}
            data-sibling-arrow="1"
          >
            {provided.placeholder}
          </Arrow>
        )
      }}
    </Droppable>
  )
}

export default SiblingArrow