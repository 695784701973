import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { getWeeklyDepartmentSummaryRoute, getAllDepartments } from "utils/apiRoutes"
import moment from 'moment';
import { alphabeticalData } from "utils/sortData"
import { set } from 'date-fns'

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;

const WeeklyDepartmentSummaryPage = (props) => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [departments, setDepartments] = useState([])
    const [payload, setPayload] = useState({ department_id: null })
    const [columnValues, setColumnValues] = useState([])
    const [headingSelectionValue, setHeadingSelectionValue] = useState("")
    const fetchDepartments = () => {

        setLoading(true)
        ZenSmartAPI.get(getAllDepartments)
            .then((res) => {
                setLoading(false)
                setDepartments(res.data.data)

            })
            .catch((res) => {
                setLoading(false)
            })

    }
    const constructData = (data) => {
        const returnData = [];
        data.map(item => {
            returnData.push({
                "scan_type": item.scan_type
            })
            item.operator_data.map(item2 => {
                const returnValue = item2
                returnValue["scan_type"] = item2.name
                item2.weekly_report.map(weeklyReport => {
                    returnValue[weeklyReport.date] = weeklyReport.value
                })
                returnData.push(returnValue)
            })
        })
        console.log(`returnData`, returnData)
        return returnData
    }
    const fetchData = () => {
        if (payload.department_id === null) {
            notification.error({ message: "Please select a department!" })
            return
        }
        setLoading(true)
        ZenSmartAPI.post(getWeeklyDepartmentSummaryRoute, payload)
            .then((res) => {
                setLoading(false)
                if (res.data.data.report_values.length > 0) {
                    setItemData(constructData(res.data.data.report_values))
                    setColumnValues(res.data.data.columns)
                }
                else {
                    notification.error({ message: "No data found!" })
                    setItemData([])
                }
                setHeadingSelectionValue(`${departments.find(data => payload.department_id === data.text).text}: ${payload.date}`)
            })
            .catch((error) => {
                setLoading(false);
                // handle validation errors

                if (_.get(error, 'response.status') === 400) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An unhandled error occurred. Please contact your supervisor.'),
                    });
                }

                if (_.get(error, 'response.status') > 499) {
                    notification.error({
                        message: 'An unhandled error occurred. Please contact your supervisor'
                    });
                }
            })
        return true
    }

    const changeDepartment = (value) => {
        setPayload({ ...payload, department_id: value })

    }

    useEffect(() => {
        fetchDepartments()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Performance {">"} Operator Performance {">"} Weekly Department Summary</Title>
            </Header>
            <Panel title="WEEKLY DEPARTMENT SUMMARY">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <InputStyle />
                    <Row type="flex" align="middle">
                        <DateColStyle span={4} >
                            <Heading>DEPARTMENT</Heading>
                            <Select
                                optionFilterProp="children"
                                showSearch
                                style={{ marginTop: 10, width: '100%', textTransform: "capitalize" }} onChange={changeDepartment} placeholder="Select Department..." >
                                <Option value="">All</Option>
                                {alphabeticalData(departments, "name").map(data =>
                                    <Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </DateColStyle>
                        <DateColStyle span={4}>
                            <br />
                            <StyleButton style={{ marginTop: 10 }} onClick={fetchData} loading={loading} type="primary">GO</StyleButton>
                        </DateColStyle>
                    </Row>
                    {headingSelectionValue !== "" &&
                        <DateColStyle span={6} style={{ padding: 30 }}>
                            <Heading style={{ fontWeight: "bold", textTransform: "capitalize" }}>{headingSelectionValue.toUpperCase()}</Heading>
                        </DateColStyle>
                    }
                </div>

                <TableData loading={loading} data={itemData} setData={setItemData} columnValues={columnValues} />
            </Panel>
        </>
    )
}

export default WeeklyDepartmentSummaryPage