import React, {useCallback, useEffect, useState} from 'react';
import {Col, Input, Modal, Row} from 'antd';
import styled from 'styled-components';
import {Label} from 'components/zensmart-design-system/components/Label/Label.js';
import {Button} from '../../components/zensmart-design-system';
import {useSelector} from 'react-redux';

const { confirm } = Modal

const Thumbnail = styled.img`
  padding : 5px;
  height : 170px;
  width : 100%;
`;

const CenterButton = styled.p`
  text-align : center;
`;

export default function BarcodePressReleaseModal(props) {

  const { visible, onClose, filesData, handleClick } = props;
  const userData = useSelector((state) => state.user.details);
  const [count, setCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');



 

  const resetModalState = () => {


  }

 

  return (
    <Modal
      visible={visible}
      width={700}
      centered
      footer={null}
      destroyOnClose={true}
    >
      <Row style={{ overflow: 'auto', maxHeight: '400px' }}>
        <Row  type="flex" justify="center" align="center">
            {filesData && filesData.map(data => {
            return (
                
                <Col span={8}>
                    <CenterButton>
                        <Button isDisabled={data.value.disabled} size={'large'} style={{ width: '90%' }}
                        onClick={() => {
                            handleClick(data.value.position)
                          } }>{data.value.position.toUpperCase()}</Button>
                    </CenterButton>
                </Col>
            )
            })}
        </Row>
      </Row>
      {errorMessage &&
        <Row>
          <div style={{ textAlign: 'center' }}>
            <Label style={{ margin: 10 }} status={'red'}>
              {errorMessage}
            </Label>
          </div>
        </Row>
      }
      
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        
      </Row>
      <Row type="flex" justify="center" align="center" style={{ marginTop: '15px' }}>
        <Col span={6} offset={1}>
          <CenterButton>
            <Button  appearance={`ghost`} size={'large'} style={{ width: '100%' }} onClick={onClose}
              color="red" ghost>CANCEL</Button>
          </CenterButton>
        </Col>
      </Row>
    </Modal>);
}