import React, {useContext, useEffect, useState, useMemo} from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import {DesignerContext} from '../../DispatchDesignerContext';
import FormModal from '../../components/FormModal';
import QualifierInput
  from '../../../../../components/composites/QualifierInput';
import {
  Checkbox,
  Form,
  Icon,
  Input,
  InputNumber,
  Select as AntSelect,
} from 'antd';

const {Option} = AntSelect;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`;

const Select = styled(AntSelect)`
  width: 100%;
`;

const Number = styled(InputNumber)`
  width: 100%;
`;

function PackTypeFormModal(props) {

  const {stages, types, settings, openPackTypeDrawerInEditOnly, setPackTypeFormVisible} = useContext(
      DesignerContext);
  const [showQualifier, setShowQualifier] = useState(false);
  const [qualifierValue, setQualifierValue] = useState(null);
  const [maximumPagesValue, setMaximumPagesValue] = useState(null);
  const [packs, setPacks] = useState([]);
  const {form, stageData, ...modal} = props;
  const [errors, setErrors] = useState([]);
  const {setFieldsValue, getFieldDecorator, getFieldValue} = form;

  const maximumPagesHandler = (value) => {
    setMaximumPagesValue(value)
  }
  const debounceMaximumPageHandler = useMemo(
    () => _.debounce(maximumPagesHandler, 500)
  , []);

  useEffect(() => {

    if (!modal.visible) {
      setPacks([]);
    }

    if (modal.visible) {
      let [nodeType, nodeId] = stageData.parentID.split('.');
      // no qualifier required

      if (nodeType === 'buckets' || nodeType === 'switches') {
        if (stageData.qualifier !== 'other') {

          if (nodeType === 'buckets') {
            nodeId = stages.buckets[nodeId].parent.index;
          }

          if (stages.switches[nodeId].nextNode.indexes !== null) {
            setShowQualifier(true);
            setQualifierValue(stageData.qualifier);
          }
        }
      }

      if (stageData.context) {
        setPacks(stageData.context.map(pack => {
          return {
            dispatch_pack_type_id: pack.id,
            id: pack.pack_type.id,
            code: pack.pack_type.code,
            extras: pack.extras,
          };
        }));
      }

      if (stageData.extras && stageData.extras.maximum_pages) {
        setMaximumPagesValue(stageData.extras.maximum_pages);
      } else {
        setMaximumPagesValue(null);
      }
    }
  }, [stageData]);

  if (!modal.visible) {
    return null;
  }

  const packType = getFieldValue('type');

  const renderFormItem = (field, placeholder, initialValue, input = null) => {

    let component = <Input/>;
    if (input) {
      component = input;
    }

    return (
        <StyledFormItem label={placeholder}
                        validateStatus={errors[field] ? 'error' : null}
                        help={errors[field] && errors[field][0]}>
          {getFieldDecorator(field,
              {initialValue})(
              component,
          )}
        </StyledFormItem>
    );
  };

  const getPayload = () => {
    return {
      type: 'dispatch_pack',
      packs: packs.map(p => {
        return {
          pack_type_id: p.id,
          extras: p.extras,
        };
      }),
      parent_node_id: stageData.parentID.split('.').reverse()[0],
      qualifier: qualifierValue,
      maximum_pages: maximumPagesValue
    };
  };

  const handleClose = () => {
    setErrors([]);
  };

  const updatePacks = (pack) => {
    setPacks(packs.map(p => {
      return p;
    }));
  };

  const packTypeWidth = _.get(settings, 'use_flats_flag', false) === true ? 'w-3/5' : 'w-4/5'

  return (
      <FormModal {...modal}
                 form={form}
                 onClose={handleClose}
                 onError={setErrors}
                 getPayload={getPayload}
                 stageData={stageData}
      >
        {showQualifier &&
        <StyledFormItem validateStatus={errors['qualifier'] && 'error'}
                        help={errors['qualifier'] && errors['qualifier'][0]}
                        label="Qualifier">
          <QualifierInput value={qualifierValue}
                          onChange={value => setQualifierValue(value)}
                          types={types.qualifiers}
                          falseWhiteSpace={true}/>
        </StyledFormItem>
        }

        {_.get(settings, 'use_maximum_pages', false) &&
          <StyledFormItem validateStatus={errors['maximum_pages'] && 'error'}
                          help={errors['maximum_pages'] && errors['maximum_pages'][0]}
                          label="Maximum Pages">
            <Input id='floating' defaultValue={maximumPagesValue}
                   style={{textAlign: 'right'}}
                   className={`text-gray-800 cursor-pointer font-bold mr-2`}
                   type="number"
                   onChange={e => {
                     e.persist()
                     debounceMaximumPageHandler(e.target.value)
                   }}
            />
          </StyledFormItem>
        }
        <>
          <table className={`w-full`}>
            <tbody>
            {packs.map(pack => (
                <tr key={_.uniqueId('packs_')}>
                  <td className={`py-1 ${packTypeWidth}`}>
                    <AntSelect
                        defaultValue={pack.code}
                        value={pack.code}
                        showSearch
                        onSelect={code => {
                          const selectedPack = types.packs.find(pack => pack.code === code);
                          if (selectedPack !== null) {
                            pack.id = selectedPack.id;
                            pack.code = selectedPack.code;
                            updatePacks(pack);
                          }
                        }}
                    >
                      {types.packs.map(pack => (
                          <Option key={pack.id} value={pack.code}>{pack.code}</Option>
                      ))}
                    </AntSelect>
                  </td>
                  {_.get(settings, 'use_flats_flag', false) &&
                    <td className={`w-1/5`} style={{textAlign: 'center'}}>
                      <Checkbox
                          defaultChecked = {_.get(pack, 'extras.flats_flag', false)}
                          onChange = {e => {
                            if (pack.extras == null) {
                              pack.extras = {
                                flats_flag : e.target.checked
                              }
                            }
                            pack.extras.flats_flag = e.target.checked
                            updatePacks(pack)
                          }}
                      >
                        Flat
                      </Checkbox>
                    </td>
                  }
                  <td className={`w-1/5`} style={{textAlign: 'center'}}>
                    {pack.id !== null &&
                      <Icon
                          onClick={e => openPackTypeDrawerInEditOnly(
                              types.packs.find(p => p.id === pack.id, true))}
                          style={{textAlign: 'right'}}
                          className={`text-gray-800 cursor-pointer font-bold mr-2`}
                          type="edit"
                      />
                    }
                    <Icon
                        onClick={e => setPacks(
                            packs.filter(p => p.dispatch_pack_type_id !==
                                pack.dispatch_pack_type_id))}
                        style={{textAlign: 'right'}}
                        className={`text-red-800 cursor-pointer font-bold`}
                        type="close"
                    />
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
          {errors['packs'] &&
          <ul>
            {errors['packs'].map(
                error => <li style={{color: '#f5222d'}}>{error}</li>)}
          </ul>
          }
          <button
              onClick={e => {
                e.preventDefault();
                setPacks(
                    [
                      ...packs, {
                      dispatch_pack_type_id: _.uniqueId(),
                      id: null,
                      code: null,
                      extras: {flat_flag: false}
                    }],
                );
              }}
              className={`w-full border border-gray-300 bg-gray-200 text-gray-700 rounded p-1 mt-1`}
          >
            Add additional article
          </button>
        </>

        {/*{renderFormItem(*/}
        {/*    'code',*/}
        {/*    'Packaging code',*/}
        {/*    stageData.packaging_code,*/}
        {/*    <AutoComplete*/}
        {/*        dataSource={types.packs.map(pack => pack.code)}*/}
        {/*        onSelect={code => {*/}
        {/*          const selectedPack = types.packs.find(*/}
        {/*              pack => pack.code === code);*/}
        {/*          setFieldsValue({*/}
        {/*            type: selectedPack.type,*/}
        {/*            width: selectedPack.width,*/}
        {/*            height: selectedPack.height,*/}
        {/*            length: selectedPack.length,*/}
        {/*            weight: selectedPack.weight,*/}
        {/*            flexible_height: selectedPack.flexible_height,*/}
        {/*            flexible_field: selectedPack.flexible_field,*/}
        {/*          });*/}
        {/*        }}*/}
        {/*        filterOption={true}*/}
        {/*    />,*/}
        {/*)}*/}

        {/*{renderFormItem(*/}
        {/*    'weight',*/}
        {/*    'Packaging Weight',*/}
        {/*    stageData.weight,*/}
        {/*    <Number/>,*/}
        {/*)}*/}

        {/*{renderFormItem(*/}
        {/*    'width',*/}
        {/*    'Packaging Width',*/}
        {/*    stageData.width,*/}
        {/*    <Number/>,*/}
        {/*)}*/}

        {/*{renderFormItem(*/}
        {/*    'length',*/}
        {/*    'Packaging Length',*/}
        {/*    stageData.length,*/}
        {/*    <Number/>,*/}
        {/*)}*/}

        {/*{renderFormItem(*/}
        {/*    'type',*/}
        {/*    'Packaging Height Calculation Type',*/}
        {/*    stageData.type,*/}
        {/*    <Select>*/}
        {/*      <Option value="flexible">Flexible</Option>*/}
        {/*      <Option value="fixed">Fixed</Option>*/}
        {/*    </Select>,*/}
        {/*)}*/}

        {/*<span style={{*/}
        {/*  display: packType === 'flexible' ? 'block' : 'none',*/}
        {/*}}>*/}

        {/*  {renderFormItem(*/}
        {/*      'height',*/}
        {/*      'Base Packaging Height',*/}
        {/*      stageData.height,*/}
        {/*      <Number/>,*/}
        {/*  )}*/}

        {/*  {renderFormItem(*/}
        {/*      'flexible_height',*/}
        {/*      'Flexible Packaging Height',*/}
        {/*      stageData.flexible_height,*/}
        {/*      <Number/>,*/}
        {/*  )}*/}

        {/*  {renderFormItem(*/}
        {/*      'flexible_field',*/}
        {/*      'Flexible Field',*/}
        {/*      stageData.flexible_field,*/}
        {/*      <Select>*/}
        {/*        <Option value="page_count">Page Count</Option>*/}
        {/*        <Option value="quantity">Quantity</Option>*/}
        {/*      </Select>,*/}
        {/*  )}*/}
        {/*</span>*/}

        {/*<span style={{*/}
        {/*  display: packType === 'flexible' ? 'none' : 'block',*/}
        {/*}}>*/}
        {/*  {renderFormItem(*/}
        {/*      'height',*/}
        {/*      'Packaging Height',*/}
        {/*      stageData.flexible_height,*/}
        {/*      <Number/>,*/}
        {/*  )}*/}
        {/*</span>*/}

      </FormModal>
  );

}

export default Form.create()(PackTypeFormModal);
