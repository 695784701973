import React from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import { styles } from "../zensmart-design-system/index";

const color = styles.color;

const StyledMenuButton = styled.button`
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // height: ${({ open }) => open ? '2rem' : '1.5rem'};
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  span {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => open ? color.blue.light : color.gray};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      width: ${({ open }) => open ? '1.1rem' : '2rem'};
    }
    :nth-child(3) {
      width: ${({ open }) => open ? '1.1rem' : '2rem'};
    }
  }
  :focus{
    outline: 0;
  }
`;

export const MenuButton = ({ isOpen, ...menuButton }) => (
  <StyledMenuButton
    aria-label="Toggle menu"
    aria-expanded={isOpen}
    open={isOpen}
    {...menuButton}
  >
    <span />
    <span />
    <span />
  </StyledMenuButton>
)
  
MenuButton.propTypes = {
  isOpen: bool.isRequired,
  onClick: func.isRequired,
};
  
export default MenuButton;