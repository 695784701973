import React, { useState, useEffect, useRef } from "react";
import { Panel } from "components/primaries";
import { Button, Table, Icon, notification} from 'antd';
import styled from "styled-components";
import { color } from "components/zensmart-design-system/shared/styles.js";
import TaskHeader from "../partials/TaskHeader";
import ReportFaultModal from "./ReportFaultModal";
import { ZenSmartAPI } from 'utils';
import { getTasksByDeviceTypeAndClassRoute, startTaskRoute, endTaskRoute } from "utils/apiRoutes"
import { useSelector, useDispatch } from 'react-redux';
import { getTasksByDeviceTypeAndClass } from "store/actions/machine";
import moment from 'moment';
import StationInputModal from "../partials/StationInputModal";
import Faults from "./Faults"
import "../Machine.css";

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;

const StyledIcon = styled(Icon)`
  font-size: 30px;
  margin-bottom: 5px;
  margin-left: 12px;
  &.start-icon {
    color: #60BFA6;
  }
  &.end-icon {
    color: #ff4d4f;
  }
`

const ReportButton = styled(Button)`
  span {
    text-decoration: underline;
  }
`

const StyledDivider = styled.hr`
  background-color : #2E5BFF;
  margin: 1rem;
  margin-bottom: 2rem;
  opacity: 25%;
  height: 1px;
`

const SubtitleStyle = styled.h2`
  color: #8798AD;
  font-size: 13px;
  font-weight: normal;
  margin: 1rem 50px;
`
const Message = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 1em 50px 2em;
`
const TaskTitle = styled.p`
  font-family: 'Rubik', sans-serif;
  font-weight: ${props => (props.bold ? '600;' : '400;')};
`

const StyledTable = styled(Table)`
  thead > tr > th {
    background: transparent;
  }

  tbody .ant-table-row.striped {
    background: #f2f4fd;
  }

  tr > th:nth-child(1),
  tr > td:nth-child(1) {
    padding-left: 50px;
  }

  tr > td:last-child {
    padding-right: 50px;
  }

  .ant-pagination {
    margin: 20px 50px;
  }

  .ant-table-row-expand-icon {
    visibility: hidden;
  }
`

const parseAPIResponseErrors = (response, errorMsgPlaceholder) => {
  let errorMessage = '';
  if(response && (response.errors !== undefined || response.validationMessages !== undefined)) {
    errorMessage += response.message ? `${response.message} ` : '';
    const errors = response.errors ? Object.values(response.errors) : Object.values(response.validationMessages);
    errors.forEach(err => errorMessage += `${err} `);
  } else {
    errorMessage = errorMsgPlaceholder;
  }
  return errorMessage;
}

const Maintenance = () => {
  
  const timersRef = useRef([]);
  
  const [ showStationInputModal, setShowStationInputModal ] = useState(true);
  const [ openTasks, setOpenTasks ] = useState([]);
  const [ showReportFaultModal, setShowReportFaultModal ] = useState(false);
  const [ showFaults, setShowFaults ] = useState(false);
  const [ selectedTask, setSelectedTask ] = useState(null);
  const [reload, setReload] = useState(false)

  const dispatch = useDispatch();
  const machine = useSelector(state => state.machine);

  const columns = [
    {
      title: 'TASK',
      dataIndex: 'task',
      key: 'task',
      render(value, row) {
        return (
          <div>
            <TaskTitle bold>{row?.title}</TaskTitle>
            <TaskTitle>{row?.description ?? ''}</TaskTitle>
          </div>
        )
      }
    },
    {
      title: '# PERFORMED TODAY',
      dataIndex: 'execution_count',
      key: 'execution_count',
      render: (obj, row) => 
        <span 
          style={ row.execution_count > 0 ? {cursor: 'pointer', color: '#40a9ff', textDecoration: 'underline'} : {}}
          onClick={ () => row.execution_count > 0 ? toggleTask(row.key) : null }
        >
            {row.execution_count}
        </span>
    },
    {
      title: 'START TASK',
      dataIndex: 'start_task',
      key: 'start_task',
      render: (obj, row) => 
      <>
        { row.history.length > 0 && row.history[0] && row.history[0].endedBy === null ?
          <>
            <StyledIcon className="end-icon" type="pause-circle" onClick={() => endTask(row) } />
            <h4 key={row.id} ref={el => timersRef.current[row.id] = el}>00:00:00</h4>
          </>
          :
          <StyledIcon className="start-icon" type="play-circle" onClick={() => startTask(row) } />
        }
      </>
  
    },
    {
      title: '',
      dataIndex: 'report_fault',
      key: 'report_fault',
      render: (obj, row) => <ReportButton type="link" onClick={ () => openReportFaultModal(row) }>Report fault</ReportButton>
    }
  ];

  useEffect(()=>{
    let intervalID;
    if(!showStationInputModal) {
      intervalID = startTimer();
    }
    return () => clearInterval(intervalID);
  })

  const openReportFaultModal = row => {
    setShowReportFaultModal(true); 
    setSelectedTask(row)
  }

  const toggleTask = (key) => {
    const tasks = Array.from(openTasks);
    tasks.indexOf(key) >= 0 ? tasks.splice(tasks.indexOf(key), 1) : tasks.push(key);
    setOpenTasks(tasks);
  }

  const loadTasksByDeviceTypeAndClass = (station, taskClass) => {
    setShowFaults(false);
    setShowStationInputModal(false);
    setOpenTasks([]);
    dispatch(getTasksByDeviceTypeAndClass(machine.maintenance.tasks, station.station_type, taskClass))
    if(station) {
      const payload = {
        station_id: station.id,
        device_type: station.station_type,
        class: taskClass
      }
      ZenSmartAPI.post(getTasksByDeviceTypeAndClassRoute, payload)
        .then((res)=>{
          dispatch(getTasksByDeviceTypeAndClass(res.data.data, station.station_type, taskClass))
        })
        .catch(err => {
          notification.error({
            message: parseAPIResponseErrors(err.response.data, 'Fetching tasks failed.')
          })    
        })
    } 
  }

  const startTimer = () =>
  {
    const taskTimerList = machine.maintenance.tasks.filter(task => task.history && task.history.length > 0 && task.history[0] && task.history[0].endedBy === null )
    let count = 0;
    const timerIntervalID = setInterval(() => {
      taskTimerList.forEach((task,index) => {
        const startTime = moment(task.history[0].started_at);
        const endTime = moment(task.history[0].ended_at).add(count,'second');

        const diff = moment.duration(endTime.diff(startTime));
        var hrs = endTime.diff(startTime, 'hours') < 10 ? `0${endTime.diff(startTime, 'hours')}` : endTime.diff(startTime, 'hours');
        var min = diff.minutes() < 10 ? `0${diff.minutes()}` : diff.minutes();
        var sec = diff.seconds() < 10 ? `0${diff.seconds()}` : diff.seconds();

        const timer = [hrs, min, sec].join(':')  
        if(timersRef.current[task.id])
          timersRef.current[task.id].innerHTML = timer;
      })
      count += 1;
    }, 1000)
    return timerIntervalID;
  }

  const startTask = task => {
    const payload = {
      id: task.id,
      station_id: machine.station.id
    }
    ZenSmartAPI.post(startTaskRoute, payload)
    .then((res)=>{
      loadTasksByDeviceTypeAndClass(machine.station, task.class)
      notification.success({
        message: 'Successfully started a task.'  
      })
    })
    .catch((res)=>{
      notification.error({
        message: 'Starting a task failed.'  
      })
    })
  }

  const endTask = task => {
    const payload = {
      id: task.id,
      station_id: machine.station.id,
      task_log_id: task.history[0].id
    }
    ZenSmartAPI.post(endTaskRoute, payload)
    .then((res)=>{
      loadTasksByDeviceTypeAndClass(machine.station, task.class)
      notification.success({
        message: 'Successfully ended a task.'  
      })
    })
    .catch((res)=>{
      notification.error({
        message: 'Ending a task failed.'  
      })
    })
  }

  const renderHistory = record => {
    const historyColumns = 
    [
      {
        title: 'Operator',
        dataIndex: 'startedBy',
        key: 'startedBy',
      },
      {
        title: 'Started',
        dataIndex: 'started_at',
        key: 'started_at',
      },
      {
        title: 'Completed',
        dataIndex: 'ended_at',
        key: 'ended_at'
      },
    ];

    const history = record.history.map(log => {
      return {
        key: log.id,
        ...log,
        ended_at: log.endedBy !== null ? log.ended_at : '--'
      }
    })
    return <Table pagination={false} dataSource={history} columns={historyColumns} />;
  }

  const dataSource = machine && machine.maintenance.tasks.map((task,index) => { 
    return {
      ...task, 
      key: index
    } 
  })

  return (
  <div className="machine-content">
    <StationInputModal
      show={showStationInputModal}
      setShow={setShowStationInputModal}
      onStationEnter={loadTasksByDeviceTypeAndClass}
    />
  
  { !showStationInputModal && 
  <>
    <ReportFaultModal 
      showReportFaultModal={showReportFaultModal}
      setShowReportFaultModal={setShowReportFaultModal}
      selectedTask={selectedTask}
      reload={setReload}
    />
    <Header>
      <Title>Machine {">"} Tasks</Title>
    </Header>
    <Panel title="TASKS">
        <TaskHeader 
          setShowReportFaultModal={setShowReportFaultModal}
          showMaintenanceTabs={true}
          loadTasksByDeviceTypeAndClass={loadTasksByDeviceTypeAndClass}
          setShowFaults={setShowFaults}
        />

        <StyledDivider />

        <SubtitleStyle>{ moment().format('ddd DD MMM YYYY').toUpperCase() }</SubtitleStyle>

        { dataSource.length > 0 ?
          <StyledTable 
            dataSource={dataSource} 
            columns={columns}
            expandedRowKeys={ openTasks }
            expandedRowRender={record => renderHistory(record) }
            rowClassName={ (record,i) => i % 2 === 0 ? 'striped' : '' }
          />
          : <Message>There are no tasks to display.</Message>
        }
    </Panel>
  </>
  }
  </div>
);
}
export default Maintenance;
