import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Row, Col } from 'antd'


const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    font-size : 11px !important;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    border: 1px solid #f1f3ff;
}

`
const TableData = (props) => {

    const { loading, data, columnValues } = props
    const [columns, setColumns] = useState([])



    const rowStyle = (value, index) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (odd) {
            obj.props.style = {}
        }

        return obj
    }

    useEffect(() => {
        if (columnValues.length > 0) {
            const constructColumn = []
            columnValues.map(item => {
                constructColumn.push({
                    title: item === "product" ? "PRODUCTS" : item.toUpperCase().replace(/_/g, " "),
                    dataIndex: item,
                    width: item === "product" ? 150 : null,
                    key: item,
                    render(value, row, index) {
                        return rowStyle(item === "product" ? value.name : value !== null && value !== undefined ? value : "", index, row, item === "operator")
                    }
                })
            })
            setColumns(constructColumn)
        }
    }, [columnValues]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "200px" }}
                    pagination={false}

                />
            </StyleTable>
        </>
    )
}

export default TableData