import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { ReportItem } from "../partials/composites";
import { DashboardWidget } from "pages/Performance/Widget";
import { Search } from "components/zensmart-design-system";
import { GetLabel } from "pages/Performance/utils";
import * as _ from "lodash";
import is from "is_js";
import { useSelector } from "react-redux";
import { initialDashboard } from "store/reducers/dashboardReducer";

const SideBar = styled.div`
  position: relative;
  background-color: #dbe9fd;
  border-bottom-left-radius: 5;
  max-height: 100%;
  width: 26%;
  width: ${(props) => (props.edit ? "26%" : "0")};
  overflow-y: auto;
  transition: width 0.5s;
`;

const SideBarStyle = styled.div`
  padding: 20px;

  h2 {
    font-weight: 700;
  }
`;

function DashboardEditor(props) {
  const {
    sidebar,
    isCreate,
    dashboard,
    editMapping,
    editLayout,
    runningNo,
    setRunningNo,
    updateDashboardMapping,
    updateDashboardLayout,
    isEdit,
  } = props;

  const [dataFields, setDataFields] = useState([]);
  const [searchContent, setSearchContent] = useState("");

  const reports = useSelector((state) => state.dashboard.reports);
  const dashboardMapping = useSelector((state) => state.dashboard.mapping);
  const dashboardLayout = useSelector((state) => state.dashboard.layout);

  useEffect(() => {
    setDataFields([...reports]);

    if (!isCreate) {
      updateDashboardMapping({ id: dashboard.id, mapping: editMapping });
      updateDashboardLayout(editLayout);
    }

    return () => {
      updateDashboardMapping(initialDashboard);
      updateDashboardLayout([]);
    };
  }, []);

  function modifyFields(e) {
    setSearchContent(e.target.value);

    const determineFields = (field) => {
      if (field.name) {
        return (
          GetLabel(`${field.table}.${field.name}`)
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) > -1
        );
      }
    };
    
    setDataFields(reports.filter(determineFields));
  }

  function onDrop(e) {
    e.preventDefault();

    const parentElement = e.currentTarget
    const parentBoundRect = parentElement.getBoundingClientRect();
    const reportId = parseInt(e.dataTransfer.getData("reportId"));
    const x = Math.floor(((e.clientX + parentElement.scrollLeft) - parentBoundRect.left) / 100);
    const y = Math.floor(((e.clientY + parentElement.scrollTop) - parentBoundRect.top) / 70);

    if (is.existy(reportId)) {
      let layoutId = _.padStart("1", 9, "0");
      let currentNo = 0;

      do {
        currentNo = runningNo < 999999999 ? runningNo + 1 : 1;
        layoutId = _.padStart(currentNo.toString(), 9, "0");
        const checkLayout = dashboardLayout.find(
          (layout) => layout.i === layoutId
        );

        if (is.not.existy(checkLayout)) {
          break;
        }
      } while (true);

      setRunningNo(currentNo);

      updateDashboardMapping({
        ...dashboardMapping,
        mapping: [
          ...dashboardMapping.mapping,
          {
            layout_id: layoutId,
            report_id: reportId,
            deleted: false,
          },
        ],
      });

      const filteredReport = reports.find((report) => report.id === reportId);
      const minValues =
        filteredReport.visualization === "total"
          ? { w: 1, h: 1, minW: 1, minH: 1 }
          : { w: 3, h: 3, minW: 3, minH: 3 };

      updateDashboardLayout([
        ...dashboardLayout,
        { i: layoutId, x: x, y: y, ...minValues },
      ]);
    }
  }

  function onRemove(item) {
    const modifiedMapping = dashboardMapping.mapping
      .filter((d) => !(is.not.existy(d.id) && d.layout_id === item))
      .map((mapping) => {
        if (mapping.layout_id === item) {
          return { ...mapping, deleted: true };
        } else {
          return { ...mapping };
        }
      });

    updateDashboardMapping({
      ...dashboardMapping,
      mapping: [...modifiedMapping],
    });

    const filteredLayout = dashboardLayout.filter(
      (layout) => layout.i !== item
    );

    updateDashboardLayout(filteredLayout);
  }

  return (
    <Spin tip="Loading..." spinning={props.loading}>
      <div
        style={{
          display: "flex",
          height: window.screen.height - 300,
        }}
      >
        <SideBar edit={sidebar}>
          <SideBarStyle>
            <h2>Existing Reports</h2>
            <Search
              style={{ minWidth: 130 }}
              onChange={modifyFields}
              value={searchContent}
            />
            {dataFields &&
              dataFields.map((report, index) => (
                <ReportItem
                  key={index}
                  index={index}
                  report={report}
                  id={report.id}
                  name={report.name}
                />
              ))}
          </SideBarStyle>
        </SideBar>
        <DashboardWidget
          isEdit={isEdit}
          active={true}
          sidebar={sidebar}
          dashboardMapping={dashboardMapping.mapping}
          dashboardLayout={dashboardLayout}
          updateDashboardLayout={updateDashboardLayout}
          onDrop={onDrop}
          onRemove={onRemove}
        />
      </div>
    </Spin>
  );
}

export default DashboardEditor;
