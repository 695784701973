import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table } from 'antd'
import _ from 'lodash';


const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid ;
}

.ant-table-tbody {
    cursor : pointer;
}

tr.stuck-batch th, tr.stuck-batch td {
    background: #FF8000 !important;
    font-color : white;
}

tr.failed-batch th, tr.failed-batch td {
    background: #fb4b4b !important;
    font-color : white;
}


tr.selected-batch th, tr.selected-batch td {
    background: rgba(11, 107, 242, 0.5) !important;
}


`
const BatchedTableData = (props) => {

    const { itemData, loading, columns, selectedBatched, setSelectedBatched } = props
    const [lastBatchShiftSelected, setLastBatchShiftSelected] = useState(null)

    const columnLength = columns.length > 7 ? columns.length * 100 : 700
    const selectBatch = (record, event) => {

        if (event.shiftKey && lastBatchShiftSelected) {
            const lastBatchShiftSelectedIndex = itemData.findIndex((data) => data.id === lastBatchShiftSelected.id)
            const recordIndex = itemData.findIndex((item) => item.id === record.id)
            const ar2 = lastBatchShiftSelectedIndex < recordIndex ? itemData.slice(lastBatchShiftSelectedIndex, recordIndex + 1) : itemData.slice(recordIndex, lastBatchShiftSelectedIndex + 1);
            const selectSent = ar2.filter(filterItem => filterItem.id !== lastBatchShiftSelected.id).map(item => {
                var element = document.getElementsByClassName(item.id)[0];
                element.classList.add("selected-batched");
                return item.id;
            })
            if (selectedBatched.includes(lastBatchShiftSelected.id)) {
                setSelectedBatched(_.uniq(selectedBatched.concat(selectSent)), true)
            }
            else {
                setSelectedBatched(selectedBatched.filter(item => !selectSent.includes(item)))
            }
            return
        }

        var element = document.getElementsByClassName(record.id)[0];
        if (selectedBatched.includes(record.id)) {
            setSelectedBatched(selectedBatched.filter(data => data !== record.id))
            element.classList.remove("selected-batch");
        }
        else {
            setSelectedBatched([...selectedBatched, record.id])
            element.classList.add("selected-batch");
        }
        setLastBatchShiftSelected(record)

    }

    return (
        <>
            <TableStyling />
            <StyleTable>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={itemData}
                    bordered
                    size="middle"
                    pagination={false}
                    scroll={{ y: "52vh", x: columnLength }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => selectBatch(record, event), // click row

                        };
                    }}
                    id={"batch-table"}
                    rowClassName={(record, index) => `${record.id}`}

                />
            </StyleTable>
        </>
    )
}

export default BatchedTableData