import React, { useEffect, useState } from 'react';
import { Header, Title } from 'components/primaries';
import { ZenSmartAPI } from 'utils'
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'
import ImpositionRuleUpdateModal from './ImpositionRuleUpdateModal';
import { fetchImpositionRuleInitDataRoute, ticketDataFieldRoute, impositionRuleRoute } from "utils/apiRoutes"
import { notification, Modal } from 'antd';

const ImpositionRulePage = () => {
    const [fetchParams, setFetchParams] = useState({})
    const [fetchData, setFetchData] = useState({ refresh_data: null })
    const [updateImpositionRuleModal, setUpdateImpositionRuleModal] = useState(false)
    const [formInitialValue, setFormInitialValue] = useState({})
    const [impositionModalName, setRuleModalName] = useState("")
    const [impositionInitData, setImpositionInitData] = useState({})
    const [fieldsValue, setFieldsValue] = useState([])
    const [groupsValue, setGroupsValue] = useState([1,2,3,4])
    const [fieldType, setFieldType] = useState("string")
    const [group, setGroup] = useState("")
    const addImpositionRule = (row, refreshData) => {
        setUpdateImpositionRuleModal(true)
        setRuleModalName("Add New Imposition Rule")
    }
    const editImposition = (row, refreshData) => {
        ZenSmartAPI.get(`${impositionRuleRoute}/${row.id}`)
            .then((res) => {
                setUpdateImpositionRuleModal(true)
                setFormInitialValue(res.data)
                setRuleModalName(`Edit Imposition Rule ${res.data.model_definition_name}`)
            })
            .catch((res) => {
                if (res.response.status === 422) {
                    notification.error({ message: "Accessory code already exist!" })
                }
                else {

                    notification.error({ message: "Error saving accessory!" })
                }

            })
    }

    const deleteImposition = (row, refreshData) => {
        Modal.confirm({
            'content': "Are you sure you want to delete this imposition rule?",
            onOk: () => {
                ZenSmartAPI.delete(`${impositionRuleRoute}/${row.id}`)
                    .then((res) => {
                        fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                        notification.success({ message: "Delete imposition rule successful!" })
                    })
                    .catch((res) => {
                        if (res.response.data) {
                            notification.error({ message: res.response.data.message })
                        }
                    })
            },
        });
    }

    useEffect(() => {

        ZenSmartAPI.get(fetchImpositionRuleInitDataRoute)
            .then((res) => {
                setImpositionInitData(res.data.data)
                ZenSmartAPI.get(ticketDataFieldRoute(), {})
                    .then((res) => {
                        setFieldsValue(res.data.data)
                    })

            })
            .catch((res) => {
                if (res.response.data) {
                    notification.error({ message: res.response.data.message })
                }
            })

    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <Header>
                <Title>App Setup {">"} Imposition Rules</Title>
            </Header>
            <ImpositionRuleUpdateModal
                updateImpositionRuleModal={updateImpositionRuleModal}
                setUpdateImpositionRuleModal={setUpdateImpositionRuleModal}
                fetchData={fetchData}
                fetchParams={fetchParams}
                formInitialValue={formInitialValue}
                setFormInitialValue={setFormInitialValue}
                impositionModalName={impositionModalName}
                setRuleModalName={setRuleModalName}
                fieldsValue={fieldsValue}
                setValueField={setFieldsValue}
                fieldType={fieldType}
                setFieldType={setFieldType}

                groupsValue={groupsValue}
                setGroupsValue={setGroupsValue}
                group={group}
                setGroup={setGroup}

                impositionInitData={impositionInitData}
            />
            <Panel title="IMPOSITION RULES">
                {/* TODO:: ticket_data_fields */}
                <DataListPage model="imposition_rule" select={[]} hidden={["id", "model_definition_id"]} link={""} pageSize={50}
                    customizedButton={
                        [{
                            buttonLabel: "Add New Imposition Rule",
                            buttonType: "primary",
                            buttonAction: "normal",
                            actionFunction: addImpositionRule,
                            offset: 14,
                            span: 4
                        }
                        ]
                    }
                    setOutSourceFetchData={setFetchData}
                    setOutSourceParameters={setFetchParams}
                    actionButtons={
                        [
                            {
                                buttonLabel: "Edit",
                                buttonType: "primary",
                                buttonAction: "normal",
                                actionFunction: editImposition,
                                reloadData: true
                            },
                            {
                                buttonLabel: "Delete",
                                buttonType: "danger",
                                buttonAction: "normal",
                                actionFunction: deleteImposition,
                                reloadData: true
                            },
                        ]
                    }
                />

            </Panel>
        </>
    );
};

export default ImpositionRulePage;