import React, { useState, useEffect } from 'react'
import { Row, Col, notification, Select, DatePicker } from 'antd'
import { ZenSmartAPI } from 'utils'
import { customDateInputs } from 'utils/customSelectOptionsInput'
import { Button } from '../../../components/zensmart-design-system';
import { saveNewFilterConfigurationRoute, filterConfigurationRoute } from "utils/apiRoutes"
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import _ from "lodash"
import QualifierInput
    from '../../../components/composites/QualifierInput';
import FilterFieldsComponent from './FilterFieldsComponent'
const OptGroup = Select.OptGroup
const Option = Select.Option

const ConfigurationFilters = (props) => {
    const {
        setLoading,
        oneConfiguration,
        configurationInitData,
        fieldSelectValues
    } = props
    const [filterNewFieldValue, setFilterNewFieldValue] = useState({})
    const [filterFields, setFilterFields] = useState([])
    const [fieldType, setFieldType] = useState("string")
    const defaultCustomDateFilters = {
        from: null,
        to: null
    }
    const [customDatesFilters, setCustomDateFilters] = useState(defaultCustomDateFilters)

    const saveNewFilter = () => {

        if (filterNewFieldValue.model_definition_id && filterNewFieldValue.qualifier) {
            const payload = filterNewFieldValue

            if (payload.qualifier.includes('datetime:custom')) {
                if (!customDatesFilters.from || !customDatesFilters.to) {
                    notification.error({ message: "Please select all date fields!" })
                    return
                } else {
                    payload.qualifier = `datetime:custom,${customDatesFilters.from},${customDatesFilters.to}`
                }

            }

            setLoading(true)
            ZenSmartAPI.post(saveNewFilterConfigurationRoute(oneConfiguration.id), payload)
                .then((res) => {
                    setLoading(false)
                    setFilterNewFieldValue({})
                    setFilterFields([...filterFields, res.data.data])
                    setFieldType("string")
                    setCustomDateFilters(defaultCustomDateFilters)

                })
                .catch((err) => {
                    setLoading(false)
                    errorNoticationHandler(err, "Cant save sorter!")
                })
        }
        else {
            notification.error({ message: "Please select fields first!" })

        }

    }
    const updateFilterNewFieldValue = (value, key) => {
        if (key === "model_definition_id") {
            const getType = fieldSelectValues.find(data => data.id === value).field_type;
            setFieldType(getType);
        }
        setFilterNewFieldValue({ ...filterNewFieldValue, [key]: value })

    }

    const changeFilterFieldValues = (value, index) => {

        let items = [...filterFields];
        let item = { ...items[index] };
        item = value;
        items[index] = item;
        setFilterFields(items)
    }

    const deleteFilter = (filterId) => {
        setLoading(true)
        ZenSmartAPI.delete(filterConfigurationRoute(filterId))
            .then((res) => {
                setLoading(false)
                setFilterFields(filterFields.filter(data => data.id != filterId))
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant Delete filter!")
            })
    }

    const generateDateOptions = (updateFunction, defaultValue) => {
        const splitValue = defaultValue && defaultValue.includes('datetime') ? defaultValue.split('datetime:')[1].split(',')[0] : null
        return customDateInputs(value => updateFunction(`datetime:${value}`, 'qualifier'), splitValue)
    }

    const onChangeDate = (value, dateString, key) => {
        setCustomDateFilters({ ...customDatesFilters, [key]: dateString === '' ? null : dateString })
    }
    useEffect(() => {
        if (oneConfiguration.filters) {
            setFilterFields(oneConfiguration.filters)
        }
    }, [oneConfiguration]) // eslint-disable-line react-hooks/exhaustive-deps

    return <div>
        <div style={{ minHeight: 100, padding: 20, border: "2px solid rgba(218,225,247,255)", borderRadius: "10px" }}>
            <h2>ADD FIELD:</h2>
            <Row type="flex" align="middle">
                <Col span="6" style={{ padding: 10 }}>
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Enter Field (Click Suggestions to add each field)"
                        optionFilterProp="children"
                        value={_.get(filterNewFieldValue, 'model_definition_id', undefined)}
                        onChange={(value) => updateFilterNewFieldValue(value, 'model_definition_id')}
                    >
                        {fieldSelectValues && fieldSelectValues.map(data =>
                            <Select.Option value={data.id}>{data.label.replace('lines.', '')}</Select.Option>
                        )}
                    </Select>
                </Col>

                <Col span="6" style={{ padding: 10 }}>
                    {fieldType === "datetime" || fieldType === "date" ? generateDateOptions(updateFilterNewFieldValue, _.get(filterNewFieldValue, 'qualifier', undefined)) :
                        <QualifierInput
                            onChange={value => updateFilterNewFieldValue(value, 'qualifier')}
                            types={_.get(configurationInitData, 'qualifiers', [])}
                            value={_.get(filterNewFieldValue, 'qualifier', undefined)}
                            placeholder="Set qualifier..."
                            falseWhiteSpace={true}
                        />
                    }
                </Col>
                {filterNewFieldValue.qualifier && filterNewFieldValue.qualifier.includes("datetime:custom") ?
                    <>
                        <Col span="4" style={{ padding: 10 }}>
                            <DatePicker style={{ width: '100%' }} onChange={(value, dateString) => onChangeDate(value, dateString, "from")} />

                        </Col>
                        <Col span="4" style={{ padding: 10 }}>
                            <DatePicker style={{ width: '100%' }} onChange={(value, dateString) => onChangeDate(value, dateString, "to")} />
                        </Col>
                    </>
                    : null
                }
                <Col span="3" style={{ padding: 10 }}>
                    <Button size="small" onClick={saveNewFilter} style={{ width: "100%" }}>Add as Filter</Button>
                </Col>
                <Col span="24" style={{ padding: 10 }}>
                    <br />
                    {filterFields.map((data, key) => {
                        return <FilterFieldsComponent
                            data={data}
                            fieldSelectValues={fieldSelectValues}
                            configurationInitData={configurationInitData}
                            deleteFilter={deleteFilter}
                            key={key}
                            changeFilterFieldValues={changeFilterFieldValues}
                            setLoading={setLoading}
                            filterFields={filterFields}
                            generateDateOptions={generateDateOptions}
                        />
                    })}
                </Col>
            </Row>


            <br />
        </div>
    </div >
}

export default ConfigurationFilters