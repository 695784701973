import React, { useEffect, useState } from 'react';
import { PRINTER_TABLE_COLUMNS } from './PrinterTableConstants';
import Table from '../../components/Table/Table';
import { buildRow } from '../../components/Table/TableUtilities';
import PrinterDisplayTable from 'pages/Performance/components/Table/PrinterDisplayTable';

const PrinterTable = ({
    activeRows,
    loading,
    data,
    handleRow
}) => {
    const [dataSource, setDataSource] = useState(data);
    const buildDataSource = (data) => {
        return data?.map((data) => {
            const departmentId = (data?.department_id ?? null);
            const departmentName = (data?.department_name ?? null);
            const printerName = (data?.short_name ?? null);

            const isGrandTotal = (departmentId === null && departmentName.includes('Total'));
            const isDepartmentTotal = (departmentId !== null && departmentName.includes('Total'));
            
            const isHeader = (isGrandTotal || isDepartmentTotal);
            const isHidden = (!activeRows.includes(departmentId) && !isHeader);

            return buildRow(departmentId, isHeader, isHidden, Object.assign(data, {
                short_name : (isHeader) ? departmentName : printerName
            }));
        });
    }

    useEffect(() => {
        setDataSource([]);
        setDataSource(buildDataSource(data));
    }, [data, loading]);

    return (
        <PrinterDisplayTable
            rowKey='printers'
            columns={PRINTER_TABLE_COLUMNS}
            loading={loading}
            dataSource={dataSource}
            handleRow={(id) => handleRow(id)}
        />
    );
}

export default PrinterTable;
