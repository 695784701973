import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Input, Checkbox, notification } from 'antd';
import { addAccessoriesRoute, editSingleAccessoryRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'

function UpdateForm(props) {
    const {
        form,
        updateAccessoriesModal,
        setUpdateAccessoriesModal,
        fetchParams,
        fetchData,
        formInitialValue,
        setFormInitialValue,
        accessoryModalName,
        setAccessoryModalName
    } = props

    const {
        getFieldDecorator,
        getFieldsError,
        getFieldsValue,
        validateFields,

    } = form


    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            const payload = getFieldsValue();
            
            if (!err) {
                setLoading(true)
                const api = formInitialValue.id ?  ZenSmartAPI.patch(editSingleAccessoryRoute(formInitialValue.id), payload) : ZenSmartAPI.post(addAccessoriesRoute, payload)
                api.then((res) => {
                        notification.success({ message: formInitialValue.id ? "Update accessory successful!" : "Add new accessory successful!" })
                        setLoading(false);
                        fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                        handleCancel()
                    })
                    .catch((res) => {
                        setLoading(false);
                        if (res.response.status === 422) {
                            notification.error({ message: "Accessory code already exist!" })
                        }
                        else {

                            notification.error({ message: "Error saving accessory!" })
                        }

                    })
            }
        });
    };

    const handleCancel = () => {
        setUpdateAccessoriesModal(false)
        setFormInitialValue({})
        setAccessoryModalName("")
    }

    return (
        <div>
            <Modal title={accessoryModalName} visible={updateAccessoriesModal} footer={[
                <Button
                    key="back"
                    onClick={handleCancel}
                >
                    Cancel
            </Button>,
                <Button key="submit"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    Save
            </Button>,
            ]}>
                {updateAccessoriesModal &&
                    <Form layout="vertical" onSubmit={handleSubmit}>
                        <Form.Item label="Code:"
                        >
                            {getFieldDecorator(`code`, {
                                rules: [{ required: true , 
                                          pattern: new RegExp(/^$|.*\S+.*/gm),   
                                          message: "Please enter code"
                                        }],
                                initialValue: formInitialValue.code ? formInitialValue.code : undefined

                            }
                            )(
                                <Input placeholder={"Please Input Code!"} />
                            )}
                        </Form.Item>
                        <Form.Item label="Accessory Name:"
                        >
                            {getFieldDecorator(`name`, {
                                rules: [{ required: true , 
                                          pattern: new RegExp(/^$|.*\S+.*/gm),   
                                          message: "Please enter accessory name"
                                        }],
                                initialValue: formInitialValue.name ? formInitialValue.name : undefined

                            }
                            )(
                                <Input placeholder={"Please Input Accessory Name!"} />
                            )}
                        </Form.Item>
                        <Form.Item label="Weight:"
                        >
                            {getFieldDecorator(`weight`, {
                                    rules: [{ required: false ,
                                        pattern: new RegExp(/^(\d{1,5}|\d{0,5}\.\d{1,2})$/),
                                        message: "Please enter 2 decimal value or 0 for empty"
                                    }],
                                    initialValue: formInitialValue.weight ? formInitialValue.weight : undefined
                                }
                            )(
                                <Input placeholder={"Please Input Weight!"} />
                            )}
                        </Form.Item>
                        <Form.Item label="Required to scan:"
                        >
                            {getFieldDecorator(`required_to_scan`, {
                                initialValue: formInitialValue.required_to_scan ? formInitialValue.required_to_scan : false,
                                valuePropName: "checked",

                            }
                            )(
                                <Checkbox />
                            )}
                        </Form.Item>
                    </Form>
                }
            </Modal>
        </div>
    )
}

const AccessoriesForm = Form.create({
})(UpdateForm)

const AccessoriesUpdateModal = (props) => {
    const { updateAccessoriesModal, setUpdateAccessoriesModal, fetchData, fetchParams, formInitialValue, setFormInitialValue, accessoryModalName, setAccessoryModalName } = props
    return (
        <div>
            <AccessoriesForm
                updateAccessoriesModal={updateAccessoriesModal}
                setFormInitialValue={setFormInitialValue}
                formInitialValue={formInitialValue}
                setUpdateAccessoriesModal={setUpdateAccessoriesModal}
                fetchData={fetchData}
                fetchParams={fetchParams}
                accessoryModalName={accessoryModalName}
                setAccessoryModalName={setAccessoryModalName}
            />
        </div>
    )
}

export default AccessoriesUpdateModal