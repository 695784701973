import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Row, Col, Tag } from 'antd'
import { panelSortData } from 'utils/sortData'
import moment from 'moment';
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { failInitialData } from "utils/panelsInitialData"

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const DueDateTag = styled(Tag)`
    padding : 5px 17px;
    font-size : 12px;
`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

`
const dueDateColor = {
    later: "purple",
    today: "blue",
    tomorrow: "green"
}

const TableData = (props) => {

    const { itemData, loading, data, setData, ascend, setAscend } = props

    const sortingData = (data, property, ifNotProperty) => {
        if (ascend[property] === null) {
            setAscend({ ...failInitialData, [property]: true })
        }
        else {
            ascend[property] === true ? setAscend({ ...failInitialData, [property]: false }) : setAscend({ ...failInitialData, [property]: true })
        }
        setData(panelSortData(data, property, ascend[property], ifNotProperty))
    }
    const getHeaders = (property, text, ifAscend) => {
        return (
            <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifAscend)} style={{ cursor: "pointer" }}>
                <Col span={ascend[property] === null ? 24 : 21}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }

    const columns = [
        {
            title: getHeaders("fail_date_time", "FAIL DATE / TIME", false),
            dataIndex: 'fail_date_time',
            key: 'fail_date_time',
            render(value, row, index) {
                const dataValue = moment.unix(value).format("D MMM YYYY, h:mma")
                return rowStyle(value ? dataValue : "-", index, row)
            },
        },
        {
            title: getHeaders("failed_by", "FAIL", false),
            dataIndex: 'failed_by',
            key: 'failed_by',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("failed_reason", "FAIL REASON", false),
            dataIndex: 'failed_reason',
            key: 'failed_reason',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("line_id", "LINE", true),
            dataIndex: 'line_id',
            key: 'line_id',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("product", "PRODUCT", true),
            dataIndex: 'product',
            key: 'product',

            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("lamination_code", "LAMINATION", false),
            dataIndex: 'lamination_code',
            key: 'lamination_code',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("transaction", "TRANSACTION", true),
            dataIndex: 'transaction',
            key: 'transaction',

            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("due_date", "DUE DATE", false),
            dataIndex: 'due_date',
            key: 'due_date',
            render(record, row, index) {
                const value = <DueDateTag color={dueDateColor[record && record.toLowerCase()] ?
                    dueDateColor[record && record.toLowerCase()] : "red"}
                    key={record}>
                    {record && record.toUpperCase()}
                </DueDateTag>
                return rowStyle(record ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("last_scan_date_time", "LAST SCAN DATE TIME", false),
            dataIndex: 'last_scan_date_time',
            key: 'last_scan_date_time',
            render(value, row, index) {
                const dataValue = moment.unix(value).format("D MMM YYYY, h:mma")
                return rowStyle(value ? dataValue : "-", index, row)
            },
        },
        {
            title: getHeaders("last_scan_type", "LAST SCAN TYPE", false),
            dataIndex: 'last_scan_type',
            key: 'last_scan_type',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("last_scan_operator", "LAST SCAN OPERATOR", false),
            dataIndex: 'last_scan_operator',
            key: 'last_scan_operator',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
    ];

    const rowStyle = (value, index, row) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }

        return obj
    }
    useEffect(() => {

        if (data.length > 0) {

            const itemObj = itemData.map(data => {
                const obj = {
                    fail_date_time: data.fail_date_time,
                    failed_by: data.failed_by,
                    failed_reason: data.failed_reason,
                    line_id: data.line_id,
                    product: data.product,
                    lamination_code: data.lamination_code,
                    transaction: data.transaction,
                    due_date: data.due_date,
                    last_scan_date_time: data.last_scan_date_time,
                    last_scan_type: data.last_scan_type,
                    last_scan_operator: data.last_scan_operator,
                }
                return obj;
            })

            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "50vh" }}
                    pagination={false}

                />
            </StyleTable>
        </>
    )
}

export default TableData