import React, { useState, useRef, useEffect } from 'react';
import { Label } from 'components/zensmart-design-system';
import { Modal, Input, Col, Row, notification, Button } from 'antd';
import styled from 'styled-components';
import { ZenSmartAPI } from 'utils';
import Cookies from 'js-cookie';
import { matchScanBlockRoute } from "utils/apiRoutes"
import _ from 'lodash';
import successSound from 'static/sounds/good.mp3';

const CenterButton = styled.p`
  text-align : center;
`;

function MatchCheckDialog(props) {
  const { visible, maskClosable, onCancel, scanTypeId } = props;
  const [inputValue, setInputValue] = useState('');
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState([]);
  const scanMatchInput = useRef(null);
  const successAudio = new Audio(successSound);

  const refocusQueue = () => {
    setTimeout(() => {
      if (scanMatchInput.current) {
        scanMatchInput.current.focus()
      }
    }, 1000)
  }


  const handleSubmit = (event) => {
    event.preventDefault();

    //check if input is empty
    if (_.isEmpty(inputValue)) {
      notification.error({
        message: 'Please scan a barcode',
        duration: 1
      });
      return;
    }

    //if scannedBlock is empty, it means it's a new scan and should retreive the line from api
    if (_.isEmpty(blocks)) {

      const payload = {
        scan_type_id: scanTypeId,
        barcode: inputValue,
      }

      setLoading(true);
      ZenSmartAPI.post(matchScanBlockRoute, payload)
        .then((response) => {

          setLoading(false);
          // setBlocks with the data and add the current barcode to scannedBarcode
          setScannedBarcode([...scannedBarcode, inputValue]);

          setBlocks(response.data);

          notification.success({ message: "Barcode scanned." })
          setInputValue('');

        }).catch((error) => {
          setLoading(false);
          setInputValue('');
          notification.error({
            message: _.get(error, 'response.data.message',
              'An issue occured while scanning the barcode. Please try again.')
          });
        });

      return;
    }

    //check if barcode already scanned
    const isBarcodeScanned = _.find(scannedBarcode, (barcode) => barcode === inputValue);
    if (isBarcodeScanned) {
      notification.error({
        message: `Block ${inputValue} has already been scanned - please check and try again`
      });
      setInputValue('');
      return;
    }

    // if blocks is not empty, check if input is a sibling block 
    const siblingBlock = _.find(blocks.data, (block) => block.barcode === inputValue);
    if (!siblingBlock) {
      notification.error({
        message: `Block ${inputValue} does NOT match the first Block scanned - please check and try again`
      });
      setInputValue('');
      return;
    }

    // if sibling block is found, add it to scannedBarcode
    setScannedBarcode([...scannedBarcode, inputValue]);
    setInputValue('');

    // if all blocks are scanned, show success message and clear blocks and scannedBarcode so that a new scan session can start
    if (scannedBarcode.length === blocks.data.length - 1) {
      successAudio.play();
      notification.success({ message: "Block match success!" });
      setBlocks([]);
      setScannedBarcode([]);
      return;
    }

    //adding notification for sibling block scanned since there can be more than 2 blocks
    if(siblingBlock){
      notification.success({ message: "Barcode scanned." })
    }

  };

  const handleKeyDown = (event) => {
    //esc key to close the modal
    if (event.keyCode === 27) {
      onCancel();
    }

    //enter key to submit
    if (event.keyCode === 13) {
      handleSubmit(event);
    }
  };

  //on dialog close clear blocks and scannedBarcode
  useEffect(() => {    
    if (!visible) {
      setBlocks([]);
      setScannedBarcode([]);
      setInputValue('');
    }
  }, [visible]);


  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={maskClosable}
      centered
      width={500}
      footer={null}
    >
      <div>
        <Row>
          <div style={{ textAlign: 'center' }}>
            <Label className="mb-5" status={'blue'}>
              MATCH CHECK
            </Label>
          </div>
        </Row>
        <Row className="mb-5" style={{ display: 'flex', justifyContent: 'center' }}>
          <Input
            type="text"
            placeholder="Scan Barcode for Match Check"
            value={inputValue}
            autoFocus={true}
            onBlur={refocusQueue}
            onKeyDown={handleKeyDown}
            onChange={(event) => setInputValue(event.target.value)}
            ref={scanMatchInput}
          />
        </Row>

        <Row type="flex" justify='end'>
          <Col className='mr-2'>
            {!_.isEmpty(blocks) && (
              <div style={{ textAlign: 'center' }}>
                Total of siblings blocks to be scanned: <b> {blocks.data.length - scannedBarcode.length} left </b>
              </div>
            )}
          </Col>
          <Col >
            <CenterButton>
              <Button
                type="primary"
                disabled={loading}
                onClick={handleSubmit}>Scan
              </Button>
            </CenterButton>
          </Col>
          <Col offset={1}>
            <CenterButton>
              <Button
                type="danger" ghost
                onClick={onCancel}
                disabled={loading}
              >Close
              </Button>
            </CenterButton>
          </Col>
        </Row>

      </div>
    </Modal>
  );
}

export default MatchCheckDialog;
