import React, { useState } from "react";
import { Button, Select, Modal, Form, Input, Checkbox, DatePicker, notification } from 'antd';
import styled from "styled-components";
import { ZenSmartAPI } from 'utils';
import { saveSingleTaskRoute } from "utils/apiRoutes"
import { useSelector } from 'react-redux';
import moment from 'moment';
const { TextArea } = Input;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`

const StyledDatePicker = styled(DatePicker)`
  margin: 5px 0;
  max-width: 150px;
`

const parseAPIResponseErrors = (response, errorMsgPlaceholder) => {
  let errorMessage = '';
  if(response && (response.errors !== undefined || response.validationMessages !== undefined)) {
    errorMessage += response.message ? `${response.message} ` : '';
    const errors = response.errors ? Object.values(response.errors) : Object.values(response.validationMessages);
    errors.forEach(err => errorMessage += `${err} `);
  } else {
    errorMessage = errorMsgPlaceholder;
  }
  return errorMessage;
}

const notifyAPIErrors = (response, errorMsgPlaceholder) => {
  let errorMessage = [];
  if(response && (response.errors !== undefined || response.validationMessages !== undefined)) {
    const errors = response.errors ? Object.values(response.errors) : Object.values(response.validationMessages);
    errors.forEach(err => errorMessage.push(`${err} `));
  } else {
    errorMessage.push(errorMsgPlaceholder)
  }
  errorMessage.forEach(err => {
    notification.error({
      message: err
    })
  });
}

const TaskFormModal = ({deviceClass, allClasses, ...props}) => {
  const { task, setTask, show, setShow, loadTasks } = props;
  const classes = ['start-up', 'running', 'shutdown', 'maintenance'];
  const applicableDaysOptions = ['Weekly Roster', 'Specific Date'];
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [ isInit, setIsInit ] = useState(false);
  const [ selectedApplicableDaysOption, setSelectedApplicableDaysOption ] = useState('');
  const [ applicableDays, setApplicableDays ] = useState([]); 
  const [ applicableDates, setApplicableDates ] = useState([moment()]);
  const machine = useSelector(state => state.machine);
  const [isRecursive, setIsRecursive] = useState(true)

  if(show && !isInit && selectedApplicableDaysOption === '') {
    let frequencyType = 'Weekly Roster';
    if(task) 
      frequencyType = task.frequency.length > 0 && task.frequency[0].match(/\d/g) ? 'Specific Date' : 'Weekly Roster';
    setSelectedApplicableDaysOption(frequencyType);
  }

  if(show && !isInit && selectedApplicableDaysOption) {
    if(task) {
      if(selectedApplicableDaysOption === 'Weekly Roster') {
        let currentDays = task.frequency.split(',').map(day => day.trim())
        if(JSON.stringify(currentDays) !== JSON.stringify(applicableDays)) {
          setApplicableDays(currentDays)
        }
      } else {
        let specificDates = task.frequency.split(',').map(date => moment(date.trim()));
        if(JSON.stringify(specificDates) !== JSON.stringify(applicableDates)) {
          setApplicableDates(specificDates)
          setIsRecursive('recursive' in task ? task.recursive : false)
        }
      }
    } else {
      setApplicableDays([]);
      setApplicableDates([moment()]);
    }
    setIsInit(true);
  }

  const changeApplicableDays = (checked, day) => {
    const daysList = [...applicableDays];
    checked === false ? daysList.splice(daysList.indexOf(day),1) : daysList.push(day);
    daysList.sort((a,b) => {
      return days.indexOf(a) - days.indexOf(b)
    })
    setApplicableDays(daysList);
  }

  const changeApplicableDate = (val, index) => {
    const dates = [...applicableDates];
    dates[index] = val;
    setApplicableDates(dates);
  }

  const changeFrequencyType = (val) => {
    setSelectedApplicableDaysOption(val);
    setApplicableDays([]);
    setApplicableDates([moment()]);
  }

  const onChangeTask = (field,value) => {
    setTask({
      ...task,
      [field]: value
    })
  }

  const saveTask = () => {  
    const dates = [...applicableDates].map(date => date.format('DD-MMM-YYYY'));
    const ploadItem = allClasses.find(el => el.device_type === deviceClass);
    const { device_type } = ploadItem;
    const payload = {
      ...task, 
      class: task && task.class ? task.class : 'start-up', 
      device_type: device_type,
      frequency: selectedApplicableDaysOption === 'Weekly Roster' ? applicableDays : dates,
      sort_order: task && task.sort_order ? task.sort_order : machine.tasks.length + 1,
      ...(selectedApplicableDaysOption === 'Specific Date' && { recursive: isRecursive})
      // station_id: station_id
    }

    ZenSmartAPI.post(saveSingleTaskRoute, payload)
    .then(res => {
      if(res.data && res.data.data) {
        notification.success({
          message: task.id ? 'Successfully updated task' : 'Successfully added new task.'
        })
        setShow(false);
        loadTasks();
      } 
    })
    .catch(err => {
      notifyAPIErrors(err.response.data, 'Saving task failed.')   
    })
  }

  const onCloseForm = () => {
    setTask(null); 
    setIsInit(false);
    setSelectedApplicableDaysOption('');
  }

  return (
    <Modal
      title={ task && task.id ? 'Edit Task' : 'Create New Task'}
      visible={show}
      onCancel={ () => setShow(false) }
      afterClose={ () => onCloseForm() }
      footer={[
        <Button key="submit" type="primary" onClick={ saveTask }>
          { task && task.id ? 'Edit' : 'Create' }
        </Button>,
        <Button key="back" onClick={ () => setShow(false) }>
          Cancel
        </Button>
      ]}
    > 
      <Form>
        <StyledFormItem label="Class" name="class">
          <Select 
            placeholder="Select class" 
            value={ task && task.class ? task.class : 'start-up'} 
            onChange={val => onChangeTask('class', val) }
          >
            { classes.map( className =>
              <Select.Option key={className}>{className}</Select.Option>
            )}
          </Select>       
        </StyledFormItem>

        <StyledFormItem label="Title" name="title">
          <Input 
            placeholder="Enter title" 
            value={ task && task.title ? task.title : '' } 
            onChange={e => onChangeTask('title', e.target.value) }
          />
        </StyledFormItem>

        <StyledFormItem label="Description" name="description">
          <TextArea 
            placeholder="Enter description" 
            value={ task && task.description ? task.description : '' } 
            onChange={e => onChangeTask('description', e.target.value) }
          />
        </StyledFormItem>

        <StyledFormItem label="Applicable days" name="applicable_days">
          <Select
            placeholder="Select applicable days"
            value={ selectedApplicableDaysOption }
            onChange={ val => changeFrequencyType(val) }
          >
            { applicableDaysOptions.map(applicableDay => 
              <Select.Option key={applicableDay}>{ applicableDay }</Select.Option>
            )}
          </Select>
          { selectedApplicableDaysOption === 'Weekly Roster' ? 
            days.map(day => 
            <Checkbox checked={ applicableDays.indexOf(day) >= 0 ? true : false } onChange={ e => changeApplicableDays(e.target.checked, day) }>{ day }</Checkbox>
          )
          :
          <>
            <Button type="success" onClick={() => setApplicableDates([...applicableDates, moment()])}>Add Specific Date</Button>
            <Checkbox style={{ marginLeft: '1.5rem'}} checked={isRecursive} onChange={() => setIsRecursive(!isRecursive)}>Recursive</Checkbox>
            { applicableDates.map((date,index) => 
            <div >
              <StyledDatePicker 
                value={date}
                allowClear={false} 
                onChange={ val => changeApplicableDate(val,index) }
              />
              <Button style={{ marginLeft: '2.5rem'}} type="danger" ghost size="small" onClick={() => {
                const dates = [...applicableDates]
                setApplicableDates(dates.filter(d => d !== date))
              }}>Remove</Button>
            </div>
            )}
          </>
          }
        </StyledFormItem>
      </Form>
    </Modal>
  );
}

export default TaskFormModal

