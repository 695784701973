import React, { useState } from "react";
import { Panel } from "components/primaries";
import styled from "styled-components";
import { color } from "components/zensmart-design-system/shared/styles.js";
import { Table, notification } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import TaskHeader from "../partials/TaskHeader";
import { useSelector } from "react-redux";
import { ZenSmartAPI } from "utils";
import { taskOverviewRoute } from "utils/apiRoutes";
import LogModal from "./LogModal";
import moment from "moment";
import { Header, Title, StyledDivider, SubtitleStyle } from "../styles";

const StyledTable = styled(Table)`
  margin-top: 3rem;
  thead > tr > th {
    background: transparent;
  }

  tbody tr:nth-child(odd) {
    background: #f2f4fd;
  }

  .ant-table-thead > tr,
  .ant-table-tbody > tr {
    font-size: 12px;
  }

  tr > th:nth-child(1),
  tr > td:nth-child(1) {
    padding-left: 20px;
  }

  tr > td:last-child {
    padding-right: 50px;
  }

  .ant-pagination {
    margin: 20px 50px;
  }

  .ant-table-thead > tr > th:first-child {
    writing-mode: initial;
    padding-left: 20px;
    min-width: 300px;
  }

  .ant-table-thead > tr > th:last-child {
    width: 200px;
  }

  .ant-table-thead > tr > th {
    padding: 8px 5px;
    font-weight: 600;
    height: 100px;
    max-width: 50px;
  }

  .ant-table-thead .week-days,
  .ant-table-thead .week-days-first {
    writing-mode: sideways-lr;
  }

  .ant-table-thead .week-days-first {
    position: relative !important;
    bottom: -8px !important;
  }

  .week-date,
  .week-date-fri {
    position: relative;
    top: -50px;
    width: 120px;
  }

  .week-date-fri {
    top: -29px;
  }
`;

const IconStyle = `
  font-size: 12px;
`;

const Check = styled(CheckOutlined)`
  color: ${color.mountain_meadow.dark};
  ${IconStyle}
`;

const Close = styled(CloseOutlined)`
  color: ${color.red.dark};
  ${IconStyle}
`;

const Blank = styled.div`
  background: #d4d4d4;
  width: 35px;
  height: 20px;
`;

const Link = styled.span`
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;
`;

const Overview = () => {
  const [logModalData, setLogModalData] = useState(null);
  const [showLogModal, setShowLogModal] = useState(false);
  const [isFault, setIsFault] = useState(false);
  const [table, setTable] = useState({ columns: [], data: [] });

  const machine = useSelector((state) => state.machine);

  const loadTasksOverview = (station, start_date, end_date) => {
    setTable({ columns: [], data: [] });
    ZenSmartAPI.get(taskOverviewRoute(station.barcode, start_date, end_date))
      .then((res) => {
        transformOverviewData(res.data, start_date, end_date);
      })
      .catch((res) => {
        notification.error({
          message: "Loading of task overview failed.",
        });
      });
  };

  const overviewItemResult = (item, key, index) => {
    const itemDay = moment(machine.overview.start_date)
      .add(index, "days")
      .format("ddd");
    const itemResult = item.frequency.includes(itemDay) ? <Close /> : <Blank />;
    return itemResult;
  };

  const transformItemsToTableRow = (data) => {
    let tableDatas = [];
    const taskClasses = Object.keys(data);
    taskClasses.forEach((taskClass, index) => {
      let key = 0;
      const datas = data[taskClass].map((item) => {
        let history = {};
        Object.entries(item.history).forEach((key, index) => {
          if (taskClass === "faults") {
            history[key[0]] =
              Array.isArray(key[1]) && key[1].length > 0 ? (
                <Link onClick={() => showEventSummary(key[1], true)}>
                  {key[1].length}
                </Link>
              ) : (
                "-"
              );
          } else {
            history[key[0]] =
              Array.isArray(key[1]) && key[1].length > 0 ? (
                <Check onClick={() => showEventSummary(key[1])} />
              ) : (
                overviewItemResult(item, key[0], index)
              );
          }
        });
        key += 1;
        return {
          key: `${taskClass}-${index}-${key}`,
          title: item.title,
          ...history,
        };
      });
      tableDatas = [...tableDatas, ...datas];
    });
    return tableDatas;
  };

  const transformOverviewData = (data, start_date, end_date) => {
    let overviewTable = {
      columns: [],
      data: {
        faults: [],
        start_up: [],
        running: [],
        shutdown: [],
      },
    };
    data.faults.map((fault) => {
      if (overviewTable.columns.length > 0) return null;
      else {
        overviewTable = {
          ...overviewTable,
          data: {
            ...overviewTable.data,
            faults: {
              title: fault.title,
              history: fault.history,
            },
          },
          columns: Object.keys(fault.history),
        };
      }
    });

    let columns = overviewTable.columns.map((day, index) => {
      const weekDay = moment(start_date).add(index, "days").format("ddd");
      const isLastItem = overviewTable.columns.length - 1 === index;
      return {
        title: (
          <>
            <span
              className={
                weekDay === "Mon" || index === 0
                  ? "week-days-first"
                  : "week-days"
              }
            >
              {weekDay}
            </span>
            {(weekDay === "Mon" || index === 0) && (
              <div
                className={weekDay !== "Fri" ? `week-date` : `week-date-fri`}
              >
                {getWeekRange(index, start_date)}
              </div>
            )}
          </>
        ),
        dataIndex: day,
        key: day,
        width: weekDay === "Sun" || isLastItem ? "140px" : "20px",
      };
    });

    setTable({
      ...table,
      columns: [
        {
          title: "Tasks",
          dataIndex: "title",
          key: "title",
          width: "300px",
        },
        ...columns,
      ],
      data: [...transformItemsToTableRow(data)],
    });
  };

  const getWeekRange = (index, start_date) => {
    const rangeEnd =
      index === 0 && moment(start_date).day() !== 1
        ? 7 - moment(start_date).day()
        : 6;
    return `${moment(start_date)
      .add(index, "days")
      .format("DD MMM")} - ${moment(start_date)
      .add(index + rangeEnd, "days")
      .format("DD MMM")}`;
  };

  const showEventSummary = (data, isFaultLog = false) => {
    setShowLogModal(true);
    setLogModalData(data);
    setIsFault(isFaultLog);
  };

  return (
    <>
      <LogModal
        setShow={setShowLogModal}
        show={showLogModal}
        data={logModalData}
        setData={setLogModalData}
        isFault={isFault}
        setIsFault={setIsFault}
      />
      <Header>
        <Title>Machine {">"} Task Management Overview</Title>
      </Header>
      <Panel title="TASKS MANAGEMENT">
        <TaskHeader
          loadTasks={() => null}
          loadTasksOverview={loadTasksOverview}
          overview="tasks"
          showOverview={true}
        />
        <StyledDivider />
        <SubtitleStyle>MANAGEMENT OVERVIEW</SubtitleStyle>

        <StyledTable
          dataSource={table.data}
          columns={table.columns}
          rowKey="key"
          scroll={{ x: true }}
        />
      </Panel>
    </>
  );
};

export default Overview;
