import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from "styled-components";
import { useMenu } from 'context/menuContext'
import { color } from "components/zensmart-design-system/shared/styles.js";
import { Table, Checkbox, notification } from 'antd'
import { Panel } from "components/primaries";
import { alphabeticalData } from 'utils/sortData';
import { ZenSmartAPI } from "utils";
import { permissionsRoute, operatorPermissionsRoute } from "utils/apiRoutes";

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;

const StyleTable = styled.div`
  font-family: "Rubik", sans-serif;
`;

const TableStyling = createGlobalStyle`
  tr, td {
    transition: all 0s !important;
  }
`;

function PermissionsTable() {
  const [permissions, setPermissions] = useState([])
  const [operators, setOperators] = useState([])
  const [updateIndex, setUpdateIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [, setScrolled] = useState(false)
  const [, setMenuOpen] = useMenu()

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 140) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  const updateUserPermissions = (user, index, data) => {
    const permissions = (data && data.length > 0) ? data.map(d => d.code) : []
    const updatedOperators = [...operators]
    updatedOperators[updateIndex+index].permissions = permissions
    setOperators(updatedOperators)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    ZenSmartAPI.get(permissionsRoute)
      .then((res) => {
        const allPermissions = alphabeticalData(res.data.data, "name")
        const modifiedPermissions = allPermissions.map(p => (
          {
            title: p.name,
            dataIndex: p.code,
            key: p.code,
            width: 100,
            render: (value, row, index) => {
              if (row.permissions && row.permissions.includes(p.code)) {
                return <Checkbox checked={true} onChange={() => {
                  ZenSmartAPI.delete(`/api/v1/users/${row.id}/permissions/${p.code}`).then(response => {
                    updateUserPermissions(row.id, index, response.data.data)
                  }, (e) => notification.error({
                    message: 'Could not update user permission'
                  }))
                }} />
              } else {
                return <Checkbox checked={false} onChange={() => {
                  ZenSmartAPI.post(`/api/v1/users/${row.id}/permissions/${p.code}`).then(response => {
                    updateUserPermissions(row.id, index, response.data.data)
                  }, (e) => notification.error({
                    message: 'Could not update user permission'
                  }))
                }} />
              }
            }
          }
        ))
        setPermissions(modifiedPermissions)
      })
      .catch((res) => {
        notification.error({
          message: "Failed to load list of permissions.",
        });
      });
  }, [operators, updateIndex])

  // Get operators and their permissions
  useEffect(() => {
    setMenuOpen(false)
    setIsLoading(true)
    ZenSmartAPI.get(operatorPermissionsRoute)
      .then((res) => {
        const ordered = alphabeticalData(res.data.data, "name")
        setOperators(ordered)
        setIsLoading(false)
      })
      .catch((res) => {
        setIsLoading(false)
        notification.error({
          message: "Failed to load list of operators.",
        });
      });
  }, [])

  const columns = [
    {
      title: "Operator",
      dataIndex: "name",
      key: "name",
      width: 120,
      fixed: "left"
    },
    ...permissions
  ]

  return (
    <>
      <Header>
        <Title>People {">"} Permissions</Title>
      </Header>
      <Panel>
        <TableStyling />
        <StyleTable>
          <Table
            columns={columns}
            dataSource={operators}
            loading={isLoading}
            scroll={{ x: '100vw', y: 'calc(100vh - 280px)'}}
            rowKey="id"
            size="middle"
            pagination={{ defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['20', '30', '50', '100', '150']}}
            hideOnSinglePage
            sticky
            onChange={({current, defaultPageSize}) => {
              const index = (current - 1) * defaultPageSize
              setUpdateIndex(index)
            }}
          />
        </StyleTable>
      </Panel>
    </>
  );
}

export default PermissionsTable