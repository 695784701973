import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Input, Select, Checkbox } from 'antd';
import { InputSection } from '../composites';
import { GetLabel } from '../../utils';
import _ from 'lodash';

const { TabPane } = Tabs
const { Option } = Select

const ChartSelection = ({
  visible,
  handleOK,
  handleCancel,
  loadConfiguration,
  availableCharts = {},
  groups = [],
  chartSize = [],
  matrixType,
  stackableChartTypes
}) => {
  const [configuration, setConfiguration] = useState({})

  useEffect(() => {
    if (!_.isEmpty(loadConfiguration)) {
      setConfiguration(loadConfiguration)
    }
    else {
      setConfiguration({ xAxis: 'record count', yAxis: Object.values(groups).length === 1 ? groups[0] : '' })
    }
  }, [loadConfiguration, groups]);

  const setTargetConfig = (value) => {
    setConfiguration({ ...configuration, target: value.target.value })
  }
  const setMultipleCharts = (value) => {
    setConfiguration({ ...configuration, multiple_charts: value })
  }
  const stackSection = (
    <InputSection
      label={'Stacked Data'}
      style={{
        marginBottom: 20
      }}
    >
      <Select
        style={{ width: '100%' }}
        value={configuration.stackData ? configuration.stackData : ''}
        onSelect={val => setConfiguration(configuration => {
          configuration.stackData = val
          return { ...configuration }
        })}
      >
        {configuration.stackData && <Option value={null}>&nbsp; </Option>}
        {Object.values(groups).map(column => (
          column !== configuration.yAxis && <Option value={column}>{GetLabel(column)}</Option>
        ))}
      </Select>
    </InputSection>
  )
  
  return (
    <Modal title='Chart Editor' visible={visible} onCancel={handleCancel} onOk={() => {
      handleOK(configuration)
      // setConfiguration(configTemplate)
    }}>
      <div style={{
        width: '100%'
      }}>
        <Tabs defaultActiveKey='1'>
          <TabPane tab="Chart Data" key="1">
            <InputSection label='Select chart type:'
              style={{
                marginBottom: 20
              }}
            >
              <Select
                style={{ width: '100%' }}
                value={configuration.type ? configuration.type : ''}
                onSelect={(val) => setConfiguration(configuration => {
                  configuration.type = val
                  return { ...configuration }
                })}>
                {Object.keys(availableCharts).map((column, index) => (
                  <Option key={index} value={availableCharts[column]}>{column}</Option>
                ))}
              </Select>
            </InputSection>
            <InputSection
              label={configuration.type ? (configuration.type === 'PieChart' ? 'Slice Quantity' : 'Y-axis:') : 'Y-axis:'}
              style={{
                marginBottom: 20
              }}
            >
              <Select
                value={matrixType ? matrixType : (
                  configuration.xAxis ? configuration.xAxis : 'record count'
                )}
                onSelect={val => setConfiguration(configuration => {
                  configuration.xAxis = val
                  return { ...configuration }
                })}
                style={{ width: '100%' }}
              >
                {matrixType ? (
                  <Option value={matrixType}>{matrixType}</Option>
                ) : (
                  <Option value='record count'>Record Count</Option>
                )}
              </Select>
            </InputSection>
            <InputSection
              label={configuration.type ? (configuration.type === 'PieChart' ? 'Slice Object' : 'X-axis:') : 'X-axis:'}
              style={{
                marginBottom: 20
              }}
            >
              <Select
                style={{ width: '100%' }}
                value={Object.values(groups).length === 1 ? GetLabel(Object.values(groups)[0]) : configuration.yAxis ? configuration.yAxis : ''}
                onSelect={val => setConfiguration(configuration => {
                  configuration.yAxis = val
                  return { ...configuration }
                })}
              >
                {Object.values(groups).map(column => (
                  <Option value={column}>{GetLabel(column)}</Option>
                ))}
              </Select>
            </InputSection>
            {configuration.type === "BarChart" || configuration.type === "ColumnChart" || configuration.type === "LineChart" || configuration.type === "Line" ?
              <InputSection label='Target:'
                style={{
                  marginBottom: 20
                }}
              >
                <Input
                  value={configuration && configuration.target ? configuration.target : null}
                  type="number"
                  onChange={setTargetConfig}
                  style={{ width: '100%' }}
                />
              </InputSection>
              : null
            }
            {(
              groups.length > 1 &&
              stackableChartTypes.includes(configuration.type) && // all except wordtree and pie chart
              stackSection
            )}
            {(configuration.type === "BarChart" || configuration.type === "ColumnChart") && (
              < InputSection label='SHOW VALUES:' style={{ padding: "10px 0px" }}>
                <Checkbox
                  checked={configuration && configuration.showValues ? configuration.showValues : false}
                  onChange={val => setConfiguration(configuration => {
                    configuration.showValues = val.target.checked
                    return { ...configuration }
                  })}
                >
                </Checkbox>
              </InputSection>
            )}
            {groups.length > 1 &&
              stackableChartTypes.includes(configuration.type) && // all except wordtree and pie chart
              < InputSection label='STACKED PERCENTAGE:' style={{ padding: "20px 0px" }}>
                <Checkbox
              checked={configuration && configuration.stacked_percentage ? configuration.stacked_percentage : false}
              onChange={val => setConfiguration(configuration => {
                configuration.stacked_percentage = val.target.checked
                return { ...configuration }
              })}
            >
            </Checkbox>
              </InputSection>
            }
          </TabPane>
        <TabPane tab="Formatting" key="2">
          <InputSection
            label=' Chart Title:'
            style={{
              marginBottom: 20
            }}
          >
            <Input
              defaultValue={configuration.title}
              onChange={e => {
                const newConfiguration = { ...configuration }
                newConfiguration.title = e.target.value
                setConfiguration(newConfiguration)
              }}
              style={{ width: '100%' }}
            />
          </InputSection>
          <InputSection label=' Chart Size:'>
            <Select
              value={configuration.size}
              onSelect={size => {
                const newConfiguration = { ...configuration }
                newConfiguration.size = size
                setConfiguration(newConfiguration)
              }}
              style={{ width: '100%' }}
            >
              {chartSize.map((size, index) => (
                <Option
                  value={size}
                  key={index}
                >
                  {size}
                </Option>
              ))}
            </Select>
          </InputSection>
          {groups && groups.length > 1 && configuration.stackData && stackableChartTypes.includes(configuration.type) &&
              <InputSection label='MULTIPLE CHARTS:' style={{ padding: "20px 0px" }}>
                <Checkbox
                  checked={configuration && configuration.multiple_charts ? configuration.multiple_charts : false}
                  onChange={(e) => setMultipleCharts(e.target.checked)}
                >
                </Checkbox>
              </InputSection>
            }
        </TabPane>
        </Tabs>
      <div>

      </div>
      </div>
    </Modal >
  )
}

export default ChartSelection