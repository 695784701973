import React, {useState} from 'react';
import {Header, Title} from 'components/primaries';
import {DataListPage} from "pages/DataListPage"
import {Panel} from 'components/primaries'
import {Modal, notification} from "antd";
import {ZenSmartAPI} from "../../../utils";
import {getEmailTemplate} from "../../../utils/apiRoutes";

const EmailTemplate = () => {
    const deleteEmailTemplate = (row, refreshData) => {
        Modal.confirm({
            'content': "Are you sure you want to delete this Email Template?",
            onOk: () => {
                ZenSmartAPI.delete(getEmailTemplate(row.id))
                    .then((res) => {
                        notification.success({message: "Delete Email Template successful!"})
                        refreshData();
                    })
                    .catch((res) => {
                        console.log('res', res);
                        if (res.response.data) {
                            notification.error({message: res.response.data.message})
                        }
                    })
            },
        });
    }
    return (
        <>
            <Header>
                <Title>App Setup {">"} Email Templates </Title>
            </Header>
            <Panel title="EMAIL TEMPLATES">
                <DataListPage model="emailTemplates" select={['id', 'name', 'html_source', 'required_component_rules']}
                              link={"app/data-view/email-templates/"} hidden={['id']} sort={"name"} pageSize={15}
                              addListButton={{
                                  buttonLabel: "Add Email Tempolate",
                                  actionApi: "/api/v1/data-api/email-templates",
                                  offset: 15,
                                  span: 3
                              }}
                              actionButtons={
                                  [{
                                      buttonLabel: "DELETE",
                                      buttonType: "danger",
                                      buttonAction: "normal",
                                      actionFunction: deleteEmailTemplate,
                                      reloadData: true
                                  }]
                              }
                />
            </Panel>
        </>
    )
}
export default EmailTemplate