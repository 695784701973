import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {
  Form,
  Checkbox,
  Input,
  Select,
  DatePicker,
  AutoComplete,
  Modal,
  notification
} from 'antd'
import { alphabeticalData } from 'utils/sortData';
import { ZenSmartAPI } from 'utils'

import { FIELD } from 'types'
import { color } from 'components/zensmart-design-system/shared/styles.js'

const ErrorList = styled.ul`
  list-style-type: inherit;
  margin-right: 16px;

  & > li {
    margin-bottom: 8px;
    color: ${color.red.normal};
  }
`
const { TextArea } = Input;

function useDataInputForm(props) {
  const { getFieldError, getFieldDecorator, getFieldValue, setFieldsValue } = props.form
  const { field } = props
  const [addNewFeatureOptions, setAddNewFeatureOptions] = useState({})
  const [itemForMultipleSelect, setItemForMultipleSelect] = useState([])


  const getDataInput = (field) => {

    const fieldName = `${field.resource_name}:${field.resource_id}.${field.field}`

    const addNewDataSelect = (evt, options, endpoint, propertyName, resourceName, fieldValueProperty) => {

      const findOptions = options.some(data => data.text.toLowerCase() === evt.target.value.toLowerCase())

      if (evt.keyCode === 13 && !findOptions && evt.target.value !== "" && evt.target.value !== null) {
        const inputValue = evt.target.value
        Modal.confirm({
          'content': `Are you sure you want to create this (${inputValue})?`,
          onOk: () => {
            ZenSmartAPI.post(endpoint, { [propertyName]: inputValue }).then(res => {
              notification.success({ message: "Add new data successful!" })
              const resource = getFieldValue(resourceName)
              resource[fieldValueProperty] = res.data.data.id + ''
              setFieldsValue({
                [resourceName]: resource,
              })
              const optionData = options
              optionData.push({
                text: res.data && res.data.data.name ? res.data.data.name.charAt(0).toUpperCase() + res.data.data.name.slice(1) : null,
                value: `${res.data.data.id}`,
                selected: true
              })
              setAddNewFeatureOptions({ ...addNewFeatureOptions, [resourceName]: alphabeticalData(optionData, "text") })

            })
          },
        });

      }
    }

    const errors = getFieldError(fieldName)
    const validateStatus = errors ? 'error' : null

    const handleChange = (e) => {
      setItemForMultipleSelect(e)
    };
    const errorList = errors ? (
        <ErrorList>
          {errors.map(error => (
              <li key={error}>{error}</li>
          ))}
        </ErrorList>
    ) : null

    let input = null

    switch (field.data_type) {
      case FIELD.BOOLEAN: {
        input = getFieldDecorator(fieldName, {
          initialValue: field.value + '' === 'true',
          valuePropName: 'checked'
        })(<Checkbox />)
        break
      }

      case FIELD.SELECT: {
        const options = alphabeticalData(field.options, "text").map(option => (
            <Select.Option value={option.value + ''} key={option.value}>
              {option.text}
            </Select.Option>
        ))

        const defaultSelected = field.options.find(option => (
            option.text === field.value || option.value + '' === field.value + ''
        ))

        const initialValue = defaultSelected && defaultSelected.value + ''

        input = getFieldDecorator(fieldName, { initialValue })(
            <Select style={{ minWidth: 228 }}>{options}</Select>
        )
        break
      }
      case FIELD.MULTIPLE_TAG_SELECT: {
        const options = alphabeticalData(field.options, "text").map(option => (
            <Select.Option value={option.value + ''} key={option.value}>
              {option.text}
            </Select.Option>
        ))

        const findValues = typeof field.value === "string" ? field.value.split(',').map(function (item) {
          return item.trim();
        }) : field.value
        const defaultSelected = field.options.filter(option => {
          return (
              (findValues ? findValues.includes(option.text.trim()) : null)
          )
        }).map(function (obj) {
          return obj.value;
        });
        const initialValue = Array.isArray(field.value) ? field.value : defaultSelected && defaultSelected

        input = getFieldDecorator(fieldName, { initialValue })(
            <Select style={{ minWidth: 228 }} mode="multiple">{options}</Select>
        )
        break
      }

      case FIELD.ADD_FEATURE_SELECT: {

        const options = alphabeticalData(addNewFeatureOptions[`${field.resource_name}:${field.resource_id}`] ?
                                         addNewFeatureOptions[`${field.resource_name}:${field.resource_id}`] : field.options, "text").map(option => (
            <Select.Option
                value={option.value + ''}
                key={option.value}
            >
              {option.text}
            </Select.Option>
        ))

        const defaultSelected = field.options.find(option => (
            option.text === field.value || option.value + '' === field.value + ''
        ))

        const initialValue = defaultSelected && defaultSelected.value + ''

        input = getFieldDecorator(fieldName, { initialValue })(
            <Select
                style={{ minWidth: 228 }}
                optionFilterProp="children"
                showSearch
                onInputKeyDown={async (event) => {
                  addNewDataSelect(event,
                      field.options,
                      field.create_endpoint,
                      field.create_field,
                      `${field.resource_name}:${field.resource_id}`,
                      field.field)

                }}
                className={`${field.resource_name}:${field.resource_id}`}
            >{options}</Select>
        )
        break
      }

      case FIELD.TAG_SELECT: {
        const options = alphabeticalData(addNewFeatureOptions[`${field.resource_name}:${field.resource_id}`] ?
                                         addNewFeatureOptions[`${field.resource_name}:${field.resource_id}`] : field.options, "text").map(option => (
            <Select.Option
                value={option.value + ''}
                key={option.value}
            >
              {option.text}
            </Select.Option>
        ))

        let defaultSelected = field.options.filter(option => (
            (option.selected === true) && option.value
        )).map(function (obj) {
          return obj.text;
        });

        defaultSelected = defaultSelected.filter(function (e) { return e });

        if (itemForMultipleSelect.length == 0 && defaultSelected.length > 0) {
          if (Array.isArray(field.html_value)) {
            defaultSelected = field.html_value
          }
          setItemForMultipleSelect(defaultSelected)
        }

        const initialValue = { initialValue: itemForMultipleSelect };
        input = getFieldDecorator(fieldName, initialValue)(
            <Select
                style={{ minWidth: 228 }}
                mode="tags"
                optionFilterProp="children"
                showSearch
                className={`${field.resource_name}:${field.resource_id}`}
                onChange={(e) => handleChange(e)}
            >{options}</Select>
        )
        break
      }
      case FIELD.AUTOCOMPLETE: {
        const options = alphabeticalData(field.options, "text").map(option => (
            <Select.Option value={option.value ? option.value + '' : 'none'} key={option.value}>
              {option.text}
            </Select.Option>
        ))

        const defaultSelected = field.options.find(option => (
            option.text === field.value || option.value + '' === field.value + ''
        ))

        const initialValue = defaultSelected && defaultSelected.value + ''

        input = getFieldDecorator(fieldName, { initialValue })(
            <AutoComplete
                optionFilterProp="children"
                showSearch style={{ minWidth: 228 }}
            >{options}</AutoComplete>
        )
        break
      }

      case FIELD.DATE: {
        input = getFieldDecorator(fieldName, {
          initialValue: field.value ? moment(field.value) : null
        })(<DatePicker format="LL" />)
        break
      }
      
      case FIELD.DATETIME: {
        input = getFieldDecorator(fieldName, {
          initialValue: field.value ? moment(field.value) : null
        })(<DatePicker showTime />)
        break
      }

      case FIELD.DATETIME: {
        input = getFieldDecorator(fieldName, {
          initialValue: field.value ? moment(field.value) : null
        })(<DatePicker showTime />)
        break
      }

      case FIELD.DATETIME: {
        input = getFieldDecorator(fieldName, {
          initialValue: field.value ? moment(field.value) : null
        })(<DatePicker showTime />)
        break
      }

      case FIELD.DATETIME: {
        input = getFieldDecorator(fieldName, {
          initialValue: field.value ? moment(field.value) : null
        })(<DatePicker showTime />)
        break
      }

      case FIELD.DATETIME: {
        input = getFieldDecorator(fieldName, {
          initialValue: field.value ? moment(field.value) : null
        })(<DatePicker showTime />)
        break
      }

      case FIELD.TEXTAREA: {
        input = getFieldDecorator(fieldName, {
          initialValue: ( ( typeof field.value === 'string' && field.value.includes('\\n') === true) ? field.value.replaceAll('\\n','\n') :  field.value )
        })(<TextArea rows={24} />
        )
        break
      }

      default: {
        input = getFieldDecorator(fieldName, {
          initialValue: field.value
        })(<Input type="text" />)
        break
      }
    }

    return (
        <Form.Item
            validateStatus={validateStatus}
            help={errorList}
        >
          {input}
        </Form.Item>
    )
  }

  return getDataInput(field)
}
const UseDataInput = Form.create({
  name: 'UseDataInput'
})(useDataInputForm)

function GetDataInput(props) {
  const { field, form } = props
  return (
      <UseDataInput
          field={field}
          form={form}
      />
  )
}


export default GetDataInput
