import React, {useContext, useEffect, useState} from 'react';
import {Form, Input, InputNumber, Select as AntSelect} from 'antd';
import FormModal from '../../components/FormModal';
import styled from 'styled-components';
import {DesignerContext} from '../../DispatchDesignerContext';
import { alphabeticalData } from 'utils/sortData';

import QualifierInput
  from '../../../../../components/composites/QualifierInput';

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`;

const Select = styled(AntSelect)`
  width: 100%;
`;

const Number = styled(InputNumber)`
  width: 100%;
`;

const {Option} = Select


function ProviderFormModal(props) {

  const {stages, providers, types} = useContext(DesignerContext);
  const {stageData, form, ...modal} = props
  const [errors, setErrors] = useState([]);
  const [showQualifier, setShowQualifier] = useState(false);
  const [qualifierValue, setQualifierValue] = useState(null);
  const {setFieldsValue, getFieldDecorator, getFieldValue} = form;

  useEffect(() => {
    if (modal.visible) {
      let [nodeType, nodeId] = stageData.parentID.split('.');

      // no qualifier required
      if (nodeType === 'buckets' || nodeType === 'switches') {
        if (stageData.qualifier !== 'other') {

          if (nodeType === 'buckets') {
            nodeId = stages.buckets[nodeId].parent.index;
          }

          if (stages.switches[nodeId].nextNode.indexes !== null) {
            setShowQualifier(true);
            setQualifierValue(stageData.qualifier);
          }
        }
      }
    }
  }, [stageData])

  if (modal.visible === false) {
    return null;
  }



    const handleClose = () => {
    setShowQualifier(false);
    setShowQualifier(null);
  }

  const getPayload = () => {
    return {
      type: 'dispatch_provider',
      logistics_provider_id: getFieldValue('logistics_provider_id'),
      qualifier: qualifierValue,
      parent_node_id: stageData.parentID.split('.').reverse()[0]
    }
  }

  const renderFormItem = (field, placeholder, initialValue, input = null) => {

    let component = <Input />
    if(input) {
      component = input
    }

    return (
        <StyledFormItem label={placeholder}
                        validateStatus={errors[field] ? 'error' : null}
                        help={errors[field] && errors[field][0]}>
          {getFieldDecorator(field,
              {initialValue})(
              component
          )}
        </StyledFormItem>
    );
  };


  return (
      <FormModal
          {...modal}
          onClose={handleClose}
          onError={setErrors}
          getPayload={getPayload}
          stageData={stageData}
      >
        {showQualifier &&
        <StyledFormItem label={`Qualifier`}>
          <QualifierInput value={qualifierValue}
                          onChange={value => setQualifierValue(value)}
                          types={types.qualifiers}/>
        </StyledFormItem>
        }

        {renderFormItem(
            'logistics_provider_id',
            'Provider name',
            stageData.context ? stageData.context.id : null,
            <Select>
              {alphabeticalData(providers, "name").map(provider => (
                  <Option key={provider.id}
                          value={provider.id}>{provider.name}</Option>
              ))}
            </Select>,
        )}

      </FormModal>
  )
}

export default Form.create()(ProviderFormModal)