import React, { useState } from 'react'
import { Panel } from 'components/primaries'
import { Row, notification, Input } from 'antd'
import { ZenSmartAPI } from 'utils'
import {
  Header,
  Title,
  Heading,
  DateColStyle,
  StyleButton,
  InputStyle,
  FloatingButton
} from './component.js'
import CurrentStack from './CurrentStack'
import { fetchSheetCount } from 'utils/apiRoutes'

const UchidaInputGenerator = () => {
  const [batchBarCode, setBatchBarCode] = useState('')

  const [stack, setStack] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchBarCodeStack = async () => {
    try {
      if (!batchBarCode) return
      setLoading(true)
      const requestModel = { batch_barcode: batchBarCode }
      const networkResponse = await ZenSmartAPI.post(
        fetchSheetCount,
        requestModel
      )
      if (networkResponse?.data?.data.block_counts.length >= 1) {
        setStack(oldState => [
          { ...networkResponse?.data.data, block_name: batchBarCode },
          ...oldState
        ])
      } else {
        notification.info({
          message: `${batchBarCode} is empty`
        })
      }
      setBatchBarCode('')
    } catch (error) {
      notification.error({
        message:
          error?.response?.data?.message ??
          error.message ??
          'Failed to fetch data'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSearchInput = e => {
    if (!loading) {
      setBatchBarCode(e.target.value)
    }
  }

  const handleGenerateFile = () => {
    const element = document.createElement('a')
    let text = ''
    stack.forEach(batch => {
      batch.block_counts.forEach((block, i) => {
        const countSum =
          +(block?.batch_sheets ?? 0) +
          +(block?.job_sheets ?? 0) +
          +(block?.content_sheets ?? 0) +
          +(batch?.blank_sheets > 0 && i === batch.block_counts.length - 1
            ? batch.blank_sheets
            : 0)

        text += `${countSum} \n`
      })
    })
    const file = new Blob([text], { type: 'text/plain;charset=utf-8' })
    element.href = URL.createObjectURL(file)
    element.download = `Uchida-${new Date().toLocaleString()}.txt`
    document.body.appendChild(element)
    element.click()
    setStack([])
  }

  return (
    <>
      <Header>
        <Title>Machine {'>'} Uchida Input Generator</Title>
      </Header>
      <Panel title='Uchida Input Generator'>
        <div style={{ padding: '10px 20px 30px 10px' }}>
          <InputStyle />
          <Row type='flex' align='middle'>
            <DateColStyle span={6}>
              <Heading>Batch Barcode</Heading>
              <Input
                style={{
                  marginTop: 10,
                  width: '100%',
                  textTransform: 'capitalize'
                }}
                onPressEnter={fetchBarCodeStack}
                value={batchBarCode}
                onChange={handleSearchInput}
                placeholder='Input Batch Barcode'
              />
            </DateColStyle>
            <DateColStyle span={4}>
              <br />
              <StyleButton
                style={{ marginTop: 10 }}
                onClick={fetchBarCodeStack}
                loading={loading}
                type='primary'
              >
                GO
              </StyleButton>
            </DateColStyle>
          </Row>
        </div>
        <CurrentStack stack={stack} />
        {stack.length > 0 && (
          <FloatingButton onClick={handleGenerateFile}>
            Generate File
          </FloatingButton>
        )}
      </Panel>
    </>
  )
}

export default UchidaInputGenerator
