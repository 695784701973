import React, {useContext, useState} from 'react';
import {Button, Form, Modal, notification} from 'antd';
import {ZenSmartAPI} from '../../../../utils';
import {DesignerContext} from '../DispatchDesignerContext';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #e2e8f0;

  & > button {
    margin-left: 8px;
  }
`;

function FormModal(props) {
  const {updateStages, selectedProduct, clearModal} = useContext(DesignerContext);
  const {form, stageData, onError, onClose, children, ...modal} = props;
  const [loading, setLoading] = useState(false);

  const handleSubmit = evt => {
    evt.preventDefault();
    setLoading(true);
    const {id} = stageData;

    const handleError = (error) => {
      setLoading(false);
      const {response} = error;
      if (response.status === 422) {
        onError(response.data.errors);
        notification.error({
          message: 'There are errors in form.',
        });
        return;
      }

      if (response.status >= 400 && response.status < 500) {
        notification.error({
          message: response.message ? response.message : 'Something went wrong. Could not update dispatch designer.',
        });
        return;
      }

      if (response.status >= 500) {
        notification.error({
          message: 'Something went wrong. Could not update dispatch designer.',
        });
      }
    };

    Promise.resolve(props.getPayload()).then(data => {
      if (!id) {
        ZenSmartAPI.post(`/api/v1/products/${selectedProduct.id}/dispatch-nodes`,
            data).then(response => {
          updateStages(response.data);
          handleModalClose();
          notification.success({
            message: 'Stage added.',
          });
        }).catch(error => {
          handleError(error);
        });
      } else {
        ZenSmartAPI.put(
            `/api/v1/products/${selectedProduct.id}/dispatch-nodes/${id}`, data)
            .then(response => {
              updateStages(response.data);
              handleModalClose();
              notification.success({
                message: 'Stage updated.',
              });
            })
            .catch(error => {
              handleError(error);
            });
      }
    }).catch((error) => {
      handleError(error);
    })
  };

  const handleModalClose = () => {
    onClose();
    clearModal();
  };

  return (
      <Modal {...modal} destroyOnClose={true} bodyStyle={{paddingBottom: 8}} onCancel={handleModalClose} footer={null}>
        {modal.visible && (
            <Form autoComplete={`off`} onSubmit={handleSubmit}>
              {children}
              <ButtonContainer>
                <Button loading={loading} type="primary" htmlType="submit">Save
                  Changes</Button>
              </ButtonContainer>
            </Form>
        )}
      </Modal>
  );
}

export default FormModal;

