import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { productIdRoute, productSearchRoute, manualConsumptionRoute, getSingleWorkDataRoute } from "utils/apiRoutes"
import moment from 'moment';
import { failInitialData } from "utils/panelsInitialData"
import { debounce } from "utils/debounce"
import { alphabeticalData } from 'utils/sortData';

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
  padding: 10px;
`
const ColInputs = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;
const ManualConsumptionInputs = (props) => {
    const [productsDropDownValues, setProductsDropdownValues] = useState([])
    const { initialData, manualConsumptionPanel, setManualConsumptionPanel, manualConsumptionInitialState, loading, setLoading, fetchInitData } = props
    const [blockTypes, setBlockTypes] = useState([])
    const [scanStageType, setScanStageType] = useState([])
    const onSearchProduct = debounce((value, limit = null) => {
        if (value !== "" || limit) {
            ZenSmartAPI.post(productSearchRoute(value), { limit: limit, response_type: "product_flow" }).then(result => {
                setProductsDropdownValues(result.data.data)
            })
        }
    }, 400)

    const consumeWorkflow = () => {
        if (manualConsumptionPanel.product_id == null || manualConsumptionPanel.block == null ||
            manualConsumptionPanel.scan_type_id == null || manualConsumptionPanel.amount == null || manualConsumptionPanel.amount == "") {
            notification.error({ message: "Please input all manual consumption required fields!" })
            return
        }
        else {
            setLoading(true)
            ZenSmartAPI.post(manualConsumptionRoute, manualConsumptionPanel)
                .then((res) => {
                    setLoading(false)
                    notification.success({ message: "Manual consumption successful!" })
                    fetchInitData()
                }).catch((res) => {
                    setLoading(false)
                    notification.error({
                        message: _.get(res, 'response.data.errors') ? _.get(res, 'response.data.errors') : _.get(res, 'response.data.message'),
                    });
                })
        }
    }

    const getblockTypesAndStages = (value) => {
        setLoading(true)
        ZenSmartAPI.get(getSingleWorkDataRoute(value))
            .then((res) => {
                setLoading(false)
                setBlockTypes(res.data)

            }).catch((res) => {

            })
    }
    const updateManualConsumptionPanel = (value, key) => {

        if (key === "product_id") {
            setManualConsumptionPanel({ ...manualConsumptionPanel, [key]: value, block: null, scan_type_id: null })
            getblockTypesAndStages(value)
            return
        }
        if (key === "block") {
            const findBlockType = blockTypes.find(item => item.block_type === value)
            if (findBlockType) {
                setScanStageType(findBlockType.scan_types)
                setManualConsumptionPanel({ ...manualConsumptionPanel, scan_type_id: null, [key]: value })

            }
            return
        }

        if (value.target) {
            setManualConsumptionPanel({ ...manualConsumptionPanel, [key]: value.target.value })
        }
        else {
            setManualConsumptionPanel({ ...manualConsumptionPanel, [key]: value })
        }

    }
    useEffect(() => {
        onSearchProduct("", 20)
    }, [])


    return (
        <>
            <div style={{ margin: "10px 0px", borderBottom: "1px solid grey" }}>

                <Heading>Manual Consumption</Heading>
                <Row style={{ marginTop: 10, marginBottom: 10 }} type="flex" align="middle" justify="center">
                    <ColInputs span={6}>
                        <p style={{ fontWeight: "bold" }}>Product*</p>
                        <Select showSearch
                            placeholder="Search for a product"
                            optionFilterProp="children"
                            filterOption={true}
                            style={{ width: '100%' }}
                            value={manualConsumptionPanel.product_id}
                            onChange={(e) => updateManualConsumptionPanel(e, 'product_id')}
                            onSearch={(e) => onSearchProduct(e)} >
                            {alphabeticalData(productsDropDownValues, "code").map(prodDef => (
                                <Select.Option
                                    key={prodDef.id}
                                    value={prodDef.id}
                                >
                                    {prodDef.code}: {prodDef.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </ColInputs>
                    <ColInputs span={6}>
                        <p style={{ fontWeight: "bold" }}>Block*</p>
                        <Select value={manualConsumptionPanel.block}
                            onChange={(e) => updateManualConsumptionPanel(e, 'block')}
                            style={{ width: "100%" }} >
                            {blockTypes && alphabeticalData(blockTypes.map(data => data.block_type)).map(item => (
                                <Option style={{ textTransform: "capitalize" }}
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </Option>
                            ))}
                        </Select>
                    </ColInputs>
                    <ColInputs span={5}>
                        <p style={{ fontWeight: "bold" }}>Stage*</p>
                        <Select value={manualConsumptionPanel.scan_type_id}
                            onChange={(e) => updateManualConsumptionPanel(e, 'scan_type_id')} style={{ width: "100%" }} >
                            {scanStageType && alphabeticalData(scanStageType, "text").map(item => (
                                <Option style={{ textTransform: "capitalize" }}
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.text}
                                </Option>
                            ))}
                        </Select>
                    </ColInputs>
                    <ColInputs span={5}>
                        <p style={{ fontWeight: "bold" }}>Quantity*</p>
                        <Input value={manualConsumptionPanel.amount}
                            onChange={(e) => updateManualConsumptionPanel(e, 'amount')}
                            type="number" />
                    </ColInputs>
                    <ColInputs span={1}>
                        <br />
                        <Button type="primary" style={{ width: "100%" }} onClick={consumeWorkflow}>
                            GO
                        </Button>
                    </ColInputs>
                </Row>
            </div>
        </>
    )
}
export default ManualConsumptionInputs