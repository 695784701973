import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import { ratings } from "../Maintenance/ratings";

const RatingsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  width: 90%;
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RatingsMessage = styled.span`
  margin-left: 1em;
  font-weight: bold;
`;

const FeedbackContainer = styled.div`
  margin-top: 0.6rem;
  padding: 0 0.2rem;
`;

function WorkRating({ rating, feedback }) {
  const [stars, setStars] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    const values = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        values.push("fill");
      } else {
        values.push("outline");
      }
    }
    setStars(values);
  }, [rating]);

  const getRatingsMessage = (rating) => {
    return ratings.find((r) => r.value === rating).description;
  };

  const handleFeedbackToggle = () => {
    setShowFeedback(!showFeedback);
  };

  return (
    <>
      <Title>
        <span style={{ textTransform: "uppercase" }}>Rating</span>
        <Button
          type="link"
          style={{ fontWeight: 600 }}
          onClick={handleFeedbackToggle}
        >
          {showFeedback ? "Hide" : "Show"} Feedback
        </Button>
      </Title>
      <RatingsContainer>
        {stars.map((star, index) => {
          return star === "fill" ? (
            <StarFilled
              key={`${star}-${index}`}
              style={{
                marginRight: "4px",
                fontSize: "1.2em",
                color: "#fdcc0d",
              }}
            />
          ) : (
            <StarOutlined
              key={`${star}-${index}`}
              style={{
                marginRight: "4px",
                fontSize: "1.2em",
                color: "#fdcc0d",
              }}
            />
          );
        })}
        <RatingsMessage>{getRatingsMessage(rating)}</RatingsMessage>
      </RatingsContainer>
      {showFeedback && (
        <FeedbackContainer>
          <p>{feedback}</p>
        </FeedbackContainer>
      )}
    </>
  );
}

export default WorkRating;
