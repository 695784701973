import {
  BOOLEAN_TYPE,
  FLOAT_TYPE,
  INTEGER_TYPE,
  STRING_TYPE,
  TEXTAREA_TYPE,
  MULTIPLE_TYPE,
  MULTIPLE_NUMBER_TYPE
} from "modules/imposition/config/Imposition";

export const DROPDOWN = "Dropdown";

export const FormLabel = [
  { key: "degrees", value: "Degrees" },
  { key: "croplength", value: "Crop Length" },
  { key: "croptype", value: "Crop Type" },
  { key: ["xPosition", "X-Location"], value: "X" },
  { key: ["yPosition", "Y-Location"], value: "Y" },
];

export const FormTypes = [
  {
    key: ["Crop Type", "Color", "Text", "Side", "Type","All Images","Back Print", "Print on Pages", "Marker Type", "Refresh Lines Colors", "Text on Print", "Submission Sheet Text"],
    value: STRING_TYPE,
  },
  {
    key: ["Rotation", "Images Across", "Images Down",
      "Number of Lines", "Lanes", "Rows", "Lamination Whitespace", "SOJ Pages", "EOJ Pages", "Alignment Tool Rows",
      "Alignment Tool Cmyk Interval", "Sub Lanes Horizontal Gutter", "Font Size on Print", "Whitespace Length",  "Text Rotation on Print", "QC Sheet Copies", "Duplex Print Rotation"],
    value: INTEGER_TYPE,
  },
  {
    key: [
      "Radius",
      "Length",
      "Bleed",
      "X-Location",
      "Y-Location",
      "Font Size",
      "Sheet Width",
      "Sheet Height",
      "Spine Line Height",
      "Spine Line Weight",
      "Spine Line Bleed",
      "First Image X-Location",
      "First Image Y-Location",
      "Horizontal Gutter",
      "Vertical Gutter",
      "Width",
      "Height",
      "Gap",
      "First Line X",
      "First Line Y",
      "Length",
      "Scale",
      "Distance from edge",
      "Line Weight",
      "Lines Gap",
      "Template X-Offset",
      "From X",
      "From Y",
      "To X",
      "To Y",
      "Distance from Corner",
      "Cut Line Length",
      "Left Margin",
      "Top Margin",
      "Extra whitespace",
      "Size",
      "Lane Width",
      "Cut Line Width",
      "Die Line Height",
      "Die Line Width",
      "ToF X Location",
      "ToF Width",
      "ToF Height",
      "ToF Y Start",
      "Refresh Lines Length",
      "Refresh Lines Group",
      "Refresh Lines Y Offset",
      "Barcode Starting Y Location",
      "Barcode Distance",
      "Barcode Height",
      "Barcode Width",
      "Barcode X Location",
      "Barcode Y Location",
      "Eyemark X",
      "Eyemark Y",
      "Eyemark Width",
      "Eyemark Height",
      "Reverse Page Width",
      "Reverse Page Height",
      "Custom Sheet Width",
      "Custom Sheet Height",
      "Front Refresh Line X",
      "ToF Width Rear",
      "ToF Height Rear",
      "ToF X Location Rear",
      "ToF Y Start Rear",
      "Key Line Width",
      "Key Line X-Location",
      "Marker Size",
      "Wireframe Width",
      "Wireframe Height",
      "Alignment Tool Height",
      "Alignment Tool Middle Width",
      "Alignment Tool Cmyk Height",
      "Order Ident Page Length",
      "Order Ident Barcode X",
      "Order Ident Barcode Y",
      "Order Ident Barcode Page Width",
      "Order Ident Barcode Page Height",
      "Order Ident Barcode Height",
      "Order Ident Barcode Width",
      "Sub Lane X Gutter",
      "Alignment Tool Length",
      "Alignment Tool Length",
      "Alignment Tool Middle Width",
      "X-Location on Print",
      "Y-Location on Print",
      "Custom Sheet Width",
      "Custom Sheet Height",
      "Gap",
      "Degrees",
      "Frame Width",
      "Frame Height",
      "Resize Width",
      "Resize Height",
      "Limit Sheet Height",
      "Submission Sheet Height"
    ],
    value: FLOAT_TYPE,
  },
  { key: [
    "Collation Letter",
    "Cut and Stack",
    "With Text",
    "Limit Paper Wastage",
    "Stretch",
    "Barcode Only",
    "Follow Page Data",
    "Follow DTG Data",
    "Display Lamination Barcode",
    "Barcode With Text",
    "Opposite side on rear page",
    "Origin Top Left",
    "Transparency",
    "Duplicate Each Page",
    "Custom Background Color",
    "Is custom color",
    "Bold",
    "Single Line",
    "Zoom and Crop",
    "Force Single Sheet",
    "Maintain Aspect Ratio",
    "Include Spine Width",
    "Include In Batch Sheet",
    "Gen8 Template",
    "Alignment Tool Cmyk",
    "Overwrite First Page",
    "Repeating",
    "Input Includes Duplex Image",
    "Combo",
    "Add Spine Width",
    "First Lane Cut Line X",
    "Follow Page Data",
    "Combo",
    "Add Spine Width"
    ],
    value: BOOLEAN_TYPE
  },
  { key: ["Textblock","Batch Sheet Text"], value: TEXTAREA_TYPE },
  { key: ["Offcut Lanes", "Lane Widths", "Cut Lane Widths","Sub Lane X Offset", "Alignment Tool X Offset", "Alignment Tool Width", "Submission Sheet Offset", "Image Lines Alignment","Front and Rear Rotation"], value: MULTIPLE_NUMBER_TYPE },
];

export const FormValues = [
  {
    key: ["Rotation","Barcode Rotation","Text Rotation on Print","Duplex Print Rotation", "Degrees"],
    value: {
      type: DROPDOWN,
      placeholder: "Please select degrees",
      initValue: "0",
      values: ["0", "90", "180", "270"],
    },
  },
  {
    key: ["Degrees"],
    value: {
      placeholder: "Please select degrees",
      initValue: "0"
    },
  },
  {
    key: ["Gap"],
    value: {
      initValue: "1"
    }
  },
  {
    key: ["Line Weight"],
    value: {
      initValue: "1",
    },
  },
  {
    key: ["Font Size"],
    value: {
      initValue: "12",
    },
  },
  {
    key: "Crop Type",
    value: {
      type: DROPDOWN,
      placeholder: "Please select a crop type",
      values: [
        "Corners",
        "Corners Wrap",
        "Left Corners Wrap",
        "Bleed Inside",
        "Bleed In Out",
        "Top Bottom Corner Lines"
      ],
    },
  },
  {
    key: "All Images",
    value: {
      type: DROPDOWN,
      placeholder: "Please select a image type",
      initValue: "no directions",
      values: [
        "none",
        "no directions",
        "horizontal",
        "vertical",
        "first two vertical",
        "first two horizontal",
      ],
    },
  },
  {
    key: "Side",
    value: {
      type: DROPDOWN,
      placeholder: "Please select a side",
      values: ["TOP", "BOTTOM", "LEFT", "RIGHT", "AROUND", "TOP AND BOTTOM", "LEFT AND RIGHT"],
    },
  },
  {
    key: "Type",
    value: {
      type: DROPDOWN,
      placeholder: "Please select a type",
      values: ["Block", "Batch", "GP2", "Fast Barcode"],
    },
  },
  {
    key: "With Text",
    value: false,
  },
  {
    key: "Maintain Aspect Ratio",
    value: false,
  },
  {
    key: [
      "Sheet Width",
      "Sheet Height",
      "Spine Line Height",
      "Spine Line Weight",
      "Spine Line Bleed",
      "Barcode Width",
      "Barcode Height",
      "Font Size"
    ],
    value: {
      type: FLOAT_TYPE,
      initValue: 0
    },
  },
  {
    key: [
      "Wireframe Width",
    ],
    value: {
      type: FLOAT_TYPE,
      initValue: 104.6
    },
  },
  {
    key: [
      "Wireframe Height",
    ],
    value: {
      type: FLOAT_TYPE,
      initValue: 87.3125
    },
  },
  {
    key: ["Background Color", "Wireframe Color"],
    value: {
      type: DROPDOWN,
      placeholder: "Please select a color",
      initValue: "-- None --",
      values: [
        "-- None --",
        "Black",
        "Gray (Light)",
        "Gray",
        "Red",
        "Red (Light)",
        "Green",
        "Green (Light)",
        "Yellow",
        "Yellow (Light)",
        "Pink",
        "Orange",
        "Orange (Light)",
        "Blue",
        "Blue (Light)",
        "White",
        "Daily Color"],
    },
  },
  {
    key: ["Orientation"],
    value: {
      type: DROPDOWN,
      placeholder: "Please select an orientation",
      initValue: "Portrait",
      values: ["Landscape", "Portrait"],
    },
  },
  {
    key: ["Color",],
    value: {
      type: DROPDOWN,
      placeholder: "Please select a color",
      initValue: "Black",
      values: [
        "-- None --",
        "Black",
        "Gray (Light)",
        "Gray",
        "Red",
        "Red (Light)",
        "Green",
        "Green (Light)",
        "Yellow",
        "Yellow (Light)",
        "Pink",
        "Orange",
        "Orange (Light)",
        "Blue",
        "Blue (Light)",
        "White",
        "Daily Color"],
    },
  },
  {
    key: "Direction",
    value: {
      type: DROPDOWN,
      placeholder: "Please select direction",
      values: ["Horizontal", "Vertical"],
    },
  },
  {
    key: "Template",
    value: {
      type: DROPDOWN,
      placeholder: "Please select template",
      values: ["Blankets", "Soft Cover", "Neckties", "Double Sided Ornaments", "None"],
    },
  },
  {
    key: "Overlay Image",
    value: {
      type: DROPDOWN,
      placeholder: "Please select overlay",
      values: [],
    },
  },
  {
    key: "Template Job Sheets",
    value: {
      type: DROPDOWN,
      placeholder: "Please select template",
      values: ["Default", "Zero Gap", "MultiCut", "Barcode Sheet", "Print", "Poster", "Calendar", "Greeting Card", "Zero Gap"],
    },
  },
  {
    key: "Condition",
    value: {
      type: DROPDOWN,
      initValue: "none",
      values: [
        { label: "None", value: "none" },
        {
          label: "Do not rotate if landscape",
          value: "portrait_only",
        },
        {
          label: "Do not rotate if portrait",
          value: "landscape_only",
        },
        {
          label: "Force 4 in width",
          value: "force_4in_width",
        },
        {
          label: "Force 5 in width",
          value: "force_5in_width",
        },
        {
          label: "Portrait only except 4x3",
          value: "portrait_only_except_4x3",
        },
        {
          label: "Odd pages",
          value: "odd_pages",
        },
        {
          label: "Even pages",
          value: "even_pages",
        },
        {
          label: "Every two pages",
          value: "every_two",
        },
        {
          label: "Portrait Duplex Side",
          value: "portrait_duplex_side",
        },
        {
          label: "Landscape and even pages",
          value: "landscape_and_even_pages",
        },
        {
          label: "Portrait and even pages",
          value: "portrait_and_even_pages",
        }
      ],
    },
  },
  {
    key: "Sheet Location",
    value: {
      type: DROPDOWN,
      initValue: "Start",
      placeholder: "Please select Sheet location",
      values: ["Start", "End"],
    },
  },
  {
    key: "Shape",
    value: {
      type: DROPDOWN,
      placeholder: "Please select Shape",
      values: [
        {
          label: "Circle",
          value: "Circle",
        },
        {
          label: "Square",
          value: "Square",
        },
        {
          label: "Triangle",
          value: "Triangle",
        },
        {
          label: "Angled Dash",
          value: "Angled_Dash",
        }
      ],
    },
  },
  {
    key: "Resize Type",
    value: {
      type: DROPDOWN,
      placeholder: "Please select Resize Type",
      values: [
        { label: "Proportionate - fit height", value: "fit_height" },
        {
          label: "Proportionate - fit width",
          value: "fit_width",
        },
        {
          label: "Disproportionate - skew",
          value: "skew",
        }
      ],
    },
  },
  {
    key: ["Barcode Type", "Order Ident Barcode Type"],
    value: {
      type: DROPDOWN,
      initValue: "Barcode",
      placeholder: "Please select a barcode type",
      values: ["Barcode","QR","Data Matrix"],
    },
  },
  {
    key: "Draw Rectangle",
    value: {
      type: DROPDOWN,
      initValue: "All",
      placeholder: "Please select a rectangle draw condition",
      values: ["All","Images Only","Duplex Only","Images Except Last","Last Order","Last Order Last Page","First Page"],
    },
  },
  {
    key: "Cut Line Width",
    value: {
      type: DROPDOWN,
      initValue: "1",
      placeholder: "Please select a cut line width",
      values: ["0.5", "1", "2"],
    },
  },
  {
    key: "Image Alignment",
    value:{
      type: DROPDOWN,
      placeholder: "Please select image alignment",
      values: [
        {
          label: "Left",
          value: "left"
        },
        {
          label: "Right",
          value: "right"
        },
        {
          label: "Center",
          value: "center"
        },
        {
          label: "Top Left",
          value: "top_left"
        },
        {
          label: "Top Right",
          value: "top_right"
        },
        {
          label: "Top Center",
          value: "top_center"
        },
        {
          label: "Bottom Left",
          value: "bottom_left"
        },
        {
          label: "Bottom Right",
          value: "bottom_right"
        },
        {
          label: "Bottom Center",
          value: "bottom_center"
        },
        {
          label: "Align right margin",
          value: "align_right_margin"
        },
      ],
    }
  },
  {
    key: ["Print on Pages", "Apply on Pages"],
    value: {
      type: DROPDOWN,
      initValue: "ALL_PAGES",
      placeholder: "Please select print on pages",
      values: [
        {
          label: "All pages",
          value: "ALL_PAGES"
        },
        {
          label: "First page",
          value: "FIRST_PAGE",
        },
        {
          label: "Last page",
          value: "LAST_PAGE",
        },
        {
          label: "First order first page",
          value: "FIRST_ORDER_FIRST_PAGE",
        },
        {
          label: "First and second order's first page",
          value: "FIRST_AND_SECOND_ORDER_FIRST_PAGE",
        },
        {
          label: "All pages except first",
          value: "ALL_EXCEPT_FIRST_PAGE",
        },
        {
          label: "All pages except last",
          value: "ALL_EXCEPT_LAST_PAGE",
        },
        {
          label: "All pages except first and second order's first page",
          value: "ALL_EXCEPT_FIRST_AND_SECOND_ORDER_FIRST_PAGE",
        },
        {
          label: "Odd Pages",
          value: "ODD_PAGES",
        },
        {
          label: "Even Pages",
          value: "EVEN_PAGES",
        },
        {
          label: "Job Sheet",
          value: "JOB_SHEET",
        },
        {
          label: "Portrait Image",
          value: "PORTRAIT_IMAGE",
        },
        {
          label: "Landscape Image",
          value: "LANDSCAPE_IMAGE",
        },
      ],
    },
  },
  {
    key: "Marker Type",
    value: {
      type: DROPDOWN,
      placeholder: "Please select marker type",
      values: ["VIP Gold Star", "Gift Note"],
    },
  },
  {
    key: "Duplicate Condition",
    value: {
      type: DROPDOWN,
      initValue: "NONE",
      placeholder: "Please select duplicate condition",
      values: [
        {
          label: "None",
          value: "NONE"
        },
        {
          label: "First page only for odd total pages",
          value: "FIRST_PAGE_ONLY_ODD",
        }
      ],
    },
  },
  {
    key: "Page Stamp Type",
    value: {
      type: DROPDOWN,
      initValue: "PageNum",
      placeholder: "Please select page stamp type",
      values: [
        {
          label: "Page Numbers",
          value: "PageNum"
        }
      ],
    },
  },
  {
    key: "Skew Pages",
    value: {
      type: DROPDOWN,
      initValue: "ALL",
      placeholder: "Please select skew pages",
      values: [
        {
          label: "All",
          value: "ALL"
        },
        {
          label: "None",
          value: "NONE",
        }
      ],
    },
  },
  {
    key: "Shape Type",
    value: {
      type: DROPDOWN,
      initValue: "NORMAL",
      placeholder: "Please select shape type",
      values: [
        {
          label: "Normal",
          value: "NORMAL"
        },
        {
          label: "White Label",
          value: "WHITE_LABEL",
        },
        {
          label: "Shipping",
          value: "SHIPPING",
        }
      ],
    },
  },
  {
    key: "Border Type",
    value: {
      type: DROPDOWN,
      initValue: "DEFAULT",
      values: [
        {
          label: "Default",
          value: "DEFAULT",
        },
        {
          label: "Job Sheet",
          value: "JOB_SHEET",
        },
      ],
    },
  },
  {
    key: "Apply Sheet",
    value: {
      type: DROPDOWN,
      initValue: "ALL_EXCEPT_JOB_BATCH_SHEET",
      placeholder: "Please select apply sheet",
      values: [
        {
          label: "All pages",
          value: "ALL"
        },
        {
          label: "All pages except job and batch sheet",
          value: "ALL_EXCEPT_JOB_BATCH_SHEET",
        },
        {
          label: "All pages except batch sheet",
          value: "ALL_EXCEPT_BATCH_SHEET",
        }
      ],
    },
  },
  {
    key: "Qualifier",
    value: {
      type: DROPDOWN,
      placeholder: "Please select a qualifier",
      initValue: "none",
      values: [
        { label: "None", value: "none" },
        {
          label: "First Lane",
          value: "FIRST LANE"
        },
      ],
    },
  },
  {
    key: "Ad Image Location",
    value: {
      type: DROPDOWN,
      initValue: "Start",
      placeholder: "Please select Ad Image Location",
      values: ["Start", "End"],
    },
  },
  {
    key: "Data Matrix Symbol Size",
    value: {
      type: DROPDOWN,
      values: ["10x10", "12x12", "18x8", "14x14", "32x8", "16x16", "26x12", "18x18", "20x20", "36x12", "22x22", "36x16", "24x24", "26x26", "48x16", "32x32", "36x36", "40x40", "44x44", "48x48", "52x52", "64x64", "72x72", "80x80", "88x88", "96x96", "104x104", "120x120", "132x132", "144x144"],
    },
  },
  {
    key: ["X-Alignment", "Y-Alignment", "First Line X Alignment", "First Line Y Alignment"],
    value: {
      type: DROPDOWN,
      initValue: "ABSOLUTE",
      placeholder: "Please select alignment",
      values: [
        {
          label: "Absolute",
          value: "ABSOLUTE"
        },
        {
          label: "Center",
          value: "CENTER",
        },
        {
          label: "Move from center",
          value: "MOVE_FROM_CENTER",
        },
        {
          label: "Use page width",
          value: "PAGE_WIDTH"
        },
        {
          label: "Use page height",
          value: "PAGE_HEIGHT",
        },
        {
          label: "Move from page height",
          value: "MOVE_FROM_PAGE_HEIGHT",
        }
      ],
    },
  },
  {
    key: "Layer Template",
    value: {
      type: DROPDOWN,
      initValue: "CANVAS",
      placeholder: "Please select layer template",
      values: [
        {
          label: "Canvas",
          value: "CANVAS"
        },
        {
          label: "Fine Art",
          value: "FINE_ART",
        }
      ],
    },
  }
];

export const FormRequired = [
  { key: [
    "Label",
    "Rotation",
    "Collation Letter",
    "Top X",
    "Top Y",
    "Bottom X",
    "Bottom Y",
    "Left X",
    "Left Y",
    "Right X",
    "Right Y",
    "Top Length",
    "Bottom Length",
    "Left Length",
    "Right Length",
    "Border Line Weight",
    "Color",
    "Background Color",
    "Orientation",
    "Cut and Stack",
    "Limit Paper Wastage",
    "Condition",
    "Template X-Offset",
    "From X",
    "From Y",
    "To X",
    "To Y",
    "Distance from Corner",
    "X Position",
    "Y Position",
    "Border Height",
    "Border Width",
    "Follow Page Data",
    "Follow DTG Data",
    "Scale",
    "Batch Sheet Text",
    "Offcut Lanes",
    "Barcode X Location",
    "Barcode Y Location",
    "Barcode Width",
    "Barcode Height",
    "Barcode Rotation",
    "Barcode Type",
    "Draw Rectangle",
    "Cut Line X",
    "Cut Line Size",
    "Eyemark X",
    "Eyemark Y",
    "Eyemark Width",
    "Eyemark Height",
    "Front Refresh Line X",
    "Barcode CMYK",
    "Reverse Page Width",
    "Reverse Page Height",
    "Custom Sheet Width",
    "Custom Sheet Height",
    "Image Alignment",
    "Print on Pages",
    "Marker Type",
    "Is custom color",
    "Custom Background Color",
    "Text Info",
    "Bold",
    "Duplicate Condition",
    "SOJ Pages",
    "EOJ Pages",
    "ToF Width Rear",
    "ToF Height Rear",
    "ToF X Location Rear",
    "ToF Y Start Rear",
    "Key Line Width",
    "Key Line X-Location",
    "Force Single Sheet",
    "Include Spine Width",
    "Overwrite First Page",
    "Wireframe Color",
    "Wireframe Width",
    "Wireframe Height",
    "Include In Batch Sheet",
    "Shape Type",
    "Preview File Path",
    "Apply Sheet",
    "Lane Widths",
    "Cut Lane Widths",
    "Order Ident Page Length",
    "X-Location on Print",
    "Y-Location on Print",
    "Font Size on Print",
    "Text Rotation on Print",
    "Text on Print",
    "Duplex Print Rotation",
    "Repeating",
    "Combo",
    "Ad Image Location",
    "Whitespace Length",
    "QC Sheet Copies",
    "Alignment Tool Width",
    "Alignment Tool Height",
    "Alignment Tool Middle Width",
    "Alignment Tool Cmyk Height",
    "Alignment Tool Rows",
    "Alignment Tool Cmyk Interval",
    "Add Spine Width",
    "First Lane Cut Line X",
    "Limit Sheet Height",
    "Submission Sheet Text",
    "Submission Sheet Height",
    "Submission Sheet Offset",
    "Data Matrix Symbol Size",
    "Image Lines Alignment",
    "Spotcolor Name",
    "Front and Rear Rotation",
    "Resize Width",
    "Resize Height"
  ], value: "false" },
];


export const FieldMessage = [
  { key: ["degrees", "Rotation"], value: "Rotation is required. " },
  { key: "Thickness", value: "Thickness is requiered. " },
  { key: "Height", value: "Height is required. " , except : ['Skew'] },
  { key: "Length", value: "Length is required. "},
  { key: "Bleed", value: "Bleed is required. " },
  { key: "Width", value: "Width is required. ", except : ['Skew']},
  { key: "Type", value: "Type is required. " },
  { key: "Scale", value: "Scale is required. ", except : ['Skew']},
  { key: "Copies", value: "Copies is required. " },
  { key: "Side", value: "Side is required. " },
  { key: "Direction", value: "Direction is required. " },
  { key: "Number of Lines", value: "Number of Lines is required. " },
  { key: "Distance from edge", value: "Distance from edge is required. " },
  { key: ["Line Weight", "Weight"], value: "Line Weight is required. " },
  { key: ["Lines Gap", "Gap"], value: "Lines Gap is required. " },
  { key: "Crop Length", value: "Crop Length is required. " },
  { key: "Crop Type", value: "Crop Type is required. " },
  { key: "All Images", value: "All Images is required. " },
  { key: ["xPosition", "X-Location"], value: "X-Location is required. " },
  { key: ["yPosition", "Y-Location"], value: "Y-Location is required. " },
  { key: "Start", value: "Start position is required. " },
  { key: "End", value: "End position is required. " },
  { key: "Radius", value: "Radius is required. " },
  { key: "Text", value: "Text is required. " },
  { key: "Font Size", value: "Font size is required. " },
  { key: "Sheet Width", value: "Sheet width is required. " },
  { key: "Sheet Height", value: "Sheet height is required. " },
  { key: "Spine Line Height", value: "Spine Line Height is required. " },
  { key: "Spine Line Weight", value: "Spine Line Weight is required. " },
  { key: "Spine Line Bleed", value: "Spine Line Bleed is required. " },
  { key: "Resize Type", value: "Resize Type is required. " },
  {
    key: ["First Image X-Location","First Image X", "First Image Y-Location", "First Image Y"],
    value: "First Image location is required. ",
  },
  {
    key: ["First Line X", "First Line Y"],
    value: "First Line location is required. ",
  },
  { key: "Horizontal Gutter", value: "Horizontal gutter is required. " },
  { key: "Vertical Gutter", value: "Vertical gutter is required. " },
  { key: "Images Across", value: "Images across is required. " },
  { key: "Images Down", value: "Images down is required. " },
  { key: "Textblock", value: "Text block is required. " },
  { key: "Distance from Corner", value: "Distance from Corner is required. " },
  { key: "Size", value: "Size is required. " },
  { key: "Shape", value: "Shape is required. " },
  { key: "Back Print", value: "Back Print is required. " },
  { key: "Rows", value: "Rows is required. " },
  { key: "Left Margin", value: "Left Margin is required. " },
  { key: "Lamination Whitespace", value: "Lamination Whitespace is required. " },
  { key: "SOJ Pages", value: "SOJ Pages is required. " },
  { key: "EOJ Pages", value: "EOJ Pages is required. " },
  { key: "ToF Width Rear", value: "ToF Width Rear is required. " },
  { key: "ToF Height Rear", value: "ToF Height Rear is required. " },
  { key: "ToF X Location Rear", value: "ToF X Location Rear is required. " },
  { key: "ToF Y Start Rear", value: "ToF Y Start Rear is required. " },
  { key: "Key Line Width", value: "Key Line Width is required. " },
  { key: "Key Line X-Location", value: "Key Line X-Location is required. " },
  { key: "Die Line Height", value: "Die Line Height is required. " },
  { key: "Die Line Width", value: "Die Line Width is required. " },
  { key: "Refresh Lines Colors", value: "Refresh Lines Colors is required. " },
  { key: "Refresh Lines Length", value: "Refresh Lines Length is required. " },
  { key: "Refresh Lines Group", value: "Refresh Lines Group is required. " },
  { key: "Refresh Lines Y Offset", value: "Refresh Lines Y Offset is required. " },
  { key: "ToF X Location", value: "ToF X Location is required. " },
  { key: "ToF Width", value: "ToF Width is required. " },
  { key: "ToF Height", value: "ToF Height is required. " },
  { key: "ToF Y Start", value: "ToF Y Start is required. " },
  { key: "Barcode X Location", value: "Barcode X Location is required. " },
  { key: "Barcode Starting Y Location", value: "Barcode Starting Y Location is required. " },
  { key: "Barcode Distance", value: "Barcode Distance is required. " },
  { key: "Barcode Height", value: "Barcode Height is required. " },
  { key: "Barcode Width", value: "Barcode Width is required. " },
  { key: "Lanes", value: "Lanes is required. " },
  { key: "Print on Pages", value: "Print on Pages is required. " },
  { key: "Marker Type", value: "Marker Type is required. " },
  { key: "Custom Background Color", value: "Custom Background Color is required. " },
  { key: "Orientation", value: "Orientation is required. " },
  { key: "Is custom color", value: "Is custom color is required " },
  { key: "Sub Lane X Offset", value: "Sub Lane X Offset is required. " },
  { key: "Sub Lanes Horizontal Gutter", value: "Sub Lanes Horizontal Gutter is required. " },
  { key: "Order Ident Page Length", value: "Order Ident Page Length is required. " },
  { key: "Sub Lane X Gutter", value: "Sub Lane X Gutter is required. " },
  { key: "Alignment Tool Width", value: "Alignment Tool Width is required. " },
  { key: "Alignment Tool Length", value: "Alignment Tool Length is required. " },
  { key: "Alignment Tool Middle Width", value: "Alignment Tool Middle Width is required. " },
  { key: "Alignment Tool X Offset", value: "Alignment Tool X Offset is required. " },
];

export const OverlayTemplates = {
  key: "Overlay Image",
  values: [
    {
      "template_name": "8x8 Metal Tile Mask File",
      "image_location": "8x8 Metal Tile  Mask File.pdf"
    },
    {
      "template_name": "8x8 Std Heart Mask File",
      "image_location": "8x8 Std Heart Mask File.pdf"
    },
    {
      "template_name": "8x8 Std Tile Mask File",
      "image_location": "8x8 Std Tile Mask File.pdf",
    },
    {
      "template_name": "8x12 Metal Tile Mask File",
      "image_location": "8x12 Metal Tile Mask File.pdf",
    },
    {
      "template_name": "8x12 Std Tile Mask File",
      "image_location": "8x12 Std Tile Mask File.pdf",
    },
    {
      "template_name": "12x12 Metal Tile mask file",
      "image_location": "12x12 Metal Tile mask file.pdf",
    },
    {
      "template_name": "12x12 Std Tile Mask File",
      "image_location": "12x12 Std Tile Mask File.pdf",
    },
    {
      "template_name": "Car Coaster Mask File",
      "image_location": "Car Coaster Mask File.pdf",
    },
    {
      "template_name": "Circle Magnet Mask File",
      "image_location": "Circle Magnet Mask File.pdf",
    },
    {
      "template_name": "Heart Trivet Mask File",
      "image_location": "Heart Trivet Mask File.pdf",
    },
    {
      "template_name": "Large Oval Magnet Mask File",
      "image_location": "Large Oval Magnet Mask File.pdf",
    },
    {
      "template_name": "Round Trivet mask File",
      "image_location": "Round Trivet mask File.pdf",
    },
    {
      "template_name": "Small Oval Magnet mask File",
      "image_location": "Small Oval Magnet mask File.pdf",
    }
  ],
  default: "8x8 Metal Tile  Mask File.pdf"
};
