import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { debounce } from "utils/debounce";
import * as actions from "store/actions/index.js";
import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  Checkbox,
  Tooltip,
  Modal,
  Alert,
  notification,
  Select,
  Table,
} from "antd";
import "./Header.css";
import _ from "lodash";
import is from "is_js";
import { ZenSmartAPI } from "utils";
import ProductSelect from "./ProductSelect";
import BlockSelect from "./BlockSelect";
import PrinterSelect from "./PrinterSelect";
import FinishingTypeSelect from "./FinishingTypeSelect";
import {
  impositionDesignDataRoute,
  getPrintPathsRoute,
  getOutputFormats,
  getRecipeRoute,
  getTemplatePrintPaths,
  productBlockComponents,
  getImposeTemplates
}
  from "utils/apiRoutes";
import { logError } from "utils/logging";
import { SAVE_IMPOSE_TEMPLATES } from "store/actions/actionTypes";
import { useSelector } from 'react-redux'
import { packMultiType, unpackMultiType } from "modules/imposition/utils/ImpositionUtil";

const HeaderFields = styled.div`
  padding-top: 6px;
`;

const { Option } = Select;

const Header = (props) => {
  const setProductBlockPrinterResults = (data, headerState) => {
    if (is.existy(data) && is.existy(data.product_block_printer)) {
      populateData(data.product_block_printer, headerState);
    } else if (
      is.existy(data) &&
      is.existy(data.product_block_count) &&
      data.product_block_count === 0
    ) {
      updateHeaderFields({
        ...headerState,
        defaultPrinter: true,
        productBlockPrinter: {
          default: true,
        },
      });
      props.updateChangeTracker([], [], true, true);
      props.setCheckBox(true);
      props.setQaFailCheckBox(true);
    }
  };

  const [headerField, setHeaderField] = useState({
    product: props.product,
    productCode: props.productCode,
    article: props.article,
    block: props.block,
    blockType: props.blockType,
    printer: props.printer,
    printerClass: props.printerClass,
    finishingType: props.finishingType,
    finishingTypeText: props.finishingTypeText,
    defaultPrinter:
      is.existy(props.productBlockPrinter) &&
        is.existy(props.productBlockPrinter.default)
        ? props.productBlockPrinter.default
        : false,
    failDefaultPrinter: is.existy(props.productBlockPrinter) &&
      is.existy(props.productBlockPrinter.failed_default_printer)
      ? props.productBlockPrinter.failed_default_printer
      : false,
    productBlockPrinter: props.productBlockPrinter,
    products: [],
    blocks: [],
    printers: [],
    finishingTypes: [],
    plex: props.plex,
  });

  const [copyRecipeModal, setCopyRecipeModal] = useState(false);
  const [stateHeader, setStateHeader] = useState(null);
  const [copyPrintPaths, setCopyPrintPaths] = useState([]);
  const [printPathsTableColumn, setprintPathsTableColumn] = useState([]);
  const [selectedCopyPrintPaths, setSelectedCopyPrintPaths] = useState([]);
  const [initialRecipeData, setInitialRecipeData] = useState(null);
  const [copyRecipes, setCopyRecipes] = useState([]);
  const userData = useSelector((state) => state.user.details);

  const toolTipMsg =
    "Set another “Printer / Finishing Type” combination to default in order to unset this one";
  const toolTipFailMsg =
    "Set another “Printer / Finishing Type” combination to qa fail default order to unset this one";
  const [loadingCopyPrintPathTable, setLoadingCopyPrintPathTable] =
    useState(false);
  const [ifSelectAllCheckbox, setIfSelectAllCheckbox] = useState(false);
  const [selectedCopyRecipeId, setSelectedCopyRecipeId] = useState(undefined);
  const [receipBody, setReceipBody] = useState({});

  const selectCopyPrintPaths = (e, row, index) => {
    if (e.target.checked) {
      row.key = `${index}${row.id}`;
      setSelectedCopyPrintPaths([...selectedCopyPrintPaths, row]);
    } else {
      setSelectedCopyPrintPaths(
        selectedCopyPrintPaths.filter((item) => item.id !== row.id)
      );
    }
  }
  const dispatch = useDispatch();



  const fetchImpositionData = () => {
    ZenSmartAPI.get(impositionDesignDataRoute)
      .then((response) => {
        const responseData = response.data.data;
        props.saveImpositionDesignData(responseData)
      })
      .catch((err) => {
        logError(err, false);
      });
  }

  const fetchOutputFormatData = () => {
    ZenSmartAPI.get(getOutputFormats)
      .then((response) => {
        const responseData = response.data.data;
        props.saveOutputFormat(responseData)
      })
      .catch((err) => {
        logError(err, false);
      });
  }

  //set product drop down data from server response after component is mounted
  useEffect(() => {
    props.setLoading(true);
    if (!props.output_data) {
      fetchOutputFormatData()
      return;
    }
    if (!props.imposition_design_data?.products) {
      fetchImpositionData()
      return;
    }
    const imposition_design_data = _.cloneDeep(props.imposition_design_data);
    const output_data = _.cloneDeep(props.output_data);

    if (is.existy(output_data)) {
      props.setOutputFormat(output_data);
    }

    //Make a backup of the current recipe state to clear recipe while screen is loading
    const recipeData = [...props.recipes];
    props.updateRecipes([], true);
    props.setCheckBox(false);

    //Check for recipes with parameters to see if all fields has values
    //If values are not filled in, remove them upon screen refresh
    const cloneRecipes = [...props.recipes];
    let refreshRecipes = false;

    cloneRecipes.forEach((cloneRecipe, index) => {
      if (is.not.empty(cloneRecipe.parameters)) {
        const recipeParam = cloneRecipe.parameters.filter((recipeParam) =>
          is.not.empty(recipeParam.value)
        );

        if (is.empty(recipeParam)) {
          cloneRecipes.splice(index, 1);
          refreshRecipes = true;
        }
      }
    });

    if (refreshRecipes) {
      const sortedRecipes = cloneRecipes.map((cloneRecipe, index) => {
        return { ...cloneRecipe, sortOrder: index.toString() };
      });
      props.updateRecipes(sortedRecipes, true);
    }

    let blocks = [];
    props.setInitialImpositionData(imposition_design_data);
    const params = new URLSearchParams(window.location.search);
    const productIdParam = params.get("product_id");
    const productId = is.existy(productIdParam)
      ? productIdParam
      : headerField.product;

    if (productId) {
      const filteredProduct = imposition_design_data.products.find(
        (product) => product.id === parseInt(productId)
      );

      if (filteredProduct === undefined) {
        notification.error({
          message: "Product Id Not Found",
        });
        props.setLoading(false);
        return;
      }

      blocks = filteredProduct.blocks;

      props.setPaperCodes(filteredProduct.paper_codes);
      props.setLaminationCodes(filteredProduct.lamination_codes);

      //If imposition Designer is loaded with product id
      //open Imposition Designer with only product and block selected
      if (is.existy(productIdParam)) {
        clearHeaderFields();
        const selectedBlock = is.not.empty(blocks)
          ? blocks[0].id
          : null;
        const selectedBlockType = is.not.empty(blocks)
          ? blocks[0].block_type
          : null;
        const selectedBlockPlex = is.not.empty(blocks)
          ? blocks[0].plex
          : null;

        setHeaderField({
          product: filteredProduct.id,
          productCode: filteredProduct.product_code,
          article: filteredProduct.description,
          block: selectedBlock,
          blockType: selectedBlockType,
          printer: null,
          printerClass: null,
          finishingType: null,
          finishingTypeText: null,
          defaultPrinter: false,
          productBlockPrinter: null,
          products: imposition_design_data.products,
          blocks: blocks,
          printers: imposition_design_data.printers,
          finishingTypes: imposition_design_data.finishing_types,
          plex: selectedBlockPlex,
        });
        props.setCheckBox(false);
        props.setQaFailCheckBox(false);
        props.updateImpoDesignerDisabled(true);
      }
    }

    if (headerField.printer) {
      const filteredPrinter = imposition_design_data.printers.find(
        (printer) => printer.id === headerField.printer
      );

      props.setSpecifiedPrinters(filteredPrinter.specific_printers);
    }

    if (is.not.existy(productIdParam)) {
      //After product data is successfully queried and
      //screen loading completes, restore recipe backup
      //only if there are no recipes whereby all parameters values are null
      if (!refreshRecipes) {
        props.updateRecipes(recipeData, true);
      }

      setHeaderField({
        ...headerField,
        products: imposition_design_data.products,
        blocks: blocks,
        printers: imposition_design_data.printers,
        finishingTypes: imposition_design_data.finishing_types,
      });

      if (is.not.empty(props.recipes)) {
        props.updatePdfURL(props.recipes);
      }
    }

    const defaultPrinter = is.existy(headerField.defaultPrinter)
      ? headerField.defaultPrinter
      : false;
    props.setCheckBox(defaultPrinter);
    props.hasUnsavedChanges(
      props.checkUnsaveChanges(
        props.recipes,
        props.output,
        defaultPrinter
      )
    );

    props.setLoading(false);
  }, [props.output_data, props.imposition_design_data])

  const populateData = (data, headerState) => {
    let recipes = [];
    const printPaths = populatePrintPaths(data);

    if (data.recipe) {
      const recipePayload = _.cloneDeep(data.recipe);

      if (recipePayload && recipePayload.length > 0) {
        recipes = unpackMultiType(recipePayload);

        recipes.forEach((recipe, index) => {
          recipe.sortOrder = index +'';
        });
      }

      props.updateRecipeId("");
      props.updateRecipes(recipes, true);
      props.updatePdfURL(recipes, headerState);
    }

    const defaultPrinter = data.default ? data.default : false;
    const failDefaultPrinter = data.failed_default_printer ? data.failed_default_printer : false;
    props.updateChangeTracker(recipes, printPaths, defaultPrinter, failDefaultPrinter);


    updateHeaderFields({
      ...headerState,
      defaultPrinter: defaultPrinter,
      failDefaultPrinter: failDefaultPrinter,
      productBlockPrinter: {
        id: data.id,
        default: data.default,
      },
    });
    props.setCheckBox(defaultPrinter);
    props.setQaFailCheckBox(failDefaultPrinter);
  };

  const populatePrintPaths = (data) => {
    let printPaths = [];

    if (is.existy(data.print_paths)) {
      printPaths = data.print_paths.map((printPath, index) => {
        printPath.key = index;
        return printPath;
      });

      printPaths.sort((prev, curr) => Number(prev.id) - Number(curr.id));
      props.updateOutput(printPaths);
    }

    return printPaths;
  };

  const invokeSelect = (callback, value, headerFieldsState) => {
    if (
      props.checkUnsaveChanges(props.recipes, props.output, props.checkBox) ||
      (initialRecipeData &&
        initialRecipeData.product_block_printer &&
        initialRecipeData.product_block_printer.recipe)
    ) {
      Modal.confirm({
        title: "Discard changes",
        content: "Are you sure you want to discard your changes?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          props.hasUnsavedChanges(false);
          setInitialRecipeData(null);
          callback(value);
        },
        onCancel() {
          updateHeaderFields({
            ...headerField,
            product: headerField.product,
          });
        },
      });
    } else {
      callback(value, headerFieldsState);
    }
  };

  const confirmIfThereIsSelectedPrintPaths = (value) => {
    if (selectedCopyPrintPaths.length > 0) {
      Modal.confirm({
        content: `Selected print paths will be lost are you sure you want to continue?`,
        onOk: () => {
          selectCopyRecipe(value);
        },
      });
    } else {
      selectCopyRecipe(value);
    }
  };
  useEffect(() => {
    const printPathColumn = [
      {
        title: "Specific Printers",
        dataIndex: "printer_name",
        editable: true,
        type: "dropdown",
        idLabel: "printer_id",
        valueLabel: "full_name",
        sorter: (a, b) =>
          a.printer_name && b.printer_name
            ? a.printer_name.localeCompare(b.printer_name)
            : a.printer_name,
        sortDirections: ["ascend", "descend"],
      },
       {
        title: "Production Facility",
        dataIndex: "facility",
        editable: true,
        type: "text",
        sorter: (a, b) =>
          a.facility && b.facility ? a.facility.localeCompare(b.facility) : a.facility,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Paper Codes",
        dataIndex: "paper_code",
        editable: true,
        type: "dropdown",
        idLabel: "paper_code",
        valueLabel: "code",
        sorter: (a, b) =>
          a.paper_code && b.paper_code
            ? a.paper_code.localeCompare(b.paper_code)
            : a.paper_code,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Lamination Codes",
        dataIndex: "lamination_code",
        editable: true,
        type: "dropdown",
        idLabel: "lamination_code",
        valueLabel: "code",
        sorter: (a, b) =>
          a.lamination_code && b.lamination_code
            ? a.lamination_code.localeCompare(b.lamination_code)
            : a.lamination_code,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Output Format",
        dataIndex: "output_format",
        editable: true,
        type: "dropdown",
        defaultValue: props.outputFormat
          ? props.outputFormat.default_output_format
          : null,
        sorter: (a, b) =>
          a.output_format && b.output_format
            ? a.output_format.localeCompare(b.output_format)
            : a.output_format,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Path",
        dataIndex: "path",
        editable: true,
        type: "text",
        sorter: (a, b) =>
          a.path && b.path ? a.path.localeCompare(b.path) : a.path,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Pre Path",
        dataIndex: "pre_path",
        editable: true,
        type: "text",
        sorter: (a, b) =>
          a.pre_path && b.pre_path ? a.pre_path.localeCompare(b.pre_path) : a.pre_path,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "",
        dataIndex: "select",
        key: "select",
        render: (record, row, index) => {
          const value = (
            <Checkbox
              checked={selectedCopyPrintPaths.find(
                (item) => item.id === row.id
              )}
              onChange={(e) => selectCopyPrintPaths(e, row, index)}
            />
          );
          return value;
        },
      },
    ];
    setprintPathsTableColumn(printPathColumn);
  }, [selectedCopyPrintPaths, copyPrintPaths]);

  const selectCopyRecipe = (value) => {
    const findRecipe = copyRecipes.find((item) => item.id === value);
    if (findRecipe) {
      setInitialRecipeData({
        ...initialRecipeData,
        product_block_printer: { recipe: findRecipe.recipe, recipe_legacy: findRecipe.recipe_legacy },
      });
      setLoadingCopyPrintPathTable(true);
      setSelectedCopyRecipeId(findRecipe.id);
      ZenSmartAPI.post(getTemplatePrintPaths, findRecipe.print_path).then(
        (res) => {
          setCopyPrintPaths(res.data.print_paths);
          props.setSpecifiedPrinters(res.data.specific_printers);
          setSelectedCopyPrintPaths([]);
          setLoadingCopyPrintPathTable(false);
          setIfSelectAllCheckbox(false);
        }
      );
    }
  };
  const selectAllPrintPaths = (value) => {
    if (!selectedCopyRecipeId) {
      notification.error({ message: "Please select recipe first!" });
      return;
    }
    if (value.target.checked) {
      const copyPrintPathsWithKeys = copyPrintPaths.map(function (data, index) {
        data.key = `${index}${data.id}`;
        return data;
      })
      setSelectedCopyPrintPaths(copyPrintPathsWithKeys);
    } else {
      setSelectedCopyPrintPaths([]);
    }
    setIfSelectAllCheckbox(value.target.checked);
  };
  const queryProductBlockPrinter = (
    product,
    block,
    printer,
    finishingType,
    headerState,
    blocks
  ) => {
    let impoDesignerDisabled = true;

    if (
      is.existy(product) &&
      is.not.empty(product) &&
      is.existy(block) &&
      is.not.empty(block) &&
      is.existy(printer) &&
      is.not.empty(printer) &&
      is.existy(finishingType) &&
      is.not.empty(finishingType)
    ) {
      const searchBlocks = is.existy(blocks) ? blocks : headerField.blocks;

      const filteredBlock = searchBlocks.find(
        (searchBlock) => searchBlock.id === block
      );

      const filteredPrinter = headerField.printers.find(
        (searchPrinter) => searchPrinter.id === printer
      );

      if (is.existy(filteredBlock) && is.existy(filteredPrinter)) {
        const postBody = {
          product_id: product,
          block_type: filteredBlock.block_type,
          printer_class: filteredPrinter.class,
          finishing_type_id: finishingType,
        };

        setReceipBody(postBody);

        props.setLoading(true);

        ZenSmartAPI.get(getImposeTemplates).then(res => {
          const response = res.data.data

          dispatch({
            type: SAVE_IMPOSE_TEMPLATES,
            imposeTemplate: {
              key: "Template",
              values: response.impose_templates,
              default: response.default_impose_template
            },
          });

        }).catch((_) => {
          console.log("Fail to query impose recipe templates. Setting default None");
          dispatch({
            type: SAVE_IMPOSE_TEMPLATES,
            imposeTemplate: {
              key: "Template",
              values: ["None"],
              default: "None"
            },
          });
        });

        ZenSmartAPI.post(
          getPrintPathsRoute,
          postBody
        )
          .then((response) => {
            setProductBlockPrinterResults(response.data.data, headerState);
            setSelectedCopyPrintPaths([]);
            if (response.data.data.product_block_printer === undefined || response.data.data.product_block_printer.print_paths.length === 0) {
              if (!(response.data.data.product_block_printer &&
                response.data.data.product_block_printer.recipe &&
                response.data.data.product_block_printer.recipe.length > 0
              )) {
                ZenSmartAPI.post(getRecipeRoute(), postBody)
                  .then((res) => {
                    setInitialRecipeData(response.data.recipe);
                    setCopyRecipes(res.data.recipe);
                    setStateHeader(headerState);
                    setCopyRecipeModal(true);
                    setCopyPrintPaths([]);
                    setSelectedCopyPrintPaths([]);
                    setIfSelectAllCheckbox(false);
                    setSelectedCopyRecipeId(undefined);
                    //props.setSpecifiedPrinters([]);
                  })
                  .catch((err) => {
                    logError(err, true);
                  }).finally(() => {
                    props.setLoading(false);
                  });
              }
              else {
                props.setLoading(false);
              }
            } else {
              props.setLoading(false);
            }

          })
          .catch((err) => {
            logError(err, true);
            props.setLoading(false);
          });

        impoDesignerDisabled = false;
      }
    }

    props.updateImpoDesignerDisabled(impoDesignerDisabled);
  };

  const clearHeaderFields = () => {
    props.updateRecipeId("");
    props.updateRecipes([], true);
    props.updatePdfURL([]);
    props.updateOutput([]);
    props.updateChangeTracker([], [], false, false);
  };

  const saveChanges = () => {
    let outputPayload = {};
    if (props.output && props.output) {
      outputPayload = props.output.map((output) => {
        const { key, ...remainder } = output;
        return remainder;
      });
    }

    const filteredProduct = props.initialImpositionData.products.find(
      (product) => product.id === headerField.product
    );

    const filteredBlock = headerField.blocks.find(
      (blocks) => blocks.id === headerField.block
    );

    const filteredPrinter = headerField.printers.find(
      (printer) => printer.id === headerField.printer
    );

    let payload = {
      product_code: filteredProduct.product_code,
      product_block_id: headerField.block,
      block_type: filteredBlock.block_type,
      printer_class_id: headerField.printer,
      printer_class: filteredPrinter.class,
      finishing_type_id: headerField.finishingType,
      page_slots: 1,
      output_destination: "/output/imposition_designer",
      default: props.checkBox,
      failed_default_printer: props.qaFailCheckBox,
      status: "active",
      recipe_legacy: initialRecipeData?.product_block_printer?.recipe_legacy,
      recipe: packMultiType(props.recipes),
      print_paths: outputPayload,
      paper_codes: props.paperCodes,
      lamination_codes: props.laminationCodes,
    };

    props.setLoading(true);

    if (headerField.productBlockPrinter && headerField.productBlockPrinter.id) {
      payload.product_block_printer_id = headerField.productBlockPrinter.id;

      ZenSmartAPI.put(impositionDesignDataRoute, payload)
        .then((response) => {
          notification.info({
            message: "Imposition template updated",
          });
          setInitialRecipeData(null);
          const responseData = response.data.data;
          updateHeaderFields({
            ...headerField,
            defaultPrinter: props.checkBox,
            productBlockPrinter: {
              id: responseData.id,
              default: props.checkBox,
            },
          });
          props.hasUnsavedChanges(false);
          props.updateChangeTracker(
            props.recipes,
            populatePrintPaths(responseData),
            props.checkBox,
            props.qaFailCheckBox
          );
        })
        .catch((err) => {
          logError(err, true);
        })
        .finally(() => {
          props.setLoading(false);
        });
    } else {
      ZenSmartAPI.post(impositionDesignDataRoute, payload)
        .then((response) => {
            notification.info({
            message: "Imposition template created",
          });
          const responseData = response.data.data;
          updateHeaderFields({
            ...headerField,
            defaultPrinter: props.checkBox,
            productBlockPrinter: {
              id: responseData.id,
              default: props.checkBox,
            },
          });

          //change paper and lamination codes when updated
          ZenSmartAPI.get(productBlockComponents(headerField.product, filteredBlock.block_type), {
          }).then((result) => {
            const response = result.data.data;
            response.map(function (data) {
              if (data.type === 'lamination') {
                props.setLaminationCodes(data.data)
              }
              if (data.type === 'paper') {
                props.setPaperCodes(data.data)
              }
            })
          })

          props.hasUnsavedChanges(false);
          props.updateChangeTracker(
            props.recipes,
            populatePrintPaths(responseData),
            props.checkBox,
            props.qaFailCheckBox
          );
          setInitialRecipeData(null)
        })
        .catch((err) => {
          logError(err, true);
        })
        .finally(() => {
          props.setLoading(false);
        });
    }
  };

  const discardChanges = () => {
    const revertRecipes = props.changeTracker.recipe;
    props.updateRecipes(props.changeTracker.recipe);
    props.updateRecipes(revertRecipes, true);
    props.updatePdfURL(revertRecipes);
    props.updateOutput(props.changeTracker.output);
    props.setCheckBox(props.changeTracker.defaultPrinter);
    props.setQaFailCheckBox(props.changeTracker.failDefaultPrinter);
    props.hasUnsavedChanges(false);
    setInitialRecipeData(null);
  };

  const updateHeaderFields = (header) => {
    setHeaderField(header);
    props.saveHeader(header);
  };

  const defaultPrinterChange = (event, failPrinter = false) => {

    if (!failPrinter) {
      let defaultPrinter = headerField.defaultPrinter;
      if (!headerField.defaultPrinter) {
        defaultPrinter = event.target.checked;
        props.hasUnsavedChanges(
          props.checkUnsaveChanges(null, null, defaultPrinter)
        );
      }
      props.setCheckBox(defaultPrinter);
    }
    else {
      let failDefaultPrinter = headerField.failDefaultPrinter;

      failDefaultPrinter = event.target.checked;
      props.hasUnsavedChanges(
        props.checkUnsaveChanges(null, null, props.checkBox, failDefaultPrinter)
      );

      props.setQaFailCheckBox(failDefaultPrinter);
    }
  };


  const onSearchCopyRecipe = debounce((value, limit = null) => {
    if (value !== "" || limit) {
      ZenSmartAPI.post(getRecipeRoute(value), receipBody)
        .then((result) => {
          setCopyRecipes(result.data.recipe);
        })
    }
  }, 400);


  return (
    <div className="Header">
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <div className="Header-title">IMPOSITION DESIGNER</div>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={20}>
          <HeaderFields>
            <ProductSelect
              headerField={headerField}
              clearHeaderFields={clearHeaderFields}
              invokeSelect={invokeSelect}
              queryProductBlockPrinter={queryProductBlockPrinter}
              updateHeaderFields={updateHeaderFields}
              setCheckBox={props.setCheckBox}
              setQaFailCheckBox={props.setQaFailCheckBox}
              setPaperCodes={props.setPaperCodes}
              setLaminationCodes={props.setLaminationCodes}
              setInitialImpositionData={props.setInitialImpositionData}
              initialImpositionData={props.initialImpositionData}
            />
            <BlockSelect
              headerField={headerField}
              clearHeaderFields={clearHeaderFields}
              invokeSelect={invokeSelect}
              queryProductBlockPrinter={queryProductBlockPrinter}
              updateHeaderFields={updateHeaderFields}
              setCheckBox={props.setCheckBox}
              setQaFailCheckBox={props.setQaFailCheckBox}
              setPaperCodes={props.setPaperCodes}
              setLaminationCodes={props.setLaminationCodes}
            />
            <PrinterSelect
              headerField={headerField}
              clearHeaderFields={clearHeaderFields}
              invokeSelect={invokeSelect}
              queryProductBlockPrinter={queryProductBlockPrinter}
              updateHeaderFields={updateHeaderFields}
              setCheckBox={props.setCheckBox}
              setQaFailCheckBox={props.setQaFailCheckBox}
              setSpecifiedPrinters={props.setSpecifiedPrinters}
              setLoading={props.setLoading}
            />
          </HeaderFields>
        </Col>
        <Col span={4}>
          <Button
            disabled={props.impoDesignerDisabled}
            className="Header-button"
            type="primary"
            onClick={saveChanges}
          >
            Save Changes
          </Button>
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="Header-field-description-row">
        <Col span={11}>
          <span className="Header-field-description">
            <span className="Header-boldText">Article Description: </span>
            {is.not.empty(
              props.initialImpositionData
                ? props.initialImpositionData.products
                : null
            )
              ? headerField.article
              : null}
          </span>
        </Col>
        <Col span={9}>
          <FinishingTypeSelect
            headerField={headerField}
            clearHeaderFields={clearHeaderFields}
            invokeSelect={invokeSelect}
            queryProductBlockPrinter={queryProductBlockPrinter}
            updateHeaderFields={updateHeaderFields}
            setCheckBox={props.setCheckBox}
            setQaFailCheckBox={props.setQaFailCheckBox}
            setLoading={props.setLoading}
          />
        </Col>
        <Col span={4}>
          <span className="Header-boldText">Default: </span>
          <Tooltip
            placement="top"
            title={headerField.defaultPrinter ? toolTipMsg : null}
          >
            <Checkbox
              disabled={props.impoDesignerDisabled}
              checked={props.checkBox}
              onChange={(event) => defaultPrinterChange(event)}
            />
          </Tooltip>
        </Col>
        {userData && userData.allow_fail_default_printer  &&
          <Col span={4}>
            <span className="Header-boldText">Qa fail Default: </span>
            <Tooltip
              placement="top"
              title={headerField.failDefaultPrinter ? toolTipFailMsg : null}
            >
              <Checkbox
                disabled={props.impoDesignerDisabled}
                checked={props.qaFailCheckBox}
                onChange={(event) => defaultPrinterChange(event, true)}
              />
            </Tooltip>
          </Col>
        }
      </Row>
      {props.unsavedChanges ? (
        <Row gutter={[8, 8]} className="Header-field-description-row">
          <Col span={20}>
            <Alert
              showIcon
              type="warning"
              message="Unsaved Imposition Template"
              description="You have unsaved changes for this imposition template."
            />
          </Col>
          <Col span={4}>
            <Button
              className="Header-button Header-button-center"
              type="primary"
              onClick={discardChanges}
            >
              Discard Changes
            </Button>
          </Col>
        </Row>
      ) : null}
      <Modal
        title="Do you want to copy an existing recipe?"
        visible={copyRecipeModal}
        width={1200}
        onCancel={() => {
          const filteredPrinter = headerField.printers.find(
            (printer) => printer.id === headerField.printer
          );
          props.setSpecifiedPrinters(filteredPrinter.specific_printers);
          setCopyRecipeModal(false)
        }}
        onOk={() => {
          props.updateOutput(selectedCopyPrintPaths);
          setProductBlockPrinterResults(initialRecipeData, stateHeader);
          setCopyRecipeModal(false);
          props.hasUnsavedChanges(true);
        }}
      >
        {copyRecipeModal && (
          <Row type="flex" justify="space-between" align="middle">
            <Select
              style={{ width: "30%" }}
              onChange={confirmIfThereIsSelectedPrintPaths}
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              showSearch
              value={
                is.not.empty(copyRecipes) ? selectedCopyRecipeId : null
              }
              placeholder="Select recipe"
              onSearch={(e) => onSearchCopyRecipe(e)}
            >
              {copyRecipes.map((data) => (
                <Option value={data.id} style={{ textTransform: "capitalize" }}>
                  {data.text}
                </Option>
              ))}
            </Select>
            <Checkbox
              onClick={selectAllPrintPaths}
              checked={ifSelectAllCheckbox}
            >
              Select All
            </Checkbox>
          </Row>
        )}

        <div style={{ marginTop: 10 }}>
          <Table
            columns={printPathsTableColumn}
            dataSource={copyPrintPaths}
            loading={loadingCopyPrintPathTable}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    product: state.imposition.product,
    productCode: state.imposition.productCode,
    article: state.imposition.article,
    block: state.imposition.block,
    blockType: state.imposition.blockType,
    printer: state.imposition.printer,
    printerClass: state.imposition.printerClass,
    finishingType: state.imposition.finishingType,
    finishingTypeText: state.imposition.finishingTypeText,
    defaultPrinter: state.imposition.defaultPrinter,
    productBlockPrinter: state.imposition.productBlockPrinter,
    plex: state.imposition.plex,
    imposition_design_data: state.product_list.imposition_design_data,
    output_data: state.product_list.output_data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveHeader: (header) => dispatch(actions.saveHeader(header)),
    saveOutputFormat: (data) => dispatch(actions.saveOutputFormat(data)),
    saveImpositionDesignData: (data) => dispatch(actions.saveImpositionDesignData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
