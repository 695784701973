import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/zensmart-design-system';
import { Row, Col, notification, Select, Input, Button } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { ZenSmartAPI } from 'utils'
import { getAllDepartments, getDailyTargets } from "utils/apiRoutes"
import TargetChart from './TargetChart'

const Header = styled.header`
  margin-bottom: 24px;
`
const HeaderTitle = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const PanelTitleContainer = styled.section`
  margin: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > i {
    font-size: 20px;
  }
`

const Title = styled.h2`
  color: ${color.body};

  font-size: 13px;
  font-weight: normal;
  margin: 0;
`

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const { Option } = Select;
const TargetsPage = (props) => {
  const [data, setData] = useState([])
  const [chartData, setChartData] = useState([])
  const [loading, setLoading] = useState(false)
  const [departments, setDepartments] = useState([])
  const [payload, setPayload] = useState({ days: 30, department_id: null })

  const fetchData = (value, department_id = null) => {

    if (department_id) {
    }
    else if (payload.days === null || payload.department_id === null) {
      notification.error({ message: "Please input all fields!" })
      return
    }
    setLoading(true)
    ZenSmartAPI.post(getDailyTargets, department_id ? { days: 30, department_id } : payload)
      .then((res) => {
        setData(res.data.data.target_value)
        setChartData(res.data.data.chart_value)
        setLoading(false)
        if (res.data.data.target_value.length === 0) {
          notification.error({ message: "No available data!" })
        }

      })
      .catch((res) => {
        setLoading(false)
      })
  }

  const changePayload = (value, property) => {

    if (property === "days") {
      if (value > 30) {
        notification.error({ message: "Days must not go beyond 30!" })
        return
      }
      else {
        setPayload({ ...payload, [property]: value })
      }
    }
    setPayload({ ...payload, [property]: value })

  }

  const fetchDepartments = () => {

    setLoading(true)
    ZenSmartAPI.get(getAllDepartments)
      .then((res) => {
        setLoading(false)
        setDepartments(res.data.data)

      })
      .catch((res) => {
        setLoading(false)
      })
  }
  const ContainerOverrides = createGlobalStyle`
  .parent-container {
      width : ${data.length > 10 ? '2700px' : '100%'};
  }
    .parent-header {
    width:${data.length > 10 ? '2470px' : '100%'} ;
  }
  
  `
  useEffect(() => {
    fetchDepartments()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (departments.length > 0) {
      setPayload({ ...payload, department_id: departments[0].id })
      fetchData(null, departments[0].id)
    }
  }, [departments]) // eslint-disable-line react-hooks/exhaustive-deps

  console.log('payload', payload);
  return (
    <>
      <ContainerOverrides />
      <Header>
        <HeaderTitle>Performance {">"} Target View </HeaderTitle>
      </Header>
      <Panel active={true} height='100%' title="FAIL PANEL">

        <PanelTitleContainer>
          <Title>TARGET VIEW</Title>
        </PanelTitleContainer>
        <div style={{ padding: "10px 50px 50px 50px" }}>
          <Row type="flex" align="middle">
            <DateColStyle span={3} >
              <Heading>DEPARTMENT</Heading>
              <Select style={{ marginTop: 10, width: '100%' }} value={payload.department_id ? payload.department_id : departments.length > 0 ?
                departments[0].id : null} onChange={(e) => changePayload(e, 'department_id')} placeholder="Select Department" >
                {departments.map(data =>
                  <Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                )}
              </Select>
            </DateColStyle>
            <DateColStyle span={3} >
              <Heading>DAYS</Heading>
              <Input type="number" value={payload.days} onChange={(e) => changePayload(e.target.value, 'days')} style={{ marginTop: 10, width: '100%' }} />
            </DateColStyle>
            <DateColStyle span={4}>
              <br />
              <StyleButton style={{ marginTop: 10 }} onClick={fetchData} loading={loading} type="primary">GO</StyleButton>
            </DateColStyle>
          </Row>
        </div>
        <div style={{ padding: "40px 50px 50px 50px" }}>
          <TargetChart datas={data} chartData={chartData} />
        </div>
      </Panel>
    </>
  )
}

export default TargetsPage