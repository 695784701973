import React, { useEffect, useState, useRef } from "react";
import { Drawer, Input, Button, notification } from "antd";
import { ZenSmartAPI } from "utils";
import { rolesRoute } from "utils/apiRoutes";
import * as _ from 'lodash';


function RoleAddDrawer({ onClose, onSave, isOpen }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const roleNameInputRef = useRef(null);

  //on open set the state to empty
  useEffect(() => {
    if (isOpen) {
      setName("");
      setDescription("");
      setTimeout(() => {
        if (roleNameInputRef.current) {
          roleNameInputRef.current.focus();
        }
      }, 1);
    }
  }, [isOpen]);

  const handleSave = () => {

    const trimmedName = name.toLowerCase().trim();

    if (!trimmedName) {
      notification.error({
        message: "Please fill out all fields",
        duration: 1.5
      });
      return;
    }

    ZenSmartAPI.post(rolesRoute, { name: trimmedName, description }).then((response) => {

      notification.success({
        message: 'Role added successfully. Please set the neccessary Permissions and assign Users to the role.'
      });

      onSave(response.data.data);

    }).catch((error) => {
      const statusCode = _.get(error, 'response.status');
      if (statusCode !== 403) {
        const validationErrors = statusCode === 422 ? _.get(error, 'response.data.errors') : null;
        const errorList = validationErrors ? Object.values(validationErrors).flat().join(' and ') : null;

        const errorMessage = validationErrors
          ? errorList
          : _.get(error, 'response.data.message', 'An error occurred while adding the role. Please try again.');

        notification.error({
          message: errorMessage,
          duration: 1.5
        });
      }
    });
  };

  return (
    <Drawer
      title="Create New Role"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={isOpen}
      width={400}
    >
      <div className={`drawer ${isOpen ? "open" : ""}`}>
        <b>Role Name: </b>
        <Input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onPressEnter={handleSave}
          ref={roleNameInputRef}
        />
        <b>Role Description: </b>
        <Input
          id="roleDescription"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onPressEnter={handleSave}
        />
        <div className="mt-4 flex justify-end">
          <Button className="mr-2" onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default RoleAddDrawer;
