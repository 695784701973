import React from 'react';
import {Modal, Typography, Input} from 'antd';

const {Text} = Typography;
const {TextArea} = Input;

const PostbackDetailsModal = ({visible, onClose, payload, callbackResponse}) => {
    return (
        <Modal
            title="Postback Details"
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            <div>
                <Text strong>Payload:</Text>
                <TextArea
                    style={{width: '100%', minHeight: '200px'}}
                    value={JSON.stringify(payload, null, 2)}
                    readOnly
                />
            </div>
            <div style={{marginTop: 20}}>
                <Text strong>Callback Response:</Text>
                <TextArea
                    style={{width: '100%', minHeight: '200px'}}
                    value={JSON.stringify(callbackResponse, null, 2)}
                    readOnly
                />
            </div>
        </Modal>
    );
};
export {PostbackDetailsModal};