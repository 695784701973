import React, { useRef, useEffect, useState } from 'react'
import { Row, Col, Form, Input, Modal } from 'antd'
import styled, { css } from 'styled-components'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js'
import { Panel } from 'components/primaries'
import { ZenSmartAPI } from 'utils';
import Cookies from 'js-cookie';
import { normalBarcodeScanRoute } from "utils/apiRoutes"
/**
 * @note Clean the API for this component. Feels like it has to know
 * a lot of variables/prop values in order to be useful.
 */

const panelStylings = css`
  margin-bottom: 24px;
`

const StyledRow = styled(Row)`
  margin-bottom: 24px;
`

const LookupForm = Form.create({ name: 'LookupForm' })(props => {
  const { onSubmit, inputProps, form, isViewingDetails, releaseScanTypeId } = props
  const { getFieldDecorator, getFieldValue, resetFields } = form
  const [loading, setLoading] = useState(false)
  const scanInput = useRef(null)
  const refocusQueueID = useRef(null)
  const clearRefocusQueue = () => {
    clearTimeout(refocusQueueID.current)
  }

  const refocusQueue = () => {
    refocusQueueID.current = setTimeout(() => {
      if (scanInput.current) {
        scanInput.current.focus()
      }
    }, 5000)
  }


  const handleSubmit = (evt) => {
    evt.preventDefault()
    const input_value = getFieldValue('lookup_text');
    let payload = {};

    console.log("input-val:", input_value)
    if (input_value === undefined) {
      scanInput.current.focus()
      return onSubmit({ error: 'Please Scan A Barcode' })
    }

    if (input_value.includes('STATION') === true) {
      console.log("releaseScanTypeId", releaseScanTypeId)
      payload = { barcode: input_value, scan_type_id: releaseScanTypeId }
      scanInput.current.focus()
      setLoading(true)
      ZenSmartAPI.post(normalBarcodeScanRoute, payload)
        .then((res) => {
          resetFields()
          setLoading(false)
          scanInput.current.focus()
          if (res.data && res.data.duplicate_scan_prompt) {
            Modal.confirm({
              'content': res.data.duplicate_scan_prompt,
              onOk: () => {
              },
              cancelButtonProps: { style: { display: "none" } }
            });
          }
          onSubmit({ ...res.data, success_scan: true })
        })
        .catch((res) => {
          console.log("sd", res)
          resetFields()
          setLoading(false)
          scanInput.current.focus()
          if (res.response.data.hasOwnProperty('message') && res.response.status === 400) {
            onSubmit({ error: res.response.data.message })
          }
          else {
            onSubmit('Not a valid Scan Item');
          }
        })
    }
    else if ((input_value.includes('STATION') === false && Cookies.get('STATION') === undefined)
      /*|| parseInt(Cookies.get('SCAN_TYPE')) !== releaseScanTypeId*/) {
      resetFields()
      scanInput.current.focus()
      return onSubmit({ error: 'Please Scan Station Card First' })
    }
    else {
      console.log("releaseScanTypeId", releaseScanTypeId)
      payload = {
        station_id: Cookies.get('STATION'),
        barcode: input_value,
        scan_type_id: releaseScanTypeId,
        press_release: true,
        check_duplicate: true,
        check_qa_fail_pop: true,
      };
      setLoading(true)
      ZenSmartAPI.post(normalBarcodeScanRoute, payload)
        .then((res) => {
          resetFields()
          setLoading(false)
          scanInput.current.focus()
          prompSubmitScan(res, payload, input_value)
        })
        .catch((res) => {
          resetFields()
          setLoading(false)
          scanInput.current.focus()
          if (res.response.data.hasOwnProperty('message') && res.response.status === 400) {
            onSubmit({ error: res.response.data.message })
          }
          else {
            onSubmit('Not a valid Scan Item');
          }
        })
    }

  }

  const prompSubmitScan = (res, payload, input_value) => {
    if (res.data && res.data.duplicate_scan_prompt) {
      Modal.confirm({
        'content': res.data.duplicate_scan_prompt + " .Would you like to continue performing the scan?",
        onOk: () => {
          payload = {
            station_id: Cookies.get('STATION'),
            barcode: input_value,
            scan_type_id: releaseScanTypeId,
            press_release: true,
            check_qa_fail_pop: !res.data.duplicate_scan_prompt.includes("qa fail state")
          };
          setLoading(true)
          ZenSmartAPI.post(normalBarcodeScanRoute, payload)
            .then((res) => {
              if (res.data && res.data.duplicate_scan_prompt) {
                prompSubmitScan(res, payload, input_value)
              }
              else {
                resetFields()
                setLoading(false)
                scanInput.current.focus()
                onSubmit({ ...res.data, success_scan: true })
              }
            })
            .catch((res) => {
              resetFields()
              setLoading(false)
              scanInput.current.focus()
              if (res.response.data.hasOwnProperty('message') && res.response.status === 400) {
                onSubmit({ error: res.response.data.message })
              }
              else {
                onSubmit('Not a valid Scan Item');
              }
            })
        },
      });
    }
    onSubmit({ ...res.data, success_scan: true })
  }
  useEffect(() => {
    if (scanInput.current) {
      scanInput.current.focus()
    }
    return clearRefocusQueue
  }, [])

  useEffect(() => {
    resetFields();
  }, [isViewingDetails, resetFields])

  return (
    <Form onSubmit={handleSubmit}>
      {getFieldDecorator('lookup_text')(
        <Input
          type="text"
          placeholder="Scan Item"
          ref={scanInput}
          onFocus={clearRefocusQueue}
          onBlur={refocusQueue}
          {...inputProps}
          disabled={loading}
          loading={loading}
        />
      )}
    </Form>
  )
})

function DetailLookup(props) {
  const {
    title,
    detailLabel,
    detailLabelStatus,
    isViewingDetails,
    onLookup,
    inputProps,
    labelProps,
    children,
    assuranceFailInput,
    releaseScanTypeId
  } = props

  return (
    <Panel title={title} stylings={panelStylings}>
      <StyledRow type="flex" justify="center">
        <Label status={detailLabelStatus} {...labelProps}>{detailLabel}</Label>
      </StyledRow>

      <StyledRow type="flex" justify="center">
        <Col span={8}>
          <LookupForm onSubmit={onLookup} inputProps={inputProps} isViewingDetails={isViewingDetails} assuranceFailInput={assuranceFailInput} releaseScanTypeId={releaseScanTypeId} />
        </Col>
      </StyledRow>
      {children}
    </Panel>
  )
}

export default DetailLookup