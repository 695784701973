import is from "is_js";
import { notification } from "antd";

const APPLICATION_ERROR_MSG = "Application error occured";

//show error notifications and log error to browser console
export const logError = (err, showNotification) => {
    let appErrors = [], consoleError = "";

    if (is.existy(err.response) && is.existy(err.response.data)) {
        if (is.existy(err.response.data.errors)){
            Object.values(err.response.data.errors).forEach(value => {
                appErrors = [...appErrors, value];
                consoleError = consoleError + value +"\n";
            });
        }
        else if (is.existy(err.response.data.message)) {
            appErrors = [...appErrors, err.response.data.message];
            consoleError = err.response.data.message;
        } else {
            appErrors = [...appErrors, APPLICATION_ERROR_MSG];
            consoleError = err.response.data;
        }
    } else {
        appErrors = [...appErrors, APPLICATION_ERROR_MSG];
        consoleError = err;
    }

    //show notifications if enabled
    showNotification && appErrors.forEach(appError => {
        notification.error({ message: appError })
    });

    console.log(consoleError);
}