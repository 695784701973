import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { DROP } from 'types'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import styled from 'styled-components'

const Container = styled.div`
  border-top: 2px solid ${color.nav.unselected};
  margin: auto auto;
  flex-grow: ${props => props.dragType ? '0' : '1'};
  min-width: ${props => props.minWidth};
  max-height: 56px;
`

function JoinLine({ isOneChild, processID, dragType }) {
  return (
    <Droppable
      type={'Barcode Stage'}
      // type={whitelist.includes(dragType) && dragType}
      droppableId={`<${DROP.SIBLING}>${processID}`}
    >
      {provided => {
        return (
          <Container
            {...provided.droppableProps}
            ref={provided.innerRef}
            minWidth={`${isOneChild ? '12px' : '1.4em'}`}
            dragType={dragType}
          >
            <div>
              {provided.placeholder}
            </div>
          </Container>
        )
      }}
    </Droppable>
  )
}

export default JoinLine