import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Row, Col, Tag ,Button , Modal , notification} from 'antd'
import { panelSortData } from 'utils/sortData'
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { checkoutInitialData } from "utils/panelsInitialData"
import { ZenSmartAPI } from 'utils'
const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

`
const DueDateTag = styled(Tag)`
    padding : 5px 17px;
    font-size : 12px;
`
const dueDateColor = {
    later: "purple",
    today: "blue",
    tomorrow: "green"
}
const TableData = (props) => {

    const { itemData, loading, data, setData, ascend, setAscend, fetchData} = props
    const sortingData = (data, property, ifNotProperty) => {
        if (ascend[property] === null) {
            setAscend({ ...checkoutInitialData, [property]: true })
        }
        else {
            ascend[property] === true ? setAscend({ ...checkoutInitialData, [property]: false }) : setAscend({ ...checkoutInitialData, [property]: true })
        }
        setData(panelSortData(data, property, ascend[property], ifNotProperty))
    }
    const releaseOrder = (id) => {
        Modal.confirm({
            'content': "Are you sure you want this order to be returned to production?",
            onOk: () => {
                ZenSmartAPI.put('/api/v1/orders/'+id+'/checkin')
                .then((res) => {
                    fetchData()
                    notification.success({ message: "Order returned to production!" })
                })
                .catch((res) => {
                    if(res?.response?.data)
                    {
                        notification.error({ message: res.response.data.message })
                    }
                })
            },
        });
    }
    const getHeaders = (property, text, ifNotProperty) => {
        return (
            <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifNotProperty)} style={{ cursor: "pointer" }}>
                <Col span={ascend[property] === null ? 24 : 20}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }
    const columns = [
        {
            title: getHeaders("order_number", "ORDER", true),
            dataIndex: 'order_number',
            key: 'order_number',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("product", "PRODUCT", true),
            dataIndex: 'product',
            key: 'product',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("file", "FILES", false),
            dataIndex: 'file',
            key: 'file',
            render: (record, row, index) => {
                const value =
                    record.map((data, key) =>
                        <p>
                            <a href={data.link} style={{ color: "blue", textDecorationLine: "underline" }}>{data.value}{(key + 1) < record.length && ","} </a>
                        </p>
                    )

                return rowStyle(value, index)
            }
        },
        {
            title: getHeaders("checked_out", "CHECKED OUT", false),
            dataIndex: 'checked_out',
            key: 'checked_out',
            render(value, row, index) {
                const dataValue = moment.unix(value).format("D MMM YYYY, h:mma")
                return rowStyle(value ? dataValue : "-", index, row)
            },
        },
        {
            title: getHeaders("box_number", "BOX NUMBER", false),
            dataIndex: 'box_number',
            key: 'box_number',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("reason", "REASON", false),
            dataIndex: 'reason',
            key: 'reason',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("comments", "COMMENTS", false),
            dataIndex: 'comments',
            key: 'comments',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("due_date", "DUE", false),
            dataIndex: 'due_date',
            key: 'due_date',
            render(record, row, index) {
                const value = <DueDateTag color={dueDateColor[record.toLowerCase()] ?
                    dueDateColor[record.toLowerCase()] : "red"}
                    key={record}>
                    {record.toUpperCase()}
                </DueDateTag>
                return rowStyle(record ? value : "-", index, row)
            },
        },
        {
            title: "Actions",
            dataIndex: 'actions',
            key: 'actions',
            render: (obj, row) => 
            <>
                <Button type="primary" ghost onClick={ e => releaseOrder(row.order_id) }>Release</Button>
            </>

        },
    ];

    const rowStyle = (value, index) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }

        return obj
    }

    useEffect(() => {

        if (itemData) {

            const itemObj = itemData.map(data => {
                const obj = {
                    order_number: data.order_number,
                    product: data.product,
                    file: data.file,
                    checked_out: data.checked_out,
                    box_number: data.box_number,
                    reason: data.reason,
                    comments: data.comments,
                    due_date: data.due_date,
                    order_id: data.order_id,
                }
                return obj;
            })

            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "45vh" }}
                    pagination={{ pageSize: 50 }}

                />
            </StyleTable>
        </>
    )
}

export default TableData