import React from "react";
import { Table, Input, Form, Button, Select, Spin, notification, Checkbox } from "antd";
import is from "is_js";
import "./RecipeEditableTable.css";
import { connect } from "react-redux";
import { addPaperLaminationCodeRoute } from "utils/apiRoutes";
import { logError } from "utils/logging";
import { ZenSmartAPI } from "utils";
import { alphabeticalData } from "utils/sortData";
import * as actions from "../../../store/actions";
import * as _ from "lodash";

const { Option } = Select;

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "dropdown") {
      return this.InputDropDown(this.props);
    }
    return <Input className='form-width' />;
  };

  InputDropDown = (dropDown) => {
    const optionsType = dropDown.dataIndex;
    let dropDownOptions = [];
    if (
      optionsType === "paper_code" &&
      is.existy(dropDown.paperCodesOptions) &&
      is.not.empty(dropDown.paperCodesOptions)
    ) {
      dropDownOptions = dropDown.paperCodesOptions;
    } else if (
      optionsType === "lamination_code" &&
      is.existy(dropDown.laminationCodesOptions) &&
      is.not.empty(dropDown.laminationCodesOptions)
    ) {
      dropDownOptions = dropDown.laminationCodesOptions;
    } else if (
      optionsType === "printer_name" &&
      is.existy(dropDown.specifiedPrintersOptions)
    ) {
      dropDownOptions = dropDown.specifiedPrintersOptions;
    } else if (
      optionsType === "output_format" &&
      is.existy(dropDown.outputFormatOptions)
    ) {
      dropDownOptions = dropDown.outputFormatOptions;
    }

    const addCodes = (evt) => {
      const code = evt.target.value;
      const product_id = this.props.product;
      const block_id = this.props.block;

      if (evt.keyCode === 13) {
        if (optionsType === "lamination_code" || optionsType === "paper_code") {
          const codeExist = dropDownOptions.some((data) => {
            return data.code === evt.target.value;
          });

          if (!codeExist) {
            const payload = {
              product_block_id: block_id,
              component_type: optionsType === "paper_code" ? "Paper" : "Lamination",
              value: code
            };
            this.props.setLoading(true);
            evt.target.blur();
            const optType = optionsType.replace(/_/g, " ");
            ZenSmartAPI.put(addPaperLaminationCodeRoute(product_id),
              payload
            )
              .then((res) => {
                const response = res.data.data;

                if (is.existy(response) && response.component_type === "Paper") {
                  this.props.setPaperCodes(response.value);
                }else if (is.existy(response) && response.component_type === "Lamination") {
                  this.props.setLaminationCodes(response.value);
                }

                const updatedProducts = _.cloneDeep(this.props.products).map((product) => {
                  if (product.id === product_id && response.component_type === "Paper") {
                    return {...product, paper_codes: response.value }
                  }else if (product.id === product_id && response.component_type === "Lamination") {
                    return {...product, lamination_codes: response.value };
                  }else{
                    return product;
                  }
                });

                this.props.updateProducts(updatedProducts);

                notification.info({
                  message: "New " + response.component_type + " Code created successfully",
                });
              })
              .catch((err) => {
                logError(err, true);
              })
              .finally(() => {
                this.props.setLoading(false);
              });
          }
        }
      }
    };

    return (
      <Select
        showSearch
        className='form-width'
        placeholder='Select'
        onInputKeyDown={(event) => addCodes(event)}
      >
        {alphabeticalData(dropDownOptions, dropDown.valueLabel).map((item) => {
          const optionId = dropDown.idLabel ? ((item.code !== undefined) ? item.code : item.id) : item;
          const optionValue = dropDown.valueLabel
            ? item[dropDown.valueLabel]
            : item;
          return (
            <Option key={optionId} value={optionId}>
              {optionValue}
            </Option>
          );
        })}
      </Select>
    );
  };

  backSlashCheck = (rule, value, callback) => {
    const regex = /\\/gm;
    if (regex.exec(value) !== null) {
      return callback(false);
    }
    return callback();
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      idLabel,
      valueLabel,
      productId,
      paperCodesOptions,
      setPaperCodes,
      laminationCodesOptions,
      setLaminationCodes,
      specifiedPrintersOptions,
      setSpecifiedPrinters,
      setLoading,
      defaultValue,
      ...restProps
    } = this.props;

    let inputRules = {
      rules: [
        {
          required: true,
          message: `Please Input ${title}`,
        },
      ],
    };

    let inputPath = {
      rules: [
        {
          message: `Backslash is not allowed in ${title}`,
          validator: this.backSlashCheck,
        },
        {
          required: true,
          message: `Please Input ${title}`,
        },
      ],
    };

    let inputPrePath = {
      rules: [
        {
          message: `Backslash is not allowed in ${title}`,
          validator: this.backSlashCheck,
        }
      ],
    };

    if (dataIndex == "path") {
      inputRules = inputPath;
    }
    if (dataIndex == "facility") {
      inputRules.rules[0].required = false;
    }

    if (dataIndex == "pre_path") {
      inputRules = inputPrePath;
    }

    if (typeof defaultValue !== "undefined") {
      inputRules.initialValue = defaultValue;
    }

    if (
      typeof record !== "undefined" &&
      (record[idLabel] || record[dataIndex])
    ) {
      inputRules.initialValue = idLabel ? record[idLabel] : record[dataIndex];
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(
              idLabel ? idLabel : dataIndex,
              inputRules
            )(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    const { tableDatasource, tableColumns } = this.props;
    this.state = {
      dataSource: tableDatasource,
      dataCount: tableDatasource.length,
      editingKey: "",
      loading: false,
    };

    this.columns = [
      ...tableColumns,
      {
        title: "Auto Send",
        dataIndex: "auto_send",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return  (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Checkbox
                    name={`auto_send[${record.key}]`}
                    onClick={(e) => this.editAutoSend(e,record.key)}
                    style={{ marginRight: 8 }}
                    checked={(text === 1 ? true : false)}
                  >
                  </Checkbox>
                )}
              </EditableContext.Consumer>
            </span>
          )
        },
      },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </Button>
                )}
              </EditableContext.Consumer>
              <Button onClick={() => this.cancel(record.key)}>Cancel</Button>
            </span>
          ) : (
            <span>
              <Button
                disabled={editingKey !== ""}
                onClick={() => this.edit(record.key)}
                style={{ marginRight: 8 }}
              >
                Edit
              </Button>
              <Button onClick={() => this.handleDelete(record.key)}>
                Delete
              </Button>
            </span>
          );
        },
      },
    ];
  }

  handleAdd = () => {
    const { dataCount, dataSource } = this.state;
    const newData = {
      key: dataCount,
    };
    this.setState({
      editingKey: dataCount,
      dataSource: [...dataSource, newData],
      dataCount: dataCount + 1,
    });
  };

  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource];
    const filteredDataSource = dataSource.filter((item) => item.key !== key);
    this.setState({
      dataSource: filteredDataSource,
    });
    this.props.setTableDataSource(filteredDataSource);
  };

  isEditing = (record) => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const checkDuplicate = this.state.dataSource.find(
        (datasource) =>
          datasource.printer_id &&
          datasource.printer_id === row.printer_id &&
          datasource.paper_code &&
          datasource.paper_code === row.paper_code &&
          datasource.lamination_code &&
          datasource.lamination_code === row.lamination_code &&
          datasource.output_format &&
          datasource.output_format === row.output_format &&
          datasource.facility === row.facility
      );

      if (checkDuplicate && checkDuplicate.key !== key) {
        notification.error({
          message: "Duplicate record",
        });
        return;
      }

      const filteredPrinter = this.props.specifiedPrinters.find(
        (printer) => printer.id === row.printer_id
      );

      const filteredPaper = this.props.paperCodes.find(
        (paper) => paper.code === row.paper_code
      );

      const filteredLamination = this.props.laminationCodes.find(
        (lamination) => lamination.code === row.lamination_code
      );
      
      const newData = [...this.state.dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
          printer_name: filteredPrinter.full_name,
          paper_code: filteredPaper.code,
          lamination_code: filteredLamination.code,
        });
      } else {
        newData.push(row);
      }

      this.setState({ dataSource: newData, editingKey: "" });
      this.props.setTableDataSource(newData);
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  editAutoSend(e,key){
    const status = (e.target.checked === true ? 1 : 0)
    const dataSource = [...this.state.dataSource];
    const filteredDataSource = dataSource.map(item => (item.key === key ? this.setAutoSend(item,status) : this.setAutoSend(item,0) ))
    this.setState({
      dataSource: filteredDataSource,
    });
    this.props.setTableDataSource(filteredDataSource);
  }

  setAutoSend = (item,value) => {
    return Object.assign({}, item, {
      auto_send : value
    });
  }

  setLoading = (tableState) => {
    this.setState({ loading: tableState });
  };

  render() {
    const { loading } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.type,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          idLabel: col.idLabel,
          valueLabel: col.valueLabel,
          product: this.props.productId,
          block: this.props.blockId,
          products: this.props.products,
          updateProducts: this.props.updateProducts,
          paperCodesOptions: this.props.paperCodes,
          setPaperCodes: this.props.setPaperCodes,
          laminationCodesOptions: this.props.laminationCodes,
          setLaminationCodes: this.props.setLaminationCodes,
          specifiedPrintersOptions: this.props.specifiedPrinters,
          setSpecifiedPrinters: this.props.setSpecifiedPrinters,
          outputFormatOptions: this.props.outputFormat,
          setLoading: this.setLoading,
          defaultValue: col.defaultValue,
        }),
      };
    });

    return (
      <Spin tip='Loading...' spinning={this.state.loading}>
        <EditableContext.Provider value={this.props.form}>
          <Button
            disabled={this.state.editingKey !== "" ? true : false}
            onClick={this.handleAdd}
            type='primary'
            style={{ marginBottom: 16 }}
          >
            Add a row
          </Button>
          <Table
            components={components}
            bordered
            dataSource={this.state.dataSource}
            columns={columns}
            rowClassName='editable-row'
          />
        </EditableContext.Provider>
      </Spin>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

const mapStateToProps = (state) => {
  return {
    products: state.imposition.products,
    productId: state.imposition.product,
    blockId: state.imposition.block
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProducts: (products) => dispatch(actions.updateProducts(products)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditableFormTable);
