import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Row, Col, notification, Tag, Collapse, Table, Spin } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import DetailLookup from './DetailLookup'
import Cookies from 'js-cookie';
import _ from "lodash"
import PrintThisComponent from "components/composites/printTicket"
import { useReactToPrint } from 'react-to-print';
import { Label } from 'components/zensmart-design-system/components/Label/Label.js'

import { allScanTypesRoute , normalBarcodeScanRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'
import { dueDateColor } from "./mockData.js"
import BarcodePressReleaseModal from './BarcodePressReleaseModal';
import Image from "../../components/zensmart-design-system/components/Image/Image";
const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`

const Container = styled.div`
  padding-bottom: 64px;
  font-size : 10px;

`
const scanInputProps = {
  size: 'large',
  placeholder: 'Item Lookup'
}

const backButtonProps = {
  size: 'large'
}


const HeaderContainer = styled.div`
  padding-bottom: 5px;
  font-family: 'Rubik', sans-serif;
`

const BlockDetail = styled.div`
  padding: 10px 30px;
`;


const BlockHeader = styled.h1`
  text-align: center;
  font-weight: bold;
  color: ${color.heading};
  margin-right: 8px;
  font-size: 15px;
`;

const DueDateTag = styled(Tag)`
  padding: 5px 20px;
  font-size: 12px;
`;

const StyledTable = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
`;

const FlexImageWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;

  div {
    padding: 0 5px;
    text-align: center;
    margin-bottom: 5px;
  }
`;

const { Panel } = Collapse


const Scans = (props) => {
  const { scanData } = props
  const [releaseScanTypeId, setReleaseScanTypeId] = useState(null)
  const [stationId, setStationId] = useState(null)
  const [scanItem, setScanItem] = useState(null)
  const [blockSource, setBlockSource] = useState([]);
  const [blockColumns, setblockColumns] = useState([])
  const [thermalLabelPNG, setThermalLabelPNG] = useState(null);
  const orderDetail = scanItem ? scanItem.page_definitions.sections[0] : null;
  const relatedBlock = scanItem
    ? scanItem.page_definitions.sections.find(
      (val) => val.section_header === "RELATED BLOCKS"
    )
    : null;
  const [showModal, setShowModal] = useState(false)
  const [files, setFiles] = useState([])  

  const handleLookup = useCallback((lookupValue) => {
    if (lookupValue.hasOwnProperty('error') &&
      !(lookupValue.hasOwnProperty('message') &&
        lookupValue.message.includes("SCAN SUCCESSFUL"))
    ) {
      notification.error({
        message: lookupValue.error
      })
    }

    else if (lookupValue.hasOwnProperty('error') &&
      (lookupValue.hasOwnProperty('message') &&
        lookupValue.message.includes("SCAN SUCCESSFUL") &&
        lookupValue.hasOwnProperty('file_name') &&
        lookupValue.file_name != ""
      )
    ) {
      notification.success({
        message: lookupValue.file_name.replace("_", "_ ") + ' successfully sent to print',
      })

      setScanItem(lookupValue)
    }

    else if (lookupValue.hasOwnProperty('station_id')) {
      Cookies.set('STATION', lookupValue.station_id, { expires: 1 });
      setStationId(lookupValue.station_id);
      notification.success({
        message: 'Station Scan Successful'
      })
    }

    else if (lookupValue.hasOwnProperty('success_scan')) {
      notification.success({
        message: 'Barcode Scan Successful'
      })
      setScanItem(lookupValue)
      if(!lookupValue.specific_message && lookupValue.duplicate_scan_prompt)
      {
        setFiles(false)
      }
      else if(!lookupValue.specific_message)
      {
        setFiles(lookupValue.page_definitions.sections[1].section_data)
      }
      else{
        setFiles([]);
      }
    }

    else {
      notification.error({
        message: 'Invalid scan item. Please scan a valid barcode.'
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const removeScanItem = useCallback(() => {
    setScanItem(null)
  }, [])

  useEffect(() => {
    ZenSmartAPI.get(allScanTypesRoute).then(({ data }) => {
      if (data.data && Array.isArray(data.data)) {
        var found = data.data.filter(el => el['text'] == 'print submit')
        if (found.length > 0) {
          setReleaseScanTypeId(found[0]['id'])
        }
      }
    }).catch((err) => {
      notification.error({
        message: "Error loading scan types"
      });
    });
  }, []);

  useEffect(() => {
    if (Cookies.get('STATION')) {
      setStationId(Cookies.get('STATION'));
    }
  }, []);

  useEffect(() => {

    if (files.length != 0 && files) {

      setShowModal(true)

    }

  }, [files]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleClick = (position_value) => {
    if(scanItem.page_definitions.sections[0]){
      const payload = { barcode: scanItem.page_definitions.sections[0].section_header.replace('Block #',''), scan_type_id: releaseScanTypeId , position : position_value, station_id : stationId , check_duplicate: false ,
      press_release: true }
      ZenSmartAPI.post(normalBarcodeScanRoute, payload)
          .then((res) => {
            setShowModal(false)
            setFiles([])
            notification.success({
              message: 'Send For Printing'
            })
          })
          .catch((res) => {
            if (res.response.data.hasOwnProperty('message') && res.response.status === 400) {
              notification.error({ message: res.response.data.message })
            }
          })
    }
  }

  const onModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (relatedBlock) {
      const blockItemColumns = relatedBlock.table_headers.map((data, key) => {
        return {
          title: data,
          dataIndex: data,
          key: data,
        };
      });

      const blockItemSource = relatedBlock.section_data.map((data, key) => {
        const sourceDataObj = {};
        data.section_data.map((item, key) => {
          return (sourceDataObj[item.label] = item.value);
        });
        return sourceDataObj;
      });
      setBlockSource(blockItemSource);
      setblockColumns(blockItemColumns);
    }
  }, [scanItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const getToUrl = (value) => {
    window.location.href = value
  }

  const getWarning = (data) => {
    const warningData = data ? data.page_definitions.sections.find(val => val.section_header === data.scan_type) : null;
    if (warningData) {
      const findWarning = warningData.section_data.find(val => val.label === "WARNING:")
      if (findWarning) {
        return (
          <p style={{ textAlign: "center", marginBottom: 20 }}>
            <Label status="red">
              {findWarning.label} {findWarning.value}
            </Label>
          </p>
        )
      }
    }
  }

  return (
    <>
      <HeaderContainer>
        <Header>
          <Title>Print {">"} Barcode Press Release</Title>
        </Header>
        <div>
          <Spin spinning={(!releaseScanTypeId)}>
            <DetailLookup
              title={`BARCODE PRESS RELEASE`}
              isViewingDetails={scanItem}
              detailLabel={stationId ? "PLEASE SCAN BARCODE" : "PLEASE SCAN STATION CARD" /*scanItem ? scanItem.message : 'PLEASE SCAN'*/}
              detailLabelStatus={scanItem ? 'mountain_meadow' : 'blue'}
              backButtonText="New Scan"
              onLookup={handleLookup}
              onBackButtonClick={removeScanItem}
              inputProps={scanInputProps}
              backButtonProps={backButtonProps}
              releaseScanTypeId={releaseScanTypeId}
            >
              <Container>
                {scanItem && files.length == 0 && files ? (
                  <>
                    <BlockDetail>
                      {getWarning(scanItem)}
                      <BlockHeader>{orderDetail.section_header}</BlockHeader>
                      <Collapse
                        style={{ marginTop: 20 }}
                        defaultActiveKey={["1"]}
                      >
                        <Panel header="ORDER DETAILS " key="1">
                          <Row>
                            {scanItem.is_print_job_ticket &&
                              scanItem.action === "open" ? (
                              getToUrl(
                                `${scanItem.ticket_html}${localStorage.token}`
                              )
                            ) : (
                              <PrintThisComponent url={scanItem.ticket_html} />
                            )}
                            {orderDetail.section_header.includes("Batch") ? (
                              orderDetail.section_data.map((data, key) => {
                                return (
                                  <Col span={12} style={{ padding: 5 }}>
                                    <Col span={12}>
                                      <span>{data.label.toUpperCase()}</span>
                                    </Col>
                                    <Col span={12}>
                                      {data.label === "Due Date:" ? (
                                        <>
                                          <DueDateTag
                                            color={
                                              dueDateColor[
                                                data.value.due_date_text.toLowerCase()
                                              ]
                                                ? dueDateColor[
                                                data.value.due_date_text.toLowerCase()
                                                ]
                                                : "red"
                                            }
                                            key={data.value.due_date_text}
                                          >
                                            {data.value.due_date_text.toUpperCase()}
                                          </DueDateTag>
                                          <span>
                                            {" "}
                                            {data.value.formatted_due_date}{" "}
                                          </span>
                                        </>
                                      ) : data.label === "ticket_html" ? (
                                        <PrintThisComponent
                                          url={data.value.file_url}
                                          width={data.value.width}
                                          height={data.value.height}
                                        />
                                      ) : (
                                        <span> {data.value} </span>
                                      )}
                                    </Col>
                                  </Col>
                                );
                              })
                            ) : (
                              <>
                                <Col span={12} style={{ padding: 5 }}>
                                  {orderDetail.section_data.map((data, key) => {
                                    return (
                                      <>
                                        <Col span={12} style={{ padding: 5 }}>
                                          <span>{data.label.toUpperCase()}</span>
                                        </Col>
                                        <Col span={12} style={{ padding: 5 }}>
                                          {data.label === "Due Date:" ? (
                                            <>
                                              <DueDateTag
                                                color={
                                                  dueDateColor[
                                                    data.value.due_date_text.toLowerCase()
                                                  ]
                                                    ? dueDateColor[
                                                    data.value.due_date_text.toLowerCase()
                                                    ]
                                                    : "red"
                                                }
                                                key={data.value.due_date_text}
                                              >
                                                {data.value.due_date_text.toUpperCase()}
                                              </DueDateTag>
                                              <span>
                                                {" "}
                                                {
                                                  data.value.formatted_due_date
                                                }{" "}
                                              </span>
                                            </>
                                          ) : (
                                            <span> {data.value} </span>
                                          )}
                                        </Col>
                                      </>
                                    );
                                  })}
                                </Col>
                                <Col span={12}>
                                  <FlexImageWrapper>
                                    {orderDetail.thumbnails.front && (                                        <Image
                                            src={orderDetail.thumbnails.front}
                                            alt=""
                                            width={160}
                                            height={300}
                                            objectFit="fill"
                                        />
                                    )}
                                    {orderDetail.thumbnails.back && (
                                        <Image
                                            src={orderDetail.thumbnails.back}
                                            alt=""
                                            width={160}
                                            height={300}
                                            objectFit="fill"
                                        />
                                    )}
                                  </FlexImageWrapper>
                                </Col>
                              </>
                            )}
                          </Row>
                        </Panel>
                      </Collapse>
                    </BlockDetail>
                    {relatedBlock && (
                      <BlockDetail>
                        <Collapse defaultActiveKey={["1"]}>
                          <Panel header={relatedBlock.section_header} key="1">
                            <StyledTable
                              columns={blockColumns}
                              dataSource={blockSource}
                            />
                          </Panel>
                        </Collapse>
                      </BlockDetail>
                    )}
                  </>
                ) : null}

              </Container>
            </DetailLookup>
          </Spin>
        </div>
      </HeaderContainer>
      <div>
        <BarcodePressReleaseModal
          visible={showModal}
          onClose={onModalClose}
          filesData={files}
          handleClick={handleClick}
        />
      </div>                

    </>
  )
}

export default Scans