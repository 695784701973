import React, { useState, useEffect } from 'react'
import { Table, Select, Button, Form, TimePicker, Modal, InputNumber, Row, Col, Checkbox,notification } from 'antd';
import moment from 'moment';
import { generateKey } from '../../utils';
import { PlusCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { alphabeticalData } from "utils/sortData"

import { color } from 'components/zensmart-design-system/shared/styles.js';
import QualifierInput
  from '../../../../components/composites/QualifierInput';
import _ from 'lodash';
import AutoCompleteBar from '../../../../components/composites/autoCompleteBar';

const { Column } = Table;

const DeleteButton = styled.button`
  font-size: 12px;
  color: ${color.red.dark};
  font-weight: bold;
`
const ModalTitle = styled.h3`
  font-weight: bold;
`

const DayPicker = styled.div`
  display: flex;

  .ant-select-selection--single  {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 115px;
  }

  .ant-time-picker {
    width: 90px;
  }

  .ant-time-picker-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const ModalSubTitle = styled.p`
  font-weight: 300;
  margin: 10px 0;
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0243EB;
  font-size: 12px;
  border: 0;
  margin-top: 10px;
  box-shadow: none;
`

const PlusCircleFilledIcon = styled(PlusCircleFilled)`
  font-size: 16px;
`

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-label {
    display: none
  }
`;

const BatchRules = ({ visible, onCancel, batchRules, setBatchRules, originalBatchRules, setOriginalBatchRules, qualifiers, batchRulesOptions, processID, process, setModal, onSave }) => {
  const [errors] = useState({});
  const [waitThreshold, setWaitThreshold] = useState(null);
  const onRemove = (item) => {
    const newData = [...batchRules];
    newData.splice(newData.indexOf(item), 1);

    setBatchRules(newData)
  }

  const handleChangeSelect = (items, val, e) => {
    const selectedRow = batchRules.findIndex(selectedRow => selectedRow.key === e.key);
    const newData = [...batchRules];

    newData[selectedRow].ruleType = val;

    setBatchRules(newData)
  };

  const onAddNewRule = () => {
    const newRule = {
      key: generateKey(),
      ruleValue: '',
      qualifier: '',
      fields: '',
      ruleType: ''
    };

    const newData = [
      ...batchRules,
      newRule
    ];
    setOriginalBatchRules(newData)
    setBatchRules(newData)
  }

  const onChangeDayPicker = (value, item, isFrom) => {
    const selectedRow = batchRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = [...batchRules];

    if (isFrom) {
      const scheduleFrom = newData[selectedRow].scheduleFrom.split(" ");
      newData[selectedRow].scheduleFrom = `${value} ${scheduleFrom[1]}`;
      setBatchRules(newData);
    } else {
      const scheduleTo = newData[selectedRow].scheduleTo.split(" ");
      newData[selectedRow].scheduleTo = `${value} ${scheduleTo[1]}`;
      setBatchRules(newData);
    }

  }

  const constructModalHeader = () => (
    <>
      <ModalTitle>Batch Rules</ModalTitle>
      <ModalSubTitle>Use the tools below to set your batch rules.</ModalSubTitle>
    </>
  )

  const onChangeTimePicker = (value, item, isFrom) => {
    const selectedRow = batchRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = [...batchRules];

    if (isFrom) {
      const scheduleFrom = newData[selectedRow].scheduleFrom.split(" ");
      newData[selectedRow].scheduleFrom = `${scheduleFrom[0]} ${moment(value).format('HH:mm')}`;
      setBatchRules(newData);
    } else {
      const scheduleTo = newData[selectedRow].scheduleTo.split(" ");
      newData[selectedRow].scheduleTo = `${scheduleTo[0]} ${moment(value).format('HH:mm')}`;
      setBatchRules(newData);
    }

  }

  const onChangeTimeToNull = (value, item) => {
    const selectedRow = batchRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = batchRules.map((item, key) => {
      return (
        key === selectedRow
          ? {
            ...item, scheduleFrom: value.target.checked ? null : originalBatchRules[selectedRow].scheduleFrom ?
              originalBatchRules[selectedRow].scheduleFrom : `${days[new Date().getDay()]} 00:00`,
            scheduleTo: value.target.checked ? null : originalBatchRules[selectedRow].scheduleTo ? originalBatchRules[selectedRow].scheduleTo : `${days[new Date().getDay()]} 00:00`
          }
          : item
      )
    })
    setBatchRules(newData);
  };

  const onChange = (value, item, name) => {
    const selectedRow = batchRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = [...batchRules];
    newData[selectedRow][name] = value;

    setBatchRules(newData);
  }

  const setQualifierValue = (value, item) => {
    const selectedRow = batchRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = [...batchRules];
    newData[selectedRow].qualifier = value;

    setBatchRules(newData);
  };

  const setQualifierField = (modelDefinition, item) => {
    const selectedRow = batchRules.findIndex(selectedRow => selectedRow.key === item.key);
    const newData = [...batchRules];

    if (!modelDefinition) {
      newData[selectedRow].qualifierField = {};
    } else {
      newData[selectedRow].qualifierField = modelDefinition;
    }

    setBatchRules(newData);
  }

  const saveBatchRules = (e) => {
    const emptyRuleValue = batchRules.filter(item => (item.ruleValue == '' ||  item.ruleValue === null))
    const emptyRuleType = batchRules.filter(item => (item.ruleType == '' ||  item.ruleType === null))
    if(emptyRuleType.length !== 0){
      notification.error({ message: "Please select field!" })
      return false;
    }
    if(emptyRuleValue.length !== 0){
      notification.error({ message: "Please select rule value!" })
      return false;
    }
    if (waitThreshold) {
      const threshData = { text: 'Ready for Release', wait_threshold: waitThreshold }
      onSave(threshData, processID);
      setWaitThreshold(null)
    }
    onCancel(e)

  }

  const cancelBatchRules = (e) => {
    const emptyRuleValue = batchRules.filter(item => (item.ruleValue == '' ||  item.ruleValue === null))
    const emptyRuleType = batchRules.filter(item => (item.ruleType == '' ||  item.ruleType === null))
    if(emptyRuleType.length !== 0){
      notification.error({ message: "Please select field!" })
      return false;
    }
    if(emptyRuleValue.length !== 0){
      notification.error({ message: "Please select rule value!" })
      return false;
    }
    if (waitThreshold) {
      const threshData = { text: 'Ready for Release', wait_threshold: waitThreshold }
      onSave(threshData, processID);
      setWaitThreshold(null)
    }
    setWaitThreshold(null)
    onCancel(e)
  }

  useEffect(() => {
      setWaitThreshold(process.wait_threshold)
  }, [process,visible]) // eslint-disable-line react-hooks/exhaustive-deps

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return (
    <Modal
      width={1400}
      title={constructModalHeader()}
      visible={visible}
      onOk={(e) => saveBatchRules(e)}
      onCancel={(e) => cancelBatchRules(e)}
    >

      <StyledFormItem
        validateStatus={errors['qualifier'] && 'error'}
        help={errors['qualifier'] && errors['qualifier'][0]}
        label="Qualifier"
      >
        <h1>Wait Threshold (hours)</h1>
        <InputNumber value={waitThreshold} placeholder={"Wait Threshold"} style={{ width: "20%" }} onChange={(e) => {
          setWaitThreshold(e);
        }} />

      </StyledFormItem>
      <Table dataSource={batchRules} style={{ paddingTop: 20 }} pagination={false} bordered={true}>
        <Column
          title="Fields"
          dataIndex="ruleType"
          key="ruleType"
          width={250}
          render={(ruleType, item) => {
            return (
              <Select
                value={ruleType}
                onChange={(e) => handleChangeSelect(ruleType, e, item)}
                style={{ width: '100%' }}
              >
                {alphabeticalData(batchRulesOptions).map(option => {
                  return (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  )
                })}

              </Select>
            )
          }}
        />
        <Column
          title="Rule Value"
          dataIndex="ruleValue"
          key="ruleValue"
          render={(ruleValue, item) => (
            <InputNumber value={ruleValue} onChange={(e) => onChange(e, item, 'ruleValue')} />
          )}
        />
        <Column
            title="Qualifier"
            dataIndex="qualifier"
            key="qualifier"
            render={(qualifier, item) => (
                <>
                  <StyledFormItem
                      validateStatus={errors['qualifier'] && 'error'}
                      help={errors['qualifier'] && errors['qualifier'][0]}
                      label="Qualifier"
                  >
                    <AutoCompleteBar
                        resource={["queues"]}
                        prefix={"lines"}
                        limit={1}
                        placeholder="Select the field qualifier should be checked against"
                        returnValue={(ids, values, tags) => setQualifierField(values[0], item)}
                        value={_.isEmpty(item.qualifierField) ? null : [item.qualifierField]}
                    />
                  </StyledFormItem>
                  <StyledFormItem
                      validateStatus={errors['qualifier'] && 'error'}
                      help={errors['qualifier'] && errors['qualifier'][0]}
                      label="Qualifier"
                  >
                    <QualifierInput
                        disabled={_.isEmpty(item.qualifierField)}
                        value={qualifier}
                        onChange={value => setQualifierValue(value, item)}
                        types={qualifiers}
                    />
                  </StyledFormItem>
                </>
            )}
        />
        <Column
          title="Schedule From"
          dataIndex="scheduleFrom"
          key="scheduleFrom"
          render={(scheduleFrom, item) => {
            const dayValue = scheduleFrom ? scheduleFrom.split(" ")[0] : '';
            const timeValue = scheduleFrom ? scheduleFrom.split(" ")[1] : '00:00';
            return (

              <DayPicker>
                <Select value={dayValue} placeholder="Day" disabled={!dayValue} onChange={(e) => onChangeDayPicker(e, item, 'isFrom')}>
                  {days.map(day => {
                    return <Select.Option key={day} value={day}>{day}</Select.Option>
                  })}
                </Select>
                <TimePicker
                  onChange={(e) => onChangeTimePicker(e, item, 'isFrom')}
                  defaultOpenValue={moment('00:00', 'HH:mm')}
                  format={'HH:mm'}
                  placeholder="Time"
                  value={moment(timeValue, 'HH:mm')}
                  allowClear={false}
                  disabled={!dayValue}
                />
              </DayPicker>
            )
          }}
        />

        <Column
          title="Schedule To"
          dataIndex="scheduleTo"
          key="scheduleTo"
          render={(scheduleTo, item) => {
            const dayValue = scheduleTo ? scheduleTo.split(" ")[0] : '';
            const timeValue = scheduleTo ? scheduleTo.split(" ")[1] : '00:00';
            return (
              <div style={{marginTop: '19px'}}>
                <Row type="flex" align="middle" justify="center">
                  <Col>
                    <DayPicker>
                      <Select value={dayValue} placeholder="Day" disabled={!dayValue} onChange={(e) => onChangeDayPicker(e, item)}>
                        {days.map(day => {
                          return <Select.Option key={day} value={day}>{day}</Select.Option>
                        })}
                      </Select>
                      <TimePicker
                        onChange={(e) => onChangeTimePicker(e, item)}
                        defaultOpenValue={moment('00:00', 'HH:mm')}
                        format={'HH:mm'}
                        placeholder="Time"
                        value={moment(timeValue, 'HH:mm')}
                        allowClear={false}
                        disabled={!dayValue}
                      />
                    </DayPicker>
                  </Col>
                </Row>
                <div>
                    <Checkbox onChange={(e) => onChangeTimeToNull(e, item)} defaultChecked={!item.scheduleFrom || !item.scheduleTo}>No Schedule</Checkbox>
                  </div>
              </div>
            )
          }}
        />

        <Column
            title="Inactive"
            render={record => {
              return <Row type="flex" align="middle" justify="center"><Checkbox
                  defaultChecked={record.inactive}
                  onChange={e => {
                    setBatchRules(batchRules.map(batchRule => {
                      if(record.id === batchRule.id) {
                        batchRule.inactive = e.target.checked
                      }
                      return batchRule
                    }))
                  }}
              />
              </Row>

            }}
        />

        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <DeleteButton onClick={() => onRemove(record)}>REMOVE</DeleteButton>
          )}
        />
      </Table>
      <AddButton onClick={onAddNewRule}>
        <PlusCircleFilledIcon />
            ADD NEW RULE
          </AddButton>
    </Modal>
  )
};

export default BatchRules;
