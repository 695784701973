import { GET_PRODUCT_ID } from "../actions/productFlowTypes";

const initialState = {
    product_id: null,

};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_ID:
            return {
                ...state,
                product_id: action.payload
            };


        default:
            return state;
    }
}