import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { getAllCells, cellActivityRoute } from "utils/apiRoutes"
import moment from 'moment';
import { cellActivityInitialData } from "utils/panelsInitialData"
import { alphabeticalData } from "utils/sortData"

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;

const CellActivityPage = (props) => {
    const [fetchDate, setFetchDate] = useState({ department_id: 0, from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') })
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [originalData, setOriginalData] = useState([]);
    const [cells, setCells] = useState([])
    const [ascend, setAscend] = useState(cellActivityInitialData)
    const [payload, setPayload] = useState({ cell: null, date: moment().format('YYYY-MM-DD') })
    const [headingSelectionValue, setHeadingSelectionValue] = useState("")
    const fetchcells = () => {

        setLoading(true)
        ZenSmartAPI.get(getAllCells)
            .then((res) => {
                setLoading(false)
                setCells(res.data.data)

            })
            .catch((res) => {
                setLoading(false)
            })

    }
    const fetchData = () => {

        if (!payload.cell) {
            notification.error({ message: "Please select a cell!" })
            return
        }

        setLoading(true)
        ZenSmartAPI.get(cellActivityRoute(payload.cell, payload.date))
            .then((res) => {
                setLoading(false)
                if (res.data.data.length > 0) {
                    setItemData(res.data.data)
                    setOriginalData(res.data.data)
                    setAscend(cellActivityInitialData)
                }
                else {
                    notification.error({ message: "No data found!" })
                    setItemData([])
                }
                setHeadingSelectionValue(`${payload.cell}: ${payload.date}`)
            })
            .catch((error) => {
                setLoading(false);
                // handle validation errors

                if (_.get(error, 'response.status') === 400) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An unhandled error occurred. Please contact your supervisor.'),
                    });
                }

                if (_.get(error, 'response.status') > 499) {
                    notification.error({
                        message: 'An unhandled error occurred. Please contact your supervisor'
                    });
                }
            })
        return true
    }

    const changeDate = (value, dateString) => {
        setPayload({ ...payload, date: dateString })
    }

    const changeScanType = (value) => {
        setPayload({ ...payload, cell: value })

    }

    const searchOperation = async (value, source) => {
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                            false :
                            anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) : anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
                return containsValue
            })
            setItemData(result)
        }
    }


    useEffect(() => {
        fetchcells()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Performance {">"} Cell {">"} Cell Activity Data</Title>
            </Header>
            <Panel title="CELL ACTIVITY DATA">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <InputStyle />
                    <Row type="flex" align="middle">
                        <DateColStyle span={3} >
                            <Heading>CELL</Heading>
                            <Select style={{ marginTop: 10, width: '100%', textTransform: "capitalize" }} onChange={changeScanType}
                                placeholder="Select Cell"
                                optionFilterProp="children"
                                showSearch
                                dropdownMatchSelectWidth={false} >
                                {alphabeticalData(cells).map(data =>
                                    <Option value={data} style={{ textTransform: "capitalize" }}>{data.toLowerCase()}</Option>
                                )}
                            </Select>
                        </DateColStyle>
                        <DateColStyle span={3} >
                            <Heading>DATE</Heading>
                            <DatePicker style={{ marginTop: 10, width: '100%' }} onChange={changeDate} defaultValue={moment(moment(), 'YYYY-MM-DD')} />
                        </DateColStyle>
                        <DateColStyle span={4}>
                            <br />
                            <StyleButton style={{ marginTop: 10 }} onClick={fetchData} loading={loading} type="primary">GO</StyleButton>
                        </DateColStyle>
                    </Row>
                    {headingSelectionValue !== "" &&
                        <DateColStyle span={6} style={{ padding: 30 }}>
                            <Heading style={{ fontWeight: "bold", textTransform: "capitalize" }}>{headingSelectionValue.toUpperCase()}</Heading>
                        </DateColStyle>
                    }
                </div>

                <TableData loading={loading} data={itemData} setData={setItemData} ascend={ascend} setAscend={setAscend} />
            </Panel>
        </>
    )
}

export default CellActivityPage