import React, {useState} from 'react'
import { Table } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'
import _ from 'lodash';

const StyleTable = styled.div`font-family: 'Rubik', sans-serif;`

const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

.ant-table-tbody {
    cursor : pointer;
}

tr.failed-sent th, tr.failed-sent td {
    background: #fb4b4b !important;
}

tr.selected-right th, tr.selected-right td {
    background: rgba(11, 107, 242, 0.5) !important;
    font-color : white;
}`

const WorkOrderRightPanelTable = (props) => {
    const { rightLoading, column, rightTableData, selectedRightRow, setSelectedRightRow } = props;
    const [lastRightShiftSelected, setLastRightShiftSelected] = useState(null)

    const selectRow = (record,event) => {
        if (event.shiftKey && lastRightShiftSelected) {
            const lastRightShiftSelectedIndex = rightTableData.findIndex((data) => data.id === lastRightShiftSelected.id)
            const recordIndex = rightTableData.findIndex((item) => item.id === record.id)
            const ar2 = lastRightShiftSelectedIndex < recordIndex ? rightTableData.slice(lastRightShiftSelectedIndex, recordIndex + 1) : rightTableData.slice(recordIndex, lastRightShiftSelectedIndex + 1);

            const selectLeft = ar2.filter(filterItem => filterItem.id !== lastRightShiftSelected.id).map(item => {
                var element = document.getElementsByClassName(`selected-right-${item.id}`)[0];
                element.classList.add("selected-right");
                return item.id;
            })
            if (selectedRightRow.includes(lastRightShiftSelected.id)) {
              
                setSelectedRightRow(_.uniq(selectedRightRow.concat(selectLeft)), true)
            }
            else {
                setSelectedRightRow(selectedRightRow.filter(item => !selectLeft.includes(item)))
            }
            return
        }
        var element = document.getElementsByClassName(`selected-right-${record.id}`)[0];
        if (selectedRightRow.includes(record.id)) {
            setSelectedRightRow(selectedRightRow.filter(data => data !== record.id))
            element.classList.remove(`selected-right`);
        }
        else {
            setSelectedRightRow([...selectedRightRow, record.id])
            element.classList.add(`selected-right`);
        }
        setLastRightShiftSelected(record)
    }
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    size="middle"
                    scroll={{ y: "50vh", x: "900px" }}
                    bordered
                    pagination={false}
                    id={'toprint-table'}
                    loading={rightLoading}
                    columns={column}
                    dataSource={rightTableData}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => selectRow(record, event), // click row
                        };
                    }}
                    rowClassName={(record, index) => `selected-right-${record.id}`}
                />
            </StyleTable>
        </>
    )
}

export default WorkOrderRightPanelTable
