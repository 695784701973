import React, { forwardRef, useImperativeHandle } from "react";
import { Button, Input, Select, Checkbox } from "antd";
import Form, { FormComponentProps } from "antd/lib/form/Form";
import { INTEGER_TYPE, FLOAT_TYPE, BOOLEAN_TYPE, TEXTAREA_TYPE, MULTIPLE_TYPE, MULTIPLE_NUMBER_TYPE } from "../config/Imposition";
import {
  DROPDOWN,
  FormLabel,
  FormTypes,
  FormValues,
  FormRequired,
  FieldMessage,
} from "../config/Form";
import { Libraries } from "../config/Imposition";
import { getProperty, getPropertyExcept } from "modules/imposition/utils/ImpositionUtil";
import { alphabeticalData } from 'utils/sortData';
import "antd/dist/antd.css";
const { TextArea } = Input;

const { Option } = Select;

const checkValueIsNotEmpty = (value: any) => {
    return typeof value !== "undefined" && value != null && value !== "";
};

const getRecipeFormParams = (
  recipeLibId : string
) => {
  const recipe: any = Libraries.find(library => library.id === recipeLibId);
  return recipe ? recipe.parameters : []; 
};

interface RecipeParamFormProps extends FormComponentProps {
  recipeData: any;
  formData: any;
}

type Ref = FormComponentProps;

const RecipeParamForm = forwardRef<Ref, RecipeParamFormProps>(
  ({ form, recipeData, formData }: RecipeParamFormProps, ref) => {
    useImperativeHandle(ref, () => ({
      form,
    }));

    const { recipes, recipeId } = recipeData;
    const { customFormValues } = formData;

    const filteredRecipe = recipes.find(
      (recipe: any) => recipe.sortOrder === recipeId
    );
    const parameters: any[] = filteredRecipe ? filteredRecipe.parameters : [];

    const recipeLib: any = Libraries.find(library => library.id === filteredRecipe.name);
    const recipeLibParams: string[] = recipeLib ? recipeLib.parameters! : []!;

    const formInput = (parameters: any) => {
      const formParamType = getProperty(FormTypes, parameters);
      const formValuesProp = getProperty(FormValues, parameters);
      const customFormData: any = customFormValues.find((customFormValue: any) =>  customFormValue.key === parameters);

      if (formValuesProp && formValuesProp.type === DROPDOWN) {
        const selectProps = { placeholder: formValuesProp.placeholder };
        return (
          <Select {...selectProps}>
            {alphabeticalData(customFormData ? customFormData.values : formValuesProp.values).map((item: any) => {
              const optionLabel = getOption(item, true, item.label);
              const optionValue = getOption(item, false, item.value);
              return <Option key={optionValue} value={optionValue}>
                {optionLabel}
              </Option>
            })}
          </Select>
        );
      } else if (formParamType === BOOLEAN_TYPE) {
        return <Checkbox />;
      } else if (formParamType === MULTIPLE_TYPE || formParamType === MULTIPLE_NUMBER_TYPE) {
          return <Input style={{color: "green"}} />;
      } else if (formParamType === TEXTAREA_TYPE) {
        return <TextArea rows={10}/>
      }

      return <Input />;
    };

    const getOption = (item: any, isLabel: boolean, defaultField : string) => {
        if (typeof item === "string"){
            return item;
        }else if(isLabel && item.template_name){
            return item.template_name;
        }else if(!isLabel && item.image_location){
            return item.image_location;
        }else{
            return defaultField
        }
    };

    const fieldDecorator = (parameters: any) => {
      if(!parameters){
        return [{required: false}];
      }

      const formParamType = getProperty(FormTypes, parameters.name);
      const formValuesProp = getProperty(FormValues, parameters.name);
      const formReqProp = getProperty(FormRequired, parameters.name);
      const formValueExcept = getPropertyExcept(FieldMessage, parameters.name,recipeLib.label);
      const customFormData: any = customFormValues.find((customFormValue: any) =>  customFormValue.key === parameters.name);
      let rulesValues: any[] =
          formParamType !== BOOLEAN_TYPE && (formReqProp === "" || formReqProp === "true") && (formValueExcept === false)
          ? [
              {
                required: true,
                message: getProperty(FieldMessage, parameters.name),
              },
            ]
          : [
              {
                required: false,
              },
            ];

      if (!isDropDown(formValuesProp) && formParamType === INTEGER_TYPE ) {
        rulesValues = [
          ...rulesValues,
          {
            pattern: new RegExp(/^\d+$/),
            message: "Integer numbers only.",
          },
        ];
        
      }
      else if (formParamType === MULTIPLE_NUMBER_TYPE) {
        rulesValues = [
          ...rulesValues,
          {
            validator: (rule: any, value: any) => {
                let hasNan = true;

                if (value.length === 0)
                    return hasNan;

                const elements:string[] = value.split(',');
                elements.every((element) => {
                    hasNan = element !== "" && !(/\s/).test(element) && element.charAt(element.length-1) !== ".";

                    if (hasNan){
                        hasNan = !isNaN(Number(element));
                    }

                    return hasNan;
                });
                return hasNan;
            },
            message: "Integer or decimal numbers only.",
          },
        ];
      }
      else if (
        !isDropDown(formValuesProp) &&
        formParamType === FLOAT_TYPE
      ) {
        rulesValues = [
          ...rulesValues,
          {
            pattern: new RegExp(/^(?!0\d)\d*(-?\d*\.{0,1}\d+)?$/gm),
            message: "Integer or decimal numbers only.",
          },
        ];
      }

      let decorator: any = { rules: rulesValues };

      if (formParamType === BOOLEAN_TYPE) {
        decorator.valuePropName = "checked";
        decorator.initialValue = formValuesProp ? formValuesProp : false;
      }

      if (checkValueIsNotEmpty(parameters.value)) {
        decorator.initialValue = parameters.value;
      } else if (checkValueIsNotEmpty(formValuesProp.initValue)) {
        decorator.initialValue = formValuesProp.initValue;
      } 
      else if (!checkValueIsNotEmpty(parameters.value) && formReqProp === "false" &&
          (formParamType === INTEGER_TYPE || formParamType === FLOAT_TYPE )) {
          decorator.initialValue = 0;
      }
      else if (!checkValueIsNotEmpty(parameters.value) && formReqProp === "false" &&
          ( formParamType == MULTIPLE_NUMBER_TYPE || formParamType == MULTIPLE_TYPE  )) {
          decorator.initialValue = [];
      }
      else if (customFormData){
          decorator.initialValue = customFormData.default;
      }

      return decorator;
    };

    const isDropDown = (formValues: any) => {
      return formValues !== "" && formValues.type === DROPDOWN;
    };
    
    
    return (
      <Form
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        layout="horizontal"
      >
        {recipeLibParams && recipeLibParams.map((value, index) => (
          <Form.Item
            key={index}
            label={getProperty(FormLabel, value, true)}
          >
            {form.getFieldDecorator(
              (value + "_" + recipeId) as string,
              fieldDecorator(parameters.find(param => param.name === value))
            )(formInput(value))}
          </Form.Item>
        ))}
      </Form>
    );
  }
);

export default Form.create<RecipeParamFormProps>()(RecipeParamForm);
