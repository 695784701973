import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Panel } from 'components/primaries'
import { notification, Modal } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { ZenSmartAPI } from 'utils'
import { workFlowInitData, triggerRoute, allEventsInSingleTrigger } from "utils/apiRoutes"
import { DataListPage } from "pages/DataListPage"
import FormRuleModal from './FormRuleModal.js'
import WorkFlowDetailsModal from './WorkFlowDetailsModal.js'
import ManageTriggersModal from './ManageTriggersModal.js'
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`

const WorkFlowRulesPage = (props) => {
    const [modalTitle, setModalTitle] = useState("")
    const [showNewRuleModal, setShowNewRuleModal] = useState(false)
    const [editRuleInitialData, setEditRuleInitialData] = useState(null)
    const [workFlowDetailInitialData, setWorkFlowDetailInitialData] = useState(null)
    const [showWorkFlowDetail, setShowWorkFlowDetail] = useState(false)
    const [workFlowDetailModalTitle, setWorkFlowDetailModalTitle] = useState("")
    const [qualifiersData, setQualifiersData] = useState([])
    const [objectsData, setObjectsData] = useState([])
    const [dataRefresh, setDataRefresh] = useState({ refres_data: null, parameters: {} })
    const [workflowInitialDatas, setWorkFlowInitialDatas] = useState(null)
    const [fetchParams, setFetchParams] = useState({})
    const [fetchData, setFetchData] = useState({ refres_data: null })
    const [showManageTriggerModal, setShowManageTriggerModal] = useState(false)
    const [manageTriggersData, setManageTriggersData] = useState([])
    const [parentTrigger, setParentTrigger] = useState(null)
    const [loadingTrigger, setLoadingTrigger] = useState(false)
    const [ifHavePermission, setIfHavePermission] = useState(false)
    const fetchInitialData = () => {

        ZenSmartAPI.get(workFlowInitData)
            .then(result => {
                setQualifiersData(result.data.types.qualifiers)
                setObjectsData(result.data.types.objects)
                setWorkFlowInitialDatas(result.data)
                setIfHavePermission(true)
            }).catch(error => {

            })
    }

    const editSingleWorkFlowRule = (row, refreshData) => {
        if (refreshData) {
            setDataRefresh({ refresh_data: refreshData })
        }
        ZenSmartAPI.get(triggerRoute(row.id))
            .then(result => {
                setEditRuleInitialData(result.data.data)
                setModalTitle(`Edit Rule ${result.data.data.name}`)
                setShowNewRuleModal(true)
                setShowWorkFlowDetail(false)
            }).catch(error => {
                notification.error({ message: "Edit workflow rule failed!" })
            })
    }


    const deleteWorkFlowRule = (row, refreshData) => {
        if (refreshData) {
            setDataRefresh({ refresh_data: refreshData })
        }
        Modal.confirm({
            'content': "Are you sure you want to delete this rule?",
            onOk: () => {
                ZenSmartAPI.delete(triggerRoute(row.id))
                    .then(result => {
                        notification.success({ message: "Delete successful!" })
                        if (dataRefresh.refresh_data) {
                            dataRefresh.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                        }
                        else {
                            refreshData(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                        }
                        setShowWorkFlowDetail(false)

                    }).catch(error => {
                        notification.error({ message: "Delete not successful!" })
                    })
            }
        })
    }

    const activateRules = (row, refreshData, fetchWorkFlow) => {
        if (refreshData) {
            setDataRefresh({ refresh_data: refreshData })
        }
        const popupMessageActivate = `${typeof row.active === "boolean" && !row.active ? "true" : typeof row.active === "boolean" && row.active ? "false" : !row.active.value ? "true" : "false"}`
        const message = `Once you ${popupMessageActivate == "true" ? "activate" : "deactivate"}, these triggers will ${popupMessageActivate == "true" ? "" : "not"} be executed on the orders that meets the conditions.`
        Modal.confirm({
            'content': message,
            onOk: () => {
                ZenSmartAPI.post(triggerRoute(`${row.id}/activate`), { active: typeof row.active === "boolean" ? !row.active : !row.active.value })
                    .then(result => {
                        notification.success({ message: `${popupMessageActivate == "true" ? "Activate" : "Deactivate"} successful!` })
                        if (dataRefresh.refresh_data) {
                            dataRefresh.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                        }
                        else {
                            refreshData(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                        }
                        if (fetchWorkFlow) {
                            fetchSingleWorkFlowDetail(row)
                        }
                    }).catch(error => {
                        notification.error({ message: `${popupMessageActivate == "true" ? "Activate" : "Deactivate"} not successful!` })
                    })
            },
        });

    }

    const fetchSingleWorkFlowDetail = (row, refreshData) => {
        if (refreshData) {
            setDataRefresh({ refresh_data: refreshData })
        }
        ZenSmartAPI.get(triggerRoute(row.id))
            .then(result => {
                setWorkFlowDetailInitialData(result.data.data)
                setWorkFlowDetailModalTitle(`${result.data.data.name}`)
                setShowWorkFlowDetail(true)
            }).catch(error => {
                notification.error({ message: "Fetch workflow rule failed!" })
            })
    }

    const showAddnewRuleModal = (refreshData) => {

        if (refreshData) {
            setDataRefresh({ refresh_data: refreshData })
        }
        setModalTitle("Add New Rule")
        setShowNewRuleModal(true)
    }

    const manageTriggers = (row, refreshData, setTitle = true) => {
        setLoadingTrigger(true)
        setLoadingTrigger(false)
        ZenSmartAPI.get(allEventsInSingleTrigger(row.id))
            .then(result => {
                if (setTitle) {
                    setModalTitle(`Manage Trigger "${row.name.value}"`)
                }
                setShowManageTriggerModal(true)
                setManageTriggersData({ rule_data: row, data: result.data.data })
                setLoadingTrigger(false)
                if (row.prefix) {
                    setParentTrigger(row)
                }

            }).catch(error => {
                setLoadingTrigger(false)

                notification.error({ message: "Fetch trigger events failed!" })
            })
    }

    useEffect(() => {
        fetchInitialData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            {ifHavePermission &&
                <div>
                    <Header>
                        <Title>App Setup {">"} Workflow Rules</Title>
                    </Header>

                    <Panel title="WORKFLOW RULES">
                        <DataListPage model="triggers" select={[]} link={""} hidden={['id', 'created_at', 'prefix']} pageSize={50}
                            customizedButton={[
                                {
                                    buttonLabel: "Add new rule",
                                    actionFunction: showAddnewRuleModal,
                                    offset: 16,
                                    span: 2
                                },
                            ]}
                            clickableFunctions={{ name: fetchSingleWorkFlowDetail, active: activateRules }}
                            setOutSourceParameters={setFetchParams}
                            setOutSourceFetchData={setFetchData}
                            actionButtons={
                                [
                                    {
                                        buttonLabel: <EditOutlined />,
                                        buttonType: "primary",
                                        buttonAction: "normal",
                                        actionFunction: editSingleWorkFlowRule,
                                        reloadData: true
                                    },
                                    {
                                        buttonLabel: <DeleteOutlined />,
                                        buttonType: "danger",
                                        buttonAction: "normal",
                                        actionFunction: deleteWorkFlowRule,
                                        reloadData: true
                                    },
                                    {
                                        buttonLabel: "Manage Trigger",
                                        buttonType: "primary",
                                        buttonAction: "normal",
                                        actionFunction: manageTriggers,
                                        reloadData: true
                                    },
                                ]
                            }
                        />
                        <FormRuleModal
                            showModal={showNewRuleModal}
                            setShowModal={setShowNewRuleModal}
                            editRuleInitialData={editRuleInitialData}
                            modalTitle={modalTitle}
                            setEditRuleInitialData={setEditRuleInitialData}
                            setQualifiersData={setQualifiersData}
                            qualifiersData={qualifiersData}
                            objectsData={objectsData}
                            setObjectsData={setObjectsData}
                            dataRefresh={dataRefresh}
                            fetchParams={fetchParams}
                        />
                        <WorkFlowDetailsModal
                            showModal={showWorkFlowDetail}
                            setShowModal={setShowWorkFlowDetail}
                            workFlowDetailInitialData={workFlowDetailInitialData}
                            setWorkFlowDetailInitialData={setWorkFlowDetailInitialData}
                            modalTitle={workFlowDetailModalTitle}
                            editSingleWorkFlowRule={editSingleWorkFlowRule}
                            deleteWorkFlowRule={deleteWorkFlowRule}
                            activateRules={activateRules}
                            objectsData={objectsData}
                        />
                        <ManageTriggersModal
                            showModal={showManageTriggerModal}
                            setShowModal={setShowManageTriggerModal}
                            modalTitle={modalTitle}
                            data={manageTriggersData}
                            workflowInitialDatas={workflowInitialDatas}
                            manageTriggers={manageTriggers}
                            loading={loadingTrigger}
                            setLoading={setLoadingTrigger}
                            parentTrigger={parentTrigger}
                        />
                    </Panel>
                </div>
            }
        </>
    )
}

export default WorkFlowRulesPage