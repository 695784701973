import DataTypes from "./DataTypes";
import moment from 'moment';

const DataType = DataTypes.DATATYPE

const CheckTypeAndConvert = (type, values = []) => {
  if (values) {
    switch (type) {
      case DataType.bool:
        return values.filter(value => (value === 'true' || value === 'false'));
      case DataType.number:
        return values.filter(value => !isNaN(value));
      case DataType.text:
        return values.filter(value => typeof value === "string");
      case DataType.time:
        return values.filter(date => moment(date).isValid());
      case DataType.bucket:
        return values;
      default:
        return [];
    }
  }
  return [];
}

export default CheckTypeAndConvert