import React, { useEffect, useState } from 'react';
import { Modal, notification, Button, Row, Select } from 'antd';
import * as _ from 'lodash';
import { Label } from '../../../components/zensmart-design-system';
import { ZenSmartAPI } from '../../../utils';
import PermissionTableView from './Components/PermissionTableView';
import RoleAddEditView from './Components/RoleAddEditView';
import RoleAddDrawer from './Components/RoleAddDrawer';
import DataViewFieldsManage from './Components/DataViewFieldManage';
import { operatorPermissionsRoute, rolesRoute } from 'utils/apiRoutes';
import { GetDashboards } from "pages/Performance/api";

const { Option } = Select;

function RolesManageModal(props) {
    const { visible, permissions, onClose, roleId } = props;
    const [allRoles, setAllRoles] = useState([]);
    const [allPermissions, setAllPermissions] = useState(permissions);
    const [selectedRoleId, setSelectedRoleId] = useState(null);
    const [selectedRoleName, setSelectedRoleName] = useState(null);
    const [rolePermissions, setRolePermissions] = useState([]);
    const [roleUsers, setRoleUsers] = useState([]);
    const [roleDescription, setRoleDescription] = useState('');
    const [roleDashboard, setRoleDashboard] = useState('');
    const [users, setUsers] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isNewRole, setIsNewRole] = useState(false);
    const [isDataViewOpen, setIsDataViewOpen] = useState(false);
    const [hasAccess, setHasAccess] = useState(false);
    const [applyStructure, setApplyStructure] = useState(false);
    const [dashboards, setDashboards] = useState(false);
    useEffect(() => {
        if (visible) {
            setAllPermissions(permissions);
            fetchAllRoles();
            fetchAllUsers();
            fetchAllDashboards();
            setIsEditing(false);
        } else {
            setSelectedRoleId(null);
        }
    }, [visible]);


    useEffect(() => {
        if (roleId && allRoles.length > 0) {
            handleRoleChange(roleId);
        }
    }, [roleId, allRoles]);

    const fetchAllDashboards = () => {
        GetDashboards()
          .then(({ data }) => {
            setDashboards(data.dashboards);
          });
    };

    const fetchAllRoles = (callback) => {
        ZenSmartAPI.get(rolesRoute).then(response => {
            response = response.data;
            setAllRoles(response.data);
            setHasAccess(true);

            if (callback) {
                callback(response.data);
            }
        });
    };

    const fetchAllUsers = () => {
        ZenSmartAPI.get(operatorPermissionsRoute).then(response => {
            response = response.data;
            setUsers(response.data);
        });
    };

    const handleRoleChange = (value) => {
        setSelectedRoleId(value);

        const foundRole = allRoles.find((role) => role.id === value);
        if (foundRole) {
            setRolePermissions(foundRole.permissions);
            setRoleUsers(foundRole.users);
            setRoleDescription(foundRole.description);
            setSelectedRoleName(foundRole.name);
            setRoleDashboard(foundRole.dashboard_id);
            setApplyStructure(foundRole.apply_structure);
        }

        setIsEditing(false);
    }

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleDoneClick = () => {

        fetchAllRoles((updatedRoles) => {
            const foundRole = updatedRoles.find((role) => role.id === selectedRoleId);
            if (foundRole) {
                setRolePermissions(foundRole.permissions);
                setRoleUsers(foundRole.users);
                setRoleDescription(foundRole.description);
                setSelectedRoleName(foundRole.name);
            }
            setIsEditing(false);
        });
    };

    const handleNewRoleClick = () => {
        setIsNewRole(true);
    };

    const handleDataViewClick = () => {
        setIsDataViewOpen(true);
    };

    const onNewRoleSave = (newRole) => {
        setIsEditing(false);
        setIsNewRole(false);

        setAllRoles([...allRoles, newRole]);

        setSelectedRoleId(newRole.id);
        setRoleDescription(newRole.description);
        setRolePermissions(newRole.permissions);
        setRoleUsers(newRole.users);
        setApplyStructure(newRole.apply_structure);

    };

    const renderRoles = () => {

        return (
            <div>
                <Row type="flex" className='mt-5'>
                    <Select
                        style={{ width: 500 }}
                        value={selectedRoleId}
                        onChange={handleRoleChange}
                        placeholder="Select a role"
                    >
                        <Option value={null} disabled>Select a role</Option>
                        {allRoles.map((role, index) => (
                            <Option key={index} value={role.id}>
                                {role.name.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())}
                            </Option>
                        ))}
                    </Select>
                    <Button type="primary"
                        className='ml-5'
                        disabled={isEditing}
                        onClick={handleNewRoleClick}> New Role
                    </Button>
                </Row>
                {selectedRoleId && (
                    <div style={{ marginTop: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ fontWeight: 'bold', fontSize: '18px' }}>
                                Permissions assigned to the Role - {selectedRoleName?.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())}
                            </h3>
                            {isEditing ? (
                                <Button
                                    type="primary"
                                    className='ml-5'
                                    onClick={handleDoneClick}> Done
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    className='ml-5 pl-5 pr-5'
                                    onClick={handleEditClick}> Edit
                                </Button>
                            )}
                            <Button
                                type="primary"
                                className='ml-2'
                                disabled={isEditing}
                                onClick={handleDataViewClick}> Manage Data Views
                            </Button>
                        </div>
                        {isEditing ?
                            <RoleAddEditView
                                allPermissions={allPermissions}
                                rolePermissions={rolePermissions}
                                roleUsers={roleUsers}
                                users={users}
                                roleDescription={roleDescription}                                
                                role={selectedRoleId}
                                roleName={selectedRoleName}
                                roleDashboard={roleDashboard} 
                                setRoleDashboard={setRoleDashboard} 
                                dashboards={dashboards} /> :
                            <PermissionTableView
                                permissions={rolePermissions}
                                users={roleUsers}
                                roleDescription={roleDescription} />}

                    </div>
                )}
                <RoleAddDrawer
                    onClose={() => setIsNewRole(false)}
                    isOpen={isNewRole}
                    onSave={onNewRoleSave}
                />
                <DataViewFieldsManage
                    onClose={() => setIsDataViewOpen(false)}
                    visible={isDataViewOpen}
                    roleId={selectedRoleId}
                    roleName={selectedRoleName}
                    applyStructure={applyStructure}
                />
            </div>
        );
    }

    if (users === null) {
        return null;
    }

    return (
        <>
            {hasAccess && (
                <Modal
                    visible={visible}
                    destroyOnClose={true}
                    width={900}
                    onCancel={() => {
                        onClose();
                    }}
                    footer={null}
                >
                    <Row type="flex" justify="center" style={{ padding: '10 20' }}>
                        <Label status="blue">MANAGE ROLES</Label>
                    </Row>
                    {renderRoles()}
                </Modal>
            )}
        </>
    );

}

export default RolesManageModal;