import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import { styles, Button } from '../../../../components/zensmart-design-system';


const color = styles.color;

const NumberRowStyle = styled.tr`
  .name_field {
    display: flex;
    :first-child {
      width: 80%
    }
    button {
      margin-left: 10px;
      font-size: 12px;
    }
  }
  .add_field {
    button span {
      font-size: 12px;

      i svg {
        margin-bottom: 5px;
      }
    }
  }
`

const NumberFieldRow = ({
  previousRange = 0,
  index,
  addRange,
  deleteRange,
  name,
  value,
  setName,
  setValue,
  last,
  select
}) => {
  const operation = parseInt(index) === 0 ? '<=' : '>'

  return (
    <NumberRowStyle>
      <td>
        <div className='add_field'>
          <Button
            onClick={()=>addRange(parseInt(index)+1)}
            style={{display: last && 'none'}}
          >
            Add
          </Button>
        </div>
      </td>
      <td>
        <div style={{display:'flex', width:'90%'}}>
          {operation}
          &nbsp;
          {
            last ?
            (
              <span>{previousRange}</span>
            ) :
            (
              <>
              {
                parseInt(index) === 0 ? (<span></span>) : (<span>{previousRange} TO</span>)
              }
              <Input
                placeholder='Enter Number'
                type='number'
                value={value ? value : ''}
                onChange={e => {
                  setValue(e.target.value)
                }}
                onFocus={() => select()}
              /> 
              </>
            )
          }
          
        </div>
      </td>
      <td>
        <div className='name_field'>
          <Input
            placeholder='Enter Name'
            value={name ? name : ''}
            onChange={e => {
              setName(e.target.value)
            }}
            onFocus={() => select()}
          />
          <button
            onClick={()=>deleteRange(index)}
            style={{
              display:'flex',
              alignItems:'center',
              color: color.blue.normal
            }}
          >
            Delete
          </button>
        </div>
        
      </td>
    </NumberRowStyle>
  )
}

export default NumberFieldRow