import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Modal, Form, Input, Button, notification, Row, Col, Select, InputNumber } from 'antd'
import { ZenSmartAPI } from 'utils';
import { PROCESS } from 'types'
import * as _ from 'lodash'
import { endpointRoute, modelDefinitionRoute } from "utils/apiRoutes"
import { ConditionsInput } from 'components/composites';
import { PlusCircleFilled } from '@ant-design/icons';
import debounce from 'lodash/debounce';

import is from "is_js";

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`
const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`
function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const DeleteButton = styled(Button)`
  font-size: 12px;
  color: #DC262B;
  font-weight: bold;

`
const PlusCircleFilledIcon = styled(PlusCircleFilled)`
  font-size: 16px;
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0243EB;
  font-size: 12px;
  border: 0;
  margin-top: 10px;
  box-shadow: none;
`
const { Option } = Select

const methods = [
  "GET",
  "POST",
  "PATCH",
  "PUT",
  "DELETE"
]

function EditForm(props) {
  const {
    form,
    callout,
    onSave,
    processID,
    removeTrigger,
    hideModal,
    qualifiers
  } = props

  const {
    getFieldDecorator,
    getFieldsValue,
  } = form

  const conditionValues =
    callout && callout.conditions.length > 0
      ? { fieldsTags: callout.fieldsTags, qualifier: callout.conditions[0].qualifier }
      : {}

  const [endpointValue, setEndpointValue] = useState([])
  const [headersValues, setHeadersValue] = useState([])
  const [fieldsToSendValues, setFieldsToSendValue] = useState([{ key: null }])
  const [fieldSelectValue, setFieldSelectValue] = useState(null)
  // useEffect(() => {
  //   ZenSmartAPI.get(endpointRoute)
  //     .then((res) => {
  //       setEndpointValue([{ id: 1, description: "Line Update Notifier" }, { id: 2, description: "Order Update Notifier" }, { id: 3, description: "Design Review Alert" }])
  //     })
  //     .catch((res) => {
  //
  //     })
  //   onSearchField("", "orders")
  //   if (callout && callout.headers) {
  //     setHeadersValue(callout.headers)
  //   }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const extractPath = (array) => {
    let pathArray = []

    array.forEach((a) => {
      const { path } = a;
      pathArray = [...pathArray, path]
    })

    return pathArray
  }

  const handleSubmit = e => {
    e.preventDefault()
    const { condition, ...inputValues } = getFieldsValue()
    inputValues.fieldsTags = condition.fieldsTags

    if(condition && condition.fieldsTags && condition.fieldsTags.length > 0) {
      inputValues.conditions = [{
        conditionField: condition.fieldsTags[0].path,
        qualifier: condition.qualifier
      }]
    } else {
      inputValues.conditions = [];
    }
    inputValues.headers = headersValues
    inputValues.trigger_id = _.get(callout, 'trigger_id', null);
    onSave(inputValues, PROCESS.TRIGGER.CALLOUT, processID)
    hideModal()
    notification.success({
      message: 'Saved callout trigger details.'
    })
  };

  const onRemoveTrigger = () => {
    removeTrigger(PROCESS.TRIGGER.CALLOUT, processID)
  }

  // functions for adding and deleting headers
  const addHeaders = () => {
    setHeadersValue([...headersValues, { key: null, value: null }])
  }
  const deleteHeader = (index) => {
    setHeadersValue([...headersValues.filter((data, key) => key !== index)])
  }

  const setHeaderValueFunction = (e, index, property) => {
    const payload = headersValues
    payload[index][property] = e.target.value
    setHeadersValue([...payload])
  }

  const onSearchField = debounce((value, prefix, inputType) => {
    ZenSmartAPI.get(modelDefinitionRoute(`${prefix}.${value}`), {})
      .then((res) => {
        setFieldSelectValue(res.data.data)
      })
  }, 600);
  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <StyledFormItem label="Condition">
        {getFieldDecorator('condition', {
          initialValue: conditionValues
        })(
            <ConditionsInput
                resource={'scan_logs'}
                form={form}
                qualifiers={qualifiers}
            />
        )}
        <span style={{color: '#888'}}>Leave blank if the trigger should always occur</span>
      </StyledFormItem>


      <StyledFormItem label="Endpoint">
        {getFieldDecorator('endpoint', {
          initialValue: callout && callout.endpoint
        })(
          <Input />
        )}
      </StyledFormItem>

      <p style={{ color: "black" }}>Headers</p>
      {headersValues.length > 0 ? headersValues.map((data, index) => {
        return <Row type="flex" align="middle">
          <Col span={10} style={{ padding: "10px 5px 10px 0px", }}>
            <Input placeholder="Header key" value={data.key} onChange={(e) => setHeaderValueFunction(e, index, "key")} />
          </Col>
          <Col span={10} style={{ padding: "10px 0px 10px 5px" }}>
            <Input placeholder="Header Value" value={data.value} onChange={(e) => setHeaderValueFunction(e, index, "value")} />
          </Col>
          <Col span={4} style={{ paddingLeft: 5, textAlign: "center", }}>
            <DeleteButton onClick={() => deleteHeader(index)} style={{ width: "100%" }}>REMOVE</DeleteButton>
          </Col>
        </Row>
      })
        :
       <Row>
         <Col style={{color: '#888'}}>Add headers to the HTTP request.</Col>
       </Row>
      }
      <Row type="flex" align="middle" justify="end">
        <AddButton onClick={addHeaders} >
          <PlusCircleFilledIcon />
            ADD NEW HEADERS
          </AddButton>
      </Row>

      <p style={{ color: "black" }}>Retry Timings</p>
      <Row type="flex" align="middle">
        <Col span={12} style={{ paddingRight: 5 }}>
          <StyledFormItem label=" ">
            {getFieldDecorator('no_of_retries', {
              initialValue: callout && callout.no_of_retries,
              rules: [{ type: "number" }]

            })((
              <InputNumber type="number" placeholder="Number of retries" style={{ width: "100%" }} />
            ))}
          </StyledFormItem>
        </Col>
        <Col span={12} style={{ paddingLeft: 5 }}>
          <StyledFormItem label=" ">
            {getFieldDecorator('retry_interval', {
              initialValue: callout && callout.retry_interval,
              rules: [{ type: "number" }]

            })((
              <InputNumber type="number" placeholder="Delay in minutes" style={{ width: "100%" }} />
            ))}
          </StyledFormItem>
        </Col>
      </Row>
      <ButtonContainer>
        <Button onClick={hideModal}>Cancel</Button>
        {processID && <Button type="danger" onClick={onRemoveTrigger}>Delete</Button>}
        <Button
          type="primary"
          htmlType="submit"
        >
          Save Changes
        </Button>
      </ButtonContainer>
    </Form>
  )
}

const EditCalloutTriggerForm = Form.create({
  name: 'Edit Callout Trigger Form'
})(EditForm)

function CalloutTriggerModal(props) {
  const { callout, onSave, processID, qualifiers, removeTrigger, ...modal } = props

  return (
    <Modal {...modal}>
      <EditCalloutTriggerForm
        callout={callout}
        onSave={onSave}
        processID={processID}
        hideModal={modal.onCancel}
        removeTrigger={removeTrigger}
        qualifiers={qualifiers}
      />
    </Modal>
  )
}

export default CalloutTriggerModal