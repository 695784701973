import React from "react";
import styled from "styled-components";
import {
  Modal,
  Form,
  Input,
  Button,
  AutoComplete,
  notification,
  Checkbox, Select,
} from "antd";
import debounce from 'lodash/debounce';
import { getAllQuiz } from 'utils/apiRoutes'
import { ZenSmartAPI } from 'utils'

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`;

const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`;


const processTextRules = [
  {
    required: true,
    message: "Cannot be blank",
  },
  {
    max: 23,
    message: "Must be less than 23 characters",
  },
];

function EditForm(props) {

  const {
    form,
    process,
    onSave,
    processID,
    hideModal,
    gamificationQuiz,
    dispatchTemplatesOptions,
  } = props;

  const {
    getFieldDecorator,
    isFieldTouched,
    getFieldError,
    getFieldsValue,
    resetFields,
  } = form;

  const [quizFieldValues, setQuizFieldsValue] = React.useState([])

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = getFieldsValue()
    if(payload?.enable_gamification === true && payload.quiz_id  === '') {
      notification.error({message: 'Select a quiz'})
      return;
    }

    onSave(payload, processID);
    resetFields()
    hideModal();
    notification.success({
      message: "Saved dispatch changes.",
    });
  };

  const processTextError = isFieldTouched("text") && getFieldError("text");

  const onSearchField = debounce(value => {
    ZenSmartAPI.get(getAllQuiz(value)).then(res => {
      if(res?.data.length > 0) { setQuizFieldsValue(res.data) }
    })
  }, 600)

  return (
    <Form layout="vertical" onSubmit={handleSubmit} width={700}>
      <StyledFormItem
        label="Name"
        validateStatus={processTextError ? "error" : ""}
        help={processTextError || ""}
      >
        {getFieldDecorator("text", {
          initialValue: "Dispatch",
          rules: processTextRules,
        })(<Input disabled />)}
      </StyledFormItem>

      <StyledFormItem
        label="Template"
        validateStatus={processTextError ? "error" : ""}
        help={processTextError || ""}
      >
        {getFieldDecorator("template", {
          initialValue: process && process.template,
          rules: processTextRules,
        })(
          <AutoComplete
            options={dispatchTemplatesOptions}
            filterOption={true}
          >
            {dispatchTemplatesOptions.map(({id, name}) => {
              return <AutoComplete.Option key={id} value={name}>{name}</AutoComplete.Option>
            })}
          </AutoComplete>
        )}
      </StyledFormItem>

      <StyledFormItem
        validateStatus={processTextError ? 'error' : ''}
        help={processTextError || ''}
      >
        {getFieldDecorator('enable_gamification', {
          initialValue: process?.enable_gamification ?? false,
        })(<Checkbox checked={getFieldsValue().enable_gamification} >Enable Gamification</Checkbox>)}

      </StyledFormItem>

      {getFieldsValue().enable_gamification === true && (
        <>
          <StyledFormItem
            label="Select Quiz Key"
            validateStatus={processTextError ? 'error' : ''}
            help={processTextError || ''}
          >
            {getFieldDecorator('quiz_id', {
                    initialValue: process?.quiz_id ?? '',
                })(<Select 
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select Quiz Key"
                  optionFilterProp='children'
                  onSearch={onSearchField}
                >
                    { quizFieldValues.length > 0 ? quizFieldValues.map((item, index) => (
                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)) : gamificationQuiz && gamificationQuiz.map(item => (
                          <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
                    </Select>)}
          </StyledFormItem>
        </>
      )}

      <ButtonContainer>
        <Button onClick={hideModal}>Cancel</Button>

        <Button type="primary" htmlType="submit">
          Save Changes
        </Button>
      </ButtonContainer>
    </Form>
  );
}

const DispatchForm = Form.create({ name: "Dispatch Form" })(EditForm);

function DispatchModal(props) {
  const {
    process,
    onSave,
    processID,
    dispatchTemplatesOptions,
    gamificationQuiz,
    ...modal
  } = props;
  return (
    <Modal {...modal}>
      <DispatchForm
        process={process}
        gamificationQuiz={gamificationQuiz}
        onSave={onSave}
        processID={processID}
        dispatchTemplatesOptions={dispatchTemplatesOptions}
        hideModal={modal.onCancel}
      />
    </Modal>
  );
}

export default DispatchModal;
