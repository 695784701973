import React, { useState, useEffect } from 'react'
import { Tree, Button, Popconfirm, notification, Skeleton } from 'antd'
import {
  Title,
  AddTopRow,
  ModalContent,
  InnerContent,
  ButtonContainer,
  LoaderContainer,
  CloseButton
} from './index.js'
import Input from './Input'
import { ZenSmartAPI } from 'utils'
import { fetchSpecialOptionsRoute } from 'utils/apiRoutes'
import { alphabeticallySortNestedArrayOfObject } from 'utils/sortData.js'

const AddForm = ({
  setLoadingErrorAndModal,
  listData,
  setListData,
  setRunData,
  location,
  index,
  data,
  transformProduct,
  actionType,
  modelListData,
  allDeviceClassList
}) => {
  // displays empty string for new form and shows existing for edit
  const [autoRunForm, setAutoRunForm] = useState({
    description: { value: data.description ?? '', error: false },
    active: { value: data.active ?? '', error: false },
    blockType: { value: data.blockType ?? '', error: false },
    fails: { value: data.fails ?? '', error: false },
    imposition: { value: data.imposition ?? '', error: false },
    maximum_type: { value: data.maximum_type ?? '', error: false },
    maximum_value: { value: data.maximum_value ?? '', error: false },
    group_by: { value: data.group_by ?? [], error: false },
    order_by: { value: data.order_by ?? [], error: false },
    paper_code: { value: data.paper_code ?? "ALL", error: false },
    device_class: { value: data.device_class ?? '', error: false },
    device_order: { value: data.device_order ?? [], error: false },
    utilisation_type: { value: data.utilisation_type ?? '', error: false },
    fixed_value: { value: data.fixed_value ?? '', error: false },
    utilisation_max_type: {
      value: data.utilisation_max_type ?? '',
      error: false
    },
    utilisation_max_value: {
      value: data.utilisation_max_value ?? '',
      error: false
    },
    special_option: { value: data.special_option ?? ' ', error: false },
    trigger_type: { value: data.trigger_type ?? 'sequence', error: false },
    trigger_value: { value: data.trigger_value ?? [], error: false },
    products: { value: data.products ?? [], error: false },
    trigger_bypass: { value: data.trigger_bypass ?? '', error: false }
  })
  const [loading, setLoading] = useState(false)

  // Used for the tree product selection
  const [checkedKeys, setCheckedKeys] = useState(autoRunForm.products.value)
  const [autoExpandParent, setAutoExpandParent] = useState(true)

  const onCheck = async checkedKeysValue => {
    // Removed the top level values of the product tree. Only the 4th level values are needed.
    try {
      const productValues = checkedKeysValue.filter(
          item => item.includes('TOPLEVEL') === false
      )

      const selectedProducts = listData.unfilteredProductList.filter(item =>
          productValues.includes(`${item.code}`)
      )
      // Product printer in that location

      const tempAvailablePrinterClass = createPrinterCollection(
          selectedProducts
      )
      if (
          checkedKeysValue.length > 0 &&
          tempAvailablePrinterClass.length === 0
      ) {
        notification.error({message: `Product must have a common device class`})
        return
      }
      setCheckedKeys(checkedKeysValue)
      const transformedProduct = await filterProductShownBasedOnClass(
          tempAvailablePrinterClass
      )

      const blockAndImpositionList = await createImpositionAndBlockCollection(
          selectedProducts
      )

      // Set Values
      handleInput(productValues, 'products')
      setAutoRunForm(oldState => ({
        ...oldState,
        device_class: { value: '', error: false }
      }))
      setListData(oldState => ({
        ...oldState,
        blockList: blockAndImpositionList.block,
        impositionTemplateList: blockAndImpositionList.imposition,
        deviceClassList: tempAvailablePrinterClass,
        productList: transformedProduct
      }))
    } catch (error) {
      console.log({ error })
    }
  }

  const filterProductShownBasedOnClass = async availablePrinterClass => {
    const filteredProductList = await listData.unfilteredProductList.filter(
        item => {
          return availablePrinterClass.every(elem =>
              item.available_printer_classes.includes(elem.class)
          )
        }
    )
    const transformedProduct = await transformProduct(filteredProductList)
    return transformedProduct
  }

  const updateProductForEditItem = async checkedKeysValue => {
    setCheckedKeys(checkedKeysValue)
    const selectedProducts = listData.unfilteredProductList.filter(item =>
        checkedKeysValue.includes(`${item.code}`)
    )
    let tempAvailablePrinterClass = createPrinterCollection(selectedProducts)

    const printerList = []
    const extractDeviceClass = tempAvailablePrinterClass.filter(
        item => item.class === data.device_class
    )
    if (extractDeviceClass.length > 0) {
      extractDeviceClass[0].printers_on_run.map(item =>
          printerList.push({
            ...item,
            title: item.full_name,
            key: item.full_name
          })
      )
    }


    setCheckedKeys(checkedKeysValue)
    const transformedProduct = await filterProductShownBasedOnClass(
        tempAvailablePrinterClass
    )
    const blockAndImpositionList = await createImpositionAndBlockCollection(
        selectedProducts
    )

    setListData(oldState => ({
      ...oldState,
      blockList: blockAndImpositionList.block,
      impositionTemplateList: blockAndImpositionList.imposition,
      deviceOrderList: printerList,
      deviceClassList: tempAvailablePrinterClass,
      productList: transformedProduct
    }))
  }

  // FOR BOTH BLOCK TYPE AND FINISHING TYPES FOR IMPOSITION
  // This gets all the unique value of block_types, then checks if those values are in every block_types array, if true it formats it into an object with label and value, if false it returns undefined. Lastly, all undefined values are filtered out of the array.
  const createImpositionAndBlockCollection = selectedProducts => {
    let tempBlockArr = [
      ...new Set(selectedProducts.flatMap(item => item.block_types))
    ]
    .map(item => {
      return selectedProducts.every(listItem =>
          listItem.block_types.includes(item)
      )
             ? { label: item, value: item }
             : undefined
    })
    .filter(item => item !== undefined)

    let tempImpositionArr = [
      ...new Set(selectedProducts.flatMap(item => item.finishing_types))
    ]
    .map(item => {
      return selectedProducts.every(listItem =>
          listItem.finishing_types.includes(item)
      )
             ? { label: item, value: item }
             : undefined
    })
    .filter(item => item !== undefined)

    return {
      block: tempBlockArr,
      imposition: tempImpositionArr
    }
  }

  const createPrinterCollection = selectedProducts => {
    let availablePrinterClass = [
      ...new Set(
          selectedProducts.flatMap(item => item.available_printer_classes)
      )
    ]
    .map(item => {
      return selectedProducts.every(listItem => {
        return listItem.available_printer_classes.includes(item)
      })
             ? { value: item, title: item }
             : undefined
    })
    .map(item => {
      const availablePrinterClass = allDeviceClassList.filter(
          classObj => item?.value === classObj.class
      )
      return availablePrinterClass[0]
    })
    .filter(item => item !== undefined)

    return availablePrinterClass
  }

  const handleInput = (e, key) => {
    const value = e?.target?.value ?? e
    setAutoRunForm(oldState => {
      return {
        ...oldState,
        [key]: { value, error: '' }
      }
    })
  }
  const handleInputError = key => {
    setAutoRunForm(oldState => {
      return {
        ...oldState,
        [key]: { ...oldState[key], error: true }
      }
    })
  }

  const handleCloseModal = async () => {
    // on close of modal, update the product tree back to the original listing then close modal.
    if (
        listData?.originalFilteredProductList &&
        listData.originalFilteredProductList.length > 0
    ) {
      await setListData(oldState => ({
        ...oldState,
        productList: listData.originalFilteredProductList
      }))
    }
    setLoadingErrorAndModal(oldState => ({
      ...oldState,
      isModalOpen: false
    }))
  }

  // Decided to go for custom validation rather than the validation provided by Form.Input because it allowed for custom validation that involves checking how values relate to other values. The function checks if any of the required field are not supplied and updates the state to show a red border on input field as well as an error text
  const validateInputField = () => {
    let finalResult = false
    if (!autoRunForm.description.value) {
      finalResult = true
      handleInputError('description')
    }
    if (autoRunForm.active.value === '') {
      finalResult = true
      handleInputError('active')
    }
    // if (!autoRunForm.fails.value) {
    //   finalResult = true
    //   handleInputError('fails')
    // }
    if (!autoRunForm.imposition.value) {
      finalResult = true
      handleInputError('imposition')
    }
    if (!autoRunForm.blockType.value) {
      finalResult = true
      handleInputError('blockType')
    }
    if (!autoRunForm.device_class.value) {
      finalResult = true
      handleInputError('device_class')
    }
    if (!autoRunForm.utilisation_type.value) {
      finalResult = true
      handleInputError('utilisation_type')
    }
    if (autoRunForm.trigger_bypass.value === '') {
      finalResult = true
      handleInputError('trigger_bypass')
    }
    if (
        autoRunForm.trigger_type.value !== 'sequence' &&
        (!autoRunForm.trigger_value.value ||
            autoRunForm.trigger_value.value.length === 0)
    ) {
      finalResult = true
      handleInputError('trigger_value')
    }
    if (!autoRunForm.special_option.value) {
      finalResult = true
      handleInputError('special_option')
    }
    if (
        autoRunForm.maximum_type.value !== '' &&
        !autoRunForm.maximum_value.value
    ) {
      finalResult = true
      handleInputError('maximum_value')
    }
    if (autoRunForm.group_by.value.length === 0) {
      finalResult = true
      handleInputError('group_by')
    }
    if (autoRunForm.order_by.value.length === 0) {
      finalResult = true
      handleInputError('order_by')
    }
    if (autoRunForm.products.value.length === 0) {
      finalResult = true
      handleInputError('products')
    }
    if (autoRunForm.device_order.value.length === 0) {
      finalResult = true
      handleInputError('device_order')
    }
    if (
        autoRunForm.utilisation_type.value === 'fixed' &&
        !autoRunForm.fixed_value.value
    ) {
      finalResult = true
      handleInputError('fixed_value')
    }
    if (
        autoRunForm.utilisation_max_type.value !== '' &&
        !autoRunForm.utilisation_max_value.value
    ) {
      finalResult = true
      handleInputError('utilisation_max_value')
    }
    return finalResult
  }

  const handleFormSubmission = async () => {
    try {
      // show loading circle for button - decided to do this here incase validation takes time, not very likely though
      setLoading(true)
      // returns a boolean - true if validation fails, false if it doesn't
      let error = await validateInputField()
      if (error) {
        notification.error({message: `Missing required field`})
        setLoading(false)
        return
      }
      // I noticed when editing, even when the device class is changed, the previous device order element is still retained with the new one simply added to it. The implementation below is meant to remove device orders that do not belng to this class - the redundant ones
      const abstractedFullNameFromCurrentDeviceList = await listData.deviceOrderList.map(
          item => item.full_name
      )

      const filteredDeviceOrder = await autoRunForm.device_order.value.filter(
          item => abstractedFullNameFromCurrentDeviceList.includes(item)
      )
      const requestModel = {
        id: actionType === 'cloneRun' || !data.id ? '' : data.id,
        location,
        split_by: data.split_by ?? '',
        fixed_value: +autoRunForm?.fixed_value.value ?? 0,
        active: +autoRunForm?.active.value ?? 0,
        description: autoRunForm.description.value,
        blockType: autoRunForm.blockType.value,
        fails: autoRunForm.fails.value,
        imposition: autoRunForm.imposition.value,
        maximum_type: autoRunForm.maximum_type.value,
        maximum_value: autoRunForm.maximum_value.value,
        group_by: autoRunForm.group_by.value,
        order_by: autoRunForm.order_by.value,
        paper_code: autoRunForm.paper_code.value,
        device_class: autoRunForm.device_class.value,
        device_order: filteredDeviceOrder,
        utilisation_type: autoRunForm.utilisation_type.value,
        utilisation_max_type: autoRunForm.utilisation_max_type.value,
        utilisation_max_value: autoRunForm.utilisation_max_value.value,
        special_option: autoRunForm.special_option.value,
        trigger_type: autoRunForm.trigger_type.value,
        trigger_value:
            autoRunForm?.trigger_value?.value !== '' &&
            autoRunForm?.trigger_value?.value.length !== 0
            ? autoRunForm.trigger_value.value.toString()
            : 'null',
        products: autoRunForm.products.value,
        trigger_bypass: autoRunForm.trigger_bypass.value
      }
      // if id exists, filter and replace that id, else, just push the new id to the array then show the save all change floating button while closing the modal.
      if (!isNaN(index) && index !== '') {
        setRunData(oldState => [
          ...oldState.slice(0, index),
          requestModel,
          ...oldState.slice(index + 1)
        ])
      } else {
        setRunData(oldState => [...oldState, requestModel])
      }
      setLoadingErrorAndModal(oldState => ({
        ...oldState,
        actionType: '',
        editEnabled: true
      }))
      handleCloseModal()
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  const handleFetchSpecialOptions = async printer_id => {
    try {
      const apiResponse = await ZenSmartAPI.post(
          fetchSpecialOptionsRoute(printer_id),
          {
            printer_id
          }
      )
      // The select dropdown requires a label and value which is missing from the data gotten.
      const modifiedSpecialList = [{ label: 'None', value: 'null' }]
      apiResponse.data.data.special_options.forEach(item =>
          modifiedSpecialList.push({
            label: item.name,
            value: item.name
          })
      )
      setListData(oldState => ({
        ...oldState,
        specialOptionList: modifiedSpecialList
      }))
    } catch (error) {
      console.log({ error })
    }
  }

  const updateDeviceOrder = e => {
    const filteredDevice = listData.deviceClassList.filter(
        item => item.class === e
    )
    if (filteredDevice.length > 0) {
      // use the id of one of the printers on the device class to get the special option for all
      handleFetchSpecialOptions(filteredDevice[0]?.printers[0]?.id)
      const printerList = []
      filteredDevice[0].printers_on_run.map(item =>
          printerList.push({
            ...item,
            title: item.full_name,
            key: item.full_name
          })
      )
      setListData(oldState => ({
        ...oldState,
        deviceOrderList: printerList
      }))
    }
  }

  useEffect(() => {
    setListData(oldState => ({
      ...oldState,
      modelList: modelListData
    }))
  }, [])

  // Meant to update deviceOrderList based on device class selected - applicable only for existing data
  useEffect(() => {
    if (data.products && listData.unfilteredProductList.length > 0) {
      updateProductForEditItem(data.products)
    }
  }, [listData.unfilteredProductList])

  useEffect(() => {
    if (autoRunForm.device_class.value === '') {
      setListData(oldState => ({
        ...oldState,
        deviceOrderList: []
      }))
      return
    }
    updateDeviceOrder(autoRunForm.device_class.value)
  }, [autoRunForm.device_class.value, listData.deviceClassList])

  return (
      <InnerContent>
        {/* Header */}
        <AddTopRow>
          <Title small>Add Run</Title>
          <CloseButton onClick={handleCloseModal}>x</CloseButton>
        </AddTopRow>
        {listData.productList.length === 0 ||
         listData.unfilteredProductList.length === 0 ? (
             <LoaderContainer>
               <Skeleton active />
             </LoaderContainer>
         ) : (
             <ModalContent>
               {/* Section One */}
               <Title small margin bold>
                 General Description
               </Title>
               <Input
                   example={`e.g Calender Fails`}
                   label={'Description'}
                   value={autoRunForm.description.value}
                   error={autoRunForm.description.error}
                   onChange={e => handleInput(e, 'description')}
               />
               <Input
                   label={'Make Active'}
                   type={'select'}
                   value={autoRunForm.active.value}
                   error={autoRunForm.active.error}
                   onChange={e => handleInput(e, 'active')}
                   options={[
                     { label: 'Yes', value: 1 },
                     { label: 'No', value: 0 }
                   ]}
               />

               {/* Section Two */}
               <Title small margin bold>
                 What are we printing
               </Title>
               <Input
                   label={'Products'}
                   type={'custom'}
                   error={autoRunForm.products.error}
               >
                 <Tree
                     checkable
                     autoExpandParent={autoExpandParent}
                     onCheck={onCheck}
                     checkedKeys={checkedKeys}
                     treeData={alphabeticallySortNestedArrayOfObject(listData.productList, "title", "children")}
                 />
               </Input>
               <Input
                   label={'Block Type'}
                   type={'select'}
                   value={autoRunForm.blockType.value}
                   error={autoRunForm.blockType.error}
                   onChange={e => handleInput(e, 'blockType')}
                   options={listData.blockList}
               />
               <Input
                   label={'Paper Code'}
                   type={'select'}
                   value={autoRunForm.paper_code.value}
                   error={autoRunForm.paper_code.error}
                   onChange={e => handleInput(e, 'paper_code')}
                   options={listData.paperCodes}
               />
               <Input
                   label={'Fails'}
                   type={'select'}
                   value={autoRunForm.fails.value}
                   error={autoRunForm.fails.error}
                   onChange={e => handleInput(e, 'fails')}
                   options={[
                     { label: "Select a fail", value: '' },
                     { label: "Don't include any fails", value: 'none' },
                     { label: 'Only include fails', value: 'only' }
                   ]}
               />
               <Input
                   label={'Imposition Template'}
                   type={'select'}
                   value={autoRunForm.imposition.value}
                   error={autoRunForm.imposition.error}
                   onChange={e => handleInput(e, 'imposition')}
                   options={listData.impositionTemplateList}
               />

               {/* Section Three */}
               <Title small margin bold>
                 How are we printing it
               </Title>
               <Input
                   label={'Maximum Submission Type'}
                   type={'select'}
                   value={autoRunForm.maximum_type.value}
                   error={autoRunForm.maximum_type.error}
                   onChange={e => handleInput(e, 'maximum_type')}
                   options={[
                     { label: 'None', value: '' },
                     { label: 'Batches', value: 'batches' },
                     { label: 'Lines', value: 'lines' },
                     { label: 'Sheets', value: 'sheets' },
                     { label: 'SQM', value: 'sqm' }
                   ]}
               />

               <Input
                   disabled={autoRunForm.maximum_type.value === ''}
                   label={'Maximum Submission Value'}
                   value={autoRunForm.maximum_value.value}
                   error={autoRunForm.maximum_value.error}
                   type='number'
                   onChange={e => handleInput(e, 'maximum_value')}
               />
               <Input
                   label={'Group Master Submission By'}
                   example={`Sort in order of priority`}
                   type={'transfer-fix'}
                   value={autoRunForm.group_by.value}
                   error={autoRunForm.group_by.error}
                   onChange={e => handleInput(e, 'group_by')}
                   options={listData.modelList.sort((a, b) => a.label.localeCompare(b.label))}
               />
               <Input
                   label={'Order Work By'}
                   example={`Select/sort in order of priority`}
                   type={'transfer-fix'}
                   value={autoRunForm.order_by.value}
                   error={autoRunForm.order_by.error}
                   onChange={e => handleInput(e, 'order_by')}
                   options={listData.modelList.sort((a, b) => a.label.localeCompare(b.label))}
               />

               {/* Section Four */}
               <Title small margin bold>
                 Where are we printing it
               </Title>
               <Input
                   label={'Device Class'}
                   type={'select'}
                   value={autoRunForm.device_class.value}
                   error={autoRunForm.device_class.error}
                   onChange={e => {
                     handleInput(e, 'device_class')
                   }}
                   options={listData.deviceClassList}
               />

               <Input
                   label={'Device to be utilized and in what order'}
                   example={`Select/sort in order of priority`}
                   type={'transfer-fix'}
                   value={autoRunForm.device_order.value}
                   error={autoRunForm.device_order.error}
                   onChange={e => handleInput(e, 'device_order')}
                   options={listData.deviceOrderList}
               />
               <Input
                   label={'How should we utilized these devices'}
                   type={'select'}
                   value={autoRunForm.utilisation_type.value}
                   error={autoRunForm.utilisation_type.error}
                   onChange={e => handleInput(e, 'utilisation_type')}
                   options={[
                     {
                       label: 'Fixed Number of devices',
                       key: 'fixed',
                       value: 'fixed'
                     },
                     {
                       label: 'Line Volume percentage',
                       key: 'line_volume_percentage',
                       value: 'line_volume_percentage'
                     },
                     {
                       label: 'Sheet Volume percentage',
                       key: 'sheet_volume_percentage',
                       value: 'sheet_volume_percentage'
                     },
                     {
                       label: 'SQM Volume percentage',
                       key: 'sqm_volume_percentage',
                       value: 'sqm_volume_percentage'
                     },
                   ]}
               />
               <Input
                   disabled={autoRunForm.utilisation_type.value !== 'fixed'}
                   label={'Fixed Number Value'}
                   value={autoRunForm.fixed_value.value}
                   error={autoRunForm.fixed_value.error}
                   type='number'
                   onChange={e => handleInput(e, 'fixed_value')}
               />

               <Input
                   label={'Is there a maximum number of devices this run can utilise'}
                   type={'select'}
                   value={autoRunForm.utilisation_max_type.value}
                   error={autoRunForm.utilisation_max_type.error}
                   onChange={e => handleInput(e, 'utilisation_max_type')}
                   options={[
                     { label: 'No', value: '' },
                     {
                       label: 'Yes, a percentage of the available pool',
                       value: 'percentage'
                     },
                     { label: 'Yes, a fixed number of devices', value: 'fixed' }
                   ]}
               />
               <Input
                   disabled={autoRunForm.utilisation_max_type.value === ''}
                   label={'Device utilisation maximum value'}
                   value={autoRunForm.utilisation_max_value.value}
                   error={autoRunForm.utilisation_max_value.error}
                   type='number'
                   onChange={e => handleInput(e, 'utilisation_max_value')}
               />
               <Input
                   label={'Special option for device'}
                   type={'select'}
                   value={autoRunForm.special_option.value}
                   error={autoRunForm.special_option.error}
                   onChange={e => handleInput(e, 'special_option')}
                   options={listData.specialOptionList}
               />

               {/* Section Four */}
               <Title small margin bold>
                 When are we printing it?
               </Title>
               <Input
                   label={'Trigger Type'}
                   type={'select'}
                   value={autoRunForm.trigger_type.value}
                   error={autoRunForm.trigger_type.error}
                   onChange={e => handleInput(e, 'trigger_type')}
                   options={[
                     { label: 'Always trigger in sequence', value: 'sequence' },
                     {
                       label:
                           'Only trigger when sheet count reaches a percentage of the total volume',
                       value: 'sheet_volume_percentage'
                     },
                     {
                       label:
                           'Only trigger when SQM reaches a percentage of the total volume',
                       value: 'sqm_volume_percentage'
                     },
                     {
                       label:
                           'Only trigger when line count reaches a percentage of the total volume',
                       value: 'line_volume_percentage'
                     },
                     {
                       label:
                           'Only trigger when sheet count reaches a specific number',
                       value: 'fixed_sheet_volume'
                     },
                     {
                       label: 'Only trigger when SQM count reaches a specific number',
                       value: 'fixed_sqm_volume'
                     },
                     {
                       label: 'Only trigger when line count reaches a specific number',
                       value: 'fixed_line_volume'
                     },
                     {
                       label: 'Only trigger during given time windows',
                       value: 'time_window'
                     }
                   ]}
               />
               {autoRunForm.trigger_type.value === 'time_window' ? (
                   <Input
                       label={'Trigger Value'}
                       type={'select'}
                       value={autoRunForm.trigger_value.value}
                       error={autoRunForm.trigger_value.error}
                       onChange={e => handleInput(e, 'trigger_value')}
                       mode={'multiple'}
                       options={[
                         { label: '12am-1am', value: '12am-1am' },
                         { label: '1am-2am', value: '1am-2am' },
                         { label: '2am-3am', value: '2am-3am' },
                         { label: '3am-4am', value: '3am-4am' },
                         { label: '4am-5am', value: '4am-5am' },
                         { label: '5am-6am', value: '5am-6am' },
                         { label: '6am-7am', value: '6am-7am' },
                         { label: '7am-8am', value: '7am-8am' },
                         { label: '8am-9am', value: '8am-9am' },
                         { label: '9am-10am', value: '9am-10am' },
                         { label: '10am-11am', value: '10am-11am' },
                         { label: '11am-12noon', value: '11am-12noon' },
                         { label: '12noon-1pm', value: '12noon-1pm' },
                         { label: '1pm-2pm', value: '1pm-2pm' },
                         { label: '2pm-3pm', value: '2pm-3pm' },
                         { label: '3pm-4pm', value: '3pm-4pm' },
                         { label: '4pm-5pm', value: '4pm-5pm' },
                         { label: '5pm-6pm', value: '5pm-6pm' },
                         { label: '6pm-7pm', value: '6pm-7pm' },
                         { label: '7pm-8pm', value: '7pm-8pm' },
                         { label: '8pm-9pm', value: '8pm-9pm' },
                         { label: '9pm-10pm', value: '9pm-10pm' },
                         { label: '10pm-11pm', value: '10pm-11pm' },
                         { label: '11pm-12am', value: '11pm-12am' }
                       ]}
                   />
               ) : (
                    <Input
                        label={'Trigger Value'}
                        value={autoRunForm.trigger_value.value}
                        error={autoRunForm.trigger_value.error}
                        type='number'
                        onChange={e => handleInput(e, 'trigger_value')}
                    />
                )}
               <Input
                   label={
                     'Ignore sort order and execute as soon as these triggers occur?'
                   }
                   type={'select'}
                   value={autoRunForm.trigger_bypass.value}
                   error={autoRunForm.trigger_bypass.error}
                   onChange={e => handleInput(e, 'trigger_bypass')}
                   options={[
                     { label: 'Yes', value: 1 },
                     { label: 'No', value: 0 }
                   ]}
               />
               <Popconfirm
                   placement='top'
                   title={'Confirm Submission'}
                   onConfirm={handleFormSubmission}
                   okText='Yes'
                   cancelText='No'
               >
                 <ButtonContainer>
                   <Button
                       loading={loading}
                       size='large'
                       shape='round'
                       type='primary'
                   >
                     Save All Changes
                   </Button>
                 </ButtonContainer>
               </Popconfirm>
             </ModalContent>
         )}
      </InnerContent>
  )
}

export default AddForm
