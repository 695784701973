import React, { useState, useEffect } from "react";
import { Modal, Form, Select, Radio, Input, notification } from 'antd';
import styled from "styled-components";
import { useSelector } from 'react-redux';
import { ZenSmartAPI } from 'utils';
import { reportFaultRoute, indicateFaultSeverityRoute, getUsersRoute, getFaultsByStatusRoute } from "utils/apiRoutes"
import { alphabeticalData } from 'utils/sortData';

const { TextArea } = Input;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`

const StyledLabel = styled.label`
  display: block;
`

const faultSeverityQuestions = [
  'Q1. Is the machine down?',
  'Q2. Can you run the machine at expected speed?',
  'Q3. Is there any other machine available to perform the task?',
  'Q4. Is quality impacted?'
];

const parseAPIResponseErrors = (response, errorMsgPlaceholder) => {
  let errorMessage = '';
  if(response && response.errors && response.message) {
    errorMessage += `${response.message} `;
    const errors = Object.values(response.errors);
    errors.forEach(err => errorMessage += `${err} `);
  } else {
    errorMessage = errorMsgPlaceholder;
  }
  return errorMessage;
}

const ReportFaultModal = props => {
  const { showReportFaultModal, setShowReportFaultModal, stations, reload, selectedTask } = props;
  const [ faultQuestionsAnswers, setFaultQuestionsAnswers ] = useState({Q1: 'no', Q2: 'no', Q3: 'no', Q4: 'no'})
  const [ reportFaultData, setReportFaultData ] = useState({station: {id: '', name: ''}, comment: '', email: ''})
  const [ reportLoading, setReportLoading ] = useState(false);
  const [ engineersList, setEngineersList ] = useState([]);
  const machine = useSelector(state => state.machine)

  useEffect(() => {
    loadEngineersList(); 
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  const getFaultSeverity = () => {
    setReportLoading(true);
    const payload = faultQuestionsAnswers;
    ZenSmartAPI.post(indicateFaultSeverityRoute, payload)
    .then(res => {
      if(res.data && res.data.data) {
        const severity = res.data.data.toString().toLowerCase();
        reportFault(severity);
      } else {
        notification.error({
          message: parseAPIResponseErrors(null, 'Get fault severity result failed.')
        })
      }
    })
    .catch(err => {
      setReportLoading(false);
      const error = err.response && err.response.data ? err.response.data : null
      notification.error({
        message: parseAPIResponseErrors(error, 'Get fault severity result failed.')
      })
    })
  }

  const reportFault = severity => {
    const payload = {
      task_log_id: selectedTask && selectedTask.history.length > 0 ? selectedTask.history[0].id : null,
      severity: severity,
      status: 'reported',
      station_id: reportFaultData?.station?.id ? reportFaultData.station.id : machine.station.id,
      comment: reportFaultData.comment,
      email: reportFaultData.email
    }

    ZenSmartAPI.post(reportFaultRoute, payload)
    .then((res)=>{
      if(res.data.data) {
        notification.success({
          message: 'Succcessfully reported a fault.'
        })
        reload(val => !val)
        setReportLoading(false);
        setShowReportFaultModal(false);
      }
    })
    .catch(err => {
      const error = err.response && err.response.data ? err.response.data : null
      notification.error({
        message: parseAPIResponseErrors(error, 'Reporting fault failed.')
      })
      setReportLoading(false);
    })
  }

  const loadEngineersList = () => {
    ZenSmartAPI.get(getUsersRoute)
    .then((res)=> {
      if(res.data && res.data.data && engineersList.length === 0)  {
        const usersList = res.data.data.filter(engr => engr.role.toLowerCase() === 'engineer');
        if(usersList && usersList.length > 0) {
          setEngineersList(usersList)
          setReportFaultData({
            ...reportFaultData,
            email: usersList[0].email
          })
        }
      }
    })
    .catch(err => {
      notification.error({
        message: parseAPIResponseErrors(err.response.data, 'Loading engineers list failed.')
      })
    })
  }

  const onCloseReportFaultModal = () => {
    setReportFaultData({
      comment: '', 
      email: engineersList && engineersList.length > 0 ? engineersList[0].email : ''
    })
    setFaultQuestionsAnswers({Q1: 'no', Q2: 'no', Q3: 'no', Q4: 'no'})
  }

  const setStation = (name) => {
    const target = stations.filter(station => station.name === name)[0]
    setReportFaultData({...reportFaultData, station: {id: target.id, name: target.name}})
  }

  return (
    <Modal
      title={'Report Fault'}
      centered
      visible={showReportFaultModal}
      onCancel={ () => !reportLoading && setShowReportFaultModal(false) }
      afterClose={ onCloseReportFaultModal }
      onOk={() =>  getFaultSeverity()}
      okText='Report'
      okButtonProps={{style: {background: '#ff4d4f', border:'#ff4d4f'}}}
      confirmLoading={ reportLoading }
      maskClosable={!reportLoading}
    > 
      <Form name="basic">
        {stations && stations.length && (
          <StyledFormItem label="Station" name="station">
            <Select 
              placeholder="Select a station"
              value={ reportFaultData.station?.name } 
              showSearch
              onChange={ val => setStation(val) }
            >
              { stations.map(station =>
                <Select.Option key={ station.name }>{ station.name }</Select.Option>
              )}
            </Select>
          </StyledFormItem>
        )}

        <StyledFormItem label="Fault Description" name="description">
          <TextArea placeholder="Enter fault description" value={ reportFaultData.comment } onChange={ e => setReportFaultData({...reportFaultData, comment: e.target.value}) }/>
        </StyledFormItem>
  
        <StyledFormItem label="Severity" name="severity">
          { faultSeverityQuestions.map((question, index) =>
            <div key={index}>
              <StyledLabel>{question}</StyledLabel>
              <Radio.Group value={faultQuestionsAnswers[`Q${index+1}`]} onChange={e => setFaultQuestionsAnswers({...faultQuestionsAnswers, [`Q${index+1}`]: e.target.value })}>
                <Radio value={'yes'}>Yes</Radio>
                <Radio value={'no'}>No</Radio>
              </Radio.Group>
            </div>
          )}
        </StyledFormItem>

        <StyledFormItem label="Engineer" name="engineer">
          <Select 
            value={ reportFaultData.email } 
            showSearch
            onChange={ val => setReportFaultData({...reportFaultData, email: val}) }
          >
            { engineersList && alphabeticalData(engineersList, "name").map(engr =>
              <Select.Option key={ engr.email }>{ engr.name }</Select.Option>
            )}
          </Select>
        </StyledFormItem>
      </Form>
    </Modal>
  );
  
}

export default ReportFaultModal;