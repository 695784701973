import React from "react";
import { Panel } from "components/primaries";
import { Imposition } from "modules/imposition/Imposition";
import styled from "styled-components";
import { color } from "components/zensmart-design-system/shared/styles.js";

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;

const ImpositionPage = () => (
  <>
    <Header>
      <Title>Process {">"} Imposition Designer</Title>
    </Header>
    <Panel>
      <Imposition />
    </Panel>
  </>
);

export default ImpositionPage;
