import React, { useState } from 'react';
import * as S from './DatePickerStyle';
import moment from 'moment';
import { formatDate } from 'utils/dateFormatter';

const DatePicker = ({
    startDate,
    defaultStartDate,
    endDate,
    defaultEndDate,
    format = 'YYYY-MM-DD'
}) => {
    const [alertPanelStatus, setAlertPanelStatus] = useState(false);
    const [newStartDate, setNewStartDate] = useState(moment(defaultStartDate));
    const [newEndDate, setNewEndDate] = useState(moment(defaultEndDate));

    const handleSubmit = () => {
        setAlertPanelStatus(false);
        if (newStartDate !== null || newEndDate !== null) {
            if (moment(newStartDate) <= moment(newEndDate)) {
                startDate(formatDate(format, newStartDate));
                endDate(formatDate(format, newEndDate));
            } else {
                setAlertPanelStatus(true);
            }
        }
    }

    return (
        <S.DatePickerContainer>
            {alertPanelStatus && <S.AlertPanel showIcon={true} message={<><b>FROM</b> cannot be after <b>TO</b>.</>} type={'warning'} />}
            <S.DatePickerLabel>FROM</S.DatePickerLabel>
            <S.DatePickerInput
                separator={'/'}
                defaultValue={moment(defaultStartDate)}
                value={newStartDate}
                onChange={(results) => (results !== null && setNewStartDate(moment(results?._d)))}                
            />
            <S.DatePickerLabel>TO</S.DatePickerLabel>
            <S.DatePickerInput
                dateFormat={format}
                defaultValue={moment(defaultEndDate)}
                value={newEndDate}
                onChange={(results) => (results !== null && setNewEndDate(moment(results?._d)))}
            />
            <S.SubmitButton type="primary" onClick={() => {handleSubmit()}}>GO</S.SubmitButton>
        </S.DatePickerContainer>
    );
}

export default DatePicker;
