export default {
  ADD_SIBLING: 'ADD_SIBLING',
  ADD_ROOT_SIBLING: 'ADD_ROOT_SIBLING',
  ADD_CHILDREN: 'ADD_CHILDREN',
  ADD_TRIGGER: 'ADD_TRIGGER',
  REMOVE_CHILDREN: 'REMOVE_CHILDREN',
  SET_PRODUCT_PROCESSES: 'SET_PRODUCT_PROCESSES',
  UPDATE_PROCESS_DATA: 'UPDATE_PROCESS_DATA',
  UPDATE_TRIGGER_DATA: 'UPDATE_TRIGGER_DATA',
  REMOVE_TRIGGER_DATA: 'REMOVE_TRIGGER_DATA',

  SET_PRINTER_TIMELINE_DATA: 'SET_PRINTER_TIMELINE_DATA',
  SET_CONTROL_TOWER_STATE: 'SET_CONTROL_TOWER_STATE',

  PRINT_SUBMISSION: 'PRINT_SUBMISSION',
  DELETE_SUBMISSION: 'DELETE_SUBMISSION',
  SET_SUBMISSION_STATUS: 'SET_SUBMISSION_STATUS',
  FORCE_DELETE_SUBMISSION: 'FORCE_DELETE_SUBMISSION',

  ADD_DISPATCH_STAGE: 'ADD_DISPATCH_STAGE',
  EDIT_DISPATCH_STAGE: 'EDIT_DISPATCH_STAGE',
  SET_PRODUCT_STAGES_STATE: 'SET_PRODUCT_STAGES_STATE',
}