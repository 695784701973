import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Panel } from 'components/primaries'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { ZenSmartAPI } from 'utils';
import {
  PrinterTimeline,
  LastUpdatedSection,

} from './partials/sections'
import { useDispatch, useSelector } from 'react-redux'
import { SET_PRINTER_TIMELINE_DATA, SET_SUBMISSIONS_DATA, SET_FINISHING_TYPES_DATA } from 'store/actions/controlTowerTypes';
import { getPrintersRoute, getSubmissionsRoute, finishingTypesRoute, getAllAutoRunLocationsRoute } from "utils/apiRoutes"
import { notification, Spin } from "antd";
import { useParams } from 'react-router-dom'

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const ControlTower = () => {
  const dispatch = useDispatch();
  const [lastUpdated, setlastUpdated] = useState(new Date())
  const [options, setOption] = useState({})
  const [loading, setLoading] = useState(false)
  const userData = useSelector(state => state.user)
  const [locationId, setLocationId] = useState(null)
  const [autoRun, setAutoRun] = useState([])
  const [initialSetLoading, setInitialSetLoading] = useState(false)
  const { slug } = useParams()
  const fetchTimeLineDatas = (id = null, initialLoad = false) => {
    if (initialLoad) {
      setInitialSetLoading(true)
    }
    setLoading(true)
    ZenSmartAPI.get(getPrintersRoute(id))
      .then((res) => {
        setLoading(false)
        setInitialSetLoading(false)

        setlastUpdated(new Date())
        dispatch({
          type: SET_PRINTER_TIMELINE_DATA,
          payload: res.data
        })
      })
      .catch((res) => {
        setLoading(false)
        setInitialSetLoading(false)

        notification.error({ message: "Failed to fetch printers!" })

      })

  }

  const fetchTSubmissionDatas = (id = null) => {
    ZenSmartAPI.get(getSubmissionsRoute(id))
      .then((res) => {
        dispatch({
          type: SET_SUBMISSIONS_DATA,
          payload: res.data
        })
      })
      .catch((res) => {
        setLoading(false)
        notification.error({ message: "Failed to fetch submissions!" })
      })

  }

  const fetchTFinishingTypes = (firstLoad) => {
    if (!firstLoad) {
      setLoading(true)
    }
    ZenSmartAPI.get(finishingTypesRoute)
      .then((res) => {
        if (!firstLoad) {
          setLoading(false)
        }
        dispatch({
          type: SET_FINISHING_TYPES_DATA,
          payload: res.data.data
        })
      })
      .catch((res) => {
        if (!firstLoad) {
          setLoading(false)
        }
      })

  }

  const updatePrinterTimeline = (noPrinterFetch = false, initialLoad = false, id = null) => {
    const locId = id ? id : locationId
    if (!noPrinterFetch) {
      fetchTimeLineDatas(locId, initialLoad)
    }
    fetchTSubmissionDatas(locId)
    fetchTFinishingTypes(true)
  }

  useEffect(() => {
    if (slug && userData.details) {
      const id = slug && userData.details &&
        userData.details.page_settings.printer_location_settings && userData.details.page_settings.printer_location_settings.find(data => data.name.replace(/\W+(?!$)/g, '-').toLowerCase() === slug.toLowerCase())
        ? userData.details.page_settings.printer_location_settings.find(data => data.name.replace(/\W+(?!$)/g, '-').toLowerCase() === slug.toLowerCase()).id
        : null
      setLocationId(id)
      dispatch({
        type: SET_PRINTER_TIMELINE_DATA,
        payload: { data: [] }
      })
      updatePrinterTimeline(false, true, id)

    }
  }, [userData, slug]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Spin tip="Loading..." spinning={initialSetLoading}>
        <Header>
          <Title>Machine {">"} Control Tower</Title>
        </Header>
        <Panel title="CONTROL TOWER">
          <>
            <LastUpdatedSection
              loading={loading}
              dateTime={lastUpdated}
              updatePrinterTimeline={updatePrinterTimeline}
              options={options}
              setOption={setOption}
            />
            <PrinterTimeline
              updatePrinterTimeline={updatePrinterTimeline}
              locationId={locationId}
              autoRun={autoRun}
              setAutoRun={setAutoRun}
              options={options}
              setOption={setOption}
              fetchTUpdateSubmissionDatas={fetchTSubmissionDatas}
            />
          </>
        </Panel>
      </Spin>
    </>
  )
}

export default ControlTower