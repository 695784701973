import React, { forwardRef, useEffect, useState } from 'react'
import { css } from 'styled-components'

import { PROCESS } from 'types'

import { ReactComponent as Octagon } from 'static/shapes/octagon.svg'
import { ReactComponent as Arrow } from 'static/shapes/arrow.svg'
import { ReactComponent as Bullet } from 'static/shapes/bullet.svg'
import { ReactComponent as Lego } from 'static/shapes/lego.svg'
import { ReactComponent as Hexagon } from 'static/shapes/hexagon.svg'
import { ReactComponent as Diamond } from 'static/shapes/diamond.svg'

import { useHistory } from "react-router-dom";
import { Item } from '../primaries'
import { getColorFromType } from 'utils'
import { Popconfirm } from 'antd';
import { useSelector } from 'react-redux'


function LibraryItem(props, ref) {
  const history = useHistory();
  const productID = useSelector(state => state.product_flow.product_id)

  let itemProps = null
  let itemRoute = null
  //Go To Route When Stage Libaries Is Cliked

  const goToRoute = (route) => route ? history.push(route) : "";

  switch (props.type) {
    case PROCESS.TYPE.AUTOMATED:
      itemProps = { shape: <Octagon /> }
      break

    case PROCESS.TYPE.IMPOSITION:
      itemProps = { stylings: css`border-radius: 4px;` }
      itemRoute = productID ? `/app/print/imposition?product_id=${productID}` : '/app/print/imposition'

      break

    case PROCESS.TYPE.PROVIDER:
      itemProps = { shape: <Diamond /> }
      break

    case PROCESS.TYPE.QUEUED:
    case PROCESS.TYPE.READY:
    case PROCESS.TYPE.MATCH_OUT:
    case PROCESS.TYPE.LOCKED:
    case PROCESS.TYPE.EDGE:
      break;
    case PROCESS.TYPE.PACK_TYPE:
      if(props.text !== PROCESS.TYPE.PACK_TYPE) {
        itemProps = {stylings: css`min-height: 56px;height:auto;padding:8px;`}
      }
      break;
    case PROCESS.TYPE.PRE_DISPATCH:
      itemProps = { stylings: css`border-radius: 4px;` }
      break
    case PROCESS.TYPE.DISPATCH:
      itemProps = { stylings: css`border-radius: 4px;` }
      itemRoute = productID ? `/app/pack-and-ship/dispatch-define?product_id=${productID}` : `/app/pack-and-ship/dispatch-define`
      break
    case PROCESS.TYPE.BLOCK:
    case PROCESS.TYPE.FIELD_SWITCH:
      if (props.immutable) {
        itemProps = {
          stylings: css`
          border-left-width: 1px;
          border-top-width: 8px;
        `
        }
      } else {
        itemProps = {
          stylings: css`
          border-left-width: 1px;
          border-bottom-width: 8px;
        `
        }
      }
      break

    case PROCESS.TRIGGER.EMAIL:
      itemProps = {
        shape: <Arrow />,
        stylings: css`font-size: 12px;`
      }
      break

    case PROCESS.TRIGGER.FIELD:
      itemProps = {
        shape: <Bullet />,
        stylings: css`font-size: 12px;`
      }
      break

    case PROCESS.TRIGGER.CALLOUT:
      itemProps = {
        shape: <Lego />,
        stylings: css`font-size: 12px;`
      }
      break

    case PROCESS.TRIGGER.ADD_COST:
      itemProps = {
        shape: <Hexagon />,
        stylings: css`font-size: 12px;`
      }
      break

    default:
      itemProps = {}
      break
  }

  return (

    <Item
      {...props}
      {...itemProps}
      color={props.color ? props.color : getColorFromType(props.type)}
      onClick={() => goToRoute(itemRoute)}
      ref={ref}
    />

  )
}

export default forwardRef(LibraryItem)