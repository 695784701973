import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Row, Col, Button, Input, notification, Modal } from 'antd'
import { panelSortData } from 'utils/sortData'
import {
    CaretUpOutlined,
    CaretDownOutlined,
} from '@ant-design/icons';
import { editSettingsRoute, deleteSettingsRoute } from "utils/apiRoutes"
import { settingsInitialData } from "utils/panelsInitialData"
import { ZenSmartAPI } from 'utils'
import { debounce } from "utils/debounce"
import BusinessHoursModal from './Modals/BusinessHoursModal'
import PublicHolidaysModal from './Modals/PublicHolidaysModal'
import moment from 'moment'

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border-left : none !important;
    border-right : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}
`
const { TextArea } = Input;

const TableData = (props) => {

    const { itemData, loading, setLoading, data, setData, ascend, setAscend, fetchData } = props
    const [editSettingsObjects, setEditSettingsObjects] = useState({})
    const [showModals, setShowModals] = useState({})
    const sortingData = (data, property, ifNotProperty) => {
        if (ascend[property] === null) {
            setAscend({ ...settingsInitialData, [property]: true })
        }
        else {
            ascend[property] === true ? setAscend({ ...settingsInitialData, [property]: false }) : setAscend({ ...settingsInitialData, [property]: true })
        }
        setData(panelSortData(data, property, ascend[property], ifNotProperty))
    }
    const getHeaders = (property, text, ifAscend) => {
        return (
            <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifAscend)} style={{ cursor: "pointer" }}>
                <Col span={ascend[property] === null ? 24 : 21}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }
    const editSetting = (id) => {

        const editItemData = data.map(data => {
            if (data.id === id) {
                //data.edit = true
                data.editToggle = true
            }
            return data
        })
        setData(editItemData)
    }

    const writeEditSetting = debounce((value, id) => {
        const objectData = editSettingsObjects
        objectData[id] = value.target.value.replace(/\r?\n|\r/g, " ").trim()

        setEditSettingsObjects(objectData)
    }, 200);

    const undoEdit = (id, newValue = null) => {
        const undoItemData = data.map(data => {
            if (data.id === id) {
                data.editToggle = false
                data.val = newValue
            }
            return data
        })
        const objectData = editSettingsObjects
        delete objectData[id]
        setEditSettingsObjects(objectData)
        setData(undoItemData)
    }

    const saveEditSetting = (payload) => {
        let mod_payload = { ...payload };
        if (mod_payload.nullable || editSettingsObjects[mod_payload.id] !== "") {
            if (editSettingsObjects[mod_payload.id]) {
                mod_payload.val = editSettingsObjects[mod_payload.id]
            }
            else if(mod_payload.nullable && editSettingsObjects[mod_payload.id] === '') {
                mod_payload.val = null

            }
        }
        else if (editSettingsObjects[mod_payload.id] === "") {
            notification.warning({ message: "This setting should have a value!" })
            return

        }
        delete mod_payload.edit
        delete mod_payload.editToggle
        setLoading(true)
        ZenSmartAPI.put(editSettingsRoute(mod_payload.id), mod_payload)
            .then((res) => {
                undoEdit(res.data.data.id, res.data.data.val)
                notification.success({ message: "Update successful!" })
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                // handle validation errors
                notification.error({ message: "Failed updating setting!" })
            })
    }

    const setSettingsFunction = (record) => {

        switch (record.param) {
            case "business_hours": {
                return setShowModals({ ...showModals, business_hours: true })
            }
            case "public_holidays": {
                return setShowModals({ ...showModals, public_holidays: true })
            }
            default:
                return editSetting(record.id)

        }
    }

    const deleteSettings = (id) => {
        Modal.confirm({
            'content': "Are you sure you want to delete this setting?",
            onOk: () => {
                ZenSmartAPI.delete(deleteSettingsRoute(id))
                    .then((res) => {
                        setLoading(false)
                        notification.success({ message: "Delete successful!" })

                        fetchData()
                    })
                    .catch((error) => {
                        setLoading(false);
                        // handle validation errors
                        notification.error({ message: "Failed deleting setting!" })
                    })
            },
        });

    }

    const getBusinessHoursValues = (value) => {
        const constructHoursObject = []
        Object.keys(value).map(data => {
            const firstWord = data.slice(0, data.lastIndexOf('_'))
            if (!constructHoursObject.includes(firstWord)) {
                constructHoursObject.push(firstWord)
            }

        })
        const returnValue = <div>
            {constructHoursObject.map((data, index) =>
                <div key={index}>
                    <Row type="flex" align="middle" justify="center" style={{ fontWeight: "bold" }}>
                        <Col span={9} style={{ textTransform: "capitalize" }}>
                            {data}
                        </Col>
                        <Col span={15}>
                            {value[`${data}_start`] === "00:00:00" && value[`${data}_end`] === "23:59:59" ?
                                "24 hours" : value[`${data}_start`] === null && value[`${data}_end`] === null || value[`${data}_start`] === "00:00:00" && value[`${data}_end`] === "00:00:00" ?
                                    "No operation" :
                                    <div>
                                        {value[`${data}_start`]} to {value[`${data}_end`]}
                                    </div>
                            }
                        </Col>
                    </Row>
                </div>
            )}
        </div>
        return returnValue
    }

    const getPublicHoursValue = (value) => {
        if (value && value.holidays.length > 0) {
            const returnValue = <div>
                {value.holidays.map((data, index) =>
                    <Row key={index} type="flex" align="middle" justify="center" style={{ fontWeight: "bold", padding: 2 }}>
                        <p>
                            {data.name} ({moment(data.from).format('YYYY-MM-DD')} {data.to && data.from !== data.to && <span style={{ fontSize: 25 }}> &#8594; </span>}  {data.to && data.from !== data.to && moment(data.to).format('YYYY-MM-DD')})
                        </p>
                    </Row>
                )}
            </div>
            return returnValue
        }
        else {
            return "No Holidays"
        }
    }

    const columns = [
        {
            title: getHeaders("setting", "SETTING", false),
            dataIndex: 'setting',
            key: 'setting',
            render(value, row, index) {
                return rowStyle(value ? value.replace(/_/g, ' ') : "-", index, row)
            },
        },
        {
            title: getHeaders("param", "LABEL", false),
            dataIndex: 'param',
            key: 'param',
            render(value, row, index) {
                return rowStyle(value ? value.replace(/_/g, ' ') : "-", index, row)
            },
        },

        {
            title: getHeaders("description", "DESCRIPTION", false),
            dataIndex: 'description',
            key: 'param',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row, false)
            },
        },
        {
            title: getHeaders("val", "VALUE", false),
            dataIndex: 'val',
            key: 'val',
            render(value, row, index) {
                const data =
                    <div>
                        <Row type="flex" align="middle" justify="center">
                            <Col span={18}>
                                <TextArea defaultValue={value} onChange={(value) => { value.persist(); writeEditSetting(value, row.id) }} autosize />
                            </Col>
                            <Col span={3} style={{ padding: 5 }}>
                                <Button type="primary" style={{ width: "100%", fontSize: "12px" }} onClick={() => undoEdit(row.id, row.val)} icon="undo" ghost />
                            </Col>
                            <Col span={3} style={{ padding: 5 }}>
                                <Button type="primary" style={{ width: "100%", fontSize: "12px" }} icon="check" onClick={() => saveEditSetting(row)} />
                            </Col>

                        </Row>
                    </div>
                return rowStyle((typeof value === "string" || !value) && row.editToggle ? data :
                    row.param === "business_hours" ?
                        getBusinessHoursValues(value) :
                        row.param === "public_holidays" ?
                            getPublicHoursValue(value) :
                            <a style={{ width: "100%", fontSize: "12px", color: "blue", textDecorationLine: "underline" }} onClick={() => setSettingsFunction(row)}>
                                {(typeof value === "object" && value) ? "Click to view" : value}
                            </a>, index, row, false)
            },
        },
        {
            title: "ACTIONS",
            dataIndex: 'id',
            key: 'id',
            width: "20%",
            render(value, row, index) {
                return rowStyle(
                    <div style={{ textAlign: "center" }}>
                        <Row type="flex" align="middle" justify="center">
                            <Col span={12} style={{ padding: 10 }}>
                                <Button style={{ width: "100%" }} type="primary" onClick={() => setSettingsFunction(row)} disabled={!row.edit}>Edit</Button>
                            </Col>
                            <Col span={12} style={{ padding: 10 }}>
                                <Button style={{ width: "100%" }} type="danger" onClick={() => deleteSettings(value)} disabled={!row.deletable}>Delete</Button>
                            </Col>
                        </Row>
                    </div>
                    , index, row)
            },
        },
    ];

    const rowStyle = (value, index, row, capitalize = true) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div style={{ textTransform: capitalize ? "capitalize" : undefined }}>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }

        return obj
    }
    useEffect(() => {

        if (data.length > 0) {

            const itemObj = itemData.map(data => {
                const obj = {
                    id: data.id,
                    setting: data.setting,
                    param: data.param,
                    val: data.val,
                    description: data.description
                }
                return obj;
            })

            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "60vh" }}
                    pagination={false}
                    style={{ paddingBottom: 20 }}
                />
            </StyleTable>
            <BusinessHoursModal showModal={showModals} setShowModals={setShowModals} fetchData={fetchData} itemData={data} />
            <PublicHolidaysModal showModal={showModals} setShowModals={setShowModals} fetchData={fetchData} itemData={data} />
        </>
    )
}

export default TableData