import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { getOperatorsComparisonRoute, allScanTypesRoute } from "utils/apiRoutes"
import moment from 'moment';
import { alphabeticalData } from "utils/sortData"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`

const { Option } = Select;

const OperatorComparisonPage = (props) => {
    const [loading, setLoading] = useState(false)
    const [scanTypes, setScanTypes] = useState([])
    const [chartData, setChartData] = useState([])
    const [legendData, setLegendData] = useState([])
    const [payload, setPayload] = useState({
        from: moment().format('YYYY-MM-DD HH:mm:ss'),
        to: moment().format('YYYY-MM-DD HH:mm:ss'),
        group_by: "hours"
    })
    const fetchScanTypes = () => {

        setLoading(true)
        ZenSmartAPI.get(allScanTypesRoute)
            .then((res) => {
                setLoading(false)
                setScanTypes(res.data.data)

            })
            .catch((res) => {
                setLoading(false)
            })

    }

    const fetchData = () => {

        if (!payload.scan_type) {
            notification.error({ message: "Please select a scan type!" })
            return
        }

        if (!payload.from || payload.from === "" || !payload.to || payload.to === "") {
            notification.error({ message: "Please select date ranges!" })
            return
        }

        setLoading(true)
        ZenSmartAPI.post(getOperatorsComparisonRoute, payload)
            .then((res) => {
                setLoading(false)
                if (res.data.data.chart_value.length > 0) {
                    setChartData(res.data.data.chart_value)
                    setLegendData(res.data.data.legend_value)
                }
                else {
                    notification.error({ message: "No data found!" })
                }
            })
            .catch((error) => {
                setLoading(false);
                // handle validation errors

                if (_.get(error, 'response.status') === 400) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An unhandled error occurred. Please contact your supervisor.'),
                    });
                }

                if (_.get(error, 'response.status') > 499) {
                    notification.error({
                        message: 'An unhandled error occurred. Please contact your supervisor'
                    });
                }
            })
        return true
    }

    const changeFrom = (value, dateString) => {
        setPayload({ ...payload, from: dateString })
    }
    const changeTo = (value, dateString) => {
        setPayload({ ...payload, to: dateString })
    }
    const changeScanType = (value) => {
        setPayload({ ...payload, scan_type: value })

    }
    useEffect(() => {
        fetchScanTypes()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Performance {">"} Operator Performance {">"} Operators Comparison Chart</Title>
            </Header>
            <Panel title="OPERATORS COMPARISON CHART">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <InputStyle />
                    <Row type="flex" align="middle">
                        <DateColStyle span={4} >
                            <Heading>SCAN TYPE</Heading>
                            <Select
                                optionFilterProp="children"
                                showSearch
                                style={{ marginTop: 10, width: '100%', textTransform: "capitalize" }} onChange={changeScanType} placeholder="Select Scan type..." >
                                {alphabeticalData(scanTypes, "text").map(data =>
                                    <Option value={data.text} style={{ textTransform: "capitalize" }}>{data.text.toLowerCase()}</Option>
                                )}
                            </Select>
                        </DateColStyle>
                        <DateColStyle span={4} >
                            <Heading>FROM</Heading>
                            <DatePicker showTime style={{ marginTop: 10, width: '100%' }} onChange={changeFrom} format="YYYY-MM-DD HH:mm:ss" defaultValue={moment(moment(), 'YYYY-MM-DD HH:mm:ss')} />
                        </DateColStyle>
                        <DateColStyle span={4} >
                            <Heading>TO</Heading>
                            <DatePicker showTime style={{ marginTop: 10, width: '100%' }} onChange={changeTo} format="YYYY-MM-DD HH:mm:ss" defaultValue={moment(moment(), 'YYYY-MM-DD HH:mm:ss')} />
                        </DateColStyle>
                        <DateColStyle span={4} >
                            <Heading>GROUP BY</Heading>
                            <Select
                                optionFilterProp="children"
                                showSearch
                                style={{ marginTop: 10, width: '100%', textTransform: "capitalize" }} value={payload.group_by} onChange={(e) => setPayload({ ...payload, group_by: e })} placeholder="Select Group By..." >
                                <Option value={"hours"} style={{ textTransform: "capitalize" }}>Hours</Option>
                                <Option value={"days"} style={{ textTransform: "capitalize" }}>Days</Option>
                            </Select>
                        </DateColStyle>
                        <DateColStyle span={4}>
                            <br />
                            <StyleButton style={{ marginTop: 10 }} onClick={fetchData} loading={loading} type="primary">GO</StyleButton>
                        </DateColStyle>
                    </Row>

                </div>
                <Row type="flex" style={{ paddingRight: 40 }}>
                    <Col span={24}>
                        <ResponsiveContainer width="100%" height={500}>
                            <LineChart
                                width={500}
                                height={300}
                                data={alphabeticalData(chartData, "name")}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend style={{ margin: 20 }} iconType="circle" align="right" verticalAlign="top"
                                    layout="vertical" payload={legendData}
                                    wrapperStyle={{
                                        paddingLeft: "10px"
                                    }}
                                />
                                {legendData.map(data => {
                                    return <Line type="monotone" dataKey={data.id} stroke={data.color} activeDot={{ r: 8 }} strokeWidth={4} />
                                })}
                            </LineChart>
                        </ResponsiveContainer>

                    </Col>
                </Row>
            </Panel>
        </>
    )
}

export default OperatorComparisonPage