import React, { useState, useEffect } from 'react'
import CurrentRuns from './CurrentRuns'
import KillRun from './KillRun'
import Logs from './Logs'
import {
  Button,
  Row,
  InnerContainer,
  FloatingButton,
  ModalContainer,
  LoaderContainer,
  Floating
} from './components/index.js'
import { Panel } from 'components/primaries'
import {
  getRunProductsRoute,
  getRunPrintersRoute,
  getModelRoute,
  updateAutoRunsInLocationRoute
} from 'utils/apiRoutes'
import { ZenSmartAPI } from 'utils'
import { getAutoRunsInLocationRoute } from 'utils/apiRoutes'
import { Popconfirm, notification, Skeleton } from 'antd'
import AddForm from './components/AddForm'
import { Header, Title } from '../styles'
import { useSelector } from 'react-redux'

const AutoRun = ({ match }) => {
  const { name, id } = match.params

  const userData = useSelector((state) => state.user.details);

  const [runData, setRunData] = useState([])
  const [loadingErrorAndModal, setLoadingErrorAndModal] = useState({
    loading: false,
    error: '',
    index: '',
    isModalOpen: false,
    editEnabled: false,
    data: {},
    products: [],
    actionType: ''
  })
  const [allDeviceClassList, setAllDeviceClassList] = useState([])
  const [listData, setListData] = useState({
    unfilteredProductList: [],
    productList: [],
    blockList: [],
    modelList: [],
    selectedProductListParent: [],
    specialOptionList: [{ label: 'None', value: 'null' }],
    impositionTemplateList: [],
    allDeviceClassList: [],
    deviceClassList: [],
    deviceOrderList: [],
    paperCodes : [{ label : "ALL", value : "ALL"}]
  })

  const [modelListData, setModelListData] = useState([])

  const launchRunForm = () => {
    setLoadingErrorAndModal(oldState => ({
      ...oldState,
      isModalOpen: true,
      index: '',
      data: {},
      actionType: 'addRun'
    }))
  }

  const handleEdit = (index, data) => {
    setLoadingErrorAndModal(oldState => ({
      ...oldState,
      data,
      index,
      isModalOpen: true,
      actionType: 'editRun'
    }))
  }

  const handleClone = (data) => {
    setLoadingErrorAndModal(oldState => ({
      ...oldState,
      data,
      index: '',
      isModalOpen: true,
      actionType: 'cloneRun'
    }))
  }
  // I had to use the index of the array rather than the id here to allow for deleting newly registered runs that have not yet been saved to the db hence still have an id of ''. Having multiple of such makes using the id not unique.
  const handleDelete = (id, index) => {
    const removedRun = runData.filter(
        (item, i) => item.id === id && i === index
    )
    const otherRuns = runData.filter((item, i) => i !== index)
    const newRunCollection = [...otherRuns, { ...removedRun[0], delete: 1 }]
    setRunData(newRunCollection)
    setLoadingErrorAndModal(oldState => ({
      ...oldState,
      editEnabled: true
    }))
  }

  const handleFetchAllRuns = async () => {
    try {
      const apiResponse = await ZenSmartAPI.get(getAutoRunsInLocationRoute(id))
      const allData = []
      const constructPaperCode = apiResponse.data.paper_codes.map(data => {
        return {label : data, value: data}
      })
      setListData({...listData, paperCodes : constructPaperCode})
      await apiResponse.data.data.forEach(async item => {
        const ruleData = await JSON.parse(item.rule)
        allData.push({
          id: item.id,
          is_running: item.is_running,
          sortorder: item.sortorder,
          location: item.location_id,
          location_id: item.location_id,
          description: item.description,
          active: item.active ? 1 : 0,
          blockType: ruleData.products.blockType,
          fails: ruleData.products.fails,
          imposition: ruleData.products.imposition,
          maximum_type: ruleData.execution.maximum.type,
          maximum_value: ruleData.execution.maximum.value,
          group_by: ruleData.execution.group_by,
          order_by: ruleData.execution.order_by,
          paper_code: ruleData.execution.paper_code ?? "ALL",
          device_class: ruleData.resources.device_class,
          device_order: ruleData.resources.order,
          utilisation_type: ruleData.resources.utilisation.type,
          fixed_value: ruleData.resources.utilisation?.fixed_value,
          utilisation_max_type: ruleData.resources.utilisation.maximum.type,
          utilisation_max_value: ruleData.resources.utilisation.maximum.value,
          split_by: ruleData.resources.split_by ?? '',
          special_option: ruleData?.resources?.special_option ?? 'null',
          trigger_type: ruleData.triggers.type,
          trigger_value: ruleData.triggers.value,
          products: ruleData.products.productCodes,
          trigger_bypass: ruleData.triggers.bypass
        })
      })
      setRunData(allData)
    } catch (error) {
      notification.error({message: 'Error fetching runs'})
    }
  }

  const handleSaveAllChanges = async () => {
    try {
      setLoadingErrorAndModal(oldState => ({
        ...oldState,
        loading: true
      }))
      const apiResponse = await ZenSmartAPI.post(
          updateAutoRunsInLocationRoute,
          runData
      )
      if (apiResponse.data.data === 'ok') {
        await handleFetchAllRuns()
        notification.success({message: 'Changes successfully updated'})
        setLoadingErrorAndModal(oldState => ({
          ...oldState,
          editEnabled: false
        }))
      } else {
        notification.info({message: 'Something went wrong'})
      }
    } catch (error) {
      notification.error({message: error?.response?.data?.message ?? error?.message ?? 'Error updating runs'})
    } finally {
      setLoadingErrorAndModal(oldState => ({
        ...oldState,
        loading: false
      }))
    }
  }

  const transformProduct = async allProducts => {
    let obj = {}
    await allProducts.forEach(item => {
      const hasPrinterClass =
      allDeviceClassList.filter(listItem =>
              item?.available_printer_classes.includes(listItem.class)
          ).length > 0
      if (hasPrinterClass) {
        const primary = item.primary_product_group_name
        const secondary = item.secondary_product_group_name === null ? 'None' : item.secondary_product_group_name
        const tertiary = item.tertiary_product_group_name === null ? 'None' : item.tertiary_product_group_name
        obj[primary] = obj[primary] ? obj[primary] : { [secondary]: {} }

        obj[primary][secondary] = obj[primary][secondary]
                                  ? obj[primary][secondary]
                                  : { [tertiary]: [] }
        obj[primary][secondary][tertiary] = obj[primary][secondary][tertiary]
                                            ? [
              ...obj[primary][secondary][tertiary],
              { title: `${item.name} (${item.code})`, key: item.code }
            ]
                                            : [{ title: `${item.name} (${item.code})`, key: item.code }]
      }
    })
    let arr = []
    // Using TOPLEVEL keyword to be able to filter and remove the top level product code in the array - only the value of last element in the product tree are needed later on
    Object.entries(obj).forEach(item => {
      const first = {
        title: item[0],
        key: `TOPLEVEL${item[0]}`,
        children: []
      }
      Object.entries(item[1]).forEach(item2 => {
        const second = {
          title: item2[0],
          key: `TOPLEVEL${first.key}${item2[0]}`,
          children: []
        }
        Object.entries(item2[1]).forEach(item3 => {
          const third = {
            title: item3[0],
            key: `TOPLEVEL${second.key}${item3[0]}`,
            children: []
          }
          Object.entries(item3[1]).forEach(item4 => {
            third.children.push(item4[1])
          })
          second.children.push(third)
        })
        first.children.push(second)
      })
      arr.push(first)
    })

    return arr
  }

  // Brought this function here so form data can be fetched before a user clicks on the add runs button
  const fetchFormSelectionData = async () => {
    try {
      const apiResponse = await ZenSmartAPI.get(getRunProductsRoute)
      // Had to structure the Object from the database to the pattern required by Ant Design for Tree Input
      const productList = await transformProduct(apiResponse.data.data)
      setListData(oldState => ({
        ...oldState,
        unfilteredProductList: apiResponse.data.data,
        originalFilteredProductList: productList,
        productList: productList
      }))
    } catch (error) {
      notification.error({message: error?.response?.data?.message ??error?.message ?? 'Error fetching product list'})
    }
  }

  const fetchPrinterInformation = async () => {
    try {
      setListData(oldState => ({
        ...oldState,
        unfilteredProductList: [],
        productList: []
      }))
      const apiResponse = await ZenSmartAPI.get(getRunPrintersRoute(id))
      setListData(oldState => ({
        ...oldState,
        allDeviceClassList: Object.values(apiResponse?.data?.data)
      }))
      setAllDeviceClassList(oldState => (Object.values(apiResponse?.data?.data)))
    } catch (error) {
      notification.error({message: error?.response?.data?.message ??error?.message ?? 'Error fetching printer list'})
    }
  }

  // The model data is used for group_by and order_by.
  const fetchModelInformation = async () => {
    try {
      const apiResponse = await ZenSmartAPI.get(getModelRoute)
      const modifiedModelList = []
      await apiResponse.data.data.forEach(item =>
          modifiedModelList.push({ ...item, key: item.value, title: item.label })
      )
      setModelListData(modifiedModelList)
    } catch (error) {
      console.log({ error })
    }
  }

  const handleDiscard = () => {
    window.location.reload()
  }

  useEffect(() => {
    fetchPrinterInformation()
    fetchModelInformation()
    setLoadingErrorAndModal(oldState => ({
      ...oldState,
      editEnabled: false
    }))
  }, [id])

  useEffect(() => {
    if (allDeviceClassList.length > 0) {
      fetchFormSelectionData()
    }
  }, [allDeviceClassList])

  return (
      <div>
        {/* <div> */}
        <Header>
          <Title>
            Machine {'>'} Auto Run ({name})
          </Title>
        </Header>
        <Panel
            title={`Update Autorun Settings here. Contact Support if you're not sure of anything.`}
        >
          {loadingErrorAndModal.loading && (
              <LoaderContainer>
                <Skeleton active />
              </LoaderContainer>
          )}
          <Row hidden={loadingErrorAndModal.loading}>
            <>
              <InnerContainer>
                <CurrentRuns
                    handleFetchAllRuns={handleFetchAllRuns}
                    id={id}
                    handleEdit={handleEdit}
                    handleClone={handleClone}
                    handleDelete={handleDelete}
                    setLoadingErrorAndModal={setLoadingErrorAndModal}
                    setRunData={setRunData}
                    runData={runData}
                    editEnabled={loadingErrorAndModal.editEnabled}
                />
              </InnerContainer>
              <InnerContainer>
                <Logs id={id} />
              </InnerContainer>
            </>
          </Row>
          <KillRun id={id} />

          {userData && userData.permissions.find(p => p === 'create-autorun') &&
          <FloatingButton onClick={launchRunForm}>+</FloatingButton>
          }
          {loadingErrorAndModal.editEnabled && (
              <Floating>
                <Popconfirm
                    placement='top'
                    title={'Are you sure you want to save these changes'}
                    onConfirm={handleSaveAllChanges}
                    okText='Yes'
                    cancelText='No'
                >
                  <Button primary standalone>
                    Save All Changes
                  </Button>
                </Popconfirm>
                <Popconfirm
                    placement='bottom'
                    title={'Are you sure you want to discard these changes'}
                    onConfirm={handleDiscard}
                    okText='Yes'
                    cancelText='No'
                >
                  <Button standalone secondary>
                    Discard Changes
                  </Button>
                </Popconfirm>
              </Floating>
          )}
          {loadingErrorAndModal.isModalOpen && (
              <ModalContainer>
                <AddForm
                    data={loadingErrorAndModal.data}
                    index={loadingErrorAndModal.index}
                    actionType={loadingErrorAndModal.actionType}
                    location={id}
                    setLoadingErrorAndModal={setLoadingErrorAndModal}
                    setRunData={setRunData}
                    listData={listData}
                    setListData={setListData}
                    transformProduct={transformProduct}
                    modelListData={modelListData}
                    allDeviceClassList={allDeviceClassList}
                />
              </ModalContainer>
          )}
        </Panel>
      </div>
  )
}

export default AutoRun
