import React, { useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row, Col, notification, Tag, Collapse, Table } from "antd";
import { color } from "components/zensmart-design-system/shared/styles.js";
import { DetailLookup } from "components/sections";
import Cookies from "js-cookie";
import { dueDateColor } from "./mockData.js";
import _ from "lodash";
import PrintThisComponent from "components/composites/printTicket";
import { useReactToPrint } from "react-to-print";
import { Label } from "components/zensmart-design-system/components/Label/Label.js";
import Frame from 'react-frame-component';
import airhorn from 'static/sounds/airhorn.mp3'
import Image from "components/zensmart-design-system/components/Image/Image"

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;

const BlockDetail = styled.div`
  padding: 10px 30px;
`;
const Container = styled.div`
  padding-bottom: 64px;
  font-size: 10px;
`;

const backButtonProps = {
  size: "large",
};

const BlockHeader = styled.h1`
  text-align: center;
  font-weight: bold;
  color: ${color.heading};
  margin-right: 8px;
  font-size: 15px;
`;
const DueDateTag = styled(Tag)`
  padding: 5px 20px;
  font-size: 12px;
`;
const HeaderContainer = styled.div`
  padding-bottom: 5px;
  font-family: "Rubik", sans-serif;
`;

const StyledTable = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
`;

const ThermalLabelPreview = styled.img`
  position: absolute;
  z-index: -11;
  margin-left: 10px;
`;

const FlexImageWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;

  div {
    padding: 0 5px;
    text-align: center;
    margin-bottom: 5px;
  }
`;

const { Panel } = Collapse;

class ThermalLabelPrint extends React.Component {
  render() {
    return (
      <ThermalLabelPreview
        src={this.props.fileURL}
        alt="no label"
        onLoad={this.props.handleLoad}
      />
    );
  }
}

const Scans = (props) => {
  const { scanData } = props;
  const [inputPlaceholder, setInputPlaceholder] = useState("Please scan your station card");
  const [scanItem, setScanItem] = useState(null);
  const [blockSource, setBlockSource] = useState([]);
  const [blockColumns, setblockColumns] = useState([]);
  const [thermalLabelPNG, setThermalLabelPNG] = useState(null);
  const [htmlToPrint, setHtmlToPrint] = useState(null);
  const thermalLabelPrintComponentRef = useRef();
  const orderDetail = scanItem ? scanItem.page_definitions.sections[0] : null;
  const relatedBlock = scanItem
    ? scanItem.page_definitions.sections.find(
      (val) => val.section_header === "RELATED BLOCKS"
    )
    : null;
  const sound = new Audio(airhorn)

  const handleLookup = useCallback((lookupValue) => {
    console.log(`lookupValue`, lookupValue)
    if (lookupValue.hasOwnProperty("error") && !lookupValue.duplicate_scan_prompt) {
      sound.play()
      notification.error({
        message: lookupValue.error,
      });
    } else if (lookupValue.hasOwnProperty("station_id")) {
      Cookies.set("STATION", lookupValue.station_id, { expires: 1 });
      Cookies.set("SCAN_TYPE", scanData.id, { expires: 1 });
      Cookies.set("ALLOW_QUIZ", lookupValue ?.allow_quiz ?? false, { expires: 1 });
      notification.success({
        message: "Station Scan Successful",
      });
      setInputPlaceholder("Please scan a batch or block");
    } else if (lookupValue.hasOwnProperty("success_scan")) {

      if (lookupValue.batch_tickets) {
        for (let i = 0; i < lookupValue.batch_tickets.length; i++) {
          if (lookupValue.batch_tickets[i].use_osi === true) {
            window.location.assign(lookupValue.batch_tickets[i].osi_path);
          }
        }
      }


      if (lookupValue.use_osi) {
        if(!lookupValue.duplicate_scan_prompt){
          if (lookupValue ?.osi_path_secondary && lookupValue.osi_path_secondary !== "") window.location.href = lookupValue.osi_path_secondary;
          setTimeout(() => { window.location.assign(lookupValue.osi_path); }, 1000);
        }else{
          if(lookupValue.overwrite_osi_false === true){
            if (lookupValue ?.osi_path_secondary && lookupValue.osi_path_secondary !== "") window.location.href = lookupValue.osi_path_secondary;
            setTimeout(() => { window.location.assign(lookupValue.osi_path); }, 1000);
          }
        }
      }

      notification.success({
        message: "Barcode Scan Successful",
      });

      setScanItem(lookupValue);
      setHtmlToPrint(lookupValue.ticket_html);
    } else {
      sound.play()
      notification.error({
        message: "Invalid scan item. Please scan a valid barcode.",
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const removeScanItem = useCallback(() => {
    setScanItem(null);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => thermalLabelPrintComponentRef.current,
  });

  useEffect(() => {
    if (relatedBlock) {
      const blockItemColumns = relatedBlock.table_headers.map((data, key) => {
        return {
          title: data,
          dataIndex: data,
          key: data,
        };
      });

      const blockItemSource = relatedBlock.section_data.map((data, key) => {
        const sourceDataObj = {};
        data.section_data.map((item, key) => {
          return (sourceDataObj[item.label] = item.value);
        });
        return sourceDataObj;
      });
      setBlockSource(blockItemSource);
      setblockColumns(blockItemColumns);
    }
  }, [scanItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const getToUrl = (value) => {
    window.location.href = value;
  };

  const getWarning = (data) => {
    const warningData = data
      ? data.page_definitions.sections.find(
        (val) => val.section_header === data.scan_type
      )
      : null;
    if (warningData) {
      const findWarning = warningData.section_data.find(
        (val) => val.label === "WARNING:"
      );
      if (findWarning) {
        return (
          <p style={{ textAlign: "center", marginBottom: 20 }}>
            <Label status="red">
              {findWarning.label} {findWarning.value}
            </Label>
          </p>
        );
      }
    }
  };

  const PrintFrame = (body) => {
    return (
      <Frame style={{ display: 'none' }} id='iframe' initialContent={body}></Frame>
    )
  }
  useEffect(() => {
    if (htmlToPrint) {
      setTimeout(function () {
        window.frames["iframe"].focus();
        window.frames["iframe"].contentWindow.print();
      }, 1000);

    }
  }, [htmlToPrint])

  return (
    <>
      {thermalLabelPNG !== null && (
        <ThermalLabelPrint
          ref={thermalLabelPrintComponentRef}
          fileURL={thermalLabelPNG}
          handleLoad={() => handlePrint()}
        />
      )}
      <HeaderContainer>
        <Header>
          <Title>
            People {">"} Scan {">"}{" "}
            {_.startCase(scanData.text.split("-").join(" "))}
          </Title>
        </Header>
        <div>
          <DetailLookup
            title={`${scanData.text.toUpperCase().split("-").join(" ")} SCAN`}
            isViewingDetails={scanItem}
            detailLabel={scanItem ? scanItem.message : "PLEASE SCAN ITEM"}
            detailLabelStatus={scanItem ? "mountain_meadow" : "blue"}
            backButtonText="New Scan"
            onLookup={handleLookup}
            onBackButtonClick={removeScanItem}
            inputProps={{ size: "large", placeholder: inputPlaceholder }}
            backButtonProps={backButtonProps}
            scanData={scanData}
            setScanItem={setScanItem}
            setHtmlToPrint={setHtmlToPrint}
          >
            <Container>
              {htmlToPrint && PrintFrame(htmlToPrint)}
              {scanItem ? (
                <>
                  <BlockDetail>
                    {getWarning(scanItem)}
                    <BlockHeader>{orderDetail.section_header}</BlockHeader>
                    <Collapse
                      style={{ marginTop: 20 }}
                      defaultActiveKey={["1"]}
                    >
                      <Panel header="ORDER DETAILS " key="1">
                        <Row>
                          {scanItem.is_print_job_ticket &&
                            scanItem.action === "open" ? (
                              getToUrl(
                                `${scanItem.ticket_html}${localStorage.token}`
                              )
                            ) : (
                              null
                            )}
                          {orderDetail.section_header.includes("Batch") ? (
                            orderDetail.section_data.map((data, key) => {
                              return (
                                <Col span={12} style={{ padding: 5 }}>
                                  <Col span={12}>
                                    <span>{data.label.toUpperCase()}</span>
                                  </Col>
                                  <Col span={12}>
                                    {data.label === "Due Date:" ? (
                                      <>
                                        <DueDateTag
                                          color={
                                            dueDateColor[
                                              data.value.due_date_text.toLowerCase()
                                            ]
                                              ? dueDateColor[
                                              data.value.due_date_text.toLowerCase()
                                              ]
                                              : "red"
                                          }
                                          key={data.value.due_date_text}
                                        >
                                          {data.value.due_date_text.toUpperCase()}
                                        </DueDateTag>
                                        <span>
                                          {" "}
                                          {data.value.formatted_due_date}{" "}
                                        </span>
                                      </>
                                    ) : data.label === "ticket_html" ? (
                                      <PrintThisComponent
                                        url={data.value.file_url}
                                        width={data.value.width}
                                        height={data.value.height}
                                      />
                                    ) : (
                                          <span> {data.value} </span>
                                        )}
                                  </Col>
                                </Col>
                              );
                            })
                          ) : (
                              <>
                                <Col span={12} style={{ padding: 5 }}>
                                  {orderDetail.section_data.map((data, key) => {
                                    return (
                                      <>
                                        <Col span={12} style={{ padding: 5 }}>
                                          <span>{data.label.toUpperCase()}</span>
                                        </Col>
                                        <Col span={12} style={{ padding: 5 }}>
                                          {data.label === "Due Date:" ? (
                                            <>
                                              <DueDateTag
                                                color={
                                                  dueDateColor[
                                                    data.value.due_date_text.toLowerCase()
                                                  ]
                                                    ? dueDateColor[
                                                    data.value.due_date_text.toLowerCase()
                                                    ]
                                                    : "red"
                                                }
                                                key={data.value.due_date_text}
                                              >
                                                {data.value.due_date_text.toUpperCase()}
                                              </DueDateTag>
                                              <span>
                                                {" "}
                                                {
                                                  data.value.formatted_due_date
                                                }{" "}
                                              </span>
                                            </>
                                          ) : (
                                              <span> {data.value} </span>
                                            )}
                                        </Col>
                                      </>
                                    );
                                  })}
                                </Col>
                                <Col span={12}>
                                  <FlexImageWrapper>
                                    {orderDetail.thumbnails.front && (
                                      <Image
                                        src={orderDetail.thumbnails.front}
                                        alt=""
                                        width={160}
                                        height={300}
                                        objectFit="fill"
                                      />
                                    )}
                                    {orderDetail.thumbnails.back && (
                                      <Image
                                        src={orderDetail.thumbnails.back}
                                        alt=""
                                        width={160}
                                        height={300}
                                        objectFit="fill"
                                      />
                                    )}
                                  </FlexImageWrapper>
                                </Col>
                              </>
                            )}
                        </Row>
                      </Panel>
                    </Collapse>
                  </BlockDetail>
                  {relatedBlock && (
                    <BlockDetail>
                      <Collapse defaultActiveKey={["1"]}>
                        <Panel header={relatedBlock.section_header} key="1">
                          <StyledTable
                            columns={blockColumns}
                            dataSource={blockSource}
                          />
                        </Panel>
                      </Collapse>
                    </BlockDetail>
                  )}
                </>
              ) : null}
            </Container>
          </DetailLookup>
        </div>
      </HeaderContainer>
    </>
  );
};

export default Scans;
