import { GET_DISPATCH_LINES, GET_DISPATCH_STRUCTURE_LINES } from "../actions/dispatchTypes";

const initialState = {
    dispatch_items: null,
    
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DISPATCH_LINES:
      return {
        ...state,
        dispatch_items: action.payload
      };
    default:
      return state;
  }
}