import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, Spin, Select, Button, Popconfirm, notification } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { ZenSmartAPI } from 'utils'
import { fetchAutoRunOverviewInitData, updateAutoRunOverviewInitData } from "utils/apiRoutes"
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import _ from "lodash"

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 14px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DivStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
  h2 {
    font-weight: 700;
    font-family: 'Rubik', sans-serif;
  }
  .ant-input-affix-wrapper
  .ant-input {
      border-color : blue;
  }

  .ant-select-selection--multiple{
      border-color : blue;
  }
  
  .ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}
.ant-select-lg .ant-select-selection__rendered {
    color : blue;
}
`
const AutoRunPrinterOverview = ({ match }) => {
    const { name, id } = match.params

    const [loading, setLoading] = useState(false);
    const [runs, setRuns] = useState([])
    const [originalPrinterRunsData, setOriginalPrinterRunsData] = useState([])
    const [printerRunsData, setPrinterRunsData] = useState([])


    const fetchInitData = () => {
        setLoading(true)
        ZenSmartAPI.get(fetchAutoRunOverviewInitData(id))
            .then((res) => {
                setLoading(false)
                setOriginalPrinterRunsData(res.data.printers)
                setRuns(res.data.runs)
                setPrinterRunsData(res.data.printers)
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant fetch configuration init data!")

            })
    }

    const saveChanges = () => {
        setLoading(true)
        ZenSmartAPI.put(updateAutoRunOverviewInitData, { data: printerRunsData })
            .then((res) => {
                setLoading(false)
                notification.success({
                    message: 'Succesfully saved changes!',
                });
                fetchInitData()
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant update data!")

            })
    }

    const updateRunData = (id, value) => {
        setPrinterRunsData(prevVariable => {
            return prevVariable.map(item => {
                if (item.id === id) {
                    return { ...item, run_ids: value };
                }
                return item;
            });
        });
    }

    useEffect(() => {
        fetchInitData()
    }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <DivStyle />
            <Header>
                <Title>Machine {">"} Auto Run Printer Overview ({name})</Title>
            </Header>
            <Spin tip="Loading..." spinning={loading}>
                <Panel title="Auto Run Printer Overview">
                    <div style={{ padding: "20px 40px" }}>

                        {printerRunsData.map(data => {
                            return <Row type="flex" justify="start" align="middle" style={{ padding: "10px 20px" }}>
                                <Col span="6" style={{ padding: 5 }}>
                                    <Heading>{data.printer_name}</Heading>
                                </Col>
                                <Col span="18" style={{ padding: 5 }}>
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        value={data.run_ids}
                                        onChange={(value) => updateRunData(data.id, value)}
                                        allowClear
                                        mode="multiple"

                                    >
                                        {runs.map(runData =>
                                            <Select.Option value={runData.id}>{runData.name}</Select.Option>
                                        )}
                                    </Select>
                                </Col>

                            </Row>
                        })}
                        {printerRunsData.length > 0 ?
                            <Row Row type="flex" justify="end" style={{ padding: "10px 0px", marginRight: "-10px" }}>
                                <Col span={3} style={{ padding: 5 }}>
                                    <Button
                                        disabled={_.isEqual(originalPrinterRunsData, printerRunsData)}
                                        className="Header-button"
                                        type="primary"
                                        style={{ width: "100%" }}
                                        onClick={saveChanges}
                                    >
                                        Save Changes
                                    </Button>
                                </Col>

                                <Col span={3} style={{ padding: "5px 5px 0px 0px" }}>
                                    <Popconfirm
                                        placement='top'
                                        title={'Are you sure you want to cancel your changes?'}
                                        onConfirm={() => setPrinterRunsData(originalPrinterRunsData)}
                                        okText='Yes'
                                        cancelText='No'
                                        disabled={_.isEqual(originalPrinterRunsData, printerRunsData)}

                                    >
                                        <Button
                                            className="Header-button"
                                            type=""
                                            style={{ width: "100%" }}
                                        >
                                            Cancel Changes
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                            : null}
                    </div>

                </Panel>
            </Spin >
        </>
    )
}

export default AutoRunPrinterOverview