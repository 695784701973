import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, notification, Tag } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import Cookies from 'js-cookie';
import PreDispatchLookup from './preDispatchLookUp'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js'
import { dueDateColor } from './mockData';
import PrintThisComponent from "components/composites/printTicket"
import Frame from 'react-frame-component';
import airhorn from 'static/sounds/airhorn.mp3'

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const Container = styled.div`
  padding-bottom: 5px;
  font-family: 'Rubik', sans-serif;
`
const scanInputProps = {
    size: 'large',
    placeholder: 'Item Lookup'
}

const Bold = styled.span`
  font-weight: bold;
  margin-right: 8px;
  color: ${color.heading};
`
const DueDateTag = styled(Tag)`
padding: 5px 20px;
font-size : 15px;
`

const Image = styled.img`
  height: 12rem;
  margin-bottom: 5px;
  padding-right: 5px;
`
const FlexImageWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;
  div {
    padding: 0 5px;
    text-align: center;
    margin-bottom: 5px;
  }
`;
const ImageLabel = styled.p`
  text-transform: uppercase;
  text-align: center;
  `;

const dataProps = { fontSize: 15, padding: 5 };
const quantityStyle = { fontSize: 20 };

const MatchIn = (props) => {
    const [scanItem, setScanItem] = useState(null)
    const [htmlToPrint, setHtmlToPrint] = useState(null);
    const { scanData } = props
    const scanTypeId = scanData.id;
    const sound = new Audio(airhorn)

    const handleLookup = useCallback((lookupValue) => {

        if (lookupValue.hasOwnProperty('error') && !lookupValue.duplicate_scan_prompt) {
            sound.play()
            notification.error({
                message: lookupValue.error
            })
        }

        else if (lookupValue.hasOwnProperty('station_id')) {
            Cookies.set('STATION', lookupValue.station_id, { expires: 1 });
            Cookies.set('SCAN_TYPE', scanTypeId, { expires: 1 });
            Cookies.set("ALLOW_QUIZ", lookupValue?.allow_quiz ?? false, { expires: 1 });
            notification.success({
                message: 'Station Scan Successful'
            })
        }

        else if (lookupValue.hasOwnProperty('success_scan')) {
            if (lookupValue.use_osi) {
                window.location.assign(lookupValue.osi_path);
            }

            if (lookupValue.popup_message) {
                notification.success({
                    message: 'Barcode Scan Successful!',
                    description: `${lookupValue.popup_message}`,
                    duration: 3
                })
            }
            else {
                notification.success({
                    message: 'Barcode Scan Successful!'
                })
            }
            setScanItem(lookupValue)
            setHtmlToPrint(lookupValue.ticket_html)

        }

        else {
            sound.play()
            notification.error({
                message: 'Invalid scan item. Please scan a valid barcode.'
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const removeScanItem = useCallback(() => {
        setScanItem(null)
    }, [])

    const getToUrl = (value) => {
        window.location.href = value
    }

    const PrintFrame = (body) => {
        return (
            <Frame style={{ display: 'none' }} id='iframe' initialContent={body}></Frame>
        )
    }

    const allignImages = images => {
        var newAlignMent = {
            'cover_front':images.cover_front,
            'text_front':images.text_front, 
            'text_back':images.text_back,
        }
        return Object.entries(newAlignMent)
    }
    
    useEffect(() => {
        if (htmlToPrint) {
            setTimeout(function () {
                window.frames["iframe"].focus();
                window.frames["iframe"].contentWindow.print();
            }, 1000);

        }
    }, [htmlToPrint])

    const updateDataSection = (data) => {
        const newOrder = data.map((viewing) =>
            <Col span={24} style={dataProps} >
                { displayForm(viewing) }
            </Col>
        )
        return newOrder
    }

    const displayForm = (data) => {
        if (data.label === "Due Date:") {
            return <>
                <Bold>{data.label}</Bold>
                <DueDateTag color={dueDateColor[data.value.due_date_text.toLowerCase()] ?
                                   dueDateColor[data.value.due_date_text.toLowerCase()] : "red"}
                            key={data.value.due_date_text}>
                    {data.value.due_date_text.toUpperCase()}
                </DueDateTag>
                <span> {data.value.formatted_due_date} </span>
            </>
        } else if (data.label === 'Block Files' && Array.isArray(data.value)) {
            let blockFileData = data.value.map((dataValue) => (
                <a href={dataValue.link} style={{color: 'blue'}} target="_blank"
                   rel="noopener noreferrer"> {dataValue.value}</a>
            ));
            return <>
                <Bold>{data.label}</Bold>
                <span>{blockFileData}</span>
            </>;

        } else {
            return <>
                <Bold>{data.label}</Bold>
                <span> {data.value} </span>
            </>
        }
    }

    return (
        <>
            <Container>
                <Header>
                    <Title>People {">"} Scans {">"} Pre Dispatch</Title>
                </Header>
                <div>
                    <PreDispatchLookup
                        title="PRE DISPATCH SCAN"
                        isViewingDetails={scanItem}
                        setScanItem={removeScanItem}
                        detailLabel={'PLEASE SCAN ITEM'}
                        onLookup={handleLookup}
                        inputProps={scanInputProps}
                        scanTypeId={scanTypeId}
                        setHtmlToPrint={setHtmlToPrint}

                    >
                        {htmlToPrint && PrintFrame(htmlToPrint)}

                        {scanItem ?
                         <Col span={24}>
                             <Col span={24}>
                                 <Label status="mountain_meadow">
                                     SCAN SUCCESSFUL
                                 </Label>
                             </Col>
                             <Col span={24} style={dataProps} >
                                 <br />
                             </Col>
                             <Row>
                                {scanItem.is_print_job_ticket &&
                                scanItem.action === "open" ? getToUrl(`${scanItem.ticket_html}${localStorage.token}`)
                                                            : null

                                }
                                {
                                    updateDataSection(scanItem.page_definitions.sections[0].section_data)
                                }
                                <div>
                                    <FlexImageWrapper>
                                        {scanItem.page_definitions.sections[0]?.previews &&
                                        allignImages(scanItem.page_definitions.sections[0].previews).map(item =>
                                            item[1] !== null &&  (
                                                <Col>
                                                    <Image src={item[1]} alt={item[0]}  />
                                                    <ImageLabel>{item[0].replace('_', ' ')}</ImageLabel>
                                                </Col>
                                            )
                                        )}
                                    </FlexImageWrapper>
                                </div>
                             </Row>
                         </Col> : null}
                    </PreDispatchLookup>
                </div>
            </Container>

        </>
    )
}

export default MatchIn
