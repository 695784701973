import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from '../../../../components/zensmart-design-system';
import { Menu, Icon as AIcon, Dropdown } from 'antd';

const PreviewRowStyle = styled.div`
background-color: rgb(207,217,249,0.2);
padding: 8px 60px;
display: flex;
h2 {
  text-transform: uppercase;
  color: #B0BAC9;
  margin-right: 41px;
  margin-bottom: 0;
}
p {
  color: #0B6BF2;
  margin-bottom: 0;
  margin-right: 24px;
  span {
    font-weight: bolder;
    svg {
      margin-top: 5px;
    }
  }
}
.edit_matrix {
  margin-left: 5px;
}
`

const PreviewRow = ({
  hideDetails,
  setHideDetails,
  hideDropZones,
  setHideDropZones,
  visualizationOptions = [],
  visualization = '',
  setVisualization,
  groups = [],
  addChart,
  editMatrix,
  displayMatrixOptions = false,
  tightTable,
  setTightTable,
  hideAddChart=false
}) => {
  const showMenu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => setHideDetails(!hideDetails)}>
          Details
          {
            !hideDetails && (<AIcon type='check' />)
          }
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => setHideDropZones(!hideDropZones)}>
          Drop zones
          {
            !hideDropZones && (<AIcon type='check' />)
          }
        </div>
      </Menu.Item>
    </Menu>
  )

  const showVisualization = (
    <Menu>
      {visualizationOptions.map(option => (
        <Menu.Item key={option}>
          <div onClick={() => setVisualization(option)}>
            {option}
            {
              (option === visualization) && (<AIcon type='check' />)
            }
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )
  const showTightTable = (
    <Menu>
      <Menu.Item>
        <div onClick={() => setTightTable(true)}>
          Compact
          {
            (tightTable === true) && (<AIcon type='check' />)
          }
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => setTightTable(false)}>
          Loose
          {
            (tightTable === false) && (<AIcon type='check' />)
          }
        </div>
      </Menu.Item>
    </Menu>
  )
  return (
    <PreviewRowStyle>
      <h2>Preview</h2>
      <Dropdown overlay={showVisualization} placement="bottomLeft">
        <p>Visualization: <span style={{ textTransform: 'capitalize', fontWeight: 'normal' }}>{visualization}</span> <span><Icon icon='down' width='10px' height='10px' /></span></p>
      </Dropdown>
      <Dropdown overlay={showMenu} placement="bottomLeft">
        <p>Show <span><Icon icon='down' width='10px' height='10px' /></span></p>
      </Dropdown>
      <Dropdown overlay={showTightTable} placement="bottomLeft">
        <p>Table <span><Icon icon='down' width='10px' height='10px' /></span></p>
      </Dropdown>
      {groups.length > 0 && visualization !== "tabular" && !hideAddChart && (
        <Button size='small' appearance='ghost' onClick={() => addChart()}>Add Chart</Button>
      )}
      {displayMatrixOptions && (
        <Button size='small' appearance='ghost' className='edit_matrix' onClick={() => editMatrix()}>Edit Matrix</Button>
      )}
    </PreviewRowStyle>
  )
}

export default PreviewRow