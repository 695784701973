import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Checkbox, notification, Select, DatePicker } from 'antd';
import { multicutRuleRoute, modelDefinitionRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'
import debounce from 'lodash/debounce';
import { customDateInputs } from 'utils/customSelectOptionsInput'
import QualifierInput
    from '../../../components/composites/QualifierInput';
import _ from "lodash"
import moment from 'moment';
import { errorNoticationHandler } from 'utils/errorMessageHandler';
function UpdateForm(props) {
    const {
        form,
        updateMultiCutRuleModal,
        setUpdateMultiCutRuleModal,
        fetchParams,
        fetchData,
        formInitialValue,
        setFormInitialValue,
        multicutModalName,
        setRuleModalName,
        multicutInitData,
        fieldsValue,
        setValueField,
        fieldType,
        setFieldType
    } = props
    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields,
    } = form
    const [loading, setLoading] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            const payload = getFieldsValue();
            if (!err) {
                setLoading(true)
                if (payload.qualifier.includes('datetime:custom')) {
                    payload.qualifier = `datetime:custom,${moment(payload.from).format('YYYY-MM-DD')},${moment(payload.to).format('YYYY-MM-DD')}`
                    delete payload.from;
                    delete payload.to;
                }
                const api = formInitialValue.id ? ZenSmartAPI.patch(`${multicutRuleRoute}/${formInitialValue.id}`, payload) : ZenSmartAPI.post(multicutRuleRoute, payload)
                api.then((res) => {
                    notification.success({ message: formInitialValue.id ? "Update multicut rule successful!" : "Add new multicut rule successful!" })
                    setLoading(false);
                    fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                    handleCancel()
                })
                    .catch((res) => {
                        setLoading(false);
                        errorNoticationHandler(res, "Something went wrong!")
                    })
            }
        });
    };
    const handleCancel = () => {
        setUpdateMultiCutRuleModal(false)
        setFormInitialValue({})
        setRuleModalName("")
    }
    const onSearchField = debounce(value => {
        ZenSmartAPI.get(modelDefinitionRoute(`lines.${value}`), {})
            .then((res) => {
                setValueField(res.data.data)
            })
    }, 600);
    const fieldsComponent = (item) => {
        return (
            <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Enter Field (Click Suggestions to add each field)"
                optionFilterProp="children"
                onSearch={onSearchField}
                onChange={value => updateFieldValueType(value)}
            >
                {fieldsValue.map(data =>
                    <Select.Option value={data.id}>{data.label.replace('lines.', '')}</Select.Option>
                )}
            </Select>
        )
    }
    const updateFieldValueType = (value, key) => {
        const getType = fieldsValue.find(data => data.id === value)?.field_type;
        setFieldType(getType);
    }
    useEffect(() => {
        if (formInitialValue.id) {
            updateFieldValueType(formInitialValue.model_definition_id)
        }
    }, [formInitialValue]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div>
            <Modal title={multicutModalName} visible={updateMultiCutRuleModal} footer={[
                <Button
                    key="back"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button key="submit"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    Save
                </Button>,
            ]}>
                {updateMultiCutRuleModal &&
                    <Form layout="vertical" onSubmit={handleSubmit}>
                        <Form.Item label="Fields (Line Level):"
                        >
                            {getFieldDecorator(`model_definition_id`, {
                                rules: [{
                                    required: true,
                                    message: "Please select a field"
                                }],
                                initialValue: formInitialValue.model_definition_id ? formInitialValue.model_definition_id : undefined
                            }
                            )(
                                fieldsComponent()
                            )}
                        </Form.Item>
                        <Form.Item label="Qualifier:"
                        >
                            {getFieldDecorator(`qualifier`, {
                                rules: [{
                                    required: true,
                                    pattern: new RegExp(/^$|.*\S+.*/gm),
                                    message: "Please select qualifier",
                                }],
                                initialValue: (fieldType === "datetime" || fieldType === "date") && _.get(formInitialValue, 'qualifier', "").includes('datetime') ?
                                    _.get(formInitialValue, 'qualifier', "").split(',')[0] : formInitialValue.qualifier ? formInitialValue.qualifier : undefined
                            }
                            )(
                                fieldType === "datetime" || fieldType === "date" ? customDateInputs(undefined, null, 'datetime:') :
                                    <QualifierInput
                                        value={_.get(getFieldsValue(), 'qualifier', undefined)}
                                        types={multicutInitData.qualifiers} />
                            )}
                        </Form.Item>
                        {(fieldType === "datetime" || fieldType === "date") && getFieldsValue().qualifier && getFieldsValue().qualifier.includes("datetime:custom") ?
                            <>
                                <Form.Item label="From:"
                                >
                                    {getFieldDecorator(`from`, {
                                        rules: [{
                                            required: true,
                                        }],
                                        initialValue: _.get(formInitialValue, 'qualifier', "").includes('custom') ? moment(_.get(formInitialValue, 'qualifier', "").split(',')[1]) : undefined
                                    }
                                    )(
                                        <DatePicker style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                                <Form.Item label="To:"
                                >
                                    {getFieldDecorator(`to`, {
                                        rules: [{
                                            required: true,
                                        }],
                                        initialValue: _.get(formInitialValue, 'qualifier', "").includes('custom') ? moment(_.get(formInitialValue, 'qualifier', "").split(',')[2]) : undefined
                                    }
                                    )(
                                        <DatePicker style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </>
                            : null
                        }
                        <Form.Item label="Turn on:"
                        >
                            {getFieldDecorator(`active`, {
                                initialValue: formInitialValue.active ? formInitialValue.active : false,
                                valuePropName: "checked",
                            }
                            )(
                                <Checkbox />
                            )}
                        </Form.Item>
                    </Form>
                }
            </Modal>
        </div>
    )
}
const MultiCutForm = Form.create({
})(UpdateForm)
const MultiCutRuleUpdateModal = (props) => {
    const { updateMultiCutRuleModal, multicutInitData, setUpdateMultiCutRuleModal, fetchData,
        fetchParams, formInitialValue, setFormInitialValue, multicutModalName, setRuleModalName, fieldsValue, setValueField, fieldType, setFieldType } = props
    return (
        <div>
            <MultiCutForm
                updateMultiCutRuleModal={updateMultiCutRuleModal}
                multicutInitData={multicutInitData}
                setFormInitialValue={setFormInitialValue}
                formInitialValue={formInitialValue}
                setUpdateMultiCutRuleModal={setUpdateMultiCutRuleModal}
                fetchData={fetchData}
                fetchParams={fetchParams}
                multicutModalName={multicutModalName}
                setRuleModalName={setRuleModalName}
                fieldsValue={fieldsValue}
                setValueField={setValueField}
                fieldType={fieldType}
                setFieldType={setFieldType}
            />
        </div>
    )
}
export default MultiCutRuleUpdateModal