import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { color } from 'components/zensmart-design-system/shared/styles.js'

const Arrow = styled.div`
  position: relative;
  height: ${props => props.height};
  margin: 0 auto;
  border-left: 2px solid ${color.nav.unselected};
  margin-top: 18px; // height of dropzone + 3px

  &:before {
    content: '';
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 12px solid ${color.nav.unselected};
    position: absolute;
    right: -7px;
    top: -16px;
  }
`

function SiblingChildArrow({height, children}) {
  const [arrowHeight, setArrowHeight] = useState(0)
  const heightRef = useRef()

  useEffect(() => {
    if (height === 'auto') {
      let h = 0
      let count = 1
      if (children.length === 1) {
        setArrowHeight('36px')
      } else if (children.length === 2) {
        h = children.length * 64.8
        setArrowHeight(`${h}px`)
      } else if (children.length === 3) {
        h = (children.length * 64) + 32
        setArrowHeight(`${h}px`)
      } else {
        count = count + 1
        h = (children.length * 64) + (count * 32)
        setArrowHeight(`${h}px`)
      }
    } else {
      setArrowHeight(height)
    }
  },[height, children.length])

  return <Arrow ref={heightRef} height={arrowHeight} />
}

export default SiblingChildArrow