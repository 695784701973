import * as actionTypes from "../actions/actionTypes";

const initialState = {
  productOptions: [],
  imposition_design_data: {},
  output_data: null,
}

const saveModifiedReduxState = (state, payload) => {
  const modifiedState = { ...state, ...payload, };
  return modifiedState;
};

const saveProductOptions = (state, action) => {
  return saveModifiedReduxState(state, { productOptions: action.productOptions });
};
const saveOutputFormat = (state, action) => {
  return saveModifiedReduxState(state, { output_data: action.data });
};
const saveImpositionDesignData = (state, action) => {
  return saveModifiedReduxState(state, { imposition_design_data: action.data });
};


const productList = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PRODUCT_OPTIONS:
      return saveProductOptions(state, action);
    case actionTypes.SAVE_OUTPUT_FORMAT:
      return saveOutputFormat(state, action);
    case actionTypes.SAVE_IMPOSITION_DESIGN_DATA:
      return saveImpositionDesignData(state, action);
    default:
      return state;
  }
};

export default productList;