import React, { useEffect, useCallback, useState } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { message, notification } from 'antd'

import { UserThunks, UserActions } from 'store/slices/userSlice'
import { ZenSmartAPI, tokenExpired } from 'utils'

import { Dashboard } from '../templates'
import { AuthForm } from '../sections'
import { forgotPasswordRoute, getHelpLinkSettingRoute } from "utils/apiRoutes"

async function requestPasswordChange(payload, form) {
  form.submitting()

  try {
    await ZenSmartAPI.post(forgotPasswordRoute, payload)
    form.finished({
      message: 'Password Reset Requested',
      description: 'Please check the email you\'ve provided' +
        ' for further instructions to reset your account\'s password.'
    })
  } catch (e) {

    if (e && e.response && e.response.status === 422) {
      form.failed({
        message: 'No Email Given',
        description: 'An email address is needed for resetting' +
          ' your account\'s password.'
      })
      return
    }

    form.failed({
      message: 'Unexpected error',
      description: 'Something went wrong. Please contact support for more details.'
    })
  }
}

const getUserState = state => state.user

function Protected(props) {
  const { children, ...route } = props
  const user = useSelector(getUserState)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [helpLink, setHelpLink] = useState('#')

  const getHelpLink = () => {
    ZenSmartAPI.get(getHelpLinkSettingRoute)
    .then((res) => {
      const link = res.data?.data || '#'
      setHelpLink(link)
    })
    .catch((err) => { })
  }

  /**
   * Assign ZenSmart Support Portal URL
   */
  useEffect(() => {
    getHelpLink()
    // eslint-disable-next-line
  }, [])

  /**
   * Side-effect that logs out the user everytime they
   * change routes.
   */
  useEffect(() => {
    if (user.authenticated && tokenExpired()) {
      notification.error({
        message: 'Session Expired. Please sign in again.'
      })
      dispatch(UserActions.logout())
    }
    // eslint-disable-next-line
  })

  /**
   * Side-effect that gets the users details whenever they are
   * authenticated.
   */
  useEffect(() => {
    if (user.authenticated) {
      dispatch(UserThunks.getUserDetails())

      if (location.pathname === "/app"){
        history.push("/app/home")
      }
    }
    // eslint-disable-next-line
  }, [user.authenticated])

  const authenticate = useCallback((credentials, form) => {
    dispatch(UserThunks.authenticate(credentials, form))
    // eslint-disable-next-line
  }, [])

  const logout = useCallback(() => {
    dispatch(UserActions.logout())
    // eslint-disable-next-line
  }, [])

  const content = user.authenticated ? children : (
    <AuthForm
      onSignIn={authenticate}
      onResetPassword={requestPasswordChange}
    />
  )

  return (
    <Route {...route}>
      <Dashboard user={user} logout={logout} helpLink={helpLink}>
        <Switch>{content}</Switch>
      </Dashboard>
    </Route>
  )
}

export default Protected