import React, {
    useState, useEffect
} from 'react'
import { notification, Modal } from 'antd'
import { ZenSmartAPI } from 'utils'
import { deleteConsignmentRoute } from "utils/apiRoutes"
import { DataListPage } from "pages/DataListPage"
import * as _ from 'lodash';
import { useSelector } from 'react-redux'


const DispatchPanel = (props) => {
    const [showDatalist, setShowDataList] = useState(false)
    const [enableReprintLabel, setEnableReprintLabel] = useState(false)
    const [enableDeleteConsignment, setEnableDeleteConsignment] = useState(false)
    const {setPrintDispatch, setShowPrintDispatch} = props;
    const userData = useSelector(state => state.user)
    
    const focusInput = () => {
        const getInput = document.getElementById("LookupForm_lookup_text");
        if (getInput) {
            getInput.focus();
            getInput.select();
        }
    }

    const deleteConsignment = (data, refreshData) => {
        Modal.confirm({
            'content': "Are you sure you want to delete this consignment?",
            onOk: () => {
                ZenSmartAPI.delete(deleteConsignmentRoute(data.id))
                    .then((res) => {
                        focusInput()
                        notification.success({ message: "Delete consignment successful!" })
                    })
                    .catch((res) => {
                        notification.error({ message: "Delete consignment not successful!" })
                    })
            },
        });

    }

    const rePrintConsignment = (data) => {
        Modal.confirm({
            'content': "Are you sure you want to re-print this consignment?",
            onOk: () => {
                if (_.isObject(data) && data.label.use_osi) {
                    if (data.label?.osi_path_secondary && data.label.osi_path_secondary !== "") window.location.href = data.label.osi_path_secondary;
                    setTimeout(() => { window.location.assign(data.label.osi_path) }, 1000);
                }

                if (_.isObject(data) && data.label.type === 'HTML') {
                    setPrintDispatch({
                        label: {
                            content: data.label.content,
                            type: 'html'
                        }
                    })
                    setShowPrintDispatch(true);
                    setTimeout(() => {
                        setShowPrintDispatch(false);
                    })
                }

                //fetchData()
                focusInput()
                notification.success({ message: "Re-print consignment successful!" })
            },
        });

    }

    const rePrintInvoice = (data) => {

        if(!_.get(data, 'invoice.osi_path')) {
            notification.warning({ message: "No invoice available for this consignment!" });
            return;
        }

        Modal.confirm({
            'content': "Are you sure you want to re-print the invoice for this consignment?",
            onOk: () => {
                if (_.get(data, 'invoice.osi_path')) {
                    setTimeout(() => {
                        window.location.assign(data.invoice.osi_path);
                        notification.success({ message: "Invoice re-printed consignment successful!" })
                    }, 1000);
                }

                focusInput()
            },
        });

    }

    const permissionChecker = (neededPermission, callbackMethod) => {
        if(userData.details){
            var permissions = userData.details.permissions;
    
            if(permissions && !permissions.includes(neededPermission)){
                callbackMethod(false);
            }else if(permissions && permissions.includes(neededPermission)){
                callbackMethod(true);
            }else{
                callbackMethod(false);
            }
        }
    }

    useEffect(() => {
        permissionChecker('reprint-shipping-labels', setEnableReprintLabel);
        permissionChecker('delete-consignments', setEnableDeleteConsignment);

        if(props.hasCookie){
            setShowDataList(true);   
            const interval = setInterval(() => {
                setShowDataList(true)
            }, 20000);
            return () => clearInterval(interval);
        } else {
            setShowDataList(false);
        }
    }, [props.hasCookie]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {showDatalist &&
                <DataListPage model="consignment" queries={[{"column": "station_id","operator": "equals","value": parseInt(props.hasCookie)}]} hidden={['id', 'reprint_path', 'label', 'invoice']}
                 select={['transactions', 'line_id', 'consignment_number', 'provider', 'address']} link={"/"} pageSize={10} refreshDataInterval={30000}
                    actionButtons={
                        [{
                            buttonLabel: "Delete",
                            buttonType: "danger",
                            buttonAction: "normal",
                            actionFunction: deleteConsignment,
                            hidden: !enableDeleteConsignment,
                            reloadData: true
                        },
                        {
                            buttonLabel: "Re-print Label",
                            buttonType: "primary",
                            buttonAction: "normal",
                            actionFunction: rePrintConsignment,
                            hidden: !enableReprintLabel,
                            reloadData: true
                        },
                        {
                            buttonLabel: "Re-print Invoice",
                            buttonType: "primary",
                            buttonAction: "normal",
                            actionFunction: rePrintInvoice,
                            hidden: !enableReprintLabel,
                            reloadData: true
                        },
                        ]
                    }
                />
            }

        </>
    )
}

export default DispatchPanel