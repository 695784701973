export const clearBreadcrumb = () => {
    return {
        type: 'CLEAR_BREADCRUMB',
        breadcrumb: []
    }
  }
  
export const addBreadcrumb = breadcrumb => {
  return {
    type: 'ADD_BREADCRUMB',
    breadcrumb: {
      id: breadcrumb.id,
      name: breadcrumb.name,
      resource_id: breadcrumb.resource_id,
      link: breadcrumb.link
    }
  };
};

export const deleteBreadcrumbs = index => {
    return {
        type: 'DELETE_BREADCRUMBS',
        index: index
    }
}