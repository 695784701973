import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, Input } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenSmartAPI } from 'utils'
import { getCheckoutRoute } from "utils/apiRoutes"
import { checkoutInitialData } from "utils/panelsInitialData"

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const CheckOutPage = (props) => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [ascend, setAscend] = useState(checkoutInitialData)

    const fetchData = () => {
        setLoading(true)
        ZenSmartAPI.get(getCheckoutRoute)
            .then((res) => {
                setLoading(false)
                setItemData(res.data)
                setOriginalData(res.data)
                setAscend(checkoutInitialData)
                console.log('res', res);
            })
            .catch((res) => {
                setLoading(false)
            })

    }

    const searchOnArray = (anItemValue, value) => {
        const ifValueExist = anItemValue.some(data => {
            return data.value.toString().toLowerCase().includes(value.toLowerCase())
        })

        return ifValueExist;
    }

    const searchOperation = async (value, source) => {
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                            false :
                            Array.isArray(anItemValue) ? searchOnArray(anItemValue, value) :
                                anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) :
                                    anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
                return containsValue
            })
            setItemData(result)
        }
    }

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Process Center {">"} Checked Out</Title>
            </Header>
            <Panel title="Checked Out">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <Row type="flex" align="middle">
                        <InputStyle />
                        <Heading>Search :</Heading>
                        <Col style={{ padding: 10 }}>
                            <Input.Search
                                type="text"
                                placeholder="Filters.."
                                disabled={loading}
                                size="large"
                                onChange={(value) => searchOperation(value.target.value, originalData)}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </div>
                <TableData itemData={itemData} loading={loading} setLoading={setLoading} data={data} setData={setData} ascend={ascend} setAscend={setAscend} fetchData={fetchData} />
            </Panel>
        </>
    )
}

export default CheckOutPage