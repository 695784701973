import React from 'react'
import { createGlobalStyle } from 'styled-components'
import styled from 'styled-components'

import { Table, Button, Modal, Checkbox, Input, Form, notification } from 'antd'
import { ZenSmartAPI } from 'utils'
import axios from 'axios'

const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    font-family: 'Rubik', sans-serif;
}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 0px;
}
.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fbfbfb;
}
`

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
  font-weight: 500;
`
const StyledCheckbox = styled(Checkbox)`
  margin-left: 0px !important;
  display: block;import { ZenSmartAPI } from 'utils';

`

const TableData = ({
                       loading,
                       data,
                       pagination,
                       modalValues,
                       setModalValues,
                       setReloadPage
                   }) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'barcode'
        },
        {
            title: 'Name',
            dataIndex: 'client_name'
        },
        {
            title: 'Email',
            dataIndex: 'client_email'
        },
        {
            title: 'Phone Number',
            dataIndex: 'client_mobile'
        },
        {
            title: 'Order Number',
            dataIndex: 'order_id',
            render: (text, record) => (
                <>
                    <a href={`/app/data-view/orders/${record.order_id}`}>
                        {record.order.number}
                    </a>
                </>
            )
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transaction.client_number',
            render: (text, record) => (
                <>
                    <a href={`/app/data-view/transactions/${record.transaction_id}`}>
                        {record.transaction.client_number}
                    </a>
                </>
            )
        },
        {
            title: 'Date Ordered',
            dataIndex: 'created_at',
            render: (text, record) => {
                const formatedDate = new Date(record.transaction.ordered_at ?? record.transaction.created_at)
                return (
                    <>
                        {formatedDate.toISOString().split('T')[0]}
                    </>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => (
                <p>{record?.status ? `Collected ${new Date(record.collected_at).toISOString().split('T')[0]}` : `Waiting`}</p>
            )
        },
        {
            title: 'Notes',
            dataIndex: 'transaction.pickup_notes',
            render: (text, record) => (
                <p>
                {record.transaction.pickup_notes}
                </p>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button
                        style={{ marginBottom: 5, marginRight: 10 }}
                        onClick={() => handleDownloadDocket(record)}
                        type='primary'
                    >
                        Docket
                    </Button>
                    <Button
                        style={{ marginBottom: 5 }}
                        onClick={() => handleReview(record)}
                        type='primary'
                    >
                        Review
                    </Button>
                </>
            )
        }
    ]

    const handleDownloadDocket = async record => {
        try {
            const url = `${process.env.REACT_APP_ZENSMART_API_SERVER}/api/v1/consignments/docket/${record.id}?docketButtonClicked=true`
            const token = localStorage.getItem('token')
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Docket.pdf')
                document.body.appendChild(link)
                link.click()
            })
        } catch (error) {
            notification.error({
                message:
                    error?.response?.data?.message ??
                    error?.message ??
                    'Error downloading docket'
            })
        }
    }

    const handleReview = record => {
        setModalValues(prevState => ({
            ...prevState,
            visible: true,
            is_id_sighted: record.id_sighted,
            is_docket_signed: record.docket_signed,
            is_package_not_marked: record.is_not_damaged,
            is_mobile_per_order: record.mobile_on_order,
            agentMobileNumber: record.alt_mobile ?? '',
            client_name: record.client_name,
            note: record.issues ?? '',
            id: record.id
        }))
    }

    const closeModal = () => {
        setModalValues(prevState => ({
            ...prevState,
            visible: false,
            loading: false,
            is_id_sighted: false,
            is_docket_signed: false,
            is_package_not_marked: false,
            is_mobile_per_order: false,
            client_name: '',
            agentMobileNumber: '',
            note: '',
            id: ''
        }))
    }

    const onChangeCheckbox = (e, key) => {
        setModalValues(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }))
    }

    const onChangeText = (value, key) => {
        setModalValues(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const handleSubmit = async () => {
        try {
            setModalValues(prevState => ({
                ...prevState,
                loading: true
            }))

            if (modalValues.is_id_sighted === false) {
                notification.warn({
                    message: 'ID is required'
                })
                return
            }
            if (modalValues.is_docket_signed === false) {
                notification.warn({
                    message: 'Signature required'
                })
                return
            }
            if (modalValues.is_package_not_marked === false && !modalValues.note) {
                notification.warn({
                    message:
                        'You need to specify issue if package is not marked or damaged'
                })
                return
            }
            if (
                modalValues.is_mobile_per_order === false &&
                modalValues.agentMobileNumber === ''
            ) {
                notification.warn({
                    message:
                        'You need to specify mobile number if mobile number as per order'
                })
                return
            }

            const model = {
                is_not_damaged: modalValues.is_package_not_marked,
                id_sighted: modalValues.is_id_sighted,
                docket_signed: modalValues.is_docket_signed,
                mobile_on_order: modalValues.is_mobile_per_order,
                alt_mobile: modalValues.is_mobile_per_order
                    ? ''
                    : `${modalValues.agentMobileNumber}`,
                issues: modalValues.note
            }

            const request = await ZenSmartAPI.put(
                `/api/v1/consignments/pickups/${modalValues.id}`,
                model
            )

            // if update is successful
            notification.success({ message: `Pickup detail submitted` })
            setReloadPage(true)
            closeModal()
        } catch (error) {
            if (error?.response?.data?.errors) {
                let errorMessage = Object.values(error.response.data.errors)[0]
                return notification.error({ message: errorMessage })
            }
            notification.error({
                message:
                    error?.response?.data?.message ??
                    error?.message ??
                    'Error updating consignment'
            })
        } finally {
            setModalValues(prevState => ({
                ...prevState,
                loading: false
            }))
        }
    }
    return (
        <>
            <div style={{ marginTop: 20 }}>
                <TableStyling />

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered={true}
                    rowClassName={(record, index) =>
                        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                    }
                    pagination={pagination}
                    size='middle'
                />
            </div>
            <Modal
                title='Quality Check'
                visible={modalValues.visible}
                okText={'Submit'}
                onOk={handleSubmit}
                confirmLoading={modalValues.loading}
                onCancel={closeModal}
            >
                <StyledFormItem label='Package Check'>
                    <StyledCheckbox
                        checked={modalValues.is_id_sighted}
                        onChange={e => onChangeCheckbox(e, 'is_id_sighted')}
                    >
                        ID has been sighted
                    </StyledCheckbox>
                    <StyledCheckbox
                        checked={modalValues.is_docket_signed}
                        onChange={e => onChangeCheckbox(e, 'is_docket_signed')}
                    >
                        Docket has been signed
                    </StyledCheckbox>
                    <StyledCheckbox
                        checked={modalValues.is_package_not_marked}
                        onChange={e => onChangeCheckbox(e, 'is_package_not_marked')}
                    >
                        Package is not marked or damaged
                    </StyledCheckbox>
                    <StyledCheckbox
                        checked={modalValues.is_mobile_per_order}
                        onChange={e => onChangeCheckbox(e, 'is_mobile_per_order')}
                    >
                        Mobile number as per order
                    </StyledCheckbox>
                </StyledFormItem>

                {modalValues.is_mobile_per_order === false && (
                    <StyledFormItem
                        name='agentMobileNumber'
                        label='Mobile number of agent picking up package'
                    >
                        <Input
                            style={{ width: '18em' }}
                            onChange={e => onChangeText(e.target.value, 'agentMobileNumber')}
                            value={modalValues.agentMobileNumber}
                        />
                    </StyledFormItem>
                )}
                <StyledFormItem label='Issues' name='note'>
                    <Input.TextArea
                        rows={4}
                        placeholder={`Please list issues`}
                        value={modalValues.note}
                        onChange={e => onChangeText(e.target.value, 'note')}
                    />
                </StyledFormItem>
            </Modal>
        </>
    )
}

export default TableData