import React, { useEffect, useState } from "react";
import { Row, Col, Button, Select, DatePicker, notification } from "antd";
import styled from "styled-components";
import { color } from "components/zensmart-design-system/shared/styles.js";
import { ZenSmartAPI } from "utils";
import { getAllStationsRoute } from "utils/apiRoutes";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStation,
  setOverviewDates,
  setTaskClass,
} from "store/actions/machine";
import moment from "moment";
import ExportCsvButton from "../partials/ExportCsvButton";
import { useScreenWidth } from "hooks/useScreenWidth";

const { RangePicker } = DatePicker;

const HeaderRow = styled(Row)`
  .ant-col {
    padding: 10px 50px;
  }

  button {
    margin-right: 2rem;
  }
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;

const CreateTaskCol = styled(Col)`
  text-align: right;
`;

const Label = styled.label`
  display: block;
  color: ${color.heading};
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
`;

const Search = styled(Select)`
  width: 200px;
  margin-right: 10px;
`;

const DateCol = styled.div`
  max-width: 200px;
  margin: 0 10px 10px 0;
  display: inline-block;
`;

const TaskClassButton = styled(Button)`
  text-transform: capitalize;
  margin-bottom: 1rem;
`;

const tasksClasses = [
  "start-up",
  "running",
  "shutdown",
  "maintenance"
];

const TaskHeader = (props) => {
  const {
    clickCreateTask,
    showCreateTask,
    loadTasks,
    loadTasksOverview,
    showMaintenanceTabs,
    setShowReportFaultModal,
    loadTasksByDeviceTypeAndClass,
    showOverview,
    setShowFaults,
    deviceClasses,
    selectedDeviceClass,
    onDeviceClassChange
  } = props;
  const [stations, setStations] = useState([]);

  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine);
  const station = useSelector((state) => state.machine.station);
  const screenWidth = useScreenWidth();
  const [selectedStation, setSelectedStation] = useState(null);

  useEffect(() => {
    if (!showMaintenanceTabs) {
      loadStations();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadStations = () => {
    ZenSmartAPI.get(getAllStationsRoute)
      .then((res) => {
        const stations = res.data.data.filter(
          (station) => station.station_type !== null
        );
        if (props.overview === 'faults') {
          stations.unshift({ id: 0, name: 'All Stations', barcode: 'ALL'})
          setStations(stations)
        } else {
          if (stations[0].id === 0) {
            stations.shift()
          }
          setStations(stations);
        }
        setSelectedStation(stations[0]);
        dispatch(selectStation(stations[0]));
        loadTasks && loadTasks();
        loadTasksOverview &&
          loadTasksOverview(
            stations[0],
            machine.overview.start_date,
            machine.overview.end_date
          );
        loadTasksByDeviceTypeAndClass &&
          loadTasksByDeviceTypeAndClass(stations[0], "start-up");
      })
      .catch((res) => {
        notification.error({
          message: "Fetching stations failed.",
        });
      });
  };

  const onStationChange = (value) => {
    const stationIndex = stations
      .map((station) => station.id)
      .indexOf(parseInt(value));
    setSelectedStation(stations[stationIndex]);
    dispatch(selectStation(stations[stationIndex]));
    loadTasks && loadTasks();
    loadTasksOverview &&
      loadTasksOverview(
        stations[stationIndex],
        machine.overview.start_date,
        machine.overview.end_date
      );
    loadTasksByDeviceTypeAndClass &&
      loadTasksByDeviceTypeAndClass(
        stations[stationIndex],
        machine.maintenance.class
      );
  };

  const stationDropdownOptions = stations.map(
    (station) => <Select.Option key={station.id}>{station.name}</Select.Option>
  );

  const deviceClassesDropdown = deviceClasses && deviceClasses.length ? deviceClasses.map(
    cls => <Select.Option key={cls.device_type}>
    {cls.device_type}
    </Select.Option>
  ) : [];

  const onOverviewRangeDatesChange = (dates) => {
    const start_date = dates[0].format("YYYY-MM-DD 00:00:00");
    const end_date = dates[1].format("YYYY-MM-DD 00:00:00");
    dispatch(setOverviewDates(start_date, end_date));
    loadTasksOverview(machine.station, start_date, end_date);
  };

  const loadFaults = () => {
    setShowFaults(true);
    dispatch(setTaskClass("faults"));
  };

  return (
    <>
      <HeaderRow>
        {showMaintenanceTabs && (
          <Col span={12}>
            <Title>{machine.station.name}</Title>
          </Col>
        )}

        {showOverview && (
          <Col lg={{ span: 8 }} xl={{ span: 8 }}>
            <Label>STATION NAME</Label>
            <Search
              placeholder="Select station"
              value={station && station.name}
              onChange={onStationChange}
            >
              {stationDropdownOptions}
            </Search>
          </Col>
        )}

        {showCreateTask && (
          <Col lg={{ span: 8 }} xl={{ span: 8 }}>
            <Label>STATION TYPE</Label>
            <Search
              placeholder="Select station type"
              value={selectedDeviceClass}
              onChange={(value) => onDeviceClassChange(value)}
            >
              {deviceClassesDropdown}
            </Search>
          </Col>
        )}

        {showOverview && (
          <Col lg={{ span: 10 }} xl={{ span: 10 }}>
            <Label>DATE RANGE</Label>
            <RangePicker
              defaultValue={[
                moment(machine.overview.start_date),
                moment(machine.overview.end_date),
              ]}
              onChange={(dates) => onOverviewRangeDatesChange(dates)}
            />
          </Col>
        )}

        {showOverview && (
          <Col lg={{ span: 4 }} xl={{ span: 6 }}>
            {/* Show the label for large screens to get the top margin */}
            {screenWidth > 992 && (
              <Label style={{ visibility: "hidden" }}>csv</Label>
            )}
            <ExportCsvButton
              station={selectedStation}
              startDate={machine.overview.start_date}
              endDate={machine.overview.end_date}
              type={props.overview}
            />
          </Col>
        )}

        {showCreateTask && clickCreateTask && (
          <CreateTaskCol span={12}>
            <Button type="primary" onClick={() => clickCreateTask(null)}>
              CREATE TASKS
            </Button>
          </CreateTaskCol>
        )}
        {showMaintenanceTabs &&
          setShowReportFaultModal &&
          loadTasksByDeviceTypeAndClass && (
            <Col span={24}>
              {tasksClasses.map((taskClass, index) => (
                <TaskClassButton
                  key={index}
                  type="primary"
                  ghost={machine.maintenance.class !== taskClass ? true : false}
                  onClick={() =>
                    taskClass === "faults"
                      ? loadFaults()
                      : loadTasksByDeviceTypeAndClass(
                          machine.station,
                          taskClass
                        )
                  }
                >
                  {taskClass} {taskClass !== "faults" && `Tasks`}
                </TaskClassButton>
              ))}
              <Button
                type="danger"
                onClick={() => setShowReportFaultModal(true)}
                ghost
              >
                Report Fault
              </Button>
            </Col>
          )}
      </HeaderRow>
    </>
  );
};

export default TaskHeader;
