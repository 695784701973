import React, {useMemo, useState, useEffect} from 'react';
import { styles, Button } from '../../../../components/zensmart-design-system';
import styled from 'styled-components';
import BucketModal from './BucketModal';
import { Icon, Input, Select } from 'antd';
import { TextBucketGroupItem } from '../composites';
import { DataTypes, GetLabel } from '../../utils';
import { GetFieldUniqueValues } from 'pages/Performance/api';
import _ from 'lodash';


const color = styles.color;

const {GetDataType, DATATYPE} = DataTypes;

const RangeSection = styled.div`
  .table {
    display: flex;
    width: 100%;
    height: 20rem;

    .ant-input {
      border-color: ${color.blue.normal};
    }

    i svg {
      margin-bottom: 5px;
    }
    
    .group_nav {
      border: 1px solid #E1E7FD;
      border-right: none;
      padding: 15px;
      width: 40%;
      height: 100%;
      overflow-y: scroll;

      hr {
        margin: 2em 0;
      }

      .add_group {
        display: flex;

        input {
          width: 70%;
        }

        .action_button {
          margin-left: 5px;
          width: 28%;
        }
      }
    }

    .right_section {
      border: 1px solid #E1E7FD;
      padding: 0;
      width: 60%;

      .right_top {
        background-color: rgba(209, 217, 246, 0.3);
        width: 100%;
        padding: 15px;

        .ant-input {
          width: 50%;
        }
      }

      .right_bottom {
        padding: 15px;

        .add_value {
          display: flex;

          .ant-input {
            border-color: #E1E7FD;
          }

          .action_button {
            margin-left: 5px;
            width: 18%;
          }
        }

        .display_values {
          overflow-y:scroll;
          height: 12rem;

          li:hover .delete_button {
            display: inline-block;
          }

          .delete_button {
            margin-left: 2px;
            display: none;
          }
        }

        li {
          font-size: 13px;
        }
      }
    }
 }
`

const TextBucketModal = ({
  dataFields = [],
  visible,
  handleCancel,
  populateBucketField,
  bucket,
  selectedBucketFieldKey,
  currentTable
}) => {
  const defaultBucket = {
    column: '',
    name: '',
    values: [
      {
        value: [],
        name:''
      }
    ]
  }

  const defaultBucketValues = {
    value: [],
    name:''
  }

  const [editableBucket, setEditableBucket] = useState(defaultBucket)
  const [bucketName, setBucketName] = useState('')
  const [currentBucketKey, setCurrentBucketKey] = useState(0)
  const [bucketGroupValue, setBucketGroupValue] = useState('')
  const [possibleValues, setPossibleValues] = useState([])

  useEffect(() => {
    setEditableBucket(bucket)
  }, [bucket]);

  useEffect(() => {
    if (currentTable && !_.isEmpty(editableBucket.column)) {
      GetFieldUniqueValues({
        table: currentTable,
        fields: [`${editableBucket.column.table}.${editableBucket.column.field}`]
      }).then(result => {
        setPossibleValues(result.data)
      })
    }
  }, [currentTable, editableBucket.column])

  const setName = (name) => {
    setEditableBucket(editableBucket => {
      editableBucket.name = name
      return {...editableBucket}
    })
  }

  const setColumn = (column) => {
    setEditableBucket(editableBucket => {
      editableBucket.column = column
      return {...editableBucket}
    })
  }

  const addToGroup = (name) => {
    if (name) {
      const exists = editableBucket.values.some(item => item.name === name)
      if (!exists) {
        setEditableBucket(editableBucket => {
          setCurrentBucketKey(editableBucket.values.length)
          setBucketName('')
          const newGroup = defaultBucketValues
          newGroup.name = name
          editableBucket.values.push(newGroup)
          return {...editableBucket}
        })
      }
    }
  }

  const addValuesToGroup = () => {
    if (editableBucket.values.length !== 0) {
      const exists = editableBucket.values[currentBucketKey].value && editableBucket.values[currentBucketKey].value.includes(bucketGroupValue)
      if (!exists) {
        setEditableBucket(editableBucket => {
          editableBucket.values[currentBucketKey].value.push(bucketGroupValue)
          setBucketGroupValue('')
          return {...editableBucket}
        })
      }
    }
  }

  const deleteItem = (key) => {
    setEditableBucket(editableBucket => {
      editableBucket.values.splice(key, 1);
      setCurrentBucketKey(0);
      return {...editableBucket}
    });
  }

  const deleteGroupValue = (bucketGroupKey, valueKey) => {
    setEditableBucket(editableBucket => {
      editableBucket.values[bucketGroupKey].value.splice(valueKey, 1);
      return {...editableBucket}
    });
  }

  const handleOK = () => {
    populateBucketField(editableBucket, selectedBucketFieldKey)
    setEditableBucket(defaultBucket)
    setCurrentBucketKey(0)
    handleCancel()
  }

  return (
    <BucketModal 
      columns={
        useMemo(() => Object.values(dataFields).filter(column => column.field.toLowerCase() !== 'password'), [dataFields])
      }
      visible={visible}
      handleOK={() => handleOK()}
      handleCancel={handleCancel}
      bucketName={editableBucket.name && editableBucket.name}
      setBucketName={setName}
      bucketColumnField={
        (editableBucket.column && editableBucket.column.field) ? 
        GetLabel(`${editableBucket.column.table}.${editableBucket.column.field}`) :
        ''
      }
      setBucketColumn={setColumn}
    >
      <RangeSection>
        <div className='table'>
          <div className='group_nav'>
            <div className='group_nav_top'>
              {
                editableBucket && editableBucket.values && Object.keys(editableBucket.values).map(key => (
                  <TextBucketGroupItem
                    key={key}
                    index={key}
                    name={editableBucket.values[key].name}
                    active = {editableBucket && editableBucket.values[currentBucketKey] && editableBucket.values[currentBucketKey].name === editableBucket.values[key].name}
                    onClick={() => setCurrentBucketKey(key)}
                    deleteItem={deleteItem}
                  />
                ))
              }
            </div>
            <hr/>
            <div className='add_group'>
              <Input
                onPressEnter={()=>addToGroup(bucketName)}
                onChange={(e)=>setBucketName(e.target.value)}
                value={bucketName}
                placeholder='New Bucket Name'
              />
              <Button
                onClick={()=>addToGroup(bucketName)}
                size='small'
                className='action_button'
              >
                Add
              </Button>
            </div>
          </div>
          <div className='right_section'>
            <div className='right_top'>
              <Input
                placeholder='Bucket Name'
                value={
                  editableBucket && (
                    editableBucket.values[currentBucketKey] ? editableBucket.values[currentBucketKey].name : ''
                  )
                }
                disabled
              />
            </div>
            <div className='right_bottom'>
              <div className='add_value'>
                <Select
                  placeholder='Enter Value'
                  value={bucketGroupValue}
                  onSelect={(key)=>{
                    setBucketGroupValue(possibleValues[key])
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={true}
                  style={{ width: '100%'}}
                >
                  {possibleValues.map((item, index) => (
                    <Select.Option
                      key={index}
                    >
                      {item}
                    </Select.Option>
                  ))}
                </Select>
                <Button
                  onClick={()=>addValuesToGroup()}
                  size='small'
                  className='action_button'
                >
                  Add
                </Button>
              </div>
              <div className='display_values'>
                <ul>
                  {
                    editableBucket.values[currentBucketKey] && Object.values(editableBucket.values[currentBucketKey].value).map((value, index) => (
                      <li key={index}>
                        {value}
                        <span className='delete_button' onClick={()=>deleteGroupValue(currentBucketKey, index)}>
                          <Icon type='delete'/>
                        </span>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </RangeSection>
    </BucketModal>
  )
}

export default TextBucketModal