import React, { useRef, useEffect, useState } from 'react'
import {Row, Col, Form, Input, Modal, Icon as AIcon } from 'antd'
import styled, { css } from 'styled-components'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js'
import { Panel } from 'components/primaries'
import { ZenSmartAPI } from 'utils';
import Cookies from 'js-cookie';
import { normalBarcodeScanRoute } from "utils/apiRoutes"
import { defaultGamificationQuizValue, fetchGamificationQuiz, resetState } from './Gamification/helper'
import GameView, { Spinner } from './Gamification/GameView'
/**
 * @note Clean the API for this component. Feels like it has to know
 * a lot of variables/prop values in order to be useful.
 */


const panelStylings = css`
  margin-bottom: 24px;
`

const StyledRow = styled(Row)`
  margin-bottom: 24px;
`



const LookupForm = Form.create({ name: 'LookupForm' })(props => {
  const { onSubmit, inputProps, form, isViewingDetails, scanData, setHtmlToPrint } = props
  const { getFieldDecorator, getFieldValue, resetFields } = form
  const [loading, setLoading] = useState(false)
  const [scanGamification, setScanGamification] = useState(defaultGamificationQuizValue)
  const scanInput = useRef(null)
  const refocusQueueID = useRef(null)
  const clearRefocusQueue = () => {
    clearTimeout(refocusQueueID.current)
  }

  const refocusQueue = () => {
    refocusQueueID.current = setTimeout(() => {
      if (scanInput.current) {
        scanInput.current.focus()
      }
    }, 5000)
  }


  useEffect(() => {
    if(scanGamification.gameData.length === 0) {
      scanInput.current.focus()
    }
  }, [scanGamification?.gameData])

  const showInternalMessage = (message) => {
    if(Array.isArray(message)){
        if(message.length !== 0){
            const messageText = message.join('<br />')
            Modal.info({
                title: 'Alert',
                icon: <AIcon type='exclamation' />,
                content: <div dangerouslySetInnerHTML={{__html: messageText}}></div>,
            }) 
        }
    }
  }

  const handleSubmit = async(evt) => {
    if(evt) {
      evt.preventDefault()
    }
    const input_value = getFieldValue('lookup_text');
    let payload = {};

    if (input_value === undefined) {
      scanInput.current.focus()
      return onSubmit({ error: 'Please Scan A Barcode' })
    }

    if (input_value.includes('STATION') === true) {
      payload = { barcode: input_value, scan_type_id: scanData.id }
      scanInput.current.focus()
    }

    else if ((input_value.includes('STATION') === false && Cookies.get('STATION') === undefined)
      || parseInt(Cookies.get('SCAN_TYPE')) !== parseInt(scanData.id)) {
      resetFields()
      scanInput.current.focus()
      return onSubmit({ error: 'Please Scan Station Card First' })
    }

    else {
      payload = { station_id: Cookies.get('STATION'), barcode: input_value, scan_type_id: scanData.id };
    }
    if (scanGamification.isVerified === false && input_value.includes('STATION') === false && Cookies.get('ALLOW_QUIZ') === 'true') {
      const quizCheck = await fetchGamificationQuiz(payload, setLoading, setScanGamification, scanInput);
      if(quizCheck === true) return;
    }
    setLoading(true)
    setHtmlToPrint(null)
    ZenSmartAPI.post(normalBarcodeScanRoute, payload)
      .then((res) => {
        resetFields()
        setLoading(false)
        scanInput.current.focus()
        if (res.data && res.data.duplicate_scan_prompt) {
          Modal.confirm({
            'content': res.data.duplicate_scan_prompt,
            onOk: () => {
              onSubmit({ ...res.data, success_scan: true , overwrite_osi_false : true})
              return
            },
            cancelButtonProps: { style: { display: "none" } }
          });
        }
        if(res.data && res.data.internal_comments){
          showInternalMessage(res.data.internal_comments)
        }
        onSubmit({ ...res.data, success_scan: true })
        resetState(setScanGamification)
      })
      .catch((res) => {
        resetFields()
        resetState(setScanGamification)
        setLoading(false)
        scanInput.current.focus()
        if (res.response.data.hasOwnProperty('message') && res.response.status === 400) {
          onSubmit({ error: res.response.data.message })
        }
        else {
          onSubmit('Not a valid Scan Item');
        }
      })
  }

  useEffect(() => {
    if (scanInput.current) {
      scanInput.current.focus()
    }
    return clearRefocusQueue
  }, [])

  useEffect(() => {
    if (scanGamification.isVerified === true) {
       handleSubmit()
    }
  }, [scanGamification.isVerified])

  useEffect(() => {
    resetFields();
  }, [isViewingDetails, resetFields])

  return (
    <>
    <Form onSubmit={handleSubmit}>
      {getFieldDecorator('lookup_text')(
        <Input
          type="text"
          placeholder="Scan Item"
          ref={scanInput}
          onFocus={clearRefocusQueue}
          onBlur={refocusQueue}
          {...inputProps}
          disabled={loading || scanGamification.gameData.length > 0}
          loading={loading}
        />
      )}
    </Form>
      {scanGamification.loading && <Spinner size="large"  tip={'Loading...'} />}
      {(scanGamification.gameData.length > 0 && scanGamification.loading === false) && (
        <GameView
          scanGamification={scanGamification}
          setScanGamification={setScanGamification}
          resetFields={resetFields}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
})

function DetailLookup(props) {
  const {
    title,
    detailLabel,
    detailLabelStatus,
    isViewingDetails,
    onLookup,
    inputProps,
    labelProps,
    children,
    assuranceFailInput,
    scanData,
    setHtmlToPrint
  } = props

  return (
    <Panel title={title} stylings={panelStylings}>
      <StyledRow type="flex" justify="center">
        <Label status={detailLabelStatus} {...labelProps}>{detailLabel}</Label>
      </StyledRow>

      <StyledRow type="flex" justify="center">
        <Col span={8}>
          <LookupForm onSubmit={onLookup} setHtmlToPrint={setHtmlToPrint} inputProps={inputProps} scanData={scanData} isViewingDetails={isViewingDetails} assuranceFailInput={assuranceFailInput} />
        </Col>
      </StyledRow>
      {children}
    </Panel>
  )
}

export default DetailLookup
