import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typography, color, spacing } from '../../shared/styles';
import '../../shared/tailwind/styles.css';

const Text = styled.span`
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
`;

const APPEARANCES = {
  GHOST: 'ghost',
  NORMAL: 'normal',
  DROPDOWN: 'dropdown'
};

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

// return a color object based on the parameter entered
const determineColor = ($color) => {
  switch ($color) {
    case 'blue':
      return color.blue
    case 'pacific_blue':
      return color.pacific_blue
    case 'mountain_meadow':
      return color.mountain_meadow
    case 'buttercup':
      return color.buttercup
    case 'red':
      return color.red
    case 'purple':
      return color.purple
    default:
      return color.blue
  }
};

//Base styling for buttons
const StyledButton = styled.button`
  font-family: ${typography.type};
  cursor: pointer;
  display: inline-flex;
  overflow: hidden;
  padding: ${props => (props.size === SIZES.SMALL ? '10px' : '13px 19px')};
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  user-select: none;
  opacity: 1;
  margin: 0;
  border-radius: ${spacing.borderRadius.small}px;
  font-size: ${props => (props.size === SIZES.SMALL ? typography.size.s1 : typography.size.s2)}px;
  font-weight: ${typography.weight.regular};
  line-height: 1;
  ${props => 
    props.width &&
    `
      width: ${props.width};
    `}
  ${props => 
    props.height &&
    `
      height: ${props.height};
    `}
  ${props =>
    props.disabled &&
    `
      cursor: not-allowed !important;
      opacity: 0.3;
      &:hover {
        transform: none;
      }
    `}

  ${props =>
    props.isUnclickable &&
    `
      cursor: default !important;
      pointer-events: none;
      &:hover {
        transform: none;
      }
    `}

  ${props =>
    props.appearance === APPEARANCES.GHOST &&
    `
      background: ${color.white};
      border: ${spacing.border.s1}px solid ${determineColor(props.color).normal};
      color: ${determineColor(props.color).normal};
      &:active {
        background: ${determineColor(props.color).light};
      }
    `}

  ${props =>
    props.appearance === APPEARANCES.NORMAL &&
    `
      border: 0;
      background: ${determineColor(props.color).normal};
      color: ${color.white};
      &:active {
        background: ${determineColor(props.color).dark};
      }
    `}

`;

//handle cases when link is needed with button
const ButtonLink = StyledButton.withComponent('a');

const applyStyle = ButtonWrapper => {
  return (
    ButtonWrapper &&
    StyledButton.withComponent(({ containsIcon, isLoading, isUnclickable, ...rest }) => (
      <ButtonWrapper {...rest} />
    ))
  );
};

const DropdownParent = styled.div`
  width: fit-content;
`

const DropdownList = styled.ul`
  border: 1px solid #CBD6FF;
  max-height: 161px;
  min-width: 175px;
  overflow: auto;
  border-radius: ${spacing.borderRadius.small}px;
  li {
    border-bottom: 1px solid #CBD6FF;
    height: 38px;
    color: ${props => props.color && determineColor(props.color).normal};
  }
  ${props => 
    props.color && 
    `
    li:hover {
      background: ${determineColor(props.color).normal};
      color: ${color.white}
    }
  `}
  li:last-child {
    border-bottom: 0;
  }
`

//{ dropdown specific code
export function DropdownButton({
  ButtonWrapper,
  onClick,
  items,
  children,
  ...props
}){
  const [open, setOpen] = React.useState(false);
  const drop = React.useRef(null);
  function handleClick(e){
    if(!e.target.closest(`.${drop.current.className}`) && open){
      setOpen(false);
    }
  }
  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    }
  })
  const buttonInner = (
    <Fragment>
      <span className="mr-1">{children}</span>
    </Fragment>
  )
  return (
    <DropdownParent ref={drop}>
      <StyledButton onClick={() => setOpen(open => !open)} {...props}>
        {buttonInner}
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
      </StyledButton>
      {open && <DropdownList className="dropdown-menu absolute" color={props.color}>
        {items.map(function(item) {
          return <li key={item.content}><a className="py-2 px-4 block whitespace-no-wrap" href={item.link}>{item.content}</a></li>;
        })}
        {/* <li><a className="py-2 px-4 block whitespace-no-wrap" href="#">One</a></li>
        <li><a className="py-2 px-4 block whitespace-no-wrap" href="#">Two</a></li>
        <li><a className="py-2 px-4 block whitespace-no-wrap" href="#">Two</a></li>
        <li><a className="py-2 px-4 block whitespace-no-wrap" href="#">Two</a></li>
        <li><a className="py-2 px-4 block whitespace-no-wrap" href="#">Two</a></li>
        <li><a className="py-2 px-4 block whitespace-no-wrap" href="#">Two</a></li>
        <li></li> */}
      </DropdownList>}
    </DropdownParent>
  );
}

DropdownButton.propTypes = {
  appearance: PropTypes.oneOf(Object.values(APPEARANCES)),
  color: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  items: PropTypes.array,
  ButtonWrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

DropdownButton.defaultProps = {
  appearance: APPEARANCES.NORMAL,
  color: 'blue',
  size: SIZES.MEDIUM,
  items: [],
  ButtonWrapper: undefined,
};

//} end dropdown specific code

export function Button({
  isDisabled,
  isUnclickable,
  isLink,
  children,
  ButtonWrapper,
  ...props
}) {
  // populate button with text
  const buttonInner = (
    <Fragment>
      <Text>{children}</Text>
    </Fragment>
  );

  const StyledButtonWrapper = React.useMemo(() => applyStyle(ButtonWrapper), [ButtonWrapper]);

  let SelectedButton = StyledButton;
  if (ButtonWrapper) {
    SelectedButton = StyledButtonWrapper;
  } else if (isLink) {
    SelectedButton = ButtonLink;
  }

  return (
    <SelectedButton disabled={isDisabled} {...props}>
      {buttonInner}
    </SelectedButton>
  );
}

Button.propTypes = {
  /**
   Set button color
  */
  color: PropTypes.string,
  /**
   Buttons that have hrefs should use <a> instead of <button>
  */
  isLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
  appearance: PropTypes.oneOf(Object.values(APPEARANCES)),
  isDisabled: PropTypes.bool,
  /**
   Prevents users from clicking on a button multiple times (for things like payment forms)
  */
  isUnclickable: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  height: PropTypes.string,
  width: PropTypes.string,
  ButtonWrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

Button.defaultProps = {
  isLink: false,
  appearance: APPEARANCES.NORMAL,
  isDisabled: false,
  isUnclickable: false,
  size: SIZES.MEDIUM,
  ButtonWrapper: undefined,
};
