import React, { useState, useEffect } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Modal, Spin, Button } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DragDropContext } from "react-beautiful-dnd";
import axios from "axios";
import * as actions from "store/actions/index.js";
import { Libraries } from "./config/Imposition";
import is from "is_js";
import isEqual from "lodash/isEqual";
import "./Imposition.css";
import ImportRecipe from "./sections/recipe/ImportRecipe";
import ExportRecipe from "./sections/recipe/ExportRecipe";
import { packMultiType } from "modules/imposition/utils/ImpositionUtil";

import Header from "./sections/header/Header";
import Library from "./sections/library/Library";
import Preview from "./sections/preview/Preview";
import Recipe from "./sections/recipe/Recipe";

const TableBody = styled.tbody`
  ${(props) =>
    props.impoDesignerDisabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%; 
`;

const ImpositionDesigner = (props) => {
  //State for keeping track of current recipes in recipe column
  const [recipes, setRecipes] = useState(props.recipes);
  //State to keep track of the current recipe id of the selected recipe in recipe column
  const [recipeId, setRecipeId] = useState("");
  //State to keep track of output file metadata
  const [output, setOutput] = useState(props.output);
  //State to keep track to show/hide metadata dialog
  const [modal, setModal] = useState({
    visible: false,
    isTable: false,
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [pdfURL, setPdfURL] = useState(props.pdfURL);

  const [changeTracker, setChangeTracker] = useState(props.changeTracker);
  const [impoDesignerDisabled, setImpoDesignerDisabled] = useState(
    props.impoDesignerDisabled
  );

  //state to keep track of server request is in progress
  const [loading, setLoading] = useState(false);

  //Manage drop down states of Output Metadata screen
  const [specifiedPrinters, setSpecifiedPrinters] = useState([]);
  const [paperCodes, setPaperCodes] = useState([]);
  const [laminationCodes, setLaminationCodes] = useState([]);
  const [outputFormat, setOutputFormat] = useState(null);

  //State for default printer checkbox
  const [checkBox, setCheckBox] = useState(
    is.existy(props.productBlockPrinter) &&
      is.existy(props.productBlockPrinter.default)
      ? props.productBlockPrinter.default
      : false
  );
  //State for default printer checkbox
  const [qaFailCheckBox, setQaFailCheckBox] = useState(
    is.existy(props.productBlockPrinter) &&
      is.existy(props.productBlockPrinter.failed_printer_default)
      ? props.productBlockPrinter.failed_printer_default
      : false
  );

  //State to check for unsaved changes
  const [unsavedChanges, hasUnsavedChanges] = useState(false);

  //State to keep track of menu route path and warning dialog when user tries to leave screen
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [initialImpositionData, setInitialImpositionData] = useState(null)
  const history = useHistory();

  useEffect(() => {
    return () => {
      props.saveHeader();
    };
  }, []);

  useEffect(() => {
    if (modalVisible) {
      Modal.confirm({
        title: 'Warning',
        icon: <ExclamationCircleOutlined />,
        content: 'You are about to discard all changes. Are you sure you want to leave?',
        onOk() {
          setConfirmedNavigation(true);
        },
        onCancel() {
          setModalVisible(false);
          setLastLocation(null);
        },
      });
    }
  }, [modalVisible]);

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation]);

  const updateRecipes = (recipe, update) => {
    let saveRecipes = recipe;

    if (!update) {
      saveRecipes = [...recipes, recipe];
    }

    setRecipes(saveRecipes);
    props.saveRecipes(saveRecipes);
  };

  const updateRecipeId = (id) => {
    setRecipeId(id+'');
  };

  const updateOutput = (output) => {
    setOutput(output);
    props.saveOutput(output);
  };

  const handlePDFError = (err) => {
    let errMsg = err;
    setPdfURL("error");

    if (is.existy(err.response) && is.existy(err.response.data)) {
      errMsg = err.response.data;
    }

  };

  const updatePdfURL = (recipePayload, headerState) => {
    const url = `${process.env.REACT_APP_IMPOSITION_SERVER}`;

    if (is.empty(recipePayload)) {
      setPdfURL(null);
      return;
    }
    let productState = props.productState;
    let articleState = props.articleState;
    let blockState = props.blockState;
    let plexState = props.plexState;

    if (is.existy(headerState)) {
      productState = headerState.productCode;
      articleState = headerState.article;
      blockState = headerState.blockType;
      plexState = headerState.plex;
    }

    const previewPayload = {
      productCode: productState,
      productDescription: articleState ? articleState : initialImpositionData.products.find(data => data.product_code === productState).name,
      plex: plexState,
      blockType: blockState,
      recipe: packMultiType(recipePayload),
    };

    axios
      .post(url, previewPayload)
      .then((response) => {
        if (is.existy(response) && is.existy(response.data)) {
          const pdfURL = response.data;

          //Axios head call to check if PDF url is a valid resource link
          axios
            .head(pdfURL)
            .then(() => {
              setPdfURL(pdfURL);
            })
            .catch((err) => {
              handlePDFError(err);
            });
        } else {
          handlePDFError("Invalid payload response");
        }
      })
      .catch((err) => {
        handlePDFError(err);
      });
  };

  const updateChangeTracker = (recipe, output, defaultPrinter, failDefaultPrinter) => {
    const changes = {
      recipe: [...recipe],
      output: [...output],
      defaultPrinter: is.existy(defaultPrinter) ? defaultPrinter : false,
      failDefaultPrinter: is.existy(failDefaultPrinter) ? failDefaultPrinter : false,
      updateChangeTracker: is.existy(updateChangeTracker) ? updateChangeTracker : false
    };
    setChangeTracker(changes);
    props.saveChangeTracker(changes);
  };

  const updateImpoDesignerDisabled = (state) => {
    setImpoDesignerDisabled(state);
    props.saveImpoDesignerDisabled(state);
  };

  const onDragEnd = (result) => {
    const { draggableId, source, destination } = result;

    if (source.droppableId === "Library" && destination != null) {
      const insertIndex = Number(destination.index);
      const num = recipes.length;
      const data = draggableId;
      const library = Libraries.find((property) => property.id === data);
      let newParameters = [];

      if (is.existy(library.parameters) && is.not.empty(library.parameters)) {
        newParameters = library.parameters.map((param) => ({ name: param, value: "" }));
      }

      const recipe = {
        sortOrder: num.toString(),
        name: data,
        parameters: newParameters,
      };

      //If new recipe has no parameters, clear existing PDF preview first
      if (is.empty(recipe.parameters)) {
        updatePdfURL([]);
      }

      let newRecipes;
      if (insertIndex === num) {
        updateRecipes(recipe, false);
        newRecipes = [...recipes, recipe];
      } else {
        const cloneRecipes = [...recipes];
        cloneRecipes.splice(insertIndex, 0, recipe);
        newRecipes = cloneRecipes.map((value, index) => {
          return { ...value, sortOrder: index.toString() };
        });
        updateRecipes(newRecipes, true);
      }

      hasUnsavedChanges(checkUnsaveChanges(newRecipes, null, null));

      if (is.not.empty(recipe.parameters)) {
        setDialogState(insertIndex.toString());
      } else {
        updatePdfURL(newRecipes);
      }
    } else if (source.droppableId === "Recipe" && destination != null) {
      const removeIndex = Number(source.index);
      const insertIndex = Number(destination.index)

      if (insertIndex !== removeIndex) {
        updatePdfURL([]);
        const element = recipes[removeIndex];
        const cloneRecipes = [...recipes];
        cloneRecipes.splice(removeIndex, 1);
        cloneRecipes.splice(insertIndex, 0, element);
        const newRecipes = cloneRecipes.map((value, index) => {
          return { ...value, sortOrder: index.toString() };
        });
        updateRecipes(newRecipes, true);
        hasUnsavedChanges(checkUnsaveChanges(newRecipes, null, null));
        updatePdfURL(newRecipes);
      }
    }
  };

  const setDialogState = (value) => {
    const recipeId = (value.sortOrder !== undefined ? value.sortOrder : value) +'';
    updateRecipeId(recipeId);
    setModal({
      visible: true,
      isTable: false,
    });
  };

  const checkUnsaveChanges = (recipeArg, printPathsArg, defaultPrinterArg, qaFailDefaultPrinterArg) => {
    const recipeCheck = is.existy(recipeArg) ? recipeArg : props.recipes;
    const printPathsCheck = is.existy(printPathsArg) ? printPathsArg : props.output;
    const defaultPrinterCheck = is.existy(defaultPrinterArg) ? defaultPrinterArg : checkBox;
    const qaFaildefaultPrinterCheck = is.existy(qaFailDefaultPrinterArg) ? qaFailDefaultPrinterArg : qaFailCheckBox;

    return !isEqual(recipeCheck, props.changeTracker.recipe) ||
      !isEqual(printPathsCheck, props.changeTracker.output) ||
      !isEqual(qaFaildefaultPrinterCheck, props.changeTracker.failDefaultPrinter) ||
      !isEqual(defaultPrinterCheck, props.changeTracker.defaultPrinter);
  }

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation && unsavedChanges) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }

    return true;
  };


  return (
    <div className="Imposition">
      <Prompt
        when={
          props.productState != null ||
          props.printerState != null ||
          props.finishingState != null
        }
        message={handleBlockedNavigation}
      />
      <Spin tip="Loading..." spinning={loading}>
        <Header
          recipes={recipes}
          setInitialImpositionData={setInitialImpositionData}
          initialImpositionData={initialImpositionData}
          output={output}
          changeTracker={changeTracker}
          impoDesignerDisabled={impoDesignerDisabled}
          updateRecipes={updateRecipes}
          updateRecipeId={updateRecipeId}
          updateOutput={updateOutput}
          updatePdfURL={updatePdfURL}
          updateChangeTracker={updateChangeTracker}
          updateImpoDesignerDisabled={updateImpoDesignerDisabled}
          setLoading={setLoading}
          paperCodes={paperCodes}
          setPaperCodes={setPaperCodes}
          laminationCodes={laminationCodes}
          setLaminationCodes={setLaminationCodes}
          setSpecifiedPrinters={setSpecifiedPrinters}
          checkBox={checkBox}
          setCheckBox={setCheckBox}
          qaFailCheckBox={qaFailCheckBox}
          setQaFailCheckBox={setQaFailCheckBox}
          unsavedChanges={unsavedChanges}
          hasUnsavedChanges={hasUnsavedChanges}
          checkUnsaveChanges={checkUnsaveChanges}
          setOutputFormat={setOutputFormat}
        />
        <>
          <table className="Imposition-table Imposition-table-common">
            <thead>
              <tr className="Imposition-table-row">
                <th className="Imposition-table-common Imposition-table-column1">
                  LIBRARY
                  <p></p>
                </th>
                <th className="Imposition-table-common Imposition-table-column2">
                  <FlexRow>
                    <div style={{ marginTop: '0.3rem' }}>
                      RECIPE
                    </div>
                    <div>
                      <ImportRecipe
                        setRecipes={setRecipes}
                        setImpoDesignerDisabled={setImpoDesignerDisabled}
                        product={props.productState}
                        block={props.blockState}
                        printer={props.printerState}
                        finishing={props.finishingState}
                      />
                      <ExportRecipe recipes={recipes} />
                    </div>
                  </FlexRow>
                </th>
                <th className="Imposition-table-common Imposition-table-column3">
                  PREVIEW
                </th>
              </tr>
            </thead>
            <TableBody impoDesignerDisabled={impoDesignerDisabled}>
              <tr>
                <DragDropContext onDragEnd={onDragEnd}>
                  <td
                    valign="top"
                    className="Imposition-table-common Imposition-table-content"
                  >
                    <Library />
                  </td>
                  <td
                    valign="top"
                    className="Imposition-table-common Imposition-table-content"
                  >
                    <Recipe
                      recipes={recipes}
                      recipeId={recipeId}
                      output={output}
                      modal={modal}
                      changeTracker={changeTracker}
                      updateRecipes={updateRecipes}
                      updateRecipeId={updateRecipeId}
                      updateOutput={updateOutput}
                      setModal={setModal}
                      setDialogState={setDialogState}
                      updatePdfURL={updatePdfURL}
                      paperCodes={paperCodes}
                      setPaperCodes={setPaperCodes}
                      laminationCodes={laminationCodes}
                      setLaminationCodes={setLaminationCodes}
                      specifiedPrinters={specifiedPrinters}
                      setSpecifiedPrinters={setSpecifiedPrinters}
                      unsavedChanges={unsavedChanges}
                      hasUnsavedChanges={hasUnsavedChanges}
                      checkUnsaveChanges={checkUnsaveChanges}
                      outputFormat={outputFormat}
                    />
                  </td>

                  <td valign="top" className="Imposition-table-common">
                    <Preview recipes={recipes} pdfURL={pdfURL} />
                  </td>
                </DragDropContext>
              </tr>
            </TableBody>
          </table>
        </>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    recipes: state.imposition.recipes,
    output: state.imposition.output,
    pdfURL: state.imposition.pdfURL,
    changeTracker: state.imposition.changeTracker,
    impoDesignerDisabled: state.imposition.impoDesignerDisabled,
    productState: state.imposition.productCode,
    articleState: state.imposition.article,
    blockState: state.imposition.blockType,
    printerState: state.imposition.printerClass,
    finishingState: state.imposition.finishingTypeText,
    defaultPrinter: state.imposition.defaultPrinter,
    failDefaultPrinter: state.imposition.failDefaultPrinter,
    productBlockPrinter: state.imposition.productBlockPrinter,
    plexState: state.imposition.plex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveRecipes: (recipes) => dispatch(actions.saveRecipes(recipes)),
    saveOutput: (output) => dispatch(actions.saveOutput(output)),
    savePDFURL: (pdfURL) => dispatch(actions.savePDFURL(pdfURL)),
    saveChangeTracker: (changeTracker) =>
      dispatch(actions.saveChangeTracker(changeTracker)),
    saveImpoDesignerDisabled: (impoDesignerDisabled) =>
      dispatch(actions.saveImpoDesignerDisabled(impoDesignerDisabled)),
    saveHeader: (header) => dispatch(actions.saveHeader(header)),
  };
};

export const Imposition = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpositionDesigner);
