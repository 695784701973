// barcode scan routes

export const normalBarcodeScanRoute = "/api/v1/scans/barcode";
export const scanTypesRoute = (filter = null) => {
  return filter ? "/api/v1/scan-types?apply_filters=0" : "/api/v1/scan-types";
};
export const automatedScanTypesRoute = "/api/v1/scan-types/automated";
export const dispatchConsigmentRoute = "/api/v1/consignments";
export const dispatchScanRoute = (value, isPickup, scanType = null  ) => {
  let urlParams = '';
  if (scanType) {
    urlParams = '?scan_type=in-store'
  }

  return `/api/v1/scans/${isPickup === true ? 'pickup' : 'dispatch'}-items/${value}${urlParams}`;
};
export const allScanTypesRoute = "/api/v1/scan-types/all";
export const matchScanBlockRoute = "/api/v1/scans/match-scan";

// auth api

export const forgotPasswordRoute = "/api/v1/auth/forgot-password";
export const authMeRoute = "/api/v1/auth/me";
export const loginRoute = `${process.env.REACT_APP_ZENSMART_API_SERVER}/api/v1/auth/login`;
export const changePasswordValidateRoute = (token) => {
  return `/api/v1/auth/change-password/${token}/validate`;
};

// imposition design

export const getPrintPathsRoute = "/api/v1/imposition-design-data/product/get-print-paths"
export const impositionDesignDataRoute = "/api/v1/imposition-design-data"
export const getOutputFormats = "/api/v1/imposition-design-data/get-output-formats"
// export const getRecipeRoute = "/api/v1/imposition-design-data/templates"
export const getRecipeRoute = (value = null) => {
  return value ? `/api/v1/imposition-design-data/templates?value=${value}` : `/api/v1/imposition-design-data/templates`;
};
export const getTemplatePrintPaths = "/api/v1/imposition-design-data/templates/print-paths"
export const getImposeTemplates = "/api/v1/imposition-design-data/get-impose-templates"
export const getOverlayTemplates = "/api/v1/imposition-design-data/get-overlay-templates"
export const getRecipeBlacklistRoute = "/api/v1/imposition-design-data/get-recipe-blacklist"

// products

export const productsRoute = "/api/v1/products";
export const productCreateRoute = "api/v1/data-api/products";
export const productUpdateRoute = "api/v1/data-view/products";
export const productFlowRoute = "api/v1/product-flow";
export const productIdRoute = (productID) => {
  return `api/v1/product-flow/${productID}`;
};
export const templateIdRoute = (templateID) => {
  return `api/v1/product-flow/template/${templateID}`;
};
export const productCloneIdRoute = (productID) => {
  return `api/v1/products/clone/${productID}`;
};

export const productSaveCloneRoute = (productID) => {
  return `api/v1/products/clone/${productID}`;
};


export const productSearchRoute = (value) => {
  return `api/v1/products/search?value=${value}`;
};
export const productGroupsSearchRoute = (value) => {
  return `api/v1/products/groups/search?value=${value}`;
};
export const dispatchNodesRoute = (product) => {
  return `/api/v1/products/${product}/dispatch-nodes`;
};

export const productBlockComponents = (product, type) => {
  return `/api/v1/products/${product}/block-components/${type}`;
};

// groups
export const createGroupsRoute = "api/v1/products/groups";

// search
export const searchRoute = "/api/v1/elasticsearch/search";
export const modelDefinitionRoute = (filter, hideNonDb = null, resource = null) => {
  return `/api/v1/model-definitions?filter=${filter}&hideNonDb=${hideNonDb}&resource=${resource}`;
};
export const ticketDataFieldRoute = (filter = '', hideNonDb = null) => {
  return `/api/v1/ticket-data-fields?name=${filter}&hideNonDb=${hideNonDb}`;
};
export const matchRangeRoute = "/api/v1/match-range";
export const matchRangeGroups = "/api/v1/match-range/groups";
export const matchGroupCreateRoute = "/api/v1/match-group/create";
export const freightGroupRoute = "/api/v1/freight-group/all";
export const freightGroupCreateRoute = "/api/v1/freight-group";
export const matchOutGroupCreateRoute = "/api/v1/match-group";
export const fethAllFreightGroup = "api/v1/freight-group/all";
export const blockTypesRoute = "/api/v1/product-blocks/block-types";
export const productFlowInitData = "api/v1/ui/init-data/product-flow";
export const productFlowConfiguredDataRoute = "api/v1/products/flow-configured";

export const dataViewRoute = (resource, id) => {
  return `/api/v1/data-view/${resource}/${id}`
}
export const addPrinterClassRoute = "/api/v1/printer-classes"
export const finishingTypesRoute = "/api/v1/finishing-types"
export const endpointRoute = `${process.env.REACT_APP_ANALYTICS_SERVER}/api/endpoints`

export const addPaperLaminationCodeRoute = (product) => {
  return `/api/v1/products/${product}/components/addform`
}

export const getAllLaminationCode = "/api/v1/products/get-lamination-codes"
export const getExtraWipFilterSetting = "/api/v1/setting/get-wip-filter";

//control tower api

export const getPrintersRoute = (id) => {
  return `/api/v1/control-tower/printers?location_id=${id}`;
};;
export const getOnePrinterRoute = (id) => {
  return `/api/v1/control-tower/printer/${id}`;
};
export const getSubmissionDetailsRoute = (id) => {
  return `/api/v1/control-tower/master-submission/${id}`;
};
export const submissionChangeRoute = "/api/v1/control-tower/submission-change";
export const isReadyPrinterRoute = "/api/v1/control-tower/printer-ready";
export const getSubmissionsRoute = (id) => {
  return `/api/v1/control-tower/submissions?location_id=${id}`;
};

export const printOrDeleteSingleSubmissionRoute =
    "/api/v1/control-tower/single-submission-action";
export const printOrDeleteMasterSubmissionRoute =
    "/api/v1/control-tower/master-submission-action";
export const thermalLabelRoute = "/api/v1/thermal-label/list/all";
export const dispatchTemplatesRoute = "/api/v1/products/dispatch-templates";

//picking
export const pickingGroupsRoute = "/api/v1/picking/groups"
export const pickingComponentsRoute = "/api/v1/picking/components"
export const pickingLocationsRoute = "/api/v1/bom/locations"
export const createPickingGroups = "/api/v1/picking/groups/create"
export const createLocations = "/api/v1/picking/location/create"
export const createPickingRanges = "/api/v1/picking/components"
export const createPickingComponent = "/api/v1/picking/components/add"


// proscess pages

export const incomingOrdersRoute = (from, to) => {
  return `/api/v1/process-centre/overview?from=${from}&to=${to}`;
};

// Permissions
export const permissionsRoute = '/api/v1/permissions/all'
export const operatorPermissionsRoute = '/api/v1/users/all'

// Roles
export const rolesRoute = '/api/v1/roles'

// machine
export const getAllStationsRoute = "/api/v1/station/all-stations";
export const getStationsRoute = "/api/v1/station/get-all-devices";
export const getStationTasksRoute = "/api/v1/tasks/classes";
export const getStationDeviceClassesRoute = "/api/v1/tasks/device/class-list";
export const saveTaskRoute = "/api/v1/tasks/create";
export const saveSingleTaskRoute = "/api/v1/tasks/create-single";

export const deleteTaskRoute = (id) => {
  return `/api/v1/tasks/delete_task/${id}`;
};

export const taskOverviewRoute = (station_barcode, start_date, end_date) => {
  return `/api/v1/tasks/overview/task-overview?station_barcode=${station_barcode}&start_date=${start_date}&end_date=${end_date}`;
};
export const faultsOverviewRoute = (station_barcode, start_date, end_date) => {
  const startDate = start_date && start_date.split(" ")[0];
  const endDate = end_date && end_date.split(" ")[0];
  return `/api/v1/tasks/overview/fault-overview?station_barcode=${station_barcode}&start_date=${startDate}&end_date=${endDate}`;
};
export const downloadCsvRoute = (station, start_date, end_date, type) => {
  const startDate = start_date && start_date.split(" ")[0];
  const endDate = end_date && end_date.split(" ")[0];
  return `api/v1/tasks/overview/download?station_barcode=${station}&start_date=${startDate}&end_date=${endDate}&type=${type}`;
};

export const getTasksByDeviceTypeAndClassRoute = "/api/v1/tasks/devices";
export const reportFaultRoute = "/api/v1/tasks/faults/report";
export const indicateFaultSeverityRoute =
    "/api/v1/fault-severity-questions/indicate-severity";
export const startTaskRoute = "/api/v1/tasks/start";
export const endTaskRoute = "/api/v1/tasks/end";
export const getUsersRoute = "/api/v1/users";
export const getOperatorsRoute = "/api/v1/users/operators";
export const getOperatorsActivityRoute = "/api/v1/operator-activity";
export const getOperatorsTableRoute = "/api/v1/operator-table";
export const getOperatorsEfficiencyRoute = "/api/v1/operator-efficiency-chart";
export const getOperatorsComparisonRoute = "/api/v1/operator-comparison-chart";
export const getWeeklyDepartmentSummaryRoute = "/api/v1/weekly-department-summary";
export const getStationActivityRoute = "/api/v1/station-activity";
export const getStationProductActivityRoute = "/api/v1/station-product-activity";
export const getStationEfficiencyChartRoute = "/api/v1/station-efficiency-chart";
export const getFaultsByStatusRoute = "/api/v1/tasks/faults-by-status";
export const updateFaultRoute = "/api/v1/tasks/faults/log";
export const assignFaultRoute = "/api/v1/tasks/faults/assign";
export const faultFeedbackRoute = "api/v1/tasks/faults/rating";

// count down target

export const getEndBusinessDayRoute =
    "/api/v1/countdown-component/end-working-day";
export const getItemsToProduceRoute = "/api/v1/countdown-component/lines-today";

// press release analytics
export const getConsolidationsModalPopupRoute = "/api/v1/consolidations/all";

export const getPrintersModalPopupRoute = "/api/v1/submissions/options"
export const batchPrintRoute = "/api/v1/submissions/batch-print"
export const estimatePrintTimeForBatchesRoute = "/api/v1/printers/estimate-print-time-batches"
export const specialOptionsForPrinterRoute = "/api/v1/printers/special-options";
// analytics routes

export const createUserRoute = "/api/v1/data-api/users";
export const createStationRoute = "/api/v1/data-api/stations";
// pre dispatch

export const getPreDispatchOverview = (department_id) => {
  return department_id
         ? `api/v1/browse/pre-dispatch?department_id=${department_id}`
         : "api/v1/browse/pre-dispatch";
};

// thermal label
export const getThermalLabel = (id) => {
  return `/api/v1/thermal-label/${id}`;
};

// panels

export const failPanelRoute = (params) => {
  return `/api/v1/ui/panels/fails?${params}`;
};
export const wipPanelRoute = (params) => {
  return `/api/v1/ui/panels/in-progress?${params}`;
};
export const productFlowReporterRoute = (params) => {
  return `/api/v1/ui/panels/product-flow-reporter?${params}`;
};
export const holdPanelRoute = "/api/v1/ui/panels/lines/on-hold";
export const holdPanelLockSetting  = "/api/v1/setting/get-hold-panel-lock-settings"
export const dispatchPanelRoute = "/api/v1/ui/panels/dispatch";
export const holdDepartmentOrdersPanelRoute = (slug = null) => {
  return slug
         ? `/api/v1/ui/panels/orders/on-hold/department/${slug}`
         : "/api/v1/ui/panels/orders/on-hold";
};
export const releaseOrder = (orderNumber) => {
  return `/api/v1/orders/${orderNumber}/on-hold?status=0`;
};
export const changeOrderLockRoute = (orderNumber) => {
  return `/api/v1/orders/${orderNumber}/change-lock`;
};

export const getAllDepartments = "/api/v1/departments/all";
export const getAllSummaryDashboards = "api/v1/summary-dashboard/summary";
export const getAllVendors = "/api/v1/vendors/all";
export const createDepartmentsRoute = "/api/v1/departments";
export const getHealthCheckListRoute = "/api/v1/products/get-healthcheck";
export const getHealthCheckResultRoute = (id) => {
  return `/api/v1/ui/panels/healthcheck?healthcheck=${id}`;
};
export const deleteConsignmentRoute = (id) => {
  return `/api/v1/consignments/${id}`;
};

export const getQaFailFormRoute = (ids) => {
  return `/api/v1/blocks/ids/${ids}/qa-fail-form`;
};

export const getQaFailFormBatchRoute = (ids) => {
  return `/api/v1/batches/${ids}/fail`
}

export const getQaFailFormBatchRoutePost = (ids) => {
  return `/api/v1/batches/${ids}/fail-batch`
}

export const qaFailMultipleBatch = (ids) => {
  return `/api/v1/batches/${ids}/fail`
}

export const getQaFailFormLineRoute = (ids) => {
  return `/api/v1/lines/ids/${ids}/qa-fail-form`;
};
export const getCheckoutRoute = "/api/v1/orders/checked-out";

export const getInOutPanelRoute = "/api/v1/ui/panels/in-out";

export const failTransactionsPanelRoute = (params) => {
  return `/api/transactions/failed/status?${params}`;
};

export const retryTransactionsPanelRoute = (client_number) => {
  return `/api/transaction/retry-submission/${client_number}`;
};
// targets

export const getDailyTargets = "/api/v1/targets/daily-targets";

export const myWorkToday = "/api/v1/my_work_today";

// analytics export csv

export const exportSummaryCsvRoute = "/unique";
export const matrixDataRoute = "/matrix_data";

// analytics

export const waitingForPartnersRoute = "/api/v1/analytics/waiting-for-partners";
export const manualBatchingWaitingForPartnersRoute =
    "/api/v1/batches/manual-batch";
export const pressReleaseRoute = "/api/v1/analytics/press-release";
export const pressReleaseRouteFilter = "/api/v1/analytics/press-release-filter";
export const pressReleaseRouteSorting = "/api/v1/analytics/press-release-sorting";

// analytics - press release resets
export const pressReleaseSentReset = "/api/v1/analytics/press-release-reset";
export const pressReleaseSentResetAll = "/api/v1/analytics/press-release-reset-all";

export const workOrderRoute = "/api/v1/analytics/work-order";
export const workOrderPrintRoute = "/api/v1/analytics/work-order/print";

export const pressReleaseLimitRoute = (slug) => {
  return "/api/v1/analytics/press-release-limit?slug=" + slug;
}

// editable settings

export const getEditableSettingsRoute = "/api/v1/setting/get-editable-settings";
export const editSettingsRoute = (id) => {
  return `/api/v1/setting/${id}`;
};
export const updateBusinessHoursRoute = (id) => {
  return `/api/v1/setting/work-days-schedule/${id}`;
};
export const deleteSettingsRoute = (id) => {
  return `/api/v1/setting/${id}`;
};
export const getLabelsRoute = (resource) => {
  return `/api/v1/analytics/labels/${resource}`;
};

// data list

export const dataListRoute = (page) => {
  return `/api/v1/data-list?page=${page}`;
};

// client specific data list

export const dataListClientRoute = (page) => {
  return `/api/v1/data-list/client?page=${page}`;
};

// cell activity

export const cellActivityRoute = (cell, date) => {
  return `/api/v1/cell-activity?date=${date}&cell=${cell}`;
};
export const getAllCells = '/api/v1/all-cells'

// manifest

export const manifestInitialDataRoute = "/api/v1/ui/init-data/manifest";
export const manifestInitiateRoute = (code) => {
  return `/api/v1/logistics-providers/${code}/manifests/initiate`;
};

// workflow rules

export const workFlowInitData = "/api/v1/ui/init-data/work-flow";
export const createTriggerRoute = "/api/v1/triggers";
export const triggerRoute = (id) => {
  return `/api/v1/triggers/${id}`;
};
export const allEventsInSingleTrigger = (id) => {
  return `/api/v1/triggers/${id}/events`;
};
export const singleEventsInSingleTrigger = (trigger_id, event_id) => {
  return `/api/v1/triggers/${trigger_id}/events/${event_id}`;
};
export const addSingleEventsInSingleTrigger = (trigger_id) => {
  return `/api/v1/triggers/${trigger_id}/events`;
};
export const manifestReprintRoute = (id) => {
  return `/api/v1/manifests/${id}/reprint`;
};

// accessories

export const addAccessoriesRoute = "/api/v1/accessories";
export const getSingleAccessoryRoute = (code) => {
  return `/api/v1/accessories/${code}`;
};
export const editSingleAccessoryRoute = (id) => {
  return `/api/v1/accessories/${id}`;
};

export const returnToProductionExternalRoute = (id, token) => {
  return `api/v1/orders/${id}/return-to-production?token=${token}`;
};

// bom
export const bomInitdataRoute = "/api/v1/ui/init-data/bom";
export const reportRoute = "/api/v1/bom/reports";
export const manualConsumptionRoute = "/api/v1/bom/consumption";

export const uploadConsumptionRoute = "/api/v1/bom/consumption/upload";

// bom parts
export const bomPartsRoute = "/api/v1/bom/parts";

export const getSinglePartDataRoute = (id) => {
  return `/api/v1/bom/parts/${id}`;
};
export const getAllSinglePartInventoriesDataRoute = (id) => {
  return `/api/v1/bom/parts/${id}/inventory`;
};
export const getSinglePartInventoryDataRoute = (partId, inventoryid) => {
  return `/api/v1/bom/parts/${partId}/inventory/${inventoryid}`;
};
export const getHistoryPartsDataRoute = (partId) => {
  return `/api/v1/bom/parts/${partId}/history`;
};

// bom locations
export const getAllBomLocationsRoute = "/api/v1/bom/locations";

export const getSingleLocationDataRoute = (id) => {
  return `/api/v1/bom/locations/${id}`;
};
export const getAllSingleLocationPartsDataRoute = (id) => {
  return `/api/v1/bom/locations/${id}/inventory`;
};
export const getSingleLocationPartsDataRoute = (LocationId, partId) => {
  return `/api/v1/bom/locations/${LocationId}/inventory/${partId}`;
};

export const getHistoryLocationDataRoute = (locationId) => {
  return `/api/v1/bom/locations/${locationId}/history`;
};

// bom workflow

export const getSingleWorkDataRoute = (id) => {
  return `/api/v1/bom/workflows/${id}`;
};

export const getBySettingUrlPopulate = (setting, param) => {
  return `/api/v1/setting/get-by-setting/${setting}/${param}`;
};

//autorun
export const getAllAutoRunLocationsRoute = '/api/v1/run/locations'
export const getRecentRunStatusRoute = location_id =>
    `/api/v1/run/log/${location_id}`
export const getDailyRunLogRoute = location_id =>
    `/api/v1/run/logfile/${location_id}`
export const getLocationRunStatusRoute = id =>
    `/api/v1/run/location_status/${id}`
export const updateLocationRunStatusRoute = id =>
    `/api/v1/run/location_toggle/${id}`
export const getAutoRunsInLocationRoute = location_id =>
    `/api/v1/run/runs/${location_id}`
export const getRunProductsRoute = '/api/v1/run/products'
export const getRunPrintersRoute = (id) => `/api/v1/run/printers/${id}`
export const updateAutoRunsInLocationRoute = '/api/v1/run/autorun'
export const getModelRoute = '/api/v1/run/models'
export const fetchSpecialOptionsRoute = (printer_id) => `/api/v1/printers/special-options?printer_id=${printer_id}`

// autorun printer overview
export const fetchAutoRunOverviewInitData = (location_id) => `/api/v1/autorun-printer-overview/init-data/${location_id}`
export const updateAutoRunOverviewInitData = `/api/v1/autorun-printer-overview`



// Weekly Department Summary
export const fetchWeeklyCellDepartmentSummaryRoute = (department_id) => `/api/v1/weekly-cell-department?department_id=${department_id}`

export const getDepartmentWipSummary = (department_id) => `/api/v1/departments/${department_id}/wip-summary`;
export const getDepartmentWipSummaryMetaData = (department_id) => `/api/v1/departments/${department_id}/wip-summary-meta-data`;
export const getDepartmentWipSummaryData = (department_id) => `/api/v1/departments/${department_id}/wip-summary-data`;


// Uchida
export const fetchSheetCount = `/api/v1/printers/get_sheet_counts`

//Quiz/Gamification
export const checkProductQuiz = `/api/v1/quiz/check`
export const getAllQuiz = (value) => `/api/v1/quiz/?search=${value}`
// batch release designer configuration

export const fetchConfigurationRoute = `/api/v1/batching-configurations`
export const fetchConfigurationInitDataRoute = `/api/v1/ui/init-data/batching-configuration`
export const saveNewSorterConfigurationRoute = (configurationId) => `/api/v1/batching-configurations/${configurationId}/sorters`
export const sorterConfigurationRoute = (sorterId) => `/api/v1/batching-configurations/sorters/${sorterId}`

export const saveNewFilterConfigurationRoute = (configurationId) => `/api/v1/batching-configurations/${configurationId}/filters`
export const filterConfigurationRoute = (sorterId) => `/api/v1/batching-configurations/filters/${sorterId}`

// printer assignment
export const fetchPrinterAssignmentRoute = `/api/v1/ui/init-data/printer-assignment`
export const assignConfigurationToPrinterRoute = (configurationId, printerId) => `/api/v1/printers/${printerId}/batching-configurations/${configurationId}`
export const deleteConfigurationToPrinterRoute = (printerId) => `/api/v1/printers/${printerId}/batching-configurations`

// jit batching

export const fetchJitInitDataRoute = (printerLocationId) => `api/v1/jit-batching/printer-locations/${printerLocationId}/printers`
export const fetchJitInitProductsRoute = (printerLocationId, configurationId) => `api/v1/jit-batching/printer-products/${printerLocationId}/${configurationId}/products`
export const printerOutputBatchesEndpoint = `api/v1/jit-batching/release-to-print/output`
export const updateCustomConfigurationFiltersRoute = `api/v1/jit-batching/update-custom-configuration-filters`
export const fetchPresetConfigFieldsRoute = `api/v1/jit-batching/get-product-preset-config`

// station scantype
export const getAllStationScanType = `/api/v1/scan-types/all`
export const getStationScanType = (id) => `api/v1/station/${id}/scan-types`
export const removeStationScanType = (stationId, scanId) => `api/v1/station/${stationId}/scan-types/${scanId}`


// viesus rule

export const fetchViesusRuleInitDataRoute = `/api/v1/ui/init-data/viesus-rule`
export const viesusRuleRoute = `/api/v1/viesus-rules`


// Product Template

export const fetchProductTemplateList = `/api/v1/products/templates/get-templates`
export const addProductTemplateRoute = `/api/v1/products/templates/add-templates`


// multicut rule
export const fetchMultiCutRuleInitDataRoute = `/api/v1/ui/init-data/multicut-rule`
export const multicutRuleRoute = `/api/v1/multicut-rules`


// imposition rule
export const fetchImpositionRuleInitDataRoute = `/api/v1/ui/init-data/imposition-rule`
export const impositionRuleRoute = `/api/v1/imposition-rules`
export const getHelpLinkSettingRoute = "/api/v1/setting/get-help-link";

// post dispatch message
export const getAllPostDispatchMessages = '/api/v1/post-dispatch-messages'
export const editPostDispatchMessagesRoute = (id) => {
    return `/api/v1/post-dispatch-messages/update/${id}`;
};
export const addPostDispatchMessagesRoute = '/api/v1/post-dispatch-messages/add'
export const getAllTemplates = '/api/v1/templates/all'
export const deletePostDispatchMessage = id => {
    return `/api/v1/post-dispatch-messages/delete/${id}`
}

// Product dropdown
export const incrementProductSearchHitsRoute = (id) => {
  return `/api/v1/ui/products/${id}/hits`
}
export const getProductDropdownRoute = (value='', limit=20) => {
  return `/api/v1/ui/products/dropdown?query=${value}&limit=${limit}`
}
export const getMatchOverview = (department_id) => {
  return department_id
         ? `api/v1/browse/match-overview?department_id=${department_id}`
         : "api/v1/browse/match-overview";
};
export const clearPigeonHole = (type) => {
  return `api/v1/clear-pigeonhole/${type}`
};

export const getQaFailReason = (id) => {
  return `/api/v1/qa-fail-reasons/${id}`;
};

export const getCancelReason = (id) => {
  return `/api/v1/cancel-reasons/${id}`;
};

export const getCheckoutReason = (id) => {
  return `/api/v1/checkout-reason/${id}`;
};

export const getLabelTemplate = (id) => {
  return `/api/v1/label-templates/${id}`;
};
export const getEmailTemplate = (id) => {
  return `/api/v1/email-templates/${id}`;
};
export const getPostbacksRoute = "/api/v1/order-postback-requests/list-all";

export const resendPostbackRoute = (id) => {
  return `/api/v1/order-postback-requests/${id}/resend`;
};
