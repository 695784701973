import React from "react";
import { notification, Select } from "antd";
import is from "is_js";
import { ZenSmartAPI } from "utils";
import { finishingTypesRoute } from "utils/apiRoutes";
import {logError} from "utils/logging";
import { alphabeticalData } from 'utils/sortData';

const FinishingTypeSelect = (props) => {
  const {
    headerField,
    invokeSelect,
    queryProductBlockPrinter,
    clearHeaderFields,
    updateHeaderFields,
    setCheckBox,
    setQaFailCheckBox
  } = props;

  const finishingTypeChange = (value) => {
    invokeSelect(finishingTypeSelect, value);
  };

  const finishingTypeSelect = (value, headerFieldsState) => {
    clearHeaderFields();

    const searchFinishingType = is.existy(headerFieldsState)
      ? headerFieldsState.finishingTypes
      : headerField.finishingTypes;

    const filteredFinishingType = searchFinishingType.find(
      (finishingType) => finishingType.id === value
    );

    let headerState = is.existy(headerFieldsState) ? {
      ...headerFieldsState,
      defaultPrinter: false,
      productBlockPrinter: null,
    } : {
      ...headerField,
      finishingType: value,
      finishingTypeText: filteredFinishingType.name,
      defaultPrinter: false,
      productBlockPrinter: null,
    };

    updateHeaderFields(headerState);
    setCheckBox(false);
    setQaFailCheckBox(false);

    queryProductBlockPrinter(
      headerField.product,
      headerField.block,
      headerField.printer,
      value,
      headerState
    );
  };

  const finishingTypeKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      const finishingType = evt.target.value;

      const finishingTypeData = headerField.finishingTypes.find(
        (ftype) => ftype.name === finishingType
      );

      if (is.existy(finishingTypeData)) {
        updateHeaderFields({
          ...headerField,
          finishingType: finishingTypeData.id,
          finishingTypeText: finishingTypeData.name,
        });
        return;
      }

      if (is.not.existy(finishingTypeData)) {
        clearHeaderFields();

        props.setLoading(true);
        ZenSmartAPI.post(finishingTypesRoute, {
          name: finishingType,
        })
          .then((response) => {
            const responseId = response.data.data.id;
            const updatedHeader = {
              ...headerField,
              finishingType: responseId,
              finishingTypeText: finishingType,
              finishingTypes: [
                ...headerField.finishingTypes,
                { id: responseId, name: finishingType },
              ],
            };
            updateHeaderFields(updatedHeader);
            invokeSelect(finishingTypeSelect, responseId, updatedHeader);
            notification.info({
              message: "Finishing Type created",
            });
          })
          .catch((err) => {
            logError(err, true);
          })
          .finally(() => {
            props.setLoading(false);
          });
      }
    }
  };

  return (
    <>
      <span className="Header-field-finishing">
        <span className="Header-boldText">Finishing Type: </span>
        <Select
          showSearch
          className="Header-select"
          value={
            is.not.empty(headerField.finishingTypes)
              ? headerField.finishingType
              : null
          }
          optionFilterProp="children"
          filterOption={true}
          onInputKeyDown={(event) => finishingTypeKeyDown(event)}
          onChange={(value) => finishingTypeChange(value)}
        >
          {alphabeticalData(headerField.finishingTypes, "name").map((finishingType) => {
            return (
              <Select.Option key={finishingType.id} value={finishingType.id}>
                {finishingType.name}
              </Select.Option>
            );
          })}
        </Select>
      </span>
    </>
  );
};

export default FinishingTypeSelect;
