import { GET_TITLE } from "../actions/globalTypes";

const initialState = {
    title: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TITLE:
            return {
                ...state,
                title: action.payload
            };
        default:
            return state;
    }
}