import {useEffect, useRef} from 'react'

function usePreviousValue(value) {
  const previousValue = useRef()
  useEffect(() => {
    previousValue.current = value
  })
  return previousValue.current
}

export default usePreviousValue