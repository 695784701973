import React, { useState, useEffect } from 'react'
import { Modal, Table, Dropdown, Button, Menu, notification, Select } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { printOrDeleteSingleSubmissionRoute, printOrDeleteMasterSubmissionRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils';
import { SubmissionChangeModal } from '.'
import * as _ from 'lodash';

function submissionChangeModal(props) {
    const { submissionChangeModal, setSubmissionChangeModal, finishingTypeData, submissionChangePayload, setSubmissionChangePayload,
        changeSubmission, updatePrinterTimeline, printerData, setOption, options } = props

    const changeFinishingType = (value) => {
        setSubmissionChangePayload({ ...submissionChangePayload, finishing_type_id: value })
    }
    return (
        <Modal visible={submissionChangeModal} onCancel={() => {
            setSubmissionChangeModal(false);
            setSubmissionChangePayload({});
            setOption({ ...options, start: null, end: null })
            updatePrinterTimeline(true)
        }} centered title={"Submission change"}
            onOk={() => changeSubmission(submissionChangePayload)}
        >
            {submissionChangeModal && submissionChangePayload &&
                <div>
                    <h1><span style={{ fontWeight: "bold" }}>From:</span> {printerData && printerData.data.find(item => item.id === _.get(submissionChangePayload, 'old_submission_data.printer_id')).full_name} {moment.unix(_.get(submissionChangePayload, 'old_submission_data.send_time')).format('LLL')}</h1>
                    <h1><span style={{ fontWeight: "bold" }}>To:</span> {printerData && printerData.data.find(item => item.id === _.get(submissionChangePayload, 'printer_id')).full_name} {moment.unix(_.get(submissionChangePayload, 'new_time')).format('LLL')}</h1>
                    <Select optionFilterProp="children"
                        showSearch style={{ width: "100%", padding: "20px 0px 0px 0px" }}
                        onChange={changeFinishingType}
                        value={submissionChangePayload.finishing_type_id}
                        placeholder={"Select finishing type..."}>
                        {finishingTypeData.map((data, key) =>
                            <Select.Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name}</Select.Option>
                        )}
                    </Select>
                </div>
            }
        </Modal>
    )
}

export default submissionChangeModal