import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Tag, Row, Col, Checkbox } from 'antd'
import { panelSortData } from 'utils/sortData'
import moment from 'moment';
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { productFlowReporterInitialData } from "utils/panelsInitialData"

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const DueDateTag = styled(Tag)`
    padding : 5px 17px;
    font-size : 12px;
`

const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

.selected-row > td {
    background: none !important;
}
.selected-row {
   background: rgba(11, 107, 242, 0.5) !important;
}
`
const dueDateColor = {
    later: "purple",
    today: "blue",
    tomorrow: "green"
}
const TableData = (props) => {

    const { itemData, loading, data, setData, ascend, setAscend, selectedCheckBoxValue, paginationNumber, setSelectedCheckBoxValue, paginationSize, checkPaginationIfAllSelected , scans , menu, setMenu } = props

    const sortingData = (data, property, ifNotProperty) => {
        if (ascend[property] === null) {
            setAscend({ ...productFlowReporterInitialData, [property]: true })
        }
        else {
            ascend[property] === true ? setAscend({ ...productFlowReporterInitialData, [property]: false }) : setAscend({ ...productFlowReporterInitialData, [property]: true })
        }

        setData(panelSortData(data, property, ascend[property], ifNotProperty))
        checkPaginationIfAllSelected(paginationNumber)
    }
    
    const getHeaders = (property, text, ifAscend) => {
        return (
            <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifAscend)} style={{ cursor: "pointer" }}>
                <Col span={ascend[property] === null ? 24 : 20}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }

    const applySelectedCss = (record, index) => {
        var elements = document.querySelectorAll(`[data-row-key="${index}"]`)[0]
        if (elements) {

            if (selectedCheckBoxValue[record]) {
                elements.classList.add("selected-row");
            }
            else {
                elements.classList.remove("selected-row");
            }
        }
    }


    const selectValue = (value, record, index) => {

        if (value.target.checked) {
            setSelectedCheckBoxValue({ ...selectedCheckBoxValue, [record]: true })

        }
        else {
            setSelectedCheckBoxValue({ ...selectedCheckBoxValue, [record]: false })

        }
    }
    const columns = [
        {
            title: getHeaders("transaction", "TRANSACTION", true),
            dataIndex: 'transaction',
            key: 'transaction',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: row.due_date_text && row.due_date_text.toLowerCase() === "overdue" ? "red" : "red", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("order_number", "ORDER", true),
            dataIndex: 'order_number',
            key: 'order_number',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: row.due_date && row.due_date.toLowerCase()=== "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("line_id", "LINE", true),
            dataIndex: 'line_id',
            key: 'line_id',

            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: row.due_date && row.due_date.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("block", "BLOCK", true),
            dataIndex: 'block',
            key: 'block',

            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: row.due_date && row.due_date.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("code", "PRODUCT", true),
            dataIndex: 'code',
            key: 'code',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: row.due_date && row.due_date.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(value ? value : "-", index, row)
            }
        },{

            title: getHeaders("block_type", "BLOCK TYPE", false),
            dataIndex: 'block_type',
            key: 'block_type',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("net_production_days", "NPD", false),
            dataIndex: 'net_production_days',
            key: 'net_production_days',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },

        },
        {
            title: getHeaders("due_date", "DUE DATE", false),
            dataIndex: 'due_date',
            key: 'due_date',
            render(record, row, index) {
                const value = <DueDateTag color={dueDateColor[row && row.due_date_text.toLowerCase()] ?
                    dueDateColor[ row && row.due_date_text.toLowerCase()] : "red"}
                    key={record}>
                    {record && record.toUpperCase()}
                </DueDateTag>
                return rowStyle(record ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("stage", "CURRENT STAGE", false),
            dataIndex: 'stage',
            key: 'stage',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        }
    ];
    const newColumns = () => {
        let newColumnHeader = [
            {
                title: getHeaders("transaction", "TRANSACTION", true),
                dataIndex: 'transaction',
                key: 'transaction',
                render: (record, row, index) => {
                    const value = <p><a href={`/${record.link}`} style={{ color: row.due_date_text && row.due_date_text.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                    return rowStyle(record.value ? value : "-", index, row)
                }
            },
            {
                title: getHeaders("order_number", "ORDER", true),
                dataIndex: 'order_number',
                key: 'order_number',
                render: (record, row, index) => {
                    const value = <p><a href={`/${record.link}`} style={{ color: row.due_date_text && row.due_date_text.toLowerCase()=== "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                    return rowStyle(record.value ? value : "-", index, row)
                }
            },
            {
                title: getHeaders("line_id", "LINE", true),
                dataIndex: 'line_id',
                key: 'line_id',
    
                render: (record, row, index) => {
                    const value = <p><a href={`/${record.link}`} style={{ color: row.due_date_text && row.due_date_text.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                    return rowStyle(record.value ? value : "-", index, row)
                }
            },
            {
                title: getHeaders("block", "BLOCK", true),
                dataIndex: 'block',
                key: 'block',
    
                render: (record, row, index) => {
                    const value = <p><a href={`/${record.link}`} style={{ color: row.due_date_text && row.due_date_text.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                    return rowStyle(record.value ? value : "-", index, row)
                }
            },
            {
                title: getHeaders("code", "PRODUCT", true),
                dataIndex: 'code',
                key: 'code',
                render: (record, row, index) => {
                    const value = <p><a href={`/${record.link}`} style={{ color: row.due_date_text && row.due_date_text.toLowerCase() === "overdue" ? "red" : "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                    return rowStyle(value ? value : "-", index, row)
                }
            },
            {

                title: getHeaders("block_type", "BLOCK TYPE", false),
                dataIndex: 'block_type',
                key: 'block_type',
                render(value, row, index) {
                    return rowStyle(value ? value : "-", index, row)
                },
            },
            {
                title: getHeaders("net_production_days", "NPD", false),
                dataIndex: 'net_production_days',
                key: 'net_production_days',
                render(value, row, index) {
                    return rowStyle(value ? value : "-", index, row)
                },
    
            },
            {
                title: getHeaders("due_date", "DUE DATE", false),
                dataIndex: 'due_date',
                key: 'due_date',
                render(record, row, index) {
                    const value = <DueDateTag color={dueDateColor[row.due_date_text && row.due_date_text.toLowerCase()] ?
                        dueDateColor[row.due_date_text && row.due_date_text.toLowerCase()] : "red"}
                        key={record}>
                        {record && record.toUpperCase()}
                    </DueDateTag>
                    return rowStyle(record ? value : "-", index, row)
                },
            },
            {
                title: getHeaders("stage", "STAGE", false),
                dataIndex: 'stage',
                key: 'stage',
                render(value, row, index) {
                    return rowStyle(value ? value : "-", index, row)
                },
            }
        ];
        Array.prototype.push.apply(newColumnHeader,menu); 
        return newColumnHeader; 
    }

    const rowStyle = (value, index, row) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }

        if (row.due_date && row.due_date.toLowerCase() === "overdue") {
            obj.props.style.background = "#faeeed"
        }

        return obj
    }

    useEffect(() => {
        if(itemData.menu){
            if(itemData.menu.length !== 0){
                const uniqueMenu = itemData.menu.filter(function(item, pos) {
                    return itemData.menu.indexOf(item) == pos;
                })
    
                const itemMenu = uniqueMenu.map(data => {
                    return {
                        title: getHeaders(data.name, data.title, false),
                        dataIndex: data.name,
                        key: data.name,
                        render(value, row, index) {
                            return rowStyle(value ? value : "-", index, row)
                        }
                    }
                })
                setMenu(itemMenu)
            }else{
                setMenu([])
            }
        }
        
        if (itemData.data) {
            const itemObj = itemData.data.map(data => {
                const obj = {
                    check_box: data.order_number,
                    id: data.id,
                    vendor_name: data.vendor_name,
                    transaction: data.transaction,
                    order_number: data.order_number,
                    line_id: data.line_id,
                    block: data.block,
                    box_number: data.box_number,
                    dispatch_method_code: data.dispatch_method_code,
                    code: data.code,
                    due_date: data.due_date,
                    due_date_text: data.due_date_text,
                    stage: data.stage,
                    stage_time: data.stage_time,
                    hrs_at_stage: data.hrs_at_stage,
                    order_created: data.order_created,
                    queued_for_batching: data.queued_for_batching,
                    ready_for_release: data.ready_for_release,
                    imposition: data.imposition,
                    at_press : data.at_press,
                    print_submit : data.print_submit,
                    printed : data.printed,
                    cut: data.cut,
                    prep: data.prep,
                    logout: data.logout,
                    pre_dispatch : data.pre_dispatch,
                    dispatch: data.dispatch,
                    complete: data.complete,
                    qa_fail: data.qa_fail,
                    picking: data.picking,
                    failed_dispatch: data.failed_dispatch,
                    dispatch_cancelled : data.dispatch_cancelled,
                    checked_out : data.checked_out,
                    cancelled : data.cancelled,
                    imposition : data.imposition,
                    qa_success : data.qa_success,
                    qc_success : data.qc_success,
                    pre_UV : data.pre_UV,
                    pre_trim : data.pre_trim,
                    sorted : data.sorted,
                    match_in : data.match_in,
                    match_out : data.match_out,
                    case_in : data.case_in,
                    post_PUR : data.post_PUR,
                    final_trim : data.final_trim,
                    punched : data.punched,
                    complete : data.complete,
                    laminated : data.laminated,
                    made : data.made,
                    release : data.release,
                    ticketed : data.ticketed,
                    rip_submit : data.rip_submit,
                    rip_complete : data.rip_complete,
                    enter_bindery : data.enter_bindery,
                    block_type : data.block_type,
                    net_production_days : data.net_production_days,
                }
                return obj;
            })
            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table

                    loading={loading}
                    columns={(menu.length !== 0 ? newColumns() : columns )}
                    dataSource={data}
                    bordered
                    size="middle"
                    pagination={{ pageSize: paginationSize, onChange: (e) => checkPaginationIfAllSelected(e) }}

                />
            </StyleTable>
        </>
    )
}

export default TableData