import React, { useEffect, useState } from 'react';
import { Header, Title } from 'components/primaries';
import styled, { createGlobalStyle } from 'styled-components'
import TableData from './TableData';
import PostDispatchMessageUpdateModal from './PostDispatchMessageUpdateModal.js'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Checkbox, Button, Input, Modal, Select } from 'antd'
import { getAllPostDispatchMessages , getAllVendors , getAllTemplates } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'


const postInitialData = {
    id: null,
    vendor: null,
    template: null,
    type: null,
    template_id: null,
    vendor_id: null,
}

const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`

const PostDispatchMessage = () => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [ascend, setAscend] = useState(postInitialData)
    const [postDispatchMessageModalName, setPostDispatchMessageModalName] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [vendors, setVendors] = useState([])
    const [templates, setTemplates] = useState([])
    const [formInitialValue, setFormInitialValue] = useState({})
    const [originalData, setOriginalData] = useState([])
    const [searchText, setSearchText] = useState(null)
    
    const fetchData = () => {
        setLoading(true)
        setFormInitialValue(postInitialData)
        ZenSmartAPI.get(getAllPostDispatchMessages)
        .then((res) => {
            setLoading(false)
            setOriginalData(res.data.data)
            if(searchText !== null){
                search(searchText)
            }else{
                setItemData(res.data.data)
            }
            
        })
        .catch((error) => {
            console.log('error', error)
            setLoading(false)
        })
    }

    const postDispatchMessage = () => {
        setShowModal(true)
        setPostDispatchMessageModalName('Add New Post Dispatch Message')
    }

    

    const fetchVendors = () => {

        setLoading(true)
        ZenSmartAPI.get(getAllVendors)
            .then((res) => {
                setLoading(false)
                setVendors(res.data.data)

            })
            .catch((res) => {
                setLoading(false)
            })

    }

    const fetchTemplates = () => {
        setLoading(true)
        ZenSmartAPI.get(getAllTemplates)
            .then((res) => {
                setLoading(false)
                setTemplates(res.data.data)

            })
            .catch((res) => {
                setLoading(false)
            })
    }
    
    useEffect(() => {
        fetchVendors()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchTemplates()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    React.useMemo(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const search = value => {
        setSearchText(value)
        let filterTable = originalData.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(value.toLowerCase())
          )
        );
        setItemData(filterTable)
    };

    return (
        <>
            <Header>
                <Title>App Setup {">"} Post Dispatch Message</Title>
            </Header>
            <Panel title="Post Dispatch Prompt Message">
                <div style={{ padding: 40 }}>
                    <Row type="flex">
                            <Col span={4}>
                                <InputStyle />
                                <Input.Search
                                    type="text"
                                    placeholder="Filters.."
                                    disabled={loading}
                                    onSearch={search}
                                    size="large"
                                    loading={loading}
                                />
                            </Col>
                            <Col span={4} offset={16}>
                                <p style={{ textAlign: "center" }}></p>
                                <Button 
                                    type="primary" 
                                    loading={loading}  
                                    style={{ minWidth: "100%" }}
                                    onClick={() => { postDispatchMessage() }}
                                >Add New</Button>
                            </Col>
                    </Row>
                </div>
                <TableData
                    itemData={itemData}
                    loading={loading}
                    setLoading={setLoading}
                    data={data}
                    setData={setData}
                    ascend={ascend}
                    setAscend={setAscend}
                    setPostDispatchMessageModalName={setPostDispatchMessageModalName}
                    setShowModal={setShowModal}
                    setFormInitialValue={setFormInitialValue}
                    fetchData={fetchData}
                />
                <PostDispatchMessageUpdateModal 
                    visible={showModal}
                    setShowModal={setShowModal}
                    formInitialValue={formInitialValue}
                    templates={templates}
                    vendors={vendors}
                    postDispatchMessageModalName={postDispatchMessageModalName}
                    setPostDispatchMessageModalName={setPostDispatchMessageModalName}
                    setFormInitialValue={setFormInitialValue}
                    fetchData={fetchData}
                />
            </Panel>
        </>
    );
};

export default PostDispatchMessage;