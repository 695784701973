import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Modal, Form, Select, Input, Button, notification, Row, Col } from 'antd'
import { modelDefinitionRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'
import debounce from 'lodash/debounce';
import { PROCESS } from 'types'
import QualifierInput
  from '../../../../components/composites/QualifierInput';
const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`

const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`
function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}
const { Option } = Select


function EditForm(props) {
  const {
    form,
    fieldUpdate,
    onSave,
    processID,
    removeTrigger,
    hideModal,
    qualifiers
  } = props

  const {
    getFieldDecorator,
    getFieldsError,
    getFieldsValue
  } = form

  const [fieldValue, setFieldValue] = useState(null)
  const [conditionFieldValue, setConditionFieldValue] = useState(null)
  const [fieldSelectValue, setFieldSelectValue] = useState(null)
  const [conditionFieldSelectValue, setConditionFieldSelectValue] = useState(null)
  const [errors] = useState({});
  const [qualifierValue, setQualifierValue] = useState(null);

  const handleSubmit = e => {
    e.preventDefault()

    const inputValues = getFieldsValue();
    inputValues.field = fieldValue ? fieldValue : fieldUpdate && fieldUpdate.field ? fieldUpdate.field : fieldValue
    inputValues.conditions = [{
      conditionField: conditionFieldValue ? conditionFieldValue : fieldUpdate && fieldUpdate.conditions[0].conditionField ? fieldUpdate.conditions[0].conditionField : conditionFieldValue,
      qualifier: qualifierValue ? qualifierValue : fieldUpdate && fieldUpdate.conditions[0].qualifier ? fieldUpdate.conditions[0].qualifier : qualifierValue
    }]

    onSave(inputValues, PROCESS.TRIGGER.FIELD, processID)
    hideModal()
    notification.success({
      message: 'Saved field update trigger details.'
    })
  }

  const onRemoveTrigger = () => {
    removeTrigger(PROCESS.TRIGGER.FIELD, processID)
  }


  const onSearchField = debounce((value, prefix, inputType) => {
    ZenSmartAPI.get(modelDefinitionRoute(`${prefix}.${value}`), {})
      .then((res) => {
        if (inputType === "condition") {
          setConditionFieldSelectValue(res.data.data)
        }
        else {
          setFieldSelectValue(res.data.data)
        }
      })
  }, 600);

  useEffect(() => {
    onSearchField('', 'scan_logs')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <StyledFormItem label="Field">

        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Enter Field (Click Suggestions to add each field)"
          optionFilterProp="children"
          value={fieldValue ? fieldValue : fieldUpdate && fieldUpdate.field ? fieldUpdate.field : null}
          onChange={(value) => setFieldValue(value)}
          onSearch={(e) => onSearchField(e, "scan_logs")}
        >
          {fieldSelectValue && fieldSelectValue.map(data =>
            <Option value={data.id}>{data.label.replace('scan_logs.', '')}</Option>
          )}
        </Select>
      </StyledFormItem>

      <StyledFormItem label="Field Value">
        {getFieldDecorator('field_value', {
          initialValue: fieldUpdate && fieldUpdate.field_value
        })(<Input />)}
      </StyledFormItem>
      <StyledFormItem label="Conditions">
      </StyledFormItem>

      <Row>
        <Col span={12} style={{ paddingRight: 5 }}>

          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Enter Field (Click Suggestions to add each field)"
            optionFilterProp="children"
            value={conditionFieldValue ? conditionFieldValue : fieldUpdate && fieldUpdate.conditions[0].conditionField ? `scan_logs ${(fieldUpdate.conditions[0].conditionField.replaceAll('.', ' ')).replaceAll('_', ' ')}` : null}
            onChange={(value) => setConditionFieldValue(value)}
            onSearch={(e) => onSearchField(e, "scan_logs", "condition")}
          >
            {conditionFieldSelectValue && conditionFieldSelectValue.map(data =>
              <Option value={data.path}>{data.label.replace('scan_logs.', '')}</Option>
            )}
          </Select>
        </Col>
        <Col span={12} >

          <StyledFormItem validateStatus={errors['qualifier'] && 'error'}
            help={errors['qualifier'] && errors['qualifier'][0]} label="">
            <QualifierInput value={qualifierValue ? qualifierValue : fieldUpdate && fieldUpdate.conditions[0].qualifier ? fieldUpdate.conditions[0].qualifier : qualifierValue}
              onChange={value => setQualifierValue(value)}
              types={qualifiers} />
          </StyledFormItem>
        </Col>
      </Row>

      <ButtonContainer>
        <Button onClick={hideModal}>Cancel</Button>
        {processID && <Button type="danger" onClick={onRemoveTrigger}>Delete</Button>}
        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
        >
          Save Changes
        </Button>
      </ButtonContainer>
    </Form >
  )
}

const EditFieldUpdateTriggerForm = Form.create({
  name: 'Edit Field Update Trigger Form'
})(EditForm)

function FieldUpdateTriggerModal(props) {
  const { fieldUpdate, onSave, processID, qualifiers, removeTrigger, ...modal } = props
  return (
    <Modal {...modal}>
      <EditFieldUpdateTriggerForm
        fieldUpdate={fieldUpdate}
        onSave={onSave}
        processID={processID}
        hideModal={modal.onCancel}
        removeTrigger={removeTrigger}
        qualifiers={qualifiers}
      />
    </Modal>
  )
}

export default FieldUpdateTriggerModal