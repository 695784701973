import moment from 'moment';

const GetDateValues = ($dataType) => {
  switch ($dataType) {
    case 'past':
      return [moment().subtract(30, 'year'), moment().subtract(1, 'day')];
    case 'future':
      return [moment().add(1, 'day'), moment().add(30, 'year')];
    case 'yesterday':
      return [moment().subtract(1, 'day'), moment().subtract(1, 'day')];
    case 'today':
      return [moment(), moment()];
    case 'tomorrow':
      return [moment().add(1, 'day'), moment().add(1, 'day')];
    case 'yesterdayandtoday':
      return [moment().subtract(1, 'day'), moment()];
    case 'last7days':
      return [moment().subtract(7, 'day'), moment()];
    case 'last14days':
      return [moment().subtract(14, 'day'), moment()];
    case 'next7days':
      return [moment(),moment().add(7, 'day')];
    case 'next14days':
      return [moment(),moment().add(14, 'day')];
    case 'last30days':
      return [moment().subtract(30, 'day'), moment()];
    case 'last60days':
      return [moment().subtract(60, 'day'), moment()];
    case 'last90days':
      return [moment().subtract(90, 'day'), moment()];
    case 'last120days':
      return [moment().subtract(120, 'day'), moment()];
    case 'next30days':
      return [moment(), moment().add(30, 'day')];
    case 'next60days':
      return [moment(), moment().add(60, 'day')];
    case 'next90days':
      return [moment(), moment().add(90, 'day')];
    case 'next120days':
      return [moment(), moment().add(120, 'day')];
    case 'thisweek':
      return [moment().startOf('week'), moment().endOf('week')];
    case 'lastweek':
      return [moment().subtract(1,'week').startOf('week'), moment().subtract(1,'week').endOf('week')];
    case 'thisweekandlastweek':
      return [moment().subtract(1,'week').startOf('week'), moment().endOf('week')];
    case 'thismonth':
      return [moment().startOf('month'), moment().endOf('month')];
    case 'lastmonth':
      return [moment().subtract(1,'month').startOf('month'), moment().subtract(1,'month').endOf('month')];
    case 'thismonthandlastmonth':
      return [moment().subtract(1,'month').startOf('month'), moment().endOf('month')];
    case 'thismonthandnextmonth':
      return [moment().startOf('month'), moment().add(1, 'month').endOf('month')];      
    case 'nextmonth':
      return [moment().add(1,'month').startOf('month'), moment().add(1,'month').endOf('month')];
    case 'thisyear':
      return [moment().startOf('year'), moment().endOf('year')];
    case 'lastyear':
      return [moment().subtract(1,'year').startOf('year'), moment().subtract(1,'year').endOf('year')];
    case 'thisyearandlastyear':
      return [moment().subtract(1,'year').startOf('year'), moment().endOf('year')];
    case 'twoyearsago':
      return [moment().subtract(2,'year').startOf('year'), moment().subtract(0,'year').endOf('year')];
    case 'thisyearandlasttwoyear':
      return [moment().subtract(2, 'years').startOf('year'), moment().endOf('year')];          
    case 'nextyear':
      return [moment().add(1, 'year').startOf('year'), moment().add(1, 'year').endOf('year')];
    case 'thisyearandnextyear':
      return [moment().startOf('year'), moment().add(1, 'year').endOf('year')]; 

    default:
      return [null,null];
  }
}

export default GetDateValues