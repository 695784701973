import React from 'react'
import styled from 'styled-components'
import { validateSelectedAnswer } from './helper'
import { Spin } from 'antd'

const GameContainer = styled.div`
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  position: relative;
  width: 150%;
  right: 20%;
`
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`

const InnerGameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${props => (props.active ? 1 : 0.8)};
  border: ${props => (props.active ? '#1890fffa' : '#1890ff14')} 3px solid;
  border-radius: 4px;
  margin: 0 10px;
`

export const Title = styled.h1`
  margin-top: 30px;
  color: gray;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  text-align: center;
`
export const QuizText = styled.h2`
  margin-bottom: 20px;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  height: 10rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  text-align: center;
  cursor: pointer;
`
export const Image = styled.img`
  height: 10rem;
  width: 10rem;
  margin-bottom: 20px;
`

export const Spinner = styled(Spin)`
  width: 100%;
  margin: 20px 0;
`

const GameView = ({
  scanGamification,
  setScanGamification,
  resetFields,
  onSubmit
}) => {
  return (
    <>
      {scanGamification.quiz_type === 'thumbnail' ? (
        <Title>SELECT CORRECT IMAGE</Title>
      ) : (
        <Title>SELECT CORRECT VALUE</Title>
      )}
      <GameContainer>
        {scanGamification.gameData.map((item, i) => (
          <InnerGameContainer
            active={item === scanGamification.selectedAnswer}
            key={i}
            onClick={() =>
              validateSelectedAnswer(
                item,
                scanGamification,
                setScanGamification,
                resetFields,
                onSubmit
              )
            }
          >
            {scanGamification.quiz_type === 'thumbnail' ? (
              <Image src={item} />
            ) : (
              <QuizText>{JSON.stringify(item)}</QuizText>
            )}
          </InnerGameContainer>
        ))}
      </GameContainer>
      <BottomContainer>
        <Title>Item {scanGamification.barcode}</Title>
      </BottomContainer>
    </>
  )
}

export default GameView
