import axios from 'axios';

const GetUniqueValues = async (requestData = {
  table: '',
  secondary_filter: [],
  primary_filter: [],
  groups: [],
  fields: [],
  bucket_fields: []
}) => {
  if (requestData.groups.length) {
    let res = await axios.post(`${process.env.REACT_APP_ANALYTICS_SERVER}/unique`, requestData)
    let { data } = res;
    return data;
  }
}

export default GetUniqueValues