import React from 'react'
import {Modal, Form, Input, Button} from 'antd'
import styled from 'styled-components'

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`

const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`

function FormDef(props) {
  const {form, stageData, onSave, onCancel} = props
  const {getFieldDecorator, getFieldsValue} = form

  const handleSubmit = evt => {
    evt.preventDefault()
    onSave({...getFieldsValue(), ...stageData})
  }

  return (
    <Form onSubmit={handleSubmit}>
      <StyledFormItem label="Exception Value">
        {getFieldDecorator('value', {initialValue: stageData.text})(<Input />)}
      </StyledFormItem>

      <ButtonContainer>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" htmlType="submit">Save Changes</Button>
      </ButtonContainer>
    </Form>
  )
}

const FormInstance = Form.create({name: 'BucketFormModal'})(FormDef)

function BucketFormModal(props) {
  const {stageData, onSave, onCancel, ...modal} = props
  return (
    <Modal {...modal} onCancel={onCancel} footer={null}>
      {modal.visible ? (
        <FormInstance stageData={stageData} onSave={onSave} onCancel={onCancel} />
      ) : null}
    </Modal>
  )
}

export default BucketFormModal