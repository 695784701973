import React from 'react';
import { Header, Title } from 'components/primaries';
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'

const Printers = () => {
    return (
        <>
            <Header>
                <Title>Print {">"} Printers</Title>
            </Header>
            <Panel title="PRINTERS">
                <DataListPage model="printers" select={['id', 'full_name', 'created_at', 'updated_at']} link={"app/data-view/printers/"} pageSize={50}
                    addListButton={{
                        buttonLabel: "Add Printer",
                        actionApi: "/api/v1/data-api/printers",
                        offset: 15,
                        span: 3
                    }}
                />
            </Panel>
        </>
    );
};

export default Printers;