import React, { useEffect, useState } from 'react';
import { TABLE_COLUMNS } from '../components/Table/AssociateReportTableHeaderConstants';
import { buildRow } from '../components/Table/TableUtilities';
import AssociateTable from '../components/Table/AssociateTable';

const AssociateProgressReportTable = ({
    activeRows,
    loading,
    data,
    handleRow
}) => {
    const [dataSource, setDataSource] = useState([]);
    const buildDataSource = (data) => {
        return data?.map((data) => {
            const userId = (data?.user_id ?? null);
            const userName = (data.user_name ?? null);
            const departmentId = (data?.department_id ?? null);
            const departmentName = (data?.department_name ?? null);

            const isGrandTotal = (userId === null && userName.includes('Total'));
            const isUserTotal = (userId !== null && userName.includes('Total'));

            const isHeader = (isGrandTotal || isUserTotal);
            const isHidden = (!activeRows.includes(userId) && !isHeader);
 
            return buildRow(userId, isHeader, isHidden, Object.assign(data, {
                title:              isHeader ? (userName !== "Total" ? userName.replace("Total", "").trim() : userName) : null,
                linear_feet_production : departmentName
            }));
        });
    }

    useEffect(() => {
        setDataSource([]);
        setDataSource(buildDataSource(data));
    }, [data, loading]);

    return (
        <AssociateTable
            rowKey='by_associate'
            columns={TABLE_COLUMNS}
            loading={loading}
            dataSource={dataSource}
            handleRow={(id) => handleRow(id)}
        />
    );
}

export default AssociateProgressReportTable;
