import { handleRowStyle } from '../../components/Table/TableUtilities';

export const LINEAR_FEET_TABLE_COLUMNS = [
    {
        title     : '',
        key       : 'title',
        align     : 'left',
        dataIndex : 'title',
        width     : 'max-content',
        type      : 'string',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'QUEUES',
        key       : 'queues',
        align     : 'center',
        dataIndex : 'queues',
        width     : 'max-content',
        type      : 'string',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'ORDERS',
        key       : 'orders',
        dataIndex : 'orders',
        align     : 'center',
        width     : '100px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'LINES SUBMITTED',
        key       : 'lines_submitted',
        dataIndex : 'lines_submitted',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'ORDERS DUE TODAY',
        key       : 'orders_due_today',
        dataIndex : 'orders_due_today',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'TOTAL LINEAR FEET DUE',
        key       : 'total_linear_feet_due',
        dataIndex : 'total_linear_feet_due',
        align     : 'center',
        width     : '150px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'TOTAL LINEAR FEET PAST DUE (PRINT DATE, NOT SHIP DATE)',
        key       : 'total_linear_feet_past_due',
        dataIndex : 'total_linear_feet_past_due',
        align     : 'center',
        width     : '180px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'LINEAR FEET DUE TODAY (PRINT DATE, NOT SHIP DATE)',
        key       : 'linear_feet_due_today',
        dataIndex : 'linear_feet_due_today',
        align     : 'center',
        width     : '180px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'LINEAR FEET DUE TOMORROW (PRINT DATE, NOT SHIP DATE)',
        key       : 'linear_feet_due_tomorrow',
        dataIndex : 'linear_feet_due_tomorrow',
        align     : 'center',
        width     : '180px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'LINEAR FEET Due TOMORROW +1 (PRINT DATE, NOT SHIP DATE)',
        key       : 'linear_feet_due_tomorrow_plus_1',
        dataIndex : 'linear_feet_due_tomorrow_plus_1',
        align     : 'center',
        width     : '180px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'LINEAR FEET DUE > TOMORROW +1 (PRINT DATE, NOT SHIP DATE)',
        key       : 'linear_feet_due_greater_than_tomorrow_plus_1',
        dataIndex : 'linear_feet_due_greater_than_tomorrow_plus_1',
        align     : 'center',
        width     : '180px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'TOTAL LINEAR FEET PRINTED TODAY (SCANNED PRINT)',
        key       : 'total_linear_feet_printed_today',
        dataIndex : 'total_linear_feet_printed_today',
        align     : 'center',
        width     : '180px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    }
];
