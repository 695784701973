import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  searching: false
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearching(state, action) {
      state.searching = action.payload
    }
  }
})

export const {
  reducer: SearchReducer,
  actions: SearchActions
} = searchSlice