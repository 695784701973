import React, { useState } from 'react';
import * as S from './DatePickerStyle';
import moment from 'moment';
import { formatDate } from 'utils/dateFormatter';

const DatePicker = ({
    date,
    defaultDate,
    format = 'YYYY-MM-DD'
}) => {
    const [newDate, setNewDate] = useState(moment(defaultDate));
    const handleSubmit = () => date(formatDate(format, newDate));

    return (
        <S.DatePickerContainer>
            <S.DatePickerInput
                separator={'/'}
                defaultValue={moment(defaultDate)}
                value={newDate}
                onChange={(results) => (results !== null && setNewDate(moment(results?._d)))}                
            />
            <S.SubmitButton type="primary" onClick={() => {handleSubmit()}}>GO</S.SubmitButton>
        </S.DatePickerContainer>
    );
}

export default DatePicker;
