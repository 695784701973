import React, { useState } from 'react'
import { Row, Col, Input, notification, Icon, Pagination } from 'antd'
import { ZenSmartAPI } from 'utils'
import { Button } from '../../../components/zensmart-design-system';
import { fetchConfigurationRoute } from "utils/apiRoutes"
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import _ from "lodash"
import { debounce } from "utils/debounce";

const Sidebar = (props) => {

    const { configurations, setConfigurations, configurationsPagination, setConfigurationsPagination, loading, setLoading, fetchConfigurations, getOneConfiguration } = props
    const [addNewConfig, setAddNewConfig] = useState(false);
    const [newConfigName, setNewConfigName] = useState(null)
    const [configurationsFilter, setConfigurationsFilter] = useState('')


    const saveNewConfiguration = (e, ifSave = false) => {
        if (e.keyCode === 13 || ifSave) {

            setLoading(true)
            ZenSmartAPI.post(fetchConfigurationRoute, { "name": newConfigName })
                .then((res) => {
                    setLoading(false)
                    onShowSizeChange(configurationsPagination.current_page)
                    cancelAddConfig()
                    notification.success({ message: "New configuration saved!" })
                })
                .catch((err) => {
                    setLoading(false)
                    errorNoticationHandler(err, "Cant save configuration!")
                })
        }
    }
    const cancelAddConfig = () => {
        setAddNewConfig(false);
        setNewConfigName(null)
    }

    const onSearchconfiguration = debounce((value) => {
        setLoading(true)
        setConfigurationsFilter(value.target.value)
        ZenSmartAPI.get(`${fetchConfigurationRoute}?filter[name]=${value.target.value}`)
            .then((res) => {
                setLoading(false)
                setConfigurations(res.data.data)
                setConfigurationsPagination(res.data.meta)
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant search configuration!")
            })

    }, 400);

    function onShowSizeChange(current, pageSize) {
        setLoading(true)
        const configurationRoute = configurationsFilter === '' ? `${fetchConfigurationRoute}?page=${current}` : `${fetchConfigurationRoute}?page=${current}&filter[name]=${configurationsFilter}`
        ZenSmartAPI.get(configurationRoute)
            .then((res) => {
                setLoading(false)
                setConfigurations(res.data.data)
                setConfigurationsPagination(res.data.meta)

            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant search configuration!")
            })
    }
    return <div>
        <h2 style={{ paddingBottom: 10 }}>Saved Configurations</h2>
        <Input.Search
            style={{ width: '100%' }}
            type="text"
            onChange={(e) => { e.persist(); onSearchconfiguration(e) }}
        />
        <div style={{ padding: 10 }}>
            {/* add new config inputs */}
            {addNewConfig ?
                <Row type="flex" align="middle">
                    <Col span={18}>
                        <Input placeholder="Please input name..."
                            onChange={(e) => setNewConfigName(e.target.value)}
                            onKeyDown={(e) => saveNewConfiguration(e)}
                        />
                    </Col>
                    <Col span={3} style={{ padding: 5 }}>
                        <Button size="small" style={{ width: "100%", background: "red" }} onClick={() => { cancelAddConfig() }} icon="delete">
                            <Icon type='delete' />
                        </Button>
                    </Col>
                    <Col span={3} style={{ padding: 5 }}>
                        <Button size="small" style={{ width: "100%" }} icon="check" onClick={(e) => saveNewConfiguration(e, true)}>
                            <Icon type='edit' />
                        </Button>
                    </Col>
                </Row>
                :
                <h2 style={{ paddingBottom: 10, cursor: "pointer", textDecoration: "underline", color: "blue" }}
                    onClick={() => setAddNewConfig(true)}>+ Add New Configuration</h2>
            }
            {/* // showing list of configurations */}
            <ul style={{ listStyleType: "circle" }}>
                {configurations.map(data => {
                    return <li span={24}>
                        <h2 style={{ cursor: "pointer", textDecoration: "underline", width: "100%", }} onClick={() => getOneConfiguration(data.id)}>{data.name}</h2>
                    </li>
                })}
            </ul>
            {configurationsPagination.per_page &&
                <p style={{ textAlign: "right" }}>
                    <Pagination
                        onChange={onShowSizeChange}
                        simple
                        current={configurationsPagination.current_page}
                        total={configurationsPagination.total}
                        pageSize={configurationsPagination.per_page}
                        size={"small"}
                        style={{ marginTop: 10 }}
                    />
                </p>
            }

        </div>
    </div>
}

export default Sidebar