import styled from 'styled-components';

const DropRegion = styled.div`
  width:100%;
  color: black;
  span {
    color: #0B6BF2;
  }
`

export default DropRegion