const GetDataType = (type) => {
  
  switch (type) {
    case 'int':
    case 'int2':
    case 'int4':
    case 'int8':
    case 'float4':
    case 'float8':
    case 'decimal':
    case 'MONEY':
      return DATATYPE.number;
    case 'varchar':
    case 'char':
    case 'varbit':
    case 'text':
      return DATATYPE.text;
    case 'bool':
      return DATATYPE.bool;
    case 'timestamp':
    case 'time':
    case 'timestamptz':
    case 'timetz':
    case 'date':
      return DATATYPE.time;
    case 'bucket':
      return DATATYPE.bucket;
    default:
      return null;
  }
}

const DATATYPE = {
  number: 'number',
  text: 'text',
  bool: 'bool',
  time: 'time',
  bucket: 'bucket'
}

export default {GetDataType, DATATYPE}