import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { icons } from '../../shared/icons';
import { color } from '../../shared/styles';

const StyledInput = styled.input`
  height: 32px;
  border-radius: 4px;
  border: 1px solid ${color.input.unselected};
  padding: 7px;
  padding-right: 27px;
  width: 100%;
  color: ${color.input.selected};
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' width='16' height='16' aria-hidden='true'><path fill='rgb(11,107,242)' d='${icons['search']}'></path></svg>");
  background-repeat: no-repeat;
  background-position: 97% 50%;
  &::placeholder {
    color: ${color.input.unselected};
    content: 'Search';
  }
  &:focus {
    border: 1px solid ${color.input.selected};
    outline: 0;
  }
`;

const StyledDiv = styled.div`
  width: ${props => props.width ? props.width : '100%'};
  height: fit-content;
  color: ${color.input.unselected};
  position: relative;
  label {
    position: absolute;
    padding-top: 6.7px;
    padding-left: 7.7px;
  }
  label::before {
    content: '${props => props.preSuggestion ? props.preSuggestion : ''}';
    color: transparent;
  }
  label::after {
    content: '${props => props.postSuggestion ? props.postSuggestion : ''}';
  }
`;

const applyStyle = SearchWrapper => {
  return (
    SearchWrapper &&
    StyledInput.withComponent(({ ...rest }) => (
      <SearchWrapper {...rest} />
    ))
  );
};

export function Search({
  suggestions,
  width,
  SearchWrapper,
  ...props
}) {

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [userInput, setUserInput] = useState('');

  const handleChange = e => {
    setUserInput(e.currentTarget.value);
    const ui = e.currentTarget.value;

    // Filter out suggestions that don't contain the user's input
    const fs = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(ui.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    setFilteredSuggestions(fs);
    setUserInput(e.currentTarget.value);
  };

  const handleKeyDown = e => {
    setFilteredSuggestions([]);
    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      setUserInput(filteredSuggestions[0]);
    }
  };

  const StyledInputWrapper = React.useMemo(() => applyStyle(SearchWrapper), [SearchWrapper]);

  let SelectedSearch = StyledInput;
  if (SearchWrapper) {
    SelectedSearch = StyledInputWrapper;
  }

  let majorSuggestion = filteredSuggestions[0];
  let pre = '';
  let post = '';
  if (majorSuggestion) {
    pre = userInput;
    post = majorSuggestion.slice(pre.length, majorSuggestion.length);
  }

  return (
    <StyledDiv preSuggestion = {pre} postSuggestion = {post} width = {width}>
      <label htmlFor = 'searchField'></label>
      <SelectedSearch onChange={(e) => handleChange(e)} onKeyDown={(e) => handleKeyDown(e)} id = 'searchField' type = 'text' value = {userInput} {...props}/>
    </StyledDiv>
  );
}

Search.propTypes = {
  suggestions: PropTypes.array,
  width: PropTypes.string,
  SearchWrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

Search.defaultProps = {
  suggestions: [],
  SearchWrapper: undefined
};
