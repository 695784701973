import moment from "moment"
import { LabelStatus, LabelTypes } from "components/zensmart-design-system";

const labelValue = {
  status: '',
  type: '',
  value: ''
}

const LabelConversion = ($date) => {
  $date = typeof $date === 'string' ? $date.toLowerCase() : ''

  labelValue.type = $date !== '' ? LabelTypes.date : ''
  labelValue.value = $date

  switch ($date) {
    case 'tomorrow':
      labelValue.status = LabelStatus.date.tomorrow
      break;
    case 'today':
      labelValue.status = LabelStatus.date.today
      break;
    case 'overdue':
      labelValue.status = LabelStatus.date.overdue
      break;
    case 'later':
      labelValue.status = LabelStatus.date.later
      break;
    default:
      labelValue.status = ''
      break;
  }

  return labelValue
}

export default LabelConversion