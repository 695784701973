import React, { useState, useEffect } from 'react';
import { notification, Tabs, Spin } from 'antd';
import styled from 'styled-components';
import { Message } from "../../styles"
import { ZenSmartAPI } from 'utils';
import FaultsList from "./FaultsList";

const SpinContainer = styled.div`
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
`

const { TabPane } = Tabs;

const Faults = ({ userId, role, reload, setReload }) => {
  const [tabs, setTabs] = useState([])
  const [data, setData] = useState(null)
  const [activeTab, setActiveTab] = useState('pending')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    ZenSmartAPI.get(`api/v1/tasks/faults/by-role?station_id=ALL&user_id=${userId}&role=${role.toLowerCase()}`)
    .then(res => {
      setLoading(false)
      setData(res.data)
      setTabs(Object.keys(res.data))
    }).catch(err => {
      setLoading(false)
      setError(true)
      notification.error({
        message: "Couldn't retrieve tabs",
      });
    })
  }, [role, reload])

  const getTabTitle = (tab) => {
    if (tab === 'assigned') {
      return `Assigned to me (${data[tab].length})`
    } else if (tab === 'reported') {
      return `Reported by me (${data[tab].length})`
    } else if (tab === 'pending') {
      return `Pending (${data[tab].length})`
    } else if (tab === 'completed') {
      return `Completed (${data[tab].length})`
    }
  }

  return <>
  {loading && <SpinContainer><Spin /></SpinContainer>}
  {error && <Message>Failed to fetch tabs.</Message>}
  {!loading && !error && (
    <Tabs defaultActiveKey={activeTab} onChange={(key) => setActiveTab(key)}>
      { tabs.map(tab => 
        <TabPane tab={getTabTitle(tab)} key={tab}>
          <FaultsList faults={data[tab]} activeTab={activeTab} reload={setReload} />
        </TabPane>
      )}
    </Tabs>)}
  </>
}

export default Faults;