import React, {useState} from 'react';
import styled from 'styled-components';
import { Icon } from '../../../../components/zensmart-design-system';

const HeaderStyle = styled.div`
  margin-top: 20px;
  span {
    ${props=> props.open ? 'display: block':'display: none'}
  }
`

HeaderStyle.defaultProps = {
  open: true
}

const SideIcon = styled.div`
background-color: #0243EB;
color: white;
height: 20px;
width: 20px;
border-radius: 10px;
text-align: center;
font-size: medium;
margin-right: 10px;
`

const FieldHeader = ({
  title,
  children,
  defaultOpen = true
}) => {
  const [open, setOpen] = useState(defaultOpen)
  
  return (
    <HeaderStyle open={open}>
      <div onClick={()=>setOpen(!open)} style={{display:'flex'}}>
        <SideIcon>
          <Icon icon={open ? 'down' : 'up'} width='10px' height='10px'/>
        </SideIcon>
        <h2>{title}</h2>
      </div>
      <span style={{minHeight:60}}>
        {children}
      </span>
    </HeaderStyle>
  )
}

export default FieldHeader