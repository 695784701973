import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Input, Select, Form, Modal, Checkbox, Tooltip } from 'antd'
import { ZenSmartAPI } from 'utils'
import _ from "lodash"
import { Button } from '../../../components/zensmart-design-system';
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import { Label } from "components/zensmart-design-system/components/Label/Label.js";
import moment from 'moment'

const DetailSpan = styled.span`
  color : rgba(136,153,175,255);
`

const StyledFormItem = styled(Form.Item)`
width : 100%;
`

const OptionForm = (props) => {
    const {
        form,
        panelData,
        virtualImpositionData,
        setVirtualImpositionData,
        selectedProduct,
        setProductError,
        setPanelLoading,
        optionValues,
        setOptionValues,
        setReleaseToPrintPayload,
        finishingTypes,
        presetChangedNotification,
        configurationMethod,
        oneConfiguration,
        setVirtualImposeOrReleaseToPrintSuccess,
        predefinedConfId,
        setPredefinedConfIdError,
        ifFail,
        laminationCode,
        productIdRef,
        isBasicVersion
    } = props

    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields,
        setFieldsValue
    } = form

    const changeTargetLengthValue = (e) => {
        if (virtualImpositionData) {
            const getPreviousData = optionValues.target_length
            Modal.confirm({
                'content': "Changing of fields will reset the virtual imposition datas do you want to continue?",
                onOk: () => {
                    setOptionValues({ ...optionValues, target_length: e.target.value })
                    setVirtualImpositionData(null)
                },
                onCancel: () => {
                    setFieldsValue({
                        "options.target_length": getPreviousData
                    })
                    return
                }
            });
        }
        else {
            setOptionValues({ ...optionValues, target_length: e.target.value })
        }
    };

    const handleSaveChanges = async (evt) => {
        evt.preventDefault()

        validateFields(async (err, values) => {
            if (!err) {
                if (!selectedProduct || selectedProduct.length < 1) {
                    setProductError(true)

                    return
                }
                setPanelLoading(true)
                const payload = getFieldsValue()
                payload['printer_id'] = panelData.id
                payload['product_id'] = selectedProduct
                payload['batching_configuration_method'] = configurationMethod || null
                if (configurationMethod === "custom") {
                    payload['batching_configuration_id'] = oneConfiguration.id
                } else if (configurationMethod === "predefined") {
                    if (!predefinedConfId) {
                        setPredefinedConfIdError(true)
                    }
                    payload['batching_configuration_id'] = predefinedConfId
                } else {
                    payload['batching_configuration_id'] = panelData.batching_configuration.id
                }

                payload.required_filters = isBasicVersion === false ? {
                    "product_profile" : productIdRef.current,
                    "fails" : ifFail,
                    "lamination_code" : laminationCode
                } : {}
                
                ZenSmartAPI.post('/api/v1/jit-batching/release-to-print/preview', payload)
                    .then((res) => {
                        setPanelLoading(false)
                        setReleaseToPrintPayload(payload)
                        setVirtualImpositionData(res.data.data)
                        setVirtualImposeOrReleaseToPrintSuccess('virtual-imposition')
                    })
                    .catch((err) => {
                        setPanelLoading(false)
                        if (_.get(err, "response.status") === 400 && _.get(err, "response.data.message") === "Printer Configuration not match!") {
                            presetChangedNotification()
                        }
                        else {
                            errorNoticationHandler(err, "Cant calculate length!")
                        }

                    })
            }
            else if (!selectedProduct || selectedProduct.length < 1) {
                setProductError(true)
            }
        })
    }

    return (
        <Form layout="horizontal" onSubmit={handleSaveChanges}>
            <Row type="flex" align="middle">
                <Col span="8">
                    <h2>Add to Roll: </h2>
                </Col>
                <Col span="16" style={{ marginTop: 15 }}>
                    <StyledFormItem >
                        {getFieldDecorator('options.add_to_roll', {
                            value: optionValues.add_to_roll,
                            rules: [
                                {
                                    required: true,
                                    whitespace: false,
                                    message: 'Field is required!',
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Please select a roll..."
                                optionFilterProp="children"
                                value={optionValues.add_to_roll}
                                size={"large"}
                                onChange={(e) => {
                                    if (virtualImpositionData) {
                                        Modal.confirm({
                                            'content': "Changing of fields will reset the virtual imposition datas do you want to continue?",
                                            onOk: () => {
                                                setOptionValues({ ...optionValues, add_to_roll: e })
                                                setVirtualImpositionData(null)
                                            },
                                        });
                                    }
                                    else {
                                        setOptionValues({ ...optionValues, add_to_roll: e })
                                    }
                                }}
                            >
                                <Select.Option value="existing" >Existing</Select.Option>
                                <Select.Option value="new" >New</Select.Option>
                            </Select>
                        )}
                    </StyledFormItem>
                </Col>
                <Col span="8">
                    <h2>Target Paper Length: </h2>
                </Col>
                <Col span="16" style={{ marginTop: 15 }}>
                    <StyledFormItem >
                        {getFieldDecorator('options.target_length', {
                            value: optionValues.target_length,
                            rules: [
                                {
                                    required: true,
                                    whitespace: false,
                                    message: 'Field is required!',
                                },
                            ],
                        })(
                            <Input type="number" value={optionValues.target_length} suffix="feet" size={"large"}
                                onChange={(e) => { e.persist(); changeTargetLengthValue(e) }}
                            />

                        )}
                    </StyledFormItem>
                </Col>
                <Col span="8">
                    <h2>Finishing Type: </h2>
                </Col>
                <Col span="16" style={{ marginTop: 15 }}>
                    <StyledFormItem >
                        {getFieldDecorator('options.finishing_type_id', {
                            value: optionValues.finishing_type_id,
                            rules: [
                                {
                                    required: true,
                                    message: 'Field is required!',
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Please select a type..."
                                optionFilterProp="children"
                                value={optionValues.finishing_type_id}
                                size={"large"}
                                onChange={(e) => {
                                    if (virtualImpositionData) {
                                        Modal.confirm({
                                            'content': "Changing of fields will reset the virtual imposition datas do you want to continue?",
                                            onOk: () => {
                                                setOptionValues({ ...optionValues, finishing_type_id: e })
                                                setVirtualImpositionData(null)
                                            },
                                        });
                                    }
                                    else {
                                        setOptionValues({ ...optionValues, finishing_type_id: e })
                                    }
                                }}
                            >
                                {finishingTypes.map(data => {
                                    return <Select.Option value={data.id} >{data.name}</Select.Option>

                                })}
                            </Select>
                        )}
                    </StyledFormItem>
                </Col>
                <Col span="8">
                    <h2>Roll Length Monitor: </h2>
                </Col>
                <Col span="16" style={{ marginTop: 15 }}>
                    <StyledFormItem >
                        {getFieldDecorator('options.roll_length_checking', {
                            initialValue: optionValues.roll_length_checking,
                        })(
                            <Checkbox checked={optionValues.roll_length_checking}
                                onChange={(e) => {
                                    if (virtualImpositionData) {
                                        Modal.confirm({
                                            'content': "Changing of fields will reset the virtual imposition datas do you want to continue?",
                                            onOk: () => {
                                                setOptionValues({ ...optionValues, roll_length_checking: e.target.checked })
                                                setVirtualImpositionData(null)
                                            },
                                        });
                                    }
                                    else {
                                        setOptionValues({ ...optionValues, roll_length_checking: e.target.checked })
                                    }
                                }} />,
                        )}
                    </StyledFormItem>
                </Col>
                <Col span="24" style={{ marginTop: 20 }}>
                    <h1 style={{ textAlign: "center" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            Virtual Impose
                        </Button>
                    </h1>
                </Col>
            </Row>
        </Form>
    )
}

const OptionFormDetails = Form.create({})(OptionForm)
const VirtualImposiionComponent = (props) => {

    const {
        panelData,
        virtualImpositionData,
        setVirtualImpositionData,
        selectedProduct,
        loading,
        setLoading,
        setProductError,
        panelLoading,
        setPanelLoading,
        optionValues,
        setOptionValues,
        setReleaseToPrintPayload,
        finishingTypes,
        calculateTimeToPrint,
        presetChangedNotification,
        configurationMethod,
        oneConfiguration,
        setVirtualImposeOrReleaseToPrintSuccess,
        predefinedConfId,
        setPredefinedConfIdError,
        isAllowedComboBatching,
        ifFail,
        laminationCode,
        productIdRef,
        isBasicVersion
    } = props

    const [expectedRollRemaining, setExpectedRollRemaining] = useState(null)
    const [rollRemaining, setRollRemaining] = useState(null)

    useEffect(() => {
        if (panelData.roll_length_remaining) {
            setExpectedRollRemaining(panelData.roll_length_remaining)
        }
        if (virtualImpositionData && virtualImpositionData.total_length) {
            setRollRemaining(panelData.roll_length_remaining - virtualImpositionData.total_length)
        }
    }, [panelData]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (virtualImpositionData && virtualImpositionData.total_length) {
            setRollRemaining(virtualImpositionData.roll_length_remaining - virtualImpositionData.total_length)
            setExpectedRollRemaining(virtualImpositionData.roll_length_remaining)

        }
        else {
            setRollRemaining(null)
        }

    }, [virtualImpositionData]) // eslint-disable-line react-hooks/exhaustive-deps
    return <div style={{ padding: 20 }}>

        <h1>OPTIONS</h1>
        <OptionFormDetails
            panelData={panelData}
            virtualImpositionData={virtualImpositionData}
            setVirtualImpositionData={setVirtualImpositionData}
            selectedProduct={selectedProduct}
            loading={loading}
            setLoading={setLoading}
            setProductError={setProductError}
            setPanelLoading={setPanelLoading}
            panelLoading={panelLoading}
            optionValues={optionValues}
            setOptionValues={setOptionValues}
            setReleaseToPrintPayload={setReleaseToPrintPayload}
            finishingTypes={finishingTypes}
            presetChangedNotification={presetChangedNotification}
            configurationMethod={configurationMethod}
            oneConfiguration={oneConfiguration}
            setVirtualImposeOrReleaseToPrintSuccess={setVirtualImposeOrReleaseToPrintSuccess}
            predefinedConfId={predefinedConfId}
            setPredefinedConfIdError={setPredefinedConfIdError}
            isAllowedComboBatching={isAllowedComboBatching}
            ifFail={ifFail}
            laminationCode={laminationCode}
            productIdRef={productIdRef}
            isBasicVersion={isBasicVersion}
        />
        <br />

        <h1>DETAILS</h1>
        <Tooltip placement="topLeft" title={"Total length remaining of the roll including the deduction of the current imposed submission that is not printed yet."}>
        <h1><DetailSpan>Roll Length Remaining: {expectedRollRemaining && `${expectedRollRemaining.toLocaleString()} Feet`} </DetailSpan></h1>
        </Tooltip>

        <h1><DetailSpan>Expected Roll Length after Current Submissions: {rollRemaining && `${rollRemaining.toLocaleString()} Feet`} </DetailSpan></h1>
        <h1><DetailSpan>Expected Completion Time of Current: {virtualImpositionData && calculateTimeToPrint(virtualImpositionData.expected_completion_time)}  </DetailSpan></h1>

        <br />
        <h1>NOTICES</h1>
        <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : "green" }}>Batches to be Formed: {virtualImpositionData && virtualImpositionData.batches_to_be_formed} </DetailSpan></h1>
        <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : "green" }}>Time to Print: {virtualImpositionData && calculateTimeToPrint(virtualImpositionData.expected_completion_time)} </DetailSpan></h1>
        <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : "green" }}>Actual Paper Length: {virtualImpositionData && `${virtualImpositionData.total_length.toLocaleString()} Feet`} </DetailSpan></h1>
        <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : virtualImpositionData.threshold_warning ? "red" : "green" }}>
            Efficiency: {virtualImpositionData && virtualImpositionData.efficiency} {virtualImpositionData && virtualImpositionData.threshold_warning &&
                <Label status={"red"}>
                    {virtualImpositionData.threshold_warning}
                </Label>
            } </DetailSpan></h1>
        <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : "green" }}>WIP: {virtualImpositionData && `${virtualImpositionData.wip_percentage}%`} </DetailSpan></h1>
        <h1><DetailSpan style={{ color: !virtualImpositionData ? "rgba(136,153,175,255)" : "green" }}>Lanes Occupied: {virtualImpositionData && `${virtualImpositionData.lanes_occupied}`} </DetailSpan></h1>

    </div>

}

export default VirtualImposiionComponent