import AnalyticsStorage from "./AnalyticsStorage";


const allLabels = AnalyticsStorage.retrieveData();
const GetLabel = (text, labels = [], modifiedAllLabels = []) => {

  if (text === null || text === undefined || text === "") {
    return ""
  }

  if(text === false)
  {
    return "False"
  }

  if(text === true)
  {
    return "True"
  }

  if(typeof text === "number")
  {
    return text.toString()
  }

  let thisData = text
  const splitText = text.split('.')
  const resource = splitText[0]
  const field = splitText[1]


  if (modifiedAllLabels.length > 0) {
    modifiedAllLabels.some(item => {
      if (item.field === field && item.resource === resource) {
        thisData = item.field_label
        return true
      }
      return false
    });
  }
  else if (allLabels) {
    allLabels.some(item => {
      if (item.field === field && item.resource === resource) {
        thisData = item.field_label
        return true
      }
      return false
    });
  } else if (labels.length) {
    labels.some(item => {
      if (item.field === field && item.resource === resource) {
        thisData = item.field_label
        return true
      }
      return false
    });
  }

  return !thisData.includes(".") ? thisData : field;
}

export default GetLabel