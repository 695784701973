import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Select, Input, DatePicker } from 'antd';
import { DataTypes, GetDateValues, GetLabel } from '../../utils';
import moment from 'moment';
import { Button } from 'components/zensmart-design-system';
import { InputSection } from '../composites';
import { alphabeticalData } from 'utils/sortData';
import debounce from 'lodash/debounce';

const Option = Select.Option
const OptGroup = Select.OptGroup
const { GetDataType, DATATYPE } = DataTypes

const EditableStyle = styled.div`
  ${props => props.visible ? `display: flex;` : `display: none;`}

  .input_field {
    width: ${props => props.mode === 'create' ? '19%' : '100%'};
    margin-right: 1rem;

    .field {
      width: 100%;
    }
  }

  .action_button {
    margin-right: 3px;
    margin-top: auto;
    overflow:visible;
  }
`

const FilterEditor = ({
  filterKey,
  visible,
  setVisibility,
  selectedItem,
  allItems = [],
  operations,
  itemFieldSelected,
  inputType,
  OKText,
  OKClicked,
  showOK = true,
  showCancel,
  showLabel,
  deleteItem,
  disableField = false,
  mode = 'create',
  editChange,
  editableReport
}) => {
  const [currentFilter, setCurrentFilter] = useState({
    table: '',
    category: '',
    field: '',
    type: '',
    operation: '',
    value: [''],
    from: '',
    to: ''
  });

  const [triggerAllDone, setTriggerAllDone] = useState(false)
  const inputRef = useCallback(node => {
    if(node !== null) {
      if(Array.isArray(currentFilter.value) && currentFilter.value.length > 0) {
        node.input.value = currentFilter.value.join();
      }
    }    
  });

  useEffect(() => {
    setCurrentFilter(selectedItem)
  }, [selectedItem]);

  useEffect(() => {
    // in run mode, automatically send requests
    if (triggerAllDone && editableReport === false) {
      const load = setTimeout(() => {
        OKClicked(currentFilter)
      }, 500);

      return () => {
        clearTimeout(load)
      }
    }
  }, [currentFilter, editableReport, triggerAllDone])

  const handleManualInputChange = debounce((value) => {
    let formatted_value = value.split(',');
    let filter = currentFilter;
    filter.value = formatted_value;
    setFilterValue(formatted_value);
    if(editableReport === false) {
      OKClicked(filter, filterKey);
    }
  }, 500)


  const setFilterValue = (value) => {
    setCurrentFilter({ ...currentFilter, value: value });
    editChange && editChange()
  }

  const setFieldAndType = (filter) => {
    setCurrentFilter({ ...filter })
    itemFieldSelected(filter)
  }

  const selectOperation = (value) => {
    // choose operation or range
    setCurrentFilter({ ...currentFilter, operation: value });
  }

  const cancel = () => {
    setVisibility(false);
  }

  const populateDateRange = (dateValue) => {

    if (dateValue === "is empty" || dateValue === "is not empty") {
      selectOperation(dateValue);
      return;
    }

    if (dateValue !== 'custom') {
      selectOperation(dateValue);
      const dateRanges = GetDateValues(dateValue)
      setDateFrom(dateRanges[0], [dateValue])
      setDateTo(dateRanges[1], [dateValue])
    }
    else {
      setFilterValue(dateValue);
    }
  }

  const setDateTo = (to, dateValue) => {
    setCurrentFilter(currentFilter => {
      return { ...currentFilter, to: to ? to.format('YYYY-MM-DD') : '', value: dateValue ? dateValue : currentFilter.value }
    })
    editChange && editChange()
  }

  const setDateFrom = (from, dateValue) => {
    setCurrentFilter(currentFilter => {
      return { ...currentFilter, from: from ? from.format('YYYY-MM-DD') : '', value: dateValue ? dateValue : currentFilter.value }
    })
    editChange && editChange()
  }

  const fieldSection = (
    <InputSection
      label={showLabel && 'Field'}
      position='top'
      className='input_field'
    >
      <Select
        size='default'
        value={
          (currentFilter.table && currentFilter.field) ?
            currentFilter.table + ' ' + GetLabel(`${currentFilter.table}.${currentFilter.field}`) : ''
        }
        onSelect={(value) => setFieldAndType(allItems[value])}
        className='field'
        disabled={disableField}
        style={{ textTransform: "capitalize" }}
        optionFilterProp="children"
        showSearch
      >
        {alphabeticalData(allItems, "table", true, "field").map((itemKey, index) => (
          <Option key={index} value={index} style={{ textTransform: "capitalize" }}>{itemKey.table} {GetLabel(`${itemKey.table}.${itemKey.field}`)}</Option>
        ))}
      </Select>
    </InputSection>
  )
  const operationSection = (
    GetDataType(inputType) !== DATATYPE.time ? (
      <InputSection
        label={showLabel && 'operation'}
        position='top'
        className='input_field'
      >
        <Select
          value={currentFilter.operation ? currentFilter.operation : 'equals'}
          onSelect={selectOperation}
          className='field'
          defaultValue="equals"
        >
          {operations.map(operation => (
            <Option key={operation} value={operation}>{operation}</Option>
          ))}
        </Select>
      </InputSection>
    )
      : <InputSection
        label={showLabel && 'Range'}
        position='top'
        className='input_field'
      >
        <Select
          value={currentFilter.operation ? currentFilter.operation : currentFilter.value ? currentFilter.value[0] === "c" ? "custom" : currentFilter.value[0] : 'custom'}
          onChange={populateDateRange}
          className='field'
        >
          <Option value="custom">Custom</Option>

          <OptGroup label="Year">
            <Option value="thisyear">This Year</Option>
            <Option value="lastyear">Last Year</Option>
            <Option value="thisyearandlastyear">This Year And Last Year</Option>
            <Option value="twoyearsago">2 Years Ago</Option>
            <Option value="thisyearandlasttwoyear">This and Last 2 years</Option>
            <Option value="nextyear">Next Year</Option>
            <Option value="thisyearandnextyear">This Year and Next Year</Option>
          </OptGroup>

          <OptGroup label="Month">
            <Option value="thismonth">This Month</Option>
            <Option value="lastmonth">Last Month</Option>
            <Option value="thismonthandlastmonth">This Month And Last Month</Option>
            <Option value="nextmonth">Next Month</Option>
            <Option value="thismonthandnextmonth">This Month and Next Month</Option>
          </OptGroup>

          <OptGroup label="Week">
            <Option value="thisweek">This Week</Option>
            <Option value="lastweek">Last Week</Option>
            <Option value="thisweekandlastweek">This Week and Last Week</Option>
          </OptGroup>

          <OptGroup label="Day">
            <Option value="yesterday">Yesterday</Option>
            <Option value="today">Today</Option>
            <Option value="yesterdayandtoday">Yesterday And Today</Option>
            <Option value="tomorrow">Tomorrow</Option>
            <Option value="last7days">Last 7 Days</Option>
            <Option value="last14days">Last 14 Days</Option>
            <Option value="next7days">Next 7 Days</Option>
            <Option value="next14days">Next 14 Days</Option>
            <Option value="last30days">Last 30 Days</Option>
            <Option value="last60days">Last 60 Days</Option>
            <Option value="last90days">Last 90 Days</Option>
            <Option value="last120days">Last 120 Days</Option>
            <Option value="next30days">Next 30 Days</Option>
            <Option value="next60days">Next 60 Days</Option>
            <Option value="next90days">Next 90 Days</Option>
            <Option value="next120days">Next 120 Days</Option>
          </OptGroup>
          <OptGroup label="Special">
            <Option value="past">in the Past</Option>
            <Option value="future">in the Future</Option>
            <Option value="is empty">is empty</Option>
            <Option value="is not empty">is not empty</Option>
          </OptGroup>
        </Select>
      </InputSection>
  )
  const valueSection = (
    GetDataType(inputType) === DATATYPE.time ? (
      <>
        <InputSection
          label={showLabel && 'From'}
          position='top'
          className='input_field'
        >
          <DatePicker
            value={currentFilter.value && (currentFilter.value.length > 0 && currentFilter.value[0] !== "custom") && Array.isArray(currentFilter.value) ? GetDateValues(currentFilter.value[0])[0] : currentFilter.from ? moment(currentFilter.from.split(/\s+/).join(' ')) : undefined}
            onChange={(date) => {
              selectOperation('equals')
              setFilterValue('custom')
              setDateFrom(date)

              if (editableReport === false) {
                setTriggerAllDone(true)
              } else {
                setTriggerAllDone(false)
              }
            }}
            className='field'
          />
        </InputSection>
        <InputSection
          label={showLabel && 'To'}
          position='top'
          className='input_field'
        >
          <DatePicker
            value={currentFilter.value && (currentFilter.value.length > 0 && currentFilter.value[0] !== "custom") && Array.isArray(currentFilter.value) ? GetDateValues(currentFilter.value[0])[1] : currentFilter.to ? moment(currentFilter.to.split(/\s+/).join(' ')) : undefined}
            onChange={(date) => {
              selectOperation('equals')
              setFilterValue('custom')
              setDateTo(date)

              if (editableReport === false) {
                setTriggerAllDone(true)
              } else {
                setTriggerAllDone(false)
              }
            }}
            className='field'
          />
        </InputSection>
      </>
    ) : (
        <InputSection
          label={showLabel && 'value'}
          position='top'
          className='input_field'
        >
          {(
            GetDataType(inputType) === DATATYPE.number ||
            GetDataType(inputType) === DATATYPE.text ||
            GetDataType(inputType) === DATATYPE.bucket
          ) &&
            <Input
              onChange={(e) => handleManualInputChange(e.target.value)}
              // onKeyUp={() => {
              //   if (editableReport === false) {
              //     setTriggerAllDone(true)
              //   } else {
              //     setTriggerAllDone(false)
              //   }
              // }}
              ref={inputRef}
              className='field'
            />
          }
          {GetDataType(inputType) === DATATYPE.bool &&
            <Select
              onChange={(val) => setFilterValue([val])}
              onSelect={() => {
                if (editableReport === false) {
                  setTriggerAllDone(true)
                } else {
                  setTriggerAllDone(false)
                }
              }}
              value={currentFilter.value !== undefined ? currentFilter.value : ''}
              className='field'
            >
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          }
          {GetDataType(inputType) === null && (
            <Input
              disabled
              className='field'
            />
          )}
        </InputSection>
      )
  )

  return (
    <EditableStyle visible={visible} mode={mode}>
      {mode === 'create' && fieldSection}

      {mode === 'create' && operationSection}

      {currentFilter.operation === "is empty" || currentFilter.operation === "is not empty" ? null : valueSection}

      {showOK === true ? (
        <Button size='small' onClick={() => OKClicked && OKClicked(currentFilter)} className='action_button'>
          {OKText ? OKText : `Update`}
        </Button>
      ) : ''}
      {showCancel && (
        <Button color='red' size='small' onClick={cancel} className='action_button'>
          Cancel
        </Button>
      )}
      {deleteItem && (
        <Button color='red' size='small' onClick={deleteItem} className='action_button'>
          Delete
        </Button>
      )}

    </EditableStyle>
  )
}
export default FilterEditor