import React, { useState, useEffect } from "react";
import { Row, Col, Spin, Select, notification } from "antd";
import styled from "styled-components";
import { Button, Panel } from "components/zensmart-design-system";
import DashboardEditor from "pages/Performance/Dashboards/DashboardEditor";
import { TitleAndControls } from "pages/Performance/partials/sections";
import {
  Header,
  ReportDescription,
  Title,
} from "pages/Performance/partials/primaries";
import { DashboardWidget } from "pages/Performance/Widget";
import is from "is_js";
import { useSelector, useDispatch } from "react-redux";
import {
  GetSavedReports,
  GetDashboards,
  CreateDashboard,
  UpdateDashboard,
  DeleteDashboard,
} from "pages/Performance/api";
import {
  TITLE_NAME,
  TITLE_DESCRIPTION,
  DASHBOARDS,
  DASHBOARDS_MAPPING,
  DASHBOARDS_LAYOUT,
  REPORTS,
} from "store/actions/dashboardTypes";
import { useHistory, useParams } from "react-router-dom";

const StyleDiv = styled.div`
  margin-top: 1rem;
  display: flex;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 100%;

  .dashboard_label {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 14px;
    padding-top: 4px;
    margin-left: 3rem;
  }

  .dashboard_option {
    width: 312px;
    margin-left: 3px;
  }

  .edit_button_section > button {
    margin-left: 10px;
  }
`;

const DescriptionContainer = styled.div`
  width: 69%;
  padding-bottom: 14px;
`;

function ControlPanel({
  loading,
  sidebar,
  showSidebar,
  closeEditor,
  saveReport,
  saveAndRunReport,
  deleteReport,
  setDashboardName,
  setDescription,
  editor,
  dashboardMapping,
  openNewFullScreenWindow,
}) {
  const titleName = useSelector((state) => state.dashboard.titleName);
  const titleDescription = useSelector(
    (state) => state.dashboard.titleDescription
  );

  return (
    <TitleAndControls
      loading={loading}
      edit={sidebar}
      setEdit={() => showSidebar(true)}
      stopEdit={closeEditor}
      runMode={showSidebar}
      save={saveReport}
      saveAndRun={saveAndRunReport}
      deleteReport={deleteReport}
      reportName={titleName}
      setReportName={setDashboardName}
      reportDescription={titleDescription}
      setReportDescription={setDescription}
      runControls={false}
      showDelete={!editor.create}
      showFullScreen={dashboardMapping && dashboardMapping.id}
      fullScreen={openNewFullScreenWindow}
      isDashboards={true}
      canModifyData={true}
    />
  );
}

function PageTitle() {
  const titleName = useSelector((state) => state.dashboard.titleName);

  return (
    <Header>
      <Title>
        Performance {">"} Dashboards {">"} {titleName ? titleName : "Unsaved"}
      </Title>
    </Header>
  );
}

function Description() {
  const titleDescription = useSelector(
    (state) => state.dashboard.titleDescription
  );

  return (
    <DescriptionContainer>
      <ReportDescription>
        {titleDescription && titleDescription}
      </ReportDescription>
    </DescriptionContainer>
  );
}

function Dashboards() {
  const [dashboard, setDashboard] = useState(null);
  const [viewMapping, setViewMapping] = useState([]);
  const [viewLayout, setViewLayout] = useState([]);
  const [editor, setEditor] = useState({ show: false, create: false });
  const [loading, setLoading] = useState(false);
  const [sidebar, showSidebar] = useState(true);
  const [runningNo, setRunningNo] = useState(
    is.existy(dashboard) ? dashboard.running_number : 0
  );
  const { slug } = useParams()
  const history = useHistory();

  const dashboards = useSelector((state) => state.dashboard.dashboards);
  const dashboardMapping = useSelector((state) => state.dashboard.mapping);
  const dashboardLayout = useSelector((state) => state.dashboard.layout);
  const dispatch = useDispatch();

  useEffect(() => {
    if (is.empty(dashboards)) {
      setLoading(true);
      GetSavedReports().then(({ data }) => {
        updateReports(data);
        GetDashboards()
          .then(({ data }) => {
            setDashboards(data.dashboards);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
    return () => {
      //Clear dashboards upon screen exit
      setDashboards([]);
      setDashboardName("");
      setDescription("");
    };
  }, []);

  function setDashboardName(dashboardName) {
    dispatch({
      type: TITLE_NAME,
      payload: dashboardName,
    });
  }

  function setDescription(description) {
    dispatch({
      type: TITLE_DESCRIPTION,
      payload: description,
    });
  }

  function setDashboards(dashboardsObj) {
    dispatch({
      type: DASHBOARDS,
      payload: dashboardsObj,
    });
  }

  function updateReports(reportsObj) {
    dispatch({
      type: REPORTS,
      payload: reportsObj,
    });
  }

  function updateDashboardMapping(dashboardsObj) {
    dispatch({
      type: DASHBOARDS_MAPPING,
      payload: dashboardsObj,
    });
  }

  function updateDashboardLayout(layout) {
    dispatch({
      type: DASHBOARDS_LAYOUT,
      payload: layout,
    });
  }

  function selectDashboard(value) {
    const filteredDashboard = dashboards.find((d) => d.id === Number(value));
    let layout = [];
    let mapping = [];
    if(!filteredDashboard) {
      notification.error({
        message: "No Dashboard Found!",
      });
      return false;
    }
    setDashboard(filteredDashboard);
    setDescription(filteredDashboard.description);
    setRunningNo(filteredDashboard.running_number);
    filteredDashboard.dashboard_reports.forEach((d) => {
      const layoutObj = d.layout;
      layout = [...layout, layoutObj];
      mapping = [
        ...mapping,
        { id: d.id, layout_id: layoutObj.i, report_id: d.report_id },
      ];
    });
    setViewMapping(mapping);
    setViewLayout(layout);
  }

  function saveReport(dashboardName, description) {
    let errorMessage;

    if (!dashboardName) {
      errorMessage = "Please enter a dashboard name to save.";
    } else if (is.empty(dashboardMapping.mapping)) {
      errorMessage = "Please add a dashboard widget.";
    }

    if (!errorMessage) {
      const isCreate = is.not.existy(dashboardMapping.id);
      let dashboardReports = [];

      dashboardMapping.mapping.forEach((d) => {
        if (!(isCreate && d.deleted)) {
          const filteredLayout = dashboardLayout.find(
            (layout) => layout.i === d.layout_id
          );
          const dashboardReportsObj = {
            id: d.id,
            report_id: d.report_id,
            layout: filteredLayout,
          };

          if (d.deleted) {
            dashboardReportsObj.soft_deletion = true;
            dashboardReportsObj.layout = {};
          }

          if (!isCreate) {
            dashboardReportsObj.dashboard_id = dashboardMapping.id;
          }

          dashboardReports = [...dashboardReports, { ...dashboardReportsObj }];
        }
      });

      if (isCreate) {
        CreateDashboard({
          name: dashboardName,
          description: description,
          dashboard_reports: dashboardReports,
          running_number: runningNo,
        }).then(({ data }) => {
          setDashboards([...dashboards, data.dashboards]);
          updateDashboardStates(data.dashboards);
          updateDashboardDetailsURL(data.dashboards?.id)
          notification.success({
            message: "Dashboard saved!",
          });
        }).catch((err) => {
          notification.error({
            message: err.response.data && err.response.data.errors ? err.response.data.errors.description[0] : "An error occurred while saving dashboard",
          });
        });
      } else {
        UpdateDashboard({
          id: dashboardMapping.id,
          name: dashboardName,
          description: description,
          dashboard_reports: dashboardReports,
          running_number: runningNo,
        }).then(({ data }) => {
          const dashboardMap = dashboards.map((d) => {
            if (d.id === data.dashboards.id) {
              return { ...data.dashboards };
            } else {
              return { ...d };
            }
          });

          setDashboards([...dashboardMap]);
          updateDashboardStates(data.dashboards);
          notification.success({
            message: "Dashboard saved!",
          });
        }).catch((err) => {
          notification.error({
            message: err.response.data && err.response.data.errors ? err.response.data.errors.description[0] : "An error occurred while saving dashboard",
          });
        });
      }
    } else {
      notification.error({
        message: errorMessage,
      });
    }
  }

  function saveAndRunReport(dashboardName, description) {
    saveReport(dashboardName, description);
    showSidebar(false);
  }

  function updateDashboardStates(dashboardsObj) {
    let mapping = [];
    let layout = [];

    dashboardsObj.dashboard_reports.forEach((d) => {
      mapping = [
        ...mapping,
        {
          id: d.id,
          dashboard_id: dashboardsObj.id,
          layout_id: d.layout.i,
          report_id: d.report_id,
        },
      ];
      layout = [...layout, d.layout];
    });

    updateDashboardMapping({
      ...dashboardMapping,
      id: dashboardsObj.id,
      mapping: mapping,
    });
    updateDashboardLayout(layout);
  }

  function openNewFullScreenWindow(value) {
    window.open(
      "/dashboard/" + (is.existy(value) ? value : dashboardMapping.id)
    );
  }

  function openDashboardDetails(value) {
    return history.push(`/app/performance/dashboards/${value}`)
  }

  function updateDashboardDetailsURL(value) {
    history.replace({ pathname: `/app/performance/dashboards/${value}`})
  }

  function deleteReport() {
    const dashboardId = dashboardMapping.id;
    DeleteDashboard(dashboardId).then(() => {
      notification.success({
        message: "Dashboard deleted!",
      });
      const filteredDashboards = dashboards.filter((d) => d.id !== dashboardId);
      setDashboards([...filteredDashboards]);
      closeEditor();
    });
  }

  function closeEditor() {
    setDashboardName("");
    setDescription("");
    setDashboard(null);
    setViewMapping([]);
    setViewLayout([]);
    setEditor({ show: false, create: false });
  }

  useEffect(() => {
    if (slug && dashboards.length > 0) {
      selectDashboard(slug)
    }
  }, [dashboards, slug])

  return (
    <Spin tip="Loading..." spinning={loading}>
      <PageTitle pageName="Dashboards" />
      {editor.show ? (
        <Panel active={true} height="100%" width="100%">
          <ControlPanel
            loading={loading}
            sidebar={sidebar}
            closeEditor={closeEditor}
            showSidebar={showSidebar}
            saveReport={saveReport}
            saveAndRunReport={saveAndRunReport}
            deleteReport={deleteReport}
            setDashboardName={setDashboardName}
            setDescription={setDescription}
            editor={editor}
            dashboardMapping={dashboardMapping}
            openNewFullScreenWindow={openNewFullScreenWindow}
          />
          <DashboardEditor
            isEdit={editor.show}
            dashboard={dashboard}
            editMapping={viewMapping}
            editLayout={viewLayout}
            isCreate={editor.create}
            loading={loading}
            setLoading={setLoading}
            sidebar={sidebar}
            runningNo={runningNo}
            setRunningNo={setRunningNo}
            updateDashboardMapping={updateDashboardMapping}
            updateDashboardLayout={updateDashboardLayout}
          />
        </Panel>
      ) : (
        <Panel active={true} height="100%" width="100%">
          <Row>
            <Col span={19}>
              <StyleDiv>
                <span className="dashboard_label">Dashboards: </span>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={true}
                  className="dashboard_option"
                  value={is.existy(dashboard) ? dashboard.id : null}
                  onChange={(value) => openDashboardDetails(value)}
                >
                  {dashboards.map((d, index) => (
                    <Select.Option key={index} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </StyleDiv>
              <Description />
            </Col>
            <Col span={5}>
              <StyleDiv>
                <div className="edit_button_section">
                  <Button
                    size="small"
                    onClick={() => {
                      setDescription("");
                      setEditor({ show: true, create: true });
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    size="small"
                    isDisabled={is.null(dashboard)}
                    onClick={() => {
                      setDashboardName(dashboard.name);
                      setEditor({ show: true, create: false });
                    }}
                  >
                    Edit
                  </Button>
                  {is.existy(dashboard) && (
                    <Button
                      size="small"
                      onClick={() => openNewFullScreenWindow(dashboard.id)}
                    >
                      Full Screen
                    </Button>
                  )}
                </div>
              </StyleDiv>
            </Col>
          </Row>
          <Row>
            <div
              style={{
                display: "flex",
                height: window.screen.height - 300,
              }}
            >
              <DashboardWidget
                keyValue={is.existy(dashboard) ? dashboard.id : 0}
                refreshData={true}
                dashboardMapping={viewMapping}
                dashboardLayout={viewLayout}
              />
            </div>
          </Row>
        </Panel>
      )}
    </Spin>
  );
}

export default Dashboards;
