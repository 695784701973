import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom'
import { Panel } from '../../components/zensmart-design-system';
import { Icon as AIcon, Modal, Button, notification, Spin, Row, Select, Col } from 'antd';
import { PageTitle, PreviewRow } from './partials/composites';
import { TitleAndControls, PrimaryFilter, SideBar, SecondaryFilter, DataDisplay, ChartSelection, NumberBucketModal, TextBucketModal, MatrixOptionsModal, NumeratorFilters } from './partials/sections';
import { GetFields, SaveReport, ExportReport, ExportReportJSON, LoadData, GetUniqueValues, LoadLabels, GetReportObjects, GetUniqueValuesCount, GetReport, GetMatrixData, GetPercentage } from './api';
import { GetLabel, AnalyticsStorage, sortObjects, formatUniqueCount, HandleError, GetDateValues } from './utils';
import _ from 'lodash';
import { DataTypes } from 'pages/Performance/utils';
import styled, { createGlobalStyle } from 'styled-components';
import { useHistory, Prompt } from "react-router-dom";
import ZenAnalyticsApi from "./utils/ZenAnalyticsApi"
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print';
import moment from 'moment'
import { generateKey } from '../ProductFlow/utils';
import fileDownload from 'js-file-download';
import { exportSummaryCsvRoute, matrixDataRoute } from "utils/apiRoutes"
import { useScreenWidth } from 'hooks/useScreenWidth';


const { DATATYPE, GetDataType } = DataTypes

const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const stackableChartTypes = [
  'AreaChart',
  'BarChart',
  'ColumnChart',
  "ComboChart",
  "Gauge",
  "LineChart",
  "Line",
  "ScatterChart",
  "SteppedAreaChart",
  "Table"
]

const ReportTitle = styled.h1`
  padding-left: 2rem;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 0;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
`


const Reporting = () => {
  const userData = useSelector(state => state.user.details)
  const history = useHistory();
  const idParams = useParams().reportID
  const [reportID, setReportID] = useState(useParams().reportID)
  const visualizationOptions = ['tabular', 'summary', 'matrix', 'total', 'percent']

  const matrixSummaryOptions = ['average', 'max', 'min', 'record count', 'sum']

  const dateGroupingOptions = {
    "Year": "Y",
    "Exact Month": "M Y",
    "Relative Month": "F",
    "Exact Week": "Y W",
    "Relative Week": "W",
    "Exact Date": "Y-m-d",
    "Relative Date": "m-d",
    "Relative Day": "l",
    "Exact Hour": "Y-m-d H",
    "Relative Hour": "H"
  }

  const availableCharts = {
    "Area": "AreaChart",
    // "Vertical Bar" : "Bar",
    "Horizontal Bar": "BarChart",
    "Vertical Bar": "ColumnChart",
    "Combo": "ComboChart",
    "Gauge": "Gauge",
    "Line": "LineChart",
    "Line 2": "Line",
    "Pie": "PieChart",
    "Scatter": "ScatterChart",
    "Stepped Area": "SteppedAreaChart",
    "Table": "Table",
    "Word Tree": "WordTree"
  }

  const chartSize = {
    'Small': [20, 20],
    'Medium': [40, 40],
    'Large': [60, 60],
    'Extra Large': [80, 80]
  }

  // state defaults
  const filterDefault = {
    field: '',
    type: '',
    operation: '',
    value: [],
    from: '',
    to: ''
  }

  // state
  const screenWidth = useScreenWidth()
  const [dataFields, setDataFields] = useState([])
  const [secondaryFilterItems, setSecondaryFilterItems] = useState([])
  const [primaryFilters, setPrimaryFilters] = useState([])
  const [dragItem, setDragItem] = useState({})
  const [dragBucketItem, setDragBucketItem] = useState({})
  const [edit, setEdit] = useState(true);
  const [editSecondaryFilter, setEditSecondaryFilter] = useState(false)
  const [editPrimaryFilter, setEditPrimaryFilter] = useState(false)
  const [tableColumn, setTableColumn] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [dataSourceCount, setDataSourceCountl] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [reportName, setReportName] = useState('')
  const [reportDescription, setReportDescription] = useState('')
  const [sortationField, setSortationField] = useState('')
  const [sortationFieldType, setSortationFieldType] = useState('')
  const [hideDetails, setHideDetails] = useState(false)
  const [hideFilters, setHideFilters] = useState(false)
  const [hideDropZones, setHideDropZones] = useState(false)
  const [saveAsModal, setSaveAsModal] = useState(false)
  const [visualization, setVisualization] = useState(visualizationOptions[0])
  const [displayChartEditor, setDisplayChartEditor] = useState(false)
  const [chartConfiguration, setChartConfiguration] = useState({})
  const [editChartConfiguration, setEditChartConfiguration] = useState({})
  const [groups, setGroups] = useState([])
  const [uniqueValues, setUniqueValues] = useState([])
  const [bucketFields, setBucketFields] = useState([])
  const [selectedBucketFieldKey, setSelectedBucketFieldKey] = useState()
  const [loadBucketFields, setLoadBucketFields] = useState([])
  const [displayNumberModal, setDisplayNumberModal] = useState(false)
  const [displayTextModal, setDisplayTextModal] = useState(false)
  const [reportingTables, setReportingTables] = useState([])
  const [currentTableObject, setCurrentTableObject] = useState([])
  const [currentTable, setCurrentTable] = useState('')
  const [matrixDataSource, setMatrixDataSource] = useState({})
  const [displayMatrixEditor, setDisplayMatrixEditor] = useState(false)
  const [isPrintReport, setIsPrintReport] = useState(false);
  const [isPrintReportLoading, setIsPrintReportLoading] = useState(false);
  const [isExportReportLoading, setIsExportReportLoading] = useState(false)
  const componentRef = useRef();
  const inputRefName = useRef();
  const inputRefDescription = useRef();
  const inputRefSaveAs = useRef();
  const [modifiedFieldLabels, setModifiedFieldLabels] = useState(null)
  const [sortField, setSortField] = useState({})
  const prevVals = usePrevious({ labels: modifiedFieldLabels });
  const [matrixEditorOptions, setMatrixEditorOptions] = useState({
    field: '',
    option: matrixSummaryOptions[3] // record count
  })
  const [saveReportID, setSaveReportID] = useState({ owner_id: null, id: null })

  const [tableWidth, setTableWidth] = useState(null)
  const [tightTable, setTightTable] = useState(false)

  const [selectedPrimaryFilter, setSelectedPrimaryFilter] = useState({
    field: '',
    type: '',
    operation: '',
    from: '',
    to: '',
    value: ''
  })

  const [selectedSecondaryFilter, setSelectedSecondaryFilter] = useState({
    field: '',
    type: '',
    operation: '',
    from: '',
    to: '',
    value: ''
  })

  const [groupMatrixX, setGroupMatrixX] = useState([])
  const [groupMatrixY, setGroupMatrixY] = useState([])
  const [currentHoveredColumn, setCurrentHoveredColumn] = useState(0)
  const [currentDropColumn, setCurrentDropColumn] = useState(0)
  const [groupedDate, setGroupedDate] = useState([])
  const [groupedDateDropDown, setGroupedDateDropDown] = useState([])
  const [generalLoad, setGeneralLoad] = useState(false)
  // { percent related states
  const [primaryNumeratorFilters, setPrimaryNumeratorFilters] = useState([])
  const [secondaryNumeratorFilters, setSecondaryNumeratorFilters] = useState([])
  const [percentage, setPercentage] = useState(0)
  const [editPrimaryNumeratorFilter, setEditPrimaryNumeratorFilter] = useState(false)
  const [editSecondaryNumeratorFilter, setEditSecondaryNumeratorFilter] = useState(false)
  const [selectedPrimaryNumeratorFilter, setSelectedPrimaryNumeratorFilter] = useState({
    field: '',
    type: '',
    operation: '',
    from: '',
    to: '',
    value: ''
  })
  const [selectedSecondaryNumeratorFilter, setSelectedSecondaryNumeratorFilter] = useState({
    field: '',
    type: '',
    operation: '',
    from: '',
    to: '',
    value: ''
  })

  const loaderArray = useRef([])
  const [labels, setLabels] = useState([])

  const setData = (data = {}) => {

    addToLoad('loading table data from api')

    let dataVisualization = data.visualization || visualization

    LoadData({
      table: data.table || currentTable,
      secondary_filter: data.secondary_filter || (secondaryFilterItems.length ? secondaryFilterItems : undefined),
      primary_filter: data.primary_filter || primaryFilters,
      fields: getFields,
      sort_field: getFields.includes(sortField.field) ? sortField : {},
      bucket_fields: loadBucketFields,
      mode: data.mode || (edit ? 'preview' : 'live'),
      visualization: dataVisualization

    }).then(result => {
      if (dataVisualization === 'tabular' || dataVisualization === "summary") {
        setDataSource(result['data'])
      }
      if (dataVisualization === 'total') {
        setDataSourceCountl(result['actual_record_count'])
      }
    }).catch(err => {
      notification.error({
      message: HandleError(err)
      })
    }).finally(() => {
      removeFromLoad('loading table data from api')
    })
  }

  useEffect(() => {
    const storedItem = localStorage.getItem('analytics_storage')
    if (storedItem) {
      setLabels(JSON.parse(storedItem))
    }
  }, [dragItem])
  const [toRemoveColumn, setToRemoveColumn] = useState(null)
  const [toRemoveBucket, setToRemoveBucket] = useState(null)
  const [reportFields, setReportFields] = useState([])

  const addToLoad = (caller = '') => {
    const callers = loaderArray.current
    callers.push(caller)
    loaderArray.current = [...(new Set(callers))] // make sure it is unique
    if (loaderArray.current.length > 0) {
      setGeneralLoad(true)
    }
  }

  const removeFromLoad = (caller = '') => {
    loaderArray.current.splice(loaderArray.current.indexOf(caller), 1)
    if (loaderArray.current.length === 0) {
      setGeneralLoad(false)
    }
  }

  const removeCustomFields = (labels, fields) => {
    const modifiedLabels = labels ? labels.filter((label) => label.field_label && (!label.field_label.endsWith('*'))) : [];
    const modifiedFields = fields ? fields.filter((field) => modifiedLabels.find((label) => label.resource === field.category && label.field === field.field)) : [];
    return { labels: modifiedLabels, fields: modifiedFields };
  }

  const getFieldLabels = (table) => {
    if (table) {
      GetFields({
        table: table,
      }).then(result => {
        LoadLabels(table).then(async (res) => {
          const response = removeCustomFields(res, result.data);
          AnalyticsStorage.saveData(response.labels);
          setModifiedFieldLabels(response.labels)
          setDataFields(response.fields)
        })
      })


    }
  }

  useEffect(() => {
    // change width of parent-container
    if (!edit && visualization === 'matrix') {
      document.getElementsByClassName('parent-container')[0].style.width = 'fit-content'
      document.getElementsByClassName('panel-div')[0].style.width = 'fit-content'
      document.getElementsByClassName('parent-container')[0].style.minWidth = '100%'
      document.getElementsByClassName('panel-div')[0].style.minWidth = '100%'
    } else {
      document.getElementsByClassName('parent-container')[0].style.width = '100%'
      document.getElementsByClassName('panel-div')[0].style.width = '100%'
    }
  }, [edit, visualization])

  useEffect(() => {
    if (reportID && reportID != undefined) {
      GetReport(reportID).then(report => {
        if (report.data) {
          const { matrix_summary, matrix_summary_field } = report.data
          if (matrix_summary && matrix_summary_field) {
            setMatrixEditorOptions({ field: matrix_summary_field, option: matrix_summary})
          }
          const { fields, table, name, description, group_matrix_x, group_matrix_y, grouped_date, tight_table, hide_details, hide_drop_zones, sortation_field, sortation_field_type, sort_field, groups, secondary_filter, primary_filter, bucket_fields, chart_configuration, visualization, primary_numerator_filters, secondary_numerator_filters } = report.data

          group_matrix_x ? setGroupMatrixX(group_matrix_x) : setGroupMatrixX([])
          group_matrix_y ? setGroupMatrixY(group_matrix_y) : setGroupMatrixY([])
          grouped_date ? setGroupedDate(grouped_date) : setGroupedDate([])
          setCurrentTable(table)
          setReportName(name)
          if (sort_field && JSON.parse(sort_field)) {
            setSortField(JSON.parse(sort_field))
          }
          setReportFields(fields)
          setTightTable(tight_table)
          setReportDescription(description)
          setHideDetails(hide_details)
          setHideDropZones(hide_drop_zones)
          setSortationField(sortation_field)
          setSortationFieldType(sortation_field_type)
          setVisualization(visualization)
          setPrimaryNumeratorFilters(primary_numerator_filters)
          setSecondaryNumeratorFilters(secondary_numerator_filters)

          let modifyPrimaryFilter;
          let modifySecondaryFilter;

          // match the from and to date base on the date and time now (e.g today)
          if (primary_filter && primary_filter.length > 0) {
            modifyPrimaryFilter = primary_filter.map(data => {              if ((data.type === "timestamp" || data.type === "date") && (data.value && data.value.length > 0 && data.value[0] !== "custom" && data.value[0] !== null) && Array.isArray(data.value)) {
                data.from = GetDateValues(data.value[0])[0].format('YYYY-MM-DD') + " 00:00:00"
                data.to = GetDateValues(data.value[0])[1].format('YYYY-MM-DD') + " 23:59:59"
              }
              return data
            })
            setPrimaryFilters(modifyPrimaryFilter)
          }
          else {
            setPrimaryFilters(primary_filter)
          }

          if (secondary_filter && secondary_filter.length > 0) {
            const modifySecondaryFilter = secondary_filter.map(data => {
              if ((data.type === "timestamp" || data.type === "date") && (data.value && data.value.length > 0 && data.value[0] !== "custom" && data.value[0] !== null) && Array.isArray(data.value)) {
                data.from = GetDateValues(data.value[0])[0].format('YYYY-MM-DD') + " 00:00:00"
                data.to = GetDateValues(data.value[0])[1].format('YYYY-MM-DD') + " 23:59:59"
              }
              return data
            })
            setSecondaryFilterItems(modifySecondaryFilter)
          }
          else {
            setSecondaryFilterItems(secondary_filter)
          }

                    // matrix has it's own function that sets initial value
                    if (visualization !== 'matrix') {
                      setData({
                        table,
                        primary_filter: modifyPrimaryFilter || primary_filter,
                        secondary_filter: modifySecondaryFilter || secondary_filter,
                        visualization,
                        mode: 'live'
                      });
                    }

          setBucketFields(bucket_fields)
          setGroups(groups)
          setEditChartConfiguration(chart_configuration)
          setSaveReportID({ owner_id: report.data.owner_id, id: report.data.id })
          setEdit(false)
        }
      })
    }
  }, [reportID]);

  useEffect(() => {
    if (dataFields.length > 0) {
      const thisDataFields = reportFields.map(field => {
        const splitField = field.split('.')
        var thisField = ""

        if (splitField.length === 1) {
          const aBucketField = _.find(bucketFields, { name: splitField[0] })

          if (aBucketField) {
            thisField = bucketToColumn(aBucketField)
          }
        }

        if (splitField.length === 2) {
          const aNormalField = _.find(dataFields, {
            table: splitField[0],
            field: splitField[1]
          })

          if (aNormalField) {
            thisField = aNormalField
          }
        }

        return thisField
      })

      if (thisDataFields.length) {
        setTableColumn(thisDataFields)
      }
    }
  }, [dataFields])

  useEffect(() => {
    // Get available report objects
    GetReportObjects().then(result => {
      setReportingTables(result.data);
    })
  }, []);

  useEffect(() => {
    setReportID(idParams);
  }, [idParams]);



  useEffect(() => {
    if (currentTable && reportingTables.length > 0) {
      let currTblObj = reportingTables.find(el => el['name'] === currentTable)
      if (currTblObj) {
        setCurrentTableObject(currTblObj)
        // If a new report and a new report object is selected, 
        //set the default primary filter to 'created_at'
        //(from whatever object is the base object of the report) with a date range of 'today'
        if (window.location.href.substr(window.location.href.lastIndexOf('/') + 1) == 'reports') {
          setPrimaryFilters([{
            "category": currTblObj.table,
            "created_at": null,
            "field": "created_at",
            "from": moment().format('YYYY-MM-DD 00:00:00'),
            "key": generateKey(),
            "path": "created_at",
            "table": currTblObj.table,
            "to": moment().format('YYYY-MM-DD 23:59:59'),
            "type": "timestamp",
            "updated_at": null,
            "value": ["today"],
          }])

        }
      }
    }
  }, [currentTable, reportingTables]);

  useEffect(() => {
    if (currentTable) {
      getFieldLabels(currentTable)
    }
  }, [currentTable]);


  const getFields = useMemo(() => {
    const fields = tableColumn.map(item => {
      if (GetDataType(item.type) !== DATATYPE.bucket) {
        return item.category + '.' + item.field
      }
    }).filter(item => item !== undefined)
    return fields
  }, [tableColumn])

  useEffect(() => {
    if (currentTable &&
        (
            visualization === 'tabular' ||
            visualization === 'total' ||
            (visualization === "summary" && groups.length === 0)) && modifiedFieldLabels
    ) {
      setData()
    }
  }, [secondaryFilterItems, edit, primaryFilters, tableColumn, currentTable, loadBucketFields, bucketFields, visualization, groups, sortField, modifiedFieldLabels]);

    useEffect(() => {
    if (currentTable && prevVals && prevVals.labels) {
      setData();
    }
  }, [modifiedFieldLabels]);

  useEffect(() => {
    if (_.isEmpty(primaryNumeratorFilters) || _.isEmpty(secondaryNumeratorFilters)) {
      setPercentage(0)
    }
    if (
        currentTable &&
        visualization === 'percent' &&
        (
            !_.isEmpty(primaryNumeratorFilters) ||
            !_.isEmpty(secondaryNumeratorFilters)
        )
    ) {
      addToLoad('loading percent data from api')
      GetPercentage({
        table: currentTable,
        percent_filter: (primaryNumeratorFilters.length || secondaryNumeratorFilters.length) ? [...primaryNumeratorFilters, ...secondaryNumeratorFilters] : undefined,
        secondary_filter: secondaryFilterItems.length ? secondaryFilterItems : undefined,
        primary_filter: primaryFilters,
        fields: tableColumn.map(item => {
          if (GetDataType(item.type) !== DATATYPE.bucket) {
            return item.category + '.' + item.field
          }
        }).filter(item => item !== undefined),
        bucket_fields: loadBucketFields
      }).then(result => {
        setPercentage(result.percent ? result.percent : 0)
      }).catch(err => {
        notification.error({
          message: HandleError(err)
        })
      }).finally(() => {
        removeFromLoad('loading percent data from api')
      })
    }
  }, [secondaryFilterItems, edit, primaryFilters, tableColumn, currentTable, loadBucketFields, bucketFields, primaryNumeratorFilters, secondaryNumeratorFilters, visualization]);

  useEffect(() => {
    if (currentTable && visualization === 'summary' && groups.length !== 0 && modifiedFieldLabels) {
      const fieldValues = tableColumn.map(item => {
        if (GetDataType(item.type) !== DATATYPE.bucket) {
          return item.category + '.' + item.field
        }
      }).filter(item => item !== undefined)
      const loadUnique = setTimeout(() => {
        addToLoad('loading summary data from api')
        GetUniqueValues({
          table: currentTable,
          groups: groups,
          fields: fieldValues,
          sort_field: fieldValues.includes(sortField.field) || groups.includes(sortField.field) ? sortField : {},
          secondary_filter: secondaryFilterItems.length ? secondaryFilterItems : undefined,
          primary_filter: primaryFilters,
          bucket_fields: bucketFields,
          grouped_dates: groupedDate,
          limit: edit ? 50 : 2000
        }).then(result => {
          setUniqueValues(sortObjects(result))
        }).catch(err => {
          notification.error({
            message: HandleError(err)
          })
        }).finally(() => {
          removeFromLoad('loading summary data from api')
        })
      }, 500);

      return () => {
        clearTimeout(loadUnique)
      }
    }
  }, [groups, secondaryFilterItems, primaryFilters, tableColumn, currentTable, bucketFields, groupedDate, visualization, edit, sortField]);

  useEffect(() => {
    if (currentTable && visualization === 'matrix') {
      const loadMatrix = setTimeout(() => {
        if (groupMatrixX && groupMatrixY && groupMatrixX.length === 0 && groupMatrixY.length === 0) {
          setMatrixDataSource({
            x_fields: [],
            y_fields: [],
            grouped_data: {}
          })
        } else {
          addToLoad('loading matrix data from api')
          GetMatrixData({
            table: currentTable,
            secondary_filter: secondaryFilterItems.length ? secondaryFilterItems : undefined,
            primary_filter: primaryFilters,
            // add number fields to allow for matrix summarization; average, sum, etc
            fields: matrixEditorOptions.field ? [matrixEditorOptions.field] : [],
            groupX: groupMatrixX,
            groupY: groupMatrixY,
            grouped_dates: groupedDate,
            bucket_fields: bucketFields,
            sort_field: sortField,
            limit: edit ? 50 : 100000000000000,
            matrix_summary: matrixEditorOptions ? matrixEditorOptions.option || null : null,
            matrix_summary_field: matrixEditorOptions ? matrixEditorOptions.field || null : null,
          }).then(result => {
            setMatrixDataSource(result.data)
          }).catch(err => {
            notification.error({
              message: HandleError(err)
            })
          }).finally(() => {
            removeFromLoad('loading matrix data from api')
          })
        }
      }, 500);

      return () => {
        clearTimeout(loadMatrix)
      }
    }
  }, [groupMatrixX, groupMatrixY, secondaryFilterItems, primaryFilters, currentTable, bucketFields, groupedDate, edit, matrixEditorOptions, visualization, sortField]);

  const getOnlyGroupedDatesOnFields = (array, fields) => {
    const returnValue = {}
    Object.keys(array).map(data => {
      if (fields.includes(data))
        returnValue[data] = array[data]
    })
    return returnValue;
  }
  useEffect(() => {
    // wrap this in a function and pass in the current config...
    if(!modifiedFieldLabels)
    {
      return
    }
    if (
        (!_.isEmpty(editChartConfiguration)) &&
        editChartConfiguration.type &&
        editChartConfiguration.yAxis && chartConfiguration
    ) {
      const fields = [editChartConfiguration.yAxis]
      if (editChartConfiguration.stackData) {
        fields.push(editChartConfiguration.stackData)
      }
      // logics to update the charts when grouping is updating
      if (editChartConfiguration.options && groups.length === 2 && !stackableChartTypes.includes(editChartConfiguration.type)) {
        delete editChartConfiguration.stackData
        editChartConfiguration.options.isStacked = false
      }
      else if (editChartConfiguration.options && groups.length === 2 && editChartConfiguration.stackData) {
        editChartConfiguration.options.isStacked = true
        if (groups.some(data => data !== editChartConfiguration.yAxis)) {
          editChartConfiguration.yAxis = groups.find(data => data !== editChartConfiguration.stackData)
        }
        editChartConfiguration.stackData = groups.find(data => data !== editChartConfiguration.yAxis)

      }
      else if (editChartConfiguration.stackData && groups.length === 1 && editChartConfiguration.yAxis === groups[0]) {
        delete editChartConfiguration.stackData
        editChartConfiguration.options.isStacked = false
      }
      else if (editChartConfiguration.stackData && groups.length === 1 && editChartConfiguration.stackData === groups[0]) {
        editChartConfiguration.yAxis = editChartConfiguration.stackData
        delete editChartConfiguration.stackData
        editChartConfiguration.options.isStacked = false
      }
      else if ((editChartConfiguration.stackData && (!groups.some(data => data === editChartConfiguration.stackData) || !groups.some(data => data === editChartConfiguration.yAxis))) || groups.length === 0) {
        return deleteChart()
      }

      addToLoad('loading table data from api')
      const fieldsAndGroups = !editChartConfiguration.stackData ? fields : groups.length === 1 || groups.length === 2 ? groups : fields
      GetUniqueValuesCount({
        table: currentTable,
        fields: fieldsAndGroups,
        secondary_filter: secondaryFilterItems.length ? secondaryFilterItems : undefined,
        primary_filter: primaryFilters,
        bucket_fields: bucketFields,
        sort_field: fieldsAndGroups.includes(sortField.field) ? sortField : {},
        limit: edit ? 50 : visualization === 'matrix' ? 100000000000000 : 2000,
        summary_type: matrixEditorOptions.option ? matrixEditorOptions.option : undefined,
        summary_field: matrixEditorOptions.field ? matrixEditorOptions.field : undefined,
        groups: fieldsAndGroups,
        grouped_dates: Object.keys(groupedDate).length > 0 ? getOnlyGroupedDatesOnFields(groupedDate, fields) : []
      }).then(uniqueData => {
        removeFromLoad('loading table data from api')
        setChartConfiguration(
            formatUniqueCount(
                uniqueData,
                editChartConfiguration,
                matrixEditorOptions.option,
                false,
                getGroupType,
                groupedDate
            )
        )
      }).catch(err => {
        notification.error({ message: "Cant retrieve chart data!" })
        removeFromLoad('loading table data from api')
      })
    }
  }, [editChartConfiguration, primaryFilters, secondaryFilterItems, groups, groupedDate, matrixEditorOptions, edit, sortField, modifiedFieldLabels]);

  // { page reload

  const refreshWithObject = (tableName) => {
    setCurrentTable(tableName)
    resetState()
  }

  const resetState = () => {
    setSecondaryFilterItems([])
    setPrimaryFilters([])
    setDragItem({})
    setDragBucketItem({})
    setEdit(true)
    setEditSecondaryFilter(false)
    setEditPrimaryFilter(false)
    setTableColumn([])
    setReportFields([])
    setDataSource([])
    setSortField({})
    // setReportName('')
    setHideDetails(false)
    setHideDropZones(false)
    setVisualization(visualizationOptions[0])
    setDisplayChartEditor(false)
    setChartConfiguration({})
    setEditChartConfiguration({})
    setGroups([])
    setUniqueValues([])
    setBucketFields([])
    setSelectedBucketFieldKey()
    setLoadBucketFields([])
    setDisplayNumberModal(false)
    setDisplayTextModal(false)
    setTightTable(true)
    setSelectedPrimaryFilter({
      field: '',
      type: '',
      operation: '',
      from: '',
      to: '',
      value: ''
    })
    setSelectedSecondaryFilter({
      field: '',
      type: '',
      operation: '',
      from: '',
      to: '',
      value: ''
    })
    setGroupMatrixX([])
    setGroupMatrixY([])
    setMatrixDataSource({})
    setDisplayMatrixEditor(false)
    setMatrixEditorOptions({
      field: '',
      option: matrixSummaryOptions[3] // record count
    })
    setCurrentDropColumn(0)
    setCurrentHoveredColumn(0)
    setGroupedDate([])
    setGroupedDateDropDown([])
  }
  // }

  const removeColumn = (column) => {
    setToRemoveColumn(column)
  }

  useEffect(() => {
    const removeTableColumn = tableColumn.filter(item => item.id !== toRemoveColumn.id)
    const findIfDuplicatedName = removeTableColumn.filter(item => item.field == toRemoveColumn.field)

    if (findIfDuplicatedName.length === 1) {
      const changeDuplicatedName = removeTableColumn.map(data => {
        if (data.id === findIfDuplicatedName[0].id) {
          // data.path = data.field
          data.path = GetLabel(data.table + '.' + data.field, labels)
        }
        return data
      })
      setTableColumn(changeDuplicatedName)
      return
    }

    setTableColumn(removeTableColumn)
  }, [toRemoveColumn])

  const removeLoadedBucket = useCallback((bucket) => {
    setToRemoveBucket(bucket)
    setLoadBucketFields(loadBucketFields => {
      return [..._.pull(loadBucketFields, bucket)]
    })
  }, [toRemoveBucket])

  const populateGroupedDate = (item, option) => {
    setGroupedDateDropDown({ ...groupedDateDropDown, [item]: option })
    setGroupedDate(groupedDate => {
      groupedDate[item] = dateGroupingOptions[option]
      return { ...groupedDate }
    })
  }

  const getGroupType = (group) => {
    const groupComponents = group ? group.split(".") : false
    const table = groupComponents ? groupComponents[0] : ""
    const field = groupComponents ? groupComponents[1] : ""

    const item = _.find(dataFields, {
      table: table,
      field: field
    })

    if (item) {
      return item.type
    }
    return ''
  }

  const containsGroupedItem = (item) => {
    return groupedDateDropDown[item]
  }

  const chooseTable = (proposedTable) => {
    switch (currentTable) {
      case '':
        refreshWithObject(proposedTable)
        break;
      case proposedTable:
        //do nothing
        break;
      default:
        Modal.confirm({
          title: 'Do you want to switch table?',
          icon: <AIcon type='exclamation' />,
          content: 'Switching tables will clear all modifications. Click OK to proceed.',
          onOk() {
            refreshWithObject(proposedTable)
          },
          onCancel() { },
        })
        break;
    }
  }

  const populatePrimaryFilters = (newValue, key = undefined) => {
    setPrimaryFilters(formerValue => {
      if (key !== undefined) {
        //edit
        newValue.key = generateKey()
        formerValue[key] = newValue
      } else {
        //create
        newValue.key = generateKey()
        formerValue = [...formerValue, newValue]
      }
      setSelectedPrimaryFilter(filterDefault)
      return [...(formerValue)];
    })
  }

  const deletePrimaryFilterItem = (key) => {
    setPrimaryFilters(primaryFilters.filter(data => data.key !== key.key))

  }

  const populateSecondaryFilter = (newValue, key = undefined) => {
    setSecondaryFilterItems(formerValue => {
      if (key !== undefined) {
        //edit
        newValue.key = generateKey()
        formerValue[key] = newValue
      } else {
        //create
        newValue.key = generateKey()
        formerValue = [...formerValue, newValue]
      }

      return [...(formerValue)];
    })
  }

  const deleteSecondaryFilterItem = (theValue) => {
    const checkExistense = (item) => {
      return item.key !== theValue.key
    };
    setSecondaryFilterItems(formerValue => (
        formerValue.filter(checkExistense)
    ))
  }
  const addToTable = (newValue) => {
    setTableColumn((formerValue) => {
      const exist = _.findIndex(formerValue, newValue)
      var ifSameColumn = false
      const findSameColumn = formerValue.map(data => {
            if (data.field == newValue.field && data.id !== newValue.id) {
              data.path = `${data.table} ${GetLabel(data.table + '.' + data.field, labels)}`
              ifSameColumn = true
            }
            return data
          }
      )
      if (ifSameColumn) {
        newValue.path = `${newValue.table} ${GetLabel(newValue.table + '.' + newValue.field, labels)}`
      }
      else {
        newValue.path = GetLabel(newValue.table + '.' + newValue.field, labels)
      }
      if (exist !== -1) {
        findSameColumn.splice(exist, 1)

        const newCurrentDropColumn = exist < currentDropColumn ? currentDropColumn - 1 : currentDropColumn

        findSameColumn.splice(newCurrentDropColumn, 0, newValue);
      } else {
        findSameColumn.splice(currentDropColumn, 0, newValue);
      }
      ifSameColumn = false
      return [...findSameColumn];
    })
  }

  const dropInTable = (e) => {
    e.preventDefault();
    if (!_.isEmpty(dragBucketItem)) {
      addToTable(bucketToColumn(dragBucketItem))
      setLoadBucketFields(loadBucketFields => {
        loadBucketFields.push(dragBucketItem)
        return [..._.uniq(loadBucketFields)]
      })
    } else {
      addToTable(dragItem)
    }
  }

  const addBucketFieldToTable = (dragBucketItem) => {
    setLoadBucketFields(loadBucketFields => {
      const exists = _.some(loadBucketFields, dragBucketItem)
      if (!exists) {
        loadBucketFields.push(dragBucketItem)
        addToTable(bucketToColumn(dragBucketItem))
      }
      return [...loadBucketFields]
    })
  }

  const dropInSecondary = (e) => {
    e.preventDefault();
    setEditSecondaryFilter(true);
    if (!_.isEmpty(dragBucketItem)) {
      addToSecondaryFilter(bucketToColumn(dragBucketItem))
    } else {
      addToSecondaryFilter(dragItem)
    }
  }

  const dropInPrimary = (e) => {
    e.preventDefault()
    setEditPrimaryFilter(true)
    if (!_.isEmpty(dragBucketItem)) {
      addToPrimaryFilter(bucketToColumn(dragBucketItem))
    } else {
      addToPrimaryFilter(dragItem)
    }
  }

  // { percent related functions

  const dropInPrimaryNumerator = (e) => {
    e.preventDefault()
    setEditPrimaryNumeratorFilter(true)
    if (!_.isEmpty(dragBucketItem)) {
      addToPrimaryNumeratorFilter(bucketToColumn(dragBucketItem))
    } else {
      addToPrimaryNumeratorFilter(dragItem)
    }
  }

  const deletePrimaryNumeratorField = (theKey) => {
    setPrimaryNumeratorFilters(primaryNumeratorFilters => {
      primaryNumeratorFilters.splice(theKey, 1)
      return [...primaryNumeratorFilters]
    })
  }

  const addToPrimaryNumeratorFilter = (newValue) => {
    setSelectedPrimaryNumeratorFilter({ ...newValue })
  }

  const populatePrimaryNumeratorFilter = (newValue, key = undefined) => {
    setPrimaryNumeratorFilters(formerValue => {
      if (key !== undefined) {
        //edit
        newValue.key = generateKey()
        formerValue[key] = newValue
      } else {
        //create
        newValue.key = generateKey()
        formerValue = [...formerValue, newValue]
      }

      return [...(formerValue)];
    })
  }

  const dropInSecondaryNumerator = (e) => {
    e.preventDefault()
    setEditSecondaryNumeratorFilter(true)
    if (!_.isEmpty(dragBucketItem)) {
      addToSecondaryNumeratorFilter(bucketToColumn(dragBucketItem))
    } else {
      addToSecondaryNumeratorFilter(dragItem)
    }
  }

  const deleteSecondaryNumeratorField = (theKey) => {
    setSecondaryNumeratorFilters(primaryNumeratorFilters => {
      primaryNumeratorFilters.splice(theKey, 1)
      return [...primaryNumeratorFilters]
    })
  }

  const addToSecondaryNumeratorFilter = (newValue) => {
    setSelectedSecondaryNumeratorFilter({ ...newValue })
  }

  const populateSecondaryNumeratorFilter = (newValue, key = undefined) => {
    setSecondaryNumeratorFilters(formerValue => {
      if (key !== undefined) {
        //edit
        newValue.key = generateKey()
        formerValue[key] = newValue
      } else {
        //create
        newValue.key = generateKey()
        formerValue = [...formerValue, newValue]
      }

      return [...(formerValue)];
    })
  }

  // }

  const addToSecondaryFilter = (newValue) => {
    setSelectedSecondaryFilter({ ...newValue })
  }

  const addToPrimaryFilter = (newValue) => {
    setSelectedPrimaryFilter({ ...newValue })
  }

  const dropInSummaryGrouping = () => {
    let column_item = ''
    if (!_.isEmpty(dragBucketItem)) {
      column_item = dragBucketItem.name
    } else {
      if (GetDataType(dragItem.type) === DATATYPE.bucket) {
        column_item = dragItem.field
      } else {
        column_item = `${dragItem.table}.${dragItem.field}`
      }
    }
    addToGrouping(column_item)
  }

  const addToGrouping = (theItem) => {
    if (!groups.includes(theItem) && groups.length < 3) {
      setGroups([...groups, theItem])
      if (groupMatrixY && groupMatrixY.length < 2 && !groupMatrixY.includes(theItem)) {
        setGroupMatrixY(groupMatrixY => [...groupMatrixY, theItem])
      } else if (groupMatrixX && groupMatrixX.length < 2 && !groupMatrixX.includes(theItem)) {
        setGroupMatrixX(groupMatrixX => [...groupMatrixX, theItem])
      }
    }
  }

  const dragStartMatrix = (matrixItem) => {
    const splitItem = matrixItem.split('.')
    // get the equivalent data from fields and set drag item
    const thisField = dataFields.filter(field => (
        field.table === splitItem[0] && field.field === splitItem[splitItem.length - 1]
    ))

    setDragItem(thisField.length ? thisField[0] : {})
  }

  const dragEndMatrix = () => {
    setDragItem({})
  }

  const dropMatrixX = (e) => {
    e.preventDefault();
    const column_item = {
      field: '',
      table: ''
    }
    if (!_.isEmpty(dragBucketItem)) {
      column_item.field = dragBucketItem.name
      // column_item.table = dragBucketItem.column.table
      column_item.table = ''
    } else {
      column_item.field = dragItem.field
      column_item.table = dragItem.table
    }

    const droppedItem = column_item.table ? `${column_item.table}.${column_item.field}` : column_item.field;

    // if the value exists in the other matrix group, remove
    if (groupMatrixY.includes(droppedItem)) {
      deleteMatrixYitem(droppedItem)
      setGroupMatrixX(groupMatrixX => [...groupMatrixX, droppedItem])

    } else if (!groups.includes(droppedItem) && !groupMatrixX.includes(droppedItem)) {
      setGroups([...groups, droppedItem])
      setGroupMatrixX(groupMatrixX => [...groupMatrixX, droppedItem])
    }
  }
  const deleteMatrixXitem = (groupXItem, ifDeleteGroup) => {
    setGroupMatrixX(groupMatrixX => {
      groupMatrixX.splice(groupMatrixX.indexOf(groupXItem), 1)
      return [...groupMatrixX]
    })

    // get the values index in group and delete
    const index = groups.indexOf(groupXItem)
    if (index !== -1) {
      const groupItem = groups[index]
      chartConfiguration.yAxis === groupItem && (
          setChartConfiguration({})
      )
      if (ifDeleteGroup) {
        setGroups(groups => {
          groups.splice(index, 1)
          return [...groups]
        })
        delete groupedDate[groupXItem]
        delete groupedDateDropDown[groupXItem]

      }
    }
  }
  const deleteMatrixYitem = (groupYItem, ifDeleteGroup) => {
    setGroupMatrixY(groupMatrixY => {
      groupMatrixY.splice(groupMatrixY.indexOf(groupYItem), 1)
      return [...groupMatrixY]
    })

    // get the values index in group and delete
    const index = groups.indexOf(groupYItem)

    if (index !== -1) {
      const groupItem = groups[index]
      chartConfiguration.yAxis === groupItem && (
          setChartConfiguration({})
      )
      if (ifDeleteGroup) {
        setGroups(groups => {
          groups.splice(index, 1)
          return [...groups]
        })
        delete groupedDate[groupYItem]
        delete groupedDateDropDown[groupYItem]

      }
    }
  }

  const dropMatrixY = (e) => {
    e.preventDefault();
    const column_item = {
      field: '',
      table: ''
    }
    if (!_.isEmpty(dragBucketItem)) {
      column_item.field = dragBucketItem.name
      // column_item.table = dragBucketItem.column.table
      column_item.table = ''
    } else {
      column_item.field = dragItem.field
      column_item.table = dragItem.table
    }

    const droppedItem = column_item.table ? `${column_item.table}.${column_item.field}` : column_item.field

    if (groupMatrixX.includes(droppedItem)) {
      deleteMatrixXitem(droppedItem)
      setGroupMatrixY(groupMatrixY => [...groupMatrixY, droppedItem])
    } else if (!groups.includes(droppedItem) && !groupMatrixY.includes(droppedItem)) {
      setGroups([...groups, droppedItem])
      setGroupMatrixY(groupMatrixY => [...groupMatrixY, droppedItem])
    }
  }

  const dragOver = (e) => {
    e.preventDefault();
  }

  const bucketsToColumns = () => {
    return bucketFields.map(bucket => bucketToColumn(bucket))
  }

  const bucketToColumn = (bucket) => (
      {
        "category": bucket.column.category,
        "field": bucket.name,
        "table": bucket.column.table,
        "type": "bucket",
        "bucket": true
      }
  )

  const populateBucketField = (field, index = null) => {
    setBucketFields(bucketFields => {
      if (index) {
        // update
        bucketFields[index] = field
      } else {
        // create
        bucketFields.push(field)
      }

      const uniqueBucketFields = _.uniqBy(bucketFields, field => field.name)
      setSelectedBucketFieldKey(undefined)
      return [...uniqueBucketFields]
    })
  }

  const addBucketField = (modal) => {
    setSelectedBucketFieldKey(undefined)
    displayBucketModal(modal)
  }

  const editBucketField = (index) => {
    if (index !== undefined) {
      setSelectedBucketFieldKey(index)
      const thisField = bucketFields[index]
      if (thisField) {
        const modalType = GetDataType(thisField.column.type) === DATATYPE.number ? 'number' : 'text'
        displayBucketModal(modalType)
      }
    }
  }

  const deleteBucketField = (index) => {
    if (index !== undefined) {
      setBucketFields(bucketFields => {
        setSelectedBucketFieldKey(undefined)
        bucketFields.splice(index, 1)
        return [...bucketFields]
      })
    }
  }

  const displayBucketModal = (modal = 'text') => {
    if (modal === 'text') {
      setDisplayTextModal(true)
    } else {
      setDisplayNumberModal(true)
    }
  }

  const removeGroup = (index) => {

    const groupItem = groups[index]
    chartConfiguration.yAxis === groupItem && (
        setChartConfiguration({})
    )
    setGroups(groups => {
      groups.splice(index, 1)
      return [...groups]
    })
    delete groupedDate[groupItem]
    delete groupedDateDropDown[groupItem]
    if (groupMatrixX && groupMatrixX.includes(groupItem)) {
      setGroupMatrixX(groupMatrixX => {
        groupMatrixX.splice(groupMatrixX.indexOf(groupItem), 1)
        return [...groupMatrixX]
      })
    } else if (groupMatrixY && groupMatrixY.includes(groupItem)) {
      setGroupMatrixY(groupMatrixY => {
        groupMatrixY.splice(groupMatrixY.indexOf(groupItem), 1)
        return [...groupMatrixY]
      })
    }
  }

  const addChart = () => {
    setEditChartConfiguration({})
    setDisplayChartEditor(true)
  }

  const editChart = () => {
    setEditChartConfiguration(chartConfiguration)
    setDisplayChartEditor(true)
  }

  const deleteChart = () => {
    setChartConfiguration({})
    setEditChartConfiguration({})
  }

  const handleChartSelection = (chartConfig) => {
    setEditChartConfiguration(chartConfig)
    setDisplayChartEditor(false)
  }

  const saveReport = async () => {
    if (reportName) {

      const save = await SaveReport({
        name: reportName,
        id: saveReportID.id ? saveReportID.id : null,
        description: reportDescription,
        tight_table: tightTable,
        sortation_field: sortationField,
        sortation_field_type: sortationFieldType,
        hide_details: hideDetails,
        hide_drop_zones: hideDropZones,
        table: currentTable,
        primary_filter: primaryFilters,
        secondary_filter: secondaryFilterItems,
        bucket_fields: bucketFields,
        owner_id: userData.id,
        sort_field: sortField,
        fields: tableColumn.map(item => {
          if (GetDataType(item.type) !== DATATYPE.bucket) {
            return item.category + '.' + item.field
          }
        }).filter(item => item !== undefined),
        groups: groups,
        //visualization: chartConfiguration.type ? chartConfiguration.type : 'table',
        visualization: visualization,
        chartConfiguration: chartConfiguration,
        group_matrix_x: groupMatrixX,
        group_matrix_y: groupMatrixY,
        grouped_date: groupedDate,
        primary_numerator_filters: primaryNumeratorFilters,
        secondary_numerator_filters: secondaryNumeratorFilters,
        ...(matrixEditorOptions.option && {matrix_summary: matrixEditorOptions.option}),
        ...(matrixEditorOptions.field && {matrix_summary_field: matrixEditorOptions.field})
      }).then((res) => {
        setSaveReportID({ owner_id: res.owner_id, id: res.report_id })
        notification.success({
          message: 'Report saved!'
        })
      }).catch((res) => {
        if (res.response.status === 500) {
          notification.error({
            message: 'Report name already exist!'
          })
          return
        }
      });
    } else {
      notification.error({
        message: 'Please enter a report name to save.'
      })
    }
  }

  const saveAsReport = async () => {
    const getReportNameInput = inputRefSaveAs.current.state ? inputRefSaveAs.current.state.value : null
    if (getReportNameInput && getReportNameInput.length > 0) {
      const save = await SaveReport({
        name: getReportNameInput,
        id: null,
        description: reportDescription,
        tight_table: tightTable,
        sortation_field: sortationField,
        sortation_field_type: sortationFieldType,
        hide_details: hideDetails,
        hide_drop_zones: hideDropZones,
        table: currentTable,
        primary_filter: primaryFilters,
        secondary_filter: secondaryFilterItems,
        bucket_fields: bucketFields,
        owner_id: userData.id,
        fields: tableColumn.map(item => {
          if (GetDataType(item.type) !== DATATYPE.bucket) {
            return item.category + '.' + item.field
          }
        }).filter(item => item !== undefined),
        groups: groups,
        visualization: visualization,
        chartConfiguration: chartConfiguration,
        group_matrix_x: groupMatrixX,
        group_matrix_y: groupMatrixY,
        grouped_date: groupedDate,
        primary_numerator_filters: primaryNumeratorFilters,
        secondary_numerator_filters: secondaryNumeratorFilters,
        ...(matrixEditorOptions.option && {matrix_summary: matrixEditorOptions.option}),
        ...(matrixEditorOptions.field && {matrix_summary_field: matrixEditorOptions.field})
      });
      if (save) {
        notification.success({
          message: 'Report saved!'
        })
        history.push(`/app/performance/reports/${save.report_id}`)
        setSaveAsModal(false)

      }
    } else {
      notification.error({
        message: 'Please enter a report name to save.'
      })
    }
  }

  const saveAndRunReport = () => {
    if (reportName) {
      SaveReport({
        name: reportName,
        id: saveReportID.id ? saveReportID.id : null,
        description: reportDescription,
        tight_table: tightTable,
        sortation_field: sortationField,
        sortation_field_type: sortationFieldType,
        sort_field: sortField,
        hide_details: hideDetails,
        hide_drop_zones: hideDropZones,
        table: currentTable,
        primary_filter: primaryFilters,
        secondary_filter: secondaryFilterItems,
        bucket_fields: bucketFields,
        owner_id: userData.id,
        fields: tableColumn.map(item => {
          if (GetDataType(item.type) !== DATATYPE.bucket) {
            return item.category + '.' + item.field
          }
        }).filter(item => item !== undefined),
        groups: groups,
        visualization: visualization,
        chartConfiguration: chartConfiguration,
        group_matrix_x: groupMatrixX,
        group_matrix_y: groupMatrixY,
        grouped_date: groupedDate,
        primary_numerator_filters: primaryNumeratorFilters,
        secondary_numerator_filters: secondaryNumeratorFilters,
        ...(matrixEditorOptions.option && {matrix_summary: matrixEditorOptions.option}),
        ...(matrixEditorOptions.field && {matrix_summary_field: matrixEditorOptions.field})
      }).then((res) => {
        // Load data in live mode
        if (res) {
          setSaveReportID({ owner_id: res.owner_id, id: res.report_id })
        }
        LoadData({
          table: currentTable,
          secondary_filter: secondaryFilterItems.length ? secondaryFilterItems : undefined,
          primary_filter: primaryFilters,
          fields: tableColumn.map(item => {
            if (GetDataType(item.type) !== DATATYPE.bucket) {
              return item.category + '.' + item.field
            }
          }).filter(item => item !== undefined),
          mode: 'live',
          visualization: visualization,
          bucket_fields: loadBucketFields
        }).then(result => {
          let total_count = result['total_count'] || 0;
          setDataSource(result['data'])
          setTotalCount(total_count);
          setDataSourceCountl(result['actual_record_count'])
        })
        setEdit(false)
        notification.success({
          message: 'Report saved!'
        })
        if (!reportID || reportID != res.report_id) {
          window.location.replace(`/app/performance/reports/${res.report_id}`)
        }
      }).catch((res) => {
        notification.error({
          message: 'Report name already exist!'
        })
        return
      });

    } else {
      notification.error({
        message: 'Please enter a report name to save.'
      })
    }
  }

  const deleteReport = () => {
    if (saveReportID.owner_id === userData.id) {
      Modal.confirm({
        'content': 'Are you sure you want to delete this report?',
        onOk: () => {
          ZenAnalyticsApi.post('report/delete', saveReportID)
          .then(result => {
            notification.success({
              message: "Delete Report Successful!"
            })
            setReportID(undefined)
            history.push(`/app/performance/saved-reports/`)
            resetState()
            setReportName('')
            setReportDescription('')
            setSortationField('')
            setSortationFieldType('ascend')
            setCurrentTable('')
            setSaveReportID({ owner_id: null, id: null })


          }).catch(error => {
            notification.error({
              message: error.message ? error.message : "Report can't be deleted!"
            })
          })
        },
      });


    }
    else {
      notification.error({
        message: "Not authorize to delete this report!"
      })
    }
  }
  const exportCSV = () => {
    setGeneralLoad(true)
    const tableFields = tableColumn.map(item => {
      if (GetDataType(item.type) !== DATATYPE.bucket) {
        return item.category + '.' + item.field
      }
    }).filter(item => item !== undefined)

    if (tableFields.length === 0 && groups.length === 0) return notification.error({ message: "No Data Found!" })
    LoadData({
      table: currentTable,
      secondary_filter: secondaryFilterItems.length ? secondaryFilterItems : undefined,
      primary_filter: primaryFilters,
      fields: tableFields.length > 0 ? tableFields : groups,
      bucket_fields: loadBucketFields,
      mode: edit ? 'preview' : 'no-limit',
      visualization: visualization,
      data_fields: modifiedFieldLabels,
      export_csv: true
    }).then((res) => {
      fileDownload(res, "export_" + (saveReportID && saveReportID.id ? "" : "unsaved_report_") + (reportName && reportName.length > 0 ? reportName.replace(/[\W_]+/g, "_").split(' ').join('_') : "") + "_" + moment().format().replace(/[\W_]+/g, "_").split(' ').join('_') + '.csv');
      setGeneralLoad(false)
    }).catch((res) => {
      setGeneralLoad(false)
      if (res.response.status === 400) {
        notification.error({ message: "Please save report before exporting." })
      }
    })
  }

  const exportJSON = () => {
    if (saveReportID.id) {
      ExportReportJSON(reportName)
    } else {
      notification.error({
        message: 'Please first save your report in order to generate a JSON endpoint.',
      });
    }
  }

  const getPanelWidth = document.getElementsByClassName("panel-div")[0];
  const browserWidth = document.documentElement;
  const getHeaderColumnWidth = document.getElementsByTagName("TH")[0];
  const [panelWidth, setPanelWidth] = useState(null)
  const [fixedColumnWidth, setFixedColumnWidth] = useState(null)
  const [panelHeight, setPanelHeight] = useState(null)
  const [fixedColumn, setFixedColumn] = useState(false)

  const getSensor = () => {
    if (getPanelWidth) {
      setPanelHeight(getPanelWidth.clientHeight)
    }
  }
  window.addEventListener('resize', getSensor);

  const setWidthStylings = !edit && getPanelWidth && browserWidth && getPanelWidth.clientWidth > browserWidth.offsetWidth ? `
  
  .parent-header {
    width : ${parseInt(getPanelWidth.clientWidth) + 70}px !important;
  }
 
  `

    : ""

  const ContainerOverrides = createGlobalStyle`
  ${setWidthStylings}

  `

  const PrintReportOVerrides = createGlobalStyle`
  .print-report, .divFooter {display: none}
  .summary-table {
    width: 99.99%;
    overflow: ${props => props.edit ? "scroll" : "hidden !important"};
    height: ${props => props.edit ? "350px" : "auto !important"};
    
  }

  @media print {
    .print-report {display: block !important};
    .summary-table {
      overflow : hidden !important;
      height : auto !important;
   };
   
  @page {
    size: auto;
    margin: .5cm 1cm;
  }
 }
 @media print {
     div.divFooter {
       position: static;
       bottom: 0;
       display: block;
       font-weight : bold;
  }
}
  `
  useEffect(() => {
    if (getPanelWidth) {
      setPanelWidth(getPanelWidth.clientWidth)
      setPanelHeight(getPanelWidth.clientHeight)
    }

  }, [getPanelWidth]) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setIsPrintReportLoading(false)
    }

  });
  useEffect(() => {
    if (isPrintReport) {
      handlePrint()
      setIsPrintReport(false)
    }
  }, [isPrintReport]);

  const exportSummaryCsv = (groupData = false, isMatrix = false,) => {
    setGeneralLoad(true)

    ZenAnalyticsApi.post(exportSummaryCsvRoute, {
      table: currentTable,
      groups: groups.length === 0 ? tableColumn.map(item => {
        if (GetDataType(item.type) !== DATATYPE.bucket) {
          return item.category + '.' + item.field
        }
      }) : groupData ? groupData : groups,
      fields: isMatrix ? [] : tableColumn.map(item => {
        if (GetDataType(item.type) !== DATATYPE.bucket) {
          return item.category + '.' + item.field
        }
      }).filter(item => item !== undefined),
      secondary_filter: secondaryFilterItems.length ? secondaryFilterItems : undefined,
      primary_filter: primaryFilters,
      bucket_fields: bucketFields,
      grouped_dates: groupedDate,
      limit: edit ? 50 : undefined,
      export_csv: true,
      data_fields: modifiedFieldLabels,
    })
    .then((res) => {
      fileDownload(res.data, "export_" + (saveReportID && saveReportID.id ? "" : "unsaved_report_") + (reportName && reportName.length > 0 ? reportName.replace(/[\W_]+/g, "_").split(' ').join('_') : "") + "_" + moment().format().replace(/[\W_]+/g, "_").split(' ').join('_') + '.csv');
      setGeneralLoad(false)


    }).catch(res => {
      notification.error({ message: "Can't Export Csv!" })
      setGeneralLoad(false)
    })

  }

  const generateSortingDropdown = () => {
    const tableFields = tableColumn.map(item => {
      if (GetDataType(item.type) !== DATATYPE.bucket) {
        return item.category + '.' + item.field
      }
    }, [])

    // check tableFields if not empty
    let fieldData = []
    if (tableFields.length) {
      fieldData = _.union(tableFields, groups)
    }

    const returnData = <Select optionFilterProp="children"
                               showSearch style={{ width: "100%" }}
                               placeholder="Choose Field"
                               value={sortField.field}
                               onChange={value => {
                                 if (!sortField.sort && value) {
                                   setSortField({ ...sortField, field: value, sort: "ASC" })
                                 } else {
                                   setSortField({ ...sortField, field: value })
                                 }
                               }}
                               disabled={!edit}
                               allowClear

    >
      {fieldData.map((data, key) => {
        if (modifiedFieldLabels) {
          const findLabel = _.find(modifiedFieldLabels, {
            resource: data.split('.')[0],
            field: data.split('.')[1]
          })
          if (findLabel) {
            return <Select.Option value={data}>{findLabel.field_label}</Select.Option>
          }
        }
      })}
    </Select>
    return returnData;

  }


  const exportMatrixCsv = () => {

    if (groupMatrixX.length == 0 && groupMatrixY.length == 0) {
      notification.error({ message: "No Matrix Data Found!" })
      return
    }

    if (groupMatrixX.length == 0) {
      exportSummaryCsv(groupMatrixY, true)
      return
    }
    else if (groupMatrixY.length == 0) {
      exportSummaryCsv(groupMatrixX, true)
      return
    }
    setGeneralLoad(true)
    ZenAnalyticsApi.post(matrixDataRoute, {
      table: currentTable,
      secondary_filter: secondaryFilterItems.length ? secondaryFilterItems : undefined,
      primary_filter: primaryFilters,
      // add number fields to allow for matrix summarization; average, sum, etc
      fields: matrixEditorOptions.field ? [matrixEditorOptions.field] : [],
      groupX: groupMatrixX,
      groupY: groupMatrixY,
      grouped_dates: groupedDate,
      bucket_fields: bucketFields,
      limit: edit ? 50 : undefined,
      export_csv: true,
      data_fields: modifiedFieldLabels,
      matrix_summary: matrixEditorOptions ? matrixEditorOptions.option || null : null,
      matrix_summary_field: matrixEditorOptions ? matrixEditorOptions.field || null : null,  

    })
    .then((res) => {
      fileDownload(res.data, "export_" + (saveReportID && saveReportID.id ? "" : "unsaved_report_") + (reportName && reportName.length > 0 ? reportName.replace(/[\W_]+/g, "_").split(' ').join('_') : "") + "_" + moment().format().replace(/[\W_]+/g, "_").split(' ').join('_') + '.csv');
      setGeneralLoad(false)
    }).catch(res => {
      notification.error({ message: "Can't Export Csv!" })
      setGeneralLoad(false)

    })
  }

  const getEditWidth = () => {
    return 0.26 * screenWidth;
  }

  return (
      <Spin spinning={generalLoad} tip='Loading'>
        <div style={{ fontFamily: "'Rubik', sans-serif" }}>
          <Prompt
              when={true}
              message={(location, action) => {
                if ((window.location.pathname == location.pathname && !reportID) && (dataFields.length > 0 || reportName != "" || currentTable != "" || reportDescription != "")) {
                  Modal.confirm({
                    'content': "Unsaved changes might be lost do you want to continue?",
                    onOk: () => {
                      resetState()
                      setReportName('')
                      setCurrentTable('')
                      setDataFields([])
                      setReportDescription('')
                      setGeneralLoad(false)
                      setReportID(undefined)
                      setSaveReportID({ owner_id: null, id: null })
                      loaderArray.current = []
                      if (inputRefName.current) inputRefName.current.state.value = ""
                      if (inputRefDescription.current) inputRefDescription.current.state.value = ""
                    },
                  });
                }
                else {
                  resetState()
                  setReportName('')
                  setCurrentTable('')
                  setDataFields([])
                  setReportDescription('')
                  setReportID(undefined)
                  setGeneralLoad(false)
                  setSaveReportID({ owner_id: null, id: null })
                  loaderArray.current = []

                  if (inputRefName.current) inputRefName.current.state.value = ""
                  if (inputRefDescription.current) inputRefDescription.current.state.value = ""
                }
                return true;

              }}
          />
          <ContainerOverrides />
          <PrintReportOVerrides edit={edit} />
          <PageTitle pageName={reportName} />
          <Panel active={true} height='100%' className="panel-div" style={{ width: !edit && tableWidth && tableWidth > panelWidth ? `${tableWidth}px` : "100%" }} >

            <TitleAndControls
                setCurrentTableObject={setCurrentTableObject}
                edit={edit}
                setEdit={() => {
                  setEdit(true)
                  setHideDropZones(false)
                }}
                stopEdit={() => {
                  setEdit(false)
                  setHideDropZones(true)
                }}
                runMode={setEdit}
                save={saveReport}
                saveAndRun={saveAndRunReport}
                reportName={reportName}
                setReportName={setReportName}
                reportDescription={reportDescription}
                setReportDescription={setReportDescription}
                exportCSV={exportCSV}
                exportJSON={exportJSON}
                exportSummaryCsv={exportSummaryCsv}
                exportMatrixCsv={exportMatrixCsv}
                setHideDetails={setHideDetails}
                setHideDropZones={setHideDropZones}
                setHideFilters={setHideFilters}
                hideDetails={hideDetails}
                hideDropZones={hideDropZones}
                hideFilters={hideFilters}
                reportingTables={reportingTables}
                chooseTable={chooseTable}
                currentTable={currentTable}
                saveAsReport={saveAsReport}
                saveAsModal={saveAsModal}
                setSaveAsModal={setSaveAsModal}
                userData={userData}
                saveReportID={saveReportID}
                deleteReport={deleteReport}
                isPrintReport={isPrintReport}
                setIsPrintReport={setIsPrintReport}
                isPrintReportLoading={isPrintReportLoading}
                setIsPrintReportLoading={setIsPrintReportLoading}
                visualization={visualization}
                inputRefName={inputRefName}
                inputRefDescription={inputRefDescription}
                inputRefSaveAs={inputRefSaveAs}
                isExportReportLoading={isExportReportLoading}
                canModifyData={(userData?.should_authorize ? (userData?.permissions.find(p => p === 'modify-saved-reports-page') ? true : false) : true )}
            />
            <hr />
            <div style={{ display: 'flex', height: chartConfiguration.xAxis ? undefined : edit ? window.screen.width <= 1280 ? "120vh" : "73vh" : undefined }}>
              <div style={{ position: "relative", backgroundColor: '#DBE9FD', borderBottomLeftRadius: 5, maxHeight: "100%", width: edit ? getEditWidth() : '0', overflowY: 'auto', transition: 'width .5s' }}>
                {edit ?
                 <SideBar style={{ display: edit ? "block" : "none", height: '100%', minHeight: 500 }}
                          bucketFields={bucketFields}
                          currentTableObject={currentTableObject}
                          setDragBucketItem={setDragBucketItem}
                          setDragItem={setDragItem}
                          dataFields={dataFields}
                          addBucketField={addBucketField}
                          editBucketField={editBucketField}
                          deleteBucketField={deleteBucketField}
                          modifiedFieldLabels={modifiedFieldLabels}
                 />
                      : null}
              </div>
              <div
                  style={{
                    flexGrow: 1,
                    transition: '.5s',
                    overflowY: edit ? 'scroll' : undefined
                  }}
              >
                {(edit ? (
                        visualization === 'percent'
                    ) : (
                      visualization === 'percent' &&
                      primaryNumeratorFilters.length > 0
                  )
                ) && (
                    <h1 style={{
                      fontWeight: "bold",
                      backgroundColor: "rgb(219, 233, 253)",
                      margin: "5px 35px 0 50px"
                    }}>
                      Numerator
                    </h1>
                )}
                {(edit ?
                  (visualization === 'percent') :
                  (
                      visualization === 'percent' &&
                      primaryNumeratorFilters.length > 0
                  )
                ) && (
                    // primary numerator filter
                    <NumeratorFilters
                        filterItems={primaryNumeratorFilters}
                        deleteItem={deletePrimaryNumeratorField}
                        setFilterItems={populatePrimaryNumeratorFilter}
                        allFields={
                          [
                            ...dataFields,
                            ...bucketsToColumns()
                          ]
                        }
                        onDragOver={dragOver}
                        onDrop={dropInPrimaryNumerator}
                        droppedItem={selectedPrimaryNumeratorFilter}
                        setDroppedItem={addToPrimaryNumeratorFilter}
                        edit={editPrimaryNumeratorFilter}
                        setEdit={setEditPrimaryNumeratorFilter}
                        editableReport={edit}
                        hideFilters={hideFilters}
                        type='primary'
                    />
                )}
                {(edit && visualization === 'percent') && (
                    // secondary numerator filter
                    <NumeratorFilters
                        filterItems={secondaryNumeratorFilters}
                        deleteItem={deleteSecondaryNumeratorField}
                        setFilterItems={populateSecondaryNumeratorFilter}
                        allFields={
                          [
                            ...dataFields,
                            ...bucketsToColumns()
                          ]
                        }
                        onDragOver={dragOver}
                        onDrop={dropInSecondaryNumerator}
                        droppedItem={selectedSecondaryNumeratorFilter}
                        setDroppedItem={addToSecondaryNumeratorFilter}
                        edit={editSecondaryNumeratorFilter}
                        setEdit={setEditSecondaryNumeratorFilter}
                        editableReport={edit}
                        hideFilters={hideFilters}
                        type='secondary'
                    />
                )}
                {(edit ? (
                        visualization === 'percent'
                    ) : (
                      visualization === 'percent' &&
                      primaryNumeratorFilters.length > 0
                  )
                ) && (
                    <h1 style={{
                      fontWeight: "bold",
                      backgroundColor: "rgb(219, 233, 253)",
                      margin: "5px 35px 0 50px"
                    }}>
                      Denominator
                    </h1>
                )}
                {(edit || (!edit && primaryFilters.length > 0)) && (
                    <PrimaryFilter
                        filterItems={primaryFilters}
                        deleteItem={deletePrimaryFilterItem}
                        setFilterItems={populatePrimaryFilters}
                        allFields={
                          [
                            ...dataFields,
                            ...bucketsToColumns()
                          ]
                        }
                        onDragOver={dragOver}
                        onDrop={dropInPrimary}
                        editableReport={edit}
                        droppedItem={selectedPrimaryFilter}
                        setDroppedItem={addToPrimaryFilter}
                        edit={editPrimaryFilter}
                        setEdit={setEditPrimaryFilter}
                        visualization={visualization}
                    />
                )}

                {(edit || (!edit && secondaryFilterItems.length > 0)) && (
                    <SecondaryFilter
                        filterItems={secondaryFilterItems}
                        deleteItem={deleteSecondaryFilterItem}
                        setFilterItems={populateSecondaryFilter}
                        allFields={
                          [
                            ...dataFields,
                            ...bucketsToColumns()
                          ]
                        }
                        onDragOver={dragOver}
                        onDrop={dropInSecondary}
                        droppedItem={selectedSecondaryFilter}
                        setDroppedItem={addToSecondaryFilter}
                        edit={editSecondaryFilter}
                        setEdit={setEditSecondaryFilter}
                        editableReport={edit}
                        hideFilters={hideFilters}
                        visualization={visualization}
                    />
                )}

                {(!edit && (visualization == 'summary' || visualization == 'tabular') && totalCount > 2000) && (
                    <div className='ant-row' style={{ paddingTop: 20, paddingLeft: 48, paddingRight: 33, paddingBottom: 20 }}>
                      <p style={{fontSize: 'small', fontWeight: 500}}>Please note: This report has been truncated due to its size. To see all records please export to CSV</p>
                    </div>
                )}



                {(edit || (!edit && sortField.field && modifiedFieldLabels)) && (
                    <Row style={{ paddingLeft: 48, paddingRight: 33, paddingBottom: 20, }} align="middle">

                      <h1 style={{
                        fontWeight: "bold"
                      }}>
                        Sort Field
                      </h1>
                      <Col span={5} style={{ padding: 10, paddingLeft: 0 }}>
                        {generateSortingDropdown()}
                      </Col>
                      <Col span={5} style={{ padding: 10 }}>
                        {<Select optionFilterProp="children"
                                 showSearch style={{ width: "100%" }}
                                 placeholder="Choose Sort Method"
                                 onChange={value => { setSortField({ ...sortField, sort: value }) }}
                                 value={sortField.sort}
                                 disabled={!edit}
                                 allowClear

                        >
                          <Select.Option value={'ASC'}>Ascending</Select.Option>
                          <Select.Option value={'DESC'}>Descending</Select.Option>
                        </Select>}
                      </Col>
                    </Row>
                )}
                {
                  edit &&
                  <>
                    <PreviewRow
                        hideDetails={hideDetails}
                        setHideDetails={setHideDetails}
                        hideDropZones={hideDropZones}
                        setHideDropZones={setHideDropZones}
                        visualizationOptions={visualizationOptions}
                        visualization={visualization}
                        setVisualization={setVisualization}
                        addChart={addChart}
                        groups={groups}
                        tightTable={tightTable}
                        setTightTable={setTightTable}
                        editMatrix={() => setDisplayMatrixEditor(true)}
                        displayMatrixOptions={visualization === 'matrix'}
                        hideAddChart={chartConfiguration && chartConfiguration.type}
                    />
                  </>
                }
                <div ref={componentRef}>
                  <div className="print-report">
                    <ReportTitle style={{ fontSize: 20 }}>{reportName}</ReportTitle>
                    <hr />
                  </div>
                  <div className="print-report-style">

                    <DataDisplay
                        edit={edit}
                        dataSourceCount={dataSourceCount}
                        stackableChartTypes={stackableChartTypes}
                        sortationField={sortationField}
                        sortationFieldType={sortationFieldType}
                        setSortationField={setSortationField}
                        setSortationFieldType={setSortationFieldType}
                        columns={tableColumn}
                        removeColumn={removeColumn}
                        dataSource={dataSource}
                        matrixDataSource={matrixDataSource}
                        onDragOver={dragOver}
                        onDrop={dropInTable}
                        groups={groups}
                        removeGroup={removeGroup}
                        onGroupingDrop={dropInSummaryGrouping}
                        visualization={visualization}
                        uniqueValues={uniqueValues}
                        setHideDropZones={setHideDropZones}
                        hideDropZones={hideDropZones}
                        deleteChart={deleteChart}
                        chartSize={chartSize}
                        chartConfiguration={chartConfiguration}
                        editChart={editChart}
                        hideDetails={hideDetails}
                        tightTable={tightTable}
                        groupMatrixX={groupMatrixX}
                        groupMatrixY={groupMatrixY}
                        dropMatrixX={dropMatrixX}
                        dropMatrixY={dropMatrixY}
                        editableReport={edit}
                        loadedBuckets={loadBucketFields}
                        removeLoadedBucket={removeLoadedBucket}
                        lastHoveredColumn={currentHoveredColumn}
                        setLastHoveredColumn={setCurrentHoveredColumn}
                        setCurrentDropColumn={setCurrentDropColumn}
                        deleteMatrixXItem={deleteMatrixXitem}
                        deleteMatrixYItem={deleteMatrixYitem}
                        matrixDragStart={dragStartMatrix}
                        matrixDragEnd={dragEndMatrix}
                        matrixOptions={matrixEditorOptions}
                        currentDragColumnId={_.findIndex(tableColumn, dragItem)}
                        setDragItem={setDragItem}
                        setDragBucketItem={setDragBucketItem}
                        setGroupedDate={populateGroupedDate}
                        groupedDate={groupedDate}
                        dateGroupingOptions={Object.keys(dateGroupingOptions)}
                        containsGroupedItem={containsGroupedItem}
                        getGroupType={getGroupType}
                        setTableWidth={setTableWidth}
                        panelHeight={panelHeight}
                        isPrintReport={isPrintReport}
                        setIsPrintReport={setIsPrintReport}
                        isPrintReportLoading={isPrintReportLoading}
                        setIsPrintReportLoading={setIsPrintReportLoading}
                        reportName={reportName}
                        startLoading={addToLoad}
                        stopLoading={removeFromLoad}
                        modifiedFieldLabels={modifiedFieldLabels}
                        percentage={percentage}
                        sortField={sortField}
                    />
                    <div className="divFooter">Commercial in Confidence - Not for Distribution</div>

                  </div>

                </div>
              </div>
            </div>
            <ChartSelection
                visible={displayChartEditor}
                loadConfiguration={editChartConfiguration}
                availableCharts={availableCharts}
                handleOK={handleChartSelection}
                handleCancel={() => setDisplayChartEditor(false)}
                groups={groups}
                chartSize={chartSize ? Object.keys(chartSize) : []}
                matrixType={matrixEditorOptions.option}
                stackableChartTypes={stackableChartTypes}
            />
            <NumberBucketModal
                dataFields={dataFields}
                visible={displayNumberModal}
                handleCancel={() => setDisplayNumberModal(false)}
                selectedBucketFieldKey={selectedBucketFieldKey}
                bucket={selectedBucketFieldKey !== undefined ? bucketFields[selectedBucketFieldKey] : {
                  column: '',
                  name: '',
                  values: [
                    {
                      value: 0,
                      name: ''
                    },
                    {
                      value: 0,
                      name: ''
                    }
                  ]
                }}
                populateBucketField={populateBucketField}
            />
            <TextBucketModal
                dataFields={dataFields}
                visible={displayTextModal}
                handleCancel={() => setDisplayTextModal(false)}
                selectedBucketFieldKey={selectedBucketFieldKey}
                bucket={selectedBucketFieldKey !== undefined ? bucketFields[selectedBucketFieldKey] : {
                  column: '',
                  name: '',
                  values: []
                }}
                populateBucketField={populateBucketField}
                currentTable={currentTable}
            />
            <MatrixOptionsModal
                summaryOptions={matrixSummaryOptions}
                numberFields={useMemo(
                    () => dataFields.filter(
                        column => (
                            GetDataType(column.type) === DATATYPE.number
                        )
                    ),
                    [dataFields]
                )}
                configuration={matrixEditorOptions}
                setConfiguration={setMatrixEditorOptions}
                visible={displayMatrixEditor}
                cancelDisplay={() => setDisplayMatrixEditor(false)}
            />
          </Panel>
        </div>
      </Spin>
  )
}

export default Reporting