import styled from 'styled-components';
import { Table } from 'antd';

export const ProductGroupRowHeaderStyle = {
    'display'         : 'auto',
    'backgroundColor' : 'rgba(229, 228, 226, 0.5)',
    'fontSize'        : '15px',
    'fontWeight'      : 'bold'
};

export const TableStyled = styled(Table)`
    .ant-table-thead tr th {
        font-weight: bold;
        font-size: 15px;
        text-align: center !impotant;
        height: 140px;
    }
    table {
        text-transform: uppercase;
    }
`;

export const ProductGroupRowContentStyle = {};

export const Hidden = {
    'display': 'none'
};