import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table } from 'antd'


const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

.ant-table-tbody {
    cursor : pointer;
}

tr.even th, tr.even td {
    background: white !important;
}

tr.odd th, tr.odd td {
    background: #f1f3ff !important;
}

${props => `tr.${props.selectedRowCss} th, tr.${props.selectedRowCss} td {
    background: rgba(11, 107, 242, 0.5) !important;
    font-color : white;

}`}

`
const TableData = (props) => {

    const { itemData, loading, columns, selectedPrimaryKey, setSelectedPrimaryKey, setSelectedLineIds, groupPrimaryKeys } = props
    const [selectedRowCss, setSelectedRowCss] = useState('')
    const selectBatch = (record) => {
        setSelectedRowCss(`row${record.primary_key}`)
        if (record.primary_key === selectedPrimaryKey) {
            setSelectedRowCss('')
            setSelectedPrimaryKey(null);
            setSelectedLineIds([]);
        }
        else {
            setSelectedPrimaryKey(record.primary_key)
            setSelectedLineIds(itemData.filter(data => data.primary_key === record.primary_key).map(record => record['lines.id']))
        }
    }

    useEffect(() => {
        if (itemData.length > 0 && selectedPrimaryKey)
            setSelectedRowCss(`row${selectedPrimaryKey}`)
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <TableStyling selectedRowCss={selectedRowCss} />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={itemData}
                    bordered
                    size="middle"
                    pagination={false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => selectBatch(record), // click row

                        };
                    }}
                    rowClassName={(record, index) => groupPrimaryKeys.indexOf(record.primary_key) % 2 ? `row${record.primary_key} even` : `row${record.primary_key} odd`}

                />
            </StyleTable>
        </>
    )
}

export default TableData