import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, Input, Button as AntButton, notification, Select, Icon, DatePicker } from 'antd'
import _ from "lodash"
import debounce from 'lodash/debounce';
import QualifierInput
    from '../../../components/composites/QualifierInput';
import { FilterItem } from '../../Performance/partials/primaries';
import { Button } from '../../../components/zensmart-design-system';
import { filterConfigurationRoute } from "utils/apiRoutes"
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import { ZenSmartAPI } from 'utils'
import moment from 'moment';
const IconStyle = styled(Icon)`
  cursor : pointer;
`

const FilterFieldsComponent = (props) => {
    const { data, fieldSelectValues, configurationInitData, deleteFilter, setLoading, key, changeFilterFieldValues, filterFields, generateDateOptions } = props
    const [editMode, setEditMode] = useState(false)
    const [oneFilterValue, setOneFilterValue] = useState({})
    const [originalData, setOriginalData] = useState(null)
    const [oneFieldType, setOneFieldType] = useState("string")
    const defaultCustomDateFilters = {
        from: null,
        to: null
    }
    const [customDatesFilters, setCustomDateFilters] = useState(defaultCustomDateFilters)

    const updateFilterNewFieldValue = (value, key) => {
        if (key === "model_definition_id") {
            const getType = fieldSelectValues.find(data => data.id === value).field_type;
            setOneFieldType(getType);
        }
        setOneFilterValue({ ...oneFilterValue, [key]: value })
    }

    const updateSorter = (sorterId) => {
        const payload = oneFilterValue

        if (payload.qualifier.includes('datetime:custom')) {
            if (!customDatesFilters.from || !customDatesFilters.to) {
                notification.error({ message: "Please select all date fields!" })
                return
            } else {
                payload.qualifier = `datetime:custom,${customDatesFilters.from},${customDatesFilters.to}`
            }

        }
        setLoading(true)
        ZenSmartAPI.put(filterConfigurationRoute(sorterId), oneFilterValue)
            .then((res) => {
                setLoading(false)
                setEditMode(false)
                setCustomDateFilters(defaultCustomDateFilters)
                setOneFilterValue(res.data.data)
                setOriginalData(res.data.data)
                changeFilterFieldValues(res.data.data, key)
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant Update filter!")
            })

    }

    const cancelEdit = () => {
        setEditMode(false)
        const getType = fieldSelectValues.find(data => data.id === originalData.model_definition_id).field_type;
        setOneFieldType(getType);
        setOneFilterValue(originalData)
    }

    const onChangeDate = (value, dateString, key) => {
        setCustomDateFilters({ ...customDatesFilters, [key]: dateString === '' ? null : dateString })
    }
    useEffect(() => {
        if (fieldSelectValues) {
            const getType = fieldSelectValues.find(item => item.id === data.model_definition_id).field_type;
            setOneFieldType(getType);
        }
        if (data) {
            setOriginalData(data)
            setOneFilterValue(data);
        }
    }, [data, fieldSelectValues]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (originalData) {
            if (originalData.qualifier.includes('custom')) {
                const splitDate = originalData.qualifier.split(',');
                setCustomDateFilters({ ...customDatesFilters, from: splitDate[1], to: splitDate[2] })
            }
        }
    }, [originalData]) // eslint-disable-line react-hooks/exhaustive-deps
    const returnData = () => {
        return !editMode ? <FilterItem >
            <h2 style={{ textTransform: "capitalize", color: "black" }}>{oneFilterValue.label}</h2>
            <p> {oneFilterValue.qualifier.split(":")[0].replace('_', " ")}</p>
            <h2> {oneFilterValue.qualifier.split(":")[1]}</h2>
            <IconStyle type='edit' onClick={() => setEditMode(true)} />
            <IconStyle type='delete' onClick={() => deleteFilter(oneFilterValue.id)} />
        </FilterItem> :
            <Row type="flex" align="middle">
                <Col span="7" style={{ padding: 10 }}>
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Enter Field (Click Suggestions to add each field)"
                        optionFilterProp="children"
                        value={_.get(oneFilterValue, 'model_definition_id', undefined)}
                        onChange={(value) => updateFilterNewFieldValue(value, 'model_definition_id')}
                    >
                        {fieldSelectValues && fieldSelectValues.map(data =>
                            <Select.Option value={data.id}>{data.label.replace('lines.', '')}</Select.Option>
                        )}
                    </Select>
                </Col>

                <Col span="7" style={{ padding: 10 }}>
                    {oneFieldType === "datetime" || oneFieldType === "date" ? generateDateOptions(updateFilterNewFieldValue, _.get(oneFilterValue, 'qualifier', undefined)) :
                        <QualifierInput
                            onChange={value => updateFilterNewFieldValue(value, 'qualifier')}
                            types={_.get(configurationInitData, 'qualifiers', [])}
                            value={_.get(oneFilterValue, 'qualifier', undefined)}
                            placeholder="Set qualifier..."
                            falseWhiteSpace={true}

                        />
                    }
                </Col>
                {oneFilterValue.qualifier && oneFilterValue.qualifier.includes("datetime:custom") && (oneFieldType === "datetime" || oneFieldType === "date") ?
                    <>
                        <Col span="4" style={{ padding: 10 }}>
                            <DatePicker style={{ width: '100%' }} defaultValue={customDatesFilters.from ? moment(moment(customDatesFilters.from), 'YYYY-MM-DD') : undefined} onChange={(value, dateString) => onChangeDate(value, dateString, "from")} />

                        </Col>
                        <Col span="4" style={{ padding: 10 }}>
                            <DatePicker style={{ width: '100%' }} defaultValue={customDatesFilters.to ? moment(moment(customDatesFilters.to), 'YYYY-MM-DD') : undefined} onChange={(value, dateString) => onChangeDate(value, dateString, "to")} />
                        </Col>
                    </>
                    : null
                }
                <Col span="2" style={{ padding: 5 }}>
                    <Button size="small" style={{ width: "100%" }} onClick={() => updateSorter(oneFilterValue.id)}>Update
                    </Button>
                </Col>
                <Col span="2" style={{ padding: 5 }}>
                    <Button size="small" style={{ background: "red", width: "100%" }} onClick={cancelEdit} >Cancel
                    </Button>
                </Col>
            </Row>

    }
    return Object.keys(oneFilterValue).length > 0 ? returnData() : null
}

export default FilterFieldsComponent