import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, Select, DatePicker, Button, Input } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenSmartAPI } from 'utils'
import { getAllDepartments, getInOutPanelRoute, getAllVendors } from "utils/apiRoutes"
import moment from 'moment';
import { inOutInitialData } from "utils/panelsInitialData"

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;

const InOutPanelPage = (props) => {
    const [fetchDataPayload, setFetchDataPayload] = useState({ vendor_id: 0, department_id: 0, specific_date: moment().format('YYYY-MM-DD') })
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [originalData, setOriginalData] = useState([]);
    const [departments, setDepartments] = useState([])
    const [vendors, setVendors] = useState([])
    const [ascend, setAscend] = useState(inOutInitialData)

    const specificDate = (value, dateString) => {
        setFetchDataPayload({ ...fetchDataPayload, specific_date: dateString })
    }

    const changeDepartment = (value) => {
        setFetchDataPayload({ ...fetchDataPayload, department_id: value })

    }
    const changeVendor = (value) => {
        setFetchDataPayload({ ...fetchDataPayload, vendor_id: value })

    }

    const fetchDepartments = () => {

        setLoading(true)
        ZenSmartAPI.get(getAllDepartments)
            .then((res) => {
                setLoading(false)
                setDepartments(res.data.data)

            })
            .catch((res) => {
                setLoading(false)
            })

    }
    const fetchVendors = () => {

        setLoading(true)
        ZenSmartAPI.get(getAllVendors)
            .then((res) => {
                setLoading(false)
                setVendors(res.data.data)

            })
            .catch((res) => {
                setLoading(false)
            })

    }
    const fetchData = () => {

        setLoading(true)
        const specificDate = moment(fetchDataPayload.specific_date).format('YYYY-MM-DD')
        ZenSmartAPI.post(getInOutPanelRoute, {
            vendor_id: fetchDataPayload.vendor_id,
            department_id: fetchDataPayload.department_id,
            specific_date: specificDate
        })
            .then((res) => {
                const resData = res.data.data
                const getTotal = {
                    hours: "Total",
                    in_orders: 0,
                    in_units: 0,
                    out_orders: 0,
                    out_units: 0
                }

                resData.map(item => {
                    getTotal.in_orders = getTotal.in_orders + item.in_orders
                    getTotal.in_units = getTotal.in_units + item.in_units
                    getTotal.out_orders = getTotal.out_orders + item.out_orders
                    getTotal.out_units = getTotal.out_units + item.out_units
                    return item
                })

                resData.push(getTotal);
                setItemData(resData)
                setOriginalData(resData)
                setAscend(inOutInitialData)
                setLoading(false)
            })
            .catch((res) => {
                setLoading(false)
            })

    }

    const searchOperation = async (value, source) => {
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                            false :
                            anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) : anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
                return containsValue
            })
            setItemData(result)
        }
    }

    useEffect(() => {
        fetchData()
        fetchDepartments()
        fetchVendors()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Performance {">"} In/Out Panel</Title>
            </Header>
            <Panel title="IN/OUT PANEL">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <InputStyle />
                    <Row type="flex" align="middle">
                        <DateColStyle span={4} >
                            <Heading>FILTERS</Heading>
                            <Input.Search
                                style={{ marginTop: 10, width: '100%' }}
                                type="text"
                                placeholder="Filters.."
                                disabled={loading}
                                onChange={(value) => searchOperation(value.target.value, originalData)}
                                loading={loading}
                            />
                        </DateColStyle>
                        <DateColStyle span={3} >
                            <Heading>VENDOR</Heading>
                            <Select defaultValue={0} style={{ marginTop: 10, width: '100%' }} onChange={changeVendor} placeholder="Select Department"
                                optionFilterProp="children"
                                showSearch
                            >
                                <Option value={0}>All Vendors</Option>
                                {vendors.map(data =>
                                    <Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </DateColStyle>
                        <DateColStyle span={3} >
                            <Heading>DEPARTMENT</Heading>
                            <Select defaultValue={0} style={{ marginTop: 10, width: '100%' }} onChange={changeDepartment} placeholder="Select Vendor"
                                optionFilterProp="children"
                                showSearch  >
                                <Option value={0}>All Departments</Option>
                                {departments.map(data =>
                                    <Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </DateColStyle>
                        <DateColStyle span={3} >
                            <Heading>SPECIFIC DATE</Heading>
                            <DatePicker style={{ marginTop: 10, width: '100%' }} onChange={specificDate} defaultValue={moment(new Date(), 'YYYY-MM-DD')} />
                        </DateColStyle>
                        <DateColStyle span={4}>
                            <br />
                            <StyleButton style={{ marginTop: 10 }} onClick={fetchData} loading={loading} type="primary">GO</StyleButton>
                        </DateColStyle>
                    </Row>

                </div>

                <TableData loading={loading} data={itemData} setData={setItemData} ascend={ascend} setAscend={setAscend} />
            </Panel>
        </>
    )
}

export default InOutPanelPage