import React from 'react';
import {Tag} from 'antd';

const TAG_TYPES = {
  success: {
    color: 'green'
  },
  default: {
    color: null
  },
  error: {
    color: 'red'
  }
}

export default function DetailFieldTag({value}) {
  let parts = value.split('.');
  let successType = parts.shift();
  const fieldValue = parts.join('.');

  if (Object.keys(TAG_TYPES).indexOf(successType) === -1) {
    successType = 'default';
  }

  return <Tag color={TAG_TYPES[successType].color}>{fieldValue}</Tag>;
}