import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Row, Col, notification, Tag } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import Cookies from 'js-cookie';
import PickingLookup from './PickingLookup.js'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js'
import { dueDateColor } from './mockData';
import PrintThisComponent from "components/composites/printTicket"
import airhorn from 'static/sounds/airhorn.mp3'

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const Container = styled.div`
  padding-bottom: 5px;
  font-family: 'Rubik', sans-serif;
`
const scanInputProps = {
    size: 'large',
    placeholder: 'Item Lookup'
}

const Bold = styled.span`
  font-weight: bold;
  margin-right: 8px;
  color: ${color.heading};
`
const DueDateTag = styled(Tag)`
padding: 5px 20px;
font - size : 15px;
`
const dataProps = { fontSize: 15, padding: 5 };

const MatchIn = (props) => {
    const [scanItem, setScanItem] = useState(null)
    const [visible, setVisible] = useState(false);
    const [blockId, setBlockId] = useState(null);
    const [alreadyScanned, setAlreadyScanned] = useState(false);
    const [showDestination, setShowDestination] = useState(false)
    const [showScan, setShowScan] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const sound = new Audio(airhorn)
    
    const { scanData } = props
    const scanTypeId = scanData.id;

    const handleLookup = useCallback((lookupValue) => {
        setShowDestination(false)
        if (lookupValue.hasOwnProperty('error')) {
            sound.play()
            notification.error({
                message: lookupValue.error
            })
        }

        else if (lookupValue.hasOwnProperty('station_id')) {
            Cookies.set('STATION', lookupValue.station_id, { expires: 1 });
            Cookies.set('SCAN_TYPE', scanTypeId, { expires: 1 });
            Cookies.set("ALLOW_QUIZ", lookupValue?.allow_quiz ?? false, { expires: 1 });
            notification.success({
                message: 'Station Scan Successful'
            })
        }

        else if (lookupValue.hasOwnProperty('success_scan')) {
            if (lookupValue.popup_message) {
                notification.success({
                    message: 'Barcode Scan Successful!',
                    description: `${lookupValue.popup_message}`,
                    duration: 3
                })
            }
            else {
                notification.success({
                    message: 'Barcode Scan Successful!'
                })
            }
            setAlreadyScanned(false)
            const pickingData = lookupValue ? lookupValue.page_definitions.sections.find(val => val.section_header === 'picking') : null;
            if(pickingData.section_data[4].label === "AlreadyScan" && pickingData.section_data[4].value === true && pickingData.section_data[2].value === true){
                setShowModal(true)
                setAlreadyScanned(true)
                setShowScan(true)
            }else if(pickingData.section_data[4].label === "AlreadyScan" && pickingData.section_data[4].value === true){
                setShowModal(false)
                setAlreadyScanned(true)
                setShowScan(true)
            }else if(pickingData.section_data[2].label === "Verification"){
                if(pickingData.section_data[2].value === false){
                    setShowDestination(true)
                }else{
                    setShowDestination(false)
                }
                setVisible(pickingData.section_data[2].value)
                setShowScan(false)
            }
            
            setBlockId(pickingData.section_data[3].value)
            setScanItem(lookupValue)
        }

        else {
            sound.play()
            notification.error({
                message: 'Invalid scan item. Please scan a valid barcode.'
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const removeScanItem = useCallback(() => {
        setScanItem(null)
    }, [])

    const getToUrl = (value) => {
        window.location.href = value
    }

    return (
        <>
            <Container>
                <Header>
                    <Title>People {">"} Scans {">"} Picking</Title>
                </Header>
                <div>
                    <PickingLookup
                        title="PICKING SCAN"
                        isViewingDetails={scanItem}
                        setScanItem={removeScanItem}
                        detailLabel={'PLEASE SCAN ITEM'}
                        onLookup={handleLookup}
                        inputProps={scanInputProps}
                        scanTypeId={scanTypeId}
                        visible={visible}
                        setVisible={setVisible}
                        blockId={blockId}
                        showDestination={showDestination}
                        setShowDestination={setShowDestination}
                        setAlreadyScanned={setAlreadyScanned}
                        alreadyScanned={alreadyScanned}
                        showScan={showScan}
                        setShowScan={setShowScan}
                        showModal={showModal}
                        setShowModal={setShowModal}
                    >
                        {scanItem ?
                            <Col span={24}>
                                <Col span={24}>
                                    <Label status="mountain_meadow">
                                        SCAN SUCCESSFUL
                                    </Label>
                                </Col>
                            </Col> : null}
                    </PickingLookup>
                </div>
            </Container>

        </>
    )
}

export default MatchIn