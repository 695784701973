import React, { useState, useEffect } from 'react';
import { Modal, Select, Row, Col, Button, notification, Switch, Tooltip } from 'antd';
import { ZenSmartAPI } from 'utils';
import { Label } from 'components/zensmart-design-system';
import { DragDropContext } from 'react-beautiful-dnd';
import 'antd/dist/antd.css';
import EditDataViewFields from './EditDataViewFields';
import { rolesRoute } from "utils/apiRoutes";
import * as _ from 'lodash';

const { Option } = Select;

function DataViewFieldManage(props) {
  const { visible, onClose, roleId, roleName, applyStructure } = props;
  const [selectedResource, setSelectedResource] = useState(null);
  const [dataViewFields, setDataViewFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [sectionFields, setSectionFields] = useState({});
  const [applyStructureSwitch, setApplyStructureSwitch] = useState(false);
  const [allowSave, setAllowSave] = useState(false);

  useEffect(() => {
    if (visible) {
      fetchAllDataViewFields();
      setApplyStructureSwitch(applyStructure);
      return;
    }

  }, [visible]);

  const fetchAllDataViewFields = () => {
    // not needed
    ZenSmartAPI.get('/api/v1/roles/data-view-fields')
      .then((response) => {
        setDataViewFields(response.data);
        if (selectedResource) {
          setAvailableFields(response.data[selectedResource].fields);
        }
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message || 'Error fetching the DataView Feilds.'
        })
      });
  };

  useEffect(() => {
    if (selectedResource) {
      fetchSections();
    }
  }, [selectedResource]);


  const fetchSections = () => {
    ZenSmartAPI.get(`/api/v1/roles/${roleId}/data-view-structure/${selectedResource}`)
      .then((response) => {
        setSectionFields(response.data);
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message || 'Error fetching the DataView. Please try again!'
        })
      });
  };

  const handleColumnDragAndDrop = (sourceSectionId, sourceColId, targetColId, sourceIndex, targetIndex) => {

    const section = sectionFields.sections.find(
      (section) => section.id.toString() === sourceSectionId
    );

    // Move field from source column to target column
    const sourceCol = section.section_data.find(col => col.id === sourceColId);
    const fieldToMove = sourceCol?.fields[sourceIndex];

    // Find target column and insert field at target index
    const targetCol = section.section_data.find(col => col.id === targetColId);

    if(sourceCol.fields === undefined || targetCol.fields === undefined){
      return;
    }

    // Remove field from source column
    sourceCol.fields.splice(sourceIndex, 1);

    // add field to target column
    targetCol.fields.splice(targetIndex, 0, fieldToMove);

    // Update sort_order of fields in target column
    targetCol.fields.forEach((field, index) => {
      field.sort_order = index + 1;
    });

    // If source and target columns are the same, update sort_order of fields in that column
    if (sourceColId === targetColId) {
      sourceCol.fields.forEach((field, index) => {
        field.sort_order = index + 1;
      });
    }

    // Sync sort_order of fields in other columns
    section.section_data.forEach((col) => {
      if (col.id !== sourceColId && col.id !== targetColId) {
        col.fields.forEach((field, index) => {
          field.sort_order = index + 1;
        });
      }
    });

    return section;
  }

  const handleAvailableFieldsChange = (sourceSectionId, sourceColId, targetColId, sourceIndex, targetIndex) => {

    const section = sectionFields.sections.find(
      (section) => section.id.toString() === sourceSectionId
    );

    if (sourceColId === 'fields') {
      // Move field from available fields to target column
      const fieldToMove = section.available_fields[sourceIndex];
      section.available_fields.splice(sourceIndex, 1);

      const targetCol = section.section_data.find(col => col.id === targetColId);
      if (!targetCol.fields.find(field => field.field === fieldToMove.field)) {
        targetCol.fields.splice(targetIndex, 0, fieldToMove);
      }

      targetCol.fields.forEach((field, index) => {
        field.sort_order = index + 1;
      });

    } else {
      // Move field from source column to available fields or target column
      const sourceCol = section.section_data.find(col => col.id === sourceColId);
      const fieldToMove = sourceCol.fields[sourceIndex];
      sourceCol.fields.splice(sourceIndex, 1);

      if (!section.available_fields.find(field => field.field === fieldToMove.field)) {
        section.available_fields.splice(targetIndex, 0, fieldToMove);
      }

      section.section_data.forEach((col) => {
        if (col.id !== sourceColId && col.id !== targetColId) {
          col.fields.forEach((field, index) => {
            field.sort_order = index + 1;
          });
        }
      });
    }

    return section;
  }


  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    // Extract sectionId and column number from droppableId
    const [sourceSectionId, sourceColumn] = source.droppableId.split('_');
    const [destinationSectionId, destinationColumn] = destination.droppableId.split('_');

    if ((sourceSectionId !== destinationSectionId) || !destinationColumn) {
      return;
    }


    let section;

    if ((sourceColumn == 'fields' || destinationColumn == 'fields') && (sourceColumn !== destinationColumn)) {
      section = handleAvailableFieldsChange(sourceSectionId, sourceColumn, destinationColumn, source.index, destination.index)
    } else {
      section = handleColumnDragAndDrop(sourceSectionId, sourceColumn, destinationColumn, source.index, destination.index)
    }

    let sectionData = section.section_data
    setSectionFields(prevSectionFields => {
      let indexToUpdateSection = prevSectionFields.sections.findIndex(item => item.id == sourceSectionId);
      prevSectionFields.sections[indexToUpdateSection].section_data = sectionData;
      return prevSectionFields;
    })

  };

  const handleSave = () => {
    const data = {
      sections: sectionFields,
    };

    ZenSmartAPI.put(`/api/v1/roles/${roleId}/data-view-structure/${selectedResource}`, data)
      .then((response) => {
        setSectionFields(response.data);
        notification.success({
          message: 'Dataview updated successfully!'
        })
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message || 'Something went wrong!'
        })
      });
  };
  const handleApplyStructureChange = (checked) => {
    // setApplyStructureSwitch(checked);

    // Call the API route
    ZenSmartAPI.put(rolesRoute, { id: roleId, apply_structure: checked })
      .then((response) => {
        setApplyStructureSwitch(response.data.data.apply_structure);
        notification.success({
          message: 'Field structure applied to all Data Views and has been saved successfully!'
        });
      })
      .catch((error) => {
        const statusCode = _.get(error, 'response.status');
        if (statusCode !== 403) {
          notification.error({
            message: _.get(error, 'response.data.message', '')
          });
        }
      });
  }

  const constructModalHeader = () => (
    <Row type="flex" justify="center">
      <Label status="blue">MANAGE DATA VIEW FOR {roleName}</Label>
    </Row>
  )

  return (
    <Modal visible={visible} onCancel={onClose} maskClosable={false} footer={[
      <Button type="danger" key="close" onClick={onClose}> Close </Button>,
      <Tooltip title="Saves the changes made to the Data View fields. To enforce the changes, toggle the Apply Structure switch">
        <Button type="primary" onClick={handleSave} disabled={!allowSave} style={{ marginLeft: '10px' }} > Save Changes </Button>
      </Tooltip>
    ]} width={1000} title={constructModalHeader()}>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
          <label style={{ marginRight: 16 }}>Select a data view:</label>
          <Select
            id="select-resource"
            showSearch
            style={{ width: 230 }}
            placeholder="Select a resource"
            value={selectedResource ?? undefined}
            onChange={(value) => {
              setSelectedResource(value);
              setAvailableFields(dataViewFields[value].fields || []);
              setAllowSave(true);
            }}
          >
            {Object.entries(dataViewFields).map(([resourceKey, resourceObj]) => (
              <Option key={resourceKey} value={resourceKey}>
                {resourceObj.resource_label}
              </Option>
            ))}
          </Select>
          <label style={{ marginLeft: 16 }}>Apply all structures:</label>
          <Tooltip title="Once this switch is toggled on, all Data Views will show the the fields set in this window for the users in this role. 
            Turning it off will show all the default fields. Button will be enabled when a change to the structure is made.">
            <Switch
              style={{ marginLeft: 8 }}
              checked={applyStructureSwitch}
              onChange={handleApplyStructureChange}
            />
          </Tooltip>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          {sectionFields && sectionFields.sections && Object.keys(sectionFields.sections).length > 0 && <EditDataViewFields sections={sectionFields.sections} />}
        </div>
      </DragDropContext>
    </Modal>
  );
}

export default DataViewFieldManage;
