import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';

const StyledSvg = styled.svg`
  display: ${props => (props.block ? 'block' : 'inline-block')};
  vertical-align: middle;

  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
`;

const StyledPath = styled.path`
 fill: currentColor;
`;

const StyledCircle = styled.circle`
  fill:#E84950;
  stroke:#FFFFFF;
  stroke-width:26.4229;
  stroke-miterlimit:10;
`;

const applyStyle = NotificationWrapper => {
  return (
    NotificationWrapper &&
    StyledSvg.withComponent(({ ...rest }) => (
      <NotificationWrapper {...rest} />
    ))
  );
};

export function Notification({
  active,
  block,
  height,
  width,
  NotificationWrapper,
  ...props
}) {

  const StyledSvgWrapper = React.useMemo(() => applyStyle(NotificationWrapper), [NotificationWrapper]);

  let SelectedNotification = StyledSvg;
  if (NotificationWrapper) {
    SelectedNotification = StyledSvgWrapper;
  }

  if (active) {
    return (
      <SelectedNotification viewBox="0 0 405 437" height={height} width={width} block={block} {...props}>
        <StyledPath d="M382.31,350.12c-44.72-20.2-46.42-106.93-46.46-109.12v-58.19c0-56-34.2-104.16-82.81-124.66 c-0.1-28.79-23.55-52.18-52.34-52.18c-28.8,0-52.24,23.39-52.35,52.18c-48.59,20.5-82.8,68.66-82.8,124.66V241 c-0.04,2.19-1.74,88.91-46.48,109.12c-5.08,2.3-7.88,7.85-6.71,13.32c1.18,5.46,6.01,9.37,11.61,9.37h113.37 c2.33,12.79,8.37,24.69,17.63,34.33c12.27,12.8,28.51,19.85,45.72,19.85c17.2,0,33.45-7.05,45.73-19.85 c9.25-9.64,15.3-21.54,17.62-34.33h113.38c5.59,0,10.42-3.91,11.6-9.37C390.2,357.97,387.39,352.42,382.31,350.12z M200.7,29.72 c13.03,0,24.04,8.76,27.49,20.69c-8.88-1.85-18.07-2.81-27.49-2.81c-9.42,0-18.6,0.97-27.48,2.81 C176.66,38.48,187.68,29.72,200.7,29.72z M89.29,241.07v-58.26c0-61.47,49.98-111.47,111.41-111.47c61.44,0,111.41,50,111.41,111.47 v58.36c0.02,1.78,0.3,20.56,5.03,43.66H84.26C88.98,261.71,89.27,242.9,89.29,241.07z M200.7,403.25c-18.11,0-33.91-13.07-39-30.44 h78C234.62,390.19,218.8,403.25,200.7,403.25z M57.2,349.07c9.44-11.91,16.09-26.23,20.79-40.49h245.4 c4.7,14.26,11.36,28.58,20.8,40.49H57.2z"/>
        <StyledCircle cx="297.48" cy="122.17" r="56.78"/>
      </SelectedNotification>
    );
  }

  return (
    <Icon icon='notification' height={height} width={width} block={block} />
  )
  
}

Notification.propTypes = {
  active: PropTypes.bool,
  block: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string
};

Notification.defaultProps = {
  active: false,
  block: false,
  height: '20px',
  width: '20px'
};
