import {
  DASHBOARDS,
  REPORTS,
  DASHBOARDS_MAPPING,
  DASHBOARDS_LAYOUT,
  TITLE_NAME,
  TITLE_DESCRIPTION,
  MATRIX_FONT
} from "../actions/dashboardTypes";

export const initialDashboard = { id: null, mapping: [] };

const initialState = {
  dashboards: [],
  reports: [],
  mapping: initialDashboard,
  layout: [],
  titleName: "",
  titleDescription: "",
  matrixFonts: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DASHBOARDS:
      return {
        ...state,
        dashboards: action.payload,
      };
    case REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case DASHBOARDS_MAPPING:
      return {
        ...state,
        mapping: action.payload,
      };
    case DASHBOARDS_LAYOUT:
      return {
        ...state,
        layout: action.payload,
      };
    case TITLE_NAME:
      return {
        ...state,
        titleName: action.payload,
      };
    case TITLE_DESCRIPTION:
      return {
        ...state,
        titleDescription: action.payload,
      };
    case MATRIX_FONT:
      return {
        ...state,
        matrixFonts: action.payload,
      };
    default:
      return state;
  }
}
