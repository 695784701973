import React from 'react'
import styled from 'styled-components'
import {
    Row,
    Col,
    Button,
    Form,
    Select,
    Input,
    DatePicker,
    Checkbox,
} from 'antd'
import { alphabeticalData } from 'utils/sortData';
import { PlusCircleFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux'


const InputHeader = styled.h1`
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    margin: 10px 0px;
    font-weight : bold;
`
const { Option } = Select

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0243EB;
  font-size: 12px;
  border: 0;
  margin-top: 10px;
  box-shadow: none;
`

const DeleteButton = styled(Button)`
  font-size: 12px;
  color: red;
  font-weight: bold;
`

const PlusCircleFilledIcon = styled(PlusCircleFilled)`
  font-size: 16px;
`

const getSelectedValue = (value) => {
    const getSelected = value.find((item) => {
        return item.selected === true
    })
    if (getSelected !== undefined) {
        return getSelected.value
    }
}

export const CustomerDetail = (getFieldDecorator, errors, data) => {

    return (
        <>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>FIRST NAME</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['customer.first_name'] ? 'error' : null}
                        help={errors['customer.first_name'] && errors['customer.first_name'][0]}
                    >
                        {getFieldDecorator(`customer.first_name`, {
                            initialValue: null,
                        }
                        )(
                            <Input />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>LAST NAME</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['customer.last_name'] ? 'error' : null}
                        help={errors['customer.last_name'] && errors['customer.last_name'][0]}
                    >
                        {getFieldDecorator(`customer.last_name`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>VENDOR ID</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['customer.vendor_id'] ? 'error' : null}
                        help={errors['customer.vendor_id'] && errors['customer.vendor_id'][0]}
                    >
                        {getFieldDecorator(`customer.vendor_id`, {
                            initialValue: getSelectedValue(data.section_data.find(item => item.field === "vendor_id").options),
                        }
                        )(
                            <Select optionFilterProp="children"
                                showSearch style={{ width: "100%" }}
                            >
                                {alphabeticalData(data.section_data.find(item => item.field === "vendor_id").options, "text").map((data, key) =>
                                    <Option value={data.value}>{data.text}</Option>
                                )}
                            </Select>
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>PHONE</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['customer.business_number'] ? 'error' : null}
                        help={errors['customer.business_number'] && errors['customer.business_number'][0]}
                    >
                        {getFieldDecorator(`customer.business_number`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>EMAIL</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['customer.email'] ? 'error' : null}
                        help={errors['customer.email'] && errors['customer.email'][0]}
                    >
                        {getFieldDecorator(`customer.email`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>MOBILE</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['customer.phone_number'] ? 'error' : null}
                        help={errors['customer.phone_number'] && errors['customer.phone_number'][0]}
                    >
                        {getFieldDecorator(`customer.phone_number`
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
        </>
    )
}

export const OrderDetail = (getFieldDecorator, errors, data, components) => {
    const userData = useSelector(state => state.user)

    return (
        <>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>QUANTITY</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['order.unit_quantity'] ? 'error' : null}
                        help={errors['order.unit_quantity'] && errors['order.unit_quantity'][0]}
                    >
                        {getFieldDecorator(`order.unit_quantity`, {
                            initialValue: null,
                        }
                        )(
                            <Input type="number" style={{ width: "100%" }} />)}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>FULL PRICE INC GST</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['order.full_price_inc_tax'] ? 'error' : null}
                        help={errors['order.full_price_inc_tax'] && errors['order.full_price_inc_tax'][0]}
                    >
                        {getFieldDecorator(`order.full_price_inc_tax`, {
                            initialValue: null,
                        }
                        )(
                            <Input type="number" style={{ width: "100%" }} />)}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>AMOUNT INC GST</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['order.amount_paid_inc_tax'] ? 'error' : null}
                        help={errors['order.amount_paid_inc_tax'] && errors['order.amount_paid_inc_tax'][0]}
                    >
                        {getFieldDecorator(`order.amount_paid_inc_tax`, {
                            initialValue: null,
                        }
                        )(
                            <Input type="number" style={{ width: "100%" }} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>GIFT CARD USED INC GST</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['order.gift_card_used_inc_tax'] ? 'error' : null}
                        help={errors['order.gift_card_used_inc_tax'] && errors['order.gift_card_used_inc_tax'][0]}
                    >
                        {getFieldDecorator(`order.gift_card_used_inc_tax`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={'*Optional'} type="number" style={{ width: "100%" }} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>AMOUNT INC GST PER UNIT</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['order.price_per_unit_inc_tax'] ? 'error' : null}
                        help={errors['order.price_per_unit_inc_tax'] && errors['order.price_per_unit_inc_tax'][0]}
                    >
                        {getFieldDecorator(`order.price_per_unit_inc_tax`, {
                            initialValue: null,
                        }
                        )(
                            <Input type="number" style={{ width: "100%" }} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>TOTAL PAGES</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['order.pages_per_unit'] ? 'error' : null}
                        help={errors['order.pages_per_unit'] && errors['order.pages_per_unit'][0]}
                    >
                        {getFieldDecorator(`order.pages_per_unit`, {
                            initialValue: null,
                        }
                        )(
                            <Input type="number" style={{ width: "100%" }} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>HOLD AT DISPATCH</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['order.hold_at_dispatch'] ? 'error' : null}
                        help={errors['order.hold_at_dispatch'] && errors['order.hold_at_dispatch'][0]}
                    >
                        {getFieldDecorator(`order.hold_at_dispatch`, {
                            initialValue: false,
                        }
                        )(
                            <Checkbox />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>VIP</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['order.vip'] ? 'error' : null}
                        help={errors['order.vip'] && errors['order.vip'][0]}
                    >
                        {getFieldDecorator(`order.vip`, {
                            initialValue: false,
                        }
                        )(
                            <Checkbox />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            {data.section_data.find(item => item.field === "special_instructions") &&
                <Col span={12}>
                    <Col span={12}>
                        <InputHeader>PRINT INSTRUCTIONS</InputHeader>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            validateStatus={errors['order.special_instructions'] ? 'error' : null}
                            help={errors['order.special_instructions'] && errors['order.special_instructions'][0]}
                        >
                            {getFieldDecorator(`order.special_instructions`, {
                                initialValue: data.section_data.find(item => item.field === "special_instructions").value,
                            }
                            )(
                                <Input placeholder={"*Optional"} disabled={true} />
                            )}
                        </Form.Item >
                    </Col>
                </Col>
            }
            {data.section_data.find(item => item.field === "dispatch_method_code") &&
                <Col span={12}>
                    <Col span={12}>
                        <InputHeader>DISPATCH METHOD CODE</InputHeader>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            validateStatus={errors['order.dispatch_method_code'] ? 'error' : null}
                            help={errors['order.dispatch_method_code'] && errors['order.dispatch_method_code'][0]}
                        >
                            {getFieldDecorator(`order.dispatch_method_code`, {
                                initialValue: data.section_data.find(item => item.field === "dispatch_method_code").value,
                            }
                            )(
                                <Input placeholder={"*Optional"} />
                            )}
                        </Form.Item >
                    </Col>
                </Col>
            }
            {
                userData && userData.details && (userData.details.show_accessories_page != "0" && userData.details.show_accessories_page != false) &&
                <div>

                    <Row type="flex" align="middle" style={{ display: "none" }}>
                        <Col span={7} >
                            <Form.Item label="Label" style={{ padding: "0px 5px 0px 5px" }}
                                validateStatus={errors['components.2.label'] ? 'error' : null}
                                help={errors['components.2.label'] && errors['components.2.label'][0]}
                            >
                                {getFieldDecorator(`components[2].label`, {
                                    initialValue: "Accessory",
                                }
                                )(
                                    <Input placeholder={"Label"} disabled={true} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item label="Value" style={{ padding: "0px 5px 0px 5px" }}
                                validateStatus={errors['components.2.value'] ? 'error' : null}
                                help={errors['components.2.value'] && errors['components.2.value'][0]}
                            >
                                {getFieldDecorator(`components[2].value`, {
                                    initialValue: "ACCESSORIES",
                                }
                                )(
                                    <Input placeholder={"Label"} disabled={true} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ display: "none" }}>
                            <Form.Item label="Type" style={{ padding: "0px 5px 0px 5px" }}
                                validateStatus={errors['components.2.type'] ? 'error' : null}
                                help={errors['components.2.type'] && errors['components.2.type'][0]}
                            >
                                {getFieldDecorator(`components[2].type`, {
                                    initialValue: 'order',
                                }
                                )(
                                    <Select disabled={true} optionFilterProp="children"
                                        showSearch style={{ width: "100%" }}
                                        placeholder="Select Type">
                                        {
                                            components.types && components.types.map(item => {
                                                return <Option value={item}>{item}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col span={12}>
                        <Col span={12}>
                            <InputHeader>ACCESSORIES</InputHeader>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                validateStatus={errors['components.2.extras'] ? 'error' : null}
                                help={errors['components.2.extras'] && errors['components.2.extras'][0]}
                            >{getFieldDecorator(`components[2].extras`, {
                            }
                            )(
                                <Select optionFilterProp="children"
                                    showSearch style={{ width: "100%" }}
                                    mode="multiple"
                                    placeholder="*Optional">
                                    {
                                        components.accessories && components.accessories.map(item => {
                                            return <Option value={item.code}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            )}
                            </Form.Item >
                        </Col>
                    </Col>
                </div>

            }
        </>
    )
}

export const AddressDetail = (getFieldDecorator, errors, countries, defaultCountryCode, type) => {
    return (
        <>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>NAME</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.name`] ? 'error' : null}
                        help={errors[`${type}.name`] && errors[`${type}.name`][0]}
                    >
                        {getFieldDecorator(`${type}.name`, {
                            initialValue: null,
                        }
                        )(
                            <Input />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>COMPANY NAME</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.business_name`] ? 'error' : null}
                        help={errors[`${type}.business_name`] && errors[`${type}.business_name`][0]}
                    >
                        {getFieldDecorator(`${type}.business_name`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>ADDRESS 1</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.street1`] ? 'error' : null}
                        help={errors[`${type}.street1`] && errors[`${type}.street1`][0]}
                    >
                        {getFieldDecorator(`${type}.street1`, {
                            initialValue: null,
                        }
                        )(
                            <Input />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>ADDRESS 2</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.street2`] ? 'error' : null}
                        help={errors[`${type}.street2`] && errors[`${type}.street2`][0]}
                    >
                        {getFieldDecorator(`${type}.street2`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>ADDRESS 3</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.street3`] ? 'error' : null}
                        help={errors[`${type}.street3`] && errors[`${type}.street3`][0]}
                    >
                        {getFieldDecorator(`${type}.street3`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>ADDRESS 4</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.street4`] ? 'error' : null}
                        help={errors[`${type}.street4`] && errors[`${type}.street4`][0]}
                    >
                        {getFieldDecorator(`${type}.street4`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>CITY</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.city`] ? 'error' : null}
                        help={errors[`${type}.city`] && errors[`${type}.city`][0]}
                    >
                        {getFieldDecorator(`${type}.city`, {
                            initialValue: null,
                        }
                        )(
                            <Input />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>POSTCODE</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.postcode`] ? 'error' : null}
                        help={errors[`${type}.postcode`] && errors[`${type}.postcode`][0]}
                    >
                        {getFieldDecorator(`${type}.postcode`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>STATE</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.state`] ? 'error' : null}
                        help={errors[`${type}.state`] && errors[`${type}.state`][0]}
                    >
                        {getFieldDecorator(`${type}.state`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>COUNTRY</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.country`] ? 'error' : null}
                        help={errors[`${type}.country`] && errors[`${type}.country`][0]}
                    >
                        {getFieldDecorator(`${type}.country`, {
                            initialValue: defaultCountryCode,
                        }
                        )(
                            <Select
                                showSearch
                                placeholder="Select country"
                                optionFilterProp={'children'}
                                filterOption={true}
                            >
                                {countries.map(country => <Select.Option key={`${type}.${country.id}`} value={country.code}>{country.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>PHONE</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors[`${type}.phone`] ? 'error' : null}
                        help={errors[`${type}.phone`] && errors[`${type}.phone`][0]}
                    >
                        {getFieldDecorator(`${type}.phone`, {
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
        </>
    )
}

export const PaymentDetail = (getFieldDecorator, errors, dropdowns) => {
    return (
        <>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>TAX RATE</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['payment.tax_rate'] ? 'error' : null}
                        help={errors['payment.tax_rate'] && errors['payment.tax_rate'][0]}
                    >
                        {getFieldDecorator(`payment.tax_rate`, {
                        }
                        )(
                            <Input type="number" style={{ width: "100%" }} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>TOTAL INC TAX</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['payment.price_total_inc_tax'] ? 'error' : null}
                        help={errors['payment.price_total_inc_tax'] && errors['payment.price_total_inc_tax'][0]}
                    >
                        {getFieldDecorator(`payment.price_total_inc_tax`, {
                            initialValue: null,
                        }
                        )(
                            <Input type="number" style={{ width: "100%" }} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>TOTAL LESS DISCOUNT INC TAX</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['payment.price_total_less_discount_inc_tax'] ? 'error' : null}
                        help={errors['payment.price_total_less_discount_inc_tax'] && errors['payment.price_total_less_discount_inc_tax'][0]}
                    >
                        {getFieldDecorator(`payment.price_total_less_discount_inc_tax`, {
                            initialValue: null,
                        }
                        )(
                            <Input type="number" style={{ width: "100%" }} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>CURRENCY CODE</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['payment.currency_code'] ? 'error' : null}
                        help={errors['payment.currency_code'] && errors['payment.currency_code'][0]}
                    >
                        {getFieldDecorator(`payment.currency_code`, {
                            initialValue: null,
                        }
                        )(
                            <Select optionFilterProp="children"
                                showSearch style={{ width: "100%" }}
                                placeholder="Select Currency Code">
                                {
                                    dropdowns.currency_codes && dropdowns.currency_codes.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>PAYMENT DATE</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['payment.payment_date'] ? 'error' : null}
                        help={errors['payment.payment_date'] && errors['payment.payment_date'][0]}
                    >
                        {getFieldDecorator(`payment.payment_date`, {
                            initialValue: null,
                        }
                        )(
                            <DatePicker style={{ width: "100%" }} format={"YYYY-MM-DD"} />

                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>PAYMENT METHOD</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['payment.payment_method'] ? 'error' : null}
                        help={errors['payment.payment_method'] && errors['payment.payment_method'][0]}
                    >
                        {getFieldDecorator(`payment.payment_method`, {
                            initialValue: null,
                        }
                        )(
                            <Select optionFilterProp="children"
                                showSearch style={{ width: "100%" }}
                                placeholder="Select Payment">
                                {
                                    dropdowns.payment_methods && dropdowns.payment_methods.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>TRANSACTION ID</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['payment.client_transaction_id'] ? 'error' : null}
                        help={errors['payment.client_transaction_id'] && errors['payment.client_transaction_id'][0]}
                    >
                        {getFieldDecorator(`payment.client_transaction_id`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>PAYMENT TYPE</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['payment.payment_type'] ? 'error' : null}
                        help={errors['payment.payment_type'] && errors['payment.payment_type'][0]}
                    >
                        {getFieldDecorator(`payment.payment_type`, {
                            initialValue: null,
                        }
                        )(
                            <Select optionFilterProp="children"
                                showSearch style={{ width: "100%" }}
                                placeholder="Select Payment Type">
                                {
                                    dropdowns.payment_types && dropdowns.payment_types.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>PAYMENT CODE</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['payment.payment_code'] ? 'error' : null}
                        help={errors['payment.payment_code'] && errors['payment.payment_code'][0]}
                    >
                        {getFieldDecorator(`payment.payment_code`, {
                            initialValue: null,
                        }
                        )(
                            <Input placeholder={"*Optional"} />
                        )}
                    </Form.Item >
                </Col>
            </Col>
            <Col span={12}>
                <Col span={12}>
                    <InputHeader>STATUS</InputHeader>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={errors['payment.status'] ? 'error' : null}
                        help={errors['payment.status'] && errors['payment.status'][0]}
                    >
                        {getFieldDecorator(`payment.status`, {
                            initialValue: null,
                        }
                        )(
                            <Select optionFilterProp="children"
                                showSearch style={{ width: "100%" }}
                                placeholder="Select Status">
                                {
                                    dropdowns.statuses && dropdowns.statuses.map(item => {
                                        return <Option value={item}>{item}</Option>
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item >
                </Col>
            </Col>
        </>
    )
}

export const ComponentDetail = (getFieldDecorator, errors, addComponents, setComponents, data, getFieldValue, setFieldsValue) => {
    const userData = useSelector(state => state.user)
    const accessoryIndex = userData && userData.details && (userData.details.show_accessories_page != "0" && userData.details.show_accessories_page != false) ? 3 : 2

    const addNewComponents = () => {
        setComponents([...addComponents, { label: undefined, value: undefined, type: undefined }])

    }
    const removeComponent = (index) => {
        const components = getFieldValue('components')
        setComponents(addComponents.filter((data, key) => key !== index))
        setComponentsValue(components.filter((item, key) => key !== index + accessoryIndex))
    }

    const setComponentsValue = (data) => {
        setFieldsValue({
            components: data,
        });
    }
    return <>
        <Row type="flex" align="middle">
            <Col span={7} style={{ padding: "0px 5px 0px 5px" }}>
                <Form.Item label="Label"
                    validateStatus={errors['components.0.label'] ? 'error' : null}
                    help={errors['components.0.label'] && errors['components.0.label'][0]}
                >
                    {getFieldDecorator(`components[0].label`, {
                        initialValue: "Lamination",
                    }
                    )(
                        <Input placeholder={"Label"} disabled />
                    )}
                </Form.Item>
            </Col>
            <Col span={7} >
                <Form.Item label="Value" style={{ padding: "0px 5px 0px 5px" }}
                    validateStatus={errors['components.0.value'] ? 'error' : null}
                    help={errors['components.0.value'] && errors['components.0.value'][0]}
                >
                    {getFieldDecorator(`components[0].value`, {
                    }
                    )(
                        <Select optionFilterProp="children"
                            showSearch style={{ width: "100%" }}
                            placeholder="Select Value">
                            {
                                data.lamination_codes && data.lamination_codes.map(item => {
                                    return <Option value={item.code}>{item.code}</Option>
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={7}>
                <Form.Item label="Type" style={{ padding: "0px 5px 0px 5px" }}
                    validateStatus={errors['components.0.type'] ? 'error' : null}
                    help={errors['components.0.type'] && errors['components.0.type'][0]}
                >
                    {getFieldDecorator(`components[0].type`, {
                        initialValue: 'order',
                    }
                    )(
                        <Select disabled={true} optionFilterProp="children"
                            showSearch style={{ width: "100%" }}
                            placeholder="Select Type">
                            {
                                data.types && data.types.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={3} style={{ paddingLeft: 5, textAlign: "center", }}>
                <div style={{ marginTop: 15 }}>
                    <DeleteButton style={{ width: "100%" }} disabled>REMOVE</DeleteButton>
                </div>
            </Col>
        </Row>
        <Row type="flex" align="middle">
            <Col span={7} >
                <Form.Item label="Label" style={{ padding: "0px 5px 0px 5px" }}
                    validateStatus={errors['components.1.label'] ? 'error' : null}
                    help={errors['components.1.label'] && errors['components.1.label'][0]}
                >
                    {getFieldDecorator(`components[1].label`, {
                        initialValue: "Paper",
                    }
                    )(
                        <Input placeholder={"Label"} disabled={true} />
                    )}
                </Form.Item>
            </Col>
            <Col span={7} >
                <Form.Item label="Value" style={{ padding: "0px 5px 0px 5px" }}
                    validateStatus={errors['components.1.value'] ? 'error' : null}
                    help={errors['components.1.value'] && errors['components.1.value'][0]}
                >
                    {getFieldDecorator(`components[1].value`, {
                    }
                    )(
                        <Select optionFilterProp="children"
                            showSearch style={{ width: "100%" }}
                            placeholder="Select Value">
                            {
                                data.paper_codes && data.paper_codes.map(item => {
                                    return <Option value={item.code}>{item.code}</Option>
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={7} >
                <Form.Item label="Type" style={{ padding: "0px 5px 0px 5px" }}
                    validateStatus={errors['components.1.type'] ? 'error' : null}
                    help={errors['components.1.type'] && errors['components.1.type'][0]}
                >
                    {getFieldDecorator(`components[1].type`, {
                        initialValue: 'order',
                    }
                    )(
                        <Select disabled={true} optionFilterProp="children"
                            showSearch style={{ width: "100%" }}
                            placeholder="Select Type">
                            {
                                data.types && data.types.map(item => {
                                    return <Option value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={3} style={{ paddingLeft: 5, textAlign: "center" }}>
                <div style={{ marginTop: 15 }}>
                    <DeleteButton style={{ width: "100%" }} disabled>REMOVE</DeleteButton>
                </div>
            </Col>
        </Row>

        {
            addComponents.map((item, key) => {
                return <Row type="flex" align="middle">
                    <Col span={7} >
                        <Form.Item label="Label" style={{ padding: "0px 5px 0px 5px" }}
                            validateStatus={errors[`components.${key + 2}.label`] ? 'error' : null}
                            help={errors[`components.${key + 2}.label`] && errors[`components.${key +2}.label`][0]}
                        >
                            {getFieldDecorator(`components[${key + accessoryIndex}].label`, {
                                initialValue: null,
                            }
                            )(
                                <Input placeholder={"Label"} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={7} >
                        <Form.Item label="Value" style={{ padding: "0px 5px 0px 5px" }}
                            validateStatus={errors[`components.${key + 2}.value`] ? 'error' : null}
                            help={errors[`components.${key + 2}.value`] && errors[`components.${key + 2}.value`][0]}
                        >
                            {getFieldDecorator(`components[${key + accessoryIndex}].value`, {
                                initialValue: item.label,
                            }
                            )(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={7} >
                        <Form.Item label="Type" style={{ padding: "0px 5px 0px 5px" }}
                            validateStatus={errors[`components.${key + 2}.type`] ? 'error' : null}
                            help={errors[`components.${key + 2}.type`] && errors[`components.${key + 2}.type`][0]}
                        >
                            {getFieldDecorator(`components[${key + accessoryIndex}].type`, {
                                initialValue: item.label,
                            }
                            )(
                                <Select optionFilterProp="children"
                                    showSearch style={{ width: "100%" }}
                                    placeholder="Select Type">
                                    {
                                        data.types && data.types.map(item => {
                                            return <Option value={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3} style={{ paddingLeft: 5, textAlign: "center" }}>
                        <div style={{ marginTop: 15 }}>
                            <DeleteButton style={{ width: "100%" }} onClick={() => removeComponent(key)}>REMOVE</DeleteButton>
                        </div>
                    </Col>
                </Row>
            })
        }
        <Row type="flex" align="middle" justify="end">
            <AddButton onClick={addNewComponents} >
                <PlusCircleFilledIcon />
                ADD NEW COMPONENT
            </AddButton>
        </Row>
    </>
}