import { PROCESS } from 'types'
  function constructFlowPayload(processes, queueRules, batchRules, preDispatchRules, matchOutRules, currentProduct,applyTemplateToAllProducts = false) {
  const initialPayload = {
    apply_template_to_all_products: applyTemplateToAllProducts,
    flow: [],
    blockDefinition: []
  }

  function constructPayload(processes, currentPayload, blockType = null, parent = null, qualifiers = []) {
    const paypay = []
    const saveFlowItems = processes?.reduce((payload, process) => {
      if (process.variant === PROCESS.VARIANT.ITEM) {
        const newFlowItem = {
          blockType,
          index: process.number,
          stageNumber: process.number,
          name: process.text,
          stageType: process.type,
          triggers: process.triggers,
          is_print_job_ticket: process.is_print_job_ticket,
          infer_scan: process.infer_scan,
          enforce: process.enforce,
          wait_threshold: process.wait_threshold,
          stage: (process.stage ? process.stage : 0),
          child_parent: parent,
          qualifiers: (qualifiers.length !== 0 ? qualifiers : (process?.qualifiers ? process.qualifiers : [])),
          scan: {
            scanType: process.text,
            matchGroup: process.matchGroup,
            freightGroup: process.freightGroup,
          }
        }

        if (process.text === PROCESS.TYPE.PICKING) {
          newFlowItem.required_verification = process.required_verification
          newFlowItem.wait_threshold = process.wait_threshold
          newFlowItem.picking_group_id = process.picking_group_id
        }

        newFlowItem.enable_gamification = process.enable_gamification
        newFlowItem.quiz_id = process.quiz_id

        if (process.text === PROCESS.TYPE.PRE_DISPATCH && preDispatchRules) {
          const boxRanges = preDispatchRules.box_ranges
          if (boxRanges) {
            if (boxRanges.length > 0) {
              const newBoxRanges = boxRanges.map((data, key) => {
                const id = data.id.toString()
                if (id.includes("newRule.")) {
                  data.id = null
                }
                data.groupable_type = "freight_group"
                data.groupable_id = preDispatchRules.freight_group_id
                data.letter_range = data.letter_range ? data.letter_range.toUpperCase() : null;

                return data
              })
              newFlowItem.freightRanges = newBoxRanges
            }

            newFlowItem.scan.freightGroup = preDispatchRules.freight_group_id
            newFlowItem.isLetterRange = preDispatchRules.is_letter_range
          }
        }

        if (process.text === PROCESS.TYPE.MATCH_OUT && matchOutRules) {
          const boxRanges = matchOutRules.box_ranges
          if (boxRanges) {
            if (boxRanges.length > 0) {
              const newBoxRanges = boxRanges.map((data, key) => {
                const id = data.id.toString()
                if (id.includes("newRule.")) {
                  data.id = null
                }
                data.groupable_type = "match_group"
                data.groupable_id = matchOutRules.match_group_id
                return data
              })
              newFlowItem.matchRanges = newBoxRanges
            }
            newFlowItem.scan.matchGroup = matchOutRules.match_group_id
          }
        }

        if (process.text === PROCESS.TYPE.QUEUED && queueRules.length) {
          newFlowItem.queueRules = queueRules.map(queue => {
            const scheduleFrom = queue.scheduleFrom ? queue.scheduleFrom.split(" ") : null;
            const scheduleTo = queue.scheduleTo ? queue.scheduleTo.split(" ") : null;

            if (scheduleFrom) {

              if (scheduleFrom[1].length < 6 && scheduleTo[1].length < 6) {
                queue.scheduleFrom = `${queue.scheduleFrom}:00`;
                queue.scheduleTo = `${queue.scheduleTo}:00`;
              } else {
                queue.scheduleFrom = `${queue.scheduleFrom}`;
                queue.scheduleTo = `${queue.scheduleTo}`;
              }

            }
            return queue;
          });
        }

        if (process.text === PROCESS.TYPE.READY && batchRules.length) {
          newFlowItem.batchRules = batchRules.map(batch => {
            const scheduleFrom = batch.scheduleFrom ? batch.scheduleFrom.split(" ") : null;
            const scheduleTo = batch.scheduleFrom ? batch.scheduleTo.split(" ") : null;

            if (scheduleFrom) {
              if (scheduleFrom[1].length < 6 && scheduleTo[1].length < 6) {
                batch.scheduleFrom = `${batch.scheduleFrom}:00`;
                batch.scheduleTo = `${batch.scheduleTo}:00`;
              } else {
                batch.scheduleFrom = `${batch.scheduleFrom}`;
                batch.scheduleTo = `${batch.scheduleTo}`;
              }
            }

            return batch;
          });
        }

        if (process.id) {
          newFlowItem.id = process.id;
          newFlowItem.scan.id = process.id;
        }

        let newPayload = {
          ...payload,
          flow: [...payload.flow, newFlowItem]
        }

        if (process.children !== null && process.children !== undefined) {
          //adding children values to flow
          let dummyPayload = constructPayload(process.children, payload, blockType, process.text, process.qualifiers)
          let difference = newPayload.flow.filter(x => !dummyPayload.flow.includes(x));
          newPayload = {
            ...payload,
            flow: [...dummyPayload.flow, difference[0]]
          }
        }
        return newPayload
      }

      if (process.variant === PROCESS.VARIANT.CONTAINER) {
        if (process.type === PROCESS.TYPE.BLOCK) {
          const newBlockDefinition = {
            blockType: process.text,
            stageType: process.type,
            is_print_job_ticket: process.is_print_job_ticket,
            infer_scan: process.infer_scan,
            enforce: process.enforce,
            stage: (process.stage ? process.stage : 0),
            qualifiers: (qualifiers !== 0 ? qualifiers : (process?.qualifiers ? process.qualifiers : [])),
            metadata: {
              ...currentProduct.blockDefinition[0].metadata,
              plex: process.plex,
              action: process.action,
              action_set: process.action_set,
              requires_work_order: process.requires_work_order ? JSON.parse(process.requires_work_order) : false,
              no_print_required: process.no_print_required ? JSON.parse(process.no_print_required) : false,
              optional: process.optional,
              submission_per_block: process.submission_per_block ? JSON.parse(process.submission_per_block) : false,
              page_object: process.page_object ? JSON.parse(process.page_object) : false,
              set: process.set,
              photoshop_actions: process.photoshop_actions,
              weight: process.weight,
              value: process.value
            }
          }

          if (process.id) {
            newBlockDefinition.id = process.id
          }

          const newPayload = {
            ...payload,
            is_print_job_ticket: process.is_print_job_ticket,
            infer_scan: process.infer_scan,
            enforce: process.enforce,
            blockDefinition: [...payload.blockDefinition, newBlockDefinition]
          }

          const payloadRef = constructPayload(process.children, newPayload, process.text)
          return payloadRef
        }

        if (process.type === PROCESS.TYPE.TRANSPARENT) {
          const newPayload = constructPayload(process.children, payload)
          return newPayload
        }
      }

      return payload
    }, currentPayload)


    return saveFlowItems
  }
  const flowPayload = constructPayload(processes, initialPayload)
  currentProduct.flow.map((data, key) => {
    if (flowPayload.flow[key]) {
      const getId = currentProduct.flow.find(item => item.name.toLowerCase() === flowPayload.flow[key].name.toLowerCase())
      flowPayload.flow[key].scan.id = getId ? getId.scan.id : flowPayload.flow[key].id ? flowPayload.flow[key].id : null
      delete flowPayload.flow[key].id
      if (flowPayload.flow[key].name === "Match In") {
        let getMatchInIndex = flowPayload.flow.filter(item => item.name.toLowerCase() !== "match in" && item.blockType === flowPayload.flow[key].blockType)
        if (getMatchInIndex.length > 0) {
          getMatchInIndex = [...getMatchInIndex].sort((a, b) => a.stageNumber - b.stageNumber);
          flowPayload.flow[key].index = getMatchInIndex[getMatchInIndex.length - 1].index + 1
          flowPayload.flow[key].stageNumber = getMatchInIndex[getMatchInIndex.length - 1].index + 1
        }
      }

    }
    return data
  })

  return flowPayload
}

export default constructFlowPayload