import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { getAllDepartments, failPanelRoute } from "utils/apiRoutes"
import moment from 'moment';
import { failInitialData } from "utils/panelsInitialData"
const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;

const FailPanelPage = (props) => {
    const [fetchDate, setFetchDate] = useState({ department_id: 0, from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') })
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [originalData, setOriginalData] = useState([]);
    const [departments, setDepartments] = useState([])
    const [ascend, setAscend] = useState(failInitialData)
    const changeDateFrom = (value, dateString) => {
        setFetchDate({ ...fetchDate, from: dateString })
    }
    const changeDateTo = (value, dateString) => {
        setFetchDate({ ...fetchDate, to: dateString })
    }

    const changeDepartment = (value) => {
        setFetchDate({ ...fetchDate, department_id: value })

    }

    const fetchDepartments = () => {
        if (moment(fetchDate.from).isAfter(fetchDate.to)) {
            notification.error({ message: "To date must be greater than from date!" })
        }
        else {
            setLoading(true)
            ZenSmartAPI.get(getAllDepartments)
                .then((res) => {
                    setLoading(false)
                    setDepartments(res.data.data)

                })
                .catch((res) => {
                    setLoading(false)
                })
        }

    }
    const fetchData = () => {

        const fromDate = fetchDate.from;
        const toDate = fetchDate.to

        setLoading(true)
        ZenSmartAPI.get(failPanelRoute(`from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}&department_id=${fetchDate.department_id}`))
            .then((res) => {
                setLoading(false)
                setItemData(res.data.data)
                setOriginalData(res.data.data)
                setAscend(failInitialData)
            })
            .catch((error) => {
                setLoading(false);
                // handle validation errors
                if (_.get(error, 'response.status') === 422) {
                    notification.error({
                        message: "There are errors in search input",
                        description: _.chain(error.response.data.errors)
                            .values()
                            .flatten()
                            .first()
                            .value()
                    });
                }

                if (_.get(error, 'response.status') === 400) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An unhandled error occurred. Please contact your supervisor.'),
                    });
                }

                if (_.get(error, 'response.status') > 499) {
                    notification.error({
                        message: 'An unhandled error occurred. Please contact your supervisor'
                    });
                }
            })
            return true
    }

    const searchOperation = async (value, source) => {
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    // if null ignore
                    if (anItemValue === null) {
                        return false
                    }

                    // handle objects
                    if (anItemValue.value) {
                        return anItemValue.value.toString()
                            .toLowerCase()
                            .includes(value.toLowerCase())
                    }

                    // handle dates
                    if (moment.unix(anItemValue).isValid()) {
                        return moment.unix(anItemValue).format('D MMM YYY, h:mma')
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase());
                    }

                    // plain values
                    return anItemValue.includes(value.toLowerCase())
                });
                return containsValue;
            })
            setItemData(result)
        }
    }


    useEffect(() => {
        fetchData()
        fetchDepartments()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Performance {">"} Fail Panel</Title>
            </Header>
            <Panel title="FAIL PANEL">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <InputStyle />
                    <Row type="flex" align="middle">
                        <DateColStyle span={4} >
                            <Heading>FILTERS</Heading>
                            <Input.Search
                                style={{ marginTop: 10, width: '100%' }}
                                type="text"
                                placeholder="Filters.."
                                disabled={loading}
                                onChange={(value) => searchOperation(value.target.value, originalData)}
                                loading={loading}
                            />
                        </DateColStyle>
                        <DateColStyle span={3} >
                            <Heading>DEPARTMENT</Heading>
                            <Select defaultValue={0} style={{ marginTop: 10, width: '100%' }} onChange={changeDepartment} placeholder="Select Department" >
                                <Option value={0}>All Department</Option>
                                {departments.map(data =>
                                    <Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </DateColStyle>
                        <DateColStyle span={3} >
                            <Heading>FROM</Heading>
                            <DatePicker style={{ marginTop: 10, width: '100%' }} onChange={changeDateFrom} defaultValue={moment(moment(), 'YYYY-MM-DD')} />
                        </DateColStyle>
                        <DateColStyle span={3} >
                            <Heading>TO</Heading>
                            <DatePicker style={{ marginTop: 10, width: '100%' }} onChange={changeDateTo} defaultValue={moment(moment(), 'YYYY-MM-DD')} />
                        </DateColStyle>
                        <DateColStyle span={4}>
                            <br />
                            <StyleButton style={{ marginTop: 10 }} onClick={fetchData} loading={loading} type="primary">GO</StyleButton>
                        </DateColStyle>
                    </Row>
                    <DateColStyle span={6} style={{ margin: "15px 0px" }}>
                        <Heading>TOTAL FAILS : {itemData.length}</Heading>
                    </DateColStyle>
                </div>

                <TableData loading={loading} data={itemData} setData={setItemData} ascend={ascend} setAscend={setAscend} />
            </Panel>
        </>
    )
}

export default FailPanelPage