import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {MenuButton} from 'components/primaries/MenuButton.js'

const Header = styled.header`
  background: #194787;
  min-height: 64px;
  position: relative;

  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 8px 26px;

  @media (max-width: 640px) {
    padding: 8px 9px;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  height: 1.25rem;
  margin-right: 1rem;
`

export default function TopBar(props) {
  const {
    mainImage,
    userName,
    userImage,
    children,
    isMenuOpen,
    toggleMenu,
    ...header
  } = props

  return (
    <Header {...header}>
      <Container>
        <MenuButton isOpen={isMenuOpen} onClick={toggleMenu} />
        <Logo src="/images/zen-logo.png" alt="ZenSmart Logo" />
        {children}
      </Container>
    </Header>
  );
}

TopBar.propTypes = {
  mainImage: PropTypes.string,
  userName: PropTypes.string,
  userImage: PropTypes.string,
};