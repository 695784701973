import React from 'react';
import { Table } from 'antd';
import { useDataValue } from 'hooks';

export default function DetailFieldTable({ section, ...props }) {
  const {getDataValue, showPrint, printContent} = useDataValue()

  // const rerouteOnClick = record => ({
  //   onClick(e) {
  //     if (e.currentTarget.querySelectorAll('.detail-field-button').length === 0) {
  //       const url = `/app/data-view/${record.resourceName}/${record.resourceID}`;
  //       e.ctrlKey || e.metaKey ? window.open(url) : history.push({ pathname: url });
  //     }
  //   }
  // })
  const columns = section.table_headers.map(columnName => ({
    title: columnName,
    dataIndex: columnName,
    key: columnName,
    width: 180,
    // onCell: rerouteOnClick,
    render: field => getDataValue(field)
  }))

  const dataSource = section.section_data.map(row => (
    row.section_data.reduce((fields, field) => ({
      key: row.id,
      resourceName: row.resource_name,
      resourceID: row.id,
      ...fields,
      [field.label]: {
        ...field,
        resource_name: row.resource_name,
        resource_id: row.id,
      }
    }), {})
  ))

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      scroll={{ x: 'max-content' }}
    />
  );
}