import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Tag, Row, Col , Popconfirm , Button , notification} from 'antd'
import { panelSortData } from 'utils/sortData'
import { ZenSmartAPI } from 'utils'
import { deletePostDispatchMessage  } from "utils/apiRoutes"
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';


const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const DueDateTag = styled(Tag)`
    padding : 5px 17px;
    font-size : 12px;
`

const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

.selected-row > td {
    background: none !important;
}
.selected-row {
   background: rgba(11, 107, 242, 0.5) !important;
}
`
const ActionButton = styled(Button)`
  margin-right: 10px;
`

const TableData = (props) => {

    const { itemData, loading, data, setData, ascend, setAscend, selectedCheckBoxValue, paginationNumber, setSelectedCheckBoxValue, paginationSize, checkPaginationIfAllSelected , setPostDispatchMessageModalName , setShowModal , setFormInitialValue , fetchData} = props

    const postInitialData = {
        id: null,
        name: null,
        template: null,
        vendor: null,
        template_id : null,
        vendor_id: null,
    }

    const sortingData = (data, property, ifNotProperty) => {
        if (ascend[property] === null) {
            setAscend({ ...postInitialData, [property]: true })
        }
        else {
            ascend[property] === true ? setAscend({ ...postInitialData, [property]: false }) : setAscend({ ...postInitialData, [property]: true })
        }

        setData(panelSortData(data, property, ascend[property], ifNotProperty))
        checkPaginationIfAllSelected(paginationNumber)
    }

    const getHeaders = (property, text, ifAscend) => {
        return (
            <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifAscend)} style={{ cursor: "pointer" }}>
                <Col span={ascend[property] === null ? 24 : 20}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }

    

    const deletePrint = id => {
        ZenSmartAPI.delete(deletePostDispatchMessage(id))
          .then(res => {
            notification.success({
              message: 'Delete print dispatch message successful.'
            })
            fetchData();
          })
          .catch(res => {
            notification.error({
              message: 'Deleting print dispatch message failed.'
            })
          })
      }

    const loadPrintDispatchMessageFormModal = data => {
        setPostDispatchMessageModalName('Edit Print Dispatch Message')
        setShowModal(true)
        setFormInitialValue(data)
    }

    const columns = [
        {
            title: getHeaders("vendor", "NAME", false),
            dataIndex: 'vendor',
            key: 'vendor',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("template", "TEMPLATE", false),
            dataIndex: 'template',
            key: 'template',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("type", "TYPE", false),
            dataIndex: 'type',
            key: 'type',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: ( obj , row) => 
              <>
                <ActionButton type="primary" onClick={ e => loadPrintDispatchMessageFormModal(row) }>Edit</ActionButton>
                <Popconfirm 
                  placement="top" 
                  title={'Are you sure you want to delete this print dispatch message?'} 
                  okText="Yes" 
                  cancelText="No"
                  onConfirm={ () => deletePrint(row.id) }
                >
                  <ActionButton type="danger">Delete</ActionButton>
                </Popconfirm>
              </>
        }
    ];

    const rowStyle = (value, index, row) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }

        if (row.due_date && row.due_date.toLowerCase() === "overdue") {
            obj.props.style.background = "#faeeed"
        }

        return obj
    }

    useEffect(() => {
        if (itemData) {
            console.log('itemDataHere',itemData)
            const itemObj = itemData.map(data => {
                const obj = {
                    id: data.id,
                    vendor: data.vendor,
                    type: data.type,
                    template: data.template,
                    template_id : data.template_id,
                    vendor_id : data.vendor_id,
                }
                return obj;
            })

            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table

                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "45vh" }}
                    pagination={{ pageSize: paginationSize, onChange: (e) => checkPaginationIfAllSelected(e) }}

                />
            </StyleTable>
        </>
    )
}

export default TableData