import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import { Icon as AIcon, Row, Col, Form, Input, notification, Modal } from 'antd'
import styled, { css } from 'styled-components'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js'
import { Panel } from '../../../components/primaries'
import { ZenSmartAPI } from 'utils';
import Cookies from 'js-cookie';
import { normalBarcodeScanRoute } from "utils/apiRoutes"
import { ReactComponent as Index } from 'static/icons/inbox.svg'
import { ReactComponent as Retrieve } from 'static/icons/retrieve.svg'
import { ReactComponent as Hold } from 'static/icons/hold.svg'

/**
 * @note Clean the API for this component. Feels like it has to know
 * a lot of variables/prop values in order to be useful.
 */

const panelStylings = css`
  margin-bottom: 24px;
  padding-bottom: 50px;
`
const StyledRow = styled(Row)`
  margin-bottom: 24px;
`
const InvalidScanImage = styled.p`
  text-align: center;
  margin-top: 20px;
`
const BoxNumber = styled.h1`
  text-align: center;
  font-size: 50px;
`
const borderStylings = { borderRight: "1px solid #0B6BF2", padding: "50px" }

const LineImage = styled.img`
  height: 110px;
  width : 100%;
  padding : 5;
`
const LookupForm = Form.create({ name: 'LookupForm' })(props => {
    const { onSubmit, inputProps, form, scanTypeId, setInvalidScan, setScanItem, scanInput, loading, setLoading, clearRefocusQueue, refocusQueue, visible } = props
    const { getFieldDecorator, getFieldValue, resetFields } = form

    const handleSubmit = (evt) => {
        evt.preventDefault()
        const input_value = getFieldValue('lookup_text');

        let payload = {};

        if (input_value === undefined) {
            return onSubmit({ error: 'Please Scan A Barcode' })
        }

        if (input_value.includes('STATION') === true) {
            payload = { barcode: input_value, scan_type_id: scanTypeId }
        }

        else if ((input_value.includes('STATION') === false && Cookies.get('STATION') === undefined)
            || parseInt(Cookies.get('SCAN_TYPE')) !== parseInt(scanTypeId)) {
            resetFields()
            scanInput.current.focus()
            return onSubmit({ error: 'Please Scan Station Card First' })
        }

        else {
            payload = { station_id: Cookies.get('STATION'), barcode: input_value, scan_type_id: scanTypeId };
        }
        setLoading(true)
        ZenSmartAPI.post(normalBarcodeScanRoute, payload)
            .then((res) => {
                resetFields()
                setLoading(false);
                setInvalidScan.setInvalidScanItem(null)
              
                //     scanInput.current.focus()
               
                onSubmit({ ...res.data, success_scan: true })
            })
            .catch((res) => {
                resetFields()
                setLoading(false);
                setScanItem()
                scanInput.current.focus()

                if (res.response.data.hasOwnProperty('message') && res.response.status === 400) {
                    setInvalidScan.setInvalidScanItem(res.response.data.message);
                }
                else {
                    notification.error({
                        message: "Not a valid scan item"
                    })
                }
            })

    }



    return (
        <Form onSubmit={handleSubmit}>
            {getFieldDecorator('lookup_text')(
                <Input
                    type="text"
                    placeholder="Scan Item"
                    ref={scanInput}
                    onFocus={clearRefocusQueue}
                    onBlur={refocusQueue}
                    {...inputProps}
                    disabled={loading}
                    loading={loading}
                />
            )}
        </Form>
    )
})

function PickingLookup(props) {
    const {
        title,
        detailLabel,
        isViewingDetails,
        onLookup,
        inputProps,
        children,
        scanTypeId,
        setScanItem,
        visible,
        setVisible,
        blockId,
        showDestination,
        setShowDestination,
        alreadyScanned,
        setAlreadyScanned,
        setShowScan,
        showScan,
        setShowModal,
        showModal
    } = props

    const [invalidScanItem, setInvalidScanItem] = useState(null)
    const [inputBarcode, setInputBarcode] = useState(null)
    const [loading, setLoading] = useState(false)
    const scanInput = useRef(null)
    const scanInputBarcode = useRef(null)
    const refocusQueueID = useRef(null)
    const clearRefocusQueue = () => {
        clearTimeout(refocusQueueID.current)
    }


    const refocusQueue = () => {
        refocusQueueID.current = setTimeout(() => {
            if (scanInput.current) {
                scanInput.current.focus()
            }
            if (scanInputBarcode.current) {
                scanInputBarcode.current.focus()
            }
        }, 5000)
    }
    
    const updateDataField = (data) => {
        setInputBarcode(data)
    }

    const removeBarcode = () => {
        setScanItem(null)
    }

    useEffect(() => {
        if (scanInput.current) {
            scanInput.current.focus()
        }
        return clearRefocusQueue
    }, [])

    useLayoutEffect(() => {
        if (scanInputBarcode.current) {
            scanInputBarcode.current.focus()
        }
        //return clearRefocusQueue
    }, [showModal])

    const checkForAlreadyScanned = (alreadyScanned,isViewingDetails) => {
        const pickingData = isViewingDetails ? isViewingDetails.page_definitions.sections.find(val => val.section_header === 'picking') : null;
        const lastScan = pickingData ? pickingData.section_data.find(val => val.label === 'LastScan') : null;
        console.log('lastScan',lastScan)
        if (alreadyScanned && showScan && lastScan) {
            Modal.confirm({
                title: 'Are you sure you want to proceed?',
                icon: <AIcon type='exclamation' />,
                content: `This Block has already been through this process at "${(lastScan ? lastScan.value : null )}". Click OK to proceed.`,
                onOk() {
                    if (showModal) {
                        setVisible(true)
                        if (scanInputBarcode.current) {
                            scanInputBarcode.current.focus()
                        }
                    } else {
                        setShowDestination(true)
                        scanInput.current.focus()
                    }
                },
                onCancel() {
                    scanInput.current.focus()
                    removeBarcode()
                },
            })
            setShowScan(false)
        }else if(showDestination){
            scanInput.current.focus()
        }
    }

    checkForAlreadyScanned(alreadyScanned,isViewingDetails)
    const handleSubmit = () => {
        if (!inputBarcode) {
            notification.error({
                message: "Please Enter Barcode"
            })
            return false
        }

        
        ZenSmartAPI.get(`/api/v1/picking/scan/${inputBarcode}/${blockId}/${getPickingLocationName()}/${Cookies.get('STATION')}`)
            .then((res) => {
                notification.success({
                    message: 'Picking Success!'
                })
                setVisible(false)
                setShowDestination(true)
                scanInput.current.focus()
                setInputBarcode(null)
            })
            .catch((res) => {
                notification.error({
                    message: "Picking Failed! Barcode does not match!"
                })
                setShowDestination(false)
                scanInput.current.focus()
                setInputBarcode(null)
                setVisible(false)
                removeBarcode()
            })

    }

    const handleCancel = () => {
        notification.error({
            message: "Picking failed, please try again"
        })
        setShowDestination(false)
        setVisible(false)
        scanInput.current.focus()
        removeBarcode()
    };

    const setOrderDetail = (data) => {
        const pickingData = data ? data.page_definitions.sections.find(val => val.section_header === 'picking') : null;
        return (
            <>
                {pickingData &&
                    <Col span={12} style={{ padding: "80px" }}>
                        <h1 style={{ fontSize: "35px", textAlign: "center" }}>
                            {pickingData.section_data[1].label === "Location" ? (showDestination === true ? `Pick From` : null)
                                : null}
                        </h1>
                        <h1 style={{ fontSize: "35px", textAlign: "center" }}>
                            {pickingData.section_data[1].label === "Location" ? (showDestination === true ? `${pickingData.section_data[1].value}` : null)
                                : null}
                        </h1>
                    </Col>
                }
            </>
        )
    }

    const getPickingLocationName = () => {
        const pickingData = isViewingDetails ?
            isViewingDetails.page_definitions.sections.find(val => val.section_header === 'picking') :
            null;

        const location = pickingData && pickingData.section_data[1].label === "Location" ?
            pickingData.section_data[1].value :
            null;

        return location;
    }

    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            handleSubmit()
        }
    }
    return (
        <Panel title={title} stylings={panelStylings}>
            <Row style={{ padding: "20px 70px 0px 70px" }}>
                <Col span={12} style={borderStylings}>
                    <StyledRow>
                        <Label status="blue">{detailLabel}</Label>
                    </StyledRow>
                    <StyledRow>
                        <Col span={24}>
                            <LookupForm visible={visible} onSubmit={onLookup} setScanItem={setScanItem} setInvalidScan={{ invalidScanItem, setInvalidScanItem }}
                                inputProps={inputProps} scanTypeId={scanTypeId} isViewingDetails={isViewingDetails} scanInput={scanInput}
                                loading={loading} setLoading={setLoading} clearRefocusQueue={clearRefocusQueue} refocusQueue={refocusQueue}
                            />
                        </Col>
                    </StyledRow>

                    {children}
                </Col>
                {invalidScanItem ?
                    <Col span={12} style={{ padding: "50px" }}>
                        <p style={{ textAlign: "center" }}>
                            <Label status="red">ATTENTION: <br />{invalidScanItem}</Label>
                        </p>
                        <InvalidScanImage>
                            <Hold style={{ height: 100, width: "100%" }} />
                        </InvalidScanImage>
                    </Col>
                    : isViewingDetails && isViewingDetails.popup_message ?
                        <Col span={12} style={{ padding: "50px" }}>
                            <Row type="flex" justify="center">
                                <Col span={24} >
                                    <p style={{ textAlign: "center", marginLeft: 20 }}>
                                        <Label status="mountain_meadow">{isViewingDetails.message.toUpperCase()}</Label>
                                    </p>
                                </Col>
                                <Col span={24}>
                                    <InvalidScanImage>
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn4buLXuLzta9pURLLUaAz4SpSNpMcAu0C4Q&usqp=CAU" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "15%", height: 100 }} />
                                    </InvalidScanImage>
                                </Col>
                            </Row>
                        </Col> :
                        isViewingDetails ? setOrderDetail(isViewingDetails) : null}
            </Row>
            <Modal
                title="This Scan Requires Verification"
                visible={visible}
                centered
                onOk={handleSubmit}
                onCancel={handleCancel}
            >
                {visible &&
                    <Form>
                        <Form.Item
                            name="barcode"
                            label={`Please enter the Barcode for ${getPickingLocationName()}:`}
                        >
                            <Input
                                onChange={(value) => updateDataField(value.target.value)}
                                onKeyDown={handleKeyDown}
                                value={inputBarcode}
                                ref={scanInputBarcode}
                                autoFocus={true}
                            />
                        </Form.Item>
                    </Form>
                }
            </Modal>


        </Panel >
    )
}

export default PickingLookup