import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, Input, Modal, Form, Button, Table } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenSmartAPI } from 'utils'
import { getPreDispatchOverview, getQaFailFormLineRoute, getAllDepartments , clearPigeonHole} from "utils/apiRoutes"
import { Label } from 'components/zensmart-design-system/components/Label/Label.js';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux'
const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}


.ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}

`

const InputHeader = styled.span`
  font-weight: bold;
`

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;

const PreDispatchOverview = (props) => {
    const [itemData, setItemData] = useState([])
    const [departments, setDepartments] = useState([])
    const [originalData, setOriginalData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [selectedCheckBoxValue, setSelectedCheckBoxValue] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectAction, setSelectAction] = useState(undefined)
    const [formValues, setFormValues] = useState(null)
    const [queueRange,setQueueRange] = useState([]);
    const [visibleQueueRange,setVisibleQueueRange] = useState(false);
    const userData = useSelector((state) => state.user.details);
    const [canClearRange,setCanClearRange] = useState(false);
    const FailForm = Form.create({ name: 'FailForm' })(props => {
        const { form } = props
        const { getFieldDecorator, getFieldsValue, validateFields } = form

        const handleSaveChanges = (evt) => {
            evt.preventDefault()
            validateFields((err, values) => {
                if (!err) {
                    setLoading(true);
                    const payload = getFieldsValue();
                    var queryStringID = ''
                    selectedCheckBoxValue.map((data, key) => {
                        queryStringID = key === 0 ? queryStringID + `${data}` : queryStringID + `;${data}`
                        return data
                    })
                    ZenSmartAPI.post(getQaFailFormLineRoute(queryStringID.replace(/;$/, "").replace(/^;/, "")), payload)
                        .then((res) => {
                            notification.success({ message: "Save lines successful!" })
                            setLoading(false);
                            hideModal()
                            setSelectedCheckBoxValue([])
                            fetchData();
                        })
                        .catch((res) => {
                            setLoading(false);
                            notification.error({ message: "Failed saving a lines!" })
                        })
                }
            });
        }
        const getFormSelectedValue = (value) => {
            const getSelected = value.find((item) => {
                return item.selected === true
            })
            if (getSelected !== undefined) {
                return getSelected.value
            }
        }

        return (
            <>
                {formValues &&
                    <>
                        <p style={{ textAlign: "center" }}>
                            <Label status="blue" >{formValues.message}</Label>
                        </p>

                        <Form onSubmit={handleSaveChanges}>
                            <div style={{ padding: "10px 50px" }}>

                                {formValues ? formValues.page_definitions.sections[0].section_data.map((row, key) =>

                                    <Form.Item label={<InputHeader>{row.label}</InputHeader>} >
                                        {getFieldDecorator(row.field, {
                                            initialValue: row.options ? getFormSelectedValue(row.options) : null,
                                            rules: row.required === true ? [{ required: true, message: `Please input this feld!` }] : undefined
                                        })(
                                            row.data_type === "SELECT" ?
                                                <Select placeholder={row.label} size="large"
                                                >
                                                    {row.options.map((data, key) =>
                                                        <Option value={data.value}>{data.text}</Option>
                                                    )}
                                                </Select>
                                                :
                                                <Input
                                                    placeholder={row.required === true ? "Please Input This Field" : "*Optional"}
                                                    size="large"
                                                />
                                        )}
                                    </Form.Item>
                                ) : null}
                            </div>
                            <Row type="flex" justify="center" align="middle">
                                <Col span={5} style={{ padding: 10 }}>
                                    <Form.Item>
                                        <Button type="danger" style={{ width: "100%" }} onClick={hideModal}
                                        >Cancel</Button>
                                    </Form.Item>

                                </Col>
                                <Col span={7} style={{ padding: 10 }} >
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading ? true : undefined}
                                            style={{ width: "100%" }}
                                        >
                                            Save Changes
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }
            </>
        )
    })

    const BoxRange = ({ data }) => {
        const columns = [
            {
                title: 'Group',
                dataIndex: 'group',
                key: 'group',
            },
            {
                title: 'Box Range Start',
                dataIndex: 'box_range_start',
                key: 'box_range_start',
            },
            {
                title: 'Box Range End',
                dataIndex: 'box_range_end',
                key: 'box_range_end',
            },
            {
                title: 'Letter Range',
                dataIndex: 'letter_range',
                key: 'letter_range',
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Button
                    size="small"
                    type="primary"
                    disabled={!canClearRange}
                    onClick={() => confirmClearUsingRange(record)}
                    >
                    Clear
                    </Button>
                ),
            },
        ];
        return <Table columns={columns} dataSource={queueRange} />    
    };

    const confirmClearUsingRange = (record) => {
        if(record.message){
            Modal.confirm({
                content: record.message,
                onOk: () => {
                    clearUsingRange(record)
                },
                onCancel: () => {}
            });
        }else{
            clearUsingRange(record)
        }
    }

    const clearUsingRange = (record) => {
        let pigeon_holes = record.pigeon_holes;
        ZenSmartAPI.post(clearPigeonHole('pre-dispatch-range'),{pigeon_holes})
        .then((res) => {
            fetchData()
            setVisibleQueueRange(false)
            notification.success({ message: "PigeonHole has been successfully emptied." })
        })
        .catch((res) => {
            setVisibleQueueRange(false)
            notification.error({ message: "PigeonHole clearance failed." })
        })
    }
    const fetchDepartments = () => {

        ZenSmartAPI.get(getAllDepartments)
            .then((res) => {
                setDepartments(res.data.data)

            })
            .catch((res) => {
            })

    }
    const hideModal = () => {
        setShowModal(false)
        setFormValues(null)
        setSelectAction(undefined)
    }

    const hideRangeModal = () => {
        setVisibleQueueRange(false)
    }

    const fetchData = (deparment_id = null) => {

        setLoading(true)
        setSelectedCheckBoxValue([])
        ZenSmartAPI.get(getPreDispatchOverview(deparment_id))
            .then((res) => {
                setLoading(false)
                setItemData(res.data)
                setOriginalData(res.data)
            })
            .catch((res) => {
                setLoading(false)
            })
    }

    const changeDepartment = (value) => {
        fetchData(value)

    }

    const actionChange = (value) => {

        if(value === 'fail_block'){
            var payload = ''
            selectedCheckBoxValue.map(data => {
                payload = payload + `${data};`
                return data
            })
            if (selectedCheckBoxValue.length > 0) {
                ZenSmartAPI.get(getQaFailFormLineRoute(payload.replace(/;$/, "").replace(/^;/, "")))
                    .then((res) => {
                        setLoading(false)
                        if (res.data && res.data.page_definitions.sections[0].section_data.length > 0) {
                            setSelectAction(value)
                            setFormValues(res.data)
                            setShowModal(true)
                        }
                        else {
                            notification.error({ message: "No available input forms!" })
                        }
                    })
                    .catch((res) => {
                        setLoading(false)
                    })
            }
            else {
                notification.error({ message: "Please select a line!" })
            }
        }

        if(value === 'clear_all'){
            Modal.confirm({
                content: `Are you sure you want to empty all pigeonhole?`,
                onOk: () => {
                    ZenSmartAPI.post(clearPigeonHole('pre-dispatch-all'))
                    .then((res) => {
                        fetchData()
                        notification.success({ message: "PigeonHole has been successfully emptied." })
                    })
                    .catch((res) => {
                        notification.error({ message: "PigeonHole clearance failed." })
                    })
                },
                onCancel: () => {}
            });
        }

        if(value === 'clear_range'){
            setLoading(true);
            ZenSmartAPI.get(clearPigeonHole('pre-dispatch-range'))
            .then((res) => {
                setLoading(false);
                setQueueRange(res.data);
                setVisibleQueueRange(true)
            })
            .catch((res) => {
                setLoading(false);
            })
        }

    }

    const searchOnArray = (anItemValue, value) => {
        const ifValueExist = anItemValue.some(data => {
            if (
                (data.line_id && data.line_id.toString().toLowerCase().includes(value.toLowerCase())) ||
                (data.product_code && data.product_code.toString().toLowerCase().includes(value.toLowerCase())) ||
                (data.current_stage && data.current_stage.toString().toLowerCase().includes(value.toLowerCase())) ||
                (data.submissions && data.submissions.some(submission => submission.block_barcode && submission.block_barcode.toString().toLowerCase().includes(value.toLowerCase())))
            ) {
                return true
            }
            else {
                return false
            }
        })

        return ifValueExist;
    }

    const searchOperation = debounce(async (value, source) => {
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                            false :
                            Array.isArray(anItemValue) ? searchOnArray(anItemValue, value) :
                                anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) :
                                    anItemValue.client_number ? anItemValue.client_number.toString().toLowerCase().includes(value.toLowerCase()) :
                                        anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
                return containsValue
            })
            setItemData(result)
        }
    }, 300)

    const handleVerification = (data) => {
        Modal.confirm({
            content: `Are you sure you want to empty this pigeonhole?`,
            onOk: () => {
                let payload = { id : data }
                ZenSmartAPI.post(clearPigeonHole('pre-dispatch'), payload)
                .then((res) => {
                    fetchData()
                    notification.success({ message: "PigeonHole has been successfully emptied." })
                })
                .catch((res) => {
                    notification.error({ message: "PigeonHole clearance failed." })
                })
            },
            onCancel: () => {}
        });
    }

    const checkPermission = (permission) => {
        if (userData?.should_authorize && permission && !userData?.permissions.find(p => p === permission)) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        fetchData()
        fetchDepartments()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCanClearRange(checkPermission('can-clear-predispatch-pigeon-hole'))
    }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps
 
    return (
        <>
            <Header>
                <Title>Pack & Ship {">"} Pre-Dispatch Overview</Title>
            </Header>
            <Panel title="PRE-DISPATCH OVERVIEW">
                <div style={{ padding: "40px 0px" }}>
                    <Row type="flex" align="middle" justify="start" style={{ padding: 30 }}>
                        <InputStyle />
                        <Col span={4} style={{ padding: 10 }}>
                            <Heading>SEARCH</Heading>
                            <Input.Search style={{ marginTop: 10, width: '100%' }}
                                type="text"
                                placeholder="Filters.."
                                disabled={loading}
                                onChange={(value) => searchOperation(value.target.value, originalData)}
                                loading={loading}
                            />
                        </Col>
                        <Col span={3} style={{ padding: 10 }}>
                            <Heading>DEPARTMENT</Heading>
                            <Select defaultValue={0} style={{ marginTop: 10, width: '100%' }} onChange={changeDepartment} placeholder="Select Vendor"
                                optionFilterProp="children"
                                showSearch  >
                                <Option value={0}>All Departments</Option>
                                {departments.map(data =>
                                    <Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={3} offset={14} style={{ padding: 0 }}>
                            <Heading><br /></Heading>
                            <Select placeholder="Select Action" style={{ marginTop: 10, width: '100%' }} value={selectAction} onChange={(value) => actionChange(value)} >
                                <Option value={'fail_block'} >Fail Line/s</Option>
                                <Option disabled={!canClearRange}  value={'clear_all'} >Clear All Pigeonhole</Option>
                                <Option disabled={!canClearRange}  value={'clear_range'} >Clear Pigeonhole Using Range</Option>
                            </Select>
                        </Col>
                    </Row>
                    <TableData itemData={itemData} loading={loading} style={{ padding: 40 }} selectedCheckBoxValue={selectedCheckBoxValue}
                        setSelectedCheckBoxValue={setSelectedCheckBoxValue} handleVerification={handleVerification} canClearRange={canClearRange} />
                </div>
                <>
                    <ModalStyle />
                    <Modal
                        title=""
                        centered
                        width={650}
                        visible={showModal}
                        footer={null}
                        onCancel={hideModal}
                    >
                        <div style={{ padding: 20 }}>
                            <FailForm />
                        </div>
                    </Modal>
                    <Modal
                        title=""
                        centered
                        width={650}
                        visible={visibleQueueRange}
                        footer={null}
                        onCancel={hideRangeModal}
                    >
                        <div style={{ padding: 20 }}>
                            <BoxRange />
                        </div>
                    </Modal>
                </>

            </Panel>
        </>
    )
}

export default PreDispatchOverview