import React from 'react'
import {createGlobalStyle} from 'styled-components';

const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}

.ant-select-lg && path {
    color : blue;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}
.ant-select-lg .ant-select-selection__rendered {
    color : blue;
}
`;

export default ModalStyle