import React, { useState } from "react";
import styled from "styled-components";
import { Panel } from "components/primaries";
import { color } from "components/zensmart-design-system/shared/styles.js";
import { Button, Icon, Upload } from "antd";
import _ from "lodash";
import BulkImportResponseModal from "./BulkImportResponseModal";

const Header = styled.header`
  margin-bottom: 24px;
`;
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`;
const StyleButton = styled(Button)`
  font-family: "Rubik", sans-serif;
`;

const BulkDataLoadingToolkitPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const props = {
    name: "file",
    action: `${process.env.REACT_APP_ZENSMART_API_SERVER}/api/v1/products/import/bulk`,
    showUploadList: false,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
    onChange(info) {
      setResponse(null);
      setLoading(true);
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setLoading(false);
        setResponse({
          type: "success",
          message: "PRODUCTS IMPORTED SUCCESSFULLY",
          results: info.file.response.data,
        });
        setShowModal(true);
      } else if (info.file.status === "error") {
        setLoading(false);
        if (_.get(info, "file.error.status") === 422) {
          info.file.response.type = "validation-error";
          setShowModal(true);
          setResponse(info.file.response);
          return;
        }

        if (
          _.get(info, "file.response.error") === "bulk-product-import-error"
        ) {
          info.file.response.type = "bulk-product-import-error";
          setShowModal(true);
          setResponse(info.file.response);
          return;
        }

        setShowModal(true);
        setResponse({
          type: "error",
          message: "AN UNEXPECTED ERROR OCCURRED. PLEASE CONTACT SUPPORT!",
          results: [],
        });
      }
    },
  };

  return (
    <>
      <Header>
        <Title>Prep {">"} Bulk Data Loading Toolkit</Title>
      </Header>
      <Panel title="Bulk Data Loading Toolkit">
        <BulkImportResponseModal
          show={showModal}
          onClose={() => setShowModal(false)}
          response={response}
        />
        <Upload disabled={loading} {...props}>
          <StyleButton disabled={loading} style={{ margin: 10 }} type="primary">
            <Icon type="upload" />
            {loading ? "Uploading..." : "Upload CSV for Bulk Import"}
          </StyleButton>
        </Upload>
      </Panel>
    </>
  );
};

export default BulkDataLoadingToolkitPage;
