import React, { useRef, useEffect, useState } from 'react'
import { Row, Col, Form, Input, Tag, Modal } from 'antd'
import styled, { css } from 'styled-components'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js'
import { Panel } from '../../../../components/primaries'
import { ZenSmartAPI } from 'utils';
import Cookies from 'js-cookie';
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { dueDateColor } from '../mockData';
import { normalBarcodeScanRoute } from "utils/apiRoutes"
import { defaultGamificationQuizValue, fetchGamificationQuiz, resetState } from 'components/sections/Gamification/helper'
import GameView, { Spinner } from 'components/sections/Gamification/GameView'
import Image from "components/zensmart-design-system/components/Image/Image"
import { Button } from 'components/zensmart-design-system'
/**
 * @note Clean the API for this component. Feels like it has to know
 * a lot of variables/prop values in order to be useful.
 */

const panelStylings = css`
  margin-bottom: 24px;
  padding-bottom: 50px;
`
const StyledRow = styled(Row)`
  margin-bottom: 24px;
`
const Bold = styled.span`
  font-weight: bold;
  margin-right: 8px;
  color: ${color.heading};
`
const DueDateTag = styled(Tag)`
    padding : 5px 20px;
    font-size : 15px;
`
const PreviewLink = styled.span`
    text-decoration: underline !important;
    margin-top: 5px;
    word-wrap: break-word;
`

const FlexImageWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;

  div {
    padding: 0 5px;
    text-align: center;
    margin-bottom: 5px;
  }
`

const dataProps = { fontSize: 15, padding: 5 };
const borderStylings = { borderRight: "1px solid #0B6BF2", padding: "50px" }

const LookupForm = Form.create({ name: 'LookupForm' })(props => {
    const { onSubmit, inputProps, form, scanTypeId, setLookup, scanInput , showFailedMultiple , setLookupInput} = props
    const { getFieldDecorator, getFieldValue, resetFields } = form
    const [loading, setLoading] = useState(false);
    const [scanGamification, setScanGamification] = useState(defaultGamificationQuizValue)
    const refocusQueueID = useRef(null)
    const clearRefocusQueue = () => {
        clearTimeout(refocusQueueID.current)
    }

    const refocusQueue = () => {
        refocusQueueID.current = setTimeout(() => {
            if (scanInput.current) {
                scanInput.current.focus()
            }
        }, 20000)
    }

    const handleSubmit = async (evt) => {
        if (evt) evt.preventDefault();
        const input_value = getFieldValue('lookup_text');
        let payload = {};

        if (input_value === undefined) {
            scanInput.current.focus()
            return onSubmit({ error: 'Please Scan A Barcode' })
        }

        if (input_value.includes('STATION') === true) {
            payload = { barcode: input_value, scan_type_id: scanTypeId }
        }

        else if ((input_value.includes('STATION') === false && Cookies.get('STATION') === undefined)
            || parseInt(Cookies.get('SCAN_TYPE')) !== parseInt(scanTypeId)) {
            resetFields()
            scanInput.current.focus()
            return onSubmit({ error: 'Please Scan Station Card First' })
        }

        else {
            payload = { station_id: Cookies.get('STATION'), barcode: (showFailedMultiple ? input_value.replaceAll(/\n/g, ",") : input_value), multipleBarcode : showFailedMultiple , scan_type_id: scanTypeId };
        }
        if (scanGamification.isVerified === false && input_value.includes('STATION') === false && Cookies.get('ALLOW_QUIZ') === 'true') {
            const quizCheck = await fetchGamificationQuiz(payload, setLoading, setScanGamification, scanInput);
            if (quizCheck === true) return;
        }

        setLoading(true);
        scanInput.current.focus()
        ZenSmartAPI.post(normalBarcodeScanRoute, payload)
            .then((res) => {
                resetFields()
                setLoading(false);
                scanInput.current.blur()
                input_value.includes('STATION') === true && scanInput.current.focus()
                if (res.data && res.data.duplicate_scan_prompt) {
                    Modal.confirm({
                        'content': res.data.duplicate_scan_prompt,
                        onOk: () => {
                        },
                        cancelButtonProps: { style: { display: "none" } }
                    });
                }
                if(showFailedMultiple){
                    if(res.data?.page_definitions){
                        let inputBarcode = res.data.page_definitions.sections[1].section_data.filter( (data) => {
                            return (data.label === 'Blocks#:' ?  data.value : null);
                        })
                        setLookupInput(inputBarcode[0].value)
                    }
                }
                onSubmit({ ...res.data, success_scan: true })
                resetState(setScanGamification)
            })
            .catch((res) => {
                setLoading(false);
                resetFields()
                resetState(setScanGamification)
                if (res?.response?.data.hasOwnProperty('message') && res?.response?.status === 400) {
                    onSubmit({ error: res.response.data.message })
                }
                else {
                    onSubmit('Not a valid Scan Item');
                }
            })

    }
    useEffect(() => {
        if (scanInput.current) {
            scanInput.current.focus()
        }
        return clearRefocusQueue
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (scanGamification.isVerified === true) handleSubmit()
    }, [scanGamification.isVerified])

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {getFieldDecorator('lookup_text')(
                    (showFailedMultiple === true ? 
                        <Input.TextArea
                            type="text"
                            rows={10}
                            placeholder="Scan Item"
                            ref={scanInput}
                            onFocus={clearRefocusQueue}
                            onChange={(e) => setLookup(e)}
                            onBlur={refocusQueue}
                            {...inputProps}
                            disabled={loading}
                            loading={loading}
                        />
                        : 
                        <Input
                            type="text"
                            placeholder="Scan Item"
                            ref={scanInput}
                            onFocus={clearRefocusQueue}
                            onChange={(e) => setLookup(e)}
                            onBlur={refocusQueue}
                            {...inputProps}
                            disabled={loading}
                            loading={loading}
                        />
                    )
                )}
                 { showFailedMultiple && <Button style={{float: 'right'}} >Submit</Button>}
            </Form>
            {scanGamification.loading && <Spinner size="large" tip={'Loading...'} />}
            {(scanGamification.gameData.length > 0 && scanGamification.loading === false) && (
                <GameView
                    scanGamification={scanGamification}
                    setScanGamification={setScanGamification}
                    resetFields={resetFields}
                    onSubmit={onSubmit}
                />
            )}
        </>
    )
})


function QAFailLookup(props) {
    const {
        title,
        detailLabel,
        isViewingDetails,
        isItemFailed,
        onLookup,
        inputProps,
        children,
        scanTypeId,
        setLookup,
        scanInput,
        showFailedMultiple,
        setShowFailedMultiple,
        setLookupInput,
    } = props

    const failMultiple = () => {
        setShowFailedMultiple(showFailedMultiple === false ? true : false)
    }

    const renderSection = (data) => {
        switch (data.label) {
            case "Due Date:":
                return (data.value?.due_date_text && <Col span={24} style={dataProps} >
                    <>
                        <Bold>{data.label}</Bold>
                        <DueDateTag color={dueDateColor[data.value.due_date_text.toLowerCase()] ?
                            dueDateColor[data.value.due_date_text.toLowerCase()] : "red"}
                            key={data.value.due_date_text}>
                            {data.value.due_date_text.toUpperCase()}
                        </DueDateTag>
                        <span> {data.value.formatted_due_date} </span>
                    </>
                </Col>)
                break;
            case "preview_path":
                return(data.value &&  <Col span={24} style={dataProps} >
                    <>
                        <Bold>PDF File</Bold>
                        <PreviewLink>
                            <a href={data.value} target="_blank">View PDF File</a>
                        </PreviewLink>
                    </>
                </Col>)
                break;
            case "thumbnail":
                return null
                break;
            case "Fails:":
                return (<Col span={24} style={dataProps} >
                    <>
                        <Bold>{data.label}</Bold>
                        <span> {data.value} </span>
                    </>
                </Col>)
                break;
            default:
                return (data.value && <Col span={24} style={dataProps} >
                    <>
                        <Bold>{data.label}</Bold>
                        <span> {data.value} </span>
                    </>
                </Col>)
                break;
        }
    }

    const setOrderDetail = (data) => {

        return (
            <Col span={12} style={{ padding: "50px" }}>
                <Col span={24}>
                    <p style={{ textAlign: "center" }}>
                        <Label status="mountain_meadow">
                            {data.message}
                        </Label>
                    </p>
                </Col>
                <Row>
                    <Col>
                        {isItemFailed ?
                            isItemFailed.page_definitions.sections[0].section_data.map(({ label, value }, key) =>
                                <ul style={{ marginTop: 10 }} key={key}>
                                    <Bold style={{ lineHeight: 2 }}>{label}</Bold>
                                    {Array.isArray(value) ? value.map((value, index) => <li style={{ lineHeight: 2 }} key={index}>
                                        <span style={{ fontWeight: "bold", fontSize: 20 }}>&#8226; </span>{value}</li>) : <li>{value}</li>}
                                </ul>
                            )
                            : null}
                    </Col>
                </Row>
                <Col span={24} style={dataProps} >
                    <br />
                </Col>
                <Row>
                    {data.page_definitions.sections[1].section_data.map((data, key) => renderSection(data))}
                    {
                        data.page_definitions.sections[1].section_data.map((data, key) =>
                            (data.label === "thumbnail" ?
                                <Col span={24} style={dataProps} >
                                    <>
                                        <FlexImageWrapper>
                                            {data.value.front &&
                                                <Image
                                                    src={data.value.front}
                                                    width={180}
                                                    height={300}
                                                    alt=""
                                                    objectFit="fill"
                                                />
                                            }
                                            {data.value.back &&
                                                <Image
                                                    src={data.value.back}
                                                    width={180}
                                                    height={300}
                                                    alt=""
                                                    objectFit="fill"
                                                />
                                            }
                                        </FlexImageWrapper>
                                    </>
                                </Col> : <></>
                            )
                        )
                    }
                </Row>
            </Col>
        )
    }

    return (
        <Panel title={title} stylings={panelStylings}>
            <Row style={{ padding: "20px 70px 0px 70px" }}>
                <Col span={12} style={borderStylings}>
                    <StyledRow>
                        <Label status="blue">{detailLabel}</Label>
                        <Button onClick={() => failMultiple()} style={{float: 'right'}} >{showFailedMultiple === false ? "Fail Multiple ": "Single Fail"}</Button>
                    </StyledRow>
                    <StyledRow>
                        <Col span={24}>
                            <LookupForm scanInput={scanInput} onSubmit={onLookup} inputProps={inputProps} scanTypeId={scanTypeId} setLookup={setLookup} isViewingDetails={isViewingDetails} showFailedMultiple={showFailedMultiple} setLookupInput={setLookupInput}  />
                        </Col>
                    </StyledRow>
                    {children}
                </Col>
                {isItemFailed ? setOrderDetail(isItemFailed) : isViewingDetails ? setOrderDetail(isViewingDetails) : null}
            </Row>
        </Panel>
    )
}

export default QAFailLookup