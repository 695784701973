
const breadcrumbs = (state = [], action) => {
    switch (action.type) {
        case 'ADD_BREADCRUMB':
            return [
                ...state,
                {
                    id: action.breadcrumb.id,
                    name: action.breadcrumb.name,
                    resource_id: action.breadcrumb.resource_id,
                    link: action.breadcrumb.link
                }
            ]
        case 'DELETE_BREADCRUMBS':
            let breadcrumbs = [...state];
            breadcrumbs.splice(action.index + 1);
            return breadcrumbs;
        case 'CLEAR_BREADCRUMB':
            return []
        default:
            return state;
    }
};

export default breadcrumbs;
