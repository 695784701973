import React, { useState } from "react";
import { Modal, Form, Button, Input, notification } from 'antd';
import styled from "styled-components";
import { ZenSmartAPI } from "utils";
import { getAllStationsRoute, getStationTasksRoute } from "utils/apiRoutes"
import { useDispatch } from 'react-redux';
import { selectStation } from 'store/actions/machine';
import { useHistory } from "react-router-dom";

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`

const StationInputModal = props => {
  const { show, setShow, onStationEnter } = props;
  const [ stationBarcode, setStationBarcode ] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleKeyDown = e => {
    if(e.keyCode === 13 && stationBarcode !== '') {
      getStation(stationBarcode);
    }
  }

  const getStation = barcode => {
    ZenSmartAPI.get(getAllStationsRoute)
    .then((res)=>{
      const filteredStations = res.data && res.data.data ? res.data.data.filter(station => station.barcode === barcode) : null;
      const selectedStation = filteredStations.length > 0 ? filteredStations[0] : null;
      if(selectedStation) {
        if(selectedStation.station_type) {
          const payload = {device_type: selectedStation.station_type}
          ZenSmartAPI.post(getStationTasksRoute, payload)
          .then((res)=>{
            dispatch(selectStation(selectedStation))
            onStationEnter(selectedStation, 'start-up');
          })
          .catch((res)=>{
            notification.error({
              message: 'Fetching tasks failed.'  
            })
          })
        } else {
          notification.error({
            message: 'Couldn\'t find station type'
          })
        }
      }
      else {
        notification.error({
          message: 'Station barcode is invalid.'
        })
      }
    })
    .catch((res)=>{
      
    })
  }

  return (
    <Modal
      zIndex={1}
      visible={show}
      onCancel={ () => setShow(false) }
      closable={false}
      maskClosable={false}
      keyboard={false}
      getContainer={ document.getElementsByClassName('machine-content') }
      footer={[
        <Button key="back" onClick={ () => history.push('/app') }>
          Cancel
        </Button>
      ]}
    > 
      <Form>
        <StyledFormItem label="Station Barcode" name="station-barcode">
          <Input 
            autoFocus
            placeholder="Enter station barcode" 
            onChange={e => setStationBarcode(e.target.value) }
            onKeyDown={handleKeyDown}
          />
        </StyledFormItem>
      </Form>
    </Modal>
  );
}

export default StationInputModal

