import {configureStore, combineReducers} from '@reduxjs/toolkit'

import {loadState} from 'localStorage.js'

import {SearchReducer} from './slices/searchSlice.js'
import {UserReducer} from './slices/userSlice.js'
import impositionReducer from './reducers/impositionReducer.js'
import ScanReducer from './reducers/scanReducer.js'
import dispatchReducer from './reducers/dispatchReducer.js'
import breadcrumbsReducer from './reducers/breadcrumbsReducer.js';
import globalReducer from './reducers/globalReducer'
import machineReducer from './reducers/machineReducer'
import middleware from './middlewares'
import controlTowerReducer from './reducers/controlTowerReducer.js'
import productFlowReducer from './reducers/productFlowReducer.js'
import dashboardReducer from './reducers/dashboardReducer'
import productListReducer from './reducers/productListReducer'

const rootReducer = combineReducers({
  search: SearchReducer,
  user: UserReducer,
  imposition: impositionReducer,
  product_list: productListReducer,
  scan: ScanReducer,
  dispatch : dispatchReducer,
  breadcrumbs: breadcrumbsReducer,
  global : globalReducer,
  controlTower : controlTowerReducer,
  machine: machineReducer,
  product_flow : productFlowReducer,
  dashboard: dashboardReducer
})

export default configureStore({
  reducer: rootReducer,
  middleware,
})