export default {
  TEXT: 'TEXT',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  INTEGER: 'INTEGER',
  SELECT: 'SELECT',
  IMAGE: 'IMAGE',
  LINK: 'LINK',
  TEXTAREA : 'TEXTAREA',
  AUTOCOMPLETE : 'AUTOCOMPLETE',
  ADD_FEATURE_SELECT : 'ADD_FEATURE_SELECT',
  MULTIPLE_TAG_SELECT : 'MULTIPLE_TAG_SELECT',
  TAG_SELECT : 'TAG_SELECT',
  DATETIME: 'DATETIME'
}
