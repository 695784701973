import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Col, Select, Button, Spin } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { bomInitdataRoute } from "utils/apiRoutes"
import ReportingInputs from './PanelInputs/ReportingInputs'
import ManualConsumptionInputs from './PanelInputs/ManualConsumptionInputs'
import BomDataInput from './PanelInputs/BomDataInput'
import moment from 'moment';
import { useSelector } from 'react-redux'

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const BomMaterialsPage = (props) => {
  const [initialData, setInitialData] = useState(null)
  const userData = useSelector(state => state.user)

  // reporting panel
  const reportingInitialState = {
    report_format: null,
    date_from: null,
    date_to: null,
    product: null,
    line: null
  }
  const [reportingPanel, setReportingPanel] = useState(reportingInitialState)
  const [loading, setLoading] = useState(false)

  // manual consumption panel
  const manualConsumptionInitialState = {
    product_id: null,
    block: null,
    scan_type_id: null,
    amount: null
  }
  const [manualConsumptionPanel, setManualConsumptionPanel] = useState(manualConsumptionInitialState)

  // update bom data panel
  const [updateParts, setUpdateParts] = useState(null)
  const [updateLocation, setUpdateLocation] = useState(null)
  const [updateWorkFlow, setUpdateWorkFLow] = useState(null)


  const fetchInitData = () => {
    setLoading(true)
    ZenSmartAPI.get(bomInitdataRoute)
      .then((res) => {
        setLoading(false)
        setInitialData(res.data.data)
      }).catch((err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchInitData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {
        userData && userData.details && userData.details.show_materials_page && userData.details.show_materials_page.show_bill_of_materials_page &&
        <div>
          <Header>
            <Title>Materials {">"} BOM Materials</Title>
          </Header>
          <Panel title="BOM Manager">
            <Spin tip="Loading..." spinning={loading}>
              <ReportingInputs
                reportingPanel={reportingPanel}
                setReportingPanel={setReportingPanel}
                reportingInitialState={reportingInitialState}
                initialData={initialData}

                 />
              <ManualConsumptionInputs
                initialData={initialData}
                manualConsumptionInitialState={manualConsumptionInitialState}
                manualConsumptionPanel={manualConsumptionPanel}
                setManualConsumptionPanel={setManualConsumptionPanel}
                loading={loading}
                setLoading={setLoading}
                fetchInitData={fetchInitData}

              />
              <BomDataInput
                initialData={initialData}
                updateParts={updateParts}
                setUpdateParts={setUpdateParts}
                updateLocation={updateLocation}
                setUpdateLocation={setUpdateLocation}
                updateWorkFlow={updateWorkFlow}
                setUpdateWorkFLow={setUpdateWorkFLow}
                fetchInitData={fetchInitData}
                loading={loading}
                setLoading={setLoading}

              />
            </Spin>
          </Panel>
        </div>
      }
    </>
  )
}

export default BomMaterialsPage