import {PROCESS} from 'types'

const createItem = (config = {}) => ({
  matchGroup: '',
  editable: true,
  is_print_job_ticket: false,
  ...config,

  accepts: null,
  children: null,

  attachables: [
    PROCESS.TRIGGER.EMAIL,
    PROCESS.TRIGGER.FIELD,
    PROCESS.TRIGGER.CALLOUT,
    PROCESS.TRIGGER.ADD_COST,
  ],
  triggers: {},

  plex: null,
  submission_per_block: false,
  page_object: false,
  requires_work_order: false,
  no_print_required: false,
})

export default createItem