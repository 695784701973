import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

const StyledSpan = styled.span`
  width: 100%;
  .delete_icon {
    vertical-align: text-bottom;
    visibility: hidden;
    margin: 1px;
  }
  &:hover .delete_icon {
    visibility: visible;
  }
`

const ColumnHeaderInfo = ({
  label,
  deleteAction,
  showDelete,
  setDragAction,
  stopDragAction,
  ...props
}) => (
  <StyledSpan
    draggable
    onDragStart = {() => setDragAction && setDragAction()}
    onDragEnd = {() => stopDragAction && stopDragAction()}
    {...props}
  >
    {label}
    {showDelete && (
      <Icon
        className='delete_icon'
        type='delete'
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          deleteAction && deleteAction();
        }}
      />
    )}
  </StyledSpan>
)

export default ColumnHeaderInfo