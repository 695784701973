import React from "react";
import styled, { css } from "styled-components";
import { IconContainer } from './DropComponents';
import is from "is_js";
import { ReactComponent as CloseIcon } from "static/icons/close.svg";
import { ReactComponent as EditIcon } from "static/icons/edit-icon.svg";
import { FormTypes, FormValues } from "modules/imposition/config/Form";

const DragItemMain = styled.div`
  position: relative;
  border: 1px solid #e0e7ff;
  border-radius: 5px;
  width: 200px;
  height: 65px;
  box-sizing: border-box;
  margin-bottom: 10px;
  background-color: #ffffff;
  
  ${(props) =>
    props.parameters &&
    css`
      height: 100%;
    `}

  .custom_label {
    color: #1F36C7;
    word-wrap: break-word;
  }

  .recipe_name {
    word-wrap: break-word;
  }
`;

const DragItemLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  border-left: 4px solid #2e5bff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const DragItemRight = styled.div`
  width: 80%;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  margin-top: ${(props) => (props.parameters ? "6px" : "20px")};
  margin-left: 30px;
`;

const DragItemIcon = styled.div`
  display: ${(props) => (props.showIcon ? "block" : "none")};
`;

const DragItemIconContainer = styled.div`
  position: absolute;
  top: -5px;
  left: 185px;
  width: 30px;
  height: 20px;
`;

const DragItemParametersContainer = styled.div`
  margin-bottom: 10px;

  .no_parameter {
    height: 25px;
  }

  & > div {
    word-wrap: break-word;
  }
`;

const DragItemParameters = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: #8798ad;
`;

const DragItem = (props) => {
  const onClick = (event) => {};
  const onDragStart = (event) => {};
  const onDragEnd = (event) => {};
  const onIconClick = (event) => {};
  const { getProperty, valuesProp } = props;

  const getParamValue = (parameter) => {
    const paramValue = parameter.value;
    const parametersValues = getProperty(valuesProp, parameter.name);

    if (is.not.empty(parametersValues) && parametersValues.type === "Dropdown"){
      const filteredParamValue = parametersValues.values.find((parametersValue) => is.existy(parametersValue.label) && parametersValue.value === paramValue);

      if (filteredParamValue){
        return filteredParamValue.label;
      }
    }else if (is.existy(parameter.value) && is.not.string(parameter.value)){
      return parameter.value.toString();
    }

    return paramValue;
  };

  return (
    <DragItemMain
      id={props.id}
      draggable={props.draggable}
      onDragStart={props.onDragStart || ((event) => onDragStart(event))}
      onDragEnd={props.onDragEnd || ((event) => onDragEnd(event))}
      parameters={props.parameters}
    >
      <DragItemLeft />
      <DragItemRight parameters={props.parameters}>
        {" "}
        {props.parameters &&
          props.parameters[0].name === "Label" &&
          props.parameters[0].value && (
              <div className="custom_label">{props.parameters[0].value}</div>
          )}
        <div className="recipe_name">{props.name}</div>
        {props.parameters && (
          <DragItemParametersContainer>
            {props.parameters
                .filter((parameter) => parameter.name !== "Label")
                .map((parameter, index) => {
              const paramValue = getParamValue(parameter);

              return <DragItemParameters key={index}>
                {parameter.name}: {paramValue}
              </DragItemParameters>;
            })}
            {(props.parameters && props.parameters.length === 1 && props.parameters[0].name === "Label") &&
              <div className="no_parameter"/>
            }
          </DragItemParametersContainer>
        )}
      </DragItemRight>

      <DragItemIconContainer>
        <DragItemIcon
          onClick={props.onIconClick || ((event) => onIconClick(event))}
          showIcon={props.showIcon}
        >
          <CloseIcon width="20px" height="15px" />
        </DragItemIcon>
      </DragItemIconContainer>

      <IconContainer
        color="blue"
        showIcon={props.showIcon}
        onClick={props.onClick || ((event) => onClick(event))}
      >
        <EditIcon width="8px" height="8px" fill="white" />
      </IconContainer>
    </DragItemMain>
  );
};

export default DragItem;
