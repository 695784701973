import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, DatePicker, Button, Input, Modal } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenBaradurAPI } from 'utils'
import { failTransactionsPanelRoute, retryTransactionsPanelRoute } from "utils/apiRoutes"
import moment from 'moment';
import { failedTransactionInitialData } from "utils/panelsInitialData"

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`

const FailedTransactionPage = (props) => {
    const [fetchDate, setFetchDate] = useState({ from: moment().subtract(7, "days").format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') })
    const [itemData, setItemData] = useState([])
    const [currentPagination, setCurrentPagination] = useState(1)
    const [totalPagination, setTotalPagination] = useState(0)
    const [pageSize, setPageSize] = useState(0)
    const [loading, setLoading] = useState(false)
    const [originalData, setOriginalData] = useState([]);
    const [ascend, setAscend] = useState(failedTransactionInitialData)

    const changeDateFrom = (value, dateString) => {
        setFetchDate({ ...fetchDate, from: dateString })
    }
    const changeDateTo = (value, dateString) => {
        setFetchDate({ ...fetchDate, to: dateString })
    }

    const fetchData = () => {

        const fromDate = new Date(fetchDate.from);
        const toDate = new Date(fetchDate.to)

        setLoading(true)
        ZenBaradurAPI.get(failTransactionsPanelRoute(`from=${fromDate.getTime() / 1000}&to=${toDate.getTime() / 1000}`))
            .then((res) => {
                setLoading(false)
                setItemData(res.data)
                setOriginalData(res.data)
                setAscend(failedTransactionInitialData)
            })
            .catch((res) => {
                setLoading(false)
            })

    }


    const searchOperation = async (value, source) => {
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                            false :
                            moment(anItemValue).isValid() ? moment(anItemValue).format("D MMM YYYY, h:mma").toString().toLowerCase().includes(value.toLowerCase()) : anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) : anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
                return containsValue
            })
            setItemData(result)
        }
    }

    const retryData = (client_number) => {
        Modal.confirm({
            'content': "Are you sure you want to retry this transaction?",
            onOk: () => {
                setLoading(true)
                ZenBaradurAPI.get(retryTransactionsPanelRoute(client_number))
                    .then((res) => {
                        setLoading(false)
                        setItemData(itemData.filter(data => data.client_number !== client_number))
                        setOriginalData(originalData.filter(data => data.client_number !== client_number))
                        notification.success({ message: "Retry transaction successful!" })
                    })
                    .catch((res) => {
                        setLoading(false)
                        notification.error({ message: "Retry transaction not successful!" })

                    })
            },
        });

    }

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Process {">"} Failed Transactions</Title>
            </Header>
            <Panel title="FAILED TRANSACTIONS">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <InputStyle />
                    <Row type="flex" align="middle">
                        <DateColStyle span={4} >
                            <Heading>FILTERS</Heading>
                            <Input.Search
                                style={{ marginTop: 10, width: '100%' }}
                                type="text"
                                placeholder="Filters.."
                                disabled={loading}
                                onChange={(value) => searchOperation(value.target.value, originalData)}
                                loading={loading}
                            />
                        </DateColStyle>
                        <DateColStyle span={3} >
                            <Heading>FROM</Heading>
                            <DatePicker style={{ marginTop: 10, width: '100%' }} onChange={changeDateFrom} defaultValue={moment(new Date(moment().subtract(7, "days")), 'YYYY-MM-DD')} />
                        </DateColStyle>
                        <DateColStyle span={3} >
                            <Heading>TO</Heading>
                            <DatePicker style={{ marginTop: 10, width: '100%' }} onChange={changeDateTo} defaultValue={moment(new Date(), 'YYYY-MM-DD')} />
                        </DateColStyle>
                        <DateColStyle span={4}>
                            <br />
                            <StyleButton style={{ marginTop: 10 }} onClick={fetchData} loading={loading} type="primary">GO</StyleButton>
                        </DateColStyle>
                    </Row>
                </div>

                <TableData loading={loading} data={itemData} currentPagination={currentPagination} setCurrentPagination={setCurrentPagination}
                    setTotalPagination={setTotalPagination} totalPagination={totalPagination} pageSize={pageSize} setData={setItemData}
                    ascend={ascend} setAscend={setAscend} retryData={retryData} />
            </Panel>
        </>
    )
}

export default FailedTransactionPage