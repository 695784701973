import React, { useEffect, useState } from 'react'
import { getDailyRunLogRoute, getRecentRunStatusRoute } from 'utils/apiRoutes'
import { Skeleton, notification } from 'antd'
import { Card, Title, LogText } from './components/index.js'

const Logs = ({ id }) => {
  const [loading, setLoading] = useState(false)
  const [logData, setLogData] = useState('')
  const delayInMs = 2000
  const lengthOfCharacters = 20000
  const [startFetchingLog, setStartFetchingLog] = useState(false)

  const setTimeoutLogUpdate = () => {
    setTimeout(() => setStartFetchingLog(true), delayInMs)
  }

  const fetchLogs = async () => {
    try {
      const url = `${process.env.REACT_APP_ZENSMART_API_SERVER}${getDailyRunLogRoute(id)}`
      setStartFetchingLog(oldState => false)
      const apiRequest = await fetch(url, {
        headers: {
          Range: `bytes=${lengthOfCharacters}-`
        }
      })
      const data = await apiRequest.text()
      const dataArr = data.split('\n') //'<br />\n' ||

      if (dataArr.length === 1) {
        setLogData(oldState => {
          setTimeoutLogUpdate()
          return data
        })
        return
      }

      const startingIndex = dataArr.length > 21 ? dataArr.length - 21 : 0
      // show the last 20 elements in the data returned
      let top20Logs = await dataArr.slice(startingIndex, dataArr.length - 1);
      setLogData(oldState => {
        setTimeoutLogUpdate()
        return top20Logs
      })
    } catch (error) {
      notification.error({message: error?.response?.data?.message ?? error.message ?? 'Error fetching runs'})
    }
  }

  useEffect(() => {
    const loadLogs = async () => {
      try {
        setLoading(true)
        await fetchLogs()
        setTimeoutLogUpdate()
      } catch (error) {
        notification.error({message: error?.response?.data?.message ?? error.message ?? 'Error fetching runs'})
      } finally {
        setLoading(false)
      }
    }
    loadLogs()
  }, [id])

  useEffect(() => {
    if (startFetchingLog) {
      setStartFetchingLog(false)
      fetchLogs()
      return () => setStartFetchingLog(false)
    }
  }, [startFetchingLog])

  return (
    <div>
      <Title>Logs</Title>
      <Card>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {typeof logData === 'string' ? (
              <LogText>{logData}</LogText>
            ) : (
              logData.map((log, index) => (
                <LogText key={index}>{log}</LogText>
              ))
            )}
          </>
        )}
      </Card>
      <a
        href={`${
          process.env.REACT_APP_ZENSMART_API_SERVER
        }${getRecentRunStatusRoute(id)}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        View Today's Logfile
      </a>
    </div>
  )
}

export default Logs
