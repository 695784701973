import { GetLabel } from "pages/Performance/utils/index";
import _ from "lodash";
import { alphabeticalData, arrayReportSort } from 'utils/sortData';
import moment from 'moment'

export const defaultChartTop = 16;
export const defaultChartLeft = 80;
export const defaultChartRight = 16;
export const defaultChartBottom = 50;
export const emptyRecords = "No records could be found";

export const sortObjects = (value) => {
  let sortable = [];
  for (let objects in value) {
    sortable.push([objects, value[objects]]);
  }

  sortable.sort(function (a, b) {
    return a[0] - b[0];
  });

  const objSorted = {};
  sortable.map(function (item, ) {
    objSorted[" " + item[0]] = item[1]
  });
  return objSorted;
};

export const formatUniqueCount = (uniqueData, editChartConfiguration, vAxis = '', isDashboard = false, getGroupType = false,groupedDate = {}) => {
  const modifiedY = GetLabel(editChartConfiguration.yAxis);
  const yAxisColumn = editChartConfiguration.yAxis;

  if (typeof uniqueData !== "undefined" && uniqueData.length > 0){
    if (!editChartConfiguration.stackData) {
      editChartConfiguration.data = [[modifiedY, vAxis ? vAxis : "Record count"]];
      const sortData = arrayReportSort(Object.values(uniqueData).map((value) => [
        value[yAxisColumn] ? value[yAxisColumn].toString() : null,
        Number(value["sub_total"]),
      ]), "0", yAxisColumn, groupedDate)
      editChartConfiguration.data.push(
          ...sortData
      );
    } else {
      const stackDataColumn = editChartConfiguration.stackData;
      const getDataTypeGroup1 = getGroupType ? getGroupType(yAxisColumn) : false
      const getDataTypeGroup2 = getGroupType ? getGroupType(stackDataColumn) : false

      editChartConfiguration.data = convert_unique_count(
          yAxisColumn,
          stackDataColumn,
          uniqueData,
          getDataTypeGroup1,
          getDataTypeGroup2,
          groupedDate
      );
    }
  }else{
    editChartConfiguration.data = null;
  }

  const chartOptions = editChartConfiguration.type === "PieChart" ? {
    chartArea: {
      width: "100%",
      height: "80%"
    },
    legend: {
      position: "right",
    },
  } : {
      chartArea: {
        width: "100%",
        height: "100%",
        top: defaultChartTop,
        left: defaultChartLeft,
        right: defaultChartRight,
        bottom: defaultChartBottom,
      },
      legend: {
        position: "bottom",
      },
    }

  if (isDashboard) {
    chartOptions.legend = {
      ...chartOptions.legend,
      textStyle: {
        fontSize: 12,
      },
    }
  }

  editChartConfiguration.options = {
    title: editChartConfiguration.title,
    hAxis: { title: modifiedY },
    vAxis: { title: vAxis ? vAxis : "Record count" },
    isStacked: editChartConfiguration.stackData ? true : false,
    ...chartOptions
  };

  return editChartConfiguration;
};

const convert_unique_count = (group1, group2, uniqueData, getDataTypeGroup1 = false, getDataTypeGroup2 = false, groupedDate = {}) => {
  const group1Grouped = _.groupBy(uniqueData, group1)
  const group2Unique = _.uniq(_.map(uniqueData, group2))

  const heading = [
    GetLabel(getDataTypeGroup2 === "bool" && group1 == "0" ? "False" : getDataTypeGroup2 === "bool" && group1 == "1" ? "True" : group1),
    ...(group2Unique.map(item => GetLabel(getDataTypeGroup2 === "bool" && item == "0" ? "False" : getDataTypeGroup2 === "bool" && item == "1" ? "True" : item)))
  ]

  const result = Object.entries(group1Grouped).map(([group1Unique, group1UniqueGrouped]) => {
    const converted = [getDataTypeGroup1 === "bool" && group1Unique == "0" ? "False" : getDataTypeGroup1 === "bool" && group1Unique == "1" ? "True" : group1Unique]
    const finalGrouping = _.groupBy(group1UniqueGrouped, group2)

    group2Unique.map(fn => {
      converted.push(finalGrouping[fn] === undefined ? 0 : Number(finalGrouping[fn][0]['sub_total']))
    })

    return converted
  })
  
  const sortData = arrayReportSort(result, "0", group1, groupedDate,)

  return [heading, ...sortData]
};