import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { getStationProductActivityRoute, getAllStationsRoute } from "utils/apiRoutes"
import moment from 'moment';
import { alphabeticalData } from "utils/sortData"

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;

const StationProductActivityPage = (props) => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [stations, setstations] = useState([])
    const [payload, setPayload] = useState({
        stations_id: [],
        from: moment().format('YYYY-MM-DD HH:mm:ss'),
        to: moment().format('YYYY-MM-DD HH:mm:ss'),
    })
    const [headingSelectionValue, setHeadingSelectionValue] = useState("")
    const fetchstations = () => {

        setLoading(true)
        ZenSmartAPI.get(getAllStationsRoute)
            .then((res) => {
                setLoading(false)
                setstations(res.data.data)

            })
            .catch((res) => {
                setLoading(false)
            })

    }
    const constructData = (data) => {
        const returnData = [];
        Object.keys(data).map(item => {
            data[item].map((item2, key) => {
                if (key === 0) {
                    returnData.push(item2)
                }
                else {
                    item2.operator = ""
                    returnData.push(item2)
                }
            })
        })
        return returnData
    }
    const fetchData = () => {

        if (!payload.stations_id && payload.stations_id.length === 0) {
            notification.error({ message: "Please select a station!" })
            return
        }
        if (!payload.from || payload.from === "" || !payload.to || payload.to === "") {
            notification.error({ message: "Please select date ranges!" })
            return
        }

        setLoading(true)
        ZenSmartAPI.post(getStationProductActivityRoute, payload)
            .then((res) => {
                setLoading(false)
                if (Object.keys(res.data.data).length > 0) {
                    setItemData(constructData(res.data.data))
                }
                else {
                    notification.error({ message: "No data found!" })
                }
            })
            .catch((error) => {
                setLoading(false);
                // handle validation errors

                if (_.get(error, 'response.status') === 400) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An unhandled error occurred. Please contact your supervisor.'),
                    });
                }

                if (_.get(error, 'response.status') > 499) {
                    notification.error({
                        message: 'An unhandled error occurred. Please contact your supervisor'
                    });
                }
            })
        return true
    }

    const changeFrom = (value, dateString) => {
        setPayload({ ...payload, from: dateString })
    }
    const changeTo = (value, dateString) => {
        setPayload({ ...payload, to: dateString })
    }

    const changeStation = (value) => {
        setPayload({ ...payload, stations_id: value })

    }

    useEffect(() => {
        fetchstations()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Performance {">"} Station Performance {">"} Station Product Activity Data</Title>
            </Header>
            <Panel title="STATION PRODUCT ACITIVTY DATA">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <InputStyle />
                    <Row type="flex" align="middle">
                        <DateColStyle span={4} >
                            <Heading>STATION</Heading>
                            <Select
                                optionFilterProp="children"
                                showSearch
                                mode="multiple"
                                style={{ marginTop: 10, width: '100%', textTransform: "capitalize" }} onChange={changeStation} placeholder="Select Station..." >
                                {alphabeticalData(stations, "name").map(data =>
                                    <Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </DateColStyle>
                        <DateColStyle span={4} >
                            <Heading>FROM</Heading>
                            <DatePicker showTime style={{ marginTop: 10, width: '100%' }} onChange={changeFrom} format="YYYY-MM-DD HH:mm:ss" defaultValue={moment(moment(), 'YYYY-MM-DD HH:mm:ss')} />
                        </DateColStyle>
                        <DateColStyle span={4} >
                            <Heading>TO</Heading>
                            <DatePicker showTime style={{ marginTop: 10, width: '100%' }} onChange={changeTo} format="YYYY-MM-DD HH:mm:ss" defaultValue={moment(moment(), 'YYYY-MM-DD HH:mm:ss')} />
                        </DateColStyle>
                        <DateColStyle span={4}>
                            <br />
                            <StyleButton style={{ marginTop: 10 }} onClick={fetchData} loading={loading} type="primary">GO</StyleButton>
                        </DateColStyle>
                    </Row>
                </div>

                <TableData loading={loading} data={itemData} setData={setItemData} />
            </Panel>
        </>
    )
}

export default StationProductActivityPage