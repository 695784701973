import React  from 'react'
import styled from 'styled-components'
import { Modal, Form, InputNumber, Button, Checkbox, Select, AutoComplete, notification } from 'antd'
import { alphabeticalData } from 'utils/sortData';
import debounce from 'lodash/debounce';
import { getAllQuiz } from 'utils/apiRoutes'
import { ZenSmartAPI } from 'utils'

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`

const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`

const Number = styled(InputNumber)`
  width: 100%;
`;

const processTextRules = [
    {
        required: true,
        message: 'Cannot be blank'
    },
    {
        max: 23,
        message: 'Must be less than 23 characters'
    }
];
const { Option } = AutoComplete;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function EditForm(props) {
    const {
        form,
        process,
        onSave,
        processID,
        isChild,
        hideModal,
        gamificationQuiz,
        scanTypesOptions
    } = props

    const {
        getFieldDecorator,
        isFieldTouched,
        getFieldError,
        getFieldsError,
        getFieldsValue,
        resetFields,
    } = form
    const [quizFieldValues, setQuizFieldsValue] = React.useState([])

    const handleSubmit = e => {
        e.preventDefault()
        const payload = getFieldsValue()
        if(payload?.enable_gamification === true && payload.quiz_id === '') {
            notification.error({message: 'Select a quiz'})
            return;
          }
        const getScanTypeoption = scanTypesOptions.find(item => item.text.toLowerCase() === payload.text.toLowerCase())
        if(getScanTypeoption)
        {
          payload.id = getScanTypeoption.id
        }
        if (isChild) {
            onSave(payload, processID, [], false, process.key)
        } else {
            onSave(payload, processID)
        }
        resetFields()
        hideModal()
        // notification.success({
        //     message: 'Saved barcode item changes.'
        // })
    }

    const processTextError = isFieldTouched('text') && getFieldError('text')

    const onSearchField = debounce(value => {
        ZenSmartAPI.get(getAllQuiz(value)).then(res => {
            if(res?.data.length > 0) { setQuizFieldsValue(res.data) }
        })
      }, 600)
    return (
        <Form layout="vertical" onSubmit={handleSubmit}>
            <StyledFormItem
                label="Name"
                validateStatus={processTextError ? 'error' : ''}
                help={processTextError || ''}
            >
                {getFieldDecorator('text', {
                    initialValue: process && process.text,
                    rules: processTextRules
                })(
                    <AutoComplete
                        options={scanTypesOptions}
                        filterOption={true}
                    >
                        {alphabeticalData(scanTypesOptions, "text").map(({ id, text }) => {
                            return text.split(" ").join("-").toLowerCase() === "match-in" || text.split(" ").join("-").toLowerCase() === "pre-dispatch" ||
                                text.split(" ").join("-").toLowerCase() === "dispatch" ? null : <Option key={id} value={text} style={{ textTransform: "capitalize" }}>{text}</Option>
                        })}
                    </AutoComplete>
                )}
            </StyledFormItem>

            <StyledFormItem
                label="Wait Threshold"
                validateStatus={processTextError ? 'error' : ''}
                help={processTextError || ''}
            >
                {getFieldDecorator('wait_threshold', {
                    initialValue: process && process.wait_threshold,
                })(<Number min={0} placeholder="Wait Threshold" />)}
            </StyledFormItem>

            <StyledFormItem
                validateStatus={processTextError ? 'error' : ''}
                help={processTextError || ''}>
                {getFieldDecorator('enable_gamification', {
                    initialValue: process?.enable_gamification ?? false,
                })(<Checkbox checked={getFieldsValue().enable_gamification} >Enable Gamification</Checkbox>)}
        </StyledFormItem>

        {getFieldsValue().enable_gamification === true && (
          <>
          <StyledFormItem
                label="Select Quiz Key"
                validateStatus={processTextError ? 'error' : ''}
                help={processTextError || ''}
            >
                {getFieldDecorator('quiz_id', {
                    initialValue: process?.quiz_id ?? '',
                })(<Select 
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select Quiz Key"
                  optionFilterProp='children'
                  onSearch={onSearchField}
                >
                    { quizFieldValues.length > 0 ? quizFieldValues.map((item) => (
                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)) : gamificationQuiz && gamificationQuiz.map(item => (
                          <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
                    </Select>)}
            </StyledFormItem>
          </>)}

            <ButtonContainer>
                <Button onClick={hideModal}>Cancel</Button>

                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={hasErrors(getFieldsError())}
                >
                    Save Changes
                </Button>
            </ButtonContainer>
        </Form>
    )
}

const AutomatedStageForm = Form.create({ name: 'Automated Stage Form' })(EditForm)

function AutomatedStageModal(props) {
    const { process, onSave, processID, isChild, isEdit,scanTypesOptions, gamificationQuiz, ...modal } = props
    return (
        <Modal {...modal}>
            <AutomatedStageForm
                process={process}
                gamificationQuiz={gamificationQuiz}
                onSave={onSave}
                processID={processID}
                isChild={isChild}
                isEdit={isEdit}
                hideModal={modal.onCancel}
                scanTypesOptions={scanTypesOptions}
            />
        </Modal>
    )
}

export default AutomatedStageModal