import React from 'react'
import styled from 'styled-components'
import { Modal, Form, Input, Button, notification } from 'antd'

const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`
const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`
const processTextRules = [
  {
    required: true,
    message: 'Cannot be blank'
  },
  {
    max: 23,
    message: 'Must be less than 23 characters'
  }
]

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function EditForm(props) {
  const {
    form,
    process,
    onSave,
    processID,
    hideModal
  } = props

  const {
    getFieldDecorator,
    isFieldTouched,
    getFieldError,
    getFieldsError,
    getFieldsValue
  } = form

  const handleSubmit = e => {
    e.preventDefault()
    onSave(getFieldsValue(), processID)
    hideModal()
    notification.success({
      message: 'Saved new stage item name.'
    })
  }

  const processTextError = isFieldTouched('text') && getFieldError('text')

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <StyledFormItem
        label="Name"
        validateStatus={processTextError ? 'error' : ''}
        help={processTextError || ''}
      >
        {getFieldDecorator('text', {
          initialValue: process && process.text,
          rules: processTextRules
        })(<Input placeholder="Stage Item Name" />)}
      </StyledFormItem>

      <ButtonContainer>
        <Button onClick={hideModal}>Cancel</Button>

        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
        >
          Save Changes
        </Button>
      </ButtonContainer>
    </Form>
  )
}

const ProcessItemForm = Form.create({ name: 'Process Item Form' })(EditForm)

function ProcessItemTextModal(props) {
  const { process, onSave, processID, ...modal } = props
  return (
    <Modal {...modal}>
      <ProcessItemForm
        process={process}
        onSave={onSave}
        processID={processID}
        hideModal={modal.onCancel}
      />
    </Modal>
  )
}

export default ProcessItemTextModal