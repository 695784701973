import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table } from 'antd'


const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`

const TableStyling = createGlobalStyle`
.time-table-row-select > .ant-spin-nested-loading > .ant-spin-container > div > .ant-table-content > .ant-table-body > table > tbody > tr:hover > td {
    background-color: unset;
}
.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
}

.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    background-color : white;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
    background-color: #f1f3ff !important;
}

.ant-table-thead > tr:first-child > th:last-child {
    background-color : white !important ;
}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

.ant-table-thead > tr:first-child > th:first-child {
    background-color : white !important;
}
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    border : 1px solid #f1f3ff;
}
`
const TableData = (props) => {

    const [data, setData] = useState([]);
    const { itemData, loading } = props
    const columns = [

        {
            title: '',
            width: 230,
            children: [
                {
                    title: '',
                    dataIndex: 'vendor',
                    key: 'vendor',
                    width: 150,
                    render(value, row, index) {

                        const rowValue = <div>
                            <p>{value.vendor_name}</p>
                            <p style={{ color: value.last_transaction_date_color }}>{value.last_transaction_date}</p>
                        </div>
                        return rowStyle(rowValue, index, true)
                    },

                },
                {
                    title: 'LINES',
                    dataIndex: 'total_lines',
                    key: 'total_lines',
                    width: 80,
                    render(value, row, index) {
                        return rowStyle(value, index)
                    },
                },
            ],
        },
        {
            title: 'CREATED',
            width: 160,
            children: [
                {
                    title: 'PASS',
                    dataIndex: 'created_pass',
                    key: 'created_pass',
                    width: 80,
                    render(value, row, index) {
                        return rowStyle(value, index)
                    },
                },
                {
                    title: 'FAIL',
                    dataIndex: 'created_fail',
                    key: 'created_fail',
                    width: 80,
                    render(value, row, index) {
                        return rowStyle(value, index)
                    },
                },
            ],
        },
        {
            title: 'ARCHIVE',
            width: 160,
            children: [
                {
                    title: 'PASS',
                    dataIndex: 'archive_pass',
                    key: 'archive_pass',
                    width: 80,
                    render(value, row, index) {
                        return rowStyle(value, index)
                    },
                },
                {
                    title: 'FAIL',
                    dataIndex: 'archive_fail',
                    key: 'archive_fail',
                    width: 80,
                    render(value, row, index) {
                        return rowStyle(value, index)
                    },
                },
            ],

        },
        {
            title: 'PAUSE',
            width: 80,
            children: [
                {
                    title: 'ON HOLD',
                    dataIndex: 'pause',
                    key: 'pause',
                    width: 80,
                    render(value, row, index) {
                        return rowStyle(value, index)
                    },
                },
            ],
        },
        {
            title: '',
            width: 160,
            children: [
                {
                    title: 'WAITING FOR PARTNERS',
                    dataIndex: 'queued',
                    key: 'queued',
                    width: 80,
                    render: (value, row, index) => {
                        const obj = {
                            children: value,
                            props: { style: { textAlign: "center" } },
                        };
                        if (index === 0) {
                            obj.props.rowSpan = data.length;
                        }
                        else {
                            obj.props.rowSpan = 0;
                        }
                        return obj;
                    },
                },
                {
                    title: 'BATCHED & READY',
                    dataIndex: 'batched',
                    key: 'batched',
                    width: 80,
                    render: (value, row, index) => {
                        const obj = {
                            children: <div>{value.batches} Batches <br /> {value.lines} Lines</div>,
                            props: { style: { textAlign: "center" } },
                        };
                        if (index === 0) {
                            obj.props.rowSpan = data.length;
                        }
                        else {
                            obj.props.rowSpan = 0;
                        }
                        return obj;
                    },
                },
            ],
        },

    ];

    const rowStyle = (value, index, boldText = false) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (odd) {
            obj.props.style = {}
        }

        if (boldText) {
            obj.props.style.fontWeight = "bold"
        }

        return obj
    }


    useEffect(() => {

        if (itemData) {

            const itemObj = itemData.map(data => {
                const obj = {
                    vendor: data.vendor,
                    total_lines: data.total_lines,
                    created_pass: data.lines_created.pass,
                    created_fail: data.lines_created.fail,
                    archive_pass: data.archived.pass,
                    archive_fail: data.archived.fail,
                    pause: data.paused,
                    queued: `${data.queued} Lines`,
                    batched: { batches: data.batched.batches, lines: data.batched.lines }

                }
                return obj;
            })

            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                />
            </StyleTable>
        </>
    )
}

export default TableData