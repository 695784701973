import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Modal, notification, Row } from 'antd'
import * as _ from 'lodash'
import { createGlobalStyle } from 'styled-components'
import { Label } from '../../../components/zensmart-design-system'
import { ZenSmartAPI } from '../../../utils'
import { getStationScanType } from 'utils/apiRoutes'
import { removeStationScanType } from '../../../utils/apiRoutes';

const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}

.ant-select-lg && path {
    color : blue;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder  {
    color : blue;
}
.ant-select-lg .ant-select-selection__rendered {
    color : blue;
}
`

export default function ScanTypeModal ({
  selectedStation,
  visible,
  onClose,
  allScanTypes
}) {
  const [stationScanTypes, setStationScanTypes] = useState([])
  const [currentScanTypeOperation, setCurrentScanTypeOperation] = useState({
    loading: false,
    id: ''
  })

  const displayError = (error, customMessage) => {
    const { response } = error
    notification.error({
      message: _.get(response, 'data.message', customMessage)
    })
  }

  const fetchStationScanTypes = () => {
    setCurrentScanTypeOperation({ loading: true, id: 'all' })
    ZenSmartAPI.get(getStationScanType(selectedStation.id))
      .then(response => {
        setStationScanTypes(response.data.data)
      })
      .catch(error => {
        displayError(
          error,
          `Something went wrong while fetching scan types for ${selectedStation.name}`
        )
      })
      .finally(() => {
        setCurrentScanTypeOperation({ loading: false, id: '' })
      })
  }

  const handleUpdateScan = async (checked, scan) => {
    try {
      const action = checked ? 'add' : 'remove'
      console.log({ action, scan, checked })
      setCurrentScanTypeOperation(oldState => ({
        ...oldState,
        loading: true,
        id: scan.id
      }))
      if (action === 'add') {
        const requestModel = {
          scan_type_id: scan.id
        }
        const request = await ZenSmartAPI.post(
          getStationScanType(selectedStation.id),
          requestModel
        )
        setStationScanTypes(request.data.data)
        console.log({ request: request.data.data })
      } else if (action === 'remove') {
        const request = await ZenSmartAPI.delete(
          removeStationScanType(selectedStation.id, scan.id)
        )
        setStationScanTypes(request.data.data)
      }
    } catch (error) {
      displayError(
        error,
        `Something went wrong while updating scan ${scan.name}.`
      )
    } finally {
      setCurrentScanTypeOperation(oldState => ({
        ...oldState,
        loading: false,
        id: ''
      }))
    }
  }

  useEffect(() => {
    if (selectedStation?.id) {
      fetchStationScanTypes()
    }
  }, [selectedStation, visible])

  const renderScanTypes = () => {
    console.log({ stationScanTypes, allScanTypes })
    return (
      <div style={{ marginTop: '16px' }}>
        <Row>
          {allScanTypes
          .sort((a, b) => a.text.localeCompare(b.text))
          .map(scan => (
            <Col
              style={{
                paddingRight: '5px',
                textTransform: 'uppercase',
                marginBottom: '20px'
              }}
              key={scan.id}
              span={8}
            >
              <Checkbox
                checked={_.find(stationScanTypes, ['text', scan.text])}
                onChange={e => handleUpdateScan(e.target.checked, scan)}
                disabled={
                  currentScanTypeOperation.loading &&
                  (currentScanTypeOperation.id === scan.id ||
                    currentScanTypeOperation.id === 'all')
                }
              >
                {scan.text}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </div>
    )
  }

  if (selectedStation === null) {
    return null
  }

  return (
    <>
      <ModalStyle />
      <Modal
        visible={visible}
        destroyOnClose={true}
        width={900}
        onCancel={() => {
          setStationScanTypes([])
          onClose()
        }}
        bodyStyle={{
          minHeight: 400
        }}
        footer={null}
      >
        <Row type='flex' justify='center' style={{ padding: '10 20' }}>
          <Label status='blue'>
            MANAGE SCAN TYPES FOR STATION {selectedStation.name}{' '}
          </Label>
        </Row>
        {renderScanTypes()}
      </Modal>
    </>
  )
}
