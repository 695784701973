import React, { useState } from "react";
import { Form, Select, Input } from "antd";
import { Modal, notification } from "antd";
import { ZenSmartAPI } from "utils";
import { faultFeedbackRoute } from "utils/apiRoutes";
import { ratings } from "../ratings";
import styled from "styled-components";
const { TextArea } = Input;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`;

function FaultFeedbackModal({
  fault,
  isVisible,
  closeModal,
  setFeedbackPosted,
  reload
}) {
  const [comments, setComments] = useState("");
  const [rating, setRating] = useState("");

  const sendFeedback = () => {
    ZenSmartAPI.post(faultFeedbackRoute, {
      fault_id: fault.id,
      feedback: comments,
      rating,
    })
      .then((res) => {
        setFeedbackPosted(true);
        notification.success({
          message: "Your feedback was posted successfully!",
        });
        reload(val => !val)
        closeModal(res.data);
      })
      .catch((res) => {
        notification.error({
          message: "Couldn't post your feedback.",
        });
      });
  };

  const handleRatingChange = (e) => {
    setRating(e);
  };

  return (
    <Modal
      title="Rate the Engineer's work"
      visible={isVisible}
      onOk={sendFeedback}
      onCancel={() => closeModal()}
    >
      <Form>
        <StyledFormItem label="Rating" name="rating">
          <Select
            value={rating ? rating : "Select rating"}
            onChange={handleRatingChange}
          >
            {ratings.map((rating) => (
              <Select.Option key={rating.value}>
                {rating.description}
              </Select.Option>
            ))}
          </Select>
        </StyledFormItem>
        <StyledFormItem label="Comments" name="comments">
          <TextArea
            placeholder="Enter description"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </StyledFormItem>
      </Form>
    </Modal>
  );
}

export default FaultFeedbackModal;
