import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    notification,
    Button,
    DatePicker,
    Input,
    Drawer,
    Checkbox,
    Spin,
    Modal
} from "antd";
import moment from "moment"
import {
    updateBusinessHoursRoute
} from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'
import { createGlobalStyle } from 'styled-components'



const { TextArea } = Input;

const HeadingStyle = createGlobalStyle`

.heading:hover{
    cursor: pointer;
    text-decoration: underline
}
  `

const PublicHolidaysModal = (props) => {

    const { showModal, setShowModals, itemData, fetchData } = props
    const [loading, setLoading] = useState(false)
    const [holidaysData, setHolidaysData] = useState([])
    const [description, setDescription] = useState("")
    const [editHoliday, setEditHoliday] = useState({})
    const [addHolidayData, setAddHolidayData] = useState({})
    const addHolidayInitialData = {
        name: null,
        description: null,
        from: moment(moment(), 'YYYY-MM-DD'),
        to: moment(moment(), 'YYYY-MM-DD'),
        recurring: false

    }
    const cancelEdit = () => {
        setShowModals({ ...showModal, public_holidays: false })
        setHolidaysData([])
        setAddHolidayData({})

    }
    const addHoliday = () => {
        setAddHolidayData(addHolidayInitialData)
    }
    const deleteHoliday = (index) => {

        const payload = holidaysData
        payload.splice(index, 1)
        Modal.confirm({
            'content': "Are you sure you want to delete this holiday?",
            onOk: () => {
                setLoading(true)
                ZenSmartAPI.put(updateBusinessHoursRoute(1), { holidays: { holidays: payload } })
                    .then((res) => {
                        setLoading(false)
                        notification.success({ message: "Delete holiday successful!" })

                        fetchData()
                    })
                    .catch((error) => {
                        setLoading(false);
                        if (error.message) {
                            notification.error({ message: error.message })
                        }
                        else {
                            notification.error({ message: "Failed updating public holidays!" })
                        }
                    })
            },
        });

    }

    const savePublicHolidays = (name, index, ifAddHoliday) => {

        const holidayDataObject = ifAddHoliday ? addHolidayData : editHoliday[name]
        if (holidayDataObject.name === null || holidayDataObject.name === "") {
            notification.error({ message: "Holiday name required!" })
            return
        }

        const findSameName = holidaysData.some((data, i) => data.name === holidayDataObject.name && index !== i)

        if (findSameName) {
            notification.error({ message: "Holiday name must be unique!" })
            return
        }
        setLoading(true)
        const payload = holidaysData

        if (index === "addHoliday") {
            payload.push(holidayDataObject)
        }
        else {
            payload[index] = holidayDataObject
        }

        ZenSmartAPI.put(updateBusinessHoursRoute(1), { holidays: { holidays: payload } })
            .then((res) => {
                setLoading(false)
                var messageText
                if (ifAddHoliday) {
                    setAddHolidayData({})
                    messageText = "Create new holiday successful!"
                }
                else {
                    setEditHoliday({})
                    messageText = "Update holiday Successful!"
                }
                notification.success({ message: messageText })
                fetchData()
            })
            .catch((error) => {
                setLoading(false);
                if (error.message) {
                    notification.error({ message: error.message })
                }
                else {
                    notification.error({ message: "Failed updating public holidays!" })
                }
            })
    }

    const setHolidayFunction = (e, data, name, ifAddHoliday) => {
        if (ifAddHoliday) {
            setAddHolidayData({ ...addHolidayData, [name]: e.target.value })
        }
        else {

            setEditHoliday({ ...editHoliday, [data.name]: { ...editHoliday[data.name], [name]: e.target.value } })
        }
    }

    const changeDate = (timeString, data, name, ifAddHoliday) => {
        if (ifAddHoliday) {
            setAddHolidayData({ ...addHolidayData, [name]: timeString })
        }
        else {
            setEditHoliday({ ...editHoliday, [data.name]: { ...editHoliday[data.name], [name]: timeString } })
        }
    }
    const setRecurring = (e, data, name, ifAddHoliday) => {
        if (ifAddHoliday) {
            if (e.target.checked) {
                setAddHolidayData({ ...addHolidayData, [name]: e.target.checked, to: null })

            }
            else {
                setAddHolidayData({ ...addHolidayData, [name]: e.target.checked })
            }
        }
        else {
            if (e.target.checked) {
                setEditHoliday({ ...editHoliday, [data.name]: { ...editHoliday[data.name], [name]: e.target.checked, to: null } })
            }
            else {
                setEditHoliday({ ...editHoliday, [data.name]: { ...editHoliday[data.name], [name]: e.target.checked } })
            }
        }
    }

    const renderInputFields = (data, index, ifAddHoliday = false, name) => {

        const dateToValue = !ifAddHoliday && editHoliday[name] ? moment(editHoliday[name].to, 'YYYY-MM-DD') : moment(data.to, 'YYYY-MM-DD')
        console.log(`dateToValue`, dateToValue)
        return (
            <>
                <Col span={24} style={{ padding: 5 }}>
                    <Row type="flex" align="middle">
                        <Col span={6} style={{ padding: 5 }}>
                            <p style={{ fontWeight: "bold" }}>
                                Holiday Name :
                            </p>
                        </Col>
                        <Col span={18} style={{ padding: 5 }}>
                            <Input style={{ width: "100%" }} placeholder="Add Holiday Name" defaultValue={data.name} onChange={(e) => setHolidayFunction(e, data, "name", ifAddHoliday)} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ padding: 5 }}>
                    <Row type="flex" align="middle">
                        <Col span={6} style={{ padding: 5 }}>
                            <p style={{ fontWeight: "bold" }}>
                                Description :
                            </p>
                        </Col>
                        <Col span={18} style={{ padding: 5 }}>
                            <TextArea defaultValue={data.description} placeholder="Add Description" onChange={(e) => setHolidayFunction(e, data, "description", ifAddHoliday)} autosize />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ padding: 5 }}>
                    <Row type="flex" align="middle">
                        <Col span={6} style={{ padding: 5 }}>
                            <p style={{ fontWeight: "bold" }}>
                                From :
                            </p>
                        </Col>
                        <Col span={18} style={{ padding: 5 }}>
                            <DatePicker style={{ width: '100%' }} defaultValue={moment(data.from, 'YYYY-MM-DD')} onChange={(time, timestring) => changeDate(timestring, data, "from", ifAddHoliday)} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ padding: 5 }}>
                    <Row type="flex" align="middle">

                        <Col span={6} style={{ padding: 5 }}>
                            <p style={{ fontWeight: "bold" }}>
                                To :
                            </p>
                        </Col>
                        <Col span={18} style={{ padding: 5 }}>
                            <DatePicker style={{ width: '100%' }} value={dateToValue.isValid() ? dateToValue : null
                            } disabled={!ifAddHoliday && editHoliday[name] ? editHoliday[name].recurring :
                                data.recurring} onChange={(time, timestring) => changeDate(timestring, data, "to", ifAddHoliday)} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ padding: 5 }}>
                    <Row type="flex" align="middle">
                        <Col span={7} style={{ padding: 5 }}>
                            <p style={{ fontWeight: "bold" }}>
                                Recurring Holiday :
                            </p>
                        </Col>
                        <Col span={17} style={{ padding: 5 }}>
                            <Checkbox defaultChecked={data.recurring} onChange={(e => setRecurring(e, data, "recurring", ifAddHoliday))} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ padding: 5 }}>
                    <Row type="flex" align="middle" justify="end">
                        <Col span={6} style={{ padding: 5 }}>
                            <Button type="primary" style={{ width: "100%" }} ghost onClick={() => ifAddHoliday ? setAddHolidayData({}) : setEditHoliday({})}>
                                Cancel
                            </Button>
                        </Col>
                        <Col span={7} style={{ padding: 5 }}>
                            <Button type="primary" style={{ width: "100%" }} loading={loading} onClick={() => savePublicHolidays(data.name, index, ifAddHoliday)}>
                                Save Changes
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }


    const renderFields = (data, index) => {
        return (
            <Row type="flex" align="middle">
                <Col span={20} style={{ padding: 5 }}>
                    <h1 style={{ fontWeight: "bold", textTransform: "capitalize" }} className="heading"
                        onClick={() => !editHoliday[data.name] &&
                            setEditHoliday({ [data.name]: data })}>{data.name} ({moment(data.from).format('MMMM DD YYYY')}{data.to && data.from !== data.to ? ` - ${moment(data.to).format('MMMM DD YYYY')}` : null})</h1>
                </Col>
                <Col span={4} style={{ padding: 5 }}>
                    <Button type='danger' onClick={() => deleteHoliday(index)}>Delete</Button>
                </Col>
                {editHoliday && editHoliday[data.name] &&
                    renderInputFields(data, index, false, data.name)
                }
            </Row >
        )
    }

    useEffect(() => {
        if (itemData && itemData.length > 0 && showModal.public_holidays) {
            const holidays = itemData.find(data => data.param === "public_holidays")
            if (holidays && holidays.val) {
                setHolidaysData(holidays.val.holidays)
                setDescription(holidays.description)
            }
        }
    }, [itemData, showModal]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (holidaysData.length === 0 && showModal.public_holidays) {
            addHoliday()
        }
    }, [holidaysData]) // eslint-disable-line react-hooks/exhaustive-deps



    return (
        <Drawer visible={showModal.public_holidays} title="Public Holidays" width={600} onClose={cancelEdit}

        >
            <HeadingStyle />
            <Spin tip="Saving..." spinning={loading}>
                <p>{description}</p>
                <br />
                <Row type="flex" align="middle" justify="end" >
                    <Col span={6} style={{ padding: 10 }}>
                        <Button style={{ width: "100%" }} disabled={addHolidayData && Object.keys(addHolidayData).length > 0} type="primary" onClick={addHoliday}>Add Holiday</Button>
                    </Col>
                    <div>
                        {addHolidayData && Object.keys(addHolidayData).length > 0 &&
                            renderInputFields(addHolidayData, "addHoliday", true)
                        }
                    </div>
                </Row>

                <div >
                    {holidaysData && holidaysData.length > 0 &&
                        holidaysData.map((data, index) => {
                            if (data)
                                return renderFields(data, index)
                        })
                    }
                </div>
            </Spin>
        </Drawer >
    );
}

export default PublicHolidaysModal;
