import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table } from 'antd'
import _ from 'lodash';


const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : #f1f3ff;
    font-size : 12px;

}

tr.stuck-sent th, tr.stuck-sent td {
    background: #FF8000 !important;
}

tr.failed-sent th, tr.failed-sent td {
    background: #fb4b4b !important;
}


tr.selected-sent th, tr.selected-sent td {
    background: rgba(11, 107, 242, 0.5) !important;
`
const SentTableData = (props) => {

    const { itemData, loading, columns, selectedSent, setSelectedSent } = props
    const [lastSentShiftSelected, setLastSentShiftSelected] = useState(null)
    const columnLength = columns.length > 7 ? columns.length * 100 : 700
    const selectSent = (record, event) => {

        if (event.shiftKey && lastSentShiftSelected) {
            const lastSentShiftSelectedIndex = itemData.findIndex((data) => data.id === lastSentShiftSelected.id)
            const recordIndex = itemData.findIndex((item) => item.id === record.id)
            const ar2 = lastSentShiftSelectedIndex < recordIndex ? itemData.slice(lastSentShiftSelectedIndex, recordIndex + 1) : itemData.slice(recordIndex, lastSentShiftSelectedIndex + 1);

            const selectSent = ar2.filter(filterItem => filterItem.id !== lastSentShiftSelected.id).map(item => {
                var element = document.getElementsByClassName(item.id)[0];
                element.classList.add("selected-sent");
                return item.id;
            })
            if (selectedSent.includes(lastSentShiftSelected.id)) {
                setSelectedSent(_.uniq(selectedSent.concat(selectSent)), true)
            }
            else {
                setSelectedSent(selectedSent.filter(item => !selectSent.includes(item)))
            }
            return
        }
        var element = document.getElementsByClassName(record.id)[0];
        if (selectedSent.includes(record.id)) {
            setSelectedSent(selectedSent.filter(data => data !== record.id))
            element.classList.add("selected-sent");
        }
        else {
            setSelectedSent([...selectedSent, record.id])
            element.classList.add("selected-sent");
        }
        setLastSentShiftSelected(record)

    }

    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={itemData}
                    bordered
                    size="middle"
                    pagination={false}
                    scroll={{ y: "50vh", x: columnLength }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => selectSent(record, event), // click row

                        };
                    }}
                    id={"sent-table"}
                    rowClassName={(record, index) => record.id}
                />
            </StyleTable>
        </>
    )
}

export default SentTableData