import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Input, Button, notification, Modal } from 'antd'
import { ZenSmartAPI } from 'utils'
import { pressReleaseRouteFilter, pressReleaseSentReset, pressReleaseSentResetAll } from "utils/apiRoutes"
import SentTableData from './SentTableData'
import FailModal from './FailModal'
import axios from 'axios';
import { getQaFailFormBatchRoute, getQaFailFormBatchRoutePost } from "utils/apiRoutes"
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
import { alphabeticalData } from 'utils/sortData';
import { applySelectedCss, getHeaders, searchOperation, searchOperationWithoutDelay, applyFails, sortingData } from './PressReleaseHelperFunctions'
import { errorNoticationHandler } from 'utils/errorMessageHandler';


const { Option } = Select;
const SentPanel = (props) => {

    const { loading, setLoading, fetchData, itemData, setItemData, originalData, setOriginalData, selectedSent, setSelectedSent, labels, printers, filters, selectedPrinterOption,
        setSelectedPrinterOption, getPrintersToBeSend, buttonLoading, sentFilterData, sentColumnFilterDataObject, setSentColumnFilterDataObject,
        setSentFilterData, renderFilterColumnsData, sentPanelInputValue, ascend, setAscend, pressRelease, pressReleaseLimit, user, setSentBlocksCount } = props
    const [columns, setColumns] = useState([])
    const [requestCancel, setRequestCancel] = useState({ cancel: undefined })
    const [sortColumnsClasses, setSortColumnClasses] = useState(null)
    const [searchFilterValue, setSearchFilterValue] = useState("")
    var getTable = document.getElementById(`sent-table`)
    if (getTable) {
        const getTr = getTable.getElementsByTagName(`tr`)[0]
        if (getTr) {
            const getSortWidth = getTr.getElementsByTagName(`th`);
            if (Array.from(getSortWidth).length > 0 && sortColumnsClasses === null) {
                const filterWidth = Array.from(getSortWidth).map(item => item.clientWidth)
                setSortColumnClasses(filterWidth)
            }
        }
    }
    const { details: { page_settings, permissions } } = user
    const [formValues, setFormValues] = useState(null)
    const [showModal, setShowModal] = useState(false)

    const fetchSentData = (value) => {
        setLoading(true)
        setSelectedPrinterOption(value);
        const CancelToken = axios.CancelToken;

        if (requestCancel.cancel !== undefined) {
            requestCancel.cancel("cancel request");
        }

        ZenSmartAPI.post(pressReleaseRouteFilter, { printer: value, sent_data_filter: filters, limit: pressReleaseLimit, setting_id: pressRelease?.id }, {
            cancelToken: new CancelToken(function executor(c) {
                setRequestCancel({ ...requestCancel, cancel: c })
            }),
        })
        .then((res) => {
            setLoading(false)

            if (pressRelease.sent_sorting) {
                setItemData(sortingData(res.data.data, pressRelease.sent_sorting.column_name, false, res.data.labels, ascend, setAscend, null, null, !pressRelease.sent_sorting.if_ascend))
            }
            else {
                setItemData(res.data.data)
            }
            setSentBlocksCount(res.data.data_count);
            setOriginalData(res.data.data)
            setSentFilterData(renderFilterColumnsData(labels, res.data.data))
            setSelectedSent([])
            setSentColumnFilterDataObject({})
        })
        .catch((error) => {
            if (error.message && error.message === "Cannot read property 'status' of undefined") {
                return;
            }
            notification.error({ message: "Failed fetching data!" })
            setLoading(false)
        })
    }
    const setColumnDataFilterFunction = (value, property) => {
        setSentColumnFilterDataObject({ ...sentColumnFilterDataObject, [property]: value })
    }

    const setColumnFiltersLogic = (searchInputValue) => {
        var arrayValue = []
        if (searchInputValue !== "") {
            arrayValue = searchOperationWithoutDelay(searchInputValue, originalData, originalData, setItemData, setSearchFilterValue, setColumnFiltersLogic,
                sentColumnFilterDataObject)
        }
        else {
            arrayValue = originalData
        }
        Object.keys(sentColumnFilterDataObject).map(item => {
            if (sentColumnFilterDataObject[item] !== "") {
                arrayValue = arrayValue.filter(data => {
                    const dataItem = moment(data[item]).isValid() && (item === 'imposed_datetime' || item === 'sent_datetime') ? moment(data[item]).format('MMM DD ddd hh:mm').toString()
                                                                                                                               : item === "fails" && data[item] === 0 ? "False" : item === "fails" && data[item] > 0 ? "True" : data[item]
                    return dataItem === sentColumnFilterDataObject[item]
                })
            }
            return item
        })
        setItemData(arrayValue)
    }

    const renderFilterColumnDatas = (data) => {
        const returnValue = Object.keys(sentFilterData).map((item, key) => {
            const getTableHeaderWidth = sortColumnsClasses[key];
            if (getTableHeaderWidth && !pressRelease.hide_columns.includes(item)) {
                return <div style={{ textAlign: "center", padding: "10px 2px 15px 2px", width: getTableHeaderWidth }}>
                    <Select defaultValue={""} style={{ marginTop: 10, width: '100%' }}
                            optionFilterProp="children"
                            showSearch
                            dropdownMatchSelectWidth={false}
                            onChange={(e) => setColumnDataFilterFunction(e, item)}
                    >
                        <Option value={""} style={{ fontWeight: "bold" }}>{sentFilterData[item].label}</Option>
                        {sentFilterData[item].date_data ? alphabeticalData(sentFilterData[item].date_data).map(data =>
                                                            <Option value={moment(data).format('MMM DD ddd hh:mm').toString()}>{moment(data).format('MMM DD ddd hh:mm').toString()}</Option>
                                                        )
                                                        : alphabeticalData(sentFilterData[item].data).map(data =>
                                <Option value={data}>{data}</Option>
                            )}
                    </Select>

                </div >
            }
            return null
        })
        return <div style={{ position: "relative", overflowX: "auto", width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
                {returnValue}
            </div>
        </div>
    }
    useEffect(() => {
        if (Object.keys(labels).length > 0) {
            const columnData = [];
            Object.keys(labels).map(data => {
                if (!pressRelease.hide_columns.includes(data)) {

                    columnData.push({
                        title: getHeaders(data, labels[data].label.toUpperCase(), false, itemData, labels, ascend, setAscend, setItemData,user.details.id, pressRelease.id, null, "sent"),
                        dataIndex: data,
                        key: data,
                        width: data === "id" ? "160px" : null,

                        render: (value, row) => {
                            if (data === "id") {
                                applySelectedCss(value, 'selected-sent', selectedSent)
                                if (row.fails > 0) {
                                    applyFails(value, 'failed-sent')
                                }
                                if (row.stuck === "TRUE") {
                                    applyFails(value, 'stuck-sent')
                                }
                                if (row.sent_datetime) {
                                    var duration = moment.duration(moment(new Date()).diff(moment(row.sent_datetime)));
                                    var hours = duration.asHours();
                                    if (hours > 12) {
                                        applyFails(value, 'failed-sent')

                                    }

                                }
                            }
                            if (data === "fails") {
                                return <div>{value > 0 ? <ExclamationCircleOutlined
                                    style={{
                                        color: 'white',
                                        backgroundColor: '#E84A50',
                                        fontSize: 20,
                                        borderRadius: '50%',
                                        width: 20,
                                        height: 20
                                    }}
                                /> : "-"}</div>
                            }
                            else if (data === 'imposed_datetime' || data === 'sent_datetime') {
                                return moment(value).format('MMM DD ddd hh:mm A').toString()
                            }
                            else if (data === "linear_length") {
                                return <div>{value !== null ? parseFloat(value).toFixed(2) : "-"}</div>
                            }
                            else {
                                return <div>{value !== null ? value : "-"}</div>
                            }
                        }
                    })
                }
                return data
            })
            setColumns(columnData)
        }

    }, [ascend, labels, itemData, selectedSent]) // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        setColumnFiltersLogic(searchFilterValue)

    }, [sentColumnFilterDataObject, originalData]) // eslint-disable-line react-hooks/exhaustive-deps

    const resetPressReleaseSent = () => {
        let payload = {
            printer: selectedPrinterOption,
            sent_filter: {
                where: pressRelease.sent.where
            }
        }

        setLoading(true)

        ZenSmartAPI.post(pressReleaseSentReset, payload)
        .then((res) => {
            setLoading(false)
            notification.success({message: "Submitted items are now reverted."})
            fetchData()
        }).catch((err) => {
            setLoading(false)
            errorNoticationHandler(err, "Cant Reset Data!")

        })
    }

    const resetAllPressReleaseSent = () => {
        let payload = {
            sent_filter: {
                where: pressRelease.sent.where
            }
        }

        setLoading(true)

        ZenSmartAPI.post(pressReleaseSentResetAll, payload)
        .then((res) => {
            setLoading(false)
            notification.success({message: "All submitted items in press release pages are now reverted."})
            fetchData()
        }).catch((err) => {
            setLoading(false)
            errorNoticationHandler(err, "Cant Reset All Data!")
        })
    }

    const fetchQaFailForm = () => {
        let payload = ''

        selectedSent.forEach(batch => payload = payload + `${batch};`)

        if (selectedSent.length > 0) {
            const route = getQaFailFormBatchRoute(payload.replace(/;$/, "").replace(/^;/, ""))
            ZenSmartAPI.get(route)
            .then((res) => {
                setLoading(false)
                if (res.data && res.data.page_definitions.sections[0].section_data.length > 0) {
                    setFormValues(res.data)
                    setShowModal(true)
                }
                else {
                    notification.error({ message: "No available input forms!" })
                }
            })
            .catch((res) => {
                setLoading(false)
                notification.error({ message: "Couldn't fetch the form." })
            })
        }
        else {
            notification.error({ message: "Please select a batch!" })
        }
    }

    const showFailButton = () => {
        const hasPermission = permissions.indexOf('allow-print-fail')
        const allowPrintFail = page_settings && page_settings['allow-print-fail']
        return hasPermission && allowPrintFail ? true : false
    }

    const showResetButton = () => {
        const permissionIndex = permissions.indexOf('batch-press-release-reset')

        return (permissionIndex !== -1)
    }

    const showResetAllButton = () => {
        const permissionIndex = permissions.indexOf('batch-press-release-reset-all')

        return (permissionIndex !== -1)
    }

    const hideModal = () => {
        setShowModal(false)
        setFormValues(null)
    }

    return (
        <>
            <FailModal
                selectedSent={selectedSent}
                setSelectedSent={setSelectedSent}
                formValues={formValues}
                showModal={showModal}
                hideModal={hideModal}
                fetchData={fetchData}
                getQaFailFormRoute={getQaFailFormBatchRoutePost}
            />

            <div style={{ padding: 5, minHeight: "75vh" }} className="panel-div">
                <Row type="flex" align="middle" style={{ padding: 10 }}>
                    <Col span={6} style={{ padding: 10 }}>
                        <Input.Search
                            type="text"
                            placeholder="Search"
                            disabled={loading}
                            onChange={(value) => searchOperation(value.target.value, originalData, originalData, setItemData, setSearchFilterValue, setColumnFiltersLogic, sentColumnFilterDataObject)}
                            loading={loading}
                            ref={sentPanelInputValue}
                        />
                    </Col>
                    {printers.length > 0 && selectedPrinterOption &&
                    <Col span={4} style={{ padding: 10 }}>
                        <Select style={{ width: "100%" }} defaultValue={selectedPrinterOption ? selectedPrinterOption : printers.length > 1 ? printers[0] : undefined} onChange={fetchSentData}>
                            <Option value={"all"} style={{ textTransform: "capitalize", fontWeight: "bold" }}>ALL</Option>
                            {printers.map(item => {
                                return <Option value={item} style={{ textTransform: "capitalize" }}>{item}</Option>
                            })}
                        </Select>
                    </Col>
                    }
                    <Col span={4} style={{ padding: 10 }}>
                        <Button type="danger" ghost style={{ width: "100%", display: (loading ? 'none' : 'block') }} disabled={buttonLoading || !showResetButton()} onClick={() => {
                            Modal.confirm({
                                'title': 'Warning',
                                'content': "This will revert back items from 'Sent To Print' back to 'Batched'. Do you want to proceed?",
                                onOk: () => {
                                    resetPressReleaseSent()
                                }
                            })
                        }}>Reset</Button>
                    </Col>

                    <Col span={4} style={{ padding: 10 }}>
                        <Button type="danger" ghost style={{ width: "100%", display: (loading ? 'none' : 'block') }} disabled={buttonLoading || !showResetAllButton()} onClick={() => {
                            Modal.confirm({
                                'title': 'Warning',
                                'content': "This will revert back items from 'Sent To Print' back to 'Batched' from all press release pages. Do you want to proceed?",
                                onOk: () => {
                                    resetAllPressReleaseSent()
                                }
                            })
                        }}>Reset All</Button>
                    </Col>

                    {itemData.length > 0 && <Col span={6} style={{ padding: 10 }}>
                        <Row type="flex" align="middle" justify="end">

                            <Col span="12" style={{ padding: 5 }}><Button type="danger" ghost style={{ width: "100%" }} disabled={!showFailButton()} onClick={() => fetchQaFailForm()}>Fail</Button> </Col>
                            <Col span="12" style={{ padding: 5 }}> <Button type="primary" disabled={page_settings && !page_settings['allow-print-resend']} loading={buttonLoading} style={{ width: "100%",padding:"2px" }} onClick={() => getPrintersToBeSend("sent", true)}>Re-Send</Button> </Col>

                        </Row>
                        {/* <Button type="primary" style={{ width: "100%" }} loading={buttonLoading} onClick={() => getPrintersToBeSend("sent")}>Re-Send</Button> */}
                    </Col>}

                </Row>
                <SentTableData
                    loading={loading}
                    setLoading={setLoading}
                    columns={columns}
                    itemData={itemData}
                    selectedSent={selectedSent}
                    setSelectedSent={setSelectedSent}
                />
                {columns.length > 0 && sortColumnsClasses && !loading &&
                <div >
                    {renderFilterColumnDatas(itemData)}
                </div>
                }
            </div>
        </>
    )
}

export default SentPanel

