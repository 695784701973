import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Modal, notification, Row, Col } from 'antd';
import { useState, useEffect } from 'react';
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { createGlobalStyle } from 'styled-components';
import Cookies from 'js-cookie';

const ModalStyle = createGlobalStyle`
.ant-modal-close-x {
    display : none
}
`
const HandleInactive = (props) => {

    const [seconds, setSeconds] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const { logout, userData } = props
    const handleOnIdle = event => {
        if (showModal == false) {
            setSeconds(59)
            setShowModal(true)
        }

    }

    const handleOnAction = (e) => {
        if (parseInt(Cookies.get('SESSION_TIMEOUT')) !== 0) {
            Cookies.set('SESSION_TIMEOUT', 0, { expires: 1 });
        }
    }
    
    let timeoutTimer = userData.details.inactive_login_expiration_duration ? 1000 * 60 * userData.details.inactive_login_expiration_duration : 1000 * 60 * 5
    if (timeoutTimer.toString().length >= 0) {
        timeoutTimer = timeoutTimer.toString().substr(0, timeoutTimer.toString().length - 1)
    }

    const { getLastActiveTime } = useIdleTimer({
        timeout: timeoutTimer,
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        debounce: 500
    })

    const closeModal = () => {
        setShowModal(false)
        setSeconds(null)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds != null) {
                if (seconds === 0) {
                    logout();
                    Cookies.remove('SESSION_TIMEOUT');

                    notification.error({
                        message: 'You automatically logged out!'
                    })
                }
                else {
                    const reduceSeconds = seconds - 1
                    setSeconds(reduceSeconds)
                }
            }

        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);
    return (
        <div>
            <ModalStyle />
            <Modal
                title="Inactive Detected"
                centered
                visible={showModal}
                onOk={() => closeModal()}
                onCancel={() => closeModal()}
                footer={null}
            >
                <div style={{ padding: "25px 0px" }}>
                    <Row type="flex" align="middle" justify="center">
                        <Col span={2}>
                            <ExclamationCircleOutlined style={{ fontSize: 30, color: "orange" }} />
                        </Col>
                        <Col span={20}>
                            <h1 style={{
                                textAlign: "center", fontSize: 20, fontFamily: 'Rubik,sans-serif'
                            }}>
                                Your session will expire in 0:{seconds < 10 && "0"}{seconds} seconds</h1>
                        </Col>
                    </Row>

                </div>
            </Modal>
        </div >
    )
}

export default HandleInactive