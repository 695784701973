import React, {useEffect, useState} from 'react';
import {AutoComplete, Tag} from 'antd';
import _ from 'lodash';
import {ZenSmartAPI} from 'utils';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import {modelDefinitionRoute} from 'utils/apiRoutes';
import {alphabeticalData} from 'utils/sortData';

const { Option } = AutoComplete;

const InputTag = styled.div`
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  &:hover{
    border: 1px solid #1890ff;
    transition:0.3s;
  }
  & .ant-input:focus,
  .ant-input:active {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
  & input {
    border: none;
    width: 100%;
  }
`
const TagStyle = styled(Tag)`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin: 2.5px;
  font-size: 13px;
  font-family: 'Rubik', sans-serif;

`
const UlTag = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
  & li {
    background: none;
    flex-grow: 1;
    padding: 0;
  }
`
const AutoCompleteField = styled(AutoComplete)`
  width : 100%;
  & ul
  {
      min-width: 300px !important
  }
`
const AutoCompleteBar = (props) => {

  const [result, setResult] = useState([]);
  const { placeholder, returnValue, prefix, restrict, limit, ignoredValues , hideNonDb, resource} = props
  const [tags, setTags] = useState([])
  const [fieldClear, setFieldClear] = useState(null)
  const ifPrefix = prefix ? `${prefix}.` : ""
  const strResource = resource ? `${resource.join(',')}` : null

  const addReturnValue = (value) => {
    const val = value.label;
    if (tags.find(tag => tag.label === val)) {
      return;
    }
    if (value.id) {
      setFieldClear(null)
      setResult([])
      setTags([...tags, value])
    }
  }

  const handleSearch = debounce(value => {
    ZenSmartAPI.get(modelDefinitionRoute(`${ifPrefix}${value}`,`${hideNonDb}`, `${strResource}`), {})
      .then((res) => {
        let result = [];
        if (!value || value.indexOf('@') >= 0) {
          result = [];
        } else {
          result = res.data.data;
        }
        if (ignoredValues && _.isArray(ignoredValues)) {
          result = result.filter(item => {
            if (ignoredValues.indexOf(item.path) !== -1) {
              return false;
            }
            return true;
          });
        }
        restrict ? setResult(result.filter(val => !tags.map(item => item.id).includes(val.id)).slice(0, restrict)) :
          setResult(result.filter(val => !tags.map(item => item.id).includes(val.id)));

      })
  }, 600);

  const fieldValue = value => {
    setFieldClear(value)
  };

  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags)
  }

  useEffect(() => {
    const tagsId = tags.map(item => item.id)
    setResult(result.filter(val => !tagsId.includes(val.id)))
    returnValue(tagsId, tags);
  }, [tags]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { value } = props;
    if (value) setTags(value)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InputTag >
      <UlTag >
        {tags.map((tag, i) => (
          <TagStyle key={i} closable onClose={() => removeTag(i)}>
            {tag.label}
          </TagStyle>
        ))}
        <li >
          <AutoCompleteField
            disabled={limit && tags.length >= limit}
            onSearch={(value) => {
              setFieldClear(value)
              handleSearch(value);
            }}
            placeholder={placeholder}
            autoFocus={true}
            notFoundContent={"No Data Found"}
            value={fieldClear ? fieldClear : ""}
          >
            {alphabeticalData(result, "label").map((data, key) => (
              <Option key={key} value={JSON.stringify({ id: data.id, label: data.label })} onClick={() => {
                addReturnValue({ label: ifPrefix ? data.label.replace(ifPrefix, '') : data.label, path: data.path, id: data.id ? data.id : "" }); !data.id && handleSearch(`${data.label}.`)
                  ; !data.id && fieldValue(data.label)
              }}>
                {ifPrefix ? data.label.replace(ifPrefix, '') : !data.id ? `${data.label}.` : data.label}
              </Option>
            ))}
          </AutoCompleteField>
        </li>
      </UlTag>
    </InputTag>
  );
}


export default AutoCompleteBar
