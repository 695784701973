import React, { useEffect, useState } from 'react';
import { alphabeticalData } from 'utils/sortData';
import { Button, Form, Input, notification, Popover, Select, Checkbox } from 'antd';

const { Option } = Select;
const FormItem = Form.Item;

function QualifierInput(props) {
  const { types, value, disabled, falseWhiteSpace } = props;
  const [visible, setVisible] = useState(false);
  const { form } = props;

  useEffect(() => {
    if (value) {

      if (value === 'other') {
        form.setFieldsValue({
          operand_type: value,
        });
      } else {
        const parts = value.split(':');
        if (parts.length < 2) {
          throw new Error(`The value ${value} is not in valid format! `);
        }

        form.setFieldsValue({
          operand_type: parts[0],
        }, () => {
          if (parts[1] === 'empty' && (parts[0] === "is" || parts[0] === "not")) {
            form.setFieldsValue({ is_empty: parts[1] })
          }
          else if (parts[0] === "between" || parts[0] === "not_between" || parts[0] === 'are' || parts[0] === 'contains' || parts[0] === 'has') {
            let formValues = {};
            parts[1] = ((parts[1].includes('\\,') === true) ? parts[1].replaceAll(/[\\,]+/g, "|") : parts[1])
            parts[1].split(',').forEach((part, index) => {
              part = part.replaceAll(/[|]+/g, "\\\\,")
              formValues[`field${index}`] = part;
            });
            form.setFieldsValue(formValues);
          }
          else {
            let formValues = {};
            formValues[`field0`] = parts[1]
            form.setFieldsValue(formValues);
          }
        });
      }

    }
  }, [value]);

  const renderInputs = () => {
    const { getFieldDecorator, getFieldValue } = props.form;
    const selectedOperandKey = getFieldValue('operand_type');

    if (!selectedOperandKey) {
      return null;
    }

    const selectedType = types.find(type => type.key === selectedOperandKey);
    return [...Array(selectedType.inputs).keys()].map(index => {
      return (
        <FormItem key={index}>
          {getFieldDecorator(`field${index}`, {
            rules: [
              {
                required: ( (index === 0) ?  true :  (selectedType.key === 'contains' || selectedType.key === 'are' || selectedType.key === 'has') ? false : true ),
                whitespace: falseWhiteSpace ? undefined : false,
                pattern: falseWhiteSpace ? undefined : /^([A-Za-z0-9\.,\\* ])+$/gm,
                message: 'Please set a valid value.',
              },
            ],
          })(
            <Input autoComplete={`off`} disabled={form.getFieldValue('is_empty')} />,
          )}
        </FormItem>
      );
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { form } = props;

    form.validateFields((err, values) => {

      if (err !== null) {
        notification.error({
          message: 'There are errors in qualifier inputs.',
        });
        return;
      }
      if (values.operand_type === 'other') {
        props.onChange(values.operand_type);
      } else {
        let qualifierValue = `${values.operand_type}:`;
        delete values.operand_type;
        if (values.is_empty) {
          qualifierValue += "empty";
        }else {
          delete values.is_empty

          if(checkForDuplicateArrayValue(Object.values(values))){
            notification.error({
              message: 'Same qualifier inputs not allowed.',
            });
            return false;
          }

          Object.entries(values).forEach( ([key, value]) => {
            if (value === undefined) {
              delete values[key];
            }
            if (value === null) {
              delete values[key];
            }
          });

          const valueArray = [...new Set(Object.values(values))];

          qualifierValue += valueArray.join(',');
        }
        props.onChange(qualifierValue);
      }

      setVisible(false);
    });
  };

  const checkForDuplicateArrayValue = (array) => {
      var valuesSoFar = Object.create(null);
      for (var i = 0; i < array.length; ++i) {
        var value = array[i];
        if (value in valuesSoFar) {
            if(value){
              return true;
            }
        }
        valuesSoFar[value] = true;
      }
      return false;
    }

  // Determine whether the qualifier can be set
  const settable = () => {
    const { getFieldValue } = props.form;
    const selectedOperandKey = props.form.getFieldValue('operand_type');

    if (form.getFieldValue('is_empty')) {
      return true
    }
    if (!!selectedOperandKey === false) {
      return false;
    }

    let settable = true;

    const selectedType = types.find(type => type.key === selectedOperandKey);
    if(selectedType.key === 'are' || selectedType.key === 'contains' || selectedType.key === 'has'){
      let filledoutput = 0;
      [...Array(selectedType.inputs).keys()].forEach(index => {
        if (getFieldValue(`field${index}`)) {
          filledoutput = (filledoutput + 1);
        }
      });

      if(filledoutput === 0){
        return false;
      }
    }else{
      [...Array(selectedType.inputs).keys()].forEach(index => {
        if (!!getFieldValue(`field${index}`) === false) {
          settable = false;
        }
      });
    }


    return settable;
  };

  const setQualifierToNull = (value) => {

    if (value.target.value === '') {
      props.onChange(null);
    }
  };

  const getOperandValue = (value) => {

    if (value !== "is" && value !== "not") {
      form.setFieldsValue({ is_empty: null })
    }

  }


  const renderForm = () => {
    const { getFieldDecorator } = props.form;

    return (
      <Form onSubmit={handleSubmit} style={{ width: '300px' }}>
        <FormItem>
          {getFieldDecorator('operand_type', {
            rules: [
              {
                required: true,
                whitespace: false,
                message: 'Field is required',
              },
            ],
          })(
            <Select initialValue={`0`} onChange={getOperandValue}>
              {alphabeticalData(types, 'label').map(type => (
                <Option key={type.key}
                  value={type.key}>{type.label}</Option>
              ))}
            </Select>,
          )}
        </FormItem>

        {!form.getFieldValue('is_empty') && renderInputs()}
        {(form.getFieldValue('operand_type') == "is" || form.getFieldValue('operand_type') == "not")
          &&
          <FormItem style={{ marginTop: "-10px" }}>
            {getFieldDecorator('is_empty', {
              valuePropName: "checked",
            })(
              <Checkbox >Empty</Checkbox>,
            )}
          </FormItem>

        }
        <Button
          onClick={handleSubmit}
          style={{ width: '100%' }}
          disabled={settable() === false}
        >Set</Button>
      </Form>
    );
  };
  return (
    <>
      {disabled ?
        <Input disabled={true} value={props.value} allowClear
          onChange={setQualifierToNull} />
        :
        <Popover
          placement={`bottomLeft`}
          content={renderForm()}
          title="Set Qualifier"
          trigger={`click`}
          style={{ width: '500px' }}
          onVisibleChange={value => setVisible(value)}
          visible={visible}
        >
          <Input value={props.value} allowClear onChange={setQualifierToNull} />

        </Popover>
      }
    </>
  );
}

const QualifierInputWithForm = Form.create()(
  QualifierInput);

export default QualifierInputWithForm;
