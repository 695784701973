import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Icon, notification, Result} from 'antd';
import styled from 'styled-components';
import getFromPath from 'lodash/fp/get';
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import {ZenSmartAPI} from 'utils';
import {Details} from 'components/sections';
import {color} from 'components/zensmart-design-system/shared/styles.js';
import {Panel} from 'components/primaries';
import {dataViewRoute} from 'utils/apiRoutes';
import SearchBreadCrumbs from './SearchBreadCrumbs';
import moment from 'moment'

const SearchText = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`

const BackButtonContainer = styled.div`
  margin-bottom: 16px;
`

function mapToUpdatePayload(values) {

  const payload = Object.keys(values).map((valueKey) => {
    const [resource, id] = valueKey.split(':')
    const data = Object.keys(values[valueKey]).map(field => {
      let value = values[valueKey][field]
      /**
       * Non undefined and null Values should be transformed to a
       * string and also we have to invoke `toDate` whenever it's
       * possible because there are values which have Moment objects
       * in them so we have to properly cast those into a valid date type.
       */
      if(Array.isArray(value)){
        value = value
      }else if(value !== undefined && value !== null){
        value = value && value.toDate ? value.toDate() : value + ''
        if(field === 'send_time_date' || field === 'finish_date'){
          value = value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null
        }
      }
      return { field, value }
    })
    return { resource, id, data }
  })
  return { payload }
}

function getNewDetails(details, newDetailValues) {
  // Clone so we can safely mutate the new state
  let newDetails = cloneDeep(details)

  newDetails.page_definitions.sections.forEach(section => {
    if(section){
      switch (section.render_as) {
        case 'panel': {
          section.section_data.forEach(field => {
            const newDetailValueIndex = newDetailValues.findIndex(newDetailValue => (
                newDetailValue.id + '' === section.id + '' &&
                newDetailValue.resource === section.resource_name &&
                newDetailValue.field === field.field
            ))

            if (newDetailValueIndex > -1) {
              const newDetailValue = newDetailValues.splice(newDetailValueIndex, 1)[0]
              field.value = newDetailValue.value
              field.html_value = newDetailValue.html_value
            }

            if(field.label === "Current Upstream Status"){
              newDetails.page_definitions.resource_headers.map((x) => {
                if(x.label === "Current Upstream Status"){
                  x.value = field.value
                }
                return x;
              })
            }

          })
          break
        }

        default: break // no-op
      }
    }

  })

  return newDetails
}

const DataView = () => {
  const [fetchingDetails, setFetchingDetails] = useState(false)
  const [error, setError] = useState(null)
  const [details, setDetails] = useState(null)
  const { resource, id } = useParams()
  const history = useHistory()
  const [ignoreWorkflow, setIgnoreWorkflow] = useState(false)

  const fetchResource = () => {
    setFetchingDetails(true)

    ZenSmartAPI.get(dataViewRoute(resource, id))
    .then(res => {
      setFetchingDetails(false)
      setDetails(res.data.data)
    })
    .catch(err => {
      setFetchingDetails(false)
      setDetails(null)
      setError('Could not fetch resource. Please try again later.')
      notification.error({
        message: 'Could not fetch resource...'
      })
    })
  }

  useEffect(() => {
    if (resource !== undefined && id !== undefined) {
      fetchResource()
    }
    // eslint-disable-next-line
  }, [resource, id])

  const updateDetails = (values, form) => {
    if (_.isEmpty(values)) {
      return;
    }
    form.submitting()
    const payload = mapToUpdatePayload(values)
    payload.ignore_workflow_execution = ignoreWorkflow
    ZenSmartAPI.patch(dataViewRoute(resource, id), payload)
    .then(res => {
      const newDetails = getNewDetails(details, res.data.data)
      setDetails(newDetails)

      form.finished()
      notification.success({
        message: 'Successfully saved changes!'
      })
    })
    .catch(error => {
      form.failed()

      // 403 errors are handled globally
      if (_.get(error, 'response.status') === 403) {
        return;
      }

      // [422] There are validation errors
      if (error.response && error.response.status === 422) {
        const { errors, message: errorMessage } = error.response.data

        /**
         * Loop over the errors hash and get the field name and error
         * message from it which we'll use to set the errors state on
         * the inputs.
         */
        Object.keys(errors).forEach(errorKey => {
          const errorMessages = errors[errorKey]

          const pathSegments = errorKey.split('.')

          /**
           * Special case for when the server's error validation response
           * doesn't provide the normal "path segment format" on its keys.
           *
           * We need to loop through the form values in order to get the
           * resource key which we'll need to append with to get the
           * complete name of the input to set the error message with.
           */
          if (pathSegments.length === 1) {
            const fieldName = pathSegments[0]

            Object.keys(values).forEach(resourceKey => {
              /**
               * If the fieldName from the single "path segment"
               * is in the hash of the current resource key value.
               * We set the error.
               */
              if (fieldName in values[resourceKey]) {
                form.setErrors(`${resourceKey}.${fieldName}`, errorMessages)
              }
            })

            return
          }

          const resourcePath = pathSegments.slice(0, 2)
          const resource = getFromPath(resourcePath, payload)

          const dataPath = pathSegments.slice(2, 4)
          const { field } = getFromPath(dataPath, resource)

          const fieldName = `${resource.resource}:${resource.id}.${field}`

          /**
           * Remove the "path segment" provided on the error message as this
           * is unusable for users to see.
           *
           * Currently the format that the error message comes like this:
           *
           * "The {path segment} {error message}" ie.
           * "The payload.1.data.1 is a required field"
           *
           * So in this case, we have to remove the _The payload.1.data.1_ part
           * of this message.
           */
          const truncatedErrorMessages = errorMessages
          .map(errorMessage => errorMessage.split(' ').slice(2).join(' '))

          form.setErrors(fieldName, truncatedErrorMessages)
        })

        notification.error({
          message: errorMessage
        })
        return
      }

      notification.error({
        message: 'Something went wrong while updating...'
      })
    })
  }

  if (fetchingDetails) {
    return (
        <SearchText>
          <Icon spin type="loading" /> Fetching resource...
        </SearchText>
    )
  }

  if (error) {
    return (
        <Panel title="FETCH RESOURCE FAILED">
          <Result status="error" title={error} />
        </Panel>
    )
  }

  if (details) {
    return (
        <>
          <SearchBreadCrumbs details={details} />

          <Details
              details={details}
              refetchResource={fetchResource}
              onSave={updateDetails}
              ignoreWorkflow={ignoreWorkflow}
              setIgnoreWorkflow={setIgnoreWorkflow}
              setDetails={setDetails}
          />
        </>
    )
  }

  return null
}

export default DataView
