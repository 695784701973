import React from 'react'
import { SubTitle, Container, ListItem } from './component.js'


const CurrentStack = ({ stack }) => {
  return (
    <Container>
      <SubTitle>Current Stack</SubTitle>
      {stack.map((batch, index) => (
        <div key={index}>
          <ListItem strong> {batch.block_name}</ListItem>
          <div>
            {batch.block_counts.map((count, i) => (
              <ListItem key={i}>{`${count.barcode}: [${
                count.batch_sheets > 0
                  ? `${count.batch_sheets} batch sheet, `
                  : ''
              } ${
                count.job_sheets > 0 ? `${count.job_sheets} job sheet, ` : ''
              } ${
                count.blank_sheets > 0
                  ? `${count.blank_sheets} blank sheet, `
                  : ''
              }  ${
                count.content_sheets > 0
                  ? `${count.content_sheets} content sheet`
                  : ''
              } ${
                batch.blank_sheets > 0 && i === batch.block_counts.length - 1
                  ? `, ${batch.blank_sheets} blank sheet `
                  : ''
              }]`}</ListItem>
            ))}
          </div>
        </div>
      ))}
      {stack.length === 0 && <p>No Data</p>}
    </Container>
  )
}

export default CurrentStack
