import React, { useEffect, useState, Fragment } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { MenuOutlined } from '@ant-design/icons'
import { notification, Skeleton } from 'antd'
import {
  InnerRow,
  Title,
  Description,
  RunRow,
  Button,
  RunContainer
} from './components/index.js'
import { Popconfirm } from 'antd'
import { ZenSmartAPI } from 'utils'
import { useSelector } from 'react-redux'


const RunTile = SortableElement(({ data, handleEdit, handleDelete, handleUpdateStatus, i, handleClone, userData }) => (
    <RunRow isRunning={data.is_running} inactive={data.active}>
      <InnerRow>
        <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
        <Description>&nbsp;&nbsp;{data.description}</Description>
      </InnerRow>
      <InnerRow>
        <Button primary onClick={() => handleUpdateStatus(data.id, data.active)}>
          {data.active == 1 ? "Off" : "On"}
        </Button>
        <Popconfirm
            placement='bottom'
            title={`Are you sure you want to delete ${data.description}`}
            onConfirm={() => handleDelete(data.id, i)}
            okText='Yes'
            cancelText='No'
        >
          {userData && userData.permissions.find(p => p === 'delete-autorun') &&
          <Button>{`Delete`}</Button>
          }
        </Popconfirm>
        {userData && userData.permissions.find(p => p === 'update-autorun') &&
        <Button primary onClick={() => handleEdit(i, data)}>
          Edit
        </Button>
        }
        {userData && userData.permissions.find(p => p === 'create-autorun') &&
        <Button primary onClick={() => handleClone(data)}>
          Clone
        </Button>
        }
      </InnerRow>
    </RunRow>
))

const RunList = SortableContainer(({ handleDelete, handleEdit, runData, handleFetchAllRuns, handleClone }) => {
  const userData = useSelector((state) => state.user.details);
  // update active status
  const onUpdateRunStatus = async (id, val) => {
    try {
      const payload = {
        active: val == 1 ? false : true
      }
      const res = await ZenSmartAPI.post(`/api/v1/run/runs/${id}/active`, payload)
      await handleFetchAllRuns()
      console.log(res)
    } catch(err) {
      console.log(err)
    }
  }

  return (
      <div>
        {runData.map((item, i) => (
            <Fragment key={i}>
              {item ?.delete === 1 ? null : (
                  <RunTile
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      handleUpdateStatus={onUpdateRunStatus}
                      handleClone={handleClone}
                      i={i}
                      index={i}
                      key={i}
                      data={item}
                      userData={userData}
                  />
              )}
            </Fragment>
        ))}
      </div>
  )
})

const CurrentRuns = ({
  id,
  runData,
  setRunData,
  setLoadingErrorAndModal,
  handleEdit,
  handleClone,
  handleDelete,
  editEnabled,
  handleFetchAllRuns
}) => {
  const [loading, setLoading] = useState(false)
  const userData = useSelector((state) => state.user.details);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (userData && userData.permissions.find(p => p === 'sort-autorun')) {
      const newData = arrayMove(runData, oldIndex, newIndex)
      setRunData(newData)
      if (editEnabled === false) {
        setLoadingErrorAndModal(oldState => ({ ...oldState, editEnabled: true }))
      }
    }
    else{
      notification.error({
        message: 'No permission to sort a run!'
      })
    }
  }

  useEffect(() => {
    const fetchAllRuns = async () => {
      try {
        setLoading(true)
        await handleFetchAllRuns()
      } catch (error) {
        console.log({ error })
      } finally {
        setLoading(false)
      }
    }
    fetchAllRuns()
  }, [id])

  return (
      <RunContainer>
        <Title>Current Runs</Title>
        {loading ? (
            <Skeleton active />
        ) : (
             <>
               {runData.length === 0 ? (
                   <p>No Data</p>
               ) : (
                    <RunList
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleClone={handleClone}
                        onSortEnd={onSortEnd}
                        runData={runData}
                        handleFetchAllRuns={handleFetchAllRuns}
                        userData={userData}
                        axis='xy'
                    />
                )}
             </>
         )}
      </RunContainer>
  )
}
export default CurrentRuns
