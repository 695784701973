import axios from 'axios';

const GetFields = async (getData = {
  table: ''
}) => {
  
  let res = await axios.get(`${process.env.REACT_APP_ANALYTICS_SERVER}/fields`, {
    params: getData
  });
  let { data } = res;
  return data;
}

export default GetFields