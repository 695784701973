import React, { useState } from 'react'
import { Modal, Button, Form, Input, notification } from 'antd';
import { changeOrderLockRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'

function UpdateForm(props) {
    const {
        form,
        updateLockModal,
        setUpdateLockModal,
        modalTitle,
        setData,
        data,
        lockOrderNumber,
        setLockOrder
    } = props

    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields,
    } = form


    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                const payload = getFieldsValue();
                setLoading(true)
                ZenSmartAPI.put(changeOrderLockRoute(lockOrderNumber), payload)
                    .then((res) => {
                        setData(
                            data.map((order) => {
                                if (order.order_id == lockOrderNumber) {
                                    return { ...order, locked: !order.locked, lock_comment: payload.comment };
                                } else {
                                    return order;
                                }
                            })
                        );

                        notification.success({
                            message: res?.data?.data?.message || 'Order lock has been updated'
                        })
                        setLoading(false);
                        handleCancel()
                    })
                    .catch((err) => {
                        notification.error({
                            message: err?.response?.data?.message || 'Something went wrong'
                        })
                    }).finally(() => setLoading(false));
            }
        });
    };

    const handleCancel = () => {
        setUpdateLockModal(false)
        setLockOrder('')
    }

    return (
        <div>
            <Modal title={modalTitle} visible={updateLockModal} footer={[
                <Button
                    key="back"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button key="submit"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>,
            ]}>
                {updateLockModal &&
                    <Form layout="vertical" onSubmit={handleSubmit}>
                        <Form.Item label="Comment:">
                            {getFieldDecorator(`comment`, {
                                rules: [{
                                    required: true,
                                    message: "Please enter comment"
                                }],
                                initialValue: ''
                            }
                            )(
                                <Input placeholder={"Please enter comment!"} />
                            )}
                        </Form.Item>
                    </Form>
                }
            </Modal>
        </div>
    )
}

const UpdateLockForm = Form.create({
})(UpdateForm)

const LockUpdateModal = (props) => {
    const { updateLockModal, setUpdateLockModal, modalTitle, setData, data, lockOrder, setLockOrder } = props
    return (
        <div>
            <UpdateLockForm
                updateLockModal={updateLockModal}
                setUpdateLockModal={setUpdateLockModal}
                modalTitle={modalTitle}
                setData={setData}
                data={data}
                lockOrderNumber={lockOrder}
                setLockOrder={setLockOrder}
            />
        </div>
    )
}

export default LockUpdateModal