import axios from 'axios';

const GetFieldUniqueValues = async (requestData = {
  table: '',
  fields: []
}) => {
  if (requestData.table) {
    let res = await axios.post(`${process.env.REACT_APP_ANALYTICS_SERVER}/bucket_suggestions`, requestData)
    let { data } = res;
    return data;
  }
}

export default GetFieldUniqueValues