import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Checkbox, Row, Col, Button } from 'antd'
import { panelSortData } from 'utils/sortData'
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { holdInitialData } from "utils/panelsInitialData"
import _ from "lodash"

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

`
const TableData = (props) => {

    const { itemData, loading, data, setData, ascend, setAscend, confirmRelease, updateLock , enableLockOrder} = props
    const sortingData = (data, property, ifNotProperty) => {
        if (ascend[property] === null) {
            setAscend({ ...holdInitialData, [property]: true })
        }
        else {
            ascend[property] === true ? setAscend({ ...holdInitialData, [property]: false }) : setAscend({ ...holdInitialData, [property]: true })
        }
        setData(panelSortData(data, property, ascend[property], ifNotProperty))
    }
    const getHeaders = (property, text, ifAscend) => {
        return (
            <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifAscend)} style={{ cursor: "pointer" }}>
                <Col span={ascend[property] === null ? 24 : 20}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }
    const columns = [
        {
            title: getHeaders("vendor", "VENDOR", false),
            dataIndex: 'vendor',
            key: 'vendor',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("transaction_id", "TRANSACTION", true),
            dataIndex: 'transaction_id',
            key: 'transaction_id',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("order_number", "ORDER", true),
            dataIndex: 'order_number',
            key: 'order_number',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`} style={{ color: "blue", textDecorationLine: "underline" }}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("primary_product_group", "PRIMARY", false),
            dataIndex: 'primary_product_group',
            key: 'primary_product_group',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("article_description", "PRODUCT", false),
            dataIndex: 'article_description',
            key: 'article_description',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("files_retrieved", "RETRIEVED?", false),
            dataIndex: 'files_retrieved',
            key: 'files_retrieved',
            render(record, row, index) {
                const value = <Checkbox defaultChecked={record} disabled />
                return rowStyle(record ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("locked", "LOCKED?", false),
            dataIndex: 'locked',
            key: 'locked',
            render(record, row, index) {
                const value = <Checkbox defaultChecked={record} disabled />
                return rowStyle(record ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("lock_comment", "LOCK COMMENT", false),
            dataIndex: 'lock_comment',
            key: 'lock_comment',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("on_hold_reason", "REASON", false),
            dataIndex: 'on_hold_reason',
            key: 'on_hold_reason',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("block_files", "FILES", false),
            dataIndex: 'block_files',
            key: 'block_files',
            render(value, row, index) {
                var newValue = value.map((item, index) => {
                    return <p><a href={`${item.link}`} target="_blank" style={{ color: "blue", textDecorationLine: "underline" }}>{item.value}</a></p>
                })
                return rowStyle(newValue.length != 0 ? newValue : "-", index, row)
            },
        },
        {
            title: getHeaders("due_date", "DUE", false),
            dataIndex: 'due_date',
            key: 'due_date',
            render(value, row, index) {
                const dataValue = moment.unix(value).format("D MMM YYYY")
                return rowStyle(value ? dataValue : "-", index, row)
            },
        },
        {
            title: getHeaders("age_on_hold", "AGE ON HOLD", false),
            dataIndex: 'age_on_hold',
            key: 'age_on_hold',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: "ACTIONS",
            dataIndex: 'actions',
            key: 'actions',
            render: (value, row, index) => {
                return rowStyle('RELEASE', index, row, true , enableLockOrder)
            },
        },

    ];

    // remove lock column if settings disabled
    if(!enableLockOrder) {
        let lockIndex = columns.findIndex(function(column) {
            return column.dataIndex == "locked"
        });
        columns.splice(lockIndex, 2); 
    }

    const rowStyle = (value, index, row, isButton , enableLockOrder) => {
        const button = (val) =>(
            <> 
                {enableLockOrder ? <> {!row.locked && 
                    <Button 
                        type="primary"
                        onClick={() => confirmRelease(`Are you sure to release the order ${row.order_number.value}?`, row.order_id)}
                        >
                        {val}
                    </Button>
                }
                    <Button 
                        type={row.locked ? "danger" : 'default'}
                        onClick={() => updateLock(`Are you sure to ${row.locked ? 'unlock' : 'lock'} the order ${row.order_number.value}?`, row.order_id)}
                    >
                        {row.locked ? 'UNLOCK' : 'LOCK'} 
                    </Button>
                </> 
                :   <Button 
                        type="primary"
                        onClick={() => confirmRelease(`Are you sure to release the order ${row.order_number.value}?`, row.order_id)}
                    >
                        {val}
                    </Button>
                }
            </>
        );

        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: !isButton ? <div>{value}</div> : button(value),
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }

        return obj
    }

    useEffect(() => {

        if (itemData) {

            const itemObj = itemData.map(data => {
                const obj = {
                    order_number: data.order_number,
                    transaction_id: data.transaction_id,
                    vendor: data.vendor,
                    order_id: data.order_id,
                    primary_product_group: data.primary_product_group,
                    article_description: data.article_description,
                    files_retrieved: data.files_retrieved,
                    locked: data.locked,
                    lock_comment: data.lock_comment,
                    on_hold_reason: data.on_hold_reason,
                    block_files: data.block_files,
                    due_date: data.due_date,
                    age_on_hold: data.age_on_hold,
                }
                return obj;
            })

            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "45vh" }}
                    pagination={{ pageSize: 50 }}

                />
            </StyleTable>
        </>
    )
}

export default TableData