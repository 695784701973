import React from 'react';
import { List } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

const StyledList = styled(List)`
  background: #fff;
  padding: 10px;
  margin-top: 20px;
`

const StyledListItem = styled(List.Item)`
  padding: 15px 10px;
  
  .ant-list-item-meta-title {
    font-weight: 600;
  }
`

const FaultLogs = props => {
  const { faultLogs } = props;
  return (
    <StyledList pagination={true}>
      { faultLogs && faultLogs.map(log => 
        <StyledListItem key={log.id}>
            <List.Item.Meta
              title={`${log.user.toUpperCase()} ${moment(log.updated_at).format('DD MMM YYYY, ddd hh:mm a')}`}
              description={log.comment ? log.comment : '--'}
            />
            <div>{log.status}</div>
        </StyledListItem>
      )}
    </StyledList>
  )
}

export default FaultLogs;