import axios from 'axios';

const LoadData = async (requestData = {
  table: '',
  groups: [],
  secondary_filter: [],
  primary_filter: [],
  fields: [],
  order: '',
  bucket_fields: [],
  mode: '',
  visualization: ''
}) => {
  let res = await axios.post(`${process.env.REACT_APP_ANALYTICS_SERVER}/data`, requestData)
  let { data } = res;
  return data;
}

export default LoadData