
import React, { useEffect } from 'react'
import { ZenSmartAPI } from 'utils';
import { Route } from 'react-router-dom';
import { Scans, QAFail, PreDispatch, Match } from 'pages/People'
import { SCAN_MENU } from '../../../store/actions/scanTypes';
import { useDispatch } from 'react-redux'
import { scanTypesRoute } from "utils/apiRoutes"
import { Dispatch } from 'pages/PackAndShip'
import { useSelector } from 'react-redux'

export const fetchScanTypes = (dispatch, filter = null) => {
  ZenSmartAPI.get(scanTypesRoute(filter), {}).then((res) => {
    dispatch({
      type: SCAN_MENU,
      payload: res.data,
    });
  });
};

export const ScanRoutes = () => {

    const scanData = useSelector(state => state.scan.scan_menu.data)
    const dispatch = useDispatch()
    useEffect(() => {
        fetchScanTypes(dispatch);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const scanItem = (data) => {

        switch (data.text.toLowerCase()) {
            case "qa-fail":
            case "qa fail":
                return < Route path={`/app/people/scan/${data.text.split(" ").join("-").toLowerCase()}`}  component={() => <QAFail scanData={data} />} />;
            case "pre-dispatch":
            case "pre dispatch":
                return <Route path={`/app/people/scan/${data.text.split(" ").join("-").toLowerCase()}`} component={() => <PreDispatch scanData={data} />} />;
            case "dispatch":
                return <Route path={`/app/people/scan/${data.text.split(" ").join("-").toLowerCase()}`} component={() => <Dispatch scanData={data} />} />
            case "match in":
                return <Route path={`/app/people/scan/match`} component={() => <Match scanData={data} />} />
            case "match":
                return null;
            default:
                return <Route path={`/app/people/scan/${data.text.split(" ").join("-").toLowerCase()}`} component={() => <Scans scanData={data} />} />

        }

    }
    return (
        <>
            {scanData ?
                scanData.map((data, key) => {
                    return scanItem(data)
                }) : null}
        </>
    )
}

export const apiRoutes = {
    barcodeApi: "/api/v1/scans/barcode",
}