import React, { useEffect, useState } from 'react'
import { Header, Title } from 'components/primaries'
import { ZenSmartAPI } from 'utils'
import { Panel } from 'components/primaries'
import { Input, notification } from 'antd'
import TableData from './TableData'

const { Search } = Input

const FIRST_PAGE = 1
const DEFAULT_ROW_PER_PAGE = 10

const ManualPickup = () => {
    const [loading, setLoading] = useState(false)
    const [reloadPage, setReloadPage] = useState(true)
    const [searchQuery, setSearchQuery] = useState('')
    const [manualPickupData, setManualPickupData] = useState([])
    const [pagination, setPagination] = useState({
        current: FIRST_PAGE,
        defaultPageSize: 15,
        hideOnSinglePage: true,
        pageSize: DEFAULT_ROW_PER_PAGE,
        total: 0,
        showQuickJumper: false,
        onChange: (page, pageSize) => fetchManualPickupData(page, pageSize)
    })
    const [modalValues, setModalValues] = useState({
        visible: false,
        loading: false,
        is_id_sighted: false,
        is_docket_signed: false,
        is_package_not_marked: false,
        is_mobile_per_order: false,
        client_name: '',
        agentMobileNumber: '',
        note: '',
        id: ''
    })

    const handleSearch = e => {
        setSearchQuery(e)
        setReloadPage(true)
    }

    const fetchManualPickupData = async (page, pageSize) => {
        try {
            setLoading(true)
            setReloadPage(false)
            const url = searchQuery
                ? `api/v1/consignments/pickups?search=${searchQuery}&page=${page}&qtyPerPage=${pageSize}`
                : `api/v1/consignments/pickups?page=${page}&qtyPerPage=${pageSize}`

            const request = await ZenSmartAPI.get(url)
            const result = request.data
            setPagination(oldState => ({
                ...oldState,
                current: result.current_page,
                pageSize: +result.per_page,
                total: result.total
            }))
            if (result.data.length === 0) notification.info({ message: 'No data' })
            setManualPickupData(result.data)
        } catch (error) {
            notification.error({
                message:
                    error?.response?.data?.message ??
                    error?.message ??
                    'Error updating runs'
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (reloadPage === true) {
            fetchManualPickupData(FIRST_PAGE, DEFAULT_ROW_PER_PAGE)
        }
    }, [searchQuery, reloadPage])

    return (
        <>
            <Header>
                <Title>Pack and Ship {'>'} Manual Pickup</Title>
            </Header>

            <Panel title='Manual Pickup'>
                <div style={{ padding: 20 }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '13em', marginRight: 20 }}>
                            <Search
                                placeholder='Input search text'
                                onSearch={handleSearch}
                                style={{ width: '25em' }}
                            />
                        </div>
                    </div>
                    <TableData
                        searchQuery={searchQuery}
                        setReloadPage={setReloadPage}
                        pagination={pagination}
                        loading={loading}
                        data={manualPickupData}
                        modalValues={modalValues}
                        setModalValues={setModalValues}
                    />
                </div>
            </Panel>
        </>
    )
}

export default ManualPickup