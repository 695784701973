import React, { useState } from 'react';
import styled from 'styled-components';
import { Search } from '../../../../components/zensmart-design-system';
import { BucketFields, Fields } from '../composites';
import { GetLabel } from '../../utils';

const SideBarStyle = styled.div`
  p:hover {
    cursor: pointer;
    background-color: rgba(209,217,246,0.9);
  }
  h2 {
    font-weight: 700;
  }
`

const SideBar = ({
  currentTableObject,
  setDragItem,
  setDragBucketItem,
  dataFields = [],
  bucketFields,
  addBucketField,
  editBucketField,
  deleteBucketField,
  modifiedFieldLabels
}) => {
  const [searchContent, setSearchContent] = useState('');

  return (
    <SideBarStyle style={{ padding: 30 }}>
      <h2>Fields</h2>
      <Search style={{ minWidth: 230 }} onChange={e => setSearchContent(e.target.value)} value={searchContent} />
      <BucketFields
        bucketFields={bucketFields}
        setDragBucketItem={setDragBucketItem}
        addBucketField={addBucketField}
        editBucketField={editBucketField}
        deleteBucketField={deleteBucketField}
      />
      <Fields
        currentTableObject={currentTableObject}
        dataFields={
          searchContent !== '' ? dataFields.filter(item => GetLabel(`${item.table}.${item.field}`, [], modifiedFieldLabels).toLowerCase().indexOf((searchContent.toLowerCase())) > -1) : dataFields
        } setDragItem={setDragItem} modifiedFieldLabels={modifiedFieldLabels} />
    </SideBarStyle>
  )
}

export default SideBar