const createContainer = (config = {}) => ({
  sibling: null,
  text: null,
  editable: true,

  accepts: [],
  children: [],
  plex: 'simplex',
  submission_per_block: false,
  page_object: false,
  requires_work_order: false,
  no_print_required: false,

  ...config,

  attachables: null,
  triggers: null,

  table: null,
  fieldToUpdateOnScan: null,
})
export default createContainer