import React from "react";
import styled from "styled-components";
import { PieIcon, LineIcon, ColumnIcon, MatrixIcon } from "static/icons";

const LineChart = [
  "AreaChart",
  "ComboChart",
  "LineChart",
  "Line",
  "ScatterChart",
];

const ColumnChart = [
  "BarChart",
  "ColumnChart",
  "SteppedAreaChart",
];

const PieChart = [ "PieChart" ];

const ReportItemContainer = styled.div`
  margin-top: ${(props) => (props.index === 0 ? "15px" : "5px")};
  display: flex;
  
  .icon_container {
    width: 9%
  }
`;

const ReportItemText = styled.div`
  margin-left: 5px;
  width: 91%
`;

function ReportItem(props) {
  const { index, report } = props;
  const chartType = report.chart_configuration && report.chart_configuration.type ? report.chart_configuration.type : null;

  function drag(e) {
    e.dataTransfer.setData("reportId", e.target.id);
  }

  return (
    <ReportItemContainer
      id={report.id}
      index={index}
      draggable={true}
      onDragStart={(event) => drag(event)}
    >
      <div className="icon_container">
        {PieChart.includes(chartType) ? (
          <PieIcon width="18px" height="18px" />
        ) : LineChart.includes(chartType) ? (
          <LineIcon width="18px" height="18px" />
        ) : ColumnChart.includes(chartType) ? (
          <ColumnIcon width="18px" height="18px" />
        ) : <MatrixIcon width="18px" height="18px" /> }
      </div>
      <ReportItemText>{report.name}</ReportItemText>
    </ReportItemContainer>
  );
}

export default ReportItem;
