import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, InputNumber} from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { getPrintersModalPopupRoute, getBySettingUrlPopulate, getConsolidationsModalPopupRoute, pressReleaseLimitRoute } from 'utils/apiRoutes';
import { ZenSmartAPI } from 'utils'
import { debounce } from 'utils/debounce'
import { pressReleaseRoute } from "utils/apiRoutes"
import BatchedPanel from "./BatchedPanel"
import SentPanel from "./SentPanel"
import BatchManagerModal from './PressReleaseModal';
import { alphabeticalData } from 'utils/sortData';
import { useParams } from 'react-router-dom'
import { renderFilterColumnsData, searchOperation, searchOperationWithoutDelay, sortingData } from './PressReleaseHelperFunctions'
import { UserThunks } from 'store/slices/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const STATUS = {
    batched: "batched",
    sent: "sent"
}
const PageStyling = createGlobalStyle`
@media only screen and (max-width: 1280px) {
    span, td, th, .ant-select-selection-selected-value {
        font-size : 11px !important
    }
    .panel-div
    {
        min-height : 90vh;
    }
    .main-div{
        width : 1200px !important
    }
    .parent-header {
       width : ${props => props.getPanelWidth ? `${parseInt(props.getPanelWidth.clientWidth) + 300}px!important` : null};
    }
    .parent-container {
       width : ${props => props.getPanelWidth ? `${parseInt(props.getPanelWidth.clientWidth) + 530}px!important` : null};
    }
  }

  @media only screen and (max-width: 1152px) {
   
    .main-div{
        width : 1000px !important
    }
    
  }

@media screen and (min-width: 1281px) and (max-width: 1440px) {
    span {
        font-size : 12px !important;
    }
    td, th, .ant-select-selection-selected-value {
        font-size : 11px !important;
    }
    .panel-div
    {
        min-height : 80vh;
    }
}


@media only screen and (max-height: 900px) {
   
    .panel-div
    {
        min-height : 80vh;
    }
    .ant-table-body {
        height : 42vh !important
    }
    
  }

  .ant-table-placeholder {
    display : none !important;
}


  
`


const PressReleasePage = (props) => {
    const getPanelWidth = document.getElementsByClassName("main-div")[0];
    const [batchedLoading, setBatchedLoading] = useState(false)
    const [sentLoading, setSentLoading] = useState(false)
    const [batchedItemData, setBatchedItemData] = useState([])
    const [batchedOriginalItemData, setOriginalBatchedItemData] = useState([])
    const [batchedBlocksCount, setBatchedBlocksCount] = useState({})
    const [sentBlocksCount, setSentBlocksCount] = useState({})
    const [sentItemData, setSentItemData] = useState([])
    const [sentOriginalItemData, setSentOriginalItemData] = useState([])
    const [batchedLabels, setBatchedLabels] = useState({})
    const [sentLabels, setSentLabels] = useState({})
    const [selectedBatched, setSelectedBatched] = useState([])
    const [selectedSent, setSelectedSent] = useState([])
    const [printers, setPrinters] = useState([])
    const [printerSelectModalValues, setPrinterSelectModaValues] = useState(null)
    const [consolidationSelectModalValues, setConsolidationSelectModalValues] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [loadingBatchedButton, setLoadingBatchedButton] = useState(false)
    const [loadingSentButton, setLoadingSentButton] = useState(false)
    const [modalCaller, setModalCaller] = useState('')
    const [printerSent, setPrinterSent] = useState(null)
    const [selectedPrinterOption, setSelectedPrinterOption] = useState(null)
    const [batchedFilterData, setBatchedFilterData] = useState([])
    const [sentFilterData, setSentFilterData] = useState([])
    const [batchedColumnFilterDataObject, setBatchedColumnFilterDataObject] = useState({})
    const [sentColumnFilterDataObject, setSentColumnFilterDataObject] = useState({})
    const [batchedAscend, setBatchedAscend] = useState({})
    const [sentAscend, setSentAscend] = useState({})
    const [pressRelease, setPressRelease] = useState(null)
    const [currentSearchKey, setCurrentSearchKey] = useState('')
    const [firstFetch, setFirstFetch] = useState(false)
    const [originalUserDetails, setOriginalUserDetails] = useState({})
    const { slug } = useParams()
    const userData = useSelector(state => state.user)
    const dispatch = useDispatch()
    const batchPanelInputValue = useRef()
    const sentPanelInputValue = useRef()
    const [pressReleaseLimit, setPressReleaseLimit] = useState(null)
    const [pressReleaseLimitEnabled, setPressReleaseLimitEnabled] = useState(false)
    const permissions = userData?.details?.permissions

    const batchReleaseCountDown = userData?.details?.page_settings?.batch_release_countdown
    ? parseInt(userData?.details?.page_settings?.batch_release_countdown) : null
    const [seconds, setSeconds] = useState(batchReleaseCountDown)

    const refreshStates = () => {
        setSelectedBatched([])
        setSelectedSent([])
        setPrinters([])
        setPrinterSent(null)
        setSelectedPrinterOption(null)
        setSelectedPrinterOption(null)
        setPrinterSelectModaValues(null)
        setConsolidationSelectModalValues({})
        setBatchedItemData([])
        setSentItemData([])
        setSentAscend({})
        setBatchedAscend({})
        setSelectedPrinterOption(null)
        batchPanelInputValue.current.input.state.value = null
        sentPanelInputValue.current.input.state.value = null
    }
    const allowUpdatePressReleaseLimit = () => {
        const permissionIndex = permissions.indexOf('edit-app-setting')
        return (permissionIndex !== -1)
    }
    const setColumnFiltersLogic = (searchInputValue) => {
        var arrayValue = [];
        if (searchInputValue !== "") {
            arrayValue = searchOperationWithoutDelay(searchInputValue, setOriginalBatchedItemData, setOriginalBatchedItemData, setBatchedItemData, setCurrentSearchKey, setColumnFiltersLogic,
                batchedColumnFilterDataObject)
        }
        else {
            arrayValue = batchedOriginalItemData
        }
        Object.keys(batchedColumnFilterDataObject).map(item => {
            if (batchedColumnFilterDataObject[item] !== "") {
                arrayValue = arrayValue.filter(data => {
                    const dataItem = item === "fails" && data[item] === 0 ? "False" : item === "fails" && data[item] > 0 ? "True" : data[item]
                    return dataItem === batchedColumnFilterDataObject[item]
                })
            }
            return item
        })
        setBatchedItemData(arrayValue)
    }
    const fetchData = (sort = false, requiresRefresh = true) => {
        setBatchedLoading(true)
        setSentLoading(true)

        if (requiresRefresh) {
            refreshStates()
        }
        setSeconds(null)
        ZenSmartAPI.post(pressReleaseRoute, { ...pressRelease, specific_printer: selectedPrinterOption, limit: pressReleaseLimit, setting_id : pressRelease?.id })
            .then((res) => {
                const result = res.data
                setBatchedLoading(false)
                setSentLoading(false)
                setSeconds(batchReleaseCountDown)
                setBatchedBlocksCount(result.batched_data.data_count);
                setSentBlocksCount(result.sent_data.data_count);
                if (!currentSearchKey) {
                if (pressRelease.batched_sorting) {
                    setBatchedItemData(sortingData(result.batched_data.data, pressRelease.batched_sorting.column_name, false, result.batched_data.labels, batchedAscend, setBatchedAscend, null, null, !pressRelease.batched_sorting.if_ascend))
                }
                else {
                    setBatchedItemData(result.batched_data.data)
                }
                }
                if (currentSearchKey) {

                    searchOperation(currentSearchKey, result.batched_data.data, result.batched_data.data, setBatchedItemData, setCurrentSearchKey, setColumnFiltersLogic, batchedColumnFilterDataObject)
                }
                setOriginalBatchedItemData(result.batched_data.data)
                setBatchedLabels(result.batched_data.labels)
                setBatchedFilterData(renderFilterColumnsData(result.batched_data.labels, result.batched_data.data))

                if (pressRelease.sent_sorting) {
                    setSentItemData(sortingData(result.sent_data.data, pressRelease.sent_sorting.column_name, false, result.sent_data.labels, sentAscend, setSentAscend, null, null, !pressRelease.sent_sorting.if_ascend))
                }
                else {
                    setSentItemData(result.sent_data.data)
                }
                setSentOriginalItemData(result.sent_data.data)
                setSentLabels(result.sent_data.labels)
                setSentFilterData(renderFilterColumnsData(result.sent_data.labels, result.sent_data.data))
                setPrinters(result.printers)
                setSelectedPrinterOption(result.printers.length > 0 ? result.printers[0] : null)

            })
            .catch((res) => {
                setSeconds(batchReleaseCountDown)
                setBatchedLoading(false)
                setSentLoading(false)
                notification.error({ message: "Failed fetching data!" })
            })

    }
    const setLoadingButtons = (buttonType, ifTrue) => {
        if (buttonType === "batched") {
            setLoadingBatchedButton(ifTrue)
        }
        else {
            setLoadingSentButton(ifTrue)
        }
    }

    const getPrintersToBeSend = (panelType, isResendBatched = false) => {
        const payload = { batch_barcodes: panelType === "batched" ? selectedBatched : selectedSent }
        payload.failed_batch = isResendBatched
        if (payload.batch_barcodes.length > 0) {

            setLoadingButtons(panelType, true);

            const getConsolidationsPromise = ZenSmartAPI.get(getConsolidationsModalPopupRoute);
            const getPrintersPromise = ZenSmartAPI.post(getPrintersModalPopupRoute, payload);

            Promise.all([ getConsolidationsPromise, getPrintersPromise ]).then(result => {
                const [consolidationResult, printerResult] = result;
                setPrinterSelectModaValues(printerResult.data.data);
                setConsolidationSelectModalValues(consolidationResult.data.data);
                setLoadingButtons(panelType, false)
                setModalCaller(panelType)
                setShowModal(true)
            }).catch(err => {
                setLoadingButtons(panelType, false)
                if (err.response) {
                    notification.error({
                        message: err.response.data.message
                    })
                    return
                }
            })
        } else {
            notification.error({ message: "Please select an item!" })
        }
    }
    const handleOnIdle = () =>{
        setSeconds(batchReleaseCountDown)
    }

    let timeoutTimer = 1000
    if (timeoutTimer.toString().length >= 0) {
        timeoutTimer = timeoutTimer.toString().substr(0, timeoutTimer.toString().length - 1)
    }

    const { getLastActiveTime } = useIdleTimer({
        timeout: timeoutTimer,
        onIdle: handleOnIdle,
        debounce: 500
    })

    const successPrint = () => {
        if (modalCaller === STATUS.batched) {
            const mergeRowSent = selectedBatched.map(data => {
                const returnBatch = batchedOriginalItemData.find(item => item.id === data)
                returnBatch['imposed_datetime'] = new Date()
                returnBatch['sent_datetime'] = new Date()
                return returnBatch
            })
            if (printerSent.name === selectedPrinterOption) {
                setSentOriginalItemData(sentOriginalItemData.concat(mergeRowSent))
                setSentItemData(sentItemData.concat(mergeRowSent))
                setSentFilterData(renderFilterColumnsData(sentLabels, sentOriginalItemData.concat(mergeRowSent)))

            }
            if (!printers.includes(printerSent.name)) {
                setPrinters(alphabeticalData([...printers, printerSent.name]))
            }

            if (!selectedPrinterOption) {
                setSelectedPrinterOption(printerSent.name)
                setSentOriginalItemData(sentOriginalItemData.concat(mergeRowSent))
                setSentItemData(sentItemData.concat(mergeRowSent))
                setSentFilterData(renderFilterColumnsData(sentLabels, sentOriginalItemData.concat(mergeRowSent)))
            }

            const removeFromBatch = batchedOriginalItemData.filter(item => {
                const ifExistOnSelectedRow = selectedBatched.some(data => data === item.id)
                return !ifExistOnSelectedRow
            })
            setBatchedItemData(removeFromBatch)
            setOriginalBatchedItemData(removeFromBatch)
            setBatchedFilterData(renderFilterColumnsData(batchedLabels, removeFromBatch))

            setSelectedBatched([])

        }
        else if (modalCaller === STATUS.sent) {
            if (!printers.includes(printerSent.name)) {
                setPrinters(alphabeticalData([...printers, printerSent.name]))
            }

            if (printerSent.name !== selectedPrinterOption) {
                const removeFromSent = sentOriginalItemData.filter(item => {
                    const ifExistOnSelectedRow = selectedSent.some(data => data === item.id)
                    return !ifExistOnSelectedRow
                })
                setSentItemData(removeFromSent)
                setSentOriginalItemData(removeFromSent)
                setSentFilterData(renderFilterColumnsData(sentLabels, removeFromSent))

            }
            else {
                selectedSent.map(id => {
                    var element = document.getElementsByClassName(id)[0];
                    element.classList.remove("selected-sent");
                    return id
                })
            }
            setSelectedSent([])
        }
    }
    const getPressReleaseInterface = () => {
        return (
            <>
                <PageStyling getPanelWidth={getPanelWidth} />

                <Header>
                    <Title style={{ textTransform: "capitalize" }}>
                         Print {">"} Press {">"} {pressRelease && pressRelease.name && pressRelease.name}
                    </Title>
                </Header>
                <div className="main-div">
                    {
                        pressReleaseLimitEnabled &&
                        <Row type="flex" align="middle" style={{ padding: "15px 15px 0px 15px" }} >
                            <Col span={1} style={{ padding: 0 }} >
                                <b>Limit:</b>
                            </Col>
                            <Col span={1} style={{ padding: 0 }} >
                                <InputNumber
                                    rows={4}
                                    value={pressReleaseLimit}
                                    disabled={!allowUpdatePressReleaseLimit()}
                                    onChange={debounce((e) => {
                                        setPressReleaseLimit(e)
                                    }, 2000)}
                                />
                            </Col>
                        </Row>
                    }
                    <Row type="flex" align="middle" style={{ padding: "5px 15px 0px 15px" }} >
                        <Col span={12} style={{ padding: 0 }} >
                            <Row type="flex" align="left" style={{fontWeight: "bold" }} >
                                <Col style={{ padding: "0px 5px" }} >
                                    <p>Batches: {batchedOriginalItemData.length}</p>
                                </Col>
                                <Col style={{ padding: "0px 5px" }} >
                                    <p>Blocks: {batchedBlocksCount?.blocks_count}</p>
                                </Col>
                                {pressRelease?.show_linear_length ?
                                    <Col style={{ padding: "0px 5px" }} >
                                        <p>Linear Ft: {batchedBlocksCount?.linear_length_count ? parseFloat(batchedBlocksCount?.linear_length_count).toFixed(2) : 0}</p>
                                    </Col>
                                    : null}
                            </Row>
                            <Panel title="Batched" style={{ minHeight: "75vh" }} className="panel-div">
                                <BatchedPanel
                                    loading={batchedLoading}
                                    setLoading={setBatchedLoading}
                                    itemData={batchedItemData}
                                    setItemData={setBatchedItemData}
                                    originalData={batchedOriginalItemData}
                                    setOriginalData={setOriginalBatchedItemData}
                                    labels={batchedLabels}
                                    selectedBatched={selectedBatched}
                                    setSelectedBatched={setSelectedBatched}
                                    getPrintersToBeSend={getPrintersToBeSend}
                                    buttonLoading={loadingBatchedButton}
                                    batchedFilterData={batchedFilterData}
                                    batchedColumnFilterDataObject={batchedColumnFilterDataObject}
                                    setBatchedColumnFilterDataObject={setBatchedColumnFilterDataObject}
                                    batchPanelInputValue={batchPanelInputValue}
                                    ascend={batchedAscend}
                                    setAscend={setBatchedAscend}
                                    pressRelease={pressRelease}
                                    userData={userData}
                                    setCurrentSearchKey={setCurrentSearchKey}

                                />
                            </Panel>
                        </Col>
                        <Col span={12} style={{ padding: 10 }} >
                            <Row type="flex" align="left" style={{ fontWeight: "bold" }} >
                                <Col style={{ padding: "0px 5px" }} >
                                    <p>Batches: {sentOriginalItemData.length}</p>
                                </Col>
                                <Col style={{ padding: "0px 5px" }} >
                                    <p>Blocks: {sentBlocksCount?.blocks_count}</p>
                                </Col>
                                {pressRelease?.show_linear_length ?
                                    <Col style={{ padding: "0px 5px" }} >
                                        <p>Linear Ft: {sentBlocksCount?.linear_length_count ? parseFloat(sentBlocksCount?.linear_length_count).toFixed(2) : 0}</p>
                                    </Col>
                                    : null}
                            </Row>
                            <Panel title="Sent To Print" style={{ minHeight: "75vh" }} className="panel-div">
                                <SentPanel
                                    loading={sentLoading}
                                    setLoading={setSentLoading}
                                    fetchData={fetchData}
                                    itemData={sentItemData}
                                    setItemData={setSentItemData}
                                    originalData={sentOriginalItemData}
                                    setOriginalData={setSentOriginalItemData}
                                    labels={sentLabels}
                                    printers={printers}
                                    filters={pressRelease.sent}
                                    selectedSent={selectedSent}
                                    setSelectedSent={setSelectedSent}
                                    getPrintersToBeSend={getPrintersToBeSend}
                                    buttonLoading={loadingSentButton}
                                    sentFilterData={sentFilterData}
                                    setSentFilterData={setSentFilterData}
                                    sentColumnFilterDataObject={sentColumnFilterDataObject}
                                    setSentColumnFilterDataObject={setSentColumnFilterDataObject}
                                    setSelectedPrinterOption={setSelectedPrinterOption}
                                    selectedPrinterOption={selectedPrinterOption}
                                    renderFilterColumnsData={renderFilterColumnsData}
                                    sentPanelInputValue={sentPanelInputValue}
                                    ascend={sentAscend}
                                    setAscend={setSentAscend}
                                    pressRelease={pressRelease}
                                    pressReleaseLimit={pressReleaseLimit}
                                    user={userData}
                                    setSentBlocksCount={setSentBlocksCount}
                                />
                            </Panel>
                        </Col>
                    </Row>
                </div>

                {printerSelectModalValues ?
                 <BatchManagerModal
                     printerSelectValue={printerSelectModalValues}
                     consolidationSelectValue={consolidationSelectModalValues}
                     setPrinterSent={setPrinterSent}
                     showModal={showModal}
                     setShowModal={setShowModal}
                     resend={modalCaller === STATUS.sent}
                     pressReleasePayload={pressRelease}
                     batchPayload={(
                         modalCaller === STATUS.batched ? selectedBatched : (
                             modalCaller === STATUS.sent ? selectedSent : []
                         )
                     )}
                     successCallBack={successPrint}
                 />
                                          : null}
            </>
        )
    }

    const fetchPressReleaseLimit = () => {
        if (slug) {
            ZenSmartAPI.get(pressReleaseLimitRoute(slug))
                .then(res => {
                    if (res.data.limit == pressReleaseLimit) {
                        fetchData()
                    }
                    else if (res.data.limit > 0) {
                        setPressReleaseLimit(res.data.limit)
                        setPressReleaseLimitEnabled(true)
                    }
                }).catch((res) => {
                    fetchData()
                    setPressReleaseLimitEnabled(false)
                    setPressReleaseLimit(0)

                })
        }
    }

    useEffect(() => {
        if (userData.details) {
            if ((pressRelease && pressRelease.slug === slug) && userData.details === originalUserDetails) {
                return
            }
            if (slug) {
                if (pressRelease && pressRelease.slug !== slug) {
                    setFirstFetch(false)

                }

                setPressRelease(userData.details && userData.details.page_settings.press_release_setting ? userData.details.page_settings.press_release_setting.find(data => data.slug === slug) : null)
            }
            else {
                setPressRelease(userData.details && userData.details.page_settings.press_release_setting && userData.details.page_settings.press_release_setting.length === 1 ? userData.details.page_settings.press_release_setting[0] : null)
            }
            setOriginalUserDetails(userData.details)
        }
    }, [userData, slug]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (pressRelease && !firstFetch) {
            fetchPressReleaseLimit()
            setFirstFetch(true)
        }
    }, [pressRelease]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (pressRelease) {
            fetchData()
        }
    }, [pressReleaseLimit])
    useEffect(() => {
        if(Object.entries(batchedAscend).length > 0 || Object.entries(sentAscend).length > 0 )
        {
            dispatch(UserThunks.getUserDetails())
        }
    }, [batchedAscend, sentAscend]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Only start up the interval if countdown is configured.
        if (seconds !== null) {
            if (batchedLoading === true && batchReleaseCountDown) {
                setSeconds(batchReleaseCountDown)
                return;
            }
            const interval = setInterval(() => {
                if (seconds <= 0) {
                    setSeconds(batchReleaseCountDown)
                    fetchData(false, false)
                } else {
                    setSeconds(prevState => prevState - 1)
                    console.log(seconds + ' seconds before reloading press release data.')
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [seconds, batchedLoading]);

    return (
        <>
            {pressRelease && getPressReleaseInterface()}
        </>
    )
}

export default PressReleasePage
