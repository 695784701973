import React from 'react'
import styled from 'styled-components'
import { Modal, Form, Input, Button, notification } from 'antd'
import { addProductTemplateRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'

const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`
const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
`
const processTextRules = [
  {
    required: true,
    message: 'Cannot be blank'
  },
  {
    max: 23,
    message: 'Must be less than 23 characters'
  }
]

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function EditForm(props) {
  const {
    form,
    process,
    onSave,
    processID,
    hideModal,
    productTemplates,
    setProductTemplates,
  } = props

  const {
    getFieldDecorator,
    isFieldTouched,
    getFieldError,
    getFieldsError,
    getFieldsValue
  } = form

  const handleSubmit = e => {
    e.preventDefault()
    
    const data = getFieldsValue()

    const findOptions = productTemplates.some(datap => datap.name.toLowerCase() === data.template_name.toLowerCase())
    if(findOptions){
      notification.error({ message: "Product Template Already Exist!" })
      return
    }
    ZenSmartAPI.post( addProductTemplateRoute , { name: data.template_name }).then(res => {
        notification.success({ message: "Product Template Successfully Added!" })
        setProductTemplates(res.data.data)
        const newData = res.data.data
        const savedData = newData.find( datau => datau.name.toLowerCase() === data.template_name.toLowerCase() )
        onSave(savedData)
        hideModal()
      })

  }

  const processTextError = isFieldTouched('text') && getFieldError('text')

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <StyledFormItem
        label="Name"
        validateStatus={processTextError ? 'error' : ''}
        help={processTextError || ''}
      >
        {getFieldDecorator('template_name', {
          initialValue: process && process.template_name,
          rules: processTextRules
        })(<Input placeholder="Product Template Name" />)}
      </StyledFormItem>

      <ButtonContainer>
        <Button onClick={hideModal}>Cancel</Button>

        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
        >
          Add Template
        </Button>
      </ButtonContainer>
    </Form>
  )
}

const ProductTemplateUpdateForm = Form.create({ name: 'Product Template Item Form' })(EditForm)

function ProductTemplateUpdateModel(props) {
  const { process, onSave, processID, productTemplates, setProductTemplates, ...modal } = props
  return (
    <Modal {...modal}>
      <ProductTemplateUpdateForm
        process={process}
        onSave={onSave}
        processID={processID}
        hideModal={modal.onCancel}
        productTemplates={productTemplates}
        setProductTemplates={setProductTemplates}
      />
    </Modal>
  )
}

export default ProductTemplateUpdateModel