import React from 'react';
import * as S from './TableStyled';
const AssociateTable = ({
    columns,
    dataSource,
    rowKey,
    loading,
    handleRow
}) => {
    const uniqueItems = [];
    const userNamesWithTotal = new Set();

    // Loop through the dataSource and keep track of unique items
    dataSource.forEach(item => {
        const isDuplicate = uniqueItems.some(uniqueItem =>
            uniqueItem.user_name === item.user_name &&
            uniqueItem.department_name === item.department_name
        );
        if (item.user_name !== "Total" && item.user_name.includes("Total")) {
            userNamesWithTotal.add(item.user_name);
            item.department_name = "Total";
        }

        if (!isDuplicate) {
            uniqueItems.push(item);
        }
        return uniqueItems
    });   
     const filteredDataSource = uniqueItems.filter(item => {
        if (item.user_name !== "Total" && item.user_name.includes("Total")) {
            return userNamesWithTotal.has(item.user_name);
        }
        return true;
    });
    
    return (
        <S.TableStyled
            columns={columns}
            rowKey={rowKey}
            dataSource={filteredDataSource}
            scroll={{ x: 'max-content' }}
            pagination={false}
            loading={loading}
            onRowClick={(targetRow) => (targetRow?.row?.id != null && targetRow?.row?.header == true && handleRow(targetRow?.row?.id))}
        />
    );
}
export default AssociateTable;