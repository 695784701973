import React, { useState, useEffect, useLayoutEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, Input, Modal, Form, Button , TreeSelect, DatePicker, Space } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenSmartAPI } from 'utils'
import moment from 'moment'
import { getAllDepartments, productFlowReporterRoute, getQaFailFormRoute , scanTypesRoute , getRunProductsRoute} from "utils/apiRoutes"
import { productFlowReporterInitialData } from "utils/panelsInitialData"
import { Label } from 'components/zensmart-design-system/components/Label/Label.js';
import { alphabeticalData } from 'utils/sortData'
import { debounce } from "utils/debounce"
import axios from 'axios';


const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`

const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`

const InputHeader = styled.span`
  font-weight: bold;
`

const ModalStyle = createGlobalStyle`
.ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;

}
`
const { Option } = Select;

const ProductFlowReporter = (props) => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [menu, setMenu] = useState([]);
    
    const [originalData, setOriginalData] = useState([]);
    const [filterStagesData, setFilterStagesData] = useState([])
    const [departments, setDepartments] = useState([])
    const [scans, setScans] = useState([])
    const [ascend, setAscend] = useState(productFlowReporterInitialData)
    const [selectAction, setSelectAction] = useState(undefined)
    const [selectedCheckBoxValue, setSelectedCheckBoxValue] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [formValues, setFormValues] = useState(null)
    const [productData, setProductData] = useState(null)
    const dateFormat = 'YYYY/MM/DD';
    const [payload, setPayload] = useState({
        productGroup : null,
        block: "all",
        dateType : "created",
        dateRange : [moment(moment().subtract(7,'d'), dateFormat), moment(moment(), dateFormat)],
    })
    
    const [paginationNumber, setPaginationNumber] = useState(1)
    const [isPageSelectAll, setIsPageSelectAll] = useState(false)
    const paginationSize = 500
    const [filteredStagesDropdown, setFilteredStagesDropdown] = useState([])
    const [filtersSearchValue, setFiltersSearchValue] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [initialLoad, setInitialLoad] = useState(true)
    const [requestCancel, setRequestCancel] = useState({ cancel: undefined })
    

    const FailForm = Form.create({ name: 'FailForm' })(props => {
        const { form } = props
        const { getFieldDecorator, getFieldsValue, getFieldValue, validateFields, setFieldsValue } = form
        
        const handleSaveChanges = (evt) => {
            evt.preventDefault()
            validateFields((err, values) => {
                if (!err) {
                    const payload = getFieldsValue();
                    setFieldsValue({
                        ...payload,
                        loading: true
                    })
                    var queryStringID = ''
                    Object.keys(selectedCheckBoxValue).map((data, key) => {
                        if (selectedCheckBoxValue[data]) {
                            queryStringID = key === 0 ? queryStringID + `${data}` : queryStringID + `;${data}`
                        }
                        return data
                    })

                    ZenSmartAPI.post(getQaFailFormRoute(queryStringID.replace(/;$/, "").replace(/^;/, "")), payload)
                        .then((res) => {
                            notification.success({ message: "Save block successful!" })
                            setFieldsValue({
                                ...payload,
                                loading: false
                            })
                            hideModal()
                            setSelectedCheckBoxValue({})
                            setIsPageSelectAll(false)
                            fetchData();
                        })
                        .catch((res) => {
                            setLoading(false);
                            notification.error({ message: "Failed saving a block!" })
                            setFieldsValue({
                                ...payload,
                                loading: false
                            })

                        })
                }
            });

        }
        const getFormSelectedValue = (value) => {
            const getSelected = value.find((item) => {
                return item.selected === true
            })
            if (getSelected !== undefined) {
                return getSelected.value
            }
        }

        

        return (
            <>
                {formValues &&
                    <>
                        <p style={{ textAlign: "center" }}>
                            <Label status="blue" >{formValues.message}</Label>
                        </p>

                        <Form onSubmit={handleSaveChanges}>
                            <div style={{ padding: "10px 50px" }}>

                                {formValues ? formValues.page_definitions.sections[0].section_data.map((row, key) =>

                                    <Form.Item label={<InputHeader>{row.label}</InputHeader>} >
                                        {getFieldDecorator(row.field, {
                                            initialValue: row.options ? getFormSelectedValue(row.options) : null,
                                            rules: row.required === true ? [{ required: true, message: `Please input this feld!` }] : undefined
                                        })(
                                            row.data_type === "SELECT" ?
                                                <Select optionFilterProp="children"
                                                    showSearch placeholder={row.label} size="large"
                                                >
                                                    {row.options.map((data, key) =>
                                                        <Option value={data.value}>{data.text}</Option>
                                                    )}
                                                </Select>
                                                :
                                                <Input
                                                    placeholder={row.required === true ? "Please Input This Field" : "*Optional"}
                                                    size="large"
                                                />
                                        )}
                                    </Form.Item>
                                ) : null}
                                <Form.Item >
                                    {getFieldDecorator("loading", {
                                        initialValue: false,
                                    })(
                                        <Input
                                            style={{ display: "none" }}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                            <Row type="flex" justify="center" align="middle">
                                <Col span={5} style={{ padding: 10 }}>
                                    <Form.Item>
                                        <Button type="danger" style={{ width: "100%" }} onClick={hideModal}
                                        >Cancel</Button>
                                    </Form.Item>

                                </Col>
                                <Col span={7} style={{ padding: 10 }} >
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={getFieldValue('loading')}
                                            style={{ width: "100%" }}
                                        >
                                            Save Changes
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }
            </>
        )
    })

    const fetchDepartments = () => {

        setLoading(true)
        ZenSmartAPI.get(getAllDepartments)
            .then((res) => {
                setLoading(false)
                setDepartments(res.data.data)
            })
            .catch((res) => {
                setLoading(false)
            })

    }

    const fetchScans = (filter = null) => {

        setLoading(true)
        ZenSmartAPI.get(scanTypesRoute(filter), {})
        .then((res) => {
            setLoading(false)
            setScans(res.data.data)
        })
        .catch((res) => {
            setLoading(false)
        });
    }

    const hideModal = () => {
        setShowModal(false)
        setFormValues(null)
        setSelectAction(undefined)
    }

    const fetchData = () => {
        setItemData([])
        setLoading(true)
        const CancelToken = axios.CancelToken;

        if (requestCancel.cancel !== undefined) {
            requestCancel.cancel("cancel request");
        }
        const dateRange = payload.dateRange.map(function (data){
                        return moment(data).format("YYYY-MM-DD")
                    })
        ZenSmartAPI.get(productFlowReporterRoute(`productGroup=${payload.productGroup}&block=${payload.block}&dateRange=${dateRange}&dateType=${payload.dateType}`), {
            cancelToken: new CancelToken(function executor(c) {
                setRequestCancel({ ...requestCancel, cancel: c })
            }),
        })
            .then((res) => {
                
                setItemData(res.data.data)
                setTotalCount(res.data.data.data.length)
                setOriginalData(res.data.data)
                setAscend(productFlowReporterInitialData)
                setIsPageSelectAll(false)

                if (res.data.data.data) {
                    const filteredStages = [];
                    res.data.data.data.map(data => {
                        if (!filteredStages.includes(data.stage)) {
                            filteredStages.push(data.stage)
                        }
                        return data
                    })
                    setFilteredStagesDropdown(alphabeticalData(filteredStages))
                }
                setLoading(false)

            })
            .catch((error) => {
                //setLoading(false)
            })
    }

    

    

    const checkPaginationIfAllSelected = (value) => {
        setPaginationNumber(value)
        const checkIfNotAllSelected = data.slice(paginationSize * value - paginationSize, (paginationSize * value)).some((item, key) => {
            const ifSelectedItem = selectedCheckBoxValue[item.id];
            return ifSelectedItem ? false : true;
        })
        setIsPageSelectAll(checkIfNotAllSelected ? false : true)
    }

    const searchOperation = debounce(async (value, source) => {
        if (value === '') {
            setItemData(source)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                            false :
                            anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) : anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
                return containsValue
            })
            setItemData(result)
        }
        setFiltersSearchValue(value)
    }, 500)

    const editPayload = (value, property) => {
        if(property == 'dateRange'){
            value = value.map(function (data){
                return moment(data).format("YYYY-MM-DD")
            })
        }

        if(property == 'productGroup'){
            var output = [];
            value = value.map(function (data){
                console.log('data',data)
                if((data.includes('primary') === true) ||  
                (data.includes('secondary') === true) || 
                (data.includes('tertiary') === true) || 
                (data.includes('department') === true) ){
                    if(data.includes('primary')){
                        let dataArr = data.split('-')
                        output.push({'primary_group_name': dataArr[1].replace('_primary','') , 'department_name': dataArr[0]});
                    }else if(data.includes('secondary')){
                        let dataArr = data.split('-')
                        output.push({'secondary_group_name':dataArr[2].replace('_secondary','') ,'primary_group_name': dataArr[1], 'department_name': dataArr[0]});
                    }else if(data.includes('tertiary')){
                        let dataArr = data.split('-')
                        output.push({'tertiary_group_name' : dataArr[3].replace('_tertiary','') ,'secondary_group_name':dataArr[2] ,'primary_group_name': dataArr[1], 'department_name': dataArr[0]});
                    }else if(data.includes('department')){
                        output.push({'department_name': data.replace('_department','') });
                    }
                }else{
                    output.push({'code': data.replace('product_','') });
                }
            })
            value = JSON.stringify(output);
        }
        setPayload({ ...payload, [property]: value })
        fetchData()
    }
    const { RangePicker } = DatePicker;
    
   

    // Brought this function here so form data can be fetched before a user clicks on the add runs button
    const fetchFormSelectionData = () => {
        
        try {
            ZenSmartAPI.get(getRunProductsRoute)
            .then((apiResponse) => {

                let obj = {}
                apiResponse.data.data.forEach(item => {
                    const department = item.department_name
                    const primary = item.primary_product_group_name
                    const secondary = item.secondary_product_group_name
                    const tertiary = item.tertiary_product_group_name
                    obj[department] = obj[department] ? obj[department] : { [primary]: {} }
                    
                    obj[department][primary] = obj[department][primary] ? obj[department][primary] : { [secondary]: {} }
            
                    obj[department][primary][secondary] = obj[department][primary][secondary]
                      ? obj[department][primary][secondary]
                      : { [tertiary]: [] }
                    obj[department][primary][secondary][tertiary] = obj[department][primary][secondary][tertiary]
                      ? [
                          ...obj[department][primary][secondary][tertiary],
                          { title: item.name, key: item.code , value:  'product_'+item.code }
                        ]
                      : [{ title: item.name, key: item.code , value:  'product_'+item.code }]
                })
                let arr = []
                // Using TOPLEVEL keyword to be able to filter and remove the top level product code in the array - only the value of last element in the product tree are needed later on
                Object.entries(obj).forEach(item => {
                const first = {
                    title: (item[0] === 'null' ? "None" : item[0]),
                    key: `${item[0]}`,
                    value: `${item[0]}_department`,
                    children: []
                }
                Object.entries(item[1]).forEach(item2 => {
                    const second = {
                    title: (item2[0] === 'null' ? "None" : item2[0]),
                    key: `${first.key}-${item2[0]}`,
                    value: `${first.key}-${item2[0]}_primary`,
                    children: []
                    }
                    Object.entries(item2[1]).forEach(item3 => {
                    const third = {
                        title: (item3[0] === 'null' ? "None" : item3[0]),
                        key: `${second.key}-${item3[0]}`,
                        value: `${second.key}-${item3[0]}_secondary`,
                        children: []
                    }
                    Object.entries(item3[1]).forEach(item4 => {
                        const fourth = {
                            title: (item4[0] === 'null' ? "None" : item4[0]),
                            key: `${third.key}-${item4[0]}`,
                            value: `${third.key}-${item4[0]}_tertiary`,
                            children: []
                        }
                        Object.entries(item4[1]).forEach(item5 => {
                            fourth.children.push(item5[1])   
                        })
                        third.children.push(fourth)
                    })
                    second.children.push(third)
                    })
                    first.children.push(second)
                })
                    arr.push(first)
                })
                
                setProductData(arr)
            })
            .catch((res) => {
                setLoading(false)
            })    
        } catch (error) {
            console.log({ error })
        }
    }


    useEffect(() => {
        fetchDepartments()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchScans()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useLayoutEffect(() => {
        setLoading(true)
        fetchFormSelectionData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(initialLoad === true){
            setInitialLoad(false)
        }
        if(initialLoad === false){
            fetchData()
        }
    }, [payload]) // eslint-disable-line react-hooks/exhaustive-deps
    
    
    return (
        <>
            <Header>
                <Title>Performance {">"} Product Flow Reporter</Title>
            </Header>
            <Panel title="PRODUCT FLOW REPORTER" styles={{ width: 'max-content' , minWidth : '100%' }}>
                <div style={{ padding: 40 }}>
                    <Row type="flex" align="middle">
                        <InputStyle />
                        <Col style={{ padding: 10 , width: 250 }}>
                            <TreeSelect
                                checkable
                                treeCheckable="true"
                                showCheckedStrategy="SHOW_PARENT"
                                placeholder={(productData  ? "Please select product" : "Loading...")}
                                onChange={(value) => editPayload(value, "productGroup")} 
                                treeData={productData}
                                loading={loading}
                                style= {{
                                    width: '100%',
                                }}
                            />
                        </Col>
                        <Col  style={{ padding: 5, width: 150  }}>
                            <Select placeholder="Select Blocks" onChange={(value) => editPayload(value, "block")} value={payload.block ? payload.block : undefined} style={{ width: "100%" }} >
                                <Option value="all">All</Option>
                                <Option value="text">Text Only</Option>
                                
                            </Select>
                        </Col>
                        <Col  style={{ padding: 5 , width: 150 }}>
                            <Select value={payload.dateType ? payload.dateType : undefined} onChange={(value) => editPayload(value, "dateType")} style={{ width: "100%" }} >
                                <Option value="created">Created At</Option>
                                <Option value="printed">Printed At</Option>
                                <Option value="completed">Completed At</Option>
                            </Select>
                        </Col>
                        <Col  style={{ padding: 5 , width: 250 }}>
                            <RangePicker 
                            defaultValue={payload.dateRange}
                            onChange={(value) => editPayload(value, "dateRange")}
                            format={dateFormat}
                            />
                        </Col>
                    </Row>
                    <div style={{ padding: 10, marginBottom : "-30px", fontWeight: "bold" }}>
                        <Heading>
                            TOTAL COUNT :  { ( (loading === true) ? 'Loading...' :  totalCount )}
                        </Heading>
                    </div>
                </div>
                <TableData
                    itemData={itemData} loading={loading} setLoading={setLoading} data={data} setData={setData} ascend={ascend} setAscend={setAscend}
                    selectedCheckBoxValue={selectedCheckBoxValue} setSelectedCheckBoxValue={setSelectedCheckBoxValue} paginationSize={paginationSize}
                    checkPaginationIfAllSelected={checkPaginationIfAllSelected} paginationNumber={paginationNumber} scans={scans} menu={menu} setMenu={setMenu}
                />
                <>
                    <ModalStyle />
                    <Modal
                        title=""
                        centered
                        width={650}
                        visible={showModal}
                        footer={null}
                        onCancel={hideModal}
                    >
                        <div style={{ padding: 20 }}>
                            <FailForm />
                        </div>
                    </Modal>
                </>

            </Panel>
        </>
    )
}

export default ProductFlowReporter