import { SCAN_MENU} from "../actions/scanTypes";

const initialState = {
    scan_menu: [],
    
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SCAN_MENU:
      return {
        ...state,
        scan_menu: action.payload
      };

    
    default:
      return state;
  }
}