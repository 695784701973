import React, { useState, useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import { Row, Col, notification, Checkbox, Button, Input, Modal, Select, Switch } from 'antd'
import TableData from './TableData';
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { dataListRoute } from "utils/apiRoutes"
import { debounce } from "utils/debounce"
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { DataViewActionForm } from "components/sections";
import Axios from 'axios';

const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}
.saved-report .ant-table-bordered .ant-table-thead > tr > th, .saved-report .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: left !important;
}

`

const DataListPage = (props) => {
    const { model, select, hidden, link, pageSize, adjacentButton, addListButton, actionButtons = [], refreshDataInterval, addColumns = [], customizedButton = [], clickableFunctions = {},
        setOutSourceParameters = null, setOutSourceFetchData = null, queries, sort } = props
    const [itemData, setItemData] = useState([])
    const [columns, setColumns] = useState([])
    const [loading, setLoading] = useState(false)
    const [originalData, setOriginalData] = useState([]);
    const [ascend, setAscend] = useState({})
    const [sortingInitialData] = useState({})
    const [addFormValues, setAddFormValues] = useState(null)
    const [addListModal, setAddListModal] = useState(false)
    const [editFormValues, setEditFormValues] = useState(null)
    const [editListModal, setEditListModal] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [searchCurrentPage, setSearchCurrentPage] = useState("")
    const [searchProperty, setSearchProperty] = useState("")
    const [searchAscend, setSearchAscend] = useState(true)
    const [editListActionApi, setEditListActionApi] = useState(null)
    const [editListId, setEditListId] = useState(null)
    const [filterSearchValue, setFilterSearchValue] = useState("")
    const [tableProperties, setTableProperties] = useState(null)
    const [currentPage, setCurrentPage] = useState(null)
    const [labels, setLabels] = useState([])
    const [requestCancel, setRequestCancel] = useState({ cancel: null })
    const { searchFields } = props;
    const InitCancelToken = Axios.CancelToken;

    const fetchData = (currentPage = null, property = null, ascend = true, searchFilter = "") => {
        setLoading(true)
        setSearchValue(searchFilter)
        setSearchCurrentPage(currentPage)
        setSearchProperty(property)
        setSearchAscend(ascend)

        const CancelToken = Axios.CancelToken;

        if (requestCancel.cancel !== null) {
            requestCancel.cancel("cancel request");
        }

        const payload = {
            model: model,
            select: select ? select : [],
            limit: pageSize,
            sort: property ? {
                column: property,
                ascend: ascend
            } : sort ? {column: sort, ascend: true } : null,
            search_filters: searchFilter !== "" ? {
                search_columns: searchFields ? searchFields : labels,
                value: searchFilter
            } : null,
            queries: queries ? queries : [],
        }

        ZenSmartAPI.post(dataListRoute(currentPage), payload, {
            cancelToken: new CancelToken(function executor(c) {
                setRequestCancel({ ...requestCancel, cancel: c })
            })
        })
            .then((res) => {
                setLoading(false)
                setItemData(res.data.data.data)
                setOriginalData(res.data.data.data)
                setTableProperties(res.data.data)

                if (res.data.data.data.length > 0) {
                    setLabels(Object.keys(res.data.data.data[0]))
                }
            })
            .catch((error) => {
                setLoading(false);
                // handle validation errors

                if (_.get(error, 'response.status') === 400) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An unhandled error occurred. Please contact your supervisor.'),
                    });
                }

                if (_.get(error, 'response.status') > 499) {
                    notification.error({
                        message: 'An unhandled error occurred. Please contact your supervisor'
                    });
                }
            })
    }

    const getSortFunction = (page, value = "") => {
        const getSort = Object.keys(ascend).find(data => ascend[data] === false || ascend[data] === true)
        if (getSort) {
            fetchData(page, getSort, ascend[getSort] ? false : true, value)
        }
        else {
            fetchData(page, null, null, value)
        }
    }

    const searchOperation = debounce(async (value, source) => {
        if (value === "") {
            getSortFunction(currentPage, value)
        }
        else {
            getSortFunction(1, value)
        }
        setFilterSearchValue(value)

    }, 500)

    const sortingData = (data, property, ifNotProperty, labels, ascend, setAscend) => {
        if (ascend[property] === null) {
            setAscend({ ...labels, [property]: true })
        }
        else {
            ascend[property] === true ? setAscend({ ...labels, [property]: false }) : setAscend({ ...labels, [property]: true })
        }
        return fetchData(currentPage, property, ascend[property] === undefined || ascend[property] === null ? false : ascend[property], filterSearchValue)
    }

    const getHeaders = (property, text, ifAscend, itemData, labels, ascend, setAscend, setItemData) => {
        return (
            <Row type="flex" align="middle" onClick={() => setItemData(sortingData(itemData, property, ifAscend, labels, ascend, setAscend))} style={{ cursor: "pointer" }}>
                <Col span={ascend[property] === null || ascend[property] === undefined ? 24 : 20}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }

    const successAddListCallback = () => {
        setAddListModal(false)
        getSortFunction(currentPage, filterSearchValue)
    }

    const addListFunction = () => {

        setLoading(true)
        ZenSmartAPI.get(addListButton.actionApi)
            .then((res) => {
                setAddFormValues(res.data)
                setAddListModal(true)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false);
                if (error.response && error.response.data) {
                    notification.error({ message: error.response.data.message })
                }
                else {
                    notification.error({ message: "Something went wrong" })
                }
            })
    }

    const editListFunction = (row, options) => {
        ZenSmartAPI.get(`${options.actionApi}${row.id}`)
            .then((res) => {
                setEditFormValues(res.data)
                setEditListModal(true)
                setLoading(false)
                setEditListActionApi(`${options.updateEditApi}${row.id}`)
                setEditListId(row.id)
            })
            .catch((error) => {
                setLoading(false);
            })
    }

    const normalActionFunction = async (row, options) => {

        options.actionFunction(row, fetchData)
    }

    const mapURL = (values) => {
        return <a href={`/${values.link}`} >
            {(values.value === false || values.value === true) ? <Checkbox checked={values.value}></Checkbox> : values.value !== null ? values.value : "-"}
            {"\n"}</a>
    }

    const mapToUpdatePayload = (values) => {
        const payload = Object.keys(values).map((valueKey) => {
            const [resource] = valueKey.split(':')
            const dataValues = Object.values(values[valueKey]).map(item => (
                Object.entries(item).map(([field, value]) => {
                    if (value !== undefined && value !== null) {
                        value = value && value.toDate ? value.toDate() : value + ''
                    }
                    return { field, value }
                })
            ))
            const data = dataValues[0]
            return { resource, id: editListId ? editListId.toString() : null, data }
        })
        return { payload }
    }

    const columnValues = (value, data, row) => {

        if (value && value.type && value.type === 'clickable') {
            return <a onClick={() => clickableFunctions[data](row, fetchData)} style={{ color: "blue", textDecorationLine: "underline" }}>{value.value}</a>
        }
        else if (value && value.type && value.type === 'switch') {
            return <Switch onChange={() => clickableFunctions[data](row, fetchData)} checked={value.value} />
        }
        else if (data === "id") {
            return <a href={`/${link}${value}`} >{(value === false || value === true) ? <Checkbox checked={value}></Checkbox> : value !== null ? value : "-"}</a>
        }
        else if (value === false || value === true) {
            return <Checkbox checked={value}></Checkbox>
        }
        else if (value !== null) {
            return value
        }
        else {
            return '-'
        }
    }

    useEffect(() => {
        if (refreshDataInterval) {
            const interval = setInterval(() => {
                fetchData(searchCurrentPage, searchProperty, searchAscend, searchValue)
            }, refreshDataInterval);
            return () => clearInterval(interval);
        }
        const parameters = {
            searchCurrentPage,
            searchProperty,
            searchAscend,
            searchValue
        }

        setOutSourceParameters && setOutSourceParameters(parameters)


    }, [searchCurrentPage, searchProperty, searchAscend, searchValue]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchData()
    }, [link]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (labels.length > 0) {
            setOutSourceFetchData && setOutSourceFetchData({ refresh_data: fetchData })
        }
    }, [labels]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setOutSourceFetchData && setOutSourceFetchData({ refresh_data: fetchData })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (labels.length > 0) {
            setOutSourceFetchData && setOutSourceFetchData({ refresh_data: fetchData })
        }
    }, [labels]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setOutSourceFetchData && setOutSourceFetchData({ refresh_data: fetchData })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (itemData && itemData.length > 0) {
            const arrayData = [];
            const sortingInitialData = {}

            Object.keys(itemData[0]).map(item => {
                sortingInitialData[item] = null
                return item
            })
            Object.keys(itemData[0]).map(data => {
                if ((hidden && hidden.indexOf(data) === -1) || !hidden) {
                    arrayData.push({
                        title: getHeaders(data, data.replace(/_/g, ' ').toUpperCase(), false, itemData, sortingInitialData, ascend, setAscend, setItemData),
                        dataIndex: data,
                        key: data,
                        width: `${data.length > 2 ? undefined : 60}px`,
                        render(value, row, index) {
                            const odd = index % 2
                            const obj = {
                                props: {
                                    style: { background: "#f1f3ff", border: "none", },
                                },
                                children: <div>
                                    {Array.isArray(value) === true ?
                                        value.map(mapURL)
                                        : <div>
                                            {columnValues(value, data, row)}
                                        </div>
                                    }
                                </div>,
                            };

                            if (!odd) {
                                obj.props.style = { border: "none" }
                            }

                            return obj
                        },
                    })
                }
                return true
            })

            if (actionButtons.length > 0) {
                arrayData.push({
                    title: 'ACTIONS',
                    key: 'operation',
                    width: actionButtons.length === 1 ? 200 : actionButtons.length * 100,
                    render: (value, row, index) => {
                        const odd = index % 2
                        const obj = {
                            props: {
                                style: { background: "#f1f3ff", border: "none", },
                            },
                            children: < Row type="flex" align="middle" justify="center" >
                                {
                                    actionButtons.map(action => {
                                        return (
                                            (action.hidden ? <></> : <Col style={{ padding: 5 }}>
                                                {action.buttonAction === "edit" ?
                                                    <Button type={action.buttonType ? action.buttonType : "primary"} style={{ width: "100%" }}
                                                        onClick={() => editListFunction(row, action)}>{action.buttonLabel}</Button>
                                                    : <Button type={action.buttonType ? action.buttonType : "primary"} style={{ width: "100%" }}
                                                        ghost={action.ghost} onClick={() => normalActionFunction(row, action)}>{typeof action.buttonLabel === "string" ?
                                                            action.buttonLabel :
                                                            (action.buttonLabel.type === "boolean" && !row[action.buttonLabel.field]) ? action.buttonLabel.ifTrueButtonLabel :
                                                                (action.buttonLabel.type === "boolean" && row[action.buttonLabel.field]) ? action.buttonLabel.ifFalseButtonLabel :
                                                                    action.buttonLabel}</Button>}
                                            </Col>)
                                        )
                                    })
                                }
                            </Row >,
                        };
                        if (!odd) {
                            obj.props.style = { border: "none" }
                        }

                        return obj
                    },
                })
            }
            setColumns(arrayData);
        }

    }, [itemData, ascend]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <TableStyling />
            <Row type="flex" align="middle" justify="start" style={{ padding: 20 }} >
                <Col span={6} style={{ padding: 5 }}>
                    <Input.Search
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Filters.."
                        onChange={(value) => searchOperation(value.target.value, originalData)}
                        loading={loading}
                    />
                </Col>
                {addColumns.length > 0 &&
                    addColumns.map(data => {
                        return <Col span={data.columnSize} style={{ padding: 5 }}>
                            {data.htmlValue}
                        </Col>
                    })
                }
                {customizedButton.map(item => {
                    return <Col offset={item.offset} style={{ padding: 5 }} span={item.span}>
                        <p style={{ textAlign: "center" }}>
                        </p><Button type="primary" loading={loading} onClick={() => { item.actionFunction(fetchData) }} style={{ minWidth: "100%" }}>{item.buttonLabel}</Button>
                    </Col>
                })}
                {addListButton &&
                    <Col offset={addListButton.offset} style={{ padding: 5 }} span={addListButton.span}>
                        <p style={{ textAlign: "center" }}>
                        </p><Button type="primary" loading={loading} onClick={addListFunction} style={{ minWidth: "100%" }}>{addListButton.buttonLabel}</Button>
                    </Col>
                }

            </Row>
            <div style={{ padding: 0 }}>
                <TableData loading={loading}
                    pageSize={pageSize}
                    data={itemData}
                    sortingInitialData={sortingInitialData}
                    columns={columns}
                    link={link}
                    setData={setItemData}
                    ascend={ascend}
                    setAscend={setAscend}
                    tableProperties={tableProperties}
                    fetchData={fetchData}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    getSortFunction={getSortFunction}
                    filterSearchValue={filterSearchValue}
                />
                <Modal visible={addListModal} footer={null} width={1300}>
                    {addFormValues && addListModal && addListButton && (
                        <DataViewActionForm
                            actionValue={addFormValues}
                            setShowModalForm={setAddListModal}
                            actionApi={addListButton && addListButton.actionApi ? addListButton.actionApi : null}
                            modifyPayload={mapToUpdatePayload}
                            successCallBack={successAddListCallback}
                        />
                    )}
                </Modal>
                <Modal visible={editListModal} footer={null} width={1300}>
                    {editListModal && editFormValues && actionButtons.length > 0 && (
                        <DataViewActionForm
                            actionValue={editFormValues}
                            setShowModalForm={setEditListModal}
                            actionApi={editListActionApi ? editListActionApi : null}
                            modifyPayload={mapToUpdatePayload}
                            successCallBack={successAddListCallback}
                            ifEdit={true}
                        />
                    )}
                </Modal>
            </div>
        </>
    )
}

export default DataListPage