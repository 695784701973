import React, { useState } from 'react'
import { Label, InputFieldContainer, ErrorText } from './index.js'
import { Select, Input as AntInput, Transfer, Table } from 'antd'

import { MenuOutlined } from '@ant-design/icons'
import arrayMove from 'array-move'
import {
  SortableContainer as SortContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'

const { Option } = Select

const TableTransfer = ({ setTargetKeys, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({ direction, filteredItems, onItemSelect, selectedKeys }) => {
      const { targetKeys } = restProps

      const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
      ))
      const SortableItem = SortableElement(props => <tr {...props} />)
      const SortableContainer = SortContainer(props => <tbody {...props} />)

      const rowSelection = {
        getCheckboxProps: item => {
          // console.log(item, 'abcdef')
        },
        // getCheckboxProps: item => ({ disabled: item.disabled }),
        onSelectAll (selected, selectedRows) {
          // console.log({ selected, selectedRows })
        },
        onSelect ({ key }, selected) {
          onItemSelect(key, selected)
        },
        selectedRowKeys: selectedKeys
      }

      const onSortEnd = ({ oldIndex, newIndex }) => {
        const newData = arrayMove(targetKeys, oldIndex, newIndex)
        setTargetKeys(newData)
      }

      const DraggableContainer = props => (
        <SortableContainer
          useDragHandle
          disableAutoscroll
          helperClass='row-dragging'
          onSortEnd={onSortEnd}
          {...props}
        />
      )

      const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = targetKeys.findIndex(x => x === restProps['data-row-key'])
        return (
          <SortableItem
            index={index}
            style={{
              width: '100%',
              zIndex: 1000000,
              visibility: 'visible !important'
            }}
            {...restProps}
          />
        )
      }

      const rightTableColumns = [
        {
          dataIndex: 'title',
          title: 'Type'
        },
        {
          dataIndex: 'sort',
          // className: 'drag-visible',
          width: 30,
          title: '',
          render: () => <DragHandle />
        }
      ]
      const leftTableColumns = [
        {
          dataIndex: 'title',
          title: 'Type'
        }
      ]

      const columns =
        direction === 'left' ? leftTableColumns : rightTableColumns

      return (
        <Table
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          // rowKey='index'
          size='small'
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
          onRow={({ key }) => ({
            onClick: () => {
              onItemSelect(key, !selectedKeys.includes(key))
            }
          })}
        />
      )
    }}
  </Transfer>
)

const Input = ({
  label,
  type,
  onChange,
  value,
  children,
  example,
  placeholder,
  disabled,
  options,
  error,
  mode
}) => {
  const [targetKeys, setTargetKeys] = useState(value ?? [])
  const [selectedKeys, setSelectedKeys] = useState([])

  const onTransferChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys)
    onChange(nextTargetKeys)
  }

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  return (
    <InputFieldContainer error={error}>
      <Label>{label}</Label>
      {example && (
        <small>
          <i>{example}</i>
        </small>
      )}
      {type === 'select' ? (
        <Select
          mode={mode}
          allowClear={mode === 'multiple' ? true : false}
          value={value}
          style={{ width: '100%', height: 35 }}
          onChange={onChange}
        >
          {options.map((item, itemIndex) => (
            <Option
              key={itemIndex}
              value={item.value ?? item.device ?? item.class}
            >
              {item.label ?? item.device ?? item.class}
            </Option>
          ))}
        </Select>
      ) : type === 'custom' ? (
        <div>{children}</div>
      ) : type === 'transfer' ? (
        <Transfer
          dataSource={options}
          titles={['Available', 'Selected']}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={onTransferChange}
          onSelectChange={onSelectChange}
          render={item => item.title}
        />
      ) : type === 'transfer-fix' ? (
        <>
          <TableTransfer
            dataSource={options}
            targetKeys={targetKeys}
            setTargetKeys={setTargetKeys}
            selectedKeys={selectedKeys}
            onSelectChange={onSelectChange}
            onChange={onTransferChange}
            showSearch={false}
          />
        </>
      ) : (
        <AntInput
          type={type ?? 'text'}
          disabled={disabled}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          allowClear={true}
        />
      )}
      {error === true && <ErrorText>* Required field</ErrorText>}
    </InputFieldContainer>
  )
}

export default Input
