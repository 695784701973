import React, { useEffect, useState } from 'react';
import Table from '../components/Table/Table';
import { buildRow } from '../components/Table/TableUtilities';
import { TABLE_COLUMNS } from '../components/Table/HourlyReportTableHeaderConstants';

const HourlyLinearFeetProgressReportTable = ({
    activeRows,
    loading,
    data,
    handleRow
}) => {
    const [dataSource, setDataSource] = useState([]);
    const buildDataSource = (data) => {
        return data?.map((data) => {
            const departmentId = (data?.department_id ?? null);
            const departmentName = (data?.department_name ?? null);
            const tertiaryProductGroupId = (data?.tertiary_product_group_id ?? null);
            const tertiaryProductGroupName = (data?.tertiary_product_group_name ?? null);

            const isGrandTotal = (departmentId === null && departmentName.includes('Total'));
            const isDepartmentTotal = (departmentId !== null && departmentName.includes('Total'));
            
            const isHeader = (isGrandTotal || isDepartmentTotal);
            const isHidden = (!activeRows.includes(departmentId) && !isHeader);

            return buildRow(departmentId, isHeader, isHidden, Object.assign(data, {
                title                  : isHeader ? departmentName : null,
                linear_feet_production : tertiaryProductGroupName
            }));
        });
    }

    useEffect(() => {
        setDataSource([]);
        setDataSource(buildDataSource(data));
    }, [data, loading]);

    return (
        <Table
            rowKey='per_hour'
            columns={TABLE_COLUMNS}
            loading={loading}
            dataSource={dataSource}
            handleRow={(id) => handleRow(id)}
        />
    );
}

export default HourlyLinearFeetProgressReportTable;
