import React, {useState} from 'react';
import {Header, Title} from 'components/primaries';
import {DataListPage} from "pages/DataListPage"
import {Panel} from 'components/primaries'
import {Modal, notification} from "antd";
import {ZenSmartAPI} from "../../../utils";
import {getQaFailReason} from "../../../utils/apiRoutes";

const QaFailReasons = () => {
    const deleteQaFailReason = (row, refreshData) => {
        Modal.confirm({
            'content': "Are you sure you want to delete this Qa Fail Reason?",
            onOk: () => {
                ZenSmartAPI.delete(getQaFailReason(row.id))
                    .then((res) => {
                        notification.success({message: "Delete Qa Fail Reason successful!"})
                        refreshData();
                    })
                    .catch((res) => {
                        console.log('res', res);
                        if (res.response.data) {
                            notification.error({message: res.response.data.message})
                        }
                    })
            },
        });
    }
    return (
        <>
            <Header>
                <Title>App Setup {">"} QA Fail Reasons </Title>
            </Header>
            <Panel title="QA FAIl REASONS">
                <DataListPage model="qaFailReasons" select={['id', 'reason', 'created_at', 'updated_at']}
                              link={"app/data-view/qa-fail-reasons/"} hidden={['id']} sort={"reason"} pageSize={15}
                              addListButton={{
                                  buttonLabel: "Add Qa Fail Reason",
                                  actionApi: "/api/v1/data-api/qa-fail-reasons",
                                  offset: 15,
                                  span: 3
                              }}
                              actionButtons={
                                  [{
                                      buttonLabel: "DELETE",
                                      buttonType: "danger",
                                      buttonAction: "normal",
                                      actionFunction: deleteQaFailReason,
                                      reloadData: true
                                  }]
                              }
                />
            </Panel>
        </>
    )
}
export default QaFailReasons