import React, { useState, useCallback, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Row, Col, notification, Table, Button, Modal } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import Cookies from 'js-cookie';
import DispatchLookUp from './DispatchLookup'
import { useDispatch, useSelector } from 'react-redux'
import {
    CheckCircleTwoTone,
    ExclamationCircleTwoTone,
} from '@ant-design/icons';
import { GET_DISPATCH_LINES } from '../../../store/actions/dispatchTypes'
import DispatchPanel from './DispatchPanel/DispatchPanelPage';
import airhorn from 'static/sounds/airhorn.mp3'

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const Container = styled.div`
  padding-bottom: 5px;
  font-family: 'Rubik', sans-serif;
`
const scanInputProps = {
    size: 'large',
    placeholder: 'Item Lookup'
}

const SpanStyle = styled.span`
  font-size: 20px;
  margin-right: 8px;
  color: ${color.heading};
`
const HrStyle = styled.hr`
  background-color : black;
  height : 1px;
  border : 0px;
`
const TableOverrides = createGlobalStyle`
.ant-table-thead > tr:first-child > th:first-child {
    border-top-right-radius: 4px;
    padding-left : 300px;
}
.ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 4px;
    padding-right : 300px;
}
`
const Dispatch = (props) => {
    const isPickUp = props?.type === "pickup" ? true : false;
    const showBin = props?.showBin ? true : false;
    const dispatch = useDispatch()
    const dispatchData = useSelector((state) => state.dispatch.dispatch_items);
    const userData = useSelector((state) => state.user.details);
    const [dispatchStructure, setDispatchStructure] = useState(null)
    const [scannedLines, setScannedLines] = useState({ lines: [] })
    const [scanItem, setScanItem] = useState(null)
    const { scanData } = props
    const scanTypeId = scanData.id;
    const [dispacthModal, setDispatchModal] = useState(false)
    const [showPickupNotesModal, setShowPickupNotesModal] = useState(false);
    const [lineDataSource, setLineDataSource] = useState([])
    const [scanAccessories, setScanAccessories] = useState([])
    const [accessoriesToBeScan, setAccessoriesToBeScan] = useState(undefined)
    const [accessoriesModal, setAccessoriesModal] = useState(false)
    const [dispatchNowCount, setDispatchNowCount] = useState(null);
    const [overrideConfirmed, setOverrideConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);
    const sound = new Audio(airhorn)
    const columns = [
        {
            title: 'Line',
            dataIndex: 'line',
            key: 'line',
            align: "center",
            render(text, record) {
                return {
                    props: {
                        style: { paddingLeft: 300 },
                    },
                    children: <div>{text}</div>,
                };
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: "center",
            padding: "200px",
            render(text, record) {
                return {
                    props: {
                        style: { paddingRight: 300 },
                    },
                    children: <div>  {text !== "not-scanned" ? <CheckCircleTwoTone style={{ verticalAlign: "middle", fontSize: 25 }} /> :
                        <ExclamationCircleTwoTone style={{ verticalAlign: "middle", fontSize: 25 }} twoToneColor="red" />
                    }</div>,
                };
            },
        },
    ];

    const updateDispatchNowCount = (value) => {
      // workaround for firing useEffect on DispatchLookup.js
      setDispatchNowCount(null);
      setTimeout(function () {
        setDispatchNowCount(value);
      }, 3);
    }

    const handleLookup = useCallback((lookupValue) => {
        if (lookupValue.hasOwnProperty('error')) {
            sound.play()
            notification.error({
                message: lookupValue.error
            })
        }

        else if (lookupValue.hasOwnProperty('station_id')) {
            Cookies.set('STATION', lookupValue.station_id, { expires: 1 });
            Cookies.set('SCAN_TYPE', scanTypeId, { expires: 1 });
            Cookies.set("ALLOW_QUIZ", lookupValue?.allow_quiz ?? false, { expires: 1 });
            notification.success({
                message: 'Station scan successful'
            })
        }

        else if (lookupValue.hasOwnProperty('success_scan')) {
            notification.success({
                message: 'Barcode scan successful'
            })
            setScanItem(lookupValue)
        }

        else {
            sound.play()
            notification.error({
                message: 'Invalid scan item. Please scan a valid barcode.'
            })
        }
    }, [])

    const showDispatchwModal = useCallback((value) => {
        value === "cancel" ? setDispatchModal(false) : setDispatchModal(true)
    }, [])

    const removeScanItem = useCallback(() => {
        setScanItem(null)
    }, [])

    const setLineItems = (data) => {
        return data.map(data => {
            const dataobj = { ...data, status: "not-scanned" }
            return dataobj
        })
    }

    const changeDispatchStructureData = useCallback((data) => {
        setLineDataSource(data.line_items.map(data => {
            const dataOjb = {}
            dataOjb.line = data.number
            dataOjb.status = data.status
            return dataOjb
        }))
        setDispatchStructure(data);
    }, [])

    const updateScannedLines = (line) => {
        const scannedItems = scannedLines.lines;
        const checkDuplicate = scannedLines.lines.some(data => {
            return data.number === line.number
        })
        if (!checkDuplicate) {
            scannedItems.push(line);
        }
        setScannedLines({ lines: scannedItems });
    }

    const getTotalOrderCounts = (orders) => {
        let total = 0;
        orders.map(data => {
            total += data.lines.length;
        })

        return total;
    }

    const getScanCounts = (order) => {
        const allLineCount = order.lines.length;
        const lineCount = { value: 0 };
        order.lines.map(data => {
            const lineItems = dispatchStructure.line_items.some(item => {
                return item.number == data.label && item.status === "scanned"
            })
            if (lineItems) {
                lineCount.value = lineCount.value + 1
            }
        })
        return `${lineCount.value}/${allLineCount}`;
    }

    const checkPermission = (permission) => {
        if (userData.permissions) {
            return !!userData.permissions.find(item => {
                return item === permission
            })
        }
        return false;
    }

    const refreshDispatch = useCallback((message = null) => {
        dispatch({
            type: GET_DISPATCH_LINES,
            payload: null
        })
        setAccessoriesModal(false)
        setAccessoriesToBeScan(undefined)
        setDispatchStructure(null)
        setScannedLines({ lines: [] })
        showDispatchwModal("cancel")
        setShowPickupNotesModal(false)
        setOverrideConfirmed(false)
        setDispatchNowCount(null);
        if (message) {
            notification.success({
                message
            })
        }
        setDispatchNowCount(null)
    }, [isPickUp]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        refreshDispatch()
    }, [isPickUp])

    useEffect(() => {
        if (dispatchData) {
            const addressData = dispatchData.page_definitions.sections[0].section_data[1].value
            const dispatchStructureObj = {
                lines: dispatchData.page_definitions.sections[0].section_data[0].value,
                address: addressData.join(', '),
                orders: dispatchData.page_definitions.sections[0].section_data[2].value,
                accessories: dispatchData.page_definitions.sections[0].section_data[3]?.value ?? [],
                transaction: dispatchData.page_definitions.sections[0].section_data[4]?.value ?? [],
                line_items: setLineItems(dispatchData.page_definitions.sections[0].section_data[0].value),
                override_shipping_verification: dispatchData.override_shipping_verification,
                dispatch_confirmation_required: dispatchData.dispatch_confirmation_required,
                allow_change_article_count: dispatchData.allow_change_article_count,
                manual_pickup_id: dispatchData?.manual_pickup_id ?? null,
                post_dispatch_prompts: dispatchData.post_dispatch_prompts,
                pack_types: dispatchData.pack_types ?? null,
                preferred_pack_type: dispatchData.preferred_pack_type ?? null,
                isPickUp: dispatchData.isPickUp ?? false
            }

            setLineDataSource(dispatchStructureObj.line_items.map(data => {
                const dataOjb = {}
                dataOjb.line = data.number
                dataOjb.status = data.status
                return dataOjb
            }))
            setScanAccessories(dispatchStructureObj.accessories)
            setDispatchStructure(dispatchStructureObj)
        }
    }, [dispatchData])

    return (
        <>
            <Container>
                <Header>
                    <Title>{isPickUp ? `People > Scan > Pick Up`: `Pack & Ship > Dispatch`}</Title>
                </Header>
                <div>
                    <DispatchLookUp
                        title={isPickUp ? `Pick Up`: `DISPATCH`}
                        setScanItem={removeScanItem}
                        detailLabel={'PLEASE SCAN ITEM'}
                        onLookup={handleLookup}
                        inputProps={scanInputProps}
                        scanTypeId={scanTypeId}
                        dispatchStructure={dispatchStructure}
                        changeDispatchStructureData={changeDispatchStructureData}
                        scannedLines={scannedLines}
                        updateScannedLines={updateScannedLines}
                        dispacthModal={dispacthModal}
                        showPickupNotesModal={showPickupNotesModal}
                        setShowPickupNotesModal={setShowPickupNotesModal}
                        showDispatchwModal={showDispatchwModal}
                        refreshDispatch={refreshDispatch}
                        scanAccessories={scanAccessories}
                        setScanAccessories={setScanAccessories}
                        setAccessoriesToBeScan={setAccessoriesToBeScan}
                        accessoriesToBeScan={accessoriesToBeScan}
                        setAccessoriesModal={setAccessoriesModal}
                        accessoriesModal={accessoriesModal}
                        dispatchNowCount={dispatchNowCount}
                        setDispatchNowCount={setDispatchNowCount}
                        loading={loading}
                        setLoading={setLoading}
                        isPickUp={isPickUp}
                        showBin={showBin}
                        overrideConfirmed={overrideConfirmed}
                        setOverrideConfirmed={setOverrideConfirmed}
                    >
                        {dispatchStructure ?
                            <Row>
                                <Col xl={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }} style={{ textAlign: "right" }}>
                                    <div style={{ padding: "20px 0px" }}>
                                        {dispatchStructure.orders ?
                                        <p><SpanStyle>{scannedLines.lines.length} /  {getTotalOrderCounts(dispatchStructure.orders)}</SpanStyle></p>
                                        : ''}
                                        {dispatchStructure.orders ?
                                            dispatchStructure.orders.map(data => {
                                                const dataCount = getScanCounts(data)
                                                return (<p><SpanStyle>{dataCount} from Order {data.order_number}</SpanStyle></p>
                                                )
                                            })
                                            : null}
                                        <br />
                                        <SpanStyle>Going To <span style={{ fontWeight: "bold" }}>{dispatchStructure.address}</span></SpanStyle>
                                    </div>
                                    <HrStyle />
                                    { isPickUp !== true && <Row type={"flex"} justify="center" style={{ margin: "10px 0px" }}>
                                        {checkPermission('dispatch-now') ?
                                            <div style={{ padding: "5px 0px" }} >
                                                <Button disabled={loading ? loading : scannedLines.lines.length > 0 ? false : true} type="primary" onClick={() => {
                                                  updateDispatchNowCount(scannedLines.lines.length);
                                                }}>Dispatch Now</Button>
                                            </div>
                                            : null}
                                        <div style={{ padding: 5 }} >
                                            <Button disabled={loading ? loading : dispatchStructure ? false : true} type="danger"
                                                onClick={() => Modal.confirm({
                                                    'title': 'Warning',
                                                    'content': `All barcodes that are scanned will be lost would you like to continue?`,
                                                    onOk: () => {
                                                        refreshDispatch("Cancel dispatch successful!")
                                                    },
                                                })} ghost>Cancel Dispatch</Button>
                                        </div>
                                    </Row>}
                                    <TableOverrides />
                                    <Table pagination={false}
                                        dataSource={lineDataSource} columns={columns} />
                                </Col>
                            </Row>
                            : null}
                    </DispatchLookUp>
                </div>
                <DispatchPanel />
            </Container>

        </>
    )
}

export default Dispatch