import React, { useState } from "react";
import { Modal, Button } from "antd";
import FileSaver from "file-saver";

const ExportRecipe = ({
  recipes
}) => {
  const [showValidationModal, setShowValidationModal] = useState(false)

  const exportJsonFile = () => {
    let convertedRecipes = JSON.stringify(recipes)
    let blob = new Blob([convertedRecipes], { type: "application/json;charset=utf-8" });
    FileSaver.saveAs(blob, "Recipe.json");
  }

  const handleExport = () => {
    if (recipes.length <= 0 || !recipes) {
      setShowValidationModal(true)
    } else {
      exportJsonFile()
    }
  }

  return (
    <>
      <Button
        style={{ marginRight: '0.5rem' }}
        onClick={handleExport}
      >
        Export
      </Button>
      {/* Validation Error Modal */}
      <Modal
        title="Error"
        visible={showValidationModal}
        onCancel={() => setShowValidationModal(false)}
        onOk={() => setShowValidationModal(false)}
        centered
      >
        No recipe/s to export. Please add a recipe first.
      </Modal>
    </>
  )
}

export default ExportRecipe;