import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import moment from 'moment'
import { useSelector } from 'react-redux';
import { Button, notification} from 'antd'
import { Panel } from "components/primaries"
import ReportFaultModal from "../Maintenance/ReportFaultModal"
import Faults from "../Maintenance/Faults"
import { Header, Title, StyledDivider, SubtitleStyle, Message } from "../styles"
import { alphabeticalData } from 'utils/sortData';
import { getAllStationsRoute } from 'utils/apiRoutes'
import { ZenSmartAPI } from 'utils';

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem 0.6rem;
`

function EngineersList() {
  const [showReportFaultModal, setShowReportFaultModal] = useState(false)
  const [stations, setStations] = useState([])
  const { details } = useSelector((state) => state.user); // Get user details
  const [reload, setReload] = useState(false)

  useEffect(() => {
    ZenSmartAPI.get(getAllStationsRoute).then(res => {
      setStations(alphabeticalData(res.data.data, 'name'))
    }).catch(err => {
      notification.error({
        message: "Could not fetch all stations.",
      });
    })
  }, [])
  
  return (
    <>
      <ReportFaultModal
        showReportFaultModal={showReportFaultModal}
        setShowReportFaultModal={setShowReportFaultModal}
        stations={stations}
        reload={setReload}
      />
      <Header>
        <Title>Machine {">"} Engineer's List</Title>
      </Header>
      <Panel>
        <TitleRow>
          <Title>Engineer's List</Title>
          <Button
            type="danger"
            onClick={() => setShowReportFaultModal(true)}
            ghost
          >
            Report a Fault
          </Button>
        </TitleRow>

        <StyledDivider style={{marginBottom: '1.3rem'}} />

        <SubtitleStyle style={{marginLeft: '1rem'}}>{ moment().format('ddd DD MMM YYYY').toUpperCase() }</SubtitleStyle>
        
        {details && details.role && details.id ? <Faults userId={details.id} role={details.role} reload={reload} setReload={setReload} /> : <Message>No user role specified.</Message>}
        
      </Panel>
    </>
  )
}

export default EngineersList;