import React from 'react'
import { Modal, notification, Row, Col, Select, Input, Button, Form } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js';
import { ZenSmartAPI } from 'utils'

const ModalStyle = createGlobalStyle`
  .ant-modal-body {
    border: 2px solid blue;
    font-family: 'Rubik', sans-serif;
  }
`
const InputHeader = styled.span`
  font-weight: bold;
`

const { Option } = Select;

function FailModal({selectedSent, setSelectedSent, formValues, showModal, hideModal, fetchData, getQaFailFormRoute}) {

  const FailForm = Form.create({ name: 'FailForm' })(props => {
    const { form } = props
    const { getFieldDecorator, getFieldsValue, getFieldValue, validateFields, setFieldsValue } = form

    const handleSaveChanges = (evt) => {
      evt.preventDefault()
      validateFields((err, values) => {
        if (!err) {
          const payload = getFieldsValue();
          setFieldsValue({
            ...payload,
            loading: true
          })
          var queryStringID = ''
          selectedSent.map((data, key) => {
            queryStringID = key === 0 ? queryStringID + `${data}` : queryStringID + `;${data}`
            return data
          })

          ZenSmartAPI.post(getQaFailFormRoute(queryStringID.replace(/;$/, "").replace(/^;/, "")), payload)
            .then((res) => {
              notification.success({ message: "Save batch successful!" })
              hideModal()
              setSelectedSent([])
              fetchData()
            })
            .catch((res) => {
              res.response.data ? (
                notification.error({ message: "Failed saving a batch! " + res.response.data.message })
              ) : (
                notification.error({ message: "Failed saving a batch!" })
              );
              setFieldsValue({
                ...payload,
                loading: false
              });
            })
          }
      });
    }

    const getFormSelectedValue = (value) => {
      const getSelected = value.find((item) => item.selected === true)
      if (getSelected !== undefined) {
        return getSelected.value
      }
    }

    return (
      <>
        {formValues &&
          <>
            <p style={{ textAlign: "center" }}>
              <Label status="blue" >{formValues.message}</Label>
            </p>

            <Form onSubmit={handleSaveChanges}>
              <div style={{ padding: "10px 50px" }}>
                {formValues ? formValues.page_definitions.sections[0].section_data.map((row, key) =>
                  <Form.Item label={<InputHeader>{row.label}</InputHeader>} >
                    {getFieldDecorator(row.field, {
                      initialValue: row.options ? getFormSelectedValue(row.options) : null,
                      rules: row.required === true ? [{ required: true, message: `Please input this feld!` }] : undefined
                    })
                    ( row.data_type === "SELECT" ?
                      <Select optionFilterProp="children" showSearch placeholder={row.label} size="large">
                        <Option value="new stuff">New Stuff</Option>
                          {row.options.map((data, key) =>
                            <Option value={data.value}>{data.text}</Option>
                          )}
                      </Select> :
                      <Input placeholder={row.required === true ? "Please Input This Field" : "*Optional"} size="large" />
                    )}
                  </Form.Item> ) : null}
                  <Form.Item >
                    {getFieldDecorator("loading", {
                      initialValue: false,
                    })(
                      <Input style={{ display: "none" }} />
                    )}
                  </Form.Item>
                </div>
                <Row type="flex" justify="center" align="middle">
                  <Col span={5} style={{ padding: 10 }}>
                    <Form.Item>
                      <Button type="danger" style={{ width: "100%" }} onClick={hideModal}>Cancel</Button>
                    </Form.Item>
                  </Col>
                  <Col span={7} style={{ padding: 10 }} >
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={getFieldValue('loading')}
                        style={{ width: "100%" }}
                      >
                        Save Changes
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
            </Form>
          </>
        }
      </>
    )
  })

  return (
    <>
      <ModalStyle />
      <Modal
        title=""
        centered
        width={650}
        visible={showModal}
        footer={null}
        onCancel={hideModal}
      >
        <div style={{ padding: 20 }}>
          <FailForm />
        </div>
      </Modal>
    </>
  )
}

export default FailModal