import React from "react";
import styled from "styled-components";

const PreviewMain = styled.iframe`
  width: 100%;
  height: 100%;
`;

const Preview = (props) => {
  const writeErrorHTML = (frame) => {
    if (!frame || props.pdfURL !== "error") {
      return;
    }

    let doc = frame.contentDocument;
    doc.open();
    doc.write("<h2>Unable to impose</h2>");
    doc.close();
  };

  return (
    <PreviewMain
      title="Preview"
      src={props.pdfURL !== "error" ? props.pdfURL : null}
      ref={(iframe) => writeErrorHTML(iframe)}
    />
  );
};

export default Preview;
