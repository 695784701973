import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Modal ,notification, Button , Checkbox} from 'antd'
import moment from 'moment'
import {  clearPigeonHole } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;
`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;
}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

`
const TableData = (props) => {

    const [data, setData] = useState([]);
    const { itemData, loading, selectedCheckBoxValue, setSelectedCheckBoxValue , fetchData , canClearRange} = props

    const columns = [

        {
            title: 'MATCH BOX NUMBER',
            dataIndex: 'box_number',
            key: 'box_number',
            render(value, row, index) {
                return rowStyle(value, index)
            },
        },
        {
            title: 'TRANSACTION ID',
            dataIndex: 'transaction_client_number',
            key: 'transaction_client_number',
            render: (record, row, index) => {
                const value = <p><a href={record.url} style={{ color: "blue", textDecorationLine: "underline" }}>{record.client_number}</a></p>
                return rowStyle(value, index, row)
            }
        },
        {
            title: 'DUE DATE',
            dataIndex: 'due_date',
            key: '_due_date',
            render: (record, row, index) => {
                return rowStyle(record ? record : 'No Due Date', index)
            }
        },
        {
            title: 'VENDOR',
            dataIndex: 'vendor_name',
            key: 'vendor_name',
            render(value, row, index) {
                return rowStyle(value, index)
            },
        },
        {
            title: 'BLOCKS IN BOX',
            dataIndex: 'in_box',
            key: 'in_box',
            render: (record, row, index) => {
                const value = record.map(data => {
                    return (
                        <p>{data.block_barcode}</p>
                    )
                }
                )

                return rowStyle(value, index)

            }
        },
        {
            title: 'TOTAL IN BOX',
            dataIndex: 'total_in_box',
            key: 'total_in_box',
            render(value, row, index) {
                return rowStyle(value, index)
            },
        },
        {
            title: 'BLOCKS NOT YET ARRIVED',
            dataIndex: 'waiting_on',
            key: 'waiting_on',
            render: (record, row, index) => { const value =  (record.length === 0 ? "Ready for pre dispatch!" :
                record.map(data =>
                    <p> <a href={data.block_url} style={{ color: "blue", textDecorationLine: "underline" }}>{data.block_barcode}</a> - {data.product_code} - {data.current_stage}
                        </p>
                    )
                )

                return rowStyle(value, index)

            }
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            key: 'action',
            render(record, row, index) {
                const value = <Button
                    type="primary"
                    onClick={() => handleVerification(row)}
                    disabled={!canClearRange}
                    style={{ width: '100%' }}
                    >CLEAR</Button>
                return rowStyle(value, index)
            },
        },

    ];

    const handleVerification = (data) => {
        Modal.confirm({
            content: `Are you sure you want to empty this pigeonhole?`,
            onOk: () => {
                let payload = { id : data.pigeon_hole_id }
                console.log(payload)
                ZenSmartAPI.post(clearPigeonHole('match'), payload)
                .then((res) => {
                    fetchData()
                    notification.success({ message: "PigeonHole has been successfully emptied." })
                })
                .catch((res) => {
                    notification.error({ message: "PigeonHole clearance failed." })
                })
            },
            onCancel: () => {}
        });
    }

    const getLines = (value, id) => {
        const getSelectedData = selectedCheckBoxValue
        if (value.target.checked) {
            setSelectedCheckBoxValue([...selectedCheckBoxValue, id])
        }
        else {
            setSelectedCheckBoxValue(getSelectedData.filter(data => data !== id))
        }
    }

    const rowStyle = (value, index, boldText = false) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }

        if (boldText) {
            obj.props.style.fontWeight = "bold"
        }

        return obj
    }

    useEffect(() => {

        if (itemData) {
            const itemObj = itemData.map(data => {
                const obj = {
                    box_number: data.box_number,
                    transaction_client_number: data.transaction_client_number,
                    due_date: data.due_date,
                    vendor_name: data.vendor_name,
                    in_box: data.in_box,
                    waiting_on: data.waiting_on,
                    action: data.action,
                    total_in_box: data.total_in_box,
                    pigeon_hole_id: data.pigeon_hole_id,
                }
                return obj;
            })
            console.log('itemObj',itemObj)
            setData(itemObj);
        }
    }, [itemData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    pagination={false}
                />
            </StyleTable>
        </>
    )
}

export default TableData