import * as actionTypes from "./actionTypes";

export const saveProductOptions = productOptions => {
  return {
    type: actionTypes.SAVE_PRODUCT_OPTIONS,
    productOptions: productOptions
  };
};
export const saveOutputFormat = data => {
  return {
    type: actionTypes.SAVE_OUTPUT_FORMAT,
    data: data
  };
};
export const saveImpositionDesignData = data => {
  return {
    type: actionTypes.SAVE_IMPOSITION_DESIGN_DATA,
    data: data
  };
};