import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, Tag, Button, Typography } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData'
import _ from 'lodash'
import { ZenSmartAPI } from 'utils'
import {
  getAllDepartments,
  fetchWeeklyCellDepartmentSummaryRoute
} from 'utils/apiRoutes'
import { alphabeticalData } from 'utils/sortData'
import moment from 'moment'

const { Paragraph } = Typography

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const Heading = styled.h1`
  font-size: 15px;
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
`
const DateColStyle = styled(Col)`
  padding: 10px;
`
const StyleButton = styled(Button)`
  font-family: 'Rubik', sans-serif;
`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select

const WeeklyCellDepartmentSummary = () => {
  const [tableInfo, setTableInfo] = useState({
    data: [],
    column: []
  })
  const [loading, setLoading] = useState(false)

  const [departmentType, setDepartmentType] = useState([])
  const [payload, setPayload] = useState({
    department_id: null
  })

  const fetchDepartmentTypes = async () => {
    try {
      setLoading(true)
      const networkRequest = await ZenSmartAPI.get(getAllDepartments)
      setDepartmentType(networkRequest.data.data)
    } catch (error) {
      notification.error({
        message: error?.message ?? 'Error fetching department types'
      })
    } finally {
      setLoading(false)
    }
  }

  const sumProduct = (arr, key) => {
    let result = 0
    arr.map(item => {
      let numToAdd = item[key] ? item[key] : 0
      return (result += numToAdd)
    })
    return result
  }

  const transformToTableData = async arrData => {
    let allData = []
    let columns = []

    await arrData.map((scanType, scanIndex) => {
      const cellData = []
      scanType.cell_data.map((cellItem, cellItemIndex) => {
        const cellBreakDown = []
        cellItem.data.map((breakDown, breakDownIndex) => {
          if (columns.length === 0) {
            columns = [
              {
                title: 'Task/Operator',
                dataIndex: 'task',
                key: 'task',
                render: (text, { key }) => {
                  return {
                    props: {
                      style: {
                        fontWeight: key.includes('cell') ? '800' : '400',
                        display: 'flex'
                      }
                    },
                    children: key.includes('scan') ? (
                      <Tag color={'geekblue'}>{text}</Tag>
                    ) : key.includes('cellTotal') ? (
                      <Paragraph strong>{text}</Paragraph>
                    ) : (
                      <Paragraph>{text}</Paragraph>
                    )
                  }
                }
              },
              {
                title: breakDown.weekly_report[0].date,
                dataIndex: 'date_0',
                key: 'date_0',
                render (value, row) {
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {row?.date_0 ?? ''}
                    </Paragraph>
                  )
                }
              },
              {
                title: breakDown.weekly_report[1].date,
                dataIndex: 'date_1',
                key: 'date_1',
                render (value, row) {
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {row?.date_1 ?? ''}
                    </Paragraph>
                  )
                }
              },
              {
                title: breakDown.weekly_report[2].date,
                dataIndex: 'date_2',
                key: 'date_2',
                render (value, row) {
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {row?.date_2 ?? ''}
                    </Paragraph>
                  )
                }
              },
              {
                title: breakDown.weekly_report[3].date,
                dataIndex: 'date_3',
                key: 'date_3',
                render (value, row) {
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {row?.date_3 ?? ''}
                    </Paragraph>
                  )
                }
              },
              {
                title: breakDown.weekly_report[4].date,
                dataIndex: 'date_4',
                key: 'date_4',
                render (value, row) {
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {row?.date_4 ?? ''}
                    </Paragraph>
                  )
                }
              },
              {
                title: breakDown.weekly_report[5].date,
                dataIndex: 'date_5',
                key: 'date_5',
                render (value, row) {
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {row?.date_5 ?? ''}
                    </Paragraph>
                  )
                }
              },
              {
                title: breakDown.weekly_report[6].date,
                dataIndex: 'date_6',
                key: 'date_6',
                render (value, row) {
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {row?.date_6 ?? ''}
                    </Paragraph>
                  )
                }
              },
              {
                title: 'Total Units',
                dataIndex: 'total_units',
                key: 'total_units',
                render (value, row) {
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {row?.total_units ?? ''}
                    </Paragraph>
                  )
                }
              },
              {
                title: 'Total Hours',
                dataIndex: 'total_hours',
                key: 'total_hours',
                render (value, row) {
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {row?.total_hours ?? ''}
                    </Paragraph>
                  )
                }
              },
              {
                title: 'Average Per Hour',
                dataIndex: 'avg_per_hr',
                key: 'avg_per_hr',
                render (value, row) {
                  const avg_per_hr = row.total_units / row.total_hours
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {isNaN(avg_per_hr)
                        ? row.total_units
                        : avg_per_hr === Infinity
                        ? row.total_units
                        : Math.max(avg_per_hr).toFixed(2)}
                    </Paragraph>
                  )
                }
              },
              {
                title: 'Best Hour',
                dataIndex: 'best_hour',
                key: 'best_hour',
                render (value, row) {
                  return (
                    <Paragraph strong={row.key.includes('cellTotal')}>
                      {row?.best_hour ?? ''}
                    </Paragraph>
                  )
                }
              }
            ]
          }
          cellBreakDown.push({
            key: `detail${scanIndex}/${cellItemIndex}/${breakDownIndex}`,
            task: breakDown.name,
            date_0: breakDown.weekly_report[0].value,
            date_1: breakDown.weekly_report[1].value,
            date_2: breakDown.weekly_report[2].value,
            date_3: breakDown.weekly_report[3].value,
            date_4: breakDown.weekly_report[4].value,
            date_5: breakDown.weekly_report[5].value,
            date_6: breakDown.weekly_report[6].value,
            total_units: breakDown.total_units,
            total_hours: breakDown.total_hours,
            avg_per_hr: '',
            best_hour: breakDown.best_hours
          })
        })
        const bestHourValues = cellBreakDown.map(item => item.best_hour)
        let cellTotal = {
          key: `cellTotal${scanIndex}/${cellItemIndex}`,
          task: `Cell Totals`,
          date_0: sumProduct(cellBreakDown, 'date_0'),
          date_1: sumProduct(cellBreakDown, 'date_1'),
          date_2: sumProduct(cellBreakDown, 'date_2'),
          date_3: sumProduct(cellBreakDown, 'date_3'),
          date_4: sumProduct(cellBreakDown, 'date_4'),
          date_5: sumProduct(cellBreakDown, 'date_5'),
          date_6: sumProduct(cellBreakDown, 'date_6'),
          total_units: sumProduct(cellBreakDown, 'total_units'),
          total_hours: sumProduct(cellBreakDown, 'total_hours'),
          avg_per_hr: '',
          best_hour: Math.max(...bestHourValues)
        }

        cellData.push({
          key: `cell${scanIndex}/${cellItemIndex}`,
          task: cellItem?.cell,
          date_0: '',
          date_1: '',
          date_2: '',
          date_3: '',
          date_4: '',
          date_5: '',
          date_6: '',
          total_units: '',
          total_hours: '',
          avg_per_hr: '',
          best_hour: '',
          children: [...cellBreakDown, cellTotal]
        })
      })
      allData.push({
        key: `scan${scanIndex}`,
        task: scanType.scan_type ?? '',
        date_1: '',
        total_units: '',
        total_hours: '',
        avg_per_hr: '',
        best_hour: '',
        children: cellData
      })
    })
    return { allData, columns }
  }

  const fetchData = async () => {
    try {
      if (!payload.department_id) {
        notification.error({ message: 'Please select a department!' })
        return
      }
      setLoading(true)
      const networkResponse = await ZenSmartAPI.get(
        fetchWeeklyCellDepartmentSummaryRoute(payload.department_id)
      )

      if (networkResponse?.data?.data.length > 0) {
        const tableData = await transformToTableData(networkResponse.data.data)
        setTableInfo({ data: tableData.allData, column: tableData.columns })
      } else {
        setTableInfo(oldState => ({ ...oldState, data: [] }))
        notification.error({ message: 'No data found!' })
      }
    } catch (error) {
      if (_.get(error, 'response.status') === 400) {
        notification.error({
          message: _.get(
            error,
            'response.data.message',
            'An unhandled error occurred. Please contact your supervisor.'
          )
        })
      }

      if (_.get(error, 'response.status') > 499) {
        notification.error({
          message: 'An unhandled error occurred. Please contact your supervisor'
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const changeDepartmentType = value => {
    setPayload({ department_id: value })
  }

  useEffect(() => {
    fetchDepartmentTypes()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header>
        <Title>
          Performance {'>'} Cell {'>'} Weekly Cell Department Summary
        </Title>
      </Header>
      <Panel title='WEEKLY CELL DEPARTMENT SUMMARY'>
        <div style={{ padding: '10px 20px 30px 10px' }}>
          <InputStyle />
          <Row type='flex' align='middle'>
            <DateColStyle span={6}>
              <Heading>Department</Heading>
              <Select
                style={{
                  marginTop: 10,
                  width: '100%',
                  textTransform: 'capitalize'
                }}
                onChange={changeDepartmentType}
                placeholder='Select Department'
              >
                {alphabeticalData(departmentType, 'text').map(data => (
                  <Option
                    value={data.id}
                    key={data.id}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {data.name.toLowerCase()}
                  </Option>
                ))}
              </Select>
            </DateColStyle>
            <DateColStyle span={4}>
              <br />
              <StyleButton
                style={{ marginTop: 10 }}
                onClick={fetchData}
                loading={loading}
                type='primary'
              >
                GO
              </StyleButton>
            </DateColStyle>
          </Row>
        </div>

        <TableData
          loading={loading}
          data={tableInfo.data}
          columns={tableInfo.column}
        />
      </Panel>
    </>
  )
}

export default WeeklyCellDepartmentSummary
