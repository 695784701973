import styled from 'styled-components';
import is from "is_js";

const TableStyle = styled.div`
  width: 100%;
  ${props => (props.tight && props.isDashboard) && `
    
    font-size: ${
        is.existy(props.parentSize)
            ? (4 / 100) * ((props.parentSize.height + props.parentSize.width) / 2) + "px"
            : "9px"
    };
  `};
  
  .ant-table-thead > tr > th {
    background-color: white;
    text-transform: uppercase;
    font-weight: 500;
  }
  .ant-table-body {
    overflow-y: auto;
    overflow-x: ${(props) => (props.scrollX ? "auto" : "hidden")};
    tr:nth-child(even) {
      background-color: rgba(11,107,242,0.07);
    }
  }
  .ant-table-pagination {
    margin-right: 5px;
  }
  
  .anticon {
    vertical-align: 0.125em;
  }
  table tr .hover_column.left {
    border-left: 1px solid blue;
  }
  table tr .hover_column.right{
    border-right: 1px solid blue;
  }
  table tr td, table tr th {
    ${props => props.tight ? `
    padding: 0.4em;
    overflow-x: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    max-width: 10rem;
    ` : `
    padding-left: 51px;
    `};
  }
  td {
    color: #8798AD;
  }
`

export default TableStyle