import styled from 'styled-components';
import { styles } from '../../../../components/zensmart-design-system';

const color = styles.color;

const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`

export default Title