const STATUS = {
  IDLE: 'IDLE',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  FETCHING: 'FETCHING',

  FETCHING_INIT_PRINTER_TIMELINE_DATA: 'FETCHING_INIT_PRINTER_TIMELINE_DATA',
  FETCHING_PRINTER_TIMELINE_DATA: 'FETCHING_PRINTER_TIMELINE_DATA',
  FETCHING_SUBMISSION_DETAILS: 'FETCHING_SUBMISSION_DETAILS',

  FETCHING_USER_DETAILS: 'FETCHING_USER_DETAILS',
  FAILED_FETCHING_USER_DETAILS: 'FAILED_FETCHING_USER_DETAILS',
}

export default STATUS
