import React, { useState, useEffect } from "react";
import {
  Drawer,
  TimePicker,
  Row,
  Col,
  Checkbox,
  notification,
  Button
} from "antd";
import moment from "moment"
import {
  updateBusinessHoursRoute
} from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'

const BusinessHoursModal = (props) => {

  const { showModal, setShowModals, itemData, fetchData } = props
  const format = 'HH:mm:ss'
  const [hoursObject, setHoursObject] = useState({})
  const [loading, setLoading] = useState(false)
  const [checkedTwentyFourHours, setCheckedTwentyFourHours] = useState([])
  const [nonWorkingDays, setNonWorkingDays] = useState([])
  const [description, setDescription] = useState("")
  const changeDate = (time, timeString, property) => {
    setHoursObject({
      ...hoursObject, [property]: timeString
    })
  }

  const setTwentyFourHours = (value, start, end, day) => {
    if (value.target.checked) {
      setHoursObject({ ...hoursObject, [start]: "00:00:00", [end]: "23:59:59" })
      setCheckedTwentyFourHours([...checkedTwentyFourHours, day])
      setNonWorkingDays(nonWorkingDays.filter(data => data !== day))
    }
    else {
      setCheckedTwentyFourHours(checkedTwentyFourHours.filter(data => data !== day))
    }
  }

  const selectNonWorkingDays = (value, start, end, day) => {
    if (value.target.checked) {
      setHoursObject({ ...hoursObject, [start]: "00:00:00", [end]: "00:00:00" })
      setNonWorkingDays([...nonWorkingDays, day])
      setCheckedTwentyFourHours(checkedTwentyFourHours.filter(data => data !== day))
    }
    else {
      setNonWorkingDays(nonWorkingDays.filter(data => data !== day))
    }
  }

  const disableTwentyFourHour = (day) => {
    return nonWorkingDays.includes(day) && !checkedTwentyFourHours.includes(day);
  }

  const renderFields = (day, start, end, firstProperty, secondProperty) => {
    return (
      <div>

        <div style={{ padding: "10px 0px 10px 0px" }}>
          <p style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: 15 }}>{day}:</p>
          <Row type="flex" align="middle" justify="center">

            <Col span={7} style={{ padding: 5, textAlign: "center" }}>
              <TimePicker value={moment(start ? start : "00:00:00", format)} format={format} style={{ width: "100%" }}
                onChange={(time, timeString) => changeDate(time, timeString, firstProperty)} disabled={checkedTwentyFourHours.includes(day) || nonWorkingDays.includes(day)} />
            </Col>
            <Col span={7} style={{ padding: 5, textAlign: "center" }}>
              <TimePicker value={moment(end ? end : "00:00:00", format)} format={format} style={{ width: "100%" }}
                onChange={(time, timeString) => changeDate(time, timeString, secondProperty)} disabled={checkedTwentyFourHours.includes(day) || nonWorkingDays.includes(day)} />

            </Col>
            <Col span={4} style={{ padding: "5 0", textAlign: "right" }}>
              <Checkbox onChange={(e) => setTwentyFourHours(e, firstProperty, secondProperty, day)} checked={checkedTwentyFourHours.includes(day)} disabled={disableTwentyFourHour(day)}>24 Hours</Checkbox>
            </Col>
            <Col span={6} style={{ padding: "5 0", textAlign: "right" }}>
              <Checkbox onChange={(e) => selectNonWorkingDays(e, firstProperty, secondProperty, day)} checked={nonWorkingDays.includes(day) || (start === "00:00:00" && end === "00:00:00")}>No Operation</Checkbox>
            </Col>
          </Row>
        </div>
        <hr />
      </div>
    )
  }

  const cancelEdit = () => {
    setShowModals({ ...showModal, business_hours: false })
    setCheckedTwentyFourHours([])
    setNonWorkingDays([])
    setHoursObject({})
  }

  const saveNewBusinessHours = () => {
    setLoading(true)
    ZenSmartAPI.put(updateBusinessHoursRoute(1), hoursObject)
      .then((res) => {
        setLoading(false)
        cancelEdit()
        notification.success({ message: "Update Successful!" })
        fetchData()
      })
      .catch((error) => {
        setLoading(false);
        if (error.message) {
          notification.error({ message: error.message })
        }
        else {
          notification.error({ message: "Failed updating business hours!" })
        }
      })
  }

  useEffect(() => {
    if (itemData && itemData.length > 0 && showModal.business_hours) {
      const hoursObject = itemData.find(data => data.param === "business_hours")
      if (hoursObject) {
        setHoursObject(hoursObject.val)
        setDescription(hoursObject.description)
        const constructHoursObject = []
        Object.keys(hoursObject.val).map(data => {
          const firstWord = data.slice(0, data.lastIndexOf('_'))

          if (hoursObject.val[`${firstWord}_start`] === "00:00:00" && hoursObject.val[`${firstWord}_end`] === "23:59:59" && !constructHoursObject.includes(firstWord)) {
            constructHoursObject.push(firstWord)
          }

        })
        setCheckedTwentyFourHours(constructHoursObject)
      }
    }
  }, [itemData, showModal]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Drawer visible={showModal.business_hours} title="Business Hours" width={650} closable={false}
    >
      <p>{description}</p>
      <div>

        {
          Object.keys(hoursObject).length > 0
          &&
          <div>
            {renderFields("monday", hoursObject.monday_start, hoursObject.monday_end, "monday_start", "monday_end")}
            {renderFields("tuesday", hoursObject.tuesday_start, hoursObject.tuesday_end, "tuesday_start", "tuesday_end")}
            {renderFields("wednesday", hoursObject.wednesday_start, hoursObject.wednesday_end, "wednesday_start", "wednesday_end")}
            {renderFields("thursday", hoursObject.thursday_start, hoursObject.thursday_end, "thursday_start", "thursday_end")}
            {renderFields("friday", hoursObject.friday_start, hoursObject.friday_end, "friday_start", "friday_end")}
            {renderFields("saturday", hoursObject.saturday_start, hoursObject.saturday_end, "saturday_start", "saturday_end")}
            {renderFields("sunday", hoursObject.sunday_start, hoursObject.sunday_end, "sunday_start", "sunday_end")}

          </div>
        }
        <div style={{ position: "absolute", bottom: 0, left: 0, width: "100%", padding: 10 }}>
          <hr />
          <Row type="flex" align="middle" justify="end" style={{ marginTop: 5 }}>
            <Col span={4} style={{ padding: 5 }}>
              <Button type="primary" style={{ width: "100%" }} ghost onClick={cancelEdit}>
                Cancel
      </Button>
            </Col>
            <Col span={6} style={{ padding: 5 }}>
              <Button type="primary" style={{ width: "100%" }} loading={loading} onClick={saveNewBusinessHours}>
                Save Changes
      </Button>
            </Col>
          </Row>
        </div>
      </div >
    </Drawer >
  );
}

export default BusinessHoursModal;
