
import { GetLabel } from 'pages/Performance/utils';
import moment from "moment";

export const alphabeticalData = (data, property = null, withgetLabel = false, secondProperty = null) => {
    const sortData = data
    if (sortData) {
        if (withgetLabel) {
            sortData.sort(function (a, b) {
                if (property) {
                    const firstLabel = GetLabel(`${a[property]}.${a[secondProperty]}`)
                    const secondLabel = GetLabel(`${b[property]}.${b[secondProperty]}`)
                    if (`${a[property]} ${firstLabel}`.toLowerCase() > `${b[property]} ${secondLabel}`.toLowerCase()) {
                        return 1;
                    }
                    if (`${b[property]} ${secondLabel}`.toLowerCase() > `${a[property]} ${firstLabel}`.toLowerCase()) {
                        return -1;
                    }
                }
                else {
                    if (a > b) {
                        return 1;
                    }
                    if (b > a) {
                        return -1;
                    }
                }
                return 0;
            });
        }
        else {
            sortData.sort(function (a, b) {
                if (property) {
                    if (a[property] > b[property]) {
                        return 1;
                    }
                    if (b[property] > a[property]) {
                        return -1;
                    }
                }
                else {
                    if (moment(a).isValid() && moment(b).isValid()) {
                        const aTime = new Date(a).getTime() / 1000;
                        const bTime = new Date(b).getTime() / 1000;
                        if (aTime > bTime) {
                            return 1;
                        }
                        if (bTime > aTime) {
                            return -1;
                        }
                    }
                    if (a > b) {
                        return 1;
                    }
                    if (b > a) {
                        return -1;
                    }
                }
                return 0;
            });
        }
    }

    return sortData
}

export const panelSortData = (data, property, ifAscend, isValue = null, specificSorting = null) => {
    const sortData = data
    if (sortData) {

        sortData.sort(function (a, b) {
            if (!isValue) {
                if (ifAscend) {
                    if (specificSorting) {
                        var dates = [];
                        if (specificSorting === "dayOfWeek") {
                            dates = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
                        }
                        if ((typeof a[property] !== "object" || typeof b[property] !== "object") && dates.indexOf(a[property].replace(/ .*/, '').toLowerCase().trim()) >= 0 && dates.indexOf(b[property].replace(/ .*/, '').toLowerCase().trim()) >= 0) {
                            if (dates.indexOf(a[property].replace(/ .*/, '').toLowerCase().trim()) > dates.indexOf(b[property].replace(/ .*/, '').toLowerCase().trim())) {
                                return 1;
                            }
                            if (dates.indexOf(b[property].replace(/ .*/, '').toLowerCase().trim()) > dates.indexOf(a[property].replace(/ .*/, '').toLowerCase().trim())) {
                                return -1;
                            }
                        }
                    }
                    if (Array.isArray(a[property]) && Array.isArray(b[property])) {
                        var mergeA = ''
                        var mergeB = ''
                        a[property].map(data => {
                            mergeA = mergeA + data.value
                        })
                        b[property].map(data => {
                            mergeB = mergeB + data.value
                        })
                        if (mergeA > mergeB) {
                            return -1;
                        }
                    }
                    if (a[property] === null || b[property] === null) {
                        a = a[property] || '';
                        b = b[property] || '';
                        return a.localeCompare(b);
                    }
                    if (a[property] > b[property]) {
                        return 1;
                    }
                    if (b[property] > a[property]) {
                        return -1;
                    }
                }
                else {
                    if (specificSorting) {
                        var dates = [];
                        if (specificSorting === "dayOfWeek") {
                            dates = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
                        }
                        if ((typeof a[property] !== "object" || typeof b[property] !== "object") && dates.indexOf(a[property].replace(/ .*/, '').toLowerCase().trim()) >= 0 && dates.indexOf(b[property].replace(/ .*/, '').toLowerCase().trim()) >= 0) {
                            if (dates.indexOf(a[property].replace(/ .*/, '').toLowerCase().trim()) > dates.indexOf(b[property].replace(/ .*/, '').toLowerCase().trim())) {
                                return -1;
                            }
                            if (dates.indexOf(b[property].replace(/ .*/, '').toLowerCase().trim()) > dates.indexOf(a[property].replace(/ .*/, '').toLowerCase().trim())) {
                                return 1;
                            }
                        }
                    }
                    if (Array.isArray(a[property]) && Array.isArray(b[property])) {
                        var mergeA = ''
                        var mergeB = ''
                        a[property].map(data => {
                            mergeA = mergeA + data.value
                        })
                        b[property].map(data => {
                            mergeB = mergeB + data.value
                        })
                        if (mergeA < mergeB) {
                            return -1;
                        }
                    }
                    if (a[property] === null || b[property] === null) {
                        a = a[property] || '';
                        b = b[property] || '';
                        return b.toString().localeCompare(a.toString());
                    }
                    if (a[property] > b[property]) {
                        return -1;
                    }
                    if (b[property] > a[property]) {
                        return 1;
                    }
                }
            }
            else {
                if (ifAscend) {
                    if (a[property].value === null || b[property].value === null) {
                        var va = (a[property].value === null) ? "" : "" + a[property].value,
                            vb = (b[property].value === null) ? "" : "" + b[property].value;
                        return va < vb ? 1 : (va === vb ? 0 : -1);
                    }
                    if (a[property].value > b[property].value) {
                        return 1;
                    }
                    if (b[property].value > a[property].value) {
                        return -1;
                    }
                }
                else {
                    if (a[property].value === null || b[property].value === null) {
                        var va = (a[property].value === null) ? "" : "" + a[property].value,
                            vb = (b[property].value === null) ? "" : "" + b[property].value;
                        return va > vb ? 1 : (va === vb ? 0 : -1);
                    }
                    if (a[property].value > b[property].value) {
                        return -1;
                    }
                    if (b[property].value > a[property].value) {
                        return 1;
                    }
                }
            }
            return 0;
        });
    }


    return sortData
}

export const arrayReportSort = (data, property = null, group = "", groupedDate = {}) => {

    const sortedArray = data.sort(function (a, b) {
        if (property) {
            if (group.includes("relative_due_date")) {
                const due_dates = ['overdue', 'today', 'tomorrow', 'later']
                if (a[property] !== null && b[property] !== null && due_dates.indexOf(a[property].toLowerCase().trim()) >= 0 && due_dates.indexOf(b[property].toLowerCase().trim()) >= 0) {
                    if (due_dates.indexOf(a[property].toLowerCase().trim()) > due_dates.indexOf(b[property].toLowerCase().trim())) {
                        return 1;
                    }
                    if (due_dates.indexOf(b[property].toLowerCase().trim()) > due_dates.indexOf(a[property].toLowerCase().trim())) {
                        return -1;
                    }
                }
            }

            if (a[property] === null || a[property] === "null") {
                return -1;
            }
            if (b[property] === null || b[property] === "null") {
                return 1;
            }
            if (Object.keys(groupedDate).length > 0 && group && groupedDate[group]) {
                if (groupedDate[group] === "M Y" || groupedDate[group] === "F" || groupedDate[group] === "l") {
                    var dates = [];
                    if (groupedDate[group] === "M Y") {
                        return new Date(a[property]) - new Date(b[property]);
                    }
                    else if (groupedDate[group] === "F") {
                        dates = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"]
                    }
                    else if (groupedDate[group] === "l") {
                        dates = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
                    }

                    if ((typeof a[property] !== "object" || typeof b[property] !== "object") && dates.indexOf(a[property].replace(/ .*/, '').toLowerCase().trim()) >= 0 && dates.indexOf(b[property].replace(/ .*/, '').toLowerCase().trim()) >= 0) {
                        if (dates.indexOf(a[property].replace(/ .*/, '').toLowerCase().trim()) > dates.indexOf(b[property].replace(/ .*/, '').toLowerCase().trim())) {
                            return 1;
                        }
                        if (dates.indexOf(b[property].replace(/ .*/, '').toLowerCase().trim()) > dates.indexOf(a[property].replace(/ .*/, '').toLowerCase().trim())) {
                            return -1;
                        }
                    }
                }
            }

            if (moment(a, 'ddd dddd').weekday() || moment(b, 'ddd dddd').weekday()) {
                return moment(a, 'ddd dddd').weekday() > moment(b, 'ddd dddd').weekday();
            }
            if (a[property] == parseInt(a[property]) && b[property] == parseInt(b[property])) {
                return a[property] - b[property];
            }
            if (a[property] > b[property]) {
                return 1;
            }
            if (b[property] > a[property]) {
                return -1;
            }

        }
        else {
            if (moment(a, 'ddd dddd').weekday() || moment(b, 'ddd dddd').weekday()) {
                return moment(a, 'ddd dddd').weekday() > moment(b, 'ddd dddd').weekday();
            }
            if (a[0] == parseInt(a[0]) && b[0] == parseInt(b[0])) {
                return a[0] - b[0];
            }
            if (a[0] > b[0]) {
                return 1;
            }
            if (b[0] > a[0]) {
                return -1;
            }
        }
    });

    return sortedArray
}
export function sortDataBaseOnValue(data, key, sortingOrder = {}, order = 'asc') {

    return data.sort(function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key))
            return 0;

        const first = (a[key].toLowerCase() in sortingOrder) ? sortingOrder[a[key]] : Number.MAX_SAFE_INTEGER;
        const second = (b[key].toLowerCase() in sortingOrder) ? sortingOrder[b[key]] : Number.MAX_SAFE_INTEGER;

        let result = 0;
        if (first < second)
            result = -1;
        else if (first > second)
            result = 1;
        return (order === 'desc') ? ~result : result
    });
}

export const alphabeticallySortNestedArrayOfObject = (array, sortParam, childrenParam) => {
    array.sort((a, b) => a[sortParam].localeCompare(b[sortParam]))
    array.forEach(a => {
        if (a[childrenParam] && a[childrenParam].length > 0)
            alphabeticallySortNestedArrayOfObject(a[childrenParam], sortParam, childrenParam)
    })
    return array;
}
