import React, {useState} from 'react'
import styled from 'styled-components'
import {Row, Col} from 'antd'
import AutoCompleteBar from "components/composites/autoCompleteBar";
import QualifierInput from "components/composites/QualifierInput";
import is from "is_js";

const Container = styled.div`
`

const defaultValue = {
  fieldsTags: [],
  qualifier: null
}

function ActualConditionsInput(props) {
  const {
    value,
    qualifiers,
    ...rest
  } = props

  const resource = props.resource ?? 'lines'

  const [condition, setCondition] = useState(value || defaultValue)

  const setField = fields => {
    if (is.existy(fields)) {
      saveCondition({
        fieldsTags: fields
      })
    }
  }

  const setQualifier = qualifier => {
    saveCondition({
      qualifier: qualifier
    })
  }

  function saveCondition(conditionValues) {
    const conditionValue = {
      ...condition,
      ...conditionValues
    }

    setCondition(conditionValue)
    props.form.setFieldsValue({
      ['condition']: conditionValue
    })
  }

  return (
    <Container {...rest}>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <AutoCompleteBar limit={1} prefix={resource} placeholder={`Select a field`}
            returnValue={(_, obj) => setField(obj)} value={condition.fieldsTags}/>
        </Col>

        <Col span={12}>
            <QualifierInput
                value={condition.qualifier}
                onChange={value => setQualifier(value)}
                types={qualifiers}
            />
        </Col>
      </Row>
    </Container>
  )
}

export default ActualConditionsInput