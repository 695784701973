import React, { useState } from 'react';
import { Modal, Form, Input, InputNumber, Button, Row, Col, AutoComplete, notification } from 'antd';
import * as _ from 'lodash';
import { Label } from 'components/zensmart-design-system';
import { ZenSmartAPI } from 'utils';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const CreateComponentModal = Form.create()(({ visible, onClose, form, orderId, componentTypes, componentCodes }) => {
  const { getFieldDecorator } = form;
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmField, setConfirmField] = useState('');

  const handleSave = () => {
    form.validateFields((error, values) => {
      if (error) {
        return;
      }

      const isNewType = values.type && !componentTypes.includes(values.type);
      const isNewCode = values.code && !componentCodes.includes(values.code);

      if (isNewType && isNewCode) {
        setConfirmVisible(true);
        setConfirmField('both');
      } else if (isNewType) {
        setConfirmVisible(true);
        setConfirmField('type');
      } else if (isNewCode) {
        setConfirmVisible(true);
        setConfirmField('code');
      } else {
        saveComponent(values);
      }
    });
  };

  const saveComponent = (values) => {
    const uppercaseValues = {
      ...values,
      code: values.code.toUpperCase(),
      ...(values.label && { label: values.label.toUpperCase() }),
    };

    ZenSmartAPI.post(`/api/v1/orders/${orderId}/component`, uppercaseValues)
      .then(response => {
        response = response.data;

        notification.success({
          message: 'Component Saved',
          description: 'The component has been successfully saved.',
        });

        form.resetFields();
        onClose();
      })
      .catch(error => {
        console.log(error);
        notification.error({
          message: _.get(error, 'response.data.message', 'Something went wrong!'),
        });
      });
  };

  const handleConfirmCancel = () => {
    setConfirmVisible(false);
    setConfirmField('');
  };

  const handleConfirmOk = () => {
    const values = form.getFieldsValue();
    saveComponent(values);
    setConfirmVisible(false);
    setConfirmField('');
  };


  const renderFormFields = () => {
    return (
      <Form layout="horizontal">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Type" {...formItemLayout}>
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Please input Type!' }],
              })(<AutoComplete dataSource={componentTypes} placeholder="Select or type a new type" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Code" {...formItemLayout}>
              {getFieldDecorator('code', {
                rules: [{ required: true, message: 'Please input Code!' }],
              })(<AutoComplete dataSource={componentCodes} placeholder="Select or type a new code" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Label" {...formItemLayout}>
              {getFieldDecorator('label')(<Input style={{ textTransform: 'uppercase' }} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Netgate Code" {...formItemLayout}>
              {getFieldDecorator('netgate_code')(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Price Per Unit Inc Tax" {...formItemLayout}>
              {getFieldDecorator('price_per_unit_inc_tax', { initialValue: 0 })(<InputNumber min={0} style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Unit Quantity" {...formItemLayout}>
              {getFieldDecorator('unit_quantity', { initialValue: 1 })(<InputNumber min={1} style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <>
      <Modal
        visible={visible}
        destroyOnClose={true}
        width={900}
        onCancel={() => {
          form.resetFields();
          onClose();
        }}
        footer={[
          <Button key="close" onClick={() => {
            form.resetFields();
            onClose();
          }}>
            Close
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
      >
        <Row type="flex" justify="center" style={{ padding: '10 20', marginBottom: '20px' }}>
          <Label status="blue">Add a Component</Label>
        </Row>
        {renderFormFields()}
      </Modal>
      <Modal
        visible={confirmVisible}
        onCancel={handleConfirmCancel}
        onOk={handleConfirmOk}
        okText="Yes"
        cancelText="No"
      >
        {confirmField === 'both' && (
          <p>A new Type ({form.getFieldValue('type')}) and Code ({form.getFieldValue('code')}) are being added. Do you want to continue?</p>
        )}
        {confirmField !== 'both' && (
          <p>A new {confirmField === 'type' ? 'Type' : 'Code'} ({form.getFieldValue(confirmField)}) is being added. Do you want to continue?</p>
        )}
      </Modal>
    </>
  );
});

export default CreateComponentModal;
