import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { GetLabel, DataTypes, DateFormatConversion } from '../../utils';
import { DropRegion, SummaryData } from '../primaries';
import HeaderRow from './HeaderRow';
import { SummaryRows } from '../composites';
import { Button } from 'components/zensmart-design-system';
import { Menu, Dropdown } from 'antd';
import { CaretDownOutlined, CheckOutlined, CaretUpOutlined } from '@ant-design/icons';
import { createGlobalStyle } from 'styled-components';
import create from 'antd/lib/icon/IconFont';
import sumstyles from './summarization.module.css';
import moment from 'moment';
import { panelSortData } from 'utils/sortData'
import is from "is_js";

const { GetDataType, DATATYPE } = DataTypes

const hideColumnsStyle = createGlobalStyle`
  tr{
    display : none;
  }
`
const TableStyle = styled.table`
  width: 100%;
  ${(props) =>
    props.tight &&
    props.isDashboard &&
    `
    font-size: ${updateFontSize(props.parentSize.height,props.tableRef)
  
    };
  `};
  margin-left: 15px;
  margin-top: 15px;
  th, td {
    ${props => props.tight ? `
    padding: 0.4em;
    overflow-x: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    ` : `
    padding: 16px;
    padding-left: 51px;
    `};
    p {
      margin: 0;
    }
  }
  tr .hover_column.left {
    border-left: 1px solid blue;
  }
  tr .hover_column.right{
    border-right: 1px solid blue;
  }
  thead th {
    text-transform: uppercase;
    cursor: pointer;
  }
  tbody tr {
    color: #8798AD;
    border: 1px solid #e8e8e8;
  }
  .drop_region {
    background-color: rgba(209,217,246,0.9);
    span {
      cursor: pointer;
    }
  }
  .header span {
    margin-left: 5px;
    cursor: pointer;
    color: #0B6BF2;
  }
`

const EmptyDiv = styled.div`
  text-align: center;
  margin: 32px 0;

  svg {
    height: 5rem;
    width: 5rem;
    margin: 0 auto;
  }

  p {
    color: rgba(0, 0, 0, 0.25);
  }
`
const updateFontSize = (widgetHeight,tableRef) => {
    
  let fontSize = 14
  const fontIncrement = 0.2
  if(is.existy(widgetHeight) && is.existy(tableRef) && tableRef.offsetHeight > widgetHeight) {
    tableRef.style.fontSize = fontSize + 'px'
    return
  }
  if (is.existy(widgetHeight) && is.existy(tableRef)) {
      while(true) {
        tableRef.style.fontSize = fontSize + 'px'
        if(tableRef.offsetHeight > widgetHeight){
          tableRef.style.fontSize = (fontSize - fontIncrement)  + 'px'
          break
        }
        else fontSize += fontIncrement
      }
  }
}

const EmptyDataStyle = () => (
  <EmptyDiv>
    <i>
      <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fill-rule="nonzero" stroke="#D9D9D9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path></g></g></svg>
    </i>

    <p>No data</p>
  </EmptyDiv>
)

const Summarization = ({
  columns = [],
  uniqueValues = [],
  groups = [],
  dataSource = [],
  onDragOver,
  onDrop,
  onGroupingDrop,
  hideDropZones,
  hideDetails,
  setHideDropZones,
  removeGroup,
  tight,
  hideColumn,
  editableReport,
  rowSelected,
  selectedRows = [],
  actionFields,
  setGroupedDate,
  groupedDate,
  containsGroupedItem,
  dateGroupingOptions = [],
  getGroupType,
  isPrintReport,
  setIsPrintReport,
  setIsPrintReportLoading,
  reportName,
  isDashboard,
  parentSize,
  filterClassName = false,
  setLastHoveredColumn,
  setCurrentDropColumn,
  setMouseCoord,
  setFirstColumnCenter,
  firstColumnCenter,
  cellRef,
  headerRef,
  lastHoveredColumn,
  classSection
}) => {
  const SORTPOSITION = {
    ASC: 'up',
    DESC: 'down'
  }
  const [selectedColumn, setSelectedColumn] = useState({
    id: null,
    position: ''
  })
  const [modifiedDataSource, setModifiedDataSource] = useState([])

  const [modifiedUniqueValues, setModifiedUniqueValues] = useState([])
  const [boundaryLeft, setBoundaryLeft] = useState(null)
  const [boundaryTop, setBoundaryTop] = useState(null)

  useEffect(() => {
    setModifiedDataSource(dataSource)
  }, [dataSource])

  useEffect(() => {
    if (!_.isEmpty(uniqueValues)) {
      setModifiedUniqueValues(toArray(uniqueValues, groups))
    }
  }, [uniqueValues])

  const sortByType = (data = [], groupedDate, group) => {
    // get the type of data and sort using specific method
    if (data.length > 0 && group) {
      const type = getGroupType(group)

      switch (GetDataType(type)) {
        case DATATYPE.number:
        case DATATYPE.bool:
          return data.sort((a, b) => numberSort(a, b));
        case DATATYPE.time:
          const grouped_date_keys = _.isEmpty(groupedDate) ? [] : Object.keys(groupedDate)
          // get the format of the group if it exists
          const format = grouped_date_keys.includes(group) ? DateFormatConversion(groupedDate[group]) : ''

          return data.sort((a, b) => timeSort(a, b, format));
        case DATATYPE.text:
        case DATATYPE.bucket:
          return data.sort((a, b) => stringSort(a, b, group));
        default:
          // return a[item.field].length - b[item.field].length
          return data;
      }
    }
    return data;
  }

  const stringSort = (a, b, group = "") => {

    if (group.includes('relative_due_date')) {
      const due_dates = ['overdue', 'today', 'tomorrow', 'later']
      if (a !== null && b !== null && due_dates.indexOf(a.toLowerCase().trim()) >= 0 && due_dates.indexOf(b.toLowerCase().trim()) >= 0) {
        if (due_dates.indexOf(a.toLowerCase().trim()) > due_dates.indexOf(b.toLowerCase().trim())) {
          return 1;
        }
        if (due_dates.indexOf(b.toLowerCase().trim()) > due_dates.indexOf(a.toLowerCase().trim())) {
          return -1;
        }
      }
    }
    const c = a && a !== null ? a.toLowerCase() : ""
    const d = b && b !== null ? b.toLowerCase() : ""
    return c.localeCompare(d)

  }

  const timeSort = (a, b, format = '') => {
    const c = format ? moment(a, format) : moment(a)
    const d = format ? moment(b, format).toString() : b
    return c.isBefore(d) ? -1 : 1;
  }

  const numberSort = (a, b) => a - b

  const toArray = (data, group = [], sortIndex = null, ifAscend = null) => {
    let flatArray = []

    sortByType(Object.keys(data), groupedDate, group[0]).map(key => {
      const checkType = getGroupType && getGroupType(group[0])
      const key_data = {
        value: checkType === "bool" && key == "True" ? "True" : checkType === "bool" && (key.trim() === "false" || key === 0) ? "False" : key,
        type: "summarization_header",
        group: group[0] ? group[0] : "",
        groupType: checkType,
        step: 1,
        count: 0
      }
      const groups_1 = sortIndex && _.isArray(data[key]) ? panelSortData(data[key], sortIndex, ifAscend) : data[key]

      if (_.isArray(groups_1)) {
        key_data.count = groups_1.length
        flatArray = [...flatArray, key_data, ...groups_1]
      } else {
        let data_2 = []
        sortByType(Object.keys(groups_1), groupedDate, group[1]).map(key_2 => {
          const checkType2 = getGroupType && getGroupType(group[1])
          const key_data_2 = {
            value: checkType2 === "bool" && key_2 == "True" ? "True" : checkType2 === "bool" && (key_2.trim() === "false" || key_2 === 0) ? "False" : key_2,
            type: "summarization_header",
            group: group[1] ? group[1] : "",
            groupType: checkType2,
            step: 2,
            count: 0
          }

          const groups_2 = sortIndex && _.isArray(groups_1[key_2]) ? panelSortData(groups_1[key_2], sortIndex, ifAscend) : groups_1[key_2]

          if (_.isArray(groups_2)) {
            key_data_2.count = groups_2.length
            key_data.count += groups_2.length
            data_2 = [...data_2, key_data_2, ...groups_2]
          } else {
            let data_3 = []
            sortByType(Object.keys(groups_2), groupedDate, group[2]).map(key_3 => {
              const checkType3 = getGroupType && getGroupType(group[2])
              const groups_3 = sortIndex && _.isArray(groups_2[key_3]) ? panelSortData(groups_2[key_3], sortIndex, ifAscend) : groups_2[key_3]
              if (_.isArray(groups_3)) {
                const key_data_3 = {
                  value: checkType3 === "bool" && key_3 == "True" ? "True" : checkType3 === "bool" && (key_3.trim() === "false" || key_3 === 0) ? "False" : key_3,
                  type: "summarization_header",
                  group: group[2] ? group[2] : "",
                  groupType: checkType3,
                  step: 3,
                  count: groups_3.length
                }
                key_data_2.count = key_data_2.count + groups_3.length
                key_data.count += groups_3.length
                data_3 = [...data_3, key_data_3, ...groups_3]
              }
            })
            data_2 = [...data_2, key_data_2, ...data_3]
          }
        })
        flatArray = [...flatArray, key_data, ...data_2]
      }
    })
    return flatArray
  }

  const handleSort = (column) => {
    const { dataIndex, sorter, type } = column

    if (dataIndex && sorter) {
      setSelectedColumn(selectedColumn => {
        if (selectedColumn.id === dataIndex) {
          if (selectedColumn.position === SORTPOSITION.ASC) {
            selectedColumn.position = SORTPOSITION.DESC
            if (modifiedUniqueValues.length > 0) {
              setModifiedUniqueValues(toArray(uniqueValues, groups, dataIndex, true))
            }
            else {
              setModifiedDataSource([...dataSource.sort((a, b) => sorter(a, b, type, dataIndex)).reverse()])
            }
          } else {
            selectedColumn.id = null
            selectedColumn.position = ""
            setModifiedDataSource([...dataSource])
            if (modifiedUniqueValues.length > 0) {
              setModifiedUniqueValues(toArray(uniqueValues, groups, dataIndex, false))
            }
            else {
              setModifiedDataSource([...dataSource])
            }
          }
          return selectedColumn
        } else {
          selectedColumn.id = dataIndex
          selectedColumn.position = SORTPOSITION.ASC
          setModifiedDataSource([...dataSource.sort((a, b) => sorter(a, b, type, dataIndex))])
          return selectedColumn
        }
      })
    }
  }

  const actionCondition = (actionFields && actionFields.length > 0 && columns.length > 0)
  const dateGroupingMenu = (item) => (
    <Menu>
      {dateGroupingOptions.map(option => {
        return (
          <Menu.Item onClick={() => setGroupedDate(item, option)}>
            {containsGroupedItem && (option === containsGroupedItem(item)) ? <p style={{ verticalAlign: "baseline" }}>{option}<CheckOutlined /></p> : option}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  const getTdProps = (id) => {
    return {
      ref: id === 0 ? cellRef: null,
      onDragEnter: () => {
        setLastHoveredColumn(id)
        
        if (id !== 0) {
          setCurrentDropColumn(id + 1)
          setMouseCoord('right')
        } else {
          const dimensions = cellRef.current.getBoundingClientRect()
          const center = (dimensions.left + dimensions.right) / 2
          setFirstColumnCenter(center)
          setBoundaryLeft(dimensions.left)
        }
      },
      onDragOver: e => {
        if (id === 0 && cellRef.current) {
          if (e.clientX < firstColumnCenter) {
            setCurrentDropColumn(id)
            setMouseCoord('left')
          } else {
            setCurrentDropColumn(id + 1)
            setMouseCoord('right')
          }
        }
      },
      onDrop: () => {
        setLastHoveredColumn(null)
      },
      onDragLeave: (e) => {
        if ((e.clientX < boundaryLeft) || (e.clientY < boundaryTop)) {
          setLastHoveredColumn(null)
        }
      },
      className: id === lastHoveredColumn ? classSection : '',
    }
  }

  const GroupData = ({
    data = [],
    hideColumn
  }) => {
    let switchRowCol = true
    return (
      data.length > 0 && (
        data.map((row, index) => {
          switchRowCol = !switchRowCol
          return (
            <>

              {(index === (groups.length) && !hideDropZones && editableReport && groups.length < 3) && (
                <tr className={'drop_region'}>
                  <td colSpan={columns.length + (actionCondition ? 1 : 0)}>
                    <DropRegion
                      onDragOver={onDragOver}
                      onDrop={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        onGroupingDrop()
                      }}
                    >
                      Drop a field here to create a grouping.
                  <span onClick={() => setHideDropZones(true)}>Hide</span>
                    </DropRegion>
                  </td>
                </tr>
              )}
              {(row.type && row.type === 'summarization_header') ? (
                <HeaderRow getProps={getTdProps} id={index} className='header' colspan={columns.length + (actionCondition ? 1 : 0)} indent={(parseInt(row.step) - 1)} colorTone={parseInt(row.step)}>
                  <p>

                    {GetLabel(row.group) + ': ' + row.value + ' (' + row.count + (row.count === 1 ? ' record)' : ' records)')}
                    {editableReport && <span onClick={() => {
                      removeGroup(groups.indexOf(row.group))
                    }}>Remove</span>}
                    {editableReport && row.groupType && GetDataType(row.groupType) === DATATYPE.time && (
                      <Dropdown overlay={dateGroupingMenu(row.group)} placement="bottomLeft">
                        <CaretDownOutlined />
                      </Dropdown>
                    )}
                  </p>
                </HeaderRow>
              ) : (
                  !hideDetails && (
                    <tr
                      key={index}
                      onClick={() => rowSelected && rowSelected(row.primary_key)}
                      style={selectedRows.includes(row.primary_key) ? {
                        backgroundColor: 'rgba(11,107,242,0.5)',
                        color: 'white'
                      } : {}}
                      className={`data_rows ${row.specialClass && (row.specialClass === 'even' || row.specialClass === 'odd') ? (
                          `${row.specialClass}`
                        ) : (
                            `${switchRowCol === true ? sumstyles.even : sumstyles.odd} ${row.primary_key % 2 === 0 ? 'even' : 'odd'}`
                          )}`}
                    >
                      <SummaryData getProps={getTdProps} key={row.primary_key ? row.primary_key : index} row={row} columns={columns} hideColumn={hideColumn} />
                      {(actionFields &&
                        <td {...getTdProps(index)}>
                          {actionFields.map((field, index) => (
                            <Button
                              key={index}
                              size='small'
                              color={field.color}
                              onClick={e => {
                                e.stopPropagation();
                                field.action(JSON.stringify(row.primary_key))
                              }}
                              style={{ marginRight: 5 }}
                            >
                              {field.label}
                            </Button>
                          ))}
                        </td>
                      )}
                    </tr>
                  )
                )}
            </>
          )
        })
      )
    )
  }

  const getThProps = (id) => {
    return {
      ref: id === 0 ? headerRef: null,
      onDragEnter: () => {
        setLastHoveredColumn(id)
        
        if (id !== 0) {
          setCurrentDropColumn(id + 1)
          setMouseCoord('right')
        } else {
          const dimensions = headerRef.current.getBoundingClientRect()
          const center = (dimensions.left + dimensions.right) / 2
          setFirstColumnCenter(center)
          setBoundaryTop(dimensions.top)
        }
      },
      onDragOver: (e) => {
        if (id === 0 && headerRef.current) {
          if (e.clientX < firstColumnCenter) {
            setCurrentDropColumn(id)
            setMouseCoord('left')
          } else {
            setCurrentDropColumn(id + 1)
            setMouseCoord('right')
          }
        }
      },
      onDrop: () => {
        setLastHoveredColumn(null)
      },
      onDragLeave: (e) => {
        if ((e.clientX < boundaryLeft) || (e.clientY < boundaryTop)) {
          setLastHoveredColumn(null)
        }
      },
      className: id === lastHoveredColumn ? classSection : '',
    }
  }

  const tablehead = (
    <thead>

      <tr style={{ display: hideDetails ? 'none' : undefined }} className={filterClassName ? filterClassName : undefined}
      >
        {columns && columns.map((column, index) => (
          <th
            key={column.dataIndex}
            style={
              (hideColumn) ?
                (
                  (column.dataIndex === hideColumn) ? { display: 'none' } : {}
                ) : {}
            }
            onClick={() => handleSort(column)}
            className={filterClassName ? filterClassName : undefined}
            {...getThProps(index)}
          >
            {column.title}
            {
              selectedColumn && (selectedColumn.id === column.dataIndex && (
                selectedColumn.position === SORTPOSITION.ASC ? (
                  <CaretUpOutlined />
                ) : (
                    <CaretDownOutlined />
                  )
              ))
            }
          </th>
        ))}
        {actionCondition && (
          <th>Actions</th>
        )}
      </tr>
    </thead>
  )
  const tablebody = (
    <tbody >
      {
        (columns && columns.length <= 0 && editableReport && !hideDropZones) && (
          <tr className={'drop_region'}>
            <td colSpan={columns.length + (actionCondition ? 1 : 0)}>
              <DropRegion style={{
                backgroundColor: 'rgba(209,217,246,0.9)',
                paddingLeft: 51,
                paddingTop: 14,
                paddingBottom: 14
              }}
                onDragOver={onDragOver} onDrop={onDrop}>
                Drag a column here from the left panel
                  </DropRegion>
            </td>
          </tr>
        )
      }
      {
        groups && (
          <GroupData
            data={groups.length === 0 || Object.keys(uniqueValues).length === 0 ? modifiedDataSource : (
              uniqueValues ? modifiedUniqueValues : []
            )}
            hideColumn={hideColumn}
          />
        )
      }
    </tbody>
  )

  const getCount = () => {
    const data = groups.length === 0 || Object.keys(uniqueValues).length === 0 ? modifiedDataSource : (
      uniqueValues ? modifiedUniqueValues : []
    );

    return data.filter(row => (row.type && row.type === 'summarization_header') && (row.step && row.step == 1)).reduce((total, current) => total + current.count, 0);
  }

  const table = useRef(null);

  return modifiedDataSource && modifiedDataSource.length > 0 ? (
    <div>
      <TableStyle
        ref={table}
        tight={tight}
        onDragOver={onDragOver}
        onDrop={onDrop}
        isDashboard={isDashboard}
        tableRef={table.current && table.current}
        parentSize={parentSize}
      >
        <>
          {tablehead}
          {tablebody}
        </>
      </TableStyle>
  
      {groups.length > 0 && (
        <div style={{
          backgroundColor: 'grey',
          width: 'fit-content',
          color: 'white',
          paddingLeft: '1em',
          paddingRight: '1em',
          marginLeft: '1em'
        }}>
          Grand Total: ({getCount()} records){editableReport &&
            "- Partial result only displayed in edit mode, please run this report for full results."
          }
        </div>
      )}
    </div>
  ) : null;
}

export default Summarization