import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

function AvailableFields(props) {
  const { fieldsToDisplay, section } = props;

  return (
    <div style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px' }}>
      <h3>Available Fields</h3>
      <Droppable droppableId={`${section.id}_fields`} direction="horizontal" isDropDisabled={false} initial={{ fieldsToDisplay }}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ display: 'flex', flexWrap: 'wrap', minHeight: '100px', backgroundColor: snapshot.isDraggingOver ? '#e6f7ff' : '#fff', }}
          >
            {fieldsToDisplay.map((field, index) => (
              <Draggable
                key={`${section.resource_name}_fields_${field.field}`}
                draggableId={`${section.resource_name}_fields_${field.field}`}
                index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{ margin: '0 4px 8px 4px', ...provided.draggableProps.style }}
                  >
                    <span
                      style={{
                        background: '#87e8',
                        padding: '4px 8px',
                        borderRadius: 4,
                        display: 'inline-block',
                      }}
                    >
                      {field.label}
                    </span>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>

  );
}

export default AvailableFields;
