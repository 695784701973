import { handleRowStyle } from '../../components/Table/TableUtilities';

export const PRINTER_TABLE_COLUMNS = [
    {
        title     : 'PRINTERS',
        key       : 'short_name',
        dataIndex : 'short_name',
        align     : 'center',
        width     : 'auto',
        type      : 'string',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    },
    {
        title     : 'LINEAR FEET ON PRINTER',
        key       : 'linear_length',
        dataIndex : 'linear_length',
        align     : 'center',
        width     : '120px',
        type      : 'number',
        render    : (value, row) => handleRowStyle(row?.row?.style, value),
    }
];
