export const addTargetSeries = (data, chartConfiguration) => {
    const addTargetData = []

    if (!data[0].some(itemData => itemData === 'Target')) {
        const addedTarget = data.forEach((item, key) => {
            const itemObj = item
            if (key === 0) {
                itemObj.push("Target")
            }
            else {
                itemObj.push(chartConfiguration && chartConfiguration.target ? parseInt(chartConfiguration.target) : 0)
            }
            addTargetData.push(itemObj)
        })
        return addTargetData
    }
    else {
        return data
    }
}

export const addTargetSeriesOptions = (data, chartConfigData, chartConfiguration) => {
    const lengthIndex = chartConfigData[0].length - 2

    if (data.isStacked && chartConfiguration.stacked_percentage) {
        data = addPercentageStack(data)
    }
    data.series = {
        [parseInt(lengthIndex)]: {
            color: '#000000',
            type: 'line'
        },
    }
    data.seriesType = 'bars'
    return data
};

export const addPercentageStack = (data) => {
    data.isStacked = "percent"
    return data
};

export const tableChartOptions = (options, parentSize) => {
    options.width = parentSize.width;
    return options;
};

export const gaugeChartOptions = (data, options) => {
    const sum = data.reduce(((v, i) => i.length > 1 && typeof i[1] === "number" ? v + i[1] : 0), 0);
    options.max = (sum > 0) ? sum : 100;
    return options;
};
