import React, { useState, useEffect } from 'react'
import { notification } from 'antd'
import _ from "lodash"

export const errorHandler = (error = {}) => {
    return (
        <div>
            <ul>
                {Object.keys(error).map(item => {
                    if (Array.isArray(error[item])
                    ) {
                        return <li>{error[item][0]}</li>
                    }
                    else if (typeof error[item] === "object") {
                        const returnData = Object.keys(error[item]).map(item2 => {
                            return <li>{error[item][item2][0]}</li>
                        })
                        return returnData
                    }
                    else {
                        return <li>{error[item]}</li>
                    }
                })}
            </ul>
        </div>
    )

}

export const errorNoticationHandler = (err, defaultErrorMesssage = "") => {

    if (err instanceof Error && _.get(err, 'message').includes('403')) {
        return;
    }
    else if (_.get(err, 'response.status') === 422) {
        notification.error({
            message: errorHandler(_.get(err, 'response.data.errors'))
        })
        return
    }
    else if (_.get(err, 'response.data.message')) {
        notification.error({
            message: _.get(err, 'response.data.message')
        })
        return
    }
    notification.error({ message: defaultErrorMesssage })

}