import React from 'react';
import {Button, List, Modal, Table} from 'antd';
import _ from 'lodash';

export default ({show, onClose, response}) => {

  if (response === null) return null;

  const renderTitle = (response) => {
    let defaultClasses = 'text-center tracking-wider font-bold text-lg';
    const message = _.get(response, 'message', 'Failed cloning products');

    if (_.get(response, 'type') !== 'success') {
      defaultClasses += ' text-red-700';
    } else {
      defaultClasses += ' text-green-900';
    }

    return (<div
        className={defaultClasses}>{message}</div>);
  };

  const renderContent = (response) => {
    const type = _.get(response, 'type');
    if (type === 'validation-error') {
      const dataSource = _.flattenDeep(Object.keys(response.errors).map(rowIndex => {
        const error = response.errors[rowIndex];
        return Object.keys(error).map(key => {
          return {
            key: `${rowIndex}-${key}`,
            row: parseInt(rowIndex) + 1,
            field: <span className={`font-bold`}>{key}</span>,
            error: error[key],
          };
        });
      }))
      return <Table
          pagination={false}
          rowClassName={'text-red-600'}
          bordered={false}
          size={'small'}
          columns={[
            {
              title: 'Row',
              dataIndex: 'row',
              key: 'row',
            },
            {
              title: 'Field',
              dataIndex: 'field',
              key: 'field',
            },
            {
              title: 'Error',
              dataIndex: 'error',
              key: 'error',
            },
          ]}
          dataSource={dataSource}
      />;
    }

    if(response.results.length === 0) {
      return null;
    }

    return <List
        bordered={false}
        dataSource={response.results.map((result, index) => {
          return {
            key: index,
            icon: result.status === 'success' ?
                // Success icon
                  <svg
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 text-green-600 inline">
                    <path fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"/>
                  </svg> :
                // Error icon
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                       fill="currentColor"
                       className="w-6 h-6 text-red-600 inline">
                    <path fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clipRule="evenodd"/>
                  </svg>,
            code: <span className={`font-extrabold mr-1 ${result.status === 'success'
                                                     ? 'text-green-900'
                                                     : ' text-red-600'}`}>{result.code}</span>,
            status: result.status,
            message: result.status === 'success' ? <span className={'text-xs text-green-600'}>{result.message}</span> : <span className={'text-xs text-red-600'}>{result.message}</span>
          };
        })}
        renderItem={item => (
            <List.Item className={'flex justify-start'} style={{alignItems: 'flex-start'}}>
              {item.icon} {item.code} {item.message}
            </List.Item>
        )}/>;
  };

  const bodyStyle = {};
  if(_.get(response, 'results.length') === 0) {
    bodyStyle.display = 'none';
  }

  return (
      <Modal
          bordered={false}
          width={800}
          title={renderTitle(response)}
          visible={show}
          onOk={onClose}
          bodyStyle={bodyStyle}
          footer={[
            <Button key="submit" type="primary" onClick={onClose}>
              Okay
            </Button>,
          ]}
      >
        {renderContent(response)}
      </Modal>
  );
};