const HandleError = (err) => {
  const timeoutError = 'This report has taken too long to process due to the amount of data being returned. Please add additional filters or reduce report complexity.'

  if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message.includes('Maximum execution time of') ? timeoutError : err.response.data.message
  } else if (err.request && err.request.status === 504) {
    return timeoutError
  } else {
    return err.message ? err.message : 'error loading matrix data'
  }
}

export default HandleError