import React, { useState, useRef } from "react";
import { Modal, Button } from "antd";
import { LibConstants, Libraries } from "../../config/Imposition";

const ImportRecipe = ({
  setRecipes,
  setImpoDesignerDisabled,
  product,
  block,
  printer,
  finishing
}) => {
  const file = useRef()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showValidationModal, setShowValidationModal] = useState(false)
  const handleFileClick = () => {
    file.current.click()
  }

  const importJsonFile = (e) => {
    let reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(e.target.files[0]);

    function onReaderLoad(e) {
      try {
        let content = JSON.parse(e.target.result);

        // loop each of the constants and store it in an array
        let libConstantsArray = []
        for (const [key, val] of Object.entries(LibConstants)) {
          libConstantsArray.push(val)
        }

        // loop each of the keys and store it in array
        let labelsArray = []
        let labels_param_names = []
        content.forEach(item => {
          labelsArray.push(item.name)
          let parameterNamesArray = item.parameters.map(({ name }) => name);
          labels_param_names.push({
            label: item.name,
            parameters: parameterNamesArray
          });
        });

        // check whether the "Label" field name OR parameter names exists inside the libraries
        labels_param_names.forEach(label_param_names => {
          let matchingLibrary = Libraries.find(library => library.label === label_param_names.label);
          if (!matchingLibrary) {
            throw `Invalid recipe label: ${label_param_names.label}`
          }
          label_param_names.parameters.forEach(param => {
            if (!matchingLibrary.parameters.includes(param)) {
              throw `Invalid recipe parameter: ${param} for label: ${matchingLibrary.label}`
            }
          });
        });

        setRecipes(content)
        setShowConfirmModal(false)
        setImpoDesignerDisabled(false)
      } catch (err) {
        setShowConfirmModal(false)
        setShowErrorModal(true)
      }

      // empty file ref
      file.current.value = ""
    }
  }

  const handleImport = () => {
    if (!product || !block || !printer || !finishing) {
      setShowValidationModal(true)
    } else {
      setShowConfirmModal(true)
    }
  }

  return (
    <>
      <Button
        style={{ marginRight: '0.5rem' }}
        onClick={() => handleImport()}
      >
        Import
      </Button>
      {/* Confirm Modal */}
      <Modal
        title="Confirm"
        visible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onOk={() => handleFileClick()}
        centered
      >
        Are you sure? You will lose any unsaved changes!
      </Modal>
      {/* Import Error Modal */}
      <Modal
        title="Error"
        visible={showErrorModal}
        onCancel={() => setShowErrorModal(false)}
        onOk={() => setShowErrorModal(false)}
        centered
      >
        Imported file is not valid. The fields are not aligned with the current available Recipe/s.
      </Modal>
      {/* Validation Error Modal */}
      <Modal
        title="Error"
        visible={showValidationModal}
        onCancel={() => setShowValidationModal(false)}
        onOk={() => setShowValidationModal(false)}
        centered
      >
        Please select all the required fields first.
      </Modal>
      <input
        type="file"
        id="importFile"
        ref={file}
        style={{ display: 'none' }}
        onChange={(e) => importJsonFile(e)}
      />
    </>
  )
}

export default ImportRecipe;