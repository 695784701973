import React, { useState } from 'react'
import { Modal, Button, Form, Input, Select , notification } from 'antd';
import { addPostDispatchMessagesRoute , editPostDispatchMessagesRoute } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'
import { alphabeticalData } from 'utils/sortData';

function UpdateForm(props) {
    const {
        form,
        visible,
        fetchData,
        formInitialValue,
        setFormInitialValue,
        postDispatchMessageModalName,
        templates,
        vendors,
        setShowModal,
        setPostDispatchMessageModalName,
    } = props

    const {
        getFieldDecorator,
        getFieldsValue,
        validateFields,

    } = form


    const [loading, setLoading] = useState(false)
    const { Option } = Select;
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            const payload = getFieldsValue();
            if (!err) {
                setLoading(true)
                const api = formInitialValue.id ?  ZenSmartAPI.patch(editPostDispatchMessagesRoute(formInitialValue.id), payload) : ZenSmartAPI.post(addPostDispatchMessagesRoute, payload)
                api.then((res) => {
                        notification.success({ message: formInitialValue.id ? "Update accessory successful!" : "Add new post dispatch message!" })
                        fetchData()
                        setLoading(false);
                        handleCancel()
                    })
                    .catch((res) => {
                        setLoading(false);
                        if (res.response.status === 422) {
                            notification.error({ message: "Post Dispatch Message Already exist" })
                        }
                        else {
                            notification.error({ message: "Error saving post dispatch message!" })
                        }

                    })
            }
        });
    };

    const handleCancel = () => {
        setShowModal(false)
        setFormInitialValue({})
        setPostDispatchMessageModalName("")
    }
    
    return (
        <div>
            <Modal title={postDispatchMessageModalName} visible={visible} footer={[
                <Button
                    key="back"
                    onClick={handleCancel}
                >
                    Cancel
            </Button>,
                <Button key="submit"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    Save
            </Button>,
            ]}>
                {visible &&
                    <Form layout="vertical" onSubmit={handleSubmit}>
                        <Form.Item label="Template:"
                        >
                            {getFieldDecorator(`template`, {
                                rules: [{ required: true }],
                                initialValue: formInitialValue.template_id ? formInitialValue.template_id : undefined
                            }
                            )(
                                <Select 
                                    optionFilterProp="children"
                                    showSearch style={{ width: "100%" }}
                                    placeholder="Please Select Template">
                                    {alphabeticalData(templates, "text").map((data, key) =>
                                        <Option value={data.id}>{data.name}</Option>
                                    )}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Vendors:"
                        >
                            {getFieldDecorator(`vendor`, {
                                rules: [{ required: true }],
                                initialValue: formInitialValue.vendor_id ? formInitialValue.vendor_id : undefined

                            }
                            )(
                                <Select 
                                    optionFilterProp="children"
                                    showSearch style={{ width: "100%" }}
                                    placeholder="Please Select Vendor">
                                    {alphabeticalData(vendors, "text").map((data, key) =>
                                        <Option value={data.id}>{data.name}</Option>
                                    )}
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                }
            </Modal>
        </div>
    )
}

const PostDispatchMessageForm = Form.create({
})(UpdateForm)

const PostDispatchMessageUpdateModal = (props) => {
    const { visible,  fetchData, formInitialValue, setFormInitialValue, postDispatchMessageModalName , templates , vendors , setShowModal , setPostDispatchMessageModalName } = props
    return (
        <div>
            <PostDispatchMessageForm
                visible={visible}
                setFormInitialValue={setFormInitialValue}
                formInitialValue={formInitialValue}
                fetchData={fetchData}
                postDispatchMessageModalName={postDispatchMessageModalName}
                templates={templates}
                vendors={vendors}
                setShowModal={setShowModal}
                setPostDispatchMessageModalName={setPostDispatchMessageModalName}
            />
        </div>
    )
}

export default PostDispatchMessageUpdateModal