import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Row, Col } from 'antd'
import { panelSortData } from 'utils/sortData'
import moment from 'moment';
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { operatorActivityInitialData } from "utils/panelsInitialData"

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border:none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    border: 1px solid #f1f3ff;
}

`
const TableData = (props) => {

    const { loading, data } = props
    const [columns, setColumns] = useState([])

    const rowStyle = (value, index, row, ifParentRow) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { fontWeight: ifParentRow ? "bold" : undefined, border : "2px solid black !important" },
            },
            children: <div>{value}</div>,
        };
        return obj
    }
    useEffect(() => {
        if (data.length > 0) {
            const constructColumn = []
            Object.keys(data[0]).map(item => {
                constructColumn.push({
                    title: item.toUpperCase(),
                    dataIndex: item,
                    key: item,
                    render(value, row, index) {
                        return rowStyle(value !== null && value !== undefined ? value : "-", index, row, item === "operator")
                    }
                })
            })
            setColumns(constructColumn)
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "50vh" }}
                    pagination={false}

                />
            </StyleTable>
        </>
    )
}

export default TableData