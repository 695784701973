import React from 'react'
import {
    Table,
    Collapse,
    Row,
    Button,
    Col,
    Icon,
    Form,
    Select,
    Input,
    InputNumber,
    Checkbox,
} from 'antd'
import styled from 'styled-components'
import { alphabeticalData } from 'utils/sortData'
import _ from 'lodash';
import {GetDataInput} from '../../hooks';


const Field = styled(Form.Item)`
  margin-bottom: 16px;
`


const FieldPanel = styled(Form.Item)`
  .ant-input-number{
    width : 100%;
  }
`

const DeleteButton = styled(Button)`
  font-size: 12px;
  color: red;
  font-weight: bold;
`

const ButtonContainer = styled.div`
  margin-top: 15px;
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`
const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0243EB;
  font-size: 12px;
  border: 0;
  margin-top: 10px;
  box-shadow: none;
`



export const ComponentDetail = (form, getFieldDecorator, errors, components, setComponents, getFieldValue, setFieldsValue) => {
    const { Panel } = Collapse;


    const getHeaders = (text) => {
        return (
            <Row type="flex" align="middle" style={ { width : "100%" }}>
                <Col>
                    <p>{text.toUpperCase()}</p>
                </Col>
            </Row>
        )
    }

    const onAddNewRule = (panel) => {
      let emptyHeaders = [];

      if(panel.values.length !== 0){
        emptyHeaders = Object.keys(panel.values[0])
      }

      let emptyValues = {}
      if(emptyHeaders.length === 0){
        emptyHeaders = panel.fields
        emptyValues['id'] = Math.random(1,1000) 
        for(let i = 0; i < emptyHeaders.length; i++){
          emptyValues[emptyHeaders[i].field] = null;
        }
      }else{
        for(let i = 0; i < emptyHeaders.length; i++){
          emptyValues[emptyHeaders[i]] = null;
        }
      }
      

      const newData = components.map((item, key) => {
        
        return (
           ((item.collection === true) ? ((panel.table === item.table)
            ? 
            {
              ...item, 'values': (item.values.concat(emptyValues))
            }
            : item) : item)
          )
  
        }
      );
      setComponents(newData)  
    
    }

    const onRemoveNewRule = (panel,index) => {
      const newData = components.map((item, key) => {
        return (
           ((item.collection === true) ? ((panel.table === item.table)
            ? 
            {
              ...item, 'values': (Object.values(_.omit(item.values, index)))
            }
            : item) : item)
          )
  
        }
      );
      setComponents(newData) 
    }

    const getForm = (section,i,is_collection = false,formValue=null,table) => {
      const { label, field, data_type, options, required, value } = section;
          if (data_type === 'TEXT') {
            return (
                  <FieldPanel key={field} label={is_collection ? null : label}
                  style={is_collection ? { width : "250px"} : { marginBottom : "16px" , display : "inline-block" , width : "46%" , margin : "0 20px"}}
                  >
                    {getFieldDecorator( (is_collection ? [table,field,i] : [table,field]), {
                      initialValue: (formValue ? formValue : value),
                      rules: [{ required , message: `Please input the ${label}!` }]
                    })(<Input />)}
                  </FieldPanel>
            )
          } 
          if (data_type === 'ID') {
            return (
              <FieldPanel key={field} label={is_collection ? null : label}
              style={is_collection ? { width : "250px"} : { marginBottom : "16px" , display : "inline-block" , width : "46%" , margin : "0 20px"}}
              >
                {getFieldDecorator( (is_collection ? [table,field,i] : [table,field]), {
                  initialValue: (formValue ? formValue : value),
                })(<Input type="hidden"></Input>)}
              </FieldPanel>
            )
          }
          if(data_type === 'ADD_FEATURE_SELECT') {
            const fieldDetails = {
              resource_name: table,
              resource_id: field,
              field: field,
              create_endpoint: section.create_endpoint,
              create_field: section.create_field,
              data_type,
              options,
              value
            }
            return <FieldPanel key={field} label={is_collection ? null : label}
             style={is_collection ? { width : "250px"} : { marginBottom : "16px" , display : "inline-block" , width : "46%" , margin : "0 20px"}}
            >
              <GetDataInput field={fieldDetails} form={form} />
            </FieldPanel>
          }
          if (data_type === 'INTEGER') {
            return (
              <FieldPanel key={i} label={is_collection ? null : label}
              style={is_collection ? { width : "250px"} : { marginBottom : "16px" , display : "inline-block" , width : "46%" , margin : "0 20px"}}
              >
                {getFieldDecorator((is_collection ? [table,field,i] : [table,field]), {
                  initialValue: (formValue ? formValue : value),
                  rules: [{ required, message: `Please input the ${label}!` }]
                })(<InputNumber  />)}
              </FieldPanel>
            )
          }
          if (data_type === 'SELECT') {

            return (
                    <FieldPanel key={i} label={is_collection ? null : label}
                  style={is_collection ? { width : "250px"} : { marginBottom : "16px" , display : "inline-block" , width : "46%" , margin : "0 20px"}}
                  >
                    {getFieldDecorator((is_collection ? [table,field,i] : [table,field]), {
                      initialValue:  (
                        (
                          formValue !== undefined && formValue !== null && formValue !== 'null') ? (formValue === false ? "false" : formValue.toString()) : ( (value !== undefined && value !== null && value !== 'null') ? (value === false ? "false" : value.toString()) : null)
                        ),
                      rules: [{ required, message: `Please input the ${label}!` }]
                    })(
                      <Select>
                        {alphabeticalData(options, "text").map(item => <Select.Option key={item.value}>{item.text}</Select.Option>)}
                      </Select>
                    )}
                  </FieldPanel>
            )
          }
          if (data_type === 'BOOLEAN') {
            return (
              <FieldPanel key={i} label={is_collection ? null : label}
              style={is_collection ? { width : "100%"} : { marginBottom : "16px" , display : "inline-block" , width : "46%" , margin : "0 20px"}}
              >
                {getFieldDecorator((is_collection ? [table,field,i] : [table,field]), {
                  rules: [{ required, message: `Please input the ${label}!` }]
                })(<Checkbox defaultChecked={value} />)}
              </FieldPanel>
            )
          }
          
          return false;
    }

    const getInput = ( (text, record , index,idata , panel) => {
      if(idata !== undefined){
        return getForm(idata,index,true,text,panel.group_name)
      }else{
        if(text === 'action'){
          return (
              <div className={`ant-form-item ant-row`}>
                <DeleteButton onClick={ () => {onRemoveNewRule(panel,index)} } >REMOVE</DeleteButton>
              </div>
          )
        }
        
      }
    })

    const getColumns = ( (panel) => {
        let panels = panel.fields.map(idata => {
          return {
                title: getHeaders(idata.label),
                dataIndex: idata.field,
                key: idata.field,
                editable: true,
                render(text, record , index) {
                  return <div>{getInput(text, record ,index , idata , panel)}</div>
                }
            }
        })

        panels.push(
            {
              title: "Action",
              dataIndex: 'action',
              key: 'action',
              render(record , row, index) {
                return <div>{getInput('action' , row, index , undefined , panel)}</div>
              }
            }
        );

        return panels;
    })
    
    const formItems = components.map( (panel , x) => {
        return (<Panel key={x} showArrow={false} header={panel.panel_header}>
            {panel.collection ? 
              <Table
                  dataSource={panel.values}
                  columns={(getColumns(panel))}
                  size="middle"
                  rowKey="id"
                  pagination={false}
                  style={{ overflow : "scroll" }}
              >
              </Table>
            :
             _.chunk(panel.fields, 2).map((chunk, i) => <Row key={i}>{chunk.map((section, i) => {
               return getForm(section,i ,false,null,panel.group_name)
             })}</Row>)

            }
            {panel.collection && panel.enable_add_entry ? 
              <ButtonContainer>
                <Row type="flex" align="middle" justify="space-between">
                  <Col span={4}>
                  <AddButton onClick={ () => {onAddNewRule(panel)} } style={{margin : "0 0 10px 0"}}>
                    <Icon type="plus-circle" theme="filled" />
                    Add New
                  </AddButton>
                  </Col>
                </Row>
              </ButtonContainer>
            : null }
        </Panel>
        )
        
    })
    return (
        <>
            {formItems}
        </>
        
    
    )
}