import React, { useState } from 'react'
import { Table, Select, Button, Form, Row, Col, Icon as AntIcon, Modal, Input, notification, Spin } from 'antd';
import { generateKey } from '../../utils';
import styled from 'styled-components';
import QualifierInput
  from '../../../../components/composites/QualifierInput';
import debounce from 'lodash/debounce';
import { modelDefinitionRoute, freightGroupCreateRoute, fethAllFreightGroup } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'

const { Column } = Table;

const ModalTitle = styled.h3`
  font-weight: bold;
`
const Icon = styled(AntIcon)`
  font-size: 16px;
`

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-label {
    display: none
  }
`
const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0243EB;
  font-size: 12px;
  border: 0;
  margin-top: 10px;
  box-shadow: none;
    `
const RemoveButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  border: 0;
  margin-top: 10px;
  box-shadow: none;
`
const { Option } = Select

const PreDispatchModal = ({ visible, onCancel, qualifiers, preDistachRules, letterRange, setPreDistachRules, freightGroups, setFreightGroups, originalDispatchRules, setOriginalDispatchRules }) => {
  const [errors] = useState({});
  const [fieldsValue, setFieldsValue] = useState([])
  const [loading, setLoading] = useState(false)
  const onRemove = (item) => {
    const newData = [...preDistachRules.box_ranges];
    newData.splice(newData.indexOf(item), 1);
    setPreDistachRules({ ...preDistachRules, box_ranges: newData });
  }

  const onAddNewRule = () => {

    if (!preDistachRules.box_ranges) {
      notification.error({ message: "Please select freight group first!" })
      return
    }
    const newRule = {
      id: `newRule.${generateKey()}`,
      box_range_end: null,
      box_range_start: null,
      groupable_id: null,
      groupable_type: null,
      letter_range: null,
      model_definition_id: null,
      model_definition_label: null,
      qualifier: null
    };

    const newData = [
      ...preDistachRules.box_ranges,
      newRule
    ];
    setPreDistachRules({ ...preDistachRules, box_ranges: newData });

  }

  const constructModalHeader = () => (
    <>
      <ModalTitle>Pre Dispatch Ranges</ModalTitle>
    </>
  );

  const onChangeStartBox = (value, item, property) => {
    const selectedRow = preDistachRules.box_ranges.findIndex(selectedRow => selectedRow.id === item.id);
    const newData = preDistachRules.box_ranges.map((item, key) => {
      return (
        key === selectedRow
          ? { ...item, [property]: value }
          : item
      )
    }
    )
    setPreDistachRules({ ...preDistachRules, box_ranges: newData });

  }

  const onChangeModelDefinition = (value, item, index) => {
    const selectedRow = preDistachRules.box_ranges.findIndex(selectedRow => selectedRow.id === item.id);
    const parseValue = JSON.parse(value)
      if (item.is_complex) {
        let model_definition_ids = item.model_definition_id.split(';')
        let model_definition_labels = item.model_definition_label.split(';')

        model_definition_ids[index] = parseValue.id
        model_definition_labels[index] = parseValue.label

        parseValue.id = model_definition_ids.join(';')
        parseValue.label = model_definition_labels.join(';')
      }
    const newData = preDistachRules.box_ranges.map((item, key) => {
      return (
        key === selectedRow
          ? { ...item, model_definition_id: parseValue.id, model_definition_label: parseValue.label.replace('lines.', '') }
          : item
      )
    }
    )

    setPreDistachRules({ ...preDistachRules, box_ranges: newData });
  }

  const setQualifierValue = (value, item, index) => {
    const selectedRow = preDistachRules.box_ranges.findIndex(selectedRow => selectedRow.id === item.id);
      if (item.is_complex) {
          let qualifiers = item.qualifier.split(';')

          qualifiers[index] = value 

          value = qualifiers.join(';')
      }
    const newData = preDistachRules.box_ranges.map((item, key) => {
      return (
        key === selectedRow
          ? { ...item, qualifier: value }
          : item
      )
    }
    )

    setPreDistachRules({ ...preDistachRules, box_ranges: newData });
  };

  const onSearchField = debounce(value => {
    ZenSmartAPI.get(modelDefinitionRoute(`lines.${value}`), {})
      .then((res) => {
        setFieldsValue(res.data.data)
      })
  }, 600);

  const selectFreightGroup = (value) => {

    setLoading(true)
    ZenSmartAPI.get(`api/v1/freight-group/${value}/box-ranges`)
      .then((res) => {
        setPreDistachRules({ ...res.data.data, freight_group_id: value })
        setLoading(false)
      })
      .catch((res) => {
        notification.error({ message: 'Error fetching box ranges!' })
        setLoading(false)
      })

  }


  const getFreightGroups = () => {
    setLoading(true)
    ZenSmartAPI.get(fethAllFreightGroup)
      .then((res) => {
        setLoading(false)
        setFreightGroups && setFreightGroups(res.data.data)
      })
      .catch((res) => {
        setLoading(false)
      })
  };

  const OnSave = (e) => {
    if (!preDistachRules.box_ranges || preDistachRules.box_ranges.length === 0) {
      notification.error({ message: "Please add fields!" })
      return
    }

    const checkIfNullValues = preDistachRules.box_ranges.some(data => {

      if (letterRange === "1") {
        if (data.model_definition_id === null || data.model_definition_label === null || data.box_range_end === null || data.box_range_end === "" || data.box_range_start === null || data.box_range_start === ""
          || data.letter_range === null || data.letter_range === "") {
          return true
        }
      }
      else {
        if (data.box_range_end === null || data.box_range_end === "" || data.box_range_start === null || data.box_range_start === "") {
          return true
        }
      }
      if(typeof(data.model_definition_id) == "string" &&  data.model_definition_id.includes(";")){
        let fields = data.model_definition_id.split(";")
        let qualifier = data.qualifier.split(";")
        let fieldCount = 0
        let qualifierCount = 0
        for(let i = 0; i < fields.length; i++){
          if(fields[i]){
            fieldCount++
          }
        }
        for(let i = 0; i < qualifier.length; i++){
          if(qualifier[i]){
            qualifierCount++
          }
        }
        if(fieldCount !== fields.length){
          return true
        }
        if(qualifierCount !== qualifier.length){
          return true
        }
      }
      return false
    })

    if (checkIfNullValues) {
      notification.error({ message: "Please input fields!" })
      return false;
    }

    let sortOrderValueCount = 0;
    let nullCount = 0;
    for(var z = 0; z < preDistachRules.box_ranges.length; z++){
      if(preDistachRules.box_ranges[z].sort_order !== null && preDistachRules.box_ranges[z].sort_order !== undefined && preDistachRules.box_ranges[z].sort_order !== '' && preDistachRules.box_ranges[z].sort_order !== 0 &&  preDistachRules.box_ranges[z].sort_order !== '0'){
        sortOrderValueCount = (sortOrderValueCount + 1);
      }else{
        nullCount = (nullCount + 1);;
      }
    }

    if( (nullCount != preDistachRules.box_ranges.length) && sortOrderValueCount != preDistachRules.box_ranges.length){
      notification.error({ message: "Either all ranges should have sort orders Or none of them should have sort orders!" })
      return false;
    }


    const checkDupValues = checkForDuplicates(preDistachRules.box_ranges,'sort_order')
    if ((nullCount !== preDistachRules.box_ranges.length) && checkDupValues) {
      notification.error({ message: "same value for sort order not allowed!" })
      return false;
    }

    setOriginalDispatchRules(preDistachRules)
    onCancel(e, true)
  }

  

  const checkForDuplicates = (source, keyName) => {
    return source.filter((item, index, array) => {
      return array.findIndex(t => Math.floor(t[keyName]) === Math.floor(item[keyName])) === Math.floor(index);
    }).length !== source.length
  }

  const OnCancelData = (e) => {
    setPreDistachRules(originalDispatchRules)
    onCancel(e, true)
  }

  const addNewFreightGroup = (value) => {
    if (value.keyCode === 13) {
      const ifFreightGroupExist = freightGroups.some(data => {
        return data.name === value.target.value
      })

      if (ifFreightGroupExist) {
        return;
      }

      const payload = { name: value.target.value }
      ZenSmartAPI.post(freightGroupCreateRoute, payload)
        .then((res) => {
          getFreightGroups()
        }).catch((res) => {
          notification.error({ message: 'Save freight group not successful!' })
        })
    }
  }

    const fieldsComponent = (item) => {
        const modelDefinitions = item.model_definition_label ? item.model_definition_label.split(";") : [null]

        
        return (
            modelDefinitions.map((model_definition_label, index) => {
                return ( 
                    <Row align='middle' justify='center' style={{ padding: 3 }}>
                        <Select
                            showSearch
                            key={index}
                            style={{ width: "100%" }}
                            placeholder="Enter Field (Click Suggestions to add each field)"
                            optionFilterProp="children"
                            value={model_definition_label}
                            onChange={(value) => onChangeModelDefinition(value, item, index)}
                            onSearch={onSearchField}
                        >
                            {fieldsValue.map(data =>
                            <Option value={JSON.stringify({ id: data.id, label: data.label })}>{data.label.replace('lines.', '')}</Option>
                            )}
                        </Select>
                    </Row>
                )
            })
        )
    }

    const qualifierComponent = (item, qualifiers) => {
        const qualifiersArray = item.qualifier ? item.qualifier.split(";"): [null]

        
        return (
            qualifiersArray.map((qualifier, index) => {
                return ( 
                    <QualifierInput
                        key={index}
                        value={qualifier}
                        onChange={value => setQualifierValue(value, item, index)}
                        types={qualifiers}
                    />
                )
            })
        )
    }

    const handleAddFieldComponent = item => {
        const selectedRow = preDistachRules.box_ranges.findIndex(selectedRow => selectedRow.id === item.id);
        let qualifiers = item.qualifier ? item.qualifier.split(';') : [null]
        let model_definition_ids = item.model_definition_id ? item.model_definition_id.toString().split(';') : [null]
        let model_definition_labels = item.model_definition_label ? item.model_definition_label.split(';') : [null]

        model_definition_ids[model_definition_ids.length] = null 
        model_definition_labels[model_definition_labels.length] = null 
        qualifiers[qualifiers.length] = null 

        if (!item.is_complex) item.is_complex = true

        model_definition_ids = model_definition_ids.join(';')
        model_definition_labels = model_definition_labels.join(';')
        qualifiers = qualifiers.join(';')

        const newData = preDistachRules.box_ranges.map((item, key) => {
            return (
                key === selectedRow
                ? { ...item, qualifier: qualifiers, model_definition_id: model_definition_ids, model_definition_label: model_definition_labels }
                : item
            )
        }
        )

        setPreDistachRules({ ...preDistachRules, box_ranges: newData });
    }

    const handleRemoveFieldComponent = item => {
        const selectedRow = preDistachRules.box_ranges.findIndex(selectedRow => selectedRow.id === item.id);
        let qualifiers = item.qualifier.split(';')
        let model_definition_ids = item.model_definition_id.split(';')
        let model_definition_labels = item.model_definition_label.split(';')

        model_definition_ids.pop()
        model_definition_labels.pop() 
        qualifiers.pop() 

        if (model_definition_ids.length === 1) item.is_complex = false

        model_definition_ids = model_definition_ids.join(';')
        model_definition_labels = model_definition_labels.join(';')
        qualifiers = qualifiers.join(';')

        const newData = preDistachRules.box_ranges.map((item, key) => {
            return (
                key === selectedRow
                ? { ...item, qualifier: qualifiers, model_definition_id: model_definition_ids, model_definition_label: model_definition_labels }
                : item
            )
        }
        )

        setPreDistachRules({ ...preDistachRules, box_ranges: newData });
    }

  return (
    <Modal
      width={1400}
      title={constructModalHeader()}
      visible={visible}
      onOk={OnSave}
      onCancel={OnCancelData}
    >
      <Row style={{ paddingBottom: 15 }}>
        <Col span={6} style={{ padding: 10 }}>
          {freightGroups ?
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Freight Group"
              optionFilterProp="children"
              onChange={(value) => selectFreightGroup(value)}
              loading={loading}
              defaultValue={preDistachRules ? preDistachRules.freight_group_id ? parseInt(preDistachRules.freight_group_id) : undefined : undefined}
              disabled={loading}
              onInputKeyDown={(event) => addNewFreightGroup(event)}
            >
              {freightGroups && freightGroups.map(data =>
                <Option key={data.id} value={data.id}>{data.name}</Option>
              )}
            </Select>
            : <Spin />
          }
        </Col>
      </Row>

      <Table dataSource={preDistachRules ? preDistachRules.box_ranges : []} pagination={false} bordered={true}>
        <Column
          title="Fields (Line Level)"
          dataIndex="fieldsTags"
          key="fieldsTags"
          width={350}
          render={(fieldsTags, item) => {
            return (
                <>
                    {fieldsComponent(item)}
                </>
            )
          }}
        />
        <Column
          title="Qualifier"
          dataIndex="qualifier"
          key="qualifier"
          width={200}
          render={(qualifier, item) => {

            return (
              <>
                  < StyledFormItem
                      validateStatus={errors['qualifier'] && 'error'}
                      help={errors['qualifier'] && errors['qualifier'][0]}
                      label="Qualifier"
                  >
                      {qualifierComponent(item, qualifiers)}
                  </StyledFormItem>

              </>
            )
          }
          }
        />
        <Column
          title="Start Box"
          dataIndex="box_range_start"
          key="box_range_start"
          render={(scheduleFrom, item) => {
            return (
              <div>
                <Input type="number" value={item.box_range_start} onChange={(value) => onChangeStartBox(value.target.value, item, 'box_range_start')} />
              </div>
            )
          }}
        />

        <Column
          title="End Box"
          dataIndex="box_range_end"
          key="box_range_end"
          render={(scheduleTo, item) => {
            return (
              <div>
                <Input type="number" value={item.box_range_end} onChange={(value) => onChangeStartBox(value.target.value, item, 'box_range_end')} />
              </div>
            )
          }}
        />

        <Column
          title="Sort Order"
          dataIndex="sort_order"
          key="sort_order"
          render={(sort_order, item) => {
            return (
              <div>
                <Input type="number" value={item.sort_order} onChange={(value) => onChangeStartBox(value.target.value, item, 'sort_order')} />
              </div>
            )
          }}
        />

        {letterRange === "1" &&
          <Column
            title="Letter Range"
            dataIndex="letter_range"
            key="letter_range"
            render={(scheduleTo, item) => {
              return (
                <div>
                  <Input disabled={letterRange === "0" ? true : false} value={item.letter_range} maxLength={3} onChange={(value) => onChangeStartBox(value.target.value.toUpperCase(), item, 'letter_range')} />
                </div>
              )
            }}
          />
        }

        <Column
          title="Action"
          key="action"
          render={(text, item) => (
            <div>
                <Row align="middle" justify="center">
                    <Col span={24} style={{ padding: 5 }}>
                        <Button type="primary" onClick={() => handleAddFieldComponent(item)} >Add Condition</Button>
                    </Col>
                </Row>
                {
                    item.is_complex && (
                        <Row align="middle" justify="center">
                            <Col span={24} style={{ padding: 5 }}>
                                <Button type="default" onClick={() => handleRemoveFieldComponent(item)} >Remove Condition</Button>
                            </Col>
                        </Row>
                    )
                }
                <Row align="middle" justify="center">
                    <Col span={24} style={{ padding: 5 }}>
                        <Button type="danger" onClick={() => onRemove(item)} >Remove Rule</Button>
                    </Col>
                </Row>
            </div>
          )}
        />
      </Table>
      <AddButton onClick={onAddNewRule}>
        <Icon type="plus-circle" theme="filled" />
            ADD NEW RULE
          </AddButton>
    </Modal>
  )
};

export default PreDispatchModal;
