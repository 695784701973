import styled from 'styled-components'
import { keyframes } from 'styled-components'

export const Card = styled.div`
  border: 1px solid #b9b4b4;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 3px;
  min-height: 100px;
`
export const CloseButton = styled.small`
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
`

export const RunContainer = styled.div`
  min-height: 40vh;
`
export const LoaderContainer = styled.div`
  padding: 10px;
`

export const breatheAnimation = keyframes`
  0%{background-position:100% 50%}
  100%{background-position:-33% 50%}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RunRow = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #2c5273;
  opacity: ${props => (props.inactive ? 1 : 0.3)};
  padding: 5px 5px 5px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-size: 400% 100%;
  animation: ${props => (props.isRunning ? breatheAnimation : null)} 3s linear
    infinite;
  background-image: ${props =>
    props.isRunning
      ? `repeating-linear-gradient(
      90deg,
      transparent,
      transparent 0px,
      transparent 0px,
      transparent 0px
    ),
    repeating-linear-gradient(
      112deg,
      transparent,
      transparent 19px,
      #f1e8e8 10px,
      #9e9e9e26 20px
    )`
      : null};
`

export const InnerRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Floating = styled.div`
  position: fixed;
  font-family: 'Rubik', sans-serif;
  right: 20px;
  bottom: 60px;
`

export const Button = styled.button`
background: ${props =>
  props.primary
    ? '#0f6ba0;'
    : props.deep
    ? '#054367;'
    : props.secondary
    ? '#9e9e9e;'
    : '#ce1414;'}
display: ${props => (props.standalone ? 'block;' : 'flex;')}
margin-bottom: ${props => (props.standalone ? '10px;' : '0px;')}
margin-right: 5px;
padding: 2px 9px;
font-family: 'Rubik', sans-serif;
color: #fff;
outline: none;
border-radius: 5px;
&:focus {
  outline: none;
}
`

export const Description = styled.h3`
  color: #fff;
  font-family: 'Rubik', sans-serif;
  word-break: break-all;
`
export const LogText = styled.p`
  font-family: 'Rubik', sans-serif;
  word-break: break-all;
`

export const Title = styled.h1`
  margin-top: 10px;
  color: gray;
  font-family: 'Rubik', sans-serif;
  font-size: ${props => (props.small ? '15px;' : '20px;')}
  margin: ${props => (props.margin ? '10px 20px 5px 20px;' : '0px;')}
  font-weight: ${props => (props.margin ? 'bold;' : 'normal;')}
`
export const Container = styled.div`
  padding: 0px 20px 20px;
`

export const Row = styled.div`
  display: flex;
  padding: 0px 20px;
  visibility: ${props => (props.hidden ? 'hidden;' : 'visible;')} @media
      (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
    margin-bottom: 20px;
  }
`
export const InnerContainer = styled.div`
  width: 50%;
  margin-right: 20px;
  @media (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 100%;
  }
`

export const FloatingButton = styled.button`
  position: fixed;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  background: #0f6ba0;
  bottom: 20px;
  right: 20px;
  color: #fff;
  font-size: 20px;
`
export const ModalContainer = styled.div`
  background: #607d8be0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
`
export const InnerContent = styled.div`
  background: #fff;
  margin: 20px;
  width: 100%;
  max-width: 700px;
  border-radius: 10px;
  height: 80vh;
`

export const InputField = styled.input`
  outline: none;
  &:focus {
    outline: none;
  }
  border: 1px solid #767879a8;
  width: 100%;
  height: 35px;
  font-family: 'Rubik', sans-serif;
  border-radius: 6px;
  padding: 0 10px;
  margin-top: 5px;
`
export const SelectField = styled.select`
  outline: none;
  &:focus {
    outline: none;
  }
  border: 1px solid #767879a8;
  width: 100%;
  height: 35px;
  border-radius: 6px;
  font-family: 'Rubik', sans-serif;
  padding: 0 10px;
  margin-top: 3px;
`
export const Label = styled.p`
  font-family: 'Rubik', sans-serif;
  font-weight: 800;
`
export const ErrorText = styled.small`
  font-weight: 300;
  font-family: 'Rubik', sans-serif;
  color: #cc4a41;
`
export const InputFieldContainer = styled.div`
  padding: 0px 20px 20px 20px;
  .ant-input,
  .ant-select-selection {
    border-color: ${props => (props.error ? '#cc4a41;' : '#e2e8f0;')};
  }
`

export const CheckBoxWrapper = styled.div`
  position: relative;
`
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`
export const Info = styled.p`
  font-family: 'Rubik', sans-serif;
  padding-bottom: 10px;
`
export const Highlight = styled.span`
  font-weight: 700;
  font-family: 'Rubik', sans-serif;
`
export const AddTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  background: #ebe9ec61;
  border-radius: 10px 10px 0px 0px;
`
export const ModalContent = styled.div`
  overflow: scroll;
  height: 70vh;
`
