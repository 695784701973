import React from 'react';
import styled from 'styled-components';
import {Row as AntRow, Col, Skeleton, Dropdown, Menu as AntMenu, Icon} from 'antd'

import {STATUS} from 'types'
import {styles} from "../zensmart-design-system/index";

const typography = styles.typography;

const StyledDiv = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const UserName = styled.p`
  font-size: ${typography.size.s2}px;
  font-weight: ${typography.weight.regular};
  color: #2E384D;
  margin-top: 1px;
  margin-left: 7px;
  margin-bottom: 0;
`;

const UserRole = styled.p`
  font-size: ${typography.size.s1}px;
  color: #BFC5D2;
  line-height: 22px;
  margin-top: 0;
  margin-left: 7px;
`

const Row = styled(AntRow)`
  cursor: pointer;
`

const applyStyle = ProfileInfoWrapper => {
  return (
    ProfileInfoWrapper &&
    StyledDiv.withComponent(({ ...rest }) => (
      <ProfileInfoWrapper {...rest} />
    ))
  );
};

const paragraphProps = {rows: 2}

export function ProfileInfo(props) {
  const {user, logout, imageLink, ProfileInfoWrapper, ...rest} = props

  const isFetchingUserDetails = user.status === STATUS.FETCHING_USER_DETAILS

  const StyledDivWrapper = React.useMemo(
    () => applyStyle(ProfileInfoWrapper),
    [ProfileInfoWrapper]
  )

  let SelectedProfileInfo = StyledDiv;
  if (ProfileInfoWrapper) {
    SelectedProfileInfo = StyledDivWrapper;
  }

  const profileMenu = (
    <AntMenu>
      <AntMenu.Item key="0" onClick={logout}>
        <Icon type="logout" /> Sign Out
      </AntMenu.Item>
    </AntMenu>
  )

  return (
    <SelectedProfileInfo {...rest}>
      <Dropdown
        disabled={!user.authenticated}
        overlay={profileMenu}
        trigger={['click']}
      >
        <Row type="flex" gutter={8}>
          <Col span={18}>
            <Skeleton
              title={false}
              active={isFetchingUserDetails}
              loading={isFetchingUserDetails || !user.authenticated}
              paragraph={paragraphProps}
            >
              <UserName>{user.details && user.details.name}</UserName>
              <UserRole>{user.details && user.details.role}</UserRole>
            </Skeleton>
          </Col>
        </Row>
      </Dropdown>
    </SelectedProfileInfo>
  );
}