import React, {useEffect, useState} from 'react';
import {Modal, notification} from 'antd';
import _ from 'lodash';

import {ZenSmartAPI} from '../../../utils';

const DesignerContext = React.createContext(null);

function withDesignerContext(WrappedComponent) {
  return function() {

    const [types, setTypes] = useState({});
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [stages, setStages] = useState(null);
    const [providers, setProviders] = useState([]);
    const [template, setTemplate] = useState(null);
    const [modal, setModal] = useState(null);
    const [stageData, setStageData] = useState(null);
    const [apiResponse, setApiResponse] = useState({});
    const [settings, setSettings] = useState({})

    const [packTypeFormVisible, setPackTypeFormVisible] = useState(false);
    const [editingPackType, setEditingPackType] = useState({});
    const [packTypeFormEditOnly, setPackTypeFormEditOnly] = useState(false);

    // Load initial data
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      let url = `/api/v1/ui/init-data/dispatch-designer`;

      if (!!params.get('product_id')) {
        url += `?product_id=${params.get('product_id')}`;
      }

      ZenSmartAPI.get(url)
          .then(response => {
            response = response.data.data;
            setTypes(response.types);
            setProviders(response.providers);
            setApiResponse(response);
            setSettings(response.settings)
          });
    }, []);

    useEffect(() => {
      const params = new URLSearchParams(window.location.search);

        if (apiResponse.dispatch_flow) {
            // setSelectedProductById(params.get('product_id'), false);
            updateStages(apiResponse.dispatch_flow);
            if (!_.find(products,
                p => p.id === apiResponse.dispatch_flow.product.id)) {
                setProducts([...products, apiResponse.dispatch_flow.product]);
            }
            setSelectedProduct(apiResponse.dispatch_flow.product);
        }

    }, [apiResponse]);

    const updateStages = (data) => {
      setStages(data);
      setTemplate(data.template);
    };

      const copyTemplateFromProductById = (productId) => {
        const product = products.find(p => p.id === parseInt(productId));
        if (!selectedProduct) {
          return;
        }
        if (!product) {
          return;
        }
        Modal.confirm({
          'title': "Confirmation",
          'content': `Are you sure you want to clone the dispatch flow from ${product.code} to ${selectedProduct.code}? This can only be undone manually!`,
          onOk: () => {
            ZenSmartAPI.post(`/api/v1/products/${selectedProduct.id}/clone-dispatch-designer/${product.id}`)
              .then(response => {
                  notification.success({
                      message: `Dispatch flow for product ${selectedProduct.code} cloned from ${product.code}`,
                  });
                  setSelectedProductById(selectedProduct.id);
              }).catch(response => {
              notification.error({message: `Error copying dispatch flow for product ${selectedProduct.code} from ${product.code}`});
            })
          },
        });
      }

    const setSelectedProductById = (
        productId, fetchDispatchDesigner = true) => {
      const params = new URLSearchParams(window.location.search);
      const product = products.find(p => p.id === parseInt(productId));

      if (fetchDispatchDesigner) {
        ZenSmartAPI.get(`/api/v1/products/${product.id}/dispatch-nodes`)
            .then(response => {
              notification.success({
                message: `Dispatch flow loaded for product ${product.code}`,
              });
              updateStages(response.data);
            });
      }

      params.set('product_id', productId);
      window.history.replaceState({}, '',
          `${window.location.pathname}?${params}`);
      setSelectedProduct(product);
    };

    const clearModal = () => {
      const params = new URLSearchParams(window.location.search);
      params.delete('focus_on');
      window.history.replaceState({}, '',
          `${window.location.pathname}?${params}`);
      setModal(null);
      setStageData({});
    };

    const deleteNode = (dispatchNodeId) => {
      ZenSmartAPI.delete(
          `/api/v1/products/${selectedProduct.id}/dispatch-nodes/${dispatchNodeId}`)
          .then(response => {
            notification.success({
              message: `Dispatch stages removed.`,
            });
            updateStages(response.data);
          });
    };

    const addOrUpdatePackType = (pack) => {
      if (types.packs.find(p => p.id === pack.id)) {
        types.packs = types.packs.map(p => {
          if (p.id === pack.id) {
            p = pack;
          }
          return p;
        });
        setTypes(types);
      } else {
        types.packs.push(pack);
        setTypes(types);
      }
    };

    const transformQualifierValue = (stage) => {

      if (stage.siblings_count === 0 && stage.parent.has_required_qualifier ===
          false) {
        return 'All values';
      }

      if (stage.text === 'other') {
        return 'All other values';
      }

      let [operand, values] = stage.text.split(':');

      const operandValue = types.qualifiers.find(
          qualifier => qualifier.key === operand);

      return `${operandValue.label} ${values.split(',').join(' - ')}`;
    };

    const getUrlParams = () => {
      return new URLSearchParams(window.location.search);
    };

    const openPackTypeDrawer = (packType = null) => {
      if (packType) {
        setEditingPackType((packType));
      }
      setPackTypeFormVisible(true);
    };

    const openPackTypeDrawerInEditOnly = (packType) => {
        setEditingPackType((packType));
        setPackTypeFormEditOnly(true);
        setPackTypeFormVisible(true);
    };

    const closePackTypeDrawer = () => {
      setPackTypeFormVisible(false);
      setPackTypeFormEditOnly(false);
      setEditingPackType({});
    };

    return (
        <DesignerContext.Provider value={{
          types,
          products,
          setProducts,
          selectedProduct,
          copyTemplateFromProductById,
          setSelectedProductById,
          template,
          stages,
          updateStages,
          providers,
          settings,
          packTypeFormVisible,
          setPackTypeFormVisible,
          editingPackType,
          setEditingPackType,

          openPackTypeDrawer,
          closePackTypeDrawer,
          addOrUpdatePackType,
          packTypeFormEditOnly,
          setPackTypeFormEditOnly,
          openPackTypeDrawerInEditOnly,

          modal,
          setModal,
          stageData,
          setStageData,
          deleteNode,
          clearModal,
          getUrlParams,

          transformQualifierValue,
        }}>
          <WrappedComponent/>
        </DesignerContext.Provider>
    );
  };
}

export {
  DesignerContext,
  withDesignerContext,
};