import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Collapse,
  Button,
  Form,
  notification,
  Select,
  Input,
  DatePicker,
  Checkbox,
  AutoComplete,
  Modal,
} from "antd";
import { alphabeticalData } from "utils/sortData";
import { ZenSmartAPI } from "utils";
import { Label } from "components/zensmart-design-system/components/Label/Label.js";
import { useHistory } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import CreateOrderComponent from "./SpecialForms.js/CreateOrderForm/CreateOrderComponent.js";
import PermissionModal from '../../pages/People/Operators/PermissionModal';
import UserRoleManage from "pages/People/Operators/Components/UserRoleManage.js";
import CreateComponentModal from "./SpecialForms.js/ComponentForm/CreateComponent.js";
import ViewModelAudit from "./SpecialForms.js/ModelAuditform/ViewModelAudit.js";
import Image from "components/zensmart-design-system/components/Image/Image";
import ReorderTransaction from "./SpecialForms.js/ReorderTransactionForm/ReorderTransaction.js";
import * as _ from "lodash";

const { TextArea } = Input;

const InputHeader = styled.h1`
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  margin: 10px 0px;
  font-weight: bold;
`;
const InputValue = styled.h1`
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  margin: 10px 0px;
`;
const FlexImageWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;

  div {
    padding: 0 5px;
    text-align: center;
    margin-bottom: 10px;
  }
`;
const PanelHeader = styled.h1`
  fontsize: 17px;
  fontweight: bold;
`;
const AttachmentImage = styled.img`
  width: 18px;
  height: 15px;
  verticalalign: baseline;
  margin: auto;
`;
const DataViewActionForm = (props) => {
  const { Option } = Select;
  const { Panel } = Collapse;
  const history = useHistory();
  const {
    actionValue,
    showModalForm,
    setShowModalForm,
    actionApi,
    refetchResource,
    detailsForm,
    modifyPayload,
    successCallBack,
  } = props;
  const [checkUploadFileRequired, setCheckUploadFileRequired] = useState(false);
  function EditForm(props) {
    const { form } = props;
    const {
      getFieldDecorator,
      getFieldsValue,
      validateFields,
      setFieldsValue,
      getFieldValue,
      setFields,
    } = form;
    const [uploadFile, setUploadFile] = useState([]);
    const [fieldValue, setField] = useState(null);
    const hiddenFileInput = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [addNewFeatureOptions, setAddNewFeatureOptions] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [items, setItems] = useState({});
    const setErrors = (field, resource) => {
      for (const [key, value] of Object.entries(field)) {
        let fieldName = `${Object.keys(resource)[0]}[0].${key}`;
        setFields({
          [fieldName]: {
            value: getFieldValue(fieldName),
            errors: [new Error(value)],
          },
        });
      }
    };

    const handleUploadFile = (event) => {
      hiddenFileInput.current.click();
    };

    useEffect(() => {
      if (actionValue) {
        actionValue.page_definitions.sections.map((data, key) => {
          {
            const checkRequired = data.section_data.some((row, key) => {
              return row.required === true && row.data_type === "FILE";
            });
            if (checkRequired) {
              setCheckUploadFileRequired(true);
            }
          }
        });
      }
    }, [actionValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const uploadAFile = async (fileable_id, fileable_type) => {
      const returnIds = [];
      const formData = new FormData();
      formData.append("fileable_id", fileable_id);
      formData.append("fileable_type", fileable_type);
      for (let i = 0; i < uploadFile.length; i++) {
        formData.append("files[]", uploadFile[i].file);
      }
      try {
        let response = await ZenSmartAPI.post("/api/v1/files/upload", formData);
        if (response && response.data.data) {
          return response.data.data.map((data) => data.id);
        }
      } catch (error) {
        setLoading(false);
        notification.error({ message: "Error uploading file!" });
      }
    };

    const filesToUpload = (event, path, required, multipleUpload = false) => {
      const files = event.target.files;
      const returnArray = [];
      for (let i = 0; i < files.length; i++) {
        if (actionValue.message === "Upload New files for the Order") {
          if (files[i].type.includes("image")) {
          } else if (
            typeof files[i] == "undefined" ||
            files[i].type != "application/pdf"
          ) {
            notification.error({ message: "File must be pdf or image!" });
            return;
          }
        }
        returnArray.push({
          path: path,
          file: files[i],
          required: required,
          file_type: files[i].type,
        });
      }

      setField(null);
      setField(getFieldsValue);
      let checkDuplicatePdf = 0;

      uploadFile.concat(returnArray).map((data) => {
        if (data.file_type === "application/pdf") {
          checkDuplicatePdf++;
        }
      });

      if (multipleUpload && checkDuplicatePdf > 1 && returnArray.length > 1) {
        notification.error({
          message: "Pdf file type must be uploaded only once!",
        });
        return;
      } else if (
        multipleUpload &&
        checkDuplicatePdf >= 1 &&
        uploadFile
          .concat(returnArray)
          .some((data) => data.file_type.includes("image"))
      ) {
        notification.error({
          message: "Pdf file and image file can't be upload at the same time",
        });
        return;
      } else if (
        multipleUpload &&
        checkDuplicatePdf <= 2 &&
        uploadFile.length === 1 &&
        returnArray.length === 1
      ) {
        setUploadFile(returnArray);
      } else if (multipleUpload) {
        setUploadFile(uploadFile.concat(returnArray));
      } else {
        setUploadFile(returnArray);
      }
    };

    const deleteFileUpload = (index) => {
      setField(null);
      setField(getFieldsValue);
      setUploadFile(uploadFile.filter((data, key) => key !== index));
    };

    const onChangeUpdate = (field, selector, endChild = null) => {
      setFieldsValue({ [selector.concat(".").concat(field)]: [] });
      if (endChild) {
        setFieldsValue({ [selector.concat(".").concat(endChild)]: [] });
      }
    };
    const handleChange = (value, row = null, selector = null) => {
      if (row != null && row.related !== undefined && row.related === true) {
        ZenSmartAPI.get(row.related_endpoint + "/" + value)
          .then((res) => {
            if (res.status == 200) {
              setSelectedItems([]);
              if (Array.isArray(res.data) === true) {
                let array = [];
                for (let index = 0; index < res.data.length; index++) {
                  var obj = {};
                  obj = res.data[index];
                  array.push(obj);
                }
                let key = row.related_field;
                if (row.related_initial) {
                  setItems({
                    [key]: array,
                  });
                } else {
                  setItems({
                    ...items,
                    [key]: array,
                  });
                }
              }
              onChangeUpdate(
                row.related_field,
                selector,
                row.related_end_child
              );
            }
          })
          .catch((res) => {});
      } else {
        setSelectedItems(value);
      }
    };

    const handleSaveChanges = async (evt) => {
      evt.preventDefault();
      validateFields(async (err, values) => {
        if (!err) {
          setLoading(true);
          const payload = modifyPayload
            ? modifyPayload(getFieldsValue())
            : getFieldsValue();

          if (uploadFile.length > 0) {
            const upload = await uploadAFile(
              detailsForm.id,
              detailsForm.resource_name.toLowerCase()
            );
            if (upload) {
              payload[`${uploadFile[0].path.parent_property}`][0][
                `${uploadFile[0].path.child_property}`
              ] = upload.length === 1 ? upload[0] : upload;
            } else {
              setLoading(false);
              return;
            }
          } else if (checkUploadFileRequired && uploadFile.length === 0) {
            notification.error({
              message: "Please upload A file!",
            });
            setLoading(false);
            return;
          }

          ZenSmartAPI.post(actionApi, payload)
            .then((res) => {
              setLoading(false);
              if(res.data && res.data.message){
                notification.success({
                  message: res.data.message,
                });
              }else{
                notification.success({
                  message: "Succesfully saved changes",
                });
              }
              setShowModalForm(false);
              setUploadFile([]);
              setCheckUploadFileRequired(false);
              if (successCallBack) {
                successCallBack(res.data);
              }
              if (res.data.data && res.data.data.url) {
                window.location.replace(`/app/${res.data.data.url}`);
              } else {
                if (refetchResource) {
                  refetchResource();
                }
              }
            })
            .catch((res) => {
              setLoading(false);
              if (_.get(res, "response.status") === 422) {
                setErrors(_.get(res, "response.data.errors"), values);
              } else if (_.get(res, "response.data.message")) {
                notification.error({
                  message: _.get(res, "response.data.message"),
                });
              } else {
                notification.error({
                  message: "An error has occurred",
                });
              }
            });
        }
      });
    };

    const getSelectedValue = (value) => {
      const getSelected = value
        .filter((item) => {
          return item.selected === true;
        })
        .map((item) => item.value);

      if (getSelected !== undefined) {
        if (getSelected.length === 1) return getSelected[0];
        else return getSelected;
      }
    };

    const getFieldDecoratorProps = (row, resource_name, field) => {
      if (
        row.data_type === "SELECT" ||
        row.data_type === "MULTIPLE_TAG_SELECT"
      ) {
        return {
          initialValue: getSelectedValue(row.options),
        };
      }
      if (row.data_type === "SELECT_RELATED") {
        return {
          initialValue: [],
          rules:
            row.required === true
              ? [
                  {
                    required: true,
                    message: `Please select this field!`,
                  },
                ]
              : undefined,
        };
      }
      if (row.data_type === "FILE") {
        return {
          initialValue: row.value ? row.value : false,
        };
      }

      if (row.data_type === "TAG_SELECT") {
        return {
          initialValue: [],
          rules:
            row.required === true
              ? [
                  {
                    required: true,
                    message: `Please select this field!`,
                  },
                ]
              : undefined,
        };
      }

      if (row.data_type === "ADD_FEATURE_SELECT") {
        return {
          initialValue: [],
          rules:
            row.required === true
              ? [
                  {
                    required: true,
                    message: `Please select this field!`,
                  },
                ]
              : undefined,
        };
      }

      if (row.data_type === "BOOLEAN") {
        return {
          initialValue: row.value ? row.value : false,
          valuePropName: "checked",
          rules:
            row.required === true
              ? [{ required: true, message: `Please input this field!` }]
              : undefined,
        };
      } else {
        return {
          initialValue:
            fieldValue &&
            resource_name &&
            field &&
            fieldValue[`${resource_name}`]
              ? fieldValue[`${resource_name}`][0][`${field}`]
              : row.value
              ? row.value
              : null,
          rules:
            row.required === true
              ? [
                  {
                    required: true,
                    message: `Please input this field!`,
                    whitespace: true,
                  },
                ]
              : undefined,
        };
      }
    };

    const addNewDataSelect = (
      evt,
      options,
      endpoint,
      propertyName,
      index,
      resourceName,
      fieldValueProperty,
      row
    ) => {
      const findOptions = options.some(
        (data) => data.text.toLowerCase() === evt.target.value.toLowerCase()
      );
      if (
        evt.keyCode === 13 &&
        !findOptions &&
        evt.target.value !== "" &&
        evt.target.value !== null
      ) {
        const inputValue = evt.target.value;
        Modal.confirm({
          content: `Are you sure you want to create this (${inputValue})?`,
          onOk: () => {
            ZenSmartAPI.post(endpoint, { [propertyName]: inputValue })
              .then((res) => {
                notification.success({ message: "Add new data successful!" });
                const resource = getFieldValue(resourceName);
                resource[fieldValueProperty] = res.data.data.id;
                setFieldsValue({
                  [resourceName]: resource,
                });
                const optionData = options;
                optionData.push({
                  text:
                    res.data && res.data.data.name
                      ? res.data.data.name.charAt(0).toUpperCase() +
                        res.data.data.name.slice(1)
                      : null,
                  value: res.data.data.id,
                  selected: true,
                });
                setAddNewFeatureOptions({
                  ...addNewFeatureOptions,
                  [index]: alphabeticalData(optionData, "text"),
                });
                if (row.related) {
                  handleChange(res.data.data.id, row, resourceName);
                }
              })
              .catch((error) => {
                const { response } = error;
                setLoading(false);

                if (response.status === 422) {
                  Object.values(response.data.errors).forEach((error) => {
                    notification.error({
                      message: error.toString(),
                    });
                  });
                }
              });
          },
        });
      }
    };

    const getDataViewForm = (row, data, index) => {
      if (row.label === "Due Date:") {
        return (
          <InputValue>
            <Label status={"mountain_meadow"} style={{ padding: "1px 1px" }}>
              {typeof row.value === "string"
                ? row.value.toUpperCase()
                : row.value.due_date_text
                ? row.value.due_date_text
                : "_"}
            </Label>
            {row.value.formatted_due_date
              ? row.value.formatted_due_date.toUpperCase()
              : null}
          </InputValue>
        );
      } else if (row.label === "thumbnails") {
        return (
          <div>
            <FlexImageWrapper>
              {row.value.text.front ? (
                <Image
                  src={row.value.text.front}
                  alt=""
                  width={100}
                  height={250}
                  objectFit="fill"
                />
              ) : null}

              {row.value.text.back ? (
                <Image
                  src={row.value.text.back}
                  alt=""
                  width={100}
                  height={250}
                  objectFit="fill"
                />
              ) : null}

              {row.value.cover && row.value.cover.front ? (
                <Image
                  src={row.value.cover.front}
                  alt=""
                  width={100}
                  height={250}
                  objectFit="fill"
                />
              ) : null}

              {row.value.back ? (
                <Image
                  src={row.value.back}
                  alt=""
                  width={100}
                  height={250}
                  objectFit="fill"
                />
              ) : null}
            </FlexImageWrapper>
          </div>
        );
      } else if (row.disable || row.hasOwnProperty("disable") === false) {
        return (
          <InputValue>
            {typeof row.value === "string"
              ? row.value.toUpperCase()
              : row.value
              ? row.value
              : "_"}
          </InputValue>
        );
      } else {
        return (
          <Form.Item>
            {getFieldDecorator(
              `${data.resource_name}[0].${row.field}`,
              getFieldDecoratorProps(row, data.resource_name, row.field)
            )(
              row.data_type === "SELECT" ? (
                <Select
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                  dropdownMatchSelectWidth={false}
                  placeholder={row.label}
                >
                  {alphabeticalData(row.options, "text").map((data, key) => (
                    <Option value={data.value}>{data.text}</Option>
                  ))}
                </Select>
              ) : row.data_type === "MULTIPLE_TAG_SELECT" ? (
                <Select
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={row.label}
                  mode="multiple"
                >
                  {alphabeticalData(row.options, "text").map((data, key) => (
                    <Option value={data.value}>{data.text}</Option>
                  ))}
                </Select>
              ) : row.data_type === "SELECT_RELATED" ? (
                <Select
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleChange(e, row, `${data.resource_name}[0]`)
                  }
                  placeholder={row.label}
                >
                  {alphabeticalData(
                    row.related === true && items[row.field]
                      ? items[row.field]
                      : row.options,
                    "text"
                  ).map((data, key) => (
                    <Option value={data.value}>{data.text}</Option>
                  ))}
                </Select>
              ) : row.data_type === "TAG_SELECT" ? (
                <Select
                  mode="tags"
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder={row.label}
                  onChange={(e) =>
                    handleChange(e, "", `${data.resource_name}[0]`)
                  }
                >
                  {alphabeticalData(
                    items[row.field] ? items[row.field] : [],
                    "text"
                  ).map((data, key) => (
                    <Option
                      style={{
                        textTransform: "capitalize",
                      }}
                      value={data}
                    >
                      {data}
                    </Option>
                  ))}
                </Select>
              ) : row.data_type === "ADD_FEATURE_SELECT" ? (
                <Select
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                  onInputKeyDown={async (event) => {
                    addNewDataSelect(
                      event,
                      row.related === true && items[row.field]
                        ? items[row.field]
                        : row.options,
                      row.create_endpoint,
                      row.create_field,
                      index,
                      `${data.resource_name}[0]`,
                      row.field,
                      row
                    );
                  }}
                  placeholder={row.label}
                  onChange={(e) =>
                    handleChange(e, row, `${data.resource_name}[0]`)
                  }
                >
                  {alphabeticalData(
                    row.related === true && items[row.field]
                      ? items[row.field]
                      : addNewFeatureOptions[index]
                      ? addNewFeatureOptions[index]
                      : row.options,
                    "text"
                  ).map((data, key) => (
                    <Option
                      style={{ textTransform: "capitalize" }}
                      value={data.value}
                    >
                      {data.text}
                    </Option>
                  ))}
                </Select>
              ) : row.data_type === "AUTOCOMPLETE" ? (
                <AutoComplete
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={row.label}
                >
                  {alphabeticalData(row.options, "text").map((data, key) => (
                    <Option value={data.value ? data.value : "none"}>
                      {data.text}
                    </Option>
                  ))}
                </AutoComplete>
              ) : row.data_type === "DATE" ? (
                <DatePicker style={{ width: "100%" }} />
              ) : row.data_type === "BOOLEAN" ? (
                <Checkbox />
              ) : row.data_type === "INTEGER" || row.data_type === "DECIMAL" ? (
                <Input type="number" style={{ width: "100%" }} />
              ) : row.data_type === "FILE" ? (
                <>
                  <Button
                    onClick={handleUploadFile}
                    disabled={uploadFile.file ? true : undefined}
                  >
                    <UploadOutlined style={{ verticalAlign: "baseline" }} />{" "}
                    Upload a file
                  </Button>
                  <input
                    type="file"
                    multiple={row.multiple_upload}
                    ref={hiddenFileInput}
                    onChange={(event) =>
                      filesToUpload(
                        event,
                        {
                          parent_property: data.resource_name,
                          child_property: row.field,
                        },
                        row.required,
                        row.multiple_upload
                      )
                    }
                    style={{ display: "none" }}
                  />
                  {uploadFile.length > 0
                    ? uploadFile.map((data, index) => {
                        return (
                          <Row
                            type="flex"
                            align="middle"
                            style={{
                              borderBottom: ".5px solid #D9D9D9",
                              padding: 5,
                            }}
                          >
                            <Col span={20}>
                              <p style={{ lineHeight: "normal" }}>
                                {data.file.name}
                              </p>
                            </Col>
                            <Col span={2} offset={2}>
                              <AttachmentImage
                                onClick={() => deleteFileUpload(index)}
                                style={{ cursor: "pointer" }}
                                src="https://image.flaticon.com/icons/png/512/61/61848.png"
                              />
                            </Col>
                          </Row>
                        );
                      })
                    : null}
                </>
              ) : (row.field === "comment" || row.field === 'cancelled_reasons_comment' ) ? (
                <TextArea
                  rows={8}
                  placeholder={
                    row.required === true
                      ? "Please Input This Field"
                      : "*Optional"
                  }
                />
              ) : row.data_type === "TEXTAREA" ? (
                <TextArea 
                  rows={8}
                  placeholder={
                    row.required === true
                      ? "Please Input This Field"
                      : "*Optional"
                  }
                />
              ) : (
                <Input
                  placeholder={
                    row.required === true
                      ? "Please Input This Field"
                      : "*Optional"
                  }
                />
              )
            )}
          </Form.Item>
        );
      }
    };

    return (
      <>
        <Form onSubmit={handleSaveChanges}>
          <Form.Item>
            <Row align="center">
              <Col span={12}>
                <PanelHeader style={{ fontSize: 17, fontWeight: "bold" }}>
                  {actionValue.page_definitions.page_header.header_title}
                </PanelHeader>
              </Col>
              <Col span={12} gutter={15}>
                <p style={{ textAlign: "right" }}>
                  <Button
                    style={{ marginRight: 10 }}
                    type="primary"
                    htmlType="submit"
                    loading={loading ? true : undefined}
                  >
                    { actionValue.confirm_btn_text || 'Save' }
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => {
                      setUploadFile([]);
                      setCheckUploadFileRequired(false);
                      setShowModalForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                </p>
              </Col>
            </Row>
          </Form.Item>
        </Form>
        {actionValue &&
          actionValue.message &&
          typeof actionValue.message === "object" && (
            <div style={{ marginTop: "-20px" }}>
              <h1 style={{ fontWeight: "bold" }}>Message:</h1>
              {actionValue &&
                actionValue.message.map((data) => {
                  return <p>{data}</p>;
                })}
            </div>
          )}
        {actionValue
          ? actionValue.page_definitions.sections.map((data, key) => (
              <Collapse style={{ marginTop: 20 }} defaultActiveKey={["1"]}>
                <Panel
                  header={
                    typeof data.section_header === "string"
                      ? data.section_header.toUpperCase()
                      : data.section_header
                  }
                  key="1"
                >
                  <Row gutter={16}>
                    {data.section_data.map((row, key) => (
                      <Col span={12}>
                        <Col span={12}>
                          <InputHeader>{row.label.toUpperCase()}</InputHeader>
                        </Col>
                        <Col span={12}>{getDataViewForm(row, data, key)}</Col>
                      </Col>
                    ))}
                  </Row>
                </Panel>
              </Collapse>
            ))
          : null}
      </>
    );
  }
  const ActionForm = Form.create({ name: "ActionForm" })(EditForm);
  return (
    <div>
      {actionValue.special_form &&
      actionValue.special_form === "create_order" ? (
        <CreateOrderComponent
          showModalForm={showModalForm}
          setShowModalForm={setShowModalForm}
          actionValue={actionValue}
          actionApi={actionApi}
          successCallBack={successCallBack}
        />
      ) : actionValue.special_form && actionValue.special_form === "manage_permissions" ? (
        <PermissionModal
          permissions={actionValue.all}
          userId={actionValue.user.id}
          visible={showModalForm}
          onClose={setShowModalForm}
        />
      ) : actionValue.special_form && actionValue.special_form === "create_component" ? (
        <CreateComponentModal
          visible={showModalForm}
          onClose={setShowModalForm}
          orderId={actionValue.order_id}
          componentTypes={actionValue.component_types}
          componentCodes={actionValue.component_codes}
        />
      ) : actionValue.special_form && actionValue.special_form === "view_model_audit" ? (
        <ViewModelAudit
          visible={showModalForm}
          onClose={setShowModalForm}
          taggableId={actionValue.taggable_id}
          taggableType={actionValue.taggable_type}
          modelAudit={actionValue.model_audit}
        />
      ) : (
        actionValue.special_form && actionValue.special_form === "manage_roles" ? (
          <UserRoleManage
            visible={showModalForm}
            onClose={() => setShowModalForm(false)}
            user={actionValue.user}
            userRoles={actionValue.user_roles}     
            permissions={actionValue.permissions}
            roles={actionValue.roles}
          />
        ) : actionValue.special_form && actionValue.special_form === "reorder_transaction" ? (
            <ReorderTransaction
                visible={showModalForm}
                onClose={setShowModalForm}
                transaction={actionValue.transaction}
                customer={actionValue.customer}
                vendor={actionValue.vendor}
                orders={actionValue.orders}
                shippingAddress={actionValue.shipping_address}
                canEdit={actionValue.can_edit}
            />
        ) : (
          <ActionForm />
        )
      )
    }
  </div>
  );
};

export default DataViewActionForm;
