import React, {useCallback, useEffect, useState} from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {Button, Input, notification, Modal} from 'antd';
import { useSelector } from 'react-redux'

import { FIELD } from 'types';
import { color } from 'components/zensmart-design-system/shared/styles.js';
import {
  Label,
  LabelStatus,
} from 'components/zensmart-design-system/components/Label/Label';
import XMLViewer from 'react-xml-viewer';
import DetailFieldModal from '../components/sections/DetailFieldModal';
import DetailFieldBadge from '../components/sections/DetailFieldTag';
import DetailFieldTable from '../components/sections/DetailFieldTable';
import DetailFieldView from '../components/sections/DetailFieldView';

import * as _ from 'lodash';

const { TextArea } = Input;
const { confirm } = Modal;

const Anchor = styled.a`
  text-decoration: underline !important;
`

const Image = styled.img`
  min-width: 228px;
`

const FieldText = styled.div`
  color: ${color.body};
  font-size: 12px;
`
const LinkText = styled.div`
  color: ${color.body};
  font-size: 12px;
  text-decoration-line : underline;
`
const labelStylings = css`
  font-size: 11px;
  min-width: auto;
  padding: 2px 4px;
`
function useDataValue() {
  const history = useHistory();
  const [showPrint, setShowPrint] = useState(false);
  const [printContent, setShowPrintContent] = useState(null);
  const userData = useSelector(state => state.user)

  const pushURL = (e, url) => {
    e.preventDefault();
    e.ctrlKey || e.metaKey ? window.open(url) : history.push(url);
  }

  const htmlParse = (value) => {
    return <div dangerouslySetInnerHTML={{ __html: value }} />
  }

  const setPrintLabelBtn = () => {
    let enableBtn = true;

    if(userData.details){
        var permissions = userData.details.permissions;

        if(permissions && !permissions.includes('reprint-shipping-labels')){
            enableBtn = false;
        }else if(permissions && permissions.includes('reprint-shipping-labels')){
            enableBtn = true;
        }else{
            enableBtn = false;
        }
    }
    
    return enableBtn;
  };

  const handlePrintLabelButtonClick = (e, field) => {
    e.persist();
    e.stopPropagation();

    const value = field.html_value;
    if (value.use_osi) {
      setTimeout(() => {
        window.location.assign(value.osi_path);
        notification.success({ message: "Sent to print successfully!" })
      }, 1000);
    }

    if (value.type === 'HTML') {
      setShowPrintContent({
        content: value.content,
        type: 'html'
      })
      setShowPrint(true);
      setTimeout(() => {
        setShowPrint(false);
      }, 1000); // Close after 1 second, you can adjust this as needed
    }
  };

  const onPrintLabelClickHandler = (e, field) => {
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    if (field.should_confirm) {
      confirm({
        title: field.confirmation_message || 'Are you sure you want to execute this function?',
        onOk() {
          handlePrintLabelButtonClick(e, field);
        },
        onCancel() {
          // Do nothing on cancel
        },
      });
    } else {
      handlePrintLabelButtonClick(e, field);
    }
  };

  const getDataValue = useCallback(field => {
    if (!field) {
      return;
    }
    const url = field.url ? field.url.replace("api/v1", "/app") : null;
    switch (field.data_type) {
      case FIELD.BOOLEAN: {
        const status = field.value + '' === 'true'
                       ? LabelStatus.general.success
                       : LabelStatus.general.warning
        const text = field.value + '' === 'true' ? 'Yes' : 'No'
        return (
            <FieldText>
              <Label stylings={labelStylings} status={status}>{text}</Label>
            </FieldText>
        )
      }

      case FIELD.DATE: {
        const text = field.value ? moment(field.value).format('DD MMMM YYYY HH:mm') : '-'
        return field.url ? <LinkText><a href={url} onClick={e => pushURL(e, url)}>{text}</a></LinkText> : <FieldText>{text}</FieldText>
      }

      case FIELD.DATETIME: {
        const text = field.value ? moment(field.value).format('DD MMMM YYYY HH:mm:ss') : '-'
        return field.url ? <LinkText><a href={url} onClick={e => pushURL(e, url)}>{text}</a></LinkText> : <FieldText>{text}</FieldText>
      }

      case FIELD.SELECT:
      case FIELD.ADD_FEATURE_SELECT: {
        const defaultSelected = field.options.find(option => (
            option.text === field.value || option.value + '' === field.value + ''
        ))
        const text = defaultSelected ? defaultSelected.text + '' : '-'
        return field.url ? <LinkText><a href={url} onClick={e => pushURL(e, url)}>{text}</a></LinkText> : <FieldText>{text}</FieldText>

      }

      case FIELD.TAG_SELECT: {
        return htmlParse(field.html_value);
      }

      case FIELD.DATETIME: {
        const text = field.value ? moment(field.value).format('DD MMMM YYYY HH:mm:ss') : '-'
        return field.url ? <LinkText><a href={url} onClick={e => pushURL(e, url)}>{text}</a></LinkText> : <FieldText>{text}</FieldText>
      }

      case FIELD.IMAGE: {
        return field.url ? <a href={url} onClick={e => pushURL(e, url)} ><Image src={field.value} alt="File ticket thumbnail" /></a> : <Image src={field.value} alt="File ticket thumbnail" />
      }

      case FIELD.LINK: {
        return (
            <FieldText>
              <Anchor
                  href={field.value}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={evt => {
                    evt.stopPropagation()
                  }}
              >
                View {field.label}
              </Anchor>
            </FieldText>
        )
      }

      default: {


        if (field.render_type === 'TAG') {
          return (<DetailFieldBadge
              value={field.html_value}
          />)
        }

        if (field.render_type === 'XML') {
          return (
              <DetailFieldModal
                  title={field.label}
              >
                <XMLViewer overflowBreak={true} invalidXml={field.html_value}
                           xml={field.html_value} />
              </DetailFieldModal>
          )
        }

        if (field.render_type === 'HTML') {
          return (
              <DetailFieldModal
                  title={field.label}
              >
                <div dangerouslySetInnerHTML={{ __html: field.html_value }} />
              </DetailFieldModal>
          )
        }

        if (field.render_type === 'PRINT_BUTTON') {
          if(field.html_value === null) {
            return null;
          }

          return (
              <>
                <Button type="default" 
                        style={setPrintLabelBtn() === false ? {display: 'none'} : {} }
                        onClick={(e) => onPrintLabelClickHandler(e, field)}>
                  {_.get(field, 'label', 'Print')}</Button>
              </>
          )
        }

        if (field.render_type === 'TABLE') {
          return (
              <DetailFieldModal title={field.label}>
                <DetailFieldTable section={ field.html_value }/>
              </DetailFieldModal>
          )
        }

        if (field.render_type === 'DATAVIEW') {
          return (
              <DetailFieldModal title={field.label}>
                <DetailFieldView api_url={ field.html_value }/>
              </DetailFieldModal>
          )
        }

        if (/<\/?[a-z][\s\S]*>/i.test(field.html_value) && field.field.includes('extras-')) {
          return (
              <DetailFieldModal
                  title={field.label}
              >
                <div dangerouslySetInnerHTML={{ __html: field.html_value }} />
              </DetailFieldModal>
          )
        }

        // If Url
        if (field.url) {
          return (<LinkText> <a href={url} onClick={e => pushURL(e, url)} >{field.value === null ? '-' : field.value}</a> </LinkText>)
        }

        if (field.html_value) {
          return htmlParse(( typeof field.html_value === 'string' && field.html_value.includes('\\n') === true) ? field.html_value.replaceAll('\\n','<br />') :  field.html_value);
        }

        return field.value;
      }
    }
  }, [])

  return {
    getDataValue,
    showPrint,
    printContent,
  }
}

export default useDataValue
