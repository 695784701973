import axios from 'axios';

const endpointURL = `${process.env.REACT_APP_ANALYTICS_SERVER}/dashboards`;

export const CreateDashboard = async (postData) => {
    let res = await axios.post(endpointURL, postData)
    let { data } = res;
    return data;
}

export const UpdateDashboard = async (patchData) => {
    let res = await axios.patch(endpointURL, patchData)
    let { data } = res;
    return data;
}

export const DeleteDashboard = async ($id) => {
    let res = await axios.delete(endpointURL+"/"+$id);
    let { data } = res;
    return data;
}