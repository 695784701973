import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { alphabeticalData } from 'utils/sortData';
import { Modal, Form, Radio, Button, notification, AutoComplete, Input, Row, Col, Select, InputNumber, Checkbox } from 'antd'
import { PlusCircleFilled } from '@ant-design/icons';
import * as _ from "lodash";

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 4px;
  width: 100%;
`
const FormInputRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Field = styled(Form.Item)`
  margin-bottom: 16px;
  display: inline-block;
  width: 100%;
  margin: 0;
`

const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    margin-left: 8px;
  }
`
const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0243EB;
  font-size: 12px;
  border: 0;
  margin-top: 10px;
  box-shadow: none;
  padding-bottom : 20px;
`
const PlusCircleFilledIcon = styled(PlusCircleFilled)`
  font-size: 16px;
`

const processTextRules = [
  {
    required: true,
    message: 'Cannot be blank'
  },
  {
    max: 23,
    message: 'Must be less than 23 characters'
  }
]

function EditForm(props) {
  const {
    form,
    process,
    onSave,
    processID,
    hideModal,
    currentProductBlockTypes,
    setCurrentProductBlockTypes,
    blockTypesOptions,
    blockDefinition,
    oneBlockDefinition,
    setBlockDefinition,
    visible,
    primaryGroupCode
  } = props

  const {
    getFieldDecorator,
    isFieldTouched,
    getFieldError,
    getFieldsValue,
    setFieldsValue,
    validateFields,
    getFieldValue,
  } = form

  const handleEditBlockDefinition = (value, ifProccessId, previousTextValue = null) => {
    if (ifProccessId) {
      const editBlockDefinition = blockDefinition.map(data => {
        if (data.blockType === previousTextValue) {
          data.metadata.photoshop_actions = value.photoshop_actions
          data.metadata.plex = value.plex
          data.metadata.weight = value.weight
          data.metadata.requires_work_order = value.requires_work_order
          data.metadata.no_print_required = value.no_print_required
          data.metadata.submission_per_block = value.submission_per_block
          data.metadata.page_object = value.page_object
          data.metadata.optional = value.optional
          data.metadata.page_object_metadata = value.page_object_metadata
          data.metadata.value = value.value
          data.blockType = value.text
          data.metadata.media = value.media
          data.metadata.ink = value.ink
          data.metadata.metaMode = value.metaMode
          data.metadata.pallet = value.pallet
          data.metadata.xOffset = value.xOffset
          data.metadata.yOffset = value.yOffset
          return data
        }
        else {
          return data
        }
      })
      setBlockDefinition(editBlockDefinition)
    }
    else {
      const data = { metadata: {} }
      data.metadata.photoshop_actions = value.photoshop_actions
      data.metadata.plex = value.plex
      data.metadata.weight = value.weight
      data.metadata.value = value.value
      data.metadata.requires_work_order = value.requires_work_order
      data.metadata.no_print_required = value.no_print_required
      data.metadata.submission_per_block = value.submission_per_block
      data.metadata.page_object = value.page_object
      data.metadata.page_object_metadata = value.page_object_metadata
      data.blockType = value.text
      data.metadata.media = value.media
      data.metadata.ink = value.ink
      data.metadata.metaMode = value.metaMode
      data.metadata.pallet = value.pallet
      data.metadata.xOffset = value.xOffset
      data.metadata.yOffset = value.yOffset
      setBlockDefinition([...blockDefinition, data])
    }
    return true
  }

  const [photoshopActions, setPhotoShopActions] = useState([])
  const orientationInitialValue = ["none", "landscape", "portrait"]
  const [orientationDropdown, setOrientationDropdown] = useState(orientationInitialValue)
  useEffect(() => {
    if (oneBlockDefinition) {
      setPhotoShopActions(oneBlockDefinition.metadata.photoshop_actions ? oneBlockDefinition.metadata.photoshop_actions : [])
    }
    else if (process && process.photoshop_actions) {
      setPhotoShopActions(process.photoshop_actions)
    }
  }, [oneBlockDefinition, blockDefinition, visible]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (photoshopActions.length > 0) {
      const photoShopValue = getFieldValue('photoshop_actions').map(data => data.orientation)
      setOrientationDropdown(orientationInitialValue.filter(data => !photoShopValue.includes(data)))
    }

  }, [photoshopActions]) // eslint-disable-line react-hooks/exhaustive-deps
  const addNewPhotoShopAction = () => {
    setPhotoShopActions([...photoshopActions, { action: undefined, set: undefined, orientation: undefined }])
  }

  const removePhotoShopAction = (index) => {
    const value = getFieldValue('photoshop_actions')
    setPhotoShopActions(photoshopActions.filter((data, key) => key !== index))
    setPhotoShopActionValue(value.filter((item, key) => key !== index))
  }

  const onBlur = () => {
    const text = getFieldsValue().text && getFieldsValue().text.toLowerCase();
    const action = getFieldsValue().action.toLowerCase();
    const action_set = getFieldsValue().action_set.toLowerCase();
    const requires_work_order = getFieldsValue().requires_work_order.toLowerCase();
    const no_print_required = getFieldsValue().no_print_required.toLowerCase();
    const submission_per_block = getFieldsValue().submission_per_block.toLowerCase();
    const page_object = getFieldsValue().page_object.toLowerCase();
    const optional = getFieldsValue().optional.toLowerCase();
    setFieldsValue({ text, action, action_set, requires_work_order, no_print_required, submission_per_block, page_object });
  }
  const setPhotoShopActionValue = (data) => {
    setFieldsValue({
      photoshop_actions: data,
    });
  }

  const onChangeOrientation = (value, index) => {
    const filterProperty = getFieldValue('photoshop_actions').filter((data, key) => index !== key).map(item => item.orientation);
    filterProperty.push(value)
    setOrientationDropdown(orientationInitialValue.filter(data => !filterProperty.includes(data)))
  }

  const handleSubmit = e => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (+primaryGroupCode === 18) {
        const { media, ink, metaMode, pallet, xOffset, yOffset } = values;
        const isNotPresent = !media || !ink || !metaMode || !pallet || !xOffset || yOffset
        if (isNotPresent === true) {
          notification.error({
            message: 'MIM, Pallet, Offset X and Y are required for DTG Products',
          })
          return;
        }
      }
      if (!err) {
        if (process && !process.editable) {
          onSave(getFieldsValue(), processID)
          setCurrentProductBlockTypes([...currentProductBlockTypes, getFieldsValue().text])
          handleEditBlockDefinition(getFieldsValue(), processID, process && process.text)
          hideModal()
          form.resetFields();
          notification.success({
            message: 'Saved block definition changes.'
          })
        }
        else {
          if (currentProductBlockTypes.indexOf(getFieldsValue().text) < 0 || (process && getFieldsValue().text === process.text)) {
            onSave(getFieldsValue(), processID)
            handleEditBlockDefinition(getFieldsValue(), processID, process && process.text)
            setCurrentProductBlockTypes([...currentProductBlockTypes, getFieldsValue().text])
            hideModal()
            form.resetFields();
            notification.success({
              message: 'Saved block definition changes.'
            })
          } else {
            notification.error({
              message: 'Block type already exists.'
            })
            return
          }
        }
      }
    })
  }


  const processTextError = isFieldTouched('text') && getFieldError('text')
  const { Option } = AutoComplete;
  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <StyledFormItem
        label="Block Type"
        validateStatus={processTextError ? 'error' : ''}
        help={processTextError || ''}
      >
        {getFieldDecorator('text', {
          initialValue: oneBlockDefinition ? oneBlockDefinition.blockType : process && process.text.toLowerCase(),
          rules: processTextRules
        })(
          <AutoComplete
            options={blockTypesOptions.filter(type => !currentProductBlockTypes.includes(type))}
            filterOption={true}
            disabled={process && !process.editable}
          >
            {alphabeticalData(blockTypesOptions.filter(type => !currentProductBlockTypes.includes(type))).map(option =>
              <Option value={option}>{option}</Option>
            )}
          </AutoComplete>
        )}
      </StyledFormItem>

      <StyledFormItem
        label="Block Weight"
      >
        {getFieldDecorator('weight', {
          initialValue: oneBlockDefinition ? oneBlockDefinition.metadata.weight : process ? process.weight : ''
        })((
          <InputNumber
            autoComplete={`off`}
            style={{ width: '100%' }}
          />
        ))}
      </StyledFormItem>

      <StyledFormItem
        label="Block Value"
      >
        {getFieldDecorator('value', {
          initialValue: oneBlockDefinition ? oneBlockDefinition.metadata.value : process ? process.value : ''
        })((
          <InputNumber
            autoComplete={`off`}
            style={{ width: '100%' }}
          />
        ))}
      </StyledFormItem>

      {/* DTG SPECIFIC FIELDS */}
      {+primaryGroupCode === 18 && (
        <>
          <FormInputRow>
            <StyledFormItem label='Media'>
              {getFieldDecorator('media', {
                initialValue: oneBlockDefinition?.metadata?.extras?.media
                  ? oneBlockDefinition.metadata?.media ?? oneBlockDefinition.metadata.extras.media
                  : process
                    ? process.media
                    : ''
              })(<Input autoComplete={`off`} style={{ width: '95%' }} />)}
            </StyledFormItem>

            <StyledFormItem label='Ink'>
              {getFieldDecorator('ink', {
                initialValue: oneBlockDefinition?.metadata?.extras?.ink
                  ? oneBlockDefinition.metadata?.ink ?? oneBlockDefinition.metadata.extras.ink
                  : process
                    ? process.ink
                    : ''
              })(<Input autoComplete={`off`} style={{ width: '95%' }} />)}
            </StyledFormItem>

            <StyledFormItem label='Meta Mode'>
              {getFieldDecorator('metaMode', {
                initialValue: oneBlockDefinition?.metadata?.extras?.metaMode
                  ? oneBlockDefinition.metadata.metaMode ?? oneBlockDefinition.metadata.extras.metaMode
                  : process
                    ? process.metaMode
                    : ''
              })(<Input autoComplete={`off`} style={{ width: '95%' }} />)}
            </StyledFormItem>
          </FormInputRow>

          <FormInputRow>
            <StyledFormItem label='Offset X'>
              {getFieldDecorator('xOffset', {
                initialValue: oneBlockDefinition?.metadata?.extras?.x_offset
                  ? oneBlockDefinition.metadata.xOffset ?? oneBlockDefinition.metadata.extras.x_offset
                  : process
                    ? process.xOffset
                    : ''
              })(<InputNumber autoComplete={`off`} style={{ width: '95%' }} />)}
            </StyledFormItem>

            <StyledFormItem label='Offset Y'>
              {getFieldDecorator('yOffset', {
                initialValue: oneBlockDefinition?.metadata?.extras?.y_offset
                  ? oneBlockDefinition.metadata.yOffset ?? oneBlockDefinition.metadata.extras.y_offset
                  : process
                    ? process.yOffset
                    : ''
              })(<InputNumber autoComplete={`off`} style={{ width: '95%' }} />)}
            </StyledFormItem>

            <StyledFormItem label='Pallet'>
              {getFieldDecorator('pallet', {
                initialValue: oneBlockDefinition?.metadata?.extras?.pallet
                  ? oneBlockDefinition.metadata?.pallet ?? oneBlockDefinition.metadata.extras.pallet
                  : process
                    ? process.pallet
                    : ''
              })(<Input autoComplete={`off`} style={{ width: '95%' }} />)}
            </StyledFormItem>
          </FormInputRow>
        </>
      )}

      <StyledFormItem label="Plex">
        {getFieldDecorator('plex', {
          initialValue: oneBlockDefinition?.metadata?.plex ? oneBlockDefinition?.metadata?.plex : process ? process.plex : 'simplex'
        })((
          <Radio.Group>
            <Radio value="simplex">Simplex</Radio>
            <Radio value="duplex">Duplex</Radio>
          </Radio.Group>
        ))}
      </StyledFormItem>

      <StyledFormItem label="Requires Work Order?">
        {getFieldDecorator('requires_work_order', {
          initialValue: oneBlockDefinition ? `${oneBlockDefinition.metadata.requires_work_order}` : process ? process.requires_work_order : 'false'
        })((
          <Radio.Group>
            <Radio value="false">No</Radio>
            <Radio value="true">Yes</Radio>
          </Radio.Group>
        ))}
      </StyledFormItem>

      <StyledFormItem label="Page Object">
        {getFieldDecorator('page_object', {
          initialValue: oneBlockDefinition ? `${oneBlockDefinition.metadata.page_object}` : process ? process.page_object : 'false'
        })((
          <Radio.Group>
            <Radio value="false">No</Radio>
            <Radio value="true">Yes</Radio>
          </Radio.Group>
        ))}
      </StyledFormItem>

      <StyledFormItem label="Create submission individually per block?">
        {getFieldDecorator('submission_per_block', {
          initialValue: oneBlockDefinition ? `${oneBlockDefinition.metadata.submission_per_block}` : process ? process.submission_per_block : 'false'
        })((
          <Radio.Group>
            <Radio value="false">No</Radio>
            <Radio value="true">Yes</Radio>
          </Radio.Group>
        ))}
      </StyledFormItem>
      <StyledFormItem label="Optional Product Block">
        {getFieldDecorator('optional', {
          initialValue: oneBlockDefinition ? `${oneBlockDefinition.metadata.optional}` : process ? process.optional : 'false'
        })((
        <Radio.Group>
          <Radio value="false">No</Radio>
          <Radio value="true">Yes</Radio>
        </Radio.Group>
        ))}
      </StyledFormItem>

      <StyledFormItem label="No Print Required?">
        {getFieldDecorator('no_print_required', {
          initialValue: oneBlockDefinition ? `${oneBlockDefinition.metadata.no_print_required}` : process ? process.no_print_required : 'false'
        })((
          <Radio.Group>
            <Radio value="false">No</Radio>
            <Radio value="true">Yes</Radio>
          </Radio.Group>
        ))}
      </StyledFormItem>
      {photoshopActions.map((data, key) => {
        return <Row type="flex" align="middle">
          <Col span="7" style={{ padding: 5 }}>
            <Form.Item label="Photoshop Actions">
              {getFieldDecorator(`photoshop_actions[${key}].action`, {
                initialValue: data.action,
                rules: [
                  {
                    required: true,
                    whitespace: false,
                    message: 'Please input this field!',
                  },
                ],
              })((
                <Input autoComplete={`off`} />
              ))}
            </Form.Item>
          </Col>
          <Col span="7" style={{ padding: 5 }}>
            <Form.Item label="Photoshop Action Set">
              {getFieldDecorator(`photoshop_actions[${key}].set`, {
                initialValue: data.set,
                rules: [
                  {
                    required: true,
                    whitespace: false,
                    message: 'Please input this field!',
                  },
                ],
              })((
                <Input autoComplete={`off`} />
              ))}
            </Form.Item>
          </Col>
          <Col span="8" style={{ padding: 5 }}>
            <Form.Item label="Orientation">
              {getFieldDecorator(`photoshop_actions[${key}].orientation`, {
                initialValue: data.orientation ? data.orientation : undefined,
                rules: [
                  {
                    required: true,
                    whitespace: false,
                    message: 'Please input this field!',
                  },
                ],
              })((
                <Select optionFilterProp="children"
                  showSearch style={{ width: "100%" }}
                  placeholder="Select Orientation"
                  onChange={(value) => onChangeOrientation(value, key)}>
                  {orientationDropdown.map(item => {
                    return <Option style={{ textTransform: "capitalize" }} value={item}>{item}</Option>
                  })}
                </Select>
              ))}
            </Form.Item>
          </Col>
          <Col span="2">
            <Button type="danger" style={{ width: "100%", marginTop: "0px" }} ghost onClick={() => removePhotoShopAction(key)}>REMOVE</Button>
          </Col>
        </Row>
      })}
      {photoshopActions.length < 3 &&
        <Row type="flex" align="middle" justify="end">
          <AddButton onClick={addNewPhotoShopAction} >
            <PlusCircleFilledIcon />
            ADD NEW PHOTOSHOP ACTION
          </AddButton>
        </Row>
      }

      <ButtonContainer>
        <Button onClick={(e) => { hideModal(e); form.resetFields() }}>Cancel</Button>

        <Button
          type="primary"
          htmlType="submit"
          disabled={!getFieldsValue().text}
        >
          Save Changes
        </Button>
      </ButtonContainer>
    </Form >
  )
}

const BlockForm = Form.create({ name: 'Block Item Form' })(EditForm)

function BlockDetailsModal(props) {
  const [oneBlockDefinition, setOneBlockDefinition] = useState(null)
  const { process, onSave, processID, currentProductBlockTypes, setCurrentProductBlockTypes, blockTypesOptions, blockDefinition, setBlockDefinition, primaryGroupCode, ...modal } = props
  const [processText, setProcessText] = useState(process?.text ?? '')
  useEffect(() => {
    if (blockDefinition && processText) {
      setOneBlockDefinition(blockDefinition.find(data => data.blockType === processText))
    }
  }, [blockDefinition, processText]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (process?.text && process.text !== processText) {
      setProcessText(process.text)
    }
  }, [process])
  return (
    <Modal {...modal}>
      <BlockForm
        process={process}
        onSave={onSave}
        primaryGroupCode={primaryGroupCode}
        processID={processID}
        hideModal={modal.onCancel}
        currentProductBlockTypes={currentProductBlockTypes}
        setCurrentProductBlockTypes={setCurrentProductBlockTypes}
        blockTypesOptions={blockTypesOptions}
        blockDefinition={blockDefinition}
        oneBlockDefinition={oneBlockDefinition}
        setBlockDefinition={setBlockDefinition}
        visible={modal.visible}
      />
    </Modal>
  )
}

export default BlockDetailsModal