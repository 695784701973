import React, {useMemo} from 'react';
import {Button, Popover, Table, Tag, Tooltip} from 'antd';
import * as _ from 'lodash';

export default ({items, aggregates, onRemoveItem, destinations}) => {
    const totalLines = useMemo(() => items.reduce((acc, item) => acc + item.number_of_lines, 0), [items])

    return (
        <>
          {items.length > 0 && <div className={'flex justify-end'}>
                  <div>
                      <span className={'font-extrabold'}>{items.length} </span>
                      <span className={'font-light mr-4'}>SCAN{items.length > 1 && 'S'} </span>
                      <span className={'font-extrabold'}>{totalLines} </span>
                      <span className={'font-light'}>LINE{totalLines > 1 && 'S'}</span>
                  </div>
          </div>}
          <Table
              dataSource={items.length === 0 ? [] : [...items, aggregates]}
              columns={[
                  {
                      title: 'Barcode',
                      dataIndex: 'barcode',
                      key: 'barcode',
                  },
                  {
                      title: 'Destination',
                      key: 'destination-code',
                      render: (item, row, index) => {
                          if (index + 1 > items.length) {
                              return null;
                          }

                          const destination = _.find(destinations,
                              {destination_code: item.destination_code});

                          return <Popover
                              placement="topLeft"
                              title={'Address'}
                              content={
                                  <div>
                                      <div>{destination.address.name}</div>
                                      <div>{destination.address.business_name}</div>
                                      <div>{destination.address.street1}</div>
                                      <div>{destination.address.city}</div>
                                      <div>{destination.address.state}</div>
                                      <div>{destination.address.country}</div>
                                      <div>{destination.address.postcode}</div>
                                  </div>
                              }
                              trigger="hover">
                              <Button>{item.destination_code}</Button>
                          </Popover>;
                      },
                  },
                  {
                      title: 'Products',
                      key: 'product_codes',
                      render: (item, row, index) => {
                          if (index + 1 > items.length) {
                              return null;
                          }

                          return <div>
                              {_.chunk(item.product_codes, 3)
                                  .map((productCodes, rowIndex) => (
                                      <div key={rowIndex} className={`flex mt-1`}>
                                              {productCodes.map(product => (
                                                  <Tooltip key={product.code}
                                                           title={product.name}>
                                                      <Tag>{product.code}</Tag>
                                                  </Tooltip>
                                              ))}
                                      </div>
                                  ))}
                          </div>;
                      },
                  },
                  {
                      title: 'Type',
                      key: 'type',
                      render: (item, row, index) => {

                          if (index + 1 > items.length) {
                              return null;
                          }

                          const iconTypes = {
                              'drop shipment': 'cyan',
                              'finished line': 'blue',
                              'unfinished block': 'geekblue',
                          };

                          return <Tag color={_.get(iconTypes, item.type,
                              'green')}>{_.capitalize(item.type)}</Tag>;
                      },
                  },
                  {
                      title: 'Value',
                      key: 'value',
                      render: (item, row, index) => {
                          if (index + 1 <= items.length) {
                              return <span>${item.value}</span>;
                          }

                          return <span
                              className={'font-bold'}>${item.value}</span>;
                      },
                  },
                  {
                      title: 'Weight',
                      key: 'weight',
                      render: (item, row, index) => {
                          if (index + 1 <= items.length) {
                              return <span>{item.weight}</span>;
                          }

                          return <span
                              className={'font-bold'}>{item.weight}</span>;
                      },
                  },
                  {
                      title: 'Action',
                      key: 'action',
                      render: (item, row, index) => {
                          if (index + 1 <= items.length) {
                              return <Button onClick={e => onRemoveItem(item)}
                                             type="danger" icon="delete"/>;
                          }

                          return null;
                      },
                  },
              ]
              }

              locale={
                  {
                      emptyText: 'Add items by entering barcodes.',
                  }
              }

              pagination={false}
          />
        </>
    );
}