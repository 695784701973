import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Button, Divider, Icon, Modal, Popover} from 'antd';
import styled, {css} from 'styled-components';
import {Droppable} from 'react-beautiful-dnd';
import * as _ from 'lodash';

import {ACTION, PROCESS} from 'types';
import {getColorFromType} from 'utils';

import {LibraryItem} from 'components/composites';
import {
  background,
  color,
} from 'components/zensmart-design-system/shared/styles.js';
import {DesignerContext} from '../../DispatchDesignerContext';

const Container = styled.div`
  position: relative;
  padding: 48px 16px;
  overflow: auto;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 128px));
  grid-auto-rows: min-content;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  align-items: center;

  width: 100%;
  height: 400px;
`;

const IconContainer = styled.div`
  z-index: 3;
  position: absolute;
  top: -8px;
  right: -2px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  background-color: ${props => props.color};
  border-radius: 50%;

  cursor: pointer;
  transition-duration: 0.15s;

  ${props => props.stylings}
`;

const providerEditIconPosition = css`
  right: 29px;
`;

const BucketItem = styled.div`
  background-color: ${background.default};
  border: 1px solid ${color.gray};
  border-radius: 16px;

  padding: 2px 8px;
  min-width: 160px;
`;

const GridWrapper = styled.div`
  position: relative;
  grid-row: ${props => props.row};
  grid-column: ${props => props.col};
`;

const Line = styled.div`
  position: absolute;
  top: ${props => props.yPos}px;
  left: ${props => props.xPos}px;
  border: 1px solid ${color.gray};
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const LibraryDropZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 128px;
  height: 56px;

  border: 2px dashed ${color.gray};
  border-radius: 4px;

  font-size: 32px;
  color: ${color.gray};
`;

const AddNewBucketItemButtonButton = styled.button`
  background-color: ${background.default};
  font-weight: bold;

  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${color.gray};
  border-radius: 50%;
`;

const NODE_IMMUTABLE_COLOR = '#008080';

function NodeConnector(props) {
  const {stageMeasurement, parentMeasurement} = props;

  if (parentMeasurement === null || stageMeasurement === null) {
    return null;
  }

  if (!parentMeasurement.x || !stageMeasurement.x) {
    return null;
  }

  const parentMeasurementMid = parentMeasurement.top +
      (parentMeasurement.height / 2);
  const stageMeasurementMid = stageMeasurement.top +
      (stageMeasurement.height / 2);

  return (
      <Fragment>
        <Line
            xPos={parentMeasurement.right - stageMeasurement.left}
            yPos={(parentMeasurement.top + (parentMeasurement.height / 2)) -
            stageMeasurement.top}
            width={(stageMeasurement.left - parentMeasurement.right) / 2}
        />

        <Line
            xPos={(parentMeasurement.right - stageMeasurement.left) +
            ((stageMeasurement.left - parentMeasurement.right) / 2)}
            yPos={(parentMeasurement.top + (parentMeasurement.height / 2)) -
            stageMeasurement.top}
            height={stageMeasurementMid - parentMeasurementMid}
        />

        <Line
            xPos={-((stageMeasurement.left - parentMeasurement.right) / 2)}
            yPos={stageMeasurement.height / 2}
            width={(stageMeasurement.left - parentMeasurement.right) / 2}
        />
      </Fragment>
  );
}

function StageContainer(props) {
  const {rowNum, colNum, parentID, stageID, children} = props;
  const {stages} = useContext(DesignerContext);
  const wrapperRef = useRef(null);

  const [stageMeasurement, setStageMeasurement] = useState(null);
  const [parentMeasurement, setParentMeasurement] = useState(null);

  // const measuredRef = useCallback(node => {
  //   if (node !== null) {
  //     setStageMeasurement(node.getBoundingClientRect());
  //   }
  //
  //   if (parentID) {
  //     const parentNode = document.querySelector(
  //         `[data-stage-id="${parentID}"]`);
  //
  //     setParentMeasurement(
  //         parentNode ? parentNode.getBoundingClientRect() : {});
  //   }
  // }, [parentID, stageID]);

  useEffect(() => {

    if (stages && wrapperRef.current) {
      setStageMeasurement(wrapperRef.current.getBoundingClientRect());

      if (parentID) {
        const parentNode = document.querySelector(
            `[data-stage-id="${parentID}"]`);

        setParentMeasurement(
            parentNode ? parentNode.getBoundingClientRect() : {});
      }

    }

  }, [stages]);

  return (
      <GridWrapper row={rowNum} col={colNum} ref={wrapperRef}>
        <div data-stage-id={stageID}
             style={{position: 'relative', display: 'inline-block'}}>
          {children}
        </div>
        <NodeConnector
            stageMeasurement={stageMeasurement}
            parentMeasurement={parentMeasurement}
        />
      </GridWrapper>
  );
}

const renderBucketItem = (stage, transformQualifierValue) => {

  return (
      <BucketItem>
        {transformQualifierValue(stage)}
      </BucketItem>
  );

};

const renderLibraryItem = (stage, currentDragType) => {

  if (stage.type === PROCESS.TYPE.FIELD_SWITCH) {
    return <Droppable
        key={_.uniqueId('dispatch_stages_')}
        type={currentDragType}
        droppableId={`switches.${stage.id}`}>
      {(provided, snapshot) => {
        return (
          <div
              key={_.uniqueId()}
              {...provided.droppableProps}
              ref={provided.innerRef}
          >
            <LibraryItem
                {...stage}
                className={stage.nextNode.indexes && 'library-item-droppable'}
                color={stage.immutable ? NODE_IMMUTABLE_COLOR : null}
                style={{
                  backgroundColor: snapshot.isDraggingOver && '#cdd3dd',
                }}
            />
            <div style={{display: 'none'}}>{provided.placeholder}</div>
          </div>
      )}}
    </Droppable>;
  }

  if (stage.type === PROCESS.TYPE.PACK_TYPE) {
    return <Droppable
        key={_.uniqueId('dispatch_stages_')}
        type={currentDragType}
        droppableId={`packs.${stage.id}`}>
      {(provided, snapshot) => {
        return (
            <div
                key={_.uniqueId()}
                {...provided.droppableProps}
                ref={provided.innerRef}>
              {stage.context.map((dispatchPack, index) => {
                const stageData = {
                  type: 'Pack Type',
                  text: dispatchPack.pack_type.code,
                  immutable: false,
                };
                return (
                      <LibraryItem
                          style={{
                            backgroundColor: snapshot.isDraggingOver && '#cdd3dd',
                          }}
                          className={`library-item-droppable`}
                          key={dispatchPack.id} {...stageData}
                          color={stage.immutable
                                          ? NODE_IMMUTABLE_COLOR
                                          : null}
                      />
                );
              })}
              <div
                  style={{display: 'none'}}>{provided.placeholder}</div>
            </div>
        );
      }}
    </Droppable>;
  }

  return <LibraryItem key={_.uniqueId('dispatch_stages_')}
                      {...stage}
                      color={stage.immutable ? NODE_IMMUTABLE_COLOR : null}/>;

};

function DispatchStage(props) {
  const {stages, transformQualifierValue, deleteNode} = useContext(
      DesignerContext);
  const {rowNum, colNum, stage, stageID, currentDragType, viewStageData} = props;

  const stageItem = stage.type === PROCESS.TYPE.BUCKET
                    ?
                    renderBucketItem(stage,
                        transformQualifierValue)
                    : renderLibraryItem(stage, currentDragType);

  const getIconColor = (stage) => {

    if (stage.immutable) {
      return NODE_IMMUTABLE_COLOR;
    }

    return getColorFromType(stage.type);
  };

  const stageActions = () => {

    return (
        <div style={{maxWidth: '300px'}}>
          {stage.info_text &&
          <div style={{textAlign: 'center'}}>
            <div>{stage.info_text}</div>
          </div>
          }
          {!stage.immutable && stage.info_text &&
          <Divider/>
          }
          {!stage.immutable &&
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div>
              <Button
                  shape="circle"
                  icon="edit"
                  onClick={() => {
                    if (stage.type === PROCESS.TYPE.FIELD_SWITCH) {
                      Modal.confirm({
                        'title': 'Warning',
                        'content': `Editing or deleting this box will remove any boxes that occur after it and they'll have to be added again`,
                        onOk: () => {
                          viewStageData({
                            stageID,
                            parentID: stage.parent
                                      ? `${stage.parent.category}.${stage.parent.index}`
                                      : null,
                            stageType: stage.type,
                            actionType: ACTION.EDIT_DISPATCH_STAGE,
                            ...stage,
                          });
                        },
                      });
                      return;
                    }
                    viewStageData({
                      stageID,
                      parentID: stage.parent
                                ? `${stage.parent.category}.${stage.parent.index}`
                                : null,
                      stageType: stage.type,
                      actionType: ACTION.EDIT_DISPATCH_STAGE,
                      ...stage,
                    });
                  }}
              />
            </div>
            {stage.type !== PROCESS.TYPE.EDGE &&
            <div style={{marginLeft: '5px'}}>
              <Button
                  onClick={() => {
                    Modal.confirm({
                      'title': 'Warning',
                      'content': `Editing or deleting this box will remove any boxes that occur after it and they'll have to be added again`,
                      onOk: () => {
                        deleteNode(stage.id);
                      },
                    });

                  }}
                  shape="circle"
                  icon="delete"/>
            </div>
            }
          </div>
          }
        </div>
    );
  };

  return (
      <StageContainer
          rowNum={rowNum}
          colNum={colNum}
          parentID={stage.parent &&
          `${stage.parent.category}.${stage.parent.index}`}
          stageID={stageID}
      >
        {stage.type !== PROCESS.TYPE.BUCKET &&
        <Popover
            overlayStyle={{zIndex: 2}}
            trigger={['click']}
            content={stageActions(stage)}
        >
          <IconContainer
              color={getIconColor(stage)}
              stylings={stage.type === PROCESS.TYPE.PROVIDER
                        ? providerEditIconPosition
                        : null}
          >
            <Icon
                type="info"
                width="8px"
                height="8px"
                style={{color: 'white'}}
            />
          </IconContainer>
        </Popover>
        }
        {stageItem}
      </StageContainer>
  );
}

function buildStages(nextNode, props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {stages} = useContext(DesignerContext);
  const {currentDragType, viewStageData} = props;

  let rowNum = 1;

  function recursiveStageBuild(node, colNum = 2, parentID = 'dispatch.null') {

    if (node.indexes === null) {
      const dropZone = (
          <StageContainer
              rowNum={rowNum}
              colNum={colNum}
              parentID={parentID}
              stageID={null}
          >
            <Droppable isDropDisabled={stages.dispatch.id === null}
                       type={currentDragType} droppableId={`${parentID}.new`}>
              {(provided, snapshot) => (
                  <div key={_.uniqueId()} {...provided.droppableProps}
                       ref={provided.innerRef}>
                    <LibraryDropZone
                        style={{
                          backgroundColor: snapshot.isDraggingOver && '#cdd3dd',
                        }}
                        className="library-item-droppable">+</LibraryDropZone>
                    <div style={{display: 'none'}}>{provided.placeholder}</div>
                  </div>
              )}
            </Droppable>
          </StageContainer>
      );

      rowNum = rowNum + 1;

      const parentNode = parentID.split('.')[0];

      return parentNode === 'providers' ? null : dropZone;
    }

    const nextStages = stages[node.category];

    return node.indexes.map((nextStageIndex, nodeIndexNum) => {
      if (nextStageIndex === null) {
        const prevStageIndex = node.indexes[nodeIndexNum - 1];
        const prevStage = nextStages[prevStageIndex];
        const parentID = `${prevStage.parent.category}.${prevStage.parent.index}`;
        const bucketStage = (
            <StageContainer
                rowNum={rowNum}
                colNum={colNum}
                parentID={parentID}
                stageID={null}
                key={`${rowNum}:${colNum}`}
            >
              <AddNewBucketItemButtonButton
                  onClick={() => {
                    viewStageData({
                      parentID,
                      stageType: PROCESS.TYPE.BUCKET,
                      actionType: ACTION.ADD_DISPATCH_STAGE,
                    });
                  }}
              >
                +
              </AddNewBucketItemButtonButton>
            </StageContainer>
        );
        rowNum = rowNum + 1;
        return bucketStage;
      }
      const stage = nextStages[nextStageIndex];
      return (
          <Fragment key={`${rowNum}:${colNum}`}>
            <DispatchStage
                stage={stage}
                stageID={`${node.category}.${nextStageIndex}`}
                rowNum={rowNum}
                colNum={colNum}
                viewStageData={viewStageData}
                currentDragType={props.currentDragType}
            />
            {recursiveStageBuild(stage.nextNode, colNum + 1,
                `${node.category}.${nextStageIndex}`)}
          </Fragment>
      );
    });
  }

  let parentId = 'dispatch.null';
  if (props.stages.dispatch.id) {
    parentId = `dispatch.${props.stages.dispatch.id}`;
  }
  return recursiveStageBuild(nextNode, 2, parentId);
}

function DispatchStages(props) {
  const {stages, viewStageData} = props;
  const {dispatch} = stages;

  useEffect(() => {
    if (dispatch.id === null) {
      viewStageData({
        stageID: 'dispatch.null',
        stageType: 'Edge',
        actionType: ACTION.EDIT_DISPATCH_STAGE,
        ...dispatch,
      });
    }
  }, [dispatch]);

  return (
      <Container>
        <DispatchStage
            rowNum={1}
            colNum={1}
            stage={dispatch}
            stageID={dispatch.id !== null
                     ? `dispatch.${dispatch.id}`
                     : 'dispatch.null'}
            viewStageData={viewStageData}
            currentDragType={props.currentDragType}
        />
        {buildStages(dispatch.nextNode, props)}
      </Container>
  );
}

export default DispatchStages;