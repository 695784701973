import styled, { createGlobalStyle } from 'styled-components'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { Col, Button } from 'antd'

export const Container = styled.div`
  padding: 20px;
`
export const ListItem = styled.li`
  font-size: ${props => (props.strong ? '20px;' : '15px;')};
  margin-left: ${props => (props.strong ? '0px;' : '20px;')};
  margin-top: ${props => (props.strong ? '10px;' : '0px;')};
`

export const Header = styled.header`
  margin-bottom: 24px;
`
export const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
export const SubTitle = styled.h1`
  font-size: 18px;
  color: #bfc5d2;
  font-weight: bold;
  padding: 0px 0px 10px 0px;
`
export const Heading = styled.h1`
  font-size: 15px;
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
`
export const DateColStyle = styled(Col)`
  padding: 10px;
`
export const StyleButton = styled(Button)`
  font-family: 'Rubik', sans-serif;
`
export const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`

export const FloatingButton = styled.button`
  position: fixed;
  height: 40px;
  padding: 0 20px;
  border-radius: 30px;
  background: #0f6ba0;
  bottom: 20px;
  right: 20px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
`
