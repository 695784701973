import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, Input, Button as AntButton, notification, Select, Icon } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { ZenSmartAPI } from 'utils'
import { Button } from '../../../components/zensmart-design-system';
import { modelDefinitionRoute, saveNewSorterConfigurationRoute, sorterConfigurationRoute } from "utils/apiRoutes"
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import _ from "lodash"
import debounce from 'lodash/debounce';
import SorterFieldsComponent from './SorterFieldsComponent'

const ConfigurationSortOrder = (props) => {
    const {
        configurations,
        setConfigurations,
        loading,
        setLoading,
        fetchConfigurations,
        getOneConfiguration,
        oneConfiguration,
        fieldSelectValues,
        configurationInitData,
        presetProductId,
    } = props
    const [sorterNewFieldValue, setSorterNewFieldValue] = useState(null)
    const [sortersFields, setSortersFields] = useState([])

    const saveNewSorter = (value) => {
        if (sorterNewFieldValue) {
            setLoading(true)
            ZenSmartAPI.post(saveNewSorterConfigurationRoute(oneConfiguration.id), {
                  model_definition_id: sorterNewFieldValue,
                  sort_direction: "ascending",
                  is_custom_preset: presetProductId && true,
                  preset_product_id: Array.isArray(presetProductId) && presetProductId.length > 0 ? presetProductId[0] : presetProductId ,
                })
                .then((res) => {
                    setLoading(false)
                    setSorterNewFieldValue(null)
                    setSortersFields([...sortersFields, res.data.data])
                })
                .catch((err) => {
                    setLoading(false)
                    errorNoticationHandler(err, "Cant save sorter!")
                })
        }
        else {
            notification.error({ message: "Please select field first!" })

        }
    }

    const changeSorterFieldValues = (value, index) => {

        let items = [...sortersFields];
        let item = { ...items[index] };
        item = value;
        items[index] = item;
        setSortersFields(items)
    }

    const deleteSortOrder = (sorterId) => {
        console.log(`sorterId`, sorterId)
        setLoading(true)
        ZenSmartAPI.delete(sorterConfigurationRoute(sorterId))
            .then((res) => {
                setLoading(false)
                setSortersFields(sortersFields.filter(data => data.id != sorterId))
            })
            .catch((err) => {
                setLoading(false)
                errorNoticationHandler(err, "Cant Delete sorter!")
            })
    }

    useEffect(() => {
        if (oneConfiguration.sorters) {
            setSortersFields(oneConfiguration.sorters)
        }
    }, [oneConfiguration]) // eslint-disable-line react-hooks/exhaustive-deps


    return <div>
        <div style={{ minHeight: 100, padding: 20, border: "2px solid rgba(218,225,247,255)", borderRadius: "10px" }}>
            <h2>ADD FIELD:</h2>
            <Row type="flex" align="middle">
                <Col span="7" style={{ padding: 10 }}>
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Enter Field (Click Suggestions to add each field)"
                        optionFilterProp="children"
                        value={sorterNewFieldValue ? sorterNewFieldValue : undefined}
                        dropdownMatchSelectWidth={false}
                        onChange={(value) => setSorterNewFieldValue(value)}
                    >
                        {fieldSelectValues && fieldSelectValues.map(data =>
                            <Select.Option value={data.id}>{data.label.replace('lines.', '')}</Select.Option>
                        )}
                    </Select>
                </Col>
                <Col span="6" style={{ padding: 10 }}>
                    <Button size="small" onClick={saveNewSorter}>Add to Sort Order</Button>
                </Col>
                <Col span="24" style={{ padding: 10 }}>
                    <br />
                    {oneConfiguration.sorters && sortersFields.length > 0 &&
                        sortersFields.map((data, key) => {
                            return <SorterFieldsComponent
                                data={data}
                                fieldSelectValues={fieldSelectValues}
                                configurationInitData={configurationInitData}
                                deleteSortOrder={deleteSortOrder}
                                setLoading={setLoading}
                                loading={loading}
                                changeSorterFieldValues={changeSorterFieldValues}
                                key={key}
                                sortersFields={sortersFields}
                            />
                        })

                    }
                </Col>
            </Row>
            <br />

        </div>
    </div >
}

export default ConfigurationSortOrder