import React, { useRef, useEffect, useState } from 'react'
import { Row, Col, Form, Input, notification, Modal, Button } from 'antd'
import styled, { css } from 'styled-components'
import { Label } from 'components/zensmart-design-system/components/Label/Label.js'
import { Panel } from '../../../components/primaries'
import { ZenSmartAPI } from 'utils';
import Cookies from 'js-cookie';
import { normalBarcodeScanRoute } from "utils/apiRoutes"
import { ReactComponent as Index } from 'static/icons/inbox.svg'
import { ReactComponent as Retrieve } from 'static/icons/retrieve.svg'
import { ReactComponent as Hold } from 'static/icons/hold.svg'
import Image from "components/zensmart-design-system/components/Image/Image"
import MatchCheckDialog from './Components/MatchCheckDialog'

/**
 * @note Clean the API for this component. Feels like it has to know
 * a lot of variables/prop values in order to be useful.
 */

const panelStylings = css`
  margin-bottom: 24px;
  padding-bottom: 50px;
`
const StyledRow = styled(Row)`
  margin-bottom: 24px;
`
const InvalidScanImage = styled.p`
  text-align: center;
  margin-top: 20px;
`
const BoxNumber = styled.h1`
  text-align: center;
  font-size: 50px;
`
const borderStylings = { borderRight: "1px solid #0B6BF2", padding: "50px" }

const FlexImageWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;

  div {
    padding: 0 5px;
    text-align: center;
    margin-bottom: 5px;
  }
`;

const LookupForm = Form.create({ name: 'LookupForm' })(props => {
    const { onSubmit, inputProps, form, scanTypeId, setInvalidScan, setScanItem, showMatchCheckDialog } = props
    const { getFieldDecorator, getFieldValue, resetFields } = form
    const [loading, setLoading] = useState(false)
    const scanInput = useRef(null)
    const refocusQueueID = useRef(null)
    const clearRefocusQueue = () => {
        clearTimeout(refocusQueueID.current)
    }

    const refocusQueue = () => {
        if (!showMatchCheckDialog) {
            refocusQueueID.current = setTimeout(() => {
                if (scanInput.current) {
                    scanInput.current.focus()
                }
            }, 5000)
        }
    }

    useEffect(() => {
        if(!showMatchCheckDialog){
            scanInput.current.focus()
        }
        return clearRefocusQueue
    }, [showMatchCheckDialog])

    const handleSubmit = async (evt) => {
        evt.preventDefault()
        const input_value = getFieldValue('lookup_text');
        let payload = {};

        if (input_value === undefined) {
            scanInput.current.focus()
            return onSubmit({ error: 'Please Scan A Barcode' })
        }

        if (input_value.includes('STATION') === true) {
            payload = { barcode: input_value, scan_type_id: scanTypeId }
        }

        else if ((input_value.includes('STATION') === false && Cookies.get('STATION') === undefined)
            || parseInt(Cookies.get('SCAN_TYPE')) !== parseInt(scanTypeId)) {
            resetFields()
            scanInput.current.focus()
            return onSubmit({ error: 'Please Scan Station Card First' })
        }

        else {
            payload = { station_id: Cookies.get('STATION'), barcode: input_value, scan_type_id: scanTypeId };
        }
        setLoading(true);
        await ZenSmartAPI.post(normalBarcodeScanRoute, payload)
            .then((res) => {
                resetFields()
                setLoading(false)
                setInvalidScan.setInvalidScanItem(null)
                scanInput.current.focus()
                if (res.data && res.data.duplicate_scan_prompt) {
                    Modal.confirm({
                        'content': res.data.duplicate_scan_prompt,
                        onOk: () => {
                        },
                        cancelButtonProps: { style: { display: "none" } }
                    });
                }
                onSubmit({ ...res.data, success_scan: true })
            })
            .catch((res) => {
                resetFields()
                setLoading(false)
                setScanItem()
                scanInput.current.focus()

                if (res.response.data.hasOwnProperty('message') && res.response.status === 400) {
                    let errMessage = res.response.data.message.split(".")
                    setInvalidScan.setInvalidScanItem(errMessage);
                }else{
                    notification.error({
                        message: "Not a valid scan item"
                    })
                }
            })

    }
    useEffect(() => {
        if (scanInput.current) {
            scanInput.current.focus()
        }
        return clearRefocusQueue
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            {getFieldDecorator('lookup_text')(
                <Input
                    type="text"
                    placeholder="Scan Item"
                    ref={scanInput}
                    onFocus={clearRefocusQueue}
                    onBlur={refocusQueue}
                    {...inputProps}
                    disabled={loading}
                    loading={loading}
                />
            )}
        </Form>
    )
})

function MatchLookup(props) {
    const {
        title,
        detailLabel,
        isViewingDetails,
        onLookup,
        inputProps,
        children,
        scanTypeId,
        setScanItem
    } = props

    const [invalidScanItem, setInvalidScanItem] = useState(null)

    const [showMatchCheckDialog, setShowMatchCheckDialog] = useState(false)

    const setOrderDetail = (data) => {

        const matchData = data ? data.page_definitions.sections.find(val => val.section_header === data.scan_type) : null;
        const warning = { data: null };
        if (matchData) {
            const findWarning = matchData.section_data.find(val => val.label === "WARNING:")
            if (findWarning) {
                let newValue = findWarning.value.split(".")
                warning.data = { message: "WARNING:", description: newValue }
            }
        }
        return (
            <>
                {matchData &&
                    <Col span={12} style={{ padding: "50px" }}>
                        <Row>
                            <Col span={24}>
                                <p style={{ textAlign: "center" }}>
                                    {warning.data ?
                                        <Label status="red">
                                            {warning.data.message}
                                            {warning.data.description.map(description => (
                                                <>
                                                    {description && (
                                                        <div>{description}.</div>
                                                    )}
                                                </>
                                            ))}
                                        </Label>
                                        : <Label status="blue">
                                            {matchData.section_data[0].value === "PUT" ?
                                                `PLACE ${matchData.section_data[5].value} ITEMS IN MATCH BOX ${matchData.section_data[1].value}`
                                                : matchData.section_data[0].value === "GET" ?
                                                    `GET BLOCKS FROM MATCH BOX ${matchData.section_data[1].value}`
                                                    : null}
                                        </Label>}
                                </p>
                                <br /><br />
                                {matchData.section_data[0].value === "PUT" ?
                                    <Index style={{ height: 100, width: "100%" }} /> : matchData.section_data[0].value === "GET"
                                        ? <Retrieve style={{ height: 100, width: "100%" }} /> : null}
                                <BoxNumber >{matchData.section_data[1].value}</BoxNumber>
                                <br />
                                <FlexImageWrapper>
                                    {matchData.section_data[6].value.front &&
                                        <Image
                                            src={matchData.section_data[6].value.front}
                                            width={140}
                                            height={300}
                                            alt="Line image"
                                            objectFit="fill"
                                        />

                                    }
                                    {matchData.section_data[6].value.back &&
                                        <Image
                                            src={matchData.section_data[6].value.back}
                                            width={140}
                                            height={300}
                                            alt="Line image"
                                            objectFit="fill"
                                        />
                                    }
                                    {(matchData.section_data[7] && matchData.section_data[7].value.front) &&
                                        <Image
                                            src={matchData.section_data[7].value.front}
                                            width={140}
                                            height={300}
                                            alt="Line image"
                                            objectFit="fill"
                                        />
                                    }
                                    {(matchData.section_data[7] && matchData.section_data[7].value.back) &&
                                        <Image
                                            src={matchData.section_data[7].value.back}
                                            width={140}
                                            height={300}
                                            alt="Line image"
                                            objectFit="fill"
                                        />
                                    }
                                </FlexImageWrapper>
                            </Col>
                        </Row>
                    </Col>
                }
            </>
        )
    }

    return (
        <Panel title={title} stylings={panelStylings}>
            <Row style={{ padding: "20px 70px 0px 70px" }}>
                <Col span={12} style={borderStylings}>
                    <StyledRow>
                        <Label status="blue">{detailLabel}</Label>
                        <Button type="primary" onClick={() => setShowMatchCheckDialog(true)} style={{ float: "right" }}>Match Check</Button>
                    </StyledRow>
                    <StyledRow>
                        <Col span={24}>
                            <LookupForm onSubmit={onLookup} setScanItem={setScanItem} setInvalidScan={{ invalidScanItem, setInvalidScanItem }}
                                inputProps={inputProps} scanTypeId={scanTypeId} isViewingDetails={isViewingDetails} showMatchCheckDialog={showMatchCheckDialog} />
                        </Col>
                    </StyledRow>

                    {children}
                </Col>
                {invalidScanItem ?
                    <Col span={12} style={{ padding: "50px" }}>
                        <p style={{ textAlign: "center" }}>
                            <Label status="red">ATTENTION: <br />
                                {invalidScanItem.map(message => (
                                    <>
                                        {message &&
                                            (<div>{message}.</div>)
                                        }
                                    </>
                                ))}
                            </Label>
                        </p>
                        <InvalidScanImage>
                            <Hold style={{ height: 100, width: "100%" }} />
                        </InvalidScanImage>
                    </Col>
                    : isViewingDetails ? setOrderDetail(isViewingDetails) : null}
            </Row>

            <MatchCheckDialog
                visible={showMatchCheckDialog}
                maskClosable={false}
                onCancel={() => setShowMatchCheckDialog(false)}
                scanTypeId={scanTypeId} />
        </Panel >
    )
}

export default MatchLookup