import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Row, Col, Table, Button, Spin, Checkbox } from "antd";
import { TableStyle, DropRegion, DataTotal } from '../primaries';
import { GetLabel, DataTypes, StripAndGetLast, addTargetSeries, addTargetSeriesOptions, addPercentageStack, gaugeChartOptions } from '../../utils';
import { Summarization, ColumnHeaderInfo } from '../composites';
import { Chart } from "react-google-charts";
import Matrix from './Matrix';
import moment from 'moment';
import styled from "styled-components";
import { emptyRecords } from "../../utils/ReportingUtil";
const { DATATYPE, GetDataType } = DataTypes

const StyledRow = styled(Row)`
  
  //Solution taken from https://stackoverflow.com/questions/25042874/how-can-i-change-the-css-properties-of-a-google-visualization-table-and-it-colum
  .google-visualization-table-table {
    font-family: arial, helvetica;
    font-size: 15px;
    cursor: default;
    margin: 0 0 10px 0;
    background: white;
    border-spacing: 0;
  }

  // this is needed to solve fickering when tool-tip is on a bar and is being hovered on in chart... 
  // Link to issue for future reference here at https://pictureworks.atlassian.net/browse/ZSMART-8179
  // solution taken from https://stackoverflow.com/questions/37902708/google-charts-tooltip-flickering 
  .google-visualization-tooltip { pointer-events: none }
`

const EmptyChart = styled.h1`
  margin-left: 20px;
  margin-bottom: 10px;
`

function DataDisplay({
  edit,
  dataSourceCount,
  sortationField,
  setSortationField,
  sortationFieldType,
  setSortationFieldType,
  columns = [],
  removeColumn,
  dataSource = [],
  matrixDataSource = [],
  onDragOver,
  onDrop,
  groups,
  removeGroup,
  onGroupingDrop,
  visualization = '',
  uniqueValues = [],
  hideDropZones,
  setHideDropZones,
  tightTable,
  chartConfiguration,
  editChart,
  deleteChart,
  chartSize,
  hideDetails,
  groupMatrixX = [],
  groupMatrixY = [],
  dropMatrixY,
  dropMatrixX,
  editableReport,
  loadedBuckets = [],
  removeLoadedBucket,
  setCurrentDropColumn,
  lastHoveredColumn,
  setLastHoveredColumn,
  deleteMatrixXItem,
  deleteMatrixYItem,
  matrixOptions = {
    option: '',
    field: ''
  },
  matrixDragStart,
  matrixDragEnd,
  setDragItem,
  setGroupedDate,
  groupedDate,
  dateGroupingOptions,
  containsGroupedItem,
  getGroupType,
  setTableWidth,
  panelHeight,
  isPrintReport,
  setIsPrintReport,
  setIsPrintReportLoading,
  reportName,
  startLoading,
  stopLoading,
  stackableChartTypes,
  percentage,
  sortField
}) {
  const showLoad = useRef()

  const history = useHistory()
  const [tableColumn, setTableColumn] = useState([])
  const [modifiedSource, setModifiedSource] = useState([])
  const [mouseCoord, setMouseCoord] = useState('')
  const [firstColumnCenter, setFirstColumnCenter] = useState(0)
  const [boundaryLeft, setBoundaryLeft] = useState(null)
  const [boundaryTop, setBoundaryTop] = useState(null)

  const tableWidth = React.useRef()
  const headerRef = useRef(null)
  const cellRef = useRef(null)
  const getWidth = document.getElementsByTagName("TR")[0];
  
  const stackedChartRan = useRef(false)
  const stackedChartTarget = useRef(null)
  const stackedChartTitle = useRef('')
  const stackedChartSize = useRef('')
  const stackedPercentageRef = useRef(false)
  const chartTypeRef = useRef('')
  const chartData = useRef()

  // useEffect(() => {
  //   console.log('Set table width called........')
  //   if (getWidth) {
  //     setTableWidth(getWidth.clientWidth)
  //   }
  // }, [getWidth]);

  const classSection = useMemo(() => mouseCoord ? `hover_column ${mouseCoord}` : '', [mouseCoord])

  // const classSection = mouseCoord ? `hover_column ${mouseCoord}` : ''

  const is_bucket = item => GetDataType(item.type) === DATATYPE.bucket

  const is_upstream = item => {
    let field = item.field;
    return field.includes('upstream_');
  }

  useEffect(() => {
    const transform = (item, id) => {
      return ({
        title: (
          <ColumnHeaderInfo
            label={
              is_bucket(item) || is_upstream(item) ?
                item.field : 
                item.path
            }
            deleteAction={() => removeColumn && removeColumn(item)}
            showDelete={editableReport}
            setDragAction={() => {
              setDragItem(item)
            }}
            stopDragAction={() => {
              setDragItem({})
            }}
          />
        ),
        dataIndex: is_bucket(item) ? item.field : `${item.table}.${item.field}`,
        key: is_bucket(item) ? item.field : `${item.table}.${item.field}`,
        width: 220,
        render: field => {
          if (item.field && item.field.toLowerCase() === 'id') {
            return (
              <Link
                to={`/app/data-view/${item.category}/${field}`}
                style={{ color: "black", textDecoration: 'underline' }}
              >
                {field === undefined || field === null ? "" : field === false ? <><Checkbox
                  checked={false}
                  disabled={true}
                /></> : field === true ? <><Checkbox
                  checked={true}
                  disabled={true}
                /></> : `${field}`}
              </Link>
            )
          }
          return (
            <div
              style={{ width: '100%' }}
            >
              {field === undefined || field === null ? "" : field === false ? <><Checkbox
                checked={false}
                disabled={true}
              /></> : field === true ? <><Checkbox
                checked={true}
                disabled={true}
              /></> : `${field}`}
            </div>
          )

        },
        sorter: (a, b) => {
          let tblFld = item.table + '.' + item.field

          switch (GetDataType(item.type)) {
            case DATATYPE.number:
              return parseInt(a[tblFld]) - parseInt(b[tblFld])
            case DATATYPE.bool:
              return a[tblFld] - b[tblFld]
            case DATATYPE.time:
              return moment(a[tblFld]).isBefore(b[tblFld]) ? -1 : 1
            case DATATYPE.text:
              if (item.field === "relative_due_date") {
                const due_dates = ['overdue', 'today', 'tomorrow', 'later']
                if (a[tblFld] && b[tblFld] && due_dates.indexOf(a[tblFld].toLowerCase().trim()) >= 0 && due_dates.indexOf(b[tblFld].toLowerCase().trim()) >= 0) {
                  if (due_dates.indexOf(a[tblFld].toLowerCase().trim()) < due_dates.indexOf(b[tblFld].toLowerCase().trim())) {
                    return 1;
                  }
                  if (due_dates.indexOf(b[tblFld].toLowerCase().trim()) < due_dates.indexOf(a[tblFld].toLowerCase().trim())) {
                    return -1;
                  }
                }
              }
              const c = a[tblFld] && a[tblFld] !== null ? a[tblFld].toLowerCase() : ""
              const d = b[tblFld] && b[tblFld] !== null ? b[tblFld].toLowerCase() : ""
              return (c.localeCompare(d));

            case DATATYPE.bucket:
              const stripField = StripAndGetLast(tblFld)
              const a1 = a[stripField] && a[stripField] !== null ? a[stripField].toLowerCase() : ""
              const b2 = b[stripField] && b[stripField] !== null ? b[stripField].toLowerCase() : ""
              return (a1.localeCompare(b2));
            default:
              // return a[item.field].length - b[item.field].length
              return
          }
        },
        sortDirections: ['descend', 'ascend'],
        sortOrder: !is_bucket(item) && Object.keys(sortField).length === 0 ?
          (sortationField === (item.table + '.' + item.field) && sortationFieldType) :
          (sortationField === (item.field) && sortationFieldType),
        onCell: () => {
          return {
            ref: id === 0 ? cellRef: null,
            onDragEnter: () => {
              setLastHoveredColumn(id)
              
              if (id !== 0) {
                setCurrentDropColumn(id + 1)
                setMouseCoord('right')
              } else {
                const dimensions = cellRef.current.getBoundingClientRect()
                const center = (dimensions.left + dimensions.right) / 2
                setFirstColumnCenter(center)
                setBoundaryLeft(dimensions.left)
              }
            },
            onDragOver: e => {
              if (id === 0 && cellRef.current) {
                if (e.clientX < firstColumnCenter) {
                  setCurrentDropColumn(id)
                  setMouseCoord('left')
                } else {
                  setCurrentDropColumn(id + 1)
                  setMouseCoord('right')
                }
              }
            },
            onDrop: () => {
              setLastHoveredColumn(null)
            },
            onDragLeave: (e) => {
              if ((e.clientX < boundaryLeft) || (e.clientY < boundaryTop)) {
                setLastHoveredColumn(null)
              }
            }
          }
        },
        onHeaderCell: () => {
          return {
            ref: id === 0 ? headerRef: null,
            onDragEnter: () => {
              setLastHoveredColumn(id)
              
              if (id !== 0) {
                setCurrentDropColumn(id + 1)
                setMouseCoord('right')
              } else {
                const dimensions = headerRef.current.getBoundingClientRect()
                const center = (dimensions.left + dimensions.right) / 2
                setFirstColumnCenter(center)
                setBoundaryTop(dimensions.top)
              }
            },
            onDragOver: (e) => {
              if (id === 0 && headerRef.current) {
                if (e.clientX < firstColumnCenter) {
                  setCurrentDropColumn(id)
                  setMouseCoord('left')
                } else {
                  setCurrentDropColumn(id + 1)
                  setMouseCoord('right')
                }
              }
            },
            onDrop: () => {
              setLastHoveredColumn(null)
            },
            onDragLeave: (e) => {
              if ((e.clientX < boundaryLeft) || (e.clientY < boundaryTop)) {
                setLastHoveredColumn(null)
              }
            }
          }
        },
        className: id === lastHoveredColumn ? classSection : '',
      }
 
      )
    }

    //const getColumn = [...new Set(columns.filter(field => field.category.indexOf(':') === -1 ).map(transform))];
    setTableColumn(columns.map(transform))
  }, [columns, removeColumn, loadedBuckets, removeLoadedBucket, editableReport, mouseCoord]);



  useEffect(() => {
    setModifiedSource(
      dataSource.map((item, index) => {
        item.key = index; //add a key to all data source entries
        return item;
      })
    )

  }, [dataSource]);

  const getMultipleCharts = () => {
    const showMultipleCharts = chartConfiguration.data[0].map((multipleChartConfiguration, key) => {
      if (key > 0 && key <= 20) {
        const data = [[chartConfiguration.data[0][0], multipleChartConfiguration]]
        const options = {}
        options.hAxis = { title: "" }
        options.title = chartConfiguration.options.title
        options.legend = "none"
        options.vAxis = chartConfiguration.options.vAxis
        
        chartConfiguration.data.map((chartConfig, keyItem) => {
          if (keyItem > 0) {
            data.push([chartConfig[0], chartConfig[key]])
          }
        })

        return (
          <Row>
            <div style={{position : "relative"}}>
            <p style={{padding : 20, marginBottom : "-42px", fontWeight : "bold", zIndex : 999999}}>{GetLabel(chartConfiguration.stackData)} : {multipleChartConfiguration}</p>
              {chartConfiguration.data ? <Chart
                  key={chartConfiguration.size}
                  chartType={chartConfiguration.type}
                  loader={<Spin tip='loading'/>}
                  data={chartConfiguration.target && (chartConfiguration.type === "BarChart" ||
                      chartConfiguration.type === "ColumnChart" ||
                      chartConfiguration.type === "LineChart" ||
                      chartConfiguration.type === "Line") ? addTargetSeries(data) : data}
                  options={chartConfiguration.target && (chartConfiguration.type === "BarChart" || chartConfiguration.type === "ColumnChart") ? addTargetSeriesOptions(options, data) :
                      chartConfiguration.options.isStacked && chartConfiguration.stacked_percentage ? addPercentageStack(options) :
                      chartConfiguration.type === "Gauge" ? gaugeChartOptions(data, chartConfiguration.options) : options}
                  width={(chartSize && chartSize[chartConfiguration.size]) ? `${chartSize[chartConfiguration.size][0]}rem` : '20rem'}
                  height={(chartSize && chartSize[chartConfiguration.size]) ? `${chartSize[chartConfiguration.size][1]}rem` : '20rem'}
                  legendToggle
                  style={{padding: "10px 20px 20px 20px", zIndex: -1}}
              /> : <EmptyChart>{emptyRecords}</EmptyChart>}
              </div>
          </Row>
        )
      }
    })

    return (
      <div style={{ overflow: editableReport ? 'scroll' : "hidden" }}>
        {editableReport && (
          <div>
            <Button
              type='link'
              size='small'
              onClick={
                () => editChart()
              }
            >
              Edit Chart
            </Button>
            <Button
              type='link'
              size='small'
              onClick=
              {
                () => deleteChart()
              }
            >
              Delete Chart
            </Button>
          </div>
        )}
        {showMultipleCharts}
      </div >

    )
  }

  const mapArray = (data, hasTarget) => {
    let lastItem = hasTarget ? data[0].length - 1 : data[0].length - 1; // 1 & 3
    let FIRST_ITEM = 0; // 4
  
    for (let index = lastItem; index > FIRST_ITEM; index--) { // 2
      for (let item = 0; item < data.length; item++) {
        let injectedValue = (item == FIRST_ITEM)  // 5
            ? {'role': 'annotation'}
            : data[item][index]; 
        
        data[item].splice(index + 1, 0, injectedValue);
      }
    }
    return data;
  }

  const insertTarget = (chartConfig) => {
    chartConfig.data.map((arr, index) => {
      if (index === 0) {
        arr.push("Target")
      } else {
        arr.push(parseFloat(chartConfig.target, 10))
      }
    })
    return chartConfig.data
  }

  let data = []
  if (chartConfiguration.showValues && (chartConfiguration.type === "BarChart"  || chartConfiguration.type === "ColumnChart")) {
    if (chartConfiguration.options && chartConfiguration.options.isStacked && chartConfiguration.stackData) {
      const hasTarget = chartConfiguration.data[0].includes('Target') || chartConfiguration.target ? true : false
      const targetIndex = chartConfiguration.data[0].indexOf('Target')
      const chartTitle = chartConfiguration.title ? chartConfiguration.title : ''
      const chartSize = chartConfiguration.size ? chartConfiguration.size : ''
      const stackedPercentage = chartConfiguration.stacked_percentage === true ? chartConfiguration.stacked_percentage : false
      const chartType = chartConfiguration.type
      if ((hasTarget !== stackedChartTarget.current) || (chartTitle !== stackedChartTitle.current) || (chartSize !== stackedChartSize.current) || (chartType !== chartTypeRef.current)) {
        stackedChartRan.current = false
        chartData.current = []
        if (stackedChartRan.current === false) {
          const d = hasTarget && targetIndex === -1 ? insertTarget({...chartConfiguration}) : chartConfiguration.data;
          const result = mapArray(d, hasTarget)
          data = result
          chartData.current = result
          stackedChartRan.current = true
          stackedChartTarget.current = hasTarget
          stackedChartTitle.current = chartConfiguration.title ? chartConfiguration.title : ''
          stackedChartSize.current = chartConfiguration.size ? chartConfiguration.size : ''
          // stackedPercentageRef.current = chartConfiguration.stacked_percentage ? chartConfiguration.stacked_percentage : false
          chartTypeRef.current = chartConfiguration.type
        }
      } else if (stackedPercentage) {
        data = chartData.current
      } else {
        if (chartData.current && chartData.current.length) {
          data = chartData.current
        } else {
          data = chartConfiguration.data
        }
      }
      // data = [
      //   ["Path","1",{"role": "annotation"}, "30", {"role": "annotation"}, "34", {"role": "annotation"}, "Target"],
      //   ["p1",22,22, 2, 2, 1,1, 4],
      //   ["p2",15,15, 5, 5, 7,7, 4],
      //   ["p3",11,11, 4, 4, 1,1, 4]
      // ]
    } else {
      data = chartConfiguration?.data?.map((item, index) => {
        if ((item.length >= 2) && (item.length % 2 === 0)) {
          if (index === 0) {
            item.push({ role: 'annotation' })
            return item
          } else {
            item.push(item[item.length - 1])
            return item
          }
        }
        return item
      })
    }
  } else {
    data = chartConfiguration.data
  }

  const chartSection = chartConfiguration && chartConfiguration.type && groups.length > 0 && chartConfiguration.multiple_charts && chartConfiguration.stackData
  && stackableChartTypes.includes(chartConfiguration.type) ? getMultipleCharts()
    : chartConfiguration && chartConfiguration.type && (
      <div style={{ overflow: editableReport ? 'scroll' : "hidden" }} >
        {editableReport && (
          <div>
            <Button
              type='link'
              size='small'
              onClick={
                () => editChart()
              }
            >
              Edit Chart
          </Button>
            <Button
              type='link'
              size='small'
              onClick=
              {
                () => deleteChart()
              }
            >
              Delete Chart
          </Button>
          </div>
        )}
        {chartConfiguration.data ? <Chart
          key={chartConfiguration.size}
          chartType={chartConfiguration.type}
          loader={<Spin tip='loading' />}
          data={chartConfiguration.target && (chartConfiguration.type === "BarChart" ||
            chartConfiguration.type === "ColumnChart" ||
            chartConfiguration.type === "LineChart" ||
            chartConfiguration.type === "Line") ? addTargetSeries(data, chartConfiguration) : data}
          options={chartConfiguration.target && (chartConfiguration.type === "BarChart" || chartConfiguration.type === "ColumnChart") ? addTargetSeriesOptions(chartConfiguration.options, data, chartConfiguration) :
            chartConfiguration.options.isStacked && chartConfiguration.stacked_percentage ? addPercentageStack(chartConfiguration.options) : chartConfiguration.options}
          width={(chartSize && chartSize[chartConfiguration.size]) ? `${chartSize[chartConfiguration.size][0]}rem` : '20rem'}
          height={(chartSize && chartSize[chartConfiguration.size]) ? `${chartSize[chartConfiguration.size][1]}rem` : '20rem'}
          legendToggle
          style={{ padding: "10px 20px 20px 20px" }}
        /> : <EmptyChart>{emptyRecords}</EmptyChart>}
      </div>
    )

  const handleTableChange = (pagination, filters, sorter) => {
    setSortationField(sorter.field)
    setSortationFieldType(sorter.order)
  };

  // useEffect(() => {
  //   const load = setTimeout(() => {
  //     stopLoading('render data display')
  //   }, 500);
  //   showLoad.current = load
  //   return () => {
  //     startLoading('render data display')
  //     clearTimeout(showLoad.current)
  //   }
  // }, [dataSource, matrixDataSource])

  return (
    <StyledRow>
      <Col>
        {chartSection}
        {
          (visualization === 'tabular') && (!hideDetails) &&
          (
            <TableStyle onDragOver={onDragOver} onDrop={onDrop} tight={tightTable}>
              {
                tableColumn && tableColumn.length <= 0 && editableReport && (
                  <DropRegion style={{
                    backgroundColor: 'rgba(209,217,246,0.9)',
                    paddingLeft: 51,
                    paddingTop: 16,
                    paddingBottom: 16
                  }}>
                    Drag a column here from the left panel
                  </DropRegion>
                )
              }
              <Table ref={tableWidth}
                columns={tableColumn}
                dataSource={modifiedSource}
                onChange={handleTableChange}
                pagination={false}
                scroll={{ x: 'max-content', y: editableReport ? (parseInt(panelHeight) - 400) : undefined }}
              />
            </TableStyle>
          )
        }
        {
          (visualization === 'summary') &&
          (
            <div className="summary-table">
              <Summarization
                columns={tableColumn}
                dataSource={modifiedSource}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onGroupingDrop={onGroupingDrop}
                uniqueValues={uniqueValues}
                groups={groups}
                hideDropZones={hideDropZones}
                setHideDropZones={setHideDropZones}
                hideDetails={hideDetails}
                tight={tightTable}
                removeGroup={removeGroup}
                editableReport={editableReport}
                groupedDate={groupedDate}
                setGroupedDate={setGroupedDate}
                containsGroupedItem={containsGroupedItem}
                dateGroupingOptions={dateGroupingOptions}
                getGroupType={getGroupType}
                isPrintReport={isPrintReport}
                setIsPrintReport={setIsPrintReport}
                setIsPrintReportLoading={setIsPrintReportLoading}
                reportName={reportName}
                headerRef={headerRef}
                cellRef={cellRef}
                firstColumnCenter={firstColumnCenter}
                lastHoveredColumn={lastHoveredColumn}
                setLastHoveredColumn={setLastHoveredColumn}
                setCurrentDropColumn={setCurrentDropColumn}
                setMouseCoord={setMouseCoord}
                setFirstColumnCenter={setFirstColumnCenter}
                classSection={classSection}
              />
            </div>
          )
        }
        {
          (visualization === 'matrix') &&
          (
            <Matrix
              edit={edit}
              dataSource={matrixDataSource}
              groups={groups}
              groupX={groupMatrixX}
              groupY={groupMatrixY}
              uniqueValues={uniqueValues}
              displaySource={!hideDetails}
              dropY={dropMatrixY}
              dropX={dropMatrixX}
              onDragOver={onDragOver}
              hideDropZone={hideDropZones}
              deleteGroupXItem={deleteMatrixXItem}
              deleteGroupYItem={deleteMatrixYItem}
              editableReport={editableReport}
              summaryRow={matrixOptions.field}
              summaryType={matrixOptions.option}
              onAxisDragStart={matrixDragStart}
              onAxisDragEnd={matrixDragEnd}
              setGroupedDate={setGroupedDate}
              groupedDate={groupedDate}
              containsGroupedItem={containsGroupedItem}
              dateGroupingOptions={dateGroupingOptions}
              getGroupType={getGroupType}
              isPrintReport={isPrintReport}
              setIsPrintReport={setIsPrintReport}
              setIsPrintReportLoading={setIsPrintReportLoading}
              reportName={reportName}
            />
          )
        }
        {
          (visualization === 'total') &&
          (
            <DataTotal
              style={{
                margin: '0 auto',
                fontSize: 30,
                fontWeight: 'bold',
                width: 'fit-content'
              }}
            >
              {dataSourceCount}
            </DataTotal>
          )
        }
        {
          (visualization === 'percent') &&
          (
            <DataTotal
              style={{
                margin: '0 auto',
                fontSize: 30,
                fontWeight: 'bold',
                width: 'fit-content'
              }}
            >
              {percentage && `${percentage}%`}
            </DataTotal>
          )
        }
        {
          (visualization !== 'total') && (visualization !== 'matrix') && (groups.length === 0 || visualization === 'tabular') && tableColumn.length !== 0 &&
          (
            <div style={{
              backgroundColor: 'grey',
              width: 'fit-content',
              color: 'white',
              paddingLeft: '1em',
              paddingRight: '1em',
              marginLeft: '1em'
            }}>
              Grand Total: ({dataSource.length} records){editableReport &&
                "- Partial result only displayed in edit mode, please run this report for full results."
              }
            </div>
          )
        }
      </Col>
    </StyledRow>
  )
}

DataDisplay = React.memo(DataDisplay)

export default DataDisplay