import React from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    Select,
    Input,
} from 'antd'
import {
    DeleteOutlined
} from '@ant-design/icons';
const { Option } = Select

export const FORM_TYPES = {
    TRIGGER_EMAIL: "trigger_emails",
    TRIGGER_CALLOUT: "trigger_callouts",
    TRIGGER_FIELD_UPDATE: "trigger_field_update"
}

export const TriggerEmail = (getFieldDecorator, triggerData, fieldsData = [], onSearchField, workFlowInitialData, parentTrigger, errors) => {

    return <div>
        <Col span="24">
            <Form.Item label="Description"
                validateStatus={errors['description'] ? 'error' : undefined}
                help={errors['description'] ? errors.description[0] : undefined}

            >
                {getFieldDecorator(`description`, {
                    initialValue: triggerData ? triggerData.description : undefined,
                    rules: [{ required: true, message: `Please input this field!` }]
                }
                )(
                    <Input />
                )}
            </Form.Item >
        </Col>
        <Col span="24">
            <Form.Item label="Recipient"
                validateStatus={errors['recipient'] ? 'error' : undefined}
                help={errors['recipient'] ? errors.endpoint[0] : undefined}
            >
                {getFieldDecorator(`recipient`, {
                    initialValue: triggerData ? triggerData.taggable_details.recipient_id : undefined,
                    rules: [{ required: true, message: `Please input this field!` }]
                }
                )(
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Enter Field (Click Suggestions to add field)"
                        optionFilterProp="children"
                        onSearch={(value) => onSearchField(value, false, triggerData ? triggerData.prefix : parentTrigger.prefix, 'email')}
                    >
                        {fieldsData.map(data =>
                            <Option value={data.id}>{data.label.replace(`${triggerData ? triggerData.prefix : parentTrigger.prefix}.`, '')}</Option>
                        )}
                    </Select>
                )}
            </Form.Item >
        </Col>
        <Col span="24">
            <Form.Item label="Template"
                validateStatus={errors['template'] ? 'error' : undefined}
                help={errors['template'] ? errors.endpoint[0] : undefined}
            >
                {getFieldDecorator(`template`, {
                    initialValue: triggerData ? triggerData.taggable_details.template : undefined,
                    rules: [{ required: true, message: `Please inputsss this field!` }]
                }
                )(
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        onSearch={onSearchField}
                    >
                        {workFlowInitialData.trigger_library.email_templates.map(data =>
                            <Option value={data.name_slug}>{data.name}</Option>
                        )}
                    </Select>
                )}
            </Form.Item >
        </Col>
    </div>
}
export const TriggerCallout = (getFieldDecorator, triggerData, getFieldValue, setFieldsValue, headersData, setHeadersData, errors) => {


    const removeHeaders = (index) => {
        const headers = getFieldValue('headers')
        setHeadersData(headersData.filter((data, key) => key !== index))
        setHeadersValue(headers.filter((item, key) => key !== index))
    }

    const setHeadersValue = (data) => {
        setFieldsValue({
            headers: data,
        });
    }

    const addNewHeader = () => {
        setHeadersData([...headersData, { key: undefined, value: undefined }])
    }

    return <div>

        <Col span="24">
            <Col span="24">
                <Form.Item label="Description"
                    validateStatus={errors['description'] ? 'error' : undefined}
                    help={errors['description'] ? errors.description[0] : undefined}

                >
                    {getFieldDecorator(`description`, {
                        initialValue: triggerData ? triggerData.description : undefined,
                        rules: [{ required: true, message: `Please input this field!` }]
                    }
                    )(
                        <Input />
                    )}
                </Form.Item >
            </Col>
            <Col span="24">
                <Form.Item label="Endpoint"
                    validateStatus={errors['endpoint'] ? 'error' : undefined}
                    help={errors['endpoint'] ? errors.endpoint[0] : undefined}

                >
                    {getFieldDecorator(`endpoint`, {
                        initialValue: triggerData ? triggerData.taggable_details.endpoint : undefined,
                        rules: [{ required: true, message: `Please input this field!` }]
                    }
                    )(
                        <Input />
                    )}
                </Form.Item >
            </Col>
            <Col span="24">
                <Form.Item label="No of retries"
                    validateStatus={errors['no_of_retries'] ? 'error' : undefined}
                    help={errors['no_of_retries'] ? errors.endpoint[0] : undefined}
                >
                    {getFieldDecorator(`no_of_retries`, {
                        initialValue: triggerData ? triggerData.taggable_details.no_of_retries : undefined,
                        rules: [{ required: true, message: `Please input this field!` }]
                    }
                    )(
                        <Input type="number" />
                    )}
                </Form.Item >
            </Col>
            <Col span="24" style={{ marginBottom: "400" }} >
                <Form.Item label="Retry Interval"
                    validateStatus={errors['retry_interval'] ? 'error' : undefined}
                    help={errors['retry_interval'] ? errors.endpoint[0] : undefined}
                >
                    {getFieldDecorator(`retry_interval`, {
                        initialValue: triggerData ? triggerData.taggable_details.retry_interval : undefined,
                        rules: [{ required: true, message: `Please input this field!` }]
                    }
                    )(
                        <Input type="number" />
                    )}
                </Form.Item >
            </Col>
            <p style={{ fontWeight: "bold", marginBottom: 30 }}>Headers</p>
            <div style={{ marginBottom: "150px" }} >
                {headersData.length > 0 ? headersData.map((data, key) => {
                    return (
                        <div>
                            <Row type="flex" align="middle" >
                                <Col span="11" style={{ padding: 5, marginTop: "-30px" }}>
                                    <Form.Item label="Key"
                                    >
                                        {getFieldDecorator(`headers[${key}].key`, {
                                            initialValue: data.key,
                                            rules: [{ required: true, message: `Please input this field!` }]
                                        }
                                        )(
                                            <Input />
                                        )}
                                    </Form.Item >
                                </Col>
                                <Col span="11" style={{ padding: 5, marginTop: "-30px" }}>
                                    <Form.Item label="Value"
                                    >
                                        {getFieldDecorator(`headers[${key}].value`, {
                                            initialValue: data.value,
                                            rules: [{ required: true, message: `Please input this field!` }]
                                        }
                                        )(
                                            <Input />
                                        )}
                                    </Form.Item >
                                </Col>
                                <Col span="2" style={{ padding: 5, marginTop: "-15px" }}>
                                    <Button type="danger" onClick={() => removeHeaders(key)}><DeleteOutlined /></Button>
                                </Col>
                            </Row>
                        </div>
                    )
                }) : <div style={{ paddingBottom: "20px" }}>Add HTTP headers to the call out</div>}
                <Col span={24}>
                    <p style={{ textAlign: "right" }}><Button type="primary" onClick={addNewHeader}>Add Headers</Button></p>
                </Col>
            </div>
        </Col>
    </div>

}

export const FieldUpdate = (getFieldDecorator, triggerData, fieldsData = [], onSearchField, workFlowInitialData, parentTrigger, errors) => {

    return <div>
        <Col span="24">
            <Form.Item label="Description"
                validateStatus={errors['description'] ? 'error' : undefined}
                help={errors['description'] ? errors.description[0] : undefined}

            >
                {getFieldDecorator(`description`, {
                    initialValue: triggerData ? triggerData.description : undefined,
                    rules: [{ required: true, message: `Please input this field!` }]
                }
                )(
                    <Input />
                )}
            </Form.Item >
        </Col>
        <Col span="24">
            <Form.Item label="Field"
                validateStatus={errors['field_id'] ? 'error' : undefined}
                help={errors['field_id'] ? errors.endpoint[0] : undefined}
            >
                {getFieldDecorator(`field_id`, {
                    initialValue: triggerData ? triggerData.taggable_details.field_id : undefined,
                    rules: [{ required: true, message: `Please input this field!` }]
                }
                )(
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Enter Field (Click Suggestions to add field)"
                        optionFilterProp="children"
                        onSearch={(value) => onSearchField(value, false, triggerData ? triggerData.prefix : parentTrigger.prefix)}
                    >
                        {fieldsData.map(data =>
                            <Option value={data.id}>{data.label.replace(`${triggerData ? triggerData.prefix : parentTrigger.prefix}.`, '')}</Option>
                        )}
                    </Select>
                )}
            </Form.Item >
        </Col>
        <Col span="24">
            <Form.Item label="Field Value"
                validateStatus={errors['field_value'] ? 'error' : undefined}
                help={errors['field_value'] ? errors.value[0] : undefined}

            >
                {getFieldDecorator(`field_value`, {
                    initialValue: triggerData && triggerData.taggable_details ? triggerData.taggable_details.field_value : undefined,
                    rules: [{ required: true, message: `Please input this field!` }]
                }
                )(
                    <Input />
                )}
            </Form.Item >
        </Col>

    </div>
}