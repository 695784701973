import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {  color } from '../../shared/styles';

export const LabelTypes = {
  general: 'general',
  date: 'date',
  dashboard: 'dashboard',
};

const statusColors = {
  'blue': color.blue,
  'mountain_meadow': color.mountain_meadow,
  'red': color.red,
  'purple': color.purple,
  'buttercup': color.buttercup
}

export const LabelStatus = {
  general: {
    info: 'blue',
    success: 'mountain_meadow',
    warning: 'red'
  },
  date: {
    later: 'purple',
    tomorrow: 'mountain_meadow',
    today: 'blue',
    overdue: 'red'
  },
  dashboard: {
    tomorrow: 'mountain_meadow',
    today: 'blue',
    overdue: 'red',
    vip: 'buttercup',
    target: 'purple',
  }
};

const LabelTypography = {
  general: '13px',
  date: '11px',
  dashboard: '11px',
}

const LabelCorner = {
  general: '6px',
  date: '4px',
  dashboard: '4px',
}

const StyledDiv = styled.div`
  display: inline-block;
  margin-right: 8px;

  border-radius: ${props => props.type && LabelCorner[props.type]};

  ${props => (props.status && statusColors[props.status]) && `
    background-color: ${statusColors[props.status].light};
    color: ${statusColors[props.status].normal};
  `}
  padding: 6px 22px;
  width: fit-content;
  min-width: 109px;
  text-transform: uppercase;
  text-align: center;
  font-size: ${props => props.type && LabelTypography[props.type]};
  ${props => props.stylings}
`;

const applyStyle = LabelWrapper => {
  return (
    LabelWrapper &&
    StyledDiv.withComponent(({ ...rest }) => (
      <LabelWrapper {...rest} />
    ))
  );
};
/**
* 
* - 
*/

/**
 * Use labels to display short but important information that should catch the users eye.
 * It can have 2 purposes:
 *
 * - *LabelTypes* and *LabelStatus* constructs are used to populate the *type* and *status* props respectively.
 */
export function Label({
  status,
  type,
  children,
  LabelWrapper,
  ...props
}) {
  const StyledDivWrapper = React.useMemo(() => applyStyle(LabelWrapper), [LabelWrapper]);

  let SelectedLabel = StyledDiv;
  if (LabelWrapper) {
    SelectedLabel = StyledDivWrapper;
  }

  return (
    <SelectedLabel status = {status} type = {type} {...props}>
      {children}
    </SelectedLabel>
  );
}

Label.propTypes = {
  /**
   * poopulate using LabelStatus to enforce uniformity
   */
  status: PropTypes.oneOf(['mountain_meadow', 'blue', 'red', 'buttercup', 'purple']).isRequired,
  /**
   * poopulate using LabelType to enforce uniformity
   */
  type: PropTypes.oneOf([LabelTypes.dashboard, LabelTypes.date, LabelTypes.general]).isRequired
};

Label.defaultProps = {
  status: LabelStatus.general.info,
  type: LabelTypes.general
};
