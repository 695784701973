import React, {useEffect, useState} from 'react';
import {Modal, Button, Table, Row, Pagination, notification, Select} from 'antd';
import moment from 'moment';
import { Label } from 'components/zensmart-design-system';
import { ZenSmartAPI } from 'utils';
import * as _ from "lodash";
const ReorderTransaction = (props) => {
    const { visible, onClose, transaction, customer, orders, shippingAddress, vendor, canEdit } = props;
    const modalRef = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [orderDetails, setOrderDetails] = useState(orders);
    const styles = {
        panelStyle: {
            border: '1px solid #DADADA',
            backgroundColor: '#FFFFFF',
            borderRadius: '5px',
            marginBottom: '20px',
        },
        panelHeader: {
            backgroundColor: '#FAFAFA',
            color: '#333333',
            padding: '10px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottom: '1px solid #DADADA',
        },
        divider: {
            border: 'none',
            borderTop: '1px solid #ccc',
            margin: '10px 0',
        },
        panelContent: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#FFFFFF',
        },
        column: {
            width: '49%',
        },
        table: {
            border: '1px solid #DADADA',
            borderRadius: '5px',
            margin: '10px',
        },
        formRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px',
            borderBottom: '1px solid #ccc',
        },
        formRowContent: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        spanMargin: {
            margin: '10px',
        },
        inputEnabled: {
            backgroundColor: 'white', // Set your desired background color
        },
        inputDisabled: {
            backgroundColor: '#DADADA', // Set your desired background color
        },
        inputStyled: {
            textAlign: 'center',
            border: '1px solid #DADADA',
            borderRadius: '5px',
        },
        unitQuantityContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        permissionText: {
            color: 'red',
            fontStyle: 'italic',
            marginLeft: '10px',
        },
        btnSetQuantity: {
            padding: '8px 10px',
            background: 'white',
            color: 'rgba(0, 0, 0, 0.65)',
            border: '1px solid #d9d9d9',
            borderRadius: '5px',
            marginTop: '10px',
            marginRight: '10px',
        },
    };

    const handleChange = (orderId, value, field) => {
        let newOrders = orderDetails.map((order) => {
            if (order.id === orderId) {
                return { ...order, [field] : value };
            }
            return order;
        });
        setOrderDetails(newOrders)
    };

    const handleReasonChange = (orderId, value) => {
        let newOrders = orderDetails.map((order) => {
            if (order.id === orderId) {
                order.order_faults = order.order_faults.map(fault => {
                    if (fault.active) {
                        return { ...fault, active: false };
                    }
                    return fault; // No update needed for faults with active === false
                });
                order.order_faults = order.order_faults.map(fault => {
                    if (fault.id === value) {
                        return { ...fault, active: true };
                    }
                    return fault; // No update needed for faults with active === false
                });
                return order;
            }
            return order;
        });
        setOrderDetails(newOrders)
    };

    const handleTransactionChange = (orders, value) => {
        let newOrders = orders.map((order) => {
            order.order_faults = order.order_faults.map(fault => {
                if (fault.active) {
                    return { ...fault, active: false };
                }
                return fault; // No update needed for faults with active === false
            });
            order.order_faults = order.order_faults.map(fault => {
                if (fault.id === value) {
                    return { ...fault, active: true };
                }
                return fault; // No update needed for faults with active === false
            });
            return order;
        }, {});
        setOrderDetails(newOrders)
    };

    const handleSetAllToZero = () => {
        let newOrders = orderDetails.map((order) => {
            return { ...order, 'unit_quantity' : 0 };
        }, {});
        setOrderDetails(newOrders)
    };

    const overWriteOrderFaults = () => {
        let newDetails =  orderDetails.map((order) => {
            let fauld_id = order.order_faults.filter(fault => fault.active === true)[0].id
            return { ...order, 'order_fault_id' : fauld_id };
        }, {});

        return newDetails.map(order => {
            const { lamination_code ,number,order_faults,paper_code,product_id,project_id,product_code,project_name, ...rest } = order;
            return rest;
        });
    };

    const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);

        const transactionId = transaction.id;
        ZenSmartAPI.post(`/api/v1/transactions/${transactionId}/reorder`, overWriteOrderFaults(), {
            timeout: 50000,
        })
            .then(response => {
                setLoading(false);
                response = response.data;
                notification.success({
                    message: 'Transaction Re-ordered Successfully',
                    description: 'New transaction created - ' + response.data.client_number,
                });

                onClose();

                setTimeout(() => {
                    window.location.replace(`/app/${response.data.url}`);
                }, 500);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
                notification.error({
                    message: _.get(error, 'response.data.message', 'Something went wrong!'),
                });
            });
    };
    return (
        <>
            <Modal
                visible={visible}
                ref={modalRef}
                destroyOnClose={true}
                forceRender={true}
                onCancel={onClose}
                width={1200}
                footer={[
                    <p style={{ textAlign: 'right' }}>
                        <Button style={{ marginRight: 10 }}
                                type="primary"
                                htmlType="submit"
                                loading={loading ? true : undefined}
                                onClick={handleSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            type="danger"
                            key="close"
                            onClick={event => {
                                onClose();
                            }}>
                            Cancel
                        </Button>
                    </p>
                ]}
            >
                <Row type="flex" justify="center" style={{ padding: '10 20', marginBottom: '20px' }}>
                    <Label status="blue">RE-ORDER TRANSACTION - {transaction.client_number}</Label>
                </Row>
                <div className="ml-2 mr-2 mx-auto">
                    {customer && (
                        <>
                            <div style={styles.panelStyle}>
                                <div style={styles.panelHeader}>
                                    CUSTOMER INFORMATION
                                </div>
                                <div style={styles.panelContent}>
                                    <div style={styles.column}>
                                        <p><strong>First Name:</strong> {customer.first_name}</p>
                                        <p><strong>Email:</strong> {customer.email}</p>
                                        <p><strong>Vendor Id:</strong> {vendor.name}</p>
                                    </div>
                                    <div style={styles.column}>
                                        <p><strong>Last Name:</strong> {customer.last_name}</p>
                                        <p><strong>Phone Number:</strong> {customer.phone_number}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="ml-2 mr-2 mx-auto">
                    {shippingAddress && (
                        <>
                            <div style={styles.panelStyle}>
                                <div style={styles.panelHeader}>
                                    SHIPPING ADDRESS
                                </div>
                                <div style={styles.panelContent}>
                                    <div style={styles.column}>
                                        <p><strong>Name:</strong> {shippingAddress.name}</p>
                                        <p><strong>Address 1:</strong> {shippingAddress.street1 ? shippingAddress.street1 : '-'}</p>
                                        <p><strong>Address 3:</strong> {shippingAddress.street3 ? shippingAddress.street3 : '-'}</p>
                                        <p><strong>State:</strong> {shippingAddress.state ? shippingAddress.state : '-'}</p>
                                        <p><strong>City:</strong> {shippingAddress.city ? shippingAddress.city : '-'}</p>
                                        <p><strong>Phone:</strong> {shippingAddress.phone ? shippingAddress.phone : '-'}</p>
                                    </div>
                                    <div style={styles.column}>
                                        <p><strong>Company Name:</strong> {shippingAddress.business_name ? shippingAddress.business_name : '-'}</p>
                                        <p><strong>Address 2:</strong> {shippingAddress.street2 ? shippingAddress.street2 : '-'}</p>
                                        <p><strong>Address 4:</strong> {shippingAddress.street4 ? shippingAddress.street4 : '-'}</p>
                                        <p><strong>Country:</strong> {shippingAddress.country ? shippingAddress.country : '-'}</p>
                                        <p><strong>Postcode:</strong> {shippingAddress.postcode ? shippingAddress.postcode : '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="ml-2 mr-2 mx-auto">
                    <div style={styles.panelStyle}>
                        <div style={styles.panelHeader}>
                            Transaction Details
                        </div>
                        <div style={styles.panelContent}>
                            <span style={styles.spanMargin}><strong>Transaction Fault Reason: </strong>
                                <Select
                                    defaultValue={orderDetails[0].order_faults.filter(fault => fault.active === true)[0].id}
                                    filterOption={true}
                                    onChange={event =>
                                        handleTransactionChange(orderDetails, event)
                                    }
                                    placeholder="Select Fault Reason"
                                >
                                    {orderDetails[0].order_faults.map(orderFault => orderFault ? (
                                        <Select.Option value={orderFault.id} key={orderFault.id}>
                                            {orderFault.name}
                                        </Select.Option>
                                    ) : null)}
                                </Select>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ml-2 mr-2 mx-auto">
                    <div style={styles.panelStyle}>
                        <div style={styles.panelHeader}>
                            How many of each of these original orders do you want to reorder?
                        </div>
                        {canEdit && (
                        <div style={{ textAlign: 'right' }}>
                            <button style={styles.btnSetQuantity}
                                    disabled={!canEdit} onClick={handleSetAllToZero}>Set All Order Quantities to 0</button>
                        </div> )}
                        <div style={styles.table}>
                            {orderDetails.map(order => (
                                <div key={order.id} style={styles.formRow}>
                                    <div style={styles.formRowContent}>
                                        <span style={styles.spanMargin}><strong>Order Number:</strong> {order.number}</span>
                                        <span style={styles.spanMargin}><strong>Product Code:</strong> {order.product_code}</span>
                                        <span style={styles.spanMargin}><strong>Paper Code:</strong> {order.paper_code}</span>
                                        <span style={styles.spanMargin}><strong>Order Fault Reason:</strong> {order.number}</span>
                                        <span style={styles.spanMargin}><strong>Fault Reason: </strong>
                                        <Select
                                            filterOption={true}
                                            value={order.order_faults.filter(fault => fault.active === true)[0].id}
                                            onChange={event =>
                                                handleReasonChange(order.id, event,'order_faults')
                                            }
                                        >
                                            {order.order_faults.map(orderFault => orderFault ? (
                                                <Select.Option value={orderFault.id} key={orderFault.id} active={orderFault.active}>
                                                    {orderFault.name}
                                                </Select.Option>
                                            ) : null)}
                                        </Select>
                                        </span>
                                        <span style={styles.spanMargin}><strong>Unit Quantity: </strong>
                                        <input
                                            type="number"
                                            value={order.unit_quantity}
                                            onChange={event =>
                                                handleChange(order.id, event.target.value,'unit_quantity')
                                            }
                                            disabled={!canEdit}
                                            style={{...(canEdit ? styles.inputEnabled : styles.inputDisabled), ...styles.inputStyled}}
                                        />
                                        </span>
                                        <span style={styles.spanMargin}><strong>Fault Comment: </strong>
                                        <input
                                            type='text'
                                            value={order.order_fault_comment}
                                            onChange={event =>
                                                handleChange(order.id, event.target.value,'order_fault_comment')
                                            }
                                            disabled={!canEdit}
                                            style={{...(canEdit ? styles.inputEnabled : styles.inputDisabled), ...styles.inputStyled}}
                                        />
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            {!canEdit && (
                                <span style={styles.permissionText}>
                                You do not have permission to edit order quantity
                            </span>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default ReorderTransaction;