import { useLayoutEffect, useState } from "react";

// This hook gives you the current screen width
// const screenWidth = useScreenWidth();
function useScreenWidth() {
  const [size, setSize] = useState();
  const updateSize = () => setSize(window.innerWidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
}

export { useScreenWidth };
