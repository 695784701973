import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";

function PermissionRoute(props) {
    const { component, permission, path } = props;
    const userData = useSelector((state) => state.user.details);
    if (userData && userData.should_authorize && permission && !userData.permissions.find(p => p === permission)) {
        return null 
    }

    return <Route path={path} component={component} />
}

export default PermissionRoute;