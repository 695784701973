import React from 'react';
import styled from 'styled-components';
import { InputSection } from '../composites';
import { Modal, Input, Select } from 'antd';
import { styles } from '../../../../components/zensmart-design-system';
import { GetLabel } from '../../utils';
import { alphabeticalData } from 'utils/sortData';

const Option = Select.Option;
const color = styles.color;

const ModalStyle = styled.div`
button:focus {
  outline: none;
}

.divider {
  margin-bottom: 25px;
  margin-top: 20px;
}

#instructions {
  font-size: 12px;
  margin-bottom: 10px;
}

.top_section {
  margin-bottom: 15px;
  display: flex;

  .select, .input {
    width: 100%;
  }

  .input_section {
    width: 30%;
    margin-right: 20px;
  }

  .ant-select-selection {
    border: 0.8px solid ${color.blue.normal};
    svg {
      color: ${color.blue.normal};
    }
    .ant-select-selection-selected-value {
      color: ${color.blue.normal};
    }
  }

  .ant-input {
    border-color: ${color.blue.normal};
  }
}
`

const BucketModal = ({
  children,
  columns = [],
  visible,
  handleOK,
  handleCancel,
  bucketName,
  bucketColumnField,
  setBucketName,
  setBucketColumn
}) => {

  return (
    <Modal
      title='Edit Bucket Field'
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOK}
      width={700}
    >
      <ModalStyle>
        <p id='instructions'>Use bucket fields to group, filter or arrange report data. Create multiple buckets in this bucket field to group your report records.</p>
        <div className='top_section'>
          <InputSection label='Source Column' position='top' className='input_section'>
            <Select
              onSelect={e => setBucketColumn && setBucketColumn(alphabeticalData(Object.values(columns), "table", true, "field")[e])}
              size='default'
              className='select'
              value={bucketColumnField ? bucketColumnField : ''}
              optionFilterProp="children"
              showSearch
            >
              {
                alphabeticalData(Object.values(columns), "table", true, "field").map((columnKey, key) => (
                  <Option key={key} value={key} style={{ textTransform: "capitalize" }}>
                    {columnKey.table} {GetLabel(`${columnKey.table}.${columnKey.field}`)}
                  </Option>
                ))
              }
            </Select>
          </InputSection>
          <InputSection label='Bucket Field Name' position='top' className='input_section'>
            <Input
              size='default'
              className='input'
              value={bucketName ? bucketName : ''}
              onChange={e => setBucketName && setBucketName(e.target.value)}
            />
          </InputSection>
        </div>
        <hr className='divider' />
        {children}
      </ModalStyle>
    </Modal>
  )
}

export default BucketModal