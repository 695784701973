import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { getDepartmentWipSummary } from "utils/apiRoutes";
import {ZenSmartAPI} from 'utils';
import moment from 'moment'
import Loading from 'modules/common/Loading';
import { notification, Spin, Button, DatePicker } from 'antd';
import WipSummaryModal from './WipSummaryModal';

const imposition_daily_colors = {
    1: 'rgb(255,182,193)',
    2: 'rgb(147,112,219)',
    3: 'rgb(144,238,144)',
    4: 'rgb(192, 192, 192)',
    5: 'rgb(173,216,230)',
    6: 'rgb(255, 255, 255)',
    7: 'rgb(255, 255, 255)',
}

const DepartmentWipSummary = createGlobalStyle`
    .heading-text {
        font-size: 40px;
        font-weight: bold;
        text-transform: uppercase;
        color: black;

        &.completed-today {
            font-size: 35px;
            cursor: pointer;
            padding-top: 25px;
        }
    }
    
    .due-date {
      cursor: pointer;
    }
  
    .counts-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    
    .due-container {
        width: 80%;
        display: flex;
        color: black;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 15%;
        .due-count {
            font-size: 150px;
            font-weight: bold;
            text-align: left;
            cursor: pointer;
            height: 190px;
        }
        .enroute-count {
            font-size: 50px;
            font-weight: bold;
            text-align: center;
            cursor: pointer;
        }
    }
    
    .overdue-container {
      width: 20%;
      display: flex;
      flex-direction: column;
      color: red;
      align-items: center;
      .overdue-count {
        font-size: 40px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .main-content {
        background: ${props => props.dayOfWeek ? imposition_daily_colors[props.dayOfWeek] : 'rgb(255, 255, 255)'};
    }

    .wip-summary-header {
        display: flex;
        justify-content: space-between;

        & .wip-summary-nav-buttons {
            > p {
                font-weight: bold;
                color: black;
            }
        }
    }

    .ant-spin.completed-today-loading {
        position: fixed !important;
        padding: 13px;
    }
    .ant-btn-group .ant-btn {
        border-radius: 0;
        min-width: 100px;
    }  
`

const DepartmentWipSummaries = (props) => {
    const department_id = useParams().deptID;
    const [inStageToday, setInStageTodayCount] = useState(0);
    const [enrouteToday, setEnrouteTodayCount] = useState(0);
    const [completedTodayCount, setCompletedTodayCount] = useState(0);
    const [inStageCount, setInStageCount] = useState(0);
    const [enrouteCount, setEnrouteCount] = useState(0);
    const [overdueCount, setOverdueCount] = useState(0);
    const [overdueToday, setOverdueCountToday] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [departmentName, setDepartmentName] = useState("");
    const [dailyTarget, setDailyTarget] = useState("");
    const [dayOfWeek, setDayOfWeek] = useState(moment().day());
    const [department, setDepartment] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [isMetaLoading, setIsMetaLoading] = useState(true);
    const [isModalVisible, setModalVisible] = useState(false);
    const [stage,setStage] = useState('')
    const [stageDate,setStageDate] = useState('')
    const [refreshCounter, setRefreshCounter] = useState(null);
    const [today,setToday]=useState(moment())
    const [dueDate, setDueDate] = useState({
        is_today: false,
        due_date:null
    });

    const [params,setParams]=useState({
        due_date:today.format('YYYY-MM-DD')
    })

    const handleDateChange = (value, dateString) => {
        const newDate = moment(dateString).format('YYYY-MM-DD');
        setToday(moment());
        setParams({ due_date: newDate });

        const isToday = moment(newDate).isSame(moment(), 'day');
        setDueDate({
            is_today: isToday,
            due_date: newDate
        });
        setData({ due_date: newDate });
    }

    //this calls the filter from Api
    const setData = async (params = {}) => {
        setLoading(true);
        ZenSmartAPI(getDepartmentWipSummary(department_id), {
            params
        }).then(res => {
            const {department, is_today, due_date, in_stages, enroute_stages, out_stages, overdue_count, enroute_today, instage_today, outstage_today, overdue_today} = res.data.data;
            setInStageTodayCount(instage_today);
            setEnrouteTodayCount(enroute_today);
            setInStageCount(in_stages);
            setEnrouteCount(enroute_stages);
            setOverdueCount(overdue_count);
            setOverdueCountToday(overdue_today);
            setCompletedCount(out_stages);
            setDepartment(department);
            setDayOfWeek(moment(due_date).day());
            setDueDate({
                is_today: is_today,
                due_date: due_date
            });
            setIsMetaLoading(true);
            setCompletedTodayCount(outstage_today);
            setDailyTarget(department.daily_target);
       
        }).catch(err => {
            notification.error({
                message: "Error loading Department WIP Summary."
            })
        }).finally(() => {
            setLoading(false);
            setIsMetaLoading(false);

        })
    }

    const openModal = (isOpen, stage, stageDate) => {
        setStage(stage)
        setStageDate(stageDate);
        setModalVisible(isOpen)
    }

    const closeModal = () => {
        setModalVisible(false);
    }

    useEffect(()=>{
        const deptName = JSON.parse(localStorage.getItem('department'))
        const getDeptName = deptName.find((dept) => dept.id === department_id)
        if (getDeptName) {
          const name = getDeptName.name;
          setDepartmentName(name) 
        } 
    },[])

    useEffect(() => {
        const fetchDepartmentName = async () => {
        setDepartmentName("Loading...")
        setLoading(true);
          try {
            const res = await ZenSmartAPI(getDepartmentWipSummary(department_id), {
                due_date:today.format('YYYY-MM-DD')
            });
            const { department, is_today, due_date, in_stages, enroute_stages, out_stages, enroute_today, instage_today, outstage_today, overdue_count, overdue_today } = res.data.data;
            setInStageTodayCount(instage_today);
            setEnrouteTodayCount(enroute_today);
            setInStageCount(in_stages);
            setEnrouteCount(enroute_stages);
            setOverdueCount(overdue_count);
            setOverdueCountToday(overdue_today);
            setCompletedCount(out_stages);
            setDepartment(department);
            setDepartmentName(department.name);
            setDailyTarget(department.daily_target);
            setDayOfWeek(moment(due_date).day());
            setDueDate({
                is_today: is_today,
                due_date: due_date
            });
            setIsMetaLoading(true);
            setCompletedTodayCount(outstage_today);
            setToday(moment())
          } catch (error) {
            notification.error({
              message: "Error loading Department WIP Summary."
            });
          } finally {
            setLoading(false);
            setIsMetaLoading(false);
          }
        };
      
        fetchDepartmentName();
      }, [department_id]);
      

    return (
        <div className='department-wip-summary-container'>
            <DepartmentWipSummary dayOfWeek={dayOfWeek}/>
            <Loading loading={isLoading} full={true}></Loading>
            <div className='wip-summary-header'>
                <div className='wip-summary-title'>
                    <p className='header heading-text'>{departmentName ? departmentName : 'Loading...'}</p>
                    <p className='due-date heading-text'>DUE {dueDate.is_today && 'today'}  {dueDate.due_date == null ? '' : moment(dueDate.due_date).format("MM/DD")}</p>
                    {
                        !dueDate.is_today && moment(dueDate.due_date).isBefore(moment(), 'day') ? (
                            <>
                                <p className='due-date heading-text' onClick={() => {openModal(true,'outstage', dueDate.due_date)}}>COMPLETED ON {dueDate.due_date == null? '' : moment(dueDate.due_date).format("MM/DD")}: {completedCount}</p>
                                <p className='due-date heading-text' onClick={() => {openModal(true,'enroutestage', dueDate.due_date)}}>ENROUTE ON {dueDate.due_date == null? '' : moment(dueDate.due_date).format("MM/DD")}: {enrouteCount}</p>
                            </>
                        ) : null
                    }
                </div>
                <div className='wip-summary-nav-buttons'>
                    <p>Date Navigation:</p>
                    <Button.Group>
                        <DatePicker style={{ marginTop: 10, width: '100%' }}
                                    onChange={handleDateChange}
                                    defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                        />
                    </Button.Group>
                </div>
            </div>
            <div className='counts-container'>
                <div className='due-container'>
                    {/* setting openModal to false for now */}
                    <span className='due-count' onClick={() => {openModal(true,'instage', moment(dueDate.due_date).format('YYYY-MM-DD'))}}>{inStageCount}</span>
                    <span className='enroute-count' onClick={() => {openModal(true,'enroutestage', moment(dueDate.due_date).format('YYYY-MM-DD'))}}>ENROUTE: {enrouteCount}</span>
                </div>
                <div className='overdue-container' onClick={() => {openModal(true,'overduestage', moment(dueDate.due_date).format('YYYY-MM-DD'))}}>
                    <span className='overdue-count'>OVERDUE</span>
                    <span className='overdue-count'>{overdueCount}</span>
                </div>
            </div>
        <p className='completed-today heading-text'>completed today: {isMetaLoading && !isLoading ? <Spin className='completed-today-loading' size='small'/> :<span onClick={() => {openModal(true,'outstage', moment().format('YYYY-MM-DD'))}}>{completedTodayCount}/{dailyTarget ? dailyTarget : "-"}</span>}</p>
            <WipSummaryModal visible={isModalVisible} stage={stage} closeModal={closeModal}
                is_today={dueDate.is_today} due_date={stageDate} department={department} />
        </div>
    )
}

export default DepartmentWipSummaries;
