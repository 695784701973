import axios from 'axios';

const LoadTypes = async (columns = []) => {
  if (columns.length > 0) {
    let res = await axios.post(`${process.env.REACT_APP_ANALYTICS_SERVER}/field_types`, {columns});
    let { data } = res;
    return data;
  }
}

export default LoadTypes