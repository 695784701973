import React, { useEffect, useState } from 'react';
import { Header, Title } from 'components/primaries';
import { ZenSmartAPI } from 'utils'
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'
import AccessoriesUpdateModal from './AccessoriesUpdateModal';
import { getSingleAccessoryRoute } from "utils/apiRoutes"
import { notification, Modal } from 'antd';

const Accessories = () => {
    const [fetchParams, setFetchParams] = useState({})
    const [fetchData, setFetchData] = useState({ refresh_data: null })
    const [updateAccessoriesModal, setUpdateAccessoriesModal] = useState(false)
    const [formInitialValue, setFormInitialValue] = useState({})
    const [accessoryModalName, setAccessoryModalName] = useState("")
    const addAccessories = (row, refreshData) => {
        setUpdateAccessoriesModal(true)
        setAccessoryModalName("Add New Accessory")
    }
    const editAccessory = (row, refreshData) => {
        ZenSmartAPI.get(getSingleAccessoryRoute(row.code))
            .then((res) => {
                setUpdateAccessoriesModal(true)
                setFormInitialValue(res.data)
                setAccessoryModalName(`Edit Accessory ${res.data.name}`)
            })
            .catch((res) => {
                if (res.response.status === 422) {
                    notification.error({ message: "Accessory code already exist!" })
                }
                else {

                    notification.error({ message: "Error saving accessory!" })
                }

            })
    }

    const deleteAccessory = (row, refreshData) => {
        Modal.confirm({
            'content': "Are you sure you want to delete this accessory?",
            onOk: () => {
                ZenSmartAPI.delete(getSingleAccessoryRoute(row.id))
                    .then((res) => {
                        fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                        notification.success({ message: "Delete accessory successful!" })
                    })
                    .catch((res) => {
                        console.log('res', res);
                        if(res.response.data)
                        {
                            notification.error({ message: res.response.data.message })
                        }
                    })
            },
        });

    }
    return (
        <>
            <Header>
                <Title>Prep {">"} Accessories</Title>
            </Header>
            <AccessoriesUpdateModal
                updateAccessoriesModal={updateAccessoriesModal}
                setUpdateAccessoriesModal={setUpdateAccessoriesModal}
                fetchData={fetchData}
                fetchParams={fetchParams}
                formInitialValue={formInitialValue}
                setFormInitialValue={setFormInitialValue}
                accessoryModalName={accessoryModalName}
                setAccessoryModalName={setAccessoryModalName}
            />
            <Panel title="ACCESSORIES">
                <DataListPage model="accessories" select={[]} hidden={["id"]} link={""} pageSize={50}
                    customizedButton={
                        [{
                            buttonLabel: "Add New Accessory",
                            buttonType: "primary",
                            buttonAction: "normal",
                            actionFunction: addAccessories,
                            offset: 15,
                            span: 3
                        }
                        ]
                    }
                    setOutSourceFetchData={setFetchData}
                    setOutSourceParameters={setFetchParams}
                    actionButtons={
                        [
                            {
                                buttonLabel: "Edit",
                                buttonType: "primary",
                                buttonAction: "normal",
                                actionFunction: editAccessory,
                                reloadData: true
                            },
                            {
                                buttonLabel: "Delete",
                                buttonType: "danger",
                                buttonAction: "normal",
                                actionFunction: deleteAccessory,
                                reloadData: true
                            },
                        ]
                    }
                />

            </Panel>
        </>
    );
};

export default Accessories;