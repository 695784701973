import axios from 'axios';

const GetMatrixData = async (requestData = {
  table: '',
  secondary_filter: [],
  primary_filter: [],
  fields: [],
  groupX: [],
  groupY: [],
  grouped_dates: [],
  bucket_fields: [],
  limit: 50
}) => {
  if (requestData.table) {
    let res = await axios.post(`${process.env.REACT_APP_ANALYTICS_SERVER}/matrix_data`, requestData)
    let { data } = res;
    return data;
  }
}

export default GetMatrixData