import React, {useEffect, useRef, useState} from 'react';
import {Button, notification} from 'antd';
import * as _ from 'lodash';

import {Header, Panel, Title} from '../../../components/primaries';
import Search from './Components/Search';
import ItemList from './Components/ItemList';
import CloseShipmentForm from './CloseShipmentForm';
import ZenSmartAPI from '../../../utils/ZenSmartAPI';

const Dropship = () => {

    const [logisticsProviders, setLogisticsProviders] = useState([]);
    const [defaultLogisticsProvider, setDefaultLogisticsProvider] = useState(null);
    const [destinations, setDestinations] = useState([]);
    const [items, setItems] = useState([]);
    const searchRef = useRef(null);
    const [aggregates, setAggregates] = useState({
        value: 0,
        weight: 0,
    });
    const [shouldOpenCreateForm, setShouldOpenCreateForm] = useState(false);

    const handleItemFetch = (item) => {

        let itemtDestination = _.find(destinations, {destination_code: item.destination_code});
                        
        if(!itemtDestination) {
            notification.error({
                message: `Dropship destination is not define for ${item.destination_code}`
            })
            return;
        }

        if(items.length > 0 && items[0].destination_code !== item.destination_code) {
            notification.error({
                message: 'Destination of scanned item does not match with the first item',
            });
            return;
        }

        if (_.find(items, {type: item.type, id: item.id})) {
            notification.error({
                message: 'You have already scanned this item',
            });
            return;
        }


        setItems([...items, item]);
    };

    const handleCloseCreateForm = () => {
        setShouldOpenCreateForm(!shouldOpenCreateForm);
        searchRef.current.focus();
    };

    useEffect(() => {
        ZenSmartAPI.get('/api/v1/ui/init-data/dropship').then(response => {
            response = response.data;
            setDestinations(response.data.dropship_destinations);
            setLogisticsProviders(response.data.logistics_providers);
            setDefaultLogisticsProvider(response.data.default_logistics_provider)
        });
    }, []);

    useEffect(() => {
        let weight = 0;
        let value = 0;

        items.forEach(item => {
            weight += parseFloat(item.weight);
            value += parseFloat(item.value);
        });

        setAggregates({
            weight, value,
        });

    }, [items]);

    return (
        <>
            <Header>
                <Title>Pack & Ship {'>'} Dropship</Title>
            </Header>
            <Panel title="DROPSHIP">
                {/* Search */}
                <div className="flex m-3">
                    <Search ref={searchRef} onItemFetch={handleItemFetch}/>
                    <Button disabled={items.length === 0} onClick={e => {
                        setShouldOpenCreateForm(!shouldOpenCreateForm);
                        searchRef.current.input.setValue('');
                    }} className="ml-4" type="primary">Close
                        shipment</Button>
                </div>

                {/*  Item list  */}
                <div className="m-3 mt-8">
                    <ItemList
                        onRemoveItem={item => setItems(
                            items.filter(i => i.id !== item.id))}
                        destinations={destinations}
                        items={items}
                        aggregates={aggregates}/>
                </div>
            </Panel>

            <CloseShipmentForm
                logisticsProviders={logisticsProviders}
                defaultLogisticsProvider={defaultLogisticsProvider}
                open={shouldOpenCreateForm}
                onSuccess={() => {
                    setItems([])
                    handleCloseCreateForm();
                }}
                onClose={handleCloseCreateForm}
                items={items}
            />
        </>
    );
};

export default Dropship;