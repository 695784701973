import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import _ from 'lodash';
import { Widget } from 'pages/Performance/Widget';
import { Header, Title } from 'components/primaries';
import { GetWaitingForPartners, GetWaitingForPartnersByField } from './api';
import { GetReport, GetUniqueValues } from 'pages/Performance/api';
import { CheckTypeAndConvert, DataTypes } from 'pages/Performance/utils';
import { ZenSmartAPI, searchValueInRow } from 'utils';
import { notification, Modal } from 'antd';
import { useSelector } from 'react-redux';

const { GetDataType, DATATYPE } = DataTypes;

const Container = styled.div`
  table {
    .data_rows.even {
      background-color: rgba(11,107,242,0.07);
    }
  }
`

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PageTitle = (
  <Header>
    <Title>Process &gt; Batch &gt; Waiting for Partners</Title>
  </Header>
)

const WaitingForPartners = () => {
  const [dataSource, setDataSource] = useState([])
  const [searchedDataSource, setSearchedDataSource] = useState([])
  const [report, setReport] = useState({})
  const [uniqueValues, setUniqueValues] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [isSendingAction, setSendingAction] = useState(false)
  const [generalLoad, setGeneralLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const userData = useSelector((state) => state.user.details);



  const populateRow = (row) => {
    if (row !== selectedRows[0]) {
      setSelectedRows([row])
    } else {
      setSelectedRows([])
    }
  }

  const modifyPrimaryFilter = (index, value) => {
    if (report.primaryFilter[index]) {
      const currentFilter = report.primaryFilter[index]
      const theType = GetDataType(currentFilter.type)

      if (
        Object.keys(DATATYPE).includes(theType) &&
        value &&
        value.length > 0 &&
        (theType !== DATATYPE.time ? (currentFilter.operation) : true) &&
        currentFilter.field
      ) {
        currentFilter.value = CheckTypeAndConvert(theType, value)
        if (currentFilter.value.length > 0) {
          setReport(report => {
            report.primaryFilter[index].value = currentFilter.value
            return { ...report }
          })
        }
      }
    }
  }

  const loadData = () => {
    setIsLoading(true)
    GetWaitingForPartners().then(loadedData => {
      setLoadedData(loadedData)
    }).catch(err => {
      notification.error({ message : "Failed Fetching Waiting For Partners"})
      setIsLoading(false)
    })
  }

  const setLoadedData = (loadedData) => {
    const allPrimaryKeys = _.uniqWith(_.map(loadedData.data, 'primary_key'), _.isEqual)

    setDataSource(loadedData.data ? loadedData.data.map(item => addSpecialClass(item, allPrimaryKeys)) : [])
    setSearchedDataSource(loadedData.data ? loadedData.data.map(item => addSpecialClass(item, allPrimaryKeys)) : [])
    setIsLoading(false)
  }

  const searchForValue = (value = '') => {
    searchValueInRow(value, dataSource).then(result => {
      setSearchedDataSource(result)
    })
  }

  const searchOperation = async (value, source) => {
    if (value === '') {
      return source
    } else {
      const result = await source.filter(item => {
        const itemValues = Object.values(item)

        const containsValue = itemValues.some(anItemValue => (
          (anItemValue === null) ?
            false :
            anItemValue.toString().toLowerCase().includes(value.toLowerCase())
        ))

        return containsValue
      })
      return result
    }
  }

  const postLineIDs = (lineIDs, action) => {

    return ZenSmartAPI.post(action.api, {
      "line_ids": lineIDs
    })
      .then(() => {
        // batch all handled separately
        if (action.label === 'Batch All') {
          return;
        }
        notification.success({
          message: `"${action.label}" action successfully made for this item.`
        })
        setSendingAction(false)

        // reload data
        setSelectedRows([])
        loadData()

      })
      .catch(err => {
        setSendingAction(false)
        if (action.label !== 'Batch All') {
          setIsLoading(false);
        }

        if (err.response) {
          notification.error({
            message: err.response.data.message ?
              err.response.data.message :
              (
                err.response.data.validationMessages ?
                  err.response.data.validationMessages.line_ids[0] :
                  "error processing request"
              )
          })
          return
        }

        notification.error({
          message: `Could not proceed with "${action.label}" action. Please try again later.`
        })
        throw err;
      })
  }

  const batchAllData = (action) => {
    return new Promise(resolve => {
      setSendingAction(true)

      const groupedLines = _.values(_.groupBy(dataSource, 'primary_key'));
      let processedCount = 0;
      let notificationData = {
        type: 'info',
        message: (<><Spin indicator={antIcon} /> Batching all queues in progress...</>),
        key: 'batch-all-process',
        duration: 0
      };

      if (groupedLines.length > 0) {
        notification.open(notificationData);
      }

      groupedLines.forEach(lines => {
        postLineIDs(lines.map(line => line['lines.id']), action).then(() => {
          processedCount++;
          notificationData = {
            ...notificationData,
            description: `Queue with ${lines.length} lines have been batched!`
          };
          if (processedCount === groupedLines.length) {
            notificationData = {
              ...notificationData,
              type: 'success',
              duration: 4,
              message: 'All items have been batched.',
              description: null,
            };
            setIsLoading(false);
            resolve(true)
          }
          notification.open(notificationData);
        });
      });

    });
  }

  const handleResourceAction = action => {
    if (action.label === 'Batch All') {
      confirmAction(
        () => {
          setIsLoading(true);
          batchAllData(action).then(response => {
            setSendingAction(false)
            loadData();

          }).catch(err => {
          })
        }
      )
    } else if (selectedRows.length > 0) {
      confirmAction(
        () => {
          setIsLoading(true);
          setSendingAction(true)
          postLineIDs(dataSource.filter(data => selectedRows.includes(data.primary_key)).map(data => data["lines.id"]), action)

        }
      )
    } else {
      notification.error({
        message: `Please select an item.`
      })
    }


  }

  const addSpecialClass = (item, allPrimaryKeys) => {
    if (item['primary_key'] && allPrimaryKeys.length > 0) {
      item.specialClass = (allPrimaryKeys.indexOf(item['primary_key']) % 2) === 0 ? 'even' : 'odd'
    }
    return item
  }

  const confirmAction = (callback) => {
    Modal.confirm({
      title: "Confirm action",
      content: "Are you sure you want to proceed?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        callback()
      },
      onCancel() {
        // do nothing
      }
    })
  }

  const sortBy = (value) => {
    setIsLoading(true)
    GetWaitingForPartnersByField({ sortBy: value }).then(loadedData => {
      setLoadedData(loadedData)
      setIsLoading(false)
    }).catch(() => {
      notification.error({
        message: "Error sorting data"
      })
      setIsLoading(false)
    })
  }

  useEffect(() => {
    GetReport(1).then(result => {
      if (result.data) {
        const { groups, table } = result.data
        if (groups && table && groups.length > 0) {
          GetUniqueValues({
            table: table,
            groups: groups
          }).then(uniqueItems => setUniqueValues((uniqueItems)))
        }
        setReport(result.data);
      }
    })

    loadData()
  }, []);

  if (userData === null) {
    return null;
  }
  return (
    <Container>
      {PageTitle}

      <Spin spinning={generalLoad} tip='Loading'>
        <Widget
          title='Waiting for Partners'
          filter_column={true}
          fixedheader
          selectable
          showTotalCount
          actions={[
            {
              label: 'Batch Now',
              disabled: userData.should_authorize && !userData.permissions.find(p => p === 'batch-now'),
              api: '/api/v1/batches/manual-batch'
            },
            {
              label: 'Batch All',
              disabled: userData.should_authorize && !userData.permissions.find(p => p === 'batch-all'),
              api: '/api/v1/batches/manual-batch'
            }
          ]}
          isSendingAction={isSendingAction}
          handleResourceAction={handleResourceAction}
          labelFields={['due_date']}
          dataSource={searchedDataSource}
          report={report}
          modifyPrimaryFilter={modifyPrimaryFilter}
          searchFieldChanged={searchForValue}
          uniqueValues={uniqueValues}
          selectedRows={selectedRows}
          rowSelected={populateRow}
          tight_table={true}
          dropDowns={{
            label: "Sort By",
            options: report && report.fields ? report.fields : []
          }}
          onSelect={sortBy}
          isLoading={isLoading}
        />
      </Spin>
    </Container>
  )
}

export default WaitingForPartners
