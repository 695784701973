import React, { useState, useEffect } from 'react'
import { notification, Modal } from 'antd';
import { Header, Title, Panel } from 'components/primaries'
import { DataListPage } from "pages/DataListPage"
import { ZenSmartAPI } from 'utils'
import CurrencyModal from './CurrencyModal'
function Currencies() {
  const [fetchParams, setFetchParams] = useState({})
  const [fetchData, setFetchData] = useState({ refresh_data: null })
  const [corporateCurrency, setCorporateCurrency] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalAction, setModalAction] = useState('')
  const [formInitialValues, setFormInitialValues] = useState({})
  useEffect(() => {
    ZenSmartAPI.get('/api/v1/ui/init-data/currencies')
    .then(res => {
      const { data } = res
      if (data) {
        setCorporateCurrency(data.corporate_currency)
      }
    }).catch(err => {
      notification.error({ message: 'Error fetching corporate currency.'})
    })
  }, [fetchData, showModal])
  const currencyModalAction = (row, refreshData) => {
    setShowModal(true)
    setModalAction('new')
  }
  const editCurrency = (row) => {
    setShowModal(true)
    setFormInitialValues(row)
    setModalAction('edit')
  }
  const deleteCurrency = (row) => {
    Modal.confirm({
      'content': "Are you sure you want to delete this currency?",
      onOk: () => {
        ZenSmartAPI.delete(`api/v1/currencies/${row.id}`)
          .then((res) => {
            fetchData.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
            notification.success({ message: "Delete currency successful!" })
          })
          .catch((res) => {
            if(res.response.data) {
              notification.error({ message: 'Could not delete currency!' })
            }
          })
      },
    });
  }
  return (
    <>
      <Header>
        <Title>Currencies</Title>
      </Header>
      <CurrencyModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalAction={modalAction}
        setModalAction={setModalAction}
        initialValues={formInitialValues}
        setInitialValues={setFormInitialValues}
        corporateCurrency={corporateCurrency}
        setCorporateCurrency={setCorporateCurrency}
        fetchData={fetchData}
        fetchParams={fetchParams}
      />
      <Panel title="CURRENCIES">
        <DataListPage model="currencies" select={['id', 'currency_name', 'currency_code', 'corporate', 'conversion_rate']} hidden={["id"]} link={""}
          customizedButton={[{
            buttonLabel: "Add Currency",
            buttonType: "primary",
            buttonAction: "normal",
            actionFunction: currencyModalAction,
            offset: 15,
            span: 3
          }]}
          actionButtons={[
            {
              buttonLabel: "Edit",
              buttonType: "primary",
              buttonAction: "normal",
              actionFunction: editCurrency,
              reloadData: true
            },
            {
              buttonLabel: "Delete",
              buttonType: "danger",
              buttonAction: "normal",
              actionFunction: deleteCurrency,
              reloadData: true
            },
          ]}
          setOutSourceFetchData={setFetchData}
          setOutSourceParameters={setFetchParams}
        />
      </Panel>
    </>
  );
}
export default Currencies;