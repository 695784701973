const searchValueInRow = async (value, source) => {
  if (value === '') {
    return source
  } else {
    const result = await source.filter(item => {
      const itemValues = Object.values(item)
      
      const containsValue = itemValues.some(anItemValue => (
        (anItemValue === null) ? 
        false : 
        anItemValue.toString().toLowerCase().includes(value.toLowerCase())
      ))
      
      return containsValue
    })
    return result
  }
}

export default searchValueInRow