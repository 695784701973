import React, { useState, useEffect } from "react";
import {
    Drawer,
    Row,
    Col,
    Checkbox,
    notification,
    Select,
    Button,
    Form,
    Input,
    Modal,
    Spin
} from "antd";
import { ZenSmartAPI } from 'utils'
import QualifierInput from 'components/composites/QualifierInput';
import { triggerRoute, modelDefinitionRoute, createTriggerRoute } from "utils/apiRoutes"
import debounce from 'lodash/debounce';
import {
    DeleteOutlined
} from '@ant-design/icons';
const { Option } = Select
const { TextArea } = Input;

const FormRuleModal = (props) => {

    const { showModal, setShowModal, editRuleInitialData = null, setEditRuleInitialData, modalTitle = null, qualifiersData, objectsData, dataRefresh, fetchParams } = props



    function RuleForm(props) {
        const evaluationOptions = [
            "before created",
            "after created",
            "before updated",
            "after updated",
            "before deleted",
            "after deleted"
        ]


        const {
            getFieldDecorator,
            getFieldsValue,
            getFieldValue,
            setFieldsValue,
            validateFields
        } = props.form

        const [rulesCriteriaData, setRulesCriteriaData] = useState([])
        const [fieldsData, setFieldsData] = useState([])
        const [prefix, setPrefix] = useState("")
        const [loading, setLoading] = useState(false)

        const addRuleCriteriaFunction = () => {
            if (!getFieldValue('taggable_type')) {
                notification.error({ message: "Please select object first!" })
                return
            }
            else {
                setRulesCriteriaData([...rulesCriteriaData, { field: undefined, qualifier: undefined }])
            }
        }

        const cancelAddNewRule = () => {
            setShowModal(false)
            setEditRuleInitialData(null)
            setFieldsData([])
        }

        const searchFieldsData = (value, ifSetLoading) => {
            if (ifSetLoading) {
                setLoading(true)
            }
            ZenSmartAPI.get(modelDefinitionRoute(`${prefix}.${value}`), {})
                .then((res) => {
                    setFieldsData(res.data.data)
                    if (ifSetLoading) {
                        setLoading(false)
                    }
                    if (rulesCriteriaData.length === 0 && editRuleInitialData && editRuleInitialData.rules.length > 0 && prefix === editRuleInitialData.prefix) {
                        setRulesCriteriaData(editRuleInitialData.rules)
                    }
                })
        }
        const onSearchField = debounce((value, ifSetLoading) => {
            searchFieldsData(value, ifSetLoading)
        }, 600);

        const removeComponent = (index) => {
            const rulesCriteria = getFieldValue('rules')
            setRulesCriteriaData(rulesCriteriaData.filter((data, key) => key !== index))
            setComponentsValue(rulesCriteria.filter((item, key) => key !== index))
        }

        const setComponentsValue = (data) => {
            setFieldsValue({
                rules: data,
            });
        }

        const getPrefix = (value) => {
            const getField = getFieldValue('taggable_type')
            const findPrefix = objectsData.find(data => value === data.value)

            if (getField && rulesCriteriaData.length > 0) {
                Modal.confirm({
                    'content': "Rules criteria will be lost, Do you want to continue?",
                    onOk: () => {
                        setComponentsValue([])
                        setPrefix(findPrefix.prefix)
                        setFieldsData([])
                        setRulesCriteriaData([])
                    },
                    onCancel: () => {
                        setFieldsValue({
                            "taggable_type": getField
                        })
                        return
                    }
                }
                );
            }
            else {
                setPrefix(findPrefix.prefix)
                setFieldsData([])
                setRulesCriteriaData([])

            }

        }

        const handleSubmit = e => {
            e.preventDefault();
            validateFields((err, values) => {
                if (!err) {
                    const payload = getFieldsValue()
                    if (!getFieldValue('rules')) {
                        payload.rules = []
                    }
                    const api = modalTitle === "Add New Rule" ? ZenSmartAPI.post(createTriggerRoute, payload) : ZenSmartAPI.put(triggerRoute(editRuleInitialData.id), payload)
                    setLoading(true)
                    api.then((res) => {
                        if (modalTitle === "Add New Rule") {
                            setLoading(false)
                            notification.success({ message: "Add rule successful!" })
                        }
                        else {
                            notification.success({ message: "Update rule successful!" })
                            setLoading(false)
                        }

                        if (dataRefresh && dataRefresh.refresh_data) {
                            cancelAddNewRule()
                            dataRefresh.refresh_data(fetchParams.searchCurrentPage, fetchParams.searchProperty, fetchParams.searchAscend, fetchParams.searchValue)
                        }
                    })
                }
            });
        };

        useEffect(() => {
            if (editRuleInitialData && editRuleInitialData.rules) {
                setPrefix(editRuleInitialData.prefix)
            }
        }, [editRuleInitialData]) // eslint-disable-line react-hooks/exhaustive-deps
        useEffect(() => {
            if (prefix) {
                searchFieldsData("", true)
            }
        }, [prefix]) // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <div>
                <Spin tip="Loading..." spinning={loading}>
                    <Form onSubmit={handleSubmit} autoComplete={'off'}>
                        <Col span={24}>
                            <Form.Item label="Select Object"
                            >
                                {getFieldDecorator(`taggable_type`, {
                                    initialValue: editRuleInitialData && editRuleInitialData.taggable_type ? editRuleInitialData.taggable_type : undefined,
                                    rules: [{ required: true, message: `Please select an object!` }]
                                }
                                )(
                                    <Select placeholder="Please select an object" style={{ width: "50%" }}
                                        optionFilterProp="children"
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        onChange={getPrefix}
                                    >
                                        {objectsData.map(item => {
                                            return <Option value={item.value}>{item.name}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item >
                            <Form.Item label="Rule Name"
                            >
                                {getFieldDecorator(`name`, {
                                    initialValue: editRuleInitialData && editRuleInitialData.name ? editRuleInitialData.name : undefined,
                                    rules: [{ required: true, message: `Please input this field!` }]
                                }
                                )(
                                    <Input placeholder="please input rule name" style={{ width: "50%" }} />
                                )}
                            </Form.Item >
                            <Form.Item label="Description"
                            >
                                {getFieldDecorator(`description`, {
                                    initialValue: editRuleInitialData && editRuleInitialData.description ? editRuleInitialData.description : undefined,
                                    rules: [{ required: true, message: `Please input this field!` }]
                                }
                                )(
                                    <TextArea placeholder="add description here" style={{ width: "100%", padding: 10 }}
                                    >
                                    </TextArea>
                                )}
                            </Form.Item >
                            <Form.Item label="Select Evaluation Criteria"
                            >
                                {getFieldDecorator(`events`, {
                                    initialValue: editRuleInitialData && editRuleInitialData.events ? editRuleInitialData.events : undefined,
                                    rules: [{ required: true, message: `Please select a criteria!` }]
                                }
                                )(
                                    <Checkbox.Group options={evaluationOptions} defaultValue={['Apple']} />
                                )}
                            </Form.Item >
                            <p style={{ fontWeight: "bold", marginBottom: 30 }}>Rules Criteria</p>
                            <div style={{ marginBottom: "150px" }} >
                                {prefix && rulesCriteriaData.map((item, key) => {

                                    return (
                                        <div>
                                            <Row type="flex" align="middle" >
                                                <Col span={11} style={{ padding: 10, marginTop: "-30px" }}>
                                                    <Form.Item label="Field"
                                                    >
                                                        {getFieldDecorator(`rules[${key}].condition_field_id`, {
                                                            initialValue: item.condition_field_id,
                                                            rules: [{ required: true, message: `Please input this field!` }]
                                                        }
                                                        )(
                                                            <Select
                                                                showSearch
                                                                style={{ width: "100%" }}
                                                                placeholder="Enter Field (Click Suggestions to add each field)"
                                                                optionFilterProp="children"
                                                                onSearch={onSearchField}
                                                            >
                                                                {fieldsData.map(data =>
                                                                    <Option value={data.id}>{data.label.replace(`${prefix}.`, '')}</Option>
                                                                )}
                                                            </Select>
                                                        )}
                                                    </Form.Item >
                                                </Col>
                                                <Col span={11} style={{ padding: 10, marginTop: "-30px" }}>
                                                    <Form.Item label="Qualifier"
                                                    >
                                                        {getFieldDecorator(`rules[${key}].qualifier`, {
                                                            initialValue: item.qualifier,
                                                            rules: [{ required: true, message: `Please input this field!` }]
                                                        }
                                                        )(
                                                            <QualifierInput
                                                                style={{ width: "100%", padding: 10 }}
                                                                types={qualifiersData} />
                                                        )}
                                                    </Form.Item >
                                                </Col>
                                                <Col span={2} style={{ padding: 10, marginTop: "-15px" }}>
                                                    <Button type="danger" onClick={() => removeComponent(key)}><DeleteOutlined /></Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })}
                                <Col span={24}>
                                    <p style={{ textAlign: "right" }}><Button type="primary" onClick={addRuleCriteriaFunction}>Add New Rule Criteria</Button></p>
                                </Col>
                            </div>
                        </Col>
                    </Form>
                </Spin>
                <div>
                </div>
                <div style={{ position: "absolute", bottom: 0, left: 0, width: "100%", padding: 10, background: "white" }}>
                    <hr />
                    <Row type="flex" align="middle" justify="end" style={{ marginTop: 5 }}>
                        <Col span={4} style={{ padding: 5 }}>
                            <Button type="primary" style={{ width: "100%" }} ghost onClick={cancelAddNewRule}>
                                Cancel
      </Button>
                        </Col>
                        <Col span={6} style={{ padding: 5 }}>
                            <Button type="primary" htmlType="submit"
                                style={{ width: "100%" }} loading={loading} onClick={handleSubmit} >
                                Save Changes
      </Button>
                        </Col>
                    </Row>
                </div>
            </div>

        )
    }

    const FormRuleComponent = Form.create({
    })(RuleForm)

    return (
        <Drawer visible={showModal} title={modalTitle} width={600} closable={false}
        >
            <p>
                Enter the object, name, description, and criteria to trigger your workflow rule. In the next step, associate workflow actions with this workflow rule.
            </p>
            <br />
            <br />
            <div>
                <FormRuleComponent />
            </div >
        </Drawer >
    );
}

export default FormRuleModal;
