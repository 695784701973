import React, { useState } from 'react'
import { Table, Select, Button, Form, Row, Checkbox, Col, Icon as AntIcon, Modal, Input, notification, Spin } from 'antd';
import { generateKey } from '../../utils';
import styled from 'styled-components';
import {  matchOutGroupCreateRoute, matchRangeGroups } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'

const { Column } = Table;

const ModalTitle = styled.h3`
  font-weight: bold;
`
const Icon = styled(AntIcon)`
  font-size: 16px;
`

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-label {
    display: none
  }
`
const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0243EB;
  font-size: 12px;
  border: 0;
  margin-top: 10px;
  box-shadow: none;
`
const { Option } = Select

const MatchOutModal = ({ visible, onCancel, matchOutRules , matchGroupOptions , setMatchOutRules , originalMatchOutRules , setOriginalMatchOutRules , matchGroup, 
  setMatchGroup , getMatchGroup , processID , onSave}) => {
  const [errors] = useState({});
  const [fieldsValue, setFieldsValue] = useState([])
  const [loading, setLoading] = useState(false)
  const onRemove = (item) => {
    const newData = [...matchOutRules.box_ranges];
    newData.splice(newData.indexOf(item), 1);
    setMatchOutRules({ ...matchOutRules, box_ranges: newData });
  }

  const onAddNewRule = () => {

    if (!matchOutRules.box_ranges) {
      notification.error({ message: "Please select matchout group first!" })
      return
    }

    const newRule = {
      id: `newRule.${generateKey()}`,
      box_range_end: null,
      box_range_start: null,
      is_batch: false,
    };

    const newData = [
      ...matchOutRules.box_ranges,
      newRule
    ];
    setMatchOutRules({ ...matchOutRules, box_ranges: newData });

  }

  const constructModalHeader = () => (
    <>
      <ModalTitle>MatchOut Ranges</ModalTitle>
    </>
  );

  const onChangeStartBox = (value, item, property) => {
    const selectedRow = matchOutRules.box_ranges.findIndex(selectedRow => selectedRow.id === item.id);
    const newData = matchOutRules.box_ranges.map((item, key) => {
      return (
        key === selectedRow
          ? { ...item, [property]: value }
          : item
      )
    }
    )
    setMatchOutRules({ ...matchOutRules, box_ranges: newData });

  }

  const onChangeCheckBox = (value, item, property) => {
    const selectedRow = matchOutRules.box_ranges.findIndex(selectedRow => selectedRow.id === item.id);
    const newData = matchOutRules.box_ranges.map((item, key) => {
      return (
        key === selectedRow
          ? { ...item, [property]: value }
          : { ...item, [property]: false }
      )
    }
    )
    setMatchOutRules({ ...matchOutRules, box_ranges: newData });

  }
  



 



  const selectMatchOutGroup = (value) => {

    setLoading(true)
    ZenSmartAPI.get(`api/v1/match-group/${value}/box-ranges`)
      .then((res) => {
        setMatchOutRules({ ...matchOutRules, box_ranges: res.data , match_group_id : value})
        setLoading(false)
      })
      .catch((res) => {
        notification.error({ message: 'Error fetching box ranges!' })
        setLoading(false)
      })

  }


  const getMatchGroups = () => {
    setLoading(true)
    ZenSmartAPI.get(matchRangeGroups)
      .then((res) => {
        setLoading(false)
        setMatchGroup && setMatchGroup(res.data)
      })
      .catch((res) => {
        setLoading(false)
      })
  };

  const handleSubmit = (e) => {
    if (!matchOutRules.box_ranges || matchOutRules.box_ranges.length === 0) {
      notification.error({ message: "Please add fields!" })
      return
    }

    const checkIfNullValues = matchOutRules.box_ranges.some(data => {
      
      if (data.box_range_end === null || data.box_range_end === "" || data.box_range_start === null || data.box_range_start === "") {
        return true
      }
      return false
    })
    if (checkIfNullValues) {
      notification.error({ message: "Please input fields!" })
    }
    else {
      setOriginalMatchOutRules(matchOutRules)
      onSave(originalMatchOutRules, processID)
      onCancel(e, true)
    }

  }

  const OnCancelData = (e) => {
    setMatchOutRules(originalMatchOutRules)
    onCancel(e, true)
  }

  const addNewMatchOutGroup = (value) => {
    if (value.keyCode === 13) {
      const ifMatchGroupExist = matchGroup.some(data => {
        return data.name === value.target.value
      })

      if (ifMatchGroupExist) {
        notification.error({ message: 'MatchOut group already exist!' })
        return;
      }

      const payload = { name: value.target.value , code : value.target.value }
      ZenSmartAPI.post(matchOutGroupCreateRoute, payload)
        .then((res) => {
          getMatchGroups()
        }).catch((res) => {
          notification.error({ message: 'Save MatchOut group not successful!' })
        })
    }
  }

  return (
    <Modal
      width={1200}
      title={constructModalHeader()}
      visible={visible}
      onOk={handleSubmit}
      onCancel={OnCancelData}
    >
      <Row style={{ paddingBottom: 15 }}>
        <Col span={6} style={{ padding: 10 }}>
          {matchGroup && visible ?
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Match Group"
              optionFilterProp="children"
              loading={loading}
              onChange={(value) => selectMatchOutGroup(value)}
              onInputKeyDown={(event) => addNewMatchOutGroup(event)}
              defaultValue={matchOutRules ? matchOutRules.match_group_id ? parseInt(matchOutRules.match_group_id) : undefined : undefined}
              disabled={loading}
            >
              {matchGroup && matchGroup.map(data =>
                <Option key={data.id} value={data.id}>{data.name}</Option>
              )}
            </Select>
            : <Spin />
          }
        </Col>
      </Row>

      <Table dataSource={matchOutRules ? matchOutRules.box_ranges : []} pagination={false} bordered={true}>
        <Column
          title="Start Box"
          dataIndex="box_range_start"
          key="box_range_start"
          render={(scheduleFrom, item) => {
            return (
              <div>
                <Input type="number" value={item.box_range_start} onChange={(value) => onChangeStartBox(value.target.value, item, 'box_range_start')} />
              </div>
            )
          }}
        />

        <Column
          title="End Box"
          dataIndex="box_range_end"
          key="box_range_end"
          render={(scheduleTo, item) => {
            return (
              <div>
                <Input type="number" value={item.box_range_end} onChange={(value) => onChangeStartBox(value.target.value, item, 'box_range_end')} />
              </div>
            )
          }}
        />

        <Column
          title="Batch"
          dataIndex="is_batch"
          key="is_batch"
          render={(isBatched, item) => {
            return (
              <div>
                <Checkbox
                  checked={item.is_batch}
                  onChange={(value) => onChangeCheckBox(value.target.checked, item, 'is_batch')}
                ></Checkbox>
              </div>
            )
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, item) => (
            <div>
              <Row align="middle" justify="center">
                <Col span={24} style={{ padding: 5 }}>
                  <Button type="danger" onClick={() => onRemove(item)} >Remove</Button>
                </Col>
              </Row>
            </div>
          )}
        />
      </Table>
      <AddButton onClick={onAddNewRule}> 
        <Icon type="plus-circle" theme="filled" />
            ADD NEW RULE
          </AddButton>
    </Modal>
  )
};

export default MatchOutModal;