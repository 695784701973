import { notification } from 'antd';

const notifyAPIErrorResponse = (response, errorMsgPlaceholder) => {
  let errorMessage = [];
  if(response && (response.errors !== undefined || response.validationMessages !== undefined)) {
    const errors = response.errors ? Object.values(response.errors) : Object.values(response.validationMessages);
    errors.forEach(err => errorMessage.push(`${err} `));
  } else {
    errorMessage.push(errorMsgPlaceholder)
  }
  errorMessage.forEach(err => {
    notification.error({
      message: err
    })
  });
}

export default notifyAPIErrorResponse;