import moment from "moment"
import { panelSortData } from 'utils/sortData'
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { Row, Col, notification } from 'antd'
import React from 'react';
import { debounce } from "utils/debounce";
import { pressReleaseRouteSorting, pressReleaseLimit } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'

export const renderFilterColumnsData = (labels, data) => {
    const returnValue = {}
    Object.keys(labels).map(item => {
        returnValue[item] = item === 'imposed_datetime' || item === 'sent_datetime' ? { label: labels[item].label, data: [], date_data: [] } : { label: labels[item].label, data: [] }
        return item
    })

    data.map(item => {
        Object.keys(item).map(secondItem => {
            const value = moment(item[secondItem]).isValid() && (secondItem === 'imposed_datetime' || secondItem === 'sent_datetime') ? moment(item[secondItem]).format('MMM DD ddd hh:mm').toString() :
                secondItem === "fails" && item[secondItem] === 0 ? "False" : secondItem === "fails" && item[secondItem] > 0 ? "True" : item[secondItem];

            if (returnValue[secondItem] && !returnValue[secondItem].data.includes(value)) {
                returnValue[secondItem].data.push(value)
                if ((secondItem === 'imposed_datetime' || secondItem === 'sent_datetime') && returnValue[secondItem].date_data) {
                    returnValue[secondItem].date_data.push(item[secondItem])
                }
            }
            return secondItem
        })
        return item
    })
    return returnValue
}

export const sortingData = (data, property, ifNotProperty, labels, ascend, setAscend, user_id = null, press_release_settings_id = null, initialAscend = null, type = null) => {

    const sortingInitialData = {}

    Object.keys(labels).map(item => {
        sortingInitialData[item] = null
        return item
    })

    let ascendValue = false;
    if (ascend[property] === null) {
        ascendValue = initialAscend !== null ? initialAscend : true
    }
    else {
        ascendValue = initialAscend !== null ? initialAscend : ascend[property] === true ? false : true
    }
    setAscend({ ...sortingInitialData, [property]: ascendValue })

    if (user_id && press_release_settings_id) {
        const payload = {
            user_id,
            press_release_settings_id,
            column_name: property,
            if_ascend: !ascendValue,
            type
        }
        ZenSmartAPI.post(pressReleaseRouteSorting, payload)
            .then((res) => {
            })
            .catch((res) => {
                notification.error({ message: "Can't Save Sorting Values!" })
            })
    }

    return panelSortData(data, property === "relative_due_date" && labels['due_date'] ? "due_date" : property, initialAscend !== null ? !initialAscend : !ascendValue, ifNotProperty, property === "due_day" ? "dayOfWeek" : null)
}

export const getHeaders = (property, text, ifAscend, itemData, labels, ascend, setAscend, setItemData, user_id = null, press_release_settings_id = null, initialAscend = null, type = null) => {
    // console.log('itemData', property, ifAscend, ascend, setAscend)
    return (
        <Row type="flex" align="middle" onClick={() => setItemData(sortingData(itemData, property, ifAscend, labels, ascend, setAscend, user_id, press_release_settings_id, initialAscend, type))} style={{ cursor: "pointer" }}>
            <Col span={ascend[property] === null || ascend[property] === undefined ? 24 : 20}>
                <p>{text}</p>
            </Col>
            {ascend[property] !== null &&
                <Col span={2}>
                    <p>
                        {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                            : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
                    </p>
                </Col>
            }
        </Row>
    )
}

export const applySelectedCss = (record, className, selectedArray) => {
    var elements = document.getElementsByClassName(record)[0]
    if (elements) {
        if (selectedArray.includes(record)) {
            elements.classList.add(className);
        }
        else {
            elements.classList.remove(className);
        }
    }
}
export const workOrderApplySelectedCss = (record, className, selectedArray, panelClassName) => {
    var elements = document.getElementsByClassName(panelClassName)[0]
    if (elements) {
        if (selectedArray.includes(record)) {
            elements.classList.add(className);
        }
        else {
            elements.classList.remove(className);
        }
    }
}
export const applyFails = (record, className) => {
    var elements = document.getElementsByClassName(record)[0]
    if (elements) {
        elements.classList.add(className);
    }
}

export const searchOperation = debounce(async (value, source, originalData, setItemData, setSearchFilterValue, setColumnFiltersLogic, columnFilterDataObject = {}, setCurrentSearchKey) => {
    if (value === '') {
        setItemData(originalData)

    } else {
        const result = await source.filter(item => {
            const itemValues = Object.values(item)
            const containsValue = itemValues.some(anItemValue => {
                return (
                    (anItemValue === null) ?
                        false :
                        anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) : anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                )
            })
            return containsValue
        })
        setItemData(result)
    }
    setSearchFilterValue(value)
    if (setCurrentSearchKey !== undefined) {
        setCurrentSearchKey(value)
    }

    if (Object.keys(columnFilterDataObject).length > 0) {
        setColumnFiltersLogic(value)
    }
}, 500)

export const searchOperationWithoutDelay = (value, source) => {
    if (value === '') {
        return source
    } else {
        const result = source.filter(item => {
            const itemValues = Object.values(item)
            const containsValue = itemValues.some(anItemValue => {
                return (
                    (anItemValue === null) ?
                        false :
                        anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) : anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                )
            })
            return containsValue
        })
        return result
    }
}

export const getPressReleaseLimit = () => {

}

