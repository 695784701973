import React, { useMemo, useState, useEffect } from 'react';
import { styles } from '../../../../components/zensmart-design-system';
import styled from 'styled-components';
import BucketModal from './BucketModal';
import { NumberFieldRow } from '../composites';
import { DataTypes, GetLabel } from '../../utils';
import { notification } from 'antd';

const color = styles.color;

const { GetDataType, DATATYPE } = DataTypes;

const RangeSection = styled.div`
  h2 {
    font-size: 15px;
  }
 table {
   width: 100%;
   
   td:not(:first-child){
    border: 1px solid #E1E7FD;
    font-weight: 600;
   }

   td {
    padding:15px;
   }

   .heading {
     background-color: ${color.blue.light};
     opacity: 0.4;
     font-weight: 600;
     font-size: 12px;
     padding: 5px 20px;
     text-transform: uppercase;
   }
   .range {
     width: 40%;
   }
 }
`

const NumberBucketModal = ({
  dataFields = [],
  visible,
  handleCancel,
  populateBucketField,
  bucket,
  selectedBucketFieldKey
}) => {
  const defaultBucket = {
    column: '',
    name: '',
    values: [
      {
        value: 0,
        name: ''
      },
      {
        value: 0,
        name: ''
      }
    ]
  }
  const [editableBucket, setEditableBucket] = useState(defaultBucket)
  const [currentBucketKey, setCurrentBucketKey] = useState(0)

  useEffect(() => {
    setEditableBucket(bucket)

  }, [bucket]);

  const checkValues = () => {
    const { values } = editableBucket
    let err = ''

    var arrayOfValues = Object.keys(values).map((valueItem) => {
      //check: that next value is greater than previous value
      if (values[valueItem + 1]) {
        if ((values[valueItem].value > values[valueItem + 1].value)) {
          err = `Upper range cannot be less than lower range at index ${valueItem}`
        }
      }

      //check: that name exists
      if (values[valueItem].name === '') {
        err = `Name cannot be empty at index ${valueItem}`
      }

      //check: that range value exists
      if (values[valueItem] === 0 && err === '') {
        err = `Range value cannot be empty at index ${values[valueItem]}`
      }

      return values[valueItem].name
    })

    var isDuplicate = arrayOfValues.some((item, index) => arrayOfValues.indexOf(item) !== index)

    if (isDuplicate && err === '') {
      err = 'Range name should be unique'
    }


    //check that column name and bucket name are defined
    if (editableBucket.name === '') {
      err = 'Bucket name cannot be empty'
    }

    if (editableBucket.column === '') {
      err = 'Bucket column name cannot be empty'
    }

    //handle submission or error
    if (err === '') {
      handleOK()
    } else {
      notification.error({
        message: err
      })
    }
  }

  const setName = (name) => {
    setEditableBucket(editableBucket => {
      editableBucket.name = name
      return { ...editableBucket }
    })
  }

  const setColumn = (column) => {
    console.log(column);

    setEditableBucket(editableBucket => {
      editableBucket.column = column
      return { ...editableBucket }
    })
  }

  const setBucketRangeName = (name) => {
    setEditableBucket(editableBucket => {
      if (name !== undefined) {
        const { values } = editableBucket
        values[currentBucketKey].name = name
        editableBucket.values = values
      }
      return { ...editableBucket }
    })
  }

  const setBucketValue = (value) => {
    setEditableBucket(editableBucket => {
      const { values } = editableBucket
      values[currentBucketKey].value = value
      //populate the next value
      if (values[currentBucketKey + 1]) {
        values[currentBucketKey + 1].value = value
      }
      editableBucket.values = values
      return { ...editableBucket }
    })
  }

  const addRange = (index) => {
    index = parseInt(index)

    setEditableBucket(editableBucket => {
      const { values } = editableBucket
      values.splice(index, 0, {
        value: 0,
        name: ''
      })
      editableBucket.values = values
      return { ...editableBucket }
    })
  }

  const deleteRange = (index) => {
    setEditableBucket(editableBucket => {
      if (parseInt(index) === 0 || (parseInt(index) + 1 === editableBucket.values.length)) {
        const { values } = editableBucket
        values[index] = {
          value: 0,
          name: ''
        }
        editableBucket.values = values
      } else {
        const { values } = editableBucket
        values.splice(index, 1)
        editableBucket.values = values
      }
      return { ...editableBucket }
    })
  }

  const handleOK = () => {
    populateBucketField(editableBucket, selectedBucketFieldKey)
    setEditableBucket(defaultBucket)
    setCurrentBucketKey(0)
    handleCancel()
  }

  return (
    <BucketModal
      columns={
        useMemo(() => Object.values(dataFields).filter(column => GetDataType(column.type) === DATATYPE.number), [dataFields])
      }
      visible={visible}
      handleOK={() => checkValues()}
      handleCancel={handleCancel}
      bucketName={editableBucket.name && editableBucket.name}
      setBucketName={setName}
      bucketColumnField={
        (editableBucket.column && editableBucket.column.field) ?
          GetLabel(`${editableBucket.column.table}.${editableBucket.column.field}`) :
          ''
      }
      setBucketColumn={setColumn}
    >
      <RangeSection>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '80%' }}>
            <h2>Define Ranges</h2>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <td></td>
              <td className='heading range'>Range</td>
              <td className='heading'>Name</td>
            </tr>
          </thead>
          <tbody>
            {
              editableBucket && Object.keys(editableBucket.values).map((rangeKey) => {
                return (
                  <NumberFieldRow
                    key={rangeKey}
                    index={rangeKey}
                    previousRange={editableBucket.values[rangeKey - 1] ? editableBucket.values[rangeKey - 1].value : 0}
                    last={parseInt(rangeKey) + 1 === parseInt(editableBucket.values.length)}
                    addRange={addRange}
                    deleteRange={deleteRange}
                    name={editableBucket.values[rangeKey] ? editableBucket.values[rangeKey].name : ''}
                    value={editableBucket.values[rangeKey] ? editableBucket.values[rangeKey].value : ''}
                    setName={setBucketRangeName}
                    setValue={setBucketValue}
                    select={() => setCurrentBucketKey(rangeKey)}
                  />
                )
              })
            }
          </tbody>
        </table>
      </RangeSection>
    </BucketModal>
  )
}

export default NumberBucketModal