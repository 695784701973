import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Table, Row, Col } from 'antd'
import { panelSortData } from 'utils/sortData'
import moment from 'moment';
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { operatorActivityInitialData } from "utils/panelsInitialData"

const StyleTable = styled.div`
font-family: 'Rubik', sans-serif;

`
const TableStyling = createGlobalStyle`

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border : none !important;
    background-color : white;

}

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
}

`
const TableData = (props) => {

    const { loading, data } = props


    const columns = [
        {
            title: "OPERATOR",
            dataIndex: 'operator',
            key: 'operator',
            render(value, row, index) {
                return rowStyle(value !== null && value !== undefined ? value : "-", index, row)
            },
        },
        {
            title: "SCAN TYPE",
            dataIndex: 'scan_type',
            key: 'scan_type',
            render(value, row, index) {
                console.log(`value`, value)
                return rowStyle(value !== null && value !== undefined ? value.toUpperCase() : "-", index, row)
            },
        },
        {
            title: "QUANTITY",
            dataIndex: 'units',
            key: 'units',
            render(value, row, index) {
                return rowStyle(value !== null && value !== undefined ? value : "-", index, row)
            },
        },
        {
            title: "AVG UNITS PER HOUR",
            dataIndex: 'avg_unit_per_hr',
            key: 'avg_unit_per_hr',
            render(value, row, index) {
                return rowStyle(value !== null && value !== undefined ? value : "-", index, row)
            },
        },
    ];

    const rowStyle = (value, index, row) => {
        const odd = index % 2
        const obj = {
            props: {
                style: { background: "#f1f3ff", border: "none" },
            },
            children: <div>{value}</div>,
        };

        if (!odd) {
            obj.props.style = { border: "none" }
        }
        if ((data.length - 1) === index) {
            obj.props.style.fontWeight = "bold"
        }

        return obj
    }

    return (
        <>
            <TableStyling />
            <StyleTable>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{ y: "50vh" }}
                    pagination={false}

                />
            </StyleTable>
        </>
    )
}

export default TableData