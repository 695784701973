import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, Select, Input, Modal, Button, notification } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import { ZenSmartAPI } from 'utils'
import { waitingForPartnersRoute, manualBatchingWaitingForPartnersRoute, getLabelsRoute } from "utils/apiRoutes"
import { GetLabel } from '../Performance/utils';
import { useSelector } from 'react-redux';
import { debounce } from "utils/debounce"
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import moment from "moment";
const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;

`
const Heading = styled.h1`
  font-size : 13px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`

const PageStyling = createGlobalStyle`
@media only screen and (max-width: 1280px) {
    span, td, th {
        font-size : 11px !important
    }
  }

  @media screen and (min-width: 1281px) and (max-width: 1440px) { 
    span, td, th {
        font-size : 13px !important
    }
}
`
const HeadingTotal = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const WaitingForPartners = (props) => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([]);
    const [groupPrimaryKeys, setGroupPrimaryKeys] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [columns, setColumns] = useState([])
    const [selectedLineIds, setSelectedLineIds] = useState([])
    const [selectedPrimaryKey, setSelectedPrimaryKey] = useState(null)
    const [sortByOptions, setSortByOptions] = useState([]);
    const userData = useSelector((state) => state.user.details);
    const [searchFilterValue, setSearchFilterValue] = useState('')
    const [modifiedLabels, setModifiedLabels] = useState([])
    // filter for multi panel
    const { slug } = useParams();
    const [filterMultiPanel, setFilterMultiPanel] = useState(null);
    const searchOperation = debounce(async (value, source) => {
        if (value === '') {
            setItemData(originalData)
            setSearchFilterValue(value)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                            false :
                            anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) : anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
                return containsValue
            })
            setItemData(result)
            setSearchFilterValue(value)
        }
    }, 500)

    const fetchData = (sort = false) => {
        setLoading(true)
        ZenSmartAPI.get(sort ? `${waitingForPartnersRoute}/${sort}` : waitingForPartnersRoute, { params: { filter: filterMultiPanel } })
            .then((res) => {
                setLoading(false)
                setItemData(res.data.data)
                setOriginalData(res.data.data)
                const primaryKeys = []

                res.data.data.map(data => {
                    if (!primaryKeys.includes(data.primary_key)) {
                        primaryKeys.push(data.primary_key)
                    }
                    return data
                })

                if (columns.length === 0 && res.data.fields.length > 0) {
                    const arrayData = [];
                    setSortByOptions(res.data.fields)
                    res.data.fields.map(data => {
                        arrayData.push({
                            title: data === "lines.queued_at" ? "LINE AGE" : GetLabel(data, modifiedLabels).toUpperCase().replace(/_/g, " "),
                            dataIndex: data,
                            key: data,
                            render: (text, record) => {
                                if (data === "lines.queued_at" && text) {

                                    var a = moment();//now
                                    var b = moment(text);
                                    var duration = moment.duration(b.diff(a));
                                    var daysForHours = duration.asDays();
                                    duration.subtract(moment.duration(daysForHours, 'days'));
                                    var hours = duration.hours();
                                    duration.subtract(moment.duration(hours, 'hours'));
                                    var days = a.diff(b, 'days')

                                    return (
                                        <span>
                                            {days > 1 ? `${days} days and` : days == 1 ? `${days} day and` : null} {`${hours} hrs`}
                                        </span>
                                    )
                                } else {
                                    return (
                                        <span>
                                            {text}
                                        </span>
                                    )
                                }
                            },
                        })

                        return true
                    })
                    setColumns(arrayData);
                }
                setGroupPrimaryKeys(primaryKeys);
            })
            .catch((res) => {
                setLoading(false)
            })

    }

    const fetchLabels = () => {
        const labelName = userData.page_settings.waiting_for_partners_setting ? userData.page_settings.waiting_for_partners_setting : null;
        ZenSmartAPI.get(getLabelsRoute(labelName))
            .then((res) => {
                setLoading(false)
                if (res.data.length > 0) {
                    setModifiedLabels(res.data);
                }
                else {
                    notification.error({ message: "No labels found can't load data!" })
                }
            }).catch((err) => {
                setLoading(false)
                notification.error({ message: "Error fetching labels can't load data!" })
            })
    }

    const singleBatching = () => {
        if (selectedLineIds.length > 0) {
            Modal.confirm({
                title: "Confirm Action",
                content: "Are you sure you want to proceed?",
                okText: "Yes",
                cancelText: "No",
                onOk() {
                    setLoading(true)
                    ZenSmartAPI.post(manualBatchingWaitingForPartnersRoute, { line_ids: selectedLineIds })
                        .then((res) => {
                            setLoading(false)

                            const removeBatchedPrimaryKeys = originalData.filter(data => data.primary_key !== selectedPrimaryKey)
                            if (searchFilterValue !== '') {
                                searchOperation(searchFilterValue, removeBatchedPrimaryKeys)
                            }
                            else {
                                setItemData(removeBatchedPrimaryKeys);
                            }
                            setOriginalData(removeBatchedPrimaryKeys)
                            const primaryKeys = []

                            removeBatchedPrimaryKeys.map(data => {
                                if (!primaryKeys.includes(data.primary_key)) {
                                    primaryKeys.push(data.primary_key)
                                }
                                return data
                            })

                            setGroupPrimaryKeys(primaryKeys);
                            setSelectedPrimaryKey(null);
                            setSelectedLineIds([])
                            notification.success({
                                message: '"Batch Now" action successfully made for this item.',
                                duration: 4
                            })

                        })
                        .catch((res) => {
                            setLoading(false)
                            notification.error({
                                message: "Batching Failed!"
                            })
                        })
                },

            });
        }
        else {
            notification.error({ message: "Please select an item!" })
        }
    }

    const batchingAll = () => {

        if (groupPrimaryKeys.length > 0) {

            Modal.confirm({
                title: "Confirm Action",
                content: "Are you sure you want to proceed?",
                okText: "Yes",
                cancelText: "No",
                onOk() {
                    setLoading(true)
                    notification.open({
                        type: 'info',
                        message: (<><Spin indicator={antIcon} /> Batching all queues in progress...</>),
                        key: 'batch-all-process',
                        duration: 0
                    });
                    const batchedGroupPrimaryKeys = groupPrimaryKeys

                    groupPrimaryKeys.map(keys => {
                        const getLineIds = itemData.filter(item => item.primary_key === keys).map(record => record['lines.id']);
                        const notificationData = {
                            type: 'info',
                            message: (<><Spin indicator={antIcon} /> Batching all queues in progress...</>),
                            key: 'batch-all-process',
                            description: `Queue with ${getLineIds.length} lines have been batched!`,
                            duration: 0
                        }
                        ZenSmartAPI.post(manualBatchingWaitingForPartnersRoute, { line_ids: getLineIds })
                            .then((res) => {
                                notification.open(notificationData);
                                var removeKey = batchedGroupPrimaryKeys.indexOf(keys);
                                if (removeKey !== -1) {
                                    batchedGroupPrimaryKeys.splice(removeKey, 1);
                                }

                                if (batchedGroupPrimaryKeys.length === 0) {
                                    notification.open({
                                        type: 'success',
                                        duration: 4,
                                        message: 'All items have been batched.',
                                        description: null,
                                    });

                                    notification.open({ ...notificationData, duration: 2 });
                                    setSortByOptions([])
                                    fetchData();
                                }

                            })
                            .catch((res) => {
                                notification.open({ ...notificationData, description: `Queue with ${getLineIds.length} lines failed to batched!` });

                                var removeKey = batchedGroupPrimaryKeys.indexOf(keys);
                                if (removeKey !== -1) {
                                    batchedGroupPrimaryKeys.splice(removeKey, 1);
                                }

                                if (batchedGroupPrimaryKeys.length === 0) {
                                    notification.open({
                                        type: 'success',
                                        duration: 4,
                                        message: 'All items have been batched.',
                                        description: null,
                                    });
                                    notification.open({ ...notificationData, duration: 1 });
                                    setSortByOptions([])
                                    fetchData();
                                }
                            })
                        return keys
                    })

                },

            });
        }

        else {
            notification.error({ message: "No Batch Found!" })
        }

    }

    useEffect(() => {
        if (userData) {
            fetchLabels()
        }
    }, [userData]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (modifiedLabels.length > 0) {
            fetchData()
        }
    }, [modifiedLabels, filterMultiPanel]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userData) {
            if (slug) {
                setFilterMultiPanel(userData && userData.page_settings.waiting_for_partners_setting_multi_panel && userData.page_settings.waiting_for_partners_setting_multi_panel.find(data => data.slug === slug) ? userData.page_settings.waiting_for_partners_setting_multi_panel.find(data => data.slug === slug).filter : null)
            }
            else {
                setFilterMultiPanel(null);
            }
        }
    }, [slug, userData]);

    return (
        <>
            <PageStyling />
            <Header>
                <Title>Performance {">"} Batch {">"} Waiting For Partners</Title>
            </Header>
            <Panel title="Waiting For Partners">
                <div style={{ padding: "10px 50px 0px 50px" }}>
                    <Row type="flex" align="middle" style={{ padding: "15px 15px 0px 15px" }} justify="space-between">
                        <Col span={6} style={{ padding: 10 }}>
                            <Row type="flex" align="middle">
                                <Heading>Search :</Heading>
                                <Input.Search
                                    type="text"
                                    placeholder="Search"
                                    disabled={loading}
                                    onChange={(value) => searchOperation(value.target.value, originalData)}
                                    loading={loading}
                                />
                            </Row>
                        </Col>
                        <Col span={5} style={{ padding: 5 }}>
                            <Row type="flex" align="middle">
                                <Heading>Sort By :</Heading>
                                <Select style={{ width: "100%" }} onChange={fetchData} dropdownMatchSelectWidth={false}
                                >
                                    {sortByOptions.length > 0 &&
                                        sortByOptions.map(item => {
                                            return item !== "primary_key" && <Option value={item === "line_age" ? "lines.queued_at" : item === "due_day" ? "orders.due_date" : item} style={{ textTransform: "capitalize" }}>{GetLabel(item, modifiedLabels).replace(/_/g, " ")}</Option>
                                        })
                                    }
                                </Select>
                            </Row>
                        </Col>
                        <Col span={11} style={{ padding: 5 }}>
                            <br />
                            <Row type="flex" align="right" justify="end">
                                <Col span={8} style={{ padding: 5 }}>
                                    <Button type="primary" style={{ width: "100%" }} onClick={singleBatching} disabled={loading}>Batch Now</Button>
                                </Col>
                                <Col span={8} style={{ padding: 5 }}>
                                    <Button type="primary" style={{ width: "100%" }} onClick={batchingAll}
                                        disabled={loading || userData && userData.should_authorize && !userData.permissions.find(p => p === 'batch-all')}
                                    >Batch All</Button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </div>
                <div style={{ margin: "20px 40px 20px 40px", fontWeight: "bold" }}>
                    <HeadingTotal>TOTAL COUNTS: {`${itemData.length}`}</HeadingTotal>
                </div>
                <div style={{ paddingBottom: 10 }}>

                    <TableData itemData={itemData}
                        loading={loading}
                        setLoading={setLoading}
                        groupPrimaryKeys={groupPrimaryKeys}
                        selectedPrimaryKey={selectedPrimaryKey}
                        setSelectedPrimaryKey={setSelectedPrimaryKey}
                        data={data}
                        columns={columns}
                        setData={setData}
                        selectedLineIds={selectedLineIds}
                        setSelectedLineIds={setSelectedLineIds}
                    />
                </div>

            </Panel>
        </>
    )
}

export default WaitingForPartners