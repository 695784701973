import React, {useState} from 'react';
import {Button, Icon, Upload} from 'antd';
import _ from 'lodash';
import BulkCloneResponseModal from './BulkCloneResponseModal';

export default () => {

  const [showModal, setShowModal] = useState(false);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const props = {
    name: 'file',
    action: `${process.env.REACT_APP_ZENSMART_API_SERVER}/api/v1/products/clone/bulk`,
    showUploadList: false,
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    onChange(info) {
      setResponse(null);
      setLoading(true);
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        setLoading(false);
        setResponse({
          type: 'success',
          message: 'PRODUCTS CLONED SUCCESSFULLY',
          results: info.file.response.data,
        });
        setShowModal(true);
      } else if (info.file.status === 'error') {
        setLoading(false);
        if (_.get(info, 'file.error.status') === 422) {
          info.file.response.type = 'validation-error';
          setShowModal(true);
          setResponse(info.file.response);
          return;
        }

        if (_.get(info, 'file.response.error') === 'bulk-product-clone-error') {
          info.file.response.type = 'bulk-product-clone-error';
          setShowModal(true);
          setResponse(info.file.response);
          return;
        }

        setShowModal(true);
        setResponse({
          type: 'error',
          message: 'AN UNEXPECTED ERROR OCCURRED. PLEASE CONTACT SUPPORT!',
          results: [],
        });
      }
    },
  };

  return (
      <>
        <BulkCloneResponseModal show={showModal}
                                onClose={() => setShowModal(false)}
                                response={response}/>
        <Upload disabled={loading} {...props}>
          <Button disabled={loading}>
            <Icon type="upload"/> {loading
                                   ? 'Processing..'
                                   : 'Upload CSV for Bulk Cloning'}
          </Button>
        </Upload>
      </>
  );
}